import { containsHTML } from '@meetingflow/common/StringHelpers';
import { isArray } from 'lodash';
import { Descendant, Editor, Element, Text, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { htmlToSlateDescendants } from '../../../Helpers/SlateHelpers';

const SLATE_FRAGMENT_FORMAT = 'application/x-slate-fragment';
const MF_FRAGMENT_FORMAT = 'application/x-meetingflow-slate-fragment';

const catchSlateFragment = /data-slate-fragment="(.+?)"/m;
export const getSlateFragmentAttribute = (
  dataTransfer: DataTransfer,
): string | void => {
  const htmlData = dataTransfer.getData('text/html');
  const [, fragment] = htmlData.match(catchSlateFragment) || [];
  return fragment;
};

export const withRichPaste = (editor: Editor & ReactEditor) => {
  const { insertData, insertTextData, setFragmentData, insertFragment } =
    editor;

  editor.setFragmentData = (data, origin) => {
    setFragmentData(data, origin);
    const fragment = data.getData(SLATE_FRAGMENT_FORMAT);
    if (fragment) {
      data.setData(MF_FRAGMENT_FORMAT, fragment);
    }
  };

  editor.insertData = (data) => {
    const fragment =
      data.getData(MF_FRAGMENT_FORMAT) || getSlateFragmentAttribute(data);
    // If the clipboard has meetingflow content, let slate handle inserting the slate fragments directly
    if (fragment) {
      const decoded = decodeURIComponent(window.atob(fragment));
      const parsed = JSON.parse(decoded) as Descendant[];

      const validateNode = (c: Descendant | Descendant[]): boolean =>
        (isArray(c) && c.every(validateNode)) ||
        Text.isText(c) ||
        (Element.isElement(c) && c.children.every(validateNode));

      if (validateNode(parsed)) {
        if (
          !!editor.selection &&
          parsed.length === 1 &&
          Element.isElement(parsed[0]) &&
          parsed[0].children.every(
            (child) =>
              (Element.isElement(child) && Editor.isInline(editor, child)) ||
              Text.isText(child),
          )
        ) {
          insertFragment(parsed[0].children);
          return;
        } else {
          insertFragment(parsed);
          return;
        }
      }
    }

    const htmlData = data.getData('text/html');
    const plainTextData = data.getData('text/plain');

    if (htmlData && containsHTML(htmlData)) {
      try {
        const slateNodes = htmlToSlateDescendants(
          htmlData,
          data.getData('text/rtf'),
        );
        const validateNode = (c: Descendant | Descendant[]): boolean =>
          (isArray(c) && c.every(validateNode)) ||
          Text.isText(c) ||
          (Element.isElement(c) && c.children.every(validateNode));

        if (validateNode(slateNodes)) {
          if (
            !!editor.selection &&
            slateNodes.length === 1 &&
            Element.isElement(slateNodes[0]) &&
            slateNodes[0].children.every(
              (child) =>
                (Element.isElement(child) && Editor.isInline(editor, child)) ||
                Text.isText(child),
            )
          ) {
            insertFragment(slateNodes[0].children);
            return;
          } else {
            insertFragment(slateNodes);
            return;
          }
        }
        return;
      } catch {
        console.error(`Error inserting rich text content`);
      }
    }
    if (plainTextData) {
      try {
        const validateNode = (c: Descendant): boolean =>
          Text.isText(c) ||
          (Element.isElement(c) && c.children.every(validateNode));
        let parsed = JSON.parse(plainTextData);
        if (isArray(parsed)) {
          parsed = { children: parsed };
        }
        if (Element.isElement(parsed) && validateNode(parsed)) {
          Transforms.insertNodes(editor, parsed.children);
          return;
        }
      } catch { }
    }
    return insertData(data);
  };

  editor.insertTextData = (data) => {
    // If the clipboard has meetingflow content, let slate handle inserting the slate fragments directly
    if (!!data.getData(MF_FRAGMENT_FORMAT)) {
      return insertTextData(data);
    }

    const htmlData = data.getData('text/html');
    const plainTextData = data.getData('text/plain');

    if (htmlData && containsHTML(htmlData)) {
      try {
        const slateNodes = htmlToSlateDescendants(
          htmlData,
          data.getData('text/rtf'),
        );
        Transforms.insertNodes(editor, slateNodes);
        return true;
      } catch {
        console.error(`Error inserting rich text content`);
      }
    }
    if (plainTextData) {
      try {
        const validateNode = (c: Descendant): boolean =>
          Text.isText(c) ||
          (Element.isElement(c) && c.children.every(validateNode));
        let parsed = JSON.parse(plainTextData);
        if (isArray(parsed)) {
          parsed = { children: parsed };
        }
        if (Element.isElement(parsed) && validateNode(parsed)) {
          Transforms.insertNodes(editor, parsed.children);
          return true;
        }
      } catch { }
    }
    return insertTextData(data);
  };

  return editor;
};
