import {
  Contact,
  RemoteCursorData,
  SlateAIAction,
  SlateSidePanelShortcut,
  SlateTag,
  Tag,
  User,
} from '@meetingflow/common/Api/data-contracts';
import {
  CustomElement,
  CustomText,
  SlateImage,
  SlateLink,
  SlateMention,
} from '@meetingflow/common/Types/Slate';
import {
  WithCursorsOptions,
  withCursors,
  withYHistory,
  withYjs,
} from '@slate-yjs/core';
import { isArray } from 'lodash';
import {
  Editor,
  Element,
  Location,
  MaximizeMode,
  Path,
  Range,
  Text,
  Transforms,
  createEditor,
} from 'slate';
import { withHistory } from 'slate-history';
import { withReact } from 'slate-react';
import { WebsocketProvider } from 'y-websocket';
import * as Y from 'yjs';
import { CreateDeferredPromise } from '../../../Helpers/DeferredPromise';
import { hasContent } from '../../../Helpers/SlateHelpers';
import { LinkContent } from '../../../types/LinkContent';
import { withChecklists } from '../plugins/withChecklists';
import { withCustomBreaks } from '../plugins/withCustomBreaks';
import { withId } from '../plugins/withId';
import { withImages } from '../plugins/withImages';
import { withLinks } from '../plugins/withLinks';
import { withLists } from '../plugins/withLists';
import { withMarkdown } from '../plugins/withMarkdown';
import { withMentions } from '../plugins/withMentions';
import { withNoEmptyDocument } from '../plugins/withNoEmptyDocument';
import { withPanelActions } from '../plugins/withPanelActions';
import { withRichPaste } from '../plugins/withRichPaste';
import { withSyncAwareness } from '../plugins/withSyncAwareness';
import { withTags } from '../plugins/withTags';

export const LIST_TYPES: Element['type'][] = ['numbered-list', 'bulleted-list'];
export const INLINE_TYPE: Element['type'][] = [
  'link',
  'tag',
  'mention',
  'ai-action',
  'side-panel-shortcut',
];

export const getCoreEditor = (
  getAccessToken: () => Promise<string>,
  organizationSlug: string,
  getLinkContent: CreateDeferredPromise<
    LinkContent,
    Partial<LinkContent> | undefined
  >,
  meetingPlanId?: string,
) => {
  return withMarkdown(
    withChecklists(
      withImages(
        withRichPaste(
          withLinks(
            withPanelActions(
              withLists(
                withTags(
                  withMentions(
                    withCustomBreaks(
                      withNoEmptyDocument(
                        // withDebug(
                        withReact(
                          withHistory(withId(createEditor())),
                          'x-meetingflow-slate-fragment',
                        ),
                        // ),
                      ),
                    ),
                  ),
                ),
              ),
            ),
            getLinkContent,
          ),
        ),
        getAccessToken,
        organizationSlug,
        meetingPlanId,
      ),
    ),
  );
};

export const getYjsEditor = (
  yArray: Y.XmlText,
  provider: WebsocketProvider,
  cursorOpts: WithCursorsOptions<RemoteCursorData>,
  getAccessToken: () => Promise<string>,
  organizationSlug: string,
  getLinkContent: CreateDeferredPromise<
    LinkContent,
    Partial<LinkContent> | undefined
  >,
  meetingPlanId?: string,
) => {
  return withYHistory(
    withSyncAwareness(
      withCursors(
        withYjs(
          getCoreEditor(
            getAccessToken,
            organizationSlug,
            getLinkContent,
            meetingPlanId,
          ),
          yArray,
          {
            autoConnect: false,
          },
        ),
        provider.awareness,
        cursorOpts,
      ),
      provider,
    ),
  );
};

export const beforeText = (editor: Editor): string | undefined => {
  const { selection } = editor;

  if (!selection || !Range.isCollapsed(selection)) {
    return;
  }

  const { anchor } = selection;
  const block = Editor.above(editor, {
    match: (n) => Element.isElement(n) && Editor.isBlock(editor, n),
  });
  const path = block ? block[1] : [];
  const blockRange = { anchor, focus: Editor.start(editor, path) };

  return Editor.string(editor, blockRange);
};

export const afterText = (editor: Editor): string | undefined => {
  const { selection } = editor;

  if (!selection || !Range.isCollapsed(selection)) {
    return;
  }

  const { anchor } = selection;
  const block = Editor.above(editor, {
    match: (n) => Element.isElement(n) && Editor.isBlock(editor, n),
  });
  const path = block ? block[1] : [];
  const blockRange = { anchor, focus: Editor.end(editor, path) };

  return Editor.string(editor, blockRange);
};

export const isListActive = (editor: Editor, at?: Location): boolean => {
  const [ancestor] = Editor.nodes(editor, {
    at,
    match: (n) => Element.isElement(n) && LIST_TYPES.includes(n.type),
  });

  return !!ancestor;
};

export const isNestedListActive = (editor: Editor, at?: Location): boolean => {
  const [, match] = Editor.nodes(editor, {
    at,
    match: (n) => Element.isElement(n) && LIST_TYPES.includes(n.type),
  });

  return !!match;
};

export const indentList = (editor: Editor): void => {
  const { selection } = editor;
  if (!selection) {
    return;
  }

  const listActive = isListActive(editor);
  if (!listActive) {
    return;
  }

  const { anchor, focus } = selection;
  const selectionCollapsed = Range.isCollapsed(selection);
  const isForwardSelection = Range.isForward(selection);

  Editor.withoutNormalizing(editor, () => {
    for (const [, path] of Editor.nodes<Element>(editor, {
      mode: 'lowest',
      match: (n, p) =>
        Element.isElement(n) &&
        n.type === 'list-item' &&
        (selectionCollapsed ||
          (isForwardSelection &&
            !Editor.isEnd(editor, anchor, p) &&
            !Editor.isStart(editor, focus, p)) ||
          (!isForwardSelection &&
            !Editor.isStart(editor, anchor, p) &&
            !Editor.isEnd(editor, focus, p))),
    })) {
      const parent = Editor.above<Element>(editor, {
        at: path,
        match: (n) => Element.isElement(n) && LIST_TYPES.includes(n.type),
        mode: 'lowest',
      });

      if (!parent) {
        continue;
      }

      const [parentNode] = parent;

      Transforms.wrapNodes(
        editor,
        { type: parentNode.type, children: [] } as CustomElement,
        { at: path },
      );
    }
  });
};

export const deindentList = (editor: Editor): void => {
  const { selection } = editor;
  const nestedListActive = isNestedListActive(editor);
  if (!nestedListActive || !selection) {
    return;
  }

  const { anchor, focus } = selection;
  const selectionCollapsed = Range.isCollapsed(selection);
  const isForwardSelection = Range.isForward(selection);

  const pathRefs = Array.from(
    Editor.nodes<Element>(editor, {
      mode: 'lowest',
      match: (n, p) =>
        Element.isElement(n) &&
        n.type === 'list-item' &&
        isNestedListActive(editor, p) &&
        (selectionCollapsed ||
          (isForwardSelection &&
            !Editor.isEnd(editor, anchor, p) &&
            !Editor.isStart(editor, focus, p)) ||
          (!isForwardSelection &&
            !Editor.isStart(editor, anchor, p) &&
            !Editor.isEnd(editor, focus, p))),
    }),
  ).map(([_, path]) => Editor.pathRef(editor, path));

  if (!pathRefs.length) {
    return;
  }

  Editor.withoutNormalizing(editor, () => {
    for (const pathRef of pathRefs) {
      const path = pathRef.unref();
      if (path) {
        Transforms.unwrapNodes(editor, {
          match: (n) => Element.isElement(n) && LIST_TYPES.includes(n.type),
          at: path,
          mode: 'lowest',
          split: true,
        });
      }
    }
  });
};

export const isBlockActive = (
  editor: Editor,
  format: Element['type'],
  at?: Location,
): boolean => {
  const [match] = Editor.nodes(editor, {
    at,
    match: (n) => Element.isElement(n) && n.type === format,
  });

  return !!match;
};

export const toggleBlock = (
  editor: Editor,
  format: Element['type'],
  additionalProps?: Partial<Element>,
): void => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Editor.withoutNormalizing(editor, () => {
    Transforms.unwrapNodes(editor, {
      mode: 'all',
      match: (n) => Element.isElement(n) && LIST_TYPES.includes(n.type),
      split: true,
    });

    Transforms.setNodes(editor, {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
      ...additionalProps,
    });

    if (!isActive && isList) {
      const block = { type: format, children: [] } as CustomElement;
      Transforms.wrapNodes(editor, block);
    }
  });
};

export const isMarkActive = (
  editor: Editor,
  format: keyof Omit<Text, 'text'>,
): boolean => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export const toggleMark = (
  editor: Editor,
  format: keyof Omit<Text, 'text'>,
): void => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export const isFormatActive = (
  editor: Editor,
  format: keyof Text,
  at?: Location,
) => {
  const [match] = Editor.nodes(editor, {
    at,
    match: (n) => Text.isText(n) && !!n[format],
    mode: 'all',
  });
  return !!match;
};

export const toggleFormat = (editor: Editor, format: keyof Text) => {
  const isActive = isFormatActive(editor, format);
  Transforms.setNodes(
    editor,
    { [format]: isActive ? null : true },
    { match: Text.isText, split: true },
  );
};

export const insertLink = (
  editor: Editor,
  href: string,
  text?: string,
): void => {
  const { selection } = editor;

  const marks = Editor.marks(editor);

  const isCollapsed = selection && Range.isCollapsed(selection);

  const activeLinkNodes = Array.from(
    Editor.nodes<SlateLink>(editor, {
      match: (n) => Element.isElement(n) && n.type === 'link',
    }),
  );

  // If a single link element is active and the cursor is collapsed, update the href on the link
  if (isCollapsed && activeLinkNodes.length === 1) {
    const [, path] = activeLinkNodes[0];
    Transforms.setNodes(editor, { href }, { at: path });
    return;
  }

  Editor.withoutNormalizing(editor, () => {
    // Unwrap existing links so that the highlighted text span will become the link text
    if (activeLinkNodes.length) {
      unwrapNodes(editor, 'link');
    }

    const link = {
      type: 'link',
      href,
      children: isCollapsed ? [{ ...marks, text: text || href }] : [],
    } as SlateLink;

    // If there is no selection, insert the link at the current carat position
    if (isCollapsed) {
      Transforms.insertNodes(editor, [link, { ...marks, text: '' }]);
      // Else linkify the currently selected text
    } else {
      Transforms.wrapNodes(editor, link, {
        split: true,
        match: Text.isText,
        mode: 'lowest',
      });
      Transforms.collapse(editor, { edge: 'end' });
    }
  });
};

export const insertAIAction = (
  editor: Editor,
  text?: string,
  displayText?: string,
  prompt?: string,
): void => {
  const { selection } = editor;

  const marks = Editor.marks(editor);

  const isCollapsed = selection && Range.isCollapsed(selection);

  const activeAIActionNodes = Array.from(
    Editor.nodes<SlateAIAction>(editor, {
      match: (n) => Element.isElement(n) && n.type === 'ai-action',
    }),
  );

  // If a single link element is active and the cursor is collapsed, update the attributes on the ai action node
  if (isCollapsed && activeAIActionNodes.length === 1) {
    const [, path] = activeAIActionNodes[0];
    Transforms.setNodes(editor, { displayText, prompt }, { at: path });
    return;
  }

  Editor.withoutNormalizing(editor, () => {
    // Unwrap existing links so that the highlighted text span will become the link text
    if (activeAIActionNodes.length) {
      unwrapNodes(editor, 'ai-action');
    }

    const aiAction = {
      type: 'ai-action',
      displayText,
      prompt,
      children: isCollapsed ? [{ ...marks, text: text || '' }] : [],
    } as SlateAIAction;

    // If there is no selection, insert the ai action at the current caret position
    if (isCollapsed) {
      Transforms.insertNodes(editor, [aiAction, { ...marks, text: '' }]);
      // Else actionify the currently highlighted text
    } else {
      Transforms.wrapNodes(editor, aiAction, {
        split: true,
        match: Text.isText,
        voids: true,
      });
      Transforms.collapse(editor, { edge: 'end' });
    }
  });
};

export const insertSidePanelShortcut = (
  editor: Editor,
  text: string,
  panelType?: string,
): void => {
  const { selection } = editor;

  const marks = Editor.marks(editor);

  const isCollapsed = selection && Range.isCollapsed(selection);

  const activePanelShortcutNodes = Array.from(
    Editor.nodes<SlateSidePanelShortcut>(editor, {
      match: (n) => Element.isElement(n) && n.type === 'side-panel-shortcut',
    }),
  );

  // If a single link element is active and the cursor is collapsed, update the attributes on the ai action node
  if (isCollapsed && activePanelShortcutNodes.length === 1) {
    const [, path] = activePanelShortcutNodes[0];
    Transforms.setNodes(editor, { panelType }, { at: path });
    return;
  }

  Editor.withoutNormalizing(editor, () => {
    // Unwrap existing links so that the highlighted text span will become the link text
    if (activePanelShortcutNodes.length) {
      unwrapNodes(editor, 'side-panel-shortcut');
    }

    const panelShortcut = {
      type: 'side-panel-shortcut',
      panelType,
      children: isCollapsed ? [{ ...marks, text: text || '' }] : [],
    } as SlateSidePanelShortcut;

    // If there is no selection, insert the ai action at the current caret position
    if (isCollapsed) {
      Transforms.insertNodes(editor, [panelShortcut, { ...marks, text: '' }]);
      // Else actionify the currently highlighted text
    } else {
      Transforms.wrapNodes(editor, panelShortcut, {
        split: true,
        match: Text.isText,
        voids: true,
      });
      Transforms.collapse(editor, { edge: 'end' });
    }
  });
};

export const insertImageNode = (
  editor: Editor,
  url?: string,
  mimeType?: string,
) => {
  if (!url) {
    return;
  }

  const { selection } = editor;

  const imageNode: SlateImage = {
    type: 'image',
    href: url,
    mimeType,
    children: [{ text: '' }],
  };

  if (!!selection) {
    const [parentNode, parentPath] = Editor.parent(
      editor,
      selection.focus?.path,
    );

    if (
      Element.isElement(parentNode) &&
      (editor.isVoid(parentNode) || hasContent(parentNode))
    ) {
      // Insert the new image node after the void node or a node with content
      Transforms.insertNodes(editor, imageNode, {
        at: Path.next(parentPath),
        select: true,
      });
    } else {
      // If the node is empty, replace it instead
      Transforms.removeNodes(editor, { at: parentPath });
      Transforms.insertNodes(editor, imageNode, {
        at: parentPath,
        select: true,
      });
    }
  } else {
    // Insert the new image node at the bottom of the Editor when selection
    // is falsey
    Transforms.insertNodes(editor, imageNode, { select: true });
    Transforms.insertNodes(editor, {
      type: 'paragraph',
      children: [{ text: '' }],
    });
  }
};

export const insertMention = (
  editor: Editor,
  type: 'contact' | 'user',
  mentioned?: Pick<Contact | User, 'id' | 'name' | 'email'>,
) => {
  if (!mentioned) {
    return;
  }

  const mention = {
    type: 'mention',
    contactId: type === 'contact' ? mentioned.id : undefined,
    userId: type === 'user' ? mentioned.id : undefined,
    name: mentioned.name,
    email: mentioned.email,
    children: [{ text: '' }],
  } as SlateMention;

  Transforms.insertNodes(editor, mention);
  Transforms.move(editor);
};

export const insertTag = (editor: Editor, tag?: Pick<Tag, 'id' | 'label'>) => {
  if (!tag) {
    return;
  }

  const tagElement = {
    type: 'tag',
    tagId: tag.id,
    label: tag.label,
    children: [{ text: '' }],
  } as SlateTag;

  Transforms.insertNodes(editor, tagElement);
  Transforms.move(editor);
};

export const insertExitBreak = (editor: Editor, at?: Location) => {
  const parentNode = getParentBlock(editor, { at });
  Editor.withoutNormalizing(editor, () => {
    Transforms.splitNodes(editor, {
      at,
      always: true,
    });
    if (parentNode && INLINE_TYPE.includes(parentNode[0].type)) {
      Transforms.unwrapNodes(editor, {
        match: (n) => Element.isElement(n) && n.type === parentNode['0'].type,
      });
    } else {
      Transforms.setNodes(
        editor,
        { type: 'paragraph' },
        { match: (n) => Element.isElement(n) },
      );
    }
  });
};

export const getParentBlock = <T extends Element = Element>(
  editor: Editor,
  {
    at,
    type,
  }: {
    at?: Location;
    type?: Element['type'] | Element['type'][];
  } = {},
) =>
  Editor.above<T>(editor, {
    match: (n) =>
      Element.isElement(n) &&
      (!type || (isArray(type) ? type.includes(n.type) : n.type === type)),
    at,
    mode: 'lowest',
  });

export const getChildBlocks = <T extends Element = Element>(
  editor: Editor,
  {
    at,
    type,
  }: {
    at: Path;
    type?: Element['type'] | Element['type'][];
  },
) =>
  Editor.nodes<T>(editor, {
    match: (n, p) =>
      Element.isElement(n) &&
      Path.isChild(p, at) &&
      (!type || (isArray(type) ? type.includes(n.type) : n.type === type)),
    at,
  });

export const getChildText = (
  editor: Editor,
  {
    at,
  }: {
    at: Path;
  },
) =>
  Editor.nodes<CustomText>(editor, {
    match: (n, p) => Text.isText(n) && Path.isChild(p, at),
    at,
  });

export const getPreviousSiblingBlock = <T extends Element = Element>(
  editor: Editor,
  {
    at,
    type,
  }: {
    at?: Location;
    type?: Element['type'] | Element['type'][];
  } = {},
) =>
  Editor.previous<T>(editor, {
    match: (n) =>
      Element.isElement(n) &&
      (!type || (isArray(type) ? type.includes(n.type) : n.type === type)),
    at,
  });

export const getNextSiblingBlock = <T extends Element = Element>(
  editor: Editor,
  {
    at,
    type,
  }: {
    at?: Location;
    type?: Element['type'] | Element['type'][];
  } = {},
) =>
  Editor.next<T>(editor, {
    match: (n) =>
      Element.isElement(n) &&
      (!type || (isArray(type) ? type.includes(n.type) : n.type === type)),
    at,
  });

export const unwrapNodes = (
  editor: Editor,
  type: Element['type'] | Element['type'][],
  {
    at,
    split,
    mode,
  }: { at?: Location; split?: boolean; mode?: MaximizeMode } = {},
) =>
  Transforms.unwrapNodes(editor, {
    at,
    match: (n) =>
      Element.isElement(n) &&
      (!type || (isArray(type) ? type.includes(n.type) : n.type === type)),
    split: split === undefined ? true : split,
    mode: mode === undefined ? 'all' : mode,
  });
