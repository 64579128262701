import { DateTime } from 'luxon';
import { createSearchParams } from 'react-router-dom';

export const collectToRecord = (
  params: URLSearchParams,
): Record<string, string | string[]> => {
  const result = {} as Record<string, string | string[]>;
  for (const [key, value] of params.entries()) {
    if (key in result) {
      const currentValue = result[key];
      if (currentValue instanceof Array) {
        result[key] = [...currentValue, value];
      } else {
        result[key] = [currentValue, value];
      }
    } else {
      result[key] = value;
    }
  }

  return result;
};

export const mergeSearchParams = (
  searchParams: URLSearchParams,
  newSearchParams: Record<string, string | string[]> | URLSearchParams,
): URLSearchParams => {
  if (newSearchParams instanceof URLSearchParams) {
    newSearchParams.sort();
    return newSearchParams;
  } else {
    const newParams = createSearchParams({
      ...collectToRecord(searchParams),
      ...newSearchParams,
    });
    newParams.sort();
    return newParams;
  }
};

export const getDateValue = (
  searchParams: URLSearchParams,
  key: string,
): Date | undefined => {
  if (searchParams.has(key)) {
    try {
      DateTime.fromISO(decodeURIComponent(searchParams.get(key)!)).toJSDate();
    } catch (err: unknown) {
      return undefined;
    }
  }
  return undefined;
};

export const getValue = (
  searchParams: URLSearchParams,
  key: string,
): string | undefined => searchParams.get(key) || undefined;

export const setValue = (
  searchParams: URLSearchParams,
  key: string,
  value: string,
) => mergeSearchParams(searchParams, { [key]: value });

export const deleteValue = (searchParams: URLSearchParams, key: string) => {
  const newParams = new URLSearchParams(searchParams);
  newParams.delete(key);
  newParams.sort();
  return newParams;
};

export const deleteValues = (searchParams: URLSearchParams, keys: string[]) => {
  const newParams = new URLSearchParams(searchParams);
  for (const key of keys) {
    newParams.delete(key);
  }
  newParams.sort();
  return newParams;
};

export const getArrayValues = (
  searchParams: URLSearchParams,
  key: string,
): string[] => (searchParams.has(key) ? searchParams.getAll(key) : []);

export const addArrayValue = (
  searchParams: URLSearchParams,
  key: string,
  value: string,
) => {
  const newParams = new URLSearchParams(searchParams);
  newParams.append(key, value);
  newParams.sort();
  return newParams;
};

export const removeArrayValue = (
  searchParams: URLSearchParams,
  key: string,
  value: string,
) => {
  const newParams = new URLSearchParams(
    Array.from(searchParams.entries()).filter(
      ([k, v]) => k !== key || v !== value,
    ),
  );
  newParams.sort();
  return newParams;
};

export const removeAllArrayValues = (
  searchParams: URLSearchParams,
  key: string,
) => {
  const newParams = new URLSearchParams(searchParams);
  newParams.delete(key);
  newParams.sort();
  return newParams;
};
