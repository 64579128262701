import { IconButton, Panel, PanelType, Text } from '@fluentui/react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { BackIconProps, CancelIconProps } from '../../../../utils/iconProps';
import { DealRoomTaskCommentsPanel } from './DealRoomTaskCommnetsPanel';
import { useDealRoom } from '../../../../Hooks/useDealRoom';
import { useUserProfile } from '../../../../Hooks/useProfile';
import { useCommentsContainerStyles } from '../../Components/ConversationsTab/useCommentsContainerStyles';
import { useBoolean } from '@fluentui/react-hooks';

type DealRoomTaskCommentsContainerProps = {
  currentTaskId: number | null;
  onDismiss: () => void;
  organizationSlug: string;
  dealRoomId: number;
};

export const DealRoomTaskCommentsContainer = ({
  currentTaskId,
  onDismiss,
  organizationSlug,
  dealRoomId,
}: DealRoomTaskCommentsContainerProps) => {
  const [currentThreadId, setCurrentThreadId] = useState<number | undefined>(
    undefined,
  );
  const [
    isMentionsPopupOpen,
    { setTrue: setIsMentionPopupOpen, setFalse: setIsMentionPopupClose },
  ] = useBoolean(false);
  const dealRoomPanelRef = useRef<{ backToConvList: Function }>(null);

  const { user: mfUser } = useUserProfile();
  const { dealRoom } = useDealRoom(organizationSlug, dealRoomId);

  const { currentContactId, currentContactCompanyId } = useMemo(() => {
    if (!dealRoom || !mfUser?.email)
      return {
        currentContactId: undefined,
        currentContactCompanyId: undefined,
      };

    const currentContact = dealRoom.contacts?.find(
      (contact) => contact.email.toLowerCase() === mfUser.email.toLowerCase(),
    );

    const currentCompany = dealRoom.companies?.[0];

    return {
      currentContactId: currentContact?.id,
      currentContactCompanyId: currentCompany?.id,
    };
  }, [dealRoom, mfUser?.email]);

  const handleCheckForMentionsPopup = useCallback(
    (isPopupOpen: boolean) => {
      if (isPopupOpen) {
        setIsMentionPopupOpen();
      } else {
        setIsMentionPopupClose();
      }
    },
    [setIsMentionPopupClose, setIsMentionPopupOpen],
  );

  const styles = useCommentsContainerStyles();

  return (
    <Panel
      className={styles.panel}
      isOpen={currentTaskId !== null}
      onDismiss={isMentionsPopupOpen ? undefined : onDismiss} // Don't dismiss if mentions popup is open because it will se the click event as it is outside the panel
      type={PanelType.medium}
      headerText={''} // Empty header text since we'll implement custom header later
      isLightDismiss // Allows clicking outside panel to dismiss
      onRenderNavigation={() => null}
    >
      <div className={styles.mainContainer}>
        <IconButton
          className={styles.headerButton}
          iconProps={CancelIconProps}
          onClick={onDismiss}
        />
        <div className={styles.content}>
          <div className={styles.titleContainer}>
            {currentThreadId !== undefined ? (
              <>
                <IconButton
                  onClick={() => {
                    if (dealRoomPanelRef?.current?.backToConvList) {
                      dealRoomPanelRef.current.backToConvList();
                    }
                  }}
                  iconProps={BackIconProps}
                />
                <Text className={styles.title}>Thread</Text>
              </>
            ) : (
              <Text className={styles.title}>Comments</Text>
            )}
          </div>

          {currentTaskId !== null && (
            <DealRoomTaskCommentsPanel
              ref={dealRoomPanelRef}
              organizationSlug={organizationSlug}
              dealRoomId={dealRoomId}
              currentThreadId={currentThreadId}
              setCurrentThreadId={setCurrentThreadId}
              taskId={currentTaskId}
              currentContactCompanyId={currentContactId}
              currentContactId={currentContactCompanyId}
              handleCheckForMentionsPopup={handleCheckForMentionsPopup}
            />
          )}
        </div>
      </div>
    </Panel>
  );
};
