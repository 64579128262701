import { ILinkProps, Link } from '@fluentui/react';
import React, { useState } from 'react';
import { AsyncClickOptions, AsyncOnClickProps } from './Common';

export const withAsyncOnClick = (
  InnerComponent: React.ComponentType<ILinkProps>,
  { disableOnExecute }: AsyncClickOptions = {},
) => {
  return ({
    disabled,
    onClick,
    ...rest
  }: Omit<ILinkProps, 'onClick'> & AsyncOnClickProps) => {
    const [isExecuting, setIsExecuting] = useState<boolean>(false);
    return (
      <InnerComponent
        disabled={disabled || (disableOnExecute && isExecuting)}
        onClick={async (e) => {
          if (isExecuting) {
            return;
          }

          setIsExecuting(true);
          try {
            await onClick(e);
          } catch (err: unknown) {
            console.info(
              `AsyncLink caught exception executing onClick handler`,
            );
          } finally {
            setIsExecuting(false);
          }
        }}
        {...rest}
      />
    );
  };
};

export const AsyncLink = withAsyncOnClick(Link);
