import { useAuth0 } from '@auth0/auth0-react';
import { Checkbox, FontSizes, Text, TextField } from '@fluentui/react';
import {
  DetailedMeetingflow,
  Task,
} from '@meetingflow/common/Api/data-contracts';
import { WithRequiredProperty } from '@meetingflow/common/TypeHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { orderBy } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import * as Y from 'yjs';
import { AsyncPrimaryButton } from '../../../../Components/HOC/AsyncButton';
import { SalesforceFormComponent } from '../../../../Components/MeetingPlans/SidePanels/Salesforce/SalesforceFormComponent';
import StyledDateTime from '../../../../Components/StyledDateTime';
import { toPlainText } from '../../../../Helpers/MeetingPlanHelpers';
import {
  getDefaultState,
  getFieldNameFieldMap,
  getYupObjectValidator,
} from '../../../../Helpers/Salesforce/SalesforceFieldHelpers';
import { validateData } from '../../../../Helpers/Salesforce/SalesforceYupValidators';
import { hasContent } from '../../../../Helpers/yjsHelpers';
import { useExternalServiceConfigurations } from '../../../../Hooks/useExternalServiceConfigurations';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import { useLocalStorageState } from '../../../../Hooks/useLocalStorageState';
import { useUserProfile } from '../../../../Hooks/useProfile';
import { SalesforceAddress } from '../../../../Models/Salesforce/SalesforceAddress';
import { SalesforceLocation } from '../../../../Models/Salesforce/SalesforceLocation';
import { SalesforceSchemaResponse } from '../../../../Models/Salesforce/SalesforceObjectSchema';
import { SalesforceTask } from '../../../../Models/SalesforceTask';
import { SalesforceSObjectSchemaQuery } from '../../../../QueryNames';
import { ApiClient } from '../../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../../Themes/Themes';
import { SalesforcePanelContext } from '../../../../types/SalesforcePanelContext';
import MeetingPlanNotesSummaryForm from '../../../Common/NotesSummaryForm';
import { BaseSidePanel } from '../BaseSidePanel';
import { SalesforceAccountTile } from './SalesforceAccountTile';
import { SalesforceOpportunityTile } from './SalesforceOpportunityTile';

export type LogMeetingToSalesforceSidePanelProps = {
  organizationSlug: string;
  meetingPlanId: string;
  internalDomains: string[];
  meetingPlan: Pick<
    DetailedMeetingflow,
    | 'id'
    | 'title'
    | 'startTime'
    | 'organizer'
    | 'creator'
    | 'attendees'
    | 'companies'
    | 'textSummary'
    | 'callRecording'
    | 'userActivity'
  >;
  ydoc: Y.Doc;
  notesYArray: Y.XmlText;
  tasks: Task[];
  onSalesforceObjectClick: (c: SalesforcePanelContext) => void;
  onSuccess: () => void;
  onClose: () => void;
  onBack?: () => void;
} & WithRequiredProperty<
  Omit<SalesforcePanelContext, 'associatedWithPlan' | 'serviceInstanceId'>,
  'objectType' | 'objectId'
>;

export const LogMeetingToSalesforceSidePanel = ({
  objectType,
  objectId,
  name,
  serviceConfigurationId,
  organizationSlug,
  meetingPlanId,
  internalDomains,
  meetingPlan,
  ydoc,
  notesYArray,
  tasks,
  onSalesforceObjectClick,
  onSuccess,
  onClose,
  onBack,
}: LogMeetingToSalesforceSidePanelProps) => {
  const appInsights = useAppInsightsContext();
  const { getAccessTokenSilently } = useAuth0();
  const { user } = useUserProfile();

  const { isDark } = useLightOrDarkMode();

  const {
    configurationById,
    configurationsWithToken: salesforceConfigurations,
  } = useExternalServiceConfigurations({
    app: 'SALESFORCE',
    withToken: true,
  });

  const [taskState, setTaskState] = useState<
    Record<
      string,
      | string
      | boolean
      | number
      | SalesforceAddress
      | SalesforceLocation
      | undefined
      | null
    >
  >({});

  const [title, setTitle] = useState<string>(
    meetingPlan.title ?? 'Meetingflow Meeting',
  );
  const [summary, setSummary] = useState<string>(
    meetingPlan?.textSummary || '',
  );
  const [gptSummary, setGPTSummary] = useState<string | undefined>('');

  const [includeFullNotes, setFullNotes] = useLocalStorageState<boolean>(
    'log-to-sf-include-full-notes',
    true,
  );

  const [includeCallInsights, setIncludeCallInsights] =
    useLocalStorageState<boolean>('log-to-sf-include-call-insights', true);

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const objectName = useMemo(() => {
    switch (objectType) {
      case 'ACCOUNT':
        return 'account';
      case 'DEAL':
        return 'opportunity';
      case 'CONTACT':
        return 'contact';
      case 'LEAD':
        return 'lead';
      default:
        return 'object';
    }
  }, [objectType]);

  const { data: taskSchema, refetch: refetchTaskSchema } = useQuery(
    SalesforceSObjectSchemaQuery(
      organizationSlug,
      serviceConfigurationId,
      'Task',
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<SalesforceSchemaResponse>(
        `/organization/${organizationSlug}/external/salesforce/configuration/${serviceConfigurationId}/schema/Task`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled:
        !!serviceConfigurationId && !!configurationById(serviceConfigurationId),
    },
  );

  useEffect(() => {
    if (serviceConfigurationId) {
      refetchTaskSchema();
    }
  }, [serviceConfigurationId, refetchTaskSchema]);

  const fieldNameFieldMap = useMemo(
    () => getFieldNameFieldMap(taskSchema?.data),
    [taskSchema?.data],
  );

  const createableCustomFields = useMemo(() => {
    if (!taskSchema?.data) {
      return [];
    }

    return taskSchema.data.customFields
      .map((f) => f.fieldName)
      .filter((fieldName) => fieldNameFieldMap[fieldName]?.createable === true);
  }, [fieldNameFieldMap, taskSchema?.data]);

  const formSchema = useMemo(
    () => getYupObjectValidator(createableCustomFields, fieldNameFieldMap),
    [createableCustomFields, fieldNameFieldMap],
  );

  const [isValid, errors] = useMemo(
    () => validateData(formSchema, taskState),
    [formSchema, taskState],
  );

  useEffect(() => {
    const defaultState = getDefaultState(
      createableCustomFields,
      fieldNameFieldMap,
    );
    setTaskState(defaultState);
  }, [createableCustomFields, fieldNameFieldMap]);

  const lastLoggedToCRM = useMemo(() => {
    return orderBy(
      meetingPlan?.userActivity?.filter(
        (a) =>
          a.type.toString() === 'LOG_MEETING_TO_CRM' &&
          a.additionalDetails.app === 'SALESFORCE',
      ),
      ['createdAt'],
      ['desc'],
    )?.[0];
  }, [meetingPlan?.userActivity]);

  const lastLoggedToCRMBy = meetingPlan?.attendees.find(
    (a) => a.email === lastLoggedToCRM?.user?.email,
  );

  const lastLoggedBanner = (
    <>
      {lastLoggedToCRM ? (
        <Text
          block
          style={{
            backgroundColor: MEETINGFLOW_COLORS.orange,
            padding: '.5rem',
            borderRadius: '.25rem',
            margin: '.5rem 0',
            fontSize: FontSizes.small,
            color: isDark ? undefined : 'white',
          }}
        >
          This meeting was logged to HubSpot{' '}
          <StyledDateTime
            dateTime={lastLoggedToCRM.createdAt}
            displayComponents={['date']}
          />{' '}
          by{' '}
          {lastLoggedToCRMBy?.name ||
            lastLoggedToCRMBy?.email ||
            `Unknown User`}
          .
        </Text>
      ) : null}
    </>
  );

  return (
    <BaseSidePanel
      title={`Log Meeting to ${name}`}
      loading={false}
      onBack={onBack}
      onClose={onClose}
    >
      <div style={{ padding: '.25rem .5rem' }}>
        {objectType === 'ACCOUNT' ? (
          <SalesforceAccountTile
            meetingPlanId={meetingPlan.id}
            organizationSlug={organizationSlug}
            key={objectId}
            externalId={objectId}
            name={name}
            salesforceInstance={salesforceConfigurations[0].instanceId}
            showExternalLink
            onClick={() =>
              onSalesforceObjectClick?.({
                name: name,
                objectId: objectId,
                objectType: objectType,
                serviceConfigurationId: salesforceConfigurations[0].id,
              })
            }
          />
        ) : (
          <SalesforceOpportunityTile
            meetingPlanId={meetingPlan.id}
            organizationSlug={organizationSlug}
            key={objectId}
            externalId={objectId}
            name={name}
            salesforceInstance={salesforceConfigurations[0].instanceId}
            showExternalLink
            onClick={() =>
              onSalesforceObjectClick?.({
                name: name,
                objectId: objectId,
                objectType: objectType,
                serviceConfigurationId: salesforceConfigurations[0].id,
              })
            }
          />
        )}
      </div>

      <div
        style={{
          display: 'grid',
          gridTemplateRows: 'auto 1fr',
          gridTemplateColumns: 'auto 1fr auto auto',
          gridColumnGap: '1rem',
          gridTemplateAreas: `
          'title title title title'
          'custom-fields custom-fields custom-fields custom-fields'
          'summary summary summary summary'
          'include-agenda-notes include-call-insights cancel-button add-button'`,
          padding: '1rem',
        }}
      >
        <div style={{ gridArea: 'title', marginBottom: '0.75rem' }}>
          <TextField
            label="Title"
            value={title}
            errorMessage={
              !title.length ? `Meeting title is required` : undefined
            }
            onChange={(_e, newValue) => setTitle(newValue ?? '')}
          />
        </div>

        <div style={{ gridArea: 'custom-fields', marginBottom: '0.75rem' }}>
          {createableCustomFields.map((fieldName) => {
            const field = fieldNameFieldMap[fieldName];
            if (!field) {
              return null;
            }

            return (
              <SalesforceFormComponent
                organizationSlug={organizationSlug}
                salesforceConfigurationId={serviceConfigurationId}
                key={field.name}
                errors={errors}
                isDirty
                field={field}
                state={taskState}
                setState={setTaskState}
              />
            );
          })}
        </div>

        <div
          style={{
            gridArea: 'summary',
            marginBottom: '0.75rem',
            display: 'block',
          }}
        >
          <MeetingPlanNotesSummaryForm
            organizationSlug={organizationSlug}
            meetingplan={meetingPlan}
            summaryType={'SALESFORCE_SUMMARY'}
            displaySubject={false}
            bodyLabel="Summary"
            summarizeDisabled={
              !hasContent(notesYArray) &&
              !meetingPlan?.callRecording?.transcript?.length
            }
            onSummaryChange={setSummary}
            onSummaryGenerated={setGPTSummary}
            surfaceName="SALESFORCE_SUMMARY"
            summarizeLabel={'Generate a summary'}
            initialSummaryValue={meetingPlan?.textSummary || ''}
          />
        </div>
        <div style={{ gridArea: 'include-agenda-notes' }}>
          <Checkbox
            title="Include full notes"
            label="Include full notes"
            checked={includeFullNotes}
            onChange={(_e, checked) => setFullNotes(!!checked)}
          />
        </div>

        {meetingPlan?.callRecording?.transcriptAnalysis?.topics?.length ? (
          <div style={{ gridArea: 'include-call-insights' }}>
            <Checkbox
              title="Include Call Insights"
              label="Include Call Insights"
              checked={includeCallInsights}
              onChange={(_e, checked) => setIncludeCallInsights(!!checked)}
            />
          </div>
        ) : null}
        <div style={{ gridArea: 'add-button', marginLeft: '0.5rem' }}>
          <AsyncPrimaryButton
            disabled={isSaving || title.length === 0 || !isValid}
            onClick={async () => {
              const description = toPlainText(
                organizationSlug!,
                meetingPlan,
                ydoc,
                summary,
                {
                  includeNotes: includeFullNotes,
                  includeCallInsights: includeCallInsights,
                },
              );
              const token = await getAccessTokenSilently();
              setIsSaving(true);
              await toast.promise(
                ApiClient.post<SalesforceTask>(
                  `/organization/${organizationSlug}/external/salesforce/configuration/${serviceConfigurationId}/${objectName}/${objectId}/note`,
                  {
                    ...taskState,
                    Subject: title
                      ? `[Meetingflow] ${title}`
                      : `[Meetingflow] ${meetingPlan.title}`,
                    ActivityDate: DateTime.utc().toISO()!,
                    Description: description,
                    gptSummary,
                    summary,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      'x-meetingplan-id': meetingPlanId,
                    },
                  },
                ),
                {
                  loading: 'Logging meeting to Salesforce...',
                  success: () => {
                    setIsSaving(false);
                    appInsights.trackEvent({
                      name: 'LOG_MEETING',
                      properties: {
                        organizationSlug: organizationSlug!,
                        planId: meetingPlanId,
                        app: 'SALESFORCE',
                        sObjectType: objectType,
                      },
                    });
                    onSuccess();
                    return <>Successfully logged meeting to Salesforce.</>;
                  },
                  error: (result) => {
                    setIsSaving(false);
                    appInsights.trackEvent({
                      name: 'LOG_MEETING_ERROR',
                      properties: {
                        organizationSlug: organizationSlug!,
                        planId: meetingPlanId,
                        app: 'SALESFORCE',
                        sObjectType: objectType,
                        statusCode: result.status,
                        statusText: result.statusText,
                      },
                    });
                    return (
                      <>
                        Error logging meeting to Salesforce. This error has been
                        logged and Meetingflow support staff has been notified.
                      </>
                    );
                  },
                },
              );
            }}
          >
            {isSaving ? 'Saving...' : 'Log Meeting'}
          </AsyncPrimaryButton>
        </div>
      </div>
    </BaseSidePanel>
  );
};
