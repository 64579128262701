import {
  FontSizes,
  FontWeights,
  NeutralColors,
  Text,
  mergeStyles,
  Icon,
  IIconProps,
  FontIcon,
  Spinner,
} from '@fluentui/react';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useLightOrDarkMode } from '../Hooks/useLightOrDarkMode';
import { MEETINGFLOW_COLORS } from '../Themes/Themes';

interface CardProps {
  id?: string;
  className?: string;
  title?: string | React.ReactNode;
  description?: string;
  maxHeight?: string | number;
  minHeight?: string | number;
  loading?: boolean;
  children?: React.ReactNode;
  contentContainerPadding?: string;
  contentContainerOverflow?: string;
  color?: string;
  callToAction?: React.ReactNode;
  iconProps?: IIconProps;
  style?: React.CSSProperties;
  noBottomPadding?: boolean;
  defaultCollapsed?: boolean;
  allowCollapsing?: boolean;
  titleColor?: string;
  titleSize?: string;
  smallHeader?: boolean;
  showBorder?: boolean;
  marginBottom?: string;
  backgroundColor?: string;
  onToggleCollapsed?: (isCollapsed: boolean) => void;
  animateCollapse?: boolean;
  showShadow?: boolean;
}

export const Card = ({
  id,
  title,
  description,
  maxHeight,
  minHeight,
  contentContainerPadding,
  contentContainerOverflow,
  loading,
  children,
  callToAction,
  color,
  iconProps,
  style,
  noBottomPadding,
  defaultCollapsed = false,
  allowCollapsing = false,
  titleColor,
  titleSize = FontSizes.mediumPlus,
  smallHeader,
  showBorder = true,
  marginBottom = '1rem',
  className,
  backgroundColor,
  onToggleCollapsed,
  animateCollapse = true,
  showShadow = true,
}: CardProps) => {
  const { isDark } = useLightOrDarkMode();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(defaultCollapsed);
  const cardClass = mergeStyles({
    position: 'relative',
    top: '2px',
    backgroundColor: backgroundColor
      ? backgroundColor
      : isDark
      ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
      : MEETINGFLOW_COLORS.white,
    marginBottom: isCollapsed ? 0 : marginBottom,
    boxShadow: showShadow ? '0px 0px 2px rgba(0,0,0,.1)' : 'none',
    paddingBottom: noBottomPadding || isCollapsed ? '0 !important' : '.25rem',
    border: showBorder
      ? isDark
        ? `1px solid ${NeutralColors.gray180}`
        : `1px solid ${MEETINGFLOW_COLORS.white}`
      : 'none',
    borderRadius: '.25rem',
    maxHeight: isCollapsed ? '28px' : maxHeight,
    minHeight: minHeight || undefined,
    overflow:
      isCollapsed || contentContainerOverflow === 'hidden' ? 'hidden' : 'auto',
    transition: animateCollapse ? '.3s ease-in-out all' : '0s ease-in-out all',

    '.card-header': {
      display: 'block',
      position: 'sticky',
      top: 0,
      zIndex: 2,
      padding: smallHeader ? '.25rem' : '.75rem',
      cursor: showBorder && allowCollapsing ? 'pointer' : undefined,
      color: titleColor ? titleColor : undefined,
      borderTopLeftRadius: '.25rem',
      borderTopRightRadius: '.25rem',
      transition: animateCollapse
        ? '.3s ease-in-out all'
        : '0s ease-in-out all',
      borderBottom:
        isCollapsed || color
          ? 'none'
          : `1px solid ${
              isDark ? NeutralColors.gray170 : NeutralColors.gray20
            } !important`,

      backgroundColor: color
        ? color
        : isDark
        ? NeutralColors.gray200
        : NeutralColors.white,
      span: {
        fontSize: smallHeader ? FontSizes.smallPlus : titleSize,
        fontWeight: FontWeights.semibold,
        position: smallHeader ? 'relative' : undefined,
        top: smallHeader ? '2px' : undefined,
        transition: animateCollapse
          ? '.3s ease-in-out all'
          : '0s ease-in-out all',
        color: titleColor
          ? titleColor
          : color
          ? 'white'
          : isDark
          ? 'white'
          : NeutralColors.gray130,
      },
      '.description': {
        display: 'block',
        fontSize: FontSizes.small,
        fontWeight: FontWeights.semilight,
        color: isDark ? 'white' : NeutralColors.gray130,
        lineHeight: '1rem',
      },
    },
    '.card-content': {
      position: 'relative',
      height: allowCollapsing ? (isCollapsed ? 0 : 'auto') : '100%',
      overflow: contentContainerOverflow ? contentContainerOverflow : 'auto',
      padding: isCollapsed
        ? 0
        : contentContainerPadding
        ? contentContainerPadding
        : 0,

      '.ms-Button-label': {
        color: isDark ? 'white' : undefined,
      },
    },
  });

  const callToActionClass = mergeStyles({
    position: 'sticky',
    top: smallHeader ? '0' : '.25rem',
    paddingRight: smallHeader ? '0' : '.25rem',
    textAlign: 'right',
    zIndex: 150,
    height: 0,

    '.ms-Button-label': {
      color: isDark ? 'white !important' : undefined,
    },
  });

  useEffect(() => {
    onToggleCollapsed?.(isCollapsed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCollapsed]);

  return (
    <div id={id} className={classNames(cardClass, className)} style={style}>
      {callToAction ? (
        <div className={callToActionClass}>{callToAction}</div>
      ) : null}

      {title || description ? (
        <div
          className="card-header"
          onClick={
            showBorder && allowCollapsing
              ? () => {
                  setIsCollapsed(!isCollapsed);
                }
              : undefined
          }
        >
          <div
            style={{
              width: 'fit-content',
              cursor: !showBorder && allowCollapsing ? 'pointer' : undefined,
              userSelect: 'none',
              WebkitUserSelect: 'none',
            }}
            onClick={
              !showBorder && allowCollapsing
                ? () => {
                    setIsCollapsed(!isCollapsed);
                  }
                : undefined
            }
          >
            {allowCollapsing ? (
              <FontIcon
                style={{
                  marginRight: '.5rem',
                  marginLeft: smallHeader ? '.25rem' : undefined,
                  fontSize: smallHeader ? FontSizes.xSmall : undefined,
                }}
                iconName={isCollapsed ? 'ChevronRightMed' : 'ChevronDownMed'}
                className="app-menu-chevron"
              />
            ) : null}
            <Text
              variant="xLarge"
              style={{
                color: titleColor ? titleColor : undefined,
                lineHeight: '1.25rem',
                position: 'relative',
                top: smallHeader ? '-.1rem' : '-.15rem',
              }}
            >
              {iconProps ? (
                <Icon
                  {...iconProps}
                  style={{
                    position: 'relative',
                    top: '.15rem',
                    left: smallHeader ? '.15rem' : undefined,
                    display: 'inline-block',
                    marginRight: '.5rem',
                    fontSize: smallHeader
                      ? FontSizes.smallPlus
                      : FontSizes.xLarge,
                    fontWeight: FontWeights.regular,
                    color: titleColor ? titleColor : undefined,
                  }}
                />
              ) : null}
              {title}
            </Text>
            <Text className="description">{description}</Text>
          </div>
        </div>
      ) : null}

      <div className={'card-content'}>{loading ? <Spinner /> : children}</div>
    </div>
  );
};
