import { DatePickerProps } from '@mui/x-date-pickers';
import { DSDatePickerRoot } from './DSDatePicker.styles';
import { DateTime } from 'luxon';
import { DSTextFieldSize } from './DSTextField';

export type DSDatePickerProps<TDate> = DatePickerProps<DateTime> & {
  label?: string;
  placeholder?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  size?: DSTextFieldSize;
};

const DSDatePicker = ({ size, sx, ...props }: DSDatePickerProps<DateTime>) => {
  const muiSize = size === 'xsmall' ? 'small' : size;
  const customSx =
    size === 'xsmall'
      ? {
          '& .MuiInputBase-input': {
            fontSize: 12,
            height: 8,
            padding: 2,
          },
          ...sx,
        }
      : sx;

  return (
    <DSDatePickerRoot
      {...props}
      slotProps={{
        textField: {
          sx: customSx,
          size: muiSize,
        },
      }}
    />
  );
};

export default DSDatePicker;
