import {
  Text,
  mergeStyles,
  NeutralColors,
  FontWeights,
  Icon,
  FontSizes,
} from '@fluentui/react';
import { MEETINGFLOW_COLORS } from '../../../../Themes/Themes';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import SalesforceIcon from '../../../../Static/Images/salesforce.png';
import { SalesforcePinTileButton } from './SalesforcePinTileButton';

export type SalesforceContactTileProps = {
  organizationSlug: string;
  meetingPlanId?: string;
  externalId: string;
  salesforceInstance: string;
  name: string;
  email?: string | null;
  isPinned?: boolean;
  externalServiceObjectId?: number;
  isLoading?: boolean;
  hideBottomBorder?: boolean;
  showArrow?: boolean;
  showObjectType?: boolean;
  showExternalLink?: boolean;
  onClick?: () => void;
  onPinClick?: (e?: React.MouseEvent<HTMLElement>) => Promise<unknown>;
};
export const SalesforceContactTile = ({
  organizationSlug,
  externalId,
  name,
  email,
  salesforceInstance,
  meetingPlanId,
  isPinned,
  externalServiceObjectId,
  isLoading,
  showExternalLink,
  showArrow = true,
  showObjectType,
  hideBottomBorder,
  onClick,
  onPinClick,
}: SalesforceContactTileProps) => {
  const { isDark } = useLightOrDarkMode();

  const showOverline = !!showObjectType;

  const contactTileClass = mergeStyles({
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gridTemplateAreas: `'pin content logo arrow'`,
    padding: '.5rem 0',
    gridColumnGap: '.25rem',
    gridRowGap: '.25rem',
    backgroundColor: isDark
      ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
      : MEETINGFLOW_COLORS.white,
    transition: 'all .3s ease-in-out',
    cursor: onClick ? 'pointer' : undefined,
    borderBottom: hideBottomBorder
      ? 'none !important'
      : `1px solid ${isDark ? NeutralColors.gray170 : NeutralColors.gray20}`,

    '.pin': {
      gridArea: 'pin',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginRight: '.5rem',
    },

    '.content': {
      gridArea: 'content',
    },

    '.no-email': {
      color: isDark ? NeutralColors.gray120 : NeutralColors.gray70,
    },

    ':last-child': {
      borderBottom: 'none',
    },

    '.logo': {
      gridArea: 'logo',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },

    '.arrow': {
      gridArea: 'arrow',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      // top: showOverline ? '.5rem' : '0',
      color: NeutralColors.gray60,
      transition: '.3s ease-in-out all',
    },

    ':hover': {
      backgroundColor: onClick
        ? isDark
          ? NeutralColors.gray180
          : NeutralColors.gray10
        : undefined,

      '.arrow': {
        color: MEETINGFLOW_COLORS.purpleDark,
        position: 'relative',
      },

      img: { filter: `grayscale(0%) !important` },
    },
  });

  return (
    <div
      className={contactTileClass}
      onClick={onClick}
      title={onClick ? 'Select Contact' : undefined}
    >
      {meetingPlanId ? (
        <SalesforcePinTileButton
          className="pin"
          meetingPlanId={meetingPlanId}
          organizationSlug={organizationSlug}
          objectId={externalId}
          objectName={name}
          objectType={'Contact'}
          tileHasOverline={showOverline}
          isPinned={isPinned}
          onPinToggle={onPinClick}
          externalServiceObjectId={externalServiceObjectId}
        />
      ) : null}
      <div className="content">
        {showOverline ? (
          <Text
            style={{
              lineHeight: '1rem',
              fontWeight: FontWeights.bold,
              color: NeutralColors.gray100,
              textTransform: 'uppercase',
              fontSize: '8px',
            }}
            block
            nowrap
          >
            {showObjectType ? <span>Contact • </span> : null}
          </Text>
        ) : null}

        <Text
          variant="medium"
          style={{
            display: 'block',
            fontWeight: FontWeights.semibold,
            position: 'relative',
            // top: showOverline ? '-.25rem' : '.1rem',
            maxWidth: 'calc(100% - 2rem)',
            paddingRight: '2rem',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color: isDark ? NeutralColors.gray30 : NeutralColors.gray180,
          }}
          block
        >
          {name}
        </Text>
        {email ? (
          <div
            style={{
              fontSize: FontSizes.small,
              display: 'block',
              whiteSpace: 'nowrap',
            }}
            className={email ? '' : 'no-email'}
          >
            {email || 'No Email'}
          </div>
        ) : null}
      </div>

      {showExternalLink ? (
        <Icon
          key="Salesforce"
          className="logo"
          imageProps={{
            height: '16px',
            src: SalesforceIcon,
            alt: 'Salesforce',
            width: '1.3rem',
            title: `View ${name} on Salesforce`,
          }}
          onClick={(e) => {
            e.stopPropagation();
            window.open(`${salesforceInstance}/${externalId}`, '_blank');
          }}
        />
      ) : null}

      {showArrow ? (
        <div className="arrow">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5858 6.34317L12 4.92896L19.0711 12L12 19.0711L10.5858 17.6569L16.2427 12L10.5858 6.34317Z"
              fill="currentColor"
            />
          </svg>
        </div>
      ) : null}
    </div>
  );
};
