import { FC, PropsWithChildren, useState } from 'react';
import { DecisionSiteMeetingflowFilters } from './DecisionSiteMeetingflowFilters';
import DecisionSiteMeetingflowList from './DecisionSiteMeetingflowList';
import { useInfiniteQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { MeetingflowsApiClient } from '../../../../Services/NetworkCommon';
import { OrganizationMeetingPlansQuery } from '../../../../QueryNames';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { useDealRoom } from '../../../../Hooks/useDealRoom';
import { useSearchParams } from 'react-router-dom';
import { collectToRecord } from '../../../../Helpers/SearchParamHelpers';
import { StyledListWithFilters } from './DecisionSiteMeetingflowListWithFilters.styles';

interface DecisionSiteMeetingflowListWithFiltersProps {
  onFiltersChange?: (filters: string) => void;
}

const DecisionSiteMeetingflowListWithFilters: FC<
  PropsWithChildren<DecisionSiteMeetingflowListWithFiltersProps>
> = ({ onFiltersChange }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { slug: organizationSlug } = useOrganization();
  const { dealRoomId } = useDealRoom();
  const [searchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(0);

  const { data: meetingPlansPages } = useInfiniteQuery(
    [
      ...OrganizationMeetingPlansQuery(
        organizationSlug || '',
        '',
        50,
        0,
        dealRoomId,
      ),
      searchParams.toString(),
    ],
    async () => {
      const token = await getAccessTokenSilently();
      return MeetingflowsApiClient.listPlans(
        {
          organizationSlug: organizationSlug!,
          limit: 50,
          skip: 0,
          dealRoomId,
          ...collectToRecord(searchParams),
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!organizationSlug && !!dealRoomId,
    },
  );

  return (
    <StyledListWithFilters>
      <div className="list-filters">
        <DecisionSiteMeetingflowFilters onFiltersChange={onFiltersChange} />
      </div>
      <div className="list-container">
        <DecisionSiteMeetingflowList
          meetingPlansPages={meetingPlansPages}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      </div>
    </StyledListWithFilters>
  );
};

export default DecisionSiteMeetingflowListWithFilters;
