import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const DSMenuBarWithOverflowRoot = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
`;

export const MenuItemsContainer = styled(Box)`
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-grow: 1;
  gap: 8px;
  min-width: 0; // This helps with proper flex behavior
`;

export const OverflowContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-left: 8px;
  flex-shrink: 0; // Prevent overflow button from shrinking
`;
