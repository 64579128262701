import React, { useMemo } from 'react';
import {
  getTheme,
  IconButton,
  mergeStyleSets,
  TextField,
} from '@fluentui/react';
import { ArtifactTitleProps } from './types';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { useNavigate } from 'react-router';

/**
 * ArtifactTitle Component
 *
 * Displays and handles editing of the artifact title.
 * Supports both view and edit modes with proper keyboard interactions.
 */
export const ArtifactTitle: React.FC<ArtifactTitleProps> = ({
  title,
  isEditing,
  onStartEdit,
  onBlur,
  onChange,
  artifact,
  organizationSlug,
  dealRoomId,
}) => {
  const navigate = useNavigate();

  const titleEditFieldStyles = useMemo(
    () => ({
      root: {
        height: '48px',
      },
      wrapper: {
        height: '48px',
      },
      fieldGroup: {
        height: '48px',
      },
      field: {
        fontSize: '40px',
        lineHeight: '48px',
        fontWeight: '400',
        color: DEALROOMS_COLORS.themePrimary,
        padding: '.5rem',
      },
    }),
    [],
  );

  const useStyles = () => {
    const theme = getTheme();
    return mergeStyleSets({
      header: [
        theme.fonts.xLarge,
        {
          display: 'flex',
          alignItems: 'center',
          gap: '1.5rem',
          paddingBottom: '1.5rem',
          '.back': {
            color: DEALROOMS_COLORS.themeSecondary,
            width: '1.5rem',
            height: '1.5rem',
            i: {
              fontSize: '1rem',
            },
          },

          '.title-field': {
            fontSize: '40px',
            lineHeight: '48px',
            fontWeight: '400',
            color: DEALROOMS_COLORS.themePrimary,
            overflowWrap: 'anywhere',

            ':hover': {
              cursor: 'pointer',
            },
          },
        },
      ],
    });
  };

  const styles = useStyles();

  // Format title with file extension
  const titleEl = useMemo(() => {
    if (!artifact) {
      return <></>;
    }
    const name = artifact.label || artifact.name;
    const parts = name.split('.');
    if (parts.length > 1) {
      const extension = parts.pop();
      return (
        <>
          {parts.join('.')}
          <span style={{ color: DEALROOMS_COLORS.themeSecondary }}>
            .{extension}
          </span>
        </>
      );
    }
    return <span>{name}</span>;
  }, [artifact]);

  return (
    <div className={styles.header}>
      <IconButton
        className="back"
        iconProps={{ iconName: 'Back' }}
        onClick={() => {
          navigate(
            `/organization/${organizationSlug}/dealroom/${dealRoomId}/artifacts`,
          );
        }}
      />
      <div>
        {isEditing ? (
          <TextField
            value={title}
            onChange={(e, newValue) => onChange(newValue || '')}
            onBlur={onBlur}
            onKeyDown={async (e) => {
              if (e.key === 'Enter') {
                await onBlur();
              }
            }}
            autoFocus
            styles={titleEditFieldStyles}
          />
        ) : (
          <div className="title-field" onClick={onStartEdit}>
            {titleEl}
          </div>
        )}
      </div>
      {/*<UpdateArtifact
        organizationSlug={organizationSlug}
        dealRoomId={dealRoomId}
        artifact={artifact}
      /> */}
    </div>
  );
};
