import { DealRole } from '@meetingflow/common/Api/data-contracts';

export const isBuyer = (userRole: DealRole | null | undefined) => {
  const buyerRoles = [
    'BUYER',
    'BUYER_ADMIN',
    'BUYER_CONTRIBUTOR',
    'BUYER_REVIEWER',
  ];
  return buyerRoles.includes(userRole || '');
};
