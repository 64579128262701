import { useAuth0 } from '@auth0/auth0-react';
import {
  Checkbox,
  ComboBox,
  FontSizes,
  FontWeights,
  IComboBoxOption,
  NeutralColors,
  Text,
} from '@fluentui/react';
import {
  DetailedMeetingflow,
  Task,
} from '@meetingflow/common/Api/data-contracts';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { orderBy } from 'lodash';
import { useMemo, useState } from 'react';
import 'react-customize-token-input/dist/react-customize-token-input.css';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import * as Y from 'yjs';
import { hasContent } from '../../../Helpers/yjsHelpers';
import { useExternalServiceConfigurations } from '../../../Hooks/useExternalServiceConfigurations';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { useLocalStorageState } from '../../../Hooks/useLocalStorageState';
import { useUserProfile } from '../../../Hooks/useProfile';
import { SlackChannelsQuery } from '../../../QueryNames';
import { ApiClient } from '../../../Services/NetworkCommon';
import MeetingPlanNotesSummaryForm from '../../Common/NotesSummaryForm';
import { AsyncPrimaryButton } from '../../HOC/AsyncButton';
import { BaseSidePanel } from './BaseSidePanel';

export type SlackChannel = {
  name: string;
  topic: string;
  purpose: string;
  isPrivate: boolean;
};

export interface SlackSidePanelProps {
  organizationSlug: string;
  meetingPlanId: string;
  internalDomains: string[];
  tasks: Task[];
  meetingPlan: Pick<
    DetailedMeetingflow,
    | 'id'
    | 'title'
    | 'startTime'
    | 'organizer'
    | 'creator'
    | 'attendees'
    | 'companies'
    | 'textSummary'
    | 'callRecording'
  >;
  notesYArray: Y.XmlText;
  initialSummary?: string;
  onBack?: () => void;
  onClose: () => void;
  onSendSummary: () => Promise<unknown>;
}

export const SlackSidePanel: React.FunctionComponent<SlackSidePanelProps> = ({
  organizationSlug,
  meetingPlanId,
  internalDomains,
  notesYArray,
  meetingPlan,
  tasks,
  initialSummary,
  onBack,
  onClose,
  onSendSummary,
}) => {
  const { isDark } = useLightOrDarkMode();
  const { getAccessTokenSilently } = useAuth0();
  const { user } = useUserProfile();

  const appInsights = useAppInsightsContext();

  const { hasToken } = useExternalServiceConfigurations({
    app: 'SLACK',
    withToken: true,
  });

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [channel, setChannel] = useLocalStorageState<string>(
    `SLACK_SUMMARY_CHANNEL_${organizationSlug}`,
    '',
  );
  const [summary, setSummary] = useState<string | undefined>(
    initialSummary || meetingPlan?.textSummary || '',
  );
  const [gptSummary, setGPTSummary] = useState<string | undefined>('');

  const [includeCallInsights, setIncludeCallInsights] =
    useLocalStorageState<boolean>('slack-include-call-insights', true);

  const {
    data: channels,
    // isLoading: channelsLoading,
    // refetch: refetchChannels,
  } = useQuery(
    SlackChannelsQuery(organizationSlug),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<SlackChannel[]>(
        `/organization/${organizationSlug}/external/slack/channels`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    { cacheTime: 0, enabled: hasToken() },
  );

  const channelOptions: IComboBoxOption[] = useMemo(
    () =>
      channels?.data
        ? !!searchTerm
          ? orderBy(
              channels.data.filter((c) => c.name.startsWith(searchTerm)),
              'name',
            ).map((c) => {
              return {
                key: c.name,
                text: c.name,
              };
            })
          : orderBy(channels.data, 'name').map((c) => {
              return {
                key: c.name,
                text: c.name,
              };
            })
        : channel
          ? [{ key: channel, text: channel }]
          : [],
    [channel, channels?.data, searchTerm],
  );

  const selectedChannel = useMemo(
    () => channels?.data?.find((c) => c.name === channel),
    [channel, channels?.data],
  );

  const planURL = `${window.origin}/organization/${organizationSlug}/plan/${meetingPlan.id}`;

  return (
    <BaseSidePanel title="Slack" onBack={onBack} onClose={onClose}>
      <Text
        block
        style={{
          fontSize: FontSizes.small,
          color: NeutralColors.gray120,
        }}
      >
        Post your Meetingflow link and summary to a Slack channel to let others
        know about it.{' '}
      </Text>

      <Text
        block
        variant="mediumPlus"
        style={{ fontWeight: FontWeights.semibold, margin: '1rem 0 .5rem 0' }}
      >
        Slack channel:
      </Text>
      <div>
        <ComboBox
          errorMessage={
            channel && channels?.data !== undefined && !selectedChannel
              ? 'Please select a valid channel'
              : undefined
          }
          allowFreeform
          placeholder={'Select a channel...'}
          selectedKey={channel || null}
          prefix="#"
          options={channelOptions}
          autoComplete={'on'}
          style={{ marginBottom: '1rem' }}
          onInputValueChange={setSearchTerm}
          openOnKeyboardFocus
          calloutProps={{ alignTargetEdge: true }}
          onChange={(_e, o) => {
            if (o) {
              setChannel(o.key.toString());
            } else if (searchTerm) {
              const matchingOption = channelOptions?.find(
                (c) => c.key.toString() === searchTerm,
              );
              if (matchingOption) {
                setChannel(matchingOption.key.toString());
              }
            }
          }}
        />
      </div>
      <MeetingPlanNotesSummaryForm
        organizationSlug={organizationSlug}
        meetingplan={meetingPlan}
        summaryType={'SLACK_UPDATE'}
        displaySubject={false}
        bodyLabel="Message"
        summarizeDisabled={
          !hasContent(notesYArray) &&
          !meetingPlan?.callRecording?.transcript?.length
        }
        onSummaryChange={setSummary}
        onSummaryGenerated={setGPTSummary}
        surfaceName="SLACK_SUMMARY"
        initialSummaryValue={initialSummary || meetingPlan.textSummary || ''}
        summarizeLabel={'Generate a summary'}
      />

      {meetingPlan?.callRecording?.transcriptAnalysis?.topics?.length ? (
        <div style={{ gridArea: 'include-call-insights' }}>
          <Checkbox
            title="Include Call Insights"
            label="Include Call Insights"
            checked={includeCallInsights}
            onChange={(_e, checked) => setIncludeCallInsights(!!checked)}
          />
        </div>
      ) : null}

      <div style={{ textAlign: 'right' }}>
        <AsyncPrimaryButton
          text="Share via Slack"
          disabled={!selectedChannel || !summary}
          onClick={async () => {
            if (!selectedChannel || !summary) {
              return;
            }

            try {
              const token = await getAccessTokenSilently();

              await toast.promise(
                ApiClient.post(
                  `/organization/${organizationSlug}/external/slack/send-summary`,
                  {
                    message: summary,
                    meetingPlanId,
                    channel: selectedChannel.name,
                    gptSummary,
                    includeCallInsights,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                    validateStatus: (status) => [200, 201].includes(status),
                  },
                ),
                {
                  loading: 'Sharing to Slack',
                  success: () => {
                    appInsights.trackEvent({
                      name: 'SEND_SLACK_SUMMARY',
                      properties: {
                        organizationSlug,
                        meetingPlanId,
                      },
                    });

                    return `Shared the summary with ${selectedChannel.name} via Slack!`;
                  },
                  error: (err) => {
                    return 'Something went wrong sharing the summary to Slack, please try again';
                  },
                },
              );

              onSendSummary();
            } catch {}
          }}
          styles={{
            label: { color: isDark ? 'white' : undefined },
          }}
        />
      </div>
    </BaseSidePanel>
  );
};
