import { useAuth0 } from '@auth0/auth0-react';
import { DetailedMeetingflow } from '@meetingflow/common/Api/data-contracts';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useMemo } from 'react';
import toast from 'react-hot-toast';
import { ApiClient } from '../../Services/NetworkCommon';
import { ContactPicker } from '../Common/ContactPicker';

interface DecisionSiteMeetingPlanAddAttendeesButtonProps {
  organizationSlug: string;
  meetingPlan: Pick<DetailedMeetingflow, 'id' | 'attendees'>;
  allowEditAttendees?: boolean;
  refetchMeetingPlan?: () => Promise<unknown>;
  buttonLabel?: string;
}
const DecisionSiteMeetingPlanAddAttendeesButton = ({
  organizationSlug,
  meetingPlan,
  refetchMeetingPlan,
  buttonLabel = 'List Attendee',
}: DecisionSiteMeetingPlanAddAttendeesButtonProps) => {
  const appInsights = useAppInsightsContext();
  const { getAccessTokenSilently } = useAuth0();

  const existingAttendees = useMemo(
    () => meetingPlan.attendees.map((attendee) => attendee.email),
    [meetingPlan.attendees],
  );

  const onSubmit = async (emailAddress: string) => {
    const token = await getAccessTokenSilently();
    await toast.promise(
      ApiClient.put(
        `/organization/${organizationSlug}/plan/${meetingPlan.id}/attendee/${emailAddress}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
      {
        loading: `Adding ${emailAddress} as an attendee on this Meetingflow...`,
        success: (response) => {
          refetchMeetingPlan?.();
          return `Successfully added ${emailAddress} as an attendee on this Meetingflow.`;
        },
        error: (err) => {
          return `Something went wrong adding ${emailAddress} as an attendee on this Meetingflow.`;
        },
      },
    );

    appInsights.trackEvent({
      name: 'ADDED_ATTENDEE_TO_PLAN',
      properties: {
        organizationSlug,
        emailAddress,
        meetingPlanId: meetingPlan.id,
      },
    });

    // Reset the picker to default
    return true;
  };

  return (
    <ContactPicker
      id="meetingflow-attendees-add-attendee-picker"
      organizationSlug={organizationSlug}
      addLabel={buttonLabel}
      focusOnEdit
      allowFreeform
      onPick={onSubmit}
      excludeContactEmails={existingAttendees}
    />
  );
};

export default DecisionSiteMeetingPlanAddAttendeesButton;
