import { IconButton, Stack, Text } from '@fluentui/react';
import { ChromeCloseIconProps } from '../../../../utils/iconProps';
import { getStyles } from '../UploadDealRoomAttachmentsDialog.styles';

interface DialogHeaderProps {
  title: string;
  onDismiss: () => void;
}

export const DialogHeader: React.FC<DialogHeaderProps> = ({
  title,
  onDismiss,
}) => {
  const styles = getStyles();

  return (
    <Stack.Item className={styles.headerContainer}>
      <Text className={styles.title}>{title}</Text>
      <IconButton
        className={styles.closeButton}
        ariaLabel="Close"
        iconProps={ChromeCloseIconProps}
        onClick={onDismiss}
        alt="Close image"
      />
    </Stack.Item>
  );
};
