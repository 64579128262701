import {
  ActionType,
  BuyerSelectedOptions,
  SurveyAction,
} from '../types/BuyerOrientationSurveyTypes';
import React, { useReducer } from 'react';
import { optionValuesThatNeedPhoneNumber } from '../Components/DealRoom/Components/BuyerOrientationSurvey/buyerOrientationSurveyUtils';

const buyerInitialSelectedOptions: BuyerSelectedOptions = {
  name: null,
  title: null,
  department: null,
  assistancePreference: null,
  consumptionPreference: null,
  communicationPreference: null,
  phoneNumber: null,
  priorities: [],
  otherPriorityText: null,
  invitations: [],
};

const surveyReducer = (
  state: BuyerSelectedOptions,
  action: SurveyAction,
): BuyerSelectedOptions => {
  switch (action.type) {
    case ActionType.UPDATE_NAME:
      return {
        ...state,
        ...(typeof action.payload === 'string'
          ? {
              name: action.payload || null,
            }
          : {}),
      };
    case ActionType.UPDATE_TITLE:
      return {
        ...state,
        ...(typeof action.payload === 'string'
          ? {
              title: action.payload || null,
            }
          : {}),
      };

    case ActionType.UPDATE_DEPARTMENT:
      return {
        ...state,
        ...(typeof action.payload === 'string'
          ? {
              department: action.payload || null,
            }
          : {}),
      };
    case ActionType.UPDATE_ASSISTANCE_PREFERENCE:
      return {
        ...state,
        ...(typeof action.payload === 'string'
          ? {
              assistancePreference:
                state.assistancePreference === action.payload || !action.payload
                  ? null
                  : action.payload,
            }
          : {}),
      };
    case ActionType.UPDATE_CONSUMPTION_PREFERENCE:
      return {
        ...state,
        ...(typeof action.payload === 'string'
          ? {
              consumptionPreference:
                state.consumptionPreference === action.payload ||
                !action.payload
                  ? null
                  : action.payload,
            }
          : {}),
      };
    case ActionType.UPDATE_COMMUNICATION_PREFERENCE:
      return {
        ...state,
        ...(typeof action.payload === 'string'
          ? {
              communicationPreference:
                state.communicationPreference === action.payload ||
                !action.payload
                  ? null
                  : action.payload,
              ...(!optionValuesThatNeedPhoneNumber.includes(action.payload)
                ? {
                    phoneNumber: null,
                  }
                : {}),
            }
          : {}),
      };
    case ActionType.UPDATE_PHONE_NUMBER:
      return {
        ...state,
        ...(typeof action.payload === 'string'
          ? {
              phoneNumber: action.payload || null,
            }
          : {}),
      };
    case ActionType.UPDATE_PRIORITIES:
      if (typeof action.payload !== 'string') {
        return { ...state };
      }
      // eslint-disable-next-line no-case-declarations
      const newPrioritiesList = state.priorities.includes(action.payload)
        ? state.priorities.filter((item) => item !== action.payload)
        : [...state.priorities, action.payload];
      return {
        ...state,
        priorities: newPrioritiesList,
        ...(!newPrioritiesList.includes('OTHER')
          ? {
              otherPriorityText: null,
            }
          : {}),
      };
    case ActionType.UPDATE_OTHER_PRIORITY:
      return {
        ...state,
        ...(typeof action.payload === 'string'
          ? {
              otherPriorityText: action.payload || null,
            }
          : {}),
      };
    case ActionType.UPDATE_INVITATIONS:
      // will be modified
      return {
        ...state,
      };
    case ActionType.UPDATE_MULTIPLE_SELECTIONS:
      return {
        ...state,
        ...(typeof action.payload === 'string'
          ? {}
          : {
              ...action.payload,
            }),
      };
    default:
      return { ...state };
  }
};

export const useBuyerSurveyReducer = (): [
  BuyerSelectedOptions,
  React.Dispatch<SurveyAction>,
] => {
  const [orientationForm, updateOrientationForm] = useReducer(
    surveyReducer,
    buyerInitialSelectedOptions,
  );

  return [orientationForm, updateOrientationForm];
};
