import {
  getRemoteCaretsOnLeaf,
  getRemoteCursorsOnLeaf,
  TextWithRemoteCursors,
} from '@slate-yjs/react';
import { RenderLeafProps } from 'slate-react';
import { RemoteCursorData } from '@meetingflow/common/Types/Slate';
import { RenderLeaf } from './RenderLeaf';
import { v4 } from 'uuid';
import { mergeStyles } from '@fluentui/react';

export const RenderDecoratedLeaf = ({
  children,
  leaf,
  ...rest
}: RenderLeafProps) => {
  getRemoteCursorsOnLeaf<
    RemoteCursorData,
    TextWithRemoteCursors<RemoteCursorData>
  >(leaf as TextWithRemoteCursors<RemoteCursorData>).forEach((cursor) => {
    if (cursor.data) {
      children = (
        <span
          style={{
            backgroundColor: cursor.data.alphaColor,
            cursor: 'default',
            userSelect: 'none',
            WebkitTouchCallout: 'none',
            WebkitUserSelect: 'none',
            MozUserSelect: 'none',
            msUserSelect: 'none',
          }}
        >
          {children}
        </span>
      );
    }
  });

  const caretClass = mergeStyles({
    position: 'relative',

    '&:hover': {
      '.cursor': {
        animation: `blinker 0s linear infinite !important`,
      },

      '.user-name': {
        display: 'inline-block',
        opacity: '1',
      },
    },

    '.cursor': {
      width: '0.5rem',
      position: 'absolute',
      height: '1.25rem',
      left: '-.25rem',
      display: 'inline-block',
      animation: `blinker 1s linear infinite`,
      backgroundColor: 'transparent',
      border: '1px solid transparent',

      '.cursor-line': {
        position: 'absolute',
        width: '1px',
        height: '100%',
        left: 'calc(50% - 1px)',
        top: '0',
        borderRight: `2px solid transparent`,
        boxSizing: 'border-box',
      },
    },

    '.user-name': {
      cursor: 'default',
      fontSize: '0.75rem',
      lineHeight: '1rem',
      color: 'white',
      whiteSpace: 'nowrap',
      borderRadius: '0.25rem',
      borderBottomLeftRadius: '0px',
      paddingLeft: '0.375rem',
      paddingRight: '0.375rem',
      userSelect: 'none',
      WebkitTouchCallout: 'none',
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      msUserSelect: 'none',
      zIndex: 10000,
      position: 'absolute',
      top: 'calc(-1rem + 1px)',
      left: '0',
      opacity: '0',
      transition: 'opacity 0.3s ease-in-out',
      display: 'none',
    },
  });

  getRemoteCaretsOnLeaf<
    RemoteCursorData,
    TextWithRemoteCursors<RemoteCursorData>
  >(leaf as TextWithRemoteCursors<RemoteCursorData>).forEach((caret) => {
    if (caret.data) {
      const id = `cursor-${v4()}`;
      children = (
        <span className={caretClass}>
          <span id={id} contentEditable={false} className="cursor">
            <span
              className="cursor-line"
              style={{
                borderColor: caret.data.color,
              }}
            ></span>
          </span>
          <span
            style={{
              backgroundColor: caret.data.color,
            }}
            className="user-name"
          >
            <span contentEditable={false}>{caret.data.name}</span>
          </span>
          {children}
        </span>
      );
    }
  });

  return (
    <RenderLeaf leaf={leaf} {...rest}>
      {children}
    </RenderLeaf>
  );
};
