import { styled } from '@mui/material/';
import { AsyncDiv } from '../../../Components/HOC/AsyncIntrinsicElement';

export const DecisionSiteEventCardRoot = styled(AsyncDiv)(({ theme }) => ({
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '.5rem',


    '&:hover': {
        backgroundColor: theme.palette.background.default,
    },
    // '&:active': {
    //     backgroundColor: theme.palette.action.selected,
    // },
    // '&:focus': {
    //     backgroundColor: theme.palette.action.selected,
    // },    
    // '&:disabled': {
    //     backgroundColor: theme.palette.action.disabled,
    //     color: theme.palette.text.disabled,
    // },

    p: {
        margin: 0,
    },

    '.event-title': {
        fontSize: theme.typography.body2.fontSize,
        fontWeight: 'regular',
        color: theme.palette.text.primary,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: theme.spacing(1),
        margin: 0,

        '.icons': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: theme.spacing(1),
        }
    },
    '.event-datetime': {
        fontSize: theme.typography.caption.fontSize,
        fontWeight: 'regular',
        color: theme.palette.text.secondary,
        margin: 0,
    },

    '.button': {
        flex: '0 0 auto',
        marginLeft: 'auto',
    }
}));