import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { DSTextFieldRoot } from './DSTextField.styles';

export type DSTextFieldSize = TextFieldProps['size'] | 'xsmall';

interface DSTextFieldProps extends Omit<TextFieldProps, 'size'> {
  size?: DSTextFieldSize;
}

export const DSTextField = React.forwardRef<HTMLDivElement, DSTextFieldProps>(
  ({ size, sx, ...props }, ref) => {
    const muiSize = size === 'xsmall' ? 'small' : size;
    const customSx =
      size === 'xsmall'
        ? {
            '& .MuiInputBase-input': {
              fontSize: 12,
              height: 8,
              padding: 2,
            },
            ...sx,
          }
        : sx;

    return (
      <DSTextFieldRoot ref={ref} size={muiSize} sx={customSx} {...props} />
    );
  },
);

DSTextField.displayName = 'DSTextField';

export default DSTextField;
