export const EyeOffIllustration = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8536 10.1464C10.6583 9.95118 10.3417 9.95118 10.1464 10.1464C9.95118 10.3417 9.95118 10.6583 10.1464 10.8536L13.6453 14.3524C11.9704 15.4918 10.7233 17.2738 10.2801 19.3979C10.2237 19.6682 10.3971 19.9331 10.6674 19.9895C10.9378 20.0459 11.2026 19.8725 11.259 19.6021C11.6628 17.667 12.8236 16.0629 14.3671 15.0742L15.9489 16.656C15.0651 17.2913 14.4895 18.3284 14.4895 19.5C14.4895 21.433 16.0565 23 17.9895 23C19.161 23 20.1981 22.4244 20.8334 21.5405L25.1464 25.8536C25.3417 26.0488 25.6583 26.0488 25.8536 25.8536C26.0488 25.6583 26.0488 25.3417 25.8536 25.1464L10.8536 10.1464ZM18.1238 16.0025L21.4869 19.3657C21.418 17.5395 19.95 16.0714 18.1238 16.0025ZM15.531 13.4098L16.3341 14.2129C16.8714 14.0735 17.4301 14 18 14C21.2056 14 24.0574 16.3269 24.7409 19.6021C24.7973 19.8725 25.0622 20.0459 25.3325 19.9895C25.6028 19.933 25.7762 19.6682 25.7198 19.3979C24.9425 15.6731 21.6934 13 18 13C17.1448 13 16.3134 13.1433 15.531 13.4098Z"
      fill="#6F7288"
    />
  </svg>
);
