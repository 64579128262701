import { mergeStyles, Stack } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../../Themes/Themes';
import { buyerOrientationSurveyOptions } from '../buyerOrientationSurveyUtils';
import { SurveyOptionCard } from '../SurveyOptionCard';
import {
  ActionType,
  BuyerOrientationStepProps,
} from '../../../../../types/BuyerOrientationSurveyTypes';
import { useCallback } from 'react';

export const BuyerOrientationAssistance = ({
  orientationForm,
  updateOrientationForm,
}: BuyerOrientationStepProps) => {
  const stepContainerStyles = mergeStyles({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',

    '.buyer-orientation-step-title': {
      color: DEALROOMS_COLORS.themePrimary,
      fontWeight: '400',
      fontSize: '1.5rem',
      lineHeight: '2.5rem',
      textAlign: 'center',
    },

    '.buyer-orientation-step-cards': {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'space-between',
      width: '100%',
      flexWrap: 'wrap',

      '@media(max-width: 720px)': {
        flexDirection: 'column',
        gap: '1.5rem',
      },
    },
  });

  const handleSelectOption = useCallback(
    (newValue: string) => {
      updateOrientationForm({
        type: ActionType.UPDATE_ASSISTANCE_PREFERENCE,
        payload: newValue,
      });
    },
    [updateOrientationForm],
  );

  return (
    <div className={stepContainerStyles}>
      <Stack horizontal className="buyer-orientation-step-cards">
        {buyerOrientationSurveyOptions.assistancePreference.options.map(
          (option) => (
            <SurveyOptionCard
              key={option.optionId}
              option={option}
              isSelected={
                option.value === orientationForm.assistancePreference
              }
              handleSelectOption={handleSelectOption}
              isDisabled={
                !!orientationForm.assistancePreference &&
                orientationForm.assistancePreference !== option.value
              }
            />
          ),
        )}
      </Stack>
    </div>
  );
};
