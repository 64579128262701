// Import required dependencies
import { useAuth0 } from '@auth0/auth0-react';
import { Pivot, PivotItem, Spinner } from '@fluentui/react';
import saveAs from 'file-saver';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import { useShareDealRoomArtifactDialog } from '../../Hooks/Modals/DealRoom/useShareDealRoomArtifactDialog';
import { useDealRoom } from '../../Hooks/useDealRoom';
import { useDealRoomArtifactId } from '../../Hooks/useDealRoomArtifactId';
import { useOrganization } from '../../Hooks/useOrganization';
import { OrganizationDealRoomArtifactQuery } from '../../QueryNames';
import { DealRoomsApiClient } from '../../Services/NetworkCommon';
import '../../Styles/DealRoom/core.scss';
import { SplitViewLayout } from '../Layouts/SplitViewLayout';
import { ArtifactsSidebar } from './Artifacts/ArtifactsSidebar/ArtifactsSidebar';
import { DealRoomArtifactCommentsPanel } from './Artifacts/DealRoomArtifactCommentsPanel';
import { DealRoomArtifactAssistantPanel } from './Assistant/DealRoomArtifactAssistantPanel';
import { useTitle } from '../../Hooks/useTitle';
import {
  ArtifactActions,
  ArtifactMetadata,
  ArtifactPreview,
  ArtifactTitle,
} from './Components/ArtifactDetailsViewComponents';
import { getStyles } from './Components/ArtifactDetailsViewComponents/styles';
import useDeviceType from '../../Hooks/deviceDetection';
import { useUserProfile } from '../../Hooks/useProfile';
import { DEALROOMS_COLORS } from '../../Themes/Themes';
import { DealRoomCommandBarButton } from './Components/DealRoomButton';

/**
 * DealRoomArtifactDetailView Component
 *
 * A comprehensive view for displaying and interacting with deal room artifacts.
 * Features include:
 * - Artifact preview (documents, images, videos, etc.)
 * - Metadata display (creation date, updates, etc.)
 * - Interactive actions (download, share, voting)
 * - AI assistant and comments integration
 */
export const DealRoomArtifactDetailView = () => {
  const { isMobile } = useDeviceType();

  // Hooks and state
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { slug: organizationSlug } = useOrganization();
  const { user: mfUser } = useUserProfile();
  const [sidePanelTab, setSidePanelTab] = useState<'ask-ai' | 'comment'>(
    'ask-ai',
  );
  const [currentThreadId, setCurrentThreadId] = useState<number | undefined>(
    undefined,
  );
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const dealRoomPanelRef = useRef<{ backToConvList: Function }>(null);
  const styles = getStyles();

  useTitle(`Artifact`);

  // URL parameters and routing
  const artifactId = useDealRoomArtifactId();
  const {
    dealRoomId,
    dealRoomRole,
    refetch: refreshDealRoom,
    dealRoom,
  } = useDealRoom();

  // Fetch artifact data
  const {
    data: artifactResponse,
    isLoading: artifactLoading,
    refetch: refetchArtifact,
  } = useQuery(
    OrganizationDealRoomArtifactQuery(
      organizationSlug!,
      dealRoomId!,
      artifactId!,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return DealRoomsApiClient.getArtifact(
        organizationSlug!,
        dealRoomId!,
        artifactId!,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!organizationSlug && !!dealRoomId && !!artifactId,
    },
  );

  const artifact = useMemo(() => artifactResponse?.data, [artifactResponse]);

  const [title, setTitle] = useState<string | undefined>(
    artifact?.label || artifact?.name,
  );

  // Set initial title when artifact is loaded
  useEffect(() => {
    setTitle(artifact?.label || artifact?.name);
  }, [artifact]);

  // Share dialog setup
  const {
    createDeferred: createConfirmShareDeferred,
    dialog: confirmShareDialog,
  } = useShareDealRoomArtifactDialog({
    organizationSlug: organizationSlug!,
    artifactName: artifact?.name,
  });

  // Record artifact view
  useEffect(() => {
    getAccessTokenSilently()
      .then((token) => {
        DealRoomsApiClient.viewArtifact(
          organizationSlug!,
          dealRoomId!,
          artifactId!,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      })
      .then(() => refetchArtifact());
  }, [
    artifactId,
    dealRoomId,
    getAccessTokenSilently,
    organizationSlug,
    refetchArtifact,
  ]);

  // Handle feature action
  const handleOnFeature = useCallback(
    async (feature: boolean) => {
      try {
        if (
          !organizationSlug ||
          !dealRoomId ||
          !artifactId ||
          !getAccessTokenSilently
        ) {
          return;
        }

        const token = await getAccessTokenSilently();
        if (feature) {
          await DealRoomsApiClient.featureArtifact(
            {
              organizationSlug,
              dealRoomId,
              artifactId,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
        } else {
          await DealRoomsApiClient.unfeatureArtifact(
            organizationSlug,
            dealRoomId,
            artifactId,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
        }
        await refetchArtifact();
      } catch (err: unknown) {}
    },
    [
      artifactId,
      dealRoomId,
      getAccessTokenSilently,
      organizationSlug,
      refetchArtifact,
    ],
  );

  // Handle download action
  const handleOnDownloadArtifact = useCallback(async () => {
    try {
      if (artifact && 'fileUrl' in artifact) {
        saveAs(artifact.fileUrl, artifact.name);
      }
    } catch (err: unknown) {}
  }, [artifact]);

  // Handle share action
  // const handleOnShare = useCallback(async () => {
  //   try {
  //     if (
  //       !createConfirmShareDeferred ||
  //       typeof organizationSlug !== 'string' ||
  //       !organizationSlug ||
  //       typeof dealRoomId !== 'number' ||
  //       typeof artifactId !== 'number' ||
  //       !getAccessTokenSilently
  //     ) {
  //       return;
  //     }
  //
  //     const confirmShare = await createConfirmShareDeferred().promise;
  //
  //     if (!confirmShare) {
  //       return;
  //     }
  //
  //     const token = await getAccessTokenSilently();
  //     await toast.promise(
  //       DealRoomsApiClient.shareArtifact(
  //         organizationSlug,
  //         dealRoomId,
  //         artifactId,
  //         {
  //           subject: confirmShare.subject,
  //           message: confirmShare.message,
  //           contacts: confirmShare.contacts,
  //         },
  //         {
  //           headers: { Authorization: `Bearer ${token}` },
  //         },
  //       ),
  //       {
  //         loading: 'Sharing Artifact',
  //         success: (_response) => {
  //           return 'Successfully shared Artifact';
  //         },
  //         error: (err: unknown) => {
  //           return 'Something went wrong sharing Artifact, please try again later';
  //         },
  //       },
  //     );
  //   } catch (err: unknown) {}
  // }, [
  //   organizationSlug,
  //   dealRoomId,
  //   artifactId,
  //   createConfirmShareDeferred,
  //   getAccessTokenSilently,
  // ]);

  // Handle upvote/downvote action
  const handleOnVote = useCallback(
    async (upVote?: boolean) => {
      try {
        if (
          !artifact ||
          typeof organizationSlug !== 'string' ||
          typeof dealRoomId !== 'number' ||
          typeof artifactId !== 'number' ||
          !getAccessTokenSilently ||
          !refetchArtifact
        ) {
          return;
        }

        const token = await getAccessTokenSilently();
        await (
          upVote
            ? artifact.upvoted
              ? DealRoomsApiClient.removeArtifactUpvote
              : DealRoomsApiClient.upvoteArtifact
            : artifact.downvoted
              ? DealRoomsApiClient.removeArtifactDownvote
              : DealRoomsApiClient.downvoteArtifact
        )(organizationSlug, dealRoomId, artifactId, {
          headers: { Authorization: `Bearer ${token}` },
        });
        await refetchArtifact();
      } catch (err: unknown) {}
    },
    [
      artifact,
      organizationSlug,
      dealRoomId,
      artifactId,
      getAccessTokenSilently,
      refetchArtifact,
    ],
  );

  // Handle title change
  const handleOnBlurName = useCallback(async () => {
    try {
      if (
        !organizationSlug ||
        !dealRoomId ||
        !artifactId ||
        !getAccessTokenSilently ||
        !title ||
        title === artifact?.name ||
        title === artifact?.label
      ) {
        setIsEditingTitle(false);
        return;
      }

      let dataToSend: {};
      if (artifact?.label) {
        dataToSend = { label: title };
      } else {
        dataToSend = { name: title };
      }

      const token = await getAccessTokenSilently();
      await DealRoomsApiClient.updateArtifact(
        organizationSlug,
        dealRoomId,
        artifactId,
        {
          ...dataToSend,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      await refetchArtifact();
      refreshDealRoom();
      toast.success('Artifact title updated successfully');

      setIsEditingTitle(false);
    } catch (err: unknown) {}
  }, [
    organizationSlug,
    dealRoomId,
    artifactId,
    getAccessTokenSilently,
    title,
    artifact?.label,
    artifact?.name,
    refetchArtifact,
    refreshDealRoom,
  ]);

  // Redirect if IDs are missing
  useEffect(() => {
    if (!dealRoomId && !artifactId) {
      navigate(`/organization/${organizationSlug}/library/dealrooms`);
    } else if (!artifactId) {
      navigate(`/organization/${organizationSlug}/dealroom/${dealRoomId}`);
    } else if (!dealRoomId) {
      navigate(`/organization/${organizationSlug}/library/dealrooms`);
    }
  }, [artifactId, dealRoomId, navigate, organizationSlug]);

  const { currentContactId, currentContactCompanyId } = useMemo(() => {
    if (!dealRoom || !mfUser?.email)
      return {
        currentContactId: undefined,
        currentContactCompanyId: undefined,
      };

    const currentContact = dealRoom.contacts?.find(
      (contact) => contact.email.toLowerCase() === mfUser.email.toLowerCase(),
    );

    const currentCompany = dealRoom.companies?.[0];

    return {
      currentContactId: currentContact?.id,
      currentContactCompanyId: currentCompany?.id,
    };
  }, [dealRoom, mfUser?.email]);

  // Render side panel content based on selected tab
  const sidePanelContent = useMemo(() => {
    if (!organizationSlug || !dealRoomId || !artifactId) {
      return null;
    }

    switch (sidePanelTab) {
      case 'ask-ai':
        return (
          <DealRoomArtifactAssistantPanel
            key={artifactId}
            organizationSlug={organizationSlug!}
            dealRoomId={dealRoomId}
            artifactId={artifactId}
          />
        );
      case 'comment':
        return (
          <DealRoomArtifactCommentsPanel
            ref={dealRoomPanelRef}
            organizationSlug={organizationSlug!}
            dealRoomId={dealRoomId}
            artifactId={artifactId}
            currentThreadId={currentThreadId}
            setCurrentThreadId={(convId: number) => setCurrentThreadId(convId)}
            currentContactId={currentContactId}
            currentContactCompanyId={currentContactCompanyId}
          />
        );
    }
  }, [
    organizationSlug,
    dealRoomId,
    artifactId,
    sidePanelTab,
    currentThreadId,
    currentContactId,
    currentContactCompanyId,
  ]);

  const previewArtifact = useMemo(() => {
    if (!artifact || !organizationSlug || !dealRoomId) return null;

    return (
      <ArtifactPreview
        artifact={artifact}
        organizationSlug={organizationSlug}
        dealRoomId={dealRoomId}
      />
    );
  }, [artifact, organizationSlug, dealRoomId]);

  // Component rendering
  if (artifactLoading) return <Spinner />;
  if (!organizationSlug || !dealRoomId || !artifactId || !artifact) return null;

  const askAICommentsContainer = (
    <SplitViewLayout
      rootClassName={styles.sidebarRootLayout}
      layoutName="DealRoomArtifactDetailViewSidePanel"
      header={
        currentThreadId === undefined ? (
          <Pivot
            className={styles.pivotContainer}
            selectedKey={sidePanelTab}
            onLinkClick={(i) =>
              setSidePanelTab(
                (i?.props?.itemKey as 'ask-ai' | 'comment' | undefined) ||
                  'ask-ai',
              )
            }
          >
            <PivotItem itemKey="ask-ai" headerText="Ask AI" />
            <PivotItem itemKey="comment" headerText="Comments" />
          </Pivot>
        ) : (
          <div
            className="backToConvsContainer"
            style={{
              padding: '1rem 1rem 0.5rem 1rem',
              borderBottom: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
            }}
          >
            <DealRoomCommandBarButton
              className="backToConvsButton"
              buttonStyleType="COMMAND_BAR"
              iconProps={{ iconName: 'ChromeBack' }}
              onClick={() => {
                if (dealRoomPanelRef?.current?.backToConvList) {
                  dealRoomPanelRef.current.backToConvList();
                }
              }}
            >
              Back to all comment threads
            </DealRoomCommandBarButton>
          </div>
        )
      }
    >
      {sidePanelContent}
    </SplitViewLayout>
  );

  return (
    <div className={styles.wrapper}>
      <ArtifactsSidebar
        organizationSlug={organizationSlug}
        dealRoomId={dealRoomId}
        artifactId={artifactId}
      />
      <div className={styles.viewContainer}>
        <SplitViewLayout
          rootClassName={styles.splitViewRootLayout}
          layoutName="DealRoomArtifactDetailView"
          fullWidthHeader
          header={
            <ArtifactTitle
              title={title}
              isEditing={isEditingTitle}
              onStartEdit={() => setIsEditingTitle(true)}
              onBlur={handleOnBlurName}
              onChange={setTitle}
              artifact={artifact}
              organizationSlug={organizationSlug}
              dealRoomId={dealRoomId}
            />
          }
          sidebar={!isMobile && askAICommentsContainer}
          contentClassName={styles.content}
          sidebarWidthPercent={35}
          gap="1rem"
          footer={isMobile && askAICommentsContainer}
        >
          <>
            <div className="content-title">
              <ArtifactMetadata artifact={artifact} />
              <ArtifactActions
                artifact={artifact}
                dealRoomRole={dealRoomRole}
                onFeature={handleOnFeature}
                onDownload={handleOnDownloadArtifact}
                // onShare={handleOnShare}
                onUpvote={() => handleOnVote(true)}
                onDownvote={() => handleOnVote(false)}
              />
            </div>
            {previewArtifact}
          </>
        </SplitViewLayout>
        {confirmShareDialog}
      </div>
    </div>
  );
};
