import { useAuth0 } from '@auth0/auth0-react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { UserApiClient } from '../Services/NetworkCommon';
import Bugsnag from '@bugsnag/js';

const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID!;

export type LogoutProps = {
  appInsights: ApplicationInsights;
};
export const Logout = ({ appInsights }: LogoutProps) => {
  const { getAccessTokenSilently, logout } = useAuth0();
  const [params] = useSearchParams();

  useEffect(() => {
    const returnTo = params.has('returnTo')
      ? params.get('returnTo')!
      : window.location.origin;

    getAccessTokenSilently()
      .then((token) =>
        UserApiClient.logout({
          headers: { Authorization: `Bearer ${token}` },
        }),
      )
      .finally(() => {
        appInsights.clearAuthenticatedUserContext();
        Bugsnag.setUser(undefined, undefined, undefined);
        Bugsnag.clearMetadata('organization');
        localStorage.clear();
        logout({
          clientId,
          logoutParams: { returnTo },
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>Logging you out of Meetingflow...</div>;
};

export default Logout;
