import { YjsEditor } from '@slate-yjs/core';
import { BaseEditor, Editor } from 'slate';
import { WebsocketProvider } from 'y-websocket';
import { encoding } from 'lib0';
import { WS_MESSAGE } from '@meetingflow/common/Constants';

const SYNCED: WeakSet<SyncAwareEditor> = new WeakSet();
const CONNECTED: WeakSet<SyncAwareEditor> = new WeakSet();

export type SyncAwareEditor = BaseEditor & {
  provider: WebsocketProvider;
  isSynced: () => boolean;
  saveDocument: () => void;
  recreateDocument: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SyncAwareEditor = {
  isSyncAwareEditor(value: unknown): value is SyncAwareEditor {
    return (
      Editor.isEditor(value) &&
      typeof (value as unknown as SyncAwareEditor).isSynced === 'function'
    );
  },

  isSynced(value: unknown): boolean {
    return SyncAwareEditor.isSyncAwareEditor(value) && SYNCED.has(value);
  },

  isConnected(value: unknown): boolean {
    return SyncAwareEditor.isSyncAwareEditor(value) && CONNECTED.has(value);
  },

  provider(value: unknown): WebsocketProvider | null {
    return SyncAwareEditor.isSyncAwareEditor(value) ? value.provider : null;
  },
};

export const withSyncAwareness = <T extends YjsEditor>(
  editor: T,
  provider: WebsocketProvider,
) => {
  const e = editor as T & SyncAwareEditor;

  const { connect, disconnect } = e;

  e.isSynced = () => SYNCED.has(e);

  e.saveDocument = () => {
    console.info(`Sending save message to collab server`);
    const encoder = encoding.createEncoder();
    encoding.writeVarUint(encoder, WS_MESSAGE.SAVE);
    provider.ws?.send(encoding.toUint8Array(encoder));
  };

  e.recreateDocument = () => {
    console.info(`Sending recreate message to collab server`);
    const encoder = encoding.createEncoder();
    encoding.writeVarUint(encoder, WS_MESSAGE.RECREATE);
    provider.ws?.send(encoding.toUint8Array(encoder));
  };

  e.provider = provider;

  const statusChangeListener = (status: string) => {
    if (status === 'connected') {
      setTimeout(() => CONNECTED.add(e), 100);
    } else {
      setTimeout(() => CONNECTED.delete(e), 100);
    }
  };

  const awarenessSyncListener = (synced: boolean) => {
    if (synced) {
      setTimeout(() => SYNCED.add(e), 100);
    } else {
      setTimeout(() => SYNCED.delete(e), 100);
    }
  };

  e.connect = () => {
    connect();

    provider.on('status', statusChangeListener);
    provider.on('sync', awarenessSyncListener);
  };

  e.disconnect = () => {
    provider.off('status', statusChangeListener);
    provider.off('sync', awarenessSyncListener);

    CONNECTED.delete(e);

    disconnect();
  };

  return e;
};
