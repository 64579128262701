import { useAuth0 } from '@auth0/auth0-react';
import {
  FontSizes,
  mergeStyles,
  NeutralColors,
  SearchBox,
  Spinner,
  Text,
  Toggle,
} from '@fluentui/react';
import { ExternalServiceObject } from '@meetingflow/common/Api/data-contracts';
import { CollectToMap } from '@meetingflow/common/ArrayHelpers';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useExternalServiceConfigurations } from '../../../../Hooks/useExternalServiceConfigurations';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import { useLocalStorageState } from '../../../../Hooks/useLocalStorageState';
import { HubSpotDeal } from '../../../../Models/HubSpot/HubSpotDeal';
import { HubSpotDealPipeline } from '../../../../Models/HubSpot/HubSpotDealPipeline';
import { HubSpotOwner } from '../../../../Models/HubSpot/HubSpotOwner';
import {
  HubSpotDealPipelinesQuery,
  HubSpotDealsQuery,
  HubSpotOwnersQuery,
} from '../../../../QueryNames';
import { ApiClient } from '../../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../../Themes/Themes';
import { HubSpotPanelContext } from '../../../../types/HubSpotPanelContext';
import { HubSpotDealTile } from './HubSpotDealTile';

export type HubSpotBrowserSidePanelDealContentProps = {
  organizationSlug: string;
  meetingPlanId?: string;
  associatedObjects?: ExternalServiceObject[];
  configurationId: number;
  externalDomains?: string[];
  externalEmails?: string[];
  onPickedObject: (
    object: ExternalServiceObject | Omit<ExternalServiceObject, 'id'>,
  ) => void;
  onPinnedObject:
    | ((e?: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<unknown>)
    | undefined;
  pushHubSpotPanel: (context: HubSpotPanelContext) => void;
};
export const HubSpotBrowserSidePanelDealContent = ({
  organizationSlug,
  meetingPlanId,
  associatedObjects,
  configurationId,
  externalDomains,
  externalEmails,
  onPickedObject,
  onPinnedObject,
  pushHubSpotPanel,
}: HubSpotBrowserSidePanelDealContentProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const { isDark } = useLightOrDarkMode();

  const { loading: configurationsLoading, configurationById } =
    useExternalServiceConfigurations({ app: 'HUBSPOT', withToken: true });

  const [q, setQ] = useState<string>('');

  const [onlyActive, setOnlyActive] = useLocalStorageState<boolean>(
    'HUBSPOT_PICKER_SIDE_PANEL_DEALS_ONLY_ACTIVE',
    true,
  );
  const [onlyMine, setOnlyMine] = useLocalStorageState<boolean>(
    'HUBSPOT_PICKER_SIDE_PANEL_DEALS_ONLY_MINE',
    true,
  );

  const {
    data: hubspotOwners,
    isLoading: hubspotOwnersLoading,
    refetch: refetchHubspotOwners,
  } = useQuery(
    HubSpotOwnersQuery(organizationSlug!, configurationId),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<HubSpotOwner[]>(
        `/organization/${organizationSlug}/external/hubspot/configuration/${configurationId}/owners`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!configurationId,
    },
  );

  const hubspotOwnersMap = useMemo(
    () => CollectToMap(hubspotOwners?.data ?? [], 'id'),
    [hubspotOwners?.data],
  );

  const {
    data: hubspotPipelines,
    isLoading: hubspotPipelinesLoading,
    refetch: refetchHubspotPipelines,
  } = useQuery(
    HubSpotDealPipelinesQuery(organizationSlug!, configurationId),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<HubSpotDealPipeline[]>(
        `/organization/${organizationSlug}/external/hubspot/configuration/${configurationId}/pipelines/deal`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: configurationId !== undefined,
    },
  );

  const hubspotPipelinesMap = useMemo(
    () => CollectToMap(hubspotPipelines?.data ?? [], 'id'),
    [hubspotPipelines?.data],
  );

  const {
    data: hubspotDeals,
    isLoading: hubspotDealsLoading,
    isRefetching: hubspotDealsRefetching,
    refetch: refetchHubspotDeals,
  } = useQuery(
    HubSpotDealsQuery(organizationSlug!, configurationId),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<HubSpotDeal[]>(
        `/organization/${organizationSlug}/external/hubspot/configuration/${configurationId}/deals`,
        {
          params: {
            q: q ?? undefined,
            active: onlyActive,
            my: onlyMine,
            domains: undefined,
          },
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRefetch = useCallback(debounce(refetchHubspotDeals, 250), [
    refetchHubspotDeals,
  ]);

  useEffect(() => {
    debouncedRefetch();
  }, [q, onlyActive, onlyMine, debouncedRefetch]);

  useEffect(() => {
    if (configurationId) {
      refetchHubspotOwners();
      refetchHubspotPipelines();
      refetchHubspotDeals();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configurationId]);

  const panelContent = mergeStyles({
    padding: '0 .5rem',
  });

  const slideSpinnerClass = mergeStyles({
    height: '2rem',
    animationName: 'slideDownSpinnerAnimation',
    animationDuration: '1s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '.5',
    animationFillMode: 'forwards',
    position: 'absolute',
    top: '4.35rem',
    right: '.5rem',
  });

  if (
    configurationsLoading ||
    hubspotOwnersLoading ||
    hubspotPipelinesLoading
  ) {
    return <Spinner className={slideSpinnerClass} />;
  }

  return (
    <>
      <div className={panelContent}>
        <div
          style={{
            marginLeft: '-.5rem',
            marginRight: '-.5rem',
            marginBottom: '.5rem',
            padding: '.5rem',
            borderBottom: `1px solid ${
              isDark ? NeutralColors.gray180 : MEETINGFLOW_COLORS.purpleGrey
            }`,
            backgroundColor: isDark
              ? NeutralColors.gray210
              : MEETINGFLOW_COLORS.purpleUltraLight,
          }}
        >
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              marginBottom: '.25rem',
            }}
          >
            <div style={{ paddingRight: '.5rem ' }}>
              <Toggle
                disabled={hubspotDealsLoading || hubspotDealsRefetching}
                label={'Show Only Mine'}
                checked={onlyMine === true}
                onChange={(e, checked) => setOnlyMine(!!checked)}
                inlineLabel
                styles={{
                  root: {
                    margin: 0,
                    transition: '.3s ease-in-out all',
                    '.ms-Toggle-background': {
                      backgroundColor: MEETINGFLOW_COLORS.white,
                    },
                    '.ms-Toggle-thumb': {
                      backgroundColor: MEETINGFLOW_COLORS.teal,
                    },
                    'button:disabled .ms-Toggle-thumb': {
                      backgroundColor: NeutralColors.gray70,
                    },
                    'button:hover': {
                      backgroundColor: NeutralColors.gray40,
                    },
                  },
                  label: {
                    order: 0,
                    margin: 0,
                    marginRight: '.25rem',
                    fontSize: FontSizes.small,
                    width: `calc(100% - 66px)`,
                    color: NeutralColors.gray120,
                  },

                  pill: {
                    backgroundColor: NeutralColors.gray40,
                    outlineWidth: `0 !important`,
                    borderWidth: `0 !important`,
                  },
                }}
              />
            </div>
            <div
              style={{
                borderLeft: `1px solid ${
                  isDark ? NeutralColors.gray180 : NeutralColors.gray50
                }`,
                paddingLeft: '.5rem',
              }}
            >
              <Toggle
                disabled={hubspotDealsLoading || hubspotDealsRefetching}
                label={'Show Only Active'}
                checked={onlyActive === true}
                onChange={(e, checked) => setOnlyActive(!!checked)}
                inlineLabel
                styles={{
                  root: {
                    margin: 0,
                    transition: '.3s ease-in-out all',
                    '.ms-Toggle-background': {
                      backgroundColor: MEETINGFLOW_COLORS.white,
                    },
                    '.ms-Toggle-thumb': {
                      backgroundColor: MEETINGFLOW_COLORS.teal,
                    },
                    'button:disabled .ms-Toggle-thumb': {
                      backgroundColor: NeutralColors.gray70,
                    },
                    'button:hover': {
                      backgroundColor: NeutralColors.gray40,
                    },
                  },
                  label: {
                    order: 0,
                    margin: 0,
                    marginRight: '.25rem',
                    fontSize: FontSizes.small,
                    width: `calc(100% - 66px)`,
                    color: NeutralColors.gray120,
                  },

                  pill: {
                    backgroundColor: NeutralColors.gray40,
                    outlineWidth: `0 !important`,
                    borderWidth: `0 !important`,
                  },
                }}
              />
            </div>
          </div>
          <SearchBox
            disabled={hubspotDealsLoading || hubspotDealsRefetching}
            placeholder="Search opportunities by name..."
            value={q}
            onChange={(e, newValue) => setQ(newValue ?? '')}
            styles={{
              root: {
                transition: '.3s all ease-in-out',
                fontSize: FontSizes.small,
                height: '1.5rem',
                marginTop: '.5rem',
                width:
                  configurationsLoading ||
                  hubspotDealsLoading ||
                  hubspotDealsRefetching
                    ? 'calc(100% - 2rem)'
                    : '100%',
              },
            }}
          />
        </div>
      </div>

      <div
        style={{
          overflowY: 'auto',
          height: 'calc(100% - 18rem)',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '.125rem',
          padding: '0 .5rem 2rem .5rem',
          boxSizing: 'border-box',
        }}
      >
        {hubspotDealsLoading || hubspotDealsRefetching ? (
          <Spinner className={slideSpinnerClass} />
        ) : null}
        {hubspotDeals?.data && hubspotDeals?.data?.length > 0 ? (
          <>
            {hubspotDeals?.data?.map((deal) => {
              const associatedObjectIds = associatedObjects?.map(
                (o) => o.externalId,
              );
              const isPinned = associatedObjectIds?.includes(deal.id);
              const externalServiceObjectId = associatedObjects?.find(
                (o) => o.externalId === deal.id,
              )?.id;
              const externalServiceObject = {
                externalId: deal.id,
                objectType: 'DEAL',
                name: deal.properties.dealname,
                serviceConfigurationId: configurationId,
                serviceConfiguration: configurationById(configurationId)!,
              } as Omit<ExternalServiceObject, 'id'>;
              return (
                <HubSpotDealTile
                  key={deal.id}
                  organizationSlug={organizationSlug}
                  meetingPlanId={meetingPlanId}
                  id={deal.id}
                  name={deal.properties.dealname}
                  ownerName={
                    deal.properties.hubspot_owner_id
                      ? `${
                          hubspotOwnersMap[deal.properties.hubspot_owner_id]
                            ?.firstName
                        } ${
                          hubspotOwnersMap[deal.properties.hubspot_owner_id]
                            ?.lastName
                        }`
                      : undefined
                  }
                  ownerEmail={
                    deal.properties.hubspot_owner_id
                      ? hubspotOwnersMap[deal.properties.hubspot_owner_id]
                          ?.email
                      : undefined
                  }
                  stage={
                    deal.properties.pipeline
                      ? hubspotPipelinesMap[
                          deal.properties.pipeline
                        ]?.stages?.find(
                          (s) => s.id === deal.properties.dealstage,
                        )?.label
                      : undefined
                  }
                  hubspotInstance={
                    configurationById(configurationId)!.instanceId!
                  }
                  isPinned={isPinned}
                  externalServiceObjectId={externalServiceObjectId}
                  pushHubSpotPanel={pushHubSpotPanel}
                  onClick={() => onPickedObject(externalServiceObject)}
                  onPinClick={onPinnedObject}
                  showExternalLink
                  showArrow
                />
              );
            })}
          </>
        ) : (
          <div>
            {!hubspotDealsLoading && !hubspotDealsRefetching ? (
              <Text
                block
                variant="small"
                style={{ fontStyle: 'italic', margin: '.5rem 0' }}
              >
                There are no matching deals. Change your filters above to show
                all accounts, if needed.
              </Text>
            ) : null}
          </div>
        )}
      </div>
    </>
  );
};
