import {
  AppState,
  Auth0Provider,
  AuthorizationParams,
} from '@auth0/auth0-react';
import { EMPTY_OBJECT } from 'docx';
import { PropsWithChildren, useCallback, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const domain = import.meta.env.VITE_AUTH0_DOMAIN!;
const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID!;
const audience = import.meta.env.VITE_API_AUDIENCE!;

const Auth0ProviderWithHistory = ({ children }: PropsWithChildren<{}>) => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const onRedirectCallback = useCallback(
    (appState: AppState | undefined) => {
      let returnUrl = appState?.returnTo || window.location.pathname;

      // Create default from current url
      let url = new URL(window.location.href);

      try {
        // try parse as full url
        url = new URL(returnUrl);
      } catch (err: unknown) {
        // a path will throw TypeError, recreate using window.location.origin as a base
        try {
          url = new URL(returnUrl, window.location.origin);
        } catch (err: unknown) {}
      }

      // Use a redirect query parameter, if there is one
      if (url.searchParams.has('redirect')) {
        returnUrl = url.searchParams.get('redirect')!;
      }

      navigate(returnUrl, {
        replace: true,
      });
    },
    [navigate],
  );

  const hasEmailParam = searchParams.has('email');

  const additionalParams: Partial<AuthorizationParams> = useMemo(() => {
    return hasEmailParam
      ? {
          login_hint: searchParams.get('email'),
        }
      : EMPTY_OBJECT;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasEmailParam]);

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      useRefreshTokens
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience,
        redirect: searchParams.get('redirect') || undefined,
        ...additionalParams,
      }}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
