import { mergeStyleSets } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

export const useCommentsContainerStyles = () =>
  mergeStyleSets({
    panel: {
      '.ms-Panel-contentInner': {},
      '.ms-Panel-content': {
        padding: '1rem 0 0',
        height: 'calc(100% - 2rem)',
        backgroundColor: DEALROOMS_COLORS.financialLighterGray,
      },
      '.ms-Panel-scrollableContent': {
        height: '100% !important',
      },
    },
    headerButton: {
      marginLeft: '1rem',
      i: {
        fontSize: '1.25rem',
        color: DEALROOMS_COLORS.themePrimary,
      },
    },
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
      height: '100%',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      width: '100%',
      flexGrow: '1',
      overflow: 'auto',
    },
    backToConvsButton: {
      border: 'none',
      padding: '0.8rem 0.75rem;',
      i: {
        margin: '0 1rem 0 0',
      },
      ':hover': {
        color: 'inherit',
        i: {
          color: 'inherit',
        },
      },
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
      padding: '0 1rem',
    },
    title: {
      color: DEALROOMS_COLORS.themePrimary,
      fontSize: '2em',
      fontWeight: '500',
    },
  });
