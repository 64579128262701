import {
  DefaultButton,
  IBaseButtonProps,
  ILinkProps,
  Link,
} from '@fluentui/react';
import classNames from 'classnames';
import React, { PropsWithChildren, useCallback } from 'react';
import { useMatch, useNavigate } from 'react-router';

export type LocationAwareLinkProps = {
  id?: string;
  className?: string;
  label?: string | React.ReactNode;
  path: string;
  exact?: boolean;
  asButton?: boolean;
  componentProps?:
    | Partial<Omit<IBaseButtonProps, 'onClick'>>
    | Partial<Omit<ILinkProps, 'onClick'>>;
};
export const LocationAwareLink = React.memo(
  ({
    id,
    className,
    label,
    path,
    exact,
    asButton,
    children,
    componentProps = {},
  }: PropsWithChildren<LocationAwareLinkProps>) => {
    const navigate = useNavigate();
    const match = useMatch({ path, end: exact || false });

    const isActive = !!match;

    const classes = classNames(className, isActive ? 'active' : undefined);

    const Component = asButton ? DefaultButton : Link;

    const onClick = useCallback((e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLElement>) => {
      if (!e.ctrlKey && !e.metaKey && !e.shiftKey && e.button === 0) {
        e.preventDefault();
        navigate(path)
      }
    }, [navigate, path]);

    return (
      //@ts-ignore
      <Component
        id={id}
        //@ts-ignore
        // as={'a'}
        className={classes}
        href={path}
        onClick={onClick}
        {...componentProps}
      >
        {label}
        {children}
      </Component>
    );
  },
);
