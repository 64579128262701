import { useAuth0 } from '@auth0/auth0-react';
import {
  FontSizes,
  FontWeights,
  NeutralColors,
  Pivot,
  PivotItem,
} from '@fluentui/react';
import {
  Contact,
  DetailedMeetingflow,
  ListPlansParams,
} from '@meetingflow/common/Api/data-contracts';
import { useQuery } from 'react-query';
import { MeetingflowBrowser } from '../../../Components/Organization/Home/MeetingflowBrowser';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import {
  MeetingPlanRelatedPlansQuery,
  OrganizationMeetingPlansQuery,
} from '../../../QueryNames';
import { MeetingflowsApiClient } from '../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { BaseSidePanel } from './BaseSidePanel';
import { MeetingPlanRelatedPlanReaderList } from './RelatedPlansList';
import { useEffect, useMemo } from 'react';

export interface PlansSidePanelProps {
  organizationSlug: string;
  meetingflowId: string;
  meetingflow: Pick<DetailedMeetingflow, 'id' | 'externalSeriesId'> | undefined;
  title?: string;
  filters?: Omit<ListPlansParams, 'organizationSlug'>;
  onBack?: () => void;
  onClose: () => void;
  onContactClick: (c: Contact['id']) => void;
  onMeetingflowClick: (meetingflowId: DetailedMeetingflow['id']) => void;
  color: string;
}
export const PlansSidePanel = ({
  meetingflow,
  meetingflowId,
  organizationSlug,
  title,
  filters,
  onBack,
  onClose,
  onContactClick,
  onMeetingflowClick,
  color,
}: PlansSidePanelProps) => {
  const { isDark } = useLightOrDarkMode();
  const { getAccessTokenSilently } = useAuth0();

  const planFilters = useMemo(
    () =>
      filters
        ? {
            ...filters,
            excludeMeetingflowId: meetingflowId,
          }
        : {
            relatedMeetingflowId: meetingflowId,
            excludeMeetingflowId: meetingflowId,
          },
    [filters, meetingflowId],
  );

  const filterString = useMemo(() => {
    //@ts-ignore
    const params = new URLSearchParams(planFilters);
    params.sort();
    return params.toString();
  }, [planFilters]);

  // Query for related, because we need to hide the tab if none exists.
  const {
    data: plansData,
    isLoading: plansLoading,
    refetch: plansRefetch,
    isRefetching: plansRefetching,
  } = useQuery(
    OrganizationMeetingPlansQuery(organizationSlug!, filterString, 1),
    async () => {
      const token = await getAccessTokenSilently();
      return MeetingflowsApiClient.listPlans(
        {
          organizationSlug,
          ...planFilters,
          limit: 1,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!organizationSlug && !!meetingflowId,
    },
  );

  const hasPlans = !!plansData?.data?.length;

  useEffect(() => {
    if (organizationSlug && meetingflowId) {
      plansRefetch();
    }
  }, [meetingflowId, organizationSlug, planFilters, plansRefetch]);

  const pivotStyles = useMemo(
    () => ({
      root: {
        height: '28px',
      },
      link: {
        marginTop: '.25rem',
        fontSize: FontSizes.small,
        height: '1.5rem',
        borderTopLeftRadius: '3px',
        borderTopRightRadius: '3px',
        color: NeutralColors.gray120,
        selectors: {
          ':hover': {
            backgroundColor: isDark
              ? NeutralColors.gray150
              : MEETINGFLOW_COLORS.purpleLighter,
          },
          ':active': {
            backgroundColor: isDark
              ? NeutralColors.gray150
              : MEETINGFLOW_COLORS.purpleLighter,
          },
        },
      },
      linkContent: {
        height: '32px',
      },
      text: {
        lineHeight: '28px',
      },
      linkIsSelected: {
        backgroundColor: isDark
          ? NeutralColors.gray150
          : MEETINGFLOW_COLORS.purpleLighter,
        color: isDark ? NeutralColors.gray90 : NeutralColors.gray140,
        fontWeight: FontWeights.semibold,
        '::before': {
          display: 'none',
        },
      },
      itemContainer: {
        height: '100%',
        maxHeight: '100%',
        '> div:last-child': {
          height: '100%',
          maxHeight: '100%',
        },
        borderTop: `1px solid ${
          isDark ? NeutralColors.gray150 : MEETINGFLOW_COLORS.purpleGrey
        }`,
      },
    }),
    [isDark],
  );

  return (
    <BaseSidePanel
      title="Other Meetingflows"
      onBack={onBack}
      onClose={onClose}
      providesOwnScrolling
    >
      <Pivot styles={pivotStyles}>
        {hasPlans ? (
          <PivotItem headerText={title ?? 'Related Meetingflows'}>
            <MeetingPlanRelatedPlanReaderList
              key={filterString}
              organizationSlug={organizationSlug}
              meetingflowId={meetingflowId}
              title={title ?? 'Related Meetingflows'}
              filters={planFilters}
              maxCount={planFilters?.limit}
              meetingflow={meetingflow}
              onContactClick={onContactClick}
              color={color}
              stickyTopPosition="-10px"
              onMeetingflowClick={onMeetingflowClick}
            />
          </PivotItem>
        ) : null}
        <PivotItem headerText="All Meetingflows">
          <MeetingflowBrowser
            organizationSlug={organizationSlug}
            onClickMeetingflow={onMeetingflowClick}
          />
        </PivotItem>
      </Pivot>
    </BaseSidePanel>
  );
};
