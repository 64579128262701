import { useAuth0 } from '@auth0/auth0-react';
import {
  FontSizes,
  FontWeights,
  NeutralColors,
  Spinner,
  Text,
  mergeStyles,
} from '@fluentui/react';
import {
  DetailedMeetingflow,
  ExternalServiceObject,
} from '@meetingflow/common/Api/data-contracts';
import { CollectToMap } from '@meetingflow/common/ArrayHelpers';
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useExternalServiceConfigurations } from '../../../../Hooks/useExternalServiceConfigurations';
import { HubSpotCompany } from '../../../../Models/HubSpot/HubSpotCompany';
import { HubSpotDeal } from '../../../../Models/HubSpot/HubSpotDeal';
import { HubSpotDealPipeline } from '../../../../Models/HubSpot/HubSpotDealPipeline';
import { HubSpotOwner } from '../../../../Models/HubSpot/HubSpotOwner';
import {
  HubSpotCompanySuggestionsQuery,
  HubSpotDealPipelinesQuery,
  HubSpotDealSuggestionsQuery,
  HubSpotOwnersQuery,
} from '../../../../QueryNames';
import { ApiClient } from '../../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../../Themes/Themes';
import { SalesforcePanelContext } from '../../../../types/SalesforcePanelContext';
import { HubSpotCompanyTile } from './HubSpotCompanyTile';
import { HubSpotDealTile } from './HubSpotDealTile';

interface HubSpotInsightsPanelContentProps {
  organizationSlug: string;
  meetingflowId: string;
  meetingflow: Pick<DetailedMeetingflow, 'creator' | 'organizer' | 'attendees'>;
  configurationId: number;
  externalDomains?: string[];
  onPickedObject: (
    object: ExternalServiceObject | Omit<ExternalServiceObject, 'id'>,
  ) => void;
  onPinnedObject:
    | ((e?: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<unknown>)
    | undefined;
  pushHubSpotPanel: (context: SalesforcePanelContext) => void;
  associatedObjects?: ExternalServiceObject[];
  showSuggestionsToggle?: boolean;
  showTitle?: boolean;
  showHubSpotObjectPicker?: () => void;
  maxItems?: number;
}

export const HubSpotInsightsPanelContent = ({
  organizationSlug,
  meetingflowId,
  meetingflow,
  configurationId,
  externalDomains,
  onPickedObject,
  onPinnedObject,
  pushHubSpotPanel,
  associatedObjects = [],
  showTitle = true,
  showHubSpotObjectPicker,
  maxItems = 5,
}: HubSpotInsightsPanelContentProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const { loading: configurationsLoading, configurationById } =
    useExternalServiceConfigurations({ app: 'HUBSPOT', withToken: true });

  const associatedHubSpotObjects = useMemo(
    () =>
      associatedObjects.filter(
        (o) =>
          o.serviceConfiguration.app === 'HUBSPOT' &&
          o.serviceConfigurationId === configurationId,
      ),
    [associatedObjects, configurationId],
  );

  const {
    data: hubspotOwners,
    isLoading: hubspotOwnersLoading,
    refetch: refetchHubspotOwners,
  } = useQuery(
    HubSpotOwnersQuery(organizationSlug!, configurationId),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<HubSpotOwner[]>(
        `/organization/${organizationSlug}/external/hubspot/configuration/${configurationId}/owners`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      // 5 min
      staleTime: 300000,
      enabled: !!organizationSlug && !!configurationId,
    },
  );

  const hubspotOwnersMap = useMemo(
    () => CollectToMap(hubspotOwners?.data ?? [], 'id'),
    [hubspotOwners?.data],
  );

  const {
    data: hubspotPipelines,
    isLoading: hubspotPipelinesLoading,
    refetch: refetchHubspotPipelines,
  } = useQuery(
    HubSpotDealPipelinesQuery(organizationSlug!, configurationId),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<HubSpotDealPipeline[]>(
        `/organization/${organizationSlug}/external/hubspot/configuration/${configurationId}/pipelines/deal`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!organizationSlug && !!configurationId,
    },
  );

  const hubspotPipelinesMap = useMemo(
    () => CollectToMap(hubspotPipelines?.data ?? [], 'id'),
    [hubspotPipelines?.data],
  );

  const {
    data: hubspotCompanySuggestions,
    isLoading: hubspotCompanySuggestionsLoading,
    isRefetching: hubspotCompanySuggestionsRefetching,
    refetch: refetchHubspotCompanySuggestions,
  } = useQuery(
    HubSpotCompanySuggestionsQuery(
      organizationSlug!,
      configurationId,
      externalDomains,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<HubSpotCompany[]>(
        `/organization/${organizationSlug}/external/hubspot/configuration/${configurationId}/companies`,
        {
          params: {
            active: true,
            domains: externalDomains,
            limit: maxItems,
          },
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled:
        !!organizationSlug && !!configurationId && !!externalDomains?.length,
    },
  );

  const {
    data: hubspotDealSuggestions,
    isLoading: hubspotDealSuggestionsLoading,
    isRefetching: hubspotDealSuggestionsRefetching,
    refetch: refetchHubspotDealSuggestions,
  } = useQuery(
    HubSpotDealSuggestionsQuery(
      organizationSlug!,
      configurationId,
      externalDomains,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<HubSpotDeal[]>(
        `/organization/${organizationSlug}/external/hubspot/configuration/${configurationId}/deals`,
        {
          params: {
            active: true,
            domains: externalDomains,
            limit: maxItems,
          },
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled:
        !!organizationSlug && !!configurationId && !!externalDomains?.length,
    },
  );

  useEffect(() => {
    if (!!organizationSlug && !!configurationId) {
      refetchHubspotOwners();
      refetchHubspotPipelines();

      if (!!externalDomains?.length) {
        refetchHubspotDealSuggestions();
        refetchHubspotCompanySuggestions();
      }
    }
  }, [
    configurationId,
    externalDomains?.length,
    organizationSlug,
    refetchHubspotCompanySuggestions,
    refetchHubspotDealSuggestions,
    refetchHubspotOwners,
    refetchHubspotPipelines,
  ]);

  const listClass = mergeStyles({
    position: 'relative',
    animationName: 'fadeInSlideAnimation',
    animationDuration: '1s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '1',
    animationFillMode: 'forwards',
    width: 'auto',
    transition: '.3s ease-in-out all',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    rowGap: '.125rem',
    marginBottom: '1rem',
  });

  const slideSpinnerClass = mergeStyles({
    height: '2rem',
    animationName: 'slideDownSpinnerAnimation',
    animationDuration: '1s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '.5',
    animationFillMode: 'forwards',
  });

  const sectionSubHeaderClass = mergeStyles({
    margin: '.5rem 0 .5rem 0!important',
    fontSize: FontSizes.mini,
    fontWeight: FontWeights.semibold,
    color: NeutralColors.gray100,
    position: 'relative',
    display: 'block',
    width: '100%',
  });

  const sectionHeaderClass = mergeStyles({
    margin: '0 0 0 0',
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.semibold,
    color: MEETINGFLOW_COLORS.teal,
    position: 'relative',
    display: 'block',
    width: '100%',

    userSelect: 'none',
    webkitUserSelect: 'none',
  });

  const hubspotAccounts = hubspotCompanySuggestions?.data
    ?.filter(
      (account) =>
        !associatedHubSpotObjects.some((a) => a.externalId === account.id),
    )
    ?.slice(0, maxItems);

  const dealSuggestions = hubspotDealSuggestions?.data
    ?.filter(
      (deal) => !associatedHubSpotObjects.some((o) => o.externalId === deal.id),
    )
    ?.slice(0, maxItems);

  if (configurationsLoading) {
    return (
      <div style={{ margin: '1rem 0' }}>
        <Spinner className={slideSpinnerClass} />
      </div>
    );
  }

  if (
    !hubspotAccounts?.length &&
    !dealSuggestions?.length &&
    !associatedObjects.length
  ) {
    return null;
  }

  return (
    <div className={listClass}>
      <Text block className={sectionHeaderClass}>
        HubSpot
      </Text>

      {showTitle && associatedObjects.length ? (
        <Text
          block
          className={sectionSubHeaderClass}
          style={{
            marginBottom: '.5rem',
          }}
        >
          Pinned Items
        </Text>
      ) : null}
      {associatedObjects.length
        ? associatedObjects
            .filter((o) => o.serviceConfiguration.app === 'HUBSPOT')
            .map((o) => {
              const objType = o.objectType;
              switch (objType) {
                case 'ACCOUNT': {
                  return (
                    <HubSpotCompanyTile
                      key={o.externalId}
                      id={o.externalId}
                      organizationSlug={organizationSlug}
                      meetingPlanId={meetingflowId}
                      name={o.name}
                      hubspotInstance={
                        configurationById(configurationId)!.instanceId!
                      }
                      onClick={() => onPickedObject(o)}
                      externalServiceObjectId={o.id}
                      onPinClick={onPinnedObject}
                      showExternalLink
                      showObjectType
                      showArrow={false}
                      isPinned
                    />
                  );
                }
                case 'DEAL': {
                  return (
                    <HubSpotDealTile
                      key={o.externalId}
                      organizationSlug={organizationSlug}
                      meetingPlanId={meetingflowId}
                      id={o.externalId}
                      name={o.name}
                      companyName={o.parent?.name}
                      onClickCompanyName={(
                        e?: React.MouseEvent<HTMLElement>,
                      ) => {
                        e?.preventDefault();
                        e?.stopPropagation();
                        if (
                          o.parent?.objectType === 'ACCOUNT' &&
                          o.parent.externalId
                        ) {
                          pushHubSpotPanel({
                            name: o.parent.name,
                            objectId: o.parent.externalId,
                            objectType: 'ACCOUNT',
                            serviceConfigurationId: configurationId,
                          });
                        }
                      }}
                      hubspotInstance={
                        configurationById(configurationId)!.instanceId!
                      }
                      onClick={() => onPickedObject(o)}
                      isPinned
                      externalServiceObjectId={o.id}
                      showExternalLink
                      onPinClick={onPinnedObject}
                      pushHubSpotPanel={pushHubSpotPanel}
                      showObjectType
                    />
                  );
                }
                default:
                  return null;
              }
            })
        : null}

      {showTitle && hubspotAccounts?.length ? (
        <Text block className={sectionSubHeaderClass}>
          Suggested Companies
        </Text>
      ) : null}
      {hubspotAccounts && hubspotAccounts?.length
        ? hubspotAccounts.map((company) => {
            const externalServiceObject = {
              externalId: company.id,
              objectType: 'ACCOUNT',
              name: company.properties.name,
              serviceConfigurationId: configurationId,
              serviceConfiguration: configurationById(configurationId)!,
            } as ExternalServiceObject;
            return (
              <HubSpotCompanyTile
                key={company.id}
                meetingPlanId={meetingflowId}
                organizationSlug={organizationSlug}
                id={company.id}
                name={company.properties.name}
                ownerName={
                  company.properties.hubspot_owner_id &&
                  !!hubspotOwnersMap[company.properties.hubspot_owner_id]
                    ? `${
                        hubspotOwnersMap[company.properties.hubspot_owner_id]
                          .firstName
                      } ${
                        hubspotOwnersMap[company.properties.hubspot_owner_id]
                          .lastName
                      }`
                    : undefined
                }
                ownerEmail={
                  company.properties.hubspot_owner_id &&
                  !!hubspotOwnersMap[company.properties.hubspot_owner_id]
                    ? hubspotOwnersMap[company.properties.hubspot_owner_id]
                        ?.email
                    : undefined
                }
                hubspotInstance={
                  configurationById(configurationId)!.instanceId!
                }
                onClick={() => onPickedObject(externalServiceObject)}
                onPinClick={onPinnedObject}
                showArrow
                showExternalLink
              />
            );
          })
        : null}

      {showTitle && dealSuggestions?.length ? (
        <Text block className={sectionSubHeaderClass}>
          Suggested Deals
        </Text>
      ) : null}
      {dealSuggestions?.length
        ? dealSuggestions.map((deal) => {
            const externalServiceObject = {
              externalId: deal.id,
              objectType: 'DEAL',
              name: deal.properties.dealname,
              serviceConfigurationId: configurationId,
              serviceConfiguration: configurationById(configurationId)!,
            } as ExternalServiceObject;

            return (
              <HubSpotDealTile
                key={deal.id}
                organizationSlug={organizationSlug}
                meetingPlanId={meetingflowId}
                id={deal.id}
                name={deal.properties.dealname}
                ownerName={
                  deal.properties.hubspot_owner_id
                    ? `${hubspotOwnersMap[deal.properties.hubspot_owner_id]
                        ?.firstName} ${hubspotOwnersMap[
                        deal.properties.hubspot_owner_id
                      ]?.lastName}`
                    : undefined
                }
                ownerEmail={
                  deal.properties.hubspot_owner_id
                    ? hubspotOwnersMap[deal.properties.hubspot_owner_id]?.email
                    : undefined
                }
                stage={
                  deal.properties.pipeline
                    ? hubspotPipelinesMap[
                        deal.properties.pipeline
                      ]?.stages?.find((s) => s.id === deal.properties.dealstage)
                        ?.label
                    : undefined
                }
                hubspotInstance={
                  configurationById(configurationId)!.instanceId!
                }
                onClick={() => onPickedObject(externalServiceObject)}
                isLoading={false}
                onPinClick={onPinnedObject}
                showArrow
                showExternalLink
              />
            );
          })
        : null}
    </div>
  );
};
