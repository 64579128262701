import {
  IColumn,
  IDialogProps,
  SelectionMode,
  Spinner,
  Text,
  FontIcon,
  FontWeights,
  Persona,
  PersonaSize,
} from '@fluentui/react';
import { MeetingPlanShare } from '../../../Models/MeetingPlan';
import { StyledDetailsList } from '../../StyledDetailsList';
import { useQuery } from 'react-query';
import { MeetingPlanSharesQuery } from '../../../QueryNames';
import { useAuth0 } from '@auth0/auth0-react';
import { MeetingflowsApiClient } from '../../../Services/NetworkCommon';
import { PersonaWithTooltip } from '../../PersonaWithTooltip';
import StyledDateTime from '../../StyledDateTime';
import 'react-customize-token-input/dist/react-customize-token-input.css';
import { AsyncLink } from '../../HOC/AsyncLink';
import toast from 'react-hot-toast';
import { EMPTY_ARRAY } from '../../../Constants';
import { useMemo } from 'react';
import {
  Contact,
  Meetingflow,
  User,
} from '@meetingflow/common/Api/data-contracts';
import { getInitialsFromName } from '@meetingflow/common/StringHelpers';

type PersonWithKey = Pick<Contact, 'name' | 'email' | 'emailDomain'> & {
  key: string;
  share?: MeetingPlanShare;
};

type UseShareDialogAccessControlStateProps = {
  organizationSlug: string;
  meetingPlanId: string;
  onlyGuests?: boolean;
};

export const useShareDialogAccessControlState = ({
  organizationSlug,
  meetingPlanId,
  onlyGuests,
}: UseShareDialogAccessControlStateProps): {
  meetingPlanShares:
    | (User & {
        readonly sharedAt: string;
      })[]
    | undefined;
  meetingPlanSharesLoading: boolean;
  refetchMeetingPlanShares: () => Promise<unknown>;
} => {
  const { getAccessTokenSilently } = useAuth0();

  const {
    data: meetingPlanShares,
    isLoading: meetingPlanSharesLoading,
    refetch: refetchMeetingPlanShares,
  } = useQuery(
    MeetingPlanSharesQuery(organizationSlug, meetingPlanId, onlyGuests),
    async () => {
      const token = await getAccessTokenSilently();
      return MeetingflowsApiClient.listShares(
        {
          organizationSlug,
          meetingPlanId,
          roles: onlyGuests ? ['GUEST'] : undefined,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    { cacheTime: 0 },
  );

  return {
    meetingPlanShares: meetingPlanShares?.data,
    meetingPlanSharesLoading,
    refetchMeetingPlanShares,
  };
};

export type InviteContactsDialogProps = {
  organizationSlug: string;
  meetingPlan: Pick<Meetingflow, 'id' | 'companies'>;
  internalDomains: string[];
  onlyGuests?: boolean;
};

export const ShareDialogAccessControl = ({
  organizationSlug,
  meetingPlan,
  internalDomains,
  meetingPlanShares,
  meetingPlanSharesLoading,
  refetchMeetingPlanShares,
  onlyGuests,
  ...rest
}: IDialogProps &
  InviteContactsDialogProps &
  ReturnType<typeof useShareDialogAccessControlState>) => {
  const { getAccessTokenSilently } = useAuth0();

  const columns: IColumn[] = useMemo(
    () => [
      {
        key: 'company',
        name: 'Company',
        minWidth: 32,
        maxWidth: 32,
        fieldName: 'company',
        onRender: (person: PersonWithKey) => {
          const company = meetingPlan.companies.find(
            (c) =>
              c?.domains
                ?.map((domain) => domain.domain)
                .includes(person.emailDomain),
          );
          return (
            <div>
              {company ? (
                <PersonaWithTooltip
                  imageUrl={
                    company?.logo || company?.twitter_avatar || undefined
                  }
                  organizationSlug={organizationSlug}
                  persona={{
                    data: {
                      company: company,
                    },
                    imageUrl:
                      company?.logo || company?.twitter_avatar || undefined,

                    personaName:
                      company?.name ||
                      company?.legalName ||
                      company?.domains?.[0].domain,
                  }}
                  isCompany
                />
              ) : (
                <Persona
                  text={person.name || undefined}
                  imageInitials={getInitialsFromName(
                    person?.name || person?.share?.name,
                  )}
                  secondaryText={person.email}
                  size={PersonaSize.size32}
                  hidePersonaDetails={true}
                />
              )}
            </div>
          );
        },
      },
      {
        key: 'email',
        name: 'Email',
        minWidth: 100,
        maxWidth: 200,
        fieldName: 'email',
        onRender: (person: PersonWithKey) => (
          <div style={{ position: 'relative', top: '-.5rem' }}>
            {person.name || person.share?.name ? (
              <Text
                block
                style={{
                  fontWeight: FontWeights.semibold,
                  lineHeight: '1.75rem',
                }}
              >
                {person.name || person.share?.name}
              </Text>
            ) : null}
            <Text
              block
              style={{
                lineHeight:
                  person?.name || person.share?.name ? '.5rem' : '1.75rem',
                paddingTop:
                  person?.name || person.share?.name ? undefined : '.5rem',
                fontWeight:
                  person?.name || person.share?.name
                    ? FontWeights.regular
                    : FontWeights.semibold,
              }}
            >
              {person.email || person.share?.email}
            </Text>
          </div>
        ),
      },
      {
        key: 'shared',
        name: 'Shared',
        minWidth: 100,
        maxWidth: 200,
        fieldName: 'share',
        onRender: (share: MeetingPlanShare) => {
          return (
            <>
              {share ? (
                <>
                  <Text
                    block
                    style={{ paddingLeft: '16px', position: 'relative' }}
                  >
                    <FontIcon
                      aria-label="Shared"
                      style={{
                        color: 'green',
                        display: 'inline-block',
                        height: '1rem',
                        width: '1rem',
                        position: 'absolute',
                        top: '.15rem',
                        left: '-8px',
                      }}
                      iconName="SkypeCheck"
                    />
                    <Text>
                      Shared{' '}
                      <StyledDateTime
                        displayComponents={['date']}
                        dateTime={share.sharedAt}
                      />
                    </Text>
                    <Text block>
                      <AsyncLink
                        onClick={async () => {
                          const token = await getAccessTokenSilently();
                          const result =
                            await MeetingflowsApiClient.deleteShare(
                              organizationSlug,
                              meetingPlan.id,
                              share.email,
                              {
                                headers: { Authorization: `Bearer ${token}` },
                              },
                            );

                          if (result.status === 204) {
                            refetchMeetingPlanShares();
                            toast.success(`Access to Meetingflow was removed`);
                          } else {
                            toast.error(`Unable to remove access`);
                          }
                        }}
                      >
                        Remove Access
                      </AsyncLink>
                    </Text>
                  </Text>
                </>
              ) : null}
            </>
          );
        },
      },
    ],
    [
      getAccessTokenSilently,
      meetingPlan.companies,
      meetingPlan.id,
      organizationSlug,
      refetchMeetingPlanShares,
    ],
  );

  if (meetingPlanSharesLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div style={{ margin: '1rem 0' }}>
        <Text
          block
          variant="medium"
          style={{ fontWeight: FontWeights.semibold, margin: '0 0 .5rem 0' }}
        >
          {onlyGuests ? 'Guest' : 'Creator, Collaborator, and Guest'} access
        </Text>

        <StyledDetailsList
          items={meetingPlanShares || EMPTY_ARRAY}
          columns={columns}
          selectionMode={SelectionMode.none}
          isHeaderVisible={false}
          compact
          noDataMessage={`There are no ${
            onlyGuests
              ? 'Guests'
              : 'additional Creators, Collaborators, or Guests'
          } who have access to this Meetingflow. Invite others using the 'Share' menu at the top of a Meetingflow.`}
        />
      </div>
    </>
  );
};

export default ShareDialogAccessControl;
