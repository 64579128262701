import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { mergeStyleSets } from '@fluentui/react';

type NotificationsBulletProps = {
  numberOfNotifications: number | null | undefined;
};

export const NotificationsBullet = ({
  numberOfNotifications,
}: NotificationsBulletProps) => {
  const styles = mergeStyleSets({
    bullet: {
      backgroundColor: DEALROOMS_COLORS.notificationBubble,
      color: DEALROOMS_COLORS.white,
      borderRadius: '0.7rem',
      padding: '0.08rem 0.3rem',
      lineHeight: '1rem',
      fontWeight: '600',
    },
  });

  if (!numberOfNotifications) return null;

  return <div className={styles.bullet}>{numberOfNotifications}</div>;
};
