import {
  FontIcon,
  FontSizes,
  FontWeights,
  mergeStyles,
  NeutralColors,
  TeachingBubble,
  useTheme,
} from '@fluentui/react';
import React, { useState } from 'react';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';

interface AddResourceChipProps {
  headlineText?: string;
  onClick: () => void;
  iconOnly?: boolean;
}

export const AddResourceChip = ({
  onClick,
  headlineText = 'Add resources to your plan',
  iconOnly,
}: AddResourceChipProps) => {
  const theme = useTheme();
  const { isDark } = useLightOrDarkMode();
  const [coachmarkVisible, setCoachmarkVisible] = useState<boolean>(false);
  const coachmarkTarget = React.useRef<HTMLDivElement>(null);

  const chipStyles = mergeStyles({
    display: 'inline-block',
    height: '1.5rem',
    backgroundColor: isDark
      ? MEETINGFLOW_COLORS.orangeDark
      : MEETINGFLOW_COLORS.orangeDark,
    borderRadius: '.75rem',
    lineHeight: '1.25rem',
    fontWeight: FontWeights.semibold,
    color: isDark
      ? MEETINGFLOW_COLORS.purpleLight
      : MEETINGFLOW_COLORS.purpleDark,
    cursor: 'pointer',
    transition: '.3s ease-in-out all',
    ':hover': {
      backgroundColor: theme.palette.themePrimary,
      color: NeutralColors.white,
      button: {
        transition: '.3s ease-in-out all',
        backgroundColor: NeutralColors.white,
        color: theme.palette.themePrimary,
      },
    },
    boxSizing: 'border-box',
    minWidth: '2.5rem',
    textAlign: 'center',

    span: {
      display: 'inline-block',
      width: '100%',
    },
    i: {
      fontSize: FontSizes.xLarge,
      position: 'relative',
      top: '2px',
      transform: 'rotate(45deg)',
    },
  });

  return (
    <>
      <div
        className={chipStyles}
        onClick={onClick}
        ref={coachmarkTarget}
        onMouseOver={() => setCoachmarkVisible(true)}
        onMouseOut={() => setCoachmarkVisible(false)}
      >
        <span>
          <FontIcon iconName="Attach" style={{ color: 'white' }} />
          {!iconOnly ? headlineText : null}
        </span>
      </div>
      {coachmarkVisible ? (
        <TeachingBubble
          target={coachmarkTarget.current}
          //@ts-ignore
          headline={<span style={{ color: 'white' }}>{headlineText}</span>}
          onDismiss={() => setCoachmarkVisible(false)}
          ariaDescribedBy="example-description1"
          ariaLabelledBy="example-label1"
        >
          <span style={{ color: 'white' }}>
            You can add your presentation deck, related documents, or any other
            relevant file or link.
          </span>
        </TeachingBubble>
      ) : null}
    </>
  );
};
