import { useAuth0 } from '@auth0/auth0-react';
import { mergeStyleSets, Spinner, Stack, Text } from '@fluentui/react';
import { capitalize } from 'lodash';
import { useEffect, useMemo, useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { useUserProfile } from '../../Hooks/useProfile';
import { useTitle } from '../../Hooks/useTitle';
import { ListOrganizationsData } from '@meetingflow/common/Api/data-contracts';
import { OrganizationsApiClient } from '../../Services/NetworkCommon';
import { DEALROOMS_COLORS } from '../../Themes/Themes';
import { OrganizationPersona } from '../OrganizationPersona';
import { DecisionSiteHomeContainer } from './DecisionSiteHomeContainer';
import { DecisionSiteHomePlaceholder } from './DecisionSiteHomePlaceholder';

export const DecisionSiteHome = () => {
  const { user: auth0User, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const { user } = useUserProfile();

  useTitle('Home');

  const styles = mergeStyleSets({
    content: {
      gap: '1rem',
      overflow: 'auto',
      width: '100%',
    },
    sectionTitle: {
      color: DEALROOMS_COLORS.themePrimary,
      fontWeight: 400,
    },
    workspacesContainer: {
      flexWrap: 'wrap',
      gap: '2rem',
      padding: '1rem',
    },
    workspaceItem: {
      minWidth: '150px',
      position: 'relative',
      padding: '1rem',
      margin: '0',
      borderRadius: '4px',
      border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
      boxShadow: '0 2px 2px 0 #00000005',
      ':hover': {
        cursor: 'pointer',
        backgroundColor: DEALROOMS_COLORS.neutralLighter,
      },
    },
  });

  // Build welcome message using user's first name from various possible sources
  const headerTitle = useMemo(() => {
    const generalTitle = `Welcome to ${import.meta.env.VITE_SITE_NAME}`;
    const userName =
      user?.firstName ||
      user?.name?.split(' ')[0] ||
      auth0User?.nickname ||
      auth0User?.name?.split(' ')[0];

    return userName ? `${generalTitle}, ${capitalize(userName)}` : generalTitle;
  }, [user, auth0User]);

  // Fetch organizations data
  const [organizations, setOrganizations] = useState<ListOrganizationsData | null>(null);
  const [organizationsAreLoading, setOrganizationsAreLoading] = useState(true);

  const fetchOrgData = useCallback(async () => {
    try {
      setOrganizationsAreLoading(true);
      const token = await getAccessTokenSilently();
      const response = await OrganizationsApiClient.listOrganizations(
        {
          entitlement: 'DEAL_ROOM'
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      setOrganizations(response.data);
    } catch (err) {
      console.error('Error fetching organizations:', err);
    } finally {
      setOrganizationsAreLoading(false);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    fetchOrgData();
  }, [fetchOrgData]);

  // Auto-navigate if user only has one organization
  useEffect(() => {
    if (organizations?.length === 1) {
      navigate(`/organization/${organizations[0].slug}`);
    }
  }, [navigate, organizations]);

  return (
    <DecisionSiteHomeContainer
      title={headerTitle}
      description={`To get started using ${import.meta.env.VITE_SITE_NAME}, select one of your workspaces.`}
    >
      <Stack className={styles.content}>
        {(() => {
          if (organizationsAreLoading) {
            return <Spinner />;
          }

          if (!organizations || !organizations.length) {
            return <DecisionSiteHomePlaceholder />;
          }

          return (
            <>
              <Text className={styles.sectionTitle} block variant="large">
                Your Workspaces
              </Text>
              <Stack className={styles.workspacesContainer} horizontal>
                {organizations.map((organization) => (
                  <Stack.Item
                    grow={1}
                    key={organization.id}
                    onClick={() => navigate(`/organization/${organization.slug}`)}
                    className={styles.workspaceItem}
                  >
                    <OrganizationPersona organization={organization} />
                  </Stack.Item>
                ))}
              </Stack>
            </>
          );
        })()}
      </Stack>
    </DecisionSiteHomeContainer>
  );
};

export default DecisionSiteHome;
