import {
  mergeStyleSets,
  Text,
  TextField,
  IconButton,
  Stack,
} from '@fluentui/react';
import classNames from 'classnames';
import { useState, useCallback, useEffect } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useDealRoomId } from '../../../../Hooks/useDealRoomId';
import { useOrganizationSlug } from '../../../../Hooks/useOrganizationSlug';
import { DealRoomsApiClient } from '../../../../Services/NetworkCommon';
import { InfoSection } from './InfoSection';
import { getCardBasicStructureStyles } from './InfoSectionUtils';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { DealRoomCommandBarButton } from '../DealRoomButton';
import { AcceptIconProps, EditIconProps } from '../../../../utils/iconProps';
import toast from 'react-hot-toast';

// Define the structure for callout card form data
interface KeyFactData {
  id: number;
  title: string;
  value: string;
  editableByBuyer: boolean;
}

const styles = mergeStyleSets({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gap: '1rem',
    width: '100%',
  },
});

export const DealRoomKeyFacts = () => {
  const [keyFacts, setKeyFacts] = useState<KeyFactData[]>([]);
  const { getAccessTokenSilently } = useAuth0();
  const organizationSlug = useOrganizationSlug();
  const dealRoomId = useDealRoomId();

  const loadKeyFacts = useCallback(async () => {
    if (!organizationSlug || !dealRoomId) {
      return;
    }

    const token = await getAccessTokenSilently();
    const response = await DealRoomsApiClient.listKeyFacts(
      organizationSlug,
      dealRoomId,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    setKeyFacts(response.data as KeyFactData[]);
  }, [organizationSlug, dealRoomId, getAccessTokenSilently, setKeyFacts]);

  useEffect(() => {
    loadKeyFacts();
  }, [loadKeyFacts]);

  if (keyFacts.length === 0) {
    return null;
  }

  if (!organizationSlug || !dealRoomId) {
    return null;
  }

  return (
    <InfoSection headerIconName="Stars" title="Key Details">
      <div className={styles.container}>
        {keyFacts.map((keyFact) => (
          <DealRoomKeyFactCard
            key={keyFact.id}
            organizationSlug={organizationSlug}
            dealRoomId={dealRoomId}
            keyFact={keyFact}
          />
        ))}
      </div>
    </InfoSection>
  );
};

const cardStyles = mergeStyleSets({
  ...getCardBasicStructureStyles('PRIMARY'),
  card: {
    ...getCardBasicStructureStyles('PRIMARY').card,
    padding: '1rem',
    gap: '.25rem',
  },
  header: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '1.5rem',
  },
  title: {
    color: DEALROOMS_COLORS.themePrimary,
  },
  textField: {
    margin: '2px 0',
    '.ms-TextField-fieldGroup': {
      height: 'fit-content',
      borderRadius: '.25rem',
    },
    input: {
      fontSize: '1.5rem',
      lineHeight: '1.5rem',
      color: DEALROOMS_COLORS.themePrimary,
      borderRadius: '.25rem',
      padding: '.25rem 1rem',
    },
  },
  description: {
    fontSize: '2rem',
    lineHeight: '2.5rem',
    color: DEALROOMS_COLORS.themePrimary,
    selectors: {
      '&.long-text': {
        fontSize: '1rem',
        lineHeight: '1.5rem',
      },
    },
  },
});

const DealRoomKeyFactCard = ({
  organizationSlug,
  dealRoomId,
  keyFact,
}: {
  organizationSlug: string;
  dealRoomId: number;
  keyFact: KeyFactData;
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [editing, { setTrue: setEditing, setFalse: setNotEditing }] =
    useBoolean(false);
  const [textValue, setTextValue] = useState('');

  useEffect(() => {
    setTextValue(keyFact.value || '');
  }, [keyFact.value]);

  const handleSave = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();

      await DealRoomsApiClient.updateKeyFact(
        organizationSlug,
        dealRoomId,
        keyFact.id,
        {
          value: textValue,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      toast.success('Updated successfully');
    } catch (error) {
      toast.error('Failed to update');
    }
  }, [
    getAccessTokenSilently,
    textValue,
    organizationSlug,
    dealRoomId,
    keyFact,
  ]);

  return (
    <div className={classNames(cardStyles.card)}>
      <div className={cardStyles.header}>
        <Text className={cardStyles.title}>{keyFact.title}</Text>
        {keyFact.editableByBuyer && !editing && (
          <IconButton
            className="edit"
            iconProps={{
              ...EditIconProps,
              iconName: 'Edit',
              styles: {},
            }}
            onClick={setEditing}
          />
        )}
      </div>
      {editing ? (
        <TextField
          className={cardStyles.textField}
          placeholder="Insert text"
          value={textValue}
          onChange={(event, newValue) => {
            setTextValue(newValue ?? '');
          }}
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              e.preventDefault();
              e.stopPropagation();
              setNotEditing();
            }
          }}
        />
      ) : (
        <Stack horizontal tokens={{ childrenGap: '.5rem' }}>
          <Text
            block
            className={classNames(cardStyles.description, {
              'long-text': textValue.length > 12,
            })}
          >
            {textValue || 'N/A'}
          </Text>
        </Stack>
      )}

      {keyFact.editableByBuyer && (
        <>
          {editing ? (
            <DealRoomCommandBarButton
              text="Save"
              customClasses="save"
              buttonStyleType="COMMAND_BAR"
              iconProps={AcceptIconProps}
              onClick={() => {
                handleSave();
                setNotEditing();
              }}
            />
          ) : null}
        </>
      )}
    </div>
  );
};
