import { create } from 'zustand';
import {
  AssistancePreference,
  CommunicationPreference,
  ConsumptionPreference,
} from '@meetingflow/common/Api/data-contracts';

type buyerPrefsKeys =
  | 'assistancePreference'
  | 'consumptionPreference'
  | 'communicationPreference'
  | 'phoneNumber';

type Store = {
  isLoading: boolean;
  selectedAvatar: File | null;
  uploadedImgAsPath: string | null;
  userProfileData: {
    firstName?: string;
    lastName?: string;
    timezone?: string;
  };
  // userProfileData: {[key: string]: string | null},
  buyerPrefs: {
    assistancePreference?: AssistancePreference | null;
    consumptionPreference?: ConsumptionPreference | null;
    communicationPreference?: CommunicationPreference | null;
    phoneNumber?: string | null;
  };

  setIsLoading: (value: boolean) => void;
  setSelectedAvatar: (file: File | null) => void;
  setUploadedImgAsPath: (path: string | null) => void;
  setUserProfileData: (key: string, value: string) => void;
  resetUserProfileData: () => void;
  setBuyerPrefs: (key: buyerPrefsKeys, value: string) => void;
  resetBuyerPrefs: () => void;
  resetAllSettings: () => void;
};

const initialState = {
  isLoading: false,
  selectedAvatar: null,
  uploadedImgAsPath: null,
  userProfileData: {},
  buyerPrefs: {},
};

const useStore = create<Store>((set) => ({
  ...initialState,

  setIsLoading: (currentValue: boolean) =>
    set((state) => ({
      isLoading: currentValue,
    })),

  setSelectedAvatar: (file: File | null) =>
    set((state) => ({
      selectedAvatar: file,
    })),

  setUploadedImgAsPath: (path: string | null) =>
    set((state) => ({
      uploadedImgAsPath: path,
    })),

  setUserProfileData: (key, value) =>
    set((state) => ({
      userProfileData: {
        ...state.userProfileData,
        [key]: value,
      },
    })),

  resetUserProfileData: () =>
    set((state) => ({
      userProfileData: {},
    })),

  setBuyerPrefs: (key, value) =>
    set((state) => ({
      buyerPrefs: {
        ...state.buyerPrefs,
        [key]: value,
      },
    })),

  resetBuyerPrefs: () =>
    set((state) => ({
      buyerPrefs: {},
    })),

  resetAllSettings: () =>
    set((state) => ({
      ...initialState,
    })),
}));

export default useStore;
