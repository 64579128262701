import { Autocomplete, AutocompleteProps } from '@mui/material';
import { Company } from '@meetingflow/common/Api/data-contracts';
import { DSTextField } from '../DS';
import {
  DSCompanyPickerMenuItemRoot,
  DSCompanyPickerRoot,
} from './DSCompanyPicker.styles';

interface DSCompanyPickerProps {
  label?: string;
  companies: Company[];
  onChange?: (value: Company[]) => void;
  autoCompleteProps?: Omit<
    AutocompleteProps<Company, boolean, boolean, boolean>,
    'options' | 'renderInput'
  >;
}

export const DSCompanyPicker = ({
  label,
  companies,
  onChange,
  autoCompleteProps,
  ...props
}: DSCompanyPickerProps) => {
  return (
    <DSCompanyPickerRoot>
      <Autocomplete
        options={companies}
        multiple
        renderInput={(params) => <DSTextField {...params} label={label} />}
        renderOption={(props, option) => (
          <DSCompanyPickerMenuItemRoot {...props}>
            <strong>{option.name}</strong>
            {option.isInternal && <span>Internal</span>}
          </DSCompanyPickerMenuItemRoot>
        )}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          return (
            option.name?.slice(0, 25) ??
            option.legalName?.slice(0, 25) ??
            option.id?.toString()
          );
        }}
        onChange={(_, value) => {
          // @ts-ignore
          onChange?.(value);
        }}
        size="small"
        {...autoCompleteProps}
      />
    </DSCompanyPickerRoot>
  );
};

export default DSCompanyPicker;
