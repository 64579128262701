import { useAuth0 } from '@auth0/auth0-react';
import { Portal } from '@blueprintjs/core';
import { Text } from '@fluentui/react';
import { FontSizes, FontWeights, NeutralColors } from '@fluentui/theme';
import { Tag } from '@meetingflow/common/Api/data-contracts';
import { DateTime } from 'luxon';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { BaseRange, Editor, Path, Point, Range, Transforms } from 'slate';
import { ReactEditor, useSlateSelection } from 'slate-react';
import { insertTag } from '../../Components/Collab/Helpers/EditorHelpers';
import { OrganizationTagsQuery } from '../../QueryNames';
import { TagsApiClient } from '../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { useLightOrDarkMode } from '../useLightOrDarkMode';

const tagRegexp = /^#(?<tag>[a-zA-Z0-9]+([_-][a-zA-Z0-9])*)$/;

export type UseTagsParams = {
  organizationSlug: string;
  editor: Editor;
  allowTags?: boolean;
};
export const useTags = ({
  organizationSlug,
  editor,
  allowTags,
}: UseTagsParams) => {
  const { getAccessTokenSilently } = useAuth0();
  const { isDark } = useLightOrDarkMode();

  const ref = useRef<HTMLDivElement>(null);
  const [target, setTarget] = useState<BaseRange | undefined>();
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState('');

  const selection = useSlateSelection();

  const { data: tagList, refetch: refetchTags } = useQuery(
    OrganizationTagsQuery(organizationSlug!, search),
    async () => {
      const token = await getAccessTokenSilently();
      return TagsApiClient.listTags(
        {
          organizationSlug,
          q: search,
          limit: 5,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    },
    {
      enabled: !!allowTags && !!search && !!target,
      onSettled: (data, error) => {
        if (error || data?.data.length) {
          setIndex(0);
        } else if (data?.data && index >= data.data.length) {
          setIndex(data.data.length - 1);
        } else if (index < 0) {
          setIndex(0);
        }
      },
    },
  );

  const tags: Tag[] = useMemo(() => {
    if (!search) {
      return tagList?.data ?? [];
    }

    const searchId = search.toLowerCase();

    if (tagList?.data && tagList.data.some((t) => t.id === searchId)) {
      return tagList.data;
    }

    return [
      {
        id: searchId,
        createdAt: DateTime.now().toISO(),
        updatedAt: null,
        label: search,
      },
      ...(tagList?.data ?? []),
    ];
  }, [search, tagList?.data]);

  useEffect(() => {
    if (!!allowTags && !!search && !!target) {
      refetchTags();
    }
  }, [allowTags, refetchTags, search, target]);

  useEffect(() => {
    if (index < 0) {
      setIndex(0);
    }
  }, [index]);

  useLayoutEffect(() => {
    if (target && ref.current && allowTags && search && tags.length) {
      const el = ref.current;
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();
      el.style.top = `${rect.top + window.pageYOffset + 24}px`;
      el.style.left = `${rect.left + window.pageXOffset}px`;
    }
  }, [allowTags, editor, search, tags.length, target]);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (!allowTags) {
        return;
      }

      if (target && search && tags?.length) {
        if (!tags) {
          return;
        }

        switch (event.key) {
          case 'ArrowDown': {
            event.preventDefault();
            setIndex((previous) =>
              tags.length - 1 > previous ? previous + 1 : previous,
            );
            return true;
          }
          case 'ArrowUp': {
            event.preventDefault();
            setIndex((previous) => (previous > 0 ? previous - 1 : previous));
            return true;
          }
          case 'Tab':
          case 'Enter': {
            const tag = tags[index];

            if (!tag) {
              return;
            }

            event.preventDefault();

            Transforms.select(editor, target);
            insertTag(editor, tag);
            setTarget(undefined);
            setSearch('');

            return true;
          }
          case 'Escape':
            event.preventDefault();
            setTarget(undefined);
            setSearch('');
            setIndex(0);
            return true;
        }
      }
    },
    [allowTags, editor, index, search, tags, target],
  );

  useEffect(() => {
    if (!allowTags) {
      setTarget(undefined);
      setSearch('');
      setIndex(0);
      return;
    }

    if (!selection) {
      setTarget(undefined);
      setSearch('');
      setIndex(0);
      return;
    }

    if (!Range.isCollapsed(selection)) {
      setTarget(undefined);
      setSearch('');
      setIndex(0);
      return;
    }

    const [selectionStart, selectionEnd] = Range.edges(selection);
    let start = selectionStart;
    let end = selectionEnd;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (!start) {
        break;
      }

      const previousCharacterPoint = Editor.before(editor, start, {
        unit: 'character',
      });

      if (!previousCharacterPoint) {
        break;
      }

      if (!Path.equals(previousCharacterPoint.path, selectionStart.path)) {
        break;
      }

      const previousCharacter = Editor.string(
        editor,
        Editor.range(editor, previousCharacterPoint, start),
      );

      if (!/[-_a-zA-Z0-9#]/.test(previousCharacter)) {
        break;
      }

      start = previousCharacterPoint;
    }

    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (!end) {
        break;
      }

      const nextCharacterPoint = Editor.after(editor, end, {
        unit: 'character',
      });

      if (!nextCharacterPoint) {
        break;
      }

      if (!Path.equals(selectionEnd.path, nextCharacterPoint.path)) {
        break;
      }

      const nextCharacter = Editor.string(
        editor,
        Editor.range(editor, end, nextCharacterPoint),
      );

      if (!/[-_a-zA-Z0-9#]/.test(nextCharacter)) {
        break;
      }

      end = nextCharacterPoint;
    }

    const matchedString = Editor.string(
      editor,
      Editor.range(editor, start, end),
    );

    if (tagRegexp.test(matchedString)) {
      const match = tagRegexp.exec(matchedString);
      const range = Editor.range(editor, start, end);

      setTarget((previous) =>
        previous && Range.equals(range, previous) ? previous : range,
      );
      setSearch(match!.groups!.tag!);
      setIndex(0);
    } else {
      setTarget(undefined);
      setSearch('');
      setIndex(0);
    }
  }, [allowTags, editor, selection]);

  return {
    onKeyDown,
    tagSuggestions:
      allowTags && target ? (
        <Portal>
          <div
            ref={ref}
            style={{
              top: '-9999px',
              left: '-9999px',
              position: 'absolute',
              zIndex: 1,
              padding: '3px',
              borderRadius: '.25rem',
              boxShadow: '0 1px 5px rgba(0,0,0,.2)',
              backgroundColor: isDark ? NeutralColors.gray200 : 'white',
              color: isDark ? 'white' : 'black',
              maxWidth: '17rem',
            }}
            data-cy="tag-suggestions-portal"
          >
            {tags.map((tag, i) => (
              <div
                key={tag.id}
                style={{
                  padding: '0.25rem 0.5rem',
                  borderRadius: i === index ? '3px' : undefined,
                  transition: '.3s border-radius ease-in-out',
                  borderBottom:
                    i !== index
                      ? `1px solid ${
                          isDark ? NeutralColors.gray170 : NeutralColors.gray20
                        }`
                      : undefined,
                  cursor: 'pointer',
                  color:
                    i === index || isDark
                      ? NeutralColors.gray30
                      : NeutralColors.gray180,
                  background:
                    i === index
                      ? isDark
                        ? MEETINGFLOW_COLORS.purpleDark
                        : MEETINGFLOW_COLORS.purpleGrey
                      : 'transparent',
                }}
                onClick={() => {
                  if (index !== i) {
                    setIndex(i);
                  }
                }}
                onDoubleClick={() => {
                  Transforms.select(editor, target);
                  insertTag(editor, tag);
                  setTarget(undefined);
                  setSearch('');
                  setIndex(0);
                }}
              >
                <Text
                  style={{
                    fontSize: FontSizes.small,
                    fontWeight: FontWeights.semibold,
                  }}
                  block
                >
                  #{tag.label}
                </Text>
                <Text style={{ fontSize: FontSizes.xSmall }} block>
                  #{tag.id}
                </Text>
              </div>
            ))}
            {!search ? (
              <span
                style={{
                  display: 'block',
                  padding: 'calc(.25rem + 3px)',
                  fontSize: FontSizes.small,
                  color: isDark ? NeutralColors.gray100 : NeutralColors.gray130,
                  backgroundColor: isDark
                    ? NeutralColors.gray180
                    : NeutralColors.gray10,
                }}
              >
                <span
                  style={{
                    fontWeight: FontWeights.bold,
                    color: MEETINGFLOW_COLORS.teal,
                    textTransform: 'uppercase',
                  }}
                >
                  Hint:
                </span>{' '}
                Starting typing for tag suggestions...
              </span>
            ) : null}
          </div>
        </Portal>
      ) : null,
  };
};
