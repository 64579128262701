import {
  FontWeights,
  IconButton,
  mergeStyles,
  NeutralColors,
} from '@fluentui/react';
import { SlateLink } from '@meetingflow/common/Types/Slate';
import { Editor, Node, Range } from 'slate';
import { useSlate } from 'slate-react';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import {
  getParentBlock,
  insertLink,
  isBlockActive,
} from '../Helpers/EditorHelpers';

export type LinkButtonProps = {
  hint?: string;
};
export const LinkButton = ({ hint }: LinkButtonProps) => {
  const editor = useSlate();
  const { isDark } = useLightOrDarkMode();

  const isActive = isBlockActive(editor, 'link');

  const iconButtonClass = mergeStyles({
    backgroundColor: isActive
      ? isDark
        ? MEETINGFLOW_COLORS.purpleSecondary
        : MEETINGFLOW_COLORS.white
      : 'transparent',
    color: isActive
      ? isDark
        ? MEETINGFLOW_COLORS.white
        : MEETINGFLOW_COLORS.purpleSecondary
      : NeutralColors.gray30,
    width: '1.75rem',
    height: '1.75rem',
    i: {
      fontWeight: FontWeights.semibold,
    },

    ':hover': {
      backgroundColor: MEETINGFLOW_COLORS.purpleSecondary,
      color: 'white !important',
    },
  });

  const title =
    `${isActive ? 'Update' : 'Insert'} link` + (hint ? ` (${hint})` : '');

  return (
    <IconButton
      iconProps={{ iconName: 'AddLink' }}
      className={iconButtonClass}
      title={title}
      alt={title}
      data-meetingflow-is-active={isActive}
      onClick={(event) => {
        event.preventDefault();

        const { selection } = editor;

        const activeLinkNode = getParentBlock<SlateLink>(editor, {
          type: 'link',
        });

        const activeLink = activeLinkNode?.[0];

        editor
          .getLinkContent(
            activeLink
              ? { href: activeLink.href, text: Node.string(activeLink) }
              : selection && !Range.isCollapsed(selection)
              ? { text: Editor.string(editor, selection) }
              : undefined,
          )
          .then((content) => {
            if (content.href) {
              insertLink(editor, content.href, content.text);
            }
          })
          .catch();
      }}
    />
  );
};
