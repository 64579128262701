import { Autocomplete, AutocompleteProps } from '@mui/material';
import { Contact } from '@meetingflow/common/Api/data-contracts';
import { DSTextField } from '../DS';
import {
  DSContactPickerMenuItemRoot,
  DSContactPickerRoot,
} from './DSContactPicker.styles';

interface DSContactPickerProps {
  label?: string;
  contacts: Contact[];
  onChange?: (value: Contact[]) => void;
  autoCompleteProps?: Omit<
    AutocompleteProps<Contact, boolean, boolean, boolean>,
    'options' | 'renderInput'
  >;
}

export const DSContactPicker = ({
  label,
  contacts,
  onChange,
  autoCompleteProps,
  ...props
}: DSContactPickerProps) => {
  return (
    <DSContactPickerRoot>
      <Autocomplete
        options={contacts}
        multiple
        renderInput={(params) => <DSTextField {...params} label={label} />}
        renderOption={(props, option) => (
          <DSContactPickerMenuItemRoot {...props}>
            <strong>{option.name}</strong>
            <span>{option.email}</span>
          </DSContactPickerMenuItemRoot>
        )}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          return `${option.name} ${option.email}`;
        }}
        onChange={(_, value) => {
          // @ts-ignore
          onChange?.(value);
        }}
        size="small"
        slotProps={{
          popper: {
            style: { width: 'fit-content', maxWidth: '300px' },
          },
        }}
        {...autoCompleteProps}
      />
    </DSContactPickerRoot>
  );
};

export default DSContactPicker;
