import { useAuth0 } from '@auth0/auth0-react';
import {
  FontIcon,
  FontSizes,
  FontWeights,
  ITooltipHostStyles,
  Icon,
  NeutralColors,
  Persona,
  PersonaSize,
  Text,
  TooltipHost,
  mergeStyles,
} from '@fluentui/react';
import {
  CalendarEvent,
  PostMeetingflowPayload,
} from '@meetingflow/common/Api/data-contracts';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import {
  AsyncDiv,
  AsyncSpan,
} from '../../../Components/HOC/AsyncIntrinsicElement';
import { isAxiosErrorResponse } from '../../../Helpers/AxiosHelpers';
import useBreakpoints from '../../../Hooks/useBreakpoints';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { useModifierAwareNavigate } from '../../../Hooks/useModifierAwareNavigate';
import {
  MeetingPlanQuery,
  OrganizationMeetingPlansQuery,
  OrganizationMeetingsHappeningSoon,
  OrganizationUpcomingMeetings,
} from '../../../QueryNames';
import { MeetingflowsApiClient } from '../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { MeetingPlanViewCreateButton } from '../../Common/MeetingPlanViewCreateButton';
import { CompanyCard } from '../../MeetingPlans/MeetingPlanAttendees';
import StyledDateTime from '../../StyledDateTime';
import { titleCase } from '@meetingflow/common/StringHelpers';
import { useOrganization } from '../../../Hooks/useOrganization';
import { GroupBy } from '@meetingflow/common/ArrayHelpers';

interface EventCardProps {
  event: CalendarEvent;
  organizationSlug: string;
  onClick?: () => void;
  disableCreateViewMeetingflowOnClick?: boolean;
  showCompanies?: boolean;
  showTextLabelOnCreateButton?: boolean;
  muteIfNoMeetingflow?: boolean;
  showTimesOnly?: boolean;
  createMeetingflowOnClick?: boolean;
  allowScheduleCallRecording?: boolean;
  cardTitleAttr?: string;
  hideNewMeetingflowButton?: boolean;
  hideConferenceIcon?: boolean;
}

const EventCard = ({
  event,
  organizationSlug,
  onClick,
  disableCreateViewMeetingflowOnClick = false,
  showCompanies,
  showTextLabelOnCreateButton = false,
  muteIfNoMeetingflow,
  showTimesOnly = false,
  createMeetingflowOnClick = false,
  allowScheduleCallRecording = true,
  cardTitleAttr,
  hideNewMeetingflowButton = false,
  hideConferenceIcon = false,
}: EventCardProps) => {
  const { isDark } = useLightOrDarkMode();
  const navigate = useModifierAwareNavigate();
  const breakpoints = useBreakpoints();
  const { user, getAccessTokenSilently } = useAuth0();
  const client = useQueryClient();
  const appInsights = useAppInsightsContext();
  const { organization, canCreatePlans } = useOrganization();
  const eventIsInPast = DateTime.now() > DateTime.fromISO(event.endTime);

  const eventIsNow =
    DateTime.now() > DateTime.fromISO(event.startTime) &&
    DateTime.now() < DateTime.fromISO(event.endTime);

  const eventIsSoon =
    DateTime.fromISO(event.startTime).diffNow('minutes').minutes < 60;

  const eventIsNowOrSoon = !eventIsInPast && (eventIsNow || eventIsSoon);

  const calendarEventClass = mergeStyles({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    flex: '1 1 auto',
    height: 'auto',
    minHeight: '32px',
    width: '100%',
    paddingBottom: '.25rem',
    padding: '.25rem',
    backgroundColor: 'transparent',
    borderRadius: '.25rem',
    cursor:
      onClick || createMeetingflowOnClick ? 'pointer' : 'default !important',
    margin: '0',
    transition: '.3s ease-in-out all',

    ':hover': {
      backgroundColor:
        onClick || createMeetingflowOnClick
          ? isDark
            ? NeutralColors.gray210
            : MEETINGFLOW_COLORS.purpleLighter
          : undefined,
      img: {
        filter: 'grayscale(0%) !important',
        opacity: `1 !important`,
      },
      'div.event-icons': {
        opacity: `1 !important`,
      },

      '.event-title': {
        color: isDark ? NeutralColors.white : undefined,
      },
    },

    '.card-content': {
      display: 'flex',
      flexDirection: 'column',
    },

    '.meetingflow-icons': {
      position: 'absolute',
      top: '.25rem',
      right: '.75rem',
      transition: '.3s ease-in-out all',
      opacity: '.5',
      height: '24px',

      'i, .ms-Button': {
        cursor: 'default',
        display: 'inline-block',
        height: '22px',
        width: '22px',
        fontSize: '14px',
        lineHeight: '22px',
        backgroundColor: isDark
          ? NeutralColors.gray140
          : MEETINGFLOW_COLORS.purpleMedium,
        border: `1px solid ${
          isDark ? 'transparent' : MEETINGFLOW_COLORS.white
        }`,
        color: MEETINGFLOW_COLORS.white,
        borderRadius: '.75rem',
        textAlign: 'center',
        position: 'relative',
        top: '0',
      },
    },

    '.event-details': {
      display: 'grid',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      opacity: muteIfNoMeetingflow ? '.5' : undefined,
      transition: '.3s ease-in-out all',

      ':hover': {
        opacity: '1',
      },

      '.event-title': {
        display: 'block',
        fontSize: FontSizes.medium,
        fontWeight: FontWeights.semibold,
        height: '20px',
        lineHeight: '20px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        color: isDark ? NeutralColors.white : undefined,
        paddingRight: '2rem',

        a: {
          color: 'unset',
          ':hover': {
            color: 'unset',
            textDecoration: 'unset',
          },
        },
      },
      '.event-datetime': {
        display: 'block',
        fontSize: FontSizes.small,
        height: '20px',
        lineHeight: '20px',
        position: 'relative',

        span: {
          fontWeight: eventIsNowOrSoon ? FontWeights.bold : FontWeights.regular,
          color: eventIsNowOrSoon ? MEETINGFLOW_COLORS.teal : undefined,
        },
      },
    },

    '.button': {
      width: showTextLabelOnCreateButton && breakpoints.lg ? 'auto' : '2rem',
      zIndex: '15',
      marginRight: '.5rem',
      button: { float: 'right', '.ms-Button-label': { textAlign: 'center' } },
    },
  });

  const recordLinkClass = mergeStyles({
    display: 'inline-block',
    marginRight: '.25rem',
    color: `${MEETINGFLOW_COLORS.white} !important`,
    cursor: 'pointer',
    fontSize: FontSizes.mini,
    fontWeight: FontWeights.semibold,
    textTransform: 'uppercase',
    backgroundColor: MEETINGFLOW_COLORS.purpleGreyLight,
    lineHeight: '.75rem',
    padding: '0 .25rem .15rem .25rem',
    borderRadius: '.5rem',
    transition: '.3s ease-in-out all',
    boxSizing: 'border-box',
    position: 'relative',
    top: '-1px',

    i: {
      marginRight: '.25rem',
      fontWeight: FontWeights.regular,
      position: 'relative',
      top: '.1rem',
    },

    '&.not-scheduled': {
      backgroundColor: isDark
        ? MEETINGFLOW_COLORS.magenta
        : MEETINGFLOW_COLORS.magenta,

      ':hover': {
        backgroundColor: MEETINGFLOW_COLORS.teal,
      },
    },

    '&.scheduled': {
      backgroundColor: isDark
        ? NeutralColors.gray160
        : MEETINGFLOW_COLORS.purpleGrey,
      color: MEETINGFLOW_COLORS.purpleMedium,

      ':hover': {
        backgroundColor: isDark
          ? MEETINGFLOW_COLORS.purpleDark
          : MEETINGFLOW_COLORS.purpleMedium,
        color: MEETINGFLOW_COLORS.white,
      },
    },
  });

  const companyClass = mergeStyles({
    display: 'block',
    marginBottom: '.5rem',
  });

  const companyNameClass = mergeStyles({
    transition: '.3s ease-in-out all',
    color: MEETINGFLOW_COLORS.purplePrimary,
    cursor: 'pointer',
  });

  const attendeeNameClass = mergeStyles({
    marginLeft: '28px',
    marginBottom: '2px',
    color: isDark ? NeutralColors.gray80 : MEETINGFLOW_COLORS.purplePrimary,
    cursor: 'pointer',
    ':hover': {
      textDecoration: 'underline',
    },
  });

  const toolTipHostStyles = useMemo(
    () =>
      ({
        root: {
          display: 'inline-block',
          height: '24px',
          width: '24px',
          marginLeft: '.25rem',
        },
      }) as ITooltipHostStyles,
    [],
  );

  const tooltipCalloutProps = useMemo(
    () => ({
      styles: {
        root: {
          padding: 0,
          color: isDark ? undefined : MEETINGFLOW_COLORS.white,
        },
        calloutMain: {
          padding: '.25rem',
          backgroundColor: isDark ? undefined : MEETINGFLOW_COLORS.white,
          'div.tooltip-content-container': {
            padding: '.25rem',
            backgroundColor: isDark
              ? NeutralColors.gray160
              : MEETINGFLOW_COLORS.purpleUltraSuperLight,
            borderBottomRightRadius: '.15rem',
            borderBottomLeftRadius: '.15rem',
          },
        },
      },
    }),
    [isDark],
  );

  const tooltipHeaderClass = mergeStyles({
    display: 'block',
    fontWeight: FontWeights.semibold,
    fontSize: FontSizes.mini,
    backgroundColor: MEETINGFLOW_COLORS.purpleGrey,
    color: MEETINGFLOW_COLORS.black,
    textTransform: 'uppercase',
    position: 'relative',
    padding: '.25rem',
    borderRadius: '.15rem',
    marginBottom: '.25rem',
  });

  const beforeEnd = event
    ? DateTime.fromISO(event.endTime).diffNow().milliseconds > 0
    : true;

  const createMeetingflow = async (
    e: React.MouseEvent<unknown>,
    scheduleCallRecording = false,
  ) => {
    e.stopPropagation();
    if (!!event?.meetingplanId) {
      navigate(
        `/organization/${organizationSlug}/plan/${event.meetingplanId}`,
        e,
      );
    } else {
      const token = await getAccessTokenSilently();

      let reqBody: PostMeetingflowPayload = { record: scheduleCallRecording };
      if (event) {
        reqBody = {
          source: event.source,
          eventId: event.externalId,
          eventTime: event.startTime,
          record: scheduleCallRecording,
        };
      }

      await toast.promise(
        MeetingflowsApiClient.postMeetingflow(organizationSlug, reqBody, {
          headers: { Authorization: `Bearer ${token}` },
          validateStatus: (code) => code === 201 || code === 302,
        }),
        {
          loading: 'Creating Meetingflow',
          success: (result) => {
            Promise.all([
              client.invalidateQueries(
                OrganizationMeetingPlansQuery(organizationSlug),
              ),
              client.invalidateQueries(
                OrganizationMeetingsHappeningSoon(organizationSlug),
              ),
              client.invalidateQueries(
                OrganizationUpcomingMeetings(organizationSlug),
              ),
            ]);

            client.setQueryData(
              MeetingPlanQuery(organizationSlug, result.data.id),
              result,
            );

            navigate(
              `/organization/${organizationSlug}/plan/${result.data.id}${
                scheduleCallRecording ? '?scheduleCallRecording=true' : ''
              }`,
              e,
            );

            if (result.status === 201) {
              appInsights.trackEvent({
                name: `CREATE_PLAN${event ? '_FOR_EVENT' : '_ADHOC'}`,
                properties: {
                  organizationSlug,
                  source: event ? event?.source : '',
                  eventId: event ? event?.externalId : '',
                },
              });

              return `A Meetingflow ${
                event ? `for ${event?.title}` : ''
              } has been created!`;
            } else if (result.status === 302) {
              return `A Meetingflow ${
                event ? `for ${event?.title}` : ''
              } has been created!`;
            }

            return null;
          },
          error: (err) => {
            if (err && isAxiosErrorResponse(err, 403)) {
              return `You don't have permission to create Meetingflows in this workspace`;
            }

            appInsights.trackException({
              exception: err instanceof Error ? err : new Error(err),
              properties: {
                organizationSlug,
                eventSource: event?.source,
                eventId: event?.externalId,
                status: isAxiosErrorResponse(err)
                  ? err.response?.status
                  : undefined,
                statusText: isAxiosErrorResponse(err)
                  ? err.response?.statusText
                  : undefined,
              },
            });

            appInsights.trackEvent({
              name: 'CREATE_PLAN_FAILED',
              properties: {
                organizationSlug,
                source: event?.source,
                eventId: event?.externalId,
                status: isAxiosErrorResponse(err)
                  ? err.response?.status
                  : undefined,
                statusText: isAxiosErrorResponse(err)
                  ? err.response?.statusText
                  : undefined,
              },
            });

            return 'Something went wrong creating the Meetingflow, please try again';
          },
        },
      );
    }
  };

  const createMeetingflowAndScheduleCallRecording = (
    e: React.MouseEvent<unknown>,
  ) => createMeetingflow(e, true);

  const callRecordingButton = useMemo(() => {
    if (!event) {
      return null;
    }

    if (!canCreatePlans) {
      return null;
    }

    if (beforeEnd && event?.conferenceInfo?.joinUri) {
      return allowScheduleCallRecording ? (
        <AsyncSpan
          className={classNames(recordLinkClass, 'not-scheduled')}
          onClick={createMeetingflowAndScheduleCallRecording}
        >
          <FontIcon iconName={'CircleFill'} />
          Record
        </AsyncSpan>
      ) : null;
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, beforeEnd, allowScheduleCallRecording, recordLinkClass]);

  const externalCompanies = event.companies.filter((c) => !c.isInternal);

  const peopleByDomain = useMemo(
    () => GroupBy(event?.attendees || [], (a) => a.emailDomain),
    [event?.attendees],
  );

  const attendeesWithoutCompany = useMemo(() => {
    const companyDomains = event?.companies
      ?.map((c) => c.domains.map((d) => d.domain))
      .flat();
    return (
      Object.keys(peopleByDomain)
        .filter((key) => !companyDomains?.includes(key))
        .flatMap((key) => peopleByDomain[key]) ?? []
    );
  }, [peopleByDomain, event?.companies]);

  const onTooltipToggle = useCallback(
    (isOpen: boolean, key: string) => {
      if (isOpen) {
        appInsights.trackEvent({
          name: `OPEN_EVENT_CARD_TOOLTIP_${key.toUpperCase()}`,
          properties: {
            organizationId: organization?.id,
            eventExternalId: event?.externalId,
          },
        });
      }
    },
    [appInsights, event?.externalId, organization?.id],
  );

  const onConferenceIconClick = useCallback(() => {
    window.open(event?.conferenceInfo?.joinUri, '_blank');
    appInsights.trackEvent({
      name: 'JOIN_CONFERENCE_CALL',
      properties: {
        organizationId: organization?.id,
        type: event?.conferenceInfo?.type,
      },
    });
  }, [
    appInsights,
    event?.conferenceInfo?.joinUri,
    event?.conferenceInfo?.type,
    organization?.id,
  ]);

  const conferenceIcon = useMemo(() => {
    if (
      event &&
      event?.conferenceInfo &&
      eventIsNowOrSoon &&
      !hideConferenceIcon
    ) {
      return (
        <TooltipHost
          styles={toolTipHostStyles}
          calloutProps={tooltipCalloutProps}
          onTooltipToggle={(isOpen: boolean) =>
            onTooltipToggle(isOpen, 'CONFERENCE')
          }
          content={
            <>
              <Text
                className={classNames(tooltipHeaderClass, 'join-conference')}
              >
                Join {titleCase(event?.conferenceInfo?.type || 'Online')}{' '}
                Meeting
              </Text>
            </>
          }
        >
          <FontIcon
            onClick={onConferenceIconClick}
            iconName="Video"
            className="join-conference"
            title="Join Conference"
          />
        </TooltipHost>
      );
    }

    return null;
  }, [
    event,
    eventIsNowOrSoon,
    onTooltipToggle,
    onConferenceIconClick,
    toolTipHostStyles,
    tooltipCalloutProps,
    tooltipHeaderClass,
    hideConferenceIcon,
  ]);

  const eventIcon = useMemo(() => {
    if (event?.attendees?.length) {
      return (
        <TooltipHost
          styles={toolTipHostStyles}
          calloutProps={tooltipCalloutProps}
          onTooltipToggle={(isOpen: boolean) =>
            onTooltipToggle(isOpen, 'ATTENDEES')
          }
          content={
            <div>
              <Text className={tooltipHeaderClass}>Calendar Event</Text>
              <div className="tooltip-content-container">
                <Text
                  style={{
                    display: 'block',
                    fontWeight: FontWeights.semibold,
                    fontSize: FontSizes.small,
                  }}
                  title={event?.title}
                  aria-label={event?.title}
                >
                  {event?.title}
                  {event?.externalSeriesId ? (
                    <Icon
                      iconName="RecurringEvent"
                      styles={{
                        root: {
                          fontSize: FontSizes.size12,
                          display: 'inline-block',
                          marginLeft: '.25rem',
                          position: 'relative',
                          top: '.15rem',
                          color: NeutralColors.gray100,
                        },
                      }}
                    />
                  ) : null}
                </Text>
                {event?.startTime ? (
                  <>
                    <Text
                      style={{
                        display: 'block',
                        fontSize: FontSizes.small,
                        color: NeutralColors.gray100,
                        marginBottom: '.25rem',
                      }}
                    >
                      <StyledDateTime
                        dateTime={event?.startTime}
                        displayComponents={['date']}
                      />{' '}
                    </Text>

                    <Text
                      style={{
                        display: 'block',
                        fontSize: FontSizes.small,
                        color: NeutralColors.gray100,
                        marginBottom: '.25rem',
                      }}
                    >
                      <>
                        <StyledDateTime
                          dateTime={event?.startTime}
                          displayComponents={['time']}
                        />
                      </>
                      {event?.endTime ? (
                        <>
                          {' — '}{' '}
                          <StyledDateTime
                            dateTime={event?.endTime}
                            displayComponents={['time']}
                          />
                        </>
                      ) : null}
                    </Text>
                  </>
                ) : null}
                {event.conferenceInfo?.type ? (
                  <Text
                    style={{
                      display: 'block',
                      fontSize: FontSizes.small,
                    }}
                  >
                    <span style={{ fontWeight: FontWeights.semibold }}>
                      Via:{' '}
                    </span>
                    {titleCase(event.conferenceInfo?.type)}
                  </Text>
                ) : null}
                {event.creator ? (
                  <Text
                    style={{
                      display: 'block',
                      fontSize: FontSizes.small,
                    }}
                  >
                    <span style={{ fontWeight: FontWeights.semibold }}>
                      Creator:{' '}
                    </span>
                    {titleCase(event.creator.name || event.creator.email)}
                  </Text>
                ) : null}
                {event.organizer ? (
                  <Text
                    style={{
                      display: 'block',
                      fontSize: FontSizes.small,
                    }}
                  >
                    <span style={{ fontWeight: FontWeights.semibold }}>
                      Organizer:{' '}
                    </span>
                    {titleCase(event.organizer.name || event.organizer.email)}
                  </Text>
                ) : null}
              </div>

              {event?.attendees?.length ? (
                <>
                  <Text
                    className={tooltipHeaderClass}
                    style={{ marginTop: '.5rem' }}
                  >
                    Attendees
                  </Text>
                  <div className="tooltip-content-container">
                    <div className="meetingflow-companies">
                      {event?.companies
                        ? event?.companies.map((company) => {
                            const companyAttendees =
                              company.domains
                                ?.map((domain) => domain.domain)
                                ?.flatMap(
                                  (domain) =>
                                    Object.keys(peopleByDomain)
                                      .filter(
                                        (key) =>
                                          key === domain ||
                                          key.endsWith(domain),
                                      )
                                      .flatMap((key) => peopleByDomain[key]) ??
                                    [],
                                ) ?? [];

                            return (
                              <div
                                key={`meetingflowcard-${event.externalId}-company-${company.id}`}
                                className={companyClass}
                              >
                                <div className={companyNameClass}>
                                  <Persona
                                    imageUrl={
                                      company?.logo ||
                                      company?.twitter_avatar ||
                                      undefined
                                    }
                                    styles={{
                                      root: {
                                        float: 'left',
                                        clear: 'left',
                                        width: '24px',
                                        marginRight: '.25rem',
                                      },
                                    }}
                                    size={PersonaSize.size24}
                                    hidePersonaDetails
                                  />
                                  <Text
                                    style={{
                                      display: 'inline-block',
                                      fontSize: FontSizes.small,
                                      fontWeight: FontWeights.semibold,
                                      lineHeight: '24px',
                                    }}
                                  >
                                    {company?.name ||
                                      company?.legalName ||
                                      company?.domains?.[0].domain}
                                  </Text>
                                </div>
                                <div className="company-attendees">
                                  {companyAttendees.map((a) => (
                                    <div
                                      className={attendeeNameClass}
                                      key={`company-attendee-${a.email}`}
                                    >
                                      {a.name || a.email}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            );
                          })
                        : null}
                      {attendeesWithoutCompany.length ? (
                        <>
                          <Text
                            style={{
                              display: 'inline-block',
                              fontSize: FontSizes.small,
                              fontWeight: FontWeights.semibold,
                              lineHeight: '24px',
                            }}
                          >
                            Unknown Company
                          </Text>
                          <div className="company-attendees">
                            {attendeesWithoutCompany.map((a) => (
                              <div
                                className={attendeeNameClass}
                                key={`no-company-attendee-${a.email}`}
                              >
                                {a.name || a.email}
                              </div>
                            ))}
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          }
        >
          <FontIcon
            iconName="People"
            className="attendees"
            title="Calendar Event"
          />
        </TooltipHost>
      );
    }

    return null;
  }, [
    event,
    onTooltipToggle,
    peopleByDomain,

    tooltipCalloutProps,
    tooltipHeaderClass,
    toolTipHostStyles,
    companyNameClass,
    companyClass,
    attendeeNameClass,

    attendeesWithoutCompany,
  ]);

  return (
    <AsyncDiv
      title={cardTitleAttr || undefined}
      className={calendarEventClass}
      style={{
        cursor: onClick ? 'pointer' : undefined,
      }}
      onClick={async () =>
        createMeetingflowOnClick
          ? createMeetingflow
          : onClick
            ? onClick()
            : undefined
      }
    >
      <div className="meetingflow-icons">
        {eventIcon}
        {conferenceIcon}
      </div>
      {!hideNewMeetingflowButton ? (
        <div className="button">
          <MeetingPlanViewCreateButton
            organizationSlug={organizationSlug}
            event={event}
            noTooltip
            hideTextLabel={!showTextLabelOnCreateButton}
            onClickDisabled={disableCreateViewMeetingflowOnClick}
          />
        </div>
      ) : null}
      <div className="event-details">
        <Text className="event-title">{event?.title}</Text>
        <Text className="event-datetime">
          {callRecordingButton}
          {event?.startTime ? (
            <div
              style={{
                display: 'inline-block',
                color: NeutralColors.gray120,
                width: `calc(100% - 0)`,
                boxSizing: 'content-box',
              }}
            >
              <StyledDateTime
                useRelativeDates
                dateTime={event?.startTime}
                displayComponents={showTimesOnly ? ['time'] : ['date', 'time']}
              />
            </div>
          ) : null}
        </Text>
        {showCompanies && externalCompanies.length ? (
          <div
            style={{
              display: 'flex',
              height: '20px',
              marginTop: '.5rem',
            }}
          >
            {externalCompanies && organizationSlug
              ? // @ts-ignore
                externalCompanies.map((c: Company) => (
                  <CompanyCard
                    company={c}
                    organizationSlug={organizationSlug}
                    key={c.id}
                    logoOnly
                    onClick={(_id, e) => {
                      if (e) {
                        navigate(
                          `/organization/${organizationSlug}/library/companies/${c.id}`,
                          e,
                        );
                      }
                    }}
                    logoSize={'16px'}
                    personaSize={PersonaSize.size16}
                  />
                ))
              : null}
          </div>
        ) : null}
      </div>
    </AsyncDiv>
  );
};

export default EventCard;
