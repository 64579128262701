import { useAuth0 } from '@auth0/auth0-react';
import {
  ChoiceGroup,
  DefaultButton,
  IChoiceGroupOption,
  Text,
  TextField,
  mergeStyles,
} from '@fluentui/react';
import { useForceUpdate } from '@fluentui/react-hooks';
import { Resource } from '@meetingflow/common/Api/data-contracts';
import { useCallback, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { v4 } from 'uuid';
import {
  uploadFileToOrg,
  uploadFileToPlan,
} from '../../../Helpers/FileHelpers';
import { externalize, isUrl } from '../../../Helpers/URLHelpers';
import { AsyncPrimaryButton } from '../../HOC/AsyncButton';
import { BaseModal } from './BaseModal';

const choiceStyle = { gridArea: 'type' };
const nameStyle = { root: { gridArea: 'title' } };
const linkStyle = { root: { gridArea: 'url' } };
const filePickerStyle = { gridArea: 'url' };
const cancelButtonStyle = { gridArea: 'cancel', marginTop: '3rem' };
const addButtonStyle = { gridArea: 'accept', marginTop: '3rem' };

const options: IChoiceGroupOption[] = [
  { key: 'link', text: 'Link' },
  { key: 'file', text: 'File' },
];

type AddMeetingPlanResourceDialogProps = {
  organizationSlug: string;
  meetingPlanId?: string;
  file?: File;
  onAdd: (item: Resource) => void;
  onDismiss: () => void;
};
export const AddMeetingPlanResourceDialog = ({
  organizationSlug,
  meetingPlanId,
  file,
  onAdd,
  onDismiss,
}: AddMeetingPlanResourceDialogProps) => {
  const forceUpdate = useForceUpdate();
  const { getAccessTokenSilently } = useAuth0();
  const [resourceType, setResourceType] = useState<Resource['type']>(
    file ? 'file' : 'link',
  );
  const [title, setTitle] = useState(file?.name || '');
  const [url, setUrl] = useState('');

  const filePickerRef = useRef<HTMLInputElement>(null);

  const modalStyles = mergeStyles({
    '.ms-Dialog-main': {
      width: '60%',
      height: 'auto',
      maxHeight: '60%',
      paddingBottom: '.5rem',
    },
  });

  const dialogContentStyle = mergeStyles({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr auto auto',
    gridTemplateRows: 'auto auto auto auto',
    gridTemplateAreas: `
    'type type type type'
    'title title title title'
    'url url url url'
    'none0 none1 cancel accept'
    `,
    gridColumnGap: '0.5rem',
    gridRowGap: '0.5rem',
    padding: '1rem',
  });

  const onAddClick = async () => {
    if (!title) {
      return;
    }

    if (resourceType === 'file') {
      if (
        !file &&
        (!filePickerRef.current || !filePickerRef.current.files?.length)
      ) {
        return;
      }

      const uploadFile = file || filePickerRef.current?.files?.[0];

      if (!uploadFile) {
        return;
      }

      const token = await getAccessTokenSilently();
      const uploadPromise = meetingPlanId
        ? uploadFileToPlan(token, organizationSlug, meetingPlanId, uploadFile)
        : uploadFileToOrg(token, organizationSlug, uploadFile);

      toast.promise(uploadPromise, {
        loading: 'Uploading file',
        success: 'Successfully uploaded file',
        error: 'Failed to upload file',
      });

      try {
        const uploadedFileUrl = await uploadPromise;
        onAdd({
          id: v4(),
          type: 'file',
          title,
          url: uploadedFileUrl,
          name: uploadFile.name,
          size: uploadFile.size,
          mimeType: uploadFile.type,
        });
        return;
      } catch (err) {}
    } else {
      if (!url || !isUrl(externalize(url))) {
        return;
      }

      onAdd({ id: v4(), type: 'link', title, url: externalize(url) });
      return;
    }
  };

  return (
    <BaseModal
      className={modalStyles}
      isOpen
      title="Add resource to Meetingflow"
      onDismiss={onDismiss}
    >
      <div className={dialogContentStyle}>
        {!file ? (
          <ChoiceGroup
            label="Resource type"
            style={choiceStyle}
            selectedKey={resourceType}
            options={options}
            onChange={(e, o) =>
              setResourceType((o?.key as Resource['type']) || 'link')
            }
          />
        ) : (
          <Text>Adding {file.name}</Text>
        )}
        <TextField
          styles={nameStyle}
          label="Name"
          title="Name"
          value={title}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
              onAddClick();
            }
          }}
          onChange={(e, newValue) => setTitle(newValue ?? '')}
        />
        {resourceType === 'link' ? (
          <TextField
            styles={linkStyle}
            label="URL"
            title="URL"
            value={url}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === 'Enter') {
                onAddClick();
              }
            }}
            onChange={(e, newValue) => setUrl(newValue ?? '')}
          />
        ) : !file ? (
          <input
            ref={filePickerRef}
            style={filePickerStyle}
            type="file"
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === 'Enter') {
                onAddClick();
              }
            }}
            onChange={() => {
              if (filePickerRef.current?.files?.length && !title) {
                setTitle(filePickerRef.current.files[0].name);
              }
              forceUpdate();
            }}
          />
        ) : null}
        <DefaultButton style={cancelButtonStyle} onClick={onDismiss}>
          Cancel
        </DefaultButton>
        <AsyncPrimaryButton
          style={addButtonStyle}
          disabled={
            !title ||
            (resourceType === 'link' && !isUrl(externalize(url))) ||
            (resourceType === 'file' &&
              !file &&
              !filePickerRef.current?.files?.length)
          }
          onClick={onAddClick}
        >
          Add
        </AsyncPrimaryButton>
      </div>
    </BaseModal>
  );
};
