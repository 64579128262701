import {
  FontWeights,
  Icon,
  NeutralColors,
  Text,
  mergeStyles,
} from '@fluentui/react';
import React from 'react';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import HubSpotIcon from '../../../../Static/Images/hubspot.png';
import { MEETINGFLOW_COLORS } from '../../../../Themes/Themes';
import { HubSpotPinTileButton } from './HubSpotPinTileButton';

export type HubSpotCompanyTileProps = {
  meetingPlanId?: string;
  organizationSlug: string;
  id: string;
  name: string;
  hubspotInstance: string;
  ownerName?: string;
  ownerEmail?: string;
  showArrow?: boolean;
  showObjectType?: boolean;
  showExternalLink?: boolean;
  isPinned?: boolean;
  externalServiceObjectId?: number;
  isLoading?: boolean;
  onClick?: () => void;
  onPinClick?: (e?: React.MouseEvent<HTMLElement>) => Promise<unknown>;
};

export const HubSpotCompanyTile = ({
  meetingPlanId,
  organizationSlug,
  id,
  name,
  hubspotInstance,
  ownerName,
  ownerEmail,
  showArrow = true,
  showObjectType = false,
  onClick,
  onPinClick,
  isPinned,
  externalServiceObjectId,
  isLoading,
  showExternalLink = false,
}: HubSpotCompanyTileProps) => {
  const { isDark } = useLightOrDarkMode();

  const showOverline = !!ownerName || !!ownerEmail || !!showObjectType;

  const accountTileClass = mergeStyles({
    position: 'relative',
    transition: 'all .3s ease-in-out',
    cursor: onClick ? 'pointer' : undefined,
    backgroundColor: isDark
      ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
      : MEETINGFLOW_COLORS.white,
    padding: '.25rem',
    borderRadius: '.25rem',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    columnGap: '.25rem',
    rowGap: '.25rem',
    boxSizing: 'border-box',

    '.pin': {
      flexBasis: '22px',
    },

    '.content': {
      flexBasis: 'auto',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',

      '*': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },

    ':last-child': {
      borderBottom: 'none',
    },

    '.arrow': {
      flexBasis: '24px',
      height: '24px',
      position: 'relative',
      alignSelf: 'center',
      color: NeutralColors.gray60,
      transition: '.3s ease-in-out all',
    },

    ':hover': {
      backgroundColor: onClick
        ? isDark
          ? NeutralColors.gray210
          : MEETINGFLOW_COLORS.purpleLighter
        : undefined,
        

      '.arrow': {
        color: MEETINGFLOW_COLORS.purpleDark,
        position: 'relative',
      },

      img: { filter: `grayscale(0%) !important` },
    },
  });


  const salesforceLogoClass = mergeStyles({
    position: 'relative' as const,
    cursor: 'pointer',
    transition: '.3s ease-in-out all',
    flexBasis: '28px',
    height: '20px',
    alignSelf: 'center',
  });

  return (
    <div
      className={accountTileClass}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.();
      }}
      style={{ margin: 0, width: '100%' }}
    >
      {meetingPlanId ? (
        <HubSpotPinTileButton
          className="pin"
          meetingPlanId={meetingPlanId}
          organizationSlug={organizationSlug}
          objectId={id}
          objectName={name}
          objectType={'ACCOUNT'}
          tileHasOverline={showOverline}
          isPinned={isPinned}
          onPinToggle={onPinClick}
          externalServiceObjectId={externalServiceObjectId}
        />
      ) : null}

      <div className="content">
        {showOverline ? (
          <Text
            style={{
              lineHeight: '1rem',
              fontWeight: FontWeights.bold,
              color: NeutralColors.gray100,
              textTransform: 'uppercase',
              fontSize: '8px',
            }}
            block
            nowrap
          >
            {showObjectType ? <span>Company • </span> : null}
            {ownerName || ownerEmail ? (
              <span style={{ textTransform: 'initial' }}>
                {ownerName || ownerEmail}
              </span>
            ) : null}
          </Text>
        ) : null}
        <Text
          variant="medium"
          style={{
            display: 'block',
            fontWeight: FontWeights.semibold,
            position: 'relative',
            top: showOverline ? '-.25rem' : '.1rem',
            maxWidth: 'calc(100% - 2rem)',
            paddingRight: '2rem',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color: isDark ? NeutralColors.gray30 : NeutralColors.gray180,
          }}
          block
        >
          {name}
        </Text>
      </div>
      {showExternalLink ? (
            <Icon
              key="Salesforce"
              className={salesforceLogoClass}
              imageProps={{
                height: '16px',
                src: HubSpotIcon,
                alt: 'Salesforce',
                width: '16px',
                title: `View ${name} on Salesforce`,
                style: {
                  position: 'relative',
                  color: NeutralColors.gray60,
                  filter: `grayscale(100%)`,
                  opacity: isDark ? '1' : '.5',
                  transition: '.3s ease-in-out all',
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                window.open(
                  `https://app.hubspot.com/contacts/${hubspotInstance}/companies/${id}`,
                  '_blank',
                );
              }}
            />
          ) : null}

      {showArrow ? (
        <div className="arrow">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5858 6.34317L12 4.92896L19.0711 12L12 19.0711L10.5858 17.6569L16.2427 12L10.5858 6.34317Z"
              fill="currentColor"
            />
          </svg>
        </div>
      ) : null}
    </div>
  );
};
