import { mergeStyles, NeutralColors } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { PropsWithChildren, useCallback, useLayoutEffect, useRef } from 'react';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { useLocalStorageState } from '../../Hooks/useLocalStorageState';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';

type ScrollBehavior = 'auto' | 'scroll' | 'hidden' | 'visible';

type SplitViewLayoutProps = {
  layoutName: string;
  rootClassName?: string;
  header?: React.ReactNode;
  headerClassName?: string;
  fullWidthHeader?: boolean;
  sidebar?: React.ReactNode;
  sidebarClassName?: string;
  sidebarPosition?: 'left' | 'right';
  constrainSidebar?: boolean;
  sidebarHorizontalScrollBehavior?: ScrollBehavior;
  sidebarVerticalScrollBehavior?: ScrollBehavior;
  footer?: React.ReactNode;
  footerClassName?: string;
  fullWidthFooter?: boolean;
  contentClassName?: string;
  constrainContent?: boolean;
  contentHorizontalScrollBehavior?: ScrollBehavior;
  contentVerticalScrollBehavior?: ScrollBehavior;
  resizerClassName?: string;
  gap?: string;
  allowResize?: boolean;
  sidebarWidthPercent?: number;
  initialSidebarWidthPercent?: string;
  minSidebarWidth?: number;
  maxSidebarWidth?: number;
  midWidthHeader?: boolean;
};

export const SplitViewLayout = ({
  layoutName,
  rootClassName,
  header,
  headerClassName,
  fullWidthHeader,
  sidebar,
  sidebarClassName,
  constrainSidebar = true,
  sidebarPosition = 'right',
  sidebarHorizontalScrollBehavior = 'auto',
  sidebarVerticalScrollBehavior = 'auto',
  footer,
  footerClassName,
  fullWidthFooter,
  children,
  contentClassName,
  constrainContent = true,
  contentHorizontalScrollBehavior = 'auto',
  contentVerticalScrollBehavior = 'auto',
  resizerClassName,
  gap,
  allowResize,
  sidebarWidthPercent,
  initialSidebarWidthPercent,
  minSidebarWidth = 10,
  maxSidebarWidth = 50,
  midWidthHeader,
}: PropsWithChildren<SplitViewLayoutProps>) => {
  const layoutRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const resizerRef = useRef<HTMLDivElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  const { isDark } = useLightOrDarkMode();

  const [
    resizerRefSet,
    { setTrue: setResizerRefSet, setFalse: setResizerRefNotSet },
  ] = useBoolean(false);

  const [sidebarWidth, setSidebarWidth] = useLocalStorageState(
    layoutName,
    initialSidebarWidthPercent?.toString() ?? '40',
  );

  const styles = mergeStyles({
    display: 'grid',
    gridTemplateColumns: sidebar
      ? sidebarPosition === 'left'
        ? `${sidebarWidthPercent ?? sidebarWidth}% auto 1fr`
        : `1fr auto ${sidebarWidthPercent ?? sidebarWidth}%`
      : '1fr',
    gridTemplateRows: 'auto 1fr auto',
    gridTemplateAreas: sidebar
      ? sidebarPosition === 'left'
        ? `
      "${fullWidthHeader ? 'header header header' : midWidthHeader ? 'header . .' : 'sidebar resizer header'}"
      "sidebar resizer content"
      "${fullWidthFooter ? 'footer footer footer' : 'sidebar resizer footer'}"
    `
        : `
      "${fullWidthHeader ? 'header header header' : midWidthHeader ? 'header . .' : 'header resizer sidebar'}"
      "content resizer sidebar"
      "${fullWidthFooter ? 'footer footer footer' : 'footer resizer sidebar'}"
    `
      : `
      "header"
      "content"
      "footer"
    `,
    gap,
    height: '100%',
    width: '100%',

    '.header': {
      position: 'relative',
      gridArea: 'header',
      width: '100%',
      zIndex: 2,
    },

    '.sidebar': {
      position: 'relative',
      gridArea: 'sidebar',
      width: '100%',
      height: '100%',
      overflowX: sidebarHorizontalScrollBehavior,
      overflowY: sidebarVerticalScrollBehavior,
      zIndex: 1,
      ...(constrainSidebar
        ? { maxWidth: '100%', maxHeight: '100%', boxSizing: 'border-box' }
        : {}),
    },

    '.resizer': {
      position: 'relative',
      gridArea: 'resizer',
      cursor: 'col-resize',
      width: '8px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0',
      padding: '0',
      boxSizing: 'border-box',
      transition: '.3s ease-in-out all',
      zIndex: 3,
    },

    '.content': {
      position: 'relative',
      gridArea: 'content',
      height: '100%',
      width: '100%',
      overflowX: contentHorizontalScrollBehavior,
      overflowY: contentVerticalScrollBehavior,
      zIndex: 0,

      ...(constrainContent
        ? { maxWidth: '100%', maxHeight: '100%', boxSizing: 'border-box' }
        : {}),
    },

    '.footer': {
      position: 'relative',
      gridArea: 'footer',
      height: '100%',
      width: '100%',
      zIndex: 2,
    },
  });

  const handleDragResize = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (contentRef?.current && sidebarRef?.current && layoutRef?.current) {
        const { x: mainColumnPositionX } =
          contentRef.current.getBoundingClientRect();
        const newMainColumnWidth = e.clientX - mainColumnPositionX;
        const { width: layoutWidth } =
          layoutRef.current.getBoundingClientRect();
        let newMainColumnWidthPercentage = Math.round(
          (newMainColumnWidth / layoutWidth) * 100,
        );

        // Apply min/max constraints
        const newSidebarWidthPercentage = 100 - newMainColumnWidthPercentage;
        if (newSidebarWidthPercentage < minSidebarWidth) {
          newMainColumnWidthPercentage = 100 - minSidebarWidth;
        } else if (newSidebarWidthPercentage > maxSidebarWidth) {
          newMainColumnWidthPercentage = 100 - maxSidebarWidth;
        }

        setSidebarWidth((100 - newMainColumnWidthPercentage).toString());
      }
    },
    [maxSidebarWidth, minSidebarWidth, setSidebarWidth],
  );

  useLayoutEffect(() => {
    if (resizerRef.current && !resizerRefSet) {
      setResizerRefSet();
    } else if (!resizerRef.current && resizerRefSet) {
      setResizerRefNotSet();
    }
  });

  useLayoutEffect(() => {
    if (!allowResize || sidebarWidthPercent) {
      return;
    }

    const resizerColumnCurrent = resizerRef?.current;
    resizerColumnCurrent?.addEventListener('mousedown', (event) => {
      document.addEventListener('mousemove', handleDragResize, false);
      document.addEventListener(
        'mouseup',
        () => {
          document.removeEventListener('mousemove', handleDragResize, false);
        },
        false,
      );
    });

    return () => {
      resizerColumnCurrent?.removeEventListener('mousedown', (event) => {
        document.addEventListener('mousemove', handleDragResize, false);
        document.addEventListener(
          'mouseup',
          () => {
            document.removeEventListener('mousemove', handleDragResize, false);
          },
          false,
        );
      });
    };
  }, [allowResize, handleDragResize, resizerRefSet, sidebarWidthPercent]);

  return (
    <div className={classNames(styles, rootClassName)} ref={layoutRef}>
      {header ? (
        <div className={classNames('header', headerClassName)} ref={headerRef}>
          {header}
        </div>
      ) : null}
      {sidebar ? (
        <div
          className={classNames('sidebar', sidebarClassName)}
          ref={sidebarRef}
        >
          {sidebar}
        </div>
      ) : null}
      {sidebar && allowResize ? (
        <div
          className={classNames('resizer', resizerClassName)}
          ref={resizerRef}
        >
          <svg
            width="8px"
            height="16px"
            viewBox="0 0 8 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'relative', left: '-1px', top: '-4rem' }}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.5 4.625C6.12132 4.625 6.625 4.12132 6.625 3.5C6.625 2.87868 6.12132 2.375 5.5 2.375C4.87868 2.375 4.375 2.87868 4.375 3.5C4.375 4.12132 4.87868 4.625 5.5 4.625ZM9.5 4.625C10.1213 4.625 10.625 4.12132 10.625 3.5C10.625 2.87868 10.1213 2.375 9.5 2.375C8.87868 2.375 8.375 2.87868 8.375 3.5C8.375 4.12132 8.87868 4.625 9.5 4.625ZM10.625 7.5C10.625 8.12132 10.1213 8.625 9.5 8.625C8.87868 8.625 8.375 8.12132 8.375 7.5C8.375 6.87868 8.87868 6.375 9.5 6.375C10.1213 6.375 10.625 6.87868 10.625 7.5ZM5.5 8.625C6.12132 8.625 6.625 8.12132 6.625 7.5C6.625 6.87868 6.12132 6.375 5.5 6.375C4.87868 6.375 4.375 6.87868 4.375 7.5C4.375 8.12132 4.87868 8.625 5.5 8.625ZM10.625 11.5C10.625 12.1213 10.1213 12.625 9.5 12.625C8.87868 12.625 8.375 12.1213 8.375 11.5C8.375 10.8787 8.87868 10.375 9.5 10.375C10.1213 10.375 10.625 10.8787 10.625 11.5ZM5.5 12.625C6.12132 12.625 6.625 12.1213 6.625 11.5C6.625 10.8787 6.12132 10.375 5.5 10.375C4.87868 10.375 4.375 10.8787 4.375 11.5C4.375 12.1213 4.87868 12.625 5.5 12.625Z"
              fill={
                isDark ? NeutralColors.gray120 : MEETINGFLOW_COLORS.purpleMedium
              }
            />
          </svg>
        </div>
      ) : null}
      {footer ? (
        <div className={classNames('footer', footerClassName)} ref={footerRef}>
          {footer}
        </div>
      ) : null}
      {children ? (
        <div
          className={classNames('content', contentClassName)}
          ref={contentRef}
        >
          {children}
        </div>
      ) : null}
    </div>
  );
};
