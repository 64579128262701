import { GenericError } from '@auth0/auth0-spa-js';
import { isObject } from 'lodash';
import { hasOwnProperty } from '@meetingflow/common/ObjectHelpers';

// Helpers to check for auth0 authentication errors requiring interactive login
// due to inactivity or changed scopes.
// see: https://community.auth0.com/t/getaccesstokensilently-throws-error-login-required/52333/4

export const isGenericError = (err: unknown): err is GenericError =>
  err instanceof GenericError;

export const isLoginRequiredError = (err: unknown) =>
  err instanceof GenericError && err.error === 'login_required';

export const isConsentRequiredError = (err: unknown) =>
  err instanceof GenericError && err.error === 'consent_required';

export const isUnknownOrInvalidRefreshToken = (err: unknown) =>
  isObject(err) &&
  hasOwnProperty(err, 'error') &&
  err.error === 'invalid_grant' &&
  hasOwnProperty(err, 'error_description') &&
  err.error_description === 'Unknown or invalid refresh token.';
