import { useState } from 'react';
import { MenuProps } from '@mui/material/Menu';
import DSButton, { DSButtonProps } from './DSButton';
import { KeyboardArrowDown } from '@mui/icons-material';
import { ClickAwayListener } from '@mui/material';
import DSMenu from './DSMenu';

interface DSMenuButtonProps extends DSButtonProps {
  label: string;
  startIcon: React.ReactNode;
  menuItems: React.ReactNode;
  menuProps?: MenuProps;
  showChevron?: boolean;
  branded?: boolean;
  iconOnly?: boolean;
}

const DSMenuButton: React.FC<DSMenuButtonProps> = ({
  label,
  startIcon,
  menuItems,
  menuProps,
  showChevron = true,
  branded = false,
  iconOnly = false,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuClose = (
    event:
      | MouseEvent
      | TouchEvent
      | React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setMenuOpen(false);
  };

  const handleMenuOpen = (
    event:
      | MouseEvent
      | TouchEvent
      | React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (event.currentTarget) {
      setAnchorEl(event.currentTarget as HTMLElement);
    }
    setMenuOpen(true);
  };

  const handleMenuToggle = (
    event:
      | MouseEvent
      | TouchEvent
      | React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (menuOpen) {
      handleMenuClose(event);
    } else {
      handleMenuOpen(event);
    }
  };

  const buttonWidth = anchorEl?.offsetWidth;

  return (
    <ClickAwayListener onClickAway={handleMenuClose}>
      <div>
        <DSButton
          aria-controls={menuOpen ? 'menu-button' : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? 'true' : undefined}
          onClick={handleMenuToggle}
          startIcon={startIcon}
          endIcon={showChevron ? <KeyboardArrowDown /> : undefined}
          branded={branded}
          {...props}
        >
          {!iconOnly && <span className="button-label">{label}</span>}
          <DSMenu
            id="menu-button-menu"
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleMenuClose}
            MenuListProps={{
              'aria-labelledby': 'menu-button-menu',
              sx: { minWidth: buttonWidth },
            }}
            {...menuProps}
          >
            {menuItems}
          </DSMenu>
        </DSButton>
      </div>
    </ClickAwayListener>
  );
};

export default DSMenuButton;
