import { DSConfigurationHeaderRow } from '../../Components/DSConfigurationHeaderRow';
import { Stack } from '@fluentui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MilestoneObject } from '../../../../../types/MilestoneTypes';
import { useMilestonesSummary } from '../../../../../Hooks/useMilestonesSummary';
import { GetMilestonesSummaryData, DealRoomMilestoneType } from '@meetingflow/common/Api/data-contracts';
import { DealRoomsApiClient } from '../../../../../Services/NetworkCommon';
import { useAuth0 } from '@auth0/auth0-react';
import { useMutualPlan } from '../../../../../Hooks/useMutualPlan';
import { DSConfigurationMutualPlanMilestoneItem } from './DSConfigurationMutualPlanMilestoneItem';
import { dsConfigurationMutualPlanStyles as styles } from './styles';
import { DSConfigurationMutualPlanTemplateCard } from './DSConfigurationMutualPlanTemplate/DSConfigurationMutualPlanTemplateCard';
import { DSConfigurationMutualPlanCreateMilestoneButton } from './DSConfigurationMutualPlanCreateMilestoneDialog';
import { DSConfigurationHideMutualPlanButton } from './DSConfigurationMutualPlanHideButton';
import toast from 'react-hot-toast';

interface DSConfigurationMilestonesProps {
    organizationSlug: string;
    dealRoomId: number;
}

export const DSConfigurationMutualPlan = ({ organizationSlug, dealRoomId }: DSConfigurationMilestonesProps) => {
    const [milestones, setMilestones] = useState<GetMilestonesSummaryData>([]);

    const { getAccessTokenSilently } = useAuth0();

    const { milestonesSummary, refetch: refetchMilestonesSummary } = useMilestonesSummary(organizationSlug, dealRoomId);
    const { refetch: refetchMutualPlan } = useMutualPlan(organizationSlug, dealRoomId);

    // Initialize milestones state when milestonesSummary is available
    useEffect(() => {
        if (!Array.isArray(milestonesSummary)) return;
        setMilestones(milestonesSummary);
    }, [milestonesSummary]);

    // Sort milestones by visibility and then by dueDate
    const sortedMilestones = useMemo(() => {
        return [...milestones].sort((a, b) => {
            if (a.visible !== b.visible) return a.visible ? -1 : 1;
            if (!a.dueDate && !b.dueDate) return 0;
            if (!a.dueDate) return 1;
            if (!b.dueDate) return -1;
            return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
        });
    }, [milestones]);

    const getMilestoneByType = useCallback((type: DealRoomMilestoneType) => {
        return sortedMilestones.find(milestone => milestone.type === type && milestone)!;
    }, [sortedMilestones]);

    // Update a milestone with new data
    const updateMilestone = useCallback(async (
        milestoneType: DealRoomMilestoneType,
        updates: Partial<MilestoneObject>
    ) => {
        try {
            const token = await getAccessTokenSilently();
            const updatedMilestones = milestones.map(milestone =>
                milestone.type === milestoneType ? { ...milestone, ...updates } : milestone
            );

            await toast.promise(
                DealRoomsApiClient.saveMilestones(organizationSlug, dealRoomId, updatedMilestones, {
                    headers: { Authorization: `Bearer ${token}` },
                }),
                {
                    loading: 'Updating milestone...',
                    success: () => {
                        setMilestones(updatedMilestones);
                        return 'Milestone updated successfully';
                    },
                    error: 'Failed to update milestone',
                }
            );

            refetchMilestonesSummary();
            refetchMutualPlan();
        } catch (error) {
            console.error('Error updating milestone:', error);
            toast.error('Failed to update milestone');
        }
    }, [dealRoomId, getAccessTokenSilently, milestones, organizationSlug, refetchMilestonesSummary, refetchMutualPlan]);

    const handleOnChangeDueDate = useCallback((
        milestoneType: DealRoomMilestoneType,
        newValue: Date | null | undefined,
    ) => {
        if (newValue) {
            updateMilestone(milestoneType, { dueDate: newValue.toISOString() });
        }
    }, [updateMilestone]);

    // Toggle milestone visibility
    const handleClickMilestone = useCallback((milestoneType: DealRoomMilestoneType) => {
        updateMilestone(milestoneType, { visible: !(getMilestoneByType(milestoneType).visible) });
    }, [updateMilestone, getMilestoneByType]);

    // Delete a milestone
    const handleDeleteMilestone = useCallback(async (milestoneType: DealRoomMilestoneType) => {
        try {
            const token = await getAccessTokenSilently();
            await toast.promise(
                DealRoomsApiClient.deleteMilestone(organizationSlug, dealRoomId, milestoneType, {
                    headers: { Authorization: `Bearer ${token}` },
                }),
                {
                    loading: 'Deleting milestone...',
                    success: () => {
                        refetchMilestonesSummary();
                        refetchMutualPlan();
                        return 'Milestone deleted successfully';
                    },
                    error: 'Failed to delete milestone',
                }
            );
        } catch (error) {
            console.error('Error deleting milestone:', error);
            toast.error('Failed to delete milestone');
        }
    }, [dealRoomId, getAccessTokenSilently, organizationSlug, refetchMilestonesSummary, refetchMutualPlan]);

    return (
        <Stack tokens={{ childrenGap: 20 }} className={styles.contentContainer}>
            <DSConfigurationHeaderRow
                title="Mutual Plan"
                description="The Mutual Plan is how sellers and buyers collaborate to reach a decision. Create and track key milestones to align stakeholders and drive your deal forward with clarity and purpose.">
                <DSConfigurationHideMutualPlanButton />
            </DSConfigurationHeaderRow>

            <DSConfigurationMutualPlanTemplateCard
                organizationSlug={organizationSlug}
                dealRoomId={dealRoomId}
                hasExistingMilestones={sortedMilestones.length > 0}
                template={{
                    name: 'Investor Close Plan Template',
                    description: 'Put together a mutual plan to help you close your deal with your investor.',
                    milestones: ["Sourcing", "Screening", "Partner Review", "Due Diligence", "Negotiations", "Support", "Exit"],
                }}
                refetchMutualPlan={refetchMutualPlan}
                refetchMilestones={refetchMilestonesSummary}
            />

            <div className={styles.content}>
                <div className={styles.milestones}>
                    {sortedMilestones.map((milestone) => (
                        <DSConfigurationMutualPlanMilestoneItem
                            key={milestone.type}
                            milestone={milestone}
                            onClickMilestone={handleClickMilestone}
                            onChangeDueDate={handleOnChangeDueDate}
                            onDeleteMilestone={handleDeleteMilestone}
                        />
                    ))}
                </div>
            </div>

            <DSConfigurationMutualPlanCreateMilestoneButton
                organizationSlug={organizationSlug}
                dealRoomId={dealRoomId}
                refetchMutualPlan={refetchMutualPlan}
                refetchMilestones={refetchMilestonesSummary}
            />
        </Stack>
    );
};
