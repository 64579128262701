import { mergeStyles } from '@fluentui/react';
import classNames from 'classnames';
import {
  DetailedHTMLProps,
  ImgHTMLAttributes,
  ReactNode,
  useEffect,
  useState,
} from 'react';

type ImageWithFallbackProps = DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> & {
  fallbackSrc?: string | null;
  fallback?: ReactNode;
  objectFit?: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down' | 'unset';
};

export const ImageWithFallback = ({
  className,
  src,
  fallbackSrc,
  fallback,
  objectFit,
  ...rest
}: ImageWithFallbackProps) => {
  const [imgSrc, setImgSrc] = useState<string | undefined>(src);

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  const style = mergeStyles({
    width: '100%',
    height: '100%',
    objectFit: objectFit ?? 'cover',
  });

  return imgSrc ? (
    <img
      className={classNames(style, className)}
      src={imgSrc}
      onError={() => {
        if (imgSrc === src && fallbackSrc && fallbackSrc !== src) {
          setImgSrc(fallbackSrc);
        } else {
          setImgSrc(undefined);
        }
      }}
      {...rest}
    />
  ) : (
    (fallback ?? null)
  );
};
