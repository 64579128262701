import { Icon, mergeStyleSets } from '@fluentui/react';
import { ArtifactType } from '@meetingflow/common/Api/data-contracts';
import classNames from 'classnames';
import { useMemo } from 'react';
import { artifactTypeToIconName } from '../../../Helpers/IconHelpers';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

type ArtifactIconPreviewProps = {
  className?: string;
  iconClassName?: string;
  type: ArtifactType;
  fileExtension?: string | null;
  mimeType?: string | null;
};

export const ArtifactIconPreview = ({
  className,
  iconClassName,
  type,
  fileExtension,
  mimeType,
}: ArtifactIconPreviewProps) => {
  const iconName = useMemo(
    () => artifactTypeToIconName(type, mimeType),
    [type, mimeType],
  );

  const styles = mergeStyleSets({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    icon: {
      fontSize: 278,
      color: DEALROOMS_COLORS.cloudburst,
      transform: 'rotate(45deg)',
      opacity: 0.1,
    },
  });

  return (
    <div className={classNames(styles.root, className)}>
      <Icon
        className={classNames(styles.icon, iconClassName)}
        iconName={iconName}
      />
    </div>
  );
};
