import { useForceUpdate } from '@fluentui/react-hooks';
import { WS_MESSAGE } from '@meetingflow/common/Constants';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import * as decoding from 'lib0/decoding';
import * as time from 'lib0/time';
import { DebouncedFunc, throttle } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import * as awarenessProtocol from 'y-protocols/awareness';
import { WebsocketProvider } from 'y-websocket';
import * as Y from 'yjs';
import { isUrlReachable } from '../Helpers/URLHelpers';
import { PresenceState } from '../types/MeetingPlanDocument';
import { useLightOrDarkMode } from './useLightOrDarkMode';
import { useNetworkStatus } from './useNetworkStatus';

const DC_TIMEOUT = 10500;

type UseCollabProviderOptions = {
  providerName: string;
  documentName: string;
  ydoc: Y.Doc;
  connect?: boolean;
  color: string;
  name?: string | null;
  email: string;
  picture?: string;
  onMessage?: (messageType: number) => boolean;
};

export const useCollabProvider = ({
  providerName,
  documentName,
  ydoc,
  connect = true,
  color,
  name,
  email,
  picture,
  onMessage,
}: UseCollabProviderOptions) => {
  const forceRender = useForceUpdate();
  const status = useRef<string>('disconnected');
  const [hasConnected, setHasConnected] = useState<boolean>(false);
  const [isSynced, setIsSynced] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const { isDark } = useLightOrDarkMode();
  const appInsights = useAppInsightsContext();

  const [provider, setProvider] = useState<WebsocketProvider | undefined>(
    undefined,
  );

  const [awarenessStates, setAwarenessStates] = useState<
    Map<number, PresenceState>
  >(new Map<number, PresenceState>());

  const networkConnected = useNetworkStatus();

  const createProvider = useCallback(() => {
    status.current = 'disconnected';
    setHasConnected(false);
    setIsSynced(false);
    setIsError(false);
    setProvider(() => {
      if (!documentName) {
        return undefined;
      }

      return new WebsocketProvider(
        `${import.meta.env.PROD ? 'wss' : 'ws'}://${
          import.meta.env.VITE_COLLAB_ENDPOINT
        }`,
        documentName,
        ydoc,
        {
          connect: false,
        },
      );
    });
  }, [documentName, ydoc]);

  const isCollabServerReachable: DebouncedFunc<() => Promise<boolean>> =
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useCallback(
      throttle(
        () =>
          isUrlReachable(
            `${import.meta.env.DEV ? 'http' : 'https'}://${
              import.meta.env.VITE_COLLAB_ENDPOINT
            }/`,
          ),
        15000,
        { leading: true },
      ),
      [],
    );

  useEffect(() => {
    if (!provider) {
      return;
    }

    if (connect) {
      provider.connect();
    }

    return () => {
      provider.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider]);

  useEffect(() => {
    if (!provider?.ws) {
      return;
    }

    const { ws } = provider;

    const existingListener = ws.onmessage?.bind(ws) || null;

    const dcTimeout = setInterval(() => {
      if (
        provider.ws &&
        provider.wsconnected &&
        provider.wsLastMessageReceived &&
        DC_TIMEOUT < time.getUnixTime() - provider.wsLastMessageReceived
      ) {
        console.warn(
          `${providerName}: ${time.getUnixTime()}: Collab connection timeout`,
        );
        appInsights.trackEvent({
          name: 'COLLAB_TIMEOUT',
          properties: {
            providerName,
            documentName,
          },
        });

        provider.disconnect();

        // manual websocket.onclose from y-websocket
        provider.wsconnecting = false;
        provider.wsconnected = false;
        provider.synced = false;
        provider.wsLastMessageReceived = 0;
        provider.ws = null;

        awarenessProtocol.removeAwarenessStates(
          provider.awareness,
          Array.from(provider.awareness.getStates().keys()).filter(
            (client) => client !== provider.doc.clientID,
          ),
          provider,
        );

        status.current = 'disconnected';
        forceRender();

        setImmediate(() => {
          provider.connect();
        });
      }
    }, DC_TIMEOUT / 5);

    ws.onmessage = (event) => {
      // Set wsLastMessageReceived to prevent dcTimeout
      provider.wsLastMessageReceived = time.getUnixTime();

      const decoder = decoding.createDecoder(new Uint8Array(event.data));
      const messageType = decoding.readVarUint(decoder);

      // If the caller has handled the message,
      // or it's a known MF specific type,
      // return
      if (
        onMessage?.(messageType) ||
        [
          WS_MESSAGE.REFRESH_ACTION_ITEMS,
          WS_MESSAGE.RECREATE,
          WS_MESSAGE.REFRESH_PLAN,
          WS_MESSAGE.SAVE,
          WS_MESSAGE.REFRESH_DEALROOM,
          WS_MESSAGE.REFRESH_DEALROOM_ARTIFACTS,
          WS_MESSAGE.REFRESH_DEALROOM_ACTION_ITEMS,
          WS_MESSAGE.HEARTBEAT,
        ].includes(messageType)
      ) {
        return;
      }

      existingListener?.(event);
    };

    return () => {
      clearInterval(dcTimeout);

      ws.onmessage = existingListener;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider?.ws, onMessage]);

  useEffect(() => {
    if (!provider) {
      return;
    }

    const onStatusChange = ({ status: newStatus }: { status: string }) => {
      console.info(
        `${providerName}: ${time.getUnixTime()}: Provider status change ${newStatus}`,
      );
      ReactDOM.unstable_batchedUpdates(() => {
        status.current = newStatus;
        if (newStatus === 'connected') {
          provider.wsLastMessageReceived = time.getUnixTime();
          setHasConnected(true);
        }
        forceRender();
      });
    };

    const onSynced = (synced: boolean) => {
      console.info(
        `${providerName}: ${time.getUnixTime()}: Provider synced ${synced}`,
      );
      // Set synced in timeout to give the Ydoc state chance to update
      setImmediate(() => setIsSynced(synced));
    };

    const onConnectionClose = () => {
      console.info(
        `${providerName}: ${time.getUnixTime()}: Provider connection closed`,
      );
    };

    const onConnectionError = (err: unknown) => {
      if (networkConnected) {
        isCollabServerReachable()?.then((reachable) => {
          if (reachable && !hasConnected) {
            console.info(
              `${providerName}: ${time.getUnixTime()}: Provider connection closed, unable to establish connection`,
            );
            setIsError(true);
            provider.disconnect();
          }
        });
      }
    };

    const onAwarenessChange = () => {
      setAwarenessStates(
        provider.awareness.getStates() as Map<number, PresenceState>,
      );
    };

    provider.on('status', onStatusChange);
    provider.on('sync', onSynced);
    provider.on('connection-close', onConnectionClose);
    provider.on('connection-error', onConnectionError);

    provider.awareness.on('change', onAwarenessChange);

    setAwarenessStates(
      provider.awareness.getStates() as Map<number, PresenceState>,
    );

    return () => {
      provider.off('status', onStatusChange);
      provider.off('sync', onSynced);
      provider.off('connection-close', onConnectionClose);
      provider.off('connection-error', onConnectionError);

      provider.awareness.off('change', onAwarenessChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasConnected, isCollabServerReachable, networkConnected, provider]);

  useEffect(() => {
    if (!provider || !provider.awareness) {
      return;
    }

    provider.awareness.setLocalState({
      alphaColor: isDark
        ? color.slice(0, -2) + '0.5)'
        : color.slice(0, -2) + '0.2)',
      color,
      name,
      email,
      picture,
    });
  }, [color, email, isDark, name, picture, provider]);

  useEffect(() => {
    createProvider();
  }, [createProvider]);

  return {
    provider,
    awarenessStates,
    status: status.current,
    isConnected: status.current === 'connected',
    isConnecting: status.current === 'connecting',
    isDisconnected: status.current === 'disconnected',
    isSynced,
    isError,
    hasConnected,
    recreateProvider: createProvider,
  };
};
