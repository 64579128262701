import { useAuth0 } from '@auth0/auth0-react';
import {
  Checkbox,
  DatePicker,
  Dropdown,
  FontIcon,
  FontSizes,
  NeutralColors,
  Spinner,
  SpinnerSize,
  Text,
  TextField,
  mergeStyles,
} from '@fluentui/react';
import { DeduplicateArray } from '@meetingflow/common/ArrayHelpers';
import {
  fromDateString,
  fromDateTimeString,
} from '@meetingflow/common/DateHelpers';
import { Truthy, toBoolean } from '@meetingflow/common/TypeHelpers';
import classnames from 'classnames';
import { isString } from 'lodash';
import { DateTime } from 'luxon';
import { useQuery } from 'react-query';
import {
  parseLocaleFloat,
  toParsedFloatLocaleString,
} from '../../../../Helpers/NumberHelpers';
import {
  serializeAddress,
  serializeLocation,
} from '../../../../Helpers/Salesforce/SalesforceFieldHelpers';
import { usePickSalesforceAccountModal } from '../../../../Hooks/Modals/usePickSalesforceAccountDialog';
import { usePickSalesforceContactModal } from '../../../../Hooks/Modals/usePickSalesforceContactDialog';
import { usePickSalesforceLeadModal } from '../../../../Hooks/Modals/usePickSalesforceLeadDialog';
import { usePickSalesforceOpportunityModal } from '../../../../Hooks/Modals/usePickSalesforceOpportunityDialog';
import { useExternalServiceConfigurations } from '../../../../Hooks/useExternalServiceConfigurations';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import { SalesforceAccount } from '../../../../Models/Salesforce/SalesforceAccount';
import { SalesforceAddress } from '../../../../Models/Salesforce/SalesforceAddress';
import { SalesforceContact } from '../../../../Models/Salesforce/SalesforceContact';
import { SalesforceLead } from '../../../../Models/Salesforce/SalesforceLead';
import { SalesforceLocation } from '../../../../Models/Salesforce/SalesforceLocation';
import { SalesforceField } from '../../../../Models/Salesforce/SalesforceObjectSchema';
import { SalesforceOpportunity } from '../../../../Models/Salesforce/SalesforceOpportunity';
import { SalesforceObjectQuery } from '../../../../QueryNames';
import { ApiClient } from '../../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../../Themes/Themes';
import { AsyncLink } from '../../../HOC/AsyncLink';
import { SalesforceAccountTile } from './SalesforceAccountTile';
import { SalesforceContactTile } from './SalesforceContactTile';
import { SalesforceLeadTile } from './SalesforceLeadTile';
import { SalesforceOpportunityTile } from './SalesforceOpportunityTile';

type SalesforceAccountLookupComponentProps = {
  organizationSlug: string;
  salesforceConfigurationId: number;
  accountId: string | null;
  onSelectAccount: (accountId: string | null) => void;
};
const SalesforceAccountLookupComponent = ({
  organizationSlug,
  salesforceConfigurationId,
  accountId,
  onSelectAccount,
}: SalesforceAccountLookupComponentProps) => {
  const { createDeferred, dialog } = usePickSalesforceAccountModal({
    organizationSlug,
    salesforceConfigurationId,
  });
  const { getAccessTokenSilently } = useAuth0();
  const { isDark } = useLightOrDarkMode();

  const { loading: salesforceConfigurationsLoading, configurationById } =
    useExternalServiceConfigurations({ app: 'SALESFORCE', withToken: true });

  const { data: salesforceObject, isLoading: salesforceObjectLoading } =
    useQuery(
      SalesforceObjectQuery(
        organizationSlug!,
        salesforceConfigurationId,
        'Account',
        accountId || undefined,
      ),
      async () => {
        const token = await getAccessTokenSilently();
        return ApiClient.get<SalesforceAccount>(
          `/organization/${organizationSlug}/external/salesforce/configuration/${salesforceConfigurationId}/object/Account/${accountId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      },
      {
        enabled: !!configurationById(salesforceConfigurationId) && !!accountId,
      },
    );

  if (salesforceConfigurationsLoading || salesforceObjectLoading) {
    return (
      <div style={{ height: '38px' }}>
        <Spinner size={SpinnerSize.small} />
      </div>
    );
  }

  const noDataClass = mergeStyles({
    position: 'relative',
    padding: '.25rem .5rem',
    borderRadius: '.25rem',
    height: '32px',
    backgroundColor: isDark ? NeutralColors.gray160 : MEETINGFLOW_COLORS.white,
    border: `1px solid ${
      isDark ? NeutralColors.gray140 : NeutralColors.gray60
    }`,
    transition: '.3s ease-in-out all',
    button: {
      display: 'block',
      lineHeight: '2rem',
      top: '0 !important',
      textOverflow: 'ellipsis !important',
      overflow: 'hidden !important',
      whiteSpace: 'nowrap !important',
      maxWidth: `100% !important`,
      color: `${MEETINGFLOW_COLORS.orange} !important`,
      width: '100%',
      ':hover': {
        textDecoration: 'none !important',
      },
    },
    ':hover': {
      border: `1px solid ${MEETINGFLOW_COLORS.purpleSecondary}`,
      cursor: 'pointer',
      textDecoration: 'none !important',
      backgroundColor: isDark
        ? NeutralColors.gray150
        : MEETINGFLOW_COLORS.purpleGrey,
    },
  });

  if (!accountId) {
    return (
      <div
        className={classnames(
          'account-lookup-field',
          'no-selection',
          noDataClass,
        )}
      >
        <AsyncLink
          onClick={async () => {
            try {
              const newAccountId = await createDeferred().promise;
              onSelectAccount(newAccountId);
            } catch (err) {
              console.error(err);
            }
          }}
        >
          Select Account
        </AsyncLink>
        {dialog}
      </div>
    );
  }

  if (!salesforceObject?.data) {
    return (
      <div className={classnames('account-lookup-field', 'error', noDataClass)}>
        <div>Could not find an Account with id {accountId}</div>
        <AsyncLink
          onClick={async () => {
            try {
              const newAccountId = await createDeferred().promise;
              onSelectAccount(newAccountId);
            } catch (err) {
              console.error(err);
            }
          }}
        >
          Choose new Account
        </AsyncLink>
        {dialog}
      </div>
    );
  }

  const account = salesforceObject?.data;

  const objectWrapperClass = mergeStyles({
    position: 'relative',
    padding: '.25rem .5rem',
    borderRadius: '.25rem',
    backgroundColor: isDark ? NeutralColors.gray160 : MEETINGFLOW_COLORS.white,
    border: `1px solid ${
      isDark ? NeutralColors.gray140 : NeutralColors.gray60
    }`,
    transition: '.3s ease-in-out all',
    '.content span': {
      lineHeight: '1rem',
      top: '0 !important',
      paddingRight: '5rem !important',
      textOverflow: 'ellipsis !important',
      overflow: 'hidden !important',
      whiteSpace: 'nowrap !important',
      maxWidth: `calc(100% - 6rem) !important`,
    },
    ':hover': {
      border: `1px solid ${MEETINGFLOW_COLORS.purpleSecondary}`,
      cursor: 'pointer',
      backgroundColor: isDark
        ? NeutralColors.gray150
        : MEETINGFLOW_COLORS.purpleGrey,
    },
    '.choose-label': {
      position: 'absolute',
      top: '.75rem',
      right: '.5rem',
      color: MEETINGFLOW_COLORS.orange,
      width: '3rem',
      fontSize: FontSizes.small,
    },
  });

  return (
    <div
      className={classnames('account-lookup-field', objectWrapperClass)}
      onClick={async () => {
        try {
          const newAccountId = await createDeferred().promise;
          onSelectAccount(newAccountId);
        } catch (err) {
          console.error(err);
        }
      }}
    >
      <SalesforceAccountTile
        name={account.Name}
        organizationSlug={organizationSlug}
        salesforceInstance={
          configurationById(salesforceConfigurationId)!.instanceId
        }
        externalId={accountId}
        showArrow={false}
        hideBottomBorder
      />
      <Text className="choose-label">Change</Text>
      {dialog}
    </div>
  );
};

type SalesforceOpportunityLookupComponentProps = {
  organizationSlug: string;
  salesforceConfigurationId: number;
  opportunityId: string | null;
  onSelectOpportunity: (opportunityId: string | null) => void;
};
const SalesforceOpportunityLookupComponent = ({
  organizationSlug,
  salesforceConfigurationId,
  opportunityId,
  onSelectOpportunity,
}: SalesforceOpportunityLookupComponentProps) => {
  const { createDeferred, dialog } = usePickSalesforceOpportunityModal({
    organizationSlug,
    salesforceConfigurationId,
  });
  const { getAccessTokenSilently } = useAuth0();
  const { isDark } = useLightOrDarkMode();

  const { loading: salesforceConfigurationsLoading, configurationById } =
    useExternalServiceConfigurations({ app: 'SALESFORCE', withToken: true });

  const { data: salesforceObject, isLoading: salesforceObjectLoading } =
    useQuery(
      SalesforceObjectQuery(
        organizationSlug!,
        salesforceConfigurationId,
        'Opportunity',
        opportunityId || undefined,
      ),
      async () => {
        const token = await getAccessTokenSilently();
        return ApiClient.get<SalesforceOpportunity>(
          `/organization/${organizationSlug}/external/salesforce/configuration/${salesforceConfigurationId}/object/Opportunity/${opportunityId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      },
      {
        enabled:
          !!configurationById(salesforceConfigurationId) && !!opportunityId,
      },
    );

  if (salesforceConfigurationsLoading || salesforceObjectLoading) {
    return (
      <div style={{ height: '38px' }}>
        <Spinner size={SpinnerSize.small} />
      </div>
    );
  }

  const noDataClass = mergeStyles({
    position: 'relative',
    padding: '.25rem .5rem',
    borderRadius: '.25rem',
    height: '32px',
    backgroundColor: isDark ? NeutralColors.gray160 : MEETINGFLOW_COLORS.white,
    border: `1px solid ${
      isDark ? NeutralColors.gray140 : NeutralColors.gray60
    }`,
    transition: '.3s ease-in-out all',
    button: {
      display: 'block',
      lineHeight: '2rem',
      top: '0 !important',
      textOverflow: 'ellipsis !important',
      overflow: 'hidden !important',
      whiteSpace: 'nowrap !important',
      maxWidth: `100% !important`,
      color: `${MEETINGFLOW_COLORS.orange} !important`,
      width: '100%',
      ':hover': {
        textDecoration: 'none !important',
      },
    },
    ':hover': {
      border: `1px solid ${MEETINGFLOW_COLORS.purpleSecondary}`,
      cursor: 'pointer',
      textDecoration: 'none !important',
      backgroundColor: isDark
        ? NeutralColors.gray150
        : MEETINGFLOW_COLORS.purpleGrey,
    },
  });

  if (!opportunityId) {
    return (
      <div
        className={classnames(
          'opportunity-lookup-field',
          'no-selection',
          noDataClass,
        )}
      >
        <AsyncLink
          onClick={async () => {
            try {
              const newOpportunityId = await createDeferred().promise;
              onSelectOpportunity(newOpportunityId);
            } catch (err) {
              console.error(err);
            }
          }}
        >
          Choose Opportunity
        </AsyncLink>
        {dialog}
      </div>
    );
  }

  if (!salesforceObject?.data) {
    return (
      <div
        className={classnames('opportunity-lookup-field', 'error', noDataClass)}
      >
        <div>Could not find an Opportunity with id {opportunityId}</div>
        <AsyncLink
          onClick={async () => {
            try {
              const newOpportunityId = await createDeferred().promise;
              onSelectOpportunity(newOpportunityId);
            } catch (err) {
              console.error(err);
            }
          }}
        >
          Select Opportunity
        </AsyncLink>
        {dialog}
      </div>
    );
  }

  const opportunity = salesforceObject?.data;

  const objectWrapperClass = mergeStyles({
    position: 'relative',
    padding: '.25rem .5rem',
    borderRadius: '.25rem',
    backgroundColor: isDark ? NeutralColors.gray160 : MEETINGFLOW_COLORS.white,
    border: `1px solid ${
      isDark ? NeutralColors.gray140 : NeutralColors.gray60
    }`,
    transition: '.3s ease-in-out all',
    '.content span': {
      lineHeight: '1rem',
      top: '0 !important',
      paddingRight: '5rem !important',
      textOverflow: 'ellipsis !important',
      overflow: 'hidden !important',
      whiteSpace: 'nowrap !important',
      maxWidth: `calc(100% - 6rem) !important`,
    },
    ':hover': {
      border: `1px solid ${MEETINGFLOW_COLORS.purpleSecondary}`,
      cursor: 'pointer',
      backgroundColor: isDark
        ? NeutralColors.gray150
        : MEETINGFLOW_COLORS.purpleGrey,
    },
    '.choose-label': {
      position: 'absolute',
      top: '.75rem',
      right: '.5rem',
      color: MEETINGFLOW_COLORS.orange,
      width: '3rem',
      fontSize: FontSizes.small,
    },
  });

  return (
    <div
      className={classnames('opportunity-lookup-field', objectWrapperClass)}
      onClick={async () => {
        try {
          const newOpportunityId = await createDeferred().promise;
          onSelectOpportunity(newOpportunityId);
        } catch (err) {
          console.error(err);
        }
      }}
    >
      <SalesforceOpportunityTile
        name={opportunity.Name}
        organizationSlug={organizationSlug}
        salesforceInstance={
          configurationById(salesforceConfigurationId)!.instanceId
        }
        externalId={opportunityId}
        showArrow={false}
        showExternalLink={false}
        accountName={opportunity.Account?.Name}
        hideBottomBorder
      />
      <Text className="choose-label">Change</Text>
      {dialog}
    </div>
  );
};

type SalesforceContactLookupComponentProps = {
  organizationSlug: string;
  salesforceConfigurationId: number;
  contactId: string | null;
  onSelectContact: (contactId: string | null) => void;
};
const SalesforceContactLookupComponent = ({
  organizationSlug,
  salesforceConfigurationId,
  contactId,
  onSelectContact,
}: SalesforceContactLookupComponentProps) => {
  const { createDeferred, dialog } = usePickSalesforceContactModal({
    organizationSlug,
    salesforceConfigurationId,
  });
  const { getAccessTokenSilently } = useAuth0();
  const { isDark } = useLightOrDarkMode();

  const { loading: salesforceConfigurationsLoading, configurationById } =
    useExternalServiceConfigurations({ app: 'SALESFORCE', withToken: true });

  const { data: salesforceObject, isLoading: salesforceObjectLoading } =
    useQuery(
      SalesforceObjectQuery(
        organizationSlug!,
        salesforceConfigurationId,
        'Contact',
        contactId || undefined,
      ),
      async () => {
        const token = await getAccessTokenSilently();
        return ApiClient.get<SalesforceContact>(
          `/organization/${organizationSlug}/external/salesforce/configuration/${salesforceConfigurationId}/object/Contact/${contactId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      },
      {
        enabled: !!configurationById(salesforceConfigurationId) && !!contactId,
      },
    );

  if (salesforceConfigurationsLoading || salesforceObjectLoading) {
    return (
      <div style={{ height: '38px' }}>
        <Spinner size={SpinnerSize.small} />
      </div>
    );
  }

  const noDataClass = mergeStyles({
    position: 'relative',
    padding: '.25rem .5rem',
    borderRadius: '.25rem',
    height: '32px',
    backgroundColor: isDark ? NeutralColors.gray160 : MEETINGFLOW_COLORS.white,
    border: `1px solid ${
      isDark ? NeutralColors.gray140 : NeutralColors.gray60
    }`,
    transition: '.3s ease-in-out all',
    button: {
      display: 'block',
      lineHeight: '2rem',
      top: '0 !important',
      textOverflow: 'ellipsis !important',
      overflow: 'hidden !important',
      whiteSpace: 'nowrap !important',
      maxWidth: `100% !important`,
      color: `${MEETINGFLOW_COLORS.orange} !important`,
      width: '100%',
      ':hover': {
        textDecoration: 'none !important',
      },
    },
    ':hover': {
      border: `1px solid ${MEETINGFLOW_COLORS.purpleSecondary}`,
      cursor: 'pointer',
      textDecoration: 'none !important',
      backgroundColor: isDark
        ? NeutralColors.gray150
        : MEETINGFLOW_COLORS.purpleGrey,
    },
  });

  if (!contactId) {
    return (
      <div
        className={classnames(
          'contact-lookup-field',
          'no-selection',
          noDataClass,
        )}
      >
        <AsyncLink
          onClick={async () => {
            try {
              const newContactId = await createDeferred().promise;
              onSelectContact(newContactId);
            } catch (err) {
              console.error(err);
            }
          }}
        >
          Select Contact
        </AsyncLink>
        {dialog}
      </div>
    );
  }

  if (!salesforceObject?.data) {
    return (
      <div className={classnames('contact-lookup-field', 'error', noDataClass)}>
        <div>Could not find a Contact with id {contactId}</div>
        <AsyncLink
          onClick={async () => {
            try {
              const newContactId = await createDeferred().promise;
              onSelectContact(newContactId);
            } catch (err) {
              console.error(err);
            }
          }}
        >
          Select Contact
        </AsyncLink>
        {dialog}
      </div>
    );
  }

  const contact = salesforceObject?.data;

  const objectWrapperClass = mergeStyles({
    position: 'relative',
    padding: '.25rem .5rem',
    borderRadius: '.25rem',
    backgroundColor: isDark ? NeutralColors.gray160 : MEETINGFLOW_COLORS.white,
    border: `1px solid ${
      isDark ? NeutralColors.gray140 : NeutralColors.gray60
    }`,
    transition: '.3s ease-in-out all',
    '.content span': {
      lineHeight: '1rem',
      top: '0 !important',
      paddingRight: '5rem !important',
      textOverflow: 'ellipsis !important',
      overflow: 'hidden !important',
      whiteSpace: 'nowrap !important',
      maxWidth: `calc(100% - 6rem) !important`,
    },
    ':hover': {
      border: `1px solid ${MEETINGFLOW_COLORS.purpleSecondary}`,
      cursor: 'pointer',
      backgroundColor: isDark
        ? NeutralColors.gray150
        : MEETINGFLOW_COLORS.purpleGrey,
    },
    '.choose-label': {
      position: 'absolute',
      top: '.75rem',
      right: '.5rem',
      color: MEETINGFLOW_COLORS.orange,
      width: '3rem',
      fontSize: FontSizes.small,
    },
  });

  return (
    <div
      className={classnames('contact-lookup-field', objectWrapperClass)}
      onClick={async () => {
        try {
          const newContactId = await createDeferred().promise;
          onSelectContact(newContactId);
        } catch (err) {
          console.error(err);
        }
      }}
    >
      <SalesforceContactTile
        organizationSlug={organizationSlug}
        salesforceInstance={
          configurationById(salesforceConfigurationId)!.instanceId
        }
        externalId={contactId}
        name={contact.Name}
        email={contact.Email}
        hideBottomBorder
        showExternalLink={false}
      />
      <Text className="choose-label">Change</Text>
      {dialog}
    </div>
  );
};

type SalesforceLeadLookupComponentProps = {
  organizationSlug: string;
  salesforceConfigurationId: number;
  leadId: string | null;
  onSelectLead: (leadId: string | null) => void;
};
const SalesforceLeadLookupComponent = ({
  organizationSlug,
  salesforceConfigurationId,
  leadId,
  onSelectLead,
}: SalesforceLeadLookupComponentProps) => {
  const { createDeferred, dialog } = usePickSalesforceLeadModal({
    organizationSlug,
    salesforceConfigurationId,
  });
  const { getAccessTokenSilently } = useAuth0();
  const { isDark } = useLightOrDarkMode();

  const { loading: salesforceConfigurationsLoading, configurationById } =
    useExternalServiceConfigurations({ app: 'SALESFORCE', withToken: true });

  const { data: salesforceObject, isLoading: salesforceObjectLoading } =
    useQuery(
      SalesforceObjectQuery(
        organizationSlug!,
        salesforceConfigurationId,
        'Lead',
        leadId || undefined,
      ),
      async () => {
        const token = await getAccessTokenSilently();
        return ApiClient.get<SalesforceLead>(
          `/organization/${organizationSlug}/external/salesforce/configuration/${salesforceConfigurationId}/object/Lead/${leadId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      },
      {
        enabled: !!configurationById(salesforceConfigurationId) && !!leadId,
      },
    );

  if (salesforceConfigurationsLoading || salesforceObjectLoading) {
    return (
      <div style={{ height: '38px' }}>
        <Spinner size={SpinnerSize.small} />
      </div>
    );
  }

  const noDataClass = mergeStyles({
    position: 'relative',
    padding: '.25rem .5rem',
    borderRadius: '.25rem',
    height: '32px',
    backgroundColor: isDark ? NeutralColors.gray160 : MEETINGFLOW_COLORS.white,
    border: `1px solid ${
      isDark ? NeutralColors.gray140 : NeutralColors.gray60
    }`,
    transition: '.3s ease-in-out all',
    button: {
      display: 'block',
      lineHeight: '2rem',
      top: '0 !important',
      textOverflow: 'ellipsis !important',
      overflow: 'hidden !important',
      whiteSpace: 'nowrap !important',
      maxWidth: `100% !important`,
      color: `${MEETINGFLOW_COLORS.orange} !important`,
      width: '100%',
      ':hover': {
        textDecoration: 'none !important',
      },
    },
    ':hover': {
      border: `1px solid ${MEETINGFLOW_COLORS.purpleSecondary}`,
      cursor: 'pointer',
      textDecoration: 'none !important',
      backgroundColor: isDark
        ? NeutralColors.gray150
        : MEETINGFLOW_COLORS.purpleGrey,
    },
  });

  if (!leadId) {
    return (
      <div
        className={classnames('lead-lookup-field', 'no-selection', noDataClass)}
      >
        <AsyncLink
          onClick={async () => {
            try {
              const newLeadId = await createDeferred().promise;
              onSelectLead(newLeadId);
            } catch (err) {
              console.error(err);
            }
          }}
        >
          Select Lead
        </AsyncLink>
        {dialog}
      </div>
    );
  }

  if (!salesforceObject?.data) {
    return (
      <div className={classnames('account-lookup-field', 'error', noDataClass)}>
        <div>Could not find a Lead with id {leadId}</div>
        <AsyncLink
          onClick={async () => {
            try {
              const newLeadId = await createDeferred().promise;
              onSelectLead(newLeadId);
            } catch (err) {
              console.error(err);
            }
          }}
        >
          Select Lead
        </AsyncLink>
        {dialog}
      </div>
    );
  }

  const lead = salesforceObject?.data;

  const objectWrapperClass = mergeStyles({
    position: 'relative',
    padding: '.25rem .5rem',
    borderRadius: '.25rem',
    backgroundColor: isDark ? NeutralColors.gray160 : MEETINGFLOW_COLORS.white,
    border: `1px solid ${
      isDark ? NeutralColors.gray140 : NeutralColors.gray60
    }`,
    transition: '.3s ease-in-out all',
    '.content span': {
      lineHeight: '1rem',
      top: '0 !important',
      paddingRight: '5rem !important',
      textOverflow: 'ellipsis !important',
      overflow: 'hidden !important',
      whiteSpace: 'nowrap !important',
      maxWidth: `calc(100% - 6rem) !important`,
    },
    ':hover': {
      border: `1px solid ${MEETINGFLOW_COLORS.purpleSecondary}`,
      cursor: 'pointer',
      backgroundColor: isDark
        ? NeutralColors.gray150
        : MEETINGFLOW_COLORS.purpleGrey,
    },
    '.choose-label': {
      position: 'absolute',
      top: '.75rem',
      right: '.5rem',
      color: MEETINGFLOW_COLORS.orange,
      width: '3rem',
      fontSize: FontSizes.small,
    },
  });

  return (
    <div
      className={classnames('lead-lookup-field', objectWrapperClass)}
      onClick={async () => {
        try {
          const newLeadId = await createDeferred().promise;
          onSelectLead(newLeadId);
        } catch (err) {
          console.error(err);
        }
      }}
    >
      <SalesforceLeadTile
        organizationSlug={organizationSlug}
        salesforceInstance={
          configurationById(salesforceConfigurationId)!.instanceId
        }
        externalId={leadId}
        name={lead.Name}
        ownerName={lead.Owner?.Name || lead.Owner?.Email || undefined}
        hideBottomBorder
        showExternalLink={false}
      />
      <Text className="choose-label">Change</Text>
      {dialog}
    </div>
  );
};

export type SalesforceFormComponentProps = {
  organizationSlug: string;
  salesforceConfigurationId: number;
  readonly?: boolean;
  field?: SalesforceField | null;
  isDirty?: boolean;
  errors?: Record<string, string[] | undefined>;
  hideLabel?: boolean;
  state: Record<
    string,
    | string
    | boolean
    | number
    | SalesforceAddress
    | SalesforceLocation
    | undefined
    | null
  >;
  setState: (
    value: React.SetStateAction<
      Record<
        string,
        | string
        | boolean
        | number
        | SalesforceAddress
        | SalesforceLocation
        | null
        | undefined
      >
    >,
  ) => void;
};
export const SalesforceFormComponent = ({
  organizationSlug,
  salesforceConfigurationId,
  field,
  errors,
  isDirty,
  readonly,
  hideLabel,
  state,
  setState,
}: SalesforceFormComponentProps) => {
  const { isDark } = useLightOrDarkMode();
  if (!field) {
    return null;
  }

  const fieldReadonly = !(
    field.createable ||
    field.updateable ||
    field.calculated
  );
  const fieldRequired = !field.nillable;

  const currentValue = state[field.name];

  const fieldWrapperStyles = {
    position: 'relative',
  } as React.CSSProperties;

  const clearButtonStyles = {
    display: 'inline-block',
    position: 'absolute',
    top: '2.15rem',
    right: '2.25rem',
  } as React.CSSProperties;

  const clearIconClass = mergeStyles({
    fontSize: '1.25rem',
    height: '1.25rem',
    width: '1.25rem',
    backgroundColor: isDark ? NeutralColors.gray190 : NeutralColors.gray20,
    color: isDark ? NeutralColors.gray20 : NeutralColors.gray120,
    borderRadius: '50%',
    textAlign: 'center',
    display: 'inline-block',
    lineHeight: '1.15rem',
    cursor: 'pointer',
    paddingLeft: '1px',
    transition: 'all 0.3s ease-in-out',

    ':hover': {
      backgroundColor: isDark ? NeutralColors.gray210 : NeutralColors.gray30,
      color: isDark ? NeutralColors.gray20 : NeutralColors.gray190,
    },
  });

  switch (field.type) {
    case 'address':
      return (
        <TextField
          key={field.name}
          disabled={true}
          errorMessage={errors?.[field.name]?.[0]}
          label={hideLabel ? undefined : field.label}
          multiline
          styles={{
            field: { height: '6rem' },
          }}
          value={serializeAddress(
            currentValue as SalesforceAddress | undefined | null,
          )}
          onChange={(_event, _newValue) => {}}
        />
      );
    case 'location':
      return (
        <TextField
          key={field.name}
          disabled={true}
          errorMessage={errors?.[field.name]?.[0]}
          label={hideLabel ? undefined : field.label}
          value={serializeLocation(
            currentValue as SalesforceLocation | undefined | null,
          )}
          onChange={(_event, _newValue) => {}}
        />
      );

    case 'currency':
      return (
        <TextField
          key={field.name}
          disabled={readonly || fieldReadonly}
          errorMessage={errors?.[field.name]?.[0]}
          label={hideLabel ? undefined : field.label}
          prefix="$"
          styles={{
            prefix: {
              backgroundColor: isDark
                ? NeutralColors.gray190
                : NeutralColors.gray20,
            },
          }}
          value={currentValue ? currentValue.toLocaleString() : ''}
          onChange={(_event, newValue) => {
            if (newValue && !isNaN(parseLocaleFloat(newValue))) {
              setState({
                ...state,
                [field.name]: toParsedFloatLocaleString(newValue),
              });
            } else {
              setState({
                ...state,
                [field.name]: newValue || null,
              });
            }
          }}
        />
      );
    case 'int':
      return (
        <TextField
          key={field.name}
          disabled={readonly || fieldReadonly}
          errorMessage={errors?.[field.name]?.[0]}
          label={hideLabel ? undefined : field.label}
          type="number"
          value={(currentValue as string | undefined | null) || ''}
          onChange={(_event, newValue) => {
            setState({
              ...state,
              [field.name]: newValue || null,
            });
          }}
        />
      );
    case 'percent':
      return (
        <TextField
          key={field.name}
          disabled={readonly || fieldReadonly}
          errorMessage={errors?.[field.name]?.[0]}
          label={hideLabel ? undefined : field.label}
          type="number"
          value={(currentValue as string | undefined | null) || ''}
          onChange={(_event, newValue) => {
            setState({
              ...state,
              [field.name]: newValue || null,
            });
          }}
        />
      );
    case 'date': {
      if (!fieldRequired && !!currentValue) {
        return (
          <div style={fieldWrapperStyles}>
            <DatePicker
              key={field.name}
              disabled={readonly || fieldReadonly}
              label={hideLabel ? undefined : field.label}
              value={fromDateString(currentValue as string | undefined | null)}
              onSelectDate={(date: Date | null | undefined) => {
                setState({
                  ...state,
                  [field.name]: date
                    ? DateTime.fromJSDate(date).toISO()!
                    : null,
                });
              }}
              placeholder="Select a date..."
              ariaLabel="Select a date"
            />
            <a
              onClick={() =>
                setState({
                  ...state,
                  [field.name]: null,
                })
              }
              title="Clear"
              content="Clear"
              style={clearButtonStyles}
            >
              <FontIcon
                iconName="StatusCircleErrorX"
                className={clearIconClass}
              />
            </a>
          </div>
        );
      }

      return (
        <DatePicker
          key={field.name}
          disabled={readonly || fieldReadonly}
          label={hideLabel ? undefined : field.label}
          value={fromDateString(currentValue as string | undefined | null)}
          onSelectDate={(date: Date | null | undefined) => {
            setState({
              ...state,
              [field.name]: date ? DateTime.fromJSDate(date).toISO()! : null,
            });
          }}
          placeholder="Select a date..."
          ariaLabel="Select a date"
        />
      );
    }
    case 'datetime': {
      if (!fieldRequired && !!currentValue) {
        return (
          <div style={fieldWrapperStyles}>
            <DatePicker
              key={field.name}
              disabled={readonly || fieldReadonly}
              label={hideLabel ? undefined : field.label}
              value={fromDateTimeString(
                currentValue as string | undefined | null,
              )}
              onSelectDate={(date: Date | null | undefined) => {
                setState({
                  ...state,
                  [field.name]: date
                    ? DateTime.fromJSDate(date).toISO()!
                    : null,
                });
              }}
              placeholder="Select a datetime..."
              ariaLabel="Select a datetime"
            />
            <a
              onClick={() =>
                setState({
                  ...state,
                  [field.name]: null,
                })
              }
              title="Clear"
              content="Clear"
              className="clear-button"
              style={clearButtonStyles}
            >
              <FontIcon
                iconName="StatusCircleErrorX"
                className={clearIconClass}
              />
            </a>
          </div>
        );
      }

      return (
        <DatePicker
          key={field.name}
          disabled={readonly || fieldReadonly}
          label={hideLabel ? undefined : field.label}
          value={fromDateTimeString(currentValue as string | undefined | null)}
          onSelectDate={(date: Date | null | undefined) => {
            setState({
              ...state,
              [field.name]: date ? DateTime.fromJSDate(date).toISO()! : null,
            });
          }}
          placeholder="Select a datetime..."
          ariaLabel="Select a datetime"
        />
      );
    }
    case 'boolean':
      return (
        <Checkbox
          key={field.name}
          disabled={readonly || fieldReadonly}
          label={hideLabel ? undefined : field.label}
          checked={toBoolean(
            (currentValue as string | undefined | null) || undefined,
          )}
          onChange={(_ev, checked) =>
            setState({
              ...state,
              [field.name]: checked ? 'true' : 'false',
            })
          }
          styles={{ root: { marginBottom: '.25rem' } }}
        />
      );
    case 'picklist': {
      let picklistOptions =
        field?.picklistValues
          ?.filter((v) => v.active || currentValue === v.value)
          ?.map((v) => ({
            key: v.value,
            text: v.label,
            disabled: !v.active,
          })) ?? [];

      if (
        !!currentValue &&
        !picklistOptions.some((o) => o.key === currentValue)
      ) {
        picklistOptions = [
          {
            key: currentValue as string,
            text: currentValue as string,
            disabled: true,
          },
          ...picklistOptions,
        ];
      }

      if (!fieldRequired && !!currentValue) {
        return (
          <div style={fieldWrapperStyles}>
            <Dropdown
              key={field.name}
              disabled={readonly || !field?.picklistValues?.length}
              errorMessage={errors?.[field.name]?.[0]}
              options={picklistOptions}
              defaultSelectedKey={
                field?.picklistValues?.find((p) => p.defaultValue)?.value
              }
              selectedKey={currentValue as string | undefined | null}
              onChange={(_evt, option, _index) => {
                setState({
                  ...state,
                  [field.name]: (option?.key as string | undefined) || null,
                });
              }}
              label={hideLabel ? undefined : field.label}
            />
            <a
              onClick={() =>
                setState({
                  ...state,
                  [field.name]: null,
                })
              }
              title="Clear"
              content="Clear"
              className="clear-button"
              style={clearButtonStyles}
            >
              <FontIcon
                iconName="StatusCircleErrorX"
                className={clearIconClass}
              />
            </a>
          </div>
        );
      }

      return (
        <Dropdown
          key={field.name}
          disabled={readonly || !field?.picklistValues?.length}
          errorMessage={errors?.[field.name]?.[0]}
          options={picklistOptions}
          defaultSelectedKey={
            field?.picklistValues?.find((p) => p.defaultValue)?.value
          }
          selectedKey={currentValue as string | undefined | null}
          onChange={(_evt, option, _index) => {
            setState({
              ...state,
              [field.name]: (option?.key as string | undefined) || null,
            });
          }}
          label={hideLabel ? undefined : field.label}
        />
      );
    }
    case 'multipicklist': {
      const selectedKeys = isString(currentValue)
        ? currentValue.split(';').filter(Truthy)
        : [];

      let multiPicklistOptions =
        field?.picklistValues
          ?.filter((v) => v.active || selectedKeys.includes(v.value))
          ?.map((v) => ({
            key: v.value,
            text: v.label,
            disabled: !v.active,
          })) ?? [];

      const missingKeys = selectedKeys.filter(
        (key) => !multiPicklistOptions.some((o) => o.key === key),
      );

      if (missingKeys.length) {
        multiPicklistOptions = [
          ...missingKeys.map((key) => ({ key, text: key, disabled: true })),
          ...multiPicklistOptions,
        ];
      }

      if (!fieldRequired && !!currentValue) {
        return (
          <div style={fieldWrapperStyles}>
            <Dropdown
              key={field.name}
              multiSelect
              disabled={readonly || !field?.picklistValues?.length}
              errorMessage={errors?.[field.name]?.[0]}
              options={multiPicklistOptions}
              defaultSelectedKeys={
                field?.picklistValues
                  ?.filter((p) => p.defaultValue)
                  .map((p) => p.value) ?? []
              }
              selectedKeys={selectedKeys}
              onChange={(_evt, option, _index) => {
                if (!option) {
                  setState({
                    ...state,
                    [field.name]: null,
                  });
                } else if (option.selected) {
                  setState({
                    ...state,
                    [field.name]:
                      DeduplicateArray([option.key, ...selectedKeys])
                        .filter(Truthy)
                        .join(';') || null,
                  });
                  return;
                } else {
                  setState({
                    ...state,
                    [field.name]:
                      selectedKeys
                        .filter((key) => key !== option.key)
                        .filter(Truthy)
                        .join(';') || null,
                  });
                }
              }}
              label={hideLabel ? undefined : field.label}
            />
            <a
              onClick={() =>
                setState({
                  ...state,
                  [field.name]: null,
                })
              }
              title="Clear"
              content="Clear"
              className="clear-button"
              style={clearButtonStyles}
            >
              <FontIcon
                iconName="StatusCircleErrorX"
                className={clearIconClass}
              />
            </a>
          </div>
        );
      }

      return (
        <Dropdown
          key={field.name}
          multiSelect
          disabled={readonly || !field?.picklistValues?.length}
          errorMessage={errors?.[field.name]?.[0]}
          options={multiPicklistOptions}
          defaultSelectedKeys={
            field?.picklistValues
              ?.filter((p) => p.defaultValue)
              .map((p) => p.value) ?? []
          }
          selectedKeys={selectedKeys}
          onChange={(_evt, option, _index) => {
            if (!option) {
              setState({
                ...state,
                [field.name]: null,
              });
            } else if (option.selected) {
              setState({
                ...state,
                [field.name]:
                  DeduplicateArray([option.key, ...selectedKeys])
                    .filter(Truthy)
                    .join(';') || null,
              });
              return;
            } else {
              setState({
                ...state,
                [field.name]:
                  selectedKeys
                    .filter((key) => key !== option.key)
                    .filter(Truthy)
                    .join(';') || null,
              });
            }
          }}
          label={hideLabel ? undefined : field.label}
        />
      );
    }
    case 'textarea':
      if (field.length > 255) {
        return (
          <TextField
            key={field.name}
            disabled={readonly || fieldReadonly}
            multiline
            styles={{
              field: { height: '5rem' },
            }}
            errorMessage={errors?.[field.name]?.[0]}
            label={hideLabel ? undefined : field.label}
            value={(currentValue as string | undefined | null) || ''}
            onChange={(_event, newValue) => {
              setState({ ...state, [field.name]: newValue || null });
            }}
          />
        );
      }

      return (
        <TextField
          key={field.name}
          disabled={readonly || fieldReadonly}
          errorMessage={errors?.[field.name]?.[0]}
          label={hideLabel ? undefined : field.label}
          value={(currentValue as string | undefined | null) || ''}
          onChange={(_event, newValue) => {
            setState({ ...state, [field.name]: newValue || null });
          }}
        />
      );
    case 'reference': {
      if (
        field.referenceTo?.length !== 1 ||
        !['Account', 'Opportunity', 'Contact', 'Lead'].includes(
          field.referenceTo[0],
        )
      ) {
        return <div>Unsupported Lookup field</div>;
      }
      const lookupType = field.referenceTo[0];
      let rendered = <div>{lookupType}</div>;
      switch (lookupType) {
        case 'Account': {
          rendered = (
            <SalesforceAccountLookupComponent
              organizationSlug={organizationSlug}
              salesforceConfigurationId={salesforceConfigurationId}
              accountId={currentValue as string | null}
              onSelectAccount={(newValue) =>
                setState({ ...state, [field.name]: newValue || null })
              }
            />
          );
          break;
        }
        case 'Opportunity': {
          rendered = (
            <SalesforceOpportunityLookupComponent
              organizationSlug={organizationSlug}
              salesforceConfigurationId={salesforceConfigurationId}
              opportunityId={currentValue as string | null}
              onSelectOpportunity={(newValue) =>
                setState({ ...state, [field.name]: newValue || null })
              }
            />
          );
          break;
        }
        case 'Contact': {
          rendered = (
            <SalesforceContactLookupComponent
              organizationSlug={organizationSlug}
              salesforceConfigurationId={salesforceConfigurationId}
              contactId={currentValue as string | null}
              onSelectContact={(newValue) =>
                setState({ ...state, [field.name]: newValue || null })
              }
            />
          );
          break;
        }
        case 'Lead': {
          rendered = (
            <SalesforceLeadLookupComponent
              organizationSlug={organizationSlug}
              salesforceConfigurationId={salesforceConfigurationId}
              leadId={currentValue as string | null}
              onSelectLead={(newValue) =>
                setState({ ...state, [field.name]: newValue || null })
              }
            />
          );
          break;
        }
        default: {
          rendered = <div>Unsupported relation with type {lookupType}</div>;
        }
      }
      return (
        <div style={fieldWrapperStyles} className="lookup-field">
          {!hideLabel ? (
            <Text
              variant="medium"
              style={{
                boxSizing: 'border-box',
                boxShadow: 'none',
                margin: '0px',
                display: 'block',
                overflowWrap: 'break-word',
                fontWeight: 600,
                padding: '5px 0px',
              }}
            >
              {field.label}
            </Text>
          ) : null}
          {rendered}
          {!fieldRequired && !!currentValue ? (
            <a
              onClick={() =>
                setState({
                  ...state,
                  [field.name]: null,
                })
              }
              title="Clear"
              content="Clear"
              className="clear-button"
              style={{ ...clearButtonStyles, top: '2.5rem', right: '4rem' }}
            >
              <FontIcon
                iconName="StatusCircleErrorX"
                className={clearIconClass}
              />
            </a>
          ) : null}
        </div>
      );
    }
    case 'string':
    default:
      return (
        <TextField
          key={field.name}
          disabled={readonly || fieldReadonly}
          errorMessage={errors?.[field.name]?.[0]}
          label={hideLabel ? undefined : field.label}
          value={(currentValue as string | undefined | null) || ''}
          onChange={(_event, newValue) => {
            setState({ ...state, [field.name]: newValue || null });
          }}
        />
      );
  }
};
