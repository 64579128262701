import React, { useState } from 'react';
import { TextField } from '@fluentui/react';
import { getCreateSiteStyles } from './styles';

interface CreateDecisionSiteProps {
  siteName: string;
  setSiteName: (name: string) => void;
  companyName: string;
  setCompanyName: (name: string) => void;
  description: string;
  setDescription: (description: string) => void;
}

export const CreateDecisionSite: React.FC<CreateDecisionSiteProps> = ({
  siteName,
  setSiteName,
  companyName,
  setCompanyName,
  description,
  setDescription,
}) => {
  const styles = getCreateSiteStyles();
  const [domainError, setDomainError] = useState<string>('');

  const validateDomain = (domain: string): boolean => {
    if (!domain) {
      setDomainError('Company domain is required');
      return false;
    }

    try {
      // Remove protocol if present
      const cleanDomain = domain.replace(/^(https?:\/\/)/, '');
      // Basic domain validation regex
      const domainRegex = /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
      
      if (!domainRegex.test(cleanDomain)) {
        setDomainError('Please enter a valid domain (e.g., augment.co)');
        return false;
      }

      setDomainError('');
      return true;
    } catch (error) {
      setDomainError('Invalid domain format');
      return false;
    }
  };

  const handleDomainChange = (newValue: string = '') => {
    setCompanyName(newValue);
    validateDomain(newValue);
  };

  return (
    <div className={styles.content}>
      <TextField
        label="Decision Site Name"
        placeholder="Enter the Decision Site Name"
        required
        value={siteName}
        onChange={(_, newValue) => setSiteName(newValue || '')}
      />
      
      <TextField
        label="Company Domain"
        placeholder="Enter the other party's domain (e.g., augment.co)"
        required
        value={companyName}
        onChange={(_, newValue) => handleDomainChange(newValue)}
        errorMessage={domainError}
        validateOnLoad={false}
        validateOnFocusOut
        onGetErrorMessage={() => domainError}
      />

      <TextField
        label="Description"
        placeholder="Enter a description for this Decision Site"
        multiline
        rows={3}
        value={description}
        onChange={(_, newValue) => setDescription(newValue || '')}
      />
    </div>
  );
};