import { CalendarEvent, PostMeetingflowPayload } from '@meetingflow/common/Api/data-contracts';
import { MeetingflowsApiClient, DealRoomsApiClient } from '../Services/NetworkCommon';
import { isAxiosErrorResponse } from '../Helpers/AxiosHelpers';
import { IAppInsights } from '@microsoft/applicationinsights-web';
import { QueryClient } from 'react-query';
import {
  OrganizationMeetingPlansQuery,
  OrganizationMeetingsHappeningSoon,
  OrganizationUpcomingMeetings,
  MeetingPlanQuery,
} from '../QueryNames';
import toast from 'react-hot-toast';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const DEFAULT_PLANS_PER_PAGE = 30;

interface CreateMeetingflowOptions {
  event?: CalendarEvent;
  organizationSlug: string;
  scheduleCallRecording?: boolean;
  token: string;
  dealRoomId?: number;
  appInsights?: ReactPlugin;
  queryClient: QueryClient;
  onSuccess?: (meetingflowId: string) => void;
}

interface CreateMeetingflowResult {
  meetingflowId: string;
  status: number;
}

interface CreateMeetingflowAndDecisionSiteArtifactOptions extends CreateMeetingflowOptions {
  dealRoomId: number; // Make dealRoomId required for this operation
}

interface DeleteMeetingflowAndDecisionSiteArtifactOptions {
  organizationSlug: string;
  dealRoomId: number;
  meetingflowId: string;
  token: string;
  queryClient: QueryClient;
  appInsights?: ReactPlugin;
}

interface RemoveMeetingflowArtifactFromDecisionSiteOptions {
  organizationSlug: string;
  dealRoomId: number;
  meetingflowId: string;
  token: string;
  queryClient: QueryClient;
  appInsights?: ReactPlugin;
}

interface ToggleMeetingflowFeatureOptions {
  organizationSlug: string;
  dealRoomId: number;
  meetingflowId: string;
  token: string;
  queryClient: QueryClient;
  appInsights?: ReactPlugin;
}

export const invalidateMeetingQueries = async (
  queryClient: QueryClient,
  organizationSlug: string,
  dealRoomId: number,
) => {
  await Promise.all([
    queryClient.invalidateQueries({
      predicate: (query) => {
        const key = query.queryKey;
        if (!Array.isArray(key)) return false;
        const [type, org, , , , , id] = key;
        return (
          type === 'organization' &&
          org === organizationSlug &&
          id === dealRoomId
        );
      },
    }),
    queryClient.invalidateQueries(OrganizationMeetingsHappeningSoon(organizationSlug)),
    queryClient.invalidateQueries(OrganizationUpcomingMeetings(organizationSlug)),
    queryClient.invalidateQueries({
      queryKey: ['artifacts', dealRoomId, organizationSlug],
    }),
  ]);
};

export const createMeetingflow = async ({
  event,
  organizationSlug,
  scheduleCallRecording = false,
  token,
  dealRoomId,
  appInsights,
  queryClient,
  onSuccess,
}: CreateMeetingflowOptions): Promise<CreateMeetingflowResult> => {
  return await toast.promise(
    (async () => {
      let reqBody: PostMeetingflowPayload = { record: scheduleCallRecording };
      if (event) {
        reqBody = {
          source: event.source,
          eventId: event.externalId,
          eventTime: event.startTime,
          record: scheduleCallRecording,
        };
      }

      const result = await MeetingflowsApiClient.postMeetingflow(organizationSlug, reqBody, {
        headers: { Authorization: `Bearer ${token}` },
        validateStatus: (code) => code === 201 || code === 302,
      });

      if (dealRoomId) {
        await invalidateMeetingQueries(queryClient, organizationSlug, dealRoomId);
      } else {
        await invalidateMeetingQueries(queryClient, organizationSlug, 0);
      }

      queryClient.setQueryData(
        MeetingPlanQuery(organizationSlug, result.data.id),
        result,
      );

      if (result.status === 201 && appInsights) {
        appInsights.trackEvent({
          name: `CREATE_PLAN${event ? '_FOR_EVENT' : '_ADHOC'}`,
          properties: {
            organizationSlug,
            source: event ? event?.source : '',
            eventId: event ? event?.externalId : '',
          },
        });
      }

      if (onSuccess) {
        onSuccess(result.data.id);
      }

      return {
        meetingflowId: result.data.id,
        status: result.status,
      };
    })(),
    {
      loading: 'Creating Meetingflow',
      success: () => `A Meetingflow ${event ? `for ${event?.title}` : ''} has been created!`,
      error: (err) => handleMeetingflowError(err, organizationSlug, event, appInsights),
    }
  );
};

export const createMeetingflowAndDecisionSiteArtifact = async ({
  event,
  organizationSlug,
  scheduleCallRecording = false,
  token,
  dealRoomId,
  appInsights,
  queryClient,
  onSuccess,
}: CreateMeetingflowAndDecisionSiteArtifactOptions): Promise<CreateMeetingflowResult> => {
  const result = await createMeetingflow({
    event,
    organizationSlug,
    scheduleCallRecording,
    token,
    dealRoomId,
    appInsights,
    queryClient,
  });

  await createMeetingflowLinkArtifact(organizationSlug, dealRoomId, result.meetingflowId, token);

  await invalidateMeetingQueries(queryClient, organizationSlug, dealRoomId);

  if (onSuccess) {
    onSuccess(result.meetingflowId);
  }

  return result;
};

export const createMeetingflowLinkArtifact = async (
  organizationSlug: string,
  dealRoomId: number,
  meetingflowId: string,
  token: string,
): Promise<void> => {
  await toast.promise(
    DealRoomsApiClient.createLinkArtifact(
      organizationSlug,
      dealRoomId,
      {
        type: 'MEETINGFLOW',
        meetingflowId: meetingflowId,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    ),
    {
      loading: 'Uploading attachment...',
      success: 'Successfully uploaded Meetingflow attachment',
      error: () => 'Failed to upload Meetingflow attachment',
    }
  );
};

export const deleteMeetingflowAndDecisionSiteArtifact = async ({
  organizationSlug,
  dealRoomId,
  meetingflowId,
  token,
  queryClient,
  appInsights,
}: DeleteMeetingflowAndDecisionSiteArtifactOptions): Promise<void> => {
  return await toast.promise(
    (async () => {

      // Get a list of artifacts
      const artifacts = await DealRoomsApiClient.listArtifacts(
        { organizationSlug, dealRoomId },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      

      // Find the meetingflow artifact
      const artifactId = artifacts.data.find(
        (artifact) => artifact.type === 'MEETINGFLOW' && artifact.meetingflowId === meetingflowId,
      )?.id;

      if (!artifactId) {
        throw new Error("Could not find an artifact for this Meetingflow in this Decision Site to delete.");
      }

      // First delete the link artifact
      await DealRoomsApiClient.deleteArtifact(
        organizationSlug,
        dealRoomId,
        artifactId,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      // Then delete the meetingflow itself — but only if there was an artifact for it.
      await MeetingflowsApiClient.deleteMeetingflow(organizationSlug, meetingflowId, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Invalidate relevant queries
      await invalidateMeetingQueries(queryClient, organizationSlug, dealRoomId);

      if (appInsights) {
        appInsights.trackEvent({
          name: 'DELETE_PLAN',
          properties: {
            organizationSlug,
            meetingflowId,
          },
        });
      }
    })(),
    {
      loading: 'Deleting Meetingflow...',
      success: 'Successfully deleted Meetingflow',
      error: (err) => {
        console.error('Error deleting meetingflow:', err);
        return 'Failed to delete Meetingflow';
      },
    }
  );
};

export const removeMeetingflowArtifactFromDecisionSite = async ({
  organizationSlug,
  dealRoomId,
  meetingflowId,
  token,
  queryClient,
  appInsights,
}: RemoveMeetingflowArtifactFromDecisionSiteOptions): Promise<void> => {
  return await toast.promise(
    (async () => {
      try {
        // Get the list of artifacts to find the one associated with this meetingflow
        const artifacts = await DealRoomsApiClient.listArtifacts(
          { organizationSlug, dealRoomId },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        // Find the meetingflow artifact
        const artifact = artifacts.data.find(
          (artifact) => artifact.type === 'MEETINGFLOW' && artifact.meetingflowId === meetingflowId,
        );

        if (!artifact) {
          throw new Error("Could not find an artifact for this Meetingflow in this Decision Site.");
        }

        // Delete just the artifact
        await DealRoomsApiClient.deleteArtifact(
          organizationSlug,
          dealRoomId,
          artifact.id,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        if (appInsights) {
          appInsights.trackEvent({
            name: 'REMOVE_MEETINGFLOW_FROM_DECISION_SITE',
            properties: {
              organizationSlug,
              dealRoomId,
              meetingflowId,
            },
          });
        }

        await invalidateMeetingQueries(queryClient, organizationSlug, dealRoomId);
      } catch (error) {
        console.error('Error removing meetingflow from decision site:', error);
        throw error;
      }
    })(),
    {
      loading: 'Removing Meetingflow from Decision Site...',
      success: 'Successfully removed Meetingflow from Decision Site',
      error: 'Failed to remove Meetingflow from Decision Site',
    }
  );
};

export const toggleMeetingflowFeature = async ({
  organizationSlug,
  dealRoomId,
  meetingflowId,
  token,
  queryClient,
  appInsights,
}: ToggleMeetingflowFeatureOptions): Promise<boolean> => {
  return await toast.promise(
    (async () => {
      try {
        // Get the list of artifacts to find the one associated with this meetingflow
        const artifacts = await DealRoomsApiClient.listArtifacts(
          { organizationSlug, dealRoomId },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        // Find the meetingflow artifact
        const artifact = artifacts.data.find(
          (artifact) => artifact.type === 'MEETINGFLOW' && artifact.meetingflowId === meetingflowId,
        );

        if (!artifact) {
          throw new Error("Could not find an artifact for this Meetingflow in this Decision Site.");
        }

        // Feature/unfeature the artifact
        if (artifact.featuredAt) {
          await DealRoomsApiClient.unfeatureArtifact(
            organizationSlug,
            dealRoomId,
            artifact.id,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
        } else {
          await DealRoomsApiClient.featureArtifact(
            {
              organizationSlug,
              dealRoomId,
              artifactId: artifact.id,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
        }

        if (appInsights) {
          appInsights.trackEvent({
            name: artifact.featuredAt ? 'UNFEATURE_MEETINGFLOW' : 'FEATURE_MEETINGFLOW',
            properties: {
              organizationSlug,
              dealRoomId,
              meetingflowId,
            },
          });
        }

        await invalidateMeetingQueries(queryClient, organizationSlug, dealRoomId);
        
        // Return the new featured state
        return !artifact.featuredAt;
      } catch (error) {
        console.error('Error toggling meetingflow feature state:', error);
        throw error;
      }
    })(),
    {
      loading: 'Updating Meetingflow feature state...',
      success: (isFeatured) => 
        isFeatured 
          ? 'Successfully featured Meetingflow in Shared With You'
          : 'Successfully removed Meetingflow from Shared With You',
      error: 'Failed to update Meetingflow feature state',
    }
  );
};

export const handleMeetingflowError = (
  error: unknown,
  organizationSlug: string,
  event: CalendarEvent | undefined,
  appInsights?: ReactPlugin,
): string => {
  if (error && isAxiosErrorResponse(error, 403)) {
    return `You don't have permission to create Meetingflows in this workspace`;
  }

  if (appInsights) {
    appInsights.trackException({
      exception: error instanceof Error ? error : new Error(String(error)),
      properties: {
        organizationSlug,
        eventSource: event?.source,
        eventId: event?.externalId,
        status: isAxiosErrorResponse(error) ? error.response?.status : undefined,
        statusText: isAxiosErrorResponse(error) ? error.response?.statusText : undefined,
      },
    });

    appInsights.trackEvent({
      name: 'CREATE_PLAN_FAILED',
      properties: {
        organizationSlug,
        source: event?.source,
        eventId: event?.externalId,
        status: isAxiosErrorResponse(error) ? error.response?.status : undefined,
        statusText: isAxiosErrorResponse(error) ? error.response?.statusText : undefined,
      },
    });
  }

  return 'Something went wrong creating the Meetingflow, please try again';
};
