import { FontWeights, IconButton, mergeStyles } from '@fluentui/react';
import { useSlate } from 'slate-react';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { indentList, isListActive } from '../Helpers/EditorHelpers';

export function ListIndentButton() {
  const editor = useSlate();
  const listActive = isListActive(editor);

  const iconButtonClass = mergeStyles({
    backgroundColor: 'transparent',
    color: MEETINGFLOW_COLORS.white,
    width: '1.75rem',
    height: '1.75rem',
    i: {
      fontWeight: FontWeights.semibold,
    },

    ':hover': {
      backgroundColor: MEETINGFLOW_COLORS.purpleSecondary,
      color: 'white !important',
    },
  });

  return (
    <IconButton
      disabled={!listActive}
      iconProps={{ iconName: 'IncreaseIndentLegacy' }}
      className={iconButtonClass}
      title={`Increase list indent`}
      alt={`Increase list indent`}
      data-meetingflow-is-active={isListActive}
      onClick={(event) => {
        event.preventDefault();
        indentList(editor);
      }}
    />
  );
}
