import { PropsWithChildren } from 'react';
import { Icon, mergeStyleSets, Text } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

interface InfoSectionProps {
  headerIconName: string;
  title: string;
}

export const InfoSection = ({
  headerIconName,
  title,
  children,
}: PropsWithChildren<InfoSectionProps>) => {
  const styles = mergeStyleSets({
    infoSection: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'stretch',
      border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
      borderRadius: '.25rem',
      backgroundColor: DEALROOMS_COLORS.neutralGray,
      boxShadow: '0px 2px 3px 0px #00000008',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: '0.5rem',
      padding: '1.375rem 1.5rem',
      backgroundColor: DEALROOMS_COLORS.white,
      borderBottom: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
      borderRadius: '.25rem .25rem 0 0',
      gap: '.5rem',
    },
    headerIcon: {
      ':has(svg)': {
        display: 'flex',
        height: 'auto',
      },
    },
    title: {
      color: DEALROOMS_COLORS.themePrimary,
      fontSize: '1.5rem',
      lineHeight: '2.5rem',
      fontWeight: '400',
    },
    content: {
      display: 'flex',
      padding: '2rem 1.5rem',
      borderRadius: '0 0 .25rem .25rem',
    },
  });

  return (
    <div className={styles.infoSection}>
      <div className={styles.header}>
        <Icon iconName={headerIconName} className={styles.headerIcon} />
        <Text className={styles.title}>{title}</Text>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
