import { useAuth0 } from '@auth0/auth0-react';
import {
  DefaultButton,
  FontIcon,
  FontSizes,
  FontWeights,
  Icon,
  NeutralColors,
  Spinner,
  Text,
  TooltipHost,
  mergeStyles,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { DetailedMeetingflow } from '@meetingflow/common/Api/data-contracts';
import PublicEmailDomains from '@meetingflow/common/PublicEmailDomains';
import { Truthy } from '@meetingflow/common/TypeHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import classnames from 'classnames';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import 'react-customize-token-input/dist/react-customize-token-input.css';
import toast from 'react-hot-toast';
import ReactMarkdown from 'react-markdown';
import rehypeExternalLinks, {
  Options as RehypeExternalLinksOptions,
} from 'rehype-external-links';
import remarkGfm from 'remark-gfm';
import { AsyncPrimaryButton } from '../../../Components/HOC/AsyncButton';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { useScrollToBottom } from '../../../Hooks/useScrollToBottom';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { BaseSidePanel } from './BaseSidePanel';

type GenerateStep = {
  key: string;
  iconName: string;
  text: string;
  minDuration?: number;
  maxDuration?: number;
};

export interface DossierSidePanelProps {
  organizationSlug: string;
  meetingPlanId: string;
  internalDomains: string[];
  meetingplan?: Pick<DetailedMeetingflow, 'attendees' | 'dossier'>;
  onBack?: () => void;
  onClose: () => void;
  onGeneratedDossier: () => Promise<unknown>;
}

export const DossierSidePanel = ({
  organizationSlug,
  meetingPlanId,
  internalDomains,
  meetingplan,
  onBack,
  onClose,
  onGeneratedDossier,
}: DossierSidePanelProps) => {
  const abortController = useRef<AbortController>();
  const dossierRef = React.useRef<HTMLDivElement>(null);

  const { isDark } = useLightOrDarkMode();
  const { getAccessTokenSilently } = useAuth0();
  const [
    isGenerating,
    {
      toggle: toggleGenerating,
      setTrue: setGenerating,
      setFalse: setNotGenerating,
    },
  ] = useBoolean(false);

  const appInsights = useAppInsightsContext();

  const [generateStep, setGenerateStep] = useState<string | undefined>(
    undefined,
  );
  const [dossier, setDossier] = useState<string>(meetingplan?.dossier || '');

  const generateSteps = useMemo(() => {
    if (!meetingplan) {
      return [
        {
          key: 'meetingflow',
          iconName: 'ReadingMode',
          text: 'Reading Meetingflow',
          minDuration: 3,
          maxDuration: 5,
        },
        {
          key: 'attendees',
          iconName: 'People',
          text: 'Researching attendees',
          minDuration: 3,
          maxDuration: 5,
        },
        {
          key: 'companies',
          iconName: 'Work',
          text: 'Researching companies',
          minDuration: 5,
          maxDuration: 7,
        },
        {
          key: 'news',
          iconName: 'News',
          text: 'Looking for related news articles',
          minDuration: 2,
          maxDuration: 4,
        },
        {
          key: 'related',
          iconName: 'SeeDo',
          text: 'Finding related Meetingflows',
          minDuration: 5,
          maxDuration: 7,
        },
        {
          key: 'reviewing',
          iconName: 'Source',
          text: 'Reviewing information',
        },
      ] satisfies GenerateStep[];
    }

    const hasExternalAttendees = meetingplan.attendees.some(
      (attendee) => !internalDomains.includes(attendee.emailDomain),
    );
    const hasExternalCompany = meetingplan.attendees.some(
      (attendee) =>
        !internalDomains.includes(attendee.emailDomain) &&
        !PublicEmailDomains.includes(attendee.emailDomain),
    );

    return [
      {
        key: 'meetingflow',
        iconName: 'ReadingMode',
        text: 'Reading Meetingflow',
        minDuration: 3,
        maxDuration: 5,
      },
      hasExternalAttendees
        ? {
            key: 'attendees',
            iconName: 'People',
            text: 'Researching external attendees',
            minDuration: 3,
            maxDuration: 5,
          }
        : undefined,
      hasExternalCompany
        ? {
            key: 'companies',
            iconName: 'Work',
            text: 'Researching external companies',
            minDuration: 5,
            maxDuration: 7,
          }
        : undefined,
      {
        key: 'news',
        iconName: 'News',
        text: 'Looking for related news articles',
        minDuration: 2,
        maxDuration: 4,
      },
      {
        key: 'related',
        iconName: 'SeeDo',
        text: 'Looking for related Meetingflows',
        minDuration: 5,
        maxDuration: 7,
      },
      {
        key: 'reviewing',
        iconName: 'Source',
        text: 'Reviewing information',
      },
    ].filter(Truthy) satisfies GenerateStep[];
  }, [internalDomains, meetingplan]);

  useEffect(() => {
    if (!isGenerating && !dossier && !!meetingplan?.dossier) {
      setDossier(meetingplan?.dossier);
    }
  }, [dossier, isGenerating, meetingplan?.dossier]);

  useEffect(() => {
    if (generateStep && generateStep?.length) {
      const currentStepIndex = generateSteps.findIndex(
        (step) => step.key === generateStep,
      );
      const currentStep = generateSteps[currentStepIndex];
      if (
        currentStepIndex !== -1 &&
        currentStepIndex < generateSteps.length - 1
      ) {
        const timeout =
          (currentStep.minDuration ?? 0.0) +
          Math.random() *
            ((currentStep.maxDuration ?? 30.0) -
              (currentStep.minDuration ?? 0.0));

        setTimeout(
          () => setGenerateStep(generateSteps[currentStepIndex + 1]?.key),
          timeout * 1000,
        );
      }
    }
  }, [generateStep, generateSteps]);

  const generateDossier = useCallback(async () => {
    abortController.current?.abort();

    const newController = new AbortController();
    abortController.current = newController;

    setGenerating();
    setGenerateStep(generateSteps[0]?.key);
    setDossier('');

    try {
      const token = await getAccessTokenSilently();
      const generatedDossier = await new Promise<string>((resolve, reject) => {
        fetch(
          `/api/organization/${organizationSlug}/plan/${meetingPlanId}/dossier`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: 'text/event-stream',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({}),
            signal: newController.signal,
          },
        )
          .then(async (res) => {
            if (res.status !== 200) {
              throw new Error(`Failed to start streaming dossier`);
            }

            let generated = '';

            const reader = res.body
              ?.pipeThrough(new TextDecoderStream())
              .getReader();

            if (!reader) {
              throw new Error('Unable to get reader');
            }
            // eslint-disable-next-line no-constant-condition
            while (true) {
              // eslint-disable-next-line no-await-in-loop
              const read = await reader.read();
              if (read.done) {
                break;
              }
              generated += read.value;
              setDossier(generated);
            }
            resolve(generated);
          })
          .catch((err) => reject(err));
      });

      if (generatedDossier) {
        await onGeneratedDossier();
      }
    } catch (err: unknown) {
      if (err instanceof DOMException && err.name === 'AbortError') {
        console.info('Dossier generation cancelled');
        return;
      } else {
        toast.error('Something went wrong generating the dossier');
      }
      console.error(typeof err, err);
    } finally {
      if (abortController.current === newController) {
        setNotGenerating();
        setGenerateStep(undefined);
        abortController.current = undefined;
      }
    }
  }, [
    setGenerating,
    generateSteps,
    getAccessTokenSilently,
    organizationSlug,
    meetingPlanId,
    onGeneratedDossier,
    setNotGenerating,
  ]);

  const abortGeneration = async () => {
    abortController.current?.abort();
  };

  const dossierOutputClass = mergeStyles({
    backgroundColor: isDark
      ? MEETINGFLOW_COLORS.tealDark
      : MEETINGFLOW_COLORS.tealVeryLight,
    animationName: 'fadeInAnimation',
    animationDuration: '.3s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '1',
    animationFillMode: 'forwards',
    transition: '.5s ease-in-out all',
    padding: '.5rem',
    borderRadius: '.15rem',
    margin: '2rem 0 0 0 ',
    position: 'relative',
    minHeight: '2rem',

    '*': {
      color: isDark ? NeutralColors.gray30 : NeutralColors.gray150,
    },

    '&.generating': {
      backgroundColor: isDark
        ? MEETINGFLOW_COLORS.tealMedium
        : MEETINGFLOW_COLORS.teal,
      padding: '.5rem 1rem .5rem .5rem',

      '*': {
        color: `${MEETINGFLOW_COLORS.white} !important`,
      },

      a: {
        textDecoration: 'underline !important',
      },

      blockquote: {
        color: isDark
          ? `${NeutralColors.gray40} !important`
          : `${MEETINGFLOW_COLORS.black} !important`,

        '*': {
          color: `${
            isDark ? NeutralColors.gray40 : MEETINGFLOW_COLORS.black
          } !important`,
        },
      },
    },

    h1: {
      display: 'none',
    },

    'h2, h3, h4, h5, h6': {
      margin: '.5rem 0 0 0',
      lineHeight: '1rem',
      fontWeight: FontWeights.semibold,
      color: isDark ? NeutralColors.white : MEETINGFLOW_COLORS.black,
    },

    h2: {
      display: 'inline-block',
      fontSize: FontSizes.medium,
      marginTop: '1rem',
    },

    h3: {
      fontSize: '13px',
      paddingBottom: '.25rem',
    },

    h4: {
      fontSize: FontSizes.small,
    },

    strong: {
      fontWeight: FontWeights.semibold,
      color: isDark ? NeutralColors.white : NeutralColors.black,
    },

    blockquote: {
      backgroundColor: isDark ? NeutralColors.black : NeutralColors.white,
      borderLeft: `3px solid ${
        isDark ? MEETINGFLOW_COLORS.tealLight : MEETINGFLOW_COLORS.teal
      }`,
      margin: '.5rem 0',
      padding: '.5rem',
      position: 'relative',
      borderRadius: '.25rem',
      fontFamily: 'Georgia, serif',
      fontSizes: FontSizes.small,
      lineHeight: '1.25rem',

      '*': {
        fontSize: FontSizes.small,
        color: isDark ? NeutralColors.gray40 : MEETINGFLOW_COLORS.black,
        lineHeight: '1.25rem',
      },

      'p:only-child': {
        marginTop: 0,
        marginBottom: 0,
      },

      'ol:first-child, ul:first-child': {
        padding: `0 0 0 .75rem`,
      },

      'ol:only-child, ul:only-child': {
        marginTop: 0,
        marginBottom: 0,
      },
    },

    'span, p, ol, ul': {
      fontSize: '12px',
      lineHeight: '1rem',
      margin: '0 0 0 0',
      color: isDark ? NeutralColors.gray30 : NeutralColors.gray200,
    },

    'ol, ul': {
      padding: `0 0 0 1.5rem`,
      marginBottom: '.75rem',
      marginTop: '.25rem',
    },

    'ol ol, ul ul': {
      padding: `0 0 0 .5rem`,
    },

    li: {
      marginLeft: 0,
      lineHeight: '1.25rem',
    },

    img: {
      margin: '0',
    },

    br: {
      margin: '0 0 .5rem 0 !important',
    },

    hr: {
      border: 0,
      margin: '.5rem 0 ',
      height: `1px`,
      backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0))`,
    },

    'hr:last-child': {
      display: 'none',
    },

    a: {
      verticalAlign: 'text-bottom',
      display: 'inline-block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      maxWidth: 'calc(100% - 1rem)',
      color: isDark
        ? MEETINGFLOW_COLORS.tealSuperLight
        : MEETINGFLOW_COLORS.purpleDark,
      lineHeight: '1rem',
      transition: '.3s ease-in-out all',

      ':hover': {
        textDecoration: 'underline',
        color: isDark
          ? MEETINGFLOW_COLORS.white
          : MEETINGFLOW_COLORS.purpleDark,
      },
    },
  });

  useScrollToBottom(dossierRef, isGenerating);

  const spinnerClass = mergeStyles({
    position: 'absolute',
    top: '.25rem',
    right: '.25rem',
  });

  const buttonsClass = mergeStyles({
    textAlign: 'center',
  });

  const generateButtonClass = mergeStyles({
    display: 'inline-block',
    fontSize: FontSizes.small,
    fontWeight: FontWeights.semibold,
    height: '1.5rem',
    lineHeight: '1.5rem',
    backgroundColor: 'transparent !important',
    border: 'none !important',

    ':disabled': {
      opacity: '.25',
      'i, span': {
        color: isDark ? NeutralColors.gray80 : NeutralColors.gray160,
      },
    },

    i: {
      float: 'right',
      display: 'inline-block',
      fontSize: FontSizes.large,
      color: MEETINGFLOW_COLORS.teal,
      fontWeight: FontWeights.regular,
      height: '1.5rem',
      width: '1.5rem',
      lineHeight: '1.5rem',
      marginLeft: '.25rem',
    },

    span: {
      display: 'inline-block',
      height: '1.5rem',
      lineHeight: '1.5rem',
      position: 'relative',
      top: '1px',
      color: isDark
        ? MEETINGFLOW_COLORS.purpleSecondary
        : MEETINGFLOW_COLORS.purplePrimary,
    },
  });

  const currentStepClass = mergeStyles({
    margin: '2rem auto',
    textAlign: 'center',

    i: {
      fontSize: '3rem',
      display: 'block',
      color: MEETINGFLOW_COLORS.orange,
    },

    span: {
      fontSize: FontSizes.mediumPlus,
    },
  });

  const currentStep = useMemo(() => {
    return generateSteps.find((step) => step.key === generateStep);
  }, [generateSteps, generateStep]);

  const ButtonComponent = isGenerating ? AsyncPrimaryButton : DefaultButton;

  return (
    <BaseSidePanel
      ref={dossierRef}
      title="Meeting Dossier"
      onBack={onBack}
      onClose={onClose}
    >
      <Text>
        Meeting Dossiers are research packages created by AI agents to help you
        prepare for this meeting. Click "generate" at any time to create a
        dossier for this Meetingflow.
      </Text>
      <div className={buttonsClass}>
        <TooltipHost
          content={
            <>
              <Text
                variant="small"
                block
                style={{
                  fontWeight: FontWeights.semibold,
                  marginBottom: '.5rem',
                  color: MEETINGFLOW_COLORS.white,
                }}
              >
                Meeting Dossier
              </Text>
              <Text
                variant="xSmall"
                block
                style={{
                  marginBottom: '.5rem',
                  color: MEETINGFLOW_COLORS.white,
                }}
              >
                Let our AI agents generate a meeting dossier for you — we'll get
                you caught up on past meetings, research your attendees and
                their companies, and surface insights that may be important to
                your meeting.
              </Text>
            </>
          }
          styles={{
            root: {
              position: 'relative',
              top: '-1px',
              float: 'right',
            },
          }}
          calloutProps={{
            backgroundColor: MEETINGFLOW_COLORS.teal,
            styles: {
              root: {
                padding: 0,
                maxWidth: '18rem',
                color: MEETINGFLOW_COLORS.white,
              },
              calloutMain: {
                padding: '.5rem',
                color: MEETINGFLOW_COLORS.white,
              },
            },
          }}
        >
          <ButtonComponent
            className={generateButtonClass}
            onClick={isGenerating ? abortGeneration : generateDossier}
          >
            <FontIcon iconName="AISparkle" />
            <span>
              {isGenerating
                ? 'Cancel'
                : meetingplan?.dossier
                ? 'Regenerate'
                : 'Generate'}
            </span>
          </ButtonComponent>
        </TooltipHost>
      </div>

      <div
        className={classnames(
          dossierOutputClass,
          isGenerating ? 'generating' : '',
        )}
      >
        {isGenerating ? (
          <>
            <Spinner
              className={spinnerClass}
              styles={{
                root: {},
                circle: {
                  backgroundColor: MEETINGFLOW_COLORS.teal,
                  color: MEETINGFLOW_COLORS.white,
                  borderColor: `${MEETINGFLOW_COLORS.white} ${MEETINGFLOW_COLORS.tealLight} ${MEETINGFLOW_COLORS.tealLight} ${MEETINGFLOW_COLORS.tealLight}`,
                },
              }}
            />
            {currentStep && !dossier ? (
              <div className={currentStepClass}>
                <Icon iconName={currentStep.iconName} />{' '}
                <span>{currentStep.text}...</span>
              </div>
            ) : null}
            {dossier ? (
              <ReactMarkdown
                rehypePlugins={[
                  [
                    rehypeExternalLinks,
                    {
                      target: '_blank',
                      rel: ['noopener', 'nofollow', 'noreferrer'],
                    } satisfies RehypeExternalLinksOptions,
                  ],
                ]}
                className="dossier-content"
                remarkPlugins={[remarkGfm]}
              >
                {dossier}
              </ReactMarkdown>
            ) : null}
          </>
        ) : (
          <ReactMarkdown
            rehypePlugins={[
              [
                rehypeExternalLinks,
                {
                  target: '_blank',
                  rel: ['noopener', 'nofollow', 'noreferrer'],
                } satisfies RehypeExternalLinksOptions,
              ],
            ]}
            className="dossier-content"
            remarkPlugins={[remarkGfm]}
          >
            {dossier}
          </ReactMarkdown>
        )}
      </div>
    </BaseSidePanel>
  );
};
