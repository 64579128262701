import { Editor, Element, Point, Range, Transforms } from 'slate';
import { getParentBlock } from '../Helpers/EditorHelpers';
import {
  SlateBaseElement,
  SlateChecklistItem,
  SlateDescendant,
} from '@meetingflow/common/Api/data-contracts';

export const withChecklists = (editor: Editor) => {
  const {
    isVoid,
    isInline,
    insertBreak,
    insertSoftBreak,
    deleteBackward,
    deleteForward,
    deleteFragment,
    normalizeNode,
  } = editor;

  editor.isVoid = (element) => isVoid(element);

  editor.isInline = (element) => isInline(element);

  editor.insertBreak = () => {
    const { selection } = editor;

    const block = getParentBlock(editor, { type: 'checklist-item' });

    if (!!selection && block) {
      const [, path] = block;

      const isStart = Point.equals(
        selection.anchor,
        Editor.start(editor, path),
      );
      const isEnd = Point.equals(selection.anchor, Editor.end(editor, path));

      // If hitting enter on an empty checklist item
      // convert the element into a paragraph
      if (isStart && isEnd) {
        Transforms.setNodes(
          editor,
          {
            type: 'paragraph',
          } as Partial<Node>,
          {
            match: (n) => Element.isElement(n) && n.type === 'checklist-item',
          },
        );
        return;
      }
    }

    insertBreak();
  };

  editor.insertSoftBreak = () => {
    insertSoftBreak();
  };

  editor.deleteBackward = (...args) => {
    const { selection } = editor;

    const match = getParentBlock(editor, { type: 'checklist-item' });

    if (selection && Range.isCollapsed(selection) && match) {
      const [, path] = match;
      const isStart = Point.equals(
        selection.anchor,
        Editor.start(editor, path),
      );

      // If the user is backspacing at the start of a checklist item
      // convert the element to a paragraph
      if (isStart) {
        Transforms.setNodes(
          editor,
          {
            type: 'paragraph',
          } as Partial<Node>,
          {
            match: (n) => Element.isElement(n) && n.type === 'checklist-item',
          },
        );
        return;
      }
    }

    deleteBackward(...args);
  };

  editor.deleteForward = (...args) => {
    deleteForward(...args);
  };

  editor.deleteFragment = (...args) => {
    deleteFragment(...args);
  };

  editor.normalizeNode = (entry) => {
    return normalizeNode(entry);
  };

  return editor;
};
