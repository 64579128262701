import { styled } from '@mui/material'

export const MeetingflowCardRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.background.default,
    flexGrow: 1,
    gap: '0.5rem',

    
    '.meetingflow-card': 
    {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.default,
        borderRadius: '0.25rem',
        flexGrow: 1,
    },

    '.meetingflow-actions': {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: theme.palette.background.default,
        borderRadius: '0.25rem',
        flexBasis: '2rem',
        alignItems: 'center',
        justifyContent: 'center',
        // gap: '0.5rem',
        // padding: '0.25rem',
        color: theme.palette.text.secondary
    },
}))