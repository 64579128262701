import { useAuth0 } from '@auth0/auth0-react';
import { Portal } from '@blueprintjs/core';
import { Text } from '@fluentui/react';
import { FontSizes, FontWeights, NeutralColors } from '@fluentui/theme';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { BaseRange, Descendant, Editor, Range, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { insertMention } from '../../Components/Collab/Helpers/EditorHelpers';
import { OrganizationContactsQuery } from '../../QueryNames';
import { ApiClient, ContactsApiClient } from '../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { useLightOrDarkMode } from '../useLightOrDarkMode';

export type UseMentionsParams = {
  organizationSlug: string;
  meetingflowId?: string;
  companyId?: number;
  contactId?: number;
  editor: Editor;
  allowMentions?: boolean;
  defaultMentionSuggestions?: {
    id: number;
    name: string | null;
    email: string;
  }[];
  useMaxZIndex?: boolean;
};
export const useMentions = ({
  organizationSlug,
  meetingflowId,
  companyId,
  contactId,
  editor,
  allowMentions,
  defaultMentionSuggestions,
  useMaxZIndex = false,
}: UseMentionsParams) => {
  const { getAccessTokenSilently } = useAuth0();
  const appInsights = useAppInsightsContext();
  const { isDark } = useLightOrDarkMode();

  const ref = useRef<HTMLDivElement>(null);
  const [target, setTarget] = useState<BaseRange | undefined>();
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState('');

  const { data: contacts, refetch: refetchSuggestions } = useQuery(
    OrganizationContactsQuery(
      organizationSlug!,
      false,
      true,
      true,
      undefined,
      search,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return ContactsApiClient.listContacts(
        {
          organizationSlug,
          q: search,
          isInternal: true,
          isInvitable: true,
          limit: 5,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'x-meetingplan-id': meetingflowId,
          },
        },
      );
    },
    {
      enabled:
        !!allowMentions &&
        !!(meetingflowId || companyId || contactId) &&
        (!!search || !defaultMentionSuggestions?.length) &&
        !!target,
    },
  );

  useEffect(() => {
    if (
      !!allowMentions &&
      !!(meetingflowId || companyId || contactId) &&
      (!!search || !defaultMentionSuggestions?.length) &&
      !!target
    ) {
      refetchSuggestions();
    }
  }, [
    allowMentions,
    companyId,
    contactId,
    defaultMentionSuggestions?.length,
    meetingflowId,
    refetchSuggestions,
    search,
    target,
  ]);

  useEffect(() => {
    if (index < 0) {
      setIndex(0);
    }
    if (
      !search &&
      defaultMentionSuggestions?.length &&
      index >= defaultMentionSuggestions.length
    ) {
      setIndex(defaultMentionSuggestions.length - 1);
    } else if (
      search &&
      !!contacts?.data?.length &&
      index >= contacts?.data?.length
    ) {
      setIndex(contacts.data.length - 1);
    }
  }, [contacts?.data, defaultMentionSuggestions?.length, index, search]);

  const [popupPosition, setPopupPosition] = useState<{
    top: number;
    left: number;
  }>({ top: -9999, left: -9999 });

  useLayoutEffect(() => {
    if (
      target &&
      ref.current &&
      allowMentions &&
      ((!search &&
        (contacts?.data.length || defaultMentionSuggestions?.length)) ||
        (search && contacts?.data.length))
    ) {
      const el = ref.current;
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();
      // el.style.top = `${rect.top + window.pageYOffset + 24}px`;
      // el.style.left = `${rect.left + window.pageXOffset}px`;
      const viewportHeight = window.innerHeight;
      const popupHeight = ref.current.offsetHeight;
      const spaceBelow = viewportHeight - rect.bottom;
      const spaceAbove = rect.top;

      if (spaceBelow < popupHeight && spaceAbove > popupHeight) {
        // Position above the target
        setPopupPosition({ top: rect.top - popupHeight, left: rect.left });
      } else {
        // Position below the target
        setPopupPosition({ top: rect.bottom, left: rect.left });
      }
    }
  }, [
    allowMentions,
    contacts?.data?.length,
    defaultMentionSuggestions?.length,
    editor,
    index,
    search,
    target,
  ]);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (!allowMentions) {
        return;
      }

      if (
        target &&
        ((search && contacts?.data?.length) ||
          (!search && defaultMentionSuggestions?.length))
      ) {
        const suggestions = search ? contacts?.data : defaultMentionSuggestions;

        if (!suggestions) {
          return;
        }

        switch (event.key) {
          case 'ArrowDown': {
            event.preventDefault();
            const prevIndex = index >= suggestions.length - 1 ? 0 : index + 1;
            setIndex(prevIndex);
            return true;
          }
          case 'ArrowUp': {
            event.preventDefault();
            const nextIndex = index <= 0 ? suggestions.length - 1 : index - 1;
            setIndex(nextIndex);
            return true;
          }
          case 'Tab':
          case 'Enter': {
            const contact = suggestions[index];

            if (!contact) {
              return;
            }

            event.preventDefault();

            Transforms.select(editor, target);
            insertMention(editor, 'contact', contact);
            setTarget(undefined);
            setSearch('');

            if (!(meetingflowId || companyId || contactId)) {
              return true;
            }

            getAccessTokenSilently().then((token) => {
              let url = '';
              if (meetingflowId) {
                url = `/organization/${organizationSlug}/plan/${meetingflowId}/mention`;
              } else if (companyId) {
                url = `/organization/${organizationSlug}/companies/${companyId}/mention`;
              } else if (contactId) {
                url = `/organization/${organizationSlug}/contact/${contactId}/mention`;
              } else {
                return;
              }

              ApiClient.post(
                url,
                { email: contact.email },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    'x-meetingplan-id': meetingflowId,
                  },
                  validateStatus: (code) => [200, 204].includes(code),
                },
              ).catch((err) => {
                appInsights.trackException({
                  exception: err instanceof Error ? err : new Error(err),
                });
              });
            });
            return true;
          }
          case 'Escape':
            event.preventDefault();
            setTarget(undefined);
            setSearch('');
            setIndex(0);
            return true;
        }
      }
    },
    [
      allowMentions,
      appInsights,
      companyId,
      contactId,
      contacts?.data,
      defaultMentionSuggestions,
      editor,
      getAccessTokenSilently,
      index,
      meetingflowId,
      organizationSlug,
      search,
      target,
    ],
  );

  const onSlateChange = useCallback(
    (v: Descendant[]) => {
      if (!allowMentions) {
        return;
      }

      const { selection } = editor;

      if (selection && Range.isCollapsed(selection)) {
        const [start] = Range.edges(selection);

        const nextCharacter = Editor.after(editor, start, {
          unit: 'character',
        });
        // Check that the character after the cursor is whitespace, if there is one, else unset target and return early
        if (
          nextCharacter &&
          !Editor.string(
            editor,
            Editor.range(editor, start, nextCharacter),
          ).match(/^\s?$/)
        ) {
          setTarget(undefined);
          setSearch('');
          setIndex(0);
          return;
        }

        // If there is no preceeding character, unset target and return early
        const preceedingCharacter = Editor.before(editor, start, {
          unit: 'character',
        });
        if (!preceedingCharacter) {
          setTarget(undefined);
          setSearch('');
          setIndex(0);
          return;
        }

        // If the preceeding character is an @, check for /^@$/ and /^\s@$/
        if (
          Editor.string(
            editor,
            Editor.range(editor, preceedingCharacter, start),
          ).match(/^@$/)
        ) {
          const secondPreceedingCharacter = Editor.before(
            editor,
            preceedingCharacter,
          );
          // If the there is no second preceeding character, or the second preceeding character is whitespace
          if (
            !secondPreceedingCharacter ||
            Editor.string(
              editor,
              Editor.range(
                editor,
                secondPreceedingCharacter,
                preceedingCharacter,
              ),
            ).match(/^\s?$/)
          ) {
            setTarget(Editor.range(editor, preceedingCharacter, start));
            setSearch('');
            setIndex(0);
            return;
          }
        }

        // Get the preceeding word
        const preceedingWord = Editor.before(editor, start, {
          unit: 'word',
        });
        // If there is no preceeding 'word', we can't be in a mention, unset and return early
        if (!preceedingWord) {
          setTarget(undefined);
          setSearch('');
          setIndex(0);
          return;
        }

        const characterPreceedingWord = Editor.before(editor, preceedingWord, {
          unit: 'character',
        });
        // If there is no character preceeding the word, unset and return early
        if (!characterPreceedingWord) {
          setTarget(undefined);
          setSearch('');
          setIndex(0);
          return;
        }

        // If there is an @ preceeding the word and it's preceeded by whitespace, or nothing, it's a mention
        if (
          Editor.string(
            editor,
            Editor.range(editor, characterPreceedingWord, preceedingWord),
          ).match(/^@$/)
        ) {
          const secondCharacterPreceedingWord = Editor.before(
            editor,
            characterPreceedingWord,
            { unit: 'character' },
          );
          // Match ^@search^$ or ^\s@search$
          if (
            !secondCharacterPreceedingWord ||
            Editor.string(
              editor,
              Editor.range(
                editor,
                secondCharacterPreceedingWord,
                characterPreceedingWord,
              ),
            ).match(/^\s?$/)
          ) {
            setTarget(Editor.range(editor, characterPreceedingWord, start));
            setSearch(
              Editor.string(
                editor,
                Editor.range(editor, preceedingWord, start),
              ).trim(),
            );
            setIndex(0);
            return;
          }
        }

        // If still no match try one more preceeding word

        const secondPreceedingWord = Editor.before(editor, preceedingWord, {
          unit: 'word',
        });
        // If there is no second preceeding 'word', unset and return early
        if (!secondPreceedingWord) {
          setTarget(undefined);
          setSearch('');
          setIndex(0);
          return;
        }

        const characterPreceedingSecondWord = Editor.before(
          editor,
          secondPreceedingWord,
          { unit: 'character' },
        );
        // If there is no character preceeding the second word, unset and return early
        if (!characterPreceedingSecondWord) {
          setTarget(undefined);
          setSearch('');
          setIndex(0);
          return;
        }

        // If there is an @ preceeding the second word and it's preceeded by whitespace, or nothing, it's a mention
        if (
          Editor.string(
            editor,
            Editor.range(
              editor,
              characterPreceedingSecondWord,
              secondPreceedingWord,
            ),
          ).match(/^@$/)
        ) {
          const secondCharacterPreceedingSecondWord = Editor.before(
            editor,
            characterPreceedingSecondWord,
            { unit: 'character' },
          );

          // Match ^@search term^$, ^\s@search term$ or ^@search@term$
          if (
            !secondCharacterPreceedingSecondWord ||
            Editor.string(
              editor,
              Editor.range(
                editor,
                secondCharacterPreceedingSecondWord,
                characterPreceedingSecondWord,
              ),
            ).match(/^\s?$/)
          ) {
            setTarget(
              Editor.range(editor, characterPreceedingSecondWord, start),
            );
            setSearch(
              Editor.string(
                editor,
                Editor.range(editor, secondPreceedingWord, start),
              ).trim(),
            );
            setIndex(0);
            return;
          }
        }
      }

      setTarget(undefined);
      setSearch('');
      setIndex(0);
    },
    [allowMentions, editor],
  );

  return {
    onKeyDown,
    onSlateChange,
    mentionSuggestions:
      allowMentions && target ? (
        <Portal>
          <div
            ref={ref}
            style={{
              // top: '-9999px',
              // left: '-9999px',
              top: `${popupPosition.top}px`,
              left: `${popupPosition.left}px`,
              position: 'absolute',
              zIndex: useMaxZIndex ? 1000001 : 1,
              padding: '3px',
              borderRadius: '.25rem',
              boxShadow: '0 1px 5px rgba(0,0,0,.2)',
              backgroundColor: isDark ? NeutralColors.gray200 : 'white',
              color: isDark ? 'white' : 'black',
              maxWidth: '17rem',
              maxHeight: '60vh',
              overflowY: 'auto',
            }}
            data-cy="mentions-portal"
          >
            {(search
              ? contacts?.data
              : defaultMentionSuggestions || contacts?.data
            )?.map((user, i) => (
              <div
                key={user.id}
                style={{
                  padding: '0.25rem 0.5rem',
                  borderRadius: i === index ? '3px' : undefined,
                  transition: '.3s border-radius ease-in-out',
                  borderBottom:
                    i !== index
                      ? `1px solid ${
                          isDark ? NeutralColors.gray170 : NeutralColors.gray20
                        }`
                      : undefined,
                  cursor: 'pointer',
                  color:
                    i === index || isDark
                      ? NeutralColors.gray30
                      : NeutralColors.gray180,
                  background:
                    i === index
                      ? isDark
                        ? MEETINGFLOW_COLORS.purpleDark
                        : MEETINGFLOW_COLORS.purpleGrey
                      : 'transparent',
                }}
                onClick={() => {
                  if (index !== i) {
                    setIndex(i);
                  }
                }}
                onDoubleClick={() => {
                  Transforms.select(editor, target);
                  insertMention(editor, 'contact', user);
                  setTarget(undefined);
                  getAccessTokenSilently()
                    .then((token) => {
                      let url = '';
                      if (meetingflowId) {
                        url = `/organization/${organizationSlug}/plan/${meetingflowId}/mention`;
                      } else if (companyId) {
                        url = `/organization/${organizationSlug}/companies/${companyId}/mention`;
                      } else if (contactId) {
                        url = `/organization/${organizationSlug}/contact/${contactId}/mention`;
                      } else {
                        return;
                      }

                      ApiClient.post(
                        url,
                        { email: user.email },
                        {
                          headers: {
                            Authorization: `Bearer ${token}`,
                            'x-meetingplan-id': meetingflowId,
                          },
                          validateStatus: (code) => [200, 204].includes(code),
                        },
                      );
                    })
                    .catch((err) => {
                      appInsights.trackException({
                        exception: err instanceof Error ? err : new Error(err),
                      });
                    });
                }}
              >
                <Text
                  style={{
                    fontSize: FontSizes.small,
                    fontWeight: FontWeights.semibold,
                  }}
                  block
                >
                  {user.name || user.email}
                </Text>
                {user.name ? (
                  <Text style={{ fontSize: FontSizes.xSmall }} block>
                    {user.email}
                  </Text>
                ) : null}
              </div>
            ))}
            {!search ? (
              <span
                style={{
                  display: 'block',
                  padding: 'calc(.25rem + 3px)',
                  fontSize: FontSizes.small,
                  color: isDark ? NeutralColors.gray100 : NeutralColors.gray130,
                  backgroundColor: isDark
                    ? NeutralColors.gray180
                    : NeutralColors.gray10,
                }}
              >
                <span
                  style={{
                    fontWeight: FontWeights.bold,
                    color: MEETINGFLOW_COLORS.teal,
                    textTransform: 'uppercase',
                  }}
                >
                  Hint:
                </span>{' '}
                Starting typing for more suggestions...
              </span>
            ) : null}
          </div>
        </Portal>
      ) : null,
  };
};
