import { User } from '@auth0/auth0-react';
import {
  DetailedMeetingflow,
  Resource,
  Task,
} from '@meetingflow/common/Api/data-contracts';
import { humanizeDateTime } from '@meetingflow/common/DateHelpers';
import { hasContent, toString } from '@meetingflow/common/SlateHelpers';
import { yTextToSlateElement } from '@slate-yjs/core';
import { syncedStore } from '@syncedstore/core';
import {
  AlignmentType,
  Document,
  ExternalHyperlink,
  HeadingLevel,
  IParagraphOptions,
  LevelFormat,
  Packer,
  Paragraph,
  ParagraphChild,
  Table,
  TableOfContents,
  TextRun,
} from 'docx';
import isUrl from 'is-url';
import { isNumber } from 'lodash';
import { Descendant, Element, Text } from 'slate';
import * as Y from 'yjs';

export const MeetingflowNumbering = 'meetingflow-numbering';

export const MeetingflowDocxStyles = {
  default: {
    document: {
      run: {
        font: 'Calibri',
      },
    },
    title: {
      paragraph: {
        spacing: {
          after: 120,
        },
      },
    },
    heading1: {
      paragraph: {
        spacing: {
          before: 120,
          after: 80,
        },
      },
    },
    heading2: {
      paragraph: {
        spacing: {
          before: 120,
          after: 80,
        },
      },
    },
    heading3: {
      paragraph: {
        spacing: {
          before: 120,
          after: 80,
        },
      },
    },
    heading4: {
      paragraph: {
        spacing: {
          before: 120,
          after: 80,
        },
      },
    },
    heading5: {
      paragraph: {
        spacing: {
          before: 120,
          after: 80,
        },
      },
    },
  },
};
export const MeetingflowDocxNumbering = {
  config: [
    {
      reference: MeetingflowNumbering,
      levels: [
        {
          level: 0,
          format: LevelFormat.DECIMAL,
          text: '%1',
          alignment: AlignmentType.START,
        },
        {
          level: 1,
          format: LevelFormat.LOWER_LETTER,
          text: '%2',
          alignment: AlignmentType.LEFT,
        },
        {
          level: 2,
          format: LevelFormat.LOWER_ROMAN,
          text: '%3',
          alignment: AlignmentType.START,
        },
        {
          level: 3,
          format: LevelFormat.UPPER_LETTER,
          text: '%4',
          alignment: AlignmentType.START,
        },
        {
          level: 4,
          format: LevelFormat.UPPER_ROMAN,
          text: '%4',
          alignment: AlignmentType.START,
        },
        {
          level: 5,
          format: LevelFormat.DECIMAL_ENCLOSED_CIRCLE,
          text: '%4',
          alignment: AlignmentType.START,
        },
      ],
    },
  ],
};

export const hyperlink = (url: string, text?: string) =>
  url
    ? new ExternalHyperlink({
        link: url,
        children: [textRun(text || url)!],
      })
    : undefined;
export const paragraph = (
  options: string | IParagraphOptions | null | undefined,
) => {
  if (!options) {
    return;
  }
  if (typeof options === 'string') {
    return options ? new Paragraph({ text: options }) : undefined;
  }
  const { text, children } = options;
  return text || children?.length ? new Paragraph(options) : undefined;
};
export const title = (text?: string) =>
  paragraph({ text, heading: HeadingLevel.TITLE });

export const heading1 = (text?: string) =>
  paragraph({ text, heading: HeadingLevel.HEADING_1 });

export const heading2 = (text?: string) =>
  paragraph({ text, heading: HeadingLevel.HEADING_2 });

export const heading3 = (text?: string) =>
  paragraph({ text, heading: HeadingLevel.HEADING_3 });

export const heading4 = (text?: string) =>
  paragraph({ text, heading: HeadingLevel.HEADING_4 });

export const bullet = (text?: string, level?: number) =>
  text ? paragraph({ text, bullet: { level: level || 0 } }) : undefined;

export const hyperlinkBullet = (url: string, text?: string, level?: number) => {
  return url
    ? paragraph({
        children: text
          ? [hyperlink(url, text)!, new TextRun(` - ${url}`)]
          : [hyperlink(url, text)!],
        bullet: { level: level || 0 },
      })
    : undefined;
};

const checkedBox = '\u2611'; // ☑
const uncheckedBox = '\u2610'; // ☐

export const checklistItem = (checked: boolean, text: string) =>
  paragraph({
    children: [
      new TextRun({
        text: (checked ? checkedBox : uncheckedBox) + ' ' + text,
      }),
    ],
  });

export const textRun = (text?: string) =>
  text ? new TextRun({ text }) : undefined;

export const toRuns = (node: Descendant): ParagraphChild[] => {
  if (Text.isText(node)) {
    return [
      new TextRun({
        bold: node.bold,
        italics: node.italic,
        strike: node.strikethrough,
        underline: node.underline ? {} : undefined,
        highlight: node.highlight ? 'yellow' : undefined,
        text: node.text,
      }),
    ];
  }
  if (Element.isElement(node)) {
    switch (node.type) {
      case 'image':
        return [];
      case 'mention':
        return [
          textRun(node.name ? `${node.name} (${node.email})` : node.email)!,
        ];
      case 'tag':
        return [textRun(`#${node.tagId}`)!];
      case 'link':
        return [hyperlink(node.href, toString(node))!];
      case undefined:
      case 'paragraph':
      case 'heading-one':
      case 'heading-two':
      case 'heading-three':
      case 'heading-four':
      case 'block-quote':
      case 'bulleted-list':
      case 'numbered-list':
      case 'checklist-item':
      default:
        return node.children.flatMap((child) => toRuns(child));
    }
  }

  return [];
};

export const descendantToParagraphs = (
  node: Descendant,
  listType?: 'bullet' | 'number' | 'checklist',
  level?: number,
): Paragraph[] => {
  if (!node) {
    return [];
  }

  if (Text.isText(node)) {
    return [new Paragraph(node.text)];
  }

  if (Element.isElement(node)) {
    switch (node.type) {
      case undefined:
        // Root node has no type, return flatmap of children to paragraphs
        return node.children.flatMap((child) => descendantToParagraphs(child));
      case 'bulleted-list':
        return node.children.flatMap((child) =>
          descendantToParagraphs(
            child,
            'bullet',
            isNumber(level) ? level + 1 : 0,
          ),
        );
      case 'numbered-list':
        return node.children.flatMap((child) =>
          descendantToParagraphs(
            child,
            'number',
            isNumber(level) ? level + 1 : 0,
          ),
        );
      case 'checklist-item': {
        return node.children.flatMap((child) =>
          descendantToParagraphs(
            child,
            'bullet',
            isNumber(level) ? level + 1 : 0,
          ),
        );
      }
      case 'list-item': {
        return [
          new Paragraph({
            bullet:
              listType === 'bullet'
                ? ({
                    level: level ?? 0,
                  } as const)
                : undefined,
            numbering:
              listType === 'number'
                ? ({
                    level: level ?? 0,
                    reference: MeetingflowNumbering,
                  } as const)
                : undefined,
            children: toRuns(node),
          }),
        ];
      }
      case 'image':
        // To-do download and insert image into doc?
        // https://github.com/dolanmiu/docx/blob/master/docs/usage/images.md
        return [];
      case 'heading-one': {
        return [
          new Paragraph({
            heading: HeadingLevel.HEADING_1,
            children: toRuns(node),
          }),
        ];
      }
      case 'heading-two': {
        return [
          new Paragraph({
            heading: HeadingLevel.HEADING_2,
            children: toRuns(node),
          }),
        ];
      }
      case 'heading-three': {
        return [
          new Paragraph({
            heading: HeadingLevel.HEADING_3,
            children: toRuns(node),
          }),
        ];
      }
      case 'heading-four': {
        return [
          new Paragraph({
            heading: HeadingLevel.HEADING_4,
            children: toRuns(node),
          }),
        ];
      }
      case 'paragraph':
      case 'block-quote':
      case 'link':
      case 'mention':
      default: {
        return [
          new Paragraph({
            children: toRuns(node),
          }),
        ];
      }
    }
  }

  return [];
};

export const xmlTextToParagraphs = (editorContent: Y.XmlText): Paragraph[] => {
  const slateElement = yTextToSlateElement(editorContent) as Descendant;
  return descendantToParagraphs(slateElement);
};

export const buildDocx = (
  user: User,
  meetingplan: Pick<
    DetailedMeetingflow,
    | 'title'
    | 'startTime'
    | 'endTime'
    | 'location'
    | 'creator'
    | 'organizer'
    | 'attendees'
  >,
  tasks: Task[],
  ydoc: Y.Doc,
): Document => {
  const store = syncedStore(
    {
      resources: [] as Resource[],
    },
    ydoc,
  );

  const objectives = yTextToSlateElement(
    ydoc.get('objectives', Y.XmlText) as Y.XmlText,
  );
  const objectiveContent = hasContent(objectives)
    ? [heading1('Meeting Plan'), ...descendantToParagraphs(objectives)]
    : [];

  const notes = yTextToSlateElement(ydoc.get('notes', Y.XmlText) as Y.XmlText);
  const noteContent = hasContent(notes)
    ? [heading1('Meeting Notes'), ...descendantToParagraphs(notes)]
    : [];

  const doc = new Document({
    creator: user.name || user.email,
    title: meetingplan.title,
    styles: MeetingflowDocxStyles,
    numbering: MeetingflowDocxNumbering,
    sections: [
      {
        children: [
          title(meetingplan.title),
          paragraph(
            `${humanizeDateTime(meetingplan.startTime, {
              useRelativeDates: false,
            })} - ${humanizeDateTime(meetingplan.endTime, {
              displayComponents: ['time'],
              timeFormatOptions: {
                hour: 'numeric',
                minute: 'numeric',
                timeZoneName: 'longGeneric',
              },
            })}`,
          ),
          meetingplan.location && isUrl(meetingplan.location)
            ? paragraph({ children: [hyperlink(meetingplan.location)!] })
            : paragraph(meetingplan.location),
          ...(meetingplan.creator
            ? [
                heading1('Creator'),
                paragraph(meetingplan.creator.name),
                paragraph(meetingplan.creator.email),
              ]
            : []),
          ...(meetingplan.organizer
            ? [
                heading1('Organizer'),
                paragraph(meetingplan.organizer.name),
                paragraph(meetingplan.organizer.email),
              ]
            : []),
          ...(meetingplan.attendees.length
            ? [
                heading1('Attendees'),
                ...meetingplan.attendees.flatMap((attendee) => [
                  paragraph(attendee.name),
                  paragraph(attendee.email),
                  paragraph(attendee.response),
                  paragraph(' '),
                ]),
              ]
            : []),
          ...(store.resources.length
            ? [
                heading1('Resources'),
                ...store.resources.map((item) =>
                  hyperlinkBullet(item.url, item.title),
                ),
              ]
            : []),
          ...objectiveContent,
          ...noteContent,
          ...(tasks.length
            ? [
                heading1('Action items'),
                ...tasks.map((task) => bullet(task.text)),
              ]
            : []),
        ].filter(Boolean) as (Paragraph | Table | TableOfContents)[],
      },
    ],
  });

  return doc;
};

export const toBlob = (doc: Document): Promise<Blob> => {
  return Packer.toBlob(doc);
};
