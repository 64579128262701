import {
  FontWeights,
  Icon,
  IconButton,
  mergeStyles,
  NeutralColors,
  Spinner,
  Text,
  useTheme,
} from '@fluentui/react';
import classnames from 'classnames';
import React from 'react';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { DEALROOMS_COLORS, MEETINGFLOW_COLORS } from '../../../Themes/Themes';

interface DecisionSiteBaseSidePanelProps {
  title: string | React.ReactNode;
  onBack?: () => void;
  onClose?: () => void;
  loading?: boolean;
  children?: React.ReactNode;
  contentPadding?: string;
  headerPadding?: string;
  providesOwnScrolling?: boolean;
}

export const DecisionSiteBaseSidePanel = React.forwardRef<
  HTMLDivElement,
  DecisionSiteBaseSidePanelProps
>(
  (
    {
      title,
      onBack,
      onClose,
      loading,
      children,
      contentPadding,
      headerPadding,
      providesOwnScrolling = false,
    }: DecisionSiteBaseSidePanelProps,
    ref,
  ) => {
    const theme = useTheme();

    const { isDark } = useLightOrDarkMode();

    const fadeInClass = mergeStyles({
      position: 'relative',
      animationName: 'fadeInSlideAnimation',
      animationDuration: '1s',
      transitionTimingFunction: 'linear',
      animationIterationCount: '1',
      animationFillMode: 'forwards',
      width: 'auto',
      transition: '.3s ease-in-out all',
    });

    const baseSidePanelClass = mergeStyles({
      height: '100%',
      position: 'relative',
      animationName: 'fadeInSlideAnimation',
      animationDuration: '1s',
      transitionTimingFunction: 'linear',
      animationIterationCount: '1',
      animationFillMode: 'forwards',
      width: '100%',
      transition: '.3s ease-in-out all',

      '.company-attendee-group': {
        backgroundColor: 'transparent',
      },
      overflow: 'hidden',
    });

    return (
      <div className={baseSidePanelClass}>
        <Text
          block
          variant={'large'}
          className={fadeInClass}
          style={{
            fontWeight: FontWeights.regular,
            padding: headerPadding ? headerPadding : '1.5rem .5rem',
            position: 'sticky',
            top: 0,
            zIndex: 150,
            backgroundColor: isDark
              ? DEALROOMS_COLORS.financialPrimaryGray
              : DEALROOMS_COLORS.meetingflowSidePanelVeryLightGray,
            boxSizing: 'border-box',
            height: 'auto',
            color: isDark
              ? DEALROOMS_COLORS.themePrimary
              : DEALROOMS_COLORS.themePrimary,
            userSelect: 'none',
            WebkitUserSelect: 'none',
            marginBottom: '1rem',
          }}
        >
          {onClose ? (
            <Icon
              className={fadeInClass}
              style={{
                color: isDark ? NeutralColors.gray130 : NeutralColors.gray60,
                display: 'inline-block',
                position: 'absolute' as const,
                right: '.5rem',
                zIndex: '151',
                cursor: 'pointer',
                fontSize: '28px',
                top: '.5rem',
                userSelect: 'none',
                WebkitUserSelect: 'none',
              }}
              iconName="StatusCircleErrorX"
              onClick={onClose}
            />
          ) : null}
          {onBack ? (
            <Icon
              className={fadeInClass}
              style={{
                position: 'relative' as const,
                top: '.25rem',
                width: '1.5rem',
                height: '1.5rem',
                marginRight: '.25rem',
                cursor: 'pointer',
                fontSize: '20px',
                color: theme.palette.neutralSecondaryAlt,
                display: 'inline-block',
                userSelect: 'none',
                WebkitUserSelect: 'none',
              }}
              iconName={'NavigateBack'}
              onClick={onBack}
            />
          ) : null}
          {title}
        </Text>
        {loading ? (
          <Spinner style={{ margin: '1rem 0' }} />
        ) : (
          <div
            ref={ref}
            style={{
              position: 'relative',
              height: 'calc(100% - 5.75rem)',
              overflowY: providesOwnScrolling ? 'hidden' : 'auto',
              overflowX: 'hidden',
              padding: contentPadding ? contentPadding : '0 1rem .5rem .5rem',
              minHeight: 'calc(100% - 5.25rem)',
              boxSizing: 'border-box',
              backgroundColor: isDark
                ? DEALROOMS_COLORS.financialPrimaryGray
                : DEALROOMS_COLORS.meetingflowSidePanelVeryLightGray,
              containerType: 'inline-size',
              width: 'calc(100% - 1px)',
            }}
            className={classnames(fadeInClass, 'base-side-panel-content')}
          >
            {children}
          </div>
        )}
      </div>
    );
  },
);
