export const DECISION_SITE_COLORS = {
    lightGray: '#D7D7D8',
    lighterGray: '#D1D1D1',
    darkerGray: '#242424',
    neutralGray: '#FCFCFD',
    neutralDark: '#AFAEB1',
    neutralDarker: '#424242',
    neutralLighter: '#F4F4F6',
    neutralLight: '#E8E8EC',
    neutralSecondary: '#605E5C',
    themePrimary: '#2B2D39',
    themeSecondary: '#6F7288',
    white: '#ffffff',
    black: '#000000',
    inputLightGray: '#F5F5F5',
    darkerRed: '#B10E1C',
    primaryButton: '#2B2D39',
    sidebarTextPrimary: '#F6F6F6',
    sidebarTextSecondary: '#95969C',
    sidebarHoverTextPrimary: '#40424F',
    financialPrimaryGray: '#DFE0E7',
    financialSecondaryGray: '#EBEBF0',
    financialLighterGray: '#F6F6F8',
    financialLightGray: '#E3E3E8',
    buttonBorder: '#D9D9D9',
    notificationBubble: '#920061',
    userSurveyPrimary: '#72003F',
    userSurveyInputPlaceholder: '#707070',
    artifactsSidePanelBorder: '#D3D0D9',
    artic: '#aee3e4',
    milestoneWarning: '#C60047',
    activeCheckBox: '#0F6CBD',
    uploadAttachmentHighLightedText: '#C60047',
    neutralLighterAlt: '#FAFBFB',
    toolbarLight: '#F6F7F9',
    peach: '#FBD6A9',
    orchid: '#621087',
    frost: '#B4C1FA',
    peacock: '#006A69',
    oink: '#F7C5DA',
    woodsmoke: '#2B2D39',
    cloudburst: '#6F7288',
    bone: '#E8E0E0',
    crimson: '#C60047',
    plum100: '#72003F',
    plum75: '#920061',
    plum50: '#BB5B8E',
    plum25: '#E1B5C7',
    meetingflowSidePanelVeryLightGray: '#FCFCFD',
    actionItemStatusNotStarted: '#F7C5DA',
    actionItemStatusInProgress: '#FBD6A9',
    actionItemStatusCompleted: '#C2E8C2',
  };