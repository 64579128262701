import { useAuth0 } from '@auth0/auth0-react';
import { Link, Text } from '@fluentui/react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useOrganization } from '../Hooks/useOrganization';
import { useEffect } from 'react';

type ErrorComponentProps = {
  error: Error;
  info: React.ErrorInfo;
  clearError: () => void;
};

export const ErrorComponent = ({ error, info }: ErrorComponentProps) => {
  const appInsights = useAppInsightsContext();
  const { user } = useAuth0();
  const { slug } = useOrganization();
  const { name, email } = user || {};

  useEffect(() => {
    appInsights.trackEvent({
      name: 'REACT_ERROR',
      properties: {
        organization: slug,
        user: {
          name,
          email,
        },
      },
    });

    appInsights.trackException({ exception: error, properties: { info } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ margin: '2rem auto', maxWidth: '500px' }}>
      <Text block variant={'xLarge'}>
        Oops. Something went wrong. 😢
      </Text>
      <Text block variant={'large'} style={{ marginBottom: '1rem' }}>
        So sorry to break your flow!
      </Text>
      <Text block>
        Our team has been informed of this error and will work on a resolution.{' '}
        In the meantime, <Link href="/">please return to the home page</Link>{' '}
        and try again.
      </Text>
    </div>
  );
};

export default ErrorComponent;
