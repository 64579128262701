import { useCallback } from 'react';
import { pickFile } from '../../../../Helpers/FileHelpers';
import toast from 'react-hot-toast';

interface UseFileUploadProps {
  setSelectedFile: (file: File | null) => void;
}
const validateFile = (file: File): boolean => {
  const hasExtension = file.name.lastIndexOf('.') > 0;
  if (!hasExtension) {
    toast.error('Please select a file with an extension, like .txt or .pdf');
    return false;
  }
  return true;
};

export const useFileUpload = ({ setSelectedFile }: UseFileUploadProps) => {
  const handleSelectFile = useCallback(async () => {
    try {
      const file = await pickFile({});
      if (!file) return;

      if (validateFile(file)) {
        setSelectedFile(file);
      }
    } catch (err) {
      toast.error('Error selecting file');
    }
  }, [setSelectedFile]);

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      const droppedFiles = event?.dataTransfer?.files;

      if (droppedFiles?.[0] && validateFile(droppedFiles[0])) {
        setSelectedFile(droppedFiles[0]);
      }
    },
    [setSelectedFile],
  );

  return { handleSelectFile, handleDrop };
};
