import {
  ContextualMenu,
  DirectionalHint,
  IconButton,
  mergeStyleSets,
} from '@fluentui/react';
import {
  DeleteIconProps,
  MoreIconProps,
  ShareIconProps,
} from '../../../utils/iconProps';
import { useBoolean } from '@fluentui/react-hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ArtifactContextualMenuCustomItem } from '../Artifacts/ArtifactsSidebar/ArtifactContextualMenuCustomItem';
import { useDeleteTaskDialog } from '../../../Hooks/Modals/DealRoom/useDeleteTaskDialog';

export const ActionItemMoreOptions = ({
  actionItemId,
  isSidePanel,
  organizationSlug,
  dealRoomId,
  handleViewTask,
  deleteCallback,
}: {
  actionItemId: number;
  isSidePanel?: boolean | undefined;
  organizationSlug: string;
  dealRoomId: number;
  handleViewTask?: () => void;
  deleteCallback?: () => void;
}) => {
  const [
    showActionItemContextMenu,
    {
      setTrue: setShowActionItemContextMenu,
      setFalse: setHideActionItemContextMenu,
    },
  ] = useBoolean(false);

  const [currentContextualMenuTarget, setCurrentContextualMenuTarget] =
    useState<HTMLElement | undefined>(undefined);

  const { createDeferred: deleteTaskDeferred, dialog: deleteTaskDialog } =
    useDeleteTaskDialog({
      organizationSlug,
      dealRoomId,
      taskId: actionItemId,
    });

  useEffect(() => {
    const currentTargetElement = document?.getElementById(
      `actionItem-${actionItemId || 'default'}${isSidePanel ? '-sidePanel' : ''}`,
    );
    setCurrentContextualMenuTarget(currentTargetElement || undefined);
  }, [actionItemId, isSidePanel]);

  const handleClickDeleteTask = useCallback(() => {
    deleteTaskDeferred()
      .promise.then(() => {
        if (deleteCallback) deleteCallback();
      })
      .catch(() => {});
  }, [deleteCallback, deleteTaskDeferred]);

  const milestoneContextualMenuItems = useMemo(() => {
    const items = [];
    if (!isSidePanel) {
      items.push({
        key: 'view_task',
        text: 'View Task',
        iconProps: ShareIconProps,
        onClick: () => handleViewTask && handleViewTask(),
        disabled: !!isSidePanel,
      });
    }
    
    items.push({
      key: 'delete_task',
      text: 'Delete Task',
      iconProps: DeleteIconProps,
      onClick: handleClickDeleteTask,
      disabled: actionItemId === undefined || actionItemId === null,
    });

    return items;
  }, [actionItemId, handleClickDeleteTask, handleViewTask, isSidePanel]); 

  const styles = mergeStyleSets({
    wrapper: {
      '.ms-layer:empty': {
        display: 'none',
      },
    },
  });

  return (
    <div className={styles.wrapper} onClick={(e) => e.stopPropagation()}>
      <IconButton
        id={`actionItem-${actionItemId || 'default'}${isSidePanel ? '-sidePanel' : ''}`}
        iconProps={MoreIconProps}
        onClick={setShowActionItemContextMenu}
      />
      {showActionItemContextMenu && (
        <ContextualMenu
          key={actionItemId}
          target={currentContextualMenuTarget}
          onDismiss={setHideActionItemContextMenu}
          contextualMenuItemAs={ArtifactContextualMenuCustomItem}
          directionalHint={DirectionalHint.bottomLeftEdge}
          items={milestoneContextualMenuItems}
        />
      )}
      {deleteTaskDialog}
    </div>
  );
};
