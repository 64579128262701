import React, { useState, JSXElementConstructor } from 'react';
import { AsyncClickOptions, AsyncOnClickProps } from './Common';

export const withAsyncOnClick = <T extends keyof JSX.IntrinsicElements>(
  InnerComponent: T,
  { disableOnExecute: parentDisableOnExecute }: AsyncClickOptions = {},
): React.FC<
  React.PropsWithChildren<JSX.IntrinsicElements[T] & AsyncOnClickProps>
> => {
  return ({
    onClick,
    disableOnExecute,
    renderSpinnerOnExecute,
    executeText,
    ...rest
  }) => {
    const [isExecuting, setIsExecuting] = useState<boolean>(false);

    return React.createElement(InnerComponent, {
      ...rest,
      style: {
        cursor: isExecuting ? 'not-allowed' : 'pointer',
        pointerEvents: isExecuting ? 'none' : 'auto',
      },
      onClick: async (e: React.MouseEvent<HTMLElement>): Promise<void> => {
        if ((parentDisableOnExecute || disableOnExecute) && isExecuting) {
          console.info(`EXECUTING ALREADY`);
          return;
        }
        setIsExecuting(true);
        try {
          await onClick(e);
        } catch (err: unknown) {
          console.info(
            `AsyncIntriniscElement caught exception executing async onClick`,
          );
        } finally {
          setIsExecuting(false);
        }
      },
    });
  };
};

export const AsyncSpan = withAsyncOnClick('span');
export const AsyncDiv = withAsyncOnClick('div');
