import { useAuth0 } from '@auth0/auth0-react';
import {
  FontWeights,
  IconButton,
  mergeStyles,
  Spinner,
  Text,
} from '@fluentui/react';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import {
  isAxiosErrorResponse,
  isForbiddenError,
} from '../../Helpers/AxiosHelpers';
import { MeetingPlanQuery } from '../../QueryNames';
import { MeetingflowsApiClient } from '../../Services/NetworkCommon';
import { OrganizationSlugRouteParams } from '../../types/RouteParams';
import OrganizationForbidden from '../Organization/OrganizationForbidden';
import { ResourceNotFound } from '../ResourceNotFound';
import { MeetingPlanPage as DecisionSiteMeetingflow } from './DecisionSiteMeetingflow';
import { DEALROOMS_COLORS } from '../../Themes/Themes';
import { useDealRoom } from '../../Hooks/useDealRoom';
import { BackIconProps } from '../../utils/iconProps';

export type DecisionSiteMeetingPlanRootProps = {};
export const DecisionSiteMeetingPlanRoot = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { dealRoomId } = useDealRoom();

  const { organizationSlug, meetingPlanId } = useParams<
    OrganizationSlugRouteParams & {
      meetingPlanId: string;
    }
  >();

  const {
    data: meetingplanData,
    isLoading: meetingPlanDataLoading,
    error: meetingPlanError,
  } = useQuery(
    MeetingPlanQuery(organizationSlug!, meetingPlanId!),
    async () => {
      const token = await getAccessTokenSilently();
      return MeetingflowsApiClient.getMeetingflow(
        organizationSlug!,
        meetingPlanId!,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!organizationSlug && !!meetingPlanId,
    },
  );

  useEffect(() => {
    if (!organizationSlug) {
      navigate(`/`);
      return;
    }
    if (!meetingPlanId) {
      navigate(`/organization/${organizationSlug}`);
      return;
    }
  }, [meetingPlanId, navigate, organizationSlug]);

  if (!organizationSlug || !meetingPlanId) {
    return null;
  }

  if (meetingPlanDataLoading) {
    return <Spinner />;
  }

  if (isForbiddenError(meetingPlanError)) {
    return <OrganizationForbidden />;
  }

  if (isAxiosErrorResponse(meetingPlanError)) {
    return <ResourceNotFound resourceType="Meetingflow" />;
  }

  const decisionSiteMeetingflowWrapperClass = mergeStyles({
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
  });

  const sectionTitleClass = mergeStyles({
    fontWeight: FontWeights.regular,
    fontSize: '2.5rem',
    lineHeight: '3rem',
    color: DEALROOMS_COLORS.themePrimary,
    marginLeft: '2rem',
    marginTop: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    alignContent: 'center',
  });

  return (
    <div className={decisionSiteMeetingflowWrapperClass}>
      <Text block className={sectionTitleClass}>
        <IconButton
          className="back"
          iconProps={BackIconProps}
          onClick={() => {
            navigate(
              `/organization/${organizationSlug}/dealroom/${dealRoomId}/journey`,
            );
          }}
        />
        {meetingplanData?.data.title}
      </Text>

      <DecisionSiteMeetingflow
        key={`${organizationSlug}__${meetingPlanId}`}
        organizationSlug={organizationSlug!}
        meetingPlanId={meetingPlanId!}
      />
    </div>
  );
};
