import { DSConfigurationHeaderRow } from '../Components/DSConfigurationHeaderRow';
import React, { useState, useCallback, useMemo } from 'react';
import { Stack, Text, TextField, PrimaryButton, mergeStyles } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { useUserProfile } from '../../../../Hooks/useProfile';
import { useDealRoom } from '../../../../Hooks/useDealRoom';

export const DSConfigurationMyInformation = () => {
  // Get user profile and update function from custom hook
  const { user: profile, updateUserProfileAsync } = useUserProfile();
  const { refetch: refetchDealRoom } = useDealRoom();

  // State for saving status and form data
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState({
    schedulingUrl: profile?.schedulingUrl || undefined,
    phoneNumber: profile?.phoneNumber || undefined,
  });

  // Styles for the container
  const containerStyle = mergeStyles({
    width: '100%',
    '.input_container': {
      label: {
        fontSize: '0.8rem',
        fontWeight: 200,
      },
      input: {
        backgroundColor: DEALROOMS_COLORS.neutralLight
      },
      '*': {
        outline: 'none',
      },
    },
  });

  // Handle input changes
  const handleInputChange = useCallback(
    (field: string) => (
      _: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string,
    ) => {
      setFormData((prev) => ({
        ...prev,
        [field]: newValue || '',
      }));
    },
    []
  );

  // Handle save action
  const handleSave = useCallback(async () => {
    try {
      setIsSaving(true);
      await updateUserProfileAsync({
        schedulingUrl: formData.schedulingUrl || null,
        phoneNumber: formData.phoneNumber || null,
      });
      await refetchDealRoom();
    } catch (error) {
      console.error('Error saving settings:', error);
    } finally {
      setIsSaving(false);
    }
  }, [formData, updateUserProfileAsync, refetchDealRoom]);


  // Check if there are any changes in the form
  const hasChanges = useMemo(() => {
    if (!profile) {
      return false;
    }

    if ((profile.schedulingUrl ?? undefined) !== formData.schedulingUrl ||
      (profile.phoneNumber ?? undefined) !== formData.phoneNumber) {
      return true;
    }

    return false;
  }, [profile, formData.schedulingUrl, formData.phoneNumber]);

  return (
    <Stack className={containerStyle} tokens={{ childrenGap: 20 }}>
      <DSConfigurationHeaderRow
        title="My Information"
        description="Update your profile information - this will appear in the dropdown at the top of your Decision Site.">
        <PrimaryButton
          onClick={handleSave}
          disabled={isSaving || !hasChanges}
          text={isSaving ? 'Saving...' : 'Save Changes'}
        />
      </DSConfigurationHeaderRow>

      <Stack tokens={{ childrenGap: 40 }}>
        <Stack tokens={{ childrenGap: 5 }} className="input_container">
          <Text variant="large">Scheduling URL</Text>
          <Text variant="small">The URL where others can schedule meetings with you</Text>
          <TextField
            value={formData.schedulingUrl}
            onChange={handleInputChange('schedulingUrl')}
            placeholder="Enter your scheduling URL"
          />
        </Stack>
        <Stack tokens={{ childrenGap: 5 }} className="input_container" >
          <Text variant="large">Phone Number</Text>
          <Text variant="small">Your contact phone number (numbers only)</Text>
          <TextField
            value={formData.phoneNumber}
            onChange={(_, newValue) => {
              const numericValue = newValue?.replace(/\D/g, '');
              handleInputChange('phoneNumber')(_, numericValue);
            }}
            placeholder="Enter your phone number"
            type="tel"
            pattern="[0-9]*"
          />
        </Stack>
      </Stack>
    </Stack>
  );
};