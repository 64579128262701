import { useAuth0 } from '@auth0/auth0-react';
import {
  FontSizes,
  FontWeights,
  NeutralColors,
  mergeStyles,
  useTheme,
} from '@fluentui/react';
import { StatusCircleErrorXIcon } from '@fluentui/react-icons-mdl2';
import { SlateMention } from '@meetingflow/common/Types/Slate';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { Editor, Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useSlateStatic } from 'slate-react';
import { useOrganization } from '../../../Hooks/useOrganization';
import {
  OrganizationContactQuery,
  OrganizationMemberQuery,
} from '../../../QueryNames';
import {
  ContactsApiClient,
  MembersApiClient,
} from '../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { MeetingPlanPanelContext } from '../../../types/MeetingPlanPanelContext';

export const MentionElement = ({
  attributes,
  children,
  element,
  setPanelContext,
}: RenderElementProps & {
  setPanelContext?: (
    context?: MeetingPlanPanelContext | MeetingPlanPanelContext[],
  ) => void;
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const theme = useTheme();
  const editor = useSlateStatic();

  const { slug: organizationSlug } = useOrganization();

  const mention = element as SlateMention;

  const {
    data: user,
    isLoading: userLoading,
    isRefetching: userRefetching,
    refetch: refetchUser,
  } = useQuery(
    OrganizationMemberQuery(organizationSlug!, mention.userId),
    async () => {
      const token = await getAccessTokenSilently();
      return MembersApiClient.getMember(organizationSlug!, mention.userId!, {
        headers: { Authorization: `Bearer ${token}` },
      });
    },
    {
      enabled: !!organizationSlug && !!mention.userId,
    },
  );

  const {
    data: contact,
    isLoading: contactLoading,
    isRefetching: contactRefetching,
    refetch: refetchContact,
  } = useQuery(
    OrganizationContactQuery(organizationSlug!, mention.email),
    async () => {
      const token = await getAccessTokenSilently();
      return ContactsApiClient.getContact(organizationSlug!, mention.email, {
        headers: { Authorization: `Bearer ${token}` },
      });
    },
    {
      enabled: !!organizationSlug && !!mention.email,
    },
  );

  useEffect(() => {
    if (mention.userId) {
      refetchUser();
    }
    if (mention.contactId) {
      refetchContact();
    }
  }, [mention.contactId, mention.userId, refetchContact, refetchUser]);

  useEffect(() => {
    if (!mention.userId) {
      const path = ReactEditor.findPath(editor, element);
      getAccessTokenSilently()
        .then((token) =>
          MembersApiClient.listMembers(
            { organizationSlug: organizationSlug!, email: mention.email },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          ),
        )
        .then((response) => {
          if (response.data.length === 1) {
            const u = response.data[0];
            Transforms.setNodes(
              editor,
              {
                userId: u.id,
                name: u.name,
              } as Partial<SlateMention>,
              { at: path },
            );
          }
        })
        .catch(() => {});
    }
  }, [
    editor,
    element,
    getAccessTokenSilently,
    mention.email,
    mention.userId,
    organizationSlug,
  ]);

  useEffect(() => {
    if (userLoading || userRefetching || contactLoading || contactRefetching) {
      return;
    }

    const path = ReactEditor.findPath(editor, element);
    if (user?.data) {
      Transforms.setNodes(
        editor,
        {
          name: user.data.name,
        } as Partial<SlateMention>,
        { at: path },
      );
    } else if (contact?.data) {
      Transforms.setNodes(
        editor,
        {
          contactId: contact?.data.id,
          name: contact.data.name,
        } as Partial<SlateMention>,
        { at: path },
      );
    }
  }, [
    contact?.data,
    contactLoading,
    contactRefetching,
    editor,
    element,
    user?.data,
    userLoading,
    userRefetching,
  ]);

  const mentionClass = mergeStyles({
    fontWeight: FontWeights.semibold,
    color: theme.palette.themePrimary,
    display: 'inline-block',
    position: 'relative',
    margin: '0px 0.1rem',
  });

  const mentionStyles = mergeStyles({
    display: 'inline-flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignItems: 'center',
    alignContent: 'space-around',
    columnGap: '0.25rem',
    height: '1.2rem',
    backgroundColor: MEETINGFLOW_COLORS.magentaDark,
    borderRadius: '1rem',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    lineHeight: '1.1rem',
    fontWeight: FontWeights.semibold,
    fontSize: FontSizes.small,
    color: MEETINGFLOW_COLORS.white,
    cursor: 'pointer',
    transition: '.3s ease-in-out all',
    ':hover': {
      backgroundColor: MEETINGFLOW_COLORS.magenta,
      color: NeutralColors.white,
      paddingRight: '0',

      '.mentions-buttons-wrapper': {
        display: 'inline-block',
        maxWidth: '5000rem',
        marginLeft: '.25rem',
      },
    },

    '.mentions-buttons-wrapper': {
      maxWidth: '0px',
      width: 'auto',
      height: '100%',
      display: 'flex',
      overflow: 'hidden',
      transition: '.3s ease-in-out all',
      backgroundColor: MEETINGFLOW_COLORS.magentaDark,
      borderTopRightRadius: '1rem',
      borderBottomRightRadius: '1rem',

      '[data-mention-button="true"]': {
        display: 'inline-block',
        height: '100%',
        width: '1rem',
        lineHeight: '1.2rem',
        textAlign: 'center',
        paddingLeft: '.25rem',
        paddingRight: '.25rem',
        transition: '.3s ease-in-out all',
        ':hover': {
          backgroundColor: MEETINGFLOW_COLORS.tealDarker,
        },
        svg: {
          height: '1em',
          verticalAlign: 'baseline',
        },
      },
    },
  });

  return (
    <div contentEditable={false} {...attributes} className={mentionClass}>
      {children}
      <div
        className={mentionStyles}
        contentEditable={false}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          const path = ReactEditor.findPath(editor, element);

          Transforms.select(editor, Editor.end(editor, path));

          if (contact?.data) {
            setPanelContext?.({ type: 'contact', contactId: contact.data.id });
          }
        }}
      >
        <span contentEditable={false} className="at-sign">
          @
        </span>
        {mention.name || mention.email}
        <div className="mentions-buttons-wrapper">
          <StatusCircleErrorXIcon
            contentEditable={false}
            data-mention-button
            onClick={(e) => {
              e.stopPropagation();
              Transforms.delete(editor, {
                at: ReactEditor.findPath(editor, element),
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};
