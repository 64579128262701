import {
  FontSizes,
  FontWeights,
  IconButton,
  NeutralColors,
  mergeStyles,
  useTheme,
} from '@fluentui/react';
import { SlateLink } from '@meetingflow/common/Types/Slate';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { isIOS, isMacOs } from 'react-device-detect';
import { Editor, Node, Range, Transforms } from 'slate';
import {
  Editable,
  useFocused,
  useSlateSelection,
  useSlateStatic,
} from 'slate-react';
import { CreateDeferredPromise } from '../../Helpers/DeferredPromise';
import { activeChords } from '../../Helpers/KeyboardEventHelpers';
import { useAIActionDialog } from '../../Hooks/Modals/useAIActionDialog';
import { useSidePanelShortcutDialog } from '../../Hooks/Modals/useSidePanelShortcutDialog';
import { useTags } from '../../Hooks/editor/useTags';
import {
  AddIntegrationModalOptions,
  AddIntegrationResult,
} from '../../Hooks/useAddIntegrationModal';
import { useCollapsibleHeadings } from '../../Hooks/useCollapsibleHeadings';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { useWindowFocus } from '../../Hooks/useWindowFocus';
import { DEALROOMS_COLORS, MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { MeetingPlanPanelContext } from '../../types/MeetingPlanPanelContext';
import EditorToolbar from './EditorToolbar';
import {
  beforeText,
  deindentList,
  getParentBlock,
  indentList,
  insertLink,
  isListActive,
  isNestedListActive,
  toggleBlock,
  toggleMark,
} from './Helpers/EditorHelpers';
import { RenderElement } from './RenderElement';
import { RenderLeaf } from './RenderLeaf';
import DecisionSiteEditorToolbar from './DecisionSiteEditorToolbar';

const isMacOrIOS = isMacOs || isIOS;

const TOOLBAR_OFFSCREEN_POSITION = '-9999px';

export type CustomEditableProps = {
  organizationSlug: string;
  meetingflowId?: string;
  allowTags?: boolean;
  name: string;
  editorMaxHeight?: number | string;
  placeholder?: string;
  readonly?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalEventProps?: Record<string, any>;
  backgroundColor?: string;
  onKeyDown:
    | ((event: React.KeyboardEvent<HTMLDivElement>) => boolean | undefined)
    | undefined;
  setPanelContext?: (
    context?: MeetingPlanPanelContext | MeetingPlanPanelContext[],
  ) => void;
  createAddIntegrationDeferred?: CreateDeferredPromise<
    AddIntegrationResult | undefined,
    AddIntegrationModalOptions | undefined
  >;
  alwaysFixedToolbar?: boolean;
  simpleTextRenderer?: boolean;
  conversationsMode?: boolean;
};
export const CustomEditable = ({
  organizationSlug,
  meetingflowId,
  allowTags,
  name,
  readonly,
  placeholder,
  editorMaxHeight,
  additionalEventProps = {},
  backgroundColor,
  onKeyDown: superKeyDown,
  setPanelContext,
  alwaysFixedToolbar = false,
  simpleTextRenderer = false,
  conversationsMode = false,
  createAddIntegrationDeferred,
}: CustomEditableProps) => {
  const appInsights = useAppInsightsContext();

  let { isDark } = useLightOrDarkMode();
  isDark = isDark && !conversationsMode && !simpleTextRenderer;
  const theme = useTheme();

  const editor = useSlateStatic();

  const selection = useSlateSelection();

  const inFocus = useFocused();
  const windowInFocus = useWindowFocus();

  const [fixedToolbarVisible, setFixedToolbarVisible] = useState(false);

  const { onKeyDown: tagKeyDownHandler, tagSuggestions } = useTags({
    editor,
    organizationSlug,
    allowTags,
  });

  const {
    createDeferred: createAIActionDialogDeferred,
    dialog: aiActionDialog,
  } = useAIActionDialog();

  const {
    createDeferred: createSidePanelShortcutDialogDeferred,
    dialog: sidePanelShortcutDialog,
  } = useSidePanelShortcutDialog();

  const {
    collapsedHeadings,
    setCollapsedHeadings,
    uncollapseHeading,
    hiddenElements,
  } = useCollapsibleHeadings({ editor });

  const renderElement = useMemo(
    () =>
      RenderElement({
        editor,
        setPanelContext,
        collapsedHeadings,
        createAddIntegrationDeferred,
        setCollapsedHeadings,
        uncollapseHeading,
        hiddenElements,
        getAIActionContext: createAIActionDialogDeferred,
        getSidePanelShortcutContext: createSidePanelShortcutDialogDeferred,
      }),
    [
      collapsedHeadings,
      createAIActionDialogDeferred,
      createSidePanelShortcutDialogDeferred,
      editor,
      hiddenElements,
      setCollapsedHeadings,
      setPanelContext,
      uncollapseHeading,
      createAddIntegrationDeferred,
    ],
  );
  const renderLeaf = useCallback(RenderLeaf, []);

  const onFocus = useCallback(() => {
    appInsights.trackEvent({
      name: `EDITOR_FOCUS_${name}`,
      properties: {
        editorName: name,
        ...additionalEventProps,
      },
    });
  }, [additionalEventProps, appInsights, name]);

  const onBlur = useCallback(() => {
    appInsights.trackEvent({
      name: `EDITOR_BLUR_${name}`,
      properties: {
        editorName: name,
        ...additionalEventProps,
      },
    });
  }, [additionalEventProps, appInsights, name]);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (!!readonly) {
        return;
      }

      if (tagKeyDownHandler(event)) {
        return;
      }

      if (superKeyDown?.(event)) {
        return;
      }

      const { selection } = editor;

      // Default left/right behavior is unit:'character'.
      // This fails to distinguish between two cursor positions, such as
      // <inline>foo<cursor/></inline> vs <inline>foo</inline><cursor/>.
      // Here we modify the behavior to unit:'offset'.
      // This lets the user step into and out of the inline without stepping over characters.
      // You may wish to customize this further to only use unit:'offset' in specific cases.
      const {
        modifierActive,
        ctrlChord,
        cmdChord,
        ctrlAltChord,
        ctrlShiftChord,
        cmdShiftChord,
        cmdControlChord,
      } = activeChords(event);

      if (!modifierActive && selection && Range.isCollapsed(selection)) {
        if (event.key === 'ArrowLeft') {
          event.preventDefault();
          Transforms.move(editor, { unit: 'offset', reverse: true });
          return;
        }
        if (event.key === 'ArrowRight') {
          event.preventDefault();
          Transforms.move(editor, { unit: 'offset' });
          return;
        }
      }

      if (
        event.key === 'Tab' &&
        !modifierActive &&
        isListActive(editor) &&
        !beforeText(editor)
      ) {
        if (event.shiftKey && isNestedListActive(editor)) {
          event.preventDefault();
          deindentList(editor);
        } else if (!event.shiftKey) {
          event.preventDefault();
          indentList(editor);
        }
        return;
      }

      if (!modifierActive) {
        return;
      }

      switch (event.key) {
        case 'b': {
          if ((isMacOrIOS && cmdChord) || (!isMacOrIOS && ctrlChord)) {
            event.preventDefault();
            toggleMark(editor, 'bold');
            break;
          }
          break;
        }
        case 'h': {
          if (!isMacOrIOS && ctrlAltChord) {
            event.preventDefault();
            toggleMark(editor, 'highlight');
            break;
          } else if (isMacOrIOS && cmdControlChord) {
            event.preventDefault();
            toggleMark(editor, 'highlight');
            break;
          }
          break;
        }
        case 'i': {
          if ((isMacOrIOS && cmdChord) || (!isMacOrIOS && ctrlChord)) {
            event.preventDefault();
            toggleMark(editor, 'italic');
            break;
          }
          break;
        }
        case 'k': {
          if ((isMacOrIOS && cmdChord) || (!isMacOrIOS && ctrlChord)) {
            event.preventDefault();
            event.stopPropagation();

            const activeLinkNode = getParentBlock<SlateLink>(editor, {
              type: 'link',
            });

            const activeLink = activeLinkNode?.[0];

            editor
              .getLinkContent(
                activeLink
                  ? { href: activeLink.href, text: Node.string(activeLink) }
                  : selection && !Range.isCollapsed(selection)
                    ? { text: Editor.string(editor, selection) }
                    : undefined,
              )
              .then((content) => {
                if (content.href) {
                  insertLink(editor, content.href, content.text);
                }
              })
              .catch();
          }
          break;
        }
        case 'u': {
          if ((isMacOrIOS && cmdChord) || (!isMacOrIOS && ctrlChord)) {
            event.preventDefault();
            toggleMark(editor, 'underline');
            break;
          }
          break;
        }
        case 'x': {
          if (
            (isMacOrIOS && cmdShiftChord) ||
            (!isMacOrIOS && ctrlShiftChord)
          ) {
            event.preventDefault();
            toggleMark(editor, 'strikethrough');
            break;
          }
          break;
        }

        case '7':
        case '&': {
          if (
            (isMacOrIOS && cmdShiftChord) ||
            (!isMacOrIOS && ctrlShiftChord)
          ) {
            event.preventDefault();
            toggleBlock(editor, 'numbered-list');
            break;
          }
          break;
        }
        case '8':
        case '*': {
          if (
            (isMacOrIOS && cmdShiftChord) ||
            (!isMacOrIOS && ctrlShiftChord)
          ) {
            event.preventDefault();
            toggleBlock(editor, 'bulleted-list');
            break;
          }
          break;
        }
        case '9':
        case '(': {
          if (
            (isMacOrIOS && cmdShiftChord) ||
            (!isMacOrIOS && ctrlShiftChord)
          ) {
            event.preventDefault();
            toggleBlock(editor, 'checklist-item', { checked: false });
          }
          break;
        }
        default: {
          break;
        }
      }
    },
    [editor, readonly, superKeyDown, tagKeyDownHandler],
  );

  const editableClass = mergeStyles({
    padding: '2.5rem .5rem 2rem 1.5rem',
    width: '100%',
    height: '100%',
    maxHeight: editorMaxHeight ? editorMaxHeight : undefined,
    boxSizing: 'border-box',
    borderRadius: '.25rem',
    flexDirection: 'column',
    overflowWrap: 'break-word',
    overflow: 'auto',
    border: 'none !important',
    outline: '1px solid transparent !important',
    backgroundColor: readonly
      ? undefined
      : isDark
        ? backgroundColor || NeutralColors.gray200
        : backgroundColor || 'white',

    animationName: 'fadeInAnimation',
    animationDuration: '.3s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '1',
    animationFillMode: 'forwards',
    transition: '.3s ease-in-out all',

    ':focus': {
      backgroundColor: readonly
        ? undefined
        : isDark
          ? backgroundColor || NeutralColors.gray200
          : backgroundColor || 'white',

      '[data-slate-placeholder="true"]': {
        opacity: `0.2 !important`,
      },
    },

    '[data-slate-placeholder="true"]': {
      width: 'auto !important',
    },

    '[data-slate-node="element"][href]': {
      color: isDark ? theme.palette.greenLight : theme.palette.themePrimary,
    },

    'p[data-slate-node="element"]:only-child': {
      margin: 0,
    },

    'div[data-slate-node="element"]:only-child': {
      margin: `0 0 .25rem 0 !important`,
    },

    '[data-meetingflow="header-collapsible"]': {
      position: 'relative',
    },

    '[data-meetingflow="header-collapse-arrow"]': {
      fontSize: FontSizes.small,
      position: 'absolute',
      left: '-1rem',
      cursor: 'pointer',
    },

    h2: {
      fontSize: FontSizes.xLarge,
      margin: '0 0 .5rem 0',
      lineHeight: '1.5rem',
      fontWeight: FontWeights.semibold,
      color: isDark ? NeutralColors.gray30 : NeutralColors.gray150,
    },

    h3: {
      fontSize: FontSizes.large,
      margin: '0 0 .5rem 0',
      lineHeight: '1.25rem',
      fontWeight: FontWeights.semibold,
      color: isDark ? NeutralColors.gray30 : NeutralColors.gray150,
    },

    h4: {
      fontSize: FontSizes.mediumPlus,
      margin: '0 0 .5rem 0',
      lineHeight: '1.25rem',
      fontWeight: FontWeights.semibold,
      color: isDark ? NeutralColors.gray30 : NeutralColors.gray120,
    },

    h5: {
      fontSize: FontSizes.medium,
      margin: '0 0 .5rem 0',
      lineHeight: '1rem',
      fontWeight: FontWeights.semibold,
      color: isDark ? NeutralColors.gray30 : NeutralColors.gray120,
    },

    h6: {
      fontSize: FontSizes.small,
      margin: '0 0 .5rem 0',
      lineHeight: '1rem',
      fontWeight: FontWeights.semibold,
      color: isDark ? NeutralColors.gray30 : NeutralColors.gray120,
    },

    strong: {
      fontWeight: FontWeights.semibold,
      color: isDark ? 'white' : NeutralColors.black,
    },

    blockquote: {
      backgroundColor: isDark ? NeutralColors.gray200 : NeutralColors.gray30,
      marginLeft: '1rem',
      marginRight: '1rem',
      marginTop: 0,
      marginBottom: '.5rem',
      padding: '.5rem',
      position: 'relative',
      borderRadius: '.5rem',
      fontFamily: 'Georgia, serif',
      fontStyle: 'italic',
      'p:only-child': {
        marginBottom: 0,
      },
    },

    'p, ol, ul': {
      fontSize: readonly ? '12px' : '13px',
      lineHeight: readonly ? '1.1rem' : '1.25rem',
      margin: '0 0 .5rem 0',
      color: isDark ? NeutralColors.gray30 : NeutralColors.gray200,

      span: {
        height: readonly ? '1.1rem' : '1.25rem',
      },
    },

    'ol, ul': {
      marginLeft: '0',
      padding: `0 0 0 1rem`,
    },

    'ol ol, ul ul': {
      marginLeft: 0,
      padding: `0 0 0 1rem`,
      marginBottom: 0,
    },

    li: {
      marginLeft: 0,
      lineHeight: '1.5rem',
    },

    img: {
      margin: '0',
    },

    '> div': {
      margin: '0 0 .5rem 0 !important',
    },

    br: {
      margin: '0 0 .5rem 0 !important',
    },

    'span[data-slate-length="0"]': {
      height: `0 !important`,
    },

    a: {
      display: 'inline-block',
    },

    '*[data-slate-inline="true"]': {
      display: 'inline-block',
    },

    ...(simpleTextRenderer
      ? {
          height: 'auto',
          padding: '0',
          wordBreak: 'break-word',
        }
      : {}),
    ...(conversationsMode
      ? {
          maxHeight: '5.5rem',
          padding: '0',
          wordBreak: 'break-word',
        }
      : {}),
  });

  const editorWrapperClass = mergeStyles({
    position: 'relative',
    height: 'calc(100vh - 9rem)',
    minHeight: `calc(100vh - 9rem)`,
    maxHeight: editorMaxHeight ? editorMaxHeight : `calc(100vh - 9rem)`,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '.75rem',
    ...(simpleTextRenderer
      ? {
          height: 'auto',
          minHeight: 'auto',
          maxHeight: 'auto',
          paddingBottom: '0',
        }
      : {}),
  });

  const fixedToolbarButtonClass = mergeStyles({
    position: 'absolute',
    top: '1px',
    left: 'calc(1.5rem - 1px)',
    paddingLeft: '1rem',
    paddingRight: '.5rem',
    paddingTop: '.2rem',
    zIndex: 500,
    height: '2rem',
    width: 'calc(100% - 1.5rem)',
    backgroundColor: isDark
      ? NeutralColors.gray210
      : MEETINGFLOW_COLORS.purpleGrey,
    boxSizing: 'border-box',
  });

  const isMeetingflow = import.meta.env.VITE_APP_NAME === 'meetingflow';
  const isDecisionSite = import.meta.env.VITE_APP_NAME !== 'meetingflow';

  const ToolbarComponent = isMeetingflow
    ? EditorToolbar
    : DecisionSiteEditorToolbar;

  return (
    <div id="editor-host" className={editorWrapperClass}>
      {!simpleTextRenderer && (
        <div className={classNames(fixedToolbarButtonClass, 'editor-toolbar')}>
          {(fixedToolbarVisible || alwaysFixedToolbar) && organizationSlug ? (
            <div
              style={{
                display: 'inline-block',
                height: '2rem',
              }}
              onMouseDown={(e) => {
                // prevent toolbar from taking focus away from editor
                e.preventDefault();
                setFixedToolbarVisible(true);
              }}
            >
              <ToolbarComponent
                editor={editor}
                readonly={readonly}
                selection={selection}
                inFocus={inFocus}
                organizationSlug={organizationSlug}
                meetingPlanId={meetingflowId}
                getAIActionContext={
                  setPanelContext ? createAIActionDialogDeferred : undefined
                }
                getSidePanelShortcutContext={
                  setPanelContext
                    ? createSidePanelShortcutDialogDeferred
                    : undefined
                }
              />
            </div>
          ) : null}
          {!alwaysFixedToolbar ? (
            <IconButton
              onMouseDown={(e) => {
                // prevent button from taking focus away from editor
                e.preventDefault();
                setFixedToolbarVisible(!fixedToolbarVisible);
              }}
              iconProps={{ iconName: 'Font' }}
            />
          ) : null}
          {aiActionDialog}
          {sidePanelShortcutDialog}
        </div>
      )}

      {!readonly &&
      !fixedToolbarVisible &&
      !alwaysFixedToolbar &&
      organizationSlug ? (
        <ToolbarComponent
          editor={editor}
          readonly={readonly}
          selection={selection}
          inFocus={inFocus}
          hoverWithSelection
          organizationSlug={organizationSlug}
          meetingPlanId={meetingflowId}
          getAIActionContext={
            setPanelContext ? createAIActionDialogDeferred : undefined
          }
          getSidePanelShortcutContext={
            setPanelContext ? createSidePanelShortcutDialogDeferred : undefined
          }
        />
      ) : null}

      <Editable
        readOnly={readonly}
        className={classNames(editableClass, 'editor-frame')}
        placeholder={windowInFocus && inFocus ? undefined : placeholder}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
      />
      {aiActionDialog}
      {sidePanelShortcutDialog}
    </div>
  );
};
