import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { absoluteUrl } from '../Helpers/URLHelpers';

export type UseModifierAwareNavigateOptions = {
  defaultToNewTab?: boolean;
};
export const useModifierAwareNavigate = (
  { defaultToNewTab }: UseModifierAwareNavigateOptions = {
    defaultToNewTab: false,
  },
) => {
  const navigate = useNavigate();

  const locationAwareNavigate = useCallback(
    (
      to: string,
      event: React.MouseEvent<unknown>,
    ) => {
      if (defaultToNewTab !== (event.ctrlKey || event.metaKey)) {
        window.open(absoluteUrl(to), '_blank');
      } else if (event.shiftKey) {
        window.open(
          absoluteUrl(to),
          '_blank',
          `innerWidth=${window.innerWidth},innerHeight=${window.innerHeight}`,
        );
      } else {
        navigate(to);
      }
    },
    [defaultToNewTab, navigate],
  );

  return locationAwareNavigate;
};
