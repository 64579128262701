import {
  FontSizes,
  mergeStyles,
  NeutralColors,
  SearchBox,
  Spinner,
  Text,
  Toggle,
} from '@fluentui/react';
import { useExternalServiceConfigurations } from '../../../../Hooks/useExternalServiceConfigurations';
import { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ApiClient } from '../../../../Services/NetworkCommon';
import { useQuery } from 'react-query';
import { SalesforceOpportunitiesQuery } from '../../../../QueryNames';
import { SalesforceOpportunity } from '../../../../Models/Salesforce/SalesforceOpportunity';
import { ExternalServiceObject } from '@meetingflow/common/Api/data-contracts';
import { SalesforceOpportunityTile } from './SalesforceOpportunityTile';
import { debounce } from 'lodash';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import { SalesforcePanelContext } from '../../../../types/SalesforcePanelContext';
import { useLocalStorageState } from '../../../../Hooks/useLocalStorageState';
import { MEETINGFLOW_COLORS } from '../../../../Themes/Themes';

export type SalesforceBrowserSidePanelOpportunityContentProps = {
  organizationSlug: string;
  meetingPlanId?: string;
  associatedObjects?: ExternalServiceObject[];
  salesforceConfigurationId: number;
  externalDomains?: string[];
  defaultNewOppName?: string;
  onPickedObject: (
    object: ExternalServiceObject | Omit<ExternalServiceObject, 'id'>,
  ) => void;
  onPinnedObject:
    | ((e?: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<unknown>)
    | undefined;
  pushSalesforcePanel: (context: SalesforcePanelContext) => void;
};
export const SalesforceBrowserSidePanelOpportunityContent = ({
  organizationSlug,
  meetingPlanId,
  associatedObjects,
  salesforceConfigurationId,
  externalDomains,
  onPickedObject,
  onPinnedObject,
  pushSalesforcePanel,
  defaultNewOppName = '',
}: SalesforceBrowserSidePanelOpportunityContentProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const { isDark } = useLightOrDarkMode();

  const { loading: configurationsLoading, configurationById } =
    useExternalServiceConfigurations({ app: 'SALESFORCE', withToken: true });

  const [q, setQ] = useState<string>('');

  const [onlyActive, setOnlyActive] = useLocalStorageState<boolean>(
    'SF_PICKER_SIDE_PANEL_DEALS_ONLY_ACTIVE',
    true,
  );
  const [onlyMine, setOnlyMine] = useLocalStorageState<boolean>(
    'SF_PICKER_SIDE_PANEL_DEALS_ONLY_MINE',
    true,
  );

  const {
    data: salesforceOpportunities,
    isLoading: salesforceOpportunitiesLoading,
    isRefetching: salesforceOpportunitiesRefetching,
    refetch: refetchSalesforceOpportunities,
  } = useQuery(
    SalesforceOpportunitiesQuery(organizationSlug!, salesforceConfigurationId),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<SalesforceOpportunity[]>(
        `/organization/${organizationSlug}/external/salesforce/configuration/${salesforceConfigurationId}/opportunities`,
        {
          params: {
            q: q ?? undefined,
            active: onlyActive,
            my: onlyMine,
            domains: undefined,
          },
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRefetch = useCallback(
    debounce(refetchSalesforceOpportunities, 250),
    [refetchSalesforceOpportunities],
  );

  useEffect(() => {
    debouncedRefetch();
  }, [q, onlyActive, onlyMine, debouncedRefetch]);

  useEffect(() => {
    refetchSalesforceOpportunities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesforceConfigurationId]);

  const panelContent = mergeStyles({
    padding: '0 .5rem',
  });

  const slideSpinnerClass = mergeStyles({
    height: '2rem',
    animationName: 'slideDownSpinnerAnimation',
    animationDuration: '1s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '.5',
    animationFillMode: 'forwards',
    position: 'absolute',
    top: '4.35rem',
    right: '.5rem',
  });

  if (configurationsLoading) {
    return <Spinner className={slideSpinnerClass} />;
  }

  return (
    <>
      <div className={panelContent}>
        <div
          style={{
            marginLeft: '-.5rem',
            marginRight: '-.5rem',
            marginBottom: '.5rem',
            padding: '.5rem',
            borderBottom: `1px solid ${
              isDark ? NeutralColors.gray180 : MEETINGFLOW_COLORS.purpleGrey
            }`,
            backgroundColor: isDark
              ? NeutralColors.gray210
              : MEETINGFLOW_COLORS.purpleUltraLight,
          }}
        >
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              marginBottom: '.25rem',
            }}
          >
            <div style={{ paddingRight: '.5rem ' }}>
              <Toggle
                disabled={
                  salesforceOpportunitiesLoading ||
                  salesforceOpportunitiesRefetching
                }
                label={'Show Only Mine'}
                checked={onlyMine === true}
                onChange={(e, checked) => setOnlyMine(!!checked)}
                inlineLabel
                styles={{
                  root: {
                    margin: 0,
                    transition: '.3s ease-in-out all',
                    '.ms-Toggle-background': {
                      backgroundColor: MEETINGFLOW_COLORS.white,
                    },
                    '.ms-Toggle-thumb': {
                      backgroundColor: MEETINGFLOW_COLORS.teal,
                    },
                    'button:disabled .ms-Toggle-thumb': {
                      backgroundColor: NeutralColors.gray70,
                    },
                    'button:hover': {
                      backgroundColor: NeutralColors.gray40,
                    },
                  },
                  label: {
                    order: 0,
                    margin: 0,
                    marginRight: '.25rem',
                    fontSize: FontSizes.small,
                    width: `calc(100% - 66px)`,
                    color: NeutralColors.gray120,
                  },

                  pill: {
                    backgroundColor: NeutralColors.gray40,
                    outlineWidth: `0 !important`,
                    borderWidth: `0 !important`,
                  },
                }}
              />
            </div>
            <div
              style={{
                borderLeft: `1px solid ${
                  isDark ? NeutralColors.gray180 : NeutralColors.gray50
                }`,
                paddingLeft: '.5rem',
              }}
            >
              <Toggle
                disabled={
                  salesforceOpportunitiesLoading ||
                  salesforceOpportunitiesRefetching
                }
                label={'Show Only Active'}
                checked={onlyActive === true}
                onChange={(e, checked) => setOnlyActive(!!checked)}
                inlineLabel
                styles={{
                  root: {
                    margin: 0,
                    transition: '.3s ease-in-out all',
                    '.ms-Toggle-background': {
                      backgroundColor: MEETINGFLOW_COLORS.white,
                    },
                    '.ms-Toggle-thumb': {
                      backgroundColor: MEETINGFLOW_COLORS.teal,
                    },
                    'button:disabled .ms-Toggle-thumb': {
                      backgroundColor: NeutralColors.gray70,
                    },
                    'button:hover': {
                      backgroundColor: NeutralColors.gray40,
                    },
                  },
                  label: {
                    order: 0,
                    margin: 0,
                    marginRight: '.25rem',
                    fontSize: FontSizes.small,
                    width: `calc(100% - 66px)`,
                    color: NeutralColors.gray120,
                  },

                  pill: {
                    backgroundColor: NeutralColors.gray40,
                    outlineWidth: `0 !important`,
                    borderWidth: `0 !important`,
                  },
                }}
              />
            </div>
          </div>
          <SearchBox
            disabled={
              salesforceOpportunitiesLoading ||
              salesforceOpportunitiesRefetching
            }
            placeholder="Search opportunities by name..."
            value={q}
            onChange={(e, newValue) => setQ(newValue ?? '')}
            styles={{
              root: {
                transition: '.3s all ease-in-out',
                fontSize: FontSizes.small,
                height: '1.5rem',
                marginTop: '.5rem',
                width:
                  configurationsLoading ||
                  salesforceOpportunitiesLoading ||
                  salesforceOpportunitiesRefetching
                    ? 'calc(100% - 2rem)'
                    : '100%',
              },
            }}
          />
        </div>
      </div>

      <div
        style={{
          overflowY: 'auto',
          maxHeight: 'calc(100% - 18rem)',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '.125rem',
          padding: '0 .5rem 2rem .5rem',
          boxSizing: 'border-box',
        }}
      >
        {salesforceOpportunitiesLoading || salesforceOpportunitiesRefetching ? (
          <Spinner className={slideSpinnerClass} />
        ) : null}
        {salesforceOpportunities?.data &&
        salesforceOpportunities?.data?.length > 0 ? (
          <>
            {salesforceOpportunities?.data?.map((opportunity) => {
              const associatedObjectIds = associatedObjects?.map(
                (o) => o.externalId,
              );
              const isPinned = associatedObjectIds?.includes(opportunity.Id);
              const externalServiceObjectId = associatedObjects?.find(
                (o) => o.externalId === opportunity.Id,
              )?.id;
              const externalServiceObject = {
                externalId: opportunity.Id,
                objectType: 'DEAL',
                name: opportunity.Name,
                serviceConfigurationId: salesforceConfigurationId,
                serviceConfiguration: configurationById(
                  salesforceConfigurationId,
                )!,
              } as Omit<ExternalServiceObject, 'id'>;
              return (
                <SalesforceOpportunityTile
                  organizationSlug={organizationSlug}
                  meetingPlanId={meetingPlanId}
                  key={opportunity.Id}
                  externalId={opportunity.Id}
                  name={opportunity.Name}
                  salesforceInstance={
                    configurationById(salesforceConfigurationId)!.instanceId!
                  }
                  accountName={opportunity.Account?.Name}
                  isPinned={isPinned}
                  externalServiceObjectId={externalServiceObjectId}
                  pushSalesforcePanel={pushSalesforcePanel}
                  onClick={() => onPickedObject(externalServiceObject)}
                  onPinClick={onPinnedObject}
                  showExternalLink
                />
              );
            })}
          </>
        ) : (
          <div>
            {!salesforceOpportunitiesLoading &&
            !salesforceOpportunitiesRefetching ? (
              <Text
                block
                variant="small"
                style={{ fontStyle: 'italic', margin: '.5rem 0' }}
              >
                There are no matching opportunities. Change your filters above
                to show all accounts, if needed.
              </Text>
            ) : null}
          </div>
        )}
      </div>
    </>
  );
};
