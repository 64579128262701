import {
  Facepile,
  IFacepilePersona,
  OverflowButtonType,
  PersonaSize,
} from '@fluentui/react';
import { PresenceState } from '../types/MeetingPlanDocument';
import { useLightOrDarkMode } from '../Hooks/useLightOrDarkMode';
import { PersonaWithTooltip } from './PersonaWithTooltip';
import { useParams } from 'react-router';
import { OrganizationSlugRouteParams } from '../types/RouteParams';
import { uniqBy } from 'lodash';
import { Contact } from '@meetingflow/common/Api/data-contracts';
import { getDomain } from '@meetingflow/common/StringHelpers';

interface PresenceFacepileProps {
  awarenessStates: Map<number, PresenceState>;
  maxDisplayablePersonas?: number;
}

const NUM_DISPLAYABLE_PERSONAS = 12;

export const PresenceFacepile = ({
  awarenessStates,
  maxDisplayablePersonas = NUM_DISPLAYABLE_PERSONAS,
}: PresenceFacepileProps) => {
  const { isDark } = useLightOrDarkMode();

  const { organizationSlug } = useParams<OrganizationSlugRouteParams>();

  const personas: IFacepilePersona[] = (
    import.meta.env.PROD
      ? uniqBy(
          Array.from(awarenessStates.values()),
          (awareness) => awareness.email,
        )
      : Array.from(awarenessStates.values())
  )
    .filter((state) => state.name && state.email)
    .map((state) => {
      const persona = {
        personaName: state.name,
        imageUrl: state.picture,
        data: {
          name: state.name,
          email: state.email,
          emailDomain: getDomain(state.email),
          avatarUrl: state.picture,
        } as Contact,
      } as IFacepilePersona;
      return persona;
    });

  if (!personas.length) return null;

  return (
    <Facepile
      styles={{
        root: {
          display: 'inline-block',
          '.ms-Persona-initials span': {
            color: isDark ? 'white !important' : undefined,
          },
        },
        itemContainer: {
          display: 'inline-block',
          position: 'relative',
          top: '.35rem',
        },
      }}
      showTooltip
      maxDisplayablePersonas={maxDisplayablePersonas}
      overflowButtonType={OverflowButtonType.descriptive}
      overflowButtonProps={{
        ariaLabel: 'More viewers',
      }}
      overflowPersonas={personas?.slice(maxDisplayablePersonas, -1) ?? []}
      personas={personas?.slice(0, maxDisplayablePersonas) ?? []}
      // personaSize={PersonaSize.size24}
      onRenderPersonaCoin={(persona) =>
        persona && organizationSlug ? (
          <PersonaWithTooltip
            organizationSlug={organizationSlug}
            persona={persona}
            imageUrl={persona.imageUrl}
            size={PersonaSize.size24}
            styles={{
              root: {
                borderRadius: '50%',

                '.ms-Image': {
                  boxShadow: '0px 2px 3px 0px rgba(0,0,0,0.1)',
                },
              },
            }}
          />
        ) : (
          <></>
        )
      }
      onRenderPersona={(persona) =>
        persona && organizationSlug ? (
          <PersonaWithTooltip
            organizationSlug={organizationSlug}
            persona={persona}
            imageUrl={persona.imageUrl}
            size={PersonaSize.size24}
            styles={{
              root: {
                borderRadius: '50%',

                '.ms-Image': {
                  boxShadow: '0px 2px 3px 0px rgba(0,0,0,0.1)',
                },
              },
            }}
          />
        ) : (
          <></>
        )
      }
    />
  );
};
