import { mergeStyleSets } from '@fluentui/react';
import { DealRoomMilestone } from '@meetingflow/common/Api/data-contracts';
import { AddToIconProps } from '../../../utils/iconProps';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import DealRoomAsyncCommandBarButton, {
  DealRoomCommandBarButton,
} from '../Components/DealRoomButton';
import { MilestoneDatePicker } from './MilestoneDatePicker';
import { MilestoneMoreOptions } from './MilestoneMoreOptions';

type MilestoneHeaderProps = {
  milestone: DealRoomMilestone;
  organizationSlug: string;
  dealRoomId: number;
  onDueDateChange: (newDate: Date | null | undefined) => void;
  onRequestArtifact: () => Promise<void>;
  onAddTask: () => void;
};

export const MilestoneHeader = ({
  milestone,
  organizationSlug,
  dealRoomId,
  onDueDateChange,
  onRequestArtifact,
  onAddTask,
}: MilestoneHeaderProps) => {
  const styles = mergeStyleSets({
    milestoneHeader: {
      display: 'flex',
      alignItems: 'center',
      gap: '1.5rem',
    },
    title: {
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
      fontWeight: '400',
      color: DEALROOMS_COLORS.themePrimary,
    },
    buttons: {
      marginLeft: 'auto',
      display: 'flex',
      alignItems: 'center',
      gap: '.5rem',
    },
    buttonsCustomStyle: {
      border: 'none',
      backgroundColor: 'transparent',
    },
  });

  return (
    <div className={styles.milestoneHeader}>
      <div className={styles.title}>{milestone.type}</div>
      <MilestoneDatePicker
        value={milestone.dueDate}
        onChange={onDueDateChange}
      />
      <div className={styles.buttons}>
        <DealRoomAsyncCommandBarButton
          onClick={onRequestArtifact}
          text="Request Artifact"
          buttonStyleType="COMMAND_BAR"
          customClasses={styles.buttonsCustomStyle}
          iconProps={AddToIconProps}
        />
        <DealRoomCommandBarButton
          onClick={onAddTask}
          text="Add Task"
          buttonStyleType="COMMAND_BAR"
          customClasses={styles.buttonsCustomStyle}
          iconProps={AddToIconProps}
        />
      </div>
      {/* <MilestoneMoreOptions
        milestoneType={milestone.type}
        organizationSlug={organizationSlug}
        dealRoomId={dealRoomId}
      /> */}
    </div>
  );
};
