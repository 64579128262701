import { useAuth0 } from '@auth0/auth0-react';
import {
  FontIcon,
  FontSizes,
  FontWeights,
  IPivotStyles,
  Layer,
  LayerHost,
  NeutralColors,
  Pivot,
  PivotItem,
  mergeStyles,
} from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import {
  CallRecordingStatus,
  CallRecordingStatusSubCode,
  Contact,
  Utterance,
} from '@meetingflow/common/Api/data-contracts';
import { DeduplicateArray } from '@meetingflow/common/ArrayHelpers';
import {
  getTranscriptStats,
  transcriptify,
  tryMatchAttendeesToParticipants,
} from '@meetingflow/common/TranscriptHelpers';
import { Truthy } from '@meetingflow/common/TypeHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { DateTime, Duration } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import 'react-customize-token-input/dist/react-customize-token-input.css';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { EMPTY_ARRAY } from '../../Constants';
import { DeferredPromise } from '../../Helpers/DeferredPromise';
import { toContentPath } from '../../Helpers/FileHelpers';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { useOrganization } from '../../Hooks/useOrganization';
import {
  MeetingPlanCallRecorderStatusQuery,
  MeetingPlanCallTranscriptQuery,
  MeetingPlanQuery,
} from '../../QueryNames';
import { MeetingflowsApiClient } from '../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { SparseMeetingflow } from '../RecordingShare/RecordingShareApp';
import VideoPlayer, {
  VideoPlayerControls,
  VideoPlayerState,
} from '../Video/VideoPlayer';
import { VideoTranscript } from '../Video/VideoTranscript';
import CallRecordingParticipantCard from './CallRecordingParticipantCard';
import CallRecordingTimeline from './CallRecordingTimeline';
import CallRecordingTopicCard, { Topic } from './CallRecordingTopicCard';
import { CollaborateDefaultContext } from './Dialogs/CollaborateDialog';

// A simple list of colors to choose from for each speaker. It needs to be very long. It's not long enough yet.
// Also, colors should all be dark enough to be readable with white text.
export const speakerColors = [
  MEETINGFLOW_COLORS.purpleSecondary,
  MEETINGFLOW_COLORS.teal,
  MEETINGFLOW_COLORS.orange,
  MEETINGFLOW_COLORS.magenta,
  MEETINGFLOW_COLORS.purpleMedium,
  MEETINGFLOW_COLORS.tealDark,
  MEETINGFLOW_COLORS.orangeDark,
  MEETINGFLOW_COLORS.magentaDark,
  MEETINGFLOW_COLORS.purpleDark,
  MEETINGFLOW_COLORS.tealLight,
  MEETINGFLOW_COLORS.orangeLight,
  MEETINGFLOW_COLORS.magentaLight,
  MEETINGFLOW_COLORS.purpleLight,
  MEETINGFLOW_COLORS.purpleDarker,
];
export interface CallRecordingPlayerProps {
  organizationSlug: string;
  meetingPlanId: string;
  sparseMeetingplan?: SparseMeetingflow;
  fullscreen?: boolean;
  externalView?: boolean;
  scheduleCallRecording?: () => void;
  onRecorderStateChanged?: (status: {
    code: CallRecordingStatus;
    subCode?: CallRecordingStatusSubCode;
  }) => Promise<unknown>;
  onContactClick?: (c: Contact['id']) => void;
  showCollaborateDialog?: (
    ...context: [] | [CollaborateDefaultContext | undefined]
  ) => DeferredPromise<boolean, CollaborateDefaultContext | undefined>;
  showBotStatus?: boolean;
}

export const CallRecordingPlayer = ({
  organizationSlug,
  meetingPlanId,
  sparseMeetingplan,
  fullscreen,
  externalView,
  onContactClick,
  scheduleCallRecording,
  onRecorderStateChanged,
  showCollaborateDialog,
  showBotStatus,
}: CallRecordingPlayerProps) => {
  const { hasEntitlement } = useOrganization();
  const { getAccessTokenSilently } = useAuth0();
  const { isDark } = useLightOrDarkMode();

  const [recorderStatus, setRecorderStatus] =
    useState<CallRecordingStatus>('unknown');

  const appInsights = useAppInsightsContext();

  const [searchParams] = useSearchParams();

  const initialTimestamp =
    searchParams.has('recordingTimestamp') &&
    searchParams.get('recordingTimestamp') &&
    !isNaN(parseFloat(searchParams.get('recordingTimestamp')!))
      ? parseFloat(searchParams.get('recordingTimestamp')!)
      : undefined;

  const [
    isFullscreen,
    {
      setTrue: setFullscreen,
      setFalse: setNonFullscreen,
      toggle: toggleFullscreen,
    },
  ] = useBoolean(fullscreen || !!initialTimestamp);

  const [
    isMinimized,
    {
      setTrue: setMinimized,
      setFalse: setNonMinimized,
      toggle: toggleMinimized,
    },
  ] = useBoolean(false);

  const [videoPlayerControls, setVideoPlayerControls] = useState<
    VideoPlayerControls | undefined
  >();

  const [videoPlayerState, setVideoPlayerState] = useState<
    VideoPlayerState | undefined
  >();

  const [videoPlayerHeight, setVideoPlayerHeight] = useState<number>(0);

  const [activeTopic, setActiveTopic] = useState<Topic | undefined>(undefined);

  const [activePivotKey, setActivePivotKey] = useState<string>('CallInsights');

  const topicLayerHostId = useId('topicLayerHost');

  const {
    data: meetingPlanData,
    // isLoading: meetingPlanIsLoading,
    isFetched: meetingPlanFetched,
    refetch: refetchMeetingPlan,
  } = useQuery(
    MeetingPlanQuery(organizationSlug, meetingPlanId),
    async () => {
      const token = await getAccessTokenSilently();
      return MeetingflowsApiClient.getMeetingflow(
        organizationSlug,
        meetingPlanId,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      enabled: !sparseMeetingplan && !!organizationSlug && !!meetingPlanId,
    },
  );

  const meetingPlan = sparseMeetingplan || meetingPlanData?.data;

  const fiveMinutesBeforeStart = meetingPlan
    ? DateTime.fromISO(meetingPlan.startTime).diffNow('minutes').minutes > 5
    : true;
  const beforeStart = meetingPlan
    ? DateTime.fromISO(meetingPlan.startTime).diffNow().milliseconds > 0
    : true;
  const beforeEnd = meetingPlan
    ? DateTime.fromISO(meetingPlan.endTime).diffNow().milliseconds > 0
    : true;
  const afterEnd = meetingPlan
    ? DateTime.fromISO(meetingPlan.endTime).diffNow().milliseconds < 0
    : false;
  const afterEnd24Hours = meetingPlan
    ? DateTime.fromISO(meetingPlan.endTime).diffNow().milliseconds < -86400000
    : false;

  const {
    data: callRecorderStatus,
    isLoading: callRecorderStatusLoading,
    refetch: refetchCallRecorderStatus,
  } = useQuery(
    MeetingPlanCallRecorderStatusQuery(organizationSlug, meetingPlanId),
    async () => {
      const token = await getAccessTokenSilently();
      return MeetingflowsApiClient.getCallRecordingStatus(
        organizationSlug,
        meetingPlanId,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      staleTime:
        !!meetingPlan?.callRecording &&
        !meetingPlan.callRecording.recordingFileName &&
        !fiveMinutesBeforeStart &&
        beforeEnd
          ? Duration.fromObject({ seconds: 5 }).as('milliseconds')
          : undefined,
      refetchOnWindowFocus:
        !!organizationSlug &&
        !!meetingPlanId &&
        hasEntitlement('CALL_RECORDING') &&
        !!meetingPlan?.callRecording?.id &&
        !meetingPlan.callRecording.recordingFileName,
      refetchOnMount:
        !!organizationSlug &&
        !!meetingPlanId &&
        hasEntitlement('CALL_RECORDING') &&
        !!meetingPlan?.callRecording?.id &&
        !meetingPlan.callRecording.recordingFileName,
      refetchOnReconnect:
        !!organizationSlug &&
        !!meetingPlanId &&
        hasEntitlement('CALL_RECORDING') &&
        !!meetingPlan?.callRecording?.id &&
        !meetingPlan.callRecording.recordingFileName,
      refetchInterval: (response): number | false => {
        if (
          response?.status === 400 ||
          !meetingPlan?.callRecording ||
          !!meetingPlan.callRecording.recordingFileName ||
          ['done', 'deleted', 'fatal', 'analysis_done'].includes(
            response?.data?.code || 'unknown',
          )
        ) {
          return false;
        }

        if (
          meetingPlan?.startTime &&
          DateTime.fromISO(meetingPlan.startTime).diffNow('minutes').minutes >
            30
        ) {
          return Duration.fromObject({ minutes: 5 }).as('milliseconds');
        }

        if (fiveMinutesBeforeStart) {
          return Math.min(
            Duration.fromObject({ minutes: 1 }).as('milliseconds'),
            DateTime.fromISO(meetingPlan!.startTime).diffNow('milliseconds')
              .milliseconds,
          );
        }
        if (!fiveMinutesBeforeStart && beforeEnd) {
          return response?.data?.code === 'in_call_recording'
            ? Duration.fromObject({ seconds: 15 }).as('milliseconds')
            : Duration.fromObject({ seconds: 5 }).as('milliseconds');
        }

        return false;
      },
      enabled:
        !!organizationSlug &&
        !!meetingPlanId &&
        hasEntitlement('CALL_RECORDING') &&
        !!meetingPlan?.callRecording?.id,
    },
  );

  const {
    data: callTranscript,
    isLoading: callTranscriptLoading,
    refetch: refetchCallTranscript,
  } = useQuery(
    MeetingPlanCallTranscriptQuery(organizationSlug, meetingPlanId),
    async () => {
      const token = await getAccessTokenSilently();
      return MeetingflowsApiClient.getCallTranscript(
        organizationSlug,
        meetingPlanId,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      staleTime:
        recorderStatus === 'in_call_recording'
          ? Duration.fromObject({ seconds: 3 }).as('milliseconds')
          : Duration.fromObject({ minutes: 15 }).as('milliseconds'),
      cacheTime:
        recorderStatus === 'in_call_recording'
          ? Duration.fromObject({ seconds: 1 }).as('milliseconds')
          : Duration.fromObject({ minutes: 15 }).as('milliseconds'),
      refetchOnWindowFocus: recorderStatus === 'in_call_recording',
      refetchOnMount: recorderStatus === 'in_call_recording',
      refetchOnReconnect: recorderStatus === 'in_call_recording',
      refetchInterval: () => {
        if (recorderStatus !== 'in_call_recording') {
          return false;
        }

        return Duration.fromObject({ seconds: 3 }).as('milliseconds');
      },
      enabled:
        !!organizationSlug &&
        !!meetingPlanId &&
        hasEntitlement('CALL_RECORDING') &&
        !!meetingPlan?.callRecording,
    },
  );

  const transcript: Utterance[] =
    callTranscript?.data ||
    meetingPlan?.callRecording?.transcript ||
    EMPTY_ARRAY;

  useEffect(() => {
    if (
      !!organizationSlug &&
      !!meetingPlanId &&
      !!meetingPlan?.callRecording?.id &&
      hasEntitlement('CALL_RECORDING')
    ) {
      refetchCallRecorderStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    meetingPlan?.callRecording?.id,
    meetingPlan?.callRecording?.lastStatus,
    meetingPlanId,
    organizationSlug,
  ]);

  useEffect(
    () => {
      if (
        callRecorderStatus?.data?.code &&
        (callRecorderStatus.data.code as CallRecordingStatus) !== recorderStatus
      ) {
        setRecorderStatus(callRecorderStatus.data.code);
        onRecorderStateChanged?.(callRecorderStatus.data);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [callRecorderStatus?.data?.code, onRecorderStateChanged, recorderStatus],
  );

  useEffect(() => {
    if (
      !!organizationSlug &&
      !!meetingPlanId &&
      !!meetingPlan?.callRecording &&
      hasEntitlement('CALL_RECORDING') &&
      recorderStatus === 'in_call_recording'
    ) {
      refetchCallTranscript();
    }
  }, [
    hasEntitlement,
    meetingPlan?.callRecording,
    meetingPlanId,
    organizationSlug,
    recorderStatus,
    refetchCallTranscript,
  ]);

  const onKeyPress = useCallback(
    (e: KeyboardEvent) => {
      // if fullscreen, close fullscreen when escape is pressed
      if (e.key === 'Escape' && isFullscreen) {
        setNonFullscreen();
      }
    },
    [setNonFullscreen, isFullscreen],
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyPress, false);

    return () => {
      document.removeEventListener('keydown', onKeyPress, false);
    };
  }, [onKeyPress]);

  // log to console when videoPlayerHeight changes
  useEffect(() => {
    console.info('videoPlayerHeight', videoPlayerHeight);
  }, [videoPlayerHeight]);

  const speakerNames = useMemo(
    () =>
      DeduplicateArray(
        transcript
          .map((u) => u.speaker)
          .filter(Truthy)
          .sort(),
      ),
    [transcript],
  );

  const speakerContactMatches = useMemo(
    () =>
      tryMatchAttendeesToParticipants(
        speakerNames,
        meetingPlan?.attendees || null,
      ),
    [meetingPlan?.attendees, speakerNames],
  );

  const getContactForParticipant = useCallback(
    (participant: string) => {
      return speakerContactMatches[participant];
    },
    [speakerContactMatches],
  );

  const getColorForSpeaker = useCallback(
    (speaker: string) => {
      const speakerIdx = speakerNames.indexOf(speaker);
      return speakerColors[speakerIdx % speakerColors.length];
    },
    [speakerNames],
  );

  const onParticipantClick = useCallback(
    (p: string) => {
      const contact = getContactForParticipant(p);
      if (contact && isFullscreen) {
        window.open(
          `/organization/${organizationSlug}/library/contact/${contact.id}`,
          '_blank',
        );
      } else if (contact && onContactClick) {
        onContactClick(contact.id);
      }
    },
    [getContactForParticipant, isFullscreen, onContactClick, organizationSlug],
  );

  // If there is no video but there is a transcript, we're in realtime mode
  const isRealtime =
    !meetingPlan?.callRecording?.recordingFileName && !!transcript.length;

  const botStatusMessage = (() => {
    if (sparseMeetingplan || !meetingPlan || !showBotStatus) {
      return null;
    }

    // @ts-ignore because if we don't have the full meetingplan object we will have already returned null here
    if (!meetingPlan.conferenceInfo?.joinUri && !meetingPlan.callRecording) {
      return (
        <div className="status disabled no-call">
          This Meetingflow isn't associated with a conference call which can be
          recorded.
        </div>
      );
    }

    if (afterEnd && !meetingPlan.callRecording) {
      return (
        <div className="status disabled call-ended-no-recording">
          This Meetingflow's call has ended; it can no longer be recorded.
        </div>
      );
    }

    if (
      (!meetingPlan.callRecording ||
        meetingPlan.callRecording.lastStatus === 'deleted') &&
      beforeStart &&
      beforeEnd
    ) {
      return null;
    }

    if (!meetingPlan.callRecording && !beforeStart && beforeEnd) {
      return (
        <div className="status call-in-progress-not-recording">
          This Meetingflow's call is in progress, and is not being recorded.
        </div>
      );
    } else if (meetingPlan.callRecording && !!callRecorderStatus?.data) {
      switch (callRecorderStatus.data.code) {
        case 'scheduled': {
          return (
            <div className="status scheduled">
              This Meetingflow's call is scheduled to be recorded.
            </div>
          );
        }
        case 'ready': {
          return (
            <div className="status in-call">
              <FontIcon iconName="UnknownCall" />
              Meetingflow Note Taker is ready to join the call.
            </div>
          );
        }
        case 'joining_call': {
          return (
            <div className="status in-call">
              <FontIcon iconName="UnknownCall" />
              Meetingflow Note Taker is joining the call.
            </div>
          );
        }
        case 'in_waiting_room': {
          return (
            <div className="status in-call">
              <FontIcon iconName="BufferTimeBoth" />
              Meetingflow Note Taker is in the waiting room of the call.
            </div>
          );
        }
        case 'in_call_not_recording': {
          return (
            <div className="status in-call">
              <FontIcon iconName="VideoOff" />
              Meetingflow Note Taker is in the call but is not yet recording.
            </div>
          );
        }
        case 'in_call_recording': {
          return (
            <div className="status in-call recording">
              <FontIcon iconName="VideoSolid" />
              Meetingflow Note Taker is in the call and recording.
            </div>
          );
        }
        case 'call_ended': {
          return (
            <div className="status in-call in-call-ended">
              <FontIcon iconName="CompletedSolid" />
              The call has ended and the recording will be available soon.
            </div>
          );
        }
        case 'done':
        case 'analysis_done': {
          // 'done' is the final Recall state, but Meetingflow will also perform its analayis after this.
          if (!meetingPlan?.callRecording?.analysisCompletedAt) {
            return (
              <div className="status complete done">
                <FontIcon iconName="CompletedSolid" />
                Meetingflow Note Taker finished recording the meeting.
                Meetingflow is analyzing the call transcript. Call Insights will
                be available soon.
              </div>
            );
          } else if (
            meetingPlan?.callRecording?.analysisCompletedAt &&
            DateTime.fromISO(
              meetingPlan?.callRecording?.analysisCompletedAt,
            ).diffNow().minutes < 10
          ) {
            return (
              <div className="status complete analysis_done">
                <FontIcon iconName="CompletedSolid" />
                Meetingflow Note Taker has finished analyzing the meeting
                recording. Call Insights are now available.
              </div>
            );
          } else return null;
        }
        case 'media_expired': {
          return (
            <div className="status complete analysis_done">
              <FontIcon iconName="CompletedSolid" />
              Meetingflow Note Taker recorded this call.
              <a
                style={{ marginLeft: '.25rem' }}
                className={'in-call'}
                onClick={() => setNonMinimized()}
              >
                View Video and AI Notes
              </a>
            </div>
          );
        }
        case 'fatal': {
          return (
            <div className="status error">
              <FontIcon iconName="CompletedSolid" />
              Meetingflow Note Taker encountered an error recording the meeting.
            </div>
          );
        }
        case 'deleted': {
          return (
            <div className="status error">
              <FontIcon iconName="Delete" />A Call Recording for this meeting
              was deleted.
            </div>
          );
        }
      }
    }

    return null;
  })();

  const callStats = useMemo(
    () =>
      transcript.length
        ? getTranscriptStats(transcriptify(transcript))
        : undefined,
    [transcript],
  );

  const turns = useMemo(() => transcriptify(transcript, false), [transcript]);

  const topicLayerHostClass = mergeStyles({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 20,
  });

  const callRecordingContainerClass = mergeStyles({
    display: externalView ? 'flex' : undefined,
    flexDirection: externalView ? 'column' : undefined,
    cursor: isMinimized ? 'pointer' : undefined,
    width: externalView ? '100%' : isMinimized ? 'auto' : '100%',
    maxWidth: externalView ? '1024px' : undefined,
    marginTop: undefined,
    height: externalView
      ? 'calc(100%)'
      : isMinimized
        ? '32px'
        : isFullscreen
          ? '100%'
          : 'auto',
    padding: externalView
      ? '0'
      : isMinimized
        ? 0
        : isFullscreen
          ? '.25rem'
          : '0 0 0 .25rem',
    marginBottom: isFullscreen || externalView ? '0' : '.5rem',
    borderRadius: '.25rem',
    borderWidth: '0',
    borderStyle: 'solid',
    containerType: 'inline-size',
    boxShadow: isFullscreen ? '0 5px 10px rgba(0,0,0,.25)' : undefined,
    boxSizing: 'border-box',
    borderColor: 'transparent',
    backgroundColor: isFullscreen
      ? isDark
        ? NeutralColors.gray220
        : MEETINGFLOW_COLORS.purpleUltraSuperLight
      : isDark
        ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
        : MEETINGFLOW_COLORS.purpleUltraSuperLight,

    '.full-screen-control': {
      display: isMinimized || externalView ? 'none' : 'block',
      position: 'absolute',
      top: '.5rem',
      right: isFullscreen || externalView ? '.5rem' : '2.5rem',
      zIndex: 50,
      fontSize: '18px',
      color: isDark ? NeutralColors.gray120 : NeutralColors.gray60,
      cursor: 'pointer',
      transition: '.3s ease-in-out all',
    },

    '.full-screen-control:hover': {
      color: isDark
        ? MEETINGFLOW_COLORS.purpleMedium
        : MEETINGFLOW_COLORS.purpleDarker,
    },

    '.minimize-control': {
      display: isFullscreen || externalView ? 'none' : 'block',
      position: 'absolute',
      top: '.7rem',
      right: '1rem',
      zIndex: 50,
      fontSize: '11px',
      color: isDark ? NeutralColors.gray120 : NeutralColors.gray60,
      cursor: 'pointer',
      transition: '.3s ease-in-out all',
    },

    '.minimize-control.open': {
      transform: 'rotate(90deg)',
    },

    '.minimize-control:hover': {
      color: isDark
        ? MEETINGFLOW_COLORS.purpleMedium
        : MEETINGFLOW_COLORS.purpleDarker,
    },

    '.section-header': {
      display: 'block',
      fontSize: FontSizes.small,
      color: MEETINGFLOW_COLORS.teal,
      fontWeight: FontWeights.semibold,
      margin: '1rem 0 .5rem 0',

      ':first-child': {
        marginTop: '0',
      },
    },

    '.transcript-timeline': {
      borderRadius: '.25rem',
      margin: isFullscreen || externalView ? '0' : '.25rem 0 .25rem 0',
      paddingTop: isMinimized ? 0 : '.25rem',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      height: isMinimized ? 0 : `${speakerNames.length / 2 + 0.5}rem`,
      backgroundColor: isDark
        ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
        : MEETINGFLOW_COLORS.purpleGreyLight,
      position: externalView || isFullscreen ? 'absolute' : 'relative',
      bottom: externalView ? '0' : isFullscreen ? '-1px' : undefined,
      left: isFullscreen ? '.2rem' : undefined,
      lineHeight: '.5rem',
      overflow: 'hidden',

      '.ms-TooltipHost, .speaker-turn, .speaker-turn-wrapper': {
        position: 'relative',
        height: '.5rem',
        lineHeight: '.5rem',
        borderRadius: '.125rem',
        cursor: 'pointer',

        '.speaker-name': {
          position: 'absolute',
          top: '0',
          left: '0',
          display: 'none',

          ':first-child': { display: 'block' },
        },
      },

      dl: {
        margin: 0,
        minWidth: 'calc(50% - 1.25rem)',
      },

      '@container (width >= 30rem)': {
        width: `calc(100% - .5rem) !important`,
      },
    },
  });

  const timelineSpeakersHeight = `${speakerNames.length * 0.35 + 1}rem`;
  const videoHeight = videoPlayerHeight ? `${videoPlayerHeight}px` : '300px';

  const callRecordingPlayerClass = mergeStyles({
    backgroundColor: externalView ? 'black' : undefined,
    height:
      externalView || isFullscreen
        ? `calc(100% - ${timelineSpeakersHeight} + 1.25rem)`
        : undefined,
    maxHeight: externalView
      ? '100%'
      : isFullscreen
        ? `calc(100% - ${timelineSpeakersHeight} + 1.25rem)`
        : videoHeight
          ? `calc(${videoHeight}) + ${timelineSpeakersHeight} + 2rem)`
          : `calc(14rem + ${timelineSpeakersHeight} + 2rem)`,
    overflow: 'hidden',
    display:
      transcript.length || meetingPlan?.callRecording?.recordingFileName
        ? 'flex'
        : 'none',
    flexDirection: externalView ? 'column' : undefined,

    '@container (width < 35rem)': {
      flexDirection: 'column',
      height: externalView ? '100%' : undefined,
      maxHeight: externalView ? undefined : '40rem',

      '.minimize-control.close': {
        transform: 'rotate(180deg)',
      },

      '.transcript-container': {
        maxHeight: externalView
          ? undefined
          : `calc(18rem + ${timelineSpeakersHeight} + 2rem)`,
      },

      '.transcript': {
        height: `calc(18rem + ${timelineSpeakersHeight} + 2rem)`,
        position: 'relative',
      },

      '.video-container': {
        paddingRight: '0 !important',
        borderTopLeftRadius: isFullscreen ? '0' : '.25rem',
        borderTopRightRadius: isFullscreen ? '0' : '.25rem',
        borderBottomRightRadius: '0 !important',
        borderBottomLeftRadius: '0 !important',
        maxWidth: 'none !important',
      },
    },

    '.transcript-container': {
      position: 'relative',
      flex: `1`,
      boxSizing: 'border-box',
      height: externalView
        ? '100%'
        : isMinimized
          ? '0 !important'
          : isFullscreen
            ? `100%`
            : `${videoHeight}`,
      transition: '.3s ease-inout all',
      padding: '0',
      minHeight: externalView
        ? `${videoPlayerHeight}`
        : isMinimized
          ? '28px !important'
          : isFullscreen
            ? undefined
            : '100%',

      '.transcript-full, .transcript-summary, .transcript-details, .transcript-analysis':
        {
          fontSize: FontSizes.small,
          padding: '.5rem',
          overflowY: 'auto',
          height: externalView ? 'auto' : undefined,
          maxHeight: externalView
            ? `calc(50% + ${timelineSpeakersHeight})`
            : isFullscreen
              ? 'calc(100% - 1.5rem)'
              : videoHeight
                ? `calc(${videoHeight} - 1.5rem)`
                : '300px',
          boxSizing: 'border-box',
          paddingBottom: externalView
            ? `calc(${timelineSpeakersHeight} + 4rem)`
            : isFullscreen
              ? `calc(${timelineSpeakersHeight} + 1rem)`
              : undefined,

          'h1, h2, h3, h4, h5, h6, p, ol, ul, li, blockquote': {
            margin: '0 0 .25rem 0',
            color: isDark ? NeutralColors.gray60 : NeutralColors.gray130,
          },

          'ul, ol, li': {
            marginLeft: '1rem',
            padding: '0',
          },

          'h1, h2, h3, h4, h5, h6': {
            fontWeight: FontWeights.semibold,
            marginTop: '.5rem',
            marginBottom: '.5rem',
            color: isDark ? NeutralColors.gray30 : NeutralColors.gray120,
          },

          h2: {
            color: MEETINGFLOW_COLORS.teal,
            fontSize: FontSizes.size14,
            marginTop: '1rem',
          },

          'h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child':
            {
              marginTop: '0',
            },

          h3: {
            fontSize: '12px',
          },

          h4: {
            fontSize: '10px',
            textTransform: 'uppercase',
            fontWeight: FontWeights.bold,
          },

          h5: {
            fontSize: FontSizes.size10,
            textTransform: 'uppercase',
            fontWeight: FontWeights.semibold,
          },

          h6: {
            fontSize: FontSizes.size10,
          },

          dl: {
            position: 'relative',
            boxSizing: 'border-box',
            margin: '1rem 0 .5rem 0',
            padding: '.5rem',
            lineHeight: '1rem',
            marginLeft: '1rem',
            marginRight: '1rem',
            backgroundColor: isDark
              ? NeutralColors.gray210
              : MEETINGFLOW_COLORS.white,
            cursor: 'pointer',
            borderRadius: '.25rem',
            transition: '.3s ease-in-out all',
            ':hover': {
              backgroundColor: isDark
                ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
                : MEETINGFLOW_COLORS.purpleGrey,
            },
            dt: {
              fontWeight: FontWeights.semibold,
              margin: '0 0 .25rem 0',
              color: MEETINGFLOW_COLORS.teal,
              '.timestamp': {
                fontWeight: FontWeights.bold,
                fontSize: FontSizes.mini,
                color: MEETINGFLOW_COLORS.orange,
              },
            },
            dd: {
              fontWeight: FontWeights.regular,
              fontFamily: 'Georgia, serif',
              fontStyle: 'italic',
              fontSize: FontSizes.medium,
              margin: '0',
              color: isDark ? NeutralColors.gray70 : NeutralColors.gray120,

              '.timestamp': {
                fontFamily:
                  '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;',
                fontWeight: FontWeights.bold,
                fontSize: FontSizes.mini,
                color: MEETINGFLOW_COLORS.orange,
                fontStyle: 'normal',
              },
            },
          },
        },

      '.transcript-full': {
        paddingTop: 0,
        paddingRight: '.5rem',
      },

      '.transcript-summary': {},

      '.transcript-details': {
        containerType: 'inline-size',
      },
      '.transcript-analysis': {
        position: 'relative',
        overflowY: activeTopic ? 'none' : undefined,
        height: activeTopic ? '100%' : undefined,
        minHeight: !isFullscreen ? '100%' : undefined,
        padding: '.5rem .25rem .5rem .5rem',
        paddingBottom: isFullscreen
          ? `${speakerNames.length / 2 + 0.5}rem`
          : undefined,

        maxHeight: activeTopic
          ? videoHeight
          : isFullscreen
            ? 'calc(100% - 1.5rem)'
            : videoHeight
              ? `calc(${videoHeight} - 1.25rem)`
              : '300px',

        '.mask': {
          display: 'block',
          opacity: activeTopic ? `.95 !important` : `0 !important`,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          minWidth: '100%',
          maxWidth: '100%',
          height: '100%',
          minHeight: '100%',
          overflow: 'hidden',
          backgroundColor: isDark
            ? NeutralColors.gray200
            : MEETINGFLOW_COLORS.purpleGreyLight,
          zIndex: 10,
        },

        '.active-topic': {
          display: activeTopic ? 'block' : 'none',
          height: 'auto',
          width: 'calc(100% - 2rem)',
          maxWidth: 'calc(100% - 2rem)',
          maxHeight: 'calc(100% - 2rem)',
          overflowY: 'auto',
          zIndex: 20,
          justifyContent: 'center',
          borderRadius: '.25rem',

          'dl.topic': {
            display: 'block',
            backgroundColor: isDark
              ? MEETINGFLOW_COLORS.purpleDarkest
              : MEETINGFLOW_COLORS.purpleLighter,
            color: isDark
              ? MEETINGFLOW_COLORS.purpleGreyLight
              : MEETINGFLOW_COLORS.purpleDarker,
            margin: 0,
            minHeight: '6rem',
            opacity: `1 !important`,
            padding: '.5rem',
            width: '100%',
            containerType: 'inline-size',
            '*': {
              opacity: `1 !important`,
            },

            '> dt': {
              fontSize: FontSizes.mediumPlus,
              color: isDark
                ? MEETINGFLOW_COLORS.purpleGreyLight
                : MEETINGFLOW_COLORS.purpleDarkest,
            },

            '> dd': {
              color: isDark
                ? MEETINGFLOW_COLORS.purpleLight
                : MEETINGFLOW_COLORS.purpleDarkest,
              paddingRight: '0 !important',
            },
          },
        },

        '.sub-topics': {
          display: 'flex',
          flexDirection: 'row',
          columnGap: isFullscreen ? '.5rem' : '.25rem',
          rowGap: isFullscreen ? '.5rem' : '.25rem',
          containerType: 'inline-size',

          // flex direction column if the container is great than 30rem
          '@container (width <= 25rem)': {
            flexDirection: 'column',
          },

          '.sub-topic': {
            flexBasis: '50%',

            dl: {
              display: 'flex',
              flexDirection: 'column',
              margin: `0 0 .25rem 0 !important`,
              rowGap: '.25rem',
              backgroundColor: isDark
                ? MEETINGFLOW_COLORS.purpleDarker
                : MEETINGFLOW_COLORS.purpleGreyLight,

              '> dt': {
                fontSize: FontSizes.small,
                marginBottom: '0',
                lineHeight: '1rem',
                color: isDark ? MEETINGFLOW_COLORS.tealLight : undefined,
                paddingRight: '1rem',
              },

              '> dd': {
                fontSize: FontSizes.small,
                color: isDark ? MEETINGFLOW_COLORS.purpleGrey : undefined,
                textAlign: 'right',
              },

              ':hover': {
                backgroundColor: isDark
                  ? MEETINGFLOW_COLORS.purpleDark
                  : MEETINGFLOW_COLORS.white,
              },
            },
          },
        },

        '.participants-container': {
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          columnGap: '.25rem',
          rowGap: '.25rem',
          marginBottom: '1rem',

          '.title': {
            backgroundColor: 'transparent',
            cursor: 'default',
            color: MEETINGFLOW_COLORS.teal,
            fontWeight: FontWeights.semibold,
            transition: '.3s ease-in-out all',
            fontSize: FontSizes.medium,
            width: '5rem',
            padding: `0.25rem 0.5rem !important`,
            ':after': {
              content: '":"',
            },
          },
        },

        '.list-container': {
          dl: {
            width: '100% !important',
            minWidth: '100% !important',
            maxWidth: '100% !important',
            flexBasis: '100% !important',

            dt: {
              '.timestamp': {
                float: 'right',
                marginRight: '1rem',
              },
            },
          },

          'dl.collapsed': {
            '.details-link': {
              color: isDark
                ? MEETINGFLOW_COLORS.teal
                : MEETINGFLOW_COLORS.purpleSecondary,
            },

            // 'dl.inline': {
            //   maxHeight: '0',
            //   margin: '0 !important',
            // },
          },

          'dl.topic': {
            display: 'block',
            backgroundColor: isDark
              ? MEETINGFLOW_COLORS.purpleDarkest
              : MEETINGFLOW_COLORS.purpleLighter,
            color: isDark
              ? MEETINGFLOW_COLORS.purpleGreyLight
              : MEETINGFLOW_COLORS.purpleDarkest,
            margin: 0,
            minHeight: '6rem',
            opacity: `1 !important`,
            padding: '.5rem',
            width: '100%',
            containerType: 'inline-size',
            '*': {
              opacity: `1 !important`,
            },

            '> dt': {
              fontSize: FontSizes.mediumPlus,
              color: isDark
                ? MEETINGFLOW_COLORS.purpleGreyLight
                : MEETINGFLOW_COLORS.purpleDarkest,
            },

            '> dd': {
              color: isDark
                ? MEETINGFLOW_COLORS.purpleLight
                : MEETINGFLOW_COLORS.purpleDarkest,
              paddingRight: '0 !important',
            },
          },

          'dl.expandable': {
            cursor: isFullscreen ? 'default' : 'pointer',
            backgroundColor: isFullscreen
              ? `${MEETINGFLOW_COLORS.purpleGreyLight} !important`
              : undefined,
            dt: {
              paddingLeft: '1rem',
              '.timestamp': {
                marginRight: '2rem',
              },
            },
            '::after': {
              content: '""',
              fontFamily: `FabricMDL2Icons`,
              position: 'absolute',
              top: '.5rem',
              left: '.25rem',
              width: '1rem',
              height: '1rem',
              fontSize: '10px',
              lineHeight: '.5rem',
            },
          },

          'dl.expandable.collapsed': {
            '::after': {
              content: '""',
            },
          },
        },

        '.list-container.topics': {
          ul: {
            listStyle: 'none',
            margin: 0,
            padding: 0,
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            columnGap: '.25rem',
            rowGap: '.25rem',

            li: {
              margin: '0',
              height: 'auto',
              minHeight: undefined,
              cursor: 'pointer',
              padding: `.25rem .5rem !important`,
              backgroundColor: isDark
                ? NeutralColors.gray170
                : MEETINGFLOW_COLORS.purpleGrey,
              color: isDark
                ? MEETINGFLOW_COLORS.purpleGreyLight
                : MEETINGFLOW_COLORS.purpleDarker,
              borderRadius: '.25rem',
              fontWeight: FontWeights.semibold,
              transition: '.3s ease-in-out all',
              fontSize: FontSizes.small,

              ':hover': {
                backgroundColor: isDark
                  ? MEETINGFLOW_COLORS.purpleDark
                  : MEETINGFLOW_COLORS.purpleMedium,
                color: `${MEETINGFLOW_COLORS.white} !important`,
              },
            },
          },

          'dl.topic': {
            width: '100% !important',
            minWidth: '100% !important',
            maxWidth: '100% !important',
            flexBasis: '100% !important',
            marginBottom: '0 !important',
          },

          '@container (width >= 30rem)': {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            columnGap: '.25rem',
            rowGap: isFullscreen ? '.5rem' : '.25rem',

            'dl.topic.collapsed': {
              width: 'calc(50% - .5rem) !important',
              minWidth: 'calc(50% - .5rem) !important',
              maxWidth: 'calc(50% - .5rem) !important',
              flexBasis: 'calc(50% - .5rem) !important',
              padding: '.25rem !important',

              dt: {
                maxWidth: 'calc(100% - 1rem)',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              },
            },

            'dl.topic.expanded': {
              width: 'calc(100%) !important',
              minWidth: 'calc(100%) !important',
              maxWidth: 'calc(100%) !important',
              flexBasis: 'calc(100%) !important',
            },
          },
        },
      },

      '.transcript-analysis .list-container, .transcript-details .list-container':
        {
          containerType: 'inline-size',

          dl: {
            margin: '0 0 .5rem 0 !important',
            minWidth: 'calc(50% - .75rem)',
          },

          '@container (width >= 30rem)': {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            columnGap: '.5rem',
            rowGap: '.5rem',

            '> dl': {
              flexBasis: 'calc(100%)',
              maxWidth: 'calc(100%)',
              minWidth: 'calc(100%)',
            },

            '> dl.call-participant': {
              flexBasis: 'calc(50% - .25rem)',
              maxWidth: 'calc(50% - .25rem)',
              minWidth: 'calc(50% - .25rem)',
              margin: '0 !important',
            },

            '> dl.call-participant:only-child': {
              flexBasis: '100%',
              maxWidth: '100%',
            },
          },

          '@container (width < 30rem)': {
            display: 'flex',
            flexDirection: 'column',
            rowGap: '.5rem',

            '> dl.call-participant': {
              flexBasis: 'calc(100% - .25rem)',
              maxWidth: 'calc(100% - .25rem)',
              minWidth: 'calc(100% - .25rem)',
              margin: '0 !important',
            },
          },

          '@container (width >= 15rem)': {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            columnGap: '.5rem',
            rowGap: '.5rem',

            '> dl': {
              flexBasis: 'calc(100% - .25rem)',
              maxWidth: 'calc(100% - .25rem)',
              minWidth: 'calc(100% - .25rem)',
            },

            '> dl.call-participant': {
              flexBasis: 'calc(50% - .25rem)',
              maxWidth: 'calc(50% - .25rem)',
              minWidth: 'calc(50% - .25rem)',
              margin: '0 !important',
            },

            '> dl.call-participant:only-child': {
              flexBasis: '100%',
              maxWidth: '100%',
            },
          },

          '@container (width < 25rem)': {
            display: 'flex',
            flexDirection: 'column',
            rowGap: '.5rem',

            '> dl.call-participant': {
              flexBasis: 'calc(100%)',
              maxWidth: 'calc(100%)',
              minWidth: 'calc(100%)',
            },
          },

          '.ms-Button-menuIcon': {
            color: isDark ? NeutralColors.gray140 : NeutralColors.gray90,
          },
        },
    },

    '.video-container': {
      flex: `1`,
      backgroundColor: 'black',
      margin: externalView ? '0 auto' : undefined,
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      paddingRight: '.25rem',
      borderTopLeftRadius: isFullscreen ? '0' : '.25rem',
      borderTopRightRadius: externalView ? '.25rem' : '0',
      borderBottomRightRadius: '0',
      borderBottomLeftRadius: isFullscreen ? '0' : '.25rem',
      containerType: 'inline-size',
      transition: '.3s ease-in-out all',
      overflow: 'hidden',
      minHeight: externalView ? videoHeight : undefined,
      maxHeight: externalView ? videoHeight : undefined,
      maxWidth: externalView ? '500px' : undefined,
      justifyContent: 'center',
      width: externalView ? '100%' : isMinimized ? '0' : 'auto',
      height: externalView
        ? videoHeight
        : isMinimized
          ? 0
          : isFullscreen
            ? `100%`
            : undefined,

      video: {
        '-webkit-transform': 'translateZ(0)',
        borderTopLeftRadius: isFullscreen ? '0' : '.25rem',
        borderTopRightRadius: '0',
        borderBottomRightRadius: '0',
        borderBottomLeftRadius: isFullscreen ? '0' : '.25rem',
        boxShadow: isDark
          ? '1px 1px 2px rgba(0, 0, 0, .5)'
          : '1px 1px 2px rgba(0, 0, 0, 0.025)',
        marginLeft: '.25rem',
        height: externalView ? videoHeight : undefined,
        maxHeight: externalView ? videoHeight : undefined,

        '@container (width < 30rem)': {
          borderTopLeftRadius: isFullscreen ? '0' : '.25rem',
          borderTopRightRadius: isFullscreen ? '0' : '.25rem',
          borderBottomRightRadius: '0',
          borderBottomLeftRadius: '0',
        },
      },

      '.video-react': {
        backgroundColor: 'transparent !important',

        '.video-react-control-bar': {
          borderBottomLeftRadius: isFullscreen ? '0' : '.25rem',
          left: '.25rem',
          display: 'flex !important',
        },
      },

      '.extra-controls': {
        display: 'inline-block',

        '@container (width < 21rem)': {
          display: 'none',
        },
      },

      '.extra-extra-controls': {
        display: 'inline-block',

        '@container (width < 17rem)': {
          display: 'none',
        },
      },

      '.video-react-button': {
        width: '30px',
      },

      '.video-react-big-play-button': {
        width: '3em',
      },
    },
  });

  const pivotStyles = useMemo(() => {
    return {
      root: {
        backgroundColor: externalView
          ? isDark
            ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
            : 'white'
          : 'transparent',
        flexDirection: 'row-reverse',
        transition: '.3s ease-in-out all',
        display: 'flex',
        height: '32px',
        paddingRight: externalView ? '0' : '4rem',
        margin: externalView ? 0 : !isMinimized ? '0 0 0 1rem' : '0',

        '.ms-Pivot-link::before': {
          content: '>',
        },
      },
      link: {
        position: 'relative',
        top: isMinimized ? undefined : '0',
        marginTop: isFullscreen ? 0 : '.25rem',
        marginRight: '.25rem',
        fontSize: FontSizes.small,
        height: isMinimized ? '1.5rem' : isFullscreen ? '2rem' : '1.75rem',
        borderTopLeftRadius: '3px',
        borderTopRightRadius: '3px',
        borderBottomLeftRadius: !isMinimized ? '0' : '3px',
        borderBottomRightRadius: !isMinimized ? '0' : '3px',
        border: `1px solid transparent`,
        color: NeutralColors.gray120,
        backgroundColor: 'transparent',
        transition: '.3s ease-in-out all',
        selectors: {
          ':hover': {
            backgroundColor: isDark
              ? NeutralColors.gray150
              : MEETINGFLOW_COLORS.purpleGrey,
          },
          ':active': {
            backgroundColor: isDark
              ? NeutralColors.gray150
              : MEETINGFLOW_COLORS.purpleGreyLight,
          },
        },
      },
      linkContent: {
        height: '32px',
      },
      text: {
        lineHeight: '28px',
        transition: '.3s ease-in-out all',
        fontWeight: FontWeights.semibold,
        color: isDark ? NeutralColors.gray80 : NeutralColors.gray140,
        // '::before': {
        //   transition: '.3s ease-in-out all',
        //   display: 'inline-flex',
        //   content: "'▶'",
        //   border: 'none',
        //   position: 'relative',
        //   top: 0,
        //   left: 0,
        //   right: 'unset',
        //   height: 'auto',
        //   backgroundColor: 'transparent',
        //   color: isDark
        //     ? NeutralColors.gray80
        //     : MEETINGFLOW_COLORS.purpleMedium,
        // },
      },
      linkIsSelected: {
        backgroundColor: !isMinimized
          ? isDark
            ? NeutralColors.gray150
            : MEETINGFLOW_COLORS.purpleGrey
          : undefined,

        color: isDark ? 'white' : NeutralColors.gray140,
        fontWeight: FontWeights.semibold,
        '::before': {
          display: 'none',
        },
        '.ms-Pivot-text': {
          '::before': {
            // content: topSectionVisible ? "'▼' !important" : undefined,
            '-webkit-transform': !isMinimized ? 'rotate(90deg)' : undefined,
            transform: !isMinimized ? 'rotate(90deg)' : undefined,
          },
        },
      },
      itemContainer: {
        backgroundColor: externalView
          ? isDark
            ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
            : 'white'
          : undefined,
        height: !isMinimized ? '100%' : '0',
        opacity: !isMinimized ? '1' : '0',
        maxHeight: '100%',
        '> div:last-child': {
          height: '100%',
          maxHeight: '100%',
        },
        borderTop: `1px solid ${
          isDark ? NeutralColors.gray150 : MEETINGFLOW_COLORS.purpleGrey
        }`,
      },
    } as Partial<IPivotStyles>;
  }, [isDark, isMinimized, isFullscreen, externalView]);

  const callRecorderStatusClass = mergeStyles({
    'div.status': {
      lineHeight: '1rem',
      fontSize: FontSizes.small,
      position: isMinimized ? 'absolute' : 'relative',
      top: isMinimized ? '0' : undefined,
      backgroundColor: 'transparent',
      color: isDark ? undefined : MEETINGFLOW_COLORS.black,

      i: {
        marginRight: '.25rem',
        position: 'relative',
        top: '1px',
        color: isDark ? undefined : MEETINGFLOW_COLORS.white,
      },
    },

    div: {
      margin: '.25rem',
      padding: '.25rem .5rem',
      backgroundColor: isDark
        ? MEETINGFLOW_COLORS.purpleSecondary
        : MEETINGFLOW_COLORS.purpleUltraLight,
      borderRadius: '.25rem',
      color: isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.black,

      'span.record-link': {
        display: 'inline-block',
        marginLeft: '.25rem',
        color: isDark
          ? MEETINGFLOW_COLORS.white
          : MEETINGFLOW_COLORS.purpleSecondary,
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },

    'div.status.complete': {
      display: afterEnd24Hours || isMinimized ? 'none' : undefined,
      height: isMinimized ? '20px' : undefined,
      lineHeight: isMinimized ? '20px' : undefined,
      backgroundColor: `${
        isDark
          ? MEETINGFLOW_COLORS.tealDarker
          : MEETINGFLOW_COLORS.tealVeryLight
      } !important`,
      color: isDark
        ? `${MEETINGFLOW_COLORS.white} !important`
        : `${MEETINGFLOW_COLORS.black} !important`,
      i: {
        color: isDark ? undefined : MEETINGFLOW_COLORS.teal,
      },

      'span.record-link': {
        color: `${MEETINGFLOW_COLORS.white} !important`,
      },
    },

    'div.status.in-call': {
      backgroundColor: MEETINGFLOW_COLORS.teal,
      color: MEETINGFLOW_COLORS.white,
      animation: 'recordingBlinker 2s infinite',

      'span.record-link': {
        color: `${MEETINGFLOW_COLORS.white} !important`,
      },
    },

    'div.status.in-call.recording': {
      backgroundColor: MEETINGFLOW_COLORS.red,
      color: MEETINGFLOW_COLORS.white,
      animation: 'recordingBlinker 2s infinite',
      'span.record-link': {
        color: `${MEETINGFLOW_COLORS.white} !important`,
      },
    },

    'div.status.in-call.call_ended': {
      backgroundColor: MEETINGFLOW_COLORS.magenta,
      color: MEETINGFLOW_COLORS.white,
      animation: 'recordingBlinker 2s infinite',
      'span.record-link': {
        color: `${MEETINGFLOW_COLORS.white} !important`,
      },
    },

    'div.status.error': {
      backgroundColor: MEETINGFLOW_COLORS.red,
      color: MEETINGFLOW_COLORS.white,
      'span.record-link': {
        color: `${MEETINGFLOW_COLORS.white} !important`,
      },
    },
  });

  const transcriptHasContent =
    transcript?.length && transcript[0]?.words?.length > 0;

  const showCollabDialogForTimestamp = useCallback(
    (timestamp: number) => {
      if (!showCollaborateDialog) {
        return;
      } else {
        showCollaborateDialog({
          type: 'InviteShare',
          recordingTimestamp: timestamp,
        }).promise.then(() => {
          return;
        });
      }
    },
    [showCollaborateDialog],
  );

  const Video = useMemo(
    () =>
      !!meetingPlan?.callRecording?.recordingFileName ? (
        <div className={'video-container'}>
          <VideoPlayer
            source={
              externalView
                ? '/recording-share' +
                  toContentPath(
                    meetingPlan.callRecording.recordingFileName,
                    organizationSlug,
                    meetingPlan.id,
                  )!
                : toContentPath(
                    meetingPlan.callRecording.recordingFileName,
                    organizationSlug,
                    meetingPlan.id,
                  )!
            }
            thumbnail={
              externalView
                ? '/recording-share' +
                  toContentPath(
                    meetingPlan?.callRecording?.thumbnailFileName,
                    organizationSlug,
                    meetingPlan.id,
                  )
                : toContentPath(
                    meetingPlan?.callRecording?.thumbnailFileName,
                    organizationSlug,
                    meetingPlan.id,
                  )
            }
            transcript={
              externalView
                ? '/recording-share' +
                  toContentPath(
                    meetingPlan?.callRecording?.subtitleFileName,
                    organizationSlug,
                    meetingPlan.id,
                  )
                : toContentPath(
                    meetingPlan?.callRecording?.subtitleFileName,
                    organizationSlug,
                    meetingPlan.id,
                  )
            }
            onPlayerControls={setVideoPlayerControls}
            onPlayerStateChanged={setVideoPlayerState}
            onPlayerHeightChanged={setVideoPlayerHeight}
            initialTimestamp={videoPlayerState?.currentTime || initialTimestamp}
          />
        </div>
      ) : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      meetingPlan?.callRecording?.recordingFileName,
      meetingPlan?.callRecording?.thumbnailFileName,
      meetingPlan?.callRecording?.subtitleFileName,
      organizationSlug,
      meetingPlan?.id,
      initialTimestamp,
      isFullscreen,
    ],
  );

  const callRecordingPlayer = useMemo(
    () => (
      <div
        className={callRecordingContainerClass}
        onClick={isMinimized ? () => setNonMinimized() : undefined}
      >
        {!externalView ? (
          <div className={callRecorderStatusClass}>{botStatusMessage}</div>
        ) : null}
        {!!meetingPlan?.callRecording?.recordingFileName ||
        transcriptHasContent ||
        meetingPlan?.callRecording?.transcriptAnalysis ||
        meetingPlan?.callRecording?.transcriptSummary ? (
          <div className={callRecordingPlayerClass}>
            {Video}
            {meetingPlan?.callRecording?.transcriptAnalysis ||
            transcriptHasContent ||
            meetingPlan?.callRecording?.transcriptSummary ? (
              <div className={'transcript-container'}>
                <Pivot
                  style={{
                    height: 'calc(100% - 1.5rem)',
                  }}
                  selectedKey={activePivotKey}
                  styles={pivotStyles}
                  onLinkClick={(pivotItem) => {
                    appInsights.trackEvent({
                      name: 'CALL_RECORDING_INSIGHTS_TAB_CLICKED',
                      properties: {
                        meetingPlanId,
                        tab: pivotItem?.props?.itemKey,
                      },
                    });
                    if (
                      activePivotKey == pivotItem?.props.itemKey &&
                      !isMinimized
                    ) {
                      setMinimized();
                    } else {
                      setActivePivotKey(pivotItem?.props.itemKey || '');
                      setNonMinimized();
                    }
                  }}
                  // onLinkClick={(item) => {
                  //   item?.props?.itemKey &&
                  //     setActivePivotKey(item?.props?.itemKey);
                  // }}
                >
                  {meetingPlan?.callRecording?.transcriptAnalysis ? (
                    <PivotItem
                      itemKey="CallInsights"
                      // @ts-ignore for JSX
                      headerText={
                        <>
                          Insights{' '}
                          <FontIcon
                            iconName="AISparkle"
                            style={{ color: MEETINGFLOW_COLORS.teal }}
                          />
                        </>
                      }
                    >
                      <div className={'transcript-analysis'}>
                        {activeTopic ? (
                          <>
                            <div
                              className="mask"
                              onClick={(e) =>
                                activeTopic
                                  ? setActiveTopic(undefined)
                                  : undefined
                              }
                            />

                            <LayerHost
                              id={topicLayerHostId}
                              className={topicLayerHostClass}
                              onClick={(e) =>
                                activeTopic
                                  ? setActiveTopic(undefined)
                                  : undefined
                              }
                            />

                            <Layer
                              className="active-topic"
                              hostId={topicLayerHostId}
                            >
                              <CallRecordingTopicCard
                                key={`${meetingPlanId}-active-topic-${activeTopic.title}`}
                                topic={activeTopic}
                                meetingPlanId={meetingPlanId}
                                organizationSlug={organizationSlug}
                                showCollabDialogForTimestamp={
                                  showCollabDialogForTimestamp
                                }
                                videoPlayerControls={videoPlayerControls}
                                isExpanded
                                transcript={transcript}
                                onClickJumpToTranscript={(t: number) => {
                                  setActivePivotKey('CallTranscript');
                                  appInsights.trackEvent({
                                    name: 'CALL_RECORDING_JUMP_TO_TRANSCRIPT_FROM_TOPIC',
                                    properties: {
                                      meetingPlanId,
                                      recordingTimestamp: t,
                                      topicTitle: activeTopic?.title,
                                    },
                                  });
                                }}
                                onSelectTopic={(t: Topic) => {
                                  activeTopic?.timestamp === t.timestamp
                                    ? setActiveTopic(undefined)
                                    : setActiveTopic(t);
                                }}
                              />
                            </Layer>
                          </>
                        ) : null}
                        {/* <div className="section-header">Participants</div>
                        {speakerNames.length ? (
                          <div className="participants-container">
                            {speakerNames.map((p) => (
                              <CallRecordingParticipantCard
                                key={`${meetingPlanId}-participant-${p}`}
                                participant={p}
                                mini
                                getColorForSpeaker={getColorForSpeaker}
                                callStats={callStats}
                                onParticipantClick={onParticipantClick}
                              />
                            ))}
                          </div>
                        ) : null} */}
                        {/* <div className="section-header">Topics</div> */}
                        <div className="list-container topics">
                          {isFullscreen ? (
                            <>
                              {meetingPlan?.callRecording?.transcriptAnalysis?.topics?.map(
                                (t: Topic, idx: number) => (
                                  <CallRecordingTopicCard
                                    key={`${meetingPlanId}-topic-${t.title}`}
                                    topic={t}
                                    meetingPlanId={meetingPlanId}
                                    organizationSlug={organizationSlug}
                                    isExpanded
                                    showCollabDialogForTimestamp={
                                      showCollabDialogForTimestamp
                                    }
                                    videoPlayerControls={videoPlayerControls}
                                    transcript={transcript}
                                    onClickJumpToTranscript={(t: number) => {
                                      setActivePivotKey('CallTranscript');
                                      appInsights.trackEvent({
                                        name: 'CALL_RECORDING_JUMP_TO_TRANSCRIPT_FROM_TOPIC',
                                        properties: {
                                          meetingPlanId,
                                          recordingTimestamp: t,
                                          topicTitle: activeTopic?.title,
                                        },
                                      });
                                    }}
                                    onSelectTopic={(t: Topic) => {
                                      activeTopic?.timestamp === t.timestamp
                                        ? setActiveTopic(undefined)
                                        : setActiveTopic(t);
                                    }}
                                  />
                                ),
                              )}
                            </>
                          ) : null}

                          <ul className="topics">
                            {meetingPlan?.callRecording?.transcriptAnalysis?.topics?.map(
                              (t: Topic, idx: number) => (
                                <li
                                  key={`${meetingPlanId}-topic-${t.title}`}
                                  onClick={(e) => {
                                    setActiveTopic(t);
                                    videoPlayerControls?.seek(t.timestamp);
                                    appInsights.trackEvent({
                                      name: 'CALL_RECORDING_INSIGHTS_SELECT_TOPIC',
                                      properties: {
                                        meetingPlanId,
                                        recordingTimestamp: t.timestamp,
                                        topicTitle: t.title,
                                      },
                                    });
                                  }}
                                >
                                  {t.title}
                                </li>
                              ),
                            )}
                          </ul>
                        </div>
                      </div>
                    </PivotItem>
                  ) : null}
                  <PivotItem headerText="Transcript" itemKey="CallTranscript">
                    <div className={'transcript-full'}>
                      <VideoTranscript
                        playerTime={
                          videoPlayerState?.seekingTime ||
                          videoPlayerState?.currentTime
                        }
                        attendees={meetingPlan?.attendees || []}
                        botStatus={callRecorderStatus?.data?.code}
                        callTranscript={transcript}
                        onSeekTranscriptUtterance={videoPlayerControls?.seek}
                        showCollabDialogForTimestamp={
                          showCollabDialogForTimestamp
                        }
                        meetingPlanId={meetingPlanId}
                        isFullscreen={isFullscreen}
                        onContactClick={onContactClick}
                        isRealtime={isRealtime}
                      />
                    </div>
                  </PivotItem>
                  {speakerNames.length ? (
                    <PivotItem
                      // @ts-ignore for JSX
                      headerText={<>Participants</>}
                      itemKey="CallDetails"
                    >
                      <div className={'transcript-details'}>
                        <div className="list-container">
                          {speakerNames.map((p) => (
                            <CallRecordingParticipantCard
                              key={`${meetingPlanId}-participant-${p}`}
                              participant={p}
                              getColorForSpeaker={getColorForSpeaker}
                              callStats={callStats}
                              onParticipantClick={onParticipantClick}
                            />
                          ))}
                        </div>
                      </div>
                    </PivotItem>
                  ) : null}
                </Pivot>

                {isFullscreen ? (
                  <FontIcon
                    iconName="ErrorBadge"
                    className="full-screen-control close"
                    title="Close Full Screen"
                    onClick={() => {
                      setNonFullscreen();
                      setActiveTopic(undefined);
                    }}
                  >
                    Inline
                  </FontIcon>
                ) : (
                  <FontIcon
                    iconName="ScaleUp"
                    className="full-screen-control open"
                    title="Full Screen Call Recording"
                    onClick={() => {
                      setFullscreen();
                      setActiveTopic(undefined);
                    }}
                  >
                    Full-screen
                  </FontIcon>
                )}

                {isMinimized ? (
                  <FontIcon
                    iconName="TriangleSolidDown12"
                    className="minimize-control open"
                    title="Expand Call Recording"
                    onClick={() => {
                      setNonMinimized();
                      setActiveTopic(undefined);
                    }}
                  >
                    Expand
                  </FontIcon>
                ) : (
                  <FontIcon
                    iconName="TriangleSolidDown12"
                    className="minimize-control close"
                    title="Collapse Call Recording"
                    onClick={() => {
                      setMinimized();
                      setActiveTopic(undefined);
                    }}
                  >
                    Collapse
                  </FontIcon>
                )}
              </div>
            ) : null}
          </div>
        ) : null}

        {turns?.length && speakerNames?.length ? (
          <div className="transcript-timeline">
            <CallRecordingTimeline
              speakers={speakerNames}
              turns={turns}
              callStats={callStats}
              meetingPlanId={meetingPlanId}
              getColorForSpeaker={getColorForSpeaker}
              videoPlayerControls={videoPlayerControls}
            />
          </div>
        ) : null}
      </div>
    ),
    [
      Video,
      activePivotKey,
      activeTopic,
      appInsights,
      callRecorderStatus?.data?.code,
      callRecordingContainerClass,
      callRecordingPlayerClass,
      callStats,
      getColorForSpeaker,
      isFullscreen,
      isRealtime,
      meetingPlan?.attendees,
      meetingPlan?.callRecording?.recordingFileName,
      meetingPlan?.callRecording?.transcriptAnalysis,
      meetingPlan?.callRecording?.transcriptSummary,
      meetingPlanId,
      onContactClick,
      onParticipantClick,
      organizationSlug,
      pivotStyles,
      setFullscreen,
      setNonFullscreen,
      showCollabDialogForTimestamp,
      speakerNames,
      topicLayerHostClass,
      topicLayerHostId,
      transcript,
      transcriptHasContent,
      turns,
      videoPlayerControls,
      videoPlayerState?.currentTime,
      videoPlayerState?.seekingTime,
      botStatusMessage,
      isMinimized,
      setMinimized,
      setNonMinimized,
      callRecorderStatusClass,
      externalView,
    ],
  );

  if (isFullscreen) {
    return (
      <Layer
        onLayerDidMount={() => {
          appInsights.trackEvent({
            name: 'CALL_RECORDING_FULL_SCREEN_PLAYER_OPENED',
            properties: {
              meetingPlanId,
            },
          });
        }}
        onLayerWillUnmount={() => {
          appInsights.trackEvent({
            name: 'CALL_RECORDING_FULL_SCREEN_PLAYER_CLOSED',
            properties: {
              meetingPlanId,
            },
          });
        }}
        styles={{
          root: {
            height: '100vh',
            width: '100vw',
          },
          content: {
            display: 'flex',
            flex: '1 1 auto',
            height: '100vh',
            width: '100vw',
            boxSizing: 'border-box',
            padding: '2rem',
            backgroundColor: isDark
              ? `rgba(0,0,0,.85)`
              : `rgba(255,255,255,.65)`,
            '> div': {
              width: '100%',
              height: '100%',
            },
          },
        }}
      >
        {callRecordingPlayer}
      </Layer>
    );
  }

  return callRecordingPlayer;
};
