import * as yup from 'yup';
export const toErrorCollection = (err: yup.ValidationError) => {
  const errors: Record<string, string[]> = {};
  for (const inner of err.inner) {
    if (inner.path) {
      if (!(inner.path in errors)) {
        errors[inner.path] = [];
      }

      errors[inner.path].push(...inner.errors);
    }
  }

  return errors;
};
