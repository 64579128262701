import { mergeStyleSets } from '@fluentui/react';
import React from 'react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import classNames from 'classnames';
import { ImageWithFallback } from '../../../Common/ImageWithFallback';
import { OwnerPicturePlaceholderIllustration } from './ConvReplyIllustrations';

interface ConversationRepliesProps {
  repliesCount: number;
  isRecent: boolean;
  repliesOwnersPictures: string[];
}

/**
 * ConversationReplies Component
 * Displays the number of replies in a conversation along with profile pictures of users who replied
 * Shows "No replies" when repliesCount is 0
 * Highlights the reply count when there are recent replies
 */
const ConversationReplies: React.FC<ConversationRepliesProps> = ({
  repliesCount,
  isRecent,
  repliesOwnersPictures,
}) => {
  const styles = mergeStyleSets({
    replyContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    replyContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: '.25rem',
      minWidth: '120px',
    },
    replyOwners: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    reply: {
      fontSize: '0.8125rem',
      fontWeight: '400',
      color: DEALROOMS_COLORS.themePrimary,
    },
    noReply: {
      fontSize: '0.8125rem',
      fontWeight: '400',
      color: DEALROOMS_COLORS.themeSecondary,
    },
    recentReply: {
      fontWeight: '600 !important',
    },
    ownerPicture: {
      width: '20px !important',
      height: '20px !important',
      borderRadius: '50% !important',
    },
    fallbackContainer: {
      width: '20px',
      height: '20px',
    },
  });

  return (
    <div className={styles.replyContainer}>
      {/* Show "No replies" message when there are no replies */}
      {repliesCount === 0 && <div className={styles.noReply}>No replies</div>}
      {/* Show reply count and profile pictures when there are replies */}
      {repliesCount > 0 && (
        <div className={styles.replyContent}>
          {/* Container for overlapping profile pictures */}
          <div className={styles.replyOwners}>
            {repliesOwnersPictures.map((picture, index) => (
              <ImageWithFallback
                className={styles.ownerPicture}
                key={index}
                src={picture}
                alt={`Reply owner ${index + 1}`}
                style={{
                  // Overlap profile pictures by -.25rem for a stacked effect
                  marginLeft: index > 0 ? '-.25rem' : '0',
                  // Ensure proper layering of overlapped images
                  zIndex: repliesOwnersPictures.length + index,
                }}
                fallback={
                  <span
                    className={styles.fallbackContainer}
                    style={{
                      marginLeft: index > 0 ? '-.25rem' : '0',
                      zIndex: repliesOwnersPictures.length + index,
                    }}
                  >
                    <OwnerPicturePlaceholderIllustration />
                  </span>
                }
              />
            ))}
          </div>
          {/* Reply count text with conditional highlighting for recent replies */}
          <div
            className={classNames(styles.reply, {
              [styles.recentReply]: isRecent,
            })}
          >
            {`${repliesCount} ${repliesCount > 1 ? 'replies' : 'reply'}`}
          </div>
        </div>
      )}
    </div>
  );
};

export default ConversationReplies;
