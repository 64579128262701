import { useRef, useState } from 'react';

export type UsePopupAuthOptions = {
  popupWidth?: number;
  popupHeight?: number;
  popupLeft?: number;
  popupTop?: number;
};

export const usePopupAuth = ({
  popupWidth,
  popupHeight,
  popupLeft,
  popupTop,
}: UsePopupAuthOptions = {}) => {
  const popupRef = useRef<Window | null>(null);
  const [inProgress, setInProgress] = useState(false);

  const createPopup = (title: string, content?: string) => {
    if (popupRef.current) {
      popupRef.current?.close();
      popupRef.current = null;
    }

    const width = popupWidth ?? 500;
    const height = popupHeight ?? 800;
    const left = popupLeft ?? window.screenX + (window.outerWidth - width) / 2;
    const top =
      popupTop ?? window.screenY + (window.outerHeight - height) / 2.5;

    const popup = window.open(
      '',
      title,
      `width=${width},height=${height},left=${left},top=${top}`,
    );

    if (!popup) {
      return false;
    }

    if (content) {
      popup.document.body.innerText = content;
    }

    popupRef.current = popup;

    const timer = setInterval(() => {
      if (popup.closed) {
        clearInterval(timer);
        setInProgress(false);
        if (popup === popupRef.current) {
          popupRef.current = null;
        }
      }
    }, 500);

    return true;
  };

  const showAuth = (authUrl: string) => {
    return new Promise<void>((resolve, reject) => {
      if (!popupRef.current) {
        return reject('No popup to show auth');
      }

      setInProgress(true);

      popupRef.current.location.href = authUrl;

      popupRef.current.focus();

      const timer = setInterval(() => {
        if (!popupRef.current || popupRef.current.closed) {
          clearInterval(timer);
          resolve();
        }
      }, 500);
    });
  };

  const closePopup = () => {
    setInProgress(false);
    popupRef?.current?.close();
  };

  return {
    createPopup,
    showAuth,
    closePopup,
    inProgress,
  };
};
