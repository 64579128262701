import { useAuth0 } from '@auth0/auth0-react';
import {
  BaseButton,
  Button,
  FontIcon,
  FontSizes,
  FontWeights,
  Link,
  mergeStyles,
  NeutralColors,
  Spinner,
  Text,
} from '@fluentui/react';
import {
  Contact,
  DetailedMeetingflow,
  ListPlansParams,
} from '@meetingflow/common/Api/data-contracts';
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import {
  MeetingPlanRelatedPlansQuery,
  OrganizationMeetingPlansQuery,
} from '../../../QueryNames';
import { MeetingflowsApiClient } from '../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { MeetingflowCard } from '../MeetingflowCard';

export interface MeetingPlanRelatedPlanReaderListProps {
  organizationSlug: string;
  meetingflowId: string;
  meetingflow?: Pick<DetailedMeetingflow, 'id' | 'externalSeriesId'>;
  title: string;
  filters: Omit<ListPlansParams, 'organizationSlug'>;
  maxCount?: number;
  color: string;
  showTitle?: boolean;
  onContactClick?: (c: Contact['id']) => void;
  onMeetingflowClick?: (meetingflowId: DetailedMeetingflow['id']) => void;
  onRelatedPlansViewAllClick?: (
    event: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | HTMLSpanElement
      | BaseButton
      | Button,
      MouseEvent
    >,
  ) => void;
  hideIfNoContent?: boolean;
  stickyTopPosition?: string;
  headersAreSubheaders?: boolean;
}

export const MeetingPlanRelatedPlanReaderList = ({
  organizationSlug,
  meetingflowId,
  meetingflow,
  title,
  filters,
  maxCount,
  onContactClick,
  onMeetingflowClick,
  showTitle,
  onRelatedPlansViewAllClick,
  hideIfNoContent,
  headersAreSubheaders,
}: MeetingPlanRelatedPlanReaderListProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const filterString = useMemo(() => {
    //@ts-ignore
    const params = new URLSearchParams(filters);
    params.sort();
    return params.toString();
  }, [filters]);

  const {
    data: plansData,
    isLoading: plansLoading,
    refetch: plansRefetch,
    isRefetching: plansRefetching,
  } = useQuery(
    OrganizationMeetingPlansQuery(organizationSlug!, filterString, maxCount),
    async () => {
      const token = await getAccessTokenSilently();
      return MeetingflowsApiClient.listPlans(
        {
          organizationSlug,
          ...filters,
          limit: maxCount,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!organizationSlug && !!meetingflowId,
    },
  );

  const slideSpinnerInHeaderClass = mergeStyles({
    height: '2rem',
    animationName: 'slideDownSpinnerAnimation',
    animationDuration: '1s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '.5',
    animationFillMode: 'forwards',
    position: 'absolute',
    top: '-.25rem',
    right: '0',
  });

  const sectionHeaderClass = mergeStyles({
    margin: '0 0 .25rem 0',
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.semibold,
    color: MEETINGFLOW_COLORS.teal,
    position: 'relative',
    display: 'block',
    width: '100%',
  });

  const sectionSubHeaderClass = mergeStyles({
    margin: '0 !important',
    fontSize: FontSizes.mini,
    fontWeight: FontWeights.semibold,
    color: NeutralColors.gray100,
    position: 'relative',
    display: 'block',
    width: '100%',
  });

  const planListWrapperClass = mergeStyles({
    '@container (width >= 35rem)': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      columnGap: '0',
      rowGap: '0',

      '> div': {
        boxSizing: 'border-box',
        flexBasis: 'calc(50% - .25rem)',
        maxWidth: 'calc(50% - 0.25rem)',
      },

      '> div:only-child': {
        flexBasis: '100%',
        maxWidth: '100%',
      },
    },

    '@container (width < 35rem)': {
      display: 'flex',
      flexBasis: '100%',
      maxWidth: '100%',
      flexDirection: 'column',
      columnGap: '0',
      rowGap: '0',

      '> div': {
        boxSizing: 'border-box',
        flexBasis: '100%',
        maxWidth: '100%',
      },

      '> span': {
        margin: '0 0 .25rem 0 !important',
      },
    },
  });

  useEffect(() => {
    if (organizationSlug) {
      plansRefetch();
    }
  }, [organizationSlug, meetingflowId, filterString, plansRefetch, maxCount]);

  const plansInSeries = useMemo(
    () =>
      plansData?.data?.filter(
        (p) => p.externalSeriesId === meetingflow?.externalSeriesId,
      ),
    [meetingflow?.externalSeriesId, plansData?.data],
  );

  const plansNotInSeries = useMemo(
    () =>
      plansData?.data?.filter(
        (p) => p.externalSeriesId !== meetingflow?.externalSeriesId,
      ),
    [meetingflow?.externalSeriesId, plansData?.data],
  );

  return (
    <div
      id="related-meetingflows-list"
      style={{
        display:
          (hideIfNoContent && plansLoading) ||
          (hideIfNoContent && !plansLoading && !plansData?.data?.length) ||
          !plansData?.data
            ? 'none'
            : 'block',
        minHeight: showTitle ? '4rem' : undefined,
      }}
    >
      {showTitle ? (
        <Text block className={sectionHeaderClass}>
          {title}
          {onRelatedPlansViewAllClick ? (
            <>
              <Link
                style={{
                  display: 'block',
                  float: 'right',
                  textAlign: 'right',
                  fontWeight: FontWeights.semilight,
                  fontSize: FontSizes.small,
                  transition: '.3s ease-in-out all',
                  marginRight: plansLoading || plansRefetching ? '2rem' : 0,
                }}
                onClick={onRelatedPlansViewAllClick}
              >
                All
                <FontIcon
                  style={{
                    display: 'inline-block',
                    position: 'relative',
                    top: '.15rem',
                    marginLeft: '.25rem',
                  }}
                  iconName="PageRight"
                />{' '}
              </Link>
              {plansLoading || plansRefetching ? (
                <Spinner className={slideSpinnerInHeaderClass} />
              ) : null}
            </>
          ) : null}
        </Text>
      ) : null}

      <div className={planListWrapperClass}>
        {plansInSeries?.length ? (
          <>
            {plansInSeries?.map((p) => (
              <MeetingflowCard
                key={p.id}
                meetingflowId={p.id}
                meetingflowObj={p}
                refetchMeetingflow={plansRefetch}
                organizationSlug={organizationSlug}
                onClick={onMeetingflowClick}
                expandable
                showCompanies
                noTitleLink
                showCallRecordingButton
              />
            ))}
          </>
        ) : null}
        {plansNotInSeries?.length ? (
          <>
            {plansNotInSeries?.map((p) => (
              <MeetingflowCard
                key={p.id}
                meetingflowId={p.id}
                meetingflowObj={p}
                refetchMeetingflow={plansRefetch}
                organizationSlug={organizationSlug}
                onClick={onMeetingflowClick}
                expandable
                showCompanies
                noTitleLink
                showCallRecordingButton
              />
            ))}
          </>
        ) : null}
      </div>
    </div>
  );
};
