import { IconButton, mergeStyleSets } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { CancelIconProps } from '../../../utils/iconProps';
import { ActionItemMoreOptions } from './ActionItemMoreOptions';
import classNames from 'classnames';

type ActionItemPanelHeaderProps = {
  actionItemId: number | null;
  organizationSlug: string;
  dealRoomId: number;
  onClickCloseButton: () => void;
  handleClickDeleteTask: () => Promise<void>;
};

export const ActionItemPanelHeader = ({
  actionItemId,
  organizationSlug,
  dealRoomId,
  onClickCloseButton,
  handleClickDeleteTask,
}: ActionItemPanelHeaderProps) => {
  const styles = mergeStyleSets({
    header: {
      display: 'flex',
      alignItems: 'center',
      gap: '.5rem',
      padding: '1.5rem 0 0',
    },
    closeButton: {
      marginRight: 'auto',
    },
    headerButton: {
      i: {
        fontSize: '1.25rem',
        color: DEALROOMS_COLORS.themePrimary,
      },
    },
  });

  return (
    <div className={styles.header}>
      <IconButton
        className={classNames(styles.closeButton, styles.headerButton)}
        iconProps={CancelIconProps}
        onClick={onClickCloseButton}
      />
      {actionItemId !== null && (
        <>
          {/*<IconButton
                className={styles.headerButton}
                iconProps={ShareIconProps}
                onClick={() => {}}
              />*/}
          {/* <IconButton
                className={styles.headerButton}
                iconProps={DeleteIconProps}
                onClick={handleClickDeleteTask}
              /> */}
          <ActionItemMoreOptions
            isSidePanel={true}
            actionItemId={actionItemId}
            organizationSlug={organizationSlug}
            dealRoomId={dealRoomId}
            deleteCallback={onClickCloseButton}
          />
        </>
      )}
    </div>
  );
};
