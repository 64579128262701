import { useAuth0 } from '@auth0/auth0-react';
import { useOrganizationSlug } from './useOrganizationSlug';
import { useDealRoomId } from './useDealRoomId';
import { useQuery, useQueryClient } from 'react-query';
import { OrganizationTaskCommentsQuery } from '../QueryNames';
import { DealRoomsApiClient } from '../Services/NetworkCommon';
import { isForbiddenError } from '../Helpers/AxiosHelpers';
import { useCallback } from 'react';

export const useTaskComments = (
  orgSlug?: string,
  dealRoomId?: number,
  taskId?: number,
) => {
  const { getAccessTokenSilently } = useAuth0();
  const client = useQueryClient();

  const slg = useOrganizationSlug();
  const drId = useDealRoomId();
  const orgSlugFinal = orgSlug || slg;
  const dealRoomIdFinal = dealRoomId || drId;

  const {
    data: taskComments,
    isLoading: taskCommentsLoading,
    isFetched: taskCommentsIsFetched,
    isError: taskCommentsIsError,
    error: taskCommentsError,
    refetch: refetchTaskComments,
  } = useQuery(
    OrganizationTaskCommentsQuery(orgSlugFinal, dealRoomIdFinal, taskId),
    async () => {
      const token = await getAccessTokenSilently();
      return DealRoomsApiClient.listActionItemComments(
        orgSlugFinal!,
        dealRoomIdFinal!,
        taskId!,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    },
    {
      enabled:
        !!orgSlugFinal &&
        !!dealRoomIdFinal &&
        !isNaN(dealRoomIdFinal) &&
        !!taskId &&
        !isNaN(taskId),
      retry: (failureCount, error) => {
        if (isForbiddenError(error)) {
          return false;
        }
        return failureCount < 3;
      },
    },
  );

  const refetchAll = useCallback(async () => {
    await client.invalidateQueries(
      OrganizationTaskCommentsQuery(orgSlugFinal, dealRoomIdFinal, taskId),
    );
  }, [client, dealRoomIdFinal, orgSlugFinal, taskId]);

  return {
    taskComments: taskComments?.data,
    isLoading: taskCommentsLoading,
    isFetched: taskCommentsIsFetched,
    isError: taskCommentsIsError,
    error: taskCommentsError,
    refetch: refetchTaskComments,
    refetchAll,
  };
};
