import { SalesforceObjectLayout } from '../../Models/Salesforce/SalesforceObjectLayout';
import {
  SalesforceField,
  SalesforceSchemaResponse,
} from '../../Models/Salesforce/SalesforceObjectSchema';
import {
  booleanValidator,
  dateTimeValidator,
  emptyValidator,
  integerValidator,
  localizedFloatValidator,
  multiPicklistValidator,
  percentValidator,
  pickListValidator,
  stringValidator,
} from './SalesforceYupValidators';
import * as yup from 'yup';
import { SalesforceAddress } from '../../Models/Salesforce/SalesforceAddress';
import { SalesforceLocation } from '../../Models/Salesforce/SalesforceLocation';

export type SalesforceFieldNameFieldMap = Record<
  string,
  SalesforceField | undefined
>;

export const encodeLightningField = (text: string): string =>
  encodeURIComponent(text.replaceAll(',', '%2c'));

export const getYupObjectValidator = (
  fieldNames: string[],
  fieldNameMap: SalesforceFieldNameFieldMap,
) => {
  return yup.object(
    Object.fromEntries(
      fieldNames.map((fieldName) => [
        fieldName,
        getFieldValidator(fieldNameMap[fieldName]),
      ]),
    ),
  );
};

export const getLayoutFields = (layout?: SalesforceObjectLayout) =>
  (
    layout?.detailLayoutSections?.flatMap(
      (layoutSection) =>
        layoutSection.layoutRows?.flatMap(
          (row) =>
            row.layoutItems?.flatMap(
              (item) =>
                item.layoutComponents
                  ?.filter((component) => !!component.details)
                  ?.flatMap((component) => component.details) ?? [],
            ) ?? [],
        ) ?? [],
    ) ?? []
  ).filter(Boolean) as SalesforceField[];

export const getFieldNameFieldMap = (
  schema?: SalesforceSchemaResponse,
  layout?: SalesforceObjectLayout,
): SalesforceFieldNameFieldMap => {
  if (!schema) {
    return {};
  }

  const layoutFields = getLayoutFields(layout);

  const fieldNames = [
    ...schema.defaultFields,
    ...(schema.customFields?.map((f) => f.fieldName) || []),
  ].filter((name) => !name.includes('.'));

  return Object.fromEntries(
    fieldNames.map((name) => [
      name,
      layoutFields?.find((f) => f.name === name) ??
        schema?.salesforceSchema?.fields?.find((f) => f.name === name),
    ]),
  );
};

export const getFieldValidator = (field?: SalesforceField) => {
  if (!field) {
    return emptyValidator();
  }

  switch (field.type) {
    case 'currency':
      return localizedFloatValidator(field.label, field.nillable);
    case 'int':
      return integerValidator(field.label, field.nillable);
    case 'percent':
      return percentValidator(field.label, field.nillable);
    case 'date':
    case 'datetime':
      return dateTimeValidator(field.label, field.nillable);
    case 'boolean':
      return booleanValidator(field.label, field.nillable);
    case 'picklist': {
      return pickListValidator(
        field.label,
        field?.picklistValues?.filter((v) => v.active)?.map((v) => v.value),
        field.nillable,
      );
    }
    case 'multipicklist': {
      return multiPicklistValidator(
        field.label,
        field?.picklistValues?.filter((v) => v.active)?.map((v) => v.value),
        field.nillable,
      );
    }
    case 'textarea':
    case 'string':
    default: {
      return stringValidator(field.label, field.nillable);
    }
  }
};

export const getDefaultState = (
  fieldNames: string[],
  fieldNameMap?: SalesforceFieldNameFieldMap,
) => {
  return Object.fromEntries(
    fieldNames.map((fieldName) => [
      fieldName,
      getDefaultFieldValue(fieldNameMap?.[fieldName]),
    ]),
  );
};

export const getDefaultFieldValue = (
  field?: SalesforceField,
): string | boolean | null | undefined => {
  if (!field) {
    return undefined;
  }

  if (field.defaultValue !== undefined && field.defaultValue !== null) {
    return `${field.defaultValue}`;
  }

  return field.nillable ? null : undefined;
};

export const serializeLocation = (value?: SalesforceLocation | null) => {
  if (!value) {
    return '';
  }

  return [value.latitude, value.longitude].filter(Boolean).join(', ');
};

export const serializeAddress = (value?: SalesforceAddress | null) => {
  if (!value) {
    return '';
  }

  return [
    value.street,
    value.city,
    value.state,
    value.postalCode,
    value.country,
  ]
    .filter(Boolean)
    .join(',\n');
};
