import {
  DealRoomActionItemStatus,
  DealRoomMilestoneType,
} from '@meetingflow/common/Api/data-contracts';
import { DateTime } from 'luxon';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

type DealRoomActionItemsStatusValues = {
  [key: string]: {
    key: DealRoomActionItemStatus;
    text: string;
  };
};

export const dealRoomActionItemStatusValues: DealRoomActionItemsStatusValues = {
  notStarted: {
    key: 'NOT_STARTED',
    text: 'Not Started',
  },
  docRequested: {
    key: 'DOC_REQUESTED',
    text: 'Requested',
  },
  onHold: {
    key: 'ON_HOLD',
    text: 'On Hold',
  },
  inProgress: {
    key: 'IN_PROGRESS',
    text: 'In Progress',
  },
  completed: {
    key: 'COMPLETED',
    text: 'Completed',
  },
};

export const dealRoomActionItemStatusOptions = [
  dealRoomActionItemStatusValues.notStarted,
  dealRoomActionItemStatusValues.docRequested,
  dealRoomActionItemStatusValues.onHold,
  dealRoomActionItemStatusValues.inProgress,
  dealRoomActionItemStatusValues.completed,
];

export const getStatusDropdownBackgroundColor = (
  status: DealRoomActionItemStatus | undefined,
) => {
  switch (status) {
    case 'COMPLETED':
      return DEALROOMS_COLORS.actionItemStatusCompleted;
    case 'DOC_REQUESTED':
      return DEALROOMS_COLORS.actionItemStatusNotStarted;
    case 'IN_PROGRESS':
      return DEALROOMS_COLORS.actionItemStatusInProgress;
    case 'ON_HOLD':
      return DEALROOMS_COLORS.actionItemStatusInProgress;
    case 'NOT_STARTED':
      return DEALROOMS_COLORS.actionItemStatusNotStarted;
    default:
      return DEALROOMS_COLORS.actionItemStatusNotStarted;
  }
};

export const isSameDueDate = (date1: string, date2: string) => {
  const currentDate1 = DateTime.fromISO(date1);
  const currentDate2 = DateTime.fromISO(date2);

  return currentDate1.hasSame(currentDate2, 'day');
};
