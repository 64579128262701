import {
  FontSizes,
  FontWeights,
  NeutralColors,
  mergeStyles,
} from '@fluentui/react';
import { TranscriptStats } from '@meetingflow/common/TranscriptHelpers';
import classNames from 'classnames';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';

interface CallRecordingParticipantCardProps {
  participant: string;
  callStats: TranscriptStats | undefined;
  mini?: boolean;
  getColorForSpeaker: (participant: string) => string;
  onParticipantClick?: (participant: string) => void;
}

const CallRecordingParticipantCard = ({
  participant,
  callStats,
  mini,
  onParticipantClick,
  getColorForSpeaker,
}: CallRecordingParticipantCardProps) => {
  const minutesTalkTime = (
    (callStats?.talkTime[participant] || 0) / 60
  )?.toFixed(0);

  const percentTalkTime = callStats?.talkPercentage[participant]?.toFixed(2);

  const percentTalkTimeRounded =
    callStats?.talkPercentage[participant]?.toFixed(0);

  const speakerTurns = callStats?.speakerTurns[participant];

  const { isDark } = useLightOrDarkMode();

  const participantCardStyles = mergeStyles({
    height: !mini ? '64px' : 'auto',
    minHeight: !mini ? '64px' : undefined,
    cursor: 'unset',
    margin: '0 !important',
    padding: !mini ? undefined : `.25rem 2rem .25rem .5rem !important`,
    backgroundColor: isDark
      ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
      : NeutralColors.gray120,

    '.pie-chart': {
      // '--w': '48px',
      height: 'var(--w)',
      width: 'var(--w)',
      aspectRatio: 1,
      display: 'inline-grid',
      placeContent: 'center',
      margin: '5px',
      fontSize: FontSizes.small,
      color: MEETINGFLOW_COLORS.black,
      fontWeight: FontWeights.light,
      fontFamily: 'Georgia, serif',
      backgroundColor: `rgba(255,255,255,.75)`,
      border: `1px solid ${MEETINGFLOW_COLORS.white}`,
      borderRadius: '50%',
      position: 'absolute',
      top: mini ? '-.125rem' : '.125rem',
      right: 0,
    },

    'div.pie-chart-border': {
      content: '',
      position: 'absolute',
      borderRadius: '50%',
      inset: 0,
      background: ' conic-gradient(var(--c) calc(var(--p)*1%),#0000 0)',
      '-webkit-mask':
        'radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)))',
      mask: 'radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)))',
    },

    dt: {
      margin: `0 !important`,
    },

    'dt, dd': {
      color: `${MEETINGFLOW_COLORS.white} !important`,
      textShadow: '1px 1px 2px rgba(0, 0, 0, .25)',

      strong: {
        fontWeight: FontWeights.semibold,
      },
    },
  });

  return (
    <dl
      key={participant}
      className={classNames(participantCardStyles, 'call-participant')}
      onClick={() => onParticipantClick?.(participant)}
      style={{
        backgroundColor: getColorForSpeaker(participant),
      }}
    >
      <dt>{participant}</dt>

      {!mini && minutesTalkTime && speakerTurns ? (
        <dd>
          <strong>{speakerTurns}</strong> turns,{' '}
          <strong>{minutesTalkTime}</strong> min
        </dd>
      ) : null}

      {percentTalkTimeRounded ? (
        <div
          className={'pie-chart'}
          style={{
            // @ts-ignore
            '--p': percentTalkTime,
            '--b': '10px',
            '--c': getColorForSpeaker(participant),
            '--w': mini ? '16px' : '48px',
          }}
        >
          <div className="pie-chart-border" />
          {!mini ? <>{percentTalkTimeRounded || 0}%</> : null}
        </div>
      ) : null}
    </dl>
  );
};

export default CallRecordingParticipantCard;
