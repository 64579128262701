import { useAuth0 } from '@auth0/auth0-react';
import {
  Dropdown,
  FontSizes,
  FontWeights,
  NeutralColors,
  PrimaryButton,
  Spinner,
  Stack,
  Text,
  useTheme,
} from '@fluentui/react';
import { ExternalServiceObjectType } from '@meetingflow/common/Api/data-contracts';
import {
  PickValues,
  fieldDirty,
  getDirtyFields,
  objectIsDirty,
  recordHasOwnProperty,
} from '@meetingflow/common/ObjectHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import { isAxiosErrorResponse } from '../../../../Helpers/AxiosHelpers';
import {
  getFieldNameFieldMap,
  getYupObjectValidator,
} from '../../../../Helpers/HubSpot/HubSpotFieldHelpers';
import { parseLocaleFloat } from '../../../../Helpers/NumberHelpers';
import { validateData } from '../../../../Helpers/Salesforce/SalesforceYupValidators';
import { useExternalServiceConfigurations } from '../../../../Hooks/useExternalServiceConfigurations';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import { HubSpotDeal } from '../../../../Models/HubSpot/HubSpotDeal';
import { HubSpotDealPipeline } from '../../../../Models/HubSpot/HubSpotDealPipeline';
import { HubSpotOwner } from '../../../../Models/HubSpot/HubSpotOwner';
import { HubSpotSchemaResponse } from '../../../../Models/HubSpot/HubSpotSchema';
import {
  HubSpotDealPipelineQuery,
  HubSpotObjectQuery,
  HubSpotObjectSchemaQuery,
  HubSpotOwnerQuery,
} from '../../../../QueryNames';
import {
  ApiClient,
  ExternalServicesApiClient,
} from '../../../../Services/NetworkCommon';
import { HubSpotPanelContext } from '../../../../types/HubSpotPanelContext';
import { HubSpotDealTile } from './HubSpotDealTile';
import { HubSpotFormComponent } from './HubSpotFormComponent';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { titleCase } from '@meetingflow/common/StringHelpers';
import { useForceUpdate } from '@fluentui/react-hooks';

const DEFAULT_EDITABLE_FIELDS = [
  'amount',
  'closedate',
  'dealstage',
  'hs_next_step',
];

export type HubSpotSidePanelDealContentProps = {
  organizationSlug: string;
  meetingPlanId?: string;
  associatedWithPlan?: boolean;
  externalServiceObjectId?: number;
  configurationId: number;
  dealId: string;
  pushHubSpotPanel: (context: HubSpotPanelContext) => void;
  showObjectPicker: (startTab?: ExternalServiceObjectType) => void;
  onPinClick?: (e?: React.MouseEvent<HTMLElement>) => Promise<unknown>;
};
export const HubSpotSidePanelDealContent = ({
  organizationSlug,
  meetingPlanId,
  associatedWithPlan,
  externalServiceObjectId,
  configurationId,
  dealId,
  pushHubSpotPanel,
  showObjectPicker,
  onPinClick,
}: HubSpotSidePanelDealContentProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const {
    loading: salesforceConfigurationsLoading,
    configurationById,
    refetchAll,
  } = useExternalServiceConfigurations({ app: 'HUBSPOT', withToken: true });

  const theme = useTheme();
  const { isDark } = useLightOrDarkMode();

  const appInsights = useAppInsightsContext();

  const [formIsSubmitting, setFormIsSubmitting] = useState<boolean>(false);

  const { hasEntitlement } = useOrganization();

  const forceUpdate = useForceUpdate();

  const [updateErrors, setUpdateErrors] = useState<
    Record<string, string[] | undefined>
  >({});
  const [objectState, setObjectState] = useState<
    Record<string, string | boolean | number | undefined | null>
  >({});

  const {
    data: objectSchema,
    isLoading: objectSchemaLoading,
    refetch: refetchObjectSchema,
  } = useQuery(
    HubSpotObjectSchemaQuery(organizationSlug!, configurationId, 'deals'),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<HubSpotSchemaResponse>(
        `/organization/${organizationSlug}/external/hubspot/configuration/${configurationId}/schema/deals`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!configurationById(configurationId),
    },
  );

  const {
    data: hubspotObject,
    isLoading: hubspotObjectLoading,
    refetch: refetchHubSpotObject,
  } = useQuery(
    HubSpotObjectQuery(organizationSlug!, configurationId, 'deals', dealId),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<HubSpotDeal>(
        `/organization/${organizationSlug}/external/hubspot/configuration/${configurationId}/deals/${dealId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!configurationById(configurationId),
    },
  );

  const {
    data: hubspotOwner,
    isLoading: hubspotOwnerLoading,
    refetch: refetchHubSpotOwner,
  } = useQuery(
    HubSpotOwnerQuery(
      organizationSlug!,
      configurationId,
      hubspotObject?.data?.properties?.hubspot_owner_id || '',
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<HubSpotOwner>(
        `/organization/${organizationSlug}/external/hubspot/configuration/${configurationId}/owners/${hubspotObject?.data.properties.hubspot_owner_id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled:
        !!configurationById(configurationId) &&
        !!hubspotObject?.data.properties.hubspot_owner_id,
    },
  );

  const {
    data: hubspotDealPipeline,
    isLoading: hubspotDealPipelineLoading,
    refetch: refetchHubspotDealPipeline,
  } = useQuery(
    HubSpotDealPipelineQuery(
      organizationSlug!,
      configurationId,
      hubspotObject?.data?.properties?.pipeline,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<HubSpotDealPipeline>(
        `/organization/${organizationSlug}/external/hubspot/configuration/${configurationId}/pipelines/${hubspotObject?.data.properties.pipeline}/deal`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled:
        !!configurationId &&
        !!dealId &&
        !!hubspotObject?.data?.properties?.pipeline,
    },
  );

  const { mutate: updateDefaultCustomFieldSetId } = useMutation(
    async ({
      tokenId,
      defaultCustomFieldSetId,
    }: {
      tokenId: number;
      defaultCustomFieldSetId: number | null;
    }) => {
      const token = await getAccessTokenSilently();
      const result = await ExternalServicesApiClient.setDefaultFieldSet(
        organizationSlug!,
        tokenId,
        { fieldSetId: defaultCustomFieldSetId },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return result.data;
    },
    {
      onSettled: () => {
        refetchAll().then(forceUpdate);
      },
    },
  );

  useEffect(() => {
    if (configurationId && !!configurationById(configurationId)) {
      refetchObjectSchema();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configurationById, configurationId]);

  useEffect(() => {
    if (configurationId && !!configurationById(configurationId) && dealId) {
      setUpdateErrors({});
      refetchHubSpotObject();
    }
  }, [configurationId, dealId, refetchHubSpotObject, configurationById]);

  useEffect(() => {
    if (
      configurationId &&
      !!configurationById(configurationId) &&
      hubspotObject?.data?.properties?.hubspot_owner_id
    ) {
      refetchHubSpotOwner();
    }
  }, [
    configurationId,
    configurationById,
    hubspotObject?.data?.properties?.hubspot_owner_id,
    refetchHubspotDealPipeline,
    refetchHubSpotOwner,
  ]);

  useEffect(() => {
    if (
      configurationId &&
      !!configurationById(configurationId) &&
      hubspotObject?.data?.properties?.pipeline
    ) {
      refetchHubspotDealPipeline();
    }
  }, [
    configurationId,
    configurationById,
    hubspotObject?.data?.properties?.pipeline,
    refetchHubspotDealPipeline,
  ]);

  const fieldNameFieldMap = useMemo(
    () =>
      getFieldNameFieldMap(
        objectSchema?.data?.hubspotSchema,
        hubspotDealPipeline?.data,
      ),
    [hubspotDealPipeline?.data, objectSchema?.data?.hubspotSchema],
  );

  const fields = useMemo(() => {
    return (
      objectSchema?.data?.customFields?.length
        ? objectSchema.data.customFields.map((f) => f.fieldName)
        : DEFAULT_EDITABLE_FIELDS
    ).filter((f) => !fieldNameFieldMap[f]?.hidden);
  }, [fieldNameFieldMap, objectSchema?.data?.customFields]);

  const readOnlyFields = useMemo(() => {
    return fields.filter(
      (name) =>
        !!fieldNameFieldMap[name] &&
        (!!fieldNameFieldMap[name]?.calculated ||
          !!fieldNameFieldMap[name]?.modificationMetadata.readOnlyValue),
    );
  }, [fieldNameFieldMap, fields]);

  const editableFields = useMemo(() => {
    return fields.filter(
      (name) => !!fieldNameFieldMap[name] && !readOnlyFields.includes(name),
    );
  }, [fieldNameFieldMap, fields, readOnlyFields]);

  useEffect(() => {
    if (hubspotObject?.data?.properties && editableFields) {
      setObjectState(PickValues(hubspotObject.data.properties, editableFields));
    }
  }, [editableFields, hubspotObject?.data]);

  const formSchema = useMemo(
    () =>
      getYupObjectValidator(
        objectSchema?.data?.hubspotSchema,
        editableFields,
        fieldNameFieldMap,
      ),
    [editableFields, fieldNameFieldMap, objectSchema?.data.hubspotSchema],
  );

  const [isValid, errors] = useMemo(
    () => validateData(formSchema, objectState),
    [formSchema, objectState],
  );

  const isDirty = useMemo(
    () => objectIsDirty(objectState, hubspotObject?.data?.properties || {}),
    [objectState, hubspotObject?.data],
  );

  const onSubmit = async () => {
    if (!isValid || !isDirty) {
      return;
    }

    const dirtyFields = getDirtyFields(
      objectState,
      hubspotObject?.data?.properties || {},
    );

    const updateBody = Object.fromEntries(
      Object.entries(dirtyFields).map(([name, value]) => {
        const property = fieldNameFieldMap[name];

        if (!property) {
          return [name, value];
        }

        switch (property.type) {
          case 'number': {
            if (property.showCurrencySymbol) {
              return [name, parseLocaleFloat(value as string).toFixed(2)];
            }
            return [name, (value as number).toString()];
          }
          case 'boolean': {
            return [name, (value as boolean) ? 'True' : 'False'];
          }
          default: {
            return [name, value];
          }
        }
      }),
    );

    setFormIsSubmitting(true);
    setUpdateErrors({});

    await toast.promise(
      (async () => {
        const token = await getAccessTokenSilently();

        ApiClient.patch<HubSpotDeal>(
          `/organization/${organizationSlug}/external/hubspot/configuration/${configurationId}/deals/${dealId}`,
          updateBody,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'x-meetingplan-id': meetingPlanId,
            },
          },
        );
      })(),
      {
        loading: 'Updating HubSpot deal',
        success: () => {
          appInsights.trackEvent({
            name: `HUBSPOT_DEAL_UPDATED`,
            properties: {
              oppName: hubspotObject?.data?.properties?.dealname,
              surface: 'SIDE_PANEL',
            },
          });
          refetchHubSpotObject();

          return 'HubSpot deal successfully updated';
        },
        error: (err) => {
          appInsights.trackEvent({
            name: `HUBSPOT_DEAL_UPDATE_ERROR`,
            properties: {
              oppName: hubspotObject?.data?.properties?.dealname,
              status: isAxiosErrorResponse(err)
                ? err.response?.status
                : undefined,
              statusText: isAxiosErrorResponse(err)
                ? err.response?.statusText
                : undefined,
            },
          });

          return 'Unable to update HubSpot deal. Please try again';
        },
      },
    );

    setFormIsSubmitting(false);
  };

  if (
    salesforceConfigurationsLoading ||
    objectSchemaLoading ||
    hubspotObjectLoading ||
    hubspotOwnerLoading ||
    hubspotDealPipelineLoading
  ) {
    return (
      <div
        style={{
          minHeight: '100%',
          height: `calc(100vh - 17.25rem)`,
        }}
      >
        <div style={{ margin: '1rem 0' }}>
          <Spinner />
        </div>
      </div>
    );
  }

  if (!hubspotObject?.data) {
    return (
      <Text
        variant="large"
        block
        style={{
          textAlign: 'center',
          margin: '1rem 0',
        }}
      >
        This associated HubSpot Deal couldn't be loaded. Check the Deal still
        exists and that you have access to it.
        <div
          style={{
            textAlign: 'center',
            margin: '1rem 0',
          }}
        >
          <PrimaryButton onClick={() => showObjectPicker('DEAL')}>
            Select a new Deal
          </PrimaryButton>
        </div>
      </Text>
    );
  }

  const configuration = configurationById(configurationId)!;
  const hasCustomFieldsets =
    configurationById(configurationId)?.externalServiceCustomFieldsets?.length;

  return (
    <div
      style={{
        minHeight: '100%',
        marginBottom: '1.5rem',
      }}
    >
      <div>
        <HubSpotDealTile
          key={hubspotObject.data.id}
          organizationSlug={organizationSlug}
          meetingPlanId={meetingPlanId}
          id={hubspotObject.data.id}
          name={hubspotObject.data.properties.dealname}
          hubspotInstance={configurationById(configurationId)!.instanceId}
          companyName={hubspotObject.data?.companies?.[0]?.properties?.name}
          ownerName={
            hubspotOwner?.data?.firstName
              ? `${hubspotOwner?.data?.firstName} ${hubspotOwner?.data?.lastName}`
              : undefined
          }
          ownerEmail={hubspotOwner?.data?.email}
          stage={
            hubspotDealPipeline?.data?.stages?.find(
              (stage) => stage.id === hubspotObject.data?.properties?.dealstage,
            )?.label ?? hubspotObject.data?.properties?.dealstage
          }
          isPinned={associatedWithPlan}
          externalServiceObjectId={externalServiceObjectId}
          onClick={
            associatedWithPlan ? () => showObjectPicker('DEAL') : undefined
          }
          onPinClick={onPinClick}
          onClickCompanyName={
            !!hubspotObject.data?.companies?.[0]
              ? () =>
                  pushHubSpotPanel({
                    name: hubspotObject.data?.companies?.[0].properties.name,
                    objectId: hubspotObject.data?.companies?.[0].id,
                    objectType: 'ACCOUNT',
                    serviceConfigurationId: configurationId,
                  })
              : undefined
          }
          showExternalLink
        />
      </div>

      {hasEntitlement('CUSTOM_FIELDSETS') && hasCustomFieldsets ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '1rem',
            marginBottom: '.5rem',
            paddingBottom: '.5rem',
            borderBottom: `1px solid ${
              isDark ? NeutralColors.gray170 : theme.semanticColors.bodyDivider
            }`,
          }}
        >
          <Dropdown
            label="Field Set"
            className="customFieldsetPicker"
            styles={{
              root: {
                display: 'flex',
                columnGap: '0',
              },
              label: {
                fontWeight: FontWeights.semibold,
                marginRight: '.5rem',
              },
            }}
            disabled={salesforceConfigurationsLoading}
            selectedKey={
              configuration.externalServiceUserTokens![0]
                .defaultCustomFieldSetId || -1
            }
            options={[
              {
                key: -1,
                text: 'Default',
                title: 'Default',
              },
              ...(configuration.externalServiceCustomFieldsets!.map((f) => ({
                key: f.id,
                text: f.name,
              })) ?? []),
            ]}
            onChange={(e, opt) => {
              if (opt) {
                updateDefaultCustomFieldSetId({
                  tokenId: configuration.externalServiceUserTokens![0]!.id,
                  defaultCustomFieldSetId:
                    opt.key === -1 ? null : (opt.key as number),
                });
                toast.success('Your default fieldset has been updated.');
              }
            }}
          />
        </div>
      ) : null}

      <div>
        {fields.map((fieldName) => {
          const property = fieldNameFieldMap[fieldName];
          if (!property) {
            return null;
          }

          const isReadonly = readOnlyFields.includes(fieldName);

          return (
            <HubSpotFormComponent
              key={property.name}
              schema={objectSchema?.data.hubspotSchema}
              property={property}
              errors={{ ...errors, ...updateErrors }}
              readonly={isReadonly}
              isDirty={
                isReadonly
                  ? false
                  : fieldDirty(objectState, hubspotObject.data, property.name)
              }
              state={isReadonly ? hubspotObject?.data?.properties : objectState}
              setState={setObjectState}
            />
          );
        })}
      </div>
      {recordHasOwnProperty(updateErrors, 'UPDATE_ERROR') &&
      updateErrors.UPDATE_ERROR?.length ? (
        <div
          style={{
            backgroundColor: theme.semanticColors.errorText,
            padding: '1rem 0',
            margin: '1rem',
            borderRadius: '.25rem',
            textAlign: 'center',
            fontSize: FontSizes.mediumPlus,
            color: 'white',
          }}
        >
          {updateErrors.UPDATE_ERROR[0]}
        </div>
      ) : null}
      <div
        style={{ display: 'grid', justifyItems: 'right', marginTop: '.5rem' }}
      >
        <PrimaryButton
          disabled={
            hubspotObjectLoading || formIsSubmitting || !isValid || !isDirty
          }
          text={formIsSubmitting ? 'Updating HubSpot...' : 'Update HubSpot'}
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};
