import {
  FontWeights,
  Icon,
  mergeStyles,
  NeutralColors,
  Text,
} from '@fluentui/react';
import { MouseEventHandler } from 'react';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import HubSpotIcon from '../../../../Static/Images/hubspot.png';

export type HubSpotContactTileProps = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  hubspotInstance: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
};
export const HubSpotContactTile = ({
  id,
  firstName,
  lastName,
  email,
  hubspotInstance,
  onClick,
}: HubSpotContactTileProps) => {
  const { isDark } = useLightOrDarkMode();

  const contactTileClass = mergeStyles({
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    padding: '.5rem',
    backgroundColor: 'transparent',
    transition: 'all .3s ease-in-out',
    cursor: onClick ? 'pointer' : undefined,
    border: `1px solid ${
      isDark ? NeutralColors.gray140 : NeutralColors.gray40
    }`,
    borderRadius: '.25rem',
    '>div:not(:last-child)': {
      marginBottom: '0.25rem',
    },
    '.arrow': {
      display: 'grid',
      alignItems: 'center',
    },

    ':hover': {
      backgroundColor: onClick
        ? isDark
          ? NeutralColors.gray180
          : NeutralColors.gray10
        : undefined,
      border: onClick
        ? `1px solid ${isDark ? NeutralColors.gray110 : NeutralColors.gray60}`
        : undefined,
    },
  });

  return (
    <div className={contactTileClass} onClick={onClick}>
      <div>
        <Text
          variant="mediumPlus"
          style={{
            fontWeight: FontWeights.semibold,
            position: 'relative',
            top: '-.15rem',
          }}
          block
          nowrap
        >
          {firstName} {lastName}
          <Icon
            key="HubSpot"
            style={{
              position: 'relative' as const,
              marginRight: '.25rem',
              cursor: 'pointer',
              float: 'right',
            }}
            imageProps={{
              height: '16px',
              src: HubSpotIcon,
              alt: 'HubSpot',
              width: '16px',
              title: `View ${firstName} ${lastName} on HubSpot`,
            }}
            onClick={(e) => {
              e.stopPropagation();
              window.open(
                `https://app.hubspot.com/contacts/${hubspotInstance}/contacts/${id}`,
                '_blank',
              );
            }}
          />
        </Text>
        <Text
          nowrap
          variant="small"
          style={{
            fontWeight: FontWeights.semibold,
            color: NeutralColors.gray110,
          }}
        >
          {email}
        </Text>
      </div>
    </div>
  );
};
