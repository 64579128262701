import { DefaultButton, FontSizes, FontWeights } from '@fluentui/react';

export const ChatBarAction = ({
  buttonText,
  promptText,
  onClick,
}: {
  buttonText: string;
  promptText: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  return (
    <DefaultButton
      text={buttonText || 'Summarize Document'}
      data-prompt={promptText}
      styles={{
        root: {
          backgroundColor: '#f9e6ef',
          color: '#a00053',
          borderColor: '#f9e6ef',
          borderRadius: '5px',
          minHeight: '1.5rem',
          padding: '0 .5rem',
        },
        textContainer: {},
        label: {
          fontSize: FontSizes.small,
          fontWeight: FontWeights.regular,
          margin: 0,
        },
      }}
      onClick={onClick}
    />
  );
};
