import { mergeStyleSets } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { FontWeights } from '@fluentui/theme';

export const getStyles = () => mergeStyleSets({
  modalWrapper: {
    '.ms-Dialog-main': {
      minHeight: 'auto',
      minWidth: 'auto',
      height: 'fit-content',
      width: 'calc(100% - 4rem)',
      maxWidth: '640px',
      maxHeight: 'calc(100% - 4rem)',
      boxShadow: '0px 2px 2px 0px #00000005',
      borderRadius: '0.5rem',
      backgroundColor: DEALROOMS_COLORS.white,
      padding: '1.5rem',

      // '.ms-Modal-scrollableContent': {
      //   maxHeight: 'fit-content',
      // },
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '0.5rem',
    fontSize: '1.5rem',
    justifyContent: 'space-between',
  },
  closeIcon: {
    borderRadius: '50%',
    i: {
      color: DEALROOMS_COLORS.neutralDarker,
      fontSize: '0.8rem',
    },
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '1.5rem 0 0 0',
    '> *': {
      padding: '.5rem 1rem !important',
      '.ms-Button-label': {
        fontSize: '.9375rem !important',
        lineHeight: '1.5rem !important',
      },
    },
  },
  cancel: {
    backgroundColor: DEALROOMS_COLORS.white,
  },
  confirm: {
    backgroundColor: DEALROOMS_COLORS.themePrimary,
  },
});

export const getStepStyles = () => mergeStyleSets({
  stepContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1.5rem',
    gap: '1.5rem',
    position: 'relative',
    '::before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '1px',
      backgroundColor: DEALROOMS_COLORS.neutralLight,
    },
    span: {
      width: '2rem',
      height: '2rem',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: DEALROOMS_COLORS.neutralLight,
      borderRadius: '50%',
      color: DEALROOMS_COLORS.themeSecondary,
      fontWeight: FontWeights.regular,
      border: `1px solid transparent`,
      zIndex: '2',
    },
    '.completed': {
      backgroundColor: DEALROOMS_COLORS.themePrimary,
      color: DEALROOMS_COLORS.white,
      border: `1px solid ${DEALROOMS_COLORS.themePrimary}`,
    },
    '.selected': {
      backgroundColor: DEALROOMS_COLORS.white,
      color: DEALROOMS_COLORS.themePrimary,
      border: `1px solid ${DEALROOMS_COLORS.themePrimary}`,
    },
  },
  completedStepCheckmark: {
    backgroundColor: `transparent !important`,
    fontSize: '1.8rem',
  },
});

export const getCreateSiteStyles = () => mergeStyleSets({
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.8rem',
    marginTop: '1.5rem',
    '.title': {
      fontSize: '1rem',
      fontWeight: FontWeights.regular,
      color: DEALROOMS_COLORS.themePrimary,
    },
  },
})

export const getBrandingSiteStyles = () => mergeStyleSets({
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.8rem',
    marginTop: '1.5rem',
    '.title': {
      fontSize: '1rem',
      fontWeight: FontWeights.regular,
      color: DEALROOMS_COLORS.themePrimary,
    },
    '.subTitle': {
      fontSize: '0.86rem',
      fontWeight: FontWeights.regular,
      color: DEALROOMS_COLORS.darkerGray,
      span: {
        color: DEALROOMS_COLORS.uploadAttachmentHighLightedText,
      }
    }
  },
  dropZone: {
    backgroundColor: DEALROOMS_COLORS.white,
  },
  dropHereText: {
    fontSize: '1rem',
  },
  manualUploadText: {
    '> *': {
      color: `${DEALROOMS_COLORS.themeSecondary} !important`,
      fontSize: '0.9rem',
    }
  },
  uploadButton: {
    fontSize: 'inherit',
    color: 'inherit',
    textDecoration: 'inherit',
    background: 'inherit',
    padding: '0',
    margin: '0',
    minWidth: '0',
    minHeight: '0',
    border: 'none',
    textDecorationLine: 'underline',
    cursor: 'pointer',
    span: {
      margin: 0,
      fontWeight: `${FontWeights.regular} !important`,
    },
  },
  uploadDetailsText: {
    color: DEALROOMS_COLORS.themeSecondary,
    fontSize: '0.8rem',
  },
  colorPickerTitle: {
    fontSize: '0.88rem',
    span: {
      color: DEALROOMS_COLORS.uploadAttachmentHighLightedText,
    }
  },
  colorPickerContainer: {
    backgroundColor: DEALROOMS_COLORS.inputLightGray,
    padding: '0.5rem 0.8rem',
    borderRadius: '0.2rem',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'center',
    fontSize: '1rem',
    '>span:nth-child(1)': {
      width: '20px',
      height: '20px',
      display: 'inline-block',
      borderRadius: '4px',
      border: '1px solid #ccc',
      verticalAlign: 'middle',
      marginRight: '8px',
    },
  }
})