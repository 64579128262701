import {
  DefaultButton,
  Dialog,
  DialogFooter,
  Dropdown,
  IDropdown,
  IDropdownOption,
  ITextField,
  PrimaryButton,
  TextField,
} from '@fluentui/react';
import { Truthy } from '@meetingflow/common/TypeHelpers';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MeetingPlanPanelContext } from '../../types/MeetingPlanPanelContext';
import { SidePanelShortcutContext } from '../../types/SidePanelShortcutContext';
import { useDeferredPromise } from '../useDeferredPromise';
import { useExternalServiceConfigurations } from '../useExternalServiceConfigurations';
import { useLightOrDarkMode } from '../useLightOrDarkMode';
import { useOrganization } from '../useOrganization';

type SidePanelShortcutDialogProps = {
  initialText?: string;
  initialTargetSidePanel?: MeetingPlanPanelContext['type'];
  onSubmit: (
    value: SidePanelShortcutContext | PromiseLike<SidePanelShortcutContext>,
  ) => void;
  onCancel: (reason?: unknown) => void;
};

const SidePanelShortcutDialog = ({
  initialText,
  initialTargetSidePanel,
  onSubmit,
  onCancel,
}: SidePanelShortcutDialogProps) => {
  const { isDark } = useLightOrDarkMode();
  const { slug } = useOrganization();

  const [text, setText] = useState<string>(initialText || '');
  const [targetSidePanel, setTargetSidePanel] = useState<
    MeetingPlanPanelContext['type'] | undefined
  >(initialTargetSidePanel);

  const { allConfigurations: externalServiceConfigurations } =
    useExternalServiceConfigurations();

  const dropdownOptions = useMemo(() => {
    return [
      {
        key: 'insights',
        text: 'Overview',
      },
      // {
      //   key: 'contacts',
      //   text: 'Contacts',
      // },
      // {
      //   key: 'companies',
      //   text: 'Companies',
      // },
      {
        key: 'plans',
        text: 'Related Meetingflows',
      },
      {
        key: 'timeline',
        text: 'Meetingflow Timeline',
      },
      {
        key: 'chatbot',
        text: 'AI Chat',
      },
      // {
      //   key: 'follow-up',
      //   text: 'Follow-up',
      // },
      {
        key: 'email-followup',
        text: 'Email Followup',
      },
      {
        key: 'internal-summary',
        text: 'Email Internal Summary',
      },
      {
        key: 'invite-and-notify',
        text: 'Invite and Notiffy',
      },
      ['panda-ai', 'donotdelete'].includes(slug || '')
        ? {
            key: 'crm-browser',
            text: 'CRM',
          }
        : undefined,
      externalServiceConfigurations.some((c) => c.app === 'SALESFORCE')
        ? {
            key: 'salesforce-browser',
            text: 'Salesforce',
          }
        : undefined,
      externalServiceConfigurations.some((c) => c.app === 'HUBSPOT')
        ? {
            key: 'hubspot-browser',
            text: 'HubSpot',
          }
        : undefined,
      ['panda-ai', 'donotdelete'].includes(slug || '')
        ? {
            key: 'chat',
            text: 'Slack or Teams',
          }
        : undefined,
      externalServiceConfigurations.some((c) => c.app === 'SLACK')
        ? {
            key: 'slack',
            text: 'Slack',
          }
        : undefined,
      externalServiceConfigurations.some((c) => c.app === 'TEAMS')
        ? {
            key: 'ms-teams',
            text: 'Microsoft Teams',
          }
        : undefined,
    ].filter(Truthy) satisfies IDropdownOption[];
  }, [externalServiceConfigurations, slug]) as unknown as (IDropdownOption & {
    key: MeetingPlanPanelContext['type'];
  })[];

  useEffect(() => {
    setText(initialText || '');
    setTargetSidePanel(initialTargetSidePanel);
  }, [initialText, initialTargetSidePanel]);

  const textFieldRef = useRef<ITextField>(null);
  const targetSidePanelRef = useRef<IDropdown>(null);

  useEffect(() => {
    // Effects run after the render pass, but before the DOM has necessarily finished updating, so the refs may be null
    // Use set immediate to execute after one tick, so the DOM has finished updating and the refs are bound
    setImmediate(() => {
      if (!initialText && textFieldRef.current) {
        textFieldRef.current.focus();
      } else if (targetSidePanelRef.current) {
        targetSidePanelRef.current.focus();
      }
    });
  }, [initialText, textFieldRef, targetSidePanelRef]);

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter' && !!text && !!targetSidePanel) {
        onSubmit({
          text,
          sidePanel: targetSidePanel,
        });
      }
    },
    [text, targetSidePanel, onSubmit],
  );

  return (
    <Dialog
      dialogContentProps={{
        title: !!initialTargetSidePanel
          ? 'Update Panel Shortcut'
          : 'Insert Panel Shortcut',
        showCloseButton: true,
        styles: {},
      }}
      hidden={false}
      styles={{ main: { width: '60%' } }}
      onDismiss={onCancel}
    >
      <div>
        <TextField
          label="Label"
          value={text}
          componentRef={textFieldRef}
          onKeyDown={handleKeyPress}
          onChange={(_e, newText) => setText(newText || '')}
        />

        <Dropdown
          label="Side Panel"
          selectedKey={targetSidePanel}
          componentRef={targetSidePanelRef}
          onKeyDown={handleKeyPress}
          options={dropdownOptions}
          onChange={(_e, opt) =>
            opt
              ? setTargetSidePanel(opt.key as MeetingPlanPanelContext['type'])
              : setTargetSidePanel(undefined)
          }
        />
      </div>
      <DialogFooter>
        <PrimaryButton
          text="Confirm"
          disabled={!text || !targetSidePanel}
          onClick={() => {
            onSubmit({
              text,
              sidePanel: targetSidePanel,
            });
          }}
          styles={{
            label: { color: isDark ? 'white' : undefined },
          }}
        />
        <DefaultButton text="Cancel" onClick={onCancel} />
      </DialogFooter>
    </Dialog>
  );
};

export const useSidePanelShortcutDialog = () => {
  const { createDeferred, deferred, context, resolve, reject } =
    useDeferredPromise<
      SidePanelShortcutContext,
      Partial<SidePanelShortcutContext> | undefined
    >();

  const dialog = useMemo(() => {
    if (!deferred?.isPending) {
      return null;
    }

    return (
      <SidePanelShortcutDialog
        initialText={context?.text}
        initialTargetSidePanel={
          context?.sidePanel as MeetingPlanPanelContext['type']
        }
        onSubmit={resolve}
        onCancel={reject}
      />
    );
  }, [context, deferred?.isPending, reject, resolve]);

  return {
    createDeferred,
    dialog,
  };
};
