import {
  FontSizes,
  FontWeights,
  Icon,
  Link,
  mergeStyles,
  NeutralColors,
  Text,
} from '@fluentui/react';
import {
  DetailedMeetingflow,
  ExternalServiceObjectType,
} from '@meetingflow/common/Api/data-contracts';
import { WithRequiredProperty } from '@meetingflow/common/TypeHelpers';
import { orderBy } from 'lodash';
import { useMemo } from 'react';
import { useExternalServiceConfigurations } from '../../../../Hooks/useExternalServiceConfigurations';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import { useOrganization } from '../../../../Hooks/useOrganization';
import SFAccountIcon from '../../../../Static/Images/salesforce/account_icon.png';
import SFLeadIcon from '../../../../Static/Images/salesforce/lead_icon.png';
import SFOpportunityIcon from '../../../../Static/Images/salesforce/opportunity_icon.png';
import { MEETINGFLOW_COLORS } from '../../../../Themes/Themes';
import { SalesforcePanelContext } from '../../../../types/SalesforcePanelContext';
import { AsyncLink } from '../../../HOC/AsyncLink';
import StyledDateTime from '../../../StyledDateTime';
import { BaseSidePanel } from '../BaseSidePanel';
import { SalesforceSidePanelAccountContent } from './SalesforceSidePanelAccountContent';
import { SalesforceSidePanelLeadContent } from './SalesforceSidePanelLeadContent';
import { SalesforceSidePanelOpportunityContent } from './SalesforceSidePanelOpportunityContent';
import { SalesforceSidePanelContactContent } from './SalesforceSidePanelContactContent';

export type SalesforceObjectSidePanelProps = {
  organizationSlug: string;
  meetingPlan?: Pick<
    DetailedMeetingflow,
    'id' | 'attendees' | 'associations' | 'userActivity'
  >;
  salesforceContext: SalesforcePanelContext;
  pushSalesforcePanel: (context: SalesforcePanelContext) => void;
  showObjectPicker: (defaultTab?: ExternalServiceObjectType) => void;
  onBack?: () => void;
  onClose?: () => void;
  onLogCall?: (
    object: WithRequiredProperty<
      Omit<SalesforcePanelContext, 'serviceInstanceId'>,
      'objectType' | 'objectId'
    >,
  ) => void;
  onTimelineClick?: () => void;
  defaultNewOppName?: string;
};
export const SalesforceObjectSidePanel = ({
  organizationSlug,
  meetingPlan,
  salesforceContext,
  showObjectPicker,
  onBack,
  onClose,
  pushSalesforcePanel,
  onLogCall,
  defaultNewOppName = '',
  onTimelineClick,
}: SalesforceObjectSidePanelProps) => {
  const { isDark } = useLightOrDarkMode();
  const {
    configurationsWithToken,
    // loading: salesforceConfigurationsLoading,
    configurationById,
  } = useExternalServiceConfigurations({ app: 'SALESFORCE', withToken: true });

  const { hasEntitlement } = useOrganization();

  const { objectId, objectType, serviceConfigurationId } = salesforceContext;
  const objectName = useMemo(() => {
    switch (objectType) {
      case 'ACCOUNT': {
        return 'Account';
      }
      case 'DEAL': {
        return 'Opportunity';
      }
      case 'CONTACT': {
        return 'Contact';
      }
      case 'LEAD': {
        return 'Lead';
      }
      default: {
        return 'Object';
      }
    }
  }, [objectType]);

  const activeSalesforceConfigurationId =
    serviceConfigurationId || configurationsWithToken?.[0]?.id;

  const serviceInstanceId = configurationById(
    activeSalesforceConfigurationId,
  )?.instanceId;

  const lastLoggedToCRM = useMemo(() => {
    return orderBy(
      meetingPlan?.userActivity?.filter(
        (a) =>
          a.type.toString() === 'LOG_MEETING_TO_CRM' &&
          a.additionalDetails.app === 'SALESFORCE',
      ),
      ['createdAt'],
      ['desc'],
    )?.[0];
  }, [meetingPlan?.userActivity]);

  const lastLoggedToCRMBy = meetingPlan?.attendees.find(
    (a) => a.email === lastLoggedToCRM?.user?.email,
  );

  const lastLoggedBanner = (
    <>
      {lastLoggedToCRM ? (
        <Text
          block
          style={{
            backgroundColor: MEETINGFLOW_COLORS.orange,
            padding: '.5rem',
            borderRadius: '.25rem',
            margin: '.5rem',
            fontSize: FontSizes.small,
            color: isDark ? undefined : 'white',
          }}
        >
          This meeting was logged to Salesforce{' '}
          <StyledDateTime
            dateTime={lastLoggedToCRM.createdAt}
            displayComponents={['date']}
          />{' '}
          by{' '}
          {lastLoggedToCRMBy?.name ||
            lastLoggedToCRMBy?.email ||
            `Unknown User`}
          .
          {onTimelineClick ? (
            <Link
              onClick={onTimelineClick}
              style={{
                width: 'auto',
                display: 'inline-block',
                margin: '0 .5rem',
                color: isDark
                  ? MEETINGFLOW_COLORS.purpleDarkest
                  : MEETINGFLOW_COLORS.purpleGrey,
                fontWeight: FontWeights.semibold,
                textDecoration: 'underline',
              }}
            >
              View full timeline
            </Link>
          ) : null}
        </Text>
      ) : null}
    </>
  );

  let sidebarTitle: string | React.ReactNode = 'Salesforce';

  const createNewAccUrl = configurationsWithToken[0]?.instanceId
    ? `${configurationsWithToken[0]?.instanceId}/lightning/o/Account/new`
    : undefined;

  const createNewOppUrl = configurationsWithToken[0]?.instanceId
    ? `${configurationsWithToken[0]?.instanceId}/lightning/o/Opportunity/new?defaultFieldValues=Name=${defaultNewOppName}`
    : undefined;

  const createNewContactUrl = configurationsWithToken[0]?.instanceId
    ? `${configurationsWithToken[0]?.instanceId}/lightning/o/Contact/new`
    : undefined;

  const createNewLeadUrl = configurationsWithToken[0]?.instanceId
    ? `${configurationsWithToken[0]?.instanceId}/lightning/o/Lead/new`
    : undefined;

  const salesforceLinkClass = mergeStyles({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '.5rem',
    fontWeight: FontWeights.semibold,
  });

  const salesforceIconClass = mergeStyles({
    height: '2rem',
    width: '2rem',
    borderRadius: '1rem',
    display: 'inline-block',
    position: 'relative',
    marginRight: '.5rem',
    img: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '2rem',
      width: '2rem',
      display: 'block',
    },
  });

  const actions = (
    <div
      style={{
        position: 'sticky',
        display: 'flex',
        bottom: '0',
        left: '0',
        width: 'calc(100% - .5rem)',
        boxSizing: 'border-box',
        paddingBottom: '.5rem',
        backgroundColor: isDark
          ? NeutralColors.gray210
          : MEETINGFLOW_COLORS.purpleUltraSuperLightish,
      }}
    >
      <div>
        {!!objectId && !!objectType && onLogCall ? (
          <AsyncLink
            onClick={async () => {
              return onLogCall({
                name: salesforceContext?.name,
                objectId,
                objectType,
                serviceConfigurationId,
              });
            }}
            className={salesforceLinkClass}
            as={'a'}
          >
            <div
              className={salesforceIconClass}
              style={{ backgroundColor: MEETINGFLOW_COLORS.teal }}
            >
              <Icon
                style={{
                  color: 'white',
                  lineHeight: '2rem',
                  width: '2rem',
                  textAlign: 'center',
                  fontSize: '1.25rem',
                  display: 'inline-block',
                  margin: '0 auto',
                }}
                iconName="LightningBolt"
              />
            </div>
            <span>Log Meeting to this {objectName}</span>
          </AsyncLink>
        ) : null}
        <Link
          className={salesforceLinkClass}
          href={createNewAccUrl}
          target="_blank"
          as={'a'}
        >
          <div
            className={salesforceIconClass}
            style={{ backgroundColor: '#7F8DE1' }}
          >
            <img alt="Create New Account" src={SFAccountIcon} />
          </div>
          <span>Create New Account</span>
        </Link>
        <Link
          className={salesforceLinkClass}
          href={createNewOppUrl}
          target="_blank"
          as={'a'}
        >
          <div
            className={salesforceIconClass}
            style={{ backgroundColor: '#FCB95B' }}
          >
            <img alt="Create New Opportunity" src={SFOpportunityIcon} />
          </div>
          <span>Create New Opportunity</span>
        </Link>
        {hasEntitlement('SALESFORCE_LEADS') ? (
          <Link
            className={salesforceLinkClass}
            href={createNewLeadUrl}
            target="_blank"
            as={'a'}
          >
            <div
              className={salesforceIconClass}
              style={{ backgroundColor: '#F88962' }}
            >
              <img alt="Create New Lead" src={SFLeadIcon} />
            </div>
            <span>Create New Lead</span>
          </Link>
        ) : null}
      </div>
    </div>
  );

  const panelContent = (() => {
    const panelContentStyle = mergeStyles({
      position: 'relative',
      height: 'auto',
      overflow: 'hidden',
    });

    if (
      activeSalesforceConfigurationId &&
      !configurationById(activeSalesforceConfigurationId)
    ) {
      return (
        <div className={panelContentStyle}>
          <Text
            variant="large"
            block
            style={{
              textAlign: 'center',
              margin: '1rem 0',
            }}
          >
            <span style={{ fontWeight: FontWeights.semibold }}>
              This Meetingflow is associated with object(s) from a Salesforce
              instance you do not to have access to.
            </span>
            <div>Associated Salesforce instance: {serviceInstanceId}</div>
          </Text>
        </div>
      );
    }

    if (
      activeSalesforceConfigurationId &&
      objectId &&
      objectType === 'ACCOUNT'
    ) {
      sidebarTitle = (
        <span>
          Salesforce Account{' '}
          <Link
            style={{
              fontSize: FontSizes.small,
              display: 'block',
              paddingLeft: onBack ? '1.75rem' : undefined,
            }}
            onClick={() => showObjectPicker('ACCOUNT')}
          >
            Browse Accounts
          </Link>
        </span>
      );
      return (
        <div className={panelContentStyle}>
          {lastLoggedBanner}
          <SalesforceSidePanelAccountContent
            key={objectId}
            organizationSlug={organizationSlug}
            meetingPlanId={meetingPlan?.id}
            associatedWithPlan={meetingPlan?.associations?.some(
              (association) => association.externalId === objectId,
            )}
            salesforceConfigurationId={activeSalesforceConfigurationId}
            salesforceAccountId={objectId}
            pushSalesforcePanel={pushSalesforcePanel}
            showObjectPicker={showObjectPicker}
          />
          {actions}
        </div>
      );
    }

    if (activeSalesforceConfigurationId && objectId && objectType === 'DEAL') {
      sidebarTitle = (
        <span>
          Salesforce Opportunity{' '}
          <Link
            style={{
              fontSize: FontSizes.small,
              display: 'block',
              paddingLeft: onBack ? '1.75rem' : undefined,
            }}
            onClick={() => showObjectPicker('DEAL')}
          >
            Browse Opportunities
          </Link>
        </span>
      );
      return (
        <div className={panelContentStyle}>
          {lastLoggedBanner}
          <SalesforceSidePanelOpportunityContent
            key={objectId}
            organizationSlug={organizationSlug}
            meetingPlanId={meetingPlan?.id}
            associatedWithPlan={meetingPlan?.associations?.some(
              (association) => association.externalId === objectId,
            )}
            salesforceConfigurationId={activeSalesforceConfigurationId}
            salesforceOpportunityId={objectId}
            pushSalesforcePanel={pushSalesforcePanel}
            showObjectPicker={showObjectPicker}
            defaultNewOppName={defaultNewOppName}
          />
          {actions}
        </div>
      );
    }

    if (
      activeSalesforceConfigurationId &&
      objectId &&
      objectType === 'CONTACT'
    ) {
      sidebarTitle = (
        <span>
          Salesforce Contact{' '}
          <Link
            style={{
              fontSize: FontSizes.small,
              display: 'block',
              paddingLeft: onBack ? '1.75rem' : undefined,
            }}
            onClick={() => showObjectPicker('CONTACT')}
          >
            Browse Contacts
          </Link>
        </span>
      );
      return (
        <div className={panelContentStyle}>
          {lastLoggedBanner}
          <SalesforceSidePanelContactContent
            key={objectId}
            organizationSlug={organizationSlug}
            meetingPlanId={meetingPlan?.id}
            associatedWithPlan={meetingPlan?.associations?.some(
              (association) => association.externalId === objectId,
            )}
            salesforceConfigurationId={activeSalesforceConfigurationId}
            salesforceContactId={objectId}
            pushSalesforcePanel={pushSalesforcePanel}
            showObjectPicker={showObjectPicker}
          />
          {actions}
        </div>
      );
    }

    if (activeSalesforceConfigurationId && objectId && objectType === 'LEAD') {
      sidebarTitle = (
        <span>
          Salesforce Lead{' '}
          <Link
            style={{
              fontSize: FontSizes.small,
              display: 'block',
              paddingLeft: onBack ? '1.75rem' : undefined,
            }}
            onClick={() => showObjectPicker('LEAD')}
          >
            Browse Leads
          </Link>
        </span>
      );
      return (
        <div className={panelContentStyle}>
          {lastLoggedBanner}
          <SalesforceSidePanelLeadContent
            key={objectId}
            organizationSlug={organizationSlug}
            meetingPlanId={meetingPlan?.id}
            associatedWithPlan={meetingPlan?.associations?.some(
              (association) => association.externalId === objectId,
            )}
            salesforceConfigurationId={activeSalesforceConfigurationId}
            salesforceLeadId={objectId}
            pushSalesforcePanel={pushSalesforcePanel}
            showObjectPicker={showObjectPicker}
          />
          {actions}
        </div>
      );
    }
  })();

  return (
    <BaseSidePanel
      title={sidebarTitle}
      onBack={onBack}
      onClose={onClose}
      loading={false}
    >
      {panelContent}
    </BaseSidePanel>
  );
};
