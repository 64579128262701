import { FontWeights, mergeStyles, useTheme } from '@fluentui/react';
import { useCallback, useState } from 'react';
import { externalize } from '../../../Helpers/URLHelpers';
import { Chip } from '../../Chip';
import { useAddResourceDialog } from '../../../Hooks/Modals/useAddResourceDialog';
import { useAuth0 } from '@auth0/auth0-react';
import saveAs from 'file-saver';
import toast from 'react-hot-toast';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { isAxiosErrorResponse } from '../../../Helpers/AxiosHelpers';
import { Resource } from '@meetingflow/common/Api/data-contracts';
import { AddResourceChip } from '../../MeetingPlans/AddResourceChip';

type CompanyResourcesProps = {
  organizationSlug: string;
  companyId?: number;
  resources: Resource[];
  showAdd?: boolean;
  onAdd: (item: Resource) => void;
  onUpdate: (idx: number | string, props: Partial<Resource>) => void;
  onDelete: (idx: number | string) => void;
};

export const CompanyResources = ({
  organizationSlug,
  companyId,
  resources,
  showAdd,
  onAdd,
  onUpdate,
  onDelete,
}: CompanyResourcesProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const theme = useTheme();
  const appInsights = useAppInsightsContext();
  const { createDeferred: showAddResourceDialog, dialog: addResourceDialog } =
    useAddResourceDialog({
      organizationSlug,
    });

  const [draggingFile, setDraggingFile] = useState(false);

  const containerStyle = mergeStyles({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'space-around',
    gap: '0.1rem 0.25rem',
  });

  const dropTargetStyle = mergeStyles({
    height: '1.5rem',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.1rem 0.25rem',
    borderStyle: 'dashed',
    borderWidth: '3px',
    userSelect: 'none',
    WebkitTouchCallout: 'none',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    fontWeight: FontWeights.semibold,
    borderColor: theme.palette.themePrimary,
    color: theme.palette.themePrimary,
  });

  const onDragEnter = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDraggingFile(true);
  }, []);

  const onDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDraggingFile(true);
  }, []);

  const onDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDraggingFile(false);
  }, []);

  const onDragEnd = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDraggingFile(false);
  }, []);

  const onDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (e.dataTransfer.files.length) {
        const file = e.dataTransfer.files[0];
        showAddResourceDialog(file)
          .promise.then((item) => onAdd(item))
          .catch((err) => {});
      }

      setDraggingFile(false);
    },
    [onAdd, showAddResourceDialog],
  );

  if (draggingFile) {
    return (
      <div
        className={dropTargetStyle}
        onDragEnter={showAdd ? onDragEnter : undefined}
        onDragLeave={showAdd ? onDragLeave : undefined}
        onDragOver={showAdd ? onDragOver : undefined}
        onDragEnd={showAdd ? onDragEnd : undefined}
        onDrop={showAdd ? onDrop : undefined}
      >
        Add file to plan
      </div>
    );
  }

  return (
    <>
      <div
        key={companyId}
        className={containerStyle}
        onDragEnter={showAdd ? onDragEnter : undefined}
        onDragLeave={showAdd ? onDragLeave : undefined}
        onDragOver={showAdd ? onDragOver : undefined}
        onDragEnd={showAdd ? onDragEnd : undefined}
        onDrop={showAdd ? onDrop : undefined}
      >
        {resources.map((resource, idx) => {
          return (
            <Chip
              key={resource.id || idx}
              type={resource.type}
              text={resource.title}
              mimeType={
                resource.type === 'file' ? resource.mimeType : undefined
              }
              onClick={() => {
                if (resource.type === 'file') {
                  getAccessTokenSilently().then(async (token) => {
                    const headers = new Headers();
                    headers.append('Authorization', `Bearer ${token}`);
                    if (resource.mimeType) {
                      headers.append('Accept', resource.mimeType);
                    }
                    const fetchPromise = fetch(resource.url, {
                      method: 'GET',
                      headers,
                    });

                    toast.promise(fetchPromise, {
                      loading: 'Downloading file',
                      success: 'Successfully downloaded file',
                      error: 'Something went wrong downloading file',
                    });

                    const response = await fetchPromise;
                    const blob = await response.blob();
                    saveAs(blob, resource.name);
                  });
                } else {
                  window.open(externalize(resource.url), '_blank');
                }
              }}
              onDelete={
                showAdd
                  ? () => {
                      onDelete(resource.id || idx);
                      if (resource.type === 'file') {
                        getAccessTokenSilently()
                          .then((token) => {
                            const headers = new Headers();
                            headers.append('Authorization', `Bearer ${token}`);
                            return fetch(resource.url, {
                              method: 'DELETE',
                              headers,
                            });
                          })
                          .catch((err) => {
                            appInsights.trackEvent({
                              name: `DELETE_PLAN_FILE_RESOURCE_FAILED`,
                              properties: {
                                organizationSlug,
                                resource: resource.id,
                                status:
                                  isAxiosErrorResponse(err) &&
                                  err.response?.status,
                                statusText:
                                  isAxiosErrorResponse(err) &&
                                  err.response?.statusText,
                              },
                            });
                          });
                      }
                    }
                  : undefined
              }
            />
          );
        })}
        {showAdd ? (
          <AddResourceChip
            headlineText="Add resources to this company"
            iconOnly
            onClick={() => {
              showAddResourceDialog()
                .promise.then((item) => onAdd(item))
                .catch((err) => {});
            }}
          />
        ) : null}
      </div>
      {addResourceDialog}
    </>
  );
};
