import { useAuth0 } from '@auth0/auth0-react';
import {
  TooltipHost,
  DirectionalHint,
  NeutralColors,
  FontWeights,
  BaseButton,
  Button,
} from '@fluentui/react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import useBreakpoints from '../../Hooks/useBreakpoints';
import { useModifierAwareNavigate } from '../../Hooks/useModifierAwareNavigate';
import { useOrganization } from '../../Hooks/useOrganization';
import {
  MeetingPlanQuery,
  OrganizationMeetingPlansQuery,
  OrganizationMeetingsHappeningSoon,
  OrganizationUpcomingMeetings,
} from '../../QueryNames';
import {
  DealRoomsApiClient,
  MeetingflowsApiClient,
  TextClient,
} from '../../Services/NetworkCommon';
import { AsyncDefaultButton } from '../HOC/AsyncButton';
import { AsyncLink } from '../HOC/AsyncLink';
import { useUserProfile } from '../../Hooks/useProfile';
import { isAxiosErrorResponse } from '../../Helpers/AxiosHelpers';
import { CalendarEvent } from '@meetingflow/common/Api/data-contracts';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import useCreateMeetingflowModal, {
  CreateMeetingflowModalOptions,
  CreateMeetingflowResult,
} from '../../Hooks/Modals/useNewMeetingflowDialog';
import { DSButton } from '../DealRoom/DS';
import { useDealRoom } from '../../Hooks/useDealRoom';
import useDSCreateMeetingflowModal from '../../Hooks/Modals/useDSNewMeetingflowDialog';

export type DecisionSiteMeetingPlanViewCreateButtonProps = {
  organizationSlug: string;
  event?: Pick<
    CalendarEvent,
    'externalId' | 'source' | 'meetingplanId' | 'title' | 'startTime'
  >;
  hideTextLabel?: boolean;
  noTooltip?: boolean;
  onClickDisabled?: boolean; // Allow us to disconnect the onClick handler because it's being handled by the parent in some cases
  onSuccess?: () => void;
};

export const DecisionSiteMeetingPlanViewCreateButton = ({
  organizationSlug,
  event,
  hideTextLabel,
  noTooltip,
  onSuccess,
  onClickDisabled,
}: DecisionSiteMeetingPlanViewCreateButtonProps) => {
  const { canCreatePlans } = useOrganization();
  const appInsights = useAppInsightsContext();
  const breakpoints = useBreakpoints();
  const { isDark } = useLightOrDarkMode();
  const navigate = useModifierAwareNavigate();
  const client = useQueryClient();
  const { getAccessTokenSilently, user } = useAuth0();
  const { userId, user: userProfile } = useUserProfile();
  const { organization, refetchBackground: refetchOrgRole } =
    useOrganization(organizationSlug);
  const { dealRoomId } = useDealRoom();

  const useNewMeetingflowDialog =
    userProfile?.preferenceNewMeetingflowDialog === 'ENABLED';

  const {
    createDeferred: createMeetingflowDeferred,
    dialog: createMeetingflowDialog,
  } = useDSCreateMeetingflowModal({ event: event as CalendarEvent });

  const buttonDisabled = event
    ? !event?.meetingplanId && !canCreatePlans
    : !canCreatePlans;

  const requestCreatorMembership = async () => {
    const token = await getAccessTokenSilently();

    const result = await toast.promise(
      TextClient.put(
        `/organization/${organizationSlug}/member/${userId}/role`,
        'CREATOR',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          validateStatus: (status) => [200, 201, 409].includes(status),
        },
      ),
      {
        loading: 'Requesting an upgrade to the creator role',
        success: (r) => {
          switch (r.status) {
            case 200: {
              return `Your account has been upgraded to the creator role`;
            }
            case 201: {
              return `A request for your ${
                organization?.name || organizationSlug
              } account to be upgraded to the Creator role has been sent to your workspace admin.`;
            }
            // Conflict, already exists
            case 409: {
              return `There is already a pending request to upgrade your role to Creator.`;
            }
            default: {
              return ``;
            }
          }
        },
        error: (err) => {
          appInsights.trackEvent({
            name: 'REQUEST_CREATOR_ROLE_FAILED',
            properties: {
              organizationId: organization?.id,
              status: isAxiosErrorResponse(err)
                ? err.response?.status
                : undefined,
              statusText: isAxiosErrorResponse(err)
                ? err.response?.statusText
                : undefined,
            },
          });

          return `Something went wrong requesting a creator role, please try again`;
        },
      },
    );

    switch (result.status) {
      case 200: {
        await refetchOrgRole();
        appInsights.trackEvent({
          name: 'REQUEST_CREATOR_ROLE',
          properties: {
            organizationSlug,
            email: user?.email,
            role: 'CREATOR',
          },
        });
        break;
      }
      case 201: {
        appInsights.trackEvent({
          name: 'REQUEST_CREATOR_ROLE',
          properties: {
            organizationSlug,
            email: user?.email,
            role: 'CREATOR',
          },
        });
        break;
      }
      // Conflict, already exists
      case 409:
      default: {
      }
    }
  };

  const Button = (
    <DSButton
      id="meetingflow-view-create-button"
      //   size={48}
      className={
        !event?.meetingplanId
          ? 'meeting-plan-create-button'
          : 'meeting-plan-view-button'
      }
      //   styles={{
      //     root: {
      //       padding: 0,
      //       minWidth: 'unset',
      //       width: '100%',
      //       backgroundColor: event?.meetingplanId
      //         ? isDark
      //           ? NeutralColors.gray160
      //           : MEETINGFLOW_COLORS.purpleGrey
      //         : isDark
      //           ? MEETINGFLOW_COLORS.purpleSecondary
      //           : MEETINGFLOW_COLORS.purpleTertiary,
      //       borderRadius: '1rem',
      //       border: 'none',
      //       transition: '.3s ease-in-out all',
      //     },
      //     rootFocused: {
      //       outline: `3px solid ${MEETINGFLOW_COLORS.orange} !important`,
      //       ':after': {
      //         outline: 'none !important',
      //       },
      //     },
      //     rootHovered: {
      //       border: 'none',
      //       '.ms-Button-textContainer': {
      //         color: 'white !important',
      //       },
      //       '.ms-Button-icon': {
      //         color: 'white !important',
      //       },
      //       'span.ms-Button-label': {
      //         color: event?.meetingplanId
      //           ? `${MEETINGFLOW_COLORS.white} !important`
      //           : MEETINGFLOW_COLORS.white,
      //       },
      //       backgroundColor: event?.meetingplanId
      //         ? MEETINGFLOW_COLORS.purpleSecondary
      //         : isDark
      //           ? MEETINGFLOW_COLORS.purplePrimary
      //           : MEETINGFLOW_COLORS.purpleSecondary,
      //     },
      //     flexContainer: {
      //       margin: '0 .25rem 0 .25rem',
      //     },
      //     icon: {
      //       position: 'relative',
      //       left: !hideTextLabel
      //         ? breakpoints.lg
      //           ? '-3px'
      //           : !breakpoints.md
      //             ? '3px'
      //             : undefined
      //         : undefined,
      //       fontSize: '20px',
      //       fontWeight: 500,
      //       color: event?.meetingplanId
      //         ? isDark
      //           ? NeutralColors.gray50
      //           : MEETINGFLOW_COLORS.purpleSecondary
      //         : MEETINGFLOW_COLORS.white,
      //     },
      //     textContainer: {
      //       display:
      //         !breakpoints.lg || hideTextLabel
      //           ? 'none !important'
      //           : !breakpoints.md
      //             ? 'inline'
      //             : 'inline-block',
      //       paddingRight: !hideTextLabel ? '.5rem' : undefined,
      //       'span.ms-Button-label': {
      //         color: buttonDisabled
      //           ? NeutralColors.gray120
      //           : event?.meetingplanId
      //             ? `${
      //                 isDark
      //                   ? NeutralColors.gray50
      //                   : MEETINGFLOW_COLORS.purpleSecondary
      //               } !important`
      //             : MEETINGFLOW_COLORS.white,
      //       },
      //       color: event?.meetingplanId
      //         ? MEETINGFLOW_COLORS.purpleDark
      //         : MEETINGFLOW_COLORS.white,
      //       textAlign: 'left',
      //     },
      //   }}
      //   primary={!event?.meetingplanId}
      disabled={buttonDisabled}
      variant="contained"
      //   disableOnExecute={event ? !event?.meetingplanId : true}
      //   renderSpinnerOnExecute={event ? !event?.meetingplanId : false}
      //   executeText={
      //     event
      //       ? !event?.meetingplanId
      //         ? 'Creating'
      //         : undefined
      //       : 'Creating Meetingflow'
      //   }
      //   text={breakpoints.md ? (event?.meetingplanId ? 'View' : 'Create') : ''}
      //   iconProps={{
      //     iconName: !event?.meetingplanId
      //       ? 'NewAnalyticsQuery'
      //       : 'AnalyticsQuery',
      //   }}
      onClick={
        onClickDisabled
          ? async () => {}
          : useNewMeetingflowDialog
            ? async () => createMeetingflowDeferred()
            : async (e) => {
                e.stopPropagation();
                if (!!event?.meetingplanId) {
                  navigate(
                    `/organization/${organizationSlug}/plan/dealroom/${dealRoomId}/plan/${event.meetingplanId}`,
                    e,
                  );
                } else {
                  const token = await getAccessTokenSilently();

                  let reqBody = {};
                  if (event) {
                    reqBody = {
                      source: event.source,
                      eventId: event.externalId,
                      eventTime: event.startTime,
                    };
                  }

                  await toast.promise(
                    MeetingflowsApiClient.postMeetingflow(
                      organizationSlug,
                      reqBody,
                      {
                        headers: { Authorization: `Bearer ${token}` },
                        validateStatus: (code) => code === 201 || code === 302,
                      },
                    ),
                    {
                      loading: 'Creating Meetingflow',
                      success: (result) => {
                        Promise.all([
                          client.invalidateQueries(
                            OrganizationMeetingPlansQuery(
                              organizationSlug,
                              '',
                              undefined,
                              undefined,
                              dealRoomId,
                            ),
                          ),
                          client.invalidateQueries(
                            OrganizationMeetingsHappeningSoon(organizationSlug),
                          ),
                          client.invalidateQueries(
                            OrganizationUpcomingMeetings(organizationSlug),
                          ),
                        ]);

                        client.setQueryData(
                          MeetingPlanQuery(organizationSlug, result.data.id),
                          result,
                        );

                        // navigate(
                        //   `/organization/${organizationSlug}/plan/dealroom/${dealRoomId}/plan/${result.data.id}`,
                        //   e,
                        // );

                        if (result.status === 201) {
                          appInsights.trackEvent({
                            name: `CREATE_PLAN${event ? '_FOR_EVENT' : '_ADHOC'}`,
                            properties: {
                              organizationSlug,
                              source: event ? event?.source : '',
                              eventId: event ? event?.externalId : '',
                            },
                          });

                          const newMeetingflow = result.data;

                          if (
                            newMeetingflow.id &&
                            dealRoomId &&
                            organizationSlug
                          ) {
                            toast.promise(
                              DealRoomsApiClient.createLinkArtifact(
                                organizationSlug,
                                dealRoomId,
                                {
                                  type: 'MEETINGFLOW',
                                  meetingflowId: newMeetingflow.id,
                                },
                                {
                                  headers: { Authorization: `Bearer ${token}` },
                                },
                              ),
                              {
                                loading: 'Uploading attachment...',
                                success: (result) => {
                                  //   onAdd(result.data);
                                  navigate(
                                    `/organization/${organizationSlug}/dealroom/${dealRoomId}/plan/${newMeetingflow.id}`,
                                    e,
                                  );
                                  return `Successfully added Meetingflow to Decision Site`;
                                },
                                error: (err) => {
                                  console.error(err);
                                  return `Failed to add Meetingflow to Decision Site`;
                                },
                              },
                            );
                          }

                          return `A Meetingflow ${
                            event ? `for ${event?.title}` : ''
                          } has been created!`;
                        } else if (result.status === 302) {
                          return `A Meetingflow ${
                            event ? `for ${event?.title}` : ''
                          } has been created!`;
                        }

                        onSuccess?.();

                        return null;
                      },
                      error: (err) => {
                        if (err && isAxiosErrorResponse(err, 403)) {
                          return `You don't have permission to create Meetingflows in this workspace`;
                        }

                        appInsights.trackException({
                          exception:
                            err instanceof Error ? err : new Error(err),
                          properties: {
                            organizationSlug,
                            eventSource: event?.source,
                            eventId: event?.externalId,
                            status: isAxiosErrorResponse(err)
                              ? err.response?.status
                              : undefined,
                            statusText: isAxiosErrorResponse(err)
                              ? err.response?.statusText
                              : undefined,
                          },
                        });

                        appInsights.trackEvent({
                          name: 'CREATE_PLAN_FAILED',
                          properties: {
                            organizationSlug,
                            source: event?.source,
                            eventId: event?.externalId,
                            status: isAxiosErrorResponse(err)
                              ? err.response?.status
                              : undefined,
                            statusText: isAxiosErrorResponse(err)
                              ? err.response?.statusText
                              : undefined,
                          },
                        });

                        return 'Something went wrong creating the Meetingflow, please try again';
                      },
                    },
                  );
                }
              }
      }
    >
      {event?.meetingplanId ? 'View' : 'Create'}
    </DSButton>
  );

  if (!noTooltip || !canCreatePlans) {
    return (
      <TooltipHost
        content={
          event?.meetingplanId ? (
            'View the Meetingflow for this Event'
          ) : canCreatePlans ? (
            event ? (
              'Create a Meetingflow for this Event'
            ) : (
              'Create a Meetingflow'
            )
          ) : (
            <>
              <AsyncLink
                style={{
                  textDecoration: 'underline !important',
                  fontWeight: FontWeights.semibold,
                }}
                onClick={requestCreatorMembership}
              >
                Request creator membership
              </AsyncLink>{' '}
              to create Meetingflows
            </>
          )
        }
        id={event?.externalId}
        color="#ffffff"
        directionalHint={DirectionalHint.bottomAutoEdge}
        tooltipProps={{
          delay: 0,
          styles: {
            root: {
              color: NeutralColors.white,
              padding: '1.5rem',

              a: {
                textDecoration: 'underline !important',
              },
            },
          },
        }}
        styles={{
          root: {
            display: 'inline-block',
          },
        }}
        calloutProps={{
          backgroundColor: MEETINGFLOW_COLORS.teal,
          color: 'white !important',
          directionalHint: DirectionalHint.bottomCenter,
          styles: {
            root: {
              '*': {
                color: 'white !important',
                fontWeight: FontWeights.semibold,
              },
              backgroundColor: MEETINGFLOW_COLORS.teal,
            },
            beakCurtain: {
              padding: 0,
              backgroundColor: MEETINGFLOW_COLORS.teal,
            },
          },
        }}
      >
        {Button}
      </TooltipHost>
    );
  }

  return (
    <>
      {Button}
      {createMeetingflowDialog}
    </>
  );
};
