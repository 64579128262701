import { Editor, Element, Node, Path, Range, Text, Transforms } from 'slate';
import { INLINE_TYPE } from '../Helpers/EditorHelpers';
import { SyncAwareEditor } from './withSyncAwareness';

export const withNoEmptyDocument = <T extends Editor>(editor: T) => {
  const { normalizeNode, deleteFragment } = editor;

  editor.deleteFragment = (o) => {
    const { selection } = editor;
    if (selection && Range.equals(Editor.range(editor, []), selection)) {
      Editor.withoutNormalizing(editor, () => {
        Transforms.removeNodes(editor, {
          at: [],
          hanging: true,
          voids: true,
          mode: 'highest',
          match: (n) => !Editor.isEditor(n),
        });
        Transforms.insertNodes(editor, {
          type: 'paragraph',
          children: [{ text: '' }],
        });
      });
      return;
    }
    deleteFragment(o);
  };

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    if (
      Editor.isEditor(node) &&
      (!SyncAwareEditor.isSyncAwareEditor(editor) ||
        SyncAwareEditor.isSynced(editor))
    ) {
      if (node.children.length === 0) {
        console.info(`Inserting empty paragraph into document`);
        Transforms.insertNodes(node, {
          type: 'paragraph',
          children: [{ text: '' }],
        });
        return false;
      }

      const childRefs = Array.from(Node.children(editor, path))
        .filter(([child, _childPath]) => {
          return Text.isText(child);
        })
        .map(([_child, childPath]) => {
          return Editor.pathRef(editor, childPath);
        });

      if (childRefs.length) {
        Editor.withoutNormalizing(editor, () => {
          childRefs.forEach((ref) => {
            const childPath = ref.unref();
            if (childPath) {
              Transforms.wrapNodes(
                editor,
                {
                  type: 'paragraph',
                  children: [],
                },
                {
                  at: childPath,
                  mode: 'lowest',
                  split: true,
                },
              );
            }
          });
        });
        return false;
      }
    }

    if (Element.isElement(node) && node.type === 'paragraph') {
      // If paragraph has any block children, such as paragraphs, or list items, unwrap them
      const childRefs = Array.from(Node.children(editor, path))
        .filter(([child, _childPath]) => {
          return Element.isElement(child) && !INLINE_TYPE.includes(child.type);
        })
        .map(([_child, childPath]) => {
          return Editor.pathRef(editor, childPath);
        });

      if (childRefs.length) {
        Editor.withoutNormalizing(editor, () => {
          childRefs.forEach((ref) => {
            const childPath = ref.unref();
            if (childPath) {
              Transforms.unwrapNodes(editor, {
                at: childPath,
                match: (n, p) => {
                  return (
                    Element.isElement(n) &&
                    n.type === 'paragraph' &&
                    !Path.equals(p, childPath)
                  );
                },
                mode: 'lowest',
                split: true,
              });
            }
          });
        });
        return false;
      }

      // If the paragraph is within another element, unwrap the children
      const [parent] = Editor.parent(editor, path);
      if (Element.isElement(parent) && parent.type !== 'paragraph') {
        Transforms.unwrapNodes(editor, {
          at: path,
          match: (n, p) => {
            return Element.isElement(n) && n.type === 'paragraph';
          },
          mode: 'lowest',
          split: true,
        });
        return false;
      }
    }

    return normalizeNode(entry);
  };

  return editor;
};
