import {
  createTheme as createThemeMUI,
  responsiveFontSizes,
} from '@mui/material/styles';
import { DECISION_SITE_COLORS } from './Colors';

// To use the custom colors in the a stock component with a color prop (like Button) with TS
// we need to augment the ButtonPropsColorOverrides interface. This is only needed for custom colors
// beyond the default MUI colors (primary, secondary, error, etc.), and it's only necessary to add
// the colors that will be used in the app for the component in question (Button, here).
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
      plum: true;
      peach: true;
      cloudburst: true;
      orchid: true;
      peacock: true;
      oink: true;
      bone: true;
      crimson: true;
      frost: true;
      woodsmoke: true;
    }
  }

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobile: true;
    tablet: true;
    laptop: true;
    desktop: true;
  }
  interface Palette {
    oink: Palette['primary'];
    cloudburst: Palette['primary'];
    plum: Palette['primary'];
    peach: Palette['primary'];
    orchid: Palette['primary'];
    peacock: Palette['primary'];
    bone: Palette['primary'];
    crimson: Palette['primary'];
    frost: Palette['primary'];
    woodsmoke: Palette['primary'];
  }
  interface PaletteOptions {
    oink?: PaletteOptions['primary'];
    cloudburst?: PaletteOptions['primary'];
    plum?: PaletteOptions['primary'];
    peach?: PaletteOptions['primary'];
    orchid?: PaletteOptions['primary'];
    peacock?: PaletteOptions['primary'];
    bone?: PaletteOptions['primary'];
    crimson?: PaletteOptions['primary'];
    frost?: PaletteOptions['primary'];
    woodsmoke?: PaletteOptions['primary'];
  }
  interface PaletteColor {
    lighter?: string;
    darker?: string;
  }
  interface SimplePaletteColorOptions {
    lighter?: string;
    darker?: string;
  }
}

let muiLightTheme = createThemeMUI({
  palette: {
    primary: {
      main: DECISION_SITE_COLORS.themePrimary,
    },
    secondary: {
      main: DECISION_SITE_COLORS.themeSecondary,
    },
    plum: {
      main: DECISION_SITE_COLORS.plum100,
    },
    peach: {
      main: DECISION_SITE_COLORS.peach,
    },
    cloudburst: {
      main: DECISION_SITE_COLORS.cloudburst,
    },
    orchid: {
      main: DECISION_SITE_COLORS.orchid,
    },
    peacock: {
      main: DECISION_SITE_COLORS.peacock,
    },
    oink: {
      main: DECISION_SITE_COLORS.oink,
    },
    bone: {
      main: DECISION_SITE_COLORS.bone,
    },
    crimson: {
      main: DECISION_SITE_COLORS.crimson,
    },
    frost: {
      main: DECISION_SITE_COLORS.frost,
    },
    woodsmoke: {
      main: DECISION_SITE_COLORS.woodsmoke,
    },
  },
  typography: {
    fontFamily: 'Matter SQ, sans-serif',
  },
});

const augmentColor = muiLightTheme.palette.augmentColor;

// Update the theme with custom colors variations and component style overrides
muiLightTheme = createThemeMUI({
  palette: {
    ...muiLightTheme.palette,
    // Augment the custom colors with MUI's augmentColor function (generates variants of each color based on the main color)
    peach: augmentColor({
      color: {
        main: DECISION_SITE_COLORS.peach,
      },
      name: 'peach',
    }),
    plum: augmentColor({
      color: {
        main: DECISION_SITE_COLORS.plum100,
      },
      name: 'plum',
    }),
    cloudburst: augmentColor({
      color: {
        main: DECISION_SITE_COLORS.cloudburst,
      },
      name: 'cloudburst',
    }),
    orchid: augmentColor({
      color: {
        main: DECISION_SITE_COLORS.orchid,
      },
      name: 'orchid',
    }),
    peacock: augmentColor({
      color: {
        main: DECISION_SITE_COLORS.peacock,
      },
      name: 'peacock',
    }),
    oink: augmentColor({
      color: {
        main: DECISION_SITE_COLORS.oink,
      },
      name: 'oink',
    }),
    bone: augmentColor({
      color: {
        main: DECISION_SITE_COLORS.bone,
      },
      name: 'bone',
    }),
    crimson: augmentColor({
      color: {
        main: DECISION_SITE_COLORS.crimson,
      },
      name: 'crimson',
    }),
    frost: augmentColor({
      color: {
        main: DECISION_SITE_COLORS.frost,
      },
      name: 'frost',
    }),
    woodsmoke: augmentColor({
      color: {
        main: DECISION_SITE_COLORS.woodsmoke,
      },
      name: 'woodsmoke',
    }),
  },

  // Override the style of stock MUI components, such as a Button
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          textTransform: 'none',
          fontSize: '15px',
        },
        
      },
    },
  },
});

muiLightTheme = responsiveFontSizes(muiLightTheme);

// No dark theme yet, so foir now just make a copy of the light theme
const muiDarkTheme = createThemeMUI({
  ...muiLightTheme,
  colorSchemes: {
    dark: true,
    },

});

const muiThemes = {
    light: muiLightTheme,
    dark: muiDarkTheme,
    };

export { muiThemes };
