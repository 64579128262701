import { mergeStyleSets } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../Themes/Themes';
import { getStatusDropdownBackgroundColor } from '../../Components/DealRoom/Milestones/milestonesUtils';
import { DealRoomActionItemStatus } from '@meetingflow/common/Api/data-contracts';

export const useMilestoneActionItemPanelStyles = (
  status: DealRoomActionItemStatus,
) =>
  mergeStyleSets({
    panel: {
      '.ms-Panel-contentInner': {
        backgroundColor: DEALROOMS_COLORS.financialLighterGray,
      },
    },
    wrapper: {
      gap: '1.5rem',
      '.ms-layer:empty': {
        display: 'none',
      },
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      gap: '.5rem',
      padding: '1.5rem 0 0',
    },
    closeButton: {
      marginRight: 'auto',
    },
    headerButton: {
      i: {
        fontSize: '1.25rem',
        color: DEALROOMS_COLORS.themePrimary,
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1.5rem',
    },
    taskTitle: {
      '.ms-TextField-fieldGroup': {
        borderRadius: '.25rem',
        border: 'none !important',
        height: 'auto',
        minHeight: '5rem',

        '::after': {
          borderRadius: '.25rem',
          border: `1px solid ${DEALROOMS_COLORS.neutralSecondary}`,
        },
      },
      '.ms-Label': {
        fontSize: '0.9375rem',
        lineHeight: '1.5rem',
        fontWeight: '400',
        color: DEALROOMS_COLORS.themePrimary,
      },
      textarea: {
        backgroundColor: DEALROOMS_COLORS.white,
        color: DEALROOMS_COLORS.darkerGray,
        fontSize: '1.5rem',
        fontWeight: '400',
        lineHeight: '2rem',
        borderRadius: '.25rem',
        padding: '.5625rem .875rem',
        height: '5rem',
        maxHeight: '4rem',
        overflowY: 'auto',
        border: `1px solid transparent`,

        ':hover': {
          backgroundColor: DEALROOMS_COLORS.white,
          border: `1px solid ${DEALROOMS_COLORS.themeSecondary}`,
        },

        '::placeholder': {
          color: DEALROOMS_COLORS.themeSecondary,
        },
      },
    },
    taskDescription: {
      '.ms-TextField-fieldGroup': {
        borderRadius: '.25rem',
        border: 'none !important',
        height: 'auto',
        minHeight: '6rem',

        '::after': {
          borderRadius: '.25rem',
          border: `1px solid ${DEALROOMS_COLORS.neutralSecondary}`,
        },
      },
      '.ms-Label': {
        fontSize: '0.9375rem',
        lineHeight: '1.5rem',
        fontWeight: '400',
        color: DEALROOMS_COLORS.themePrimary,
      },
      textarea: {
        backgroundColor: DEALROOMS_COLORS.white,
        color: DEALROOMS_COLORS.darkerGray,
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '1.375rem',
        borderRadius: '.25rem',
        padding: '.5625rem 0.875rem',
        height: '6rem',
        maxHeight: '4rem',
        overflowY: 'auto',

        '::placeholder': {
          color: DEALROOMS_COLORS.themeSecondary,
        },
      },
    },
    dropdownsSection: {
      display: 'flex',
      flexDirection: 'column',
      gap: '.5rem',
    },
    dropdownContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '.5rem',
    },
    dropdownTitle: {
      minWidth: '12rem',
      color: DEALROOMS_COLORS.themePrimary,
      fontSize: '.9375rem',
      lineHeight: '2rem',
    },
    dropdown: {
      '.ms-Label': {
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        fontWeight: '400',
        color: DEALROOMS_COLORS.darkerGray,
      },
      '.ms-Dropdown-title': {
        minWidth: '12rem',
        maxWidth: '12rem',
        border: 'none',
        borderRadius: '.25rem',
        backgroundColor: DEALROOMS_COLORS.white,
      },
    },
    attachmentsContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    attachmentsContainerTitle: {
      lineHeight: '1.75rem',
      fontSize: '.8125rem',
      color: DEALROOMS_COLORS.themePrimary,
    },
    footer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    title: {},
    statusDropdown: {
      '.ms-Dropdown-title': {
        border: 'none',
        borderRadius: '.25rem',
        backgroundColor: getStatusDropdownBackgroundColor(status),
        minWidth: '12rem',
        maxWidth: '12rem',
        fontWeight: '400',
        color: `${DEALROOMS_COLORS.black} !important`,
        display: 'flex',
        alignItems: 'center',
      },
    },
    dueDateElement: {
      minWidth: '12rem',
      maxWidth: '12rem',
      '.ms-TextField-fieldGroup': {
        backgroundColor: DEALROOMS_COLORS.white,
        borderRadius: '.25rem',
      },
    },

    dropdownMenu: {
      dropdownItem: {
        backgroundColor: `${DEALROOMS_COLORS.white}`,
        color: `${DEALROOMS_COLORS.black} !important`,

        '::hover': {
          backgroundColor: `${DEALROOMS_COLORS.neutralLight}`,
        },
      },
      dropdownItemSelected: {
        backgroundColor: `${DEALROOMS_COLORS.themeSecondary}`,
        color: `${DEALROOMS_COLORS.white} !important`,

        '::hover': {
          backgroundColor: `${DEALROOMS_COLORS.themeSecondary}`,
        },
      },
    },
  });
