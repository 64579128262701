import { FontWeights, mergeStyleSets, Text, useTheme } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';

type DropTargetProps = {
  className?: string;
  overlayClassName?: string;
  contentClassName?: string;
  overlayText?: string;
  onDrop: (file: File) => void | Promise<void>;
};

export const DropTarget = ({
  className,
  overlayClassName,
  contentClassName,
  overlayText,
  children,
  onDrop,
}: PropsWithChildren<DropTargetProps>) => {
  const [isDragging, { setTrue: setIsDragging, setFalse: setNotDragging }] =
    useBoolean(false);

  const { palette, fonts } = useTheme();

  const rootRef = useRef<HTMLDivElement>(null);
  const [overlayBounds, setOverlayBounds] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });

  const updateOverlayBounds = () => {
    if (rootRef.current) {
      const rect = rootRef.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;

      const top = Math.max(0, rect.top);
      const left = Math.max(0, rect.left);
      const bottom = Math.min(viewportHeight, rect.bottom);
      const right = Math.min(viewportWidth, rect.right);

      setOverlayBounds({
        top: top - rect.top,
        left: left - rect.left,
        width: right - left,
        height: bottom - top,
      });
    }
  };

  useEffect(() => {
    updateOverlayBounds();

    const handleUpdate = () => {
      if (isDragging) {
        updateOverlayBounds();
      }
    };

    window.addEventListener('resize', handleUpdate);
    window.addEventListener('scroll', handleUpdate, true);

    const rootElement = rootRef.current;

    if (rootElement) {
      rootElement.addEventListener('scroll', handleUpdate);
    }

    return () => {
      window.removeEventListener('resize', handleUpdate);
      window.removeEventListener('scroll', handleUpdate, true);

      if (rootElement) {
        rootElement.removeEventListener('scroll', handleUpdate);
      }
    };
  }, [isDragging]);

  const handleDragEnter = (event: React.DragEvent) => {
    event.preventDefault();
    setIsDragging();
  };

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    setIsDragging();
  };

  const handleDragLeave = (event: React.DragEvent) => {
    event.preventDefault();
    setNotDragging();
  };

  const handleDragEnd = (event: React.DragEvent) => {
    event.preventDefault();
    setNotDragging();
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    setNotDragging();
    if (event.dataTransfer.files.length) {
      const file = event.dataTransfer.files[0];
      onDrop(file);
    }
  };

  const styles = mergeStyleSets({
    root: {
      position: 'relative',
      display: 'flex',
      width: '100%',
      height: '100%',
    },
    overlay: {
      position: 'absolute',
      top: overlayBounds.top,
      left: overlayBounds.left,
      width: overlayBounds.width,
      height: overlayBounds.height,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: fonts.mediumPlus.fontSize,
      borderColor: palette.themePrimary,
      backgroundColor: palette.neutralLighterAlt,
      opacity: 0.9,
      userSelect: 'none',
      WebkitTouchCallout: 'none',
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      msUserSelect: 'none',
      pointerEvents: 'none',

      '& span': {
        color: palette.themePrimary,
        fontWeight: FontWeights.semibold,
      },
    },
    content: {
      position: 'relative',
      width: '100%',
      height: '100%',
      filter: isDragging ? 'blur(5px)' : 'none',
    },
  });

  return (
    <div
      ref={rootRef}
      className={classNames(styles.root, className)}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDragEnd={handleDragEnd}
      onDrop={handleDrop}
    >
      <div className={classNames(styles.content, contentClassName)}>
        {children}
      </div>
      {isDragging ? (
        <div className={classNames(styles.overlay, overlayClassName)}>
          <Text variant="large">{overlayText ?? 'Drop here'}</Text>
        </div>
      ) : null}
    </div>
  );
};
