// DashboardContent component displays a list of decision sites in a table format
// It includes a header with a title and a button to create new decision sites
import {
  DetailsRow,
  IDetailsRowProps,
  mergeStyleSets,
  Text,
} from '@fluentui/react';
import DealRoomAsyncCommandBarButton from '../../Components/DealRoomButton';
import { AddToIconProps } from '../../../../utils/iconProps';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { useAuth0 } from '@auth0/auth0-react';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { useQuery } from 'react-query';
import { OrganizationDealRoomsQuery } from '../../../../QueryNames';
import { DealRoomsApiClient } from '../../../../Services/NetworkCommon';
import { useCallback, useMemo } from 'react';
import { useNavigate } from '../../../../Hooks/useNavigate';
import { DecisionSiteTableList } from '../Components/DecisionSiteTableList';
import { useNewDealRoomDialog } from '../../../../Hooks/Modals/DealRoom/useNewDealRoomDialog';
import toast from 'react-hot-toast';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useUserProfile } from '../../../../Hooks/useProfile';

export const DashboardContent = () => {
  const navigate = useNavigate();

  // Auth and organization context
  const { getAccessTokenSilently } = useAuth0();
  const { slug } = useOrganization();
  const { user } = useUserProfile();

  const appInsights = useAppInsightsContext();

  // Fetch deal rooms data for the organization
  const { data: dealRoomsData, refetch: refetchDealRoomsData } = useQuery(
    OrganizationDealRoomsQuery(slug!),
    async () => {
      const token = await getAccessTokenSilently();
      return DealRoomsApiClient.listDealRooms(
        {
          organizationSlug: slug!,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
  );

  // Dialog hooks
  const {
    createDeferred: createNewDealRoomDeferred,
    dialog: newDealRoomDialog,
  } = useNewDealRoomDialog({
    organizationSlug: slug!,
    refetchDealRoomsData: refetchDealRoomsData,
  });

  // Define table columns configuration
  const columns = useMemo(
    () => [
      {
        key: 'name',
        name: 'Name',
        fieldName: 'name',
        minWidth: 100,
        maxWidth: 300,
      },
      {
        key: 'owner',
        name: 'Owner',
        fieldName: 'owner',
        minWidth: 100,
        maxWidth: 300,
      },
      {
        key: 'description',
        name: 'Description',
        fieldName: 'description',
        minWidth: 100,
      },
    //   {
    //     key: 'companyName',
    //     name: 'Company',
    //     fieldName: 'companyName',
    //     minWidth: 100,
    //     maxWidth: 300,
    //   },
    ],
    [],
  );

  // Transform API data into table items format
  const listItems = useMemo(() => {
    if (!dealRoomsData) return [];
    if (!user) return [];
    if (!('data' in dealRoomsData)) return [];
    if (!Array.isArray(dealRoomsData.data)) return [];

    return dealRoomsData.data.map((dealroom) => ({
      key: dealroom.id,
      ownedByCurrentUser: dealroom.owner?.id === user.id,
      name: dealroom.name,
      owner: dealroom.owner?.name || dealroom.owner?.firstName || '',
      description: dealroom.description,
    }));
  }, [dealRoomsData, user]);

  // Custom row renderer that adds click navigation to each row
  const onRenderRow = useCallback(
    (props?: IDetailsRowProps): JSX.Element => {
      if (!props) return <></>;

      return (
        <div
          onClick={() => {
            navigate(`/organization/${slug}/dealroom/${props.item.key}`);
          }}
          style={{
            cursor: 'pointer',
            width: '100%',
          }}
        >
          <DetailsRow {...props} />
        </div>
      );
    },
    [navigate, slug],
  );

  const handleCreateNewDealRoom = async () => {
    await createNewDealRoomDeferred().promise;
  };


  // Styles for component layout and appearance
  const styles = mergeStyleSets({
    container: {
      '.ms-layer:empty': {
        display: 'none',
      },
    },
    header: {
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      color: DEALROOMS_COLORS.themePrimary,
      fontSize: '2rem',
      lineHeight: '2.5rem',
      fontWeight: '400',
      flex: 1,
    },
    subtitle: {
      color: DEALROOMS_COLORS.themePrimary,
      fontSize: '1rem',
      lineHeight: '2.5rem',
      fontWeight: '400',
      flex: 1,
    },
    addNewButton: {
      padding: '.625rem 1rem',
      lineHeight: '1.25rem',
      fontSize: '0.875rem',
    },
    content: {
      paddingTop: '1.5rem',
    },
  });

  return (
    <div className={styles.container}>
      {/* Header section with title and new decision site button */}
      <div className={styles.header}>
        <Text className={styles.title}>Decision Sites</Text>
        <DealRoomAsyncCommandBarButton
          onClick={handleCreateNewDealRoom}
          text="New Decision Site"
          customClasses={styles.addNewButton}
          buttonStyleType="DEAL_ROOM"
          iconProps={AddToIconProps}
        />
      </div>
      {/* Content section with the table list of decision sites */}
      <div className={styles.content}>
        <Text className={styles.subtitle}>My Decision Sites</Text>
        <DecisionSiteTableList
          items={listItems.filter((item) => item.ownedByCurrentUser)}
          columns={columns}
          onRenderRow={onRenderRow}
        />
      </div>

      <div className={styles.content}>
        <Text className={styles.subtitle}>All Decision Sites</Text>
        <DecisionSiteTableList
          items={listItems}
          columns={columns}
          onRenderRow={onRenderRow}
        />
      </div>
      {newDealRoomDialog}
    </div>
  );
};
