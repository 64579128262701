import { IconButton, mergeStyles, Stack, Image } from '@fluentui/react';
import classNames from 'classnames';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { matchPath, useLocation } from 'react-router';
import { useCallback, useMemo } from 'react';
import { useNavigate } from '../../../Hooks/useNavigate';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { AugmentLogo } from './AugmentLogo';
import { useBoolean } from '@fluentui/react-hooks';
import { isTooDark } from '../../../Helpers/ColorHelpers';
import useDeviceType from '../../../Hooks/deviceDetection';

export type DealRoomBuyersSidebarProps = {
  organizationSlug: string;
  dealRoomId: number;
};

type SidebarLink = {
  label: string;
  path: string;
  iconName: string;
};

const leftSidebarLinks: SidebarLink[] = [
  {
    label: 'Overview',
    path: 'overview',
    iconName: 'GridViewMedium',
  },
  {
    label: 'Artifacts',
    path: 'artifacts',
    iconName: 'FabricFolder',
  },
  {
    label: 'Journey',
    path: 'journey',
    iconName: 'Clock',
  },
  {
    label: 'Mutual Plan',
    path: 'mutual-action-plan',
    iconName: 'TeamFavorite',
  },
  {
    label: 'Settings',
    path: 'settings',
    iconName: 'Settings',
  },
  {
    label: 'Config',
    path: 'config',
    iconName: 'WebComponents',
  },
];

export const DealRoomBuyersSidebar = ({
  organizationSlug,
  dealRoomId,
}: DealRoomBuyersSidebarProps) => {
  const { isMobile } = useDeviceType();

  const [isSidebarCollapsed, { toggle: toggleSidebarCollapse }] =
    useBoolean(true);
  const [isSidebarHidden, { toggle: toggleSidebarHidden }] = useBoolean(true);
  const [isContentHidden, { toggle: toggleContentHidden }] = useBoolean(true);

  const location = useLocation();
  const navigate = useNavigate();

  const { dealRoom, dealRoomRole } = useDealRoom(organizationSlug, dealRoomId);

  const backgroundColor = useMemo(() => {
    if (dealRoom?.primaryColor) {
      return dealRoom.primaryColor;
    } else {
      return DEALROOMS_COLORS.themePrimary;
    }
  }, [dealRoom]);

  const activeTab = useMemo(() => {
    const path = `/organization/:organizationSlug/dealroom/:dealRoomId`;

    if (matchPath(`${path}/artifacts`, location.pathname)) {
      return 'artifacts';
    }

    if (matchPath(`${path}/artifact/:artifactId`, location.pathname)) {
      return 'artifacts';
    }

    if (matchPath(`${path}/journey`, location.pathname)) {
      return 'journey';
    }

    if (matchPath(`${path}/mutual-action-plan`, location.pathname)) {
      return 'mutual-action-plan';
    }

    if (matchPath(`${path}/settings`, location.pathname)) {
      return 'settings';
    }

    if (matchPath(`${path}/config`, location.pathname)) {
      return 'config';
    }

    return 'overview';
  }, [location.pathname]);

  const executeUncollapse = () => {
    if (!isSidebarHidden || !isMobile) {
      toggleSidebarCollapse();
      return;
    }
    toggleSidebarHidden();
    setTimeout(() => toggleContentHidden(), 500);
  };

  const handleUnCollapse: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e?.stopPropagation();
    executeUncollapse();
  };

  const executeCollapse = useCallback(() => {
    if (!isMobile) return;
    toggleContentHidden();
    toggleSidebarHidden();
  }, [isMobile, toggleContentHidden, toggleSidebarHidden]);

  const handleCollapse: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e?.stopPropagation();
    executeCollapse();
  };

  const handleClickSidebarLink = useCallback(
    (path: string) => {
      if (isMobile) executeCollapse();
      navigate(
        `/organization/${organizationSlug}/dealroom/${dealRoomId}/${path}`,
        { preserveQuery: true },
      );
    },
    [isMobile, executeCollapse, navigate, organizationSlug, dealRoomId],
  );

  const handleClickSidebarBackButton = useCallback(() => {
    navigate(`/organization/${organizationSlug}`);
  }, [navigate, organizationSlug]);

  let textColor = backgroundColor;
  let sidebarSelectedBackgroundColor = backgroundColor;
  if (isTooDark(backgroundColor)) {
    textColor = `color-mix(in oklab, ${backgroundColor} 20%, white)`;
    sidebarSelectedBackgroundColor = `color-mix(in oklab, ${backgroundColor} 70%, white)`;
  } else {
    textColor = `color-mix(in oklab, ${backgroundColor} 20%, black)`;
    sidebarSelectedBackgroundColor = `color-mix(in oklab, ${backgroundColor} 30%, white)`;
  }

  const overlayStyle = mergeStyles({
    position: 'absolute',
    zIndex: '98', // lower than sidebar
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  });

  const styles = mergeStyles({
    position: isMobile ? 'absolute' : 'relative',
    zIndex: '99',
    backgroundColor: backgroundColor,
    willChange: 'width',
    color: textColor,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    transition: 'width 0.5s linear, padding 0.5s linear',
    boxSizing: 'border-box',
    width:
      isSidebarHidden && isMobile
        ? '0px'
        : isSidebarCollapsed
          ? '89px'
          : '164px',
    height: '100%',
    padding: isSidebarHidden && isMobile ? '1.5rem 0' : '1.5rem',
    gap: '1.5rem',

    '.left-sidebar-header': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      width: 'fit-content',

      '.left-sidebar-header-button': {
        width: '24px',
        height: '24px',
        fontSize: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
        position: 'absolute',
        top: (isSidebarHidden || isContentHidden) && isMobile ? '1rem' : '50%',
        transform: 'translateY(-50%)',
        transition: 'left 0.5s linear',
        left:
          isSidebarHidden && isMobile
            ? 'calc(100% - 10px)'
            : isSidebarCollapsed
              ? 'calc(100% + 10px)'
              : 'calc(100% + 58px)',
        backgroundColor: backgroundColor,
        color: textColor,
        i: {
          fontSize: '10px',
        },
        ':hover': {
          backgroundColor: sidebarSelectedBackgroundColor,
          color: textColor,
        },
      },
    },

    '.left-sidebar-links': {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      gap: '20px',

      '.left-sidebar-link': {
        width: '100%',
        fontSize: '16px',
        color: textColor,
        padding: '12px 8px',
        height: 'auto',
        borderRadius: '4px',
        justifyContent: 'flex-start',

        i: {
          margin: 0,
          padding: 0,
        },

        'span.ms-Button-flexContainer': {
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '8px',
          paddingLeft: '4px',
        },

        ':hover': {
          backgroundColor: sidebarSelectedBackgroundColor,
          color: textColor,
        },

        '.left-sidebar-link-label': {
          fontSize: '13px',
          whiteSpace: 'nowrap',
          opacity: 0,
          transition: 'opacity .1s linear',
          visibility: 'hidden',
        },

        '.left-sidebar-link-label-visible': {
          visibility: 'visible',
          opacity: 1,
          transition: 'visibility .1s linear .2s, opacity .1s linear .2s',
        },
      },
    },

    '.left-sidebar-back-icon': {
      width: 'fit-content',
      color: textColor,
      backgroundColor: backgroundColor,
      padding: '0 2px',
      margin: '0',

      span: {
        justifyContent: 'flex-start',
      },

      i: {
        fontSize: '16px',
      },

      ':hover': {
        backgroundColor: sidebarSelectedBackgroundColor,
        color: textColor,
      },
    },

    '.active-link': {
      color: `${textColor} !important`,
      backgroundColor: `${sidebarSelectedBackgroundColor} !important`,
    },
  });

  const renderSidebarLinks = useMemo(
    () =>
      leftSidebarLinks
        .filter(
          (link) =>
            link.path !== 'mutual-action-plan' ||
            (dealRoom?.hasMutualPlan ?? true),
        )
        .filter((link) => link.path !== 'config' || dealRoomRole === 'SELLER')
        .map((sidebarLink) => {
          return (
            <IconButton
              key={sidebarLink.path}
              className={classNames('left-sidebar-link', {
                'active-link': activeTab === sidebarLink.path,
              })}
              iconProps={{ iconName: sidebarLink.iconName }}
              title={sidebarLink.label}
              ariaLabel={sidebarLink.label}
              onClick={() => handleClickSidebarLink(sidebarLink.path)}
            >
              <span
                className={classNames(
                  'left-sidebar-link-label',
                  isSidebarCollapsed ? '' : 'left-sidebar-link-label-visible',
                )}
              >
                {sidebarLink.label}
              </span>
            </IconButton>
          );
        }),
    [
      activeTab,
      isSidebarCollapsed,
      handleClickSidebarLink,
      dealRoom,
      dealRoomRole,
    ],
  );

  return (
    <>
      {isMobile && !isSidebarHidden && (
        <div className={overlayStyle} onClick={handleCollapse} />
      )}
      <Stack className={styles}>
        <div className="left-sidebar-header">
          {(!isMobile || !isContentHidden) && (
            <Stack gap="1rem" horizontalAlign="center">
              <AugmentLogo />
              {dealRoom?.buyerLogoUrl && (
                <>
                  <Image
                    src={dealRoom.buyerLogoUrl}
                    width={36}
                    height={36}
                    style={{ borderRadius: '10%' }}
                  />
                </>
              )}
            </Stack>
          )}
          <IconButton
            className="left-sidebar-header-button"
            iconProps={{
              iconName: isSidebarCollapsed
                ? 'ChevronRightMed'
                : 'ChevronLeftMed',
            }}
            onClick={handleUnCollapse}
          />
        </div>
        {(!isMobile || !isContentHidden) && (
          <div className="left-sidebar-links">{renderSidebarLinks}</div>
        )}
        {(!isMobile || !isContentHidden) && (
          <IconButton
            className="left-sidebar-back-icon"
            iconProps={{
              iconName: 'ClosePane',
            }}
            onClick={handleClickSidebarBackButton}
          />
        )}
      </Stack>
    </>
  );
};
