export const UpdateFileIllustration = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.00029 6.08525L6 12.5C6 13.8255 7.03154 14.91 8.33562 14.9947L8.5 15L12.9144 15.0007C12.7083 15.5829 12.1528 16 11.5 16H8C6.34315 16 5 14.6569 5 13V7.5C5 6.84678 5.41754 6.29109 6.00029 6.08525ZM13.5 4C14.3284 4 15 4.67157 15 5.5V12.5C15 13.3284 14.3284 14 13.5 14H8.5C7.67157 14 7 13.3284 7 12.5V5.5C7 4.67157 7.67157 4 8.5 4H13.5Z"
      fill="#F6F6F6"
    />
  </svg>
);
