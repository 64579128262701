import { useAuth0 } from '@auth0/auth0-react';
import {
  FontSizes,
  FontWeights,
  Icon,
  Link,
  mergeStyles,
  NeutralColors,
  Text,
} from '@fluentui/react';
import {
  DetailedMeetingflow,
  ExternalServiceObjectType,
} from '@meetingflow/common/Api/data-contracts';
import { WithRequiredProperty } from '@meetingflow/common/TypeHelpers';
import { orderBy } from 'lodash';
import { useMemo } from 'react';
import { useExternalServiceConfigurations } from '../../../../Hooks/useExternalServiceConfigurations';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import { ApiClient } from '../../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../../Themes/Themes';
import { HubSpotPanelContext } from '../../../../types/HubSpotPanelContext';
import { AsyncLink } from '../../../HOC/AsyncLink';
import StyledDateTime from '../../../StyledDateTime';
import { BaseSidePanel } from '../BaseSidePanel';
import { HubSpotSidePanelCompanyContent } from './HubSpotSidePanelCompanyContent';
import { HubSpotSidePanelDealContent } from './HubSpotSidePanelDealContent';

export type HubSpotObjectSidePanelProps = {
  organizationSlug: string;
  meetingPlan?: Pick<
    DetailedMeetingflow,
    'id' | 'attendees' | 'associations' | 'userActivity'
  >;
  panelContext: HubSpotPanelContext;
  pushHubSpotPanel: (context: HubSpotPanelContext) => void;
  showObjectPicker: (defaultTab?: ExternalServiceObjectType) => void;
  onBack?: () => void;
  onClose?: () => void;
  onLogCall?: (
    object: WithRequiredProperty<
      Omit<HubSpotPanelContext, 'serviceInstanceId'>,
      'objectType' | 'objectId'
    >,
  ) => void;
  onTimelineClick?: () => void;
  onPinClick?: (e?: React.MouseEvent<HTMLElement>) => Promise<unknown>;
};
export const HubSpotObjectSidePanel = ({
  organizationSlug,
  meetingPlan,
  panelContext,
  showObjectPicker,
  onBack,
  onClose,
  pushHubSpotPanel,
  onLogCall,
  onTimelineClick,
  onPinClick,
}: HubSpotObjectSidePanelProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const { isDark } = useLightOrDarkMode();
  const {
    configurationsWithToken,
    // loading: hubspotConfigurationsLoading,
    configurationById,
  } = useExternalServiceConfigurations({ app: 'HUBSPOT', withToken: true });

  const { objectId, objectType, serviceConfigurationId } = panelContext;

  const activeConfigurationId =
    serviceConfigurationId || configurationsWithToken?.[0]?.id;

  const serviceInstanceId = configurationById(activeConfigurationId)
    ?.instanceId;

  const activeConfiguration = useMemo(
    () =>
      activeConfigurationId
        ? configurationById(activeConfigurationId)
        : undefined,
    [activeConfigurationId, configurationById],
  );

  const lastLoggedToCRM = useMemo(() => {
    return orderBy(
      meetingPlan?.userActivity?.filter(
        (a) =>
          a.type.toString() === 'LOG_MEETING_TO_CRM' &&
          a.additionalDetails.app === 'HUBSPOT',
      ),
      ['createdAt'],
      ['desc'],
    )?.[0];
  }, [meetingPlan?.userActivity]);

  const lastLoggedToCRMBy = meetingPlan?.attendees.find(
    (a) => a.email === lastLoggedToCRM?.user?.email,
  );

  const lastLoggedBanner = (
    <>
      {lastLoggedToCRM ? (
        <Text
          block
          style={{
            backgroundColor: MEETINGFLOW_COLORS.orange,
            padding: '.5rem',
            borderRadius: '.25rem',
            margin: '.5rem 0',
            fontSize: FontSizes.small,
            color: isDark ? undefined : 'white',
          }}
        >
          This meeting was logged to HubSpot{' '}
          <StyledDateTime
            dateTime={lastLoggedToCRM.createdAt}
            displayComponents={['date']}
          />{' '}
          by{' '}
          {lastLoggedToCRMBy?.name ||
            lastLoggedToCRMBy?.email ||
            `Unknown User`}
          .
          {onTimelineClick ? (
            <Link
              onClick={onTimelineClick}
              style={{
                display: 'inline-block',
                margin: '0 .5rem',
                color: isDark
                  ? MEETINGFLOW_COLORS.purpleDarkest
                  : MEETINGFLOW_COLORS.purpleGrey,
                fontWeight: FontWeights.semibold,
                textDecoration: 'underline',
              }}
            >
              View full timeline
            </Link>
          ) : null}
        </Text>
      ) : null}
    </>
  );

  let sidebarTitle: string | React.ReactNode = 'HubSpot';

  const hubspotLinkClass = mergeStyles({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '.5rem',
    fontWeight: FontWeights.semibold,
  });

  const hubspotIconClass = mergeStyles({
    height: '2rem',
    width: '2rem',
    borderRadius: '1rem',
    display: 'inline-block',
    position: 'relative',
    marginRight: '.5rem',
    img: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '2rem',
      width: '2rem',
      display: 'block',
    },
  });

  const hubspotDealDashboardUrl = activeConfiguration
    ? `https://app.hubspot.com/contacts/${activeConfiguration.instanceId}/deals`
    : undefined;

  const actions = (
    <div
      style={{
        position: 'sticky',
        display: 'flex',
        bottom: '0',
        left: '0',
        width: 'calc(100% - .5rem)',
        boxSizing: 'border-box',
        paddingBottom: '.5rem',
        backgroundColor: isDark
          ? NeutralColors.gray210
          : MEETINGFLOW_COLORS.purpleUltraSuperLightish,
      }}
    >
      <div>
        {!!objectId && !!objectType && onLogCall ? (
          <div style={{ marginTop: '.5rem' }}>
            <AsyncLink
              onClick={async () => {
                if (!meetingPlan) {
                  return;
                }

                if (
                  !meetingPlan.associations.some(
                    (association) =>
                      association.serviceConfigurationId ===
                        serviceConfigurationId &&
                      association.objectType === objectType &&
                      association.externalId === objectId,
                  )
                ) {
                  const token = await getAccessTokenSilently();
                  switch (objectType) {
                    case 'DEAL': {
                      await ApiClient.put(
                        `/organization/${organizationSlug}/plan/${meetingPlan.id}/external/hubspot/deal`,
                        {
                          configurationId: serviceConfigurationId,
                          objectId,
                        },
                        {
                          headers: { Authorization: `Bearer ${token}` },
                        },
                      );

                      await onPinClick?.();
                      break;
                    }
                    case 'ACCOUNT': {
                      await ApiClient.put(
                        `/organization/${organizationSlug}/plan/${meetingPlan.id}/external/hubspot/company`,
                        {
                          configurationId: serviceConfigurationId,
                          objectId,
                        },
                        {
                          headers: { Authorization: `Bearer ${token}` },
                        },
                      );

                      await onPinClick?.();
                      break;
                    }
                    default: {
                    }
                  }
                }

                return onLogCall({
                  name: panelContext?.name,
                  objectId,
                  objectType,
                  serviceConfigurationId,
                });
              }}
              className={hubspotLinkClass}
              as={'a'}
            >
              <div
                className={hubspotIconClass}
                style={{ backgroundColor: MEETINGFLOW_COLORS.teal }}
              >
                <Icon
                  style={{
                    color: 'white',
                    lineHeight: '2rem',
                    width: '2rem',
                    textAlign: 'center',
                    fontSize: '1.25rem',
                    display: 'inline-block',
                    margin: '0 auto',
                  }}
                  iconName="LightningBolt"
                />
              </div>
              <span>Log Meeting to HubSpot</span>
            </AsyncLink>
          </div>
        ) : null}

        {hubspotDealDashboardUrl ? (
          <div style={{ marginTop: '.5rem' }}>
            <Link
              style={{ lineHeight: '2rem', fontWeight: FontWeights.semibold }}
              href={hubspotDealDashboardUrl}
              target="_blank"
            >
              <Icon
                style={{
                  float: 'left',
                  marginRight: '.5rem',
                  position: 'relative',
                  top: '.15rem',
                  backgroundColor: 'rgb(120,160,227)',
                  borderRadius: '1rem',
                  padding: '.5rem',
                  color: 'white',
                  lineHeight: '1rem',
                }}
                iconName="Add"
              />
              HubSpot Deals
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  );

  const panelContent = (() => {
    const panelContentStyle = mergeStyles({
      position: 'relative',
      height: 'auto',
      overflow: 'hidden',
    });

    if (activeConfigurationId && !configurationById(activeConfigurationId)) {
      return (
        <div className={panelContentStyle}>
          <Text
            variant="large"
            block
            style={{
              textAlign: 'center',
              margin: '1rem 0',
            }}
          >
            <span style={{ fontWeight: FontWeights.semibold }}>
              This Meetingflow is associated with object(s) from a HubSpot
              instance you do not to have access to.
            </span>
            <div>Associated HubSpot instance: {serviceInstanceId}</div>
          </Text>
        </div>
      );
    }

    if (activeConfigurationId && objectId && objectType === 'ACCOUNT') {
      sidebarTitle = (
        <span>
          HubSpot Company{' '}
          <Link
            style={{
              fontSize: FontSizes.small,
              display: 'block',
              paddingLeft: onBack ? '1.75rem' : undefined,
            }}
            onClick={() => showObjectPicker('ACCOUNT')}
          >
            Browse Companies
          </Link>
        </span>
      );
      return (
        <div className={panelContentStyle}>
          {lastLoggedBanner}
          <HubSpotSidePanelCompanyContent
            key={objectId}
            organizationSlug={organizationSlug}
            meetingPlanId={meetingPlan?.id}
            associatedWithPlan={meetingPlan?.associations?.some(
              (association) => association.externalId === objectId,
            )}
            externalServiceObjectId={
              meetingPlan?.associations?.find((a) => a.externalId === objectId)
                ?.id
            }
            configurationId={activeConfigurationId}
            companyId={objectId}
            pushHubSpotPanel={pushHubSpotPanel}
            showObjectPicker={showObjectPicker}
            onPinClick={onPinClick}
          />
          {actions}
        </div>
      );
    }
    if (activeConfigurationId && objectId && objectType === 'DEAL') {
      sidebarTitle = (
        <span>
          HubSpot Deal{' '}
          <Link
            style={{
              fontSize: FontSizes.small,
              display: 'block',
              paddingLeft: onBack ? '1.75rem' : undefined,
            }}
            onClick={() => showObjectPicker('DEAL')}
          >
            Browse Deals
          </Link>
        </span>
      );
      return (
        <div className={panelContentStyle}>
          {lastLoggedBanner}
          <HubSpotSidePanelDealContent
            key={objectId}
            organizationSlug={organizationSlug}
            meetingPlanId={meetingPlan?.id}
            associatedWithPlan={meetingPlan?.associations?.some(
              (association) => association.externalId === objectId,
            )}
            externalServiceObjectId={
              meetingPlan?.associations?.find((a) => a.externalId === objectId)
                ?.id
            }
            configurationId={activeConfigurationId}
            dealId={objectId}
            pushHubSpotPanel={pushHubSpotPanel}
            showObjectPicker={showObjectPicker}
            onPinClick={onPinClick}
          />
          {actions}
        </div>
      );
    }

    return null;
  })();

  return (
    <BaseSidePanel
      title={sidebarTitle}
      onBack={onBack}
      onClose={onClose}
      loading={false}
    >
      {panelContent}
    </BaseSidePanel>
  );
};
