import { mergeStyles } from '@fluentui/react';
import { SlateChecklistItem } from '@meetingflow/common/Types/Slate';
import classNames from 'classnames';
import { Transforms } from 'slate';
import {
  ReactEditor,
  RenderElementProps,
  useReadOnly,
  useSlateStatic,
} from 'slate-react';

export const ChecklistItemElement = ({
  attributes,
  children,
  element,
}: RenderElementProps) => {
  const editor = useSlateStatic();
  const readOnly = useReadOnly();
  const { checked } = element as SlateChecklistItem;

  const checklistStyle = mergeStyles({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'top',
    marginLeft: '.25rem',
    marginBottom: '.25rem',
    '& + &': {
      marginTop: 0,
    },
    '.checklist-item-check-span': {
      marginRight: '0.25em',

      input: {
        margin: 0,
      },
    },
    '.checklist-item-text': {
      flex: 1,
      opacity: checked ? 0.666 : 1,
      textDecoration: !checked ? 'none' : 'line-through',
      fontSize: '13px',
      position: 'relative',
      top: '-1px',
      '&:focus': {
        outline: 'none',
      },
    },
  });
  return (
    <div {...attributes} className={classNames(checklistStyle, 'checklist')}>
      <div contentEditable={false} className="checklist-item-check-span">
        <input
          type="checkbox"
          checked={checked}
          onChange={(event) => {
            const path = ReactEditor.findPath(editor, element);
            Transforms.setNodes(
              editor,
              {
                checked: event.target.checked,
              },
              { at: path },
            );
          }}
        />
      </div>
      <span
        contentEditable={!readOnly}
        suppressContentEditableWarning
        className="checklist-item-text"
      >
        {children}
      </span>
    </div>
  );
};
