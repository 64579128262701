import React from 'react';
import { Pagination, PaginationProps } from '@mui/material';
import { DSPaginationRoot } from './DSPagination.styles';

export interface DSPaginationProps extends PaginationProps {
  children?: React.ReactNode;
}

const DSPagination: React.FC<DSPaginationProps> = ({ children, ...props }) => {
  return <DSPaginationRoot {...props}>{children}</DSPaginationRoot>;
};

export default DSPagination;
