import React, { useEffect, useRef, useState, useMemo } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
  DSMenuBarWithOverflowRoot,
  MenuItemsContainer,
  OverflowContainer,
} from './DSMenuBarWithOverflow.styles';

export interface DSMenuBarWithOverflowProps {
  items: React.ReactNode[];
  className?: string;
}

const DSMenuBarWithOverflow: React.FC<DSMenuBarWithOverflowProps> = ({
  items,
  className,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const measureContainerRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [splitIndex, setSplitIndex] = useState<number>(items.length);
  const resizeTimeoutRef = useRef<NodeJS.Timeout>();

  const handleOverflowClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { visibleItems, overflowItems } = useMemo(
    () => ({
      visibleItems: items.slice(0, splitIndex),
      overflowItems: items.slice(splitIndex),
    }),
    [items, splitIndex],
  );

  const updateVisibleItems = () => {
    if (!containerRef.current || !measureContainerRef.current) return;

    const containerWidth = containerRef.current.offsetWidth;
    const measureContainer = measureContainerRef.current;
    const children = Array.from(measureContainer.children);
    const overflowButtonWidth = 48;
    const availableWidth = containerWidth - overflowButtonWidth;
    let newSplitIndex = items.length;

    let totalWidth = 0;
    for (let i = 0; i < children.length; i++) {
      const child = children[i] as HTMLElement;
      totalWidth += child.offsetWidth + 8;

      if (totalWidth > availableWidth) {
        newSplitIndex = Math.max(1, i);
        break;
      }
    }

    if (newSplitIndex !== splitIndex) {
      setSplitIndex(newSplitIndex);
    }
  };

  useEffect(() => {
    const debouncedUpdate = () => {
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }
      resizeTimeoutRef.current = setTimeout(updateVisibleItems, 100);
    };

    const resizeObserver = new ResizeObserver(debouncedUpdate);

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    // Initial update
    debouncedUpdate();

    return () => {
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }
      resizeObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  return (
    <>
      {/* Hidden container for measurements */}
      <div
        ref={measureContainerRef}
        style={{
          position: 'absolute',
          visibility: 'hidden',
          display: 'flex',
          gap: '8px',
          whiteSpace: 'nowrap',
        }}
      >
        {items.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </div>

      <DSMenuBarWithOverflowRoot ref={containerRef} className={className}>
        <MenuItemsContainer>
          {visibleItems.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </MenuItemsContainer>

        {overflowItems.length > 0 && (
          <OverflowContainer>
            <IconButton
              size="small"
              onClick={handleOverflowClick}
              aria-label="show more"
              aria-controls="overflow-menu"
              aria-haspopup="true"
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              id="overflow-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              onClick={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {overflowItems.map((item, index) => (
                <MenuItem key={index}>{item}</MenuItem>
              ))}
            </Menu>
          </OverflowContainer>
        )}
      </DSMenuBarWithOverflowRoot>
    </>
  );
};

export default DSMenuBarWithOverflow;
