import isUrl from 'is-url';
import {
  CreateDeferredPromise,
  ObservablePromise,
} from '../../../Helpers/DeferredPromise';
import { LinkContent } from '../../../types/LinkContent';
import { Editor, Element, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { OptionalArgTuple } from '@meetingflow/common/TypeHelpers';
import { insertLink } from '../Helpers/EditorHelpers';

export type LinkEditor = ReactEditor & {
  getLinkContent: (
    ...context: OptionalArgTuple<Partial<LinkContent> | undefined>
  ) => ObservablePromise<LinkContent, Partial<LinkContent> | undefined>;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LinkEditor = {
  isLinkEditor(value: unknown): value is LinkEditor {
    return (
      Editor.isEditor(value) &&
      typeof (value as unknown as LinkEditor).getLinkContent === 'function'
    );
  },
};

export const withLinks = <T extends Editor>(
  editor: T,
  createLinkDeferred: CreateDeferredPromise<
    LinkContent,
    Partial<LinkContent> | undefined
  >,
): T => {
  const {
    isVoid,
    isInline,
    insertData,
    insertText,
    insertBreak,
    insertSoftBreak,
    deleteBackward,
    deleteForward,
    deleteFragment,
    normalizeNode,
  } = editor;

  editor.getLinkContent = (
    ...context: OptionalArgTuple<Partial<LinkContent> | undefined>
  ) => createLinkDeferred(...context).promise;

  editor.isVoid = (element) => isVoid(element);

  editor.isInline = (element: Element) => {
    return element.type === 'link' || isInline(element);
  };

  editor.insertData = (data: DataTransfer): void => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      insertLink(editor, text);
    } else {
      insertData(data);
    }
  };

  editor.insertText = (text: string): void => {
    if (text && isUrl(text)) {
      insertLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertBreak = () => insertBreak();
  editor.insertSoftBreak = () => insertSoftBreak();
  editor.deleteBackward = (...args) => deleteBackward(...args);
  editor.deleteForward = (...args) => deleteForward(...args);
  editor.deleteFragment = (...args) => deleteFragment(...args);

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    if (Element.isElement(node) && node.type === 'link' && !node.href) {
      Transforms.unwrapNodes(editor, { at: path });
      return false;
    }

    return normalizeNode(entry);
  };

  return editor;
};
