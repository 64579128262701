import {
  DealRoomAsyncPrimaryButton,
  DealRoomCommandBarButton,
  DealRoomPrimaryButton,
} from '../DealRoomButton';
import { mergeStyles } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import classNames from 'classnames';

type BuyerOrientationSurveyActionButtonsProps = {
  handleClickBack: () => void;
  handleClickNext: () => Promise<void>;
  step: number;
  numberOfSteps: number;
  isNextButtonDisabled?: boolean;
};

export const BuyerOrientationSurveyActionButtons = ({
  handleClickBack,
  handleClickNext,
  step,
  numberOfSteps,
  isNextButtonDisabled,
}: BuyerOrientationSurveyActionButtonsProps) => {
  const actionButtonStyles = mergeStyles({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    gap: '1rem',
    flexWrap: 'wrap',

    '.command-button': {
      padding: '0.5rem 1rem',
      justifyContent: 'center',
      '.ms-Button-label': {
        fontSize: '0.9375rem',
        lineHeight: '1.5rem',
        fontWeight: '400',
        color: DEALROOMS_COLORS.themePrimary,
      },
    },

    '.primary-action-button': {
      padding: '0.5rem 1rem',
      minWidth: '130px',
      backgroundColor: DEALROOMS_COLORS.userSurveyPrimary,

      ':hover': {
        backgroundColor: DEALROOMS_COLORS.themePrimary,
        borderColor: DEALROOMS_COLORS.themePrimary,
      },

      '.ms-Button-label': {
        fontSize: '0.9375rem',
        lineHeight: '1.5rem',
        fontWeight: '400',
        color: DEALROOMS_COLORS.white,
      },
    },

    '.header-button-disabled': {
      opacity: '0.3',
      ':hover': { backgroundColor: DEALROOMS_COLORS.userSurveyPrimary },
    },
    '@media(max-width: 460px)': { justifyContent: 'center' },
  });

  return (
    <div className={actionButtonStyles}>
      <DealRoomCommandBarButton
        buttonStyleType="COMMAND_BAR"
        text={step === 1 ? "I'll do this later" : 'Back'}
        customClasses="command-button"
        onClick={handleClickBack}
      />
      <DealRoomAsyncPrimaryButton
        disabled={isNextButtonDisabled}
        customClasses={classNames('primary-action-button', {
          ['header-button-disabled']: isNextButtonDisabled,
        })}
        onClick={handleClickNext}
        text={step === numberOfSteps ? 'Done' : 'Next'}
      />
    </div>
  );
};
