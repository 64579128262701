import React from 'react';
import { FormControl, FormControlProps } from '@mui/material';
import { DSFormControlRoot } from './DSFormControl.styles';

export interface DSFormControlProps extends FormControlProps {
  children?: React.ReactNode;
}

const DSFormControl: React.FC<DSFormControlProps> = ({ children, ...props }) => {
  return <DSFormControlRoot {...props}>{children}</DSFormControlRoot>;
};

export default DSFormControl;
