import { useAuth0 } from '@auth0/auth0-react';
import {
  DatePicker,
  Dropdown,
  FontIcon,
  FontSizes,
  FontWeights,
  IButtonStyles,
  IComboBoxStyles,
  IDatePickerStyles,
  ISearchBoxStyles,
  IToggleStyles,
  IconButton,
  NeutralColors,
  Toggle,
  mergeStyles,
} from '@fluentui/react';
import {
  CalendarEvent,
  Meetingflow,
} from '@meetingflow/common/Api/data-contracts';
import { humanizeDateTime } from '@meetingflow/common/DateHelpers';
import { hasOwnProperty } from '@meetingflow/common/ObjectHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { MeetingflowCard } from '../../../Components/MeetingPlans/MeetingflowCard';
import { isGoogleUser } from '../../../Helpers/IdentityHelpers';
import {
  collectToRecord,
  deleteValue,
  getDateValue,
  setValue,
} from '../../../Helpers/SearchParamHelpers';
import useBreakpoints from '../../../Hooks/useBreakpoints';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { useLocalStorageState } from '../../../Hooks/useLocalStorageState';
import { useOrganization } from '../../../Hooks/useOrganization';
import { useUserProfile } from '../../../Hooks/useProfile';
import {
  OrganizationCompanies,
  OrganizationContactsQuery,
  OrganizationMeetingPlansQuery,
  OrganizationMembersQuery,
  OrganizationOrganizerContactsQuery,
  OrganizationUpcomingMeetings,
} from '../../../QueryNames';
import {
  CompaniesApiClient,
  ContactsApiClient,
  EventsApiClient,
  MeetingflowsApiClient,
  MembersApiClient,
} from '../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import EventCard from '../Library/EventCard';

interface MeetinflowBrowserProps {
  organizationSlug: string;
  detailListTopOffset?: string;
  queryKey?: string;
  onClickMeetingflow?: (meetingflowId: string) => void;
  onClickEvent?: (eventId: string) => void;
  showOpenInMainColumnButton?: boolean;
}

export const MeetingflowBrowser = ({
  organizationSlug,
  queryKey,
  // detailListTopOffset = '14.25rem',
  onClickMeetingflow,
  onClickEvent,
  showOpenInMainColumnButton,
}: MeetinflowBrowserProps) => {
  const { user, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
  const email = user?.email;
  const appInsights = useAppInsightsContext();
  const { isDark } = useLightOrDarkMode();
  const navigate = useNavigate();

  const breakpoints = useBreakpoints();

  const {
    internalDomains,
    isLoading: orgIsLoading,
    hasEntitlement,
  } = useOrganization(organizationSlug);

  const client = useQueryClient();

  const [searchParams, setSearchParams] = useSearchParams({
    selectedDate: DateTime.now().startOf('day').toISO()!,
    q: '',
  });

  const selectedDate = useMemo(
    () =>
      searchParams.has('selectedDate')
        ? DateTime.fromISO(searchParams.get('selectedDate')!)
        : DateTime.now(),
    [searchParams],
  );

  const searchString = useMemo(() => searchParams.get('q'), [searchParams]);

  const [sortOrder, setSortOrder] = useLocalStorageState<'desc' | 'asc'>(
    'MEETINGFLOW_BROWSER_PLANS_SORT_ORDER',
    'desc',
  );
  const [sortBy, setSortBy] = useLocalStorageState<
    'startTime' | 'createdAt' | 'updatedAt'
  >('MEETINGFLOW_BROWSER_PLANS_SORT_BY', 'startTime');
  const [onlyMine, setOnlyMine] = useLocalStorageState<boolean>(
    'MEETINGFLOW_BROWSER_PLANS_ONLY_MINE',
    false,
  );
  const [includeAdHoc, setIncludeAdHoc] = useLocalStorageState<boolean>(
    'MEETINGFLOW_BROWSER_PLANS_INCLUDE_AD_HOC',
    false,
  );
  const [hasRecording, setHasRecording] = useLocalStorageState<boolean>(
    'MEETINGFLOW_BROWSER_PLANS_HAS_RECORDING',
    false,
  );

  const { user: userProfile, updateUserProfile } = useUserProfile();

  const externalOnly =
    userProfile?.preferenceAgendaMeetingFilter === 'EXTERNAL_ONLY';

  const plansQueryKey = `${organizationSlug!}${queryKey && `_${queryKey}`}`;

  const DEFAULT_MIN_DATE = DateTime.now()
    .minus({ days: 30 })
    .startOf('day')
    .toISO()!;

  const DEFAULT_MAX_DATE = DateTime.now().endOf('week').endOf('day').toISO()!;

  const {
    data: plansData,
    isLoading: plansLoading,
    isFetching: plansFetching,
    isRefetching: plansRefetching,
    refetch: refetchPlans,
  } = useQuery(
    OrganizationMeetingPlansQuery(plansQueryKey),
    async ({ pageParam }) => {
      const token = await getAccessTokenSilently();
      return MeetingflowsApiClient.listPlans(
        {
          organizationSlug: organizationSlug!,
          limit: 50,
          skip: 0,
          minDate: DEFAULT_MIN_DATE,
          maxDate: DEFAULT_MAX_DATE,
          // @ts-ignore
          isExternal: externalOnly ? true : undefined,
          participant: onlyMine ? email : undefined,
          hasRecording: hasRecording ? true : undefined,
          ...collectToRecord(searchParams),
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
  );

  const {
    data: eventsData,
    isLoading: eventsLoading,
    refetch: refetchEvents,
    isError: eventsErrored,
    isFetching: eventsFetching,
    isRefetching: eventsRefetching,
    error: eventsError,
  } = useQuery(
    OrganizationUpcomingMeetings(organizationSlug!),
    async () => {
      const token = await getAccessTokenSilently();

      return EventsApiClient.listEvents(
        {
          organizationSlug: organizationSlug!,
          q: searchString || undefined,
          minDate: DEFAULT_MIN_DATE,
          maxDate: DEFAULT_MAX_DATE,
          ...collectToRecord(searchParams),
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      // 15 minutes
      refetchInterval: 900000,
      staleTime: 900000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: true,
      retry: 1,
      enabled: !!organizationSlug,
      queryHash: `${OrganizationUpcomingMeetings(
        organizationSlug!,
      )}_${searchString}_${selectedDate
        .startOf('week')
        .startOf('day')
        .toISO()}`,
    },
  );

  useEffect(() => {
    refetchEvents();
    refetchPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchParams,
    userProfile,
    onlyMine,
    externalOnly,
    includeAdHoc,
    sortBy,
    sortOrder,
    hasRecording,
  ]);

  const filteredEvents: CalendarEvent[] = useMemo(() => {
    if (!eventsData?.data) {
      return [];
    }

    let filteredEvents: CalendarEvent[] = eventsData.data;

    // If we are not hiding internal meetings, we're good
    if (!externalOnly) {
      return filteredEvents;
    }

    // If we're hiding internal CalendarEvents, filter them out (also filter out "private" visibility events).
    filteredEvents = filteredEvents.filter((event) => {
      return (
        (event.organizer?.emailDomain &&
          !internalDomains.includes(event.organizer.emailDomain)) ||
        (event.creator?.emailDomain &&
          !internalDomains.includes(event.creator.emailDomain)) ||
        event.attendees?.some((attendee) => {
          return (
            !internalDomains.includes(attendee.emailDomain) && !event.isPrivate
          );
        })
      );
    });

    return filteredEvents;
  }, [eventsData?.data, externalOnly, internalDomains]);

  const { data: companyData } = useQuery(
    OrganizationCompanies(organizationSlug!, true),
    async () => {
      const token = await getAccessTokenSilently();
      return CompaniesApiClient.listCompanies(
        {
          organizationSlug: organizationSlug!,
          hasPlans: true,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
  );

  const { data: membersData } = useQuery(
    OrganizationMembersQuery(organizationSlug!, true),
    async () => {
      const token = await getAccessTokenSilently();
      return MembersApiClient.listMembers(
        { organizationSlug: organizationSlug!, hasPlans: true },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    { enabled: !!organizationSlug },
  );

  const { data: contactsData } = useQuery(
    OrganizationContactsQuery(organizationSlug!, true),
    async () => {
      const token = await getAccessTokenSilently();
      return ContactsApiClient.listContacts(
        { organizationSlug: organizationSlug!, hasPlans: true },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
  );

  const { data: organizersData } = useQuery(
    OrganizationOrganizerContactsQuery(organizationSlug!),
    async () => {
      const token = await getAccessTokenSilently();
      return ContactsApiClient.listContacts(
        { organizationSlug: organizationSlug!, organizedPlans: true },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
  );

  type MergedListItem = {
    date: string;
    events: CalendarEvent[];
    plans: Meetingflow[];
    combined: (CalendarEvent | Meetingflow)[];
  };

  const mergedList = useMemo(() => {
    let merged: MergedListItem[] = [];

    // @ts-ignore
    let dates = [];

    if (plansData?.data || filteredEvents) {
      dates = [
        ...new Set([
          ...filteredEvents.map((event) =>
            DateTime.fromISO(event.startTime).startOf('day').toISODate(),
          ),
        ]),
      ];

      if (plansData?.data) {
        dates.push(
          ...new Set([
            ...plansData.data.map((plan) =>
              DateTime.fromISO(plan.startTime).startOf('day').toISODate(),
            ),
          ]),
        );
      }

      // @ts-ignore
      dates.forEach((date) => {
        const eventsForDate = filteredEvents.filter(
          (event) =>
            DateTime.fromISO(event.startTime).startOf('day').toISODate() ===
            date,
        );

        let plansForDate = plansData?.data?.filter(
          (plan) =>
            DateTime.fromISO(plan.startTime).startOf('day').toISODate() ===
            date,
        );

        // If we're showing external only, filter out any plans that are internal
        if (externalOnly) {
          plansForDate = plansForDate?.filter((plan) => {
            return (
              (plan.organizer?.emailDomain &&
                !internalDomains.includes(plan.organizer.emailDomain)) ||
              (plan.creator?.emailDomain &&
                !internalDomains.includes(plan.creator.emailDomain)) ||
              plan.attendees?.some((attendee) => {
                return !internalDomains.includes(attendee.emailDomain);
              })
            );
          });
        }

        // If includeAdHoc is not selected, remove any plans that don't have a matching event
        if (!includeAdHoc) {
          plansForDate = plansForDate?.filter((plan) => {
            return plan.externalId;
          });
        }

        // Dedupe the events and plans if event.meetingplanId === plan.id, keeping the plan
        const dedupedEventsForDate = eventsForDate.filter((event) => {
          return !plansForDate?.some((plan) => plan.id === event.meetingplanId);
        });

        // push the merged object to the merged list, but only if that date doesn't already have one. if it does, merge them and dedupe the events and plans withing the date

        const combinedList = hasRecording
          ? [...(plansForDate || [])]
          : [...dedupedEventsForDate, ...(plansForDate || [])];

        const combinedListSorted = combinedList.sort((a, b) => {
          const aDate = DateTime.fromISO(
            (a as CalendarEvent).startTime || (a as Meetingflow).startTime,
          );
          const bDate = DateTime.fromISO(
            (b as CalendarEvent).startTime || (b as Meetingflow).startTime,
          );

          // sort ascending by start date
          return aDate.diff(bDate).milliseconds;
        });

        if (date && !merged.some((m) => m.date === date)) {
          merged.push({
            date,
            events: dedupedEventsForDate,
            plans: plansForDate || [],
            combined: combinedListSorted,
          });
        } else {
          const existing = merged.find((m) => m.date === date);
          if (existing) {
            existing.events = [
              ...new Set([...existing.events, ...dedupedEventsForDate]),
            ];
            existing.plans = [
              ...new Set([...existing.plans, ...(plansForDate || [])]),
            ];
            existing.combined = [
              ...new Set([...existing.combined, ...combinedList]),
            ];
          }
        }
      });
    }

    // Remove any empty dates
    merged = merged.filter((m) => m.combined.length);

    // // return the merged list sorted by the sort order
    return merged.sort((a, b) => {
      const aDate = DateTime.fromISO(a.date);
      const bDate = DateTime.fromISO(b.date);

      if (sortOrder === 'desc') {
        return bDate.diff(aDate).milliseconds;
      } else {
        return aDate.diff(bDate).milliseconds;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filteredEvents,
    plansData?.data,
    sortOrder,
    includeAdHoc,
    searchParams,
    onlyMine,
    externalOnly,
    internalDomains,
    hasRecording,
  ]);

  const fadeInClass = mergeStyles({
    position: 'relative',
    animationName: 'fadeInSlideAnimation',
    animationDuration: '1s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '1',
    animationFillMode: 'forwards',
    width: 'auto',
    transition: '.3s ease-in-out all',
  });

  const calendarControlsClass = mergeStyles({
    width: '100%',
    padding: '.5rem 0 .25rem 0',
    textAlign: 'left',
    position: 'relative',
    backgroundColor: isDark
      ? NeutralColors.gray200
      : MEETINGFLOW_COLORS.purpleUltraLight,
    borderBottom: `1px solid ${
      isDark ? NeutralColors.gray170 : MEETINGFLOW_COLORS.white
    }`,

    button: {
      display: 'inline-block',
    },

    'span.month': {
      display: 'block',
      position: 'absolute',
      left: '1rem',
      top: '1rem',
    },

    '.toggle-internal': {
      whiteSpace: 'nowrap',
    },

    '.today-button': {
      whiteSpace: 'nowrap',
      display: !breakpoints.md ? 'none' : undefined,
    },

    '.toggle-attendees': {
      whiteSpace: 'nowrap',
    },
    '.ms-Toggle-label': {
      marginRight: '.5rem',
    },
    '.week-selection': {
      marginRight: '1rem',
    },
  });

  const dateHeaderClass = mergeStyles({
    textAlign: 'center',
    borderBottom: `.25rem solid transparent`,
    position: 'relative',
    color: NeutralColors.gray130,
    padding: breakpoints.lg ? '.3rem .25 .35rem .25' : undefined,
    transition: '.3s ease-in-out all',
    ':hover': {
      cursor: 'pointer',
      borderBottom: `.25rem solid ${MEETINGFLOW_COLORS.purpleSecondary}`,
    },
    '&.active': {
      color: MEETINGFLOW_COLORS.teal,
      borderBottom: `.25rem solid ${MEETINGFLOW_COLORS.teal}`,
    },
    '.day-of-week': {
      display: 'block',
      fontSize: FontSizes.size12,
      color: isDark ? NeutralColors.gray50 : NeutralColors.gray120,
    },
    '.day-of-month': {
      display: 'block',
      fontWeight: FontWeights.semibold,
      fontSize: FontSizes.size16,
      color: isDark ? NeutralColors.gray50 : NeutralColors.gray120,
    },
    '.event-indicator': {
      display: 'block',
      fontWeight: FontWeights.semibold,
      position: 'absolute',
      width: '10px',
      left: 'calc(50% - 5px)',
      bottom: '0',
      color: MEETINGFLOW_COLORS.orange,
    },
  });

  const dateGroupClass = mergeStyles({
    maxHeight: breakpoints.md ? `calc(100vh - 16rem)` : undefined,
    overflow: 'auto',
    padding: '0',
    backgroundColor: isDark
      ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
      : MEETINGFLOW_COLORS.purpleUltraSuperLight,
  });

  const cardsWrapperClass = mergeStyles({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    rowGap: '.25rem',

    '.day': {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',

      '.row': {
        position: 'relative',
        display: 'flex',
        flexBasis: 'calc(100% - 1rem)',
        width: 'calc(100% - 1rem)',
        padding: '.25rem',
        transition: '.3s ease-in-out all',
        columnGap: '.25rem',
        borderLeft: `1px solid transparent`,

        ':hover': {
          '.open-in-main-panel-button': {
            i: {
              transition: '.3s ease-in-out all',
              color: MEETINGFLOW_COLORS.purpleMedium,
            },
          },
        },

        '.meetingflow-card-wrapper, .event-card-wrapper': {
          flexBasis: '100%',
          maxWidth: 'calc(100% - 2rem)',

          '.meetingflow-card, .event-card': {
            width: '100%',
          },
        },

        '.event-card-wrapper': {},

        '.on-my-calendar': {
          display: 'flex',
          textAlign: 'center',
          boxSizing: 'border-box',
          padding: '0 .25rem',

          'i.ms-Icon': {
            color: NeutralColors.gray100,
            fontSize: '18px',
            position: 'relative',
            top: '8px',
          },
        },
      },

      '.row.external': {
        // borderLeft: `1px solid ${
        //   isDark
        //     ? MEETINGFLOW_COLORS.orangeDarker
        //     : MEETINGFLOW_COLORS.orangeLight
        // }`,
      },
    },
  });

  const dateGroupHeaderClass = mergeStyles({
    display: 'block',
    position: 'sticky',
    top: '-.75rem',
    zIndex: 20,
    padding: '1rem .5rem .5rem .5rem',
    backgroundColor: isDark ? `rgba(0,0,0,.95)` : `rgba(255,255,255,.95)`,
    fontSize: FontSizes.small,
    fontWeight: FontWeights.semibold,
    color: isDark ? MEETINGFLOW_COLORS.teal : MEETINGFLOW_COLORS.teal,
    borderBottom: `.5px solid ${
      isDark ? NeutralColors.gray170 : MEETINGFLOW_COLORS.purpleGreyLight
    }`,

    '.relative-date': {
      display: 'inline-block',
      float: 'right',
      marginLeft: '.5rem',
      color: isDark ? NeutralColors.gray100 : NeutralColors.gray100,
      fontWeight: FontWeights.regular,
      fontSize: FontSizes.mini,
      textTransform: 'uppercase',
    },

    '&.today .relative-date': {
      color: MEETINGFLOW_COLORS.orangeDark,
    },
  });

  const eventCardWrapperClass = mergeStyles({
    transition: '.3s ease-in-out all',
    ':hover': {},

    '.meeting-plan-create-button': {
      margin: 0,
    },
  });

  const tableControlsClass = mergeStyles({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '.5rem',
    columnGap: '.25rem',
    padding: '.375rem .5rem calc(.125rem - 1px) .5rem',
    overflowX: 'auto',
    overflowY: 'hidden',
    backgroundColor: isDark
      ? NeutralColors.gray210
      : MEETINGFLOW_COLORS.purpleUltraLight,
    borderBottom: `1px solid ${
      isDark ? NeutralColors.gray170 : MEETINGFLOW_COLORS.purpleGreyLight
    }`,

    '.filters, .toggles': {
      display: 'flex',
      flexDirection: 'row',
      columnGap: '.25rem',
    },

    '.toggles': {
      columnGap: '1rem',
      justifyContent: 'space-between',
    },
  });

  const toggleStyles = {
    root: {
      display: 'inline-block',
      textAlign: 'left',
      whiteSpace: 'nowrap',
      position: 'relative',
      top: '-.4rem',
      marginBottom: '0',
      minWidth: '6rem',
      '.ms-Toggle-innerContainer': {
        display: 'inline-block',
        whiteSpace: 'nowrap',
        textAlign: 'left',
        marginTop: '.25rem',
        button: {
          transition: 'all .3s ease-in-out',
          backgroundColor: isDark
            ? NeutralColors.gray180
            : MEETINGFLOW_COLORS.purpleGrey,
        },
      },
      'label, button': {
        marginRight: '.25rem',
        textAlign: 'left',
        fontSize: FontSizes.small,
        color: NeutralColors.gray120,
        border: 'none !important',
      },
      label: {
        height: '.75rem',
        lineHeight: '.75rem',
        fontSize: FontSizes.mini,
        marginBottom: '.5rem',
        color: NeutralColors.gray120,
      },
      button: {
        position: 'relative',
        top: '.1rem',
      },

      'button[aria-checked="false"]': {
        '.ms-Toggle-thumb': {
          backgroundColor: MEETINGFLOW_COLORS.purpleLight,
        },
      },

      '.ms-Toggle-thumb': {
        backgroundColor: MEETINGFLOW_COLORS.purpleMedium,
      },
      'button:disabled .ms-Toggle-thumb': {
        backgroundColor: isDark ? NeutralColors.gray170 : NeutralColors.gray70,
      },
      'button:hover': {
        backgroundColor: isDark
          ? NeutralColors.gray190
          : MEETINGFLOW_COLORS.purpleLight,

        '.ms-Toggle-thumb': {
          backgroundColor: MEETINGFLOW_COLORS.purpleSecondary,
        },
      },
    },
  } as Partial<IToggleStyles>;

  const searchBoxStyles = {
    root: {
      maxWidth: '15rem',
      marginBottom: '.5rem',
      marginTop: '1rem',
      flexGrow: 1,
      transition: '.3s ease-in-out all',
      height: '1.5rem',
      borderRadius: '.25rem',
      backgroundColor: isDark
        ? NeutralColors.gray180
        : MEETINGFLOW_COLORS.purpleGrey,
      border: 'none',
      ':after': {
        border: 'none',
      },
    },
    field: {
      height: '1.25rem',
      borderRadius: '.25rem',
      fontSize: FontSizes.small,
      color: isDark ? NeutralColors.gray100 : MEETINGFLOW_COLORS.purpleMedium,

      '::placeholder': {
        color: isDark ? NeutralColors.gray100 : MEETINGFLOW_COLORS.purpleMedium,
      },
    },
  } as Partial<ISearchBoxStyles>;

  const comboBoxStyles = {
    root: {
      marginBottom: '.5rem',
      flexGrow: 1,
      transition: '.3s ease-in-out all',
      height: '1.5rem',
      lineHeight: '1.5rem',
      fontSize: FontSizes.small,
      backgroundColor: isDark
        ? NeutralColors.gray200
        : MEETINGFLOW_COLORS.purpleGrey,
      border: 'none',
      borderRadius: '.25rem',
      minWidth: '5rem',

      ':after': {
        display: 'none !important',
      },

      '.ms-Icon': {
        color: MEETINGFLOW_COLORS.purpleMedium,
      },

      '.ms-ComboBox-CaretDown-button': {
        backgroundColor: isDark
          ? NeutralColors.gray200
          : MEETINGFLOW_COLORS.purpleGrey,
      },
    },
    input: {
      backgroundColor: 'transparent',
      color: isDark ? NeutralColors.gray100 : NeutralColors.gray130,
      '::placeholder': {
        color: isDark ? NeutralColors.gray100 : MEETINGFLOW_COLORS.purpleMedium,
      },
    },
  } as Partial<IComboBoxStyles>;

  const datePickerStyles = {
    textField: {
      marginBottom: '.5rem',
      flexGrow: 1,
      transition: '.3s ease-in-out all',

      '> span': {
        display: 'none !important',
      },

      'div[role="combobox"]': {
        height: '1.5rem',
        lineHeight: '1.5rem',
        fontSize: FontSizes.small,
        backgroundColor: isDark
          ? NeutralColors.gray200
          : MEETINGFLOW_COLORS.purpleGrey,
        border: 'none',
        borderRadius: '.25rem',
        minWidth: '3rem',
        color: isDark ? NeutralColors.gray100 : MEETINGFLOW_COLORS.purpleMedium,

        label: {
          color: isDark
            ? NeutralColors.gray100
            : MEETINGFLOW_COLORS.purpleMedium,
        },

        '*': {
          color: isDark
            ? NeutralColors.gray100
            : MEETINGFLOW_COLORS.purpleMedium,
        },
      },
    },
    root: {
      height: '1.5rem',
      minWidth: '5rem',
      flexBasis: '25%',
      div: {
        height: '1.5rem',
        border: 'none !important',
        borderRadius: '.25rem !important',
        backgroundColor: 'tnasparent',

        ':after': {
          display: 'none !important',
        },
      },

      '.ms-Label': {
        margin: '0 0 .25rem 0',
        padding: 0,
        lineHeight: '.75rem',
        height: '.75rem',
        color: isDark ? NeutralColors.gray100 : NeutralColors.gray120,
        fontSize: FontSizes.mini,
      },
    },

    icon: {
      margin: '.2rem 0 0 0',
      padding: 0,
      color: MEETINGFLOW_COLORS.purpleMedium,
    },
  } as Partial<IDatePickerStyles>;

  const clearButtonStyles = {
    root: {
      display: 'none',
      height: '1.5rem',
      position: 'relative',
      top: '-.125rem',
      backgroundColor: isDark
        ? MEETINGFLOW_COLORS.purpleDarkest
        : MEETINGFLOW_COLORS.purpleGrey,
      border: 'none !important',
      transition: '.3s ease-in-out all',
      'div:after': {
        display: 'none !important',
      },
    },
    rootDisabled: {
      backgroundColor: 'transparent',
      cursor: 'not-allowed',
    },
    rootHovered: {
      backgroundColor: MEETINGFLOW_COLORS.purpleMedium,

      i: {
        color: MEETINGFLOW_COLORS.white,
      },
    },
    icon: {
      color: MEETINGFLOW_COLORS.purpleMedium,
    },
  } as Partial<IButtonStyles>;

  const dropdownStyles = {
    root: {
      flexGrow: 1,
      transition: '.3s ease-in-out all',
      marginTop: '-.25rem',
    },
    dropdown: {
      height: '1.5rem',
      lineHeight: '1.5rem',
      fontSize: FontSizes.small,
      backgroundColor: isDark
        ? NeutralColors.gray200
        : MEETINGFLOW_COLORS.purpleGrey,
      border: 'none',
      borderRadius: '.25rem',
    },
    title: {
      height: '1.5rem',
      lineHeight: '1.25rem',
      backgroundColor: isDark
        ? NeutralColors.gray180
        : MEETINGFLOW_COLORS.purpleGrey,
      border: 'none',
      borderRadius: '.25rem',
      color: isDark ? NeutralColors.gray80 : MEETINGFLOW_COLORS.purpleDark,
    },
    caretDownWrapper: {
      height: '1.5rem',
      lineHeight: '1.5rem',
    },
    label: {
      padding: 0,
      height: '.75rem',
      lineHeight: '.75rem',
      fontSize: FontSizes.mini,
      marginBottom: '.25rem',
      color: NeutralColors.gray120,
    },
  };

  const meetingflowsControlsClass = mergeStyles({
    position: 'sticky',
    top: 0,
    backgroundColor: isDark
      ? NeutralColors.gray200
      : MEETINGFLOW_COLORS.purpleUltraLight,
    height: '3.25rem',
    overflow: 'hidden',
    display: 'flex',
    columnGap: '.5rem',
    padding: '0 .5rem',
  });

  const getIsExternalForEventOrMeetingflow = (
    eventOrMeetingflow: CalendarEvent | Meetingflow,
  ) => {
    const hasExternalCompany = eventOrMeetingflow.attendees.some(
      (attendee) => !internalDomains.includes(attendee.emailDomain),
    );
    return hasExternalCompany;
  };

  const getCardForEvent = (event: CalendarEvent) => {
    const eventIsInPast = DateTime.now() > DateTime.fromISO(event.endTime);

    const eventIsNow =
      DateTime.now() > DateTime.fromISO(event.startTime) &&
      DateTime.now() < DateTime.fromISO(event.endTime);

    const eventIsSoon =
      DateTime.fromISO(event.startTime).diffNow('minutes').minutes < 60;

    const eventIsNowOrSoon = !eventIsInPast && (eventIsNow || eventIsSoon);

    return (
      <EventCard
        event={event}
        key={event.iCalUid}
        organizationSlug={organizationSlug}
        showCompanies
        showTimesOnly
        createMeetingflowOnClick
      />
    );
  };

  const getCardForMeetingflow = (planId: string) => {
    return (
      <MeetingflowCard
        meetingflowId={planId}
        organizationSlug={organizationSlug!}
        showCompanies
        showCallRecordingButton
        // showCreator
        showTimesOnly
        onClick={() => {
          appInsights.trackEvent({
            name: 'MEETINGFLOW_BROWSER_OPEN_MEETINGFLOW',
            properties: {
              organizationSlug,
              meetingPlanId: planId,
              emailAddress: user?.email,
              destination: 'SIDE_PANEL',
            },
          });
          onClickMeetingflow
            ? onClickMeetingflow(planId)
            : navigate(`/organization/${organizationSlug}/plan/${planId}`);
        }}
      />
    );
  };

  const getCardForObject = (obj: CalendarEvent | Meetingflow) => {
    // If it's just an event, get the EventCard
    if ('rawEvent' in obj && !obj.meetingplanId) {
      return getCardForEvent(obj as CalendarEvent);
      // If it's an event with a meetingplanId, get the MeetingflowCard
    } else if ('rawEvent' in obj && obj.meetingplanId) {
      return getCardForMeetingflow(obj.meetingplanId);
    } else if ('id' in obj) {
      // If it's a meetingflow, get the MeetingflowCard
      return getCardForMeetingflow(obj.id);
    }
  };

  const MeetingflowControls = (
    <div className={tableControlsClass}>
      {/* <SearchBox
        styles={searchBoxStyles}
        defaultValue={getValue(searchParams, 'q')}
        placeholder="Search..."
        onChange={(_event, text) => {
          if (!!text) {
            setSearchParams(setValue(searchParams, 'q', text));
          } else {
            setSearchParams(deleteValue(searchParams, 'q'));
          }
        }}
      /> */}

      <div className="filters">
        <DatePicker
          styles={datePickerStyles}
          value={
            getDateValue(searchParams, 'minDate') ||
            DateTime.fromISO(DEFAULT_MIN_DATE).toJSDate()
          }
          placeholder="From date..."
          ariaLabel="Select a Minimum date"
          label="Date range"
          onSelectDate={(date) => {
            if (!!date) {
              setSearchParams(
                setValue(
                  searchParams,
                  'minDate',
                  DateTime.fromJSDate(date).startOf('day').toISO()!,
                ),
              );
            } else {
              setSearchParams(deleteValue(searchParams, 'minDate'));
            }
          }}
        />

        <DatePicker
          styles={datePickerStyles}
          value={
            getDateValue(searchParams, 'maxDate') ||
            DateTime.fromISO(DEFAULT_MAX_DATE).toJSDate()
          }
          placeholder="To date..."
          ariaLabel="Select a maximum date"
          label=" "
          onSelectDate={(date) => {
            if (!!date) {
              setSearchParams(
                setValue(
                  searchParams,
                  'maxDate',
                  DateTime.fromJSDate(date).endOf('day').toISO()!,
                ),
              );
            } else {
              setSearchParams(deleteValue(searchParams, 'maxDate'));
            }
          }}
        />
        <Dropdown
          label="Sort By"
          options={[
            {
              key: 'startTime',
              text: breakpoints.lg ? 'Meeting Start' : 'Mtg Start',
            },
            {
              key: 'createdAt',
              text: breakpoints.lg ? 'Meetingflow Created' : 'Created',
            },
            {
              key: 'updatedAt',
              text: breakpoints.lg ? 'Meetingflow Updated' : 'Updated',
            },
          ]}
          onChange={(_, option) => {
            appInsights.trackEvent({
              name: 'MEETINGFLOW_BROWSER_CHANGE_CONTROLS',
              properties: {
                property: 'sortBy',
                organizationSlug,
                emailAddress: user?.email,
                sortBy: option?.key,
              },
            });

            setSortBy(option?.key as 'startTime' | 'createdAt' | 'updatedAt');
          }}
          selectedKey={sortBy}
          styles={dropdownStyles}
        />

        <Dropdown
          label="Sort Order"
          options={[
            { key: 'desc', text: breakpoints.lg ? 'Descending' : 'Desc' },
            { key: 'asc', text: breakpoints.lg ? 'Ascending' : 'Asc' },
          ]}
          onChange={(_, option) => {
            appInsights.trackEvent({
              name: 'MEETINGFLOW_BROWSER_CHANGE_CONTROLS',
              properties: {
                property: 'sortOrder',
                organizationSlug,
                emailAddress: user?.email,
                sortOrder: 'option?.key',
              },
            });

            setSortOrder(option?.key as 'desc' | 'asc');
          }}
          selectedKey={sortOrder}
          styles={dropdownStyles}
        />

        {/* <ComboBox
        style={{ maxWidth: '10rem' }}
        styles={comboBoxStyles}
        multiSelect={!!membersData?.data?.length}
        options={
          membersData?.data?.map((user) => ({
            key: user.id.toString(),
            text: user.name || user.email,
          })) ?? [
            {
              key: 'none',
              text: 'No Meetingflow creators',
              disabled: true,
            },
          ]
        }
        selectedKey={
          searchParams.has('owners') ? searchParams.getAll('owners') : []
        }
        placeholder="Meetingflow created by..."
        onChange={(_event, owner) => {
          if (!!owner) {
            if (!!owner.selected) {
              setSearchParams(
                addArrayValue(searchParams, 'owners', owner.key.toString()),
              );
            } else {
              setSearchParams(
                removeArrayValue(searchParams, 'owners', owner.key.toString()),
              );
            }
          } else {
            setSearchParams(removeAllArrayValues(searchParams, 'owners'));
          }
        }}
      /> */}

        {/* <ComboBox
        styles={comboBoxStyles}
        multiSelect
        options={
          organizersData?.data?.map((contact) => ({
            key: contact.id.toString(),
            text: contact.name || contact.email,
          })) ?? [
            {
              key: 'none',
              text: 'No Meetingflow organizers',
              disabled: true,
            },
          ]
        }
        selectedKey={
          searchParams.has('organizers')
            ? searchParams.getAll('organizers')
            : []
        }
        placeholder="Event organized by..."
        onChange={(_event, organizer) => {
          if (!!organizer) {
            if (!!organizer.selected) {
              setSearchParams(
                addArrayValue(
                  searchParams,
                  'organizers',
                  organizer.key.toString(),
                ),
              );
            } else {
              setSearchParams(
                removeArrayValue(
                  searchParams,
                  'organizers',
                  organizer.key.toString(),
                ),
              );
            }
          } else {
            setSearchParams(removeAllArrayValues(searchParams, 'organizers'));
          }
        }}
      /> */}

        {/* <ComboBox
        styles={comboBoxStyles}
        multiSelect
        allowFreeform
        options={
          companyData?.data?.map((company: Company) => ({
            key: company.id.toString(),
            text:
              company.name?.slice(0, 25) ??
              company?.legalName?.slice(0, 25) ??
              company.id?.toString(),
          })) ?? [
            {
              key: 'none',
              text: 'No companies',
              disabled: true,
            },
          ]
        }
        selectedKey={
          searchParams.has('companies') ? searchParams.getAll('companies') : []
        }
        placeholder="With companies..."
        onChange={(_event, company) => {
          if (!!company) {
            if (!!company.selected) {
              setSearchParams(
                addArrayValue(
                  searchParams,
                  'companies',
                  company.key.toString(),
                ),
              );
            } else {
              setSearchParams(
                removeArrayValue(
                  searchParams,
                  'companies',
                  company.key.toString(),
                ),
              );
            }
          } else {
            setSearchParams(removeAllArrayValues(searchParams, 'companies'));
          }
        }}
      /> */}

        {/* <ComboBox
        styles={comboBoxStyles}
        multiSelect
        allowFreeform
        options={[
          ...(contactsData?.data
            ? contactsData?.data?.map((contact) => ({
                key: contact.id.toString(),
                text: contact.name || contact.email,
              })) ?? []
            : [
                {
                  key: 'none',
                  text: 'No attendees',
                  disabled: true,
                },
              ]),
        ]}
        selectedKey={getArrayValues(searchParams, 'attendees')}
        placeholder="With attendees..."
        onChange={(_event, attendee) => {
          if (!!attendee) {
            if (!!attendee.selected) {
              const newParams = addArrayValue(
                searchParams,
                'attendees',
                attendee.key.toString(),
              );
              // @ts-ignore
              setSearchParams(mergeSearchParams(searchParams, newParams));
            } else {
              const newParams = removeArrayValue(
                searchParams,
                'attendees',
                attendee.key.toString(),
              );
              // @ts-ignore
              setSearchParams(mergeSearchParams(searchParams, newParams));
            }
          } else {
            const newParams = removeAllArrayValues(searchParams, 'attendees');
            // @ts-ignore
            setSearchParams(mergeSearchParams(searchParams, newParams));
          }
        }}
      /> */}
      </div>

      <div className={'toggles'}>
        <Toggle
          label={'Only External'}
          styles={toggleStyles}
          checked={externalOnly}
          onChange={(_e, checked) => {
            appInsights.trackEvent({
              name: 'MEETINGFLOW_BROWSER_CHANGE_CONTROLS',
              properties: {
                property: 'externalOnly',
                organizationSlug,
                emailAddress: user?.email,
                externalOnly: !!checked,
              },
            });

            updateUserProfile({
              preferenceAgendaMeetingFilter: checked
                ? 'EXTERNAL_ONLY'
                : 'SHOW_ALL',
            });
          }}
          role="checkbox"
          className="toggle-internal"
        />

        <Toggle
          label={'Only Mine'}
          styles={toggleStyles}
          checked={onlyMine}
          onChange={(_e, checked) => {
            appInsights.trackEvent({
              name: 'MEETINGFLOW_BROWSER_CHANGE_CONTROLS',
              properties: {
                property: 'onlyMine',
                organizationSlug,
                emailAddress: user?.email,
                onlyMine: !!checked,
              },
            });

            setOnlyMine(checked ?? false);
          }}
          role="checkbox"
          className="toggle-mine"
        />

        <Toggle
          label={'Show Ad-hoc'}
          styles={toggleStyles}
          checked={includeAdHoc}
          onChange={(_e, checked) => {
            appInsights.trackEvent({
              name: 'MEETINGFLOW_BROWSER_CHANGE_CONTROLS',
              properties: {
                property: 'includeAdHoc',
                organizationSlug,
                emailAddress: user?.email,
                includeAdHoc: !!checked,
              },
            });

            setIncludeAdHoc(checked ?? false);
          }}
          role="checkbox"
          className="toggle-calendar"
        />

        {hasEntitlement('CALL_RECORDING') ? (
          <Toggle
            disabled={plansLoading || plansRefetching}
            label={'Has Recording'}
            checked={hasRecording}
            onChange={(e, checked) => {
              appInsights.trackEvent({
                name: 'MEETINGFLOW_BROWSER_CHANGE_CONTROLS',
                properties: {
                  property: 'hasRecording',
                  organizationSlug,
                  emailAddress: user?.email,
                  hasRecording: !!checked,
                },
              });

              setHasRecording(checked ?? false);
            }}
            styles={toggleStyles}
          />
        ) : null}

        <IconButton
          iconProps={{ iconName: 'ClearFilter' }}
          label="Clear filters"
          alt="Clear filters"
          disabled={Array.from(searchParams.keys()).length === 0}
          onClick={() => setSearchParams(new URLSearchParams())}
          styles={clearButtonStyles}
        />
      </div>
    </div>
  );

  return (
    <>
      {MeetingflowControls}
      <div className={dateGroupClass}>
        {mergedList.map((day, idx) => {
          const isToday = DateTime.fromISO(day.date).hasSame(
            DateTime.now(),
            'day',
          );

          return (
            <>
              <div key={day.date}>
                <span
                  className={classNames(
                    dateGroupHeaderClass,
                    isToday ? 'today' : '',
                  )}
                >
                  {humanizeDateTime(day.date, {
                    displayComponents: ['date'],
                    useRelativeDates: false,
                    dateFormatOptions: {
                      weekday: 'long',
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                    },
                  })}

                  <span className="relative-date">
                    {humanizeDateTime(day.date, {
                      displayComponents: ['relativeDays'],
                      useRelativeDates: false,
                      dateFormatOptions: {
                        weekday: 'long',
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric',
                      },
                    })}
                  </span>
                </span>
                <div className={cardsWrapperClass}>
                  <div className="day">
                    {day.combined.length
                      ? day.combined.map((e) => {
                          return (
                            <>
                              <div
                                key={`${e.startTime}`}
                                className={classNames(
                                  'row',
                                  getIsExternalForEventOrMeetingflow(e)
                                    ? 'external'
                                    : '',
                                )}
                              >
                                {showOpenInMainColumnButton ? (
                                  <div className="open-in-main-panel-button">
                                    <IconButton
                                      iconProps={{
                                        iconName: 'DoubleChevronLeft8',
                                      }}
                                      title="Open in main panel"
                                      ariaLabel="Open in main panel"
                                      styles={{
                                        root: {
                                          transition: '.3s ease-in-out all',
                                          color: MEETINGFLOW_COLORS.purpleGrey,
                                          margin: 'auto',
                                          position: 'relative',
                                          top: '4px',
                                        },
                                        icon: {
                                          fontSize: '14px',
                                        },
                                      }}
                                      onClick={() => {
                                        appInsights.trackEvent({
                                          name: hasOwnProperty(e, 'id')
                                            ? 'MEETINGFLOW_BROWSER_OPEN_MEETINGFLOW'
                                            : 'MEETINGFLOW_BROWSER_OPEN_EVENT',
                                          properties: {
                                            organizationSlug,
                                            meetingPlanId: hasOwnProperty(
                                              e,
                                              'id',
                                            )
                                              ? e?.id
                                              : 'none',
                                            emailAddress: user?.email,
                                            destination: 'main-column',
                                          },
                                        });
                                        hasOwnProperty(e, 'id')
                                          ? navigate(
                                              `/organization/${organizationSlug}/plan/${e?.id}`,
                                            )
                                          : navigate(
                                              `/organization/${organizationSlug}/plan/create/?calendarProvider=${
                                                isGoogleUser(user!.sub!)
                                                  ? 'GOOGLE'
                                                  : 'M365'
                                              }&eventId=${e?.iCalUid}`,
                                            );
                                      }}
                                    />
                                  </div>
                                ) : null}
                                <div
                                  title={
                                    hasOwnProperty(e, 'id')
                                      ? 'Open Meetingflow in Side Panel'
                                      : 'Create Meetingflow and open in Main Panel'
                                  }
                                  className={`${
                                    hasOwnProperty(e, 'id')
                                      ? 'meetingflow-card-wrapper'
                                      : 'event-card-wrapper'
                                  }`}
                                >
                                  {getCardForObject(e)}
                                </div>
                                <div className="on-my-calendar">
                                  {e.externalId ? (
                                    <FontIcon
                                      iconName="Calendar"
                                      title={'Event is on your calendar'}
                                    />
                                  ) : null}
                                </div>
                              </div>
                            </>
                          );
                        })
                      : null}
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};
