import { useAuth0 } from '@auth0/auth0-react';
import {
  Checkbox,
  ComboBox,
  FontSizes,
  FontWeights,
  IComboBoxOption,
  IDropdownOption,
  NeutralColors,
  Text,
} from '@fluentui/react';
import {
  DetailedMeetingflow,
  Task,
} from '@meetingflow/common/Api/data-contracts';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { sortBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import 'react-customize-token-input/dist/react-customize-token-input.css';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { useDebounce } from 'use-debounce';
import * as Y from 'yjs';
import { hasContent } from '../../../Helpers/yjsHelpers';
import { useExternalServiceConfigurations } from '../../../Hooks/useExternalServiceConfigurations';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { useLocalStorageState } from '../../../Hooks/useLocalStorageState';
import { useUserProfile } from '../../../Hooks/useProfile';
import { TeamsChannelsQuery, TeamsTeamsQuery } from '../../../QueryNames';
import { ExternalServicesApiClient } from '../../../Services/NetworkCommon';
import MeetingPlanNotesSummaryForm from '../../Common/NotesSummaryForm';
import { AsyncPrimaryButton } from '../../HOC/AsyncButton';
import { BaseSidePanel } from './BaseSidePanel';

export interface TeamsSidePanelProps {
  organizationSlug: string;
  meetingPlanId: string;
  internalDomains: string[];
  tasks: Task[];
  meetingPlan: Pick<
    DetailedMeetingflow,
    | 'id'
    | 'title'
    | 'startTime'
    | 'organizer'
    | 'creator'
    | 'attendees'
    | 'companies'
    | 'textSummary'
    | 'callRecording'
  >;
  notesYArray: Y.XmlText;
  initialSummary?: string;
  onBack?: () => void;
  onClose: () => void;
  onSendSummary: () => Promise<unknown>;
}

export const TeamsSidePanel = ({
  organizationSlug,
  meetingPlanId,
  internalDomains,
  notesYArray,
  meetingPlan,
  tasks,
  initialSummary,
  onBack,
  onClose,
  onSendSummary,
}: TeamsSidePanelProps) => {
  const { isDark } = useLightOrDarkMode();
  const { getAccessTokenSilently } = useAuth0();
  const { user } = useUserProfile();

  const appInsights = useAppInsightsContext();

  const { hasToken, configurationsWithToken } =
    useExternalServiceConfigurations({
      app: 'TEAMS',
      withToken: true,
    });

  const [activeConfigurationId, setActiveConfigurationId] = useState<
    number | undefined
  >(configurationsWithToken[0]?.id);
  const [activeTeamId, setActiveTeamId] = useState<string | undefined>();
  const [activeChannelId, setActiveChannelId] = useState<string | undefined>();

  const [teamSearchTerm, setTeamSearchTerm] = useState<string>('');
  const [debouncedTeamSearchTerm] = useDebounce(teamSearchTerm, 250);

  const [channelSearchTerm, setChannelSearchTerm] = useState<string>('');
  const [debouncedChannelSearchTerm] = useDebounce(channelSearchTerm, 250);

  const [summary, setSummary] = useState<string | undefined>(
    initialSummary || meetingPlan?.textSummary || '',
  );
  const [gptSummary, setGPTSummary] = useState<string | undefined>('');

  const [includeCallInsights, setIncludeCallInsights] =
    useLocalStorageState<boolean>('teams-include-call-insights', true);

  const {
    data: teams,
    isLoading: teamsLoading,
    isFetched: teamsFetched,
    refetch: refetchTeams,
  } = useQuery(
    TeamsTeamsQuery(organizationSlug, activeConfigurationId),
    async () => {
      const token = await getAccessTokenSilently();
      return ExternalServicesApiClient.listTeamsTeams(
        { organizationSlug, configurationId: activeConfigurationId! },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    { cacheTime: 0, enabled: !!activeConfigurationId && hasToken() },
  );

  const {
    data: channels,
    isLoading: channelsLoading,
    isFetched: channelsFetched,
    refetch: refetchChannels,
  } = useQuery(
    TeamsChannelsQuery(organizationSlug, activeConfigurationId, activeTeamId),
    async () => {
      const token = await getAccessTokenSilently();
      return ExternalServicesApiClient.listTeamsTeamChannels(
        {
          organizationSlug,
          configurationId: activeConfigurationId!,
          teamId: activeTeamId!,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      cacheTime: 0,
      enabled: !!activeConfigurationId && !!activeTeamId && hasToken(),
    },
  );

  useEffect(() => {
    if (
      configurationsWithToken.length === 1 &&
      activeConfigurationId !== configurationsWithToken[0].id
    ) {
      setActiveConfigurationId(configurationsWithToken[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configurationsWithToken]);

  useEffect(() => {
    if (teams?.data?.length === 1 && activeTeamId !== teams.data[0].id) {
      setTeamSearchTerm('');
      setChannelSearchTerm('');
      setActiveTeamId(teams.data[0].id);
    }

    if (
      activeTeamId &&
      !teams?.data?.some((team) => team.id === activeTeamId)
    ) {
      setTeamSearchTerm('');
      setChannelSearchTerm('');
      setActiveTeamId(undefined);

      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teams?.data]);

  useEffect(() => {
    if (
      channels?.data?.length === 1 &&
      activeChannelId !== channels.data[0].id
    ) {
      setChannelSearchTerm('');
      setActiveChannelId(channels.data[0].id);

      return;
    }

    if (
      activeChannelId &&
      !channels?.data?.some((channel) => channel.id === activeChannelId)
    ) {
      setChannelSearchTerm('');
      setActiveChannelId(undefined);

      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channels?.data]);

  useEffect(() => {
    if (hasToken() && !!activeConfigurationId) {
      refetchTeams();
    }
  }, [activeConfigurationId, hasToken, refetchTeams]);

  useEffect(() => {
    if (hasToken() && !!activeConfigurationId && !!activeTeamId) {
      refetchChannels();
    }
  }, [activeConfigurationId, activeTeamId, hasToken, refetchChannels]);

  const teamOptions: IComboBoxOption[] = useMemo(() => {
    const sortedTeams = sortBy(teams?.data ?? [], (team) => team.displayName);

    const searchTerm = debouncedTeamSearchTerm.trim().toLowerCase();

    return sortedTeams
      .filter(
        (team) =>
          !searchTerm || team.displayName.toLowerCase().includes(searchTerm),
      )
      .map(
        (team) =>
          ({
            key: team.id,
            text: team.displayName,
            title: team.displayName,
          }) satisfies IDropdownOption,
      );
  }, [teams?.data, debouncedTeamSearchTerm]);

  const channelOptions: IComboBoxOption[] = useMemo(() => {
    const sortedChannels = sortBy(
      channels?.data ?? [],
      (channel) => channel.displayName,
    );

    const searchTerm = debouncedChannelSearchTerm.trim().toLowerCase();

    return sortedChannels
      .filter(
        (channel) =>
          !searchTerm || channel.displayName.toLowerCase().includes(searchTerm),
      )
      .map(
        (channel) =>
          ({
            key: channel.id,
            text: channel.displayName,
            title: channel.displayName,
          }) satisfies IDropdownOption,
      );
  }, [channels?.data, debouncedChannelSearchTerm]);

  const planURL = `${window.origin}/organization/${organizationSlug}/plan/${meetingPlan.id}`;

  return (
    <BaseSidePanel title="Microsoft Teams" onBack={onBack} onClose={onClose}>
      <Text
        block
        style={{
          fontSize: FontSizes.small,
          color: NeutralColors.gray120,
        }}
      >
        Post your Meetingflow link and summary to a Microsoft Teams channel to
        let others know about it.{' '}
      </Text>

      {!!teams?.data?.length ? (
        <>
          <Text
            block
            variant="mediumPlus"
            style={{
              fontWeight: FontWeights.semibold,
              margin: '1rem 0 .5rem 0',
            }}
          >
            Team:
          </Text>
          <div>
            <ComboBox
              disabled={teamsLoading && !teamsFetched}
              errorMessage={
                activeTeamId &&
                teams?.data &&
                !teams?.data.some((c) => c.id === activeTeamId)
                  ? 'Please select a valid team'
                  : undefined
              }
              placeholder={'Select a team...'}
              selectedKey={activeTeamId ?? null}
              options={teamOptions}
              allowFreeInput
              autoComplete="off"
              style={{ marginBottom: '1rem' }}
              onInputValueChange={setTeamSearchTerm}
              openOnKeyboardFocus
              calloutProps={{ alignTargetEdge: true }}
              onChange={(_e, o) => {
                if (o) {
                  setTeamSearchTerm('');
                  setChannelSearchTerm('');
                  setActiveTeamId(o.key as string);
                } else if (teamSearchTerm) {
                  const matchingOption = teamOptions?.find(
                    (c) =>
                      (c.text as string).toLocaleLowerCase() ===
                      teamSearchTerm.toLowerCase(),
                  );
                  if (matchingOption) {
                    setTeamSearchTerm('');
                    setChannelSearchTerm('');
                    setActiveTeamId(matchingOption.key as string);
                  }
                }
              }}
            />
          </div>
        </>
      ) : null}

      <Text
        block
        variant="mediumPlus"
        style={{ fontWeight: FontWeights.semibold, margin: '1rem 0 .5rem 0' }}
      >
        Channel:
      </Text>
      <div>
        <ComboBox
          disabled={channelsLoading && !channelsFetched}
          errorMessage={
            activeChannelId &&
            channels?.data &&
            !channels?.data.some((c) => c.id === activeChannelId)
              ? 'Please select a valid channel'
              : undefined
          }
          allowFreeInput
          placeholder={'Select a channel...'}
          selectedKey={activeChannelId ?? null}
          options={channelOptions}
          autoComplete="off"
          style={{ marginBottom: '1rem' }}
          onInputValueChange={setChannelSearchTerm}
          openOnKeyboardFocus
          calloutProps={{ alignTargetEdge: true }}
          onChange={(_e, o) => {
            if (o) {
              setActiveChannelId(o.key as string);
              setChannelSearchTerm('');
            } else if (channelSearchTerm) {
              const matchingOption = channelOptions?.find(
                (c) =>
                  (c.text as string).toLowerCase() ===
                  channelSearchTerm.toLowerCase(),
              );
              if (matchingOption) {
                setActiveChannelId(matchingOption.key as string);
                setChannelSearchTerm('');
              }
            }
          }}
        />
      </div>
      <MeetingPlanNotesSummaryForm
        organizationSlug={organizationSlug}
        meetingplan={meetingPlan}
        summaryType={'TEAMS_UPDATE'}
        displaySubject={false}
        bodyLabel="Message"
        summarizeDisabled={
          !hasContent(notesYArray) &&
          !meetingPlan?.callRecording?.transcript?.length
        }
        onSummaryChange={setSummary}
        onSummaryGenerated={setGPTSummary}
        surfaceName="TEAMS_SUMMARY"
        initialSummaryValue={initialSummary || meetingPlan.textSummary || ''}
        summarizeLabel={'Generate a summary'}
      />

      {meetingPlan?.callRecording?.transcriptAnalysis?.topics?.length ? (
        <div style={{ gridArea: 'include-call-insights' }}>
          <Checkbox
            title="Include Call Insights"
            label="Include Call Insights"
            checked={includeCallInsights}
            onChange={(_e, checked) => setIncludeCallInsights(!!checked)}
          />
        </div>
      ) : null}

      <div style={{ textAlign: 'right' }}>
        <AsyncPrimaryButton
          text="Share via Microsoft teams"
          disabled={
            !activeConfigurationId ||
            !activeTeamId ||
            !activeChannelId ||
            !summary
          }
          onClick={async () => {
            if (
              !activeConfigurationId ||
              !activeTeamId ||
              !activeChannelId ||
              !summary
            ) {
              return;
            }

            try {
              const token = await getAccessTokenSilently();

              await toast.promise(
                ExternalServicesApiClient.sendMeetingflowSummaryToTeamsChannel(
                  organizationSlug,
                  activeConfigurationId,
                  activeTeamId,
                  activeChannelId,
                  {
                    meetingflowId: meetingPlanId,
                    message: summary,
                    gptSummary,
                    includeCallInsights,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  },
                ),
                {
                  loading: 'Sharing to Microsoft Teams',
                  success: () => {
                    appInsights.trackEvent({
                      name: 'SEND_TEAMS_SUMMARY',
                      properties: {
                        organizationSlug,
                        meetingPlanId,
                      },
                    });

                    return `Shared the summary with ${
                      channelOptions.find((c) => c.key === activeChannelId)
                        ?.text
                    } via Microsoft Teams!`;
                  },
                  error: (err) => {
                    return 'Something went wrong sharing the summary to Microsoft Teams, please try again';
                  },
                },
              );

              onSendSummary();
            } catch {}
          }}
          styles={{
            label: { color: isDark ? 'white' : undefined },
          }}
        />
      </div>
    </BaseSidePanel>
  );
};
