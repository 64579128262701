import { NeutralColors } from '@fluentui/theme';
import {
  Contact,
  DetailedMeetingflow,
} from '@meetingflow/common/Api/data-contracts';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { HubSpotPlanContext } from '../../../Models/HubSpot/HubSpotPlanContext';
import { SalesforcePlanContext } from '../../../Models/Salesforce/SalesforcePlanContext';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { MeetingPlanAttendees } from '../MeetingPlanAttendees';
import { BaseSidePanel } from './BaseSidePanel';

export type ContactsSidePanelProps = {
  meetingPlan:
    | Pick<
        DetailedMeetingflow,
        | 'id'
        | 'externalId'
        | 'startTime'
        | 'endTime'
        | 'title'
        | 'creator'
        | 'organizer'
        | 'attendees'
        | 'companies'
        | 'viewStats'
        | 'callRecording'
      >
    | undefined;
  internalDomains: string[];
  organizationSlug: string;
  salesforcePlanContext?: SalesforcePlanContext;
  hubspotPlanContext?: HubSpotPlanContext;
  onContactClick: (c: Contact['id']) => void;
  onCompanyClick: (id: number) => void;
  onBack?: () => void;
  onClose: () => void;
  refetchMeetingPlan: () => Promise<unknown>;
};
export const ContactsSidePanel = ({
  meetingPlan,
  internalDomains,
  organizationSlug,
  salesforcePlanContext,
  hubspotPlanContext,
  onContactClick,
  onCompanyClick,
  onBack,
  onClose,
  refetchMeetingPlan,
}: ContactsSidePanelProps) => {
  const { isDark } = useLightOrDarkMode();

  const isAssociatedWithEvent = meetingPlan?.externalId;

  if (!meetingPlan) {
    return null;
  }

  return (
    <BaseSidePanel title="Attendees" onBack={onBack} onClose={onClose}>
      <MeetingPlanAttendees
        panelVisible={true}
        // displayContactNotes
        organizationSlug={organizationSlug}
        meetingPlan={meetingPlan}
        salesforcePlanContext={salesforcePlanContext}
        hubspotPlanContext={hubspotPlanContext}
        internalDomains={internalDomains}
        onContactClick={onContactClick}
        onCompanyClick={onCompanyClick}
        rowGap="1.5rem"
        allowEditingContactNotes
        showContactSecondaryText={false}
        showAvatars={false}
        allowEditAttendees={!isAssociatedWithEvent}
        refetchMeetingPlan={refetchMeetingPlan}
        contactCardBackgroundColor={
          isDark ? NeutralColors.gray220 : MEETINGFLOW_COLORS.purpleGreyLight
        }
        companyCardBackgroundColor={'transparent'}
      />
    </BaseSidePanel>
  );
};
