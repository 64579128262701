import { mergeStyleSets, Spinner, Stack } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { useCallback, useMemo, useState } from 'react';
import { Milestone } from '../Milestones/Milestone';
import { MilestoneActionItemPanel } from '../Milestones/MilestoneActionItemPanel';
import { useBoolean } from '@fluentui/react-hooks';
import { useMutualPlan } from '../../../Hooks/useMutualPlan';
import { NoMilestonesAvailable } from '../Milestones/NoMilestonesAvailable';
import {
  DealRoomMilestone,
  DealRoomMilestoneType,
} from '@meetingflow/common/Api/data-contracts';
import { useTitle } from '../../../Hooks/useTitle';
import { MilestonesSummary } from '../Milestones/MilestonesSummary';
import { DealRoomTaskCommentsContainer } from '../Milestones/TaskComments/DealRoomTaskCommentsContainer';

type DealRoomMutualProgressTabProps = {
  organizationSlug: string;
  dealRoomId: number;
};

export const DealRoomMutualProgressTab = ({
  organizationSlug,
  dealRoomId,
}: DealRoomMutualProgressTabProps) => {
  useTitle('Mutual Plan');

  const [selectedActionItemId, setSelectedActionItem] = useState<number | null>(
    null,
  );
  const [selectedMilestone, setSelectedMilestone] =
    useState<DealRoomMilestone | null>(null);
  const [noActionItemPanelKey, setNoActionItemPanelKey] = useState(Date.now());
  const [
    isEditing,
    { setTrue: setIsEditingTask, setFalse: setIsNotEditingTask },
  ] = useBoolean(false);

  const [taskIdForComments, setTaskIdForComments] = useState<number | null>(
    null,
  );

  const {
    mutualPlan,
    refetch: refetchMutualPlan,
    isLoading,
  } = useMutualPlan(organizationSlug, dealRoomId);

  const handleActionItemClick = (
    actionItemId: number,
    milestone: DealRoomMilestone,
    isEditing: boolean,
  ) => {
    setSelectedActionItem(actionItemId);
    handleSelectMilestoneForAddTask(milestone);
    if (isEditing) {
      setIsEditingTask();
    }
  };

  // Used to know which milestone to add the task to and to have correct milestone data in the task panel
  const handleSelectMilestoneForAddTask = (
    currentMilestone: DealRoomMilestone,
  ) => {
    setSelectedMilestone(currentMilestone);
  };

  const handlePanelDismiss = () => {
    setSelectedActionItem(null);
    setSelectedMilestone(null);
    setIsNotEditingTask();
  };

  const handleUpdateSelectedMilestoneBasedOnType = useCallback(
    (milestoneType: DealRoomMilestoneType) => {
      if (!Array.isArray(mutualPlan?.milestones)) return;

      const currentMilestone = mutualPlan.milestones.find(
        (milestone) => milestone.type === milestoneType,
      );

      if (!currentMilestone) return;

      setSelectedMilestone(currentMilestone);
    },
    [mutualPlan?.milestones],
  );

  const visibleMilestones = useMemo(() => {
    return (mutualPlan?.milestones || [])
      .filter((milestone) => milestone.visible)
      .sort((a, b) => {
        if (a.dueDate === null && b.dueDate === null) return 0;
        if (a.dueDate === null) return 1;
        if (b.dueDate === null) return -1;
        return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
      });
  }, [mutualPlan?.milestones]);

  const styles = mergeStyleSets({
    mutualProgressWrapper: {
      width: '100%',
      padding: '1.5rem',
      border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
      borderRadius: '.25rem',
      backgroundColor: DEALROOMS_COLORS.neutralGray,
      gap: '2rem',
      // Hide empty MS Layer elements that get injected by Fluent UI
      '.ms-layer:empty': {
        display: 'none',
      },
    },
    milestonesList: {
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
    },
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Stack className={styles.mutualProgressWrapper}>
      {/* <MilestonesSummary
        organizationSlug={organizationSlug}
        dealRoomId={dealRoomId}
      /> */}
      {visibleMilestones?.length ? (
        <div className={styles.milestonesList}>
          {visibleMilestones.map((milestone) => (
            <Milestone
              organizationSlug={organizationSlug}
              dealRoomId={dealRoomId}
              milestone={milestone}
              key={milestone.type}
              onActionItemClick={handleActionItemClick}
              handleSelectMilestoneForAddTask={handleSelectMilestoneForAddTask}
              setNoActionItemPanelKey={setNoActionItemPanelKey}
              setTaskIdForComments={setTaskIdForComments}
            />
          ))}
        </div>
      ) : (
        <NoMilestonesAvailable />
      )}
      <MilestoneActionItemPanel
        key={selectedActionItemId ?? noActionItemPanelKey}
        isOpen={selectedActionItemId !== null || selectedMilestone !== null}
        onDismiss={handlePanelDismiss}
        currentActionItemId={selectedActionItemId}
        organizationSlug={organizationSlug}
        dealRoomId={dealRoomId}
        parentMilestone={selectedMilestone}
        isEditing={isEditing}
        handleUpdateSelectedMilestoneBasedOnType={
          handleUpdateSelectedMilestoneBasedOnType
        }
      />
      <DealRoomTaskCommentsContainer
        key={taskIdForComments}
        currentTaskId={taskIdForComments}
        onDismiss={() => setTaskIdForComments(null)}
        organizationSlug={organizationSlug}
        dealRoomId={dealRoomId}
      />
    </Stack>
  );
};
