import { useNavigate } from 'react-router-dom';
import { Text } from '@fluentui/react';
import { DealRoomPrimaryButton } from './DealRoom/Components/DealRoomButton';
import { ResourceNotFoundIllustration } from './DealRoom/Components/Illustrations/ResourceNotFoundIllustration';
import { useCallback } from 'react';
import { NotFoundLayout } from './Layouts/NotFoundLayout';
import { useNotFoundContentStyles } from '../Hooks/styles/useNotFoundContentStyles';

export type ResourceNotFoundProps = {
  resourceType: string;
};

export const ResourceNotFound = ({ resourceType }: ResourceNotFoundProps) => {
  const navigate = useNavigate();

  const styles = useNotFoundContentStyles();

  const handleClickActionButton = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <NotFoundLayout illustration={<ResourceNotFoundIllustration />}>
      <Text className={styles.title}>404</Text>
      <div className={styles.subtitleContainer}>
        <Text className="subtitle-item">Oops!</Text>
        <Text className="subtitle-item">{resourceType} not found!</Text>
      </div>
      <div className={styles.descriptionContainer}>
        <Text className="description-item">Please return to the main page</Text>
      </div>
      <DealRoomPrimaryButton
        customClasses={styles.actionButton}
        onClick={handleClickActionButton}
      >
        Back to Main Page
      </DealRoomPrimaryButton>
    </NotFoundLayout>
  );
};

export default ResourceNotFound;
