import { useState, useRef, useEffect } from 'react';

export const useStateWithCallback = <T>(
  initialState: T | (() => T),
): [
  T,
  (
    newState: T | ((prevState: T) => T),
    callback?: (newState: T) => void,
  ) => void,
] => {
  const [state, setState] = useState<T>(initialState);
  const callbackRef = useRef<((newState: T) => void) | null>(null);

  useEffect(() => {
    if (callbackRef.current) {
      callbackRef.current(state);
      callbackRef.current = null;
    }
  }, [state]);

  const setStateWithCallback = (
    newState: T | ((prevState: T) => T),
    callback?: (newState: T) => void,
  ) => {
    setState((prevState) => {
      const computedState =
        typeof newState === 'function'
          ? (newState as (prevState: T) => T)(prevState)
          : newState;

      if (callback) {
        callbackRef.current = callback;
      }

      return computedState;
    });
  };

  return [state, setStateWithCallback];
};
