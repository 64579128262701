import { useAuth0 } from '@auth0/auth0-react';
import {
  Dropdown,
  FontSizes,
  FontWeights,
  Icon,
  Link,
  NeutralColors,
  PrimaryButton,
  Spinner,
  Text,
  useTheme,
} from '@fluentui/react';
import { ExternalServiceObjectType } from '@meetingflow/common/Api/data-contracts';
import {
  PickValues,
  fieldDirty,
  getDirtyFields,
  objectIsDirty,
  recordHasOwnProperty,
} from '@meetingflow/common/ObjectHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { isString } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import { isAxiosErrorResponse } from '../../../../Helpers/AxiosHelpers';
import { isSalesforceUpdateError } from '../../../../Helpers/ErrorHelpers';
import { parseLocaleFloat } from '../../../../Helpers/NumberHelpers';
import {
  getFieldNameFieldMap,
  getLayoutFields,
  getYupObjectValidator,
} from '../../../../Helpers/Salesforce/SalesforceFieldHelpers';
import { validateData } from '../../../../Helpers/Salesforce/SalesforceYupValidators';
import { useExternalServiceConfigurations } from '../../../../Hooks/useExternalServiceConfigurations';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import { SalesforceAddress } from '../../../../Models/Salesforce/SalesforceAddress';
import { SalesforceLocation } from '../../../../Models/Salesforce/SalesforceLocation';
import { SalesforceObjectLayout } from '../../../../Models/Salesforce/SalesforceObjectLayout';
import { SalesforceSchemaResponse } from '../../../../Models/Salesforce/SalesforceObjectSchema';
import { SalesforceOpportunity } from '../../../../Models/Salesforce/SalesforceOpportunity';
import {
  SalesforceObjectQuery,
  SalesforceSObjectLayoutQuery,
  SalesforceSObjectSchemaQuery,
} from '../../../../QueryNames';
import {
  ApiClient,
  ExternalServicesApiClient,
} from '../../../../Services/NetworkCommon';
import { SalesforcePanelContext } from '../../../../types/SalesforcePanelContext';
import { SalesforceFormComponent } from './SalesforceFormComponent';
import { SalesforceOpportunityTile } from './SalesforceOpportunityTile';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { useForceUpdate } from '@fluentui/react-hooks';

const DEFAULT_EDITABLE_FIELDS = [
  'Amount',
  'CloseDate',
  'StageName',
  'NextStep',
];

export type SalesforceSidePanelOpportunityContentProps = {
  organizationSlug: string;
  meetingPlanId?: string;
  associatedWithPlan?: boolean;
  salesforceConfigurationId: number;
  salesforceOpportunityId: string;
  pushSalesforcePanel: (context: SalesforcePanelContext) => void;
  showObjectPicker: (startTab?: ExternalServiceObjectType) => void;
  defaultNewOppName?: string;
};
export const SalesforceSidePanelOpportunityContent = ({
  organizationSlug,
  meetingPlanId,
  associatedWithPlan,
  salesforceConfigurationId,
  salesforceOpportunityId,
  pushSalesforcePanel,
  showObjectPicker,
  defaultNewOppName = '',
}: SalesforceSidePanelOpportunityContentProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const {
    configurationsWithToken,
    loading: salesforceConfigurationsLoading,
    refetchAll,
    configurationById,
  } = useExternalServiceConfigurations({ app: 'SALESFORCE', withToken: true });

  const theme = useTheme();
  const { isDark } = useLightOrDarkMode();

  const forceUpdate = useForceUpdate();

  const appInsights = useAppInsightsContext();

  const [formIsSubmitting, setFormIsSubmitting] = useState<boolean>(false);

  const { hasEntitlement } = useOrganization();

  const [updateErrors, setUpdateErrors] = useState<
    Record<string, string[] | undefined>
  >({});
  const [objectState, setObjectState] = useState<
    Record<
      string,
      | string
      | boolean
      | number
      | SalesforceAddress
      | SalesforceLocation
      | undefined
      | null
    >
  >({});

  const {
    data: objectSchema,
    isLoading: objectSchemaLoading,
    refetch: refetchObjectSchema,
  } = useQuery(
    SalesforceSObjectSchemaQuery(
      organizationSlug!,
      salesforceConfigurationId,
      'Opportunity',
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<SalesforceSchemaResponse>(
        `/organization/${organizationSlug}/external/salesforce/configuration/${salesforceConfigurationId}/schema/Opportunity`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!configurationById(salesforceConfigurationId),
    },
  );

  const {
    data: salesforceObject,
    isLoading: salesforceObjectLoading,
    refetch: refetchSalesforceObject,
  } = useQuery(
    SalesforceObjectQuery(
      organizationSlug!,
      salesforceConfigurationId,
      'Opportunity',
      salesforceOpportunityId,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<SalesforceOpportunity>(
        `/organization/${organizationSlug}/external/salesforce/configuration/${salesforceConfigurationId}/object/Opportunity/${salesforceOpportunityId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!configurationById(salesforceConfigurationId),
    },
  );

  const layoutId = useMemo(() => {
    if (!objectSchema?.data || !salesforceObject?.data) {
      return undefined;
    }

    if (salesforceObject.data.RecordTypeId) {
      return salesforceObject.data.RecordTypeId;
    }

    return (
      objectSchema?.data?.salesforceSchema?.recordTypeInfos?.find(
        (info) => info.active && info.defaultRecordTypeMapping,
      )?.recordTypeId ||
      objectSchema?.data?.salesforceSchema?.recordTypeInfos?.find(
        (info) => info.active && info.master,
      )?.recordTypeId ||
      objectSchema?.data?.salesforceSchema?.recordTypeInfos?.find(
        (info) => info.active && !!info.recordTypeId,
      )?.recordTypeId
    );
  }, [salesforceObject?.data, objectSchema?.data]);

  const {
    data: layoutInfo,
    isLoading: layoutInfoLoading,
    refetch: refetchLayoutInfo,
  } = useQuery(
    SalesforceSObjectLayoutQuery(
      organizationSlug!,
      salesforceConfigurationId,
      'Opportunity',
      layoutId,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<SalesforceObjectLayout>(
        `/organization/${organizationSlug}/external/salesforce/configuration/${salesforceConfigurationId}/schema/Opportunity/layout/${layoutId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!configurationById(salesforceConfigurationId) && !!layoutId,
    },
  );

  const { mutate: updateDefaultCustomFieldSetId } = useMutation(
    async ({
      tokenId,
      defaultCustomFieldSetId,
    }: {
      tokenId: number;
      defaultCustomFieldSetId: number | null;
    }) => {
      const token = await getAccessTokenSilently();
      const result = await ExternalServicesApiClient.setDefaultFieldSet(
        organizationSlug!,
        tokenId,
        { fieldSetId: defaultCustomFieldSetId },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return result.data;
    },
    {
      onSettled: () => {
        refetchAll().then(forceUpdate);
      },
    },
  );

  useEffect(() => {
    if (layoutId) {
      refetchLayoutInfo();
    }
  }, [layoutId, refetchLayoutInfo]);

  useEffect(() => {
    if (
      salesforceConfigurationId &&
      !!configurationById(salesforceConfigurationId)
    ) {
      refetchObjectSchema();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configurationById, salesforceConfigurationId]);

  useEffect(() => {
    if (
      salesforceConfigurationId &&
      !!configurationById(salesforceConfigurationId) &&
      salesforceOpportunityId
    ) {
      setUpdateErrors({});
      refetchSalesforceObject();
    }
  }, [
    salesforceConfigurationId,
    salesforceOpportunityId,
    refetchSalesforceObject,
    configurationById,
  ]);

  const createNewOppUrl = configurationsWithToken?.[0]?.instanceId
    ? `${configurationsWithToken?.[0]?.instanceId}/lightning/o/Opportunity/new?defaultFieldValues=Name=${defaultNewOppName}`
    : undefined;

  const fieldLayout = useMemo(
    () => getLayoutFields(layoutInfo?.data),
    [layoutInfo?.data],
  );

  const fieldNameFieldMap = useMemo(
    () => getFieldNameFieldMap(objectSchema?.data, layoutInfo?.data),
    [layoutInfo?.data, objectSchema?.data],
  );

  const fields = useMemo(() => {
    const fields = objectSchema?.data?.customFields?.length
      ? objectSchema.data.customFields.map((f) => f.fieldName)
      : DEFAULT_EDITABLE_FIELDS;

    if (
      objectSchema?.data?.customFields?.length &&
      objectSchema.data.customFields.every((f) => f.displayOrder === null)
    ) {
      return fields.sort(
        (a, b) =>
          (fieldLayout.findIndex((f) => f.name === a) ?? fieldLayout.length) -
          (fieldLayout.findIndex((f) => f.name === b) ?? fieldLayout.length),
      );
    }

    return fields;
  }, [fieldLayout, objectSchema?.data?.customFields]);

  const readOnlyFields = useMemo(() => {
    return fields.filter(
      (name) =>
        !!fieldNameFieldMap[name] &&
        (!!fieldNameFieldMap[name]?.calculated ||
          !fieldNameFieldMap[name]?.updateable ||
          fieldNameFieldMap[name]?.type === 'address' ||
          fieldNameFieldMap[name]?.type === 'location'),
    );
  }, [fieldNameFieldMap, fields]);

  const editableFields = useMemo(() => {
    return fields.filter(
      (name) => !!fieldNameFieldMap[name] && !readOnlyFields.includes(name),
    );
  }, [fieldNameFieldMap, fields, readOnlyFields]);

  useEffect(() => {
    if (salesforceObject?.data && editableFields) {
      setObjectState(PickValues(salesforceObject?.data, editableFields));
    }
  }, [editableFields, salesforceObject?.data]);

  const formSchema = useMemo(
    () => getYupObjectValidator(editableFields, fieldNameFieldMap),
    [editableFields, fieldNameFieldMap],
  );

  const [isValid, errors] = useMemo(
    () => validateData(formSchema, objectState),
    [formSchema, objectState],
  );

  const isDirty = useMemo(
    () => objectIsDirty(objectState, salesforceObject?.data || {}),
    [objectState, salesforceObject?.data],
  );

  const onSubmit = async () => {
    if (!isValid || !isDirty) {
      return;
    }

    const dirtyFields = getDirtyFields(
      objectState,
      salesforceObject?.data || {},
    );

    const updateBody = Object.fromEntries(
      Object.entries(dirtyFields).map(([name, value]) => {
        const field = fieldNameFieldMap[name];

        if (!field) {
          return [name, value];
        }

        switch (field.type) {
          case 'currency': {
            return [name, isString(value) ? parseLocaleFloat(value) : value];
          }
          default: {
            return [name, value];
          }
        }
      }),
    );

    setFormIsSubmitting(true);
    setUpdateErrors({});

    const token = await getAccessTokenSilently();
    await toast
      .promise(
        ApiClient.patch<SalesforceOpportunity>(
          `/organization/${organizationSlug}/external/salesforce/configuration/${salesforceConfigurationId}/object/Opportunity/${salesforceOpportunityId}`,
          updateBody,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'x-meetingplan-id': meetingPlanId,
            },
          },
        ),
        {
          loading: 'Updating Salesforce opportunity',
          success: (_result) => {
            appInsights.trackEvent({
              name: `SALESFORCE_OPPORTUNITY_UPDATED`,
              properties: {
                oppName: salesforceObject?.data?.Name,
                surface: 'SIDE_PANEL',
              },
            });
            refetchSalesforceObject();

            return 'Salesforce opportunity was successfully updated';
          },
          error: (err) => {
            appInsights.trackEvent({
              name: `SALESFORCE_OPPORTUNITY_UPDATE_ERROR`,
              properties: {
                oppName: salesforceObject?.data?.Name,
                status: isAxiosErrorResponse(err) && err.response?.status,
                statusText:
                  isAxiosErrorResponse(err) && err.response?.statusText,
              },
            });

            const responseBody = err.response?.data;
            if (
              isAxiosErrorResponse(err, 400) &&
              isSalesforceUpdateError(responseBody)
            ) {
              if (!!responseBody.fields?.[0]) {
                if (editableFields.includes(responseBody.fields[0])) {
                  setUpdateErrors({
                    [responseBody.fields[0]]: [responseBody.message],
                  });
                } else {
                  setUpdateErrors({
                    UPDATE_ERROR: [
                      `${responseBody.fields[0]} - ${responseBody.message}`,
                    ],
                  });
                }
              } else {
                setUpdateErrors({
                  UPDATE_ERROR: [responseBody.message],
                });
              }

              return `Something went wrong updating the opportunity`;
            }

            return 'Something went wrong updating the opportunity.  Please try again.';
          },
        },
      )
      .finally(() => setFormIsSubmitting(false));
  };

  if (
    salesforceConfigurationsLoading ||
    objectSchemaLoading ||
    salesforceObjectLoading ||
    layoutInfoLoading
  ) {
    return (
      <div
        style={{
          minHeight: '100%',
          height: `calc(100vh - 19rem)`,
        }}
      >
        <div style={{ margin: '1rem 0' }}>
          <Spinner />
        </div>
      </div>
    );
  }

  if (!salesforceObject?.data) {
    return (
      <Text
        variant="large"
        block
        style={{
          textAlign: 'center',
          margin: '1rem 0',
        }}
      >
        This associated Salesforce Opportunity couldn't be loaded. Check the
        opportunity still exists and that you have access to it.
        <div
          style={{
            textAlign: 'center',
            margin: '1rem 0',
          }}
        >
          <PrimaryButton onClick={() => showObjectPicker('DEAL')}>
            Select a new Opportunity
          </PrimaryButton>

          <Text style={{ marginTop: '1rem', fontStyle: 'italic' }} block>
            Or
          </Text>

          {createNewOppUrl ? (
            <div style={{ marginTop: '.5rem' }}>
              <Link
                style={{
                  fontSize: FontSizes.medium,
                }}
                href={createNewOppUrl}
                target="_blank"
              >
                <Icon
                  style={{
                    marginRight: '.5rem',
                    position: 'relative',
                    top: '.15rem',
                    backgroundColor: 'rgb(120,160,227)',
                    borderRadius: '1rem',
                    padding: '.5rem',
                    color: 'white',
                  }}
                  iconName="Add"
                />
                Create a New Opportunity
              </Link>
            </div>
          ) : null}
        </div>
      </Text>
    );
  }

  const configuration = configurationById(salesforceConfigurationId)!;
  const hasCustomFieldsets = configurationById(salesforceConfigurationId)
    ?.externalServiceCustomFieldsets?.length;

  return (
    <div
      style={{
        minHeight: '100%',
        marginBottom: '1rem',
      }}
    >
      <div>
        <SalesforceOpportunityTile
          organizationSlug={organizationSlug}
          meetingPlanId={meetingPlanId}
          showArrow={false}
          showExternalLink
          key={salesforceObject.data.Id}
          externalId={salesforceObject.data.Id}
          name={salesforceObject.data.Name}
          salesforceInstance={
            configurationById(salesforceConfigurationId)!.instanceId
          }
          accountName={salesforceObject.data.Account?.Name}
          ownerName={salesforceObject.data.Owner?.Name}
          stage={salesforceObject.data.StageName}
          onClickAccountName={
            !!salesforceObject.data?.Account?.Id
              ? () => {
                  pushSalesforcePanel({
                    objectId: salesforceObject.data.Account!.Id,
                    name: salesforceObject.data.Account!.Name,
                    objectType: 'ACCOUNT',
                    serviceConfigurationId: salesforceConfigurationId,
                  });
                }
              : undefined
          }
          onClick={
            associatedWithPlan ? () => showObjectPicker('DEAL') : undefined
          }
        />
      </div>

      {hasEntitlement('CUSTOM_FIELDSETS') && hasCustomFieldsets ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '1rem',
            marginBottom: '.5rem',
            paddingBottom: '.5rem',
            borderBottom: `1px solid ${
              isDark ? NeutralColors.gray170 : theme.semanticColors.bodyDivider
            }`,
          }}
        >
          <Dropdown
            label="Field Set"
            className="customFieldsetPicker"
            styles={{
              root: {
                display: 'flex',
                columnGap: '0',
              },
              label: {
                fontWeight: FontWeights.semibold,
                marginRight: '.5rem',
              },
            }}
            disabled={salesforceConfigurationsLoading}
            selectedKey={
              configuration.externalServiceUserTokens![0]
                .defaultCustomFieldSetId || -1
            }
            options={[
              {
                key: -1,
                text: 'Default',
                title: 'Default',
              },
              ...(configuration.externalServiceCustomFieldsets!.map((f) => ({
                key: f.id,
                text: f.name,
              })) ?? []),
            ]}
            onChange={(e, opt) => {
              if (opt) {
                updateDefaultCustomFieldSetId({
                  tokenId: configuration.externalServiceUserTokens![0]!.id,
                  defaultCustomFieldSetId:
                    opt.key === -1 ? null : (opt.key as number),
                });
                toast.success('Your default fieldset has been updated.');
              }
            }}
          />
        </div>
      ) : null}

      <div>
        {fields.map((fieldName) => {
          const field = fieldNameFieldMap[fieldName];
          if (!field) {
            return null;
          }

          const isReadonly = readOnlyFields.includes(fieldName);

          return (
            <SalesforceFormComponent
              organizationSlug={organizationSlug}
              salesforceConfigurationId={salesforceConfigurationId}
              key={field.name}
              field={field}
              readonly={isReadonly}
              errors={{ ...errors, ...updateErrors }}
              isDirty={
                isReadonly
                  ? false
                  : fieldDirty(objectState, salesforceObject.data, field.name)
              }
              state={isReadonly ? salesforceObject?.data : objectState}
              setState={setObjectState}
            />
          );
        })}
      </div>

      {recordHasOwnProperty(updateErrors, 'UPDATE_ERROR') &&
      updateErrors.UPDATE_ERROR?.length ? (
        <div
          style={{
            backgroundColor: theme.semanticColors.errorText,
            padding: '1rem 0',
            margin: '1rem',
            borderRadius: '.25rem',
            textAlign: 'center',
            fontSize: FontSizes.mediumPlus,
            color: 'white',
          }}
        >
          {updateErrors.UPDATE_ERROR[0]}
        </div>
      ) : null}
      <div
        style={{ display: 'grid', justifyItems: 'right', marginTop: '.5rem' }}
      >
        <PrimaryButton
          disabled={
            salesforceObjectLoading || formIsSubmitting || !isValid || !isDirty
          }
          text={
            formIsSubmitting ? 'Updating Salesforce...' : 'Update Salesforce'
          }
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};
