import { DSConfigurationHeaderRow } from '../Components/DSConfigurationHeaderRow';
import { PrimaryButton } from "@fluentui/react";
import { AsyncPrimaryButton } from "../../../HOC/AsyncButton";
import { toast } from "react-hot-toast";
import { useAuth0 } from "@auth0/auth0-react";

export const DSConfigurationDeveloperTools = () => {
    const { getAccessTokenSilently } = useAuth0();

    return (
        <DSConfigurationHeaderRow
            title="Developer Tools"
            description="This page is for developers only."
        >
            <PrimaryButton
                onClick={() => {
                    localStorage.clear();
                    window.location.reload();
                }}
            >
                Clear Local Storage
            </PrimaryButton>

            <AsyncPrimaryButton
                onClick={async () => {
                    getAccessTokenSilently().then((token) => {
                        navigator?.clipboard
                            ?.writeText(token)
                            .then(() => toast(`Bearer token copied to clipboard`));
                    });
                }}
            >
                Copy Bearer Token
            </AsyncPrimaryButton>
        </DSConfigurationHeaderRow>
    );

};
