import { useAuth0 } from '@auth0/auth0-react';
import { useOrganizationSlug } from './useOrganizationSlug';
import { useDealRoomId } from './useDealRoomId';
import { useQuery, useQueryClient } from 'react-query';
import { OrganizationArtifactCommentsQuery } from '../QueryNames';
import { DealRoomsApiClient } from '../Services/NetworkCommon';
import { isForbiddenError } from '../Helpers/AxiosHelpers';
import { useCallback } from 'react';

export const useArtifactComments = (
  orgSlug?: string,
  dealRoomId?: number,
  artifactId?: number,
) => {
  const { getAccessTokenSilently } = useAuth0();
  const client = useQueryClient();

  const slg = useOrganizationSlug();
  const drId = useDealRoomId();
  const orgSlugFinal = orgSlug || slg;
  const dealRoomIdFinal = dealRoomId || drId;

  const {
    data: artifactComments,
    isLoading: artifactCommentsLoading,
    isFetched: artifactCommentsIsFetched,
    isError: artifactCommentsIsError,
    error: artifactCommentsError,
    refetch: refetchArtifactComments,
  } = useQuery(
    OrganizationArtifactCommentsQuery(
      orgSlugFinal,
      dealRoomIdFinal,
      artifactId,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return DealRoomsApiClient.listArtifactComments(
        orgSlugFinal!,
        dealRoomIdFinal!,
        artifactId!,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    },
    {
      enabled:
        !!orgSlugFinal &&
        !!dealRoomIdFinal &&
        !isNaN(dealRoomIdFinal) &&
        !!artifactId &&
        !isNaN(artifactId),
      retry: (failureCount, error) => {
        if (isForbiddenError(error)) {
          return false;
        }
        return failureCount < 3;
      },
    },
  );

  const refetchAll = useCallback(async () => {
    await client.invalidateQueries(
      OrganizationArtifactCommentsQuery(
        orgSlugFinal,
        dealRoomIdFinal,
        artifactId,
      ),
    );
  }, [client, dealRoomIdFinal, orgSlugFinal, artifactId]);

  return {
    artifactComments: artifactComments?.data,
    isLoading: artifactCommentsLoading,
    isFetched: artifactCommentsIsFetched,
    isError: artifactCommentsIsError,
    error: artifactCommentsError,
    refetch: refetchArtifactComments,
    refetchAll,
  };
};
