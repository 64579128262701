import { useAuth0 } from '@auth0/auth0-react';
import {
  FontSizes,
  mergeStyles,
  NeutralColors,
  SearchBox,
  Spinner,
  Text,
} from '@fluentui/react';
import { ExternalServiceObject } from '@meetingflow/common/Api/data-contracts';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useExternalServiceConfigurations } from '../../../../Hooks/useExternalServiceConfigurations';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import { SalesforceContact } from '../../../../Models/Salesforce/SalesforceContact';
import { SalesforceContactsQuery } from '../../../../QueryNames';
import { ApiClient } from '../../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../../Themes/Themes';
import { SalesforcePanelContext } from '../../../../types/SalesforcePanelContext';
import { SalesforceContactTile } from './SalesforceContactTile';

export type SalesforceBrowserSidePanelContactContentProps = {
  organizationSlug: string;
  meetingPlanId?: string;
  associatedObjects?: ExternalServiceObject[];
  salesforceConfigurationId: number;
  externalDomains?: string[];
  defaultNewContactName?: string;
  onPickedObject: (
    object: ExternalServiceObject | Omit<ExternalServiceObject, 'id'>,
  ) => void;
  onPinnedObject:
    | ((e?: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<unknown>)
    | undefined;
  pushSalesforcePanel: (context: SalesforcePanelContext) => void;
};
export const SalesforceBrowserSidePanelContactContent = ({
  organizationSlug,
  meetingPlanId,
  associatedObjects,
  salesforceConfigurationId,
  externalDomains,
  onPickedObject,
  onPinnedObject,
  pushSalesforcePanel,
  defaultNewContactName = '',
}: SalesforceBrowserSidePanelContactContentProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const { isDark } = useLightOrDarkMode();

  const { loading: configurationsLoading, configurationById } =
    useExternalServiceConfigurations({ app: 'SALESFORCE', withToken: true });

  const [q, setQ] = useState<string>('');

  const {
    data: salesforceContacts,
    isLoading: salesforceContactsLoading,
    isRefetching: salesforceContactsRefetching,
    refetch: refetchSalesforceContacts,
  } = useQuery(
    SalesforceContactsQuery(organizationSlug!, salesforceConfigurationId),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<SalesforceContact[]>(
        `/organization/${organizationSlug}/external/salesforce/configuration/${salesforceConfigurationId}/contacts`,
        {
          params: {
            q: q ?? undefined,
            domains: undefined,
          },
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRefetch = useCallback(
    debounce(refetchSalesforceContacts, 250),
    [refetchSalesforceContacts],
  );

  useEffect(() => {
    debouncedRefetch();
  }, [q, debouncedRefetch]);

  useEffect(() => {
    refetchSalesforceContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesforceConfigurationId]);

  const panelContent = mergeStyles({
    padding: '0 .5rem',
    height: '100%',
    minHeight: '100%',
  });

  const slideSpinnerClass = mergeStyles({
    height: '2rem',
    animationName: 'slideDownSpinnerAnimation',
    animationDuration: '1s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '.5',
    animationFillMode: 'forwards',
    position: 'absolute',
    top: '4.35rem',
    right: '.5rem',
  });

  if (configurationsLoading) {
    return <Spinner className={slideSpinnerClass} />;
  }

  return (
    <div>
      <div className={panelContent}>
        <div
          style={{
            marginLeft: '-.5rem',
            marginRight: '-.5rem',
            marginBottom: '.5rem',
            padding: '.5rem',
            borderBottom: `1px solid ${
              isDark ? NeutralColors.gray180 : MEETINGFLOW_COLORS.purpleGrey
            }`,
            backgroundColor: isDark
              ? NeutralColors.gray210
              : MEETINGFLOW_COLORS.purpleUltraLight,
          }}
        >
          <SearchBox
            disabled={salesforceContactsLoading || salesforceContactsRefetching}
            placeholder="Search contacts by name..."
            value={q}
            onChange={(e, newValue) => setQ(newValue ?? '')}
            styles={{
              root: {
                transition: '.3s all ease-in-out',
                fontSize: FontSizes.small,
                height: '1.5rem',
                marginTop: '.5rem',
                width:
                  configurationsLoading ||
                  salesforceContactsLoading ||
                  salesforceContactsRefetching
                    ? 'calc(100% - 2rem)'
                    : '100%',
              },
            }}
          />
        </div>
      </div>
      <div>
        <div
          style={{
            overflowY: 'auto',
            maxHeight: 'calc(100% - 18rem)',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '.125rem',
            padding: '0 .5rem 2rem .5rem',
            boxSizing: 'border-box',
          }}
        >
          {salesforceContactsLoading || salesforceContactsRefetching ? (
            <Spinner className={slideSpinnerClass} />
          ) : null}
          {salesforceContacts?.data && salesforceContacts?.data?.length > 0 ? (
            <>
              {salesforceContacts?.data?.map((contact) => {
                const associatedObjectIds = associatedObjects?.map(
                  (o) => o.externalId,
                );
                const isPinned = associatedObjectIds?.includes(contact.Id);
                const externalServiceObjectId = associatedObjects?.find(
                  (o) => o.externalId === contact.Id,
                )?.id;
                const externalServiceObject = {
                  externalId: contact.Id,
                  objectType: 'CONTACT',
                  name: contact.Name,
                  serviceConfigurationId: salesforceConfigurationId,
                  serviceConfiguration: configurationById(
                    salesforceConfigurationId,
                  )!,
                } as Omit<ExternalServiceObject, 'id'>;
                return (
                  <SalesforceContactTile
                    organizationSlug={organizationSlug}
                    meetingPlanId={meetingPlanId}
                    key={contact.Id}
                    externalId={contact.Id}
                    name={contact.Name}
                    email={contact.Email}
                    salesforceInstance={
                      configurationById(salesforceConfigurationId)!.instanceId!
                    }
                    isPinned={isPinned}
                    externalServiceObjectId={externalServiceObjectId}
                    onClick={() => onPickedObject(externalServiceObject)}
                    onPinClick={onPinnedObject}
                    showExternalLink
                  />
                );
              })}
            </>
          ) : (
            <div>
              {!salesforceContactsLoading && !salesforceContactsRefetching ? (
                <Text
                  block
                  variant="small"
                  style={{ fontStyle: 'italic', margin: '.5rem 0' }}
                >
                  There are no matching contacts. Change your filters above to
                  show all contacts, if needed.
                </Text>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
