import {
  ConstrainMode,
  DetailsRow,
  FontSizes,
  FontWeights,
  IDetailsList,
  IDetailsListProps,
  IDetailsRowProps,
  IDetailsRowStyles,
  IRefObject,
  IShimmeredDetailsListProps,
  NeutralColors,
  ShimmeredDetailsList,
  Text,
  useTheme,
} from '@fluentui/react';
import React, { createRef, useCallback, useMemo } from 'react';
import { useLightOrDarkMode } from '../Hooks/useLightOrDarkMode';
import { MEETINGFLOW_COLORS } from '../Themes/Themes';
import useBreakpoints from '../Hooks/useBreakpoints';

interface StyledDetailsListProps {
  activeIndex?: number;
  maxHeight?: string | number;
  minWidth?: string | number;
  noDataMessage?: string;
  disableColumnHeaderClick?: boolean;
}

export const StyledDetailsList = React.memo(
  ({
    activeIndex = 0,
    maxHeight = 'auto',
    minWidth,
    noDataMessage,
    disableColumnHeaderClick,
    ...detailsListProps
  }: StyledDetailsListProps & IShimmeredDetailsListProps) => {
    const theme = useTheme();
    const { isDark } = useLightOrDarkMode();
    const breakpoints = useBreakpoints();
    const tableRef: IRefObject<IDetailsList> | undefined = createRef();

    const tableStyles = useMemo(
      () => ({
        headerWrapper: {
          flex: '0 0 auto',
        },
        root: {
          width: 'auto !important',
          maxHeight: maxHeight,
          minWidth: minWidth,
          margin: '0 auto',
          overflowX: 'hidden',

          '.ms-Shimmer-container': {
            '.ms-Shimmer-shimmerWrapper': {
              backgroundColor: 'transparent !important',
            },
          },

          '.ms-List': {
            ':after': {
              backgroundImage: `none`,
            },
          },

          '.right-align span.ms-DetailsHeader-cellName': {
            display: 'block',
            width: '100%',
            textAlign: 'right !important',
          },
          '.right-align.ms-DetailsRow-cell > span': {
            display: 'block',
            width: '100%',
            textAlign: 'right !important',
          },
          '.ms-DetailsRow, .ms-DetailsRow-fields, .ms-List-cell': {
            backgroundColor: `${
              isDark ? 'transparent' : 'transparent'
            } !important`,
          },
          '.center-align span.ms-DetailsHeader-cellName': {
            display: 'block',
            width: '100%',
            textAlign: 'center !important',
          },
          '.center-align.ms-DetailsRow-cell ': {
            textAlign: 'center !important',
          },
          '.center-align.ms-DetailsRow-cell > span': {
            display: 'block',
            width: '100%',
            textAlign: 'center !important',
          },

          '.ms-DetailsHeader': {
            height: '44px',
            backgroundColor: isDark
              ? NeutralColors.gray200
              : MEETINGFLOW_COLORS.purpleGrey,
          },

          '.ms-DetailsHeader-cell.last-column, .ms-DetailsRow-cell.last-column':
            {
              maxWidth: '175px',
            },

          '.ms-DetailsHeader-cell.right-align.last-column .ms-DetailsHeader-cellName':
            {
              justifyContent: 'right',
            },

          '.usage-numeric.ms-DetailsRow-cell > div': {
            width: '100%',
            textAlign: 'right !important',
            color: MEETINGFLOW_COLORS.teal,
            fontSize: FontSizes.mediumPlus,
            fontWeight: FontWeights.semibold,
            lineHeight: '2.75rem',
          },

          '.usage-numeric.left-align.ms-DetailsRow-cell > div': {
            textAlign: 'left !important',
          },

          '&.small-header .ms-DetailsHeader': {
            height: '1rem',
            borderBottom: 'none !important',
            backgroundColor: isDark
              ? NeutralColors.gray200
              : MEETINGFLOW_COLORS.purpleGrey,

            '.ms-DetailsHeader-cellTitle': {
              padding: 0,
              height: '1rem',
            },

            '.ms-DetailsHeader-cellName': {
              padding: '0 .25rem',
              height: '1rem',
              lineHeight: '1rem',
              fontSize: FontSizes.mini,
              fontWeight: FontWeights.regular,
              color: isDark ? NeutralColors.gray100 : NeutralColors.gray160,
            },

            '.ms-DetailsHeader-cell': {
              padding: 0,
              height: '1rem',
              minHeight: '1rem',
              transition: '.3s ease-in-out all',

              ':hover': {
                cursor: disableColumnHeaderClick ? undefined : 'pointer',
                backgroundColor: disableColumnHeaderClick
                  ? 'blue'
                  : isDark
                    ? NeutralColors.gray160
                    : MEETINGFLOW_COLORS.purpleGrey,
                color: disableColumnHeaderClick
                  ? undefined
                  : isDark
                    ? MEETINGFLOW_COLORS.white
                    : MEETINGFLOW_COLORS.purpleDark,
              },
            },
          },

          '&.long-headers .ms-DetailsHeader': {
            height: '2.5rem',
            backgroundColor: isDark
              ? NeutralColors.gray200
              : MEETINGFLOW_COLORS.purpleUltraSuperLight,

            '.ms-DetailsHeader-cellTitle': {
              padding: 0,
              display: 'flex',
              textAlign: 'center',
              alignContent: 'center',
              height: '100%',
            },

            '.ms-DetailsHeader-cellName': {
              display: 'flex',
              padding: '0 .25rem',
              lineHeight: '1rem',
              fontSize: FontSizes.small,
              whiteSpace: 'normal',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            },

            '.ms-DetailsHeader-cell': {
              padding: 0,
              minHeight: '1rem',
              transition: '.3s ease-in-out all',

              ':hover': {
                cursor: disableColumnHeaderClick ? undefined : 'pointer',
                backgroundColor: disableColumnHeaderClick
                  ? undefined
                  : isDark
                    ? NeutralColors.gray160
                    : MEETINGFLOW_COLORS.purpleGrey,
                color: disableColumnHeaderClick
                  ? undefined
                  : isDark
                    ? MEETINGFLOW_COLORS.white
                    : MEETINGFLOW_COLORS.purpleDark,
              },
            },
          },
          '.ms-DetailsHeader-cell': {
            minHeight: '44px',
            color: NeutralColors.gray100,
            backgroundColor: isDark
              ? NeutralColors.gray200
              : MEETINGFLOW_COLORS.purpleGrey,
          },
          '.ms-DetailsRow-cell': {
            height: 'auto',
            padding: '.25rem .5rem',
          },
          '.ms-DetailsRow:hover .ms-DetailsRow-cell > button.ms-Link': {
            color: `${theme.palette.themePrimary} !important`,
          },
          '.ms-DetailsRow:hover .ms-DetailsRow-cell > button.ms-Link.is-disabled':
            {
              color: `inherit !important`,
            },
          '.ms-FocusZone': {
            paddingTop: 0,
          },
          '.ms-Button--icon': {
            display: 'inline-block',
            margin: '0 auto',
            width: 'auto !important',
          },
          '.ms-Facepile-descriptiveOverflowButton': {
            display: 'inline-block !important',
            width: 'auto !important',
            margin: '0 .5rem !important',
          },
          '.ms-GroupHeader[role=row]': {
            color: NeutralColors.gray100,
            backgroundColor: isDark
              ? NeutralColors.gray200
              : MEETINGFLOW_COLORS.purpleGrey,
            borderBottom: isDark
              ? `.5px solid ${NeutralColors.gray160}`
              : `.5px solid ${MEETINGFLOW_COLORS.purpleGrey}`,
            borderTop: isDark
              ? `.5px solid ${NeutralColors.gray160}`
              : `.5px solid ${MEETINGFLOW_COLORS.purpleGrey}`,

            '> div': {
              height: '32px',
            },
          },
          '.ms-GroupHeader-title': {
            paddingLeft: 0,
            fontSize: FontSizes.small,
            fontWeight: FontWeights.semibold,
          },
          '.ms-Button--default, .ms-Button--primary': {
            display: 'inline-block',
            margin: '.25rem auto',
          },
          '.hidden': {
            display: 'none',
          },
          '.ms-GroupSpacer': {
            width: '0 !important',
          },
          '.ms-DetailsRow-cellCheck': {
            padding: '2px',
          },
          '.ms-DetailsRow-check': {
            height: '100%', // Ensure vertical centering of selection checkbox
            padding: 0,
            width: '24px',
          },
          '.ms-Check,.ms-GroupHeader-check': {
            width: '18px',
          },
          '.ms-DetailsRow': {
            borderBottom: isDark
              ? `1px solid ${NeutralColors.gray220}`
              : `1px solid ${MEETINGFLOW_COLORS.purpleGrey}`,
          },
        },
      }),
      [
        isDark,
        maxHeight,
        minWidth,
        theme.palette.themePrimary,
        disableColumnHeaderClick,
      ],
    );

    // @ts-ignore
    const onRenderRow: IDetailsListProps['onRenderRow'] = useCallback(
      (detailsRowProps: IDetailsRowProps) => {
        const rowStyles: Partial<IDetailsRowStyles> = {};
        if (detailsRowProps) {
          rowStyles.cell = {
            backgroundColor: isDark ? 'transparent' : 'transparent',
          };
          rowStyles.root = {
            borderBottomColor: isDark
              ? NeutralColors.gray180
              : NeutralColors.gray20,
          };
          return <DetailsRow {...detailsRowProps} styles={rowStyles} />;
        }
        return null;
      },
      [isDark],
    );

    const { enableShimmer } = detailsListProps;

    return (
      <>
        <ShimmeredDetailsList
          detailsListStyles={tableStyles}
          onRenderRow={onRenderRow}
          componentRef={tableRef}
          constrainMode={ConstrainMode.horizontalConstrained}
          shimmerLines={25}
          {...detailsListProps}
        />
        {!enableShimmer && detailsListProps.items.length === 0 ? (
          <Text
            variant="large"
            block
            style={{ margin: '1rem 0', textAlign: 'center' }}
          >
            {noDataMessage || 'There are no results.'}
          </Text>
        ) : null}
      </>
    );
  },
);
