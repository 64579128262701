import { ArtifactType } from '@meetingflow/common/Api/data-contracts';

export const mimeTypeToIconName = (
  mimeType?: string | null,
  defaultIcon: string = 'Document',
) => {
  if (!mimeType) {
    return defaultIcon;
  }

  if (mimeType.startsWith('image/')) {
    return 'Photo2';
  }

  if (mimeType.startsWith('video/')) {
    return 'Video';
  }

  if (mimeType.startsWith('audio/')) {
    return 'MusicInCollection';
  }

  switch (mimeType) {
    case 'application/pdf':
      return 'PDF';
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/x-abiword':
      return 'WordDocument';
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.oasis.opendocument.spreadsheet':
      return 'ExcelDocument';
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'application/vnd.oasis.opendocument.presentation':
      return 'PowerpointDocument';
    case 'application/onenote':
      return 'OneNoteLogo';
    case 'application/vnd.visio':
    case 'application/vnd.ms-visio.viewer':
      return 'VisioDocument';
    case 'application/vnd.ms-project':
      return 'ProjectDocument';
    case 'application/vnd.ms-access':
    case 'application/msaccess':
    case 'application/x-msaccess':
      return 'AccessLogo';
    case 'application/vnd.ms-publisher':
      return 'PublisherLogo';
    case 'text/html':
      return 'FileHTML';
    case 'application/json':
    case 'application/ld+json':
      return 'FileCode';
    case 'text/plain':
    case 'application/rtf':
    case 'application/vnd.oasis.opendocument.text':
    case 'application/vnd.google-apps.document':
      return 'TextDocument';
    default:
      return defaultIcon;
  }
};

export const artifactTypeToIconName = (
  artifactType: ArtifactType,
  mimeType?: string | null,
) => {
  switch (artifactType) {
    case 'DOCUMENT':
    case 'FILE': {
      if (!mimeType) {
        return 'Document';
      }

      return mimeTypeToIconName(
        mimeType,
        artifactType === 'DOCUMENT' ? 'TextDocument' : 'Page',
      );
    }
    case 'IMAGE':
      return 'Photo2';
    case 'VIDEO':
      return 'MSNVideos';
    case 'AUDIO':
      return 'Microphone';
    case 'LINK':
      return 'Link';
    case 'MEETINGFLOW':
      return 'Calendar';
    default:
      return 'Document';
  }
};
