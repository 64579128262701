import { mergeStyles } from '@fluentui/react';
import {
  Contact,
  DetailedMeetingflow,
} from '@meetingflow/common/Api/data-contracts';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { GPTChatBox } from '../../GPT/GPTChatBox';
import { GPTChatBoxContext } from '../../GPT/GPTChatBoxContext';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { useOrganization } from '../../../Hooks/useOrganization';
import { useUserProfile } from '../../../Hooks/useProfile';
import { GPTChatBoxAction } from '../../GPT/GPTChatBoxAction';
import { BaseSidePanel } from './BaseSidePanel';
import { DecisionSiteBaseSidePanel } from './DecisionSiteBaseSidePanel';
import { DecisionSiteGPTChatBox } from '../../../Components/GPT/DecisionSiteGPTChatBox';

export type DecisionSiteChatBotSidePanelProps = {
  organizationSlug: string;
  chatboxContext: GPTChatBoxContext | undefined;
  setChatBoxContext: (context: GPTChatBoxContext | undefined) => unknown;
  internalDomains: string[];
  externalDomains: string[];
  externalContactIds: number[];
  onMeetingflowClick: (meetingflowId: DetailedMeetingflow['id']) => void;
  onContactClick: (c: Contact['id']) => void;
  onCompanyClick: (id: number) => void;
  meetingPlan: DetailedMeetingflow;
  onBack?: () => void;
  onClose: () => void;
};
export const DecisionSiteChatBotSidePanel = ({
  organizationSlug,
  meetingPlan,
  internalDomains,
  externalDomains,
  externalContactIds,
  chatboxContext,
  setChatBoxContext,
  onBack,
  onClose,
}: DecisionSiteChatBotSidePanelProps) => {
  const { isGuest, hasEntitlement } = useOrganization(organizationSlug);
  const { isDark } = useLightOrDarkMode();
  const { user: mfUser } = useUserProfile();

  const sidePanelSectionClass = mergeStyles({
    margin: '0 0 1.5rem 0',
    ':empty': {
      margin: 0,
    },
  });

  const beforeStart = meetingPlan
    ? DateTime.fromISO(meetingPlan.startTime).diffNow().milliseconds > 0
    : true;
  const beforeEnd = meetingPlan
    ? DateTime.fromISO(meetingPlan.endTime).diffNow().milliseconds > 0
    : true;
  const afterEnd = meetingPlan
    ? DateTime.fromISO(meetingPlan.endTime).diffNow().milliseconds < 0
    : false;
  const afterEnd24Hours = meetingPlan
    ? DateTime.fromISO(meetingPlan.endTime).diffNow().milliseconds < -86400000
    : false;

  // meeting is exterrnal is the meetingplan attendees do not all have internal domains for their email addresses
  const isExternalMeeting = meetingPlan.attendees.some((attendee) => {
    return !internalDomains.includes(attendee.emailDomain);
  });

  const hasTranscript = !!meetingPlan.callRecording?.transcriptRawText?.length;

  const initialMessages: {
    role: 'user' | 'assistant' | 'system';
    content: string;
    display?: boolean;
  }[] = useMemo(() => {
    return [
      {
        role: 'assistant',
        content: `What would you like to know?`,
        display: true,
      },
    ];
  }, []);

  const AVAILABLE_ACTIONS: GPTChatBoxAction[] = useMemo(
    () => [
      {
        key: 'brief',
        type: 'action',
        label: 'Brief Me',
        iconName: 'Handwriting',
        enabled: beforeEnd && !isGuest,
        onClick: () => {
          return {
            role: 'user',
            display: true,
            content: 'Brief me on this Meetingflow',
          };
        },
      },
      {
        key: 'suggest-agenda',
        type: 'action',
        label: 'Suggest Agenda',
        iconName: 'CalendarAgenda',
        enabled: beforeEnd && !isGuest,
        onClick: () => {
          return {
            role: 'user',
            display: true,
            content: 'Can you suggest an agenda for the meeting?',
          };
        },
      },
      {
        key: 'draft-prep-email',
        type: 'action',
        label: 'Draft Email',
        iconName: 'Mail',
        enabled: beforeEnd && !isGuest,
        onClick: () => {
          return {
            role: 'user',
            display: true,
            content: 'Can you draft an email reminder to the attendees for me?',
          };
        },
      },
      {
        key: 'suggest-questions',
        type: 'action',
        label: 'Suggest Questions',
        iconName: 'Mail',
        enabled: beforeEnd && !isGuest && isExternalMeeting,
        onClick: () => {
          return {
            role: 'user',
            display: true,
            content:
              'Suggest some questions that would be good for me to ask during the meeting.',
          };
        },
      },
      {
        key: 'decisions-made',
        type: 'action',
        label: 'Decisions Made',
        iconName: 'DecisionSolid',
        enabled: afterEnd && !isGuest && hasTranscript,
        onClick: () => {
          return {
            role: 'user',
            display: true,
            content: 'Were any decisions made during the call?',
          };
        },
      },
      {
        key: 'concerns-raised',
        type: 'action',
        label: 'Concerns',
        iconName: 'WarningSolid',
        enabled: afterEnd && !isGuest && hasTranscript,
        onClick: () => {
          return {
            role: 'user',
            display: true,
            content: 'Were any issues or concerns raised during the call?',
          };
        },
      },
      {
        key: 'action-items',
        type: 'action',
        label: 'Action Items',
        iconName: 'TaskLogo',
        enabled: afterEnd && !isGuest && hasTranscript,
        onClick: () => {
          return {
            role: 'user',
            display: true,
            content: 'Were there any action items or follow-up tasks?',
          };
        },
      },
      {
        key: 'how-it-go',
        type: 'action',
        label: "How'd the call go?",
        iconName: 'UnknownCall',
        enabled: afterEnd && !isGuest && hasTranscript,
        onClick: () => {
          return {
            role: 'user',
            display: true,
            content: 'How did the call go?',
          };
        },
      },
      {
        key: 'summarize',
        type: 'action',
        label: 'Summarize',
        iconName: 'Handwriting',
        enabled: afterEnd && !isGuest && !hasTranscript,
        onClick: () => {
          return {
            role: 'user',
            display: true,
            content: 'Please summarize this Meetingflow for me!',
          };
        },
      },
      {
        key: 'follow-up-ideas',
        type: 'action',
        label: 'Follow up Ideas',
        iconName: 'Lightbulb',
        enabled: afterEnd && !isGuest && !hasTranscript,
        onClick: () => {
          return {
            role: 'user',
            display: true,
            content:
              'Can you suggest ways in which I might follow up on this meeting?',
          };
        },
      },
      {
        key: 'draft-followup-email',
        type: 'action',
        label: 'Draft Follow-up Email',
        iconName: 'Mail',
        enabled: afterEnd && !isGuest && !hasTranscript,
        onClick: () => {
          return {
            role: 'user',
            display: true,
            content: 'Draft a follow up email to the attdendees for me.',
          };
        },
      },
    ],
    [beforeEnd, afterEnd, isGuest, isExternalMeeting, hasTranscript],
  );

  const thisChatBotContext: GPTChatBoxContext | undefined = useMemo(() => {
    if (!chatboxContext && mfUser && meetingPlan) {
      const actions = AVAILABLE_ACTIONS.filter((action) => {
        return action.enabled;
      });
      const context: GPTChatBoxContext = {
        key: `chatbot-${meetingPlan.id}`,
        initialMessages,
        actions,
      };
      return context;
    } else return chatboxContext;
  }, [chatboxContext, meetingPlan, mfUser, initialMessages, AVAILABLE_ACTIONS]);

  return (
    <DecisionSiteBaseSidePanel
      title="AI Chat"
      onBack={onBack}
      onClose={onClose}
      providesOwnScrolling
      contentPadding="0 0 .5rem 0"
    >
      {thisChatBotContext ? (
        <DecisionSiteGPTChatBox
          key={thisChatBotContext.key}
          organizationSlug={organizationSlug!}
          meetingPlanId={meetingPlan.id}
          initialMessages={thisChatBotContext.initialMessages}
          defaultActions={thisChatBotContext.actions}
          showChatInput
          chatInputPlaceholder="Ask me a question."
          isInSidePanel
        />
      ) : null}
    </DecisionSiteBaseSidePanel>
  );
};
