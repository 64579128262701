import { styled } from '@mui/material/styles';

export const StyledMeetingflowListRoot = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  gap: 1rem;
  
  .mf-creator-container {
    container-type: inline-size;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
    
    @container (max-width: 10rem) {
      span {
        display: none;
      }
    }
  }
`;

export const StyledMeetingflowList = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  overflow: hidden;

  table {
    width: 100%;

    @container (max-width: 20rem) {
      td.creator,
      th.creator {
        display: none;
      }

      td.organizer,
      th.organizer {
        display: none;
      }
    }

    .mf-creator-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;
    }
  }

  .MuiTableContainer-root {
    border-radius: 8px;
    box-shadow: none;
  }
`;
