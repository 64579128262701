import {
  FontWeights,
  Icon,
  IconButton,
  mergeStyles,
  NeutralColors,
  useTheme,
} from '@fluentui/react';
import { Resource } from '@meetingflow/common/Api/data-contracts';
import classNames from 'classnames';
import { useMemo } from 'react';
import { mimeTypeToIconName } from '../Helpers/IconHelpers';
import { useLightOrDarkMode } from '../Hooks/useLightOrDarkMode';
import { MEETINGFLOW_COLORS } from '../Themes/Themes';

interface ChipProps {
  id?: string;
  className?: string;
  type?: Resource['type'];
  text: string;
  mimeType?: string;
  onClick?: () => void;
  onDelete?: () => void;
}

export const Chip = ({
  id,
  className,
  type,
  text,
  mimeType,
  onClick,
  onDelete,
}: ChipProps) => {
  const theme = useTheme();
  const { isDark } = useLightOrDarkMode();

  const iconName = useMemo(() => {
    if (mimeType) {
      return mimeTypeToIconName(mimeType, 'Globe');
    }

    if (!type || type === 'link') {
      return 'Globe';
    }
    if (type === 'file') {
      return mimeTypeToIconName(mimeType);
    }
  }, [type, mimeType]);

  const chipStyles = mergeStyles({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',

    columnGap: '0.5rem',
    height: '1.5rem',
    backgroundColor: isDark
      ? NeutralColors.gray150
      : MEETINGFLOW_COLORS.purpleGreyMediumAlt,
    borderRadius: '1rem',
    paddingLeft: '.5rem',
    paddingRight: '1.25rem',
    lineHeight: '1.35rem',
    fontWeight: FontWeights.semibold,
    color: isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.black,
    cursor: 'pointer',
    transition: '.3s ease-in-out all',
    maxWidth: '13rem',
    ':hover': {
      backgroundColor: isDark
        ? NeutralColors.gray130
        : MEETINGFLOW_COLORS.purpleGreyMedium,
      button: {
        transition: '.3s ease-in-out all',
        backgroundColor: isDark
          ? NeutralColors.gray120
          : MEETINGFLOW_COLORS.purpleGreyMedium,
        color: MEETINGFLOW_COLORS.white,
      },
    },
    i: {
      position: 'relative',
      top: '1px',
    },
    button: {
      maxWidth: '1rem',
      height: '1rem',
      width: '1rem',
      position: 'relative',
      top: '4px',
      marginLeft: '.5rem',

      i: {
        position: 'relative',
        top: 'unset',
        color: isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.black,
      },
    },
    '.attachment-text': {
      maxWidth: '5rem',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  });

  return (
    <div
      id={id}
      className={classNames(chipStyles, className)}
      onClick={onClick}
      title={type === 'link' ? `Open ${text}` : `Download ${text}`}
    >
      {iconName ? <Icon iconName={iconName} /> : null}
      <span className="attachment-text">{text}</span>
      {onDelete ? (
        <IconButton
          id="chip-delete-button"
          title={`Remove from Meetingflow`}
          onClick={(e) => {
            e.stopPropagation();
            return onDelete();
          }}
          styles={{
            icon: { color: 'white' },
          }}
          iconProps={{ iconName: 'StatusCircleErrorX' }}
        />
      ) : null}
    </div>
  );
};
