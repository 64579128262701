import {
  Dropdown,
  FontSizes,
  FontWeights,
  Icon,
  Link,
  mergeStyles,
  NeutralColors,
  Pivot,
  PivotItem,
  Spinner,
} from '@fluentui/react';
import {
  ExternalServiceObject,
  ExternalServiceObjectType,
} from '@meetingflow/common/Api/data-contracts';
import { useEffect, useMemo, useState } from 'react';
import { useExternalServiceConfigurations } from '../../../../Hooks/useExternalServiceConfigurations';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import { MEETINGFLOW_COLORS } from '../../../../Themes/Themes';
import { HubSpotPanelContext } from '../../../../types/HubSpotPanelContext';
import { BaseSidePanel } from '../BaseSidePanel';
import { HubSpotBrowserSidePanelCompanyContent } from './HubSpotBrowserSidePanelCompanyContent';
import { HubSpotBrowserSidePanelDealContent } from './HubSpotBrowserSidePanelDealContent';
import { HubSpotBrowserSidePanelPinnedContent } from './HubSpotBrowserSidePanelPinnedContent';

export type HubSpotBrowserSidePanelProps = {
  organizationSlug: string;
  meetingPlanId?: string;
  externalContactIds?: number[];
  externalDomains?: string[];
  externalEmails?: string[];
  defaultTab?: ExternalServiceObjectType | 'PINNED';
  associatedObjects?: ExternalServiceObject[];
  pushHubSpotPanel: (context: HubSpotPanelContext) => void;
  onPinnedObject?:
    | ((e?: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<unknown>)
    | undefined;
  onPickedObject: (
    object: ExternalServiceObject | Omit<ExternalServiceObject, 'id'>,
  ) => void;
  onTabChanged?: (tab: ExternalServiceObjectType | 'PINNED') => void;
  onLogMeetingToHubSpot?: () => void;
  onBack?: () => void;
  onClose?: () => void;
};
export const HubSpotBrowserSidePanel = ({
  organizationSlug,
  meetingPlanId,
  externalDomains,
  externalEmails,
  externalContactIds,
  defaultTab,
  onPickedObject,
  onPinnedObject,
  onTabChanged,
  pushHubSpotPanel,
  onLogMeetingToHubSpot,
  onBack,
  onClose,
  associatedObjects = [],
}: HubSpotBrowserSidePanelProps) => {
  const { isDark } = useLightOrDarkMode();

  const {
    configurationsWithToken,
    loading: configurationsLoading,
    configurationById,
  } = useExternalServiceConfigurations({ app: 'HUBSPOT', withToken: true });

  const [activeConfigurationId, setActiveConfigurationId] = useState<
    number | undefined
  >();

  const activeConfiguration = useMemo(
    () =>
      activeConfigurationId
        ? configurationById(activeConfigurationId)
        : undefined,
    [activeConfigurationId, configurationById],
  );

  useEffect(() => {
    if (configurationsWithToken?.length === 1) {
      setActiveConfigurationId(configurationsWithToken[0].id);
    }
  }, [configurationsWithToken]);

  const [selectedTab, setSelectedTab] = useState<
    ExternalServiceObjectType | 'PINNED'
  >(defaultTab || 'PINNED');

  useEffect(() => {
    if (defaultTab && selectedTab !== defaultTab) {
      setSelectedTab(defaultTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTab]);

  const panelContent = mergeStyles({
    position: 'relative',
    height: '100%',
    minHeight: '100%',

    '> div': {
      height: '100%',
      maxHeight: '100%',
    },

    'div[role="tablist"]': { position: 'sticky', padding: '0 .25rem' },

    '.section-header': {
      margin: '1rem 0 .5rem 0 ',
      fontSize: FontSizes.medium,
      fontWeight: FontWeights.semibold,
      color: MEETINGFLOW_COLORS.teal,
      position: 'relative',
      display: 'block',
    },
  });

  if (configurationsLoading) {
    return (
      <div style={{ margin: '1rem 0', minHeight: '100%' }}>
        <Spinner />
      </div>
    );
  }

  const hubspotDealDashboardUrl = activeConfiguration
    ? `https://app.hubspot.com/contacts/${activeConfiguration.instanceId}/deals`
    : undefined;

  return (
    <BaseSidePanel
      title="HubSpot"
      loading={false}
      onBack={onBack}
      onClose={onClose}
      providesOwnScrolling
      // contentPadding="0"
    >
      <div className={panelContent}>
        {configurationsWithToken.length > 1 ? (
          <Dropdown
            disabled={configurationsLoading}
            options={configurationsWithToken.map((configuration) => ({
              key: configuration.id,
              text: configuration.instanceId,
            }))}
            selectedKey={activeConfigurationId}
            onChange={(_evt, option, _index) => {
              setActiveConfigurationId(option?.key as number | undefined);
            }}
            label="HubSpot instance"
          />
        ) : null}

        {activeConfigurationId ? (
          <Pivot
            defaultSelectedKey={selectedTab ?? 'PINNED'}
            selectedKey={selectedTab}
            onLinkClick={(item) => {
              if (item?.props.itemKey) {
                setSelectedTab(
                  item.props.itemKey as ExternalServiceObjectType | 'PINNED',
                );
                onTabChanged?.(
                  item.props.itemKey as ExternalServiceObjectType | 'PINNED',
                );
              }
            }}
            styles={{
              root: {
                height: '28px',
              },
              link: {
                marginTop: '.25rem',
                fontSize: FontSizes.small,
                height: '1.5rem',
                borderTopLeftRadius: '3px',
                borderTopRightRadius: '3px',
                color: NeutralColors.gray120,
                selectors: {
                  ':hover': {
                    backgroundColor: isDark
                      ? NeutralColors.gray150
                      : MEETINGFLOW_COLORS.purpleLighter,
                  },
                  ':active': {
                    backgroundColor: isDark
                      ? NeutralColors.gray150
                      : MEETINGFLOW_COLORS.purpleLighter,
                  },
                },
              },
              linkContent: {
                height: '32px',
              },
              text: {
                lineHeight: '28px',
              },
              linkIsSelected: {
                backgroundColor: isDark
                  ? NeutralColors.gray150
                  : MEETINGFLOW_COLORS.purpleLighter,
                color: isDark ? NeutralColors.gray90 : NeutralColors.gray140,
                fontWeight: FontWeights.semibold,
                '::before': {
                  display: 'none',
                },
              },
              itemContainer: {
                height: '100%',
                maxHeight: '100%',
                '> div:last-child': {
                  height: '100%',
                  maxHeight: '100%',
                },
                borderTop: `1px solid ${
                  isDark ? NeutralColors.gray150 : MEETINGFLOW_COLORS.purpleGrey
                }`,
              },
            }}
          >
            {meetingPlanId ? (
              <PivotItem itemKey="PINNED" headerText="Related">
                <HubSpotBrowserSidePanelPinnedContent
                  key={activeConfigurationId}
                  organizationSlug={organizationSlug}
                  meetingPlanId={meetingPlanId}
                  onPickedObject={onPickedObject}
                  associatedObjects={associatedObjects}
                  configurationId={activeConfigurationId}
                  externalDomains={externalDomains}
                  externalContactIds={externalContactIds}
                  onPinnedObject={onPinnedObject}
                  pushHubSpotPanel={pushHubSpotPanel}
                />
              </PivotItem>
            ) : null}
            <PivotItem itemKey="ACCOUNT" headerText="Companies">
              <HubSpotBrowserSidePanelCompanyContent
                key={activeConfigurationId}
                organizationSlug={organizationSlug}
                meetingPlanId={meetingPlanId}
                associatedObjects={associatedObjects}
                onPickedObject={onPickedObject}
                configurationId={activeConfigurationId}
                externalDomains={externalDomains}
                onPinnedObject={onPinnedObject}
                pushHubSpotPanel={pushHubSpotPanel}
              />
            </PivotItem>
            <PivotItem itemKey="DEAL" headerText="Deals">
              <HubSpotBrowserSidePanelDealContent
                key={activeConfigurationId}
                organizationSlug={organizationSlug}
                meetingPlanId={meetingPlanId}
                associatedObjects={associatedObjects}
                onPickedObject={onPickedObject}
                configurationId={activeConfigurationId}
                externalDomains={externalDomains}
                externalEmails={externalEmails}
                onPinnedObject={onPinnedObject}
                pushHubSpotPanel={pushHubSpotPanel}
              />
            </PivotItem>
          </Pivot>
        ) : null}
        <div
          style={{
            position: 'sticky',
            bottom: '0',
            left: '0',
            width: 'calc(100% - .5rem)',
            height: '10rem',
            // marginLeft: '-.5rem',
            // paddingTop: '.5rem',
            // paddingLeft: '.5rem',
            // paddingRight: '.5rem',
            paddingBottom: '.5rem',
            // borderTop: `1px solid ${
            //   isDark ? NeutralColors.gray160 : MEETINGFLOW_COLORS.purpleLighter
            // } `,
            backgroundColor: isDark
              ? NeutralColors.gray210
              : MEETINGFLOW_COLORS.purpleUltraSuperLightish,
          }}
        >
          <div
            style={{
              padding: '.5rem .25rem .25rem .6rem',
              paddingLeft: '.6rem',
            }}
          >
            {onLogMeetingToHubSpot ? (
              <div style={{ marginTop: '.5rem' }}>
                <Link
                  style={{
                    fontSize: FontSizes.medium,
                    fontWeight: FontWeights.semibold,
                    width: 'auto',
                  }}
                  onClick={onLogMeetingToHubSpot}
                >
                  <Icon
                    style={{
                      marginRight: '.5rem',
                      position: 'relative',
                      top: '.15rem',
                      backgroundColor: 'rgb(120,160,227)',
                      borderRadius: '1rem',
                      padding: '.5rem',
                      color: 'white',
                    }}
                    iconName="LightningBolt"
                  />
                  Log meeting to HubSpot
                </Link>
              </div>
            ) : null}
            <div style={{ marginTop: '.5rem' }}>
              <Link
                style={{
                  lineHeight: '2rem',
                  fontWeight: FontWeights.semibold,
                  width: 'auto',
                }}
                href={hubspotDealDashboardUrl}
                target="_blank"
              >
                <Icon
                  style={{
                    float: 'left',
                    marginRight: '.5rem',
                    position: 'relative',
                    top: '.15rem',
                    backgroundColor: 'rgb(120,160,227)',
                    borderRadius: '1rem',
                    padding: '.5rem',
                    color: 'white',
                    lineHeight: '1rem',
                  }}
                  iconName="Add"
                />
                HubSpot Deals
              </Link>
            </div>
          </div>
        </div>
      </div>
    </BaseSidePanel>
  );
};
