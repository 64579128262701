import { useAuth0 } from '@auth0/auth0-react';
import {
  FontSizes,
  mergeStyles,
  NeutralColors,
  SearchBox,
  Spinner,
  Text,
  Toggle,
} from '@fluentui/react';
import { ExternalServiceObject } from '@meetingflow/common/Api/data-contracts';
import { CollectToMap } from '@meetingflow/common/ArrayHelpers';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useExternalServiceConfigurations } from '../../../../Hooks/useExternalServiceConfigurations';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import { useLocalStorageState } from '../../../../Hooks/useLocalStorageState';
import { HubSpotCompany } from '../../../../Models/HubSpot/HubSpotCompany';
import { HubSpotOwner } from '../../../../Models/HubSpot/HubSpotOwner';
import {
  HubSpotCompaniesQuery,
  HubSpotOwnersQuery,
} from '../../../../QueryNames';
import { ApiClient } from '../../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../../Themes/Themes';
import { HubSpotPanelContext } from '../../../../types/HubSpotPanelContext';
import { HubSpotCompanyTile } from './HubSpotCompanyTile';

export type HubSpotBrowserSidePanelCompanyContentProps = {
  organizationSlug: string;
  meetingPlanId?: string;
  associatedObjects?: ExternalServiceObject[];
  configurationId: number;
  externalDomains?: string[];
  onPickedObject: (
    opportunity: ExternalServiceObject | Omit<ExternalServiceObject, 'id'>,
  ) => void;
  onPinnedObject:
    | ((e?: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<unknown>)
    | undefined;
  pushHubSpotPanel: (context: HubSpotPanelContext) => void;
};
export const HubSpotBrowserSidePanelCompanyContent = ({
  organizationSlug,
  meetingPlanId,
  associatedObjects,
  configurationId,
  externalDomains,
  onPickedObject,
  onPinnedObject,
  pushHubSpotPanel,
}: HubSpotBrowserSidePanelCompanyContentProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const { isDark } = useLightOrDarkMode();

  const { loading: configurationsLoading, configurationById } =
    useExternalServiceConfigurations({ app: 'HUBSPOT', withToken: true });

  const [q, setQ] = useState<string>('');

  const [onlyMine, setOnlyMine] = useLocalStorageState<boolean>(
    'HS_PICKER_SIDE_PANEL_COMPANIES_ONLY_MINE',
    true,
  );

  const {
    data: hubspotOwners,
    isLoading: hubspotOwnersLoading,
    refetch: refetchHubspotOwners,
  } = useQuery(
    HubSpotOwnersQuery(organizationSlug!, configurationId),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<HubSpotOwner[]>(
        `/organization/${organizationSlug}/external/hubspot/configuration/${configurationId}/owners`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!configurationId,
    },
  );

  const hubspotOwnersMap = useMemo(
    () => CollectToMap(hubspotOwners?.data ?? [], 'id'),
    [hubspotOwners?.data],
  );

  const {
    data: hubspotCompanies,
    isLoading: hubspotCompaniesLoading,
    isRefetching: hubspotCompaniesRefetching,
    refetch: refetchHubspotCompanies,
  } = useQuery(
    HubSpotCompaniesQuery(organizationSlug!, configurationId),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<HubSpotCompany[]>(
        `/organization/${organizationSlug}/external/hubspot/configuration/${configurationId}/companies`,
        {
          params: {
            q: q ?? undefined,
            my: onlyMine,
          },
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRefetch = useCallback(debounce(refetchHubspotCompanies, 250), [
    refetchHubspotCompanies,
  ]);

  useEffect(() => {
    debouncedRefetch();
  }, [q, onlyMine, debouncedRefetch]);

  useEffect(() => {
    refetchHubspotOwners();
    refetchHubspotCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configurationId]);

  const panelContent = mergeStyles({
    padding: '0 .5rem',
  });

  const slideSpinnerClass = mergeStyles({
    height: '2rem',
    animationName: 'slideDownSpinnerAnimation',
    animationDuration: '1s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '.5',
    animationFillMode: 'forwards',
    position: 'absolute',
    top: '4.35rem',
    right: '.5rem',
  });

  if (configurationsLoading || hubspotOwnersLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className={panelContent}>
        <div
          style={{
            marginLeft: '-.5rem',
            marginRight: '-.5rem',
            marginBottom: '.5rem',
            padding: '.5rem',
            borderBottom: `1px solid ${
              isDark ? NeutralColors.gray180 : MEETINGFLOW_COLORS.purpleGrey
            }`,
            backgroundColor: isDark
              ? NeutralColors.gray210
              : MEETINGFLOW_COLORS.purpleUltraLight,
          }}
        >
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              marginBottom: '.25rem',
            }}
          >
            <div
              style={{
                paddingRight: '.5rem',
              }}
            >
              <Toggle
                disabled={hubspotCompaniesLoading || hubspotCompaniesRefetching}
                label={'Show Only Mine'}
                checked={onlyMine === true}
                onChange={(e, checked) => setOnlyMine(!!checked)}
                inlineLabel
                styles={{
                  root: {
                    margin: 0,
                    transition: '.3s ease-in-out all',
                    '.ms-Toggle-background': {
                      backgroundColor: MEETINGFLOW_COLORS.white,
                    },
                    '.ms-Toggle-thumb': {
                      backgroundColor: MEETINGFLOW_COLORS.teal,
                    },
                    'button:disabled .ms-Toggle-thumb': {
                      backgroundColor: NeutralColors.gray70,
                    },
                    'button:hover': {
                      backgroundColor: NeutralColors.gray40,
                    },
                  },
                  label: {
                    order: 0,
                    margin: 0,
                    marginRight: '.25rem',
                    fontSize: FontSizes.small,
                    width: `calc(100% - 66px)`,
                    color: NeutralColors.gray120,
                  },

                  pill: {
                    backgroundColor: NeutralColors.gray40,
                    outlineWidth: `0 !important`,
                    borderWidth: `0 !important`,
                  },
                }}
              />
            </div>
          </div>
          <SearchBox
            disabled={hubspotCompaniesLoading || hubspotCompaniesRefetching}
            placeholder="Search companies by name..."
            value={q}
            onChange={(e, newValue) => setQ(newValue ?? '')}
            styles={{
              root: {
                transition: '.3s ease-in-out all',
                fontSize: FontSizes.small,
                height: '1.5rem',
                marginTop: '.5rem',
                width:
                  configurationsLoading ||
                  hubspotCompaniesLoading ||
                  hubspotCompaniesRefetching
                    ? 'calc(100% - 2rem)'
                    : '100%',
              },
            }}
          />
        </div>
      </div>

      <div
        style={{
          overflowY: 'auto',
          maxHeight: 'calc(100% - 18rem)',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '.125rem',
          padding: '0 .5rem 2rem .5rem',
          boxSizing: 'border-box',
        }}
      >
        {hubspotCompaniesLoading || hubspotCompaniesRefetching ? (
          <Spinner className={slideSpinnerClass} />
        ) : null}
        {!!hubspotCompanies?.data?.length ? (
          hubspotCompanies?.data?.map((company) => {
            const externalServiceObject = {
              externalId: company.id,
              objectType: 'ACCOUNT',
              name: company.properties.name,
              serviceConfigurationId: configurationId,
              serviceConfiguration: configurationById(configurationId)!,
            } as Omit<ExternalServiceObject, 'id'>;
            return (
              <HubSpotCompanyTile
                key={company.id}
                meetingPlanId={meetingPlanId}
                organizationSlug={organizationSlug}
                id={company.id}
                name={company.properties.name}
                ownerName={
                  company.properties.hubspot_owner_id &&
                  !!hubspotOwnersMap[company.properties.hubspot_owner_id]
                    ? `${
                        hubspotOwnersMap[company.properties.hubspot_owner_id]
                          .firstName
                      } ${
                        hubspotOwnersMap[company.properties.hubspot_owner_id]
                          .lastName
                      }`
                    : undefined
                }
                ownerEmail={
                  company.properties.hubspot_owner_id &&
                  !!hubspotOwnersMap[company.properties.hubspot_owner_id]
                    ? hubspotOwnersMap[company.properties.hubspot_owner_id]
                        ?.email
                    : undefined
                }
                hubspotInstance={
                  configurationById(configurationId)!.instanceId!
                }
                isPinned={
                  !!associatedObjects?.find((o) => o.externalId === company.id)
                }
                externalServiceObjectId={
                  associatedObjects?.find((o) => o.externalId === company.id)
                    ?.id
                }
                onClick={() => onPickedObject(externalServiceObject)}
                onPinClick={onPinnedObject}
                showExternalLink
              />
            );
          })
        ) : (
          <div>
            {!hubspotCompaniesLoading && !hubspotCompaniesRefetching ? (
              <Text
                block
                variant="small"
                style={{ fontStyle: 'italic', margin: '5rem 0' }}
              >
                There are no matching companies. Change your filters above to
                show all companies, if needed.
              </Text>
            ) : null}
          </div>
        )}
      </div>
    </>
  );
};
