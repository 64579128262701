import {
  FontIcon,
  FontSizes,
  FontWeights,
  Icon,
  ImageIcon,
  NeutralColors,
  mergeStyles,
} from '@fluentui/react';
import { EditIcon, StatusCircleErrorXIcon } from '@fluentui/react-icons-mdl2';
import { SlateAIAction } from '@meetingflow/common/Api/data-contracts';
import { Editor, Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useSlateStatic } from 'slate-react';
import { v4 } from 'uuid';
import { CreateDeferredPromise } from '../../../Helpers/DeferredPromise';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { AIActionContext } from '../../../types/AIActionContext';
import { MeetingPlanPanelContext } from '../../../types/MeetingPlanPanelContext';
import { unwrapNodes } from '../Helpers/EditorHelpers';

export const AIActionElement = ({
  attributes,
  children,
  element,
  setPanelContext,
  getAIActionContext,
}: RenderElementProps & {
  setPanelContext?: (
    context?: MeetingPlanPanelContext | MeetingPlanPanelContext[],
  ) => void;
  getAIActionContext: CreateDeferredPromise<
    AIActionContext,
    Partial<AIActionContext> | undefined
  >;
}) => {
  const editor = useSlateStatic();

  const aiActionElement = element as SlateAIAction;

  const mentionClass = mergeStyles({
    fontWeight: FontWeights.semibold,
    color: MEETINGFLOW_COLORS.white,
    display: 'inline-block',
    position: 'relative',
    margin: '0px 0.1rem',
  });

  const mentionStyles = mergeStyles({
    display: 'inline-block !important',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignItems: 'center',
    alignContent: 'space-around',
    columnGap: '0.25rem',
    minHeight: '1.2rem',
    height: 'auto',
    backgroundColor: MEETINGFLOW_COLORS.tealDark,
    borderRadius: '.25rem',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    lineHeight: '1.1rem',
    fontWeight: FontWeights.semibold,
    fontSize: FontSizes.small,
    color: MEETINGFLOW_COLORS.white,
    cursor: 'pointer',
    transition: '.3s ease-in-out all',
    ':hover': {
      backgroundColor: MEETINGFLOW_COLORS.teal,
      color: NeutralColors.white,

      '.ai-action-buttons-wrapper': {
        display: 'inline-block',
        opacity: '1',
        right: '-1.25rem',
        top: '-.5rem',
      },
    },

    '.shortcut-type-icon': {
      display: 'inline-block',
      marginRight: '.25rem',
      position: 'relative',
      top: '2px',
      svg: {
        display: 'inline-block',
        width: '.75rem',
        marginRight: '.25rem',
      },
    },

    '.ai-action-buttons-wrapper': {
      opacity: '0',
      width: 'auto',
      height: '1.5rem',
      display: 'flex',
      overflow: 'hidden',
      transition: '.3s ease-in-out all',
      backgroundColor: MEETINGFLOW_COLORS.tealDark,
      position: 'absolute',
      top: 0,
      right: 0,
      borderRadius: '.25rem',

      '[data-ai-action-button="true"]': {
        display: 'inline-block',
        height: '100%',
        width: '1rem',
        lineHeight: '1.5rem',
        textAlign: 'center',
        paddingLeft: '.25rem',
        paddingRight: '.25rem',
        transition: '.3s ease-in-out all',
        ':hover': {
          backgroundColor: MEETINGFLOW_COLORS.tealDarker,
        },
        svg: {
          height: '1em',
          verticalAlign: 'baseline',
        },
      },
    },
  });

  return (
    <div {...attributes} contentEditable={false} className={mentionClass}>
      <div
        className={mentionStyles}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          const path = ReactEditor.findPath(editor, element);

          Transforms.select(editor, Editor.end(editor, path));

          const text = Editor.string(editor, path, { voids: false });

          setPanelContext?.({
            type: 'chatbot',
            context: {
              key: v4(),
              initialMessages: [
                {
                  role: 'user',
                  display: true,
                  displayText:
                    aiActionElement.displayText ||
                    aiActionElement.prompt ||
                    text,
                  content:
                    aiActionElement.prompt ||
                    aiActionElement.displayText ||
                    text,
                },
              ],
            },
          });
        }}
      >
        <Icon
          contentEditable={false}
          iconName="AISparkle"
          className="shortcut-type-icon"
        />
        {children}
        <div className={'ai-action-buttons-wrapper'}>
          <EditIcon
            contentEditable={false}
            data-ai-action-button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              const path = ReactEditor.findPath(editor, element);

              Transforms.select(editor, Editor.end(editor, path));

              const currentText = Editor.string(editor, path);

              getAIActionContext({
                text: Editor.string(editor, path),
                displayText: aiActionElement.displayText,
                prompt: aiActionElement.prompt,
              }).promise.then((context) => {
                if (
                  context.text !== currentText ||
                  context.displayText !== aiActionElement.displayText ||
                  context.prompt !== aiActionElement.prompt
                ) {
                  Editor.withoutNormalizing(editor, () => {
                    if (
                      context.displayText !== aiActionElement.displayText ||
                      context.prompt !== aiActionElement.prompt
                    ) {
                      Transforms.setNodes<SlateAIAction>(
                        editor,
                        {
                          displayText: context.displayText,
                          prompt: context.prompt,
                        },
                        { at: path },
                      );
                    }

                    if (context.text !== currentText) {
                      Transforms.select(editor, path);
                      Transforms.insertText(editor, context.text);
                    }
                  });
                }
              });
            }}
          />

          <StatusCircleErrorXIcon
            contentEditable={false}
            data-ai-action-button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              const path = ReactEditor.findPath(editor, element);

              unwrapNodes(editor, 'ai-action', { at: path });
            }}
          />
        </div>
      </div>
    </div>
  );
};
