import { useMemo } from 'react';
import { useDeferredPromise } from '../../useDeferredPromise';
import { FulfillRequestAttachmentDialog } from '../../../Components/DealRoom/Dialogs/FulfillRequestAttachmentDialog';

type UseFulfillRequestAttachmentProps = {
  organizationSlug: string;
  dealRoomId: number;
  actionItemId: number | null;
  ownerName: string;
  description: string;
};
export const useFulfillRequestAttachment = ({
  organizationSlug,
  dealRoomId,
  actionItemId,
  ownerName,
  description,
}: UseFulfillRequestAttachmentProps) => {
  const { createDeferred, resolve, reject, deferred } = useDeferredPromise();

  const dialog = useMemo(() => {
    if (actionItemId === null) return null;

    if (!deferred || !deferred.isPending) {
      return null;
    }

    return (
      <FulfillRequestAttachmentDialog
        organizationSlug={organizationSlug}
        dealRoomId={dealRoomId}
        onResolve={resolve}
        onDismiss={reject}
        actionItemId={actionItemId}
        ownerName={ownerName}
        description={description}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deferred, deferred?.isPending, dealRoomId, organizationSlug]);

  return {
    createDeferred,
    resolve,
    reject,
    deferred,
    dialog,
  };
};
