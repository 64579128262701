import {
  ITextFieldProps,
  mergeStyles,
  TextField,
  Icon,
  IconButton,
} from '@fluentui/react';
import { useForceUpdate } from '@fluentui/react-hooks';
import { ShareArtifactPayload } from '@meetingflow/common/Api/data-contracts';
import { useCallback, useState } from 'react';
import { BaseModal } from '../../MeetingPlans/Dialogs/BaseModal';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { DealRoomCommandBarButton } from '../Components/DealRoomButton';

type ShareDealRoomArtifactDialogProps = {
  organizationSlug: string;
  onShare: (payload: ShareArtifactPayload) => void;
  onDismiss: () => void;
  artifactName?: string;
};

export const ShareDealRoomArtifactDialog = ({
  organizationSlug,
  onShare,
  onDismiss,
  artifactName,
}: ShareDealRoomArtifactDialogProps) => {
  const forceUpdate = useForceUpdate();

  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [contacts, setContacts] = useState<ShareArtifactPayload['contacts']>(
    [],
  );
  const [emails, setEmails] = useState('');
  const [isEmailListValid, setIsEmailListValid] = useState(false);

  const modalStyles = mergeStyles({
    '& > .ms-Overlay': {
      backdropFilter: 'blur(3px)',
    },

    '.ms-Dialog-main': {
      width: '60%',
      height: 'auto',
      maxHeight: '60%',
      overflowY: 'auto',
      boxShadow: '0px 8px 16px 0px #00000024',
      borderRadius: '8px',
      maxWidth: '640px',
    },
  });

  const dialogContentStyle = mergeStyles({
    width: 'calc(100% - 2rem)',
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',
    padding: '1rem',

    '.title-section': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '0.25rem',
      flexWrap: 'wrap',
      fontSize: '1.5rem',
      lineHeight: '2.5rem',
      color: DEALROOMS_COLORS.themePrimary,
      marginBottom: '1.5rem',

      '.custom-title-style': {
        fontWeight: '700',
      },

      '.close-button': {
        ':hover': {
          backgroundColor: DEALROOMS_COLORS.inputLightGray,
          borderRadius: '4px',
        },

        i: {
          fontSize: '12px',
          padding: '8px',
          color: DEALROOMS_COLORS.neutralDarker,

          ':hover': {
            color: DEALROOMS_COLORS.neutralDarker,
          },

          ':active': {
            color: DEALROOMS_COLORS.neutralDarker,
          },
        },
      },
    },

    '.custom-label-container': {
      display: 'flex',
      alignItems: 'flex-start',
      gap: '5px',
      marginBottom: '4px',

      '.label-text': {
        color: DEALROOMS_COLORS.darkerGray,
      },

      '.label-icon': {
        color: DEALROOMS_COLORS.darkerRed,
        fontSize: '7px',
      },
    },

    '.text-field-box': {
      marginBottom: '1rem',

      '.ms-TextField-fieldGroup': {
        borderRadius: '4px',
        border: 'none !important',
        height: 'auto',

        '::after': {
          borderRadius: '4px',
          border: `2px solid ${DEALROOMS_COLORS.neutralSecondary}`,
        },
      },

      input: {
        backgroundColor: DEALROOMS_COLORS.inputLightGray,
        color: DEALROOMS_COLORS.darkerGray,
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '1.375',
        borderRadius: '4px',
        padding: '9px 14px',

        '::placeholder': {
          color: DEALROOMS_COLORS.themeSecondary,
        },
      },

      textarea: {
        backgroundColor: DEALROOMS_COLORS.inputLightGray,
        color: DEALROOMS_COLORS.darkerGray,
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '22px',
        borderRadius: '4px',
        padding: '9px 14px',
        height: '96px',
        maxHeight: '96px',
        overflowY: 'auto',

        '::placeholder': {
          color: DEALROOMS_COLORS.themeSecondary,
        },
      },
    },

    '.email-group': {
      marginBottom: '1rem',

      '.email-field': {
        marginBottom: '0',
      },

      '.email-error-message': {
        marginTop: '.5rem',
        fontSize: '14px',
        color: DEALROOMS_COLORS.darkerRed,
      },
    },

    '.action-buttons': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: '.5rem',

      '.cancel-button': {
        padding: '.4375rem 1rem',

        span: {
          fontSize: '15px',
          lineHeight: '24px',
        },
      },

      '.share-button': {
        padding: '.4375rem 1rem',
        border: `1px solid ${DEALROOMS_COLORS.primaryButton}`,
        backgroundColor: DEALROOMS_COLORS.primaryButton,
        color: DEALROOMS_COLORS.white,
        cursor: 'pointer',

        span: {
          fontSize: '15px',
          lineHeight: '24px',
        },

        i: {
          color: DEALROOMS_COLORS.white,
        },

        ':hover': {
          color: DEALROOMS_COLORS.white,

          i: {
            color: `${DEALROOMS_COLORS.white} !important`,
          },
        },

        ':active': {
          color: DEALROOMS_COLORS.white,

          i: {
            color: `${DEALROOMS_COLORS.white} !important`,
          },
        },
      },
    },
  });

  const validateEmailList = useCallback((input: string) => {
    const pattern: RegExp = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    const emailList: string[] = input
      .split(',')
      .map((email) => email.trim())
      .filter(Boolean);

    for (const email of emailList) {
      if (!pattern.test(email)) {
        return { isListValid: false, emailList };
      }
    }

    return { isListValid: true, emailList };
  }, []);

  const handleChangeEmailsInput = useCallback(
    (newValue: string) => {
      setEmails(newValue || '');
      const { isListValid, emailList } = validateEmailList(newValue || '');
      setIsEmailListValid(isListValid);
      if (isListValid) {
        setContacts(emailList.map((email: string) => ({ email })));
      } else {
        setContacts([]);
      }
    },
    [validateEmailList],
  );

  const onShareClick = useCallback(() => {
    if (!subject || !message) {
      return;
    }

    onShare({
      subject,
      message,
      contacts,
    });
  }, [contacts, message, subject, onShare]);

  const onRenderLabel = useCallback((props?: ITextFieldProps): JSX.Element => {
    return (
      <div className="custom-label-container">
        <span className="label-text">{props?.label || ''}</span>
        <Icon className="label-icon" iconName="AsteriskSolid" />
      </div>
    );
  }, []);

  return (
    <BaseModal className={modalStyles} isOpen onDismiss={onDismiss}>
      <div className={dialogContentStyle}>
        <div className="title-section">
          <div>
            Share{' '}
            <span className="custom-title-style">
              {artifactName || 'Preview Document'}
            </span>
          </div>
          <IconButton
            className="close-button"
            iconProps={{ iconName: 'ChromeClose' }}
            onClick={onDismiss}
          />
        </div>
        <TextField
          className="text-field-box"
          label="Name"
          placeholder="Add a name here..."
          onRenderLabel={onRenderLabel}
          value={subject}
          onChange={(e, newValue) => {
            setSubject(newValue || '');
          }}
        />
        <div className="email-group">
          <TextField
            className="text-field-box email-field"
            label="Email"
            placeholder="Add emails separated by commas here..."
            value={emails}
            onRenderLabel={onRenderLabel}
            onChange={(e, newValue) => {
              handleChangeEmailsInput(newValue || '');
            }}
          />
          {!isEmailListValid && emails.length > 0 && (
            <div className="email-error-message">
              Email list includes invalid emails
            </div>
          )}
        </div>
        <TextField
          className="text-field-box"
          label="Add a note"
          placeholder="Add a note here..."
          multiline
          autoAdjustHeight
          resizable={false}
          value={message}
          onRenderLabel={onRenderLabel}
          onChange={(e, newValue) => {
            setMessage(newValue || '');
            forceUpdate();
          }}
        />
        <div className="action-buttons">
          <DealRoomCommandBarButton
            text="Cancel"
            customClasses="cancel-button"
            buttonStyleType="COMMAND_BAR"
            onClick={onDismiss}
          />
          <DealRoomCommandBarButton
            text="Share"
            customClasses="share-button"
            buttonStyleType="COMMAND_BAR"
            disabled={!subject || !message || !contacts.length}
            onClick={onShareClick}
            iconProps={{ iconName: 'CheckMark' }}
          />
        </div>
      </div>
    </BaseModal>
  );
};
