export const AugmentLogo = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="8" fill="#40424F" />
    <path
      d="M13.6989 28C13.4056 27.9993 13.1175 27.9228 12.8625 27.7779C12.6074 27.633 12.394 27.4246 12.243 27.1728C12.0921 26.9211 12.0086 26.6346 12.0006 26.3411C11.9927 26.0475 12.0606 25.757 12.1978 25.4974C12.225 25.4467 14.9101 20.372 16.6073 17.3105C19.328 12.4068 22.7599 12 26.3372 12C26.7817 12.0105 27.2046 12.1946 27.5153 12.5132C27.826 12.8317 28 13.2593 28 13.7046C28 14.1498 27.826 14.5774 27.5153 14.896C27.2046 15.2145 26.7817 15.3987 26.3372 15.4091C23.0854 15.4091 21.3601 15.7595 19.5822 18.9648C17.9029 21.9962 15.2328 27.0437 15.2056 27.0935C15.0612 27.3668 14.8452 27.5957 14.5808 27.7554C14.3163 27.9151 14.0134 27.9997 13.7045 28"
      fill="#F6F6F6"
    />
    <path
      d="M26.0005 28C25.7379 28.0001 25.4778 27.9551 25.235 27.8677C24.9923 27.7802 24.7718 27.6519 24.586 27.4902C24.4002 27.3284 24.2529 27.1364 24.1523 26.925C24.0518 26.7136 24 26.4871 24 26.2583V19.7028C24.0119 19.2477 24.2279 18.8146 24.6017 18.4964C24.9754 18.1782 25.4774 18 26 18C26.5227 18 27.0246 18.1782 27.3983 18.4964C27.7721 18.8146 27.9881 19.2477 28 19.7028V26.2583C28 26.7202 27.7893 27.1632 27.4144 27.4899C27.0394 27.8165 26.5308 28 26.0005 28Z"
      fill="#F6F6F6"
    />
  </svg>
);
