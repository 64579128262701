import { useNavigate } from 'react-router-dom';
import { DecisionSiteMeetingflowCard } from '../../../../Components/MeetingPlans/DecisionSiteMeetingflowCard';
import {
  DSPagination,
  DSMeetingflowAttendeeFacepile,
  DSContactAvatar,
} from '../../DS';
import { InfiniteData } from 'react-query';
import { AxiosResponse } from 'axios';
import { Meetingflow } from '@meetingflow/common/Api/data-contracts';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { useDealRoom } from '../../../../Hooks/useDealRoom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import {
  StyledMeetingflowListRoot,
  StyledMeetingflowList,
} from './DecisionSiteMeetingflowList.styles';

interface DecisionSiteMeetingflowListProps {
  meetingPlansPages: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  InfiniteData<AxiosResponse<Meetingflow[], any>> | undefined;
  currentPage: number;
  onPageChange: (page: number) => void;
  listRef?: React.RefObject<HTMLDivElement>;
  className?: string;
}

const DecisionSiteMeetingflowList: React.FC<
  DecisionSiteMeetingflowListProps
> = ({ meetingPlansPages, currentPage, onPageChange, listRef, className }) => {
  const navigate = useNavigate();
  const { slug: organizationSlug } = useOrganization();
  const { dealRoomId } = useDealRoom();

  if (!organizationSlug || !dealRoomId) {
    return null;
  }

  return (
    <StyledMeetingflowListRoot ref={listRef} className={className}>
      <StyledMeetingflowList>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Meetingflow</TableCell>
                <TableCell className="attendees" align="right">
                  Attendees
                </TableCell>
                <TableCell className="organizer" align="right">
                  Organized by
                </TableCell>
                <TableCell className="creator" align="right">
                  Added by
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {meetingPlansPages?.pages.map((page) =>
                page?.data?.map((plan) => (
                  <TableRow
                    key={plan.id}
                    onClick={() => {
                      navigate(
                        `/organization/${organizationSlug}/dealroom/${dealRoomId}/plan/${plan.id}`,
                      );
                    }}
                  >
                    <TableCell
                      sx={{ paddingLeft: '0' }}
                      className="meetingflow"
                    >
                      <DecisionSiteMeetingflowCard
                        meetingflowId={plan.id}
                        organizationSlug={organizationSlug}
                        showCallRecordingButton
                        showCompanies
                        hideEventIcon
                        hideCRMIcon
                      />
                    </TableCell>
                    <TableCell className="attendees" align="right">
                      <DSMeetingflowAttendeeFacepile meetingPlan={plan} />
                    </TableCell>
                    <TableCell className="organizer" align="right">
                      <div className="mf-creator-container">
                        <span>{plan.organizer?.name}</span>
                        {plan.organizer && (
                          <DSContactAvatar contact={plan.organizer} />
                        )}
                      </div>
                    </TableCell>
                    <TableCell className="creator" align="right">
                      <div className="mf-creator-container">
                        <span>{plan.creator?.name}</span>
                        {plan.creator && (
                          <DSContactAvatar contact={plan.creator} />
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                )),
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledMeetingflowList>
      <div className="pagination">
        <DSPagination
          count={meetingPlansPages?.pages.length}
          page={currentPage}
          onChange={(e, page) => {
            onPageChange(page);
          }}
        />
      </div>
    </StyledMeetingflowListRoot>
  );
};

export default DecisionSiteMeetingflowList;
