import { Resource } from '@meetingflow/common/Api/data-contracts';
import { useMemo } from 'react';
import { v4 } from 'uuid';
import { AddMeetingPlanResourceDialog } from '../../Components/MeetingPlans/Dialogs/AddMeetingPlanResourceDialog';
import { useDeferredPromise } from '../useDeferredPromise';

type UseAddResourceDialogProps = {
  organizationSlug: string;
  meetingPlanId?: string;
};
export const useAddResourceDialog = ({
  organizationSlug,
  meetingPlanId,
}: UseAddResourceDialogProps) => {
  const { createDeferred, resolve, reject, deferred } = useDeferredPromise<
    Resource,
    File | undefined
  >();

  const dialog = useMemo(() => {
    if (!deferred || !deferred.isPending) {
      return null;
    }

    return (
      <AddMeetingPlanResourceDialog
        key={v4()}
        organizationSlug={organizationSlug}
        meetingPlanId={meetingPlanId}
        file={deferred.context}
        onAdd={resolve}
        onDismiss={reject}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deferred, deferred?.isPending, meetingPlanId, organizationSlug]);

  return {
    createDeferred,
    resolve,
    reject,
    deferred,
    dialog,
  };
};
