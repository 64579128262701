import { mergeStyleSets } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

type UseConversationContainerStyleProps = {
  isComment: boolean;
  isRecentNotification: boolean;
};

export const useConversationContainerStyle = ({
  isComment,
  isRecentNotification,
}: UseConversationContainerStyleProps) => {
  return mergeStyleSets({
    convButtonStyle: {
      minWidth: '19.6875rem',
      padding: '0.75rem 1rem',
      borderRadius: '0.25rem',
      border: '1px solid ' + DEALROOMS_COLORS.neutralLight,
      backgroundColor: DEALROOMS_COLORS.neutralLighterAlt,
      color: DEALROOMS_COLORS.darkerGray,
      minHeight: 'auto',
      ':hover': {
        backgroundColor: DEALROOMS_COLORS.neutralLighter,
        border: '1px solid ' + DEALROOMS_COLORS.neutralLight,
        color: DEALROOMS_COLORS.darkerGray,
      },
      ':focus': {
        outline: '1px solid ' + DEALROOMS_COLORS.neutralSecondary,
      },
      ':active': {
        backgroundColor: DEALROOMS_COLORS.neutralLight,
        color: DEALROOMS_COLORS.darkerGray,
        border: '1px solid transparent',
      },
      '.deleteConversation': {
        padding: '0 0.3rem 0 0.5rem',
        width: '1rem',
        height: '1rem',
      },
      '>span': {
        width: '100%',
      },
    },
    convContainerStyle: {
      minWidth: '17.5rem',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      gap: '0.75rem',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      position: 'relative',
      ...(isComment
        ? {
            padding: '0.5rem 0.75rem',
            width: 'calc(100% - 1.5rem)',
            borderRadius: '0.25rem',
          }
        : {}),
      ':hover': {
        backgroundColor: DEALROOMS_COLORS.neutralLighter,
      },
    },
    ownerName: {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      color: DEALROOMS_COLORS.themePrimary,
      fontWeight: isRecentNotification ? '600' : '400',
    },
    profileImage: {
      minWidth: '2.5rem !important',
      minHeight: '2.5rem !important',
      width: '2.5rem !important',
      height: '2.5rem !important',
      borderRadius: '50%',
    },
    contentContainer: {
      flexGrow: '1',
    },
    messageContainer: {
      textAlign: 'left',
      pointerEvents: 'none',
    },
    commentNotifications: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      gap: '2px',
      minWidth: '5.6rem',
    },
    commentTimeAgoContainer: {
      display: 'flex',
      gap: '.25rem',
      alignItems: 'center',
    },
  });
};
