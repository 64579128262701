import { useAuth0 } from '@auth0/auth0-react';
import { mergeStyles } from '@fluentui/react';
import {
  Contact,
  DetailedMeetingflow,
} from '@meetingflow/common/Api/data-contracts';
import { useQuery } from 'react-query';
import { ContactRecentPlansQuery } from '../../../QueryNames';
import { MeetingflowsApiClient } from '../../../Services/NetworkCommon';
import { MeetingflowCard } from '../MeetingflowCard';
import { PlanReaderList } from './PlanReader';

export interface ContactRecentPlansListProps {
  organizationSlug: string;
  contact: Pick<Contact, 'id' | 'name' | 'email' | 'emailDomain'>;
  currentMeetingPlanId?: string;
  maxCount?: number;
  onContactClick: (c: Contact['id']) => void;
  onMeetingflowClick?: (meetingflowId: DetailedMeetingflow['id']) => void;
  color: string;
}
export const ContactRecentPlansList = ({
  organizationSlug,
  currentMeetingPlanId,
  contact,
  maxCount,
  onContactClick,
  onMeetingflowClick,
  color,
}: ContactRecentPlansListProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const {
    data: relatedPlansData,
    isLoading: relatedPlansLoading,
    refetch: refetchRelatedPlans,
  } = useQuery(
    ContactRecentPlansQuery(
      organizationSlug!,
      contact.email!,
      currentMeetingPlanId,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      const contactPlansResult = await MeetingflowsApiClient.listPlans(
        { organizationSlug, contactId: contact.id, limit: maxCount },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return contactPlansResult;
    },
  );

  const planListWrapperClass = mergeStyles({
    '@container (width >= 40rem)': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      columnGap: '.5rem',
      rowGap: '.5rem',

      '> div': {
        boxSizing: 'border-box',
        flexBasis: 'calc(50% - .25rem)',
        maxWidth: 'calc(50% - 0.25rem)',
      },

      '> div:only-child': {
        flexBasis: '100%',
        maxWidth: '100%',
      },
    },

    '@container (width < 40rem)': {
      display: 'block',
    },
  });

  return (
    <div className={planListWrapperClass}>
      {relatedPlansData?.data?.map((p) => (
        <MeetingflowCard
          key={p.id}
          meetingflowObj={p}
          refetchMeetingflow={refetchRelatedPlans}
          meetingflowId={p.id}
          organizationSlug={organizationSlug}
          onClick={onMeetingflowClick}
          expandable
          showCompanies
          showCallRecordingButton
        />
      ))}
    </div>
  );
};
