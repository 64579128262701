import { DealRoomVideoArtifact } from '@meetingflow/common/Api/data-contracts';
import { ImageWithFallback } from '../../Common/ImageWithFallback';
import { ArtifactIconPreview } from './ArtifactIconPreview';
import { BaseArtifactCard, BaseArtifactCardProps } from './BaseArtifactCard';

export type VideoArtifactCardProps = BaseArtifactCardProps &
  Pick<
    DealRoomVideoArtifact,
    'fileName' | 'fileUrl' | 'thumbnailFileName' | 'thumbnailUrl' | 'mimeType'
  >;

export const VideoArtifactCard = ({
  orgSlug,
  dealRoomId,
  fileName,
  fileUrl,
  thumbnailFileName,
  thumbnailUrl,
  label,
  name,
  mimeType,
  ...rest
}: VideoArtifactCardProps) => {
  return (
    <BaseArtifactCard
      className="video-artifact"
      orgSlug={orgSlug}
      dealRoomId={dealRoomId}
      label={label}
      name={name}
      {...rest}
    >
      <ImageWithFallback
        src={thumbnailUrl || fileUrl}
        fallbackSrc={thumbnailUrl ? fileUrl : undefined}
        alt={label || name}
        fallback={
          <ArtifactIconPreview
            type="VIDEO"
            fileExtension={fileName.split('.').pop()}
            mimeType={mimeType}
          />
        }
      />
    </BaseArtifactCard>
  );
};
