import { IconButton, mergeStyleSets, Modal, Stack } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import {
  buyerOrientationSurveyOptions,
  stepIndexesBasedOnName,
  stepNames,
} from '../Components/BuyerOrientationSurvey/buyerOrientationSurveyUtils';
import { ChromeCloseIconProps } from '../../../utils/iconProps';
import { BuyerOrientationSurveySecondaryHeader } from '../Components/BuyerOrientationSurvey/BuyerOrientationSurveySecondaryHeader';
import { useUserProfile } from '../../../Hooks/useProfile';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SurveyOptionCard } from '../Components/BuyerOrientationSurvey/SurveyOptionCard';
import { BuyerOrientationSurveyActionButtons } from '../Components/BuyerOrientationSurvey/BuyerOrientationSurveyActionButtons';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import {
  BuyerPriorities,
  DealRole,
} from '@meetingflow/common/Api/data-contracts';

type BuyerPrioritiesDialogProps = {
  organizationSlug?: string;
  dealRoomId?: number;
  handleCloseModal: () => void;
  userDealRole: DealRole | null | undefined;
};

export const BuyerPrioritiesDialog = ({
  organizationSlug,
  dealRoomId,
  handleCloseModal,
  userDealRole,
}: BuyerPrioritiesDialogProps) => {
  const [userPriorities, setUserPriorities] = useState<string[]>([]);
  const initialUserPriorities = useRef<string[]>([]);

  const [otherPriorityText, setOtherPriorityText] = useState('');
  const initialOtherPriorityText = useRef('');

  const wasCurrentPrioritiesSyncedWithBE = useRef(false);

  const { user } = useUserProfile();

  const { dealRoom, refetch: refetchDealRoom } = useDealRoom(
    organizationSlug,
    dealRoomId,
  );

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (!wasCurrentPrioritiesSyncedWithBE.current) {
      const currentUserDetails = dealRoom?.contacts?.find(
        (contact) => contact.email.toLowerCase() === user?.email.toLowerCase(),
      );

      if (
        currentUserDetails &&
        'priorities' in currentUserDetails &&
        Array.isArray(currentUserDetails.priorities)
      ) {
        setUserPriorities(currentUserDetails.priorities);
        initialUserPriorities.current = currentUserDetails.priorities;

        if (
          currentUserDetails.priorities.includes('OTHER') &&
          'otherPriorityText' in currentUserDetails &&
          typeof currentUserDetails.otherPriorityText === 'string'
        ) {
          setOtherPriorityText(currentUserDetails.otherPriorityText);
          initialOtherPriorityText.current =
            currentUserDetails.otherPriorityText;
        }

        wasCurrentPrioritiesSyncedWithBE.current = true;
      }
    }
  }, [dealRoom, user?.email]);

  const styles = mergeStyleSets({
    modalWrapper: {
      '.ms-Dialog-main': {
        minHeight: 'auto',
        minWidth: 'auto',
        height: 'fit-content',
        width: 'calc(100% - 4rem)',
        maxWidth: '910px',
        maxHeight: 'calc(100% - 4rem)',
        boxShadow: '0px 2px 2px 0px #00000005',
        borderRadius: '4px',
        backgroundColor: 'transparent',

        '.ms-Modal-scrollableContent': {
          maxHeight: 'fit-content',
        },
      },
    },
    contentContainer: {
      width: '100%',
    },
    closeButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '1rem',
      backgroundColor: DEALROOMS_COLORS.neutralGray,
    },
    prioritiesContainer: {
      width: 'calc(100% - 8rem)',
      padding: '0 4rem 1.5rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '1.5rem',
      backgroundColor: DEALROOMS_COLORS.neutralGray,
    },
    prioritiesList: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      gap: '1.5rem',

      '@media(max-width: 810px)': {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },

      '@media(max-width: 620px)': {
        gridTemplateColumns: '1fr',
      },
    },
    otherOptionContainer: {
      width: '100%',
      maxWidth: '372px',
      margin: '0 auto 1.5rem',
    },
  });

  const lastOptionDetails = useMemo(() => {
    return buyerOrientationSurveyOptions.priorities.options[
      buyerOrientationSurveyOptions.priorities.options.length - 1
    ];
  }, []);

  const handleSelectOption = useCallback((newOptionValue: string) => {
    setUserPriorities((prevState: string[]) => {
      return prevState.includes(newOptionValue)
        ? prevState.filter((item) => item !== newOptionValue)
        : [...prevState, newOptionValue];
    });
  }, []);

  const handleSaveNewOptions = useCallback(async () => {
    if (
      !userDealRole ||
      !user?.email ||
      !organizationSlug ||
      !dealRoomId ||
      (JSON.stringify(userPriorities) ===
        JSON.stringify(initialUserPriorities.current) &&
        otherPriorityText === initialOtherPriorityText.current)
    ) {
      handleCloseModal();
    } else {
      const token = await getAccessTokenSilently();

      await toast.promise(
        DealRoomsApiClient.updateContact(
          organizationSlug,
          dealRoomId,
          user.email,
          {
            role: userDealRole,
            priorities: userPriorities as BuyerPriorities[],
            ...(userPriorities.includes('OTHER')
              ? {
                  otherPriorityText,
                }
              : {}),
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        ),
        {
          loading: 'Updating the option',
          success: () => {
            if (refetchDealRoom) {
              refetchDealRoom().then(() => {
                handleCloseModal();
              });
            }
            return 'The option was successfully updated.';
          },
          error: 'Something went wrong. Please try again later',
        },
      );
    }
  }, [
    dealRoomId,
    getAccessTokenSilently,
    handleCloseModal,
    organizationSlug,
    otherPriorityText,
    refetchDealRoom,
    user?.email,
    userDealRole,
    userPriorities,
  ]);

  const renderOptions = useMemo(() => {
    return buyerOrientationSurveyOptions.priorities.options
      .slice(0, -1)
      .map((option) => (
        <SurveyOptionCard
          key={option.optionId}
          option={option}
          isSelected={userPriorities.includes(option.value)}
          handleSelectOption={() => handleSelectOption(option.value)}
        />
      ));
  }, [handleSelectOption, userPriorities]);

  return (
    <Modal className={styles.modalWrapper} isOpen onDismiss={handleCloseModal}>
      <Stack className={styles.contentContainer}>
        <div className={styles.closeButton}>
          <IconButton
            ariaLabel="Close"
            iconProps={ChromeCloseIconProps}
            onClick={handleCloseModal}
            alt="Close image"
          />
        </div>
        <BuyerOrientationSurveySecondaryHeader
          step={stepIndexesBasedOnName[stepNames.priorities]}
          avatarUrl={
            dealRoom?.owner?.avatarFileUrl ||
            dealRoom?.owner?.avatarUrl ||
            undefined
          }
        />
        <div className={styles.prioritiesContainer}>
          <div className={styles.prioritiesList}>{renderOptions}</div>
          <div className={styles.otherOptionContainer}>
            <SurveyOptionCard
              key={lastOptionDetails.optionId}
              option={lastOptionDetails}
              isSelected={userPriorities.includes(lastOptionDetails.value)}
              handleSelectOption={handleSelectOption}
              showTextArea={userPriorities.includes(lastOptionDetails.value)}
              textAreaValue={otherPriorityText || ''}
              handleChangeTextArea={(newValue: string) =>
                setOtherPriorityText(newValue)
              }
            />
          </div>
          <BuyerOrientationSurveyActionButtons
            handleClickBack={handleCloseModal}
            handleClickNext={handleSaveNewOptions}
            step={stepIndexesBasedOnName[stepNames.priorities]}
            numberOfSteps={4}
          />
        </div>
      </Stack>
    </Modal>
  );
};
