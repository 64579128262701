import { useAuth0 } from '@auth0/auth0-react';
import {
  CommandButton,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  FontIcon,
  FontSizes,
  FontWeights,
  Icon,
  IconButton,
  IContextualMenuItem,
  IDropdownOption,
  IFacepilePersona,
  IPersonaProps,
  IPivotStyles,
  mergeStyles,
  NeutralColors,
  Persona,
  PersonaSize,
  Pivot,
  PivotItem,
  PrimaryButton,
  Spinner,
  Text,
  TooltipHost,
  useTheme,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import {
  Attendee,
  Company,
  Contact,
  DetailedMeetingflow,
  User,
} from '@meetingflow/common/Api/data-contracts';
import { GroupBy } from '@meetingflow/common/ArrayHelpers';
import { COMMON_INTERNAL_RESOURCE_DOMAINS } from '@meetingflow/common/PublicEmailDomains';
import { guessNameFromEmail } from '@meetingflow/common/StringHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { inflect } from 'inflection';
import randomColor from 'randomcolor';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { renderToString } from 'react-dom/server';
import toast from 'react-hot-toast';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import {
  toPersonaDescription,
  toPersonaPresence,
} from '../../Helpers/EventResponseHelper';
import { encodeLightningField } from '../../Helpers/Salesforce/SalesforceFieldHelpers';
import { hasContent } from '../../Helpers/SlateHelpers';
import { titleCase } from '../../Helpers/Typography';
import { externalize } from '../../Helpers/URLHelpers';
import useBreakpoints from '../../Hooks/useBreakpoints';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { useUserProfile } from '../../Hooks/useProfile';
import { HubSpotPlanContext } from '../../Models/HubSpot/HubSpotPlanContext';
import { SalesforcePlanContext } from '../../Models/Salesforce/SalesforcePlanContext';
import {
  ContactLinkedInData,
  HubSpotContactContextQuery,
  HubSpotPlanContextQuery,
  SalesforceContactContextQuery,
} from '../../QueryNames';
import { ApiClient, ContactsApiClient } from '../../Services/NetworkCommon';
import CrunchbaseIcon from '../../Static/Images/crunchbase.png';
import FacebookIcon from '../../Static/Images/facebook.png';
import GoogleIcon from '../../Static/Images/google.png';
import HubSpotIcon from '../../Static/Images/hubspot.png';
import LinkedInIcon from '../../Static/Images/linkedin.png';
import SalesforceIcon from '../../Static/Images/salesforce.png';
import TwitterIcon from '../../Static/Images/twitter.svg';
import { DEALROOMS_COLORS, MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { PresenceState } from '../../types/MeetingPlanDocument';
import StyledDateTime from '../StyledDateTime';
import MeetingPlanAddAttendeesButton from './MeetingPlanAddAttendeesButton';
import { CompanyNoteEditor } from './SidePanels/CompanyNoteEditor';
import { ContactNoteEditor } from './SidePanels/ContactNoteEditor';
import classNames from 'classnames';
import { useExternalServiceConfigurations } from '../../Hooks/useExternalServiceConfigurations';
import { SalesforceUser } from '../../Models/Salesforce/SaleforceUser';
import { HubSpotContact } from '../../Models/HubSpot/HubSpotContact';
import { HubSpotContactTile } from './SidePanels/HubSpot/HubSpotContactTile';
import { SalesforceContactTile } from './SidePanels/Salesforce/SalesforceContactTile';
import { GPTTeaser } from '../GPT/GPTTeaser';
import { MeetingPlanPanelContext } from '../../types/MeetingPlanPanelContext';
import { shuffle } from 'lodash';
import { suggestChatPrompts } from '../../Helpers/ContactHelpers';
import { DateTime } from 'luxon';
import { GPTChatBoxContext } from '../GPT/GPTChatBoxContext';
import DecisionSiteMeetingPlanAddAttendeesButton from './DecisionSiteMeetingPlanAddAttendeesButton';

const NUM_ATTENDEES_FOR_FULL_WIDTH_LAYOUT = 4;

const NUM_COMPANIES_TO_DEFAULT_COLLAPSE_ALL = 4;

const baseIconImageProps = {
  width: '16px',
  height: '16px',
};

const imageIconStyle = {
  position: 'relative' as const,
  marginRight: '.25rem',
  cursor: 'pointer',
};

const ROW_GAP_OFFSET = '.25rem';

interface DecisionSiteMeetingPlanAttendeesProps {
  organizationSlug: string;
  meetingPlan: Pick<
    DetailedMeetingflow,
    | 'id'
    | 'title'
    | 'creator'
    | 'organizer'
    | 'attendees'
    | 'companies'
    | 'externalId'
    | 'viewStats'
    | 'startTime'
    | 'endTime'
    | 'callRecording'
  >;
  salesforcePlanContext?: SalesforcePlanContext;
  hubspotPlanContext?: HubSpotPlanContext;
  excludeOrganizer?: boolean;
  excludeCreator?: boolean;
  excludeInternal?: boolean;
  excludeExternal?: boolean;
  internalDomains?: string[];
  showAttendeesOnly?: boolean;
  showAttendeesOnlyLimit?: number;
  showNoDataMessage?: boolean;
  limitToCompanies?: Company[];
  onlyShowAttendeesWithNotes?: boolean;
  onContactClick?: (contactId: Contact['id']) => void;
  onContactInviteClick?: (c: Contact) => void;
  onCompanyClick?: (companyId: Company['id']) => void;
  panelVisible?: boolean;
  companyMaxHeight?: string;
  displayContactNotes?: boolean;
  allowEditingContactNotes?: boolean;
  contactNotesEditableBackgroundColor?: string;
  smallWidthLayout?: boolean;
  rowGap?: string;
  attendeesPerCompanyLimit?: number;
  displayAsBoxes?: boolean;
  noPivot?: boolean;
  showAttendeeCount?: boolean;
  contactCardBackgroundColor?: string;
  companyCardBackgroundColor?: string;
  showAvatars?: boolean;
  showEmailDomains?: boolean;
  awarenessStates?: Map<number, PresenceState>;
  hideCompanyHeader?: boolean;
  allowEditAttendees?: boolean;
  refetchMeetingPlan?: () => Promise<unknown>;
  showContactSecondaryText?: boolean;
  pushPanelContext?: (context: MeetingPlanPanelContext) => void;
  setChatboxContext?: (context: GPTChatBoxContext | undefined) => unknown;
}

export const DecisionSiteMeetingPlanAttendees = ({
  organizationSlug,
  meetingPlan,
  salesforcePlanContext,
  hubspotPlanContext,
  excludeOrganizer = false,
  excludeCreator = false,
  internalDomains = [],
  excludeExternal,
  excludeInternal,
  onContactClick,
  onContactInviteClick,
  onCompanyClick,
  limitToCompanies,
  onlyShowAttendeesWithNotes = false,
  panelVisible = false,
  companyMaxHeight,
  displayContactNotes = false,
  allowEditingContactNotes = true,
  contactNotesEditableBackgroundColor,
  smallWidthLayout = false,
  rowGap,
  displayAsBoxes,
  noPivot,
  showAttendeeCount = false,
  contactCardBackgroundColor,
  companyCardBackgroundColor,
  showAvatars,
  showEmailDomains,
  awarenessStates,
  hideCompanyHeader,
  allowEditAttendees = false,
  refetchMeetingPlan,
  showContactSecondaryText = true,
  pushPanelContext,
  setChatboxContext,
}: DecisionSiteMeetingPlanAttendeesProps) => {
  const { isDark } = useLightOrDarkMode();

  const filteredAttendees: Attendee[] = useMemo(() => {
    let attendees =
      meetingPlan?.attendees?.filter(
        (attendee) =>
          !COMMON_INTERNAL_RESOURCE_DOMAINS.includes(attendee.emailDomain),
      ) ?? [];

    if (excludeCreator) {
      attendees = attendees?.filter(
        (a) => a.email !== meetingPlan.creator?.email,
      );
    }
    if (excludeOrganizer) {
      attendees = attendees?.filter(
        (a) => a.email !== meetingPlan.organizer?.email,
      );
    }

    if (excludeInternal) {
      attendees = attendees.filter(
        (a) => !internalDomains.includes(a.emailDomain),
      );
    }

    if (excludeExternal) {
      attendees = attendees.filter((a) =>
        internalDomains.includes(a.emailDomain),
      );
    }

    return attendees;
  }, [
    excludeCreator,
    excludeExternal,
    excludeInternal,
    excludeOrganizer,
    internalDomains,
    meetingPlan.attendees,
    meetingPlan.creator?.email,
    meetingPlan.organizer?.email,
  ]);

  const internalAttendees = useMemo(
    () =>
      filteredAttendees.filter((a) => internalDomains.includes(a.emailDomain)),
    [filteredAttendees, internalDomains],
  );

  const externalAttendees = useMemo(
    () =>
      filteredAttendees.filter((a) => !internalDomains.includes(a.emailDomain)),
    [filteredAttendees, internalDomains],
  );

  const peopleByDomain = useMemo(
    () => GroupBy(filteredAttendees, (a) => a.emailDomain),
    [filteredAttendees],
  );

  const sortedPeopleByDomain = useMemo(
    () =>
      Object.keys(peopleByDomain)
        .sort((a, b) => peopleByDomain[b].length - peopleByDomain[a].length)
        // @ts-ignore
        // eslint-disable-next-line no-sequences
        .reduce((acc, key) => ((acc[key] = peopleByDomain[key]), acc), {}),
    [peopleByDomain],
  );

  const internalPeopleByDomain = useMemo(
    () => GroupBy(internalAttendees, (a) => a.emailDomain),
    [internalAttendees],
  );

  const sortedInternalPeopleByDomain = useMemo(
    () =>
      Object.keys(internalPeopleByDomain)
        .sort(
          (a, b) =>
            internalPeopleByDomain[b].length - internalPeopleByDomain[a].length,
        )
        .reduce(
          // @ts-ignore
          // eslint-disable-next-line no-sequences
          (acc, key) => ((acc[key] = internalPeopleByDomain[key]), acc),
          {},
        ),
    [internalPeopleByDomain],
  );

  const externalPeopleByDomain = useMemo(
    () => GroupBy(externalAttendees, (a) => a.emailDomain),
    [externalAttendees],
  );

  const sortedExternalPeopleByDomain = useMemo(
    () =>
      Object.keys(externalPeopleByDomain)
        .sort(
          (a, b) =>
            externalPeopleByDomain[b].length - externalPeopleByDomain[a].length,
        )
        .reduce(
          // @ts-ignore
          // eslint-disable-next-line no-sequences
          (acc, key) => ((acc[key] = externalPeopleByDomain[key]), acc),
          {},
        ),
    [externalPeopleByDomain],
  );

  const sortedExternalAndInternalPeopleByDomain = useMemo(
    () => ({
      ...sortedExternalPeopleByDomain,
      ...sortedInternalPeopleByDomain,
    }),
    [sortedExternalPeopleByDomain, sortedInternalPeopleByDomain],
  );

  const filteredCompanies = useMemo(() => {
    const domainKeys = Object.keys(peopleByDomain);

    if (limitToCompanies) {
      return limitToCompanies.filter((c) =>
        c.domains?.some((d) => domainKeys.includes(d.domain)),
      );
    }

    return meetingPlan.companies.filter((c) =>
      c.domains?.some((d) => domainKeys.includes(d.domain)),
    );
  }, [meetingPlan.companies, limitToCompanies, peopleByDomain]);

  const filteredExternalCompanies = useMemo(
    () => filteredCompanies.filter((c) => !c.isInternal),
    [filteredCompanies],
  );
  const filteredInternalCompanies = useMemo(
    () => filteredCompanies.filter((c) => !!c.isInternal),
    [filteredCompanies],
  );

  const getCompanyAttendees = useCallback(
    (c: Company) => {
      let attendees =
        c.domains
          ?.map((domain) => domain.domain)
          ?.flatMap(
            (domain) =>
              Object.keys(peopleByDomain)
                .filter((key) => key === domain || key.endsWith(domain))
                .flatMap((key) => peopleByDomain[key]) ?? [],
          ) ?? [];

      if (onlyShowAttendeesWithNotes) {
        attendees = attendees.filter((a: Attendee) => {
          const thisContact = meetingPlan.attendees.find(
            (attendee) => attendee.email === a.email,
          );
          const hasNoNotes = !hasContent(thisContact?.notes);
          if (onlyShowAttendeesWithNotes) {
            return !hasNoNotes;
          } else {
            return true;
          }
        });
      }
      return attendees;
    },
    [meetingPlan.attendees, onlyShowAttendeesWithNotes, peopleByDomain],
  );

  const showPivot =
    !noPivot &&
    !excludeExternal &&
    !excludeInternal &&
    filteredExternalCompanies.length !== 0 &&
    filteredInternalCompanies.length !== 0;

  // sort companies by number of attendees
  const sortedInternalCompanies = useMemo(
    () =>
      filteredInternalCompanies
        .sort(
          (a, b) =>
            getCompanyAttendees(b).length - getCompanyAttendees(a).length,
        )
        .filter((c) => getCompanyAttendees(c).length > 0),
    [filteredInternalCompanies, getCompanyAttendees],
  );

  const sortedExternalCompanies = useMemo(
    () =>
      filteredExternalCompanies
        .sort(
          (a, b) =>
            getCompanyAttendees(b).length - getCompanyAttendees(a).length,
        )
        .filter((c) => getCompanyAttendees(c).length > 0),
    [filteredExternalCompanies, getCompanyAttendees],
  );
  const sortedCompanies = useMemo(
    () => [...sortedExternalCompanies, ...sortedInternalCompanies],
    [sortedExternalCompanies, sortedInternalCompanies],
  );

  const externalAttendeeCount = useMemo(
    () =>
      filteredExternalCompanies.flatMap((c) => getCompanyAttendees(c)).length,
    [filteredExternalCompanies, getCompanyAttendees],
  );

  const internalAttendeeCount = useMemo(
    () =>
      filteredInternalCompanies.flatMap((c) => getCompanyAttendees(c)).length,
    [filteredInternalCompanies, getCompanyAttendees],
  );

  const attendeesPivotItemClass = mergeStyles({
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '.25rem',
    rowGap: rowGap ? rowGap : '.5rem',
  });

  const pivotStyles = {
    root: {
      height: '26px',
      textAlign: 'right',
      padding: '0',
      position: 'absolute',
      right: 0,
      top: '-26px',
      zIndex: 500,
    },
    link: {
      transition: '.3s ease-in-out all',
      fontSize: FontSizes.small,
      height: 'calc(1.25rem + 1px)',
      lineHeight: 'calc(1.25rem + 1px)',
      padding: 0,
      margin: 0,
      color: isDark ? NeutralColors.gray100 : DEALROOMS_COLORS.themePrimary,
      backgroundColor: 'transparent',

      '.ms-Pivot-text': {
        fontSize: FontSizes.small,
        position: 'relative',
        top: '1px',
      },
      selectors: {
        ':first-child': {
          marginLeft: '0',
          borderBottomLeftRadius: '3px',
        },
        ':last-child': {
          borderBottomRightRadius: '3px',
        },
        ':hover': {
          backgroundColor: isDark
            ? NeutralColors.gray150
            : MEETINGFLOW_COLORS.purpleLighter,

          '.company-count': {
            backgroundColor: isDark
              ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
              : MEETINGFLOW_COLORS.purpleDark,
            color: isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.white,
          },
        },
        ':active': {
          backgroundColor: isDark
            ? NeutralColors.gray150
            : MEETINGFLOW_COLORS.purpleLighter,
        },
      },
      '.company-count': {
        transition: '.3s ease-in-out all',
        display: 'inline-block',
        backgroundColor: isDark
          ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
          : MEETINGFLOW_COLORS.purpleGreyMedium,
        color: isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.white,
        padding: '0 .25rem',
        borderRadius: '50%',
        fontSize: FontSizes.xSmall,
        fontWeight: FontWeights.bold,
        lineHeight: '1rem',
        height: '1rem',
        minWidth: '1rem',
        marginLeft: '.25rem',
        boxSizing: 'border-box',
        textAlign: 'center',
      },
    },
    linkContent: {
      height: '32px',
    },
    text: {
      lineHeight: '28px',
    },
    linkIsSelected: {
      backgroundColor: isDark
        ? NeutralColors.gray150
        : MEETINGFLOW_COLORS.purpleLighter,
      color: isDark ? NeutralColors.gray20 : MEETINGFLOW_COLORS.purpleMedium,
      fontWeight: FontWeights.semibold,
      '::before': {
        display: 'none',
      },
      '.company-count': {
        backgroundColor: isDark
          ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
          : MEETINGFLOW_COLORS.white,
        color: isDark
          ? MEETINGFLOW_COLORS.white
          : MEETINGFLOW_COLORS.purpleMedium,
      },
    },
    itemContainer: {
      height: '100%',
      maxHeight: '100%',
      containerType: 'inline-size',
      '> div:last-child': {
        height: '100%',
        maxHeight: '100%',
      },
    },
  } as Partial<IPivotStyles>;

  const pivotWrapperClass = mergeStyles({
    position: 'relative',
  });

  return showPivot ? (
    <div className={pivotWrapperClass}>
      <Pivot styles={pivotStyles}>
        {sortedExternalCompanies.length > 0 ? (
          <PivotItem
            id="meetingflow-attendees-external"
            // @ts-ignore
            headerText={
              <>
                External{' '}
                <span className={'company-count'}>{externalAttendeeCount}</span>
              </>
            }
            className={attendeesPivotItemClass}
          >
            {sortedExternalCompanies?.map((company, idx) => {
              const attendees = company ? getCompanyAttendees(company) : [];
              return company && attendees.length > 0 ? (
                <CompanyAttendees
                  key={`${meetingPlan?.id}-${company.id}`}
                  organizationSlug={organizationSlug}
                  meetingPlan={meetingPlan}
                  salesforcePlanContext={salesforcePlanContext}
                  hubspotPlanContext={hubspotPlanContext}
                  internalDomains={internalDomains}
                  company={company}
                  attendees={attendees}
                  onContactClick={onContactClick}
                  onContactInviteClick={onContactInviteClick}
                  onCompanyClick={onCompanyClick}
                  panelVisible={panelVisible}
                  companyMaxHeight={companyMaxHeight}
                  displayContactNotes={displayContactNotes}
                  allowEditingContactNotes={allowEditingContactNotes}
                  smallWidthLayout={smallWidthLayout}
                  rowGap={rowGap}
                  displayAsBoxes={displayAsBoxes}
                  showAttendeeCount={showAttendeeCount}
                  showAvatars={showAvatars}
                  soloCompany={
                    attendees.length >=
                      NUM_ATTENDEES_FOR_FULL_WIDTH_LAYOUT - 1 ||
                    sortedExternalCompanies.length === 1
                  }
                  contactNotesEditableBackgroundColor={
                    contactNotesEditableBackgroundColor
                  }
                  contactCardBackgroundColor={contactCardBackgroundColor}
                  companyCardBackgroundColor={companyCardBackgroundColor}
                  awarenessStates={awarenessStates}
                  showEmailDomains={showEmailDomains}
                  hideCompanyHeader={hideCompanyHeader}
                  allowEditAttendees={allowEditAttendees}
                  refetchMeetingPlan={refetchMeetingPlan}
                  meetingPlanId={meetingPlan.id}
                  showContactSecondaryText={showContactSecondaryText}
                  defaultCollapsed={
                    sortedExternalCompanies.length >
                    NUM_COMPANIES_TO_DEFAULT_COLLAPSE_ALL - 1
                  }
                  pushPanelContext={pushPanelContext}
                  setChatboxContext={setChatboxContext}
                />
              ) : null;
            })}
          </PivotItem>
        ) : null}
        {sortedInternalCompanies.length > 0 ? (
          <PivotItem
            id="meetingflow-attendees-internal"
            // @ts-ignore
            headerText={
              <>
                Internal{' '}
                <span className={'company-count'}>{internalAttendeeCount}</span>
              </>
            }
            className={attendeesPivotItemClass}
          >
            {sortedInternalCompanies?.map((company, idx) => {
              const attendees = company ? getCompanyAttendees(company) : [];
              return company && attendees.length > 0 ? (
                <CompanyAttendees
                  key={`${meetingPlan?.id}-${company.id}`}
                  organizationSlug={organizationSlug}
                  meetingPlan={meetingPlan}
                  salesforcePlanContext={salesforcePlanContext}
                  hubspotPlanContext={hubspotPlanContext}
                  internalDomains={internalDomains}
                  company={company}
                  attendees={attendees}
                  onContactClick={onContactClick}
                  onContactInviteClick={onContactInviteClick}
                  onCompanyClick={onCompanyClick}
                  panelVisible={panelVisible}
                  companyMaxHeight={companyMaxHeight}
                  displayContactNotes={displayContactNotes}
                  allowEditingContactNotes={allowEditingContactNotes}
                  smallWidthLayout={smallWidthLayout}
                  rowGap={rowGap}
                  displayAsBoxes={displayAsBoxes}
                  showAttendeeCount={showAttendeeCount}
                  showAvatars={showAvatars}
                  soloCompany={
                    attendees.length >=
                      NUM_ATTENDEES_FOR_FULL_WIDTH_LAYOUT - 1 ||
                    sortedInternalCompanies.length === 1
                  }
                  contactNotesEditableBackgroundColor={
                    contactNotesEditableBackgroundColor
                  }
                  contactCardBackgroundColor={contactCardBackgroundColor}
                  companyCardBackgroundColor={companyCardBackgroundColor}
                  awarenessStates={awarenessStates}
                  showEmailDomains={showEmailDomains}
                  hideCompanyHeader={hideCompanyHeader}
                  allowEditAttendees={allowEditAttendees}
                  refetchMeetingPlan={refetchMeetingPlan}
                  meetingPlanId={meetingPlan.id}
                  showContactSecondaryText={showContactSecondaryText}
                  defaultCollapsed={
                    sortedInternalCompanies.length >
                    NUM_COMPANIES_TO_DEFAULT_COLLAPSE_ALL - 1
                  }
                  pushPanelContext={pushPanelContext}
                  setChatboxContext={setChatboxContext}
                />
              ) : null;
            })}
          </PivotItem>
        ) : null}
      </Pivot>
    </div>
  ) : (
    <>
      <div id="meetingflow-attendees" className={attendeesPivotItemClass}>
        {sortedCompanies.map((company, idx) => {
          const attendees = company ? getCompanyAttendees(company) : [];
          return company && attendees.length > 0 ? (
            <CompanyAttendees
              key={`${meetingPlan?.id}=${company.id}`}
              organizationSlug={organizationSlug}
              meetingPlan={meetingPlan}
              salesforcePlanContext={salesforcePlanContext}
              hubspotPlanContext={hubspotPlanContext}
              internalDomains={internalDomains}
              company={company}
              attendees={attendees}
              onContactClick={onContactClick}
              onContactInviteClick={onContactInviteClick}
              onCompanyClick={onCompanyClick}
              panelVisible={panelVisible}
              companyMaxHeight={companyMaxHeight}
              displayContactNotes={displayContactNotes}
              allowEditingContactNotes={allowEditingContactNotes}
              smallWidthLayout={smallWidthLayout}
              rowGap={rowGap}
              displayAsBoxes={displayAsBoxes}
              showAttendeeCount={showAttendeeCount}
              showAvatars={showAvatars}
              soloCompany={true}
              contactNotesEditableBackgroundColor={
                contactNotesEditableBackgroundColor
              }
              contactCardBackgroundColor={contactCardBackgroundColor}
              companyCardBackgroundColor={companyCardBackgroundColor}
              awarenessStates={awarenessStates}
              showEmailDomains={showEmailDomains}
              hideCompanyHeader={hideCompanyHeader}
              allowEditAttendees={allowEditAttendees}
              refetchMeetingPlan={refetchMeetingPlan}
              meetingPlanId={meetingPlan.id}
              showContactSecondaryText={showContactSecondaryText}
              defaultCollapsed={
                sortedCompanies.length >
                NUM_COMPANIES_TO_DEFAULT_COLLAPSE_ALL - 1
              }
              pushPanelContext={pushPanelContext}
              setChatboxContext={setChatboxContext}
            />
          ) : null;
        })}
      </div>
      {allowEditAttendees && !meetingPlan?.externalId ? (
        <DecisionSiteMeetingPlanAddAttendeesButton
          organizationSlug={organizationSlug}
          meetingPlan={meetingPlan}
          refetchMeetingPlan={refetchMeetingPlan}
        />
      ) : null}
    </>
  );
};

interface CompanyAttendeesProps {
  organizationSlug: string;
  meetingPlan: Pick<
    DetailedMeetingflow,
    | 'id'
    | 'title'
    | 'attendees'
    | 'externalId'
    | 'viewStats'
    | 'startTime'
    | 'endTime'
    | 'callRecording'
    | 'companies'
  >;
  salesforcePlanContext?: SalesforcePlanContext;
  hubspotPlanContext?: HubSpotPlanContext;
  internalDomains: string[];
  company: Company;
  attendees: Attendee[];
  onContactClick?: (c: Contact['id']) => void;
  onContactInviteClick?: (c: Contact) => void;
  onCompanyClick?: (companyId: Company['id']) => void;
  panelVisible: boolean;
  companyMaxHeight?: string;
  displayContactNotes?: boolean;
  allowEditingContactNotes?: boolean;
  contactNotesEditableBackgroundColor?: string;
  smallWidthLayout?: boolean;
  rowGap?: string;
  displayAsBoxes?: boolean;
  showAttendeeCount?: boolean;
  soloCompany?: boolean;
  contactCardBackgroundColor?: string;
  companyCardBackgroundColor?: string;
  showAvatars?: boolean;
  awarenessStates?: Map<number, PresenceState>;
  showEmailDomains?: boolean;
  hideCompanyHeader?: boolean;
  allowEditAttendees?: boolean;
  meetingPlanId?: string;
  refetchMeetingPlan?: () => Promise<unknown>;
  showContactSecondaryText?: boolean;
  defaultCollapsed?: boolean;
  pushPanelContext?: (context: MeetingPlanPanelContext) => void;
  setChatboxContext?: (context: GPTChatBoxContext | undefined) => unknown;
}

const CompanyAttendees = ({
  organizationSlug,
  meetingPlan,
  salesforcePlanContext,
  hubspotPlanContext,
  internalDomains,
  company,
  attendees,
  onContactClick,
  onContactInviteClick,
  onCompanyClick,
  panelVisible,
  companyMaxHeight,
  displayContactNotes = false,
  smallWidthLayout = false,
  rowGap,
  displayAsBoxes,
  showAttendeeCount = false,
  soloCompany = false,
  allowEditingContactNotes = true,
  contactNotesEditableBackgroundColor,
  contactCardBackgroundColor,
  companyCardBackgroundColor,
  showAvatars,
  awarenessStates,
  showEmailDomains,
  hideCompanyHeader,
  allowEditAttendees,
  meetingPlanId,
  refetchMeetingPlan,
  showContactSecondaryText = true,
  defaultCollapsed = false,
  pushPanelContext,
  setChatboxContext,
}: CompanyAttendeesProps) => {
  const DEFAULT_COLLAPSED_ATTENDEE_COUNT = 4;

  const { isDark } = useLightOrDarkMode();
  const [isExpanded, { setTrue: setIsExpanded, setFalse: setIsCollapsed }] =
    useBoolean(
      defaultCollapsed
        ? false
        : attendees.length <= DEFAULT_COLLAPSED_ATTENDEE_COUNT,
    );

  const arrow = !isExpanded ? '▶' : '▼';

  const companyAttendeeGroupClass = mergeStyles({
    position: 'relative',
    boxSizing: 'border-box',
    borderRadius: '.25rem',
    display: 'flex',
    flexDirection: 'column',
    transition: '.3s ease-in-out all',
    backgroundColor: 'transparent',

    '.expand-arrow': {
      content: `'${arrow}'`,
      position: 'absolute',
      top: '1rem',
      left: '-.5rem',
      right: '0',
      bottom: '0',
      height: '1rem',
      width: '1rem',
      marginTop: '-.5rem',
      zIndex: 10000,
      opacity: '1',
      transition: '.3s ease-in-out all',
      display: 'inline-block',
      fontSize: '10px',
      cursor: 'pointer',
      color: isDark ? NeutralColors.gray100 : DEALROOMS_COLORS.cloudburst,
    },

    '.attendees': {
      display: 'flex',
      paddingTop: '.25rem',
      containerType: 'inline-size',
      flexWrap: 'wrap',
      alignContent: 'flex-start',
      padding: isExpanded ? '.25rem 0 0 0' : '0',
      columnGap: '.25rem',
      rowGap: '.25rem',
      transition: '.3s ease-in-out all',
      height: 'auto',
      maxHeight: isExpanded ? '5000rem' : '0',
      overflow: 'hidden',
      borderTop: isExpanded
        ? `1px solid ${
            isDark
              ? NeutralColors.gray180
              : MEETINGFLOW_COLORS.purpleGreyMediumLight
          }`
        : 'none',
    },

    '@container (width >= 70rem)': {
      minWidth: soloCompany
        ? '100% !important'
        : 'calc(25% - .5rem) !important',
      maxWidth: soloCompany
        ? '100% !important'
        : 'calc(25% - .5rem) !important',
      '.company-card': {
        maxWidth: soloCompany ? 'calc(25% - .75rem) !important' : undefined,
      },
      '.attendees': {},
    },

    '@container (width >= 50rem) and (width < 70rem)': {
      minWidth: soloCompany
        ? '100% !important'
        : 'calc(33% - .25rem) !important',
      maxWidth: soloCompany
        ? '100% !important'
        : 'calc(33% - .25rem) !important',
      '.company-card': {
        maxWidth: soloCompany ? 'calc(33% - .75rem) !important' : undefined,
      },

      '.attendees': {},
    },

    '@container (width >= 35rem) and (width < 50rem)': {
      minWidth: soloCompany ? 'calc(100% - .25rem)' : 'calc(50% - .25rem)',
      maxWidth: soloCompany ? 'calc(100% - .25rem)' : 'calc(50% - .25rem)',
      '.company-card': {
        maxWidth: soloCompany ? 'calc(100% - .25rem) !important' : undefined,
      },

      '.attendees': {
        flexWrap: 'wrap',
        flexBasis: '100%',
      },
    },

    '@container (width < 35rem)': {
      minWidth: 'calc(100% - .25rem)',
      maxWidth: 'calc(100% - .25rem)',
      '.company-card': {
        maxWidth: '100%',
      },

      '.attendees': {
        flexBasis: '100%',
        flexWrap: 'wrap',
      },
    },
  });

  const attendeeContactHasNotes = (attendee: Attendee) => {
    const thisContact = meetingPlan.attendees.find(
      (a) => a.email === attendee.email,
    );

    if (!thisContact) {
      return null;
    }
    return hasContent(thisContact?.notes);
  };

  return (
    <div
      className={classNames(
        companyAttendeeGroupClass,
        'company-attendee-group',
      )}
    >
      <div
        className="expand-arrow"
        onClick={isExpanded ? () => setIsCollapsed() : () => setIsExpanded()}
      >
        {arrow}
      </div>
      {!hideCompanyHeader ? (
        <CompanyCard
          organizationSlug={organizationSlug}
          company={company}
          salesforcePlanContext={salesforcePlanContext}
          hubspotPlanContext={hubspotPlanContext}
          // onClick={onCompanyClick}
          onClick={() => {
            isExpanded ? setIsCollapsed() : setIsExpanded();
          }}
          attendeeListHeaderStyle
          emailAllAddressList={attendees.map((a) => {
            return {
              email: a.email,
              name:
                meetingPlan.attendees.find((aa) => aa.email === a.email)
                  ?.name || undefined,
            };
          })}
          emailAllSubject={meetingPlan.title}
          soloCompany={soloCompany}
          backgroundColor={companyCardBackgroundColor}
          attendeeCount={showAttendeeCount ? attendees.length : undefined}
        />
      ) : null}

      <div className="attendees">
        {attendees
          .sort((x, y) =>
            attendeeContactHasNotes(x) === attendeeContactHasNotes(y)
              ? 0
              : attendeeContactHasNotes(x)
                ? -1
                : 1,
          )
          .map((attendee, idx) => {
            return (
              <ContactCard
                key={`${meetingPlanId}-attendee-${attendee.id}`}
                organizationSlug={organizationSlug}
                attendee={attendee}
                company={company}
                salesforcePlanContext={salesforcePlanContext}
                hubspotPlanContext={hubspotPlanContext}
                meetingPlan={meetingPlan}
                domain={attendee.emailDomain}
                internalDomains={internalDomains}
                displayContactNotes={displayContactNotes}
                onContactClick={onContactClick}
                onContactInviteClick={onContactInviteClick}
                contactNotesEditableBackgroundColor={
                  contactNotesEditableBackgroundColor
                }
                allowEditingContactNotes={allowEditingContactNotes}
                soloCompany={soloCompany}
                backgroundColor={contactCardBackgroundColor}
                showAvatar={showAvatars}
                awarenessState={
                  awarenessStates
                    ? Array.from(awarenessStates?.values()).find(
                        (as) => as.email === attendee.email,
                      )
                    : undefined
                }
                showEmailDomain={showEmailDomains}
                // hideBottomBorder={idx === attendees.length - 1}
                hideBottomBorder
                refetchMeetingPlan={refetchMeetingPlan}
                allowEditAttendees={allowEditAttendees}
                width={attendees.length === 1 ? '100%' : undefined}
                showSecondaryText={showContactSecondaryText}
                pushPanelContext={pushPanelContext}
                setChatboxContext={setChatboxContext}
              />
            );
          })}
      </div>
    </div>
  );
};

interface CompanyCardProps {
  organizationSlug: string;
  company: Company;
  salesforcePlanContext?: SalesforcePlanContext;
  hubspotPlanContext?: HubSpotPlanContext;
  onClick?: (
    companyId: Company['id'],
    e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void;
  attendeeListHeaderStyle?: boolean;
  companyListStyle?: boolean;
  backgroundColor?: string;
  emailAllAddressList?: { email: string; name: string | undefined }[];
  emailAllSubject?: string;
  inTooltip?: boolean;
  displayCompanyNotes?: boolean;
  allowEditingCompanyNotes?: boolean;
  companyNotesEditableBackgroundColor?: string;
  defaultActive?: boolean;
  alwaysActive?: boolean;
  personaSize?: PersonaSize;
  socialIconSize?: number;
  logoOnly?: boolean;
  logoSize?: string;
  hideSocialIcons?: boolean;
  hideCompanyUrl?: boolean;
  soloCompany?: boolean;
  attendeeCount?: number;
  showCollapseArrow?: boolean;
}

export const CompanyCard = ({
  organizationSlug,
  company,
  salesforcePlanContext,
  hubspotPlanContext,
  onClick,
  attendeeListHeaderStyle,
  backgroundColor,
  emailAllAddressList,
  emailAllSubject = 'A message about your meeting',
  inTooltip,
  displayCompanyNotes,
  allowEditingCompanyNotes,
  defaultActive = false,
  alwaysActive = false,
  personaSize = PersonaSize.size24,
  socialIconSize = 16,
  logoOnly = false,
  logoSize = '32px',
  hideSocialIcons = false,
  attendeeCount,
  showCollapseArrow = false,
}: CompanyCardProps) => {
  const [isActive, setIsActive] = useState(defaultActive || alwaysActive);
  const theme = useTheme();
  const { isDark } = useLightOrDarkMode();
  const appInsights = useAppInsightsContext();
  const { user } = useUserProfile();

  const navigate = useNavigate();

  const companyPersona = useMemo(() => {
    const salesforceName = salesforcePlanContext?.companies?.[company.id]?.Name;
    const hubspotName =
      hubspotPlanContext?.companies?.[company.id]?.properties?.name;
    return {
      personaName: salesforceName || hubspotName || company.name,
      imageUrl: company.logo || undefined,
    };
  }, [
    company.id,
    company.logo,
    company.name,
    hubspotPlanContext?.companies,
    salesforcePlanContext?.companies,
  ]);

  useEffect(() => {
    if (!defaultActive && !alwaysActive) {
      setIsActive(false);
    }
  }, [defaultActive, alwaysActive]);

  const randColor = useMemo(
    () =>
      randomColor({
        luminosity: 'dark',
        format: 'rgba',
        alpha: 1,
      }),
    [],
  );

  const iconImageProps = {
    width: `${socialIconSize}px`,
    height: `${socialIconSize}px`,
  };

  const imageIconClass = mergeStyles({
    marginTop: '.25rem',
    marginRight: '.25rem',
    cursor: 'pointer',

    ':hover': {
      img: {
        filter: 'grayscale(0%) !important',
      },
    },
  });

  const mailIconClass = mergeStyles({
    cursor: 'pointer',
    fontSize: '16px',
  });

  const renderCompanyLinks = (_props?: IPersonaProps) => {
    const extraLogoOptions = [] as IContextualMenuItem[];

    extraLogoOptions.push({
      key: 'CompanyPage',
      text: `Full details on ${company.name}`,
      iconProps: {
        iconName: 'NavigateExternalInline',
      },
      onClick: (e) => {
        appInsights.trackEvent({
          name: 'CLICK_COMPANY_VIEW_COMPANY_PAGE',
          properties: {
            organizationSlug,
            contactId: company.id,
          },
        });
        navigate(
          `/organization/${organizationSlug}/library/companies/${company.id}`,
        );
      },
    });

    if (emailAllAddressList) {
      extraLogoOptions.push({
        key: 'Email all attendees from this company',
        text: 'Email all attendees from this company',
        iconProps: {
          iconName: 'Mail',
        },
        onClick: (e) => {
          appInsights.trackEvent({
            name: 'CLICK_COMPANY_EMAIL_OPEN',
            properties: {
              organizationSlug,
              companyId: company.id,
            },
          });
          const emailAddressesString = emailAllAddressList.map(
            (email) =>
              `${
                email.name
                  ? `${email.name} <${email.email}>`
                  : `<${email.email}>`
              }`,
          );
          window.location.href = `mailto:${emailAddressesString}?subject=${emailAllSubject}`;
        },
      });
    }

    if (emailAllAddressList && navigator?.clipboard) {
      extraLogoOptions.push({
        key: 'Copy all attendee email addresses to clipboard',
        text: 'Copy all attendee email addresses to clipboard',
        iconProps: {
          iconName: 'Copy',
        },
        onClick: (e) => {
          appInsights.trackEvent({
            name: 'CLICK_COMPANY_COPY_EMAIL',
            properties: {
              organizationSlug,
            },
          });
          const emailAddresses = emailAllAddressList
            .filter(
              (contact) =>
                contact.email.toLowerCase() !== user?.email?.toLowerCase(),
            )
            .map(
              (contact) =>
                `${
                  contact.name
                    ? `${contact.name} <${contact.email}>`
                    : `<${contact.email}>`
                }`,
            );
          navigator.clipboard.writeText(emailAddresses.join(', '));
          toast.success(
            `Copied ${emailAddresses.length} ${inflect(
              'email address',
              emailAddresses.length,
            )} to the clipboard.`,
          );
        },
      });
    }

    if (company?.twitter_handle) {
      extraLogoOptions.push({
        key: 'Twitter',
        text: `View ${company.name} on Twitter`,
        iconProps: {
          imageProps: {
            ...iconImageProps,
            src: TwitterIcon,
            alt: 'Twitter',
            title: `View ${company.name} on Twitter`,
          },
        },
        onClick: (e) => {
          appInsights.trackEvent({
            name: 'CLICK_COMPANY_VIEW_TWITTER',
            properties: {
              organizationSlug,
              handle: company.twitter_handle,
            },
          });
          window.open(
            `https://twitter.com/${company.twitter_handle}`,
            '_blank',
          );
        },
      });
    }
    if (company?.facebook_handle) {
      extraLogoOptions.push({
        key: 'Facebook',
        text: `View ${company.name} on Facebook`,
        iconProps: {
          imageProps: {
            ...iconImageProps,
            src: FacebookIcon,
            alt: 'Facebook',
            title: `View ${company.name} on Facebook`,
          },
        },
        onClick: (e) => {
          appInsights.trackEvent({
            name: 'CLICK_COMPANY_VIEW_FACEBOOK',
            properties: {
              organizationSlug,
              handle: company.facebook_handle,
            },
          });
          window.open(
            `https://www.facebook.com/${company.facebook_handle}`,
            '_blank',
          );
        },
      });
    }

    if (!!company?.crunchbase_handle) {
      extraLogoOptions.push({
        key: 'Crunchbase',
        text: `View ${company.name} on Crunchbase`,
        iconProps: {
          imageProps: {
            ...iconImageProps,
            src: CrunchbaseIcon,
            alt: 'Crunchbase',
            title: `View ${company.name} on Crunchbase`,
          },
        },
        onClick: (e) => {
          appInsights.trackEvent({
            name: 'CLICK_COMPANY_VIEW_CRUNCHBASE',
            properties: {
              organizationSlug,
              handle: company.crunchbase_handle,
            },
          });
          window.open(
            `https://www.crunchbase.com/${company.crunchbase_handle}`,
            '_blank',
          );
        },
      });
    }

    if (!!company?.url) {
      extraLogoOptions.push({
        key: 'Website',
        text: `View Company Website`,
        iconProps: {
          imageProps: {
            ...iconImageProps,
            src: CrunchbaseIcon,
            alt: 'Website',
            title: `View Company Website`,
          },
        },
        onClick: (e) => {
          appInsights.trackEvent({
            name: 'CLICK_COMPANY_VIEW_WEBSITE',
            properties: {
              organizationSlug,
              url: company?.url,
            },
          });
          window.open(`${company?.url}`, '_blank');
        },
      });
    }

    const logos = [
      <CommandButton
        key="More"
        menuIconProps={{
          iconName: 'More',
        }}
        menuProps={{
          items: extraLogoOptions,
        }}
        styles={{
          root: {
            height: '1rem',
            width: '2rem',
          },
        }}
      />,
    ];

    if (inTooltip) {
      return {
        rendered: [],
        count: 0,
      };
    }

    if (
      salesforcePlanContext &&
      company.id in salesforcePlanContext.companies
    ) {
      logos.unshift(
        <Icon
          key="Salesforce"
          style={imageIconStyle}
          imageProps={{
            ...iconImageProps,
            src: SalesforceIcon,
            alt: 'Salesforce',
            width: '1.3rem',
            title: `View ${company.name} on Salesforce`,
            style: {
              filter: `grayscale(100%)`,
              opacity: isDark ? '1' : '.5',
              transition: '.3s ease-in-out all',
            },
          }}
          onClick={(e) => {
            e.preventDefault();
            appInsights.trackEvent({
              name: 'CLICK_COMPANY_VIEW_SALESFORCE',
              properties: {
                organizationSlug,
                instanceId: salesforcePlanContext.instanceId,
                accountId: salesforcePlanContext.companies[company.id].Id,
              },
            });
            window.open(
              `${salesforcePlanContext.instanceId}/${
                salesforcePlanContext.companies[company.id].Id
              }`,
              '_blank',
            );
          }}
        />,
      );
    }

    if (
      !company.isInternal &&
      salesforcePlanContext &&
      !(company.id in salesforcePlanContext.companies)
    ) {
      let createUrl = `${salesforcePlanContext.instanceId}/lightning/o/Account/new?defaultFieldValues=`;
      const fields: string[] = [];
      if (company.name) {
        fields.push(`Name=${encodeLightningField(company.name)}`);
      }
      if (company.url) {
        fields.push(`Website=${encodeLightningField(company.url)}`);
      }
      if (company.phone) {
        fields.push(`Phone=${encodeLightningField(company.phone)}`);
      }
      if (company.ticker) {
        fields.push(`TickerSymbol=${encodeLightningField(company.ticker)}`);
      }
      if (company.foundedYear) {
        fields.push(
          `YearStarted=${encodeLightningField(company.foundedYear.toFixed(0))}`,
        );
      }
      if (company.description) {
        fields.push(`Description=${encodeLightningField(company.description)}`);
      }

      if (fields) {
        createUrl += fields.join(',');
      }

      logos.unshift(
        <TooltipHost
          key="Add to Salesforce"
          styles={{
            root: {
              display: 'inline-block',
              position: 'relative',
              top: '-.15rem',
              marginRight: '.25rem',
            },
          }}
          calloutProps={{
            backgroundColor: DEALROOMS_COLORS.financialLightGray,
            styles: {
              root: { padding: 0 },
            },
          }}
          content={
            <div style={{ padding: '.5rem', border: '1px solid white' }}>
              <Text block style={{ color: MEETINGFLOW_COLORS.purpleDark }}>
                <strong>Create a Salesforce Account for {company.name}!</strong>
              </Text>
              <Text
                block
                style={{
                  color: MEETINGFLOW_COLORS.black,
                  fontSize: FontSizes.small,
                }}
              >
                {company.name} does not have an account record in Salesforce.
                Add one quickly via Meetingflow!
              </Text>
            </div>
          }
        >
          <div
            style={{
              position: 'relative',
              top: '.15rem',
            }}
            onClick={(e) => {
              e.preventDefault();
              appInsights.trackEvent({
                name: 'CLICK_COMPANY_CREATE_SALESFORCE_ACCOUNT',
                properties: {
                  organizationSlug,
                  companyId: company.id,
                },
              });
              window.open(createUrl, '_blank');
            }}
          >
            <Icon
              key="SalesforceAdd"
              className={imageIconClass}
              imageProps={{
                ...baseIconImageProps,
                src: SalesforceIcon,
                alt: 'Salesforce',
                width: '1.3rem',
                title: `Create account for ${company.name} in Salesforce`,
              }}
            />
            <div
              style={{
                color: '#e29c2a',
                height: '13px',
                width: '13px',
                position: 'absolute',
                bottom: '-.15rem',
                right: '-.15rem',
                backgroundColor: isDark
                  ? NeutralColors.gray180
                  : MEETINGFLOW_COLORS.white,
                borderRadius: '50%',
                textAlign: 'center',
                padding: '1px',
              }}
            >
              <FontIcon
                iconName="Error"
                style={{
                  color: '#e29c2a',
                  position: 'relative',
                  top: '-1.5px',
                  left: '.25px',
                  fontSize: '13px',
                  lineHeight: '13px',
                  fontWeight: 900,
                }}
              />
            </div>
          </div>
        </TooltipHost>,
      );
    }

    if (hubspotPlanContext && company.id in hubspotPlanContext.companies) {
      logos.unshift(
        <Icon
          key="HubSpot"
          style={imageIconStyle}
          imageProps={{
            ...iconImageProps,
            src: HubSpotIcon,
            alt: 'HubSpot',
            width: '1.3rem',
            title: `View ${company.name} on HubSpot`,
            style: {
              filter: `grayscale(100%)`,
              opacity: isDark ? '1' : '.5',
              transition: '.3s ease-in-out all',
            },
          }}
          onClick={(e) => {
            e.preventDefault();
            appInsights.trackEvent({
              name: 'CLICK_COMPANY_VIEW_HUBSPOT',
              properties: {
                organizationSlug,
                instanceId: hubspotPlanContext.instanceId,
                accountId: hubspotPlanContext.companies[company.id].id,
              },
            });
            window.open(
              `https://app.hubspot.com/contacts/${
                hubspotPlanContext.instanceId
              }/companies/${hubspotPlanContext.companies[company.id].id}`,
              '_blank',
            );
          }}
        />,
      );
    }

    if (
      !company.isInternal &&
      hubspotPlanContext &&
      !(company.id in hubspotPlanContext.companies)
    ) {
      logos.unshift(
        <TooltipHost
          key="Add to HubSpot"
          styles={{
            root: {
              display: 'inline-block',
              position: 'relative',
              top: '-.15rem',
              marginRight: '.25rem',
            },
          }}
          calloutProps={{
            backgroundColor: DEALROOMS_COLORS.financialLightGray,
            styles: {
              root: { padding: 0 },
            },
          }}
          content={
            <div style={{ padding: '.5rem', border: '1px solid white' }}>
              <Text block style={{ color: MEETINGFLOW_COLORS.purpleDark }}>
                <strong>No company exists for {company.name}!</strong>
              </Text>
              <Text
                block
                style={{
                  color: MEETINGFLOW_COLORS.black,
                  fontSize: FontSizes.small,
                }}
              >
                {company.name} does not have a company record in HubSpot.
              </Text>
            </div>
          }
        >
          <div
            style={{
              position: 'relative',
              top: '.15rem',
            }}
          >
            <Icon
              key="HubSpotAdd"
              className={imageIconClass}
              imageProps={{
                ...baseIconImageProps,
                src: HubSpotIcon,
                alt: 'HubSpot',
                width: '1.3rem',
                title: `Create company for ${company.name} in HubSpot`,
              }}
            />
            <div
              style={{
                color: '#e29c2a',
                height: '13px',
                width: '13px',
                position: 'absolute',
                bottom: '-.15rem',
                right: '-.15rem',
                backgroundColor: isDark
                  ? NeutralColors.gray180
                  : MEETINGFLOW_COLORS.white,
                borderRadius: '50%',
                textAlign: 'center',
                padding: '1px',
              }}
            >
              <FontIcon
                iconName="Error"
                style={{
                  color: '#e29c2a',
                  position: 'relative',
                  top: '-1.5px',
                  left: '.25px',
                  fontSize: '13px',
                  lineHeight: '13px',
                  fontWeight: 900,
                }}
              />
            </div>
          </div>
        </TooltipHost>,
      );
    }

    return {
      rendered: (
        <div
          style={
            logos.length
              ? {
                  height: '24px',
                  overflow: 'visible',
                }
              : undefined
          }
        >
          {logos}
        </div>
      ),
      count: logos.length,
    };
  };

  const companyWebsiteDisplayUrl =
    company?.url || company?.domains?.[0]?.domain
      ? company?.url
          ?.replace('https://', '')
          .replace('http://', '')
          .replace(/\/.*/, '')
          .replace('www.', '') ||
        company?.domains?.[0]?.domain
          ?.replace('https://', '')
          .replace('http://', '')
          .replace('www.', '')
          .replace(/\/.*/g, '')
      : '';

  const linkClass = mergeStyles({
    color: theme.palette.themePrimary,
    fontSize: FontSizes.small,
    cursor: 'pointer',
    ':hover': {
      textDecoration: 'underline',
    },
  });

  const companyClass = mergeStyles({
    containerType: 'inline-size',
    width: logoOnly && logoSize ? logoSize : 'calc(100% - .5rem)',
    height: logoOnly && logoSize ? logoSize : undefined,

    padding: logoOnly ? 0 : '.25rem .25rem .25rem .25rem',
    backgroundColor:
      defaultActive && !alwaysActive
        ? isDark
          ? NeutralColors.gray170
          : DEALROOMS_COLORS.cloudburst
        : isActive && !alwaysActive
          ? isDark
            ? NeutralColors.gray210
            : DEALROOMS_COLORS.cloudburst
          : backgroundColor
            ? backgroundColor
            : isDark
              ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
              : DEALROOMS_COLORS.cloudburst,
    position: attendeeListHeaderStyle ? 'sticky' : 'relative',
    borderTopRightRadius: '.25rem',
    borderTopLeftRadius: '.25rem',
    borderBottomRightRadius: attendeeListHeaderStyle ? 0 : '.25rem',
    borderBottomLeftRadius: attendeeListHeaderStyle ? 0 : '.25rem',
    cursor: onClick ? 'pointer' : undefined,
    display: 'block',
    margin: logoOnly ? ' 0 .25rem 0 0' : '0',

    '.company-attendee-count': {
      display: 'inline-block',
      width: '1rem',
      height: '1rem',
      borderRadius: '50%',
      backgroundColor: isDark
        ? NeutralColors.gray160
        : DEALROOMS_COLORS.cloudburst,
      color: isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.white,
      fontSize: FontSizes.mini,
      fontWeight: FontWeights.bold,
      lineHeight: '1rem',
      textAlign: 'center',
      zIndex: 100,
      marginLeft: '.25rem',
      position: 'relative',
      top: '-.1rem',
    },

    ':hover': {
      backgroundColor: onClick
        ? isDark
          ? NeutralColors.gray210
          : DEALROOMS_COLORS.lightGray
        : null,
      img: {
        filter: 'grayscale(0%) !important',
        opacity: `1 !important`,
      },
      div: {
        opacity: `1 !important`,
      },
      'i[data-icon-name="Copy"], i[data-icon-name="Mail"]': {
        color: MEETINGFLOW_COLORS.purpleDark,
        opacity: `1 !important`,
      },
      '.contact-notes-editor, .company-notes-editor': {
        '.editable-icon': {
          color: isDark
            ? MEETINGFLOW_COLORS.white
            : MEETINGFLOW_COLORS.purpleDarker,
          backgroundColor: isDark
            ? NeutralColors.gray180
            : MEETINGFLOW_COLORS.purpleGrey,
        },

        '> div > div': {
          outline: 'none',
          border: 'none',
        },
      },
    },
    '.contact-notes-editor, .company-notes-editor': {
      cursor: !isActive ? 'pointer' : undefined,
      transition: '.3s ease-in-out all',
      margin: '.25rem .25rem',
      fontSize: FontSizes.small,
      color: NeutralColors.gray110,
      overflow: 'visible',

      '[data-slate-placeholder="true"]': {
        top: '.5rem',
        left: '.5rem !important',
      },

      '> div > div': {
        borderRadius: '.25rem',
        border: `1px solid transparent`,
        transition: '.3s ease-in-out all',
      },
    },
  });

  const companyIconsContainerClass = mergeStyles({
    position: 'absolute',
    top: '.25rem',
    right: '0',
  });

  return (
    <div
      id="company-card"
      onClick={(e) => {
        onClick?.(company.id, e);
      }}
      className={classNames(companyClass, 'company-card')}
    >
      <Persona
        {...companyPersona}
        hidePersonaDetails={logoOnly}
        title={logoOnly ? company.name : undefined}
        text={company.name || ''}
        size={personaSize}
        styles={{
          root: {
            left: personaSize === PersonaSize.size56 ? '.15rem' : undefined,
            top: personaSize === PersonaSize.size56 ? '.15rem' : undefined,
          },
          primaryText: {
            fontWeight: FontWeights.semibold,
            paddingRight: inTooltip
              ? undefined
              : `calc(1.1rem * ${renderCompanyLinks().count + 2})`,
          },
        }}
        coinProps={{
          style: {
            position: 'relative',
          },
          styles: {
            initials: {
              color: 'white !important',
              borderRadius: '.15rem',
            },
            image: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '.15rem',
              img: {
                width: '100%',
                height: 'auto !important',
              },
            },
          },
        }}
        onRenderPrimaryText={(_props, _context) => {
          return (
            <Text
              style={{
                fontWeight: FontWeights.semibold,
                color: isDark ? NeutralColors.white : NeutralColors.gray180,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                fontSize:
                  personaSize === PersonaSize.size56
                    ? '20px'
                    : FontSizes.medium,
              }}
            >
              {company.name?.substring(0, 25) || ''}
              {attendeeCount ? (
                <span className="company-attendee-count">{attendeeCount}</span>
              ) : null}
            </Text>
          );
        }}
      />

      {displayCompanyNotes && !logoOnly ? (
        <div className="company-notes-editor">
          <CompanyNoteEditor
            organizationSlug={organizationSlug}
            company={company}
            color={randColor}
            backgroundColor={'transparent'}
            readOnly={!allowEditingCompanyNotes}
            onToggleEdit={(editing) => setIsActive(editing ?? !isActive)}
            alwaysEditing={alwaysActive}
          />
        </div>
      ) : null}
      {!logoOnly && !hideSocialIcons ? (
        <div
          className={classNames(companyIconsContainerClass, 'company-icons')}
        >
          {renderCompanyLinks().rendered}
        </div>
      ) : null}
    </div>
  );
};

interface ContactCardProps {
  organizationSlug: string;
  contact?:
    | Pick<Contact, 'id' | 'name' | 'email' | 'avatarUrl' | 'avatarFileUrl'>
    | Pick<User, 'id' | 'name' | 'email' | 'avatarUrl' | 'avatarFileUrl'>;
  salesforcePlanContext?: SalesforcePlanContext;
  hubspotPlanContext?: HubSpotPlanContext;
  attendee?: Pick<
    Attendee,
    'id' | 'name' | 'email' | 'avatarUrl' | 'avatarFileUrl' | 'response'
  >;
  meetingPlan?: Pick<
    DetailedMeetingflow,
    | 'id'
    | 'title'
    | 'attendees'
    | 'externalId'
    | 'viewStats'
    | 'startTime'
    | 'endTime'
    | 'companies'
    | 'callRecording'
  >;
  refetchMeetingPlan?: () => Promise<unknown>;
  company?: Company;
  domain: string;
  internalDomains?: string[];
  onContactClick?: (c: Contact['id']) => void;
  onContactInviteClick?: (c: Contact) => void;
  displayContactNotes?: boolean;
  allowEditingContactNotes?: boolean;
  contactNotesEditableBackgroundColor?: string;
  width?: string;
  backgroundColor?: string;
  secondaryText?: string;
  showEmailDomain?: boolean;
  showAvatar?: boolean;
  awarenessState?: PresenceState;
  hideBottomBorder?: boolean;
  inTooltip?: boolean;
  onRemove?: () => void;
  allowEditAttendees?: boolean;
  defaultActive?: boolean;
  alwaysActive?: boolean;
  noPadding?: boolean;
  noMargin?: boolean;
  personaSize?: PersonaSize;
  socialIconSize?: number;
  hideSocialIcons?: boolean;
  disallowClick?: boolean;
  soloCompany?: boolean;
  showOnlyAvatar?: boolean;
  showSecondaryText?: boolean;
  pushPanelContext?: (context: MeetingPlanPanelContext) => void;
  setChatboxContext?: (context: GPTChatBoxContext) => void;
}

// NOTE; For the component to work in all contexts, it should EITHER be passed a Contact OR both a Meetingflow and an Attendee

export const ContactCard = ({
  organizationSlug,
  contact,
  salesforcePlanContext,
  hubspotPlanContext,
  attendee,
  meetingPlan,
  refetchMeetingPlan,
  company,
  domain,
  internalDomains,
  onContactClick,
  onContactInviteClick,
  displayContactNotes,
  allowEditingContactNotes,
  contactNotesEditableBackgroundColor,
  width = 'auto',
  backgroundColor,
  secondaryText,
  showEmailDomain = false,
  showAvatar = true,
  awarenessState,
  hideBottomBorder,
  inTooltip,
  onRemove,
  allowEditAttendees,
  defaultActive,
  alwaysActive,
  noPadding,
  noMargin,
  personaSize = PersonaSize.size24,
  socialIconSize = 16,
  hideSocialIcons = false,
  disallowClick = false,
  soloCompany = false,
  showOnlyAvatar = false,
  showSecondaryText = false,
  pushPanelContext,
  setChatboxContext,
}: ContactCardProps) => {
  const [isActive, setIsActive] = useState(defaultActive || alwaysActive);
  const { isDark } = useLightOrDarkMode();
  const appInsights = useAppInsightsContext();
  const { getAccessTokenSilently } = useAuth0();
  const [removeContactDialogHidden, { toggle: toggleRemoveContactDialog }] =
    useBoolean(true);
  const navigate = useNavigate();

  const thisContact = useMemo(() => contact || attendee, [attendee, contact]);

  const { configurationsWithToken: salesforceConfigurations } =
    useExternalServiceConfigurations({
      app: 'SALESFORCE',
      withToken: true,
    });

  const { configurationsWithToken: hubspotConfigurations } =
    useExternalServiceConfigurations({
      app: 'HUBSPOT',
      withToken: true,
    });

  const randColor = useMemo(
    () =>
      randomColor({
        luminosity: 'dark',
        format: 'rgba',
        alpha: 1,
      }),
    [],
  );

  useEffect(() => {
    if (!defaultActive && !alwaysActive) {
      setIsActive(false);
    }
  }, [defaultActive, alwaysActive]);

  const persona = useMemo(() => {
    if (!thisContact) {
      return undefined;
    }
    const salesforceName =
      salesforcePlanContext?.contacts?.[thisContact.id]?.Name;
    const hubspotName =
      hubspotPlanContext?.contacts?.[thisContact.id]?.properties?.firstname &&
      hubspotPlanContext?.contacts?.[thisContact.id]?.properties?.lastname
        ? [
            hubspotPlanContext.contacts[thisContact.id].properties.firstname,
            hubspotPlanContext.contacts[thisContact.id].properties.lastname,
          ].join(' ')
        : undefined;

    return {
      personaName:
        thisContact.name ||
        salesforceName ||
        hubspotName ||
        guessNameFromEmail(thisContact.email) ||
        showEmailDomain
          ? thisContact.email
          : thisContact.email.split('@')[0],
      imageUrl: thisContact.avatarFileUrl || thisContact.avatarUrl || undefined,
      presence: attendee ? toPersonaPresence(attendee.response) : undefined,
      presenceTitle: attendee
        ? toPersonaDescription(attendee.response)
        : undefined,
      data: {
        contact: {
          ...thisContact,
          name:
            salesforceName ||
            hubspotName ||
            thisContact.name ||
            guessNameFromEmail(thisContact.email),
          avatarFileUrl: thisContact.avatarFileUrl,
          avatarUrl: thisContact.avatarUrl,
        },
        isInternal: company
          ? company.isInternal
          : domain
            ? internalDomains?.includes(domain)
            : false,
        inviteHandler: onContactInviteClick,
      },
    } as IFacepilePersona;
  }, [
    attendee,
    company,
    domain,
    hubspotPlanContext?.contacts,
    internalDomains,
    onContactInviteClick,
    salesforcePlanContext?.contacts,
    thisContact,
    showEmailDomain,
  ]);

  const nameString = useMemo(() => {
    return thisContact && persona
      ? thisContact.name || persona.personaName
      : '';
  }, [persona, thisContact]);

  const lastViewedString = useMemo(() => {
    return meetingPlan &&
      thisContact &&
      meetingPlan?.viewStats?.[thisContact.email]?.lastViewed
      ? `Last viewed: ${renderToString(
          <StyledDateTime
            dateTime={meetingPlan?.viewStats?.[thisContact.email]?.lastViewed}
          />,
        )
          .replace('<span>', '')
          .replace('</span>', '')
          .replace(/(\n)\s+/g, '$1')}`
      : 'Never viewed';
  }, [meetingPlan, thisContact]);

  const contactTitleString = meetingPlan
    ? `${nameString}\n${lastViewedString}`
    : nameString;

  const suggestedChatPrompts = useMemo(() => {
    if (meetingPlan && thisContact) {
      return shuffle(
        // @ts-ignore
        suggestChatPrompts(DateTime.now(), meetingPlan, thisContact),
      ).slice(0, 5);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingPlan]);

  const initialMessages: {
    role: 'user' | 'assistant' | 'system';
    content: string;
    display?: boolean;
  }[] = useMemo(() => {
    return [
      {
        role: 'user',
        content: `Get me a bio on ${nameString}!`,
        display: false,
      },
    ];
  }, [nameString]);

  if (!thisContact || !persona) {
    return null;
  }

  const attendeeClass = mergeStyles({
    backgroundColor:
      defaultActive && !alwaysActive
        ? isDark
          ? NeutralColors.gray170
          : DEALROOMS_COLORS.financialLightGray
        : isActive && !alwaysActive
          ? isDark
            ? NeutralColors.black
            : DEALROOMS_COLORS.financialLightGray
          : backgroundColor
            ? backgroundColor
            : 'transparent',
    position: 'relative',
    borderBottom: hideBottomBorder
      ? 'none'
      : isDark
        ? `1px solid ${NeutralColors.gray180}`
        : `1px solid ${NeutralColors.gray20}`,
    margin: 0,
    padding: '.25rem',
    minHeight: '2rem',
    lineHeight: showSecondaryText ? undefined : '1rem',
    cursor: disallowClick ? undefined : 'pointer',
    animation: awarenessState ? '5s pulseAnimation infinite' : undefined,
    transition: '.3s ease-in-out all',
    borderRadius: isActive ? '.25rem' : '.25rem',

    boxSizing: 'border-box',
    '@container (width >= 70rem)': {
      flexBasis: '100%',
      maxWidth: isActive ? '100%' : 'calc(20% - .25rem)',
    },

    '@container (width >= 60rem) and (width < 70rem)': {
      flexBasis: '100%',
      maxWidth: isActive ? '100%' : 'calc(20% - .25rem)',
    },

    // '@container (width >= 50rem) and (width < 60rem)': {
    //   flexBasis: '100%',
    //   maxWidth: isActive ? '100%' : 'calc(25% - .2rem)',
    // },

    '@container (width >= 35rem) and (width < 60rem)': {
      flexBasis: '100%',
      maxWidth: isActive ? '100%' : 'calc(50% - .125rem)',
    },

    '@container (width < 35rem)': {
      flexBasis: '100%',
      width: '100%',
      minWidth: '100%',
      maxWidth: '100%',
    },

    '.ms-Button--icon': {
      backgroundColor: 'transparent !important',
    },

    ':hover': {
      borderRadius: '.25rem',
      maxHeight: '5000rem',
      backgroundColor:
        disallowClick || allowEditingContactNotes
          ? undefined
          : isDark
            ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
            : DEALROOMS_COLORS.neutralDark,

      borderBottom: hideBottomBorder
        ? 'none'
        : isDark
          ? `1px solid ${NeutralColors.gray220}`
          : `1px solid ${NeutralColors.gray20}`,
      img: {
        filter: 'grayscale(0%) !important',
        opacity: `1 !important`,
      },
      div: {
        opacity: `1 !important`,
      },
      '.contact-remove': {
        display: 'block',
        opacity: '1',
      },

      'i[data-icon-name="Copy"], i[data-icon-name="Mail"]': {
        color: MEETINGFLOW_COLORS.purpleDark,
      },

      '.contact-notes-editor, .company-notes-editor': {
        '.editable-icon': {
          color: isDark
            ? MEETINGFLOW_COLORS.white
            : MEETINGFLOW_COLORS.purpleDarker,
          backgroundColor: isDark
            ? NeutralColors.gray180
            : MEETINGFLOW_COLORS.purpleGrey,
        },

        '[data-slate-placeholder="true"]': {
          top: '.5rem',
          left: '.5rem !important',
        },

        '> div > div': {
          margin: '.25rem',
          // border: `1px solid ${
          //   isDark
          //     ? MEETINGFLOW_COLORS.tealDarker
          //     : MEETINGFLOW_COLORS.purpleLight
          // }`,
        },
      },
    },

    '.additional-content': {
      maxHeight: isActive ? undefined : '0',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      transition: '.3s ease-in-out all',
      animationName: 'fadeInAnimation',
      animationDuration: '.3s',
      transitionTimingFunction: 'linear',
      animationIterationCount: '1',
      animationFillMode: 'forwards',
    },

    '.contact-notes-editor, .company-notes-editor': {
      cursor: !isActive ? 'pointer' : undefined,
      transition: '.3s ease-in-out all',
      margin: 0,
      fontSize: FontSizes.small,
      color: NeutralColors.gray110,
      overflow: 'visible',
      opacity: isActive ? '1 !important' : '0 !important',
      height: 'auto',
      maxHeight: isActive ? '200px' : '0',

      '.editor-toolbar': {
        backgroundColor: isDark
          ? NeutralColors.gray200
          : MEETINGFLOW_COLORS.white,
      },

      '[data-slate-placeholder="true"]': {
        top: '2rem !important',
        left: '1rem !important',
      },

      '.editor-frame': {
        paddingTop: '2rem',
        paddingLeft: '1rem',
      },

      '> div > div': {
        margin: '.25rem',
        borderRadius: '.25rem',
        border: `1px solid transparent`,
        transition: '.3s ease-in-out all',
      },
    },

    '.gpt-teaser': {
      border: 'none',
      padding: 0,
      marginTop: '.25rem',

      '.messages': {
        padding: '0',

        '.assistant-message, .footer': {
          margin: '0 .25rem',
        },
      },
    },

    '.contact-remove': {
      display: 'none',
      opacity: '0',
      position: 'absolute',
      top: '0',
      right: '-.5rem',
      zIndex: '50',
      transition: '.3s ease-in-out all',
      backgroundColor: 'red',
      color: 'white',
      height: '1.25rem',
      width: '1.25rem',
      borderRadius: '1rem',
      textAlign: 'center',
      lineHeight: '1.25rem',
      fontWeight: '900 !important',
    },

    '.primary-text': {
      fontWeight: FontWeights.semibold,
      color: isDark ? NeutralColors.gray60 : undefined,
      position: 'relative',
      lineHeight: showSecondaryText ? undefined : '1rem',
      fontSize: personaSize === PersonaSize.size56 ? '20px' : undefined,
      width: '100%',
      display: 'block',
      flexDirection: 'row',
      columnGap: '.25rem',
      top: '0',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      height: '26px',
      paddingLeft: '.25rem',
    },

    '.secondary-text': {
      color: NeutralColors.gray100,
      fontWeight: FontWeights.regular,

      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',

      position: 'relative',
      lineHeight: personaSize === PersonaSize.size56 ? '20px' : '1rem',
      height: personaSize === PersonaSize.size56 ? '20px' : '1rem',
      paddingLeft: '0',
      fontSize: personaSize === PersonaSize.size56 ? '14px' : FontSizes.small,
    },

    '.hover-email': {
      display: nameString === thisContact.email ? 'none' : 'inline-block',
      width: '0px',
      opacity: '0',
      transition: '.3s ease-in-out opacity',
      color: isDark ? NeutralColors.gray120 : DEALROOMS_COLORS.cloudburst,
      fontSize: FontSizes.mini,
      lineHeight: '24px',
    },

    '.attendee-name': {
      display: 'inline-block',
      width: '100%',
      maxWidth: '100%',
      opacity: '1',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      lineHeight: '24px',
    },

    ':hover .hover-email': {
      opacity: '1',
      display: nameString === thisContact.email ? 'none' : 'inline-block',
      width: '70%',
      minWidth: '70%',
      maxWidth: '70%',
      boxSizing: 'border-box',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      position: 'relative',
      paddingLeft: '.25rem',
    },

    ':hover .attendee-name': {
      width: nameString === thisContact.email ? undefined : '30%',
      minWidth: nameString === thisContact.email ? undefined : '30%',
      maxWidth: nameString === thisContact.email ? undefined : '30%',
      boxSizing: 'border-box',
    },
  });

  const attendanceStatus = attendee
    ? toPersonaDescription(attendee.response)
    : undefined;

  const aiSuggestionsWrapperClass = mergeStyles({
    display: 'flex',
    flexDirection: 'row',
    columnGap: '.25rem',
    rowGap: '.25rem',
    flexWrap: 'wrap',
    opacity: isActive ? '1 !important' : '0 !important',
    transition: '.3s ease-in-out all',
    padding: '.25rem',
  });

  const aiSuggestionClass = mergeStyles({
    fontSize: FontSizes.small,
    fontWeight: FontWeights.regular,
    userSelect: 'none',
    WebkitUserSelect: 'none',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    columnGap: '.25rem',
    backgroundColor: isDark
      ? MEETINGFLOW_COLORS.tealDark
      : DEALROOMS_COLORS.cloudburst,
    padding: '.25rem .75rem',
    borderRadius: '.25rem',
    color: MEETINGFLOW_COLORS.white,
    transition: '.3s ease-in-out all',
    opacity: isActive ? '1 !important' : '0 !important',

    ':hover': {
      backgroundColor: isDark
        ? MEETINGFLOW_COLORS.tealDarker
        : MEETINGFLOW_COLORS.tealDark,
    },
  });

  return (
    <>
      <div
        id="contact-card"
        key={`${meetingPlan?.id}-attendee-${thisContact.id}`}
        className={attendeeClass}
        data-extraclass="attendee"
        onClick={(e) => {
          if (disallowClick) {
            return;
          }
          e.preventDefault();
          onContactClick
            ? onContactClick(persona.data.contact.id)
            : setIsActive(!isActive);
        }}
      >
        <Persona
          {...persona}
          text={
            persona.personaName ||
            persona.data.contact.name ||
            guessNameFromEmail(thisContact.email) ||
            guessNameFromEmail(persona.data.contact.name) ||
            persona.data.contact.email
          }
          title={persona.data.contact.email}
          size={personaSize}
          onRenderPrimaryText={(props) => {
            if (showOnlyAvatar) {
              return null;
            }
            return (
              <>
                <Text
                  block
                  className={'primary-text'}
                  title={contactTitleString}
                >
                  <span
                    className="attendee-name"
                    style={{
                      color: isDark
                        ? NeutralColors.white
                        : NeutralColors.gray180,
                    }}
                  >
                    {nameString}
                  </span>

                  {!showSecondaryText && nameString !== thisContact?.email ? (
                    <span className="hover-email">
                      {!persona.data.contact.name
                        ? `@${persona.data.contact.email.split('@')[1]}`
                        : persona.data.contact.email}
                    </span>
                  ) : null}
                </Text>

                {showSecondaryText &&
                (persona.personaName ||
                  persona.data.contact.name ||
                  secondaryText) ? (
                  <Text block className={'secondary-text'}>
                    {secondaryText ? (
                      <span style={{ fontWeight: FontWeights.semibold }}>
                        {secondaryText},
                      </span>
                    ) : null}{' '}
                    {persona.data.contact.email}
                  </Text>
                ) : null}
              </>
            );
          }}
          styles={{
            root: {
              '.ms-Persona-imageArea': {
                display: !showAvatar ? 'none' : undefined,
              },
            },
            details: {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              paddingLeft: '0',
              paddingRight: inTooltip || hideSocialIcons ? undefined : '4rem',
              display: 'block',
              width:
                inTooltip || hideSocialIcons ? undefined : `calc(100% - 5rem)`,
              maxWidth:
                inTooltip || hideSocialIcons ? undefined : `calc(100% - 5rem)`,
            },
          }}
          coinProps={{
            style: {
              position: 'relative',
            },
          }}
        />

        {!inTooltip && !hideSocialIcons ? (
          <div
            id="meetingplan-attendee-contact-icons"
            style={{
              display: 'inline-block',
              position: 'absolute',
              top: '.25rem',
              right: '0',
            }}
          >
            <ContactLinkIcons
              contact={persona.data.contact}
              company={company}
              meetingPlanId={meetingPlan?.id}
              salesforcePlanContext={salesforcePlanContext}
              hubspotPlanContext={hubspotPlanContext}
              organizationSlug={organizationSlug}
              onContactInviteClick={onContactInviteClick}
              internalDomains={internalDomains}
              emailToSubject={meetingPlan?.title || ''}
              socialIconSize={socialIconSize}
              toggleRemoveContactDialog={
                !meetingPlan?.externalId ? toggleRemoveContactDialog : undefined
              }
              externalEventId={meetingPlan?.externalId || undefined}
            />
          </div>
        ) : null}

        <div className="additional-content">
          <div className="contact-notes-editor">
            {isActive && allowEditingContactNotes ? (
              <ContactNoteEditor
                organizationSlug={organizationSlug}
                contact={persona.data.contact}
                color={randColor}
                backgroundColor={
                  contactNotesEditableBackgroundColor || 'transparent'
                }
                readOnly={!allowEditingContactNotes}
                onToggleEdit={(editing) => setIsActive(editing ? true : false)}
                alwaysEditing={true}
              />
            ) : null}
          </div>
          {suggestedChatPrompts?.length ? (
            <div className={aiSuggestionsWrapperClass}>
              {suggestedChatPrompts.map((prompt) => (
                <div
                  key={prompt.content}
                  onClick={
                    setChatboxContext
                      ? () =>
                          setChatboxContext({
                            key: prompt.content,
                            initialMessages: [prompt],
                          })
                      : undefined
                  }
                  className={aiSuggestionClass}
                >
                  <Icon iconName="AISparkle" className="shortcut-type-icon" />
                  {prompt.displayText || prompt.content}
                </div>
              ))}
            </div>
          ) : null}
        </div>

        {meetingPlan && allowEditAttendees && !meetingPlan?.externalId ? (
          <>
            <Dialog
              hidden={removeContactDialogHidden}
              onDismiss={toggleRemoveContactDialog}
              dialogContentProps={{
                type: DialogType.normal,
                title: 'Are you sure?',
                closeButtonAriaLabel: 'Close',
                subText: `Are you sure you want to remove ${thisContact.email} as an attendee on this Meetingflow?`,
              }}
            >
              <DialogFooter>
                <DefaultButton
                  onClick={toggleRemoveContactDialog}
                  text="Cancel"
                />
                <PrimaryButton
                  text="Remove"
                  onClick={async (e) => {
                    e.preventDefault();
                    const token = await getAccessTokenSilently();
                    await toast.promise(
                      ApiClient.delete(
                        `/organization/${organizationSlug}/plan/${meetingPlan?.id}/attendee/${thisContact?.email}`,
                        {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                        },
                      ),
                      {
                        loading: `Removing ${
                          thisContact?.name || thisContact?.email
                        } as an attendee on this Meetingflow...`,
                        success: (response) => {
                          if (refetchMeetingPlan) {
                            refetchMeetingPlan();
                          }
                          return `Successfully removed ${
                            thisContact?.name || thisContact?.email
                          } as an attendee on this Meetingflow.`;
                        },
                        error: (err) => {
                          return `Something went wrong removing ${
                            thisContact?.name || thisContact?.email
                          } as an attendee on this Meetingflow.`;
                        },
                      },
                    );

                    appInsights.trackEvent({
                      name: 'REMOVED_ATTENDEE_FROM_PLAN',
                      properties: {
                        organizationSlug,
                        emailAddress: thisContact?.email,
                        meetingPlanId: meetingPlan.id,
                      },
                    });
                  }}
                />
              </DialogFooter>
            </Dialog>
          </>
        ) : null}
      </div>
    </>
  );
};

type ContactLinkIconsProps = {
  contact: Contact;
  company?: Company;
  organizationSlug: string;
  meetingPlanId?: string;
  externalEventId?: string;
  salesforcePlanContext?: SalesforcePlanContext;
  hubspotPlanContext?: HubSpotPlanContext;
  onContactInviteClick?: (c: Contact) => void;
  internalDomains?: string[];
  emailToSubject?: string;
  socialIconSize?: number;
  toggleRemoveContactDialog?: () => void;
};

const ContactLinkIcons = ({
  contact,
  company,
  organizationSlug,
  meetingPlanId,
  externalEventId,
  salesforcePlanContext,
  hubspotPlanContext,
  onContactInviteClick,
  internalDomains,
  emailToSubject,
  socialIconSize = 16,
  toggleRemoveContactDialog,
}: ContactLinkIconsProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const { isDark } = useLightOrDarkMode();

  const appInsights = useAppInsightsContext();

  const client = useQueryClient();

  const navigate = useNavigate();

  const { data: linkedInData } = useQuery(
    ContactLinkedInData(organizationSlug, contact.id),
    async () => {
      const token = await getAccessTokenSilently();
      return ContactsApiClient.getContactLinkedIn(
        { organizationSlug, contactIdOrEmail: contact.id },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    { enabled: !!contact.id && contact.linkedInUrl === null },
  );

  const contactName = useMemo(() => {
    return contact.name || guessNameFromEmail(contact.email) || contact.email;
  }, [contact.email, contact.name]);

  const companyName = useMemo(() => {
    return company?.name || company?.legalName;
  }, [company]);

  const iconImageProps = {
    width: `${socialIconSize}px`,
    height: `${socialIconSize}px`,
  };

  const imageIconClass = mergeStyles({
    marginTop: '.25rem',
    marginRight: '.25rem',
    cursor: 'pointer',

    ':hover': {
      img: {
        filter: 'grayscale(0%) !important',
      },
    },
  });

  const mailIconClass = mergeStyles({
    cursor: 'pointer',
    fontSize: `${socialIconSize}px`,
  });

  const renderLogos = (_props?: IPersonaProps) => {
    const searchTerm = !!companyName
      ? `${contactName} ${companyName}`
      : contactName || '';

    const extraLogoOptions = [
      {
        key: 'ContactPage',
        text: `Full details on ${contactName}`,
        iconProps: {
          iconName: 'NavigateExternalInline',
        },
        onClick: () => {
          appInsights.trackEvent({
            name: 'CLICK_ATTENDEE_VIEW_CONTACT_PAGE',
            properties: {
              organizationSlug,
              contactId: contact.id,
            },
          });
          navigate(
            `/organization/${organizationSlug}/library/contacts/${contact.id}`,
          );
        },
      },
      {
        key: 'Google',
        text: `Search ${contactName} on Google`,
        iconProps: {
          imageProps: {
            ...iconImageProps,
            src: GoogleIcon,
            alt: 'Google',
            title: `Search ${contactName} on Google`,
          },
          style: {
            fontSize: `${socialIconSize}px`,
            color: isDark ? NeutralColors.gray30 : NeutralColors.gray120,
          },
        },
        onClick: () => {
          appInsights.trackEvent({
            name: 'CLICK_ATTENDEE_SEARCH_GOOGLE',
            properties: {
              organizationSlug,
              searchTerm,
            },
          });
          window.open(
            `https://www.google.com/search?q=${encodeURI(searchTerm)}`,
            '_blank',
          );
        },
      },
      {
        key: 'Email',
        text: `Email ${contact.name || contact.email}`,
        iconProps: {
          iconName: 'Mail',
        },
        onClick: (e) => {
          appInsights.trackEvent({
            name: 'CLICK_ATTENDEE_EMAIL_OPEN',
            properties: {
              organizationSlug,
            },
          });
          window.location.href = `mailto:${contact.email}${
            emailToSubject ? `?subject=${emailToSubject}` : ''
          }`;
        },
      },
      {
        key: 'CopyEmail',
        text: 'Copy Email Address to Clipboard',
        iconProps: {
          iconName: 'Copy',
        },
        onClick: (e) => {
          appInsights.trackEvent({
            name: 'CLICK_ATTENDEE_COPY_EMAIL',
            properties: {
              organizationSlug,
            },
          });
          navigator.clipboard.writeText(contact.email);
          toast.success(`Copied ${contact.email} to the clipboard.`);
        },
      },
      {
        key: 'RemoveContact',
        text: 'Remove from this Meetingflow',
        iconProps: {
          iconName: 'Delete',
        },
        onClick: (e) => {
          toggleRemoveContactDialog && toggleRemoveContactDialog();
        },
        disabled: externalEventId && !toggleRemoveContactDialog ? true : false,
      },
    ] as IContextualMenuItem[];

    const logos = [];

    if (contact.linkedInUrl || linkedInData?.data) {
      logos.push(
        <Icon
          key="LinkedIn"
          className={imageIconClass}
          imageProps={{
            ...iconImageProps,
            src: LinkedInIcon,
            alt: 'LinkedIn',
            title: `View ${contactName} on LinkedIn`,
            style: { filter: `grayscale(100%)`, opacity: isDark ? '1' : '.5' },
          }}
          onClick={(e) => {
            e.preventDefault();
            appInsights.trackEvent({
              name: 'CLICK_ATTENDEE_VIEW_LINKEDIN',
              properties: {
                organizationSlug,
                contactId: contact.id,
                linkedInData: contact.linkedInUrl || linkedInData!.data,
              },
            });
            window.open(contact.linkedInUrl || linkedInData!.data, '_blank');
          }}
        />,
      );
    }

    if (salesforcePlanContext && contact.id in salesforcePlanContext.contacts) {
      logos.push(
        <Icon
          key="Salesforce"
          className={imageIconClass}
          imageProps={{
            ...iconImageProps,
            src: SalesforceIcon,
            alt: 'Salesforce',
            width: '1.3rem',
            title: `View ${contactName} on Salesforce`,
            style: {
              filter: `grayscale(100%)`,
              opacity: isDark ? '1' : '.5',
              transition: '.3s ease-in-out all',
            },
          }}
          onClick={(e) => {
            e.preventDefault();
            appInsights.trackEvent({
              name: 'CLICK_ATTENDEE_VIEW_SALESFORCE',
              properties: {
                organizationSlug,
                instanceId: salesforcePlanContext.instanceId,
                contactId: salesforcePlanContext.contacts[contact.id].Id,
              },
            });
            window.open(
              `${salesforcePlanContext.instanceId}/${
                salesforcePlanContext.contacts[contact.id].Id
              }`,
              '_blank',
            );
          }}
        />,
      );
    }

    if (hubspotPlanContext && contact.id in hubspotPlanContext.contacts) {
      logos.push(
        <Icon
          key="HubSpot"
          className={imageIconClass}
          imageProps={{
            ...iconImageProps,
            src: HubSpotIcon,
            alt: 'HubSpot',
            width: '1.3rem',
            title: `View ${contactName} on HubSpot`,
            style: {
              filter: `grayscale(100%)`,
              opacity: isDark ? '1' : '.5',
              transition: '.3s ease-in-out all',
            },
          }}
          onClick={(e) => {
            e.preventDefault();
            appInsights.trackEvent({
              name: 'CLICK_ATTENDEE_VIEW_HUBSPOT',
              properties: {
                organizationSlug,
                instanceId: hubspotPlanContext.instanceId,
                contactId: hubspotPlanContext.contacts[contact.id].id,
              },
            });
            window.open(
              `https://app.hubspot.com/contacts/${
                hubspotPlanContext.instanceId
              }/contacts/${hubspotPlanContext.contacts[contact.id].id}`,
              '_blank',
            );
          }}
        />,
      );
    }

    if (
      onContactInviteClick &&
      !contact?.orgRole &&
      internalDomains?.includes(contact.emailDomain)
    ) {
      logos.push(
        <TooltipHost
          key="Invite"
          styles={{
            root: {
              display: 'inline-block',
              position: 'relative',
              top: '-.15rem',
              marginRight: '.25rem',
            },
          }}
          calloutProps={{
            backgroundColor: MEETINGFLOW_COLORS.purpleUltraSuperLight,
            styles: {
              root: { padding: 0 },
            },
          }}
          content={
            <div style={{ padding: '.5rem', border: '1px solid white' }}>
              <Text block style={{ color: MEETINGFLOW_COLORS.purpleDark }}>
                <strong>Invite {contactName}!</strong>
              </Text>
              <Text
                block
                style={{
                  color: MEETINGFLOW_COLORS.black,
                  fontSize: FontSizes.small,
                }}
              >
                They're not a member of your Meetingflow workspace.
              </Text>
            </div>
          }
        >
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              appInsights.trackEvent({
                name: 'CLICK_ATTENDEE_INVITE',
                properties: {
                  organizationSlug,
                },
              });
              onContactInviteClick?.(contact);
            }}
            styles={{
              root: {
                borderRadius: '1rem',
                backgroundColor: 'white',
                color: MEETINGFLOW_COLORS.purpleMedium,
                border: `3px solid ${MEETINGFLOW_COLORS.purpleMedium}`,
                boxShadow: '0px 0px 10px rgba(0,0,0,.1)',
                zIndex: 1,
                height: '1.5rem',
                width: '1.5rem',

                i: {
                  fontSize: FontSizes.small,
                },
              },
            }}
            iconProps={{ iconName: 'PeopleAdd' }}
          />
        </TooltipHost>,
      );
    }

    if (
      !contact?.orgRole &&
      !internalDomains?.includes(contact.emailDomain) &&
      hubspotPlanContext &&
      !(contact.id in hubspotPlanContext.contacts)
    ) {
      logos.push(
        <TooltipHost
          key="Add to HubSpot"
          styles={{
            root: {
              display: 'inline-block',
              position: 'relative',
              top: '-.15rem',
              marginRight: '.25rem',
            },
          }}
          calloutProps={{
            backgroundColor: MEETINGFLOW_COLORS.purpleUltraSuperLight,
            styles: {
              root: { padding: 0 },
            },
          }}
          content={
            <div style={{ padding: '.5rem', border: '1px solid white' }}>
              <Text block style={{ color: MEETINGFLOW_COLORS.purpleDark }}>
                <strong>Create a HubSpot Contact for {contactName}!</strong>
              </Text>
              <Text
                block
                style={{
                  color: MEETINGFLOW_COLORS.black,
                  fontSize: FontSizes.small,
                }}
              >
                {contactName} does not have a contact record in HubSpot.
              </Text>
            </div>
          }
        >
          <div
            style={{
              position: 'relative',
              top: '.15rem',
            }}
            onClick={async (e) => {
              e.preventDefault();
              const token = await getAccessTokenSilently();
              await toast.promise(
                ApiClient.post(
                  `/organization/${organizationSlug}/external/hubspot/configuration/${hubspotPlanContext.configurationId}/contacts`,
                  {
                    name: contact.name,
                    email: contact.email,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                    validateStatus: (status) => [201, 302].includes(status),
                  },
                ),
                {
                  loading: 'Creating contact in HubSot',
                  success: (response) => {
                    if (response.status === 302) {
                      if (meetingPlanId) {
                        client.invalidateQueries(
                          HubSpotPlanContextQuery(
                            organizationSlug,
                            meetingPlanId,
                            hubspotPlanContext.configurationId,
                          ),
                        );
                      }
                      client.invalidateQueries(
                        HubSpotContactContextQuery(
                          organizationSlug,
                          contact.id,
                          hubspotPlanContext.configurationId,
                        ),
                      );

                      return 'We found an existing contact in HubSpot';
                    }
                    return 'Successfully created new contact in HubSpot, it may take a minute to show in Meetingflow';
                  },
                  error: (err) => {
                    return 'Something went wrong creating a new contact.  Try again, or create the contact in HubSpot.';
                  },
                },
              );

              appInsights.trackEvent({
                name: 'CLICK_ATTENDEE_CREATE_HUBSPOT_CONTACT',
                properties: {
                  organizationSlug,
                  contactId: contact.id,
                },
              });

              // It takes hubspot a while to make the new contact available,
              // Clear the query cache after 15 seconds to try make sure we see on refresh
              setTimeout(() => {
                if (meetingPlanId) {
                  client.invalidateQueries(
                    HubSpotPlanContextQuery(
                      organizationSlug,
                      meetingPlanId,
                      hubspotPlanContext.configurationId,
                    ),
                  );
                }
                client.invalidateQueries(
                  HubSpotContactContextQuery(
                    organizationSlug,
                    contact.id,
                    hubspotPlanContext.configurationId,
                  ),
                );
              }, 15000);
            }}
          >
            <Icon
              key="HubSpotAdd"
              className={imageIconClass}
              imageProps={{
                ...baseIconImageProps,
                src: HubSpotIcon,
                alt: 'HubSpot',
                width: '1.3rem',
                title: `Create contact for ${contactName} in HubSpot`,
              }}
            />
            <div
              style={{
                color: '#e29c2a',
                height: '13px',
                width: '13px',
                position: 'absolute',
                bottom: '-.15rem',
                right: '-.15rem',
                backgroundColor: isDark
                  ? NeutralColors.gray180
                  : MEETINGFLOW_COLORS.white,
                borderRadius: '50%',
                textAlign: 'center',
                padding: '1px',
              }}
            >
              <FontIcon
                iconName="Error"
                style={{
                  color: '#e29c2a',
                  position: 'relative',
                  top: '-1.5px',
                  left: '.25px',
                  fontSize: '13px',
                  lineHeight: '13px',
                  fontWeight: 900,
                }}
              />
            </div>
          </div>
        </TooltipHost>,
      );
    }

    if (
      !contact?.orgRole &&
      !internalDomains?.includes(contact.emailDomain) &&
      salesforcePlanContext &&
      !(contact.id in salesforcePlanContext.contacts)
    ) {
      let createUrl = `${
        salesforcePlanContext.instanceId
      }/lightning/o/Contact/new?defaultFieldValues=Email=${encodeLightningField(
        contact.email,
      )}`;
      if (contact.name) {
        const nameSplit = contact.name.split(/\s+/);
        const firstNameGuess = nameSplit.slice(0, -1);
        const lastNameGuess = nameSplit.slice(-1);
        createUrl += `,FirstName=${encodeLightningField(
          firstNameGuess.join(' '),
        )},LastName=${encodeLightningField(lastNameGuess.join(' '))}`;
      } else {
        const nameGuessSplit = contact.email
          .split('@')[0]
          .split('.')
          .map(titleCase);
        const firstNameGuess = nameGuessSplit.slice(0, -1);
        const lastNameGuess = nameGuessSplit.slice(-1);
        createUrl += `,FirstName=${encodeLightningField(
          firstNameGuess.join(' '),
        )}`;
        if (lastNameGuess.length) {
          createUrl += `,LastName=${encodeLightningField(
            lastNameGuess.join(' '),
          )}`;
        }
      }
      if (
        salesforcePlanContext &&
        company &&
        company.id in salesforcePlanContext.companies
      ) {
        createUrl += `,AccountId=${encodeLightningField(
          salesforcePlanContext.companies[company.id].Id,
        )}`;
      }

      logos.push(
        <TooltipHost
          key="Add to Salesforce"
          styles={{
            root: {
              display: 'inline-block',
              position: 'relative',
              top: '-.15rem',
              marginRight: '.25rem',
            },
          }}
          calloutProps={{
            backgroundColor: MEETINGFLOW_COLORS.purpleUltraSuperLight,
            styles: {
              root: { padding: 0 },
            },
          }}
          content={
            <div style={{ padding: '.5rem', border: '1px solid white' }}>
              <Text block style={{ color: MEETINGFLOW_COLORS.purpleDark }}>
                <strong>Create a Salesforce Contact for {contactName}!</strong>
              </Text>
              <Text
                block
                style={{
                  color: MEETINGFLOW_COLORS.black,
                  fontSize: FontSizes.small,
                }}
              >
                {contactName} does not have a contact record in Salesforce. Add
                one quickly via Meetingflow!
              </Text>
            </div>
          }
        >
          <div
            style={{
              position: 'relative',
              top: '.15rem',
            }}
            onClick={(e) => {
              e.preventDefault();
              appInsights.trackEvent({
                name: 'CLICK_ATTENDEE_CREATE_SALESFORCE_CONTACT',
                properties: {
                  organizationSlug,
                  contactId: contact.id,
                },
              });
              window.open(createUrl, '_blank');
            }}
          >
            <Icon
              key="SalesforceAdd"
              className={imageIconClass}
              imageProps={{
                ...baseIconImageProps,
                src: SalesforceIcon,
                alt: 'Salesforce',
                width: '1.3rem',
                title: `Create contact for ${contactName} in Salesforce`,
              }}
            />
            <div
              style={{
                color: '#e29c2a',
                height: '13px',
                width: '13px',
                position: 'absolute',
                bottom: '-.15rem',
                right: '-.15rem',
                backgroundColor: isDark
                  ? NeutralColors.gray180
                  : MEETINGFLOW_COLORS.white,
                borderRadius: '50%',
                textAlign: 'center',
                padding: '1px',
              }}
            >
              <FontIcon
                iconName="Error"
                style={{
                  color: '#e29c2a',
                  position: 'relative',
                  top: '-1.5px',
                  left: '.25px',
                  fontSize: '13px',
                  lineHeight: '13px',
                  fontWeight: 900,
                }}
              />
            </div>
          </div>
        </TooltipHost>,
      );
    }

    logos.push(
      <CommandButton
        key="More"
        menuIconProps={{
          iconName: 'More',
        }}
        menuProps={{
          items: extraLogoOptions,
        }}
        styles={{
          root: {
            height: '1rem',
            width: '2rem',
          },
        }}
      />,
    );

    return { logos: <div>{logos}</div>, count: logos.length };
  };

  return renderLogos().logos;
};
