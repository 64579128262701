import {
  FontSizes,
  FontWeights,
  IToggleStyles,
  NeutralColors,
  mergeStyles,
} from '@fluentui/react';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';

interface SettingsPageLayoutProps {
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
}

export const settingsSubHeadClass = mergeStyles({
  fontSize: FontSizes.mediumPlus,
  fontWeight: FontWeights.semibold,
  margin: '0 0 .5rem 0 !important',
  color: MEETINGFLOW_COLORS.teal,

  selectors: {
    '&:not(:first-child)': {
      marginTop: '1rem !important',
    },
  },
});

export const settingsSubSubHeadClass = mergeStyles({
  fontSize: FontSizes.medium,
  fontWeight: FontWeights.regular,
  margin: '0 0 .5rem 0 !important',
  color: MEETINGFLOW_COLORS.teal,

  selectors: {
    '&:not(:first-child)': {
      marginTop: '1rem !important',
    },
  },
});

export const settingsTextBlockClass = mergeStyles({
  fontSize: FontSizes.small,
  fontWeight: FontWeights.regular,
  margin: '.5rem 0 1rem 0',
  display: 'block',

  selectors: {
    '&:not(:first-child)': {
      marginTop: '1rem !important',
    },
  },
});

export const settingsPrimaryButtonClass = mergeStyles({
  margin: '1rem 0',

  '&:last-child': {
    marginBottom: 0,
  },
});

export const settingsSectionClass = mergeStyles({});

export const settingsIndentedControlClass = mergeStyles({
  marginTop: '0 !important',
  marginBottom: '1rem !important',
  paddingLeft: '1rem',
  borderLeft: `.5px solid ${MEETINGFLOW_COLORS.teal}`,

  label: {
    paddingTop: 0,
  },
});

export const settingsRootStackStyles = {
  root: {
    selectors: {
      '> :not(:first-child)': {
        marginTop: 'unset',
      },
    },
  },
};

export const settingsSectionDescriptionClass = {
  fontSize: FontSizes.smallPlus,
  maxWidth: '600px',
  backgroundColor: NeutralColors.gray20,
  padding: '.5rem 2rem .5rem 1rem',
  marginBottom: '1rem',
  marginLeft: '-1rem',
  display: 'inline-block',
  borderTopRightRadius: '.25rem',
  borderBottomRightRadius: '.25rem',
  position: 'relative',

  strong: { fontWeight: FontWeights.semibold },

  ul: {
    paddingLeft: '1rem',
  },
};

export const settingsSectionDescriptionClassDark = {
  ...settingsSectionDescriptionClass,
  backgroundColor: NeutralColors.gray170,
};

export const settingsSectionDescriptionIconClass = mergeStyles({
  fontSize: '1rem',
  position: 'absolute',
  top: '.25rem',
  right: '.25rem',
  color: MEETINGFLOW_COLORS.teal,
  display: 'none',
});

export const settingsToggleStyles = {
  root: {
    display: 'flex',
    whiteSpace: 'nowrap',
    flexDirection: 'row-reverse',
    alignItems: 'flex-start',
  },
  container: {
    display: 'inline-block',
  },
  label: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    width: 'calc(100% - 3rem)',
  },
  pill: { display: 'flex', marginRight: '.5rem', marginLeft: '-2.5rem' },
} as IToggleStyles;

export const SettingsPageLayout = ({
  title,
  subtitle,
  children,
}: SettingsPageLayoutProps) => {
  const settingsPageLayoutClass = mergeStyles({
    width: 'calc(100% - 2rem)',
    margin: '0 auto',
    maxWidth: '1280px',
    height: '100%',
    display: 'block',
    padding: '1rem',
  });

  const settingPageTitleClass = mergeStyles({
    fontWeight: FontWeights.semibold,
    lineHeight: '2rem',
    marginBottom: '1.5rem',
    span: {
      display: 'block',
      fontSize: FontSizes.medium,
      fontWeight: FontWeights.semibold,
      color: MEETINGFLOW_COLORS.purpleMedium,
      lineHeight: '1rem',
    },
  });

  return (
    <div className={settingsPageLayoutClass}>
      <h1 className={settingPageTitleClass}>
        {subtitle ? <span>{subtitle}</span> : null}
        {title}
      </h1>
      {children}
    </div>
  );
};
