import React from 'react';
import {
    Dialog,
    DialogType,
    DialogFooter,
    PrimaryButton,
    DefaultButton,
    Text,
    Stack,
} from '@fluentui/react';
import { dsConfigurationMutualPlanStyles } from './styles';

interface DSConfigurationMutualPlanDeleteMilestoneDialogProps {
    isOpen: boolean;
    milestoneName: string;
    onConfirm: () => void;
    onDismiss: () => void;
}

export const DSConfigurationMutualPlanDeleteMilestoneDialog: React.FC<DSConfigurationMutualPlanDeleteMilestoneDialogProps> = ({
    isOpen,
    milestoneName,
    onConfirm,
    onDismiss,
}) => {
    const styles = dsConfigurationMutualPlanStyles;

    return (
        // Render a confirmation dialog for milestone deletion
        <Dialog
            hidden={!isOpen}
            onDismiss={onDismiss}
            className={styles.dialog}
            dialogContentProps={{
                type: DialogType.normal,
                title: 'Delete Milestone',
                subText: `Are you sure you want to delete the milestone "${milestoneName}"?`,
            }}
        >
            {/* Display warning about permanent deletion */}
            <Stack tokens={{ childrenGap: 16 }}>
                <div className={styles.warning}>
                    <Text className={styles.warningText}>
                        This action cannot be undone. The milestone and its associated tasks and comments will be permanently deleted.
                    </Text>
                </div>
            </Stack>

            <DialogFooter>
                <DefaultButton onClick={onDismiss} text="Cancel" />
                <PrimaryButton onClick={onConfirm} text="Delete" />
            </DialogFooter>
        </Dialog>
    );
};
