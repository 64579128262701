import { useAuth0 } from '@auth0/auth0-react';
import {
  Callout,
  FontSizes,
  FontWeights,
  mergeStyles,
  useTheme,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { CopyIcon, EditIcon, RemoveLinkIcon } from '@fluentui/react-icons-mdl2';
import { SlateLink } from '@meetingflow/common/Types/Slate';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Editor, Element } from 'slate';
import {
  RenderElementProps,
  useFocused,
  useSelected,
  useSlateStatic,
} from 'slate-react';
import { toString } from '../../../Helpers/SlateHelpers';
import { PageMeta, getPageMeta } from '../../../Helpers/URLHelpers';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import useWindowFocus from '../../../Hooks/useWindowFocus';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { insertLink, unwrapNodes } from '../Helpers/EditorHelpers';
import { InlineChromiumBugfix } from './InlineChromiumBugFix';

export const LinkElement = ({
  attributes,
  children,
  element,
}: RenderElementProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const theme = useTheme();
  const editor = useSlateStatic();
  const { isDark } = useLightOrDarkMode();
  const [linkMeta, setLinkMeta] = useState<PageMeta | undefined>(undefined);
  const [linkEverFocused, setLinkEverFocused] = useState(false);

  const [
    previewVisible,
    { setFalse: setPreviewHidden, setTrue: setPreviewVisible },
  ] = useBoolean(false);

  const selected = useSelected();
  const focused = useFocused();

  const isWindowFocused = useWindowFocus();

  const link = element as SlateLink;
  const linkRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (link.href && linkEverFocused) {
      setLinkMeta(undefined);
      getAccessTokenSilently()
        .then(async (token) => {
          const meta = await getPageMeta(token, link.href!);
          setLinkMeta(meta);
        })
        .catch((err) =>
          console.error(`Failed to get page title for ${link.href}, ${err}`),
        );
    }
  }, [getAccessTokenSilently, link.href, linkEverFocused]);

  const activeLinks = Array.from(
    Editor.nodes<SlateLink>(editor, {
      match: (n) => Element.isElement(n) && n.type === 'link',
    }),
  );

  const activeLink =
    activeLinks && (activeLinks.length === 1 || activeLinks?.[0]?.[0] === link)
      ? activeLinks[0]
      : undefined;
  const activeLinkPath = activeLink?.[1];

  const linkText = activeLinkPath
    ? Editor.string(editor, activeLinkPath, { voids: false })
    : undefined;

  useEffect(() => {
    if (selected && focused && activeLink) {
      setLinkEverFocused(true);
      setPreviewVisible();
    } else if (!selected || !activeLink) {
      setPreviewHidden();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused, selected, activeLink]);

  const linkClass = mergeStyles({
    fontWeight: FontWeights.semibold,
    color: isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.white,
    textDecoration: 'underline',
    display: 'inline-block',
    position: 'relative',
  });

  const linkPreviewContent = mergeStyles({
    '.ms-Callout-main': {
      display: 'inline-flex',
      flexDirection: 'row',
      columnGap: '.25rem',
      rowGap: '0',
      alignItems: 'top',
      borderRadius: '.25rem',
      backgroundColor: isDark
        ? MEETINGFLOW_COLORS.purpleMedium
        : MEETINGFLOW_COLORS.purpleMedium,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      padding: '.25rem',
      userSelect: 'none',
      WebkitTouchCallout: 'none',
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      msUserSelect: 'none',
      zIndex: 10000,
      a: {
        color: MEETINGFLOW_COLORS.white,
        maxWidth: '200px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'block',
        fontWeight: FontWeights.semibold,
        transition: '.3s ease-in-out all',
        ':hover': {
          color: MEETINGFLOW_COLORS.white,
          textDecoration: 'underline',
        },
        img: {
          marginTop: '.5rem',
          display: 'block',
          transition: '.3s ease-in-out all',
          maxHeight: '250px',
          width: '100%',
          cursor: 'pointer',
          objectFit: 'cover',
        },
      },
      i: {
        cursor: 'pointer',
        marginTop: '.5rem',
        padding: '.25rem',
        transition: '.3s ease-in-out all',
        borderRadius: '.25rem',
        color: MEETINGFLOW_COLORS.white,
        ':hover': {
          color: MEETINGFLOW_COLORS.white,
          backgroundColor: MEETINGFLOW_COLORS.purpleDark,
        },
      },
      div: {
        margin: '0 .5rem',
        height: 'auto',
        span: {
          color: isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.white,
        },
      },
    },
  });

  return (
    <>
      {linkRef.current && isWindowFocused && previewVisible ? (
        <Callout
          contentEditable={false}
          className={linkPreviewContent}
          target={linkRef.current}
          styles={{
            beak: {
              background: isDark
                ? MEETINGFLOW_COLORS.purpleMedium
                : MEETINGFLOW_COLORS.purpleMedium,
            },
          }}
        >
          <img
            style={{
              height: '1.5rem',
              width: '1.5rem',
              position: 'relative',
              top: '.35rem',
            }}
            src={`https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${link.href}&size=32`}
            alt={`Favicon for ${link.href}`}
          />
          <div>
            <a
              title={linkMeta?.title || link.href}
              href={link.href}
              target="_blank"
              rel="noreferrer"
            >
              {linkMeta?.title || link.href}

              {linkMeta?.title ? (
                <span
                  title={link.href}
                  style={{
                    transition: '.3s ease-in-out all',
                    fontSize: FontSizes.small,
                    fontWeight: FontWeights.regular,
                    display: 'block',
                    textDecoration: 'underline',
                  }}
                >
                  {link.href}
                </span>
              ) : null}

              {linkMeta?.image ? (
                <img
                  src={linkMeta.image}
                  alt={linkMeta.imageAlt}
                  title={linkMeta.title}
                />
              ) : null}
            </a>
          </div>

          <div>
            <div>
              {navigator?.clipboard ? (
                <CopyIcon
                  style={{ height: '1rem', width: '1rem', padding: '0.2rem' }}
                  title="Copy URL"
                  onClick={(event) => {
                    event.preventDefault();
                    navigator.clipboard
                      .writeText(link.href!)
                      .then(() =>
                        toast.success(`Copied ${link.href} to the clipboard`),
                      );
                  }}
                />
              ) : null}
              <EditIcon
                style={{ height: '1rem', width: '1rem', padding: '0.2rem' }}
                title="Edit Link"
                onClick={(event) => {
                  event.preventDefault();
                  editor
                    .getLinkContent(
                      link
                        ? { href: link.href, text: toString(link) }
                        : undefined,
                    )
                    .then((content) => {
                      if (content.href) {
                        insertLink(editor, content.href, content.text);
                      }
                    })
                    .catch();
                }}
              />
              <RemoveLinkIcon
                style={{ height: '1rem', width: '1rem', padding: '0.2rem' }}
                title="Remove Link"
                onClick={(event) => {
                  event.preventDefault();
                  unwrapNodes(editor, 'link');
                }}
              />
            </div>
          </div>
          {/* <div>
              {linkText === link.href && linkMeta?.title && activeLinkPath ? (
                <>
                  <AutoEnhanceOnIcon
                    style={{
                      gridArea: 'replace-sparkle',
                      width: '100%',
                      color: theme.palette.themePrimary,
                    }}
                  />
                  <span
                    style={{
                      gridArea: 'replace',
                      width: '100%',
                      color: theme.palette.themePrimary,
                    }}
                  >
                    {' '}
                    Replace URL with page title?
                  </span>
                  <FluentLink
                    style={{
                      gridArea: 'replace-ok',
                      width: '100%',
                      color: theme.palette.themePrimary,
                    }}
                    onClick={() => {
                      Editor.withoutNormalizing(editor, () => {
                        const linkStart = Editor.start(editor, activeLinkPath);
                        const linkEnd = Editor.end(editor, activeLinkPath);

                        const matchRangeRef = Editor.rangeRef(editor, {
                          anchor: linkStart,
                          focus: linkEnd,
                        });

                        Transforms.delete(editor, {
                          at: { anchor: linkStart, focus: linkEnd },
                        });

                        const linkRange = matchRangeRef.unref();
                        if (linkRange) {
                          Transforms.select(editor, linkRange);
                          Editor.insertText(editor, linkMeta.title!);
                        }
                      });
                    }}
                  >
                    Yes
                  </FluentLink>
                </>
              ) : null}
            </div> */}
        </Callout>
      ) : null}
      <span {...attributes} className={linkClass}>
        <InlineChromiumBugfix />
        <a
          ref={linkRef}
          href={link.href}
          target="_blank"
          rel="noreferrer"
          style={{
            display: 'inline-block',
            fontWeight: FontWeights.semibold,
            color: isDark
              ? MEETINGFLOW_COLORS.purpleMedium
              : MEETINGFLOW_COLORS.purpleMedium,
            textDecoration: 'underline',
            cursor: 'text',
          }}
        >
          {children}
        </a>
        <InlineChromiumBugfix />
      </span>
    </>
  );
};
