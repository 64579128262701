import { mergeStyleSets } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

export const useCommentsControlsStyles = () =>
  mergeStyleSets({
    writeCommentControls: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      '.convButs': {
        padding: '0.5rem',
        margin: '0',
        minHeight: 'fit-content',
        minWidth: 'fit-content',
        backgroundColor: DEALROOMS_COLORS.white,
        color: DEALROOMS_COLORS.darkerGray,
        border: 'none',
        borderRadius: '0.5rem',
        ':hover': {
          backgroundColor: DEALROOMS_COLORS.neutralLighter,
          border: 'none',
          color: DEALROOMS_COLORS.darkerGray,
        },
        ':focus-visible': {
          outline: '1px solid ' + DEALROOMS_COLORS.neutralSecondary,
        },
        '.disabled': {
          color: DEALROOMS_COLORS.lighterGray,
        },
      },
    },
  });
