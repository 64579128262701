import { mergeStyleSets } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

type UseCommentsPanelStylesProps = {
  commentsPanelType: 'task' | 'artifact';
};

export const useCommentsPanelStyles = ({
  commentsPanelType,
}: UseCommentsPanelStylesProps) => {
  return mergeStyleSets({
    convWrapper: {
      position: 'relative',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      ...(commentsPanelType === 'task'
        ? {
            backgroundColor: DEALROOMS_COLORS.white,
            borderTop: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
          }
        : {}),
    },
    conversationsContainer: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
      '.addConvContainer': {
        display: 'flex',
        flexFlow: 'column',
        ...(commentsPanelType === 'artifact'
          ? { padding: '2rem 1.5rem 1rem 1.5rem' }
          : { padding: '2rem 1rem 1.5rem' }),
      },
      '.addConvButton': {},
      '.conversationsList': {
        display: 'flex',
        ...(commentsPanelType === 'artifact'
          ? { padding: '0.5rem 1.5rem 1.5rem 1.5rem' }
          : {
              padding: '0.5rem 1rem',
            }),
        gap: '0.5rem',
        overflow: 'auto',
      },
    },
    selectedConvContainer: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
      '.ms-layer:empty': {
        display: 'none',
      },
      '.commentsList': {
        padding: '1rem 0.75rem',
        overflow: 'auto',
        flexGrow: '1',
        gap: '.5rem',
      },
      '.writeCommentContainer': {
        borderTop: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
        padding: '1.5rem',
        '.writeCommentContainer2': {
          border: `1px solid ${DEALROOMS_COLORS.neutralDark}`,
          borderRadius: '0.25rem',
          padding: '1rem',
        },
      },
    },
  });
};
