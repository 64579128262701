import React from 'react';
import { AvatarGroup } from '@mui/material';
import { Meetingflow } from '@meetingflow/common/Api/data-contracts';
import { DSContactAvatar } from './DSContactAvatar';

interface DSMeetingflowAttendeeFacepileProps {
  meetingPlan: Pick<Meetingflow, 'attendees'>;
}

export const DSMeetingflowAttendeeFacepile: React.FC<
  DSMeetingflowAttendeeFacepileProps
> = ({ meetingPlan }) => {
  return (
    <AvatarGroup
      max={5}
      sx={{
        justifyContent: 'flex-start',
        '& .MuiAvatar-root': {
          width: 24,
          height: 24,
          fontSize: '0.75rem',
        },
      }}
    >
      {meetingPlan.attendees?.map((attendee) => (
        <DSContactAvatar key={attendee.id} contact={attendee} />
      ))}
    </AvatarGroup>
  );
};
