import { mergeStyleSets } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../Themes/Themes';

export const useNotFoundContentStyles = () =>
  mergeStyleSets({
    title: {
      fontSize: '8rem',
      fontWeight: 'bold',
      textAlign: 'center',
      color: DEALROOMS_COLORS.themePrimary,

      '@media(max-width: 600px)': {
        fontSize: '6rem',
      },
    },
    subtitleContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '.25rem',

      '.subtitle-item': {
        textAlign: 'center',

        color: DEALROOMS_COLORS.themePrimary,
        fontSize: '3rem',
        '@media(max-width: 600px)': {
          fontSize: '2rem',
        },
      },
    },
    descriptionContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '.25rem',

      '.description-item': {
        fontSize: '1.5rem',
        textAlign: 'center',
        color: DEALROOMS_COLORS.themeSecondary,

        '@media(max-width: 600px)': {
          fontSize: '1rem',
        },
      },
    },

    actionButton: {
      padding: '.5rem',
      fontSize: '1.5rem',
      backgroundColor: DEALROOMS_COLORS.themePrimary,
      borderColor: DEALROOMS_COLORS.themePrimary,
      color: DEALROOMS_COLORS.white,

      ':hover': {
        backgroundColor: DEALROOMS_COLORS.themeSecondary,
        borderColor: DEALROOMS_COLORS.themeSecondary,
      },

      ':active': {
        backgroundColor: DEALROOMS_COLORS.themeSecondary,
        borderColor: DEALROOMS_COLORS.themeSecondary,
      },

      '@media(max-width: 600px)': {
        fontSize: '1.25rem',
      },
    },
  });
