import { useAuth0 } from '@auth0/auth0-react';
import {
  Dropdown,
  FontSizes,
  FontWeights,
  NeutralColors,
  PrimaryButton,
  Spinner,
  Text,
  useTheme,
} from '@fluentui/react';
import { ExternalServiceObjectType } from '@meetingflow/common/Api/data-contracts';
import {
  PickValues,
  fieldDirty,
  getDirtyFields,
  objectIsDirty,
  recordHasOwnProperty,
} from '@meetingflow/common/ObjectHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import { isAxiosErrorResponse } from '../../../../Helpers/AxiosHelpers';
import {
  getFieldNameFieldMap,
  getYupObjectValidator,
} from '../../../../Helpers/HubSpot/HubSpotFieldHelpers';
import { validateData } from '../../../../Helpers/HubSpot/HubSpotYupValidators';
import { parseLocaleFloat } from '../../../../Helpers/NumberHelpers';
import { useExternalServiceConfigurations } from '../../../../Hooks/useExternalServiceConfigurations';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import { HubSpotCompany } from '../../../../Models/HubSpot/HubSpotCompany';
import { HubSpotOwner } from '../../../../Models/HubSpot/HubSpotOwner';
import { HubSpotSchemaResponse } from '../../../../Models/HubSpot/HubSpotSchema';
import { SalesforceAccount } from '../../../../Models/Salesforce/SalesforceAccount';
import {
  HubSpotObjectQuery,
  HubSpotObjectSchemaQuery,
  HubSpotOwnerQuery,
} from '../../../../QueryNames';
import {
  ApiClient,
  ExternalServicesApiClient,
} from '../../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../../Themes/Themes';
import { HubSpotPanelContext } from '../../../../types/HubSpotPanelContext';
import { HubSpotCompanyDeals } from './HubSpotCompanyDeals';
import { HubSpotCompanyTile } from './HubSpotCompanyTile';
import { HubSpotFormComponent } from './HubSpotFormComponent';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { useForceUpdate } from '@fluentui/react-hooks';

const DEFAULT_EDITABLE_FIELDS: string[] = [
  'type',
  'annualrevenue',
  'description',
];

export type HubSpotSidePanelCompanyContentProps = {
  organizationSlug: string;
  meetingPlanId?: string;
  associatedWithPlan?: boolean;
  externalServiceObjectId?: number;
  configurationId: number;
  companyId: string;
  pushHubSpotPanel: (context: HubSpotPanelContext) => void;
  showObjectPicker: (startTab?: ExternalServiceObjectType) => void;
  onPinClick?: (e?: React.MouseEvent<HTMLElement>) => Promise<unknown>;
};
export const HubSpotSidePanelCompanyContent = ({
  organizationSlug,
  meetingPlanId,
  associatedWithPlan,
  externalServiceObjectId,
  configurationId,
  companyId,
  pushHubSpotPanel,
  showObjectPicker,
  onPinClick,
}: HubSpotSidePanelCompanyContentProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const { isDark } = useLightOrDarkMode();

  const {
    loading: salesforceConfigurationsLoading,
    configurationById,
    hasTokenWithScope,
    refetchAll,
  } = useExternalServiceConfigurations({ app: 'HUBSPOT', withToken: true });

  const theme = useTheme();

  const appInsights = useAppInsightsContext();

  const { hasEntitlement } = useOrganization();

  const [formIsSubmitting, setFormIsSubmitting] = useState<boolean>(false);
  const [updateErrors, setUpdateErrors] = useState<
    Record<string, string[] | undefined>
  >({});

  const [objectState, setObjectState] = useState<
    Record<string, string | boolean | number | undefined | null>
  >({});

  const forceUpdate = useForceUpdate();

  const {
    data: objectSchema,
    isLoading: objectSchemaLoading,
    refetch: refetchObjectSchema,
  } = useQuery(
    HubSpotObjectSchemaQuery(organizationSlug!, configurationId, 'companies'),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<HubSpotSchemaResponse>(
        `/organization/${organizationSlug}/external/hubspot/configuration/${configurationId}/schema/companies`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!configurationById(configurationId),
    },
  );

  const {
    data: hubspotObject,
    isLoading: hubspotObjectLoading,
    refetch: refetchHubSpotObject,
  } = useQuery(
    HubSpotObjectQuery(
      organizationSlug!,
      configurationId,
      'companies',
      companyId,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<HubSpotCompany>(
        `/organization/${organizationSlug}/external/hubspot/configuration/${configurationId}/companies/${companyId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!configurationById(configurationId),
    },
  );

  const {
    data: hubspotOwner,
    isLoading: hubspotOwnerLoading,
    refetch: refetchHubSpotOwner,
  } = useQuery(
    HubSpotOwnerQuery(
      organizationSlug!,
      configurationId,
      hubspotObject?.data.properties.hubspot_owner_id,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<HubSpotOwner>(
        `/organization/${organizationSlug}/external/hubspot/configuration/${configurationId}/owners/${hubspotObject?.data.properties.hubspot_owner_id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled:
        !!configurationById(configurationId) &&
        !!hubspotObject?.data.properties.hubspot_owner_id,
    },
  );

  const { mutate: updateDefaultCustomFieldSetId } = useMutation(
    async ({
      tokenId,
      defaultCustomFieldSetId,
    }: {
      tokenId: number;
      defaultCustomFieldSetId: number | null;
    }) => {
      const token = await getAccessTokenSilently();
      const result = await ExternalServicesApiClient.setDefaultFieldSet(
        organizationSlug!,
        tokenId,
        { fieldSetId: defaultCustomFieldSetId },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return result.data;
    },
    {
      onSettled: () => {
        refetchAll().then(forceUpdate);
      },
    },
  );

  useEffect(() => {
    if (
      !!configurationById(configurationId) &&
      !!hubspotObject?.data.properties.hubspot_owner_id
    ) {
      refetchHubSpotOwner();
    }
  }, [
    configurationById,
    configurationId,
    hubspotObject?.data.properties.hubspot_owner_id,
    refetchHubSpotOwner,
  ]);

  useEffect(() => {
    if (configurationId && !!configurationById(configurationId)) {
      refetchObjectSchema();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configurationById, configurationId]);

  useEffect(() => {
    if (configurationId && !!configurationById(configurationId) && companyId) {
      setUpdateErrors({});
      refetchHubSpotObject();
    }
  }, [configurationId, companyId, refetchHubSpotObject, configurationById]);

  const fieldNameFieldMap = useMemo(
    () => getFieldNameFieldMap(objectSchema?.data?.hubspotSchema),
    [objectSchema?.data?.hubspotSchema],
  );

  const fields = useMemo(() => {
    return (
      objectSchema?.data?.customFields?.length
        ? objectSchema.data.customFields.map((f) => f.fieldName)
        : DEFAULT_EDITABLE_FIELDS
    ).filter((f) => !fieldNameFieldMap[f]?.hidden);
  }, [fieldNameFieldMap, objectSchema?.data?.customFields]);

  const readOnlyFields = useMemo(() => {
    return fields.filter(
      (name) =>
        !!fieldNameFieldMap[name] &&
        (!!fieldNameFieldMap[name]?.calculated ||
          !!fieldNameFieldMap[name]?.modificationMetadata.readOnlyValue),
    );
  }, [fieldNameFieldMap, fields]);

  const editableFields = useMemo(() => {
    return fields.filter(
      (name) => !!fieldNameFieldMap[name] && !readOnlyFields.includes(name),
    );
  }, [fieldNameFieldMap, fields, readOnlyFields]);

  useEffect(() => {
    if (hubspotObject?.data?.properties && editableFields) {
      setObjectState(PickValues(hubspotObject.data.properties, editableFields));
    }
  }, [editableFields, hubspotObject?.data]);

  const formSchema = useMemo(
    () =>
      getYupObjectValidator(
        objectSchema?.data?.hubspotSchema,
        editableFields,
        fieldNameFieldMap,
      ),
    [editableFields, fieldNameFieldMap, objectSchema?.data.hubspotSchema],
  );

  const [isValid, errors] = useMemo(
    () => validateData(formSchema, objectState),
    [formSchema, objectState],
  );

  const isDirty = useMemo(
    () => objectIsDirty(objectState, hubspotObject?.data?.properties || {}),
    [objectState, hubspotObject?.data],
  );

  const onSubmit = async () => {
    if (!isValid || !isDirty) {
      return;
    }

    const dirtyFields = getDirtyFields(
      objectState,
      hubspotObject?.data?.properties || {},
    );

    const updateBody = Object.fromEntries(
      Object.entries(dirtyFields).map(([name, value]) => {
        const property = fieldNameFieldMap[name];

        if (!property) {
          return [name, value];
        }

        switch (property.type) {
          case 'number': {
            if (property.showCurrencySymbol) {
              return [name, parseLocaleFloat(value as string).toFixed(2)];
            }
            return [name, (value as number).toString()];
          }
          case 'boolean': {
            return [name, (value as boolean) ? 'True' : 'False'];
          }
          default: {
            return [name, value];
          }
        }
      }),
    );

    setFormIsSubmitting(true);
    setUpdateErrors({});

    const token = await getAccessTokenSilently();
    await toast
      .promise(
        ApiClient.patch<SalesforceAccount>(
          `/organization/${organizationSlug}/external/hubspot/configuration/${configurationId}/companies/${companyId}`,
          updateBody,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'x-meetingplan-id': meetingPlanId,
            },
          },
        ),
        {
          loading: 'Updating HubSpot company',
          success: () => {
            appInsights.trackEvent({
              name: `HUBSPOT_COMPANY_UPDATED`,
              properties: {
                companyName: hubspotObject?.data?.properties?.name,
              },
            });
            refetchHubSpotObject();

            return 'HubSpot company was successfully updated';
          },
          error: (err) => {
            appInsights.trackEvent({
              name: `HUBSPOT_COMPANY_UPDATE_ERROR`,
              properties: {
                accountName: hubspotObject?.data?.properties?.name,
                status: isAxiosErrorResponse(err) && err.response?.status,
                statusText:
                  isAxiosErrorResponse(err) && err.response?.statusText,
              },
            });

            const responseBody = err.response?.data;
            if (isAxiosErrorResponse(err, 400)) {
              setUpdateErrors({
                UPDATE_ERROR: [responseBody.message],
              });

              return `Something went wrong updating the company`;
            }

            return 'Something went wrong updating the company.  Please try again.';
          },
        },
      )
      .finally(() => setFormIsSubmitting(false));
  };

  if (
    salesforceConfigurationsLoading ||
    objectSchemaLoading ||
    hubspotObjectLoading ||
    hubspotOwnerLoading
  ) {
    return (
      <div
        style={{
          minHeight: '100%',
          height: `calc(100vh - 19rem)`,
        }}
      >
        <div style={{ margin: '1rem 0' }}>
          <Spinner />
        </div>
      </div>
    );
  }

  if (!hubspotObject?.data) {
    return (
      <Text
        variant="large"
        block
        style={{
          textAlign: 'center',
          margin: '1rem 0',
        }}
      >
        The associated HubSpot Company couldn't be loaded. Check the Company
        still exists and that you have access to it.
        <div
          style={{
            textAlign: 'center',
            margin: '1rem 0',
          }}
        >
          <PrimaryButton onClick={() => showObjectPicker('ACCOUNT')}>
            Select a new Company
          </PrimaryButton>
        </div>
      </Text>
    );
  }

  const configuration = configurationById(configurationId)!;
  const hasCustomFieldsets =
    configurationById(configurationId)?.externalServiceCustomFieldsets?.length;

  return (
    <div
      style={{
        minHeight: '100%',
        marginBottom: '1rem',
      }}
    >
      <div>
        <HubSpotCompanyTile
          key={companyId}
          meetingPlanId={meetingPlanId}
          organizationSlug={organizationSlug}
          showArrow={false}
          showExternalLink
          id={companyId}
          name={hubspotObject.data.properties.name}
          isPinned={associatedWithPlan}
          externalServiceObjectId={externalServiceObjectId}
          onClick={
            associatedWithPlan ? () => showObjectPicker('ACCOUNT') : undefined
          }
          hubspotInstance={configurationById(configurationId)!.instanceId}
          ownerName={
            hubspotOwner?.data?.firstName
              ? `${hubspotOwner?.data?.firstName} ${hubspotOwner?.data?.lastName}`
              : undefined
          }
          ownerEmail={hubspotOwner?.data?.email}
          onPinClick={onPinClick}
        />
      </div>

      {hasEntitlement('CUSTOM_FIELDSETS') && hasCustomFieldsets ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '1rem',
            marginBottom: '.5rem',
            paddingBottom: '.5rem',
            borderBottom: `1px solid ${
              isDark ? NeutralColors.gray170 : theme.semanticColors.bodyDivider
            }`,
          }}
        >
          <Dropdown
            label="Field Set"
            className="customFieldsetPicker"
            styles={{
              root: {
                display: 'flex',
                columnGap: '0',
              },
              label: {
                fontWeight: FontWeights.semibold,
                marginRight: '.5rem',
              },
            }}
            disabled={salesforceConfigurationsLoading}
            selectedKey={
              configuration.externalServiceUserTokens![0]
                .defaultCustomFieldSetId || -1
            }
            options={[
              {
                key: -1,
                text: 'Default',
                title: 'Default',
              },
              ...(configuration.externalServiceCustomFieldsets!.map((f) => ({
                key: f.id,
                text: f.name,
              })) ?? []),
            ]}
            onChange={(e, opt) => {
              if (opt) {
                updateDefaultCustomFieldSetId({
                  tokenId: configuration.externalServiceUserTokens![0]!.id,
                  defaultCustomFieldSetId:
                    opt.key === -1 ? null : (opt.key as number),
                });
                toast.success('Your default fieldset has been updated.');
              }
            }}
          />
        </div>
      ) : null}

      <div
        style={{
          backgroundColor: isDark
            ? NeutralColors.gray180
            : MEETINGFLOW_COLORS.purpleUltraLight,
          borderBottomLeftRadius: '.25rem',
          borderBottomRightRadius: '.25rem',
          padding: '.25rem .25rem .25rem 1.75rem',
          marginBottom: '.5rem',
          lineHeight: '.75rem',
        }}
      >
        <Text
          style={{
            fontWeight: FontWeights.semibold,
            fontSize: FontSizes.xSmall,
          }}
        >
          Active Deals:{' '}
        </Text>
        <HubSpotCompanyDeals
          organizationSlug={organizationSlug}
          configurationId={configurationId}
          companyId={companyId}
          pushHubSpotPanel={pushHubSpotPanel}
          onlyActiveOpportunities
        />
      </div>

      {hasTokenWithScope('crm.objects.companies.write') ? (
        <>
          <div>
            {fields.map((fieldName) => {
              const property = fieldNameFieldMap[fieldName];
              if (!property) {
                return null;
              }

              const isReadonly = readOnlyFields.includes(fieldName);

              return (
                <HubSpotFormComponent
                  key={property.name}
                  schema={objectSchema?.data.hubspotSchema}
                  property={property}
                  errors={{ ...errors, ...updateErrors }}
                  readonly={isReadonly}
                  isDirty={
                    isReadonly
                      ? false
                      : fieldDirty(
                          objectState,
                          hubspotObject.data,
                          property.name,
                        )
                  }
                  state={
                    isReadonly ? hubspotObject?.data?.properties : objectState
                  }
                  setState={setObjectState}
                />
              );
            })}
          </div>

          {recordHasOwnProperty(updateErrors, 'UPDATE_ERROR') &&
          updateErrors.UPDATE_ERROR?.length ? (
            <div
              style={{
                backgroundColor: theme.semanticColors.errorText,
                padding: '1rem 0',
                margin: '1rem',
                borderRadius: '.25rem',
                textAlign: 'center',
                fontSize: FontSizes.mediumPlus,
                color: 'white',
              }}
            >
              {updateErrors.UPDATE_ERROR[0]}
            </div>
          ) : null}

          <div
            style={{
              display: 'grid',
              justifyItems: 'right',
              marginTop: '.5rem',
            }}
          >
            <PrimaryButton
              disabled={
                hubspotObjectLoading || formIsSubmitting || !isValid || !isDirty
              }
              text={formIsSubmitting ? 'Updating HubSpot...' : 'Update HubSpot'}
              onClick={onSubmit}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};
