import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { useTheme } from '@mui/material';
import { isTooDark } from '../../../Helpers/ColorHelpers';
import { DSButtonRoot } from './DSButton.styles';

export interface DSButtonProps extends ButtonProps {
  children?: React.ReactNode;
  branded?: boolean;
}

const DSButton: React.FC<DSButtonProps> = ({
  branded = false,
  children,
  ...props
}) => {
  const { dealRoom } = useDealRoom();
  const theme = useTheme();
  const brandColor = dealRoom?.primaryColor || theme.palette.primary.main;
  const brandTextColor = isTooDark(brandColor) ? 'white' : 'black';
  return (
    <DSButtonRoot
      variant="contained"
      sx={{
        backgroundColor: branded ? brandColor : undefined,
        color: branded ? brandTextColor : undefined,
        '&:hover': {
          backgroundColor: branded ? brandColor : undefined,
          color: branded ? brandTextColor : undefined,
        },
      }}
      {...props}
    >
      {children}
    </DSButtonRoot>
  );
};

export default DSButton;
