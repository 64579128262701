import { useEffect } from 'react';
import { useParams } from 'react-router';
import { OrganizationSlugRouteParams } from '../types/RouteParams';
import { useOrganization } from './useOrganization';
import { DealRoomFileArtifact } from '@meetingflow/common/Api/data-contracts';
import { useQuery } from 'react-query';
import { DealRoomsApiClient } from '../Services/NetworkCommon';
import { useAuth0 } from '@auth0/auth0-react';

interface WelcomeArtifactProps {
    organizationSlug: string;
    dealRoomId: number;
}

export const useWelcomeArtifact = ({
    organizationSlug,
    dealRoomId
}: WelcomeArtifactProps): DealRoomFileArtifact | undefined => {
    const { getAccessTokenSilently } = useAuth0();

    const {
        data: allArtifactsData,
        refetch: refetch
    } = useQuery(
        ['artifacts', organizationSlug, dealRoomId],
        async () => {
            const token = await getAccessTokenSilently();

            return DealRoomsApiClient.listArtifacts(
                {
                    organizationSlug,
                    dealRoomId,
                },
                {
                headers: { Authorization: `Bearer ${token}` },
                },
            );
        },
    );

    return allArtifactsData?.data.find(artifact => artifact.placement === 'WELCOME') as DealRoomFileArtifact;
};
