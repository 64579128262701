import React, { useState, useEffect, useCallback } from 'react';
import { DefaultButton, Image, Stack, Text } from '@fluentui/react';
import { getBrandingSiteStyles } from './styles';
import { DraggableFileUploaderContainer } from '../../DraggableFileUploaderContainer';
import { useFileUpload } from '../UploadAttachmentComponents/hooks';
import { UploadIllustration } from './Illustration';
import toast from 'react-hot-toast';

interface StepProps {
  organizationSlug: string;
  selectedFile: File | null;
  setSelectedFile: (file: File | null) => void;
  primaryColor: string;
  setPrimaryColor: (color: string) => void;
}

export const BrandingDecisionSite = ({
  organizationSlug,
  selectedFile,
  setSelectedFile,
  primaryColor,
  setPrimaryColor,
}: StepProps) => {
  const styles = getBrandingSiteStyles();

  const PrimaryColorRef= React.useRef<HTMLInputElement>(null);
  const SecondaryColorRef= React.useRef<HTMLInputElement>(null);

  const [filePreviewUrl, setFilePreviewUrl] = useState<string>('');

  const validateFile = useCallback((file: File | null) => {
    if (!file) {
      toast.error('Please upload a valid image file.');
      return;
    }
    const allowedTypes = ['image/jpg', 'image/jpeg', 'image/png'];
    if (allowedTypes.includes(file.type)) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      toast.error('Please upload an image file with a valid extension.');
    }
  }, [setSelectedFile, setFilePreviewUrl]);
  
  useEffect(() => {
    if (!selectedFile) { return; }
    validateFile(selectedFile);
  }, [validateFile, selectedFile]);

  const { handleSelectFile, handleDrop } = useFileUpload({
    setSelectedFile: validateFile,
  });

  return (
    <div className={styles.content}>
      <div className="title">Branding</div>
      <div className="subTitle">
        Logo<span>*</span>
      </div>
      <DraggableFileUploaderContainer
        handleOnDrop={handleDrop}
        className={styles.dropZone}
      >
        {!selectedFile && (
          <UploadIllustration />
        )}
        {selectedFile && (
          <Image
            src={filePreviewUrl}
            width={72}
            height={72}
            style={{ borderRadius: '10%' }}
          />
        )}
        <Text className={styles.dropHereText}>Drop your file here</Text>
        <div className={styles.manualUploadText}>
          <Text>
            Or{' '}
            <DefaultButton
              className={styles.uploadButton}
              text="click here"
              onClick={handleSelectFile}
            />{' '}
            to upload manually. Accepted formats: jpg, jpeg, png.
          </Text>
        </div>
        {selectedFile && <Text>Selected file: {selectedFile.name}</Text>}
      </DraggableFileUploaderContainer>

      <Stack tokens={{ childrenGap: 16 }}>
        <Stack
          horizontal
          tokens={{ childrenGap: 16 }}
          styles={{ root: { width: '100%' } }}
        >
          <div>
            <Text className={styles.colorPickerTitle}>
              Primary Color<span>*</span>
            </Text>
            <input
              ref={PrimaryColorRef}
              type="color"
              value={primaryColor}
              onChange={(e) => setPrimaryColor(e.target.value)}
              style={{ display: 'none', visibility: 'hidden' }}
            />
            <div
              className={styles.colorPickerContainer}
              onClick={() => PrimaryColorRef.current?.click()}
            >
              <span
                style={{
                  backgroundColor: primaryColor,
                }}
              />
              <span>{primaryColor}</span>
            </div>
          </div>
        </Stack>
      </Stack>
    </div>
  );
};