import { useMemo } from 'react';
import { useDeferredPromise } from '../../useDeferredPromise';
import { RequestAttachmentsDialog } from '../../../Components/DealRoom/Dialogs/RequestAttachmentsDialog';
import { DealRoomMilestoneType } from '@meetingflow/common/Api/data-contracts';

type UseRequestAttachmentDialogProps = {
  organizationSlug: string;
  dealRoomId: number;
  milestoneType: DealRoomMilestoneType;
};
export const useRequestAttachmentDialog = ({
  organizationSlug,
  dealRoomId,
  milestoneType,
}: UseRequestAttachmentDialogProps) => {
  const { createDeferred, resolve, reject, deferred } = useDeferredPromise();

  const dialog = useMemo(() => {
    if (!deferred || !deferred.isPending) {
      return null;
    }

    return (
      <RequestAttachmentsDialog
        organizationSlug={organizationSlug}
        dealRoomId={dealRoomId}
        onResolve={resolve}
        onDismiss={reject}
        milestoneType={milestoneType}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deferred, deferred?.isPending, dealRoomId, organizationSlug]);

  return {
    createDeferred,
    resolve,
    reject,
    deferred,
    dialog,
  };
};
