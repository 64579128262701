import { capitalize } from 'lodash';

export const titleCase = (str: string) => {
  return str.toLowerCase().split(' ').map(capitalize).join(' ');
};

// @ts-ignore
export const humanizeNumber = (num: number) => {
  const ones = [
    '',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen',
  ];
  const tens = [
    '',
    '',
    'twenty',
    'thirty',
    'forty',
    'fifty',
    'sixty',
    'seventy',
    'eighty',
    'ninety',
  ];

  const numString: string = num.toString();

  if (!numString) {
    return '';
  }

  if (num < 0) throw new Error('Negative numbers are not supported.');

  if (num === 0) return 'zero';

  //the case of 1 - 20
  if (num < 20) {
    return ones[num];
  }

  if (numString.length === 2) {
    // @ts-ignore
    return tens[numString[0]] + ' ' + ones[numString[1]];
  }

  //100 and more
  if (numString.length === 3) {
    if (numString[1] === '0' && numString[2] === '0')
      // @ts-ignore
      return ones[numString[0]] + ' hundred';
    else
      return (
        // @ts-ignore
        ones[numString[0]] +
        ' hundred and ' +
        humanizeNumber(+(numString[1] + numString[2]))
      );
  }

  if (numString.length === 4) {
    const end = +(numString[1] + numString[2] + numString[3]);
    // @ts-ignore
    if (end === 0) return ones[numString[0]] + ' thousand';
    // @ts-ignore
    if (end < 100)
      // @ts-ignore
      return ones[numString[0]] + ' thousand and ' + humanizeNumber(end);
    // @ts-ignore
    return ones[numString[0]] + ' thousand ' + humanizeNumber(end);
  }

  return '';
};
