import { useForceUpdate } from '@fluentui/react-hooks';
import { useCallback, useEffect, useRef } from 'react';
import { makeDeferred, DeferredPromise } from '../Helpers/DeferredPromise';
import { OptionalArgTuple } from '@meetingflow/common/TypeHelpers';

export const useDeferredPromise = <T, TContext = undefined>() => {
  const rerender = useForceUpdate();
  const deferRef = useRef<DeferredPromise<T, TContext>>();

  const createDeferred = useCallback(
    (...context: OptionalArgTuple<TContext>) => {
      if (!!deferRef.current && deferRef.current.promise.isPending) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        deferRef.current.reject('Cancelled');
      }

      const deferred = makeDeferred<T, TContext>(...context);
      deferRef.current = deferred;
      rerender();
      return deferred;
    },
    [rerender],
  );

  const resolve = useCallback(
    (value: T | PromiseLike<T>) => {
      if (!deferRef.current || !deferRef.current.promise.isPending) {
        return;
      }
      deferRef.current.resolve(value);
      rerender();
    },
    [rerender],
  );

  const reject = useCallback(
    (reason?: unknown) => {
      if (!deferRef.current || !deferRef.current.promise.isPending) {
        return;
      }
      deferRef.current.reject(reason);
      rerender();
    },
    [rerender],
  );

  useEffect(() => {
    return () => {
      if (!!deferRef.current && deferRef.current.promise.isPending) {
        deferRef.current.reject('Cancelled during cleanup');
      }
    };
  }, []);

  return {
    createDeferred,
    resolve,
    reject,
    context: deferRef.current?.promise?.context,
    deferred: deferRef.current?.promise,
    isPending: deferRef.current?.promise.isPending || false,
    isResolved: deferRef.current?.promise.isResolved || false,
    isRejected: deferRef.current?.promise.isRejected || false,
  };
};
