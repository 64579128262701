import { useAuth0 } from '@auth0/auth0-react';
import { FontSizes, Link } from '@fluentui/react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useExternalServiceConfigurations } from '../../../../Hooks/useExternalServiceConfigurations';
import { HubSpotDeal } from '../../../../Models/HubSpot/HubSpotDeal';
import { HubSpotCompanyDealsQuery } from '../../../../QueryNames';
import { ApiClient } from '../../../../Services/NetworkCommon';
import { HubSpotPanelContext } from '../../../../types/HubSpotPanelContext';

type HubSpotCompanyDealsProps = {
  organizationSlug: string;
  configurationId: number;
  companyId: string;
  pushHubSpotPanel: (context: HubSpotPanelContext) => void;
  maxNum?: number;
  onlyActiveOpportunities?: boolean;
};
export const HubSpotCompanyDeals = ({
  organizationSlug,
  configurationId,
  companyId,
  pushHubSpotPanel,
  maxNum,
  onlyActiveOpportunities,
}: HubSpotCompanyDealsProps) => {
  const appInsights = useAppInsightsContext();
  const { getAccessTokenSilently } = useAuth0();

  const { loading: salesforceConfigurationsLoading } =
    useExternalServiceConfigurations({ app: 'HUBSPOT', withToken: true });

  const {
    data: hubspotDeals,
    isLoading: hubspotDealsLoading,
    isRefetching: hubspotDealsRefetching,
    isFetched: hubspotDealsFetched,
    refetch: refetchHubSpotDeals,
  } = useQuery(
    HubSpotCompanyDealsQuery(organizationSlug!, configurationId, companyId),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<HubSpotDeal[]>(
        `/organization/${organizationSlug}/external/hubspot/configuration/${configurationId}/deals`,
        {
          params: {
            active: onlyActiveOpportunities,
            companyIds: [companyId],
          },
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
  );

  useEffect(() => {
    refetchHubSpotDeals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onlyActiveOpportunities]);

  useEffect(() => {
    if (hubspotDealsFetched) {
      appInsights.trackEvent({
        name: 'HUBSPOT_COMPANY_DEALS_FETCHED',
        properties: {
          organizationSlug,
          configurationId,
          companyId,
          onlyActive: onlyActiveOpportunities,
          dealCount: hubspotDeals?.data?.length || 0,
        },
      });
    }
  }, [
    appInsights,
    companyId,
    configurationId,
    hubspotDeals?.data?.length,
    hubspotDealsFetched,
    onlyActiveOpportunities,
    organizationSlug,
  ]);

  const hubspotDealsToDisplay = maxNum
    ? hubspotDeals?.data?.slice(0, maxNum)
    : hubspotDeals?.data;

  return (
    <span style={{ fontSize: FontSizes.xSmall }}>
      {salesforceConfigurationsLoading ||
      hubspotDealsLoading ||
      hubspotDealsRefetching ? (
        'Loading...'
      ) : hubspotDealsToDisplay?.length ? (
        hubspotDealsToDisplay.map((deal, idx) => {
          return (
            <Link
              as={'a'}
              style={{ fontSize: FontSizes.xSmall }}
              onClick={() => {
                pushHubSpotPanel({
                  name: deal.properties.dealname,
                  objectId: deal.id,
                  objectType: 'DEAL',
                  serviceConfigurationId: configurationId,
                });
              }}
              key={deal.id}
            >
              {deal.properties.dealname}
              {idx !== hubspotDealsToDisplay.length - 1 ? ', ' : ''}
            </Link>
          );
        })
      ) : (
        <span style={{ fontSize: FontSizes.xSmall }}>None</span>
      )}
    </span>
  );
};
