import { useBoolean } from '@fluentui/react-hooks';
import { useEffect, useMemo, useState } from 'react';
import {
  DeleteIconProps,
  EditIconProps,
  MoreIconProps,
} from '../../../../utils/iconProps';
import { ContextualMenu, IconButton, mergeStyleSets } from '@fluentui/react';

type ConversationMoreOptionsProps = {
  conversationId: number;
  isComment: boolean;
  handleSetEditing: () => void;
  handleCloseEditing: () => void;
  editConvId: number | null | undefined;
  handleDelete: () => void;
  isOwner?: boolean;
  isFirstComment?: boolean;
};

export const ConversationMoreOptions = ({
  conversationId,
  isComment,
  handleSetEditing,
  handleCloseEditing,
  editConvId,
  handleDelete,
  isOwner = false,
  isFirstComment = false,
}: ConversationMoreOptionsProps) => {
  const [
    showMoreOptionsMenu,
    { setTrue: setShowMoreOptionsMenu, setFalse: setHideMoreOptionsMenu },
  ] = useBoolean(false);

  const [currentContextualMenuTarget, setCurrentContextualMenuTarget] =
    useState<HTMLElement | undefined>(undefined);

  useEffect(() => {
    const currentTargetElement = document?.getElementById(
      `conversation-${conversationId}`,
    );
    setCurrentContextualMenuTarget(currentTargetElement || undefined);
  }, [conversationId]);

  const moreOptionsMenuItems = useMemo(() => {
    const menuItems = [
      {
        key: 'delete_conversation',
        text: 'Delete',
        iconProps: DeleteIconProps,
        onClick: handleDelete,
        disabled: !isOwner || isFirstComment,
      },
    ];

    if (isComment) {
      menuItems.push({
        key: 'edit_conversation',
        text: 'Edit',
        iconProps: EditIconProps,
        onClick: handleSetEditing,
        disabled:
          (editConvId !== null &&
            editConvId !== undefined &&
            conversationId === editConvId) ||
          !isOwner,
      });
    }

    if (isComment && editConvId !== null && editConvId !== undefined) {
      menuItems.push({
        key: 'close_edit_conversation',
        text: 'Close Editing',
        iconProps: EditIconProps,
        onClick: handleCloseEditing,
        disabled: false,
      });
    }

    return menuItems;
  }, [
    conversationId,
    editConvId,
    handleCloseEditing,
    handleDelete,
    handleSetEditing,
    isComment,
    isFirstComment,
    isOwner,
  ]);

  const styles = mergeStyleSets({
    menuContainer: {
      '.ms-layer:empty': {
        display: 'none',
      },
    },
    menuIcon: {
      width: '1.25rem',
      height: '1.25rem',
    },
    menu: {
      minWidth: '5.625rem',
      borderRadius: '.25rem',
    },
  });

  return (
    <div className={styles.menuContainer} onClick={(e) => e.stopPropagation()}>
      <IconButton
        id={`conversation-${conversationId}`}
        iconProps={MoreIconProps}
        onClick={setShowMoreOptionsMenu}
        className={styles.menuIcon}
      />
      {showMoreOptionsMenu && (
        <ContextualMenu
          className={styles.menu}
          key={conversationId}
          target={currentContextualMenuTarget}
          onDismiss={setHideMoreOptionsMenu}
          items={moreOptionsMenuItems}
        />
      )}
    </div>
  );
};
