import { Editor, Element } from 'slate';
import { getParentBlock, insertExitBreak } from '../Helpers/EditorHelpers';

const resetOnBreakTypes = [
  'heading-one',
  'heading-two',
  'heading-three',
  'heading-four',
  'image',
  'link',
  'tag',
  'mention',
] as Element['type'][];

const newLineSoftBreakTypes = [
  'paragraph',
  'block-quote',
  'checklist-item',
  'list-item',
] as Element['type'][];

export const withCustomBreaks = (editor: Editor) => {
  const { insertBreak, insertSoftBreak } = editor;

  editor.insertSoftBreak = () => {
    const activeBlock = getParentBlock(editor);
    const currentBlockType = activeBlock?.[0]?.type;

    if (
      !currentBlockType ||
      !newLineSoftBreakTypes.includes(currentBlockType)
    ) {
      insertExitBreak(editor);
      return;
    }

    editor.insertText(`\n`);
    return;
  };

  editor.insertBreak = () => {
    const activeBlock = getParentBlock(editor);
    const currentBlockType = activeBlock?.[0]?.type;

    if (!currentBlockType || !resetOnBreakTypes.includes(currentBlockType)) {
      insertBreak();
      return;
    }

    insertExitBreak(editor);
    return;
  };

  return editor;
};
