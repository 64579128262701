import { mergeStyleSets } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../Themes/Themes';
import { DealRoomActionItemStatus } from '@meetingflow/common/Api/data-contracts';
import { getStatusDropdownBackgroundColor } from '../../Components/DealRoom/Milestones/milestonesUtils';

export const useMilestoneActionItemStyles = (
  status?: DealRoomActionItemStatus,
) => {
  return mergeStyleSets({
    taskContainer: {
      display: 'flex',
      backgroundColor:
        status === 'COMPLETED'
          ? DEALROOMS_COLORS.neutralLighter
          : DEALROOMS_COLORS.white,
      opacity: status === 'COMPLETED' ? '.7' : '1',
      borderRadius: '.25rem',
      border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
      boxShadow: '0px 2px 2px 0px #00000005',
      padding: '.75rem 1rem',
      flexDirection: 'column',
      gap: '1rem',
      cursor: 'pointer',
    },
    topSection: {
      display: 'flex',
      gap: '1rem',
      alignItems: 'center',
    },
    taskTitle: {
      color: DEALROOMS_COLORS.themePrimary,
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
      fontWeight: '400',
      marginRight: 'auto',
      padding: '.25rem',

      ':hover': {
        backgroundColor: DEALROOMS_COLORS.neutralLight,
        borderRadius: '.25rem',
        cursor: 'pointer',
      },
    },
    taskTitleEditingContainer: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: '1',
      gap: '.5rem',
    },
    taskTitleEditing: {
      width: 'calc(100% - .5rem)',
      maxWidth: '300px',
      border: `1px solid ${DEALROOMS_COLORS.neutralSecondary}`,
      borderRadius: '.25rem',
      '.ms-TextField-fieldGroup': {
        height: 'auto',
        border: 'none',
        borderRadius: '.25rem',
        '::after': {
          borderRadius: '.25rem',
          border: 'none ',
          outline: `none `,
        },
      },
      input: {
        backgroundColor: 'transparent',
        color: DEALROOMS_COLORS.themePrimary,
        fontSize: '1.125rem',
        lineHeight: '1.5rem',
        fontWeight: '400',
        borderRadius: '.25rem',
        padding: '0.2rem',
        '::placeholder': {
          color: DEALROOMS_COLORS.themeSecondary,
        },
      },
    },
    saveTitleButton: {
      i: {
        color: DEALROOMS_COLORS.themePrimary,
      },
    },
    taskOptionsContainer: {
      marginLeft: 'auto',
      display: 'flex',
      alignItems: 'center',
      gap: '.5rem',
    },
    filesContainer: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: '.5rem',
    },
    fileContainer: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: DEALROOMS_COLORS.inputLightGray,
      borderRadius: '.25rem',
      gap: '.125rem',
      padding: '.25rem .375rem',
    },
    artifactName: {
      fontSize: '.75rem',
      lineHeight: '1.125rem',
      color: DEALROOMS_COLORS.neutralDarker,
    },
    statusDropdown: {
      '.ms-Dropdown-title': {
        border: 'none',
        borderRadius: '.25rem',
        backgroundColor: getStatusDropdownBackgroundColor(status),
        minWidth: '6.75rem',
        fontWeight: '400',
        color: `${DEALROOMS_COLORS.black} !important`,
        display: 'flex',
        alignItems: 'center',
      },
    },
  });
};





