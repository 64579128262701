export const EyeOpenIllustration = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2591 19.6021C11.9425 16.3269 14.7944 14 18 14C21.2057 14 24.0574 16.3269 24.7409 19.6021C24.7974 19.8725 25.0622 20.0459 25.3325 19.9895C25.6029 19.933 25.7763 19.6682 25.7199 19.3979C24.9425 15.6731 21.6934 13 18 13C14.3066 13 11.0574 15.6731 10.2802 19.3979C10.2238 19.6682 10.3972 19.933 10.6675 19.9895C10.9378 20.0459 11.2027 19.8725 11.2591 19.6021ZM17.9895 16C19.9225 16 21.4895 17.567 21.4895 19.5C21.4895 21.433 19.9225 23 17.9895 23C16.0565 23 14.4895 21.433 14.4895 19.5C14.4895 17.567 16.0565 16 17.9895 16Z"
      fill="#72003F"
    />
  </svg>
);
