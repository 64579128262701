import {
  Pivot,
  IPivotProps,
  useTheme,
  FontWeights,
  NeutralColors,
} from '@fluentui/react';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';

export const SettingsStyledPivot = (props: IPivotProps) => {
  const theme = useTheme();
  const { isDark } = useLightOrDarkMode();

  return (
    <Pivot
      aria-label="Edit Workspace"
      linkFormat={'links'}
      {...props}
      overflowBehavior="menu"
      overflowAriaLabel="More..."
      styles={{
        link: {
          height: '2rem',
          borderTopLeftRadius: '.25rem',
          borderTopRightRadius: '.25rem',
          transition: '.3s ease-in-out all',

          selectors: {
            ':hover': {
              backgroundColor: isDark
                ? MEETINGFLOW_COLORS.purpleDarker
                : MEETINGFLOW_COLORS.purpleLighter,
            },
          },
        },
        linkIsSelected: {
          ':before': {
            backgroundColor: isDark
              ? MEETINGFLOW_COLORS.white
              : MEETINGFLOW_COLORS.purpleLight,
          },
          backgroundColor: isDark
            ? MEETINGFLOW_COLORS.purpleDark
            : MEETINGFLOW_COLORS.purpleLighter,
          color: isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.black,
          fontWeight: FontWeights.regular,
        },

        itemContainer: {
          border: `.5px solid ${
            isDark ? NeutralColors.gray180 : MEETINGFLOW_COLORS.purpleLighter
          }`,
          backgroundColor: isDark
            ? NeutralColors.gray210
            : MEETINGFLOW_COLORS.white,
          padding: '1rem',
          borderBottomLeftRadius: '.25rem',
          borderBottomRightRadius: '.25rem',
          overflow: 'auto',
          maxHeight: 'calc(100vh - 15rem)',
        },
      }}
    >
      {props?.children}
    </Pivot>
  );
};
