import { Spinner } from '@fluentui/react';
import { Outlet } from 'react-router';
import { useOrganization } from '../../Hooks/useOrganization';
import OrganizationForbidden from './OrganizationForbidden';
import { ResourceNotFound } from '../ResourceNotFound';

export type OrganizationProps = {};
export const OrganizationRoot = () => {
  const {
    isLoading: orgLoading,
    forbidden: orgForbidden,
    notFound: orgNotFound,
  } = useOrganization();

  if (orgLoading) {
    return <Spinner />;
  }

  if (orgNotFound) {
    return <ResourceNotFound resourceType="Workspace" />;
  }
  if (orgForbidden) {
    return <OrganizationForbidden />;
  }

  return <Outlet />;
};
