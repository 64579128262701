import { useAuth0 } from '@auth0/auth0-react';
import {
  FontIcon,
  FontSizes,
  FontWeights,
  Text,
  TooltipHost,
  mergeStyles,
} from '@fluentui/react';
import {
  ExternalServiceObject,
  ExternalServiceObjectType,
} from '@meetingflow/common/Api/data-contracts';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useExternalServiceConfigurations } from '../../../../Hooks/useExternalServiceConfigurations';
import { ApiClient } from '../../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../../Themes/Themes';

export type HubSpotPinTileButtonProps = {
  organizationSlug: string;
  meetingPlanId: string;
  objectType: ExternalServiceObjectType;
  objectName: string;
  objectId: string;
  externalServiceObjectId?: number;
  tileHasOverline?: boolean;
  showObjectType?: boolean;
  isPinned?: boolean;
  className?: string;
  onPinToggle?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};
export const HubSpotPinTileButton = ({
  organizationSlug,
  meetingPlanId,
  objectName,
  objectType,
  objectId,
  externalServiceObjectId,
  tileHasOverline,
  isPinned,
  onPinToggle,
  className,
}: HubSpotPinTileButtonProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const { configurationsWithToken } = useExternalServiceConfigurations({
    app: 'HUBSPOT',
    withToken: true,
  });

  const [activeConfigurationId, setActiveConfigurationId] = useState<
    number | undefined
  >();

  useEffect(() => {
    if (configurationsWithToken?.length === 1) {
      setActiveConfigurationId(configurationsWithToken[0].id);
    }
  }, [configurationsWithToken]);

  const objectLabel = useMemo(() => {
    switch (objectType) {
      case 'ACCOUNT':
        return 'company';
      case 'DEAL':
        return 'deal';
      default:
        return objectType.toLowerCase();
    }
  }, [objectType]);

  const pinClass = mergeStyles({
    padding: '.25rem',
    marginTop: tileHasOverline ? '.25rem' : 0,
    marginRight: '.25rem',
    borderRadius: '.25rem',
    transition: '.3s all ease-in-out',
    color: isPinned
      ? MEETINGFLOW_COLORS.purpleMedium
      : MEETINGFLOW_COLORS.purpleLight,
    cursor: 'pointer',

    ':hover': {
      color: MEETINGFLOW_COLORS.purpleDark,
    },
  });

  return (
    <TooltipHost
      className={className}
      content={
        <div style={{ padding: '.5rem' }}>
          <Text
            style={{
              fontSize: FontSizes.smallPlus,
              fontWeight: FontWeights.bold,
              color: 'white',
            }}
          >
            {isPinned
              ? `Unpin ${objectLabel} from Meetingflow`
              : `Pin ${objectLabel} to Meetingflow`}
            <span
              style={{
                display: 'block',
                fontSize: FontSizes.mini,
                fontWeight: FontWeights.regular,
              }}
            >
              {isPinned ? (
                <>
                  Unpinning {objectName} from this Meetingflow will remove it
                  from your Pinned list, and affect the relevancy of "Related
                  Meetingflows."
                </>
              ) : (
                <>
                  Pinning {objectName} to this Meetingflow will ensure it's
                  always easily accessible, as well as improve relevancy of
                  "Related Meetingflows."
                </>
              )}
            </span>
          </Text>
        </div>
      }
      styles={{
        root: {
          alignSelf: 'baseline',
        }
      }}
      calloutProps={{
        backgroundColor: MEETINGFLOW_COLORS.teal,
        styles: {
          root: {
            color: 'green',
            padding: 0,
            maxWidth: '18rem',
          },
          beak: {
            color: 'green',
          },
        },
      }}
    >
      <FontIcon
        className={pinClass}
        onClick={async (e?: React.MouseEvent<HTMLElement>) => {
          e?.preventDefault();
          e?.stopPropagation();
          if (isPinned) {
            await toast.promise(
              (async () => {
                const token = await getAccessTokenSilently();
                return ApiClient.delete<ExternalServiceObject>(
                  `/organization/${organizationSlug}/plan/${meetingPlanId}/association/${externalServiceObjectId}`,
                  {
                    headers: { Authorization: `Bearer ${token}` },
                  },
                );
              })(),
              {
                loading: `Unpinning ${objectLabel} ${objectName} from this Meetingflow.`,
                success: () => {
                  onPinToggle?.(e);
                  return `Unpinned ${objectLabel} ${objectName} from this Meetingflow.`;
                },
                error: (err) => {
                  return `Something went wrong unpinning ${objectLabel} ${objectName} from this Meetingflow.`;
                },
              },
            );
          } else {
            await toast.promise(
              (async () => {
                let urlParam = '';
                switch (objectType) {
                  case 'ACCOUNT': {
                    urlParam = 'company';
                    break;
                  }
                  case 'DEAL': {
                    urlParam = 'deal';
                    break;
                  }
                  default:
                }
                const token = await getAccessTokenSilently();
                return ApiClient.put<ExternalServiceObject>(
                  `/organization/${organizationSlug}/plan/${meetingPlanId}/external/hubspot/${urlParam}`,
                  {
                    configurationId: activeConfigurationId,
                    objectId,
                  },
                  {
                    headers: { Authorization: `Bearer ${token}` },
                  },
                );
              })(),
              {
                loading: `Pinning ${objectLabel} ${objectName} to this Meetingflow.`,
                success: () => {
                  onPinToggle?.(e);

                  return `Pinned ${objectLabel} ${objectName} to this Meetingflow.`;
                },
                error: (err) => {
                  return `Something went wrong pinning ${objectLabel} ${objectName} to this Meetingflow.`;
                },
              },
            );
          }
        }}
        iconName={isPinned ? 'PinnedSolid' : 'Pinned'}
        title={
          isPinned
            ? `Unpin this ${objectLabel}`
            : `Pin this ${objectLabel} to this Meetingflow`
        }
      />
    </TooltipHost>
  );
};
