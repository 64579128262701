import { BaseSidePanel } from './BaseSidePanel';
import { NeutralColors, Pivot, PivotItem } from '@fluentui/react';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { CompanyCard } from '../MeetingPlanAttendees';
import { SalesforcePlanContext } from '../../../Models/Salesforce/SalesforcePlanContext';
import { HubSpotPlanContext } from '../../../Models/HubSpot/HubSpotPlanContext';
import { Company } from '@meetingflow/common/Api/data-contracts';

export type CompaniesSidePanelProps = {
  organizationSlug: string;
  companies: Company[] | undefined;
  salesforcePlanContext?: SalesforcePlanContext;
  hubspotPlanContext?: HubSpotPlanContext;
  onBack?: () => void;
  onClose: () => void;
  internalDomains: string[];
  onCompanyClick: (id: number) => void;
};
export const CompaniesSidePanel = ({
  organizationSlug,
  companies,
  salesforcePlanContext,
  hubspotPlanContext,
  onBack,
  onClose,
  internalDomains,
  onCompanyClick,
}: CompaniesSidePanelProps) => {
  const { isDark } = useLightOrDarkMode();

  const pivotStyles = {
    root: {
      borderBottom: `1px solid ${
        isDark ? NeutralColors.gray140 : NeutralColors.gray60
      }`,
    },
    itemContainer: {
      padding: '1rem 0',
    },
  };

  const internalCompanies = companies?.filter((c) => !!c.isInternal);

  const externalCompanies = companies?.filter((c) => !c.isInternal);

  return (
    <BaseSidePanel title="Companies" onBack={onBack} onClose={onClose}>
      <Pivot styles={pivotStyles}>
        <PivotItem headerText="External">
          {externalCompanies?.length
            ? externalCompanies.map((c) => (
                <>
                  <CompanyCard
                    organizationSlug={organizationSlug}
                    onClick={onCompanyClick}
                    salesforcePlanContext={salesforcePlanContext}
                    hubspotPlanContext={hubspotPlanContext}
                    key={c.id}
                    company={c}
                    companyListStyle
                    allowEditingCompanyNotes
                    displayCompanyNotes
                  />
                </>
              ))
            : 'There are no external companies associated with this meeting.'}
        </PivotItem>
        <PivotItem headerText="Internal">
          {internalCompanies
            ? internalCompanies.map((c) => (
                <CompanyCard
                  organizationSlug={organizationSlug}
                  onClick={onCompanyClick}
                  salesforcePlanContext={salesforcePlanContext}
                  hubspotPlanContext={hubspotPlanContext}
                  key={c.id}
                  company={c}
                  companyListStyle
                  allowEditingCompanyNotes
                  displayCompanyNotes
                />
              ))
            : 'There are no internal companies associated with this meeting.'}
        </PivotItem>
      </Pivot>
    </BaseSidePanel>
  );
};
