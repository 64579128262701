import { DealRoomArtifact } from '@meetingflow/common/Api/data-contracts';
import { useMemo } from 'react';
import { v4 } from 'uuid';
import { useDeferredPromise } from '../../useDeferredPromise';
import { UploadDealRoomAttachmentsDialog } from '../../../Components/DealRoom/Dialogs/UploadDealRoomAttachmentsDialog';

type UseAddDealRoomArtifactProps = {
  organizationSlug: string;
  dealRoomId: number;
  artifact?: DealRoomArtifact;
};
export const useAddDealRoomArtifactDialog = ({
  organizationSlug,
  dealRoomId,
  artifact,
}: UseAddDealRoomArtifactProps) => {
  const { createDeferred, resolve, reject, deferred } = useDeferredPromise<
    DealRoomArtifact,
    File | undefined
  >();

  const dialog = useMemo(() => {
    if (!deferred || !deferred.isPending) {
      return null;
    }

    return (
      <UploadDealRoomAttachmentsDialog
        key={v4()}
        organizationSlug={organizationSlug}
        dealRoomId={dealRoomId}
        onAdd={resolve}
        onDismiss={reject}
        artifact={artifact}
        file={deferred.ctx}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deferred, deferred?.isPending, dealRoomId, organizationSlug]);

  return {
    createDeferred,
    resolve,
    reject,
    deferred,
    dialog,
  };
};
