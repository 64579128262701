import { FC } from 'react';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { DecisionSiteUserCalendar } from '../Components/Journey/DecisionSiteUserCalendar';
import { DealRoomBuyersJourneyTabContainer } from './DealRoomBuyersJourneyTab.styles';
import DecisionSiteMeetingflowListWithFilters from '../Components/Journey/DecisionSiteMeetingflowListWithFilters';

import { useOrganization } from '../../../Hooks/useOrganization';
import { DSNewMeetingflowButton } from '../../../Components/MeetingPlans/DSNewMeetingflowButton';
import useDSCreateMeetingflowModal from '../../../Hooks/Modals/useDSNewMeetingflowDialog';

interface DealRoomBuyersJourneyTabProps {}

export const DealRoomBuyersJourneyTab: FC<
  DealRoomBuyersJourneyTabProps
> = () => {
  const { dealRoomId, dealRoomRole } = useDealRoom();
  const { slug: organizationSlug } = useOrganization();

  const {
    createDeferred: createMeetingflowDeferred,
    dialog: createMeetingflowDialog,
  } = useDSCreateMeetingflowModal();

  if (!dealRoomId || !organizationSlug) return null;

  return (
    <DealRoomBuyersJourneyTabContainer>
      {dealRoomRole === 'SELLER' && (
        <>
          <DSNewMeetingflowButton
            organizationSlug={organizationSlug}
            createMeetingflowDeferred={createMeetingflowDeferred}
            showDialog={true}
            decisionSiteId={dealRoomId}
          />
          {createMeetingflowDialog}
        </>
      )}
      <DecisionSiteMeetingflowListWithFilters />

      {/* <div className="column">
        <DecisionSiteUserCalendar />
      </div> */}
    </DealRoomBuyersJourneyTabContainer>
  );
};
