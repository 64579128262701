import {
  Text,
  mergeStyles,
  NeutralColors,
  FontWeights,
  Icon,
} from '@fluentui/react';
import { MEETINGFLOW_COLORS } from '../../../../Themes/Themes';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import SalesforceIcon from '../../../../Static/Images/salesforce.png';
import React from 'react';
import { SalesforcePinTileButton } from './SalesforcePinTileButton';

export type SalesforceLeadTileProps = {
  organizationSlug: string;
  meetingPlanId?: string;
  externalId: string;
  name: string;
  salesforceInstance: string;
  ownerName?: string;
  showArrow?: boolean;
  showObjectType?: boolean;
  showExternalLink?: boolean;
  isPinned?: boolean;
  externalServiceObjectId?: number;
  isLoading?: boolean;
  hideBottomBorder?: boolean;
  onClick?: () => void;
  onPinClick?: (e?: React.MouseEvent<HTMLElement>) => Promise<unknown>;
};

export const SalesforceLeadTile = ({
  meetingPlanId,
  organizationSlug,
  externalId,
  name,
  salesforceInstance,
  ownerName,
  showArrow = true,
  showObjectType = false,
  onClick,
  onPinClick,
  isPinned,
  externalServiceObjectId,
  isLoading,
  hideBottomBorder,
  showExternalLink = false,
}: SalesforceLeadTileProps) => {
  const { isDark } = useLightOrDarkMode();

  const showOverline = !!ownerName || !!showObjectType;

  const leadTileClass = mergeStyles({
    position: 'relative',
    transition: 'all .3s ease-in-out',
    cursor: onClick ? 'pointer' : undefined,
    backgroundColor: isDark
      ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
      : MEETINGFLOW_COLORS.white,
    padding: '.25rem',
    borderRadius: '.25rem',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    columnGap: '.25rem',
    rowGap: '.25rem',
    boxSizing: 'border-box',
    ':last-child': {
      borderBottom: 'none',
    },

    '.content': {
      flexBasis: 'auto',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',

      '*': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },

    '.arrow': {
      flexBasis: '24px',
      height: '24px',
      position: 'relative',
      alignSelf: 'center',
      color: NeutralColors.gray60,
      transition: '.3s ease-in-out all',
    },

    ':hover': {
      backgroundColor: onClick
        ? isDark
          ? NeutralColors.gray210
          : MEETINGFLOW_COLORS.purpleLighter
        : undefined,
        

      '.arrow': {
        color: MEETINGFLOW_COLORS.purpleDark,
        position: 'relative',
      },

      img: { filter: `grayscale(0%) !important` },
    },
  });

  const salesforceLogoClass = mergeStyles({
    position: 'relative' as const,
    cursor: 'pointer',
    transition: '.3s ease-in-out all',
    flexBasis: '28px',
    height: '20px',
    alignSelf: 'center',
  });

  return (
    <div
      className={leadTileClass}
      onClick={onClick ? onClick : undefined}
      style={{ margin: 0, width: '100%' }}
    >
      {meetingPlanId ? (
        <SalesforcePinTileButton
          meetingPlanId={meetingPlanId}
          organizationSlug={organizationSlug}
          objectId={externalId}
          objectName={name}
          objectType={'Lead'}
          tileHasOverline={showOverline}
          isPinned={isPinned}
          onPinToggle={onPinClick}
          externalServiceObjectId={externalServiceObjectId}
        />
      ) : null}

      <div>
        {showOverline ? (
          <Text
            style={{
              lineHeight: '1rem',
              fontWeight: FontWeights.bold,
              color: NeutralColors.gray100,
              textTransform: 'uppercase',
              fontSize: '8px',
            }}
            block
            nowrap
          >
            {showObjectType ? <span>Lead • </span> : null}
            {ownerName ? (
              <span style={{ textTransform: 'initial' }}>{ownerName}</span>
            ) : null}
          </Text>
        ) : null}
        <Text
          variant="medium"
          style={{
            display: 'block',
            fontWeight: FontWeights.semibold,
            position: 'relative',
            top: showOverline ? '-.25rem' : '.1rem',
            maxWidth: 'calc(100% - 2rem)',
            paddingRight: '2rem',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color: isDark ? NeutralColors.gray30 : NeutralColors.gray180,
          }}
          block
        >
          {name}
        </Text>
      </div>

      {showExternalLink ? (
            <Icon
              key="Salesforce"
              className={salesforceLogoClass}
              imageProps={{
                height: '16px',
                src: SalesforceIcon,
                alt: 'Salesforce',
                width: '1.3rem',
                title: `View ${name} on Salesforce`,
                style: {
                  position: 'relative',
                  color: NeutralColors.gray60,
                  filter: `grayscale(100%)`,
                  opacity: isDark ? '1' : '.5',
                  transition: '.3s ease-in-out all',
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                window.open(`${salesforceInstance}/${externalId}`, '_blank');
              }}
            />
          ) : null}

      {showArrow ? (
        <div className="arrow">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5858 6.34317L12 4.92896L19.0711 12L12 19.0711L10.5858 17.6569L16.2427 12L10.5858 6.34317Z"
              fill="currentColor"
            />
          </svg>
        </div>
      ) : null}
    </div>
  );
};
