import { Icon, Image, ImageLoadState, mergeStyleSets } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { useBoolean } from '@fluentui/react-hooks';
import { useCallback } from 'react';
import { ActionItemOwnerMoreOptions } from './ActionItemOwnerMoreOptions';

type ActionItemOwnerContainerProps = {
  avatarUrl: string | undefined;
  actionItemId: number;
  organizationSlug: string;
  dealRoomId: number;
  ownerId?: number;
};

export const ActionItemOwnerContainer = ({
  avatarUrl,
  actionItemId,
  organizationSlug,
  dealRoomId,
  ownerId,
}: ActionItemOwnerContainerProps) => {
  const [imageError, { setTrue: setImageError, setFalse: setNoImageError }] =
    useBoolean(true);

  const handleOnImageStateChange = useCallback(
    (state: ImageLoadState) => {
      if (state === 1) {
        setNoImageError();
      } else {
        setImageError();
      }
    },
    [setImageError, setNoImageError],
  );

  const styles = mergeStyleSets({
    container: {
      display: 'flex',
      alignItems: 'center',
      gap: '.5rem',
    },
    avatar: {
      borderRadius: '50%',
      backgroundColor: 'red',
      ...(imageError ? { display: 'none' } : {}),
    },
    avatarPlaceholder: {
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: DEALROOMS_COLORS.themeSecondary,
      ...(!imageError ? { display: 'none' } : {}),
    },
    placeholderIcon: {
      fontSize: '.7rem',
      color: DEALROOMS_COLORS.white,
    },
  });

  return (
    <div className={styles.container} onClick={(e) => e.stopPropagation()}>
      <ActionItemOwnerMoreOptions
        actionItemId={actionItemId}
        organizationSlug={organizationSlug}
        dealRoomId={dealRoomId}
        ownerId={ownerId}
      />
      <div>
        <div className={styles.avatarPlaceholder}>
          <Icon className={styles.placeholderIcon} iconName="UserSync" />
        </div>
        <Image
          className={styles.avatar}
          src={avatarUrl || undefined}
          width={20}
          height={20}
          onLoadingStateChange={handleOnImageStateChange}
        />
      </div>
    </div>
  );
};
