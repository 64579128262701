import { mergeStyles, IconButton } from '@fluentui/react';
import { NeutralColors, FontWeights } from '@fluentui/theme';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { useSlateStatic } from 'slate-react';
import {
  pickFile,
  uploadFileToOrg,
  uploadFileToPlan,
} from '../../../Helpers/FileHelpers';
import { insertImageNode } from '../Helpers/EditorHelpers';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';

export type AddImageButtonProps = {
  organizationSlug: string;
  meetingPlanId?: string;
};

export const AddImageButton = ({
  organizationSlug,
  meetingPlanId,
}: AddImageButtonProps) => {
  const editor = useSlateStatic();
  const { getAccessTokenSilently } = useAuth0();
  const { isDark } = useLightOrDarkMode();
  const appInsights = useAppInsightsContext();

  const iconButtonClass = mergeStyles({
    backgroundColor: 'transparent',
    color: MEETINGFLOW_COLORS.purpleSecondary,
    height: '1.25rem',
    width: '1.25rem',
    transition: 'all .3s ease-in-out',
    i: {
      fontWeight: FontWeights.semibold,

      fontSize: '12px !important',
    },
    ':hover': {
      backgroundColor: MEETINGFLOW_COLORS.purpleSecondary,
      color: 'white !important',
    },
  });

  return (
    <IconButton
      iconProps={{ iconName: 'PictureFill' }}
      className={iconButtonClass}
      title={'Insert image'}
      alt={'Insert image'}
      onClick={(event) => {
        event.preventDefault();

        pickFile({ fileTypes: 'image', excludeAcceptAll: true })
          .then(async (file) => {
            if (!file) {
              return;
            }

            toast.promise(
              (async () => {
                const token = await getAccessTokenSilently();

                const uploadedUrl = await (meetingPlanId
                  ? uploadFileToPlan(
                      token,
                      organizationSlug,
                      meetingPlanId,
                      file,
                    )
                  : uploadFileToOrg(token, organizationSlug, file));

                insertImageNode(editor, uploadedUrl, file.type);
              })(),
              {
                loading: 'Uploading image',
                success: 'Successfully uploaded image',
                error: (err) => {
                  appInsights.trackEvent({
                    name: 'IMAGE_UPLOAD_FAILED',
                    properties: {
                      fileType: file.type,
                      fileSize: file.size,
                      organizationSlug,
                      meetingPlanId,
                    },
                  });

                  appInsights.trackException({
                    exception: err instanceof Error ? err : new Error(err),
                    properties: {
                      organizationSlug,
                      meetingPlanId,
                      fileType: file.type,
                      fileSize: file.size,
                    },
                  });

                  console.error(err);
                  return 'Failed to upload image';
                },
              },
            );
          })
          .catch((err: unknown) => {
            console.error(err);
          });
      }}
    />
  );
};
