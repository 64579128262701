import { Spinner } from '@fluentui/react';
import { Suspense, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DealRoomBuyersView from './DealRoomBuyersView';
import DealRoomSellersView from './DealRoomSellersView';
import { useOrganizationSlug } from '../../Hooks/useOrganizationSlug';
import { useDealRoomId } from '../../Hooks/useDealRoomId';
import { useOrganization } from '../../Hooks/useOrganization';

export const DealRoomDetailView = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const organizationSlug = useOrganizationSlug();
  const dealRoomId = useDealRoomId();

  const { role } = useOrganization();

  useEffect(() => {
    if (!organizationSlug || !dealRoomId) {
      navigate(`/`);
    }
  }, [organizationSlug, dealRoomId, navigate]);

  if (!organizationSlug || !dealRoomId) {
    return null;
  }

  return (
    <Suspense fallback={<Spinner />}>
        <DealRoomBuyersView
          organizationSlug={organizationSlug!}
          dealRoomId={dealRoomId}
        />
    </Suspense>
  );
};
