import React from 'react';
import { Editor, Element, Path, PathRef, Range, Transforms } from 'slate';
import { RenderElementProps } from 'slate-react';
import { CreateDeferredPromise } from '../../Helpers/DeferredPromise';
import {
  AddIntegrationModalOptions,
  AddIntegrationResult,
} from '../../Hooks/useAddIntegrationModal';
import { AIActionContext } from '../../types/AIActionContext';
import { MeetingPlanPanelContext } from '../../types/MeetingPlanPanelContext';
import { SidePanelShortcutContext } from '../../types/SidePanelShortcutContext';
import { AIActionElement } from './Elements/AIActionElement';
import { ChecklistItemElement } from './Elements/ChecklistElement';
import { ImageElement } from './Elements/ImageElement';
import { LinkElement } from './Elements/LinkElement';
import { MentionElement } from './Elements/MentionElement';
import { SidePanelShortcutElement } from './Elements/SidePanelShortcutElement';
import { FontIcon } from '@fluentui/react';
import { TagElement } from './Elements/TagElement';

const elementTypeToNumber = (nodeType: Element['type']): number => {
  switch (nodeType) {
    case 'heading-one':
      return 1;
    case 'heading-two':
      return 2;
    case 'heading-three':
      return 3;
    case 'heading-four':
      return 4;
    default:
      return 1000;
  }
};

type RenderElementOptions = {
  editor?: Editor;
  collapsedHeadings?: PathRef[];
  setCollapsedHeadings?: React.Dispatch<React.SetStateAction<PathRef[]>>;
  uncollapseHeading?: (path: Path | Path[]) => void;
  hiddenElements?: Set<Element> | null;
  setPanelContext?: (
    context?: MeetingPlanPanelContext | MeetingPlanPanelContext[],
  ) => void;
  createAddIntegrationDeferred?: CreateDeferredPromise<
    AddIntegrationResult | undefined,
    AddIntegrationModalOptions | undefined
  >;
  getAIActionContext?: CreateDeferredPromise<
    AIActionContext,
    Partial<AIActionContext> | undefined
  >;
  getSidePanelShortcutContext?: CreateDeferredPromise<
    SidePanelShortcutContext,
    Partial<SidePanelShortcutContext> | undefined
  >;
};

export const RenderElement =
  ({
    editor,
    collapsedHeadings,
    setCollapsedHeadings,
    uncollapseHeading,
    hiddenElements,
    setPanelContext,
    createAddIntegrationDeferred,
    getAIActionContext,
    getSidePanelShortcutContext,
  }: RenderElementOptions = {}) =>
  (props: RenderElementProps) => {
    const { attributes, children, element } = props;

    const [match] =
      (editor &&
        Editor.nodes(editor, {
          match: (n) => n === element,
          at: [],
        })) ||
      [];

    const path = match?.[1];

    if (
      editor &&
      collapsedHeadings &&
      setCollapsedHeadings &&
      uncollapseHeading &&
      path
    ) {
      const { selection } = editor;

      const isHidden = !!hiddenElements?.has(element);

      if (isHidden) {
        if (
          selection &&
          ((selection?.anchor?.path &&
            Path.isAncestor(path, selection.anchor.path)) ||
            (selection?.focus?.path &&
              Path.isAncestor(path, selection?.focus.path)))
        ) {
          const editorEnd = Editor.end(editor, []);

          const previousCollapsedHeadings = collapsedHeadings.filter(
            (hpref) =>
              hpref.current &&
              Path.isBefore(hpref.current, selection.anchor.path) &&
              !Path.isAncestor(hpref.current, selection.anchor.path),
          );

          const pathsToUncollapse: Path[] = [];

          for (const previousHeading of previousCollapsedHeadings) {
            const [headingElement] = Editor.node(
              editor,
              previousHeading.current!,
            );

            const [collapseRangeEnd] = Editor.nodes<Element>(editor, {
              match: (n, p) =>
                Element.isElement(n) &&
                Path.isAfter(p, previousHeading.current!) &&
                elementTypeToNumber(n.type) <=
                  elementTypeToNumber((headingElement as Element).type),
              at: [],
            });

            if (
              Range.includes(
                Editor.range(
                  editor,
                  Editor.end(editor, previousHeading.current!),
                  collapseRangeEnd?.[1] ? collapseRangeEnd[1] : editorEnd,
                ),
                selection.anchor.path,
              )
            ) {
              pathsToUncollapse.push(previousHeading.current!);
            }
          }

          if (pathsToUncollapse.length) {
            uncollapseHeading(pathsToUncollapse);
          }
        }

        return (
          <div style={{ display: 'none' }} {...attributes}>
            {children}
          </div>
        );
      }

      // check if current element is a heading
      const isHeading = [
        'heading-one',
        'heading-two',
        'heading-three',
        'heading-four',
      ].includes(element.type || '');

      // In case of heading
      if (isHeading) {
        const headingCollapsed = collapsedHeadings.some(
          (range) => range.current && Path.equals(range.current, path),
        );

        const onCollapseArrowClicked = () => {
          if (headingCollapsed) {
            setCollapsedHeadings((collapsedPaths) => {
              const updatedPaths = [];

              for (const collapsedPath of collapsedPaths) {
                if (
                  collapsedPath.current &&
                  Path.equals(collapsedPath.current, path)
                ) {
                  collapsedPath.unref();
                } else if (!!collapsedPath.current) {
                  updatedPaths.push(collapsedPath);
                }
              }

              return updatedPaths;
            });
          } else {
            if (selection) {
              const [collapseRangeEnd] = Editor.nodes<Element>(editor, {
                match: (n, p) =>
                  Element.isElement(n) &&
                  Path.isAfter(p, path) &&
                  elementTypeToNumber(n.type) <=
                    elementTypeToNumber(element.type),
                at: [],
              });

              if (
                Range.includes(
                  Editor.range(
                    editor,
                    Editor.end(editor, path),
                    collapseRangeEnd?.[1]
                      ? collapseRangeEnd[1]
                      : Editor.end(editor, []),
                  ),
                  selection,
                )
              ) {
                Transforms.select(editor, Editor.end(editor, path));
              }
            }

            setCollapsedHeadings([
              ...collapsedHeadings,
              Editor.pathRef(editor, path, { affinity: 'backward' }),
            ]);
          }
        };

        switch (element.type) {
          case 'heading-one':
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                {...attributes}
                data-meetingflow="header-collapsible"
              >
                <FontIcon
                  iconName={
                    headingCollapsed ? 'SectionCollapsed' : 'SectionExpanded'
                  }
                  onClick={onCollapseArrowClicked}
                  contentEditable={false}
                  data-meetingflow="header-collapse-arrow"
                />
                <h2 contentEditable>{children}</h2>
              </div>
            );
          case 'heading-two':
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                {...attributes}
                data-meetingflow="header-collapsible"
              >
                <FontIcon
                  iconName={
                    headingCollapsed ? 'SectionCollapsed' : 'SectionExpanded'
                  }
                  onClick={onCollapseArrowClicked}
                  contentEditable={false}
                  data-meetingflow="header-collapse-arrow"
                />
                <h3 contentEditable>{children}</h3>
              </div>
            );
          case 'heading-three':
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                {...attributes}
                data-meetingflow="header-collapsible"
              >
                <FontIcon
                  iconName={
                    headingCollapsed ? 'SectionCollapsed' : 'SectionExpanded'
                  }
                  onClick={onCollapseArrowClicked}
                  contentEditable={false}
                  data-meetingflow="header-collapse-arrow"
                />
                <h4 contentEditable>{children}</h4>
              </div>
            );
          case 'heading-four':
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                {...attributes}
                data-meetingflow="header-collapsible"
              >
                <FontIcon
                  iconName={
                    headingCollapsed ? 'SectionCollapsed' : 'SectionExpanded'
                  }
                  onClick={onCollapseArrowClicked}
                  contentEditable={false}
                  data-meetingflow="header-collapse-arrow"
                />
                <h5 contentEditable>{children}</h5>
              </div>
            );
        }
      }
    }

    switch (element.type) {
      case 'paragraph':
        break;
      case 'heading-one':
        return <h2 {...attributes}>{children}</h2>;
      case 'heading-two':
        return <h3 {...attributes}>{children}</h3>;
      case 'heading-three':
        return <h4 {...attributes}>{children}</h4>;
      case 'heading-four':
        return <h5 {...attributes}>{children}</h5>;
      case 'block-quote':
        return (
          <blockquote {...attributes}>
            <p>{children}</p>
          </blockquote>
        );
      case 'bulleted-list':
        return <ul {...attributes}>{children}</ul>;
      case 'list-item':
        return <li {...attributes}>{children}</li>;
      case 'numbered-list':
        return <ol {...attributes}>{children}</ol>;
      case 'checklist-item':
        return <ChecklistItemElement {...props} />;
      case 'image':
        return <ImageElement {...props} />;
      case 'link':
        return <LinkElement {...props} />;
      case 'mention':
        return <MentionElement {...props} setPanelContext={setPanelContext} />;
      case 'tag':
        return <TagElement {...props} setPanelContext={setPanelContext} />;
      case 'ai-action': {
        if (getAIActionContext) {
          return (
            <AIActionElement
              {...props}
              setPanelContext={setPanelContext}
              getAIActionContext={getAIActionContext}
            />
          );
        }

        break;
      }
      case 'side-panel-shortcut': {
        if (
          setPanelContext &&
          createAddIntegrationDeferred &&
          getSidePanelShortcutContext
        ) {
          return (
            <SidePanelShortcutElement
              {...props}
              setPanelContext={setPanelContext}
              createAddIntegrationDeferred={createAddIntegrationDeferred}
              getSidePanelShortcutContext={getSidePanelShortcutContext}
            />
          );
        }
        break;
      }
      default:
        console.warn(`Unrecognized element type ${element.type}`);
    }

    return children?.length === 1 && children[0]?.props?.text?.text === '' ? (
      <div className="empty-text" {...attributes}>
        {children}
      </div>
    ) : (
      <div {...attributes}>{children}</div>
    );
  };
