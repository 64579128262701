import React, { Suspense } from 'react';

import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
// NOTE: without this PDFs will double render
import '@cyntler/react-doc-viewer/dist/index.css';
import { mergeStyles, Spinner } from '@fluentui/react';
import { ImageWithFallback } from '../../../Common/ImageWithFallback';
import { ArtifactIconPreview } from '../../Artifacts/ArtifactIconPreview';
import { OfficeArtifactPreview } from '../../Artifacts/OfficeArtifactPreview';
import { ArtifactPreviewProps } from './types';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

const OFFICE_MIME_TYPES = [
  'application/vnd.oasis.opendocument.text',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];

/**
 * ArtifactPreview Component
 *
 * Renders different types of artifact previews based on the artifact type:
 * - Audio files
 * - Images
 * - Videos
 * - Documents (including Office files)
 * - Links
 */
export const ArtifactPreview: React.FC<ArtifactPreviewProps> = ({
  artifact,
  organizationSlug,
  dealRoomId,
}) => {
  const previewContainer = mergeStyles({
    padding: '1.5rem',
    width: 'calc(100% - 3rem)',
    height: 'calc(100% - 3rem)',
    background: DEALROOMS_COLORS.neutralGray,
    overflow: 'auto',

    '.audio-artifact-preview': {
      width: '100%',
      maxWidth: 'calc(100% - 2rem - 2px)',
      maxHeight: 'calc(100% - 2rem - 2px)',
      padding: '1rem',
      border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
      borderRadius: '4px',
    },

    '.image-artifact-preview': {
      height: 'auto',
      maxWidth: 'calc(100% - 2rem - 2px)',
      maxHeight: 'calc(100% - 2rem - 2px)',
      padding: '1rem',
      border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
      borderRadius: '4px',
    },

    '.video-artifact-preview': {
      width: '100%',
      maxWidth: 'calc(100% - 2rem - 2px)',
      maxHeight: 'calc(100% - 2rem - 2px)',
      padding: '1rem',
      border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
      borderRadius: '4px',
    },

    '.document-artifact-preview': {
      width: '100%',
      height: '100%',
      backgroundColor: DEALROOMS_COLORS.white,
      color: DEALROOMS_COLORS.black,
      maxWidth: 'calc(100% - 2rem - 2px)',
      maxHeight: 'calc(100% - 2rem - 2px)',
      padding: '1rem',
      border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
      borderRadius: '4px',
      overflow: 'auto',
    },

    '.link-artifact-preview': {
      width: '100%',
      height: '100%',
      maxWidth: 'calc(100% - 2rem - 2px)',
      maxHeight: 'calc(100% - 2rem - 2px)',
      padding: '1rem',
      border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
      borderRadius: '4px',
    },

    '#pdf-controls': {
      display: 'flex',
      flexDirection: 'row',
      gap: '.25rem',
      backgroundColor: 'transparent !important',
      boxShadow: 'none',
      'a, button, svg, svg *': {
        boxShadow: 'none',
        backgroundColor: DEALROOMS_COLORS.financialLighterGray,
        color: DEALROOMS_COLORS.themeSecondary,
        fill: DEALROOMS_COLORS.themeSecondary,
      },

      svg: {
        backgroundColor: 'transparent !important',
      },

      '.pdf-page-count': {
        color: DEALROOMS_COLORS.themeSecondary,
      },
    },
  });

  const getPreview = () => {
    switch (artifact.type) {
      case 'AUDIO':
        return (
          <audio controls className="audio-artifact-preview">
            <source src={artifact.fileUrl} />
          </audio>
        );
      case 'IMAGE':
        return (
          <ImageWithFallback
            className="image-artifact-preview"
            src={artifact.fileUrl}
            alt={artifact.label || artifact.name}
            fallbackSrc={artifact.thumbnailUrl}
            fallback={<ArtifactIconPreview type="IMAGE" />}
            objectFit="contain"
          />
        );
      case 'VIDEO':
        return (
          <video
            controls
            className="video-artifact-preview"
            poster={artifact.thumbnailUrl || undefined}
          >
            <source src={artifact.fileUrl} />
          </video>
        );
      case 'DOCUMENT':
        if (OFFICE_MIME_TYPES.includes(artifact.mimeType || '')) {
          return (
            <OfficeArtifactPreview
              className="document-artifact-preview"
              organizationSlug={organizationSlug}
              dealRoomId={dealRoomId}
              fileName={artifact.fileName!}
              label={artifact.label}
              name={artifact.name}
              fileUrl={artifact.fileUrl!}
              thumbnailUrl={artifact.thumbnailUrl}
            />
          );
        }
        return (
          <DocViewer
            className="document-artifact-preview"
            pluginRenderers={DocViewerRenderers}
            documents={[{ uri: artifact.fileUrl! }]}
            config={{
              header: { disableHeader: true },
              pdfVerticalScrollByDefault: true,
            }}
          />
        );
      case 'LINK':
        return (
          <iframe
            className="link-artifact-preview"
            src={artifact.url}
            title={artifact.label || artifact.name}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Suspense fallback={<Spinner />}>
      <div className={previewContainer}>{getPreview()}</div>
    </Suspense>
  );
};
