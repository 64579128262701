import { safeStringify } from '@meetingflow/common/ObjectHelpers';
import { Editor, Element } from 'slate';
import { unwrapNodes } from '../Helpers/EditorHelpers';

export const withPanelActions = <T extends Editor>(editor: T): T => {
  const { isInline, normalizeNode } = editor;

  editor.isInline = (element: Element) =>
    ['ai-action', 'side-panel-shortcut'].includes(element.type || '') ||
    isInline(element);

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    if (Element.isElement(node) && node.type === 'ai-action') {
      const nodeText = Editor.string(editor, path);
      if (!nodeText) {
        unwrapNodes(editor, 'ai-action', { at: path });
        return false;
      }
    } else if (Element.isElement(node) && node.type === 'side-panel-shortcut') {
      if (
        !node.panelType ||
        ![
          'insights',
          'contacts',
          'companies',
          'plans',
          'timeline',
          'chatbot',
          'follow-up',
          'email-followup',
          'internal-summary',
          'invite-and-notify',
          'salesforce-browser',
          'hubspot-browser',
          'slack',
          'ms-teams',
          'crm-browser',
          'chat',
        ].includes(node.panelType)
      ) {
        console.info(`Unwrapping side-panel-shortcut ${safeStringify(node)}`);
        unwrapNodes(editor, 'side-panel-shortcut', { at: path });
        return false;
      }
    }

    return normalizeNode(entry);
  };

  return editor;
};
