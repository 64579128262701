import {
  Contact,
  DetailedMeetingflow,
} from '@meetingflow/common/Api/data-contracts';
import { MeetingPlanUserActivityList } from '../MeetingPlanUserActivity';
import { BaseSidePanel } from './BaseSidePanel';

export interface TimelineSidePanelProps {
  meetingPlan:
    | Pick<
        DetailedMeetingflow,
        | 'id'
        | 'attendees'
        | 'creator'
        | 'organizer'
        | 'companies'
        | 'userActivity'
      >
    | undefined;
  organizationSlug: string;
  onBack?: () => void;
  onClose: () => void;
  onContactClick: (c: Contact['id']) => void;
}
export const TimelineSidePanel = ({
  meetingPlan,
  organizationSlug,
  onBack,
  onClose,
  onContactClick,
}: TimelineSidePanelProps) => {
  return (
    <BaseSidePanel title="Timeline" onBack={onBack} onClose={onClose}>
      {meetingPlan ? (
        <MeetingPlanUserActivityList
          meetingPlan={meetingPlan}
          organizationSlug={organizationSlug}
          onContactClick={onContactClick}
        />
      ) : null}
    </BaseSidePanel>
  );
};
