import { getCurrentLocale, getNumberSeparator } from './LocaleHelpers';
import { escapeRegExp } from 'lodash';

export * from '@meetingflow/common/NumberHelpers';

export const parseLocaleFloat = (numStr: string) => {
  const decimalSeparator = getNumberSeparator(getCurrentLocale(), 'decimal');
  const re = new RegExp(`[^0-9${escapeRegExp(decimalSeparator)}]`, 'g');
  return parseFloat(numStr.replaceAll(re, ''));
};

export const isLocalizedFloat = (numStr: string) => {
  const groupSeparator = getNumberSeparator(getCurrentLocale(), 'group');
  const decimalSeparator = getNumberSeparator(getCurrentLocale(), 'decimal');
  const re = new RegExp(
    `[^0-9${escapeRegExp(groupSeparator)}${escapeRegExp(decimalSeparator)}]`,
    'g',
  );

  return numStr.match(re) === null && !isNaN(parseLocaleFloat(numStr));
};

export const toParsedFloatLocaleString = (numStr: string) => {
  const decimalSeparator = getNumberSeparator(getCurrentLocale(), 'decimal');
  const hasDecimal = numStr.includes(decimalSeparator);
  const decimalDigits = hasDecimal
    ? numStr.split(decimalSeparator)[1].length
    : undefined;
  if (numStr.endsWith(decimalSeparator)) {
    return parseLocaleFloat(numStr).toLocaleString() + decimalSeparator;
  }
  return parseLocaleFloat(numStr).toLocaleString(undefined, {
    minimumFractionDigits: decimalDigits,
    maximumFractionDigits: 2,
  });
};

const numbersToWords: {
  [key: number]: string;
} = {
  0: 'zero',
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
  6: 'six',
  7: 'seven',
  8: 'eight',
  9: 'nine',
};

export const numbertoWords = (number?: number) => {
  if (!number) {
    return 'zero';
  }
  return numbersToWords[number] || number.toString();
};
