import { useEffect } from 'react';
import { useParams } from 'react-router';
import { OrganizationSlugRouteParams } from '../types/RouteParams';
import { useOrganization } from './useOrganization';

const siteName = import.meta.env.VITE_SITE_NAME || 'Meetingflow';

export const useTitle = (title?: string) => {
  const { organizationSlug } = useParams<OrganizationSlugRouteParams>();
  const { organization } = useOrganization(organizationSlug);
  useEffect(() => {
    if (!!title && !!(organization?.name || organizationSlug)) {
      document.title = `${title} - ${
        organization?.name || organizationSlug
      } - ${siteName}`;
    } else if (title) {
      document.title = `${title} - ${siteName}`;
    } else if (organization?.name || organizationSlug) {
      document.title = `${
        organization?.name || organizationSlug
      } - ${siteName}`;
    } else {
      document.title = siteName;
    }
  }, [organizationSlug, organization?.name, title]);
};
