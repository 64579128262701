import { Autocomplete, AutocompleteProps } from '@mui/material';
import { Tag } from '@meetingflow/common/Api/data-contracts';
import { DSTextField } from '../DS';
import { DSTagPickerMenuItemRoot, DSTagPickerRoot } from './DSTagPicker.styles';

interface DSTagPickerProps {
  label?: string;
  tags: Tag[];
  onChange?: (value: Tag[]) => void;
  autoCompleteProps?: Omit<
    AutocompleteProps<Tag, boolean, boolean, boolean>,
    'options' | 'renderInput'
  >;
}

export const DSTagPicker = ({
  label,
  tags,
  onChange,
  autoCompleteProps,
  ...props
}: DSTagPickerProps) => {
  return (
    <DSTagPickerRoot>
      <Autocomplete
        options={tags}
        multiple
        renderInput={(params) => <DSTextField {...params} label={label} />}
        renderOption={(props, option) => (
          <DSTagPickerMenuItemRoot {...props}>
            <strong>{option.label}</strong>
          </DSTagPickerMenuItemRoot>
        )}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          return option.label ?? option.id?.toString();
        }}
        onChange={(_, value) => {
          // @ts-ignore
          onChange?.(value);
        }}
        size="small"
        {...autoCompleteProps}
      />
    </DSTagPickerRoot>
  );
};

export default DSTagPicker;
