import {
  Icon,
  mergeStyleSets,
  PrimaryButton,
  Spinner,
  Text,
} from '@fluentui/react';
import classNames from 'classnames';
import { DealRoomArtifact } from '@meetingflow/common/Api/data-contracts';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { useCallback } from 'react';
import { artifactTypeToIconName } from '../../../../Helpers/IconHelpers';
import { hasOwnOptionalStringProperty } from '@meetingflow/common/ObjectHelpers';

type AttachmentsListProps = {
  attachmentList: DealRoomArtifact[];
  artifactId: number;
  handleArtifactClick: (newArtifactId: number) => void;
  setShowArtifactMenu: () => void;
  setContextMenuTargetId: (contextMenuTargetId: number | null) => void;
  loading?: boolean;
};

export const AttachmentsList = ({
  attachmentList,
  artifactId,
  handleArtifactClick,
  setShowArtifactMenu,
  setContextMenuTargetId,
  loading,
}: AttachmentsListProps) => {
  const styles = mergeStyleSets({
    attachContainer: {
      padding: '.3rem .5rem .3rem 1rem',
      borderRadius: '4px',
      border: 'none',
      backgroundColor: 'transparent',
      color: DEALROOMS_COLORS.white,
      minHeight: 'fit-content',
      fontSize: '1rem',
      lineHeight: '1.375rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      ':hover': {
        backgroundColor: DEALROOMS_COLORS.neutralLighter,
        border: 'none',

        ' .artifact-more-options': {
          display: 'block',
        },
      },
      ':focus-visible': {
        outline: `1px solid  ${DEALROOMS_COLORS.darkerGray}`,
        outlineOffset: '-2px',
      },
      ':active': {
        backgroundColor: DEALROOMS_COLORS.neutralLighter,
        border: 'none',
      },
      '::after': {
        outline: 'none !important',
      },
      '.ms-Button-flexContainer': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '.25rem',
        width: '100%',
      },
    },
    attachName: {
      color: DEALROOMS_COLORS.themeSecondary,
      fontSize: '.9375rem',
      lineHeight: '1.5rem',
      fontWeight: '500',
      paddingRight: '1rem',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    fileIcon: {
      color: DEALROOMS_COLORS.themeSecondary,
    },
    activeAttachment: {
      backgroundColor: DEALROOMS_COLORS.white,
    },
    moreOptionIcon: {
      color: DEALROOMS_COLORS.themePrimary,
      marginLeft: 'auto',
      display: 'none',
    },
    noAttachments: {
      width: '100%',
      textAlign: 'center',
    },
  });

  const getArtifactIconName = useCallback((artifact: DealRoomArtifact) => {
    return artifactTypeToIconName(
      artifact.type,
      hasOwnOptionalStringProperty(artifact, 'mimeType')
        ? artifact.mimeType
        : undefined,
    );
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      {!attachmentList.length ? (
        <Text className={styles.noAttachments}>No Artifacts</Text>
      ) : (
        attachmentList.map((artifact) => (
          <PrimaryButton
            className={`${styles.attachContainer} ${artifactId === artifact.id ? styles.activeAttachment : ''}`}
            key={artifact.id}
            onClick={() => handleArtifactClick(artifact.id)}
            id={`attachment-more-options-${artifact.id}`}
          >
            <Icon
              className={styles.fileIcon}
              iconName={getArtifactIconName(artifact)}
            />
            <span className={styles.attachName}>
              {artifact.name ||
                ('fileName' in artifact ? artifact.fileName : `Attachment`)}
            </span>
            <Icon
              className={classNames(
                styles.moreOptionIcon,
                'artifact-more-options',
              )}
              iconName="More"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setContextMenuTargetId(artifact.id);
                setShowArtifactMenu();
              }}
            />
          </PrimaryButton>
        ))
      )}
    </>
  );
};
