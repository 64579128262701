import { useAuth0 } from '@auth0/auth0-react';
import { FontSizes, FontWeights, PrimaryButton, Text } from '@fluentui/react';
import {
  Contact,
  DetailedMeetingflow,
} from '@meetingflow/common/Api/data-contracts';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useMemo } from 'react';
import toast from 'react-hot-toast';
import { CreateDeferredPromise } from '../../../Helpers/DeferredPromise';
import {
  AddIntegrationModalOptions,
  AddIntegrationResult,
} from '../../../Hooks/useAddIntegrationModal';
import { useExternalServiceConfigurations } from '../../../Hooks/useExternalServiceConfigurations';
import { useOrganization } from '../../../Hooks/useOrganization';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import {
  MeetingPlanHubSpotBrowserPanelContext,
  MeetingPlanHubSpotObjectPanelContext,
  MeetingPlanPanelContext,
  MeetingPlanSalesforceBrowserPanelContext,
  MeetingPlanSalesforceObjectPanelContext,
} from '../../../types/MeetingPlanPanelContext';
import { BaseSidePanel } from './BaseSidePanel';

export interface FollowUpSidePanelProps {
  meetingPlan:
    | Pick<
        DetailedMeetingflow,
        | 'id'
        | 'attendees'
        | 'creator'
        | 'organizer'
        | 'companies'
        | 'userActivity'
        | 'textSummary'
      >
    | undefined;
  organizationSlug: string;
  panelVisible: boolean;
  pushPanelContext: (context: MeetingPlanPanelContext) => void;
  setPanelContext: (
    context?: MeetingPlanPanelContext | MeetingPlanPanelContext[],
  ) => void;
  lastSalesforcePanelContext:
    | MeetingPlanSalesforceObjectPanelContext
    | MeetingPlanSalesforceBrowserPanelContext;
  lastHubSpotPanelContext:
    | MeetingPlanHubSpotObjectPanelContext
    | MeetingPlanHubSpotBrowserPanelContext;
  createAddIntegrationDeferred: CreateDeferredPromise<
    AddIntegrationResult | undefined,
    AddIntegrationModalOptions | undefined
  >;
  onBack?: () => void;
  onClose: () => void;
  onContactClick: (c: Contact['id']) => void;
}

export const FollowUpSidePanel = ({
  meetingPlan,
  organizationSlug,
  panelVisible,
  pushPanelContext,
  setPanelContext,
  lastSalesforcePanelContext,
  lastHubSpotPanelContext,
  createAddIntegrationDeferred,
  onBack,
  onClose,
  onContactClick,
}: FollowUpSidePanelProps) => {
  const appInsights = useAppInsightsContext();

  const { user } = useAuth0();

  const { isGuest, hasEntitlement } = useOrganization(organizationSlug);

  const { refetch: refetchExternalServiceConfigurations, hasToken } =
    useExternalServiceConfigurations({ withToken: true });

  const { hasToken: hasSalesforceToken } = useExternalServiceConfigurations({
    app: 'SALESFORCE',
    withToken: true,
  });

  const { hasToken: hasHubspotToken } = useExternalServiceConfigurations({
    app: 'HUBSPOT',
    withToken: true,
  });

  const followUpButtonStyles = useMemo(
    () => ({
      root: {
        boxSizing: 'border-box',
        height: '40px',
        margin: '0 .5rem',
        flexBasis: '25%',
        backgroundColor: MEETINGFLOW_COLORS.purpleMedium,
        border: `1px solid ${MEETINGFLOW_COLORS.purpleMedium}`,
        color: MEETINGFLOW_COLORS.white,
        opacity: '.8',
        transition: '.3s ease-in-out all',

        '@container (width > 65rem)': {
          flexBasis: 'calc(33% - 1rem)',
        },

        '@container (width < 65rem)': {
          flexBasis: 'calc(50% - 1rem)',
        },
      },
      rootHovered: {
        opacity: '1',
        backgroundColor: MEETINGFLOW_COLORS.purpleMedium,
        border: `1px solid ${MEETINGFLOW_COLORS.purpleMedium}`,
      },
      label: {
        fontSize: FontSizes.small,
        lineHeight: '1.2',
      },
    }),
    [],
  );

  return (
    <BaseSidePanel title="Follow Up" onBack={onBack} onClose={onClose}>
      {meetingPlan ? (
        <div id="meetingflow-followup-actions" className="actions">
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              boxSizing: 'border-box',
              rowGap: '1rem',
            }}
          >
            <PrimaryButton
              iconProps={{
                iconName: 'CannedChat',
              }}
              styles={followUpButtonStyles}
              onClick={() => {
                appInsights.trackEvent({
                  name: 'CLICK_FOLLOW_UP_SUMMARIZE',
                  properties: {
                    meetingPlanId: meetingPlan?.id,
                    userId: user?.id,
                  },
                });
                if (panelVisible) {
                  pushPanelContext({
                    type: 'internal-summary',
                  });
                } else {
                  setPanelContext({
                    type: 'internal-summary',
                  });
                }
              }}
              target="_blank"
            >
              {meetingPlan?.textSummary
                ? hasToken('SLACK')
                  ? 'Edit/Post Summary'
                  : 'Edit Notes Summary'
                : hasToken('SLACK')
                  ? 'Summarize Notes'
                  : 'Summarize'}
            </PrimaryButton>

            {hasSalesforceToken() ? (
              <PrimaryButton
                iconProps={{
                  iconName: 'LightningBolt',
                }}
                styles={followUpButtonStyles}
                onClick={() => {
                  appInsights.trackEvent({
                    name: 'CLICK_FOLLOW_UP_UPDATE_SALESFORCE',
                    properties: {
                      meetingPlanId: meetingPlan?.id,
                      userId: user?.id,
                    },
                  });
                  if (panelVisible) {
                    pushPanelContext(lastSalesforcePanelContext);
                  } else {
                    setPanelContext(lastSalesforcePanelContext);
                  }
                }}
                target="_blank"
              >
                Update Salesforce
              </PrimaryButton>
            ) : null}

            {hasHubspotToken() ? (
              <PrimaryButton
                iconProps={{
                  iconName: 'LightningBolt',
                }}
                styles={followUpButtonStyles}
                onClick={() => {
                  appInsights.trackEvent({
                    name: 'CLICK_FOLLOW_UP_UPDATE_HUBSPOT',
                    properties: {
                      meetingPlanId: meetingPlan?.id,
                      userId: user?.id,
                    },
                  });
                  if (panelVisible) {
                    pushPanelContext(lastHubSpotPanelContext);
                  } else {
                    setPanelContext(lastHubSpotPanelContext);
                  }
                }}
                target="_blank"
              >
                Update HubSpot
              </PrimaryButton>
            ) : null}

            {!hasSalesforceToken() && !hasHubspotToken() ? (
              <PrimaryButton
                iconProps={{
                  iconName: 'LightningBolt',
                }}
                styles={followUpButtonStyles}
                onClick={() => {
                  appInsights.trackEvent({
                    name: 'FOLLOW_UP_CRM PROMO_CLICK',
                    properties: {
                      organizationSlug,
                      emailAddress: user?.email,
                    },
                  });
                  createAddIntegrationDeferred({
                    displayOnly: ['SALESFORCE', 'HUBSPOT'],
                    title: 'Add CRM Integration',
                    description: (
                      <div
                        style={{
                          padding: '.5rem',
                        }}
                      >
                        <Text
                          variant="mediumPlus"
                          block
                          style={{
                            fontWeight: FontWeights.semibold,
                            color: MEETINGFLOW_COLORS.teal,
                            marginBottom: '.25rem',
                            paddingLeft: '1rem',
                          }}
                        >
                          Connecting your CRM is quick and easy.
                        </Text>
                        <Text
                          variant="medium"
                          block
                          style={{ maxWidth: '28rem', paddingLeft: '1rem' }}
                        >
                          Meetingflow works with both Salesforce and HubSpot,
                          allowing you to keep your workspaces' source of truth
                          update to date — without losing your flow.
                        </Text>
                      </div>
                    ),
                  })
                    .promise.then((result) => {
                      if (result === undefined) {
                        return;
                      }
                      refetchExternalServiceConfigurations();
                    })
                    .catch(() => toast.error('Failed to add integration.'));
                }}
                target="_blank"
              >
                Update Your CRM
              </PrimaryButton>
            ) : null}

            {!isGuest ? (
              <PrimaryButton
                iconProps={{
                  iconName: 'Share',
                }}
                styles={followUpButtonStyles}
                onClick={() => {
                  appInsights.trackEvent({
                    name: 'CLICK_FOLLOW_UP_SHARE_MEETINGFLOW',
                    properties: {
                      meetingPlanId: meetingPlan?.id,
                      userId: user?.id,
                    },
                  });
                  if (panelVisible) {
                    pushPanelContext({
                      type: 'invite-and-notify',
                    });
                  } else {
                    setPanelContext({
                      type: 'invite-and-notify',
                    });
                  }
                }}
                target="_blank"
              >
                Share Meetingflow
              </PrimaryButton>
            ) : null}

            {hasEntitlement('CHAT_GPT') ? (
              <PrimaryButton
                styles={followUpButtonStyles}
                iconProps={{
                  iconName: 'Mail',
                }}
                onClick={() => {
                  appInsights.trackEvent({
                    name: 'CLICK_FOLLOW_UP_EMAIL',
                    properties: {
                      organizationSlug,
                      meetingPlanId: meetingPlan?.id,
                      userId: user?.id,
                    },
                  });
                  if (panelVisible) {
                    pushPanelContext({
                      type: 'email-followup',
                    });
                  } else {
                    setPanelContext({
                      type: 'email-followup',
                    });
                  }
                }}
                target="_blank"
              >
                Send Follow-up Email
              </PrimaryButton>
            ) : null}
          </div>
        </div>
      ) : null}
    </BaseSidePanel>
  );
};
