import {
  ContextualMenu,
  DirectionalHint,
  mergeStyleSets,
  Text,
} from '@fluentui/react';
import { ChatIllustration } from '../../Components/Illustrations/ChatIllustration';
import { useTaskComments } from '../../../../Hooks/useTaskComments';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { useEffect, useMemo, useState } from 'react';
import { AddToIconProps } from '../../../../utils/iconProps';
import { useBoolean } from '@fluentui/react-hooks';

type CommentsContextualMenuProps = {
  organizationSlug: string;
  dealRoomId: number;
  taskId: number;
  setTaskIdForComments: () => void;
};

export const CommentsContextualMenu = ({
  organizationSlug,
  dealRoomId,
  taskId,
  setTaskIdForComments,
}: CommentsContextualMenuProps) => {
  const [currentContextualMenuTarget, setCurrentContextualMenuTarget] =
    useState<HTMLElement | undefined>(undefined);
  const [
    showContextualMenu,
    { setTrue: setShowContextualMenu, setFalse: setHideContextualMenu },
  ] = useBoolean(false);
  const { taskComments } = useTaskComments(
    organizationSlug,
    dealRoomId,
    taskId,
  );

  useEffect(() => {
    const currentTargetElement = document?.getElementById(
      `comments-contextual-menu-${taskId}`,
    );
    setCurrentContextualMenuTarget(currentTargetElement || undefined);
  }, [taskId]);

  const styles = mergeStyleSets({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      '.ms-layer:empty': {
        display: 'none',
      },
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      gap: '.25rem',
      padding: '0.375rem',
      border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
      backgroundColor: DEALROOMS_COLORS.sidebarTextPrimary,
      borderRadius: '.25rem',
    },
    title: {
      fontWeight: '400',
      fontSize: '1rem',
      color: DEALROOMS_COLORS.neutralDarker,
    },
    contextualMenu: {
      minWidth: '5.625rem',
      borderRadius: '.25rem',
      '.ms-ContextualMenu-itemText': {
        color: DEALROOMS_COLORS.neutralDarker,
      },
      i: {
        color: `${DEALROOMS_COLORS.themePrimary} !important`,
      },
    },
  });

  const contextualMenuItems = useMemo(
    () => [
      {
        key: 'add_comment',
        name: 'Add Comment',
        iconProps: AddToIconProps,
        onClick: () => setTaskIdForComments(),
      },
    ],
    [setTaskIdForComments],
  );

  const title = useMemo(() => {
    return taskComments?.length
      ? `${taskComments.length} comment${taskComments.length > 1 ? 's' : ''}`
      : 'No Comments';
  }, [taskComments]);

  return (
    <div className={styles.wrapper}>
      <div
        id={`comments-contextual-menu-${taskId}`}
        className={styles.content}
        onClick={(e) => {
          e.stopPropagation();
          setShowContextualMenu();
        }}
      >
        <ChatIllustration />
        <Text className={styles.title}>{title}</Text>
      </div>
      {showContextualMenu && (
        <ContextualMenu
          key={taskId}
          className={styles.contextualMenu}
          target={currentContextualMenuTarget}
          onDismiss={setHideContextualMenu}
          // contextualMenuItemAs={ArtifactContextualMenuCustomItem}
          directionalHint={DirectionalHint.bottomRightEdge}
          items={contextualMenuItems}
        />
      )}
    </div>
  );
};
