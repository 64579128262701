// Navigation bar component for the Decision Site Entry view
// Displays the Augment logo, navigation tabs, and user avatar
import {
  Icon,
  mergeStyleSets,
  Image,
  ImageLoadState,
  PrimaryButton,
} from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { AugmentLogo } from '../Components/AugmentLogo';
import { useBoolean } from '@fluentui/react-hooks';
import { useCallback, useMemo } from 'react';
import { useUserProfile } from '../../../Hooks/useProfile';
import { matchPath, useLocation } from 'react-router';
import classNames from 'classnames';

export const DecisionSiteEntryNavbar = () => {
  // State to handle avatar image loading errors
  const [imageError, { setTrue: setImageError, setFalse: setNoImageError }] =
    useBoolean(true);

  const location = useLocation();

  const { user: mfUser } = useUserProfile();

  // Handler for avatar image load state changes
  const handleOnImageStateChange = useCallback(
    (state: ImageLoadState) => {
      if (state === 1) {
        setNoImageError();
      } else {
        setImageError();
      }
    },
    [setImageError, setNoImageError],
  );

  // Determine which navigation tab is currently active based on URL
  const activeTab = useMemo(() => {
    if (
      matchPath(`/organization/:organizationSlug/template`, location.pathname)
    ) {
      return 'template';
    }

    return 'dashboard';
  }, [location.pathname]);

  // Styles for the navbar components using FluentUI's mergeStyleSets
  const styles = mergeStyleSets({
    navbar: {
      display: 'flex',
      alignItems: 'center',
      padding: '0.75rem 2rem',
      backgroundColor: DEALROOMS_COLORS.white,
      gap: '2rem',
      borderBottom: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
    },
    avatar: {
      borderRadius: '50%',
      backgroundColor: 'red',
      ...(imageError ? { display: 'none' } : {}),
    },
    avatarPlaceholder: {
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: DEALROOMS_COLORS.themeSecondary,
      ...(!imageError ? { display: 'none' } : {}),
    },
    avatarPlaceholderIcon: {
      fontSize: '1rem',
      color: DEALROOMS_COLORS.white,
    },
    logoContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 'fit-content',
      marginLeft: 'auto',
    },

    navButtons: {
      display: 'flex',
      alignItems: 'center',
      gap: '2rem',
    },

    button: {
      backgroundColor: 'transparent',
      border: 'none',
      padding: '0',

      color: DEALROOMS_COLORS.themePrimary,
      '.ms-Button-label': {
        fontSize: '1.125rem',
        lineHeight: '1.5rem',
        fontWeight: '400',
        margin: 0,
      },
      ':hover': {
        backgroundColor: 'transparent',
        border: 'none',
        color: DEALROOMS_COLORS.themePrimary,
        '::before': {
          content: '""',
          position: 'absolute',
          bottom: '-4px',
          left: 0,
          width: '100%',
          height: '2px',
          backgroundColor: DEALROOMS_COLORS.themePrimary,
        },
      },
      ':focus': {
        backgroundColor: 'transparent',
        border: 'none',
        color: DEALROOMS_COLORS.themePrimary,
      },
      ':focus-visible': {
        backgroundColor: 'transparent',
        border: 'none',
        color: DEALROOMS_COLORS.themePrimary,
        outline: `1px solid ${DEALROOMS_COLORS.neutralSecondary}`,
      },
      ':active': {
        backgroundColor: 'transparent',
        border: 'none',
        color: DEALROOMS_COLORS.themePrimary,
      },
    },
    activeButton: {
      '.ms-Button-label': {
        fontWeight: '600',
      },
      '::before': {
        content: '""',
        position: 'absolute',
        bottom: '-4px',
        left: 0,
        width: '100%',
        height: '2px',
        backgroundColor: DEALROOMS_COLORS.themePrimary,
      },
    },
  });

  return (
    <div className={styles.navbar}>
      {/* Augment logo on the left */}
      <AugmentLogo />

      {/* Navigation tabs in the center */}
      <div className={styles.navButtons}>
        <PrimaryButton
          className={classNames(
            styles.button,
            activeTab === 'dashboard' && styles.activeButton,
          )}
        >
          Dashboard
        </PrimaryButton>
        
        {/* <PrimaryButton
          className={classNames(
            styles.button,
            activeTab === 'template' && styles.activeButton,
          )}
        >
          Template
        </PrimaryButton> */}
      </div>

      {/* User avatar on the right */}
      <div className={styles.logoContainer}>
        {/* Fallback icon shown when avatar image fails to load */}
        <div className={styles.avatarPlaceholder}>
          <Icon className={styles.avatarPlaceholderIcon} iconName="UserSync" />
        </div>
        {/* User avatar image */}
        <Image
          className={styles.avatar}
          src={mfUser?.avatarFileUrl || mfUser?.avatarUrl || undefined}
          width={40}
          height={40}
          onLoadingStateChange={handleOnImageStateChange}
        />
      </div>
    </div>
  );
};
