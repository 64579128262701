import { Contact, Resource } from '@meetingflow/common/Api/data-contracts';
import randomColor from 'randomcolor';
import { useEffect, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import syncedStore, { Y } from '@syncedstore/core';
import { useSyncedStore } from '@syncedstore/react';
import { useDeferredPromise } from '../../../Hooks/useDeferredPromise';
import { ContactResources } from './ContactResources';
import { isString } from 'lodash';
import toast from 'react-hot-toast';
import { useCollabProvider } from '../../../Hooks/useCollabProvider';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useUserProfile } from '../../../Hooks/useProfile';

interface ContactResourcesDocProps {
  organizationSlug: string;
  contact: Pick<Contact, 'id' | 'name' | 'email' | 'emailDomain'>;
}

const ContactResourcesDoc = ({
  organizationSlug,
  contact,
}: ContactResourcesDocProps) => {
  const { user } = useAuth0();
  const { email } = user!;
  const { user: mfUser } = useUserProfile();
  const appInsights = useAppInsightsContext();

  const contactId = contact.id;

  const ydoc = useMemo(() => {
    return new Y.Doc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationSlug, contactId]);

  const syncStore = useMemo(() => {
    return syncedStore(
      {
        resources: [] as Resource[],
      },
      ydoc,
    );
  }, [ydoc]);

  const store = useSyncedStore(syncStore);

  const color = useMemo(
    () =>
      randomColor({
        luminosity: 'dark',
        format: 'rgba',
        alpha: 1,
      }),
    [],
  );

  const {
    createDeferred: createProviderReconnectDeferred,
    deferred: providerReconnectDeferred,
    resolve: resolveProviderReconnect,
  } = useDeferredPromise<boolean>();

  const { hasConnected: collabHasConnected, isConnected: collabIsConnected } =
    useCollabProvider({
      providerName: 'CONTACT',
      documentName: `Contact__${organizationSlug}__${contact.email}`,
      ydoc,
      color,
      email: email!,
      name: mfUser?.name || user!.name,
      picture: mfUser?.avatarFileUrl || mfUser?.avatarUrl || user?.picture,
    });

  useEffect(() => {
    if (
      !providerReconnectDeferred?.isPending &&
      collabHasConnected &&
      !collabIsConnected
    ) {
      toast.promise(createProviderReconnectDeferred().promise, {
        loading:
          'Connection to the collaboration server was lost, attempting to reconnect',
        success: 'Successfully reconnected to the collaboration server',
        error: 'Unable to reconnect to the collaboration server',
      });
    } else if (!!providerReconnectDeferred?.isPending && collabIsConnected) {
      resolveProviderReconnect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    providerReconnectDeferred?.isPending,
    collabHasConnected,
    collabIsConnected,
  ]);

  return (
    <ContactResources
      key={`contact-resources-${contactId}`}
      showAdd
      organizationSlug={organizationSlug}
      contactId={contact.id}
      resources={store.resources}
      onAdd={(item) => {
        store.resources.push(item);
        appInsights.trackEvent({
          name: 'ADD_RESOURCE_TO_CONTACT',
          properties: {
            contactId,
          },
        });
      }}
      onUpdate={(idOrIdx, props) => {
        if (isString(idOrIdx)) {
          const index = store.resources.findIndex((r) => r.id === idOrIdx);
          if (index !== -1) {
            Object.assign(store.resources[index], props);
          }
        } else {
          Object.assign(store.resources[idOrIdx], props);
        }
      }}
      onDelete={(idOrIdx) => {
        if (isString(idOrIdx)) {
          const index = store.resources.findIndex((r) => r.id === idOrIdx);
          if (index !== -1) {
            store.resources.splice(index, 1);
          }
        } else {
          store.resources.splice(idOrIdx, 1);
        }
      }}
    />
  );
};

export default ContactResourcesDoc;
