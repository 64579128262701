import {
  FontWeights,
  IconButton,
  mergeStyles,
  NeutralColors,
} from '@fluentui/react';
import { SlateSidePanelShortcut } from '@meetingflow/common/Api/data-contracts';
import { Editor, Node, Range } from 'slate';
import { useSlate } from 'slate-react';
import { CreateDeferredPromise } from '../../../Helpers/DeferredPromise';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { MeetingPlanPanelContext } from '../../../types/MeetingPlanPanelContext';
import { SidePanelShortcutContext } from '../../../types/SidePanelShortcutContext';
import {
  getParentBlock,
  insertSidePanelShortcut,
  isBlockActive,
} from '../Helpers/EditorHelpers';

export type SidePanelShortcutButtonProps = {
  hint?: string;
  getSidePanelShortcutContext: CreateDeferredPromise<
    SidePanelShortcutContext,
    Partial<SidePanelShortcutContext> | undefined
  >;
};
export const SidePanelShortcutButton = ({
  hint,
  getSidePanelShortcutContext,
}: SidePanelShortcutButtonProps) => {
  const editor = useSlate();
  const { isDark } = useLightOrDarkMode();

  const isActive = isBlockActive(editor, 'side-panel-shortcut');

  const iconButtonClass = mergeStyles({
    backgroundColor: isActive
      ? isDark
        ? MEETINGFLOW_COLORS.purpleSecondary
        : MEETINGFLOW_COLORS.white
      : 'transparent',
    color: isActive
      ? isDark
        ? MEETINGFLOW_COLORS.white
        : MEETINGFLOW_COLORS.purpleSecondary
      : NeutralColors.gray30,
    width: '1.75rem',
    height: '1.75rem',
    i: {
      fontWeight: FontWeights.semibold,
    },

    ':hover': {
      backgroundColor: MEETINGFLOW_COLORS.purpleSecondary,
      color: 'white !important',
    },
  });

  const title =
    `${isActive ? 'Update' : 'Insert'} Side Panel Shortcut` +
    (hint ? ` (${hint})` : '');

  return (
    <IconButton
      iconProps={{ iconName: 'OpenPane' }}
      className={iconButtonClass}
      title={title}
      alt={title}
      data-meetingflow-is-active={isActive}
      onClick={(event) => {
        event.preventDefault();

        const { selection } = editor;

        const activeSidePanelShortcutNode =
          getParentBlock<SlateSidePanelShortcut>(editor, {
            type: 'side-panel-shortcut',
          });

        const activeSidePanelShortcut = activeSidePanelShortcutNode?.[0];

        getSidePanelShortcutContext(
          activeSidePanelShortcut
            ? {
                text: Node.string(activeSidePanelShortcut),
                sidePanel: activeSidePanelShortcut.panelType as
                  | MeetingPlanPanelContext['type']
                  | undefined,
              }
            : selection && !Range.isCollapsed(selection)
            ? { text: Editor.string(editor, selection) }
            : undefined,
        )
          .promise.then((content) => {
            insertSidePanelShortcut(editor, content.text, content.sidePanel);
          })
          .catch();
      }}
    />
  );
};
