import { useAuth0 } from '@auth0/auth0-react';
import {
  DirectionalHint,
  FontWeights,
  NeutralColors,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import toast from 'react-hot-toast';
import { isAxiosErrorResponse } from '../../Helpers/AxiosHelpers';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { useOrganization } from '../../Hooks/useOrganization';
import { useUserProfile } from '../../Hooks/useProfile';
import { TextClient } from '../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { AsyncDefaultButton, AsyncPrimaryButton } from '../HOC/AsyncButton';
import { AsyncLink } from '../HOC/AsyncLink';

export type IntegrationTileProps = {
  iconSrc: string;
  iconAlt: string;
  iconWidth?: string;
  iconHeight?: string;
  title: string;
  description: string;
  integrationActive: boolean;
  needsCreatorRole?: boolean;
  disabled?: boolean;
  noMaxWidth?: boolean;
  onAdd: () => Promise<unknown>;
  onRemove: () => Promise<unknown>;
  onRequest?: () => Promise<unknown>;
};
export const IntegrationTile = ({
  iconSrc,
  iconAlt,
  iconWidth,
  iconHeight,
  title,
  description,
  integrationActive,
  needsCreatorRole,
  disabled,
  onAdd,
  onRemove,
  onRequest,
  noMaxWidth,
}: IntegrationTileProps) => {
  const { isDark } = useLightOrDarkMode();
  const appInsights = useAppInsightsContext();
  const { getAccessTokenSilently, user } = useAuth0();
  const { userId } = useUserProfile();
  const {
    slug: organizationSlug,
    organization,
    refetchBackground: refetchOrgRole,
  } = useOrganization();

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: 'auto 1fr auto',
        height: 'auto',
        minHeight: '8rem',
        padding: '.75rem',
        borderRadius: '.25rem',
        maxWidth: noMaxWidth ? undefined : '15rem',
        backgroundColor: isDark
          ? NeutralColors.gray200
          : MEETINGFLOW_COLORS.purpleUltraLight,
      }}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto 1fr',
          columnGap: '.75rem',
          alignItems: 'center',
          marginBottom: '.5rem',
        }}
      >
        <img
          src={iconSrc}
          width={iconWidth ?? '2rem'}
          height={iconHeight ?? '2rem'}
          alt={iconAlt}
        />
        <Text variant="large" style={{ fontWeight: FontWeights.semibold }}>
          {title}
        </Text>
      </div>
      <div style={{ alignItems: 'center' }}>
        <Text>{description}</Text>
      </div>
      <div style={{ width: '100%', marginTop: '1rem' }}>
        <TooltipHost
          styles={{
            root: {
              display: 'inline-block',
            },
          }}
          content={
            integrationActive ? (
              'Remove this integration'
            ) : onRequest ? (
              'Request Meetingflow add this integration'
            ) : needsCreatorRole ? (
              <>
                <AsyncLink
                  onClick={async () => {
                    const token = await getAccessTokenSilently();

                    const result = await toast.promise(
                      TextClient.put(
                        `/organization/${organizationSlug}/member/${userId}/role`,
                        'CREATOR',
                        {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                          validateStatus: (status) =>
                            [200, 201, 409].includes(status),
                        },
                      ),
                      {
                        loading: 'Requesting an upgrade to the creator role',
                        success: (r) => {
                          switch (r.status) {
                            case 200: {
                              return `Your account has been upgraded to the creator role`;
                            }
                            case 201: {
                              return `A request for your ${
                                organization?.name || organizationSlug
                              } account to be upgraded to the Creator role has been sent to your workspace admin.`;
                            }
                            // Conflict, already exists
                            case 409: {
                              return `There is already a pending request to upgrade your role to Creator.`;
                            }
                            default: {
                              return ``;
                            }
                          }
                        },
                        error: (err) => {
                          appInsights.trackEvent({
                            name: 'REQUEST_CREATOR_ROLE_FAILED',
                            properties: {
                              organizationId: organization?.id,
                              status: isAxiosErrorResponse(err)
                                ? err.response?.status
                                : undefined,
                              statusText: isAxiosErrorResponse(err)
                                ? err.response?.statusText
                                : undefined,
                            },
                          });

                          return `Something went wrong requesting a creator role, please try again`;
                        },
                      },
                    );

                    switch (result.status) {
                      case 200: {
                        await refetchOrgRole();
                        appInsights.trackEvent({
                          name: 'REQUEST_CREATOR_ROLE',
                          properties: {
                            organizationSlug,
                            email: user?.email,
                            role: 'CREATOR',
                          },
                        });
                        break;
                      }
                      case 201: {
                        appInsights.trackEvent({
                          name: 'REQUEST_CREATOR_ROLE',
                          properties: {
                            organizationSlug,
                            email: user?.email,
                            role: 'CREATOR',
                          },
                        });
                        break;
                      }
                      // Conflict, already exists
                      case 409:
                      default: {
                      }
                    }
                  }}
                >
                  Request creator membership
                </AsyncLink>{' '}
                to add this integration
              </>
            ) : (
              `Add this integration to Meetingflow`
            )
          }
          color="#ffffff"
          directionalHint={DirectionalHint.topCenter}
          tooltipProps={{
            styles: {
              root: {
                color: NeutralColors.white,
                display: 'inline-block',
              },
            },
          }}
        >
          {integrationActive ? (
            <AsyncDefaultButton disabled={disabled} onClick={onRemove}>
              Remove
            </AsyncDefaultButton>
          ) : onRequest ? (
            <AsyncPrimaryButton disabled={disabled} onClick={onRequest}>
              Request
            </AsyncPrimaryButton>
          ) : (
            <AsyncPrimaryButton disabled={disabled} onClick={onAdd}>
              Add
            </AsyncPrimaryButton>
          )}
        </TooltipHost>
      </div>
    </div>
  );
};
