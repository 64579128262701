import { Truthy } from '@meetingflow/common/TypeHelpers';
import { CustomText } from '@meetingflow/common/Types/Slate';
import { postCleanHtml, preCleanHtml } from '@udecode/plate-core';
import {
  cleanDocx,
  getDocxListContentHtml,
  getDocxListIndent,
  getTextListStyleType,
  isDocxContent,
  isDocxList,
} from '@udecode/plate-serializer-docx';
import { isArray } from 'lodash';
import { Descendant, Editor, Element } from 'slate';
import { jsx } from 'slate-hyperscript';
import parse from 'style-to-js';
import { getCoreEditor } from '../Components/Collab/Helpers/EditorHelpers';
import { makeDeferred } from './DeferredPromise';

export * from '@meetingflow/common/SlateHelpers';

const toSlateDescendant = (
  el: globalThis.Element | globalThis.ChildNode,
  markAttributes: Partial<CustomText> = {},
): Descendant | Descendant[] | null => {
  const nodeType = el.nodeType;
  const nodeName = el.nodeName;

  const nodeAttributes = { ...markAttributes };

  // define attributes for text nodes
  switch (nodeName) {
    case 'STRONG':
    case 'B':
      nodeAttributes.bold = true;
      break;
    case 'EM':
    case 'I':
      nodeAttributes.italic = true;
      break;
    case 'U':
      nodeAttributes.underline = true;
      break;
    case 'MARK':
      nodeAttributes.highlight = true;
      break;
    case 'DEL':
    case 'S':
      nodeAttributes.strikethrough = true;
      break;
    case 'CODE':
    case 'KBD':
      nodeAttributes.code = true;
      break;
  }

  if (nodeName === 'SPAN') {
    const element = el as globalThis.Element;
    if (element.getAttribute('style')) {
      const styles = parse(element.getAttribute('style')!);
      if (
        'backgroundColor' in styles &&
        ['yellow', '#ffff00'].includes(styles.backgroundColor.toLowerCase())
      ) {
        nodeAttributes.highlight = true;
      }
      if (
        'background' in styles &&
        styles.background.toLowerCase().includes('yellow')
      ) {
        nodeAttributes.highlight = true;
      }
      // Word highlight
      if ('mso-highlight' in styles) {
        nodeAttributes.highlight = true;
      }
      if (
        'fontWeight' in styles &&
        styles.fontWeight.toLowerCase().includes('bold')
      ) {
        nodeAttributes.bold = true;
      }
      if (
        'fontWeight' in styles &&
        styles.fontWeight.toLowerCase().includes('700')
      ) {
        nodeAttributes.bold = true;
      }
      if (
        'fontStyle' in styles &&
        styles.fontStyle.toLowerCase().includes('underline')
      ) {
        nodeAttributes.underline = true;
      }
      if (
        'fontStyle' in styles &&
        styles.fontStyle.toLowerCase().includes('italic')
      ) {
        nodeAttributes.italic = true;
      }
      if (
        'textDecoration' in styles &&
        styles.textDecoration.toLowerCase().includes('line-through')
      ) {
        nodeAttributes.strikethrough = true;
      }
    }
    // Evernote highlighting
    if (element.getAttribute('data-highlight')) {
      nodeAttributes.highlight = true;
    }
  }

  if (nodeType === Node.TEXT_NODE) {
    const { parentElement } = el;
    if (!parentElement || parentElement.nodeName === 'BODY') {
      return null;
    }
    return el.textContent ? jsx('text', markAttributes, el.textContent) : null;
  } else if (nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  // Word gives lists as spans with custom styles, handle them specially.
  if (nodeName === 'P' && isDocxList(el as globalThis.Element)) {
    const element = el as globalThis.Element;
    const indentDepth = getDocxListIndent(element);
    const text = element.textContent || '';
    const listStyle = getTextListStyleType(text);

    element.innerHTML = getDocxListContentHtml(element);

    const children = el.childNodes
      ? Array.from(el.childNodes)
          .flatMap((node) => toSlateDescendant(node, nodeAttributes))
          .filter(Truthy)
      : [];

    let ul = jsx('element', { type: 'list-item' }, children);
    for (let i = 0; i < indentDepth; i++) {
      ul = jsx(
        'element',
        { type: listStyle ? 'numbered-list' : 'bulleted-list' },
        ul,
      );
    }

    return ul;
  }

  if (
    nodeName === 'DIV' &&
    Array.from(el.childNodes).every((childNode) => childNode.nodeName === 'BR')
  ) {
    return [];
  }

  const children = el.childNodes
    ? Array.from(el.childNodes)
        .flatMap((node) => toSlateDescendant(node, nodeAttributes))
        .filter(Truthy)
    : [];

  // Ensure every node has at least one empty text child
  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''));
  }

  switch (nodeName) {
    case 'META':
    case 'HEAD':
      return null;
    case 'BODY':
      return jsx('fragment', {}, children);
    case 'DIV':
    case 'P':
      return jsx('element', { type: 'paragraph' }, children);
    case 'H1':
      return jsx('element', { type: 'heading-one' }, children);
    case 'H2':
      return jsx('element', { type: 'heading-two' }, children);
    case 'H3':
      return jsx('element', { type: 'heading-three' }, children);
    case 'H4':
      return jsx('element', { type: 'heading-four' }, children);
    case 'OL': {
      const element = el as globalThis.Element;
      // Slack indented list
      if (element.getAttribute('data-indent')) {
        const indentDepth = parseInt(element.getAttribute('data-indent')!);
        let ol = jsx('element', { type: 'numbered-list' }, children);
        for (let i = 0; i < indentDepth; i++) {
          ol = jsx('element', { type: 'numbered-list' }, ol);
        }
        return ol;
      }
      return jsx('element', { type: 'numbered-list' }, children);
    }
    case 'UL': {
      const element = el as globalThis.Element;
      // Evernote to-do list
      if (element.getAttribute('data-todo')) {
        return children.filter(Truthy) as Descendant[];
      }
      if (
        children.some(
          (c) => Element.isElement(c) && c.type === 'checklist-item',
        )
      ) {
        return children.filter(Truthy) as Descendant[];
      }

      // Slack indented list
      if (element.getAttribute('data-indent')) {
        const indentDepth = parseInt(element.getAttribute('data-indent')!);
        let ul = jsx('element', { type: 'bulleted-list' }, children);
        for (let i = 0; i < indentDepth; i++) {
          ul = jsx('element', { type: 'bulleted-list' }, ul);
        }
        return ul;
      }

      return jsx('element', { type: 'bulleted-list' }, children);
    }
    case 'LI': {
      const element = el as globalThis.Element;
      // Google docs checklist item
      if (element.getAttribute('role') === 'checkbox') {
        return jsx(
          'element',
          {
            type: 'checklist-item',
            checked: element.getAttribute('aria-checked') === 'true',
          },
          children,
        );
      }
      // evernote checklist item
      if (element.getAttribute('data-checked')) {
        return jsx(
          'element',
          {
            type: 'checklist-item',
            checked: element.getAttribute('data-checked') === 'true',
          },
          children,
        );
      }
      return jsx('element', { type: 'list-item' }, children);
    }
    case 'BR':
      return jsx('text', nodeAttributes, '\n');
    case 'BLOCKQUOTE':
      return jsx('element', { type: 'block-quote' }, children);
    // Not a real element, included in word doc HTML
    case 'O:P':
      return null;
    case 'A':
      return jsx(
        'element',
        { type: 'link', href: (el as globalThis.Element).getAttribute('href') },
        children,
      );
    case 'TABLE':
    case 'TH':
    case 'TD':
    case 'TBODY':
      return children.filter(Truthy) as Descendant[];
    case 'TR':
      return jsx('element', { type: 'paragraph' }, children);
    case 'COLGROUP':
    case 'COL':
      return null;
    //Inline tags listed on https://developer.mozilla.org/en-US/docs/Web/HTML/Element
    case 'ABBR':
    case 'B':
    case 'BDI':
    case 'BDO':
    case 'CITE':
    case 'CODE':
    case 'DATA':
    case 'DEL':
    case 'DFN':
    case 'EM':
    case 'I':
    case 'INS':
    case 'KBD':
    case 'MARK':
    case 'Q':
    case 'RP':
    case 'RT':
    case 'RUBY':
    case 'S':
    case 'SAMP':
    case 'SMALL':
    case 'SPAN':
    case 'STRONG':
    case 'SUB':
    case 'SUP':
    case 'TIME':
    case 'U':
    case 'VAR':
    case 'WBR':
      return children.filter(Truthy) as Descendant[];
    case 'BUTTON':
    case 'FORM':
    case 'IMG':
    case 'INPUT':
      return null;
    default:
      console.warn(`Unhandled nodeName ${nodeName}`);
      return null;
  }
};

export const isDocXHTML = (htmlContent: string) => {
  if (!htmlContent) {
    return false;
  }

  const document = new DOMParser().parseFromString(
    preCleanHtml(htmlContent),
    'text/html',
  );
  const { body } = document;

  return isDocxContent(body);
};

export const cleanHtml = (htmlContent: string, rtfContent?: string) => {
  const document = new DOMParser().parseFromString(
    preCleanHtml(htmlContent),
    'text/html',
  );
  const { body } = document;

  if (isDocxContent(body)) {
    return cleanDocx(htmlContent, rtfContent ?? '');
  }

  return postCleanHtml(body.innerHTML);
};

export const htmlToSlateDescendants = (
  htmlContent: string,
  rtfContent?: string,
) => {
  const document = new DOMParser().parseFromString(
    cleanHtml(htmlContent, rtfContent).replaceAll(/\n/g, ''),
    'text/html',
  );

  const descendants = toSlateDescendant(document.body);

  const nodes = isArray(descendants)
    ? descendants.filter(Truthy)
    : descendants
      ? [descendants]
      : [];

  const newEditor = getCoreEditor(
    () => Promise.resolve('Super secret'),
    'fake-org',
    makeDeferred,
  );

  newEditor.children = nodes;

  Editor.normalize(newEditor, { force: true });

  return newEditor.children;
};
