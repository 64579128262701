import { mergeStyles, Stack, Text } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../Themes/Themes';
import { NoWorkspacesIllustration } from '../DealRoom/Components/Illustrations/NoWorkspacesIllustration';

export const DecisionSiteHomePlaceholder = () => {
  const wrapperContainerStyle = mergeStyles({
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: DEALROOMS_COLORS.neutralGray,
    width: '100%',

    '.logo-container': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      svg: {
        maxWidth: '100%',
      },
    },

    '.content-container': {
      gap: '.25rem',
      justifyContent: 'center',
      alignItems: 'center',

      '.title': {
        fontSize: '1.125rem',
        fontWeight: '400',
        lineHeight: '1.5rem',
        textAlign: 'center',
        color: DEALROOMS_COLORS.themePrimary,
      },

      '.description': {
        fontSize: '.9375rem',
        fontWeight: '400',
        lineHeight: '1.5rem',
        textAlign: 'center',
        color: DEALROOMS_COLORS.themeSecondary,
      },
    },
  });

  return (
    <Stack className={wrapperContainerStyle}>
      <Stack.Item className="logo-container">
        <NoWorkspacesIllustration />
      </Stack.Item>
      <Stack className="content-container">
        <Text className="title">There are no workspaces to show yet</Text>
        <Text className="description">
          Once a workspace is created, it will be shown here.
        </Text>
      </Stack>
    </Stack>
  );
};
