import { Editor, Element, Transforms } from 'slate';

export const withMentions = <T extends Editor>(editor: T) => {
  const { isInline, isVoid, normalizeNode } = editor;

  editor.isVoid = (element: Element) =>
    element.type === 'mention' || isVoid(element);

  editor.isInline = (element) =>
    element.type === 'mention' || isInline(element);

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;
    // If an image has no href, remove it
    if (Element.isElement(node) && node.type === 'mention') {
      if (!node.contactId) {
        Transforms.removeNodes(editor, { at: path });
        return false;
      }
    }
    return normalizeNode(entry);
  };

  return editor;
};
