import { useAuth0 } from '@auth0/auth0-react';
import {
  FontSizes,
  FontWeights,
  mergeStyles,
  PersonaSize,
  Spinner,
  Text,
} from '@fluentui/react';
import {
  Company,
  Contact,
  DetailedMeetingflow,
} from '@meetingflow/common/Api/data-contracts';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useExternalServiceConfigurations } from '../../../Hooks/useExternalServiceConfigurations';
import { useOrganization } from '../../../Hooks/useOrganization';
import { HubSpotCompany } from '../../../Models/HubSpot/HubSpotCompany';
import { HubSpotPlanContext } from '../../../Models/HubSpot/HubSpotPlanContext';
import { SalesforceAccount } from '../../../Models/Salesforce/SalesforceAccount';
import { SalesforcePlanContext } from '../../../Models/Salesforce/SalesforcePlanContext';
import {
  HubSpotCompanyContextQuery,
  OrganizationCompanyQuery,
  SalesforceCompanyContextQuery,
} from '../../../QueryNames';
import { ApiClient, CompaniesApiClient } from '../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { SalesforcePanelContext } from '../../../types/SalesforcePanelContext';
import CompanyResourcesDoc from '../../Organization/Library/CompanyResourcesDoc';
import ResourceNotFound from '../../ResourceNotFound';
import { CompanyCard, MeetingPlanAttendees } from '../MeetingPlanAttendees';
import { BaseSidePanel } from './BaseSidePanel';
import { CompanyNewsList } from './CompanyNewsList';
import { HubSpotCompanyTile } from './HubSpot/HubSpotCompanyTile';
import { SalesforceAccountTile } from './Salesforce/SalesforceAccountTile';

export type CompanySidePanelProps = {
  color: string;
  organizationSlug: string;
  companyId: Company['id'];
  meetingPlan: Pick<
    DetailedMeetingflow,
    | 'id'
    | 'externalId'
    | 'startTime'
    | 'endTime'
    | 'title'
    | 'creator'
    | 'organizer'
    | 'attendees'
    | 'companies'
    | 'viewStats'
    | 'callRecording'
  >;
  salesforcePlanContext?: SalesforcePlanContext;
  hubspotPlanContext?: HubSpotPlanContext;
  onContactClick: (c: Contact['id']) => void;
  onSalesforceAccountClick: (c: SalesforcePanelContext) => void;
  onBack?: () => void;
  onClose: () => void;
};
export const CompanySidePanel = ({
  color,
  organizationSlug,
  companyId,
  meetingPlan,
  salesforcePlanContext,
  hubspotPlanContext,
  onContactClick,
  onSalesforceAccountClick,
  onClose,
  onBack,
}: CompanySidePanelProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const { configurationsWithToken: salesforceConfigurations } =
    useExternalServiceConfigurations({
      app: 'SALESFORCE',
      withToken: true,
    });

  const { configurationsWithToken: hubspotConfigurations } =
    useExternalServiceConfigurations({
      app: 'HUBSPOT',
      withToken: true,
    });

  const { isGuest } = useOrganization(organizationSlug);

  const {
    data: salesforceAccounts,
    isLoading: salesforceAccountsLoading,
    isError: salesforceAccountsError,
  } = useQuery(
    SalesforceCompanyContextQuery(
      organizationSlug,
      companyId,
      salesforceConfigurations[0]?.id,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<SalesforceAccount[]>(
        `/organization/${organizationSlug}/external/salesforce/configuration/${salesforceConfigurations[0]?.id}/company/${companyId}/context`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    { enabled: !!salesforceConfigurations.length },
  );

  const {
    data: hubspotCompanies,
    isLoading: hubspotCompaniesLoading,
    isError: hubspotCompaniesError,
  } = useQuery(
    HubSpotCompanyContextQuery(
      organizationSlug,
      companyId,
      hubspotConfigurations[0]?.id,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<HubSpotCompany[]>(
        `/organization/${organizationSlug}/external/hubspot/configuration/${hubspotConfigurations[0]?.id}/company/${companyId}/context`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    { enabled: !!hubspotConfigurations.length },
  );

  const { data: companyData, isLoading: companyLoading } = useQuery(
    OrganizationCompanyQuery(organizationSlug, companyId),
    async () => {
      const token = await getAccessTokenSilently();
      return CompaniesApiClient.getCompany(organizationSlug, companyId, {
        headers: { Authorization: `Bearer ${token}` },
      });
    },
  );

  const company = useMemo(() => {
    return (
      companyData?.data ?? meetingPlan.companies.find((c) => c.id === companyId)
    );
  }, [companyData?.data, companyId, meetingPlan.companies]);

  const sidePanelSectionClass = mergeStyles({
    margin: '1rem 0',
  });

  return (
    <BaseSidePanel title="Company" onClose={onClose} onBack={onBack}>
      {!company && companyLoading ? (
        <Spinner />
      ) : !company ? (
        <ResourceNotFound resourceType="Company" />
      ) : (
        <>
          <div className={sidePanelSectionClass}>
            <Text
              block
              style={{
                fontSize: FontSizes.medium,
                fontWeight: FontWeights.semibold,
                color: MEETINGFLOW_COLORS.teal,
                marginTop: '.5rem',
                marginBottom: '.5rem',
              }}
            >
              Details and Notes
            </Text>
            <CompanyCard
              organizationSlug={organizationSlug}
              company={company}
              salesforcePlanContext={salesforcePlanContext}
              hubspotPlanContext={hubspotPlanContext}
              displayCompanyNotes={!isGuest}
              allowEditingCompanyNotes={!isGuest}
              personaSize={PersonaSize.size72}
            />
          </div>

          <div className={sidePanelSectionClass}>
            <Text
              block
              style={{
                fontSize: FontSizes.medium,
                fontWeight: FontWeights.semibold,
                color: MEETINGFLOW_COLORS.teal,
                marginTop: '.5rem',
                marginBottom: '.5rem',
              }}
            >
              Resources
            </Text>
            <CompanyResourcesDoc
              company={company}
              organizationSlug={organizationSlug}
            />
          </div>
          {hubspotConfigurations.length ? (
            <div className={sidePanelSectionClass}>
              <Text
                block
                style={{
                  fontSize: FontSizes.medium,
                  fontWeight: FontWeights.semibold,
                  marginBottom: '.5rem',
                }}
              >
                HubSpot Companies
              </Text>
              {hubspotCompaniesLoading ? (
                <Spinner />
              ) : hubspotCompaniesError ? (
                <Text
                  block
                  variant="mediumPlus"
                  style={{ fontStyle: 'italic', margin: '1rem 0' }}
                >
                  Something went wrong fetching HubSpot companies associated
                  with this company. Please try again.
                </Text>
              ) : !hubspotCompanies?.data.length ? (
                <>
                  <Text
                    block
                    variant="mediumPlus"
                    style={{ fontStyle: 'italic', margin: '1rem 0' }}
                  >
                    There are no matching companies.
                  </Text>
                </>
              ) : (
                hubspotCompanies.data.map((account) => (
                  <HubSpotCompanyTile
                    key={account.id}
                    id={account.id}
                    organizationSlug={organizationSlug}
                    name={account.properties.name}
                    hubspotInstance={hubspotConfigurations[0].instanceId}
                  />
                ))
              )}
            </div>
          ) : null}
          {salesforceConfigurations.length &&
          salesforceAccounts?.data.length ? (
            <div className={sidePanelSectionClass}>
              <Text
                block
                style={{
                  fontSize: FontSizes.medium,
                  fontWeight: FontWeights.semibold,
                  marginBottom: '.5rem',
                }}
              >
                Salesforce Accounts
              </Text>
              {salesforceAccountsLoading ? (
                <Spinner />
              ) : salesforceAccountsError ? (
                <Text
                  block
                  variant="mediumPlus"
                  style={{ fontStyle: 'italic', margin: '1rem 0' }}
                >
                  Something went wrong fetching Salesforce accounts associated
                  with this company. Please try again.
                </Text>
              ) : (
                salesforceAccounts.data.map((account) => (
                  <SalesforceAccountTile
                    meetingPlanId={meetingPlan.id}
                    organizationSlug={organizationSlug}
                    key={account.Id}
                    externalId={account.Id}
                    name={account.Name}
                    salesforceInstance={salesforceConfigurations[0].instanceId}
                    ownerName={account.Owner?.Name}
                    onClick={
                      onSalesforceAccountClick
                        ? () =>
                            onSalesforceAccountClick({
                              name: account.Name,
                              objectId: account.Id,
                              objectType: 'ACCOUNT',
                              serviceConfigurationId:
                                salesforceConfigurations[0].id,
                            })
                        : undefined
                    }
                  />
                ))
              )}
            </div>
          ) : null}
          <div className={sidePanelSectionClass}>
            <Text
              block
              style={{
                margin: '1rem 0 .5rem 0 ',
                fontSize: FontSizes.medium,
                fontWeight: FontWeights.semibold,
                color: MEETINGFLOW_COLORS.teal,
                position: 'relative',
                display: 'block',
              }}
            >
              Attendees
            </Text>
            <MeetingPlanAttendees
              organizationSlug={organizationSlug}
              meetingPlan={meetingPlan}
              salesforcePlanContext={salesforcePlanContext}
              hubspotPlanContext={hubspotPlanContext}
              showAttendeesOnly
              limitToCompanies={[company]}
              onContactClick={onContactClick}
              hideCompanyHeader
              noPivot
              showEmailDomains
              smallWidthLayout
              showAvatars={false}
            />
          </div>
          <div className={sidePanelSectionClass}>
            <CompanyNewsList
              organizationSlug={organizationSlug}
              companies={[company]}
              maxArticlesPerCompany={3}
              title="News"
            />
          </div>
        </>
      )}
    </BaseSidePanel>
  );
};
