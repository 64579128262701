import * as yup from 'yup';
import { isLocalizedFloat, parseLocaleFloat } from '../NumberHelpers';
import { isBoolean, isInteger } from '@meetingflow/common/TypeHelpers';
import { toErrorCollection } from '../YupErrorHelpers';

export const validateData = (
  schema: yup.AnyObjectSchema,
  data: unknown,
): [boolean, Record<string, undefined | string[]>] => {
  try {
    if (
      schema.isValidSync(data, {
        abortEarly: false,
        recursive: true,
      }) ||
      schema.validateSync(data, {
        abortEarly: false,
        recursive: true,
      })
    ) {
      return [true, {} as Record<string, string[]>];
    }
  } catch (err: unknown) {
    if (err instanceof yup.ValidationError) {
      return [false, toErrorCollection(err)];
    }
  }

  return [false, {} as Record<string, string[]>];
};

export const localizedFloatValidator = (
  fieldName: string,
  nullable?: boolean,
) => {
  const validator = yup
    .string()
    .test(
      `${fieldName} number`,
      `${fieldName} should be a number`,
      (v) => !v || (isLocalizedFloat(v) && isFinite(parseLocaleFloat(v))),
    );

  if (nullable) {
    return validator.nullable();
  }

  return validator.required(`${fieldName} is required`);
};

export const integerValidator = (fieldName: string, nullable?: boolean) => {
  const validator = yup
    .string()
    .test(
      `${fieldName} is integer`,
      `${fieldName} should be an integer`,
      isInteger,
    );

  if (nullable) {
    return validator.nullable();
  }

  return validator.required(`${fieldName} is required`);
};

export const percentValidator = (fieldName: string, nullable?: boolean) => {
  const validator = yup
    .string()
    .test(
      `${fieldName} is integer`,
      `${fieldName} should be an integer`,
      isInteger,
    )
    .test(
      `${fieldName} is between 0 and 100`,
      `${fieldName} should be between 0 and 100`,
      (v) => {
        if (!v || !isInteger(v)) {
          return false;
        }
        const value = Number(v);
        return value >= 0 && value <= 100;
      },
    );

  if (nullable) {
    return validator.nullable();
  }

  return validator.required(`${fieldName} is required`);
};

export const booleanValidator = (fieldName: string, nullable?: boolean) => {
  const validator = yup
    .string()
    .test(
      `${fieldName} is boolean`,
      `${fieldName} should be a boolean`,
      isBoolean,
    );

  if (nullable) {
    return validator.nullable();
  }

  return validator.required(`${fieldName} is required`);
};

export const dateTimeValidator = (fieldName: string, nullable?: boolean) => {
  const validator = yup
    .string()
    .test(
      `${fieldName} is ISO datetime`,
      `${fieldName} should be a date`,
      (v) => {
        try {
          v && new Date(v);
          return true;
        } catch {
          return false;
        }
      },
    );

  if (nullable) {
    return validator.nullable();
  }

  return validator.required(`${fieldName} is required`);
};

export const enumerationValidator = (
  fieldName: string,
  values?: string[],
  nullable?: boolean,
) => {
  if (nullable) {
    return yup
      .string()
      .oneOf([null, '', ...(values ?? [])], `Invalid value for ${fieldName}`)
      .nullable();
  }

  return yup
    .string()
    .oneOf(values ?? [], `Invalid value for ${fieldName}`)
    .required(`${fieldName} is required`);
};

export const multiEnumerationValidator = (
  fieldName: string,
  values?: string[],
  nullable?: boolean,
) => {
  if (nullable) {
    return yup
      .string()
      .test(
        `${fieldName} should be in list of available values`,
        `${fieldName} should be in list of available values`,
        (value) => {
          if (value === null) {
            return true;
          }
          if (value === '') {
            return true;
          }
          if (!value && !values?.length) {
            return true;
          }

          return (
            value
              ?.split(';')
              .every((selected) => values?.includes(selected) || false) || false
          );
        },
      )
      .nullable();
  }

  return yup
    .string()
    .test(
      `${fieldName} should be in list of available values`,
      `${fieldName} should be in list of available values`,
      (value) => {
        if (value === null) {
          return true;
        }
        if (value === '') {
          return true;
        }
        if (!value && !values?.length) {
          return true;
        }

        return (
          value
            ?.split(';')
            .every((selected) => values?.includes(selected) || false) || false
        );
      },
    )
    .required(`${fieldName} is required`);
};

export const stringValidator = (fieldName: string, nullable?: boolean) => {
  const validator = yup.string();

  if (nullable) {
    return validator.nullable();
  }

  return validator.required(`${fieldName} is required`);
};

export const emptyValidator = () => {
  return yup.string().nullable();
};
