import { mergeStyleSets } from '@fluentui/react';
import { PropsWithChildren } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import { DEALROOMS_COLORS } from '../../Themes/Themes';

type DraggableFileUploaderContainerProps = {
  handleOnDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  className?: string;
};

export const DraggableFileUploaderContainer = ({
  children,
  handleOnDrop,
  className,
}: PropsWithChildren<DraggableFileUploaderContainerProps>) => {
  const [
    isTargetInDropZone,
    { setTrue: setIsTargetIn, setFalse: setIsTargetOut },
  ] = useBoolean(false);

  const styles = mergeStyleSets({
    dropZone: {
      width: '100%',
      boxSizing: 'border-box',
      borderRadius: '.25rem',
      border: `2px dashed ${DEALROOMS_COLORS.lightGray}`,
      backgroundColor: DEALROOMS_COLORS.neutralLighterAlt,
      margin: '0 auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      gap: '1rem',
      padding: '1.5rem 1rem',
    },
    dropZoneActive: {
      filter: 'blur(2px)',
    },
  });

  return (
    <div
      className={`${styles.dropZone} ${
        isTargetInDropZone ? styles.dropZoneActive : ''
      } ${className ? className : ''}`}
      onDrop={(event) => {
        event.preventDefault();
        setIsTargetOut();
        handleOnDrop(event);
      }}
      onDragOver={(event) => {
        event.preventDefault();
        setIsTargetIn();
      }}
      onDragLeave={(event) => {
        event.preventDefault();
        setIsTargetOut();
      }}
    >
      {children}
    </div>
  );
};
