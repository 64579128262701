import {
  booleanValidator,
  dateTimeValidator,
  emptyValidator,
  enumerationValidator,
  integerValidator,
  localizedFloatValidator,
  stringValidator,
} from './HubSpotYupValidators';
import * as yup from 'yup';
import {
  HubSpotProperty,
  HubSpotSchemaResponse,
} from '../../Models/HubSpot/HubSpotSchema';
import { DateTime } from 'luxon';
import { HubSpotDealPipeline } from '../../Models/HubSpot/HubSpotDealPipeline';

export type HubSpotFieldNameFieldMap = Record<
  string,
  HubSpotProperty | undefined
>;

export const encodeLightningField = (text: string): string =>
  encodeURIComponent(text.replaceAll(',', '%2c'));

export const getYupObjectValidator = (
  schema: HubSpotSchemaResponse['hubspotSchema'] | undefined,
  fieldNames: string[],
  fieldNameMap: HubSpotFieldNameFieldMap,
) => {
  if (!schema) {
    return yup.object({});
  }

  return yup.object(
    Object.fromEntries(
      fieldNames.map((fieldName) => [
        fieldName,
        getFieldValidator(schema, fieldNameMap[fieldName]),
      ]),
    ),
  );
};

export const getFieldNameFieldMap = (
  schema?: HubSpotSchemaResponse['hubspotSchema'],
  dealPipeline?: HubSpotDealPipeline,
): HubSpotFieldNameFieldMap => {
  if (!schema || !schema.properties) {
    return {};
  }

  return Object.fromEntries(
    schema.properties.map((p) => {
      if (p.name === 'dealstage' && dealPipeline) {
        return [
          p.name,
          {
            ...p,
            options: dealPipeline.stages?.map((stage) => ({
              label: stage.label,
              value: stage.id,
              displayOrder: stage.displayOrder,
              hidden: stage.archived,
            })),
          },
        ];
      }
      return [p.name, p];
    }),
  );
};

export const getFieldValidator = (
  schema: HubSpotSchemaResponse['hubspotSchema'],
  property?: HubSpotProperty,
) => {
  if (!property) {
    return emptyValidator();
  }

  switch (property.type) {
    case 'number':
      if (property.showCurrencySymbol) {
        return localizedFloatValidator(
          property.label,
          !schema.requiredProperties?.includes(property.name),
        );
      }
      return integerValidator(
        property.label,
        !schema.requiredProperties?.includes(property.name),
      );
    case 'datetime':
      return dateTimeValidator(
        property.label,
        !schema.requiredProperties?.includes(property.name),
      );
    case 'bool':
      return booleanValidator(
        property.label,
        !schema.requiredProperties?.includes(property.name),
      );
    case 'enumeration': {
      return enumerationValidator(
        property.label,
        property.options?.map((o) => o.value),
        !schema.requiredProperties?.includes(property.name),
      );
    }
    case 'string':
    default: {
      return stringValidator(
        property.label,
        !schema.requiredProperties?.includes(property.name),
      );
    }
  }
};

export const getDefaultState = (
  schema: HubSpotSchemaResponse['hubspotSchema'],
  fieldNames: string[],
  fieldNameMap?: HubSpotFieldNameFieldMap,
) => {
  return Object.fromEntries(
    fieldNames.map((fieldName) => [
      fieldName,
      getDefaultFieldValue(schema, fieldNameMap?.[fieldName]),
    ]),
  );
};

export const getDefaultFieldValue = (
  schema: HubSpotSchemaResponse['hubspotSchema'],
  property?: HubSpotProperty,
): string | number | boolean | null | undefined => {
  if (!property) {
    return undefined;
  }

  if (!schema.requiredProperties?.includes(property.name)) {
    return null;
  }

  switch (property.type) {
    case 'number':
      return 0;
    case 'bool':
      return true;
    case 'datetime':
      return DateTime.now().toISO();
    default:
      return '';
  }
};
