import { IDropdownOption, NeutralColors } from '@fluentui/react';
import { AutomaticallyShareRecordingsPreference, MeetingflowVisibility } from '@meetingflow/common/Api/data-contracts';
import { MEETINGFLOW_COLORS } from './Themes/Themes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EMPTY_ARRAY = Object.freeze([] as any[]) as any[];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EMPTY_OBJECT = Object.freeze({} as any) as any;

export const DISMISSED_HAPPENING_SOON_EVENT_IDS =
  'DISMISSED_HAPPENING_SOON_EVENT_IDS';

export const GOOGLE_CAL_DOMAINS = [
  'calendar.google.com',
  'group.calendar.google.com',
  'resource.calendar.google.com',
];

export const VISIBILITY_OPTIONS: IDropdownOption<MeetingflowVisibility>[] = [
  { key: 'ORGANIZATION', text: 'Workspace', data: 'ORGANIZATION' },
  { key: 'LIMITED', text: 'Limited', data: 'LIMITED' },
];

export const AUTO_SHARE_RECORDINGS_OPTIONS: IDropdownOption<AutomaticallyShareRecordingsPreference>[] = [
  { key: 'ENABLED', text: 'On (Automatically email recording link to all event attendees)', data: 'ENABLED' },
  { key: 'DISABLED', text: 'Off (Do not automatically email link to recordings)', data: 'DISABLED' },
];

export const RECORDING_VISIBILITY_OPTIONS: IDropdownOption<{ key: string, text:string }>[] = [
  { key: 'ATTENDEE_DOMAINS', text: 'Anyone At Attendees\' Email Domains' },
  { key: 'ATTENDEES_ONLY', text: 'Only Attendees' },
  { key: 'ALLOWLIST_ONLY', text: 'No one' },
]

export const ROLE_ICONS = {
  ADMIN: 'Signin',
  CREATOR: 'EditCreate',
  COLLABORATOR: 'ReadingMode',
  GUEST: 'PageShared',
};

export const PRIORITY_COLORS = {
  '3': MEETINGFLOW_COLORS.orange,
  '2': MEETINGFLOW_COLORS.orange, // UNUSED
  '1': NeutralColors.gray90,
};

export const PRIORITY_COLORS_DARK = {
  '3': MEETINGFLOW_COLORS.orange,
  '2': MEETINGFLOW_COLORS.orange, // UNUSED
  '1': NeutralColors.gray160,
};
