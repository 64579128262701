import {
  Panel,
  PanelType,
  Text,
  Stack,
  TextField,
  Dropdown,
} from '@fluentui/react';
import {
  dealRoomActionItemStatusOptions,
  dealRoomActionItemStatusValues,
  isSameDueDate,
} from './milestonesUtils';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MilestoneDatePicker } from './MilestoneDatePicker';
import { MilestoneArtifactsTable } from './MilestoneArtifactsTable';
import { useDeleteTaskDialog } from '../../../Hooks/Modals/DealRoom/useDeleteTaskDialog';
import {
  DealRoomMilestone,
  DealRoomMilestoneType,
  DealRoomActionItemStatus,
  DealRoomActionItem,
} from '@meetingflow/common/Api/data-contracts';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import toast from 'react-hot-toast';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { useAuth0 } from '@auth0/auth0-react';
import { useMutualPlan } from '../../../Hooks/useMutualPlan';
import { useMilestonesSummary } from '../../../Hooks/useMilestonesSummary';
import { useAddAttachmentDialog } from '../../../Hooks/Modals/DealRoom/useAddAttachmentDialog';
import { useFulfillRequestAttachment } from '../../../Hooks/Modals/DealRoom/useFulfillRequestAttachment';
import { useUserProfile } from '../../../Hooks/useProfile';
import { dropdownMenuStyles } from './styles';
import { useMilestoneActionItemPanelStyles } from '../../../Hooks/styles/useMilestoneActionItemPanelStyles';
import { ActionItemPanelFooter } from './ActionItemPanelFooter';
import { ActionItemPanelHeader } from './ActionItemPanelHeader';

type MilestoneActionItemPanelProps = {
  isOpen: boolean;
  onDismiss: () => void;
  currentActionItemId: number | null;
  organizationSlug: string;
  dealRoomId: number;
  parentMilestone: DealRoomMilestone | null;
  isEditing: boolean;
  handleUpdateSelectedMilestoneBasedOnType: (
    milestoneType: DealRoomMilestoneType,
  ) => void;
};

export const MilestoneActionItemPanel = ({
  isOpen,
  onDismiss,
  currentActionItemId,
  organizationSlug,
  dealRoomId,
  parentMilestone,
  isEditing,
  handleUpdateSelectedMilestoneBasedOnType,
}: MilestoneActionItemPanelProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const wasInitialValuesSelected = useRef(false);
  const oldTitle = useRef('');
  const oldDescription = useRef('');
  const oldMilestoneType = useRef('');
  const oldOwnerId = useRef(-1);
  const oldStatus = useRef(dealRoomActionItemStatusValues.notStarted.key);
  const oldDueDate = useRef<string | null>(new Date().toISOString());

  const [actionItem, setActionItem] = useState<DealRoomActionItem | null>(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState<DealRoomActionItemStatus>(
    dealRoomActionItemStatusValues.notStarted.key,
  );
  const [selectedMilestoneType, setSelectedMilestoneType] =
    useState<DealRoomMilestoneType>(
      parentMilestone?.type || '',
    );
  const [dueDate, setDueDate] = useState<string | null>(
    parentMilestone?.dueDate
      ? parentMilestone.dueDate
      : new Date().toISOString(),
  );
  const [ownerId, setOwnerId] = useState<number | null>(null);

  // get data for all UI and the refetch functions to refetch data from the other components
  const { userId, user: mfUser } = useUserProfile();
  const { dealRoom } = useDealRoom(organizationSlug, dealRoomId);
  const { mutualPlan, refetch: refetchMutualPlan } = useMutualPlan(
    organizationSlug,
    dealRoomId,
  );
  const { milestonesSummary, refetch: refetchMilestonesSummary } =
    useMilestonesSummary(organizationSlug, dealRoomId);

  // used to show the AddAttachmentDialog component when the user clicks on the
  // "Add Attachment" button
  const { createDeferred: addAttachmentDeferred, dialog: addAttachmentDialog } =
    useAddAttachmentDialog({
      organizationSlug,
      dealRoomId,
      actionItemId: actionItem?.id || null,
    });

  // used to show the DeleteTaskDialog component when the user clicks on the
  // "Delete task" button
  const { createDeferred: deleteTaskDeferred, dialog: deleteTaskDialog } =
    useDeleteTaskDialog({
      organizationSlug,
      dealRoomId,
      taskId: actionItem?.id || null,
    });

  // used to show the FulfillRequestAttachmentDialog component when the user
  // clicks on the "Fulfill Request for Artifact" button
  const {
    createDeferred: fulfillRequestAttachmentDeferred,
    dialog: fulfillRequestAttachmentDialog,
  } = useFulfillRequestAttachment({
    organizationSlug,
    dealRoomId,
    actionItemId: actionItem?.id || null,
    ownerName:
      actionItem?.creator?.firstName ||
      actionItem?.creator?.name ||
      'A contact',
    description: actionItem?.description || 'Please upload the specific file',
  });

  const handleSetTaskInfo = useCallback(
    async (actionItemId: number | null, callback?: () => void) => {
      try {
        if (!parentMilestone) return;

        if (!actionItemId) {
          setDueDate(parentMilestone?.dueDate || new Date().toISOString());
          setSelectedMilestoneType(
            parentMilestone?.type || '',
          );
          if (callback) callback();
          return;
        }

        const token = await getAccessTokenSilently();
        const { data: response } = await DealRoomsApiClient.getActionItem(
          organizationSlug,
          dealRoomId,
          actionItemId,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        if (!response) return;
        setActionItem(response);
        setTitle(response.actionItem || '');
        setDescription(response.description || '');
        setStatus(
          response.status || dealRoomActionItemStatusValues.notStarted.key,
        );
        const currentDueDate = response.dueDate || null;
        setDueDate(currentDueDate);
        setOwnerId(response.assignee?.id || null);
        setSelectedMilestoneType(response.milestone?.type || '');

        // set initial states
        oldTitle.current = response.actionItem || '';
        oldDescription.current = response.description || '';
        oldOwnerId.current = response.assignee?.id || -1;
        oldMilestoneType.current = response.milestone?.type || '';
        oldStatus.current =
          response.status || dealRoomActionItemStatusValues.notStarted.key;
        oldDueDate.current = currentDueDate;
        if (callback) callback();
      } catch (error) {}
    },
    [dealRoomId, getAccessTokenSilently, organizationSlug, parentMilestone],
  );

  useEffect(() => {
    // Reset the wasInitialValuesSelected every time we close the panel
    if (!isOpen) {
      wasInitialValuesSelected.current = false;
    }
  }, [isOpen]);

  useEffect(() => {
    if (wasInitialValuesSelected.current) return;

    // Set the initial values only if we have the data needed for this action
    const canSetTheInitialValues = isEditing
      ? currentActionItemId !== null && !!parentMilestone
      : !!parentMilestone;

    if (!canSetTheInitialValues) return;

    handleSetTaskInfo(currentActionItemId, () => {
      // Set the wasInitialValuesSelected to true so we don't do the handleSetTaskInfo again
      wasInitialValuesSelected.current = true;
    }).then();
  }, [
    actionItem,
    currentActionItemId,
    handleSetTaskInfo,
    isEditing,
    parentMilestone,
  ]);

  // Handles deleting a task from header but the UI from ActionItemPanelHeader components is commented out for now
  const handleClickDeleteTask = useCallback(async () => {
    try {
      await deleteTaskDeferred().promise;
      onDismiss();
    } catch (err) {}
  }, [deleteTaskDeferred, onDismiss]);

  // Handles requesting an attachment, ignoring any errors for now
  const handleAddAttachment = useCallback(async () => {
    try {
      await addAttachmentDeferred().promise;
      await handleSetTaskInfo(actionItem?.id || null);
    } catch (err) {}
  }, [actionItem?.id, addAttachmentDeferred, handleSetTaskInfo]);

  // Handles fulfilling an attachment request, ignoring any errors for now
  const handleClickFulfillRequestAttachment = useCallback(async () => {
    try {
      await fulfillRequestAttachmentDeferred().promise;
      await handleSetTaskInfo(actionItem?.id || null);
    } catch (err) {}
  }, [actionItem?.id, fulfillRequestAttachmentDeferred, handleSetTaskInfo]);

  const handleCreateTask = useCallback(async () => {
    try {
      if (!parentMilestone) return;
      const token = await getAccessTokenSilently();
      await toast.promise(
        DealRoomsApiClient.createMilestoneActionItem(
          organizationSlug,
          dealRoomId,
          parentMilestone?.type,
          {
            actionItem: title,
            dueDate,
            ...(ownerId
              ? {
                  assigneeId: ownerId,
                }
              : {}),
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        ),
        {
          loading: 'Creating task',
          success: (result) => {
            const createTaskResponse = result?.data;
            if (
              createTaskResponse &&
              'id' in createTaskResponse &&
              'milestone' in createTaskResponse &&
              createTaskResponse.milestone &&
              'type' in createTaskResponse.milestone
            ) {
              setActionItem(createTaskResponse);
            }
            // Refetch the milestones summary and mutual plan after creating a task
            // This makes sure the task is visible in all components that use the milestones and tasks data
            Promise.all([refetchMilestonesSummary(), refetchMutualPlan()]);
            return 'Successfully created the task';
          },
          error: 'Something went wrong creating the task',
        },
      );
    } catch (error) {}
  }, [
    dealRoomId,
    dueDate,
    getAccessTokenSilently,
    organizationSlug,
    ownerId,
    parentMilestone,
    refetchMilestonesSummary,
    refetchMutualPlan,
    title,
  ]);

  const handleUpdateTask = useCallback(
    async (dataToBeSent: Object) => {
      if (!Object.keys(dataToBeSent).length || !actionItem) return;

      try {
        const token = await getAccessTokenSilently();
        await toast.promise(
          DealRoomsApiClient.updateActionItem(
            organizationSlug,
            dealRoomId,
            actionItem.id,
            dataToBeSent,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          ),
          {
            loading: 'Updating task',
            success: (result) => {
              // Refetch the milestones summary and mutual plan after updating a task
              // This makes sure the task updates are visible in all components that use the milestones and tasks data
              Promise.all([refetchMilestonesSummary(), refetchMutualPlan()]);
              return 'Successfully updated the task';
            },
            error: 'Something went wrong updating the task',
          },
        );
      } catch (err) {}
    },
    [
      actionItem,
      dealRoomId,
      getAccessTokenSilently,
      organizationSlug,
      refetchMilestonesSummary,
      refetchMutualPlan,
    ],
  );

  const handleOnBlurName = useCallback(async () => {
    if (!title) return;

    if (actionItem) {
      // don't call the function with the same value
      if (title === oldTitle.current) return;
      oldTitle.current = title;
      await handleUpdateTask({ actionItem: title });
    } else {
      await handleCreateTask();
    }
  }, [actionItem, handleCreateTask, handleUpdateTask, title]);

  const handleOnBlurDescription = useCallback(async () => {
    if (!actionItem) return;

    // don't call the function with the same value
    if (description === oldDescription.current) return;
    oldDescription.current = description;

    try {
      const token = await getAccessTokenSilently();
      await toast.promise(
        DealRoomsApiClient.updateActionItemDescription(
          organizationSlug,
          dealRoomId,
          actionItem.id,
          { description },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        ),
        {
          loading: 'Updating task',
          success: (result) => {
            // Refetch the mutual plan after updating a task
            // This makes sure the task updates are visible in all components that use the milestones and tasks data
            refetchMutualPlan().then();
            return 'Successfully updated the task';
          },
          error: 'Something went wrong updating the task',
        },
      );
    } catch (err) {}
  }, [
    actionItem,
    dealRoomId,
    description,
    getAccessTokenSilently,
    organizationSlug,
    refetchMutualPlan,
  ]);

  const handleUpdateDueDate = useCallback(
    async (newDate: Date | null | undefined) => {
      if (
        !newDate ||
        (oldDueDate.current &&
          isSameDueDate(newDate.toISOString(), oldDueDate.current))
      )
        return;

      setDueDate(newDate.toISOString());
      oldDueDate.current = newDate.toISOString();

      await handleUpdateTask({ dueDate: newDate.toISOString() });
    },
    [handleUpdateTask],
  );

  const handleUpdateOwner = useCallback(
    async (
      newOwnerId: number | undefined,
      newOwnerIdentifier: string | undefined,
    ) => {
      if (
        typeof newOwnerId !== 'number' ||
        isNaN(newOwnerId) ||
        typeof newOwnerIdentifier !== 'string' ||
        newOwnerId === oldOwnerId.current
      ) {
        return toast.error(
          `${newOwnerIdentifier} is not yet a user of this Decision Site. They must sign into Decision Site before they can be assigned a task.`,
        );
      }

      setOwnerId(newOwnerId);
      oldOwnerId.current = newOwnerId;
      if (!actionItem) return;
      await handleUpdateTask({ assigneeId: +newOwnerId });
    },
    [actionItem, handleUpdateTask],
  );

  const handleUpdateStatus = useCallback(
    async (newStatus: string | number | undefined) => {
      if (typeof newStatus !== 'string' || newStatus === oldStatus.current)
        return;
      setStatus(newStatus as DealRoomActionItemStatus);
      oldStatus.current = newStatus as DealRoomActionItemStatus;
      if (!actionItem) return;
      try {
        const token = await getAccessTokenSilently();
        await toast.promise(
          DealRoomsApiClient.updateActionItemStatus(
            organizationSlug,
            dealRoomId,
            actionItem.id,
            { status: newStatus as DealRoomActionItemStatus },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          ),
          {
            loading: 'Updating task',
            success: (result) => {
              // Refetch the milestones summary mutual plan after updating a task
              // This makes sure the task updates are visible in all components that use the milestones and tasks data
              Promise.all([refetchMilestonesSummary(), refetchMutualPlan()]);
              return 'Successfully updated the task';
            },
            error: 'Something went wrong updating the task',
          },
        );
      } catch (err) {}
    },
    [
      actionItem,
      dealRoomId,
      getAccessTokenSilently,
      organizationSlug,
      refetchMilestonesSummary,
      refetchMutualPlan,
    ],
  );

  const handleUpdateTaskMilestone = useCallback(
    async (newMilestoneType: string | number | undefined) => {
      if (
        typeof newMilestoneType !== 'string' ||
        newMilestoneType === oldMilestoneType.current
      )
        return;
      setSelectedMilestoneType(newMilestoneType as DealRoomMilestoneType);
      oldMilestoneType.current = newMilestoneType as DealRoomMilestoneType;
      if (!actionItem) return;
      try {
        const token = await getAccessTokenSilently();
        await toast.promise(
          DealRoomsApiClient.updateActionItemMilestone(
            organizationSlug,
            dealRoomId,
            actionItem.id,
            { milestone: newMilestoneType as DealRoomMilestoneType },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          ),
          {
            loading: 'Updating task',
            success: (result) => {
              // Refetch the milestones summary mutual plan after updating a task
              // This makes sure the task updates are visible in all components that use the milestones and tasks data
              Promise.all([
                refetchMilestonesSummary(),
                refetchMutualPlan(),
              ]).then((response) => {
                handleUpdateSelectedMilestoneBasedOnType(
                  newMilestoneType as DealRoomMilestoneType,
                );
              });
              return 'Successfully updated the task';
            },
            error: 'Something went wrong updating the task',
          },
        );
      } catch (err) {}
    },
    [
      actionItem,
      dealRoomId,
      getAccessTokenSilently,
      handleUpdateSelectedMilestoneBasedOnType,
      organizationSlug,
      refetchMilestonesSummary,
      refetchMutualPlan,
    ],
  );

  const handleClickDeleteArtifact = useCallback(
    async (artifactId: number) => {
      if (actionItem === null) return;
      try {
        const token = await getAccessTokenSilently();
        await toast.promise(
          DealRoomsApiClient.deleteActionItemArtifact(
            organizationSlug,
            dealRoomId,
            actionItem.id,
            artifactId,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          ),
          {
            loading: 'Deleting attachment',
            success: () => {
              // Refetch the mutual plan and task details after updating a task
              // This makes sure the task updates are visible in all components that use the milestones and tasks data
              Promise.all([
                refetchMutualPlan(),
                handleSetTaskInfo(actionItem?.id || null),
              ]);
              return 'Successfully deleted the attachment';
            },
            error: 'Something went wrong deleting the attachment',
          },
        );
      } catch (err) {}
    },
    [
      actionItem,
      dealRoomId,
      getAccessTokenSilently,
      handleSetTaskInfo,
      organizationSlug,
      refetchMutualPlan,
    ],
  );

  // The list of contacts for assigning tasks
  const dealRoomContactsOptions = useMemo(() => {
    if (!Array.isArray(dealRoom?.contacts)) return [];
    return dealRoom.contacts.map((contact) => ({
      key: contact.userId?.toString() || `${contact.email}-disabled`,
      text: `${contact.name} (${contact.email})`,
      hidden: !contact.userId, // Hide all contacts who are not users and can not yet be assigned a task. FYI, tried to make these disabled with the `disabled` prop, but it didn't work.
    }));
  }, [dealRoom?.contacts]);

  // Use only visible milestone types for the dropdown

  const milestones = useMemo(() => {
    if (!mutualPlan) return [];
    return mutualPlan.milestones;
  }, [mutualPlan]);

  const milestoneOptions = useMemo(() => {
    if (!milestones) return [];
    return milestones
      .filter((milestone) => milestone.visible)
      .map((milestone) => ({
        key: milestone.type,
        text: milestone.type,
      }));
  }, [milestones]);

  const shouldShowFulFillAttachmentButton = useMemo(() => {
    // Determine if the "Fulfill Attachment" button should be shown
    // The button is shown when:
    // 1. The current user (mfUser) exists and has an assigned task (actionItem).
    // 2. The task has an assignee.
    // 3. The current user's email matches the assignee's email, and the task status is 'DOC_REQUESTED'.
    if (!mfUser || !actionItem) return false;

    if (!actionItem.assignee) return false;

    return (
      mfUser.email.toLowerCase() === actionItem.assignee.email.toLowerCase() &&
      actionItem.status === 'DOC_REQUESTED'
    );
  }, [actionItem, mfUser]);

  // Send status in useMilestoneActionItemPanelStyles in order to generate the correct background color for action item status container
  const styles = useMilestoneActionItemPanelStyles(status);

  return (
    <Panel
      className={styles.panel}
      isOpen={isOpen}
      onDismiss={onDismiss}
      type={PanelType.medium}
      headerText={''} // Empty header text since we'll implement custom header later
      isLightDismiss // Allows clicking outside panel to dismiss
      onRenderNavigation={() => null}
    >
      {/* Temporary placeholder content - to be replaced with action item details */}
      <Stack className={styles.wrapper}>
        <ActionItemPanelHeader
          actionItemId={actionItem?.id || null}
          organizationSlug={organizationSlug}
          dealRoomId={dealRoomId}
          onClickCloseButton={onDismiss}
          handleClickDeleteTask={handleClickDeleteTask}
        />
        <div className={styles.content}>
          <TextField
            value={title}
            className={styles.taskTitle}
            multiline
            resizable={false}
            placeholder="Task name..."
            onChange={(e, newValue) => {
              setTitle(newValue || '');
            }}
            onBlur={handleOnBlurName}
          />
          <div className={styles.dropdownsSection}>
            <div className={styles.dropdownContainer}>
              <Text className={styles.dropdownTitle}>Owner</Text>
              <Dropdown
                options={dealRoomContactsOptions}
                placeholder="Select owner"
                className={styles.dropdown}
                onChange={async (ev, currentOption) => {
                  await handleUpdateOwner(
                    currentOption?.key ? +currentOption.key : undefined,
                    currentOption?.text,
                  );
                }}
                selectedKey={ownerId?.toString()}
                styles={dropdownMenuStyles}
              />
            </div>
            <div className={styles.dropdownContainer}>
              <Text className={styles.dropdownTitle}>Due Date</Text>
              <MilestoneDatePicker
                className={styles.dueDateElement}
                hideTitle={true}
                value={dueDate}
                onChange={handleUpdateDueDate}
              />
            </div>
            <div className={styles.dropdownContainer}>
              <Text className={styles.dropdownTitle}>Status</Text>
              <Dropdown
                className={styles.statusDropdown}
                options={dealRoomActionItemStatusOptions}
                selectedKey={status}
                onChange={async (ev, currentOption) => {
                  await handleUpdateStatus(currentOption?.key);
                }}
                disabled={!actionItem}
                styles={dropdownMenuStyles}
              />
            </div>
            <div className={styles.dropdownContainer}>
              <Text className={styles.dropdownTitle}>Milestone</Text>
              <Dropdown
                options={milestoneOptions}
                placeholder="Select milestone"
                className={styles.dropdown}
                selectedKey={selectedMilestoneType}
                onChange={async (ev, currentOption) => {
                  await handleUpdateTaskMilestone(currentOption?.key);
                }}
                disabled={!actionItem}
                styles={dropdownMenuStyles}
              />
            </div>
          </div>
          <TextField
            value={description}
            className={styles.taskDescription}
            label="Description"
            multiline
            placeholder="Enter task description..."
            disabled={!actionItem}
            resizable={false}
            onChange={(e, newValue) => {
              setDescription(newValue || '');
            }}
            onBlur={handleOnBlurDescription}
          />
          {actionItem &&
            Array.isArray(actionItem.artifacts) &&
            actionItem.artifacts.length > 0 && (
              <div className={styles.attachmentsContainer}>
                <Text className={styles.attachmentsContainerTitle}>
                  Artifacts
                </Text>
                <MilestoneArtifactsTable
                  artifacts={actionItem.artifacts}
                  organizationSlug={organizationSlug}
                  dealRoomId={dealRoomId}
                  handleClickDeleteArtifact={handleClickDeleteArtifact}
                />
              </div>
            )}
        </div>
        <ActionItemPanelFooter
          showFooter={!!actionItem}
          shouldShowFulFillAttachmentButton={shouldShowFulFillAttachmentButton}
          handleClickFulfillRequestAttachment={
            handleClickFulfillRequestAttachment
          }
          handleAddAttachment={handleAddAttachment}
        />
        {/*render dialog containers used in the action item panel*/}
        {deleteTaskDialog}
        {addAttachmentDialog}
        {fulfillRequestAttachmentDialog}
      </Stack>
    </Panel>
  );
};
