export const ResourceNotFoundIllustration = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      width="808.84067"
      height="681.8936"
      viewBox="0 0 808.84067 681.8936"
    >
      <path
        d="M866.24553,659.31387,597.90045,536.80922,736.07526,234.13885l268.34507,122.50466Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#f2f2f2"
      />
      <path
        d="M907.12341,483.47827l-203.14462-92.7394a1.58594,1.58594,0,0,1,1.31725-2.88543l203.14462,92.7394a1.58594,1.58594,0,0,1-1.31725,2.88543Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#fff"
      />
      <path
        d="M918.41962,458.73405,715.275,365.99465a1.58594,1.58594,0,1,1,1.31725-2.88543l203.14462,92.7394a1.58594,1.58594,0,1,1-1.31725,2.88543Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#fff"
      />
      <path
        d="M929.71583,433.98984,726.57121,341.25043a1.58594,1.58594,0,1,1,1.31725-2.88543l203.14462,92.7394a1.58595,1.58595,0,0,1-1.31725,2.88544Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#fff"
      />
      <path
        d="M941.012,409.24562,737.86742,316.50621a1.58594,1.58594,0,1,1,1.31725-2.88543l203.14462,92.73941a1.58594,1.58594,0,0,1-1.31725,2.88543Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#fff"
      />
      <path
        d="M896.91469,505.84036,693.77007,413.101a1.58594,1.58594,0,1,1,1.31725-2.88543l203.14462,92.7394a1.58594,1.58594,0,0,1-1.31725,2.88543Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#fff"
      />
      <path
        d="M930.83742,372.9484,772.15573,300.50717a3.9649,3.9649,0,0,1-2.22982-4.77891,3.816,3.816,0,0,1,5.20033-2.25249L933.75967,365.895a4.00065,4.00065,0,0,1,2.261,4.85011A3.81579,3.81579,0,0,1,930.83742,372.9484Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#fff"
      />
      <path
        d="M320.27065,356.64351,588.61572,234.13885,726.79053,536.80922,458.44546,659.31387Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#f2f2f2"
      />
      <path
        d="M417.56758,483.47827l203.14461-92.7394a1.58594,1.58594,0,1,0-1.31725-2.88543l-203.14462,92.7394a1.58594,1.58594,0,1,0,1.31726,2.88543Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#fff"
      />
      <path
        d="M406.27137,458.73405,609.416,365.99465a1.58594,1.58594,0,0,0-1.31726-2.88543l-203.14462,92.7394a1.58594,1.58594,0,1,0,1.31726,2.88543Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#fff"
      />
      <path
        d="M394.97516,433.98984l203.14462-92.73941a1.58594,1.58594,0,0,0-1.31726-2.88543L393.6579,431.1044a1.58595,1.58595,0,0,0,1.31726,2.88544Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#fff"
      />
      <path
        d="M383.679,409.24562l203.14462-92.73941a1.58594,1.58594,0,1,0-1.31726-2.88543L382.36169,406.36019a1.58594,1.58594,0,0,0,1.31726,2.88543Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#fff"
      />
      <path
        d="M427.7763,505.84036,630.92092,413.101a1.58594,1.58594,0,0,0-1.31726-2.88543L426.459,502.95493a1.58594,1.58594,0,1,0,1.31726,2.88543Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#fff"
      />
      <path
        d="M393.85356,372.9484l158.68169-72.44123a3.96488,3.96488,0,0,0,2.22982-4.77891,3.816,3.816,0,0,0-5.20032-2.25249L390.93131,365.895a4.00067,4.00067,0,0,0-2.261,4.85011A3.81579,3.81579,0,0,0,393.85356,372.9484Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#fff"
      />
      <path
        d="M222.40481,714.03126c1.69423-26.24429,16.317-52.871,40.65983-62.82314a121.16927,121.16927,0,0,0,4.93287,83.04447c4.43053,10.10062,10.41006,20.87363,7.43637,31.49487-1.85017,6.60882-7.02235,11.888-12.94859,15.34869-5.92661,3.46069-12.6203,5.33966-19.23087,7.18405l-1.25323,1.16816C229.9466,766.07489,220.71058,740.27555,222.40481,714.03126Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#f0f0f0"
      />
      <path
        d="M263.34042,651.68609a103.56706,103.56706,0,0,0-22.24942,59.71,44.59937,44.59937,0,0,0,1.33347,13.91012,25.57977,25.57977,0,0,0,7.05351,11.46684c3.04906,2.97518,6.51244,5.66536,8.78518,9.32991a15.66257,15.66257,0,0,1,1.52111,12.71858c-1.50058,5.29374-4.81379,9.73259-8.20469,13.96082-3.76494,4.69464-7.74992,9.51126-9.11223,15.51981-.16506.728-1.28505.42933-1.12024-.29759,2.37018-10.45385,12.00015-16.88329,16.20867-26.422,1.96377-4.45092,2.5981-9.52061.24636-13.9565-2.05648-3.879-5.62559-6.6562-8.73886-9.64195a27.29558,27.29558,0,0,1-7.31456-10.95513,41.23112,41.23112,0,0,1-1.86335-13.80753,100.47854,100.47854,0,0,1,5.51711-30.9156,105.40343,105.40343,0,0,1,17.12084-31.44219c.46185-.58333,1.27593.24285.8171.82236Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#fff"
      />
      <path
        d="M240.987,704.37553a15.53836,15.53836,0,0,1-12.771-15.548.58005.58005,0,0,1,1.15934-.01084,14.38817,14.38817,0,0,0,11.90924,14.43856c.73455.12924.43276,1.24874-.29759,1.12023Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#fff"
      />
      <path
        d="M247.73331,735.46815a29.94891,29.94891,0,0,0,12.329-18.01017c.16764-.72741,1.28767-.42894,1.12024.29759A31.15139,31.15139,0,0,1,248.315,736.47071c-.619.41865-1.19736-.58618-.5817-1.00256Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#fff"
      />
      <path
        d="M250.4132,671.958a8.79547,8.79547,0,0,0,8.29544-.916c.61341-.42629,1.19112.579.5817,1.00255a9.856,9.856,0,0,1-9.17473,1.0337.599.599,0,0,1-.41132-.70892.58248.58248,0,0,1,.70891-.41132Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#fff"
      />
      <path
        d="M341.5588,693.24718c-.37545.27708-.75091.55416-1.12626.841A115.83119,115.83119,0,0,0,326.333,706.50791c-.34231.34372-.684.69721-1.01576,1.05a122.11237,122.11237,0,0,0-24.32369,37.80982,118.58674,118.58674,0,0,0-5.489,17.16153c-1.92168,8.08117-3.36229,16.99612-7.71329,23.77108a20.34637,20.34637,0,0,1-1.46623,2.04693l-42.80152,2.53933c-.10017-.04305-.20024-.07635-.30088-.11936l-1.7043.17952c.05071-.30679.10989-.6239.16059-.9307.02867-.17809.06688-.35674.09555-.53483.02214-.11891.04474-.2379.05792-.34646.00722-.03961.01492-.07924.02271-.10913.01318-.10857.037-.208.05071-.3068q.48887-2.66019,1.01683-5.32276c-.00058-.00978-.00058-.00978.00838-.02012,2.71642-13.51815,6.58525-26.9283,12.36649-39.32491.17406-.37291.34707-.75556.54068-1.12963a113.15757,113.15757,0,0,1,9.23324-15.99213,100.03668,100.03668,0,0,1,6.19893-8.00172,83.18731,83.18731,0,0,1,19.85692-16.7988c14.87044-9.016,32.45882-13.17575,49.1586-9.19806C340.713,693.03281,341.13108,693.13535,341.5588,693.24718Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#f0f0f0"
      />
      <path
        d="M341.49481,693.79613a103.56714,103.56714,0,0,0-53.71447,34.27942,44.59967,44.59967,0,0,0-7.31015,11.90929,25.57987,25.57987,0,0,0-1.272,13.40234c.64324,4.21125,1.78888,8.44441,1.39723,12.73869a15.66261,15.66261,0,0,1-6.44294,11.07089c-4.38533,3.3233-9.70324,4.87269-14.95637,6.20714-5.83259,1.48165-11.91431,2.92822-16.6196,6.90551-.57011.4819-1.28453-.4309-.71528-.91207,8.18639-6.9198,19.74636-6.25544,28.84956-11.33772,4.24772-2.37149,7.8065-6.03745,8.59949-10.99517.69343-4.33531-.48423-8.70159-1.17237-12.95995a27.29557,27.29557,0,0,1,.75547-13.15093,41.2313,41.2313,0,0,1,6.82531-12.1464,100.47866,100.47866,0,0,1,23.01843-21.3627,105.40368,105.40368,0,0,1,32.6004-14.7969c.72-.18769.87255.9621.15729,1.14856Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#fff"
      />
      <path
        d="M291.92419,722.40742a15.53837,15.53837,0,0,1-.836-20.10319c.46083-.58635,1.39363.10222.93219.68934a14.38819,14.38819,0,0,0,.81585,18.69857c.5087.54544-.40628,1.2576-.91207.71528Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#fff"
      />
      <path
        d="M278.59082,751.29486a29.94894,29.94894,0,0,0,20.68738-6.9572c.5718-.47987,1.28639.43278.71528.91207a31.15143,31.15143,0,0,1-21.54181,7.19584c-.74632-.03843-.60311-1.18893.13915-1.15071Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#fff"
      />
      <path
        d="M318.96807,702.199a8.79545,8.79545,0,0,0,7.17495,4.263c.74643.029.60242,1.17947-.13915,1.15071a9.856,9.856,0,0,1-7.94787-4.69847.599.599,0,0,1,.0984-.81367.58246.58246,0,0,1,.81367.09839Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#fff"
      />
      <path
        d="M861.997,661.85822H460.74089V209.2756H861.997Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#fff"
      />
      <path
        d="M861.997,661.85822H460.74089V209.2756H861.997Zm-399.09877-2.15729H859.83966v-448.268H462.89818Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#cacaca"
      />
      <path
        d="M813.24987,421.18544H509.488a2.15729,2.15729,0,1,1,0-4.31458h303.7619a2.15729,2.15729,0,0,1,0,4.31458Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#e4e4e4"
      />
      <path
        d="M813.24987,384.18544H509.488a2.15729,2.15729,0,1,1,0-4.31458h303.7619a2.15729,2.15729,0,0,1,0,4.31458Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#e4e4e4"
      />
      <path
        d="M813.24987,347.18544H509.488a2.15729,2.15729,0,1,1,0-4.31458h303.7619a2.15729,2.15729,0,0,1,0,4.31458Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#e4e4e4"
      />
      <path
        d="M813.24987,310.18544H509.488a2.15729,2.15729,0,1,1,0-4.31458h303.7619a2.15729,2.15729,0,0,1,0,4.31458Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#e4e4e4"
      />
      <path
        d="M813.24987,454.62344H509.488a2.15729,2.15729,0,1,1,0-4.31458h303.7619a2.15729,2.15729,0,0,1,0,4.31458Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#e4e4e4"
      />
      <path
        d="M813.24987,620.62344H509.488a2.15729,2.15729,0,1,1,0-4.31458h303.7619a2.15729,2.15729,0,0,1,0,4.31458Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#e4e4e4"
      />
      <path
        d="M740.48441,585.9937h-158.231a3.99339,3.99339,0,0,1-3.989-3.989V488.92762a3.99338,3.99338,0,0,1,3.989-3.989h158.231a3.99337,3.99337,0,0,1,3.989,3.989v93.07706A3.99338,3.99338,0,0,1,740.48441,585.9937Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#fff"
      />
      <path
        d="M687.64325,532.82035a8.8014,8.8014,0,0,0-12.64552-.226L630.46735,577.125a6.28787,6.28787,0,0,1-8.86881.01331l-6.59513-6.58189a8.78014,8.78014,0,0,0-12.41958,0L589.8195,583.33459H732.91833v-1.82165Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#2b2d39"
      />
      <path
        d="M740.48441,585.9937h-158.231a3.99339,3.99339,0,0,1-3.989-3.989V488.92762a3.99338,3.99338,0,0,1,3.989-3.989h158.231a3.99337,3.99337,0,0,1,3.989,3.989v93.07706A3.99338,3.99338,0,0,1,740.48441,585.9937ZM582.25342,487.598a1.3312,1.3312,0,0,0-1.32967,1.32967v93.07706a1.33119,1.33119,0,0,0,1.32967,1.32967h158.231a1.33119,1.33119,0,0,0,1.32968-1.32967V488.92762a1.3312,1.3312,0,0,0-1.32968-1.32967Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#3f3d56"
      />
      <path
        d="M359.01738,570.43562a10.05575,10.05575,0,0,0-8.67173-12.74978l-8.29088-34.75975-12.7075,13.5408,9.82137,30.9325a10.11027,10.11027,0,0,0,19.84874,3.03623Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#ffb6b6"
      />
      <path
        d="M336.85563,557.82806a4.505,4.505,0,0,1-2.70763-2.47619l-17.579-42.99751a46.37343,46.37343,0,0,1-1.64817-32.60257l11.264-35.28292a14.49652,14.49652,0,1,1,28.87239,2.642l-14.93809,56.81578,10.00513,44.62172a4.51466,4.51466,0,0,1-2.20041,4.67919l-7.41611,4.23741a4.50564,4.50564,0,0,1-2.62316.57567A4.45469,4.45469,0,0,1,336.85563,557.82806Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#e4e4e4"
      />
      <polygon
        points="203.799 669.082 216.058 669.082 221.891 621.794 203.796 621.794 203.799 669.082"
        fill="#ffb6b6"
      />
      <path
        d="M396.25093,774.13262l24.144-.001h.001a15.3873,15.3873,0,0,1,15.38647,15.38623v.5l-39.53076.00146Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#2f2e41"
      />
      <polygon
        points="134.799 669.082 147.058 669.082 152.891 621.794 134.796 621.794 134.799 669.082"
        fill="#ffb6b6"
      />
      <path
        d="M327.25093,774.13262l24.144-.001h.001a15.3873,15.3873,0,0,1,15.38647,15.38623v.5l-39.53076.00146Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#2f2e41"
      />
      <path
        d="M406.76052,539.61811l-71.89678-6.32584s-7.10322,45.32584-6.10322,63.32584,2,46,2,46l-8,122,31-4,4-101,13-55,15,70,10,90,27-3-9-109S421.76049,554.61811,406.76052,539.61811Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#2f2e41"
      />
      <circle cx="182.18086" cy="277.56493" r="20" fill="#ffb8b8" />
      <path
        d="M399.66234,431.2756l-14.28451-11.3709s-15.469-11.93613-24.54315-3.11136l-4.07412,5.82477s-23.09822,8.65749-23.09822,19.65749l1.09822,60.34251s-8,31,4,33l69.90178,5.65749-.90178-57.65749Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#e4e4e4"
      />
      <path
        d="M362.26052,404.11811s-21-15-10-33c7.63117-12.48737,23.44413-11.49893,32.414-9.72226a15.154,15.154,0,0,1,11.086,8.72226c1.5,3.5,1.5,7-4.5,7-12,0-6,9-6,9s-8,2-6,9S362.26052,404.11811,362.26052,404.11811Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#2f2e41"
      />
      <path
        d="M471.81865,536.66284a10.05576,10.05576,0,0,0-13.94451-6.58077L433.082,504.34635l-4.072,18.11774,24.16736,21.66143a10.11027,10.11027,0,0,0,18.64135-7.46268Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#ffb6b6"
      />
      <path
        d="M446.32471,537.05427a4.505,4.505,0,0,1-3.58993-.75841l-36.97666-28.11642a46.37345,46.37345,0,0,1-17.97415-27.2502l-8.21132-36.11559a14.49652,14.49652,0,1,1,26.215-12.38415l15.97959,56.5317,31.27651,33.36137a4.51469,4.51469,0,0,1,.48026,5.1484l-4.2374,7.41612a4.50564,4.50564,0,0,1-1.96754,1.82787A4.45492,4.45492,0,0,1,446.32471,537.05427Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#e4e4e4"
      />
      <path
        d="M954.23551,139.23028H376.67973a.83826.83826,0,0,1,0-1.67651H954.23551a.83826.83826,0,0,1,0,1.67651Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#cacaca"
      />
      <circle cx="199.96734" cy="9.22077" r="9.22077" fill="#3f3d56" />
      <circle cx="231.82092" cy="9.22077" r="9.22077" fill="#3f3d56" />
      <circle cx="263.67451" cy="9.22077" r="9.22077" fill="#3f3d56" />
      <path
        d="M935.15042,114.69325H912.51761a1.67651,1.67651,0,0,1,0-3.353h22.63281a1.67651,1.67651,0,0,1,0,3.353Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#3f3d56"
      />
      <path
        d="M935.15042,120.98014H912.51761a1.67651,1.67651,0,0,1,0-3.353h22.63281a1.67651,1.67651,0,0,1,0,3.353Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#3f3d56"
      />
      <path
        d="M935.15042,127.267H912.51761a1.67651,1.67651,0,0,1,0-3.353h22.63281a1.67651,1.67651,0,0,1,0,3.353Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#3f3d56"
      />
      <path
        d="M577.57967,790.2756h-381a1,1,0,1,1,0-2h381a1,1,0,0,1,0,2Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#cacaca"
      />
      <path
        d="M780.15534,271.01854H542.8788a5.39326,5.39326,0,0,1-5.45881-4.65385,5.19076,5.19076,0,0,1,5.1625-5.72493H779.78687a5.44194,5.44194,0,0,1,5.53758,4.72437A5.19046,5.19046,0,0,1,780.15534,271.01854Z"
        transform="translate(-195.57967 -109.0532)"
        fill="#2b2d39"
      />
    </svg>
  );
};
