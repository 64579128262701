import { Editor, Element, PathRef, Path } from 'slate';
import { useCallback, useEffect, useState } from 'react';
import { elementTypeToNumber } from '@meetingflow/common/SlateHelpers';

type UseCollapsibleHeadingsOptions = {
  editor?: Editor;
};

export const useCollapsibleHeadings = ({
  editor,
}: UseCollapsibleHeadingsOptions) => {
  const [collapsedHeadings, setCollapsedHeadings] = useState<PathRef[]>([]);
  const [hiddenElements, setHiddenElements] = useState<Set<Element> | null>(
    null,
  );

  const computeHiddenElements = useCallback(() => {
    if (!collapsedHeadings?.length || !editor) {
      return null;
    }

    const hiddenElements = new Set<Element>();

    const collapsedHeadingEntries = Editor.nodes<Element>(editor, {
      match: (n, p) =>
        Element.isElement(n) &&
        [
          'heading-one',
          'heading-two',
          'heading-three',
          'heading-four',
        ].includes(n.type || '') &&
        collapsedHeadings.some(
          (hp) => hp.current && Path.equals(hp.current, p),
        ),
      at: [],
    });

    for (const collapsedHeadingEntry of collapsedHeadingEntries) {
      const [element, headingPath] = collapsedHeadingEntry;
      const [collapseRangeEnd] = Editor.nodes<Element>(editor, {
        match: (n, p) =>
          Element.isElement(n) &&
          Path.isAfter(p, headingPath) &&
          elementTypeToNumber(n.type) <= elementTypeToNumber(element.type),
        at: [],
      });
      const hiddenElementEntries = Array.from(
        Editor.nodes<Element>(editor, {
          match: (n, p) =>
            Element.isElement(n) &&
            Path.isAfter(p, headingPath) &&
            (collapseRangeEnd?.[1]
              ? Path.isBefore(p, collapseRangeEnd[1])
              : true),
          at: [],
        }),
      );
      hiddenElementEntries.forEach(([element]) => hiddenElements.add(element));
    }

    return hiddenElements.size ? hiddenElements : null;
  }, [collapsedHeadings, editor]);

  const uncollapseHeading = useCallback(
    (path: Path | Path[]) =>
      setCollapsedHeadings((collapsedPaths) => {
        const updatedPaths = [];

        for (const collapsedPath of collapsedPaths) {
          if (!collapsedPath.current) {
            continue;
          } else if (
            Path.isPath(path) &&
            Path.equals(collapsedPath.current, path)
          ) {
            collapsedPath.unref();
          } else if (
            (path as Path[]).some((p) => Path.equals(p, collapsedPath.current!))
          ) {
            collapsedPath.unref();
          } else if (!!collapsedPath.current) {
            updatedPaths.push(collapsedPath);
          }
        }

        return updatedPaths;
      }),
    [],
  );

  useEffect(() => {
    setHiddenElements(computeHiddenElements);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsedHeadings]);

  useEffect(() => {
    if (!editor) {
      return;
    }

    const { onChange } = editor;

    editor.onChange = (option) => {
      switch (option?.operation?.type) {
        case 'insert_node':
        case 'merge_node':
        case 'move_node':
        case 'remove_node':
        case 'split_node':
        case 'set_node':
          setHiddenElements(computeHiddenElements);
          break;
        case undefined:
        default:
          break;
      }

      onChange?.(option);
    };

    return () => {
      editor.onChange = onChange;
    };
  }, [computeHiddenElements, editor]);

  return {
    collapsedHeadings,
    setCollapsedHeadings,
    hiddenElements,
    uncollapseHeading,
  };
};
