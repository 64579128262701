import { PrimaryButton } from '@fluentui/react';
import React from 'react';
import { AsyncPrimaryButton } from '../../../HOC/AsyncButton';
import {
  AtMentionsIcon,
  ConvSendReplyIcon,
  LinkIcon,
} from './ConvReplyIllustrations';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { useCommentsControlsStyles } from './useCommentsControlsStyles';

interface CommentsControlsProps {
  handleOpenAttachmentDialog: () => Promise<void>;
  handleMentionClick: () => void;
  handleSend: () => Promise<void>;
  inProgress?: boolean;
}

export const CommentsControls = ({
  handleOpenAttachmentDialog,
  handleMentionClick,
  handleSend,
  inProgress = false,
}: CommentsControlsProps) => {
  const styles = useCommentsControlsStyles();

  return (
    <div className={styles.writeCommentControls}>
      <div>
        <AsyncPrimaryButton
          className="convButs"
          onClick={handleOpenAttachmentDialog}
        >
          <LinkIcon />
        </AsyncPrimaryButton>

        <PrimaryButton className="convButs" onClick={handleMentionClick}>
          <AtMentionsIcon />
        </PrimaryButton>
      </div>
      <AsyncPrimaryButton
        className="convButs"
        onClick={async (e) => {
          e.stopPropagation();
          e.preventDefault();
          await handleSend();
        }}
        disabled={inProgress}
      >
        <ConvSendReplyIcon
          color={inProgress ? DEALROOMS_COLORS.lighterGray : undefined}
        />
      </AsyncPrimaryButton>
    </div>
  );
};
