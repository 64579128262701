import { useEffect } from 'react';
import { useMatch, useNavigate } from 'react-router';

export type RedirectProps = {
  to: string;
};
export const Redirect = ({ to }: RedirectProps) => {
  const navigate = useNavigate();
  const match = useMatch({
    path: '/organization/:organizationSlug',
    end: false,
  });
  const organizationSlug = match?.params.organizationSlug;

  useEffect(() => {
    if (to.includes(':organizationSlug')) {
      if (!organizationSlug) {
        throw Error(
          'Trying to replace a path with workspace slug outside of an workspace path!',
        );
      }

      navigate(to.replace(':organizationSlug', organizationSlug));
    } else {
      navigate(to);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
