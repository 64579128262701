import { useAuth0 } from '@auth0/auth0-react';
import { IconButton, useTheme } from '@fluentui/react';
import { SlateImage } from '@meetingflow/common/Types/Slate';
import { isString } from 'lodash';
import { MouseEventHandler, useCallback, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Editor, Transforms } from 'slate';
import {
  ReactEditor,
  RenderElementProps,
  useFocused,
  useSelected,
  useSlateStatic,
} from 'slate-react';
import { isMeetingflowURL } from '../../../Helpers/URLHelpers';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { ApiClient } from '../../../Services/NetworkCommon';
import brokenImage from '../../../Static/Images/brokenimage.svg';
import { OCRAnalysisResponse } from '../../../types/OCRAnalysisResponse';
import { AsyncIconButton } from '../../HOC/AsyncButton';

export const ImageElement = ({
  attributes,
  children,
  element,
}: RenderElementProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const editor = useSlateStatic();
  const [resizing, setResizing] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const { isDark } = useLightOrDarkMode();

  const theme = useTheme();

  const selected = useSelected();
  const focused = useFocused();

  const containerRef = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const topLeftDragRef = useRef<HTMLDivElement>(null);
  const topRightDragRef = useRef<HTMLDivElement>(null);
  const bottomRightDragRef = useRef<HTMLDivElement>(null);
  const bottomLeftDragRef = useRef<HTMLDivElement>(null);
  const topCenterDragRef = useRef<HTMLDivElement>(null);
  const bottomCenterDragRef = useRef<HTMLDivElement>(null);
  const leftCenterDragRef = useRef<HTMLDivElement>(null);
  const rightCenterDragRef = useRef<HTMLDivElement>(null);

  const {
    href,
    mimeType,
    width,
    height,
    objectFit,
    objectPosition,
    imageRendering,
  } = element as SlateImage;

  const [imgSrc] = useState<string | null>(
    isMeetingflowURL(href ?? '')
      ? href.replace('/api/', '/user-content/')
      : href || null,
  );

  const setImageSize = useCallback(
    (imgWidth?: string | number, imgHeight?: string | number) => {
      const path = ReactEditor.findPath(editor, element);

      if (!imgWidth && !imgHeight) {
        Transforms.unsetNodes(editor, ['width', 'height'], { at: path });
        return;
      } else if (!imgWidth) {
        Transforms.unsetNodes(editor, 'width', { at: path });
      } else if (!imgHeight) {
        Transforms.unsetNodes(editor, 'height', { at: path });
      }
      Transforms.setNodes(
        editor,
        {
          width: imgWidth
            ? isString(imgWidth)
              ? imgWidth
              : `${Math.max(20, Math.abs(imgWidth))}px`
            : undefined,
          height: imgHeight
            ? isString(imgHeight)
              ? imgHeight
              : `${Math.max(20, Math.abs(imgHeight))}px`
            : undefined,
        },
        { at: path },
      );
    },
    [editor, element],
  );

  const onDragMouseDown: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.stopPropagation();
      setResizing(true);
      return true;
    },
    [],
  );

  const onDragMouseUp: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.stopPropagation();

      if (!containerRef.current || !imgRef.current) {
        setResizing(false);
        return true;
      }

      const nativeAspectRatio =
        imgRef.current.naturalWidth / imgRef.current.naturalHeight;

      const {
        left: containerLeft,
        top: containerTop,
        right: containerRight,
        bottom: containerBottom,
        width: containerWidth,
      } = containerRef.current.getBoundingClientRect();

      const { clientX: eventClientX, clientY: eventClientY } = event;

      switch (event.target) {
        case topLeftDragRef.current: {
          const relativeX = eventClientX - containerLeft;
          const relativeY = eventClientY - containerTop;
          const aspectPreservingX = relativeY * nativeAspectRatio;
          setImageSize(
            containerWidth - Math.min(relativeX, aspectPreservingX),
            undefined,
          );
          break;
        }
        case topRightDragRef.current: {
          const relativeX = containerRight - eventClientX;
          const relativeY = eventClientY - containerTop;
          const aspectPreservingX = relativeY * nativeAspectRatio;
          setImageSize(
            containerWidth - Math.min(relativeX, aspectPreservingX),
            undefined,
          );
          break;
        }
        case bottomRightDragRef.current: {
          const relativeX = containerRight - eventClientX;
          const relativeY = containerBottom - eventClientY;
          const aspectPreservingX = relativeY * nativeAspectRatio;
          setImageSize(
            containerWidth - Math.min(relativeX, aspectPreservingX),
            undefined,
          );
          break;
        }
        case bottomLeftDragRef.current: {
          const relativeX = eventClientX - containerLeft;
          const relativeY = containerBottom - eventClientY;
          const aspectPreservingX = relativeY * nativeAspectRatio;
          setImageSize(
            containerWidth - Math.min(relativeX, aspectPreservingX),
            undefined,
          );
          break;
        }
        case topCenterDragRef.current: {
          const relativeY = eventClientY - containerTop;
          const aspectPreservingX = relativeY * nativeAspectRatio;
          setImageSize(containerWidth - aspectPreservingX, undefined);
          break;
        }
        case bottomCenterDragRef.current: {
          const relativeY = containerBottom - eventClientY;
          const aspectPreservingX = relativeY * nativeAspectRatio;
          setImageSize(containerWidth - aspectPreservingX, undefined);
          break;
        }
        case leftCenterDragRef.current: {
          const relativeX = eventClientX - containerLeft;
          setImageSize(containerWidth - relativeX, undefined);
          break;
        }
        case rightCenterDragRef.current: {
          const relativeX = containerRight - eventClientX;
          setImageSize(containerWidth - relativeX, undefined);
          break;
        }
        default: {
          break;
        }
      }

      setResizing(false);

      return true;
    },
    [setImageSize],
  );

  const onDragDoubleClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.stopPropagation();

      switch (event.target) {
        case topLeftDragRef.current:
        case topRightDragRef.current:
        case bottomRightDragRef.current:
        case bottomLeftDragRef.current:
          setImageSize(undefined, undefined);
          break;
        case topCenterDragRef.current:
        case bottomCenterDragRef.current:
          setImageSize(undefined, undefined);
          break;
        case leftCenterDragRef.current:
        case rightCenterDragRef.current:
          setImageSize(undefined, undefined);
          break;
        default: {
          break;
        }
      }

      return true;
    },
    [setImageSize],
  );

  return (
    <div {...attributes}>
      {children}
      <div
        ref={containerRef}
        contentEditable={false}
        style={{
          margin: '1rem 0',
          position: 'relative',
          width: width ?? 'fit-content',
          height: height ?? 'fit-content',
          minWidth: '20px',
          maxWidth: '100%',
          boxShadow: selected && focused ? '0 0 0 3px #B4D5FF' : 'none',
        }}
      >
        <img
          ref={imgRef}
          src={error ? brokenImage : imgSrc || undefined}
          draggable={false}
          alt=""
          onError={() => setError(true)}
          style={{
            display: 'block',
            width: error ? '10rem' : width,
            height: error ? '10rem' : height,
            maxWidth: '100%',
            objectFit: objectFit ?? 'contain',
            objectPosition: objectPosition ?? 'center',
            imageRendering,
            filter: error && isDark ? 'invert(1)' : undefined,
          }}
        />
        <div
          contentEditable={false}
          style={{
            display: selected && focused && !resizing ? 'flex' : 'none',
            position: 'absolute',
            top: '0.5rem',
            left: '0.5rem',
            color: theme.palette.themePrimary,
            border: '1px solid grey',
            backgroundColor: 'white',
          }}
          onMouseDown={(e) => e.preventDefault()}
        >
          <IconButton
            style={{
              border: '1px solid grey',
              backgroundColor: 'white',
            }}
            label="Delete image"
            text="Delete image"
            title="Delete image"
            onClick={(event) => {
              event.stopPropagation();
              Transforms.removeNodes(editor, {
                at: ReactEditor.findPath(editor, element),
              });
            }}
            iconProps={{ iconName: 'Delete' }}
          />
          {href && isMeetingflowURL(href) ? (
            <AsyncIconButton
              style={{
                border: '1px solid grey',
                backgroundColor: 'white',
              }}
              label="Extract text from image"
              text="Extract text from image"
              title="Extract text from image"
              onClick={async (event) => {
                event.stopPropagation();

                const urlParts = href.split('/');
                const fileName = urlParts[urlParts.length - 1]!;

                const ocrUrl = href.replace(fileName, 'ocr');

                const token = await getAccessTokenSilently();
                const response = await toast.promise(
                  ApiClient.get<OCRAnalysisResponse>(ocrUrl, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                    params: {
                      file: fileName,
                    },
                  }),
                  {
                    loading: 'Analyzing image',
                    success: (r) => {
                      return 'Successfully analyzed image';
                    },
                    error: (err) => {
                      return 'Something went wrong analyzing image';
                    },
                  },
                );

                if (response.data.content) {
                  Transforms.insertNodes(editor, response.data.content, {
                    at: Editor.after(
                      editor,
                      ReactEditor.findPath(editor, element),
                    ),
                  });
                }
              }}
              iconProps={{ iconName: 'TextRecognition' }}
            />
          ) : null}
        </div>
        {/* Top left */}
        {error ? null : (
          <>
            <div
              ref={topLeftDragRef}
              draggable
              onDragStart={onDragMouseDown}
              onDragEnd={onDragMouseUp}
              onDoubleClick={onDragDoubleClick}
              style={{
                display: selected && focused ? 'inline' : 'none',
                position: 'absolute',
                top: '-0.25rem',
                left: '-0.25rem',
                width: '0.5rem',
                height: '0.5rem',
                backgroundColor: 'black',
                border: '1px solid white',
                cursor: selected && focused ? 'nwse-resize' : undefined,
                zIndex: 9999,
              }}
            />
            {/* Top right */}
            <div
              ref={topRightDragRef}
              draggable
              onDragStart={onDragMouseDown}
              onDragEnd={onDragMouseUp}
              onDoubleClick={onDragDoubleClick}
              style={{
                display: selected && focused ? 'inline' : 'none',
                position: 'absolute',
                top: '-0.25rem',
                right: '-0.25rem',
                width: '0.5rem',
                height: '0.5rem',
                backgroundColor: 'black',
                border: '1px solid white',
                cursor: selected && focused ? 'nesw-resize' : undefined,
                zIndex: 9999,
              }}
            />
            {/* Bottom right */}
            <div
              ref={bottomRightDragRef}
              draggable
              onDragStart={onDragMouseDown}
              onDragEnd={onDragMouseUp}
              onDoubleClick={onDragDoubleClick}
              style={{
                display: selected && focused ? 'inline' : 'none',
                position: 'absolute',
                bottom: '-0.25rem',
                right: '-0.25rem',
                width: '0.5rem',
                height: '0.5rem',
                backgroundColor: 'black',
                border: '1px solid white',
                cursor: selected && focused ? 'nwse-resize' : undefined,
                zIndex: 9999,
              }}
            />
            {/* Bottom left */}
            <div
              ref={bottomLeftDragRef}
              draggable
              onDragStart={onDragMouseDown}
              onDragEnd={onDragMouseUp}
              onDoubleClick={onDragDoubleClick}
              style={{
                display: selected && focused ? 'inline' : 'none',
                position: 'absolute',
                bottom: '-0.25rem',
                left: '-0.25rem',
                width: '0.5rem',
                height: '0.5rem',
                backgroundColor: 'black',
                border: '1px solid white',
                cursor: selected && focused ? 'nesw-resize' : undefined,
                zIndex: 9999,
              }}
            />
            {/* Center top */}
            <div
              ref={topCenterDragRef}
              draggable
              onDragStart={onDragMouseDown}
              onDragEnd={onDragMouseUp}
              onDoubleClick={onDragDoubleClick}
              style={{
                display: selected && focused ? 'inline' : 'none',
                position: 'absolute',
                top: '-0.25rem',
                left: '50%',
                width: '1.5rem',
                height: '0.5rem',
                backgroundColor: 'black',
                border: '1px solid white',
                transform: 'translateX(-0.75rem)',
                cursor: selected && focused ? 'ns-resize' : undefined,
                zIndex: 9999,
              }}
            />
            {/* Center bottom */}
            <div
              ref={bottomCenterDragRef}
              draggable
              onDragStart={onDragMouseDown}
              onDragEnd={onDragMouseUp}
              onDoubleClick={onDragDoubleClick}
              style={{
                display: selected && focused ? 'inline' : 'none',
                position: 'absolute',
                bottom: '-0.25rem',
                left: '50%',
                width: '1.5rem',
                height: '0.5rem',
                backgroundColor: 'black',
                border: '1px solid white',
                transform: 'translateX(-0.75rem)',
                cursor: selected && focused ? 'ns-resize' : undefined,
                zIndex: 9999,
              }}
            />
            {/* Center left */}
            <div
              ref={leftCenterDragRef}
              draggable
              onDragStart={onDragMouseDown}
              onDragEnd={onDragMouseUp}
              onDoubleClick={onDragDoubleClick}
              style={{
                display: selected && focused ? 'inline' : 'none',
                position: 'absolute',
                top: '50%',
                left: '-0.25rem',
                width: '0.5rem',
                height: '1.5rem',
                backgroundColor: 'black',
                border: '1px solid white',
                transform: 'translateY(-0.75rem)',
                cursor: selected && focused ? 'ew-resize' : undefined,
                zIndex: 9999,
              }}
            />
            {/* Center right */}
            <div
              ref={rightCenterDragRef}
              draggable
              onDragStart={onDragMouseDown}
              onDragEnd={onDragMouseUp}
              onDoubleClick={onDragDoubleClick}
              style={{
                display: selected && focused ? 'inline' : 'none',
                position: 'absolute',
                top: '50%',
                right: '-0.25rem',
                width: '0.5rem',
                height: '1.5rem',
                backgroundColor: 'black',
                border: '1px solid white',
                transform: 'translateY(-0.75rem)',
                cursor: selected && focused ? 'ew-resize' : undefined,
                zIndex: 9999,
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};
