export const StarsIllustration = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.907 8.23527V8.09721C17.9744 8.11745 18.041 8.14044 18.1069 8.16603C18.0409 8.19183 17.9742 8.21495 17.907 8.23527ZM19.9019 10.0917H19.7613C19.7819 10.0228 19.8053 9.95461 19.8314 9.88731C19.8576 9.95461 19.8812 10.0228 19.9019 10.0917ZM21.757 8.09606V8.2366C21.6881 8.21591 21.6199 8.19235 21.5526 8.16608C21.6199 8.13999 21.6881 8.11659 21.757 8.09606ZM19.7625 6.24175H19.9006C19.8802 6.30891 19.8571 6.37558 19.8313 6.4416C19.8057 6.37576 19.7827 6.3091 19.7625 6.24175Z"
      fill="#AFAEB1"
      stroke="#AFAEB1"
      strokeWidth="1.4"
    />
    <path
      d="M17.907 19.902V19.764C17.9744 19.7842 18.041 19.8072 18.1069 19.8328C18.0409 19.8586 17.9742 19.8817 17.907 19.902ZM19.9019 21.7585H19.7613C19.7819 21.6896 19.8053 21.6214 19.8314 21.5541C19.8576 21.6214 19.8812 21.6896 19.9019 21.7585ZM21.757 19.7628V19.9033C21.6881 19.8827 21.6199 19.8591 21.5526 19.8328C21.6199 19.8067 21.6881 19.7833 21.757 19.7628ZM19.7625 17.9085H19.9006C19.8802 17.9757 19.8571 18.0423 19.8313 18.1083C19.8057 18.0425 19.7827 17.9758 19.7625 17.9085Z"
      fill="#AFAEB1"
      stroke="#AFAEB1"
      strokeWidth="1.4"
    />
    <path
      d="M9.62435 9.04175C9.62435 11.0834 7.58268 13.1251 5.54102 13.1251V14.8751C7.58268 14.8751 9.62435 16.9051 9.62435 18.9584H11.3743C11.3743 16.9051 13.4043 14.8751 15.4577 14.8751V13.1251C13.4043 13.1251 11.3743 11.0834 11.3743 9.04175H9.62435ZM11.3743 9.04175C11.3743 7.87508 9.62435 7.87508 9.62435 9.04175C9.62435 12.0517 12.436 14.8751 15.4577 14.8751C16.6243 14.8751 16.6243 13.1251 15.4577 13.1251C12.436 13.1251 9.62435 15.9367 9.62435 18.9584C9.62435 20.1251 11.3743 20.1251 11.3743 18.9584C11.3743 15.9367 8.55102 13.1251 5.54102 13.1251C4.37435 13.1251 4.37435 14.8751 5.54102 14.8751C8.55102 14.8751 11.3743 12.0517 11.3743 9.04175Z"
      fill="#AFAEB1"
    />
  </svg>
);
