import { PersonaPresence } from '@fluentui/react';
import { AttendeeResponse } from '@meetingflow/common/Api/data-contracts';

export const toPersonaPresence = (
  eventResponse?: AttendeeResponse,
): PersonaPresence | undefined => {
  if (!eventResponse) {
    return undefined;
  }

  switch (eventResponse) {
    case 'ACCEPTED':
      return PersonaPresence.online;
    case 'TENTATIVE':
      return PersonaPresence.away;
    case 'DECLINED':
      return PersonaPresence.dnd;
    case 'PENDING':
      return PersonaPresence.offline;
    case 'UNKNOWN':
    default:
      return PersonaPresence.none;
  }
};

export const toPersonaDescription = (
  eventResponse?: AttendeeResponse,
): string => {
  if (!eventResponse) {
    return 'Unknown';
  }

  switch (eventResponse) {
    case 'ACCEPTED':
      return 'Accepted';
    case 'TENTATIVE':
      return 'Tentatively Accepted';
    case 'DECLINED':
      return 'Declined';
    case 'PENDING':
      return 'Pending';
    case 'UNKNOWN':
    default:
      return 'Unknown';
  }
};
