import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

export type colorType = string | undefined | null;

type IconProps = {
  color?: colorType;
};

export const UploadIllustration: React.FC<IconProps> = ({
                                                          color,
                                                        }: IconProps) => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="56" height="56" rx="28" fill={color || DEALROOMS_COLORS.inputLightGray} />
    <path
      d="M34.2498 19.5086C34.664 19.5088 35 19.1731 35 18.7589C35 18.3447 34.6644 18.0088 34.2502 18.0086L21.2502 18.0049C20.836 18.0048 20.5 18.3405 20.5 18.7547C20.5 19.1689 20.8356 19.5048 21.2498 19.5049L34.2498 19.5086ZM27.6482 37.9969L27.75 38.0037C28.1297 38.0037 28.4435 37.7215 28.4932 37.3555L28.5 37.2537L28.499 23.5687L32.2208 27.289C32.4871 27.5553 32.9038 27.5794 33.1974 27.3615L33.2815 27.2889C33.5477 27.0226 33.5719 26.606 33.354 26.3124L33.2814 26.2283L28.2837 21.2316C28.0176 20.9656 27.6012 20.9413 27.3076 21.1588L27.2235 21.2313L22.22 26.228C21.9269 26.5206 21.9266 26.9955 22.2193 27.2886C22.4854 27.5551 22.902 27.5796 23.1958 27.3619L23.28 27.2893L26.999 23.5757L27 37.2537C27 37.6334 27.2822 37.9472 27.6482 37.9969Z"
      fill={color || DEALROOMS_COLORS.cloudburst} />
  </svg>
);