import React from 'react';
import { Avatar, Tooltip } from '@mui/material';
import { BasicContact } from '@meetingflow/common/Api/data-contracts';

interface DealRoomContact {
  id: number;
  name?: string;
  email: string;
  avatarUrl?: string;
}

interface DSContactAvatarProps {
  contact: DealRoomContact | BasicContact;
  size?: number;
}

export const DSContactAvatar: React.FC<DSContactAvatarProps> = ({
  contact,
  size = 24,
}) => {
  return (
    <Tooltip title={contact.name || contact.email}>
      <Avatar
        alt={contact.name || contact.email}
        src={contact.avatarUrl || undefined}
        sx={{
          width: size,
          height: size,
          fontSize: `${size * 0.5}rem`,
        }}
      >
        {(contact.name?.[0] || contact.email?.[0] || '').toUpperCase()}
      </Avatar>
    </Tooltip>
  );
};
