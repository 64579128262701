// From https://github.com/ianstormtaylor/slate/blob/main/site/examples/inlines.tsx
// Put this at the start and end of an inline component to work around this Chromium bug:

import { mergeStyles } from '@fluentui/react';

// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
export const InlineChromiumBugfix = () => (
  <span
    contentEditable={false}
    className={mergeStyles({
      fontSize: 0,
    })}
  >
    ${String.fromCodePoint(160) /* Non-breaking space */}
  </span>
);
