import { useCallback, useEffect, useRef, useState } from 'react';
import { GPTChatBoxAction } from '../Components/GPT/GPTChatBoxAction';
import {
  AssistantThoughtMessage,
  GPTChatBoxMessage,
} from '@meetingflow/common/Api/data-contracts';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import { useBoolean } from '@fluentui/react-hooks';
import { PickValues } from '@meetingflow/common/ObjectHelpers';
import {
  isClassifiedIntentMessage,
  isErrorMessage,
  isMemoriesMessage,
  isResponseIdMessage,
  isRewrittenPromptMessage,
  isSuggestedPromptMessage,
  isThoughtMessage,
  isTokenMessage,
} from '@meetingflow/common/AssistantHelpers';

const DEBUG = false;

type useChatStateProps = {
  url: string;
  initialMessages?: GPTChatBoxMessage[];
  defaultActions?: GPTChatBoxAction[];
  additionalProps?: Record<string, unknown>;
};

export const useChatState = ({
  url,
  initialMessages,
  defaultActions,
  additionalProps,
}: useChatStateProps) => {
  const abortController = useRef<AbortController>();

  const { getAccessTokenSilently } = useAuth0();

  const [
    isGenerating,
    { setTrue: setGeneratingTrue, setFalse: setGeneratingFalse },
  ] = useBoolean(false);

  const [thoughts, setThoughts] = useState<string[]>([]);
  const [currentOutput, setCurrentOutput] = useState<string>('');
  const [memories, setMemories] = useState<string[] | undefined>();
  const [actions, setActions] = useState<GPTChatBoxAction[] | undefined>(
    defaultActions,
  );

  const [messages, setMessages] = useState<GPTChatBoxMessage[]>(
    initialMessages || [],
  );

  useEffect(() => {
    setActions(defaultActions);
  }, [defaultActions]);

  useEffect(() => {
    if (initialMessages?.length) {
      setMessages((m) =>
        initialMessages === m ? m : [...m, ...initialMessages],
      );
    }
  }, [initialMessages]);

  useEffect(() => {
    return () => {
      if (abortController.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        abortController.current.abort();
      }
    };
  }, []);

  const generate = useCallback(async () => {
    const userMessageIndex = messages.findLastIndex((m) => m.role === 'user');

    if (userMessageIndex < 0) {
      return;
    }

    abortController.current?.abort();

    const newController = new AbortController();
    abortController.current = newController;

    setGeneratingTrue();
    setCurrentOutput('');
    setThoughts([]);

    try {
      const token = await getAccessTokenSilently();
      const result = await new Promise<{
        error?: true;
        response: string;
        responseId?: number;
        rewrittenPrompt?: string;
        suggestedFollowups?: { label: string; prompt: string }[];
      }>((resolve, reject) => {
        fetch(url, {
          method: 'POST',
          headers: {
            Accept: 'text/event-stream',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            messages: messages
              .filter((message) => !message.error) // && !!message.content)
              .map((message) =>
                PickValues(message, ['role', 'name', 'content']),
              ),
            memories,
            ...additionalProps,
          }),
          signal: newController.signal,
        })
          .then(async (res) => {
            let responseId: number | undefined;
            let rewrittenPrompt: string | undefined;
            let suggestedFollowups:
              | { label: string; prompt: string }[]
              | undefined;

            let responseBody = '';
            let generated = '';

            if (!res.ok) {
              reject(new Error(`Failed to fetch response: ${res.statusText}`));
              return;
            }

            if (!res.body) {
              reject(new Error(`res has no body`));
              return;
            }

            const reader = res.body
              .pipeThrough(new TextDecoderStream())
              .getReader();

            if (!reader) {
              reject(`Failed to get stream reader`);
            }

            let gotOutput = false;

            // eslint-disable-next-line no-constant-condition
            while (true) {
              gotOutput = false;
              const read = await reader.read();

              if (read.done && !responseBody) {
                resolve({
                  response: generated,
                  responseId,
                  rewrittenPrompt,
                  suggestedFollowups,
                });
                return;
              }

              const value = read.value;

              if (value) {
                responseBody += value;
              }

              let i = responseBody.indexOf('\n\n');

              while (i >= 0) {
                const eventPayload = responseBody.slice(0, i);
                responseBody = responseBody.slice(i + 2);

                if (eventPayload.startsWith('data: ')) {
                  const payload = eventPayload.slice(6);

                  if (payload === '[DONE]') {
                    if (DEBUG) {
                      console.info(`Got [DONE] event payload ${payload}`);
                    }

                    break;
                  }

                  let message: unknown = undefined;
                  try {
                    message = JSON.parse(payload);

                    if (DEBUG) {
                      console.info(`Parsed data chunk ${payload}`);
                    }
                  } catch (err: unknown) {
                    console.warn(`Error parsing payload data chunk ${payload}`);
                  }

                  if (isTokenMessage(message)) {
                    generated += message.token;
                    gotOutput = true;
                  } else if (isThoughtMessage(message)) {
                    const m: AssistantThoughtMessage = message;
                    setThoughts((current) => {
                      if (m.thought) {
                        return [...current, m.thought];
                      } else {
                        return [];
                      }
                    });
                  } else if (isRewrittenPromptMessage(message)) {
                    rewrittenPrompt = message.rewrittenPrompt;
                    console.info(
                      `Rewrote user prompt\n\n"${message.originalPrompt}"\n\nto\n\n"${message.rewrittenPrompt}"`,
                    );
                  } else if (isClassifiedIntentMessage(message)) {
                    console.info(
                      `Classified prompt "${message.prompt}" as intent "${message.classifiedIntent}" with confidence ${message.confidence}.`,
                    );
                    if (message.entities.length) {
                      console.info(
                        `Identified entities in prompt "${
                          message.prompt
                        }"\n${JSON.stringify(message.entities, undefined, 2)}`,
                      );
                    }
                  } else if (isMemoriesMessage(message)) {
                    setMemories(message.memories);
                  } else if (isResponseIdMessage(message)) {
                    responseId = message.responseId;
                  } else if (isSuggestedPromptMessage(message)) {
                    suggestedFollowups = message.suggestedFollowups;
                  } else if (isErrorMessage(message)) {
                    resolve({
                      error: true,
                      response:
                        message.message ||
                        'Something went wrong, please try again later',
                    });
                  } else {
                    console.warn(
                      `Received unrecognized message type! ${payload}`,
                    );
                  }
                } else if (eventPayload.startsWith('done')) {
                  if (DEBUG) {
                    console.info(`Got "done" event payload ${eventPayload}`);
                  }
                  break;
                } else {
                  console.warn(
                    `Received unknown payload type: ${eventPayload}`,
                  );
                }

                if (gotOutput) {
                  setCurrentOutput(generated);
                }

                i = responseBody.indexOf('\n\n');
              }
            }
          })
          .catch((err) => reject(err));
      });

      if (result) {
        setCurrentOutput('');
        setThoughts([]);
        setActions(
          result.suggestedFollowups?.map((f) => ({
            key: f.label,
            label: f.label,
            type: 'action',
            onClick: () => ({
              role: 'user',
              display: true,
              displayText: f.label,
              content: f.prompt,
            }),
          })),
        );
        setMessages((oldMessages) => [
          ...oldMessages.map((m, idx) => {
            if (idx === userMessageIndex) {
              return {
                ...m,
                display: true,
                displayText: m.displayText || m.content,
                content: result.rewrittenPrompt || m.content,
              };
            }

            return m;
          }),
          {
            id: result.responseId,
            role: 'assistant',
            error: result.error,
            display: true,
            content: result.response,
          },
        ]);
      }
    } catch (err: unknown) {
      if (err instanceof DOMException && err.name === 'AbortError') {
        console.info('Stream summary cancelled');
        return;
      } else {
        toast.error('Something went wrong');
      }
      console.error(typeof err, err);

      setMessages((oldMessages) => [
        ...oldMessages,
        {
          role: 'assistant',
          error: true,
          display: true,
          content: 'Something went wrong',
        },
      ]);
    } finally {
      if (abortController.current === newController) {
        abortController.current = undefined;
        setThoughts([]);
        setCurrentOutput('');
        setGeneratingFalse();
      }
    }
  }, [
    additionalProps,
    getAccessTokenSilently,
    memories,
    messages,
    setGeneratingFalse,
    setGeneratingTrue,
    url,
  ]);

  useEffect(() => {
    if (
      messages &&
      messages.length &&
      messages[messages.length - 1].role === 'user'
    ) {
      generate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  const addMessage = useCallback(
    (message: GPTChatBoxMessage) =>
      setMessages((messages) => [...messages, message]),
    [],
  );

  const addUserMessage = useCallback(
    (content: string, name?: string) =>
      setMessages((messages) => [
        ...messages,
        {
          role: 'user',
          name,
          content,
          display: true,
        },
      ]),
    [],
  );

  const retryLastMessage = useCallback(
    () =>
      setMessages((messages) => {
        const lastMessage = messages[messages.length - 1];
        if (lastMessage.role !== 'user') {
          return messages.slice(0, -1);
        }
        return messages;
      }),
    [],
  );

  const abort = useCallback(() => {
    abortController.current?.abort();
  }, []);

  return {
    isGenerating,
    thoughts,
    currentOutput,
    memories,
    actions,
    messages,
    setMessages,
    addMessage,
    addUserMessage,
    retryLastMessage,
    abort,
    generate,
  };
};
