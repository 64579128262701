import { useMemo } from 'react';
import { useParams } from 'react-router';

export const useDealRoomId = () => {
  const { dealRoomId } = useParams<{ dealRoomId: string }>();

  const dealRoomIdNumber = useMemo(
    () => (dealRoomId ? parseInt(dealRoomId, 10) : undefined),
    [dealRoomId],
  );

  return dealRoomIdNumber && !isNaN(dealRoomIdNumber)
    ? dealRoomIdNumber
    : undefined;
};
