import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router';
import { DashboardContent } from './DashboardContent/DashboardContent';

export const DecisionSiteEntryContent = () => {
  const location = useLocation();

  // Determine which navigation tab is currently active based on URL
  const activeTab = useMemo(() => {
    if (
      matchPath(`/organization/:organizationSlug/template`, location?.pathname)
    ) {
      return 'template';
    }

    return 'dashboard';
  }, [location.pathname]);

  if (activeTab === 'dashboard') {
    return <DashboardContent />;
  }

  if (activeTab === 'template') {
    return <div>Template</div>;
  }

  return null;
};
