import React from 'react';

export const activeChords = (
  event: Pick<
    React.KeyboardEvent<unknown>,
    'metaKey' | 'ctrlKey' | 'altKey' | 'shiftKey'
  >,
) => ({
  modifierActive: event.ctrlKey || event.metaKey || event.altKey,
  altShiftChord:
    event.altKey && event.shiftKey && !event.ctrlKey && !event.metaKey,
  ctrlChord:
    event.ctrlKey && !event.metaKey && !event.shiftKey && !event.altKey,
  cmdChord: event.metaKey && !event.ctrlKey && !event.shiftKey && !event.altKey,
  ctrlShiftChord:
    event.ctrlKey && event.shiftKey && !event.metaKey && !event.altKey,
  cmdShiftChord:
    event.metaKey && event.shiftKey && !event.ctrlKey && !event.altKey,
  ctrlAltChord:
    event.ctrlKey && event.altKey && !event.shiftKey && !event.metaKey,
  cmdControlChord:
    event.metaKey && event.ctrlKey && !event.shiftKey && !event.altKey,
  cmdControlShiftChord:
    event.metaKey && event.ctrlKey && event.shiftKey && !event.altKey,
  cmdOptionChord:
    event.metaKey && event.altKey && !event.shiftKey && !event.ctrlKey,
  ctrlAltShiftChord:
    event.ctrlKey && event.altKey && event.shiftKey && !event.metaKey,
  cmdOptionShiftChord:
    event.metaKey && event.altKey && event.shiftKey && !event.ctrlKey,
  altChord: event.altKey && !event.ctrlKey && !event.metaKey && !event.shiftKey,
  optionChord:
    event.altKey && !event.ctrlKey && !event.metaKey && !event.shiftKey,
});
