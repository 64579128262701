import { ILinkProps, Link } from '@fluentui/react';
import React, { useCallback, useMemo } from 'react';
import { isExternalURL } from '../../Helpers/URLHelpers';
import { useModifierAwareNavigate } from '../../Hooks/useModifierAwareNavigate';

export type ModifierAwareLinkProps = {
  href: string;
  defaultToNewTab?: boolean;
} & Omit<ILinkProps, 'onClick'>;
export const ModifierAwareLink = React.memo(
  ({ href, defaultToNewTab = false, ...rest }: ModifierAwareLinkProps) => {
    const navigate = useModifierAwareNavigate({ defaultToNewTab });

    const isExternal = useMemo(() => isExternalURL(href), [href]);

    const clickHandler = useCallback(
      (
        e: React.MouseEvent<
          HTMLAnchorElement | HTMLElement | HTMLButtonElement,
          MouseEvent
        >,
      ) => {
        e.stopPropagation();
        e.preventDefault();
        navigate(href, e);
      },
      [href, navigate],
    );

    return (
      <Link
        href={isExternal && !defaultToNewTab ? href : undefined}
        onClick={isExternal && !defaultToNewTab ? undefined : clickHandler}
        {...rest}
      />
    );
  },
);
