import {
  DefaultButton,
  Dialog,
  DialogFooter,
  ITextField,
  PrimaryButton,
  TextField,
} from '@fluentui/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AIActionContext } from '../../types/AIActionContext';
import { useDeferredPromise } from '../useDeferredPromise';
import { useLightOrDarkMode } from '../useLightOrDarkMode';

type AIActionDialogProps = {
  initialText?: string;
  initialDisplayText?: string;
  initialPrompt?: string;
  onSubmit: (value: AIActionContext | PromiseLike<AIActionContext>) => void;
  onCancel: (reason?: unknown) => void;
};

const AIActionDialog = ({
  initialText,
  initialDisplayText,
  initialPrompt,
  onSubmit,
  onCancel,
}: AIActionDialogProps) => {
  const { isDark } = useLightOrDarkMode();

  const [text, setText] = useState<string>(initialText || '');
  const [displayText, setDisplayText] = useState<string>(
    initialDisplayText || '',
  );
  const [prompt, setPrompt] = useState<string>(initialPrompt || '');

  useEffect(() => {
    setText(initialText || '');
    setDisplayText(initialDisplayText || '');
    setPrompt(initialPrompt || '');
  }, [initialText, initialDisplayText, initialPrompt]);

  const textFieldRef = useRef<ITextField>(null);
  const displayTextFieldRef = useRef<ITextField>(null);
  const promptFieldRef = useRef<ITextField>(null);

  useEffect(() => {
    // Effects run after the render pass, but before the DOM has necessarily finished updating, so the refs may be null
    // Use set immediate to execute after one tick, so the DOM has finished updating and the refs are bound
    setImmediate(() => {
      if (!initialText && textFieldRef.current) {
        textFieldRef.current.focus();
      } else if (!initialDisplayText && displayTextFieldRef.current) {
        displayTextFieldRef.current.focus();
      } else if (promptFieldRef.current) {
        promptFieldRef.current.focus();
      }
    });
  }, [initialDisplayText, initialText, promptFieldRef, textFieldRef]);

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter' && !!text) {
        onSubmit({
          text,
          displayText: displayText || undefined,
          prompt: prompt || undefined,
        });
      }
    },
    [onSubmit, text, displayText, prompt],
  );

  return (
    <Dialog
      dialogContentProps={{
        title:
          !!initialDisplayText || !!initialPrompt
            ? 'Update AI Action'
            : 'Insert AI Action',
        showCloseButton: true,
        styles: {},
      }}
      hidden={false}
      styles={{ main: { width: '60%' } }}
      onDismiss={onCancel}
    >
      <div>
        <TextField
          label="Label"
          value={text}
          componentRef={textFieldRef}
          onKeyDown={handleKeyPress}
          onChange={(_e, newText) => setText(newText || '')}
          styles={{
            description: {
              display: 'block',
              lineHeight: '.85rem',
              margin: '.25rem 0 .75rem 0',
            },
          }}
          description="The label will appear on the AI Shortcut in the Notes Editor. It should be a short, descriptive phrase that indicates to users what this AI Shortcut does."
        />
        <TextField
          label="Chat Prompt"
          value={prompt}
          componentRef={promptFieldRef}
          onKeyDown={handleKeyPress}
          onChange={(_e, newHref) => setPrompt(newHref || '')}
          multiline
          autoFocus
          styles={{
            field: {
              height: '200px',
            },
            description: {
              display: 'block',
              lineHeight: '.85rem',
              margin: '.25rem 0 .75rem 0',
            },
          }}
          description="The prompt entered here will be sent to our AI Chatbot — along with contextextual information about your meeting — to generate a response."
        />
        <TextField
          label="Display Message (Optional)"
          value={displayText}
          componentRef={displayTextFieldRef}
          onKeyDown={handleKeyPress}
          onChange={(_e, newText) => setDisplayText(newText || '')}
          styles={{
            description: {
              display: 'block',
              lineHeight: '.85rem',
              margin: '.25rem 0 .75rem 0',
            },
          }}
          description="If provided, this message will be displayed in the chat window instead of the prompt. This can be helpful when prompts get very long."
        />
      </div>
      <DialogFooter>
        <PrimaryButton
          text="Confirm"
          disabled={!text}
          onClick={() => {
            onSubmit({
              text,
              displayText: displayText || undefined,
              prompt: prompt || undefined,
            });
          }}
          styles={{
            label: { color: isDark ? 'white' : undefined },
          }}
        />
        <DefaultButton text="Cancel" onClick={onCancel} />
      </DialogFooter>
    </Dialog>
  );
};

export const useAIActionDialog = () => {
  const { createDeferred, deferred, context, resolve, reject } =
    useDeferredPromise<AIActionContext, Partial<AIActionContext> | undefined>();

  const dialog = useMemo(() => {
    if (!deferred?.isPending) {
      return null;
    }

    return (
      <AIActionDialog
        initialText={context?.text}
        initialDisplayText={context?.displayText}
        initialPrompt={context?.prompt}
        onSubmit={resolve}
        onCancel={reject}
      />
    );
  }, [context, deferred?.isPending, reject, resolve]);

  return {
    createDeferred,
    dialog,
  };
};
