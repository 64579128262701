import { DateTime, DurationObjectUnits } from 'luxon';

export const timeAgo = (
  updatedAt: string,
  isNotification?: boolean | undefined,
) => {
  const timeDiff: DurationObjectUnits = DateTime.now()
    .diff(DateTime.fromISO(updatedAt), [
      'years',
      'months',
      'days',
      'hours',
      'minutes',
    ])
    .toObject();
  if (!timeDiff) return '';
  switch (true) {
    case !!timeDiff.years:
      if (isNotification) return '';
      return timeDiff.years + ' years ago';
    case !!timeDiff.months:
      if (isNotification) return '';
      return timeDiff.months + ' months ago';
    case !!timeDiff.days:
      if (isNotification) return '';
      return timeDiff.days + ' days ago';
    case !!timeDiff.hours:
      if (isNotification) return '';
      return timeDiff.hours + ' hours ago';
    case !!timeDiff.minutes:
      if (isNotification && timeDiff.minutes > 5) return '';
      return Math.ceil(timeDiff.minutes) + ' min ago';
    default:
      return '';
  }
};
