import {
  ContextualMenu,
  DirectionalHint,
  IButtonStyles,
  IconButton,
  Persona,
  PersonaSize,
  PrimaryButton,
  IPersona,
  IPersonaStyles,
} from '@fluentui/react';
import { ChevronDownIconProps } from '../../../utils/iconProps';
import { useBoolean } from '@fluentui/react-hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { useMutualPlan } from '../../../Hooks/useMutualPlan';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { drop } from 'lodash';
import { dropdownMenuStyles } from './styles';

type ActionItemOwnerMoreOptionsProps = {
  actionItemId: number;
  organizationSlug: string;
  dealRoomId: number;
  ownerId?: number;
};

export const ActionItemOwnerMoreOptions = ({
  actionItemId,
  organizationSlug,
  dealRoomId,
  ownerId,
}: ActionItemOwnerMoreOptionsProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const [
    showOwnerContextMenu,
    { setTrue: setShowOwnerContextMenu, setFalse: setHideOwnerContextMenu },
  ] = useBoolean(false);

  const [currentContextualMenuTarget, setCurrentContextualMenuTarget] =
    useState<HTMLElement | undefined>(undefined);

  const { dealRoom } = useDealRoom(organizationSlug, dealRoomId);

  const { mutualPlan, refetch: refetchMutualPlan } = useMutualPlan(
    organizationSlug,
    dealRoomId,
  );

  const handleUpdateOwner = useCallback(
    async (newOwnerId: number) => {
      try {
        const token = await getAccessTokenSilently();
        await toast.promise(
          DealRoomsApiClient.updateActionItemOwner(
            organizationSlug,
            dealRoomId,
            actionItemId,
            {
              assigneeId: newOwnerId,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          ),
          {
            loading: 'Updating task',
            success: (result) => {
              refetchMutualPlan().then();
              return 'Successfully updated the task';
            },
            error: 'Something went wrong updating the task',
          },
        );
      } catch (err) {}
    },
    [
      actionItemId,
      dealRoomId,
      getAccessTokenSilently,
      organizationSlug,
      refetchMutualPlan,
    ],
  );

  const handleClickOwner = useCallback(
    (ownerId: number) => {
      handleUpdateOwner(ownerId).then();
    },
    [handleUpdateOwner],
  );

  const ownersList = useMemo(() => {
    if (!Array.isArray(dealRoom?.contacts)) return [];

    return dealRoom.contacts
      .sort((a, b) =>
        a.userId && !b.userId ? -1 : !a.userId && b.userId ? 1 : 0,
      )
      .map((contact) => ({
        key: contact?.email,
        text: `${contact.name || contact?.email}${!contact.userId ? ' (Pending)' : ''}`,
        onClick: () => handleClickOwner(contact.id),
        disabled: !contact.userId,
      }));
  }, [dealRoom?.contacts, handleClickOwner]);

  useEffect(() => {
    const currentTargetElement = document?.getElementById(
      `owners-${actionItemId}`,
    );
    setCurrentContextualMenuTarget(currentTargetElement || undefined);
  }, [actionItemId]);

  const ownerButtonStyles = useMemo(
    () => ({
      root: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: '.5rem',
        borderColor: 'transparent',
        borderRadius: '.25rem',
        backgroundColor: 'transparent',
      },
      rootHovered: {
        backgroundColor: DEALROOMS_COLORS.neutralLight,
        borderColor: 'transparent',
        color: DEALROOMS_COLORS.themePrimary,

        label: {
          color: `${DEALROOMS_COLORS.black} !important`,
        },
      },
      rootPressed: {
        backgroundColor: DEALROOMS_COLORS.neutralLight,
        borderColor: 'transparent',
        color: DEALROOMS_COLORS.themePrimary,

        label: {
          color: `${DEALROOMS_COLORS.black} !important`,
        },
      },
      primaryText: { color: DEALROOMS_COLORS.black },
      secondaryText: { cursor: 'pointer' },
    }),
    [],
  ) as IButtonStyles;

  const ownerPersonaStyles = useMemo(
    () => ({
      details: {
        cursor: 'pointer',
      },
      root: {
        color: DEALROOMS_COLORS.black,
      },
      primaryText: { color: `${DEALROOMS_COLORS.black} !important` },
    }),
    [],
  ) as IPersonaStyles;

  return (
    <div>
      <PrimaryButton
        onClick={setShowOwnerContextMenu}
        styles={ownerButtonStyles}
      >
        <Persona
          text={
            dealRoom?.contacts.find((contact) => contact.id === ownerId)
              ?.name || ''
          }
          size={PersonaSize.size24}
          id={`owners-${actionItemId}`}
          onClick={setShowOwnerContextMenu}
          styles={ownerPersonaStyles}
        />
        <IconButton
          iconProps={ChevronDownIconProps}
          onClick={setShowOwnerContextMenu}
        />
      </PrimaryButton>
      {showOwnerContextMenu && (
        <ContextualMenu
          key={actionItemId}
          target={currentContextualMenuTarget}
          onDismiss={setHideOwnerContextMenu}
          directionalHint={DirectionalHint.bottomLeftEdge}
          items={ownersList}
          styles={{
            subComponentStyles: {
              menuItem: dropdownMenuStyles,
            },
          }}
        />
      )}
    </div>
  );
};
