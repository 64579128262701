import {
  Dropdown,
  FontSizes,
  FontWeights,
  Link,
  mergeStyles,
  NeutralColors,
  Pivot,
  PivotItem,
  Spinner,
} from '@fluentui/react';
import {
  ExternalServiceObject,
  ExternalServiceObjectType,
} from '@meetingflow/common/Api/data-contracts';
import { useEffect, useState } from 'react';
import { useExternalServiceConfigurations } from '../../../../Hooks/useExternalServiceConfigurations';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import { useOrganization } from '../../../../Hooks/useOrganization';
import SFAccountIcon from '../../../../Static/Images/salesforce/account_icon.png';
import SFLeadIcon from '../../../../Static/Images/salesforce/lead_icon.png';
import SFOpportunityIcon from '../../../../Static/Images/salesforce/opportunity_icon.png';
import { MEETINGFLOW_COLORS } from '../../../../Themes/Themes';
import { SalesforcePanelContext } from '../../../../types/SalesforcePanelContext';
import { BaseSidePanel } from '../BaseSidePanel';
import { SalesforceBrowserSidePanelAccountContent } from './SalesforceBrowserSidePanelAccountContent';
import { SalesforceBrowserSidePanelContactContent } from './SalesforceBrowserSidePanelContactContent';
import { SalesforceBrowserSidePanelLeadContent } from './SalesforceBrowserSidePanelLeadContent';
import { SalesforceBrowserSidePanelOpportunityContent } from './SalesforceBrowserSidePanelOpportunityContent';
import { SalesforceBrowserSidePanelPinnedContent } from './SalesforceBrowserSidePanelPinnedContent';

export type SalesforceBrowserSidePanelProps = {
  organizationSlug: string;
  meetingPlanId?: string;
  externalContactIds?: number[];
  externalDomains?: string[];
  defaultTab?: ExternalServiceObjectType | 'PINNED';
  defaultNewOppName?: string;
  associatedObjects?: ExternalServiceObject[];
  pushSalesforcePanel: (context: SalesforcePanelContext) => void;
  onPinnedObject?: (
    e?: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => Promise<unknown>;
  onPickedObject: (
    object: ExternalServiceObject | Omit<ExternalServiceObject, 'id'>,
  ) => void;
  onTabChanged?: (tab: ExternalServiceObjectType | 'PINNED') => void;
  onBack?: () => void;
  onClose?: () => void;
};
export const SalesforceBrowserSidePanel = ({
  organizationSlug,
  meetingPlanId,
  externalDomains,
  externalContactIds,
  defaultTab,
  onPickedObject,
  onPinnedObject,
  onTabChanged,
  pushSalesforcePanel,
  onBack,
  onClose,
  defaultNewOppName = '',
  associatedObjects = [],
}: SalesforceBrowserSidePanelProps) => {
  const {
    configurationsWithToken,
    loading: configurationsLoading,
    configurationById,
  } = useExternalServiceConfigurations({ app: 'SALESFORCE', withToken: true });

  const [activeSalesforceConfigurationId, setActiveSalesforceConfigurationId] =
    useState<number | undefined>();

  useEffect(() => {
    if (configurationsWithToken?.length === 1) {
      setActiveSalesforceConfigurationId(configurationsWithToken[0].id);
    }
  }, [configurationsWithToken]);

  const [selectedTab, setSelectedTab] = useState<
    ExternalServiceObjectType | 'PINNED'
  >(defaultTab || 'PINNED');

  useEffect(() => {
    if (defaultTab && selectedTab !== defaultTab) {
      setSelectedTab(defaultTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTab]);

  const { isDark } = useLightOrDarkMode();

  const { hasEntitlement } = useOrganization();

  const panelContent = mergeStyles({
    position: 'relative',
    height: '100%',
    minHeight: '100%',

    '> div': {
      height: '100%',
      maxHeight: '100%',
    },

    'div[role="tablist"]': { position: 'sticky', padding: '0 .25rem' },

    '.section-header': {
      margin: '1rem 0 .5rem 0 ',
      fontSize: FontSizes.medium,
      fontWeight: FontWeights.semibold,
      color: MEETINGFLOW_COLORS.teal,
      position: 'relative',
      display: 'block',
    },
  });

  if (configurationsLoading) {
    return (
      <div style={{ margin: '1rem 0', minHeight: '100%' }}>
        <Spinner />
      </div>
    );
  }

  const createNewAccUrl = activeSalesforceConfigurationId
    ? configurationById(activeSalesforceConfigurationId)?.instanceId
      ? `${
          configurationById(activeSalesforceConfigurationId)?.instanceId
        }/lightning/o/Account/new`
      : undefined
    : undefined;

  const createNewOppUrl = activeSalesforceConfigurationId
    ? configurationById(activeSalesforceConfigurationId)?.instanceId
      ? `${
          configurationById(activeSalesforceConfigurationId)?.instanceId
        }/lightning/o/Opportunity/new?defaultFieldValues=Name=${defaultNewOppName}`
      : undefined
    : undefined;

  const createNewContactUrl = activeSalesforceConfigurationId
    ? configurationById(activeSalesforceConfigurationId)?.instanceId
      ? `${
          configurationById(activeSalesforceConfigurationId)?.instanceId
        }/lightning/o/Contact/new`
      : undefined
    : undefined;

  const createNewLeadUrl = activeSalesforceConfigurationId
    ? configurationById(activeSalesforceConfigurationId)?.instanceId
      ? `${
          configurationById(activeSalesforceConfigurationId)?.instanceId
        }/lightning/o/Lead/new`
      : undefined
    : undefined;

  const salesforceLinkClass = mergeStyles({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '.5rem',
    fontWeight: FontWeights.semibold,
  });

  const salesforceIconClass = mergeStyles({
    height: '2rem',
    width: '2rem',
    borderRadius: '1rem',
    display: 'inline-block',
    position: 'relative',
    marginRight: '.5rem',
    img: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '2rem',
      width: '2rem',
      display: 'block',
    },
  });

  return (
    <BaseSidePanel
      title="Salesforce"
      loading={false}
      onBack={onBack}
      onClose={onClose}
      providesOwnScrolling
      // contentPadding="0"
    >
      <div className={panelContent}>
        {configurationsWithToken.length > 1 ? (
          <Dropdown
            disabled={configurationsLoading}
            options={configurationsWithToken.map((configuration) => ({
              key: configuration.id,
              text: configuration.instanceId,
            }))}
            selectedKey={activeSalesforceConfigurationId}
            onChange={(_evt, option, _index) => {
              setActiveSalesforceConfigurationId(
                option?.key as number | undefined,
              );
            }}
            label="Salesforce instance"
          />
        ) : null}

        {activeSalesforceConfigurationId ? (
          <Pivot
            defaultSelectedKey={selectedTab ?? 'PINNED'}
            selectedKey={selectedTab}
            onLinkClick={(item) => {
              if (item?.props.itemKey) {
                setSelectedTab(
                  item.props.itemKey as ExternalServiceObjectType | 'PINNED',
                );
                onTabChanged?.(
                  item.props.itemKey as ExternalServiceObjectType | 'PINNED',
                );
              }
            }}
            styles={{
              root: {
                height: '28px',
              },
              link: {
                marginTop: '.25rem',
                fontSize: FontSizes.small,
                height: '1.5rem',
                borderTopLeftRadius: '3px',
                borderTopRightRadius: '3px',
                color: NeutralColors.gray120,
                selectors: {
                  ':hover': {
                    backgroundColor: isDark
                      ? NeutralColors.gray150
                      : MEETINGFLOW_COLORS.purpleLighter,
                  },
                  ':active': {
                    backgroundColor: isDark
                      ? NeutralColors.gray150
                      : MEETINGFLOW_COLORS.purpleLighter,
                  },
                },
              },
              linkContent: {
                height: '32px',
              },
              text: {
                lineHeight: '28px',
              },
              linkIsSelected: {
                backgroundColor: isDark
                  ? NeutralColors.gray150
                  : MEETINGFLOW_COLORS.purpleLighter,
                color: isDark ? NeutralColors.gray90 : NeutralColors.gray140,
                fontWeight: FontWeights.semibold,
                '::before': {
                  display: 'none',
                },
              },
              itemContainer: {
                height: '100%',
                maxHeight: '100%',
                '> div:last-child': {
                  height: '100%',
                  maxHeight: '100%',
                },
                borderTop: `1px solid ${
                  isDark ? NeutralColors.gray150 : MEETINGFLOW_COLORS.purpleGrey
                }`,
              },
            }}
          >
            {meetingPlanId ? (
              <PivotItem itemKey="PINNED" headerText="Related">
                <SalesforceBrowserSidePanelPinnedContent
                  key={activeSalesforceConfigurationId}
                  organizationSlug={organizationSlug}
                  meetingPlanId={meetingPlanId}
                  onPickedObject={onPickedObject}
                  associatedObjects={associatedObjects}
                  salesforceConfigurationId={activeSalesforceConfigurationId}
                  externalDomains={externalDomains}
                  externalContactIds={externalContactIds}
                  onPinnedObject={onPinnedObject}
                  pushSalesforcePanel={pushSalesforcePanel}
                />
              </PivotItem>
            ) : null}
            <PivotItem itemKey="ACCOUNT" headerText="Accounts">
              <SalesforceBrowserSidePanelAccountContent
                key={activeSalesforceConfigurationId}
                organizationSlug={organizationSlug}
                meetingPlanId={meetingPlanId}
                associatedObjects={associatedObjects}
                onPickedObject={onPickedObject}
                salesforceConfigurationId={activeSalesforceConfigurationId}
                externalDomains={externalDomains}
                onPinnedObject={onPinnedObject}
                pushSalesforcePanel={pushSalesforcePanel}
              />
            </PivotItem>
            <PivotItem itemKey="DEAL" headerText="Opportunities">
              <SalesforceBrowserSidePanelOpportunityContent
                key={activeSalesforceConfigurationId}
                organizationSlug={organizationSlug}
                meetingPlanId={meetingPlanId}
                associatedObjects={associatedObjects}
                onPickedObject={onPickedObject}
                defaultNewOppName={defaultNewOppName}
                salesforceConfigurationId={activeSalesforceConfigurationId}
                externalDomains={externalDomains}
                onPinnedObject={onPinnedObject}
                pushSalesforcePanel={pushSalesforcePanel}
              />
            </PivotItem>
            <PivotItem itemKey="CONTACT" headerText="Contacts">
              <SalesforceBrowserSidePanelContactContent
                key={activeSalesforceConfigurationId}
                organizationSlug={organizationSlug}
                meetingPlanId={meetingPlanId}
                associatedObjects={associatedObjects}
                onPickedObject={onPickedObject}
                salesforceConfigurationId={activeSalesforceConfigurationId}
                externalDomains={externalDomains}
                onPinnedObject={onPinnedObject}
                pushSalesforcePanel={pushSalesforcePanel}
              />
            </PivotItem>
            {hasEntitlement('SALESFORCE_LEADS') ? (
              <PivotItem itemKey="LEAD" headerText="Leads">
                <SalesforceBrowserSidePanelLeadContent
                  key={activeSalesforceConfigurationId}
                  organizationSlug={organizationSlug}
                  meetingPlanId={meetingPlanId}
                  associatedObjects={associatedObjects}
                  onPickedObject={onPickedObject}
                  salesforceConfigurationId={activeSalesforceConfigurationId}
                  externalDomains={externalDomains}
                  onPinnedObject={onPinnedObject}
                  pushSalesforcePanel={pushSalesforcePanel}
                />
              </PivotItem>
            ) : null}
          </Pivot>
        ) : null}

        <div
          style={{
            position: 'sticky',
            display: 'flex',
            bottom: '0',
            left: '0',
            width: 'calc(100%)',
            height: '10rem',
            backgroundColor: isDark
              ? NeutralColors.gray210
              : MEETINGFLOW_COLORS.purpleUltraSuperLightish,
          }}
        >
          <div
            style={{
              padding: '.5rem .25rem .25rem .6rem',
              paddingLeft: '.6rem',
            }}
          >
            <Link
              className={salesforceLinkClass}
              href={createNewAccUrl}
              target="_blank"
              as={'a'}
            >
              <div
                className={salesforceIconClass}
                style={{ backgroundColor: '#7F8DE1' }}
              >
                <img alt="Create New Account" src={SFAccountIcon} />
              </div>
              <span>Create New Account</span>
            </Link>
            <Link
              className={salesforceLinkClass}
              href={createNewOppUrl}
              target="_blank"
              as={'a'}
            >
              <div
                className={salesforceIconClass}
                style={{ backgroundColor: '#FCB95B' }}
              >
                <img alt="Create New Opportunity" src={SFOpportunityIcon} />
              </div>
              <span>Create New Opportunity</span>
            </Link>
            <Link
              className={salesforceLinkClass}
              href={createNewContactUrl}
              target="_blank"
              as={'a'}
            >
              <div
                className={salesforceIconClass}
                style={{ backgroundColor: '#4D9DFF' }}
              >
                <img alt="Create New Contact" src={SFLeadIcon} />
              </div>
              <span>Create New Contact</span>
            </Link>
            {hasEntitlement('SALESFORCE_LEADS') ? (
              <Link
                className={salesforceLinkClass}
                href={createNewLeadUrl}
                target="_blank"
                as={'a'}
              >
                <div
                  className={salesforceIconClass}
                  style={{ backgroundColor: '#F88962' }}
                >
                  <img alt="Create New Lead" src={SFLeadIcon} />
                </div>
                <span>Create New Lead</span>
              </Link>
            ) : null}
          </div>
        </div>
      </div>
    </BaseSidePanel>
  );
};
