import { Avatar, styled } from "@mui/material";

export const DSUserAvatar = styled(Avatar)(({ theme }) => ({
    width: '40px',
    height: '40px',
    cursor: 'pointer',
    transition: '.3s ease-in-out all',
    '&:hover': {
      opacity: 0.8,
    },
  }));