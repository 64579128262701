import { InfoCardType } from './InfoSectionTypes';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

const getCardBackground = (type: InfoCardType) => {
  switch (type) {
    case 'PRIMARY':
      return DEALROOMS_COLORS.financialPrimaryGray;
    case 'SECONDARY':
      return DEALROOMS_COLORS.financialSecondaryGray;
    default:
      return DEALROOMS_COLORS.financialLighterGray;
  }
};

export const getCardBasicStructureStyles = (type: InfoCardType) => ({
  card: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '.5rem',
    borderRadius: '.5rem',
    padding: '1rem 1rem 2.5rem',
    backgroundColor: getCardBackground(type),
    height: 'fit-content',
  },
  title: {
    fontSize: '0.9375rem',
    lineHeight: '1.5rem',
    fontWeight: '400',
    color: DEALROOMS_COLORS.themePrimary,
  },
  description: {
    fontSize: '2rem',
    lineHeight: '2.5rem',
    color: DEALROOMS_COLORS.themePrimary,
    overflowWrap: 'anywhere',
  },
  editContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '.edit': {
      borderRadius: '4px',
      i: {
        color: DEALROOMS_COLORS.themeSecondary,
      },
    },
    '.save': {
      border: 'none',
      backgroundColor: 'transparent',

      ':hover': {
        backgroundColor: DEALROOMS_COLORS.neutralLighter,
      },

      i: {
        color: DEALROOMS_COLORS.themePrimary,
      },
    },
  },
});
