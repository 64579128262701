import React, { useCallback, useEffect, useState } from 'react';
import { PrimaryButton, IconButton, Stack, TextField } from '@fluentui/react';
import { DealRoomsApiClient } from '../../../../Services/NetworkCommon';
import { useOrganizationSlug } from '../../../../Hooks/useOrganizationSlug';
import { useDealRoomId } from '../../../../Hooks/useDealRoomId';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-hot-toast';
import { DealRoomArtifact } from '@meetingflow/common/Api/data-contracts';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { useCalloutCards } from '../../../../Hooks/useCalloutCards';
import { DSConfigurationHeaderRow } from '../Components/DSConfigurationHeaderRow';
import { isUrl } from '../../../../Helpers/URLHelpers';

// Define the structure for callout card form data
interface CalloutCardFormData {
    id?: number;
    title: string;
    description: string;
    link: string | null;
    ctaLabel?: string | null;
    artifactId?: number | null;
}

// Define an empty callout card template
const emptyCalloutCard: CalloutCardFormData = {
    title: '',
    link: '',
    description: '',
    ctaLabel: '',
    artifactId: null,
};

export const DSConfigurationCalloutCards: React.FC = () => {
    // State for storing callout cards
    const [calloutCards, setCalloutCards] = useState<CalloutCardFormData[]>([]);
    // State to track if there are unsaved changes
    const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
    // State for storing error messages
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    // State for storing available artifacts
    const [artifacts, setArtifacts] = useState<Array<DealRoomArtifact>>([]);

    // Hooks for callout cards in order to easy update them on the other places from the project
    const { refetch: refetchCalloutCards } = useCalloutCards();

    // Hooks for getting organization and deal room context
    const organizationSlug = useOrganizationSlug();
    const dealRoomId = useDealRoomId();
    const { getAccessTokenSilently } = useAuth0();

    // load callout cards from the API
    const loadCalloutCards = useCallback(async () => {
        if (!organizationSlug || !dealRoomId) {
            return;
        }

        const token = await getAccessTokenSilently();

        const response = await DealRoomsApiClient.listCalloutCards(
            organizationSlug,
            dealRoomId,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        );

        setCalloutCards(response.data as CalloutCardFormData[]);
    }, [organizationSlug, dealRoomId, getAccessTokenSilently]);

    // load artifacts from the API
    const loadArtifacts = useCallback(async () => {
        if (!organizationSlug || !dealRoomId) {
            return;
        }

        const token = await getAccessTokenSilently();
        const response = await DealRoomsApiClient.listArtifacts(
            {
                organizationSlug,
                dealRoomId
            },
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        );
        setArtifacts(response.data);
    }, [organizationSlug, dealRoomId, getAccessTokenSilently]);

    // load callout cards when component mounts or dependencies change
    useEffect(() => {
        loadCalloutCards();
    }, [loadCalloutCards]);

    // load artifacts when component mounts or dependencies change
    useEffect(() => {
        loadArtifacts();
    }, [loadArtifacts]);

    // add a new empty card
    const handleAddCard = () => {
        setCalloutCards([...calloutCards, { ...emptyCalloutCard }]);
        setUnsavedChanges(true);
    };

    // delete a card
    const handleDeleteCard = async (index: number) => {
        if (!organizationSlug || !dealRoomId) {
            return;
        }

        const token = await getAccessTokenSilently();
        const card = calloutCards[index];

        const removeCard = () => {
            const newCards = [...calloutCards];
            newCards.splice(index, 1);
            setCalloutCards(newCards);
            setUnsavedChanges(true);
            // update the callout cards from the Overview tab
            refetchCalloutCards();
        }

        // card is not saved in DB - just a temporary card user was creating
        // no need to show toast for this case
        if (!card.id) {
            removeCard();
        }

        // card was saved in the DB
        if (card.id) {
            await toast.promise(
                DealRoomsApiClient.deleteCalloutCard(
                    organizationSlug,
                    dealRoomId,
                    card.id,
                    {
                        headers: { Authorization: `Bearer ${token}` }
                    },
                ),
                {
                    loading: 'Deleting callout card...',
                    success: () => {
                        removeCard();
                        return 'Callout card deleted successfully';
                    },
                    error: 'Failed to delete callout card',
                }
            );
        }
    };

    // handle changes in card fields
    const handleCardChange = (index: number, field: keyof CalloutCardFormData, value: (string | number | null)) => {
        const newCards = [...calloutCards];
        newCards[index] = { ...newCards[index], [field]: value };
        setCalloutCards(newCards);
        setUnsavedChanges(true);
    };

    // save all callout cards
    const handleSave = async () => {
        if (!organizationSlug || !dealRoomId) {
            return;
        }

        // Validate that each card has exactly one of link or artifactId
        const invalidCards = calloutCards.filter(card => {
            const hasLink = card.link ? isUrl(card.link, { allowMailto: true }) : false;
            const hasArtifactId = Boolean(card.artifactId);
            return !hasLink && !hasArtifactId || hasLink && hasArtifactId;
        });

        // Validate that each card has a title and description
        const invalidFields = calloutCards.some(card => (!card.title || !card.description));

        if (invalidFields) {
            setErrorMessage('Each card must have a title and description.');
            return;
        }

        if (invalidCards.length > 0) {
            setErrorMessage('Each card must have either a valid link (including mailto:) OR an associated artifact, but not both.');
            return;
        }

        const token = await getAccessTokenSilently();
        const promises = calloutCards.map(async (card) => {
            if (card.id) {
                await DealRoomsApiClient.updateCalloutCard(
                    organizationSlug,
                    dealRoomId,
                    card.id,
                    card,
                    {
                        headers: { Authorization: `Bearer ${token}` }
                    }
                );
            } else {
                await DealRoomsApiClient.createCalloutCard(
                    organizationSlug,
                    dealRoomId,
                    card,
                    {
                        headers: { Authorization: `Bearer ${token}` }
                    }
                );
            }
        });
        await toast.promise(
            Promise.all(promises),
            {
                loading: 'Saving callout cards...',
                success: () => {
                    setErrorMessage(null);
                    setUnsavedChanges(false);
                    // update the callout cards from the Overview tab
                    refetchCalloutCards()
                    return 'Callout cards saved successfully';
                },
                error: (error) => {
                    const message = error?.message || 'Failed to save callout cards';
                    setErrorMessage(message);
                    return message;
                },
            }
        ).then(async () => {
            await loadCalloutCards();
        });
    };

    return (
        <Stack tokens={{ childrenGap: 20 }} styles={{ root: { width: '100%' } }}>
            {/* Header with buttons */}
            <DSConfigurationHeaderRow
                title="Callout Cards"
                description="Callout Cards are used to highlight important information or actions in your Decision Site. They can include links or associated artifacts to provide additional context."
            >
                <PrimaryButton
                    onClick={handleAddCard}
                    text="Add New Callout Card"
                />
                <PrimaryButton
                    onClick={handleSave}
                    text="Save Changes"
                    disabled={!unsavedChanges}
                />
            </DSConfigurationHeaderRow>

            {/* Error message display */}
            {errorMessage && (
                <div style={{ color: '#a4262c', marginTop: '8px' }}>
                    {errorMessage}
                </div>
            )}

            {/* Render each callout card */}
            <div style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                gap: '20px',
                width: '100%'
            }}>
                {calloutCards.map((card, index) => (
                    <Stack key={index} tokens={{ childrenGap: 10 }} styles={{
                        root: {
                            padding: 20,
                            border: '1px solid #ccc',
                            backgroundColor: '#fff',
                            borderRadius: '4px',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                        }
                    }}>
                        {/* Card header and delete button */}
                        <Stack horizontal horizontalAlign="space-between">
                            <h3>Card {index + 1}</h3>
                            <IconButton
                                iconProps={{ iconName: 'Delete' }}
                                onClick={() => handleDeleteCard(index)}
                                ariaLabel="Delete card"
                            />
                        </Stack>

                        <TextField
                            label="Title"
                            required
                            value={card.title}
                            onChange={(_, value) => handleCardChange(index, 'title', value || '')}
                        />

                        <TextField
                            label="Link"
                            value={card.link || ''}
                            onChange={(_, value) => handleCardChange(index, 'link', value || '')}
                            disabled={Boolean(card.artifactId)}
                            description="Enter a URL or select an associated artifact below, but not both"
                        />

                        <Dropdown
                            label="Associated Artifact"
                            selectedKey={card.artifactId}
                            onChange={(_, value) => handleCardChange(index, 'artifactId', value?.key as number)}
                            options={artifacts.map(artifact => ({
                                key: artifact.id,
                                text: artifact.name,
                                data: { label: artifact.label }
                            }))}
                            disabled={Boolean(card.link)}
                            onRenderOption={(option) => (
                                <div>
                                    {option?.data?.label && <span>{option.data.label}: </span>}
                                    <span>{option?.text}</span>
                                </div>
                            )}
                        />

                        <TextField
                            label="Description"
                            required
                            multiline
                            rows={3}
                            value={card.description}
                            onChange={(_, value) => handleCardChange(index, 'description', value || '')}
                        />

                        <TextField
                            label="CTA Label"
                            value={card.ctaLabel || ''}
                            onChange={(_, value) => handleCardChange(index, 'ctaLabel', value || '')}
                        />
                    </Stack>
                ))}
            </div>

        </Stack>
    );
};