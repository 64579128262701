import {
  DefaultButton,
  Dialog,
  DialogFooter,
  IDialogProps,
  PrimaryButton,
  TextField,
} from '@fluentui/react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { OmitValues } from '@meetingflow/common/ObjectHelpers';

export type MeetingPlanTemplateForm = {
  name: string;
  description: string;
};

const formSchema = yup
  .object({
    name: yup.string().min(1).required(),
    description: yup.string(),
  })
  .required();

export type TemplateNameDialogProps = {
  onEntered: (templateDetails: MeetingPlanTemplateForm) => void;
  onCancel: () => void;
};
export const TemplateNameDialog = ({
  onEntered,
  onCancel,
  ...rest
}: IDialogProps & TemplateNameDialogProps) => {
  const { isDark } = useLightOrDarkMode();

  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid, errors },
  } = useForm<MeetingPlanTemplateForm>({
    resolver: yupResolver(formSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const onSubmit = handleSubmit((data) => {
    if (isValid) {
      onEntered(data);
    }
  });

  return (
    <Dialog
      {...rest}
      dialogContentProps={{ title: 'Enter template name' }}
      onDismiss={() => {
        reset();
        onCancel();
      }}
    >
      <Controller
        name="name"
        control={control}
        defaultValue={''}
        render={({ field: { value, onChange, onBlur } }) => (
          <TextField
            errorMessage={errors.name?.message}
            label="Name"
            value={value}
            onChange={(_e, newValue) => onChange(newValue || '')}
            onBlur={onBlur}
          />
        )}
      />{' '}
      <Controller
        name="description"
        control={control}
        defaultValue={''}
        render={({ field: { value, onChange, onBlur } }) => (
          <TextField
            errorMessage={errors.description?.message}
            label="Description"
            value={value}
            onChange={(_e, newValue) => onChange(newValue || '')}
            onBlur={onBlur}
          />
        )}
      />{' '}
      <DialogFooter>
        <PrimaryButton text="Accept" disabled={!isValid} onClick={onSubmit} />
        <DefaultButton
          text="Cancel"
          onClick={() => {
            reset();
            onCancel();
          }}
          styles={{
            label: { color: isDark ? 'white' : undefined },
          }}
        />
      </DialogFooter>
    </Dialog>
  );
};
