/**
 * Milestone component represents a single milestone in the deal room.
 * It displays the milestone title, due date, and associated action items.
 * Users can request artifacts, add tasks, and manage the milestone's due date.
 */

import { mergeStyleSets } from '@fluentui/react';
import { DealRoomMilestone } from '@meetingflow/common/Api/data-contracts';
import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useMutualPlan } from '../../../Hooks/useMutualPlan';
import { useMilestonesSummary } from '../../../Hooks/useMilestonesSummary';
import { useRequestAttachmentDialog } from '../../../Hooks/Modals/DealRoom/useRequestAttachmentDialog';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { MilestoneActionItem } from './MilestoneActionItem';
import { NoTasksAvailable } from './NoTasksAvailable';
import { MilestoneHeader } from './MilestoneHeader';
import { isSameDueDate } from './milestonesUtils';
import toast from 'react-hot-toast';

type MilestoneProps = {
  milestone: DealRoomMilestone;
  organizationSlug: string;
  dealRoomId: number;
  onActionItemClick: (
    actionItemId: number,
    milestoneType: DealRoomMilestone,
    isCreating: boolean,
  ) => void;
  handleSelectMilestoneForAddTask: (milestoneType: DealRoomMilestone) => void;
  setNoActionItemPanelKey: (newValue: number) => void;
  setTaskIdForComments: (taskId: number) => void;
};

export const Milestone = ({
  milestone,
  organizationSlug,
  dealRoomId,
  onActionItemClick,
  handleSelectMilestoneForAddTask,
  setNoActionItemPanelKey,
  setTaskIdForComments,
}: MilestoneProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const {
    createDeferred: requestAttachmentDeferred,
    dialog: requestAttachmentDialog,
  } = useRequestAttachmentDialog({
    organizationSlug,
    dealRoomId,
    milestoneType: milestone.type,
  });

  // Fetch mutual plan and milestones data
  const { mutualPlan, refetch: refetchMutualPlan } = useMutualPlan(
    organizationSlug,
    dealRoomId,
  );
  const { milestonesSummary, refetch: refetchMilestonsSummary } =
    useMilestonesSummary(organizationSlug, dealRoomId);

  // Handles updating the milestone's due date
  const handleDueDateChange = useCallback(
    async (newDate: Date | null | undefined) => {
      if (!newDate || !Array.isArray(milestonesSummary)) return;

      // If the due date hasn't changed, do nothing
      if (
        !milestone.dueDate ||
        isSameDueDate(milestone.dueDate, newDate.toISOString())
      )
        return;

      // We should create a new array with all milestones and update the due date of the current milestone and send it do the BE
      const updatedMilestones = milestonesSummary.map((currentMilestone) => ({
        type: currentMilestone.type,
        dueDate:
          milestone.type === currentMilestone.type
            ? newDate.toISOString()
            : currentMilestone.dueDate,
        visible: currentMilestone.visible,
      }));

      const token = await getAccessTokenSilently();
      await toast.promise(
        DealRoomsApiClient.saveMilestones(
          organizationSlug,
          dealRoomId,
          updatedMilestones,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        ),
        {
          loading: 'Updating due date',
          success: () => {
            // Refreshes the mutual plan and milestones summary after successful update
            Promise.all([refetchMilestonsSummary(), refetchMutualPlan()]);
            return 'Successfully updated the due date';
          },
          error: 'Something went wrong updating the due date',
        },
      );
    },
    [
      dealRoomId,
      getAccessTokenSilently,
      milestone.dueDate,
      milestone.type,
      milestonesSummary,
      organizationSlug,
      refetchMilestonsSummary,
      refetchMutualPlan,
    ],
  );

  // Handles requesting an attachment, swallowing any errors for now
  const handleRequestAttachment = useCallback(async () => {
    try {
      await requestAttachmentDeferred().promise;
    } catch (err) {}
  }, [requestAttachmentDeferred]);

  const styles = mergeStyleSets({
    milestoneContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      // Hide empty MS Layer elements that get injected by Fluent UI
      '.ms-layer:empty': {
        display: 'none',
      },
    },
    tasksList: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },
  });

  return (
    <div className={styles.milestoneContainer}>
      <MilestoneHeader
        milestone={milestone}
        organizationSlug={organizationSlug}
        dealRoomId={dealRoomId}
        onDueDateChange={handleDueDateChange}
        onRequestArtifact={handleRequestAttachment}
        onAddTask={() => {
          handleSelectMilestoneForAddTask(milestone);
          setNoActionItemPanelKey(Date.now());
        }}
      />
      <div className={styles.tasksList}>
        {milestone?.actionItems?.length ? (
          [...milestone.actionItems]
            .sort((a, b) => {
              // Handle cases where either date is null
              if (!a.dueDate && !b.dueDate) return 0;
              if (!a.dueDate) return 1;
              if (!b.dueDate) return -1;

              // Compare timestamps for dates
              return (
                new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime()
              );
            })
            .map((actionItem) => (
              <MilestoneActionItem
                key={actionItem.id}
                actionItem={actionItem}
                onActionItemClick={() =>
                  onActionItemClick(actionItem.id, milestone, true)
                }
                organizationSlug={organizationSlug}
                dealRoomId={dealRoomId}
                setTaskIdForComments={setTaskIdForComments}
              />
            ))
        ) : (
          <NoTasksAvailable />
        )}
      </div>
      {requestAttachmentDialog}
    </div>
  );
};
