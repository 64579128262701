import { useAuth0 } from '@auth0/auth0-react';
import {
  FontIcon,
  FontSizes,
  FontWeights,
  ITooltipHostStyles,
  Icon,
  Link,
  NeutralColors,
  Persona,
  PersonaSize,
  Text,
  TooltipHost,
  mergeStyles,
} from '@fluentui/react';
import {
  Company,
  Contact,
  DetailedMeetingflow,
  Meetingflow,
} from '@meetingflow/common/Api/data-contracts';
import { GroupBy } from '@meetingflow/common/ArrayHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { isExternalEventOrMeetingflow } from '../../Helpers/MeetingPlanHelpers';
import { titleCase } from '../../Helpers/Typography';
import useBreakpoints from '../../Hooks/useBreakpoints';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { useOrganization } from '../../Hooks/useOrganization';
import { useUserProfile } from '../../Hooks/useProfile';
import { MeetingPlanQuery } from '../../QueryNames';
import {
  DealRoomsApiClient,
  MeetingflowsApiClient,
} from '../../Services/NetworkCommon';
import { DEALROOMS_COLORS, MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { ModifierAwareLink } from '../Common/ModifierAwareLink';
import StyledDateTime from '../StyledDateTime';
import { CompanyCard } from './MeetingPlanAttendees';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteMeetingflowAndDecisionSiteArtifact, removeMeetingflowArtifactFromDecisionSite, toggleMeetingflowFeature } from '../../utils/DecisionSiteMeetingflowHelpers';
import { useDealRoom } from '../../Hooks/useDealRoom';
import { MeetingflowCardRoot } from './DecisionSiteMeetingflowCard.styles';
import { Star, StarOutlined } from '@mui/icons-material';

const MAX_NUM_COMPANY_LOGOS_INLINE = 5;

export type MeetingFlowCardMeetingflow = Pick<
  Meetingflow,
  | 'id'
  | 'title'
  | 'startTime'
  | 'endTime'
  | 'externalId'
  | 'externalSeriesId'
  | 'conferenceInfo'
  | 'textSummary'
  | 'lastLoggedToCrm'
  | 'location'
  | 'callRecording'
  | 'owner'
  | 'organizer'
  | 'creator'
  | 'attendees'
  | 'companies'
>;

export type DecisionSiteMeetingflowCardProps = {
  organizationSlug: string;
  meetingflowId: string;
  meetingflowObj?: MeetingFlowCardMeetingflow;
  refetchMeetingflow?: () => Promise<unknown>;
  onClick?: (meetingflowId: DetailedMeetingflow['id']) => void;
  onTextClick?: () => void;
  onContactClick?: (c: Contact['id']) => void;
  onCompanyClick?: (c: Company['id']) => void;
  large?: boolean;
  alwaysShowConferenceJoinButton?: boolean;
  hideShowConferenceJoinButton?: boolean;
  hideCRMIcon?: boolean;
  hideEventIcon?: boolean;
  hideSummaryIcon?: boolean;
  hideAttendeesIcon?: boolean;
  hideCallRecordingIcon?: boolean;
  showCompanies?: boolean;
  noTitleLink?: boolean;
  expandable?: boolean;
  expanded?: boolean;
  showCallRecordingButton?: boolean;
  showCreator?: boolean;
  showTimesOnly?: boolean;
  showEndTime?: boolean;
  cardTitleAttr?: string;
  backgroundColor?: string;
  showLocation?: boolean;
  inlineIcons?: boolean;
};

export const DecisionSiteMeetingflowCard = ({
  organizationSlug,
  meetingflowObj,
  refetchMeetingflow,
  meetingflowId,
  onTextClick,
  onClick,
  onContactClick,
  onCompanyClick,
  large = false,
  alwaysShowConferenceJoinButton = false,
  hideShowConferenceJoinButton = false,
  hideCRMIcon = false,
  hideEventIcon = false,
  hideSummaryIcon = false,
  hideAttendeesIcon = false,
  hideCallRecordingIcon = false,
  showCompanies = false,
  noTitleLink = false,
  expandable = false,
  expanded = false,
  showCallRecordingButton = false,
  showCreator = false,
  showTimesOnly = false,
  showEndTime = false,
  cardTitleAttr,
  backgroundColor = 'transparent',
  showLocation = false,
  inlineIcons = false,
}: DecisionSiteMeetingflowCardProps) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const {
    role,
    organization,
    canCreatePlans,
    hasEntitlement,
    internalDomains,
  } = useOrganization(organizationSlug);

  const { dealRoomId, refetch: refreshDealRoom, dealRoomRole } = useDealRoom();

  const {
    user: mfUser,
    workspacePreferences,
    updateWorkspacePreferencesAsync,
  } = useUserProfile();

  const { isDark } = useLightOrDarkMode();
  const navigate = useNavigate();
  const appInsights = useAppInsightsContext();
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleDelete = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);

    if (!dealRoomId || !meetingflowId) return;

    const token = await getAccessTokenSilently();

    await deleteMeetingflowAndDecisionSiteArtifact({
      organizationSlug,
      dealRoomId,
      meetingflowId,
      token,
      queryClient,
      appInsights,
    });
  };

  const handleRemoveFromDecisionSite = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);

    if (!dealRoomId || !meetingflowId) return;

    const token = await getAccessTokenSilently();

    try {
      await removeMeetingflowArtifactFromDecisionSite({
        organizationSlug,
        dealRoomId,
        meetingflowId,
        token,
        queryClient,
        appInsights,
      });
    } catch (err: unknown) {
      console.error('Error removing meetingflow from decision site:', err);
    }
  };

  const [isArtifactFeatured, setIsArtifactFeatured] = useState<boolean>(false);
  const [artifactId, setArtifactId] = useState<number | undefined>();

  const { data: artifactsData, refetch: refetchArtifacts } = useQuery(
    ['artifacts', dealRoomId, organizationSlug, meetingflowId],
    async () => {
      if (!dealRoomId || !organizationSlug || !meetingflowId) return null;
      const token = await getAccessTokenSilently();
      return DealRoomsApiClient.listArtifacts(
        { organizationSlug, dealRoomId },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!dealRoomId && !!organizationSlug && !!meetingflowId,
    },
  );

  useEffect(() => {
    if (artifactsData?.data) {
      const artifact = artifactsData.data.find(
        (a) => a.type === 'MEETINGFLOW' && a.meetingflowId === meetingflowId,
      );
      setIsArtifactFeatured(!!artifact?.featuredAt);
      setArtifactId(artifact?.id);
    }
  }, [artifactsData, meetingflowId]);

  const handleFeature = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);

    if (!dealRoomId || !meetingflowId) return;

    const token = await getAccessTokenSilently();

    try {
      const newFeaturedState = await toggleMeetingflowFeature({
        organizationSlug,
        dealRoomId,
        meetingflowId,
        token,
        queryClient,
        appInsights,
      });
      setIsArtifactFeatured(newFeaturedState);
    } catch (err: unknown) {
      console.error('Error featuring meetingflow:', err);
    }
  };

  const {
    data: meetingPlanData,
    // isLoading: meetingPlanIsLoading,
    isFetched: meetingPlanFetched,
    refetch: refetchMeetingPlan,
  } = useQuery(
    MeetingPlanQuery(organizationSlug, meetingflowId),
    async () => {
      const token = await getAccessTokenSilently();
      return MeetingflowsApiClient.getMeetingflow(
        organizationSlug,
        meetingflowId,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!meetingflowId && !meetingflowObj && !refetchMeetingflow,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    },
  );

  const refetch = useCallback(() => {
    if (refetchMeetingflow) {
      refetchMeetingflow();
    } else {
      refetchMeetingPlan();
    }
  }, [refetchMeetingPlan, refetchMeetingflow]);

  useEffect(() => {
    if (meetingflowId && !meetingflowObj && !refetchMeetingflow) {
      refetchMeetingPlan();
    }
  }, [meetingflowId, meetingflowObj, refetchMeetingPlan, refetchMeetingflow]);

  const meetingflow = meetingflowObj || meetingPlanData?.data;

  const isAssociatedWithEvent = !!meetingflow?.externalId;

  const peopleByDomain = useMemo(
    () => GroupBy(meetingflow?.attendees || [], (a) => a.emailDomain),
    [meetingflow?.attendees],
  );

  const breakpoints = useBreakpoints();

  const attendeesWithoutCompany = useMemo(() => {
    const companyDomains = meetingflow?.companies
      ?.map((c) => c.domains.map((d) => d.domain))
      .flat();
    return (
      Object.keys(peopleByDomain)
        .filter((key) => !companyDomains?.includes(key))
        .flatMap((key) => peopleByDomain[key]) ?? []
    );
  }, [meetingflow?.companies, peopleByDomain]);

  const externalCompanies = useMemo(
    () => meetingflow?.companies.filter((c) => !c.isInternal),
    [meetingflow?.companies],
  );

  const badgeWidth = 2.125;
  const iconWidth = 1.466;

  const isNowOrSoon = meetingflow
    ? meetingflow?.conferenceInfo &&
      DateTime.fromISO(meetingflow?.startTime).diffNow('minutes').minutes <
        60 &&
      DateTime.fromISO(meetingflow?.endTime).diffNow('minutes').minutes > -10
    : false;

  let titleRightPaddingValue = large ? 1 : 0.5; // All cards get half a rem padding
  if (
    meetingflow?.attendees?.length ||
    (isAssociatedWithEvent && !hideAttendeesIcon)
  )
    titleRightPaddingValue = titleRightPaddingValue + iconWidth;
  if (!!meetingflow?.lastLoggedToCrm?.loggedAt && !hideCRMIcon)
    titleRightPaddingValue = titleRightPaddingValue + badgeWidth;
  if (
    alwaysShowConferenceJoinButton ||
    (isNowOrSoon && !hideShowConferenceJoinButton)
  )
    titleRightPaddingValue = titleRightPaddingValue + iconWidth;
  if (meetingflow?.textSummary && !hideSummaryIcon)
    titleRightPaddingValue = titleRightPaddingValue + iconWidth;
  if (meetingflow?.callRecording?.lastStatus)
    titleRightPaddingValue = titleRightPaddingValue + iconWidth;

  const beforeStart = meetingflow
    ? DateTime.fromISO(meetingflow.startTime).diffNow().milliseconds > 0
    : true;
  const beforeEnd = meetingflow
    ? DateTime.fromISO(meetingflow.endTime).diffNow().milliseconds > 0
    : true;
  const afterEnd = meetingflow
    ? DateTime.fromISO(meetingflow.endTime).diffNow().milliseconds < 0
    : false;
  const afterEnd24Hours = meetingflow
    ? DateTime.fromISO(meetingflow.endTime).diffNow().milliseconds < -86400000
    : false;

  const titlePadding =
    hideCRMIcon &&
    hideShowConferenceJoinButton &&
    hideCallRecordingIcon &&
    hideEventIcon &&
    hideSummaryIcon
      ? '1rem'
      : beforeEnd
        ? `calc(1rem * ${titleRightPaddingValue} + 5.5rem)`
        : `calc(1rem * ${titleRightPaddingValue} + .5rem)`;

  const expandableWrapperClass = mergeStyles({
    backgroundColor: backgroundColor,
    // padding: '.25rem',
    borderRadius: '.25rem',
  });

  const meetingflowCardClass = mergeStyles({
    height: large ? '48px' : 'auto',
    minHeight: '32px',
    paddingBottom: '.25rem',
    // padding: '.25rem',
    backgroundColor: 'transparent',
    position: 'relative',
    borderRadius: '.25rem',
    cursor: onClick ? 'pointer' : undefined,
    display: 'flex',
    flexDirection: 'column',
    columnGap: '.5rem',
    margin: '0',
    transition: '.3s ease-in-out all',
    width: 'auto',

    '.meetingflow-creator': {
      display: 'block',
      width: '32px',
      flexBasis: '32px',
    },

    '.card-content': {
      display: 'block',
      width: showCreator ? `calc(100% - 64px)` : `100%`,
      flexBasis: 'auto',
    },

    ':hover': {
      backgroundColor: onClick
        ? isDark
          ? NeutralColors.gray210
          : 'transparent'
        : undefined,
      img: {
        filter: 'grayscale(0%) !important',
        opacity: `1 !important`,
      },
      'div.meetingflow-icons': {
        opacity: `1 !important`,
      },

      '.meetingflow-title': {
        cursor: onTextClick || onClick ? 'pointer' : 'default',
        color: isDark ? NeutralColors.white : undefined,
      },
    },

    '.meetingflow-title': {
      display: inlineIcons ? 'inline-block' : 'block',
      // fontSize: large ? FontSizes.mediumPlus : FontSizes.medium,
      // fontWeight: FontWeights.regular,
      height: large ? '32px' : '20px',
      lineHeight: large ? '32px' : '20px',
      paddingRight: inlineIcons ? '.5rem' : titlePadding,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      color: isDark ? NeutralColors.white : undefined,
      boxSizing: 'border-box',

      a: {
        color: 'unset',
        ':hover': {
          color: 'unset',
          textDecoration: 'unset',
        },
      },
    },

    '.recorder-status': {
      display: 'inline-block',
    },

    '.meetingflow-datetime': {
      display: 'block',
      fontSize: FontSizes.small,
      fontWeight: FontWeights.regular,
      height: '20px',
      lineHeight: '20px',
      position: 'relative',
      top: large ? '-4px' : undefined,
      cursor: onTextClick ? 'pointer' : undefined,
    },
    '.meetingflow-icons': {
      display: inlineIcons ? 'inline-block' : 'block',
      position: !inlineIcons ? 'absolute' : 'relative',
      top: !inlineIcons && large ? '10px' : inlineIcons ? '-.5rem' : '.25rem',
      right: !inlineIcons ? '.0' : undefined,
      transition: '.3s ease-in-out all',
      opacity: '.5',
      height: inlineIcons ? '16px' : '24px',

      'i, .ms-Button': {
        cursor: 'default',
        display: 'inline-block',
        height: '22px',
        width: '22px',
        fontSize: '14px',
        lineHeight: '22px',
        backgroundColor: isDark
          ? NeutralColors.gray140
          : DEALROOMS_COLORS.cloudburst,
        border: `1px solid ${
          isDark ? 'transparent' : MEETINGFLOW_COLORS.white
        }`,
        color: MEETINGFLOW_COLORS.white,
        borderRadius: '.75rem',
        textAlign: 'center',
        position: 'relative',
        top: '0',
      },

      'i.join-conference': {
        backgroundColor: 'rgb(76,140,254)',
        cursor: 'pointer',
      },

      'i.open-meetingflow': {
        cursor: 'pointer',
      },

      'i.summary': {
        backgroundColor: MEETINGFLOW_COLORS.orange,
      },

      'i.call-recording': {
        backgroundColor: 'transparent',
        color: DEALROOMS_COLORS.cloudburst,
      },

      'i.call-recording-scheduled': {
        color: `${DEALROOMS_COLORS.cloudburst} !important`,
        border: `1px solid ${DEALROOMS_COLORS.cloudburst} !important`,
      },
      'i.call-recording-ready, &.call-recording_joining-call, &.call-recording-in_waiting_room,  &.call-recording-in_call_not_recording, &.call-recording-in_call_recording, &.call-recording-call_ended':
        {
          color: `${MEETINGFLOW_COLORS.red} !important`,
          border: `1px solid ${MEETINGFLOW_COLORS.red} !important`,
        },
      'i.call-recording-in_call_recording': {
        color: `${MEETINGFLOW_COLORS.red} !important`,
        border: `1px solid ${MEETINGFLOW_COLORS.red} !important`,
        animation: 'recordingBlinker 2s infinite',
      },
      'i.call-recording-done, &.call-recording-analysis_done': {
        color: `${MEETINGFLOW_COLORS.magenta} !important`,
        border: `1px solid ${MEETINGFLOW_COLORS.magenta} !important`,
      },
      'i.call-recording-fatal': {
        color: `${MEETINGFLOW_COLORS.red} !important`,
        border: `1px solid ${MEETINGFLOW_COLORS.red} !important`,
      },
      'i.call-recording-media_expired': {
        // Treat as done
        color: `${MEETINGFLOW_COLORS.magenta} !important`,
        border: `1px solid ${MEETINGFLOW_COLORS.magenta} !important`,
      },
    },
    '.meetingflow-companies': {
      position: 'absolute',
      bottom: '.25rem',
      right: '.25rem',
      transition: '.3s ease-in-out all',
    },
    '.badge': {
      display: 'inline-block',
      backgroundColor: DEALROOMS_COLORS.cloudburst,
      width: 'auto',
      padding: '0 .25rem',
      minWidth: '1rem',
      maxWidth: '4rem',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'center',
      fontSize: FontSizes.mini,
      textTransform: 'uppercase',
      color: MEETINGFLOW_COLORS.white,
      borderRadius: '.25rem',
      fontWeight: FontWeights.semibold,
      height: '1rem',
      lineHeight: '.95rem',
      position: 'relative',
      top: '2px',
    },
  });

  const companyClass = mergeStyles({
    display: 'block',
    marginBottom: '.5rem',
  });

  const companyNameClass = mergeStyles({
    transition: '.3s ease-in-out all',
    color: DEALROOMS_COLORS.cloudburst,
    cursor: 'pointer',
  });

  const attendeeNameClass = mergeStyles({
    marginLeft: '28px',
    marginBottom: '2px',
    color: isDark ? NeutralColors.gray80 : DEALROOMS_COLORS.cloudburst,
    cursor: 'pointer',
    ':hover': {
      textDecoration: 'underline',
    },
  });

  const toolTipHostStyles = useMemo(
    () =>
      ({
        root: {
          display: 'inline-block',
          height: '24px',
          width: '24px',
          marginLeft: '.25rem',
        },
      }) as ITooltipHostStyles,
    [],
  );

  const toolTipHostBadgeStyles = useMemo(
    () =>
      ({
        root: {
          display: 'inline-block',
          height: '24px',
          width: 'auto',
          marginLeft: '.25rem',
        },
      }) as ITooltipHostStyles,
    [],
  );

  const tooltipCalloutProps = useMemo(
    () => ({
      styles: {
        root: {
          padding: 0,
          color: isDark ? undefined : MEETINGFLOW_COLORS.white,
        },
        calloutMain: {
          padding: '.25rem',
          backgroundColor: isDark ? undefined : MEETINGFLOW_COLORS.white,
          'div.tooltip-content-container': {
            padding: '.25rem',
            borderBottomRightRadius: '.15rem',
            borderBottomLeftRadius: '.15rem',
          },
        },
      },
    }),
    [isDark],
  );

  const tooltipHeaderClass = mergeStyles({
    display: 'block',
    fontWeight: FontWeights.semibold,
    fontSize: FontSizes.mini,
    backgroundColor: DEALROOMS_COLORS.cloudburst,
    color: DEALROOMS_COLORS.white,
    textTransform: 'uppercase',
    position: 'relative',
    padding: '.25rem',
    marginBottom: '.25rem',
  });

  const onTitleClick = useCallback(
    (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (onTextClick) {
        e?.stopPropagation();
        onTextClick();
      }
    },
    [onTextClick],
  );

  const onCompanyCardClick = useCallback(
    (id: number, e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e?.stopPropagation();
      navigate(`/organization/${organizationSlug}/library/companies/${id}`);
    },
    [navigate, organizationSlug],
  );

  const onConferenceIconClick = useCallback(() => {
    window.open(meetingflow?.conferenceInfo?.joinUri, '_blank');
    appInsights.trackEvent({
      name: 'JOIN_CONFERENCE_CALL',
      properties: {
        organizationId: organization?.id,
        type: meetingflow?.conferenceInfo?.type,
      },
    });
  }, [
    appInsights,
    meetingflow?.conferenceInfo?.joinUri,
    meetingflow?.conferenceInfo?.type,
    organization?.id,
  ]);

  const onTooltipToggle = useCallback(
    (isOpen: boolean, key: string) => {
      if (isOpen) {
        appInsights.trackEvent({
          name: `OPEN_MEETINGFLOW_CARD_TOOLTIP_${key.toUpperCase()}`,
          properties: {
            organizationId: organization?.id,
            meetingflowId: meetingflow?.id,
          },
        });
      }
    },
    [appInsights, meetingflow?.id, organization?.id],
  );

  const eventIcon = useMemo(() => {
    if (
      (meetingflow?.attendees?.length || isAssociatedWithEvent) &&
      !hideEventIcon
    ) {
      return (
        <TooltipHost
          styles={toolTipHostStyles}
          calloutProps={tooltipCalloutProps}
          onTooltipToggle={(isOpen: boolean) =>
            onTooltipToggle(isOpen, 'ATTENDEES')
          }
          content={
            <div>
              {isAssociatedWithEvent ? (
                <>
                  <Text className={tooltipHeaderClass}>Calendar Event</Text>
                  <div className="tooltip-content-container">
                    <Text
                      style={{
                        display: 'block',
                        fontWeight: FontWeights.semibold,
                        fontSize: FontSizes.small,
                      }}
                    >
                      {meetingflow?.title}
                      {meetingflow?.externalSeriesId ? (
                        <Icon
                          iconName="RecurringEvent"
                          styles={{
                            root: {
                              fontSize: FontSizes.size12,
                              display: 'inline-block',
                              marginLeft: '.25rem',
                              position: 'relative',
                              top: '.15rem',
                              color: NeutralColors.gray100,
                            },
                          }}
                        />
                      ) : null}
                    </Text>
                    {meetingflow?.startTime ? (
                      <>
                        <Text
                          style={{
                            display: 'block',
                            fontSize: FontSizes.small,
                            color: NeutralColors.gray100,
                            marginBottom: '.25rem',
                          }}
                        >
                          <StyledDateTime
                            dateTime={meetingflow?.startTime}
                            displayComponents={['date']}
                          />{' '}
                        </Text>

                        <Text
                          style={{
                            display: 'block',
                            fontSize: FontSizes.small,
                            color: NeutralColors.gray100,
                            marginBottom: '.25rem',
                          }}
                        >
                          <>
                            <StyledDateTime
                              dateTime={meetingflow?.startTime}
                              displayComponents={['time']}
                            />
                          </>
                          {meetingflow?.endTime ? (
                            <>
                              {' — '}{' '}
                              <StyledDateTime
                                dateTime={meetingflow?.endTime}
                                displayComponents={['time']}
                              />
                            </>
                          ) : null}
                        </Text>
                      </>
                    ) : null}
                    {meetingflow.conferenceInfo?.type ? (
                      <Text
                        style={{
                          display: 'block',
                          fontSize: FontSizes.small,
                        }}
                      >
                        <span style={{ fontWeight: FontWeights.semibold }}>
                          Via:{' '}
                        </span>
                        {titleCase(meetingflow.conferenceInfo?.type)}
                      </Text>
                    ) : null}
                    {meetingflow.owner ? (
                      <Text
                        style={{
                          display: 'block',
                          fontSize: FontSizes.small,
                        }}
                      >
                        <span style={{ fontWeight: FontWeights.semibold }}>
                          Owner:{' '}
                        </span>
                        {titleCase(
                          meetingflow.owner.name || meetingflow.owner.email,
                        )}
                      </Text>
                    ) : null}
                    {meetingflow.organizer ? (
                      <Text
                        style={{
                          display: 'block',
                          fontSize: FontSizes.small,
                        }}
                      >
                        <span style={{ fontWeight: FontWeights.semibold }}>
                          Organizer:{' '}
                        </span>
                        {titleCase(
                          meetingflow.organizer.name ||
                            meetingflow.organizer.email,
                        )}
                      </Text>
                    ) : null}
                  </div>
                </>
              ) : null}
              {meetingflow?.attendees?.length ? (
                <>
                  <Text
                    className={tooltipHeaderClass}
                    style={
                      isAssociatedWithEvent ? { marginTop: '.5rem' } : undefined
                    }
                  >
                    Attendees
                  </Text>
                  <div className="tooltip-content-container">
                    <div className="meetingflow-companies">
                      {meetingflow?.companies
                        ? meetingflow?.companies.map((company) => {
                            const companyAttendees =
                              company.domains
                                ?.map((domain) => domain.domain)
                                ?.flatMap(
                                  (domain) =>
                                    Object.keys(peopleByDomain)
                                      .filter(
                                        (key) =>
                                          key === domain ||
                                          key.endsWith(domain),
                                      )
                                      .flatMap((key) => peopleByDomain[key]) ??
                                    [],
                                ) ?? [];

                            return (
                              <div
                                key={`meetingflowcard-${meetingflow.id}-company-${company.id}`}
                                className={companyClass}
                                onClick={() => {
                                  if (onCompanyClick) {
                                    onCompanyClick(company.id);
                                  } else {
                                    if (role && role !== 'GUEST') {
                                      navigate(
                                        `/organization/${organizationSlug}/library/companies/${company.id}`,
                                      );
                                    }
                                  }
                                  appInsights.trackEvent({
                                    name: 'MEETINGFLOW_CARD_ATTENDEES_TOOLTIP_CLICKED_COMPANY',
                                    properties: {
                                      sub: user?.sub,
                                      name: user?.name,
                                      email: user?.email,
                                      meetingPlanId: meetingflow.id,
                                      companyId: company.id,
                                    },
                                  });
                                }}
                              >
                                <div className={companyNameClass}>
                                  <Persona
                                    imageUrl={
                                      company?.logo ||
                                      company?.twitter_avatar ||
                                      undefined
                                    }
                                    styles={{
                                      root: {
                                        float: 'left',
                                        clear: 'left',
                                        width: '24px',
                                        marginRight: '.25rem',
                                      },
                                    }}
                                    size={PersonaSize.size24}
                                    hidePersonaDetails
                                  />
                                  <Text
                                    style={{
                                      display: 'inline-block',
                                      fontSize: FontSizes.small,
                                      fontWeight: FontWeights.semibold,
                                      lineHeight: '24px',
                                    }}
                                  >
                                    {company?.name ||
                                      company?.legalName ||
                                      company?.domains?.[0].domain}
                                  </Text>
                                </div>
                                <div className="company-attendees">
                                  {companyAttendees.map((a) => (
                                    <div
                                      className={attendeeNameClass}
                                      key={`company-attendee-${a.email}`}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        if (onContactClick) {
                                          onContactClick(a.id);
                                        } else {
                                          if (role && role !== 'GUEST') {
                                            navigate(
                                              `/organization/${organizationSlug}/library/contacts/${a.id}`,
                                            );
                                          }
                                        }
                                        appInsights.trackEvent({
                                          name: 'MEETINGFLOW_CARD_ATTENDEES_TOOLTIP_CLICKED_CONTACT',
                                          properties: {
                                            sub: user?.sub,
                                            name: user?.name,
                                            email: user?.email,
                                            meetingPlanId: meetingflow.id,
                                            contactId: a.id,
                                          },
                                        });
                                      }}
                                    >
                                      {a.name || a.email}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            );
                          })
                        : null}
                      {attendeesWithoutCompany.length ? (
                        <>
                          <Text
                            style={{
                              display: 'inline-block',
                              fontSize: FontSizes.small,
                              fontWeight: FontWeights.semibold,
                              lineHeight: '24px',
                            }}
                          >
                            Unknown Company
                          </Text>
                          <div className="company-attendees">
                            {attendeesWithoutCompany.map((a) => (
                              <div
                                className={attendeeNameClass}
                                key={`no-company-attendee-${a.email}`}
                                onClick={() => {
                                  if (onContactClick) {
                                    onContactClick(a.id);
                                  } else {
                                    if (role && role !== 'GUEST') {
                                      navigate(
                                        `/organization/${organizationSlug}/library/contacts/${a.id}`,
                                      );
                                    }
                                    appInsights.trackEvent({
                                      name: 'MEETINGFLOW_CARD_ATTENDEES_TOOLTIP_CLICKED_CONTACT',
                                      properties: {
                                        sub: user?.sub,
                                        name: user?.name,
                                        email: user?.email,
                                        meetingPlanId: meetingflow.id,
                                        contactId: a.id,
                                      },
                                    });
                                  }
                                }}
                              >
                                {a.name || a.email}
                              </div>
                            ))}
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          }
        >
          {!hideAttendeesIcon ? (
            <FontIcon
              iconName="People"
              className="attendees"
              title="Calendar Event"
            />
          ) : null}
        </TooltipHost>
      );
    }

    return null;
  }, [
    appInsights,
    attendeeNameClass,
    attendeesWithoutCompany,
    companyClass,
    companyNameClass,
    isAssociatedWithEvent,
    meetingflow?.attendees?.length,
    meetingflow?.companies,
    meetingflow?.conferenceInfo?.type,
    meetingflow?.endTime,
    meetingflow?.externalSeriesId,
    meetingflow?.id,
    meetingflow?.organizer,
    meetingflow?.owner,
    meetingflow?.startTime,
    meetingflow?.title,
    navigate,
    onCompanyClick,
    onContactClick,
    onTooltipToggle,
    organizationSlug,
    peopleByDomain,
    role,
    toolTipHostStyles,
    tooltipCalloutProps,
    tooltipHeaderClass,
    user?.email,
    user?.name,
    user?.sub,
    hideEventIcon,
    hideAttendeesIcon,
  ]);

  const conferenceIcon = useMemo(() => {
    if (
      meetingflow &&
      meetingflow?.conferenceInfo &&
      (isNowOrSoon || alwaysShowConferenceJoinButton) &&
      !hideShowConferenceJoinButton
    ) {
      return (
        <TooltipHost
          styles={toolTipHostStyles}
          calloutProps={tooltipCalloutProps}
          onTooltipToggle={(isOpen: boolean) =>
            onTooltipToggle(isOpen, 'CONFERENCE')
          }
          content={
            <>
              <Text
                className={classNames(tooltipHeaderClass, 'join-conference')}
              >
                Join {titleCase(meetingflow?.conferenceInfo?.type || 'Online')}{' '}
                Meeting
              </Text>
            </>
          }
        >
          <FontIcon
            onClick={onConferenceIconClick}
            iconName="Video"
            className="join-conference"
            title="Join Conference"
          />
        </TooltipHost>
      );
    }

    return null;
  }, [
    alwaysShowConferenceJoinButton,
    hideShowConferenceJoinButton,
    isNowOrSoon,
    meetingflow,
    onConferenceIconClick,
    onTooltipToggle,
    toolTipHostStyles,
    tooltipCalloutProps,
    tooltipHeaderClass,
  ]);

  const summaryIcon = useMemo(() => {
    if (meetingflow && meetingflow?.textSummary && !hideSummaryIcon) {
      return (
        <TooltipHost
          styles={toolTipHostBadgeStyles}
          calloutProps={tooltipCalloutProps}
          onTooltipToggle={(isOpen: boolean) =>
            onTooltipToggle(isOpen, 'SUMMARY')
          }
          content={
            <>
              <Text
                className={tooltipHeaderClass}
                style={{
                  backgroundColor: DEALROOMS_COLORS.cloudburst,
                  color: 'white',
                }}
              >
                Meetingflow Summary
              </Text>
              <div className="tooltip-content-container">
                <Text
                  style={{
                    display: 'block',
                    fontWeight: FontWeights.regular,
                    fontSize: FontSizes.small,
                    whiteSpace: 'pre-line',
                  }}
                >
                  {meetingflow.textSummary}
                </Text>
              </div>
            </>
          }
        >
          <FontIcon
            iconName="CannedChat"
            className="summary"
            title="Meetingflow Summary"
          />
        </TooltipHost>
      );
    }

    return null;
  }, [
    meetingflow,
    onTooltipToggle,
    toolTipHostBadgeStyles,
    tooltipCalloutProps,
    tooltipHeaderClass,
    hideSummaryIcon,
  ]);

  const callRecordingIconClass = mergeStyles({
    backgroundColor: `${DEALROOMS_COLORS.cloudburst} !important`,
  });

  const callRecordingIconName = (() => {
    if (meetingflow?.callRecording?.lastStatus) {
      switch (meetingflow?.callRecording?.lastStatus) {
        case 'scheduled': {
          return 'MSNVideos';
        }
        case 'ready': {
          return 'MSNVideos';
        }
        case 'joining_call': {
          return 'MSNVideosSolid';
        }
        case 'in_waiting_room': {
          return 'MSNVideosSolid';
        }
        case 'in_call_not_recording': {
          return 'MSNVideosSolid';
        }
        case 'in_call_recording': {
          return 'MSNVideosSolid';
        }
        case 'call_ended': {
          return 'MSNVideosSolid';
        }
        case 'done': {
          return 'MSNVideosSolid';
        }
        case 'analysis_done': {
          return 'MSNVideosSolid';
        }
        case 'fatal': {
          return 'StatusErrorFull';
        }
        case 'media_expired': {
          return 'MSNVideosSolid';
        }
      }
    }
    return undefined;
  })();

  const callRecordingTooltipMessage = (() => {
    if (meetingflow?.callRecording?.deletedAt) {
      return 'The call recording was deleted';
    }

    switch (meetingflow?.callRecording?.lastStatus) {
      case 'scheduled': {
        return 'This meeting is scheduled to be recorded.';
      }
      case 'ready': {
        return 'This meeting is ready to be recorded.';
      }
      case 'joining_call': {
        return 'Meetingflow Note Taker is joining the call.';
      }
      case 'in_waiting_room': {
        return 'Meetingflow Note Taker is in the waiting room.';
      }
      case 'in_call_not_recording': {
        return 'Meetingflow Note Taker is in the call, but not recording.';
      }
      case 'in_call_recording': {
        return 'Meetingflow Note Taker is in the call and recording.';
      }
      case 'call_ended': {
        return 'The call has ended. The video and analaysis will be available soon.';
      }
      case 'media_expired':
      case 'done': {
        return meetingflow.callRecording.recordingStartedAt
          ? 'Meetingflow Note Taker recorded this call.'
          : 'Meetingflow Note Taker was not able to record the call.';
      }
      case 'analysis_done': {
        return 'Meetingflow Note Taker has finished analyzing the call.';
      }
      case 'fatal': {
        return 'There was an error recording this call.';
      }
      case 'deleted': {
        return 'The call recording was deleted';
      }
      case undefined: {
        return undefined;
      }
    }

    return undefined;
  })();

  const callRecordingIcon = useMemo(() => {
    if (
      meetingflow &&
      meetingflow?.callRecording?.lastStatus &&
      meetingflow?.callRecording.lastStatus !== 'deleted' &&
      !hideCallRecordingIcon
    ) {
      return (
        <TooltipHost
          styles={toolTipHostBadgeStyles}
          calloutProps={tooltipCalloutProps}
          onTooltipToggle={(isOpen: boolean) =>
            onTooltipToggle(isOpen, 'CALL_RECORDING')
          }
          content={
            <>
              <Text
                className={tooltipHeaderClass}
                style={{
                  backgroundColor: DEALROOMS_COLORS.cloudburst,
                  color: 'white',
                }}
              >
                Call Recording
              </Text>
              <div className="tooltip-content-container">
                <Text
                  style={{
                    display: 'block',
                    fontWeight: FontWeights.regular,
                    fontSize: FontSizes.small,
                    whiteSpace: 'pre-line',
                  }}
                >
                  {callRecordingTooltipMessage}
                </Text>
              </div>
            </>
          }
        >
          <FontIcon
            iconName={callRecordingIconName}
            title="Call Recording"
            className={classNames(
              'call-recording',
              `call-recording-${meetingflow.callRecording?.lastStatus}`,
            )}
          />
        </TooltipHost>
      );
    }

    return null;
  }, [
    meetingflow,
    onTooltipToggle,
    toolTipHostBadgeStyles,
    tooltipCalloutProps,
    tooltipHeaderClass,
    hideCallRecordingIcon,
    callRecordingIconName,
    callRecordingTooltipMessage,
  ]);

  const lastLoggedIcon = useMemo(() => {
    if (!!meetingflow?.lastLoggedToCrm?.loggedAt && !hideCRMIcon) {
      return (
        <TooltipHost
          styles={toolTipHostBadgeStyles}
          calloutProps={tooltipCalloutProps}
          onTooltipToggle={(isOpen: boolean) => onTooltipToggle(isOpen, 'CRM')}
          content={
            <>
              <Text
                className={tooltipHeaderClass}
                style={{
                  backgroundColor: MEETINGFLOW_COLORS.teal,
                  color: 'white',
                }}
              >
                Logged To CRM
              </Text>
              <div className="tooltip-content-container">
                <StyledDateTime
                  useRelativeDates
                  dateTime={meetingflow!.lastLoggedToCrm!.loggedAt}
                />{' '}
                <Text
                  style={{
                    display: 'block',
                    fontSize: FontSizes.small,
                  }}
                >
                  by {meetingflow!.lastLoggedToCrm!.loggedBy?.name}
                </Text>
              </div>
            </>
          }
        >
          <div title="Logged to CRM" className="badge logged-to-crm">
            CRM
          </div>
        </TooltipHost>
      );
    }

    return null;
  }, [
    meetingflow,
    onTooltipToggle,
    toolTipHostBadgeStyles,
    tooltipCalloutProps,
    tooltipHeaderClass,
    hideCRMIcon,
  ]);

  const scheduleCallRecording = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (!meetingflow) return;

      if (!meetingflow.conferenceInfo) return;

      if (
        ['ciscoWebEx', 'skypeForBusiness', 'skype'].includes(
          meetingflow.conferenceInfo.type,
        )
      ) {
        switch (meetingflow.conferenceInfo.type) {
          case 'ciscoWebEx':
            toast.error(`WebEx is not a supported platform`);
            return;
          case 'skypeForBusiness':
            toast.error(`Skype For Business is not a supported platform`);
            return;
          case 'skype':
            toast.error(`Skype is not a supported platform`);
            return;
        }
        return;
      }

      const timeToStart = DateTime.fromISO(meetingflow.startTime).diffNow()
        .milliseconds;
      const timeToEnd = DateTime.fromISO(meetingflow.endTime).diffNow()
        .milliseconds;
      const beforeStart = timeToStart > 0;
      const beforeEnd = timeToEnd > 0;

      if (!meetingflow.callRecording && !beforeEnd) return;

      const botStatus = !meetingflow.callRecording
        ? ''
        : meetingflow.callRecording?.lastStatus || 'unknown';

      const headers = (token: string) => ({ Authorization: `Bearer ${token}` });
      const validateStatus = (status: number) => [200, 201].includes(status);
      const deleteStatus = (status: number) => [204].includes(status);

      if (
        beforeEnd &&
        (!meetingflow.callRecording || botStatus === 'deleted')
      ) {
        getAccessTokenSilently().then((token) =>
          toast.promise(
            MeetingflowsApiClient.startOrScheduleCallRecording(
              organizationSlug,
              meetingflow.id,
              undefined,
              {
                headers: headers(token),
                validateStatus,
              },
            ),
            {
              loading: beforeStart
                ? `Scheduling Call Recording`
                : `Starting Call Recording`,
              error: (err) => {
                console.error(err);
                return beforeStart
                  ? `Something went wrong scheduling call recording`
                  : `Something went wrong starting call recording`;
              },
              success: () => {
                refetch();
                appInsights.trackEvent({
                  name: 'CALL_RECORDING_BOT_STATUS_CHANGED',
                  properties: {
                    organizationSlug,
                    meetingPlanId: meetingflow.id,
                    change: beforeStart ? 'scheduled' : 'started',
                  },
                });

                if (
                  workspacePreferences?.orgPreferenceAutomaticCallRecording ===
                    'NONE' &&
                  ['PAID', 'VIP', 'INTERNAL'].includes(organization?.type || '')
                ) {
                  const isExternal = isExternalEventOrMeetingflow(
                    meetingflow,
                    internalDomains,
                  );

                  return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {beforeStart
                        ? `Successfully scheduled call recording`
                        : `Successfully started call recording`}
                      <br />
                      <br />
                      <Link
                        style={{ display: 'contents' }}
                        as={'a'}
                        onClick={() => {
                          if (!updateWorkspacePreferencesAsync) {
                            return;
                          }

                          toast.promise(
                            (async () => {
                              const token = await getAccessTokenSilently();
                              return updateWorkspacePreferencesAsync({
                                orgPreferenceAutomaticCallRecording: isExternal
                                  ? 'EXTERNAL'
                                  : 'ALL',
                              });
                            })(),
                            {
                              loading: 'Enabling automatic call recording',
                              error:
                                'Something went wrong enabling automatic call recording, please try again later',
                              success: () => {
                                appInsights.trackEvent({
                                  name: 'ENABLE_AUTOMATIC_CALL_RECORDING_FROM_TOAST',
                                  properties: {
                                    organizationSlug,
                                    type: isExternal ? 'EXTERNAL' : 'ALL',
                                  },
                                });

                                return (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    Successfully enabled automatic call
                                    recording.
                                    <br />
                                    <br />
                                    You can change this setting at any time in
                                    your{' '}
                                    <Link
                                      style={{ display: 'contents' }}
                                      as={'a'}
                                      onClick={() => {
                                        navigate(
                                          `/organization/${organizationSlug}/settings?tab=user`,
                                        );
                                      }}
                                    >
                                      workspace preferences
                                    </Link>
                                    .
                                  </div>
                                );
                              },
                            },
                          );
                        }}
                      >
                        Click here
                      </Link>{' '}
                      to automatically record all
                      {isExternal ? 'external ' : ''}meetings on your calendar.
                    </div>
                  );
                }

                return beforeStart
                  ? `Successfully scheduled call recording`
                  : `Successfully started call recording`;
              },
            },
            {
              success: {
                duration:
                  workspacePreferences?.orgPreferenceAutomaticCallRecording ===
                    'NONE' &&
                  ['PAID', 'VIP', 'INTERNAL'].includes(organization?.type || '')
                    ? 10000
                    : undefined,
              },
            },
          ),
        );
      } else if (
        meetingflow.callRecording &&
        [
          'scheduled',
          'ready',
          'joining_call',
          'in_waiting_room',
          'in_call_not_recording',
          'in_call_recording',
        ].includes(botStatus)
      ) {
        getAccessTokenSilently().then((token) =>
          toast.promise(
            MeetingflowsApiClient.stopOrCancelCallRecording(
              organizationSlug,
              meetingflow.id,
              {
                headers: headers(token),
                validateStatus: deleteStatus,
              },
            ),
            {
              loading: beforeStart
                ? `Cancelling scheduled call recording`
                : `Stopping call recording`,
              error: (err) => {
                console.error(err);
                return beforeStart
                  ? `Something went wrong cancelling scheduled call recording`
                  : `Something went wrong stopping call recording`;
              },
              success: () => {
                refetch();
                appInsights.trackEvent({
                  name: 'CALL_RECORDING_BOT_STATUS_CHANGED',
                  properties: {
                    organizationSlug,
                    meetingPlanId: meetingflow.id,
                    change: beforeStart ? 'cancelled' : 'stopped',
                  },
                });
                return beforeStart
                  ? `Successfully cancelled scheduled call recording`
                  : `Successfully stopped call recording`;
              },
            },
          ),
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [
      appInsights,
      getAccessTokenSilently,
      internalDomains,
      meetingflow,
      navigate,
      organization?.type,
      organizationSlug,
      refetch,
      updateWorkspacePreferencesAsync,
      workspacePreferences?.orgPreferenceAutomaticCallRecording,
    ],
  );

  const recordLinkClass = mergeStyles({
    display: 'inline-block',
    marginRight: '.25rem',
    color: MEETINGFLOW_COLORS.white,
    cursor: 'pointer',
    fontSize: FontSizes.mini,
    fontWeight: FontWeights.semibold,
    textTransform: 'uppercase',
    backgroundColor: MEETINGFLOW_COLORS.purpleGreyLight,
    lineHeight: '.75rem',
    padding: '0 .25rem .15rem .25rem',
    borderRadius: '.5rem',
    transition: '.3s ease-in-out all',
    boxSizing: 'border-box',

    i: {
      marginRight: '.25rem',
      fontWeight: FontWeights.regular,
      position: 'relative',
      top: '.1rem',
    },

    '&.not-scheduled': {
      backgroundColor: isDark
        ? MEETINGFLOW_COLORS.magenta
        : MEETINGFLOW_COLORS.magenta,

      ':hover': {
        backgroundColor: MEETINGFLOW_COLORS.teal,
      },
    },

    '&.scheduled': {
      backgroundColor: isDark
        ? NeutralColors.gray160
        : MEETINGFLOW_COLORS.purpleGrey,
      color: MEETINGFLOW_COLORS.purpleMedium,

      ':hover': {
        backgroundColor: isDark
          ? MEETINGFLOW_COLORS.purpleDark
          : MEETINGFLOW_COLORS.purpleMedium,
        color: MEETINGFLOW_COLORS.white,
      },
    },
  });

  const callRecordingButton = useMemo(() => {
    if (!meetingflow) {
      return null;
    }

    if (!meetingflow.conferenceInfo) return null;

    if (!meetingflow.conferenceInfo.joinUri) return null;

    if (
      ['ciscoWebEx', 'skypeForBusiness', 'skype'].includes(
        meetingflow.conferenceInfo.type,
      )
    ) {
      return null;
    }

    if (!canCreatePlans) {
      return null;
    }

    if (
      (!meetingflow.callRecording ||
        meetingflow.callRecording.lastStatus === 'deleted') &&
      beforeStart &&
      beforeEnd &&
      meetingflow?.conferenceInfo?.joinUri
    ) {
      return scheduleCallRecording ? (
        <span
          className={classNames(recordLinkClass, 'not-scheduled')}
          onClick={scheduleCallRecording}
        >
          <FontIcon iconName={'CircleFill'} />
          Record
        </span>
      ) : null;
    }

    if (
      !meetingflow.callRecording &&
      !beforeStart &&
      beforeEnd &&
      meetingflow?.conferenceInfo?.joinUri
    ) {
      return scheduleCallRecording ? (
        <span
          className={classNames(recordLinkClass, 'not-scheduled')}
          onClick={scheduleCallRecording}
        >
          <FontIcon iconName={'CircleFill'} />
          Record
        </span>
      ) : null;
    } else if (
      meetingflow.callRecording &&
      !!meetingflow?.callRecording?.lastStatus &&
      meetingflow?.conferenceInfo?.joinUri
    ) {
      switch (meetingflow?.callRecording?.lastStatus) {
        case 'scheduled': {
          return (
            <span
              className={classNames(recordLinkClass, 'scheduled')}
              onClick={scheduleCallRecording}
            >
              <FontIcon iconName={'CircleStopSolid'} />
              Cancel
            </span>
          );
        }
        case 'ready':
        case 'joining_call':
        case 'in_waiting_room':
        case 'in_call_not_recording':
        case 'in_call_recording': {
          return null;
        }
        case 'call_ended': {
          return null;
        }
        case 'done':
        case 'analysis_done': {
          return null;
        }
        case 'media_expired': {
          return null;
        }
        case 'fatal': {
          return null;
        }
        case 'deleted': {
          return null;
        }
      }
    }

    return null;
  }, [
    beforeEnd,
    beforeStart,
    meetingflow,
    scheduleCallRecording,
    recordLinkClass,
    canCreatePlans,
  ]);

  const card = (
    <div
      title={cardTitleAttr || undefined}
      className={meetingflowCardClass}
      style={{
        cursor: onClick ? 'pointer' : undefined,
      }}
      onClick={() => onClick && onClick(meetingflowId)}
    >
      {showCreator ? (
        <div className={'meetingflow-creator'}>
          <Persona
            text={meetingflow?.owner?.name || meetingflow?.owner?.email}
            size={PersonaSize.size32}
            hidePersonaDetails
            coinProps={{
              styles: {
                initials: {
                  color: 'white !important',
                },
              },
            }}
          />
        </div>
      ) : null}

      <div className="card-content">
        {!inlineIcons ? (
          <div className="meetingflow-icons">
            {conferenceIcon}
            {callRecordingIcon}
            {summaryIcon}
            {lastLoggedIcon}
            {eventIcon}
          </div>
        ) : null}

        <Text
          className="meetingflow-title"
          // onClick={() => onClick && onClick(meetingflowId)}
          aria-label={meetingflow?.title}
          title={meetingflow?.title}
        >
          {showCompanies &&
          externalCompanies?.length &&
          externalCompanies?.length < MAX_NUM_COMPANY_LOGOS_INLINE ? (
            <div
              style={{
                display: 'inline-flex',
                height: '20px',
                position: 'relative',
                top: '2px',
              }}
            >
              {externalCompanies && organizationSlug
                ? // @ts-ignore
                  externalCompanies.map((c: Company) => (
                    <CompanyCard
                      key={c.id}
                      company={c}
                      organizationSlug={organizationSlug}
                      logoOnly
                      // onClick={onCompanyCardClick}
                      logoSize="16px"
                      personaSize={PersonaSize.size16}
                    />
                  ))
                : null}
            </div>
          ) : null}
          {noTitleLink ? (
            meetingflow?.title
          ) : (
            <ModifierAwareLink
              defaultToNewTab={false}
              href={`/organization/${organizationSlug}/dealroom/${dealRoomId}/plan/${meetingflowId}`}
              as={'a'}
            >
              {meetingflow?.title}
            </ModifierAwareLink>
          )}
        </Text>

        {inlineIcons ? (
          <div className="meetingflow-icons">
            {conferenceIcon}
            {callRecordingIcon}
            {summaryIcon}
            {lastLoggedIcon}
            {eventIcon}
          </div>
        ) : null}

        <Text
          className="meetingflow-datetime"
          onClick={() => onClick && onClick(meetingflowId)}
        >
          {meetingflow?.startTime ||
          (meetingflow?.location && !meetingflow.conferenceInfo?.joinUri) ? (
            <div
              style={{
                display: 'block',
                color: NeutralColors.gray120,
                width: `calc(100% - ${titlePadding})`,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                boxSizing: 'content-box',
              }}
            >
              {showCallRecordingButton &&
              hasEntitlement('CALL_RECORDING') &&
              canCreatePlans
                ? callRecordingButton
                : null}

              <StyledDateTime
                useRelativeDates
                dateTime={meetingflow?.startTime}
                displayComponents={showTimesOnly ? ['time'] : ['date', 'time']}
              />

              {meetingflow?.endTime && showEndTime ? (
                <>
                  {'–'}{' '}
                  <StyledDateTime
                    dateTime={meetingflow?.endTime}
                    displayComponents={['time']}
                    includeTimeZone
                  />
                </>
              ) : null}

              {showLocation &&
              meetingflow?.location &&
              !meetingflow.conferenceInfo?.joinUri ? (
                <div title={meetingflow?.location}>{meetingflow?.location}</div>
              ) : null}
            </div>
          ) : null}
        </Text>

        {showCompanies &&
        externalCompanies?.length &&
        externalCompanies?.length > MAX_NUM_COMPANY_LOGOS_INLINE ? (
          <div
            style={{
              display: 'flex',
              height: '20px',
              marginTop: '.5rem',
            }}
          >
            {externalCompanies && organizationSlug
              ? // @ts-ignore
                externalCompanies.map((c: Company) => (
                  <CompanyCard
                    key={c.id}
                    company={c}
                    organizationSlug={organizationSlug}
                    logoOnly
                    onClick={onCompanyCardClick}
                    logoSize="16px"
                    personaSize={PersonaSize.size16}
                  />
                ))
              : null}
          </div>
        ) : null}
      </div>
    </div>
  );

  if (expandable) {
    return <div className={expandableWrapperClass}>{card}</div>;
  }
  return (
    <MeetingflowCardRoot>
      <div className="meetingflow-actions">
        <IconButton
          onClick={handleMenuClick}
          size="small"
          sx={{ ml: 1 }}
          aria-controls={open ? 'meetingflow-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="meetingflow-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          onClick={(e) => e.stopPropagation()}
        >
          {dealRoomRole === 'SELLER' && (
            <MenuItem onClick={handleFeature}>
              {isArtifactFeatured ? (
                <Star sx={{ mr: 1 }} />
              ) : (
                <StarOutlined sx={{ mr: 1 }} />
              )}
              {isArtifactFeatured
                ? 'Remove from Shared With You'
                : 'Feature in Shared With You'}
            </MenuItem>
          )}
          <MenuItem onClick={handleRemoveFromDecisionSite}>
            <DeleteIcon sx={{ mr: 1 }} />
            Remove from Decision Site
          </MenuItem>
          <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
            <DeleteIcon sx={{ mr: 1 }} />
            Delete Meetingflow
          </MenuItem>
        </Menu>
      </div>
      <div className="meetingflow-card">{card}</div>
    </MeetingflowCardRoot>
  );
};
