import { useBoolean } from '@fluentui/react-hooks';
import { useEffect } from 'react';

export const useNetworkStatus = (): boolean => {
  const [isOnline, { setTrue: setOnline, setFalse: setOffline }] = useBoolean(
    typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
      ? navigator.onLine
      : true,
  );

  useEffect(() => {
    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);

    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, [setOffline, setOnline]);

  return isOnline;
};
