import React from 'react';
import { CheckmarkIcon } from 'react-hot-toast';
import { getStepStyles } from './styles';

interface Step {
  stepArray: {
    value: number;
    label: string;
  }[];
  currentStep: number;
}

export const StepCounter = ({
  stepArray,
  currentStep
}: Step) => {
  const styles = getStepStyles();

  return (
    <div className={styles.stepContainer}>
      {stepArray.map((step) => (
        <span
          key={`step_${step.value}`}
          className={
            step.value < currentStep
              ? 'completed'
              : step.value === currentStep
                ? 'selected'
                : ''
          }
        >
            {step.value < currentStep && (
              <CheckmarkIcon className={styles.completedStepCheckmark} />
            )}
          {step.value >= currentStep && step.label}
          </span>
      ))}
    </div>
  );
};