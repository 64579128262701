import { useEffect, useMemo, useState } from 'react';

export type InteractionMode = 'TOUCH' | 'POINTER';
export const useInteractionMode = () => {
  const [currentInteractionMode, setCurrentInteractionMode] =
    useState<InteractionMode>('POINTER');

  const supportsTouch = useMemo(
    () => 'ontouchstart' in window || navigator.maxTouchPoints,
    [],
  );

  useEffect(() => {
    const root = document.getElementById('root')!;
    const onMouseEvent = () => {
      setCurrentInteractionMode('POINTER');
    };
    const onTouchEvent = () => {
      setCurrentInteractionMode('TOUCH');
    };
    if (supportsTouch) {
      root.addEventListener('mouseenter', onMouseEvent);
      root.addEventListener('touchstart', onTouchEvent);
    }
    return () => {
      if (supportsTouch) {
        root.removeEventListener('mouseenter', onMouseEvent);
        root.removeEventListener('touchstart', onTouchEvent);
      }
    };
  }, [supportsTouch]);

  return { supportsTouch, currentInteractionMode };
};
