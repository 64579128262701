import React from 'react';
import Menu, { MenuProps } from '@mui/material/Menu';
import { DSMenuRoot } from './DSMenu.styles';

export interface DSMenuProps extends MenuProps {
  children?: React.ReactNode;
}

const DSMenu: React.FC<DSMenuProps> = ({ children, ...props }) => {
  return <DSMenuRoot {...props}>{children}</DSMenuRoot>;
};

export default DSMenu;
