import { useBoolean } from '@fluentui/react-hooks';
import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';

const DEBUG = false; // true;

export const useScrollToBottom = (
  ref: React.RefObject<Element>,
  enabled?: boolean,
) => {
  const [refSet, { setTrue: setRefSet, setFalse: setRefNotSet }] = useBoolean(
    !!ref.current,
  );
  const [userScrolled, setUserScrolled] = useBoolean(false);
  const lastScrollHeightRef = useRef(0);
  const isScrollingRef = useRef(false);

  useLayoutEffect(() => {
    if (ref.current && !refSet) {
      setRefSet();
    } else if (!ref.current && refSet) {
      setRefNotSet();
    }
  });

  const scrollToBottom = useCallback(() => {
    if (ref.current) {
      const { scrollTop, scrollHeight, clientHeight } = ref.current;
      const bottomThreshold = 1;

      if (
        Math.abs(scrollHeight - clientHeight - scrollTop) <= bottomThreshold
      ) {
        // Already at the bottom, no need to scroll
        return;
      }

      if (scrollHeight === lastScrollHeightRef.current) {
        // Content height hasn't changed, no need to scroll
        return;
      }

      if (DEBUG) {
        console.info(`Scrolling to bottom: ${scrollTop} -> ${scrollHeight}`);
      }

      isScrollingRef.current = true;
      ref.current.scrollTop = scrollHeight;
      lastScrollHeightRef.current = scrollHeight;
      setTimeout(() => (isScrollingRef.current = false), 100);
    }
  }, [ref]);

  useEffect(() => {
    const { current } = ref;

    if (!current) {
      return;
    }

    const handleScroll = () => {
      if (isScrollingRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = current;
      const scrolledToBottom =
        Math.abs(scrollHeight - clientHeight - scrollTop) < 1;
      if (DEBUG) {
        console.info(`Setting userScrolled to ${!scrolledToBottom}`);
      }
      scrolledToBottom ? setUserScrolled.setFalse() : setUserScrolled.setTrue();
    };

    current.addEventListener('scroll', handleScroll);

    return () => {
      current.removeEventListener('scroll', handleScroll);
    };
  }, [ref, refSet, setUserScrolled]);

  useLayoutEffect(() => {
    if (ref?.current && enabled && !userScrolled) {
      scrollToBottom();
    }
  });

  return {
    scrollToBottom,
    isUserScrolled: userScrolled,
  };
};
