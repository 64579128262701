import { IconButton, TextField, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useAddDealRoomArtifactDialog } from '../../../../Hooks/Modals/DealRoom/useAddDealRoomArtifactDialog';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDealRoom } from '../../../../Hooks/useDealRoom';
import { useNavigate } from '../../../../Hooks/useNavigate';
import { useArtifactSidebarStyles } from './useSidebarStyles';
import { AttachmentsList } from './AttachmentsList';
import {
  AddArtifactIconProps,
  ChevronRightMedIconProps,
  ZoomIconProps,
} from '../../../../utils/iconProps';
import { useConfirmationDialog } from '../../../../Hooks/Modals/useConfirmationDialog';
import { ArtifactContextualMenu } from './ArtifactContextualMenu';
import DealRoomAsyncCommandBarButton from '../../Components/DealRoomButton';
import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { DealRoomsApiClient } from '../../../../Services/NetworkCommon';
import { useLocalStorageState } from '../../../../Hooks/useLocalStorageState';
import useDeviceType from '../../../../Hooks/deviceDetection';

type ArtifactsSidebarProps = {
  organizationSlug: string;
  dealRoomId: number;
  artifactId: number;
};

export const ArtifactsSidebar = ({
  organizationSlug,
  dealRoomId,
  artifactId,
}: ArtifactsSidebarProps) => {
  const { isMobile, isTablet } = useDeviceType();

  const panelRef = useRef<HTMLDivElement>(null);
  const resizerRef = useRef<HTMLDivElement | null>(null);

  const [isSidebarOpen, setIsSidebarOpen] = useLocalStorageState(
    'artifactsSidebarOpen',
    true,
  );

  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen(!isSidebarOpen);
  }, [isSidebarOpen, setIsSidebarOpen]);

  const [
    showArtifactMenu,
    {
      setTrue: setShowArtifactMenu,
      setFalse: setHideArtifactMenu,
      toggle: toggleShowUserMenu,
    },
  ] = useBoolean(false);

  const [searchedTerm, setSearchedTerm] = useState('');
  const [contextMenuTargetId, setContextMenuTargetId] = useState<number | null>(
    null,
  );

  const styles = useArtifactSidebarStyles(isSidebarOpen, isMobile, isTablet);

  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  const {
    createDeferred: showAddDealRoomArtifactDialog,
    dialog: addDealRoomArtifactDialog,
  } = useAddDealRoomArtifactDialog({
    organizationSlug,
    dealRoomId,
  });
  const {
    createDeferred: createConfirmDeleteDeferred,
    dialog: confirmDeleteDialog,
  } = useConfirmationDialog({
    title: `Delete Artifact?`,
    subText:
      'Deleting this Artifact will delete it for all users. It will be restorable for 30 days. Are you sure you want to delete this Artifact?',
    primaryAction: 'CANCEL',
    removeWhiteLabel: true,
  });

  const { dealRoom, refetch: refetchDealRoom } = useDealRoom(
    organizationSlug,
    dealRoomId,
  );

  const {
    data: allArtifactsData,
    isLoading: isArtifactsLoading,
    refetch: refetchArtifacts,
  } = useQuery(['artifacts', dealRoomId, organizationSlug], async () => {
    const token = await getAccessTokenSilently();
    return DealRoomsApiClient.listArtifacts(
      { organizationSlug, dealRoomId, includeDeleted: true },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  const filteredArtifacts = useMemo(() => {
    if (Array.isArray(allArtifactsData?.data)) {
      return allArtifactsData?.data.filter((artifact) => {
        const fileName = ('fileName' in artifact ? artifact.fileName || '' : '')
          .toLowerCase()
          .trim();
        const name = (artifact.name || '').toLowerCase().trim();

        const currentSearchedTerm = searchedTerm.toLowerCase().trim();

        return (
          (fileName.includes(currentSearchedTerm) ||
            name.includes(currentSearchedTerm)) &&
          !artifact.deletedAt
        );
      });
    }
    return [];
  }, [searchedTerm, allArtifactsData?.data]);

  const handleOpenAddArtifact = useCallback(async () => {
    try {
      const result = await showAddDealRoomArtifactDialog().promise;
      if (result) {
        await refetchDealRoom();
      }
    } catch (error) {
      // no file selected
    }
  }, [refetchDealRoom, showAddDealRoomArtifactDialog]);

  const handleArtifactClick = useCallback(
    (newArtifactId: number) => {
      if (isMobile || isTablet) setIsSidebarOpen(false);
      navigate(
        `/organization/${organizationSlug}/dealroom/${dealRoomId}/artifact/${newArtifactId}`,
        { preserveQuery: true },
      );
    },
    [
      dealRoomId,
      isMobile,
      isTablet,
      navigate,
      organizationSlug,
      setIsSidebarOpen,
    ],
  );

  const handleDismissContextualMenu = useCallback(() => {
    setHideArtifactMenu();
    setContextMenuTargetId(null);
  }, [setHideArtifactMenu, setContextMenuTargetId]);

  // -- RESIZING FUNCTIONALITY --
  useEffect(() => {
    // Get the resizable element and the resizer element
    const resizeableEle = panelRef.current;
    const resizerEle = resizerRef.current;

    // Guard clause: return if elements are not present
    if (!resizeableEle || !resizerEle) return;

    // Get the current computed styles for the resizable element
    const styles = window.getComputedStyle(resizeableEle);

    // Initialize width and x variables
    let width = parseInt(styles.width, 10);
    let x = 0;

    // Function to handle mouse movement for resizing
    const onMouseMoveRightResize = (event: MouseEvent) => {
      event.preventDefault();

      // Calculate the change in x direction
      const dx = event.clientX - x;

      // event.clientX is the horizontal coordinate within the application's client area at which the event occurred (as opposed to the coordinate within the page)
      x = event.clientX;

      // Define maximum and minimum rem sizes for the panel
      const maxRem = 29;
      const minRem = 18.5;

      // Convert rem to pixels based on the root font size
      const remToPx = parseFloat(
        getComputedStyle(document.documentElement).fontSize,
      );

      // Calculate the new width, ensuring it remains within specified bounds
      width = Math.min(
        maxRem * remToPx,
        Math.max(minRem * remToPx, width + dx),
      );

      // Set the new width in rems
      resizeableEle.style.width = `${width / remToPx}rem`;
    };

    // Function to handle mouse up event, stopping the resize
    const onMouseUpRightResize = (event: MouseEvent) => {
      event.preventDefault();

      // Restore transition for smooth effect
      resizeableEle.style.transition = 'all 0.5s linear';

      // Remove event listeners for mousemove and mouseup
      document.removeEventListener('mousemove', onMouseMoveRightResize);
      document.removeEventListener('mouseup', onMouseUpRightResize);
    };

    // Function to handle mouse down event, starting the resize
    const onMouseDownRightResize = (event: MouseEvent) => {
      event.preventDefault();

      // Capture the initial x position
      x = event.clientX;

      // Disable transition for immediate effect
      resizeableEle.style.transition = 'none';

      // Add event listeners for mousemove and mouseup to handle resizing
      document.addEventListener('mousemove', onMouseMoveRightResize);
      document.addEventListener('mouseup', onMouseUpRightResize);
    };

    // Remove any existing mousedown event listener to avoid duplicates
    resizerEle.removeEventListener('mousedown', onMouseDownRightResize);

    // Add mousedown listener to start resize on user interaction
    resizerEle.addEventListener('mousedown', onMouseDownRightResize);

    // Set initial width
    resizeableEle.style.width = isSidebarOpen ? '16.5rem' : '1rem';

    // Cleanup function to remove the mousedown listener
    return () => {
      resizerEle.removeEventListener('mousedown', onMouseDownRightResize);
    };
  }, [isSidebarOpen]);

  return (
    <>
      {(isMobile || isTablet) && (
        <div className={styles.sidePanelDummyForMobile} />
      )}
      <div className={`${styles.sidePanelContentWrapper} `} ref={panelRef}>
        <IconButton
          className={styles.expandButton}
          iconProps={ChevronRightMedIconProps}
          onClick={toggleSidebar}
        />
        <div className={styles.resizer} ref={resizerRef} />
        <div
          className={`${styles.sidePanelContent} ${isSidebarOpen ? styles.sidePanelContentVisible : ''}`}
        >
          <DealRoomAsyncCommandBarButton
            customClasses={styles.addAttachment}
            onClick={handleOpenAddArtifact}
            text="New Artifact"
            buttonStyleType="DEAL_ROOM"
            iconProps={AddArtifactIconProps}
          />
          <div className={styles.searchContainer}>
            <IconButton
              className={styles.searchButton}
              iconProps={ZoomIconProps}
            />
            <TextField
              value={searchedTerm}
              onChange={(e, newValue) => setSearchedTerm(newValue || '')}
              className={styles.searchInput}
              placeholder="Search Artifacts"
            />
          </div>
          <Text className={styles.attachmentListTitle}>Your Artifacts</Text>
          <div className={styles.attachmentListContainer}>
            <AttachmentsList
              attachmentList={filteredArtifacts}
              artifactId={artifactId}
              handleArtifactClick={handleArtifactClick}
              setShowArtifactMenu={setShowArtifactMenu}
              setContextMenuTargetId={setContextMenuTargetId}
              loading={isArtifactsLoading}
            />
          </div>
          <div className={styles.endLine}></div>
        </div>
        <ArtifactContextualMenu
          showArtifactMenu={showArtifactMenu}
          contextMenuTargetId={contextMenuTargetId}
          handleDismissContextualMenu={handleDismissContextualMenu}
          artifactId={artifactId}
          organizationSlug={organizationSlug}
          dealRoomId={dealRoomId}
          createConfirmDeleteDeferred={createConfirmDeleteDeferred}
        />
        {addDealRoomArtifactDialog}
        {confirmDeleteDialog}
      </div>
    </>
  );
};
