import React from 'react';
import { Stack, Text, PrimaryButton } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

type DSConfigurationHeaderRowProps = {
    title: string;
    description: string;
    children: React.ReactNode;
};

export const DSConfigurationHeaderRow = ({
    title,
    description,
    children
}: DSConfigurationHeaderRowProps) => {
    const styles = {
        description: {
            color: DEALROOMS_COLORS.neutralSecondary,
        },
    };

    return (
        <Stack horizontal verticalAlign='start' horizontalAlign="space-between" styles={{ root: { width: '100%' } }}>
            <Stack tokens={{ childrenGap: 8 }}>
                <Text variant="xLarge" block>{title}</Text>
                <Text style={styles.description}>{description}</Text>
            </Stack>
            <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }}>
                {children}
            </Stack>
        </Stack>
    );
};