import { BaseSidePanel } from './BaseSidePanel';
import {
  Contact,
  Meetingflow,
  Task,
} from '@meetingflow/common/Api/data-contracts';
import { Y } from '@syncedstore/core';
import { FontSizes, FontWeights, NeutralColors, Text } from '@fluentui/react';
import ShareDialogInviteShareSelector, {
  useShareDialogInviteShareSelectorState,
} from '../Dialogs/ShareDialogInviteShareSelector';

export interface InviteAndNotifySidePanelProps {
  meetingPlan: Meetingflow;
  organizationSlug: string;
  notesYArray: Y.XmlText;
  tasks: Task[];
  internalDomains: string[];
  context?: CollaborateDefaultContext;
  onDone: () => void;
  onBack?: () => void;
  onClose: () => void;
}

type CollaborateDialogPivot =
  | 'InviteShare'
  | 'EmailShare'
  | 'PrepMeeting'
  | 'ShareAccess';

interface CollaborateDialogContextInterface {
  type: CollaborateDialogPivot;
}
interface CollaborateInviteShareContext
  extends CollaborateDialogContextInterface {
  type: 'InviteShare';
  contact?: Contact;
}

interface CollaborateEmailShareContext
  extends CollaborateDialogContextInterface {
  type: 'EmailShare';
}
interface CollaboratePrepMeetingContext
  extends CollaborateDialogContextInterface {
  type: 'PrepMeeting';
}

interface CollaborateShareAccessContext
  extends CollaborateDialogContextInterface {
  type: 'ShareAccess';
}

type CollaborateDefaultContext =
  | CollaborateInviteShareContext
  | CollaborateEmailShareContext
  | CollaboratePrepMeetingContext
  | CollaborateShareAccessContext;

export const InviteAndNotifySidePanel = ({
  meetingPlan,
  organizationSlug,
  notesYArray,
  tasks,
  internalDomains,
  context,
  onBack,
  onClose,
  onDone,
}: InviteAndNotifySidePanelProps) => {
  const invitePivotState = useShareDialogInviteShareSelectorState({
    organizationSlug,
    meetingPlanId: meetingPlan.id,
    meetingPlan,
    internalDomains,
    hideExternalAttendees: true,
    defaultSelectedContactEmail:
      context?.type === 'InviteShare' ? context.contact?.email : undefined,
  });

  return (
    <BaseSidePanel title="Share Meetingflow" onBack={onBack} onClose={onClose}>
      <Text
        block
        style={{
          fontSize: FontSizes.small,
          color: NeutralColors.gray120,
        }}
      >
        Sharing your Meetingflow informs others about it,{' '}
        <strong style={{ fontWeight: FontWeights.semibold }}>
          and allows them to collaborate on it with you
        </strong>
        . If a person you share with is not currently a member of Meetingflow or
        your Meetingflow workspace, they will be invited to join.
      </Text>
      {meetingPlan ? (
        <ShareDialogInviteShareSelector
          key="InviteShare"
          organizationSlug={organizationSlug}
          meetingPlanId={meetingPlan.id}
          meetingPlan={meetingPlan}
          internalDomains={internalDomains}
          onConfirm={onDone}
          onCancel={onClose}
          notesYArray={notesYArray}
          tasks={tasks}
          {...invitePivotState}
        />
      ) : null}
    </BaseSidePanel>
  );
};
