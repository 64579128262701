import { useAuth0 } from '@auth0/auth0-react';
import { DealRole } from '@meetingflow/common/Api/data-contracts';
import { useCallback, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { isBadRequest, isForbiddenError } from '../Helpers/AxiosHelpers';
import { OrganizationDealRoomQuery } from '../QueryNames';
import { DealRoomsApiClient } from '../Services/NetworkCommon';
import { useDealRoomId } from './useDealRoomId';
import { useOrganization } from './useOrganization';
import { useOrganizationSlug } from './useOrganizationSlug';
import { useUserProfile } from './useProfile';

export const useDealRoom = (orgSlug?: string, dealRoomId?: number) => {
  const { getAccessTokenSilently } = useAuth0();
  const client = useQueryClient();

  const { userId, user: mfUser } = useUserProfile();
  const { role: orgRole } = useOrganization(orgSlug);

  const slg = useOrganizationSlug();
  const orgSlugFinal = orgSlug || slg;
  const drId = useDealRoomId();
  const dealRoomIdFinal = dealRoomId || drId;

  const {
    data: dealRoomData,
    isLoading: dealRoomLoading,
    isFetched: dealRoomIsFetched,
    isError: dealRoomIsError,
    error: dealRoomError,
    refetch: refetchDealRoom,
  } = useQuery(
    OrganizationDealRoomQuery(orgSlugFinal, dealRoomIdFinal),
    async () => {
      const token = await getAccessTokenSilently();
      return DealRoomsApiClient.getDealRoom(orgSlugFinal!, dealRoomIdFinal!, {
        headers: { Authorization: `Bearer ${token}` },
      });
    },
    {
      enabled: !!orgSlugFinal && !!dealRoomIdFinal && !isNaN(dealRoomIdFinal),
      retry: (failureCount, error) => {
        if (isForbiddenError(error)) {
          return false;
        }
        if (isBadRequest(error)) {
          return false;
        }

        return failureCount < 3;
      },
    },
  );

  const dealRoomRole: DealRole | undefined = useMemo(() => {
    if (!dealRoomData?.data || !mfUser || !userId) {
      return undefined;
    }

    const contact = dealRoomData?.data?.contacts?.find(
      (c) => c.email === mfUser?.email || c.userId === userId,
    );

    if (contact?.role) {
      return contact.role;
    }

    return ['ADMIN', 'CREATOR', 'COLLABORATOR'].includes(orgRole || '')
      ? 'SELLER'
      : 'BUYER';
  }, [dealRoomData?.data, mfUser, orgRole, userId]);

  const refetch = useCallback(async () => {
    client.invalidateQueries(
      ['artifacts', dealRoomIdFinal, orgSlugFinal],
    );
    await refetchDealRoom();
  }, [refetchDealRoom, client, dealRoomIdFinal, orgSlugFinal]);

  const refetchAll = useCallback(async () => {
    await client.invalidateQueries(
      OrganizationDealRoomQuery(orgSlugFinal, dealRoomIdFinal),
    );
  }, [client, dealRoomIdFinal, orgSlugFinal]);

  return {
    dealRoomId: dealRoomIdFinal,
    dealRoom: dealRoomData?.data,
    dealRoomRole,
    isLoading: dealRoomLoading,
    isFetched: dealRoomIsFetched,
    isError: dealRoomIsError,
    error: dealRoomError,
    refetch,
    refetchAll,
  };
};
