import { useAuth0 } from '@auth0/auth0-react';
import {
  ITheme,
  mergeStyleSets,
  Spinner,
  Text,
  useTheme,
} from '@fluentui/react';
import { DocumentIcon, EmptyRecycleBinIcon } from '@fluentui/react-icons-mdl2';
import {
  DealRoomArtifact,
  DetailedDealRoom,
} from '@meetingflow/common/Api/data-contracts';
import { DeduplicateArray } from '@meetingflow/common/ArrayHelpers';
import classNames from 'classnames';
import { lazy, useCallback, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { isBadRequest, isForbiddenError } from '../../../Helpers/AxiosHelpers';
import { useAddDealRoomArtifactDialog } from '../../../Hooks/Modals/DealRoom/useAddDealRoomArtifactDialog';
import { useNavigate } from '../../../Hooks/useNavigate';
import { useTitle } from '../../../Hooks/useTitle';
import { OrganizationDealRoomArtifactsQuery } from '../../../QueryNames';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { DropTarget } from '../../Common/DropTarget';
import { AudioArtifactCard } from './AudioArtifactCard';
import { DealRoomArtifactsTable } from './DealRoomArtifactsTable';
import { DocumentArtifactCard } from './DocumentArtifactCard';
import { FileArtifactCard } from './FileArtifactCard';
import { ImageArtifactCard } from './ImageArtifactCard';
import { LinkArtifactCard } from './LinkArtifactCard';
import { MeetingflowArtifactCard } from './MeetingflowArtifactCard';
import { VideoArtifactCard } from './VideoArtifactCard';

const DealRoomAsyncCommandBarButton = lazy(
  () => import('../Components/DealRoomButton'),
);

type DealRoomArtifactsProps = {
  className?: string;
  artifactContainerClassName?: string;
  organizationSlug: string;
  dealRoomId: number;
  featured?: boolean;
  includeDeleted?: boolean;
  showAdd?: boolean;
  activity?: DetailedDealRoom['activity'];
  artifacts?: DetailedDealRoom['artifacts'];
  refreshDealRoom: () => Promise<unknown>;
  onClick?: (id: DealRoomArtifact['id']) => unknown;
  onAdd?: (item: DealRoomArtifact) => Promise<unknown>;
  onUpdate?: (idx: number | string, props: Partial<DealRoomArtifact>) => void;
  onDelete?: (id: DealRoomArtifact['id']) => Promise<unknown>;
  useTableView?: boolean;
  includeMeetingflows?: boolean;
};

export const DealRoomArtifacts = ({
  className,
  artifactContainerClassName,
  organizationSlug,
  dealRoomId,
  featured,
  includeDeleted,
  showAdd,
  activity,
  artifacts,
  onClick,
  onAdd,
  onUpdate,
  onDelete,
  useTableView,
  includeMeetingflows,
}: DealRoomArtifactsProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const {
    createDeferred: showAddDealRoomArtifactDialog,
    dialog: addDealRoomArtifactDialog,
  } = useAddDealRoomArtifactDialog({
    organizationSlug,
    dealRoomId,
  });

  const navigate = useNavigate();

  useTitle('Artifacts');

  const {
    data: dealRoomArtifacts,
    isLoading: artifactsLoading,
    refetch: refetchArtifacts,
  } = useQuery(
    OrganizationDealRoomArtifactsQuery(
      organizationSlug,
      dealRoomId,
      featured,
      includeDeleted,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return DealRoomsApiClient.listArtifacts(
        { organizationSlug, dealRoomId, featured, includeDeleted },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !artifacts && !!organizationSlug && !!dealRoomId,
      retry: (failureCount, error) => {
        if (isForbiddenError(error)) {
          return false;
        }
        if (isBadRequest(error)) {
          return false;
        }

        return failureCount < 3;
      },
    },
  );

  useEffect(() => {
    refetchArtifacts();
  }, [featured, includeDeleted, refetchArtifacts]);

  const drArtifacts = useMemo(() => {
    if (artifacts && featured) {
      return artifacts
        .filter((a) => a.featuredAt)
        .sort((a, b) => {
          if (a.placement === 'WELCOME') return -1;
          if (b.placement === 'WELCOME') return 1;
          return 0;
        });
    }

    if (artifacts) {
      return artifacts;
    }
    return dealRoomArtifacts?.data || [];
  }, [artifacts, dealRoomArtifacts?.data, featured]);

  const refetch = useCallback(async () => {
    await Promise.all([refetchArtifacts()]);
  }, [refetchArtifacts]);

  const handleOnClick = useCallback(
    (id: DealRoomArtifact['id']) => {
      if (onClick) {
        onClick(id);
      }

      const artifact = drArtifacts.find((a) => a.id === id);

      if (!artifact) {
        return;
      }

      switch (artifact.type) {
        case 'MEETINGFLOW': {
          navigate(
            `/organization/${organizationSlug}/dealroom/${dealRoomId}/plan/${artifact.meetingflowId}`,
            {
              preserveQuery: true,
            },
          );
          break;
        }
        default: {
          navigate(
            `/organization/${organizationSlug}/dealroom/${dealRoomId}/artifact/${id}`,
            { preserveQuery: true },
          );
        }
      }
    },
    [dealRoomId, drArtifacts, navigate, onClick, organizationSlug],
  );

  const handleOnDelete = useCallback(
    async (id: DealRoomArtifact['id']) => {
      await refetch();
      await onDelete?.(id);
    },
    [onDelete, refetch],
  );

  const handleOnUpdate = useCallback(
    async (idx: number | string, props: Partial<DealRoomArtifact>) => {
      await refetch();
      onUpdate?.(idx, props);
    },
    [onUpdate, refetch],
  );

  const generateStyles = useCallback(
    (theme: ITheme) => {
      const { palette } = theme;
      return mergeStyleSets({
        containerStyle: {
          height: '100%',
          backgroundColor: useTableView
            ? DEALROOMS_COLORS.white
            : DEALROOMS_COLORS.neutralGray,
          display: 'flex',
          flexDirection: 'column',
          padding: useTableView ? '2rem' : '2rem',
          gap: '2rem',
          borderRadius: '0.25rem',
          border: `1px solid ${palette.neutralLight}`,
        },

        noArtifactsStyle: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          minHeight: '10rem',
        },

        artifactContainerStyle: {
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
          gap: '1rem',
        },
        actionButtonsContainer: {
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
        },
      });
    },
    [useTableView],
  );

  const addAttachmentButtonStyles = useMemo(() => {
    return {
      root: {
        padding: '0',
        minWidth: '2rem',
        backgroundColor: DEALROOMS_COLORS.cloudburst,
        border: 'none',
        transition: 'all 0.3s ease-in-out',
      },
      rootHovered: {
        border: `1px solid ${DEALROOMS_COLORS.woodsmoke}`,
        backgroundColor: DEALROOMS_COLORS.woodsmoke,
      },
      rootPressed: {
        border: `1px solid ${DEALROOMS_COLORS.woodsmoke}`,
      },
      rootFocused: {
        border: `1px solid ${DEALROOMS_COLORS.woodsmoke}`,
      },
      rootExpanded: {
        border: `1px solid ${DEALROOMS_COLORS.woodsmoke}`,
      },
      textContainer: {
        color: DEALROOMS_COLORS.white,
      },
      icon: {
        color: `${DEALROOMS_COLORS.white} !important`,
      },
      iconHovered: {
        color: `${DEALROOMS_COLORS.white} !important`,
      },
    };
  }, []);

  const theme = useTheme();
  const styles = useMemo(() => generateStyles(theme), [theme, generateStyles]);

  return (
    <>
      {useTableView ? (
        <div className={styles.actionButtonsContainer}>
          <DealRoomAsyncCommandBarButton
            style={{
              border: 'none',
            }}
            styles={addAttachmentButtonStyles}
            iconProps={{ iconName: 'Add' }}
            text="Add Artifact"
            buttonStyleType="COMMAND_BAR"
            onClick={async () => {
              const result = await showAddDealRoomArtifactDialog().promise;
              if (result) {
                await refetch();
                await onAdd?.(result);
              }
            }}
          />
        </div>
      ) : null}

      <div
        id="dealroom-artifacts"
        className={classNames(styles.containerStyle, className)}
      >
        {' '}
        <DropTarget
          overlayText="Add artifact to Decision Site"
          onDrop={async (file) => {
            const result = await showAddDealRoomArtifactDialog(file).promise;
            if (result) {
              await refetch();
              await onAdd?.(result);
            }
          }}
        >
          {drArtifacts?.length ? (
            useTableView ? (
              <DealRoomArtifactsTable
                dealRoomId={dealRoomId}
                organizationSlug={organizationSlug}
                icon={<DocumentIcon />}
                isCollapsible
                includeDeleted={false}
                // table view always excludes meetingflows now that they're in Journey
                excludeMeetingflows
                onClick={handleOnClick}
                onDelete={handleOnDelete}
                refreshDealRoom={refetch}
                title="All Artifacts"
                defaultCollapsed={false}
              />
            ) : (
              <div
                className={classNames(
                  'artifacts',
                  styles.artifactContainerStyle,
                  artifactContainerClassName,
                )}
              >
                {drArtifacts.map((a) => {
                  const viewCount =
                    DeduplicateArray(
                      activity
                        ?.filter(
                          (activity) =>
                            activity.type === 'VIEW_ARTIFACT' &&
                            activity.additionalDetails.artifactId === a.id,
                        )
                        .map((a) => a.userId) ?? [],
                    ).length ?? 0;
                  switch (a.type) {
                    case 'MEETINGFLOW':
                      return (
                        <MeetingflowArtifactCard
                          key={a.id}
                          {...a}
                          viewCount={viewCount}
                          orgSlug={organizationSlug}
                          dealRoomId={dealRoomId}
                          refreshDealRoom={refetch}
                          onClick={handleOnClick}
                          onDelete={handleOnDelete}
                        />
                      );

                    case 'AUDIO':
                      return (
                        <AudioArtifactCard
                          key={a.id}
                          {...a}
                          viewCount={viewCount}
                          orgSlug={organizationSlug}
                          dealRoomId={dealRoomId}
                          refreshDealRoom={refetch}
                          onClick={handleOnClick}
                          onDelete={handleOnDelete}
                        />
                      );

                    case 'DOCUMENT':
                      return (
                        <DocumentArtifactCard
                          key={a.id}
                          {...a}
                          viewCount={viewCount}
                          orgSlug={organizationSlug}
                          dealRoomId={dealRoomId}
                          refreshDealRoom={refetch}
                          onClick={handleOnClick}
                          onDelete={handleOnDelete}
                        />
                      );

                    case 'FILE':
                      return (
                        <FileArtifactCard
                          key={a.id}
                          {...a}
                          viewCount={viewCount}
                          orgSlug={organizationSlug}
                          dealRoomId={dealRoomId}
                          refreshDealRoom={refetch}
                          onClick={handleOnClick}
                          onDelete={handleOnDelete}
                        />
                      );

                    case 'IMAGE':
                      return (
                        <ImageArtifactCard
                          key={a.id}
                          {...a}
                          orgSlug={organizationSlug}
                          dealRoomId={dealRoomId}
                          refreshDealRoom={refetch}
                          onClick={handleOnClick}
                          onDelete={handleOnDelete}
                        />
                      );

                    case 'LINK':
                      return (
                        <LinkArtifactCard
                          key={a.id}
                          {...a}
                          viewCount={viewCount}
                          orgSlug={organizationSlug}
                          dealRoomId={dealRoomId}
                          refreshDealRoom={refetch}
                          onClick={handleOnClick}
                          onDelete={handleOnDelete}
                        />
                      );

                    case 'VIDEO':
                      return (
                        <VideoArtifactCard
                          key={a.id}
                          {...a}
                          viewCount={viewCount}
                          orgSlug={organizationSlug}
                          dealRoomId={dealRoomId}
                          refreshDealRoom={refetch}
                          onClick={handleOnClick}
                          onDelete={handleOnDelete}
                        />
                      );
                  }
                })}
              </div>
            )
          ) : (
            <div className={styles.noArtifactsStyle}>
              {!artifactsLoading ? (
                <Text>No {!!featured ? 'featured' : ''} artifacts</Text>
              ) : (
                <Spinner />
              )}
            </div>
          )}
        </DropTarget>
        {includeDeleted ? (
          <div id="dealroom-deleted-artifacts">
            <DealRoomArtifactsTable
              onClick={handleOnClick}
              onDelete={handleOnDelete}
              dealRoomId={dealRoomId}
              organizationSlug={organizationSlug}
              refreshDealRoom={refetch}
              title="Deleted Artifacts"
              subTitle="Available for 30 days"
              icon={<EmptyRecycleBinIcon />}
              isCollapsible
              defaultCollapsed
              includeDeleted
              showOnlyDeleted
              excludeMeetingflows={!includeMeetingflows}
            />
          </div>
        ) : null}
      </div>
      {addDealRoomArtifactDialog}
    </>
  );
};
