import { useAuth0 } from '@auth0/auth0-react';
import { Link, FontSizes, Text, FontWeights } from '@fluentui/react';
import { SalesforceOpportunity } from '../../../../Models/Salesforce/SalesforceOpportunity';
import { SalesforceOpportunitiesQuery } from '../../../../QueryNames';
import { ApiClient } from '../../../../Services/NetworkCommon';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useExternalServiceConfigurations } from '../../../../Hooks/useExternalServiceConfigurations';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { SalesforcePanelContext } from '../../../../types/SalesforcePanelContext';

type SalesforceAccountOpportunitiesProps = {
  organizationSlug: string;
  salesforceConfigurationId: number;
  salesforceAccountId: string;
  pushSalesforcePanel: (context: SalesforcePanelContext) => void;
  maxNum?: number;
  onlyActiveOpportunities?: boolean;
};
export const SalesforceAccountOpportunities = ({
  organizationSlug,
  salesforceConfigurationId,
  salesforceAccountId,
  pushSalesforcePanel,
  maxNum,
  onlyActiveOpportunities,
}: SalesforceAccountOpportunitiesProps) => {
  const appInsights = useAppInsightsContext();
  const { getAccessTokenSilently } = useAuth0();

  const [onlyActive, setOnlyActive] = useState<boolean>(
    !!onlyActiveOpportunities,
  );

  const { loading: salesforceConfigurationsLoading } =
    useExternalServiceConfigurations({ app: 'SALESFORCE', withToken: true });

  const {
    data: salesforceOpportunities,
    isLoading: salesforceOpportunitiesLoading,
    isRefetching: salesforceOpportunitiesRefetching,
    isFetched: salesforceOpportunitiesFetched,
    refetch: refetchSalesforceOpportunities,
  } = useQuery(
    SalesforceOpportunitiesQuery(
      organizationSlug!,
      salesforceConfigurationId,
      salesforceAccountId,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<SalesforceOpportunity[]>(
        `/organization/${organizationSlug}/external/salesforce/configuration/${salesforceConfigurationId}/opportunities`,
        {
          params: {
            active: onlyActive,
            accountId: salesforceAccountId,
            limit: maxNum,
          },
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
  );

  useEffect(() => {
    refetchSalesforceOpportunities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onlyActive]);

  useEffect(() => {
    if (salesforceOpportunitiesFetched) {
      appInsights.trackEvent({
        name: 'SALESFORCE_ACCOUNT_OPPORTUNITIES_FETCHED',
        properties: {
          organizationSlug,
          configurationId: salesforceConfigurationId,
          accountId: salesforceAccountId,
          onlyActive: onlyActive,
          opportunityCount: salesforceOpportunities?.data?.length || 0,
        },
      });
    }
  }, [
    appInsights,
    onlyActive,
    organizationSlug,
    salesforceAccountId,
    salesforceConfigurationId,
    salesforceOpportunities?.data?.length,
    salesforceOpportunitiesFetched,
  ]);

  return (
    <>
      <Text
        style={{
          fontWeight: FontWeights.semibold,
          fontSize: FontSizes.xSmall,
        }}
      >
        {onlyActive ? 'Active ' : ''}Opportunities:{' '}
      </Text>
      <span style={{ fontSize: FontSizes.xSmall }}>
        {salesforceConfigurationsLoading ||
        salesforceOpportunitiesLoading ||
        salesforceOpportunitiesRefetching ? (
          'Loading...'
        ) : !!salesforceOpportunities?.data?.length ? (
          salesforceOpportunities?.data.map((opp, idx) => {
            return (
              <Link
                as={'a'}
                style={{ fontSize: FontSizes.xSmall }}
                onClick={() => {
                  pushSalesforcePanel({
                    name: opp.Name,
                    objectId: opp.Id,
                    objectType: 'DEAL',
                    serviceConfigurationId: salesforceConfigurationId,
                  });
                }}
                key={opp.Id}
              >
                {opp.Name}
                {idx !== salesforceOpportunities?.data.length - 1 ? ', ' : ''}
              </Link>
            );
          })
        ) : (
          <>
            <span style={{ fontSize: FontSizes.xSmall }}>None</span>
            {onlyActive ? (
              <span style={{ fontSize: FontSizes.xSmall }}>
                {' '}
                -{' '}
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    setOnlyActive(false);
                  }}
                >
                  Show all
                </Link>
              </span>
            ) : null}
          </>
        )}
      </span>
    </>
  );
};
