import { PivotItem, Stack, TextField, Text, Pivot } from '@fluentui/react';
import { getStyles } from '../UploadDealRoomAttachmentsDialog.styles';
import { DraggableFileUploaderContainer } from '../../DraggableFileUploaderContainer';
import DealRoomAsyncCommandBarButton from '../../Components/DealRoomButton';
import { AddArtifactIconProps } from '../../../../utils/iconProps';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { useFileUpload } from './hooks';
import { UploadFormProps } from './types';

export const UploadForm: React.FC<UploadFormProps> = ({
  title,
  setTitle,
  description,
  setDescription,
  selectedFile,
  setSelectedFile,
  artifact,
  url,
  setUrl,
  dealRoomRole,
  meetingflowTitle,
  setMeetingflowTitle,
  pivotRef,
  selectedTab,
  setSelectedTab,
}) => {
  const styles = getStyles();

  const { handleSelectFile, handleDrop } = useFileUpload({
    setSelectedFile,
  });

  return (
    <Pivot
      styles={{
        link: { color: DEALROOMS_COLORS.themeSecondary },
        linkIsSelected: { color: DEALROOMS_COLORS.themePrimary },
        text: { color: DEALROOMS_COLORS.themeSecondary },
        itemContainer: { padding: '1rem 0 0 0' },
      }}
      componentRef={pivotRef}
      selectedKey={selectedTab.toString()}
      onLinkClick={(item) => setSelectedTab(item?.props.itemKey || 'file')}
    >
      <PivotItem
        headerText={artifact ? 'Update Version' : 'Upload File'}
        key="file"
        itemKey="file"
      >
        <Stack.Item className={styles.content}>
          {artifact ? (
            <DraggableFileUploaderContainer
              handleOnDrop={handleDrop}
              className={styles.draggableContainer}
            >
              <DealRoomAsyncCommandBarButton
                customClasses={styles.selectFileButton}
                onClick={handleSelectFile}
                text="Select File"
                buttonStyleType="DEAL_ROOM"
                iconProps={{ iconName: 'AddArtifact' }}
              />
              <div className={styles.dropContent}>
                <Text className={styles.uploadSectionMessage}>
                  Select or drop a file to upload.
                </Text>
                <Text className={styles.uploadSectionMessage}>
                  This file will{' '}
                  <span className={styles.highlighted}>replace</span> the
                  existing version.
                </Text>
                {selectedFile && (
                  <Text className={styles.uploadSectionFileName}>
                    {selectedFile.name || 'N/A'}
                  </Text>
                )}
              </div>
            </DraggableFileUploaderContainer>
          ) : (
            <>
              <div className={styles.uploadSection}>
                <DealRoomAsyncCommandBarButton
                  customClasses={styles.selectFileButton}
                  onClick={handleSelectFile}
                  text="Select File"
                  buttonStyleType="DEAL_ROOM"
                  iconProps={AddArtifactIconProps}
                />
                <div className={styles.uploadSectionMessages}>
                  {selectedFile ? (
                    <Text className={styles.uploadSectionFileName}>
                      {selectedFile.name || 'N/A'}
                    </Text>
                  ) : (
                    <Text className={styles.uploadSectionMessage}>
                      Select a file to upload.
                    </Text>
                  )}
                </div>
              </div>
              <TextField
                value={title}
                className={styles.contentTitle}
                label="Title"
                placeholder="Enter Artifact Title"
                onChange={(e, newValue) => setTitle(newValue || '')}
              />
            </>
          )}
          {/*<TextField
            value={description}
            className={styles.contentDescription}
            label={artifact ? 'Description of Changes' : 'Description'}
            multiline
            resizable={false}
            placeholder="Enter Artifact Description"
            onChange={(e, newValue) => setDescription(newValue || '')}
          /> */}
        </Stack.Item>
      </PivotItem>
      {!artifact ? (
        <PivotItem headerText="Create from URL" key="url" itemKey="url">
          <Stack.Item className={styles.content}>
            <TextField
              value={url}
              className={styles.contentTitle}
              label="URL"
              placeholder="Enter Artifact URL"
              onChange={(e, newValue) => setUrl(newValue || '')}
            />
            <TextField
              value={title}
              className={styles.contentTitle}
              label="Title"
              placeholder="Enter Artifact Title"
              onChange={(e, newValue) => setTitle(newValue || '')}
            />
          </Stack.Item>
        </PivotItem>
      ) : null}
      {!artifact && dealRoomRole === 'SELLER' ? (
        <PivotItem
          headerText="Create a new Meetingflow"
          key="new-meetingflow"
          itemKey="new-meetingflow"
        >
          <Stack.Item className={styles.content}>
            <TextField
              value={meetingflowTitle}
              label="Name"
              className={styles.contentTitle}
              placeholder="Enter Meetingflow Name"
              onChange={(e, newValue) => {
                setMeetingflowTitle(newValue || '');
              }}
            />
          </Stack.Item>
        </PivotItem>
      ) : null}
    </Pivot>
  );
};
