import { BaseEditor, Editor } from 'slate';
import { v4 } from 'uuid';

export type IdEditor = BaseEditor & {
  id: string;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IdEditor = {
  isIdEditor(value: unknown): value is IdEditor {
    return (
      Editor.isEditor(value) &&
      typeof (value as unknown as IdEditor).id === 'string'
    );
  },

  id(value: unknown): string | undefined {
    return IdEditor.isIdEditor(value) ? value.id : undefined;
  },
};

export const withId = <T extends Editor>(editor: T): T & IdEditor => {
  const e = editor as T & IdEditor;

  e.id = v4();

  return e;
};
