export const getCurrentLocale = () => {
  return navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.language;
};

export const getNumberSeparator = (
  locale?: string,
  separatorType?: Intl.NumberFormatPartTypes,
) => {
  const numberWithGroupAndDecimalSeparator = 10000.1;
  const separator = Intl.NumberFormat(locale ?? getCurrentLocale() ?? 'en-US')
    ?.formatToParts(numberWithGroupAndDecimalSeparator)
    ?.find((part) => part.type === separatorType)?.value;

  if (separator) {
    return separator;
  }

  return Intl.NumberFormat('en-US')!
    .formatToParts(numberWithGroupAndDecimalSeparator)!
    .find((part) => part.type === separatorType)!.value;
};
