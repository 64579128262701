import React from 'react';
import { FormControlLabel, FormControlLabelProps } from '@mui/material';
import { DSFormControlLabelRoot } from './DSFormControlLabel.styles';

export interface DSFormControlLabelProps extends FormControlLabelProps {
  children?: React.ReactNode;
}

const DSFormControlLabel: React.FC<DSFormControlLabelProps> = ({ children, ...props }) => {
  return <DSFormControlLabelRoot {...props}>{children}</DSFormControlLabelRoot>;
};

export default DSFormControlLabel;
