import { mergeStyles, ThemeProvider } from '@fluentui/react';
import { ThemeProvider as ThemeProviderMUI } from '@mui/material';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { DEALROOMS_COLORS, demoTheme } from '../../Themes/Themes';
import { muiThemes } from '../../Themes/MUI/Themes';

export const APP_HEADER_HEIGHT = '0px'; // '3.5rem';
export const APP_MAX_WIDTH_INT = 2100;
export const APP_MAX_WIDTH = `${APP_MAX_WIDTH_INT}px`;

export interface DecisionSiteBaseLayoutProps {
  children?: React.ReactNode;
  contentViewportHeight?: string;
}

export const DecisionSiteBaseLayout: React.FunctionComponent<
  DecisionSiteBaseLayoutProps
> = ({ children, contentViewportHeight }) => {
  const { isDark } = useLightOrDarkMode();

  const routeContentClass = mergeStyles({
    backgroundColor: DEALROOMS_COLORS.themePrimary,
    height: `calc(100vh - ${APP_HEADER_HEIGHT})`,
    position: 'relative',

    width: '100vw',
    overflow: 'auto',
    padding: '0',
    '.content-viewport': {
      overflow: 'hidden',
      height: contentViewportHeight ? contentViewportHeight : `100%`,
      margin: '0 0',
      maxWidth: APP_MAX_WIDTH,
    },
  });

  return (
    <ThemeProviderMUI theme={muiThemes.light}>
      <ThemeProvider applyTo="body" theme={demoTheme}>
        <div className={routeContentClass}>
          <div className="content-viewport">{children}</div>
        </div>
      </ThemeProvider>
    </ThemeProviderMUI>
  );
};
