import {
  ActionButton,
  CommandBarButton,
  DefaultButton,
  IButtonProps,
  IconButton,
  PrimaryButton,
  Spinner,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React from 'react';
import { AsyncClickOptions, AsyncOnClickProps } from './Common';

export const withAsyncOnClick = (
  InnerComponent: React.ComponentType<IButtonProps>,
  { disableOnExecute: parentDisableOnExecute }: AsyncClickOptions = {},
) => {
  return ({
    disabled,
    onClick,
    disableOnExecute,
    renderSpinnerOnExecute,
    executeText,
    text,
    ...rest
  }: Omit<IButtonProps, 'onClick'> & AsyncOnClickProps) => {
    const [
      isExecuting,
      { setTrue: setIsExecuting, setFalse: setNotExecuting },
    ] = useBoolean(false);
    return (
      <InnerComponent
        disabled={
          disabled ||
          ((parentDisableOnExecute || disableOnExecute) && isExecuting)
        }
        onRenderIcon={(props, defaultRender) =>
          renderSpinnerOnExecute && isExecuting ? (
            <Spinner />
          ) : (
            defaultRender?.(props) || null
          )
        }
        text={isExecuting ? executeText || text : text}
        onClick={async (e) => {
          if (isExecuting) {
            return;
          }

          setIsExecuting();
          try {
            await onClick(e);
          } catch (err: unknown) {
            console.info(
              `AsyncButton caught exception executing async onClick`,
              err,
            );
          } finally {
            setNotExecuting();
          }
        }}
        {...rest}
      />
    );
  };
};

export const AsyncIconButton = withAsyncOnClick(IconButton);
export const AsyncDefaultButton = withAsyncOnClick(DefaultButton);
export const AsyncPrimaryButton = withAsyncOnClick(PrimaryButton);
export const AsyncCommandBarButton = withAsyncOnClick(CommandBarButton);
export const AsyncActionButton = withAsyncOnClick(ActionButton);
