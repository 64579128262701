import { PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';

export const Portal = ({
  children,
}: PropsWithChildren<unknown>): React.ReactPortal | null => {
  return typeof document === 'object'
    ? ReactDOM.createPortal(children, document.body)
    : null;
};
