import { CreateDealRoomPayload } from '@meetingflow/common/Api/data-contracts';
import { useMemo } from 'react';
import { v4 } from 'uuid';
import { NewDealRoomDialog } from '../../../Components/DealRoom/Dialogs/NewDealRoomDialog/NewDealRoomDialog';
import { useDeferredPromise } from '../../useDeferredPromise';

type UseNewDealRoomDialogProps = {
  organizationSlug: string;
  refetchDealRoomsData: () => void;
};

export const useNewDealRoomDialog = ({
  organizationSlug,
  refetchDealRoomsData,
}: UseNewDealRoomDialogProps) => {
  const { createDeferred, resolve, reject, deferred } =
    useDeferredPromise<CreateDealRoomPayload>();
  const dialog = useMemo(() => {
    if (!deferred || !deferred.isPending) {
      return null;
    }

    return (
      <NewDealRoomDialog
        key={v4()}
        organizationSlug={organizationSlug}
        onCreate={resolve}
        onDismiss={reject}
        refetchDealRoomsData={refetchDealRoomsData}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deferred, deferred?.isPending, organizationSlug, reject, resolve]);

  return {
    createDeferred,
    resolve,
    reject,
    deferred,
    dialog,
  };
};
