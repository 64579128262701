import { useAuth0 } from '@auth0/auth0-react';
import {
  BaseButton,
  Button,
  FontIcon,
  FontSizes,
  FontWeights,
  NeutralColors,
  Persona,
  PersonaSize,
  mergeStyles,
} from '@fluentui/react';
import { CalendarEvent } from '@meetingflow/common/Api/data-contracts';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { AsyncSpan } from '../../../Components/HOC/AsyncIntrinsicElement';
import useBreakpoints from '../../../Hooks/useBreakpoints';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { useModifierAwareNavigate } from '../../../Hooks/useModifierAwareNavigate';
import { DEALROOMS_COLORS, MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { CompanyCard } from '../../MeetingPlans/MeetingPlanAttendees';
import StyledDateTime from '../../StyledDateTime';
import { useOrganization } from '../../../Hooks/useOrganization';
import { GroupBy } from '@meetingflow/common/ArrayHelpers';
import { DecisionSiteEventCardRoot } from './DecisionSiteEventCard.styles';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { createMeetingflowAndDecisionSiteArtifact } from '../../../utils/DecisionSiteMeetingflowHelpers';
import { DecisionSiteMeetingPlanViewCreateButton } from '../../../Components/Common/DecisionSiteMeetingPlanViewCreateButton';
import { Tooltip, Typography } from '@mui/material';
import { titleCase } from '@meetingflow/common/StringHelpers';
import { People, VideoCall } from '@mui/icons-material';

interface DecisionSiteEventCardProps {
  event: CalendarEvent;
  organizationSlug: string;
  onClick?: () => void;
  disableCreateViewMeetingflowOnClick?: boolean;
  showCompanies?: boolean;
  showTextLabelOnCreateButton?: boolean;
  muteIfNoMeetingflow?: boolean;
  showTimesOnly?: boolean;
  createMeetingflowOnClick?: boolean;
  allowScheduleCallRecording?: boolean;
  cardTitleAttr?: string;
  hideNewMeetingflowButton?: boolean;
  hideConferenceIcon?: boolean;
}

const DecisionSiteEventCard = ({
  event,
  organizationSlug,
  onClick,
  disableCreateViewMeetingflowOnClick = false,
  showCompanies,
  showTextLabelOnCreateButton = false,
  muteIfNoMeetingflow,
  showTimesOnly = false,
  createMeetingflowOnClick = false,
  allowScheduleCallRecording = true,
  cardTitleAttr,
  hideNewMeetingflowButton = false,
  hideConferenceIcon = false,
}: DecisionSiteEventCardProps) => {
  const { isDark } = useLightOrDarkMode();
  const { user, getAccessTokenSilently } = useAuth0();
  const client = useQueryClient();
  const appInsights = useAppInsightsContext();
  const modifierAwareNavigate = useModifierAwareNavigate();
  const { dealRoomId } = useDealRoom();
  const breakpoints = useBreakpoints();
  const { organization, canCreatePlans } = useOrganization();

  const eventIsInPast = DateTime.now() > DateTime.fromISO(event.endTime);

  const eventIsNow =
    DateTime.now() > DateTime.fromISO(event.startTime) &&
    DateTime.now() < DateTime.fromISO(event.endTime);

  const eventIsSoon =
    DateTime.fromISO(event.startTime).diffNow('minutes').minutes < 60;

  const eventIsNowOrSoon = !eventIsInPast && (eventIsNow || eventIsSoon);

  const recordLinkClass = mergeStyles({
    display: 'inline-block',
    marginRight: '.25rem',
    color: `${MEETINGFLOW_COLORS.white} !important`,
    cursor: 'pointer',
    fontSize: FontSizes.mini,
    fontWeight: FontWeights.semibold,
    textTransform: 'uppercase',
    backgroundColor: DEALROOMS_COLORS.themeSecondary,
    lineHeight: '.75rem',
    padding: '0 .25rem .15rem .25rem',
    borderRadius: '.5rem',
    transition: '.3s ease-in-out all',
    boxSizing: 'border-box',
    position: 'relative',
    top: '-1px',

    i: {
      marginRight: '.25rem',
      fontWeight: FontWeights.regular,
      position: 'relative',
      top: '.1rem',
    },

    '&.not-scheduled': {
      backgroundColor: isDark
        ? MEETINGFLOW_COLORS.magenta
        : MEETINGFLOW_COLORS.magenta,

      ':hover': {
        backgroundColor: MEETINGFLOW_COLORS.teal,
      },
    },

    '&.scheduled': {
      backgroundColor: isDark
        ? NeutralColors.gray160
        : MEETINGFLOW_COLORS.purpleGrey,
      color: MEETINGFLOW_COLORS.purpleMedium,

      ':hover': {
        backgroundColor: isDark
          ? MEETINGFLOW_COLORS.purpleDark
          : MEETINGFLOW_COLORS.purpleMedium,
        color: MEETINGFLOW_COLORS.white,
      },
    },
  });

  const beforeEnd = event
    ? DateTime.fromISO(event.endTime).diffNow().milliseconds > 0
    : true;

  const createMeetingflow = async (
    e: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | BaseButton
      | Button
      | HTMLSpanElement
    >,
    scheduleCallRecording = false,
  ): Promise<unknown> => {
    e.stopPropagation();
    if (!!event?.meetingplanId) {
      modifierAwareNavigate(
        `/organization/${organizationSlug}/dealroom/${dealRoomId}/plan/${event.meetingplanId}`,
        e,
      );
      return;
    }

    const token = await getAccessTokenSilently();

    try {
      if (!dealRoomId) {
        throw new Error('No dealRoomId provided');
      }

      return await createMeetingflowAndDecisionSiteArtifact({
        event,
        organizationSlug,
        scheduleCallRecording,
        token,
        dealRoomId,
        appInsights,
        queryClient: client,
        onSuccess: (meetingflowId) => {
          modifierAwareNavigate(
            `/organization/${organizationSlug}/dealroom/${dealRoomId}/plan/${meetingflowId}${
              scheduleCallRecording ? '?scheduleCallRecording=true' : ''
            }`,
            e,
          );
        },
      });
    } catch (error) {
      console.error('Error creating meetingflow:', error);
      return error;
    }
  };

  const createMeetingflowAndScheduleCallRecording = (
    e: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | BaseButton
      | Button
      | HTMLSpanElement
    >,
  ): Promise<unknown> => createMeetingflow(e, true);

  const callRecordingButton = useMemo(() => {
    if (!event) {
      return null;
    }

    if (!canCreatePlans) {
      return null;
    }

    if (beforeEnd && event?.conferenceInfo?.joinUri) {
      return allowScheduleCallRecording ? (
        <AsyncSpan
          className={classNames(recordLinkClass, 'not-scheduled')}
          onClick={createMeetingflowAndScheduleCallRecording}
        >
          <FontIcon iconName={'CircleFill'} />
          Record
        </AsyncSpan>
      ) : null;
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, beforeEnd, allowScheduleCallRecording, recordLinkClass]);

  const externalCompanies = event.companies.filter((c) => !c.isInternal);

  const peopleByDomain = useMemo(
    () => GroupBy(event?.attendees || [], (a) => a.emailDomain),
    [event?.attendees],
  );

  const attendeesWithoutCompany = useMemo(() => {
    const companyDomains = event?.companies
      ?.map((c) => c.domains.map((d) => d.domain))
      .flat();
    return (
      Object.keys(peopleByDomain)
        .filter((key) => !companyDomains?.includes(key))
        .flatMap((key) => peopleByDomain[key]) ?? []
    );
  }, [peopleByDomain, event?.companies]);

  const eventIcon = useMemo(() => {
    if (event?.attendees?.length) {
      return (
        <Tooltip
          title={
            <div className="tooltip-content-container">
              {event?.startTime ? (
                <>
                  <Typography>
                    <StyledDateTime
                      dateTime={event?.startTime}
                      displayComponents={['date']}
                    />{' '}
                  </Typography>

                  <Typography>
                    <>
                      <StyledDateTime
                        dateTime={event?.startTime}
                        displayComponents={['time']}
                      />
                    </>
                    {event?.endTime ? (
                      <>
                        {' — '}{' '}
                        <StyledDateTime
                          dateTime={event?.endTime}
                          displayComponents={['time']}
                        />
                      </>
                    ) : null}
                  </Typography>
                </>
              ) : null}
              {event.conferenceInfo?.type ? (
                <Typography>
                  <span style={{ fontWeight: FontWeights.semibold }}>
                    Via:{' '}
                  </span>
                  {titleCase(event.conferenceInfo?.type)}
                </Typography>
              ) : null}
              {event.creator ? (
                <Typography>
                  <span style={{ fontWeight: FontWeights.semibold }}>
                    Creator:{' '}
                  </span>
                  {titleCase(event.creator.name || event.creator.email)}
                </Typography>
              ) : null}
              {event.organizer ? (
                <Typography>
                  <span style={{ fontWeight: FontWeights.semibold }}>
                    Organizer:{' '}
                  </span>
                  {titleCase(event.organizer.name || event.organizer.email)}
                </Typography>
              ) : null}

              {event?.attendees?.length ? (
                <>
                  <Typography
                  // className={tooltipHeaderClass}
                  // style={{ marginTop: '.5rem' }}
                  >
                    Attendees
                  </Typography>
                  <div className="tooltip-content-container">
                    <div className="meetingflow-companies">
                      {event?.companies
                        ? event?.companies.map((company) => {
                            const companyAttendees =
                              company.domains
                                ?.map((domain) => domain.domain)
                                ?.flatMap(
                                  (domain) =>
                                    Object.keys(peopleByDomain)
                                      .filter(
                                        (key) =>
                                          key === domain ||
                                          key.endsWith(domain),
                                      )
                                      .flatMap((key) => peopleByDomain[key]) ??
                                    [],
                                ) ?? [];

                            return (
                              <div
                                key={`meetingflowcard-${event.externalId}-company-${company.id}`}
                                // className={companyClass}
                              >
                                <div>
                                  <Persona
                                    imageUrl={
                                      company?.logo ||
                                      company?.twitter_avatar ||
                                      undefined
                                    }
                                    styles={{
                                      root: {
                                        float: 'left',
                                        clear: 'left',
                                        width: '24px',
                                        marginRight: '.25rem',
                                      },
                                    }}
                                    size={PersonaSize.size24}
                                    hidePersonaDetails
                                  />
                                  <Typography>
                                    {company?.name ||
                                      company?.legalName ||
                                      company?.domains?.[0].domain}
                                  </Typography>
                                </div>
                                <div className="company-attendees">
                                  {companyAttendees.map((a) => (
                                    <div key={`company-attendee-${a.email}`}>
                                      {a.name || a.email}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            );
                          })
                        : null}
                      {attendeesWithoutCompany.length ? (
                        <>
                          <Typography
                            style={{
                              display: 'inline-block',
                              fontSize: FontSizes.small,
                              fontWeight: FontWeights.semibold,
                              lineHeight: '24px',
                            }}
                          >
                            Unknown Company
                          </Typography>
                          <div className="company-attendees">
                            {attendeesWithoutCompany.map((a) => (
                              <div key={`no-company-attendee-${a.email}`}>
                                {a.name || a.email}
                              </div>
                            ))}
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          }
        >
          <People fontSize="small" />
        </Tooltip>
      );
    }

    return null;
  }, [event, peopleByDomain, attendeesWithoutCompany]);

  const onConferenceIconClick = useCallback(() => {
    window.open(event?.conferenceInfo?.joinUri, '_blank');
    appInsights.trackEvent({
      name: 'JOIN_CONFERENCE_CALL',
      properties: {
        organizationId: organization?.id,
        type: event?.conferenceInfo?.type,
      },
    });
  }, [
    appInsights,
    event?.conferenceInfo?.joinUri,
    event?.conferenceInfo?.type,
    organization?.id,
  ]);

  const conferenceIcon = useMemo(() => {
    if (
      event &&
      event?.conferenceInfo &&
      eventIsNowOrSoon &&
      !hideConferenceIcon
    ) {
      return (
        <Tooltip
          title={
            <>
              <Typography className={'join-conference'}>
                Join {titleCase(event?.conferenceInfo?.type || 'Online')}{' '}
                Meeting
              </Typography>
            </>
          }
        >
          <VideoCall onClick={onConferenceIconClick} />
        </Tooltip>
      );
    }

    return null;
  }, [event, eventIsNowOrSoon, onConferenceIconClick, hideConferenceIcon]);

  return (
    <DecisionSiteEventCardRoot
      title={cardTitleAttr || undefined}
      style={{
        cursor: onClick || createMeetingflowOnClick ? 'pointer' : undefined,
      }}
      onClick={async (event) =>
        createMeetingflowOnClick
          ? await createMeetingflow(event)
          : onClick
            ? onClick()
            : undefined
      }
    >
      <div className="event-details">
        <Typography component={'span'} className="event-title">
          {event?.title}

          <div className="icons">
            {eventIcon}
            {conferenceIcon}
          </div>
        </Typography>
        <Typography component={'span'} className="event-datetime">
          {callRecordingButton}
          {event?.startTime ? (
            <StyledDateTime
              useRelativeDates
              dateTime={event?.startTime}
              displayComponents={showTimesOnly ? ['time'] : ['date', 'time']}
            />
          ) : null}
        </Typography>
        {showCompanies && externalCompanies.length ? (
          <div
            style={{
              display: 'flex',
              height: '20px',
              marginTop: '.5rem',
            }}
          >
            {externalCompanies && organizationSlug
              ? // @ts-ignore
                externalCompanies.map((c: Company) => (
                  <CompanyCard
                    company={c}
                    organizationSlug={organizationSlug}
                    key={c.id}
                    logoOnly
                    onClick={(_id, e) => {
                      if (e) {
                        modifierAwareNavigate(
                          `/organization/${organizationSlug}/library/companies/${c.id}`,
                          e,
                        );
                      }
                    }}
                    logoSize={'16px'}
                    personaSize={PersonaSize.size16}
                  />
                ))
              : null}
          </div>
        ) : null}
      </div>

      {!hideNewMeetingflowButton ? (
        <div className="button">
          <DecisionSiteMeetingPlanViewCreateButton
            organizationSlug={organizationSlug}
            event={event}
            noTooltip
            hideTextLabel={!showTextLabelOnCreateButton}
            onClickDisabled={disableCreateViewMeetingflowOnClick}
          />
        </div>
      ) : null}
    </DecisionSiteEventCardRoot>
  );
};

export default DecisionSiteEventCard;
