import React, { useCallback, useState } from 'react';
import {
    PrimaryButton,
    Dialog,
    DialogType,
    DialogFooter,
    DefaultButton,
    TextField,
    DatePicker,
    Text,
    Stack,
} from '@fluentui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { DealRoomsApiClient } from '../../../../../Services/NetworkCommon';
import { dsConfigurationMutualPlanStyles } from './styles';
import toast from 'react-hot-toast';

interface DSConfigurationMutualPlanCreateMilestoneButtonProps {
    organizationSlug: string;
    dealRoomId: number;
    refetchMilestones: () => void;
    refetchMutualPlan: () => void;
}

export const DSConfigurationMutualPlanCreateMilestoneButton: React.FC<DSConfigurationMutualPlanCreateMilestoneButtonProps> = ({
    organizationSlug,
    dealRoomId,
    refetchMilestones,
    refetchMutualPlan,
}) => {
    const { getAccessTokenSilently } = useAuth0();
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    // Handle milestone creation
    const handleCreateMilestone = useCallback(async (title: string, dueDate: Date | null) => {
        try {
            const token = await getAccessTokenSilently();
            // Create milestone using API and show toast notifications
            await toast.promise(
                DealRoomsApiClient.saveMilestones(
                    organizationSlug,
                    dealRoomId,
                    [{
                        type: title,
                        dueDate: dueDate?.toISOString() || null,
                        visible: true,
                    }],
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                ),
                {
                    loading: 'Creating milestone...',
                    success: () => {
                        // Refetch data and close dialog on success
                        refetchMilestones();
                        refetchMutualPlan();
                        setIsDialogOpen(false);
                        return 'Milestone created successfully';
                    },
                    error: 'Failed to create milestone',
                }
            );
        } catch (error) {
            console.error('Error creating milestone:', error);
            toast.error('Failed to create milestone');
        }
    }, [dealRoomId, getAccessTokenSilently, organizationSlug, refetchMilestones, refetchMutualPlan]);

    return (
        <>
            {/* Button to open create milestone dialog */}
            <PrimaryButton
                text="Create Milestone"
                iconProps={{ iconName: 'Add' }}
                onClick={() => setIsDialogOpen(true)}
                styles={{
                    root: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    },
                    textContainer: {
                        flexGrow: 0,
                    },
                }}
            />

            {/* Create milestone dialog component */}
            <DSConfigurationMutualPlanCreateMilestoneDialog
                isOpen={isDialogOpen}
                onConfirm={handleCreateMilestone}
                onDismiss={() => setIsDialogOpen(false)}
            />
        </>
    );
};

interface DSConfigurationMutualPlanAddMilestoneDialogProps {
    isOpen: boolean;
    onConfirm: (title: string, dueDate: Date | null) => void;
    onDismiss: () => void;
}

const DSConfigurationMutualPlanCreateMilestoneDialog: React.FC<DSConfigurationMutualPlanAddMilestoneDialogProps> = ({
    isOpen,
    onConfirm,
    onDismiss,
}) => {
    const [title, setTitle] = useState('');
    const [dueDate, setDueDate] = useState<Date | null>(null);
    const styles = dsConfigurationMutualPlanStyles;

    const handleConfirm = () => {
        onConfirm(title, dueDate);
        setTitle('');
        setDueDate(null);
    };

    const handleDismiss = () => {
        onDismiss();
        setTitle('');
        setDueDate(null);
    };

    return (
        <Dialog
            hidden={!isOpen}
            onDismiss={handleDismiss}
            className={styles.dialog}
            dialogContentProps={{
                type: DialogType.normal,
                title: 'Create New Milestone',
                subText: 'Create a new milestone for your mutual plan',
            }}
            modalProps={{
                isBlocking: true,
                styles: {
                    main: {
                        selectors: {
                            '@media (min-width: 480px)': {
                                maxWidth: '600px',
                                minWidth: '450px',
                            },
                        },
                    },
                },
            }}
        >
            <Stack tokens={{ childrenGap: 16 }}>
                {/* Milestone title input */}
                <div className={styles.field}>
                    <div className={styles.label}>
                        <Text>Title</Text>
                        <Text className={styles.required}>*</Text>
                    </div>
                    <TextField
                        placeholder="Enter milestone title..."
                        value={title}
                        onChange={(_, newValue) => setTitle(newValue || '')}
                        required
                    />
                </div>
                {/* Milestone due date picker */}
                <div className={styles.field}>
                    <div className={styles.label}>
                        <Text>Due Date</Text>
                    </div>
                    <DatePicker
                        placeholder="Select a date..."
                        value={dueDate || undefined}
                        onSelectDate={(date) => setDueDate(date || null)}
                        minDate={new Date()}
                    />
                </div>
            </Stack>
            {/* Dialog footer with action buttons */}
            <DialogFooter>
                <DefaultButton onClick={handleDismiss} text="Cancel" />
                <PrimaryButton onClick={handleConfirm} text="Create" disabled={!title} />
            </DialogFooter>
        </Dialog>
    );
};
