import { useAuth0 } from '@auth0/auth0-react';
import {
  FontSizes,
  Icon,
  NeutralColors,
  PrimaryButton,
  Spinner,
  mergeStyles,
} from '@fluentui/react';
import { Company } from '@meetingflow/common/Api/data-contracts';
import { YjsEditor } from '@slate-yjs/core';
import { useEffect, useMemo, useState } from 'react';
import * as Y from 'yjs';
import { useLinkDialog } from '../../../Hooks/Modals/useLinkDialog';
import { useCollabProvider } from '../../../Hooks/useCollabProvider';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { useOrganization } from '../../../Hooks/useOrganization';
import { useUserProfile } from '../../../Hooks/useProfile';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import EditorFrame from '../../Collab/EditorFrame';
import { getYjsEditor } from '../../Collab/Helpers/EditorHelpers';

export type CompanyNoteEditorProps = {
  color: string;
  organizationSlug: string;
  company: Pick<Company, 'id' | 'name'>;
  backgroundColor?: string;
  readOnly?: boolean;
  onToggleEdit?: (editing?: boolean) => void;
  alwaysEditing?: boolean;
  fullWidth?: boolean;
};
export const CompanyNoteEditor = ({
  color,
  organizationSlug,
  company,
  backgroundColor,
  readOnly = false,
  onToggleEdit,
  alwaysEditing = false,
  fullWidth = false,
}: CompanyNoteEditorProps) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const { name, email, picture } = user!;
  const { isGuest } = useOrganization(organizationSlug);
  const { user: mfUser } = useUserProfile();

  const { isDark } = useLightOrDarkMode();

  const { createDeferred: createLinkDialogDeferred, dialog: linkDialog } =
    useLinkDialog();

  const ydoc = useMemo(() => {
    return new Y.Doc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationSlug, company.id]);

  const notesYArray = useMemo(() => {
    return ydoc.get('notes', Y.XmlText) as Y.XmlText;
  }, [ydoc]);

  const [editing, setEditing] = useState(alwaysEditing);

  const { provider } = useCollabProvider({
    providerName: 'COMPANY',
    documentName: `Company__${organizationSlug}__${company.id}`,
    color,
    email: email!,
    name: mfUser?.name || name,
    picture: mfUser?.avatarFileUrl || mfUser?.avatarUrl || picture,
    ydoc,
  });

  const editor = useMemo(() => {
    if (!provider) {
      return undefined;
    }
    return getYjsEditor(
      notesYArray,
      provider,
      {
        data: {
          alphaColor: isDark
            ? color.slice(0, -2) + '0.5)'
            : color.slice(0, -2) + '0.2)',
          color,
          name,
          email: email!,
          picture,
        },
      },
      getAccessTokenSilently,
      organizationSlug,
      createLinkDialogDeferred,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notesYArray, provider]);

  useEffect(() => {
    if (!editor) {
      return;
    }
    YjsEditor.connect(editor);
    return () => {
      YjsEditor.disconnect(editor);
    };
  }, [editor]);

  if (isGuest) {
    return null;
  }

  if (!editor) {
    return <Spinner />;
  }

  const editorWrapperClass = mergeStyles({
    overflow: 'visible',
    position: 'relative',
    paddingTop: editing ? '.25rem' : '0',
    paddingLeft: '0',
    width: '100%',
    minHeight: '1.25rem',
    height: 'auto',
    lineHeight: !editing ? '1rem' : undefined,
    border: `1px solid transparent`,
    borderRadius: '.25rem',
    transition: '.3s ease-in-out all',
    backgroundColor: backgroundColor || 'transparent',

    'p[data-slate-node="element"]:first-of-type': {
      marginTop: '0 !important',
    },

    'span[data-slate-placeholder="true"]': {
      top: editing ? undefined : '0 !important',
    },

    ':hover': {
      backgroundColor: !editing
        ? isDark
          ? 'transparent'
          : 'white'
        : undefined,
      border: !editing
        ? isDark
          ? `1px solid ${NeutralColors.gray150}`
          : `1px solid ${MEETINGFLOW_COLORS.purpleLighter}`
        : undefined,

      '[data-slate-editor="true"]': {
        backgroundColor: 'transparent !important',
      },
    },
  });

  return readOnly ? (
    notesYArray.toString() ? (
      <div
        style={{
          paddingTop: '.25rem',
          position: 'relative',
        }}
      >
        <Icon
          style={{
            position: 'absolute',
            left: '-1.85rem',
            top: '.4rem',
            color: NeutralColors.gray60,
          }}
          iconName="Edit"
          className="editable-icon"
        />
        {notesYArray.toString()}
      </div>
    ) : null
  ) : (
    <>
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setEditing(true);
          onToggleEdit?.(true);
        }}
        className={editorWrapperClass}
      >
        <EditorFrame
          placeholder={`Add a note about ${company.name}...`}
          organizationSlug={organizationSlug}
          companyId={company.id}
          editor={editor}
          readonly={!editing}
          name="ContactPanelNotes"
          additionalEventProps={{
            organizationSlug,
            companyName: company.name,
          }}
          showEditableIcon={!editing}
          showAutoSaveIndicator={false}
          wrapperBackgroundColor={
            !editing ? 'transparent' : isDark ? NeutralColors.gray190 : 'white'
          }
          backgroundColor={
            !editing ? 'transparent' : isDark ? NeutralColors.gray190 : 'white'
          }
          allowTags
          allowMentions
          showPlaceholderHint
          hideMentionHint
        />
      </div>
      {editing && !alwaysEditing ? (
        <div style={{ textAlign: 'right', marginTop: '.5rem' }}>
          <PrimaryButton
            styles={{
              root: {
                marginBottom: '.25rem',
                height: '1.5rem',
                fontSize: FontSizes.small,
              },
            }}
            text="Done Editing"
            onClick={(e) => {
              onToggleEdit?.(false);
              e.preventDefault();
              e.stopPropagation();
              setEditing(false);
            }}
          />
        </div>
      ) : null}
      {linkDialog}
    </>
  );
};
