import { useSlate } from 'slate-react';

import { Text } from 'slate';
import {
  FontWeights,
  IconButton,
  mergeStyles,
  NeutralColors,
} from '@fluentui/react';
import { isMarkActive, toggleMark } from '../Helpers/EditorHelpers';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';

type MarkButtonProps = {
  format: keyof Omit<Text, 'text'>;
  formatName?: string;
  iconName: string;
  disabled?: boolean;
  hint?: string;
};
export const MarkButton = ({
  format,
  formatName,
  iconName,
  disabled,
  hint,
}: MarkButtonProps) => {
  const editor = useSlate();
  const isActive = isMarkActive(editor, format);
  const { isDark } = useLightOrDarkMode();

  const iconButtonClass = mergeStyles({
    backgroundColor: isActive
      ? isDark
        ? MEETINGFLOW_COLORS.purpleSecondary
        : MEETINGFLOW_COLORS.white
      : 'transparent',
    color: isActive
      ? isDark
        ? MEETINGFLOW_COLORS.white
        : MEETINGFLOW_COLORS.purpleSecondary
      : NeutralColors.gray30,
    width: '1.75rem',
    height: '1.75rem',
    i: {
      fontWeight: FontWeights.semibold,
    },

    ':hover': {
      backgroundColor: MEETINGFLOW_COLORS.purpleSecondary,
      color: 'white !important',
    },
  });

  const title = `Toggle ${formatName || format}` + (hint ? ` (${hint})` : '');

  return (
    <IconButton
      iconProps={{ iconName }}
      className={iconButtonClass}
      title={title}
      alt={title}
      disabled={disabled}
      data-meetingflow-is-active={isActive}
      onClick={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    />
  );
};
