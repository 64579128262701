import {
  FontWeights,
  IconButton,
  mergeStyles,
  NeutralColors,
} from '@fluentui/react';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { Element } from 'slate';
import { useSlate } from 'slate-react';
import { isBlockActive, toggleBlock } from '../Helpers/EditorHelpers';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';

type FormatButtonProps = {
  format: Element['type'];
  formatName?: string;
  iconName: string;
  disabled?: boolean;
  hint?: string;
};

export function BlockButton({
  format,
  formatName,
  iconName,
  disabled,
  hint,
}: FormatButtonProps) {
  const editor = useSlate();
  const isActive = isBlockActive(editor, format);
  const { isDark } = useLightOrDarkMode();

  const iconButtonClass = mergeStyles({
    backgroundColor: isActive
      ? isDark
        ? MEETINGFLOW_COLORS.purpleSecondary
        : MEETINGFLOW_COLORS.purpleMedium
      : 'transparent',
    color: isActive
      ? isDark
        ? MEETINGFLOW_COLORS.white
        : MEETINGFLOW_COLORS.purpleSecondary
      : NeutralColors.gray30,
    width: '1.75rem',
    height: '1.75rem',
    i: {
      fontWeight: FontWeights.semibold,
    },

    ':hover': {
      backgroundColor: MEETINGFLOW_COLORS.purpleSecondary,
      color: 'white !important',
    },
  });

  const title = `Toggle ${formatName || format}` + (hint ? ` (${hint})` : '');

  return (
    <IconButton
      disabled={disabled}
      iconProps={{ iconName }}
      className={iconButtonClass}
      title={title}
      alt={title}
      data-meetingflow-is-active={isActive}
      onClick={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    />
  );
}
