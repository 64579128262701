import { mergeStyleSets } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

type TimeAgoStatusProps = {
  timeToShow: string;
  isHighlighted?: boolean;
};

export const TimeAgoStatus = ({
  timeToShow,
  isHighlighted = false,
}: TimeAgoStatusProps) => {
  const styles = mergeStyleSets({
    timeAgo: {
      color: isHighlighted
        ? DEALROOMS_COLORS.notificationBubble
        : DEALROOMS_COLORS.themeSecondary,
      textAlign: 'right',
      minWidth: 'fit-content',
    },
  });

  return <div className={styles.timeAgo}>{timeToShow}</div>;
};
