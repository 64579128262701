import { BuyerPrioritiesDialog } from '../../../Components/DealRoom/Dialogs/BuyerPrioritiesDialog';
import { DealRole } from '@meetingflow/common/Api/data-contracts';

type UseBuyerPrioritiesDialogProps = {
  organizationSlug?: string;
  dealRoomId?: number;
  handleCloseModal: () => void;
  userDealRole: DealRole | null | undefined;
};

export const useBuyerPrioritiesDialog = ({
  organizationSlug,
  dealRoomId,
  handleCloseModal,
  userDealRole,
}: UseBuyerPrioritiesDialogProps) => {
  return (
    <BuyerPrioritiesDialog
      organizationSlug={organizationSlug}
      dealRoomId={dealRoomId}
      handleCloseModal={handleCloseModal}
      userDealRole={userDealRole}
    />
  );
};
