import { styled } from '@mui/material/styles';

export const StyledMeetingflowListFiltersContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  background-color: white;
  width: 100%;

  .filter-item {
    width: 100%;
    min-width: 10rem;
    max-width: 10rem;
    padding-top: .5rem;
  }

  .filter-item-pair {
    min-width: 20.25rem;
    max-width: 20.25rem;
    display: flex;
    gap: .25rem;
  }
`;
