import { useAuth0 } from '@auth0/auth0-react';
import {
  FontSizes,
  FontWeights,
  NeutralColors,
  Spinner,
  Text,
  mergeStyles,
} from '@fluentui/react';
import {
  DetailedMeetingflow,
  ExternalServiceObject,
} from '@meetingflow/common/Api/data-contracts';
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useExternalServiceConfigurations } from '../../../../Hooks/useExternalServiceConfigurations';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { SalesforceAccount } from '../../../../Models/Salesforce/SalesforceAccount';
import { SalesforceContact } from '../../../../Models/Salesforce/SalesforceContact';
import { SalesforceLead } from '../../../../Models/Salesforce/SalesforceLead';
import { SalesforceOpportunity } from '../../../../Models/Salesforce/SalesforceOpportunity';
import {
  SalesforceAccountSuggestionsQuery,
  SalesforceContactSuggestionsQuery,
  SalesforceLeadSuggestionsQuery,
  SalesforceOpportunitySuggestionsQuery,
} from '../../../../QueryNames';
import { ApiClient } from '../../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../../Themes/Themes';
import { SalesforcePanelContext } from '../../../../types/SalesforcePanelContext';
import { SalesforceAccountTile } from './SalesforceAccountTile';
import { SalesforceContactTile } from './SalesforceContactTile';
import { SalesforceLeadTile } from './SalesforceLeadTile';
import { SalesforceOpportunityTile } from './SalesforceOpportunityTile';

interface SalesforceInsightsPanelContentProps {
  organizationSlug: string;
  meetingflowId: string;
  meetingflow: Pick<DetailedMeetingflow, 'creator' | 'organizer' | 'attendees'>;
  configurationId: number;
  externalDomains?: string[];
  externalContactIds?: number[];
  onPickedObject: (
    object: ExternalServiceObject | Omit<ExternalServiceObject, 'id'>,
  ) => void;
  onPinnedObject:
    | ((e?: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<unknown>)
    | undefined;
  pushSalesforcePanel: (context: SalesforcePanelContext) => void;
  associatedObjects?: ExternalServiceObject[];
  showSuggestionsToggle?: boolean;
  showTitle?: boolean;
  showSalesforceObjectPicker?: () => void;
  maxItems?: number;
}

export const SalesforceInsightsPanelContent = ({
  organizationSlug,
  meetingflowId,
  configurationId,
  externalDomains,
  onPickedObject,
  onPinnedObject,
  pushSalesforcePanel,
  associatedObjects = [],
  showTitle = true,
  showSalesforceObjectPicker,
  maxItems = 5,
  externalContactIds,
}: SalesforceInsightsPanelContentProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const { loading: configurationsLoading, configurationById } =
    useExternalServiceConfigurations({ app: 'SALESFORCE', withToken: true });

  const { hasEntitlement } = useOrganization(organizationSlug);

  const associatedSalesforceObjects = useMemo(
    () =>
      associatedObjects.filter(
        (o) =>
          o.serviceConfiguration.app === 'SALESFORCE' &&
          o.serviceConfigurationId === configurationId,
      ),
    [associatedObjects, configurationId],
  );

  const {
    data: salesforceAccountSuggestions,
    isLoading: salesforceAccountSuggestionsLoading,
    isRefetching: salesforceAccountSuggestionsRefetching,
    refetch: refetchSalesforceAccountSuggestions,
  } = useQuery(
    SalesforceAccountSuggestionsQuery(
      organizationSlug!,
      configurationId,
      externalDomains,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<SalesforceAccount[]>(
        `/organization/${organizationSlug}/external/salesforce/configuration/${configurationId}/accounts`,
        {
          params: {
            active: true,
            domains: externalDomains,
            limit: maxItems,
          },
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      // 5 min
      staleTime: 300000,
      enabled:
        !!organizationSlug && !!configurationId && !!externalDomains?.length,
    },
  );

  const {
    data: salesforceOpportunitySuggestions,
    isLoading: salesforceOpportunitySuggestionsLoading,
    isRefetching: salesforceOpportunitySuggestionsRefetching,
    refetch: refetchSalesforceOpportunitySuggestions,
  } = useQuery(
    SalesforceOpportunitySuggestionsQuery(
      organizationSlug!,
      configurationId,
      undefined,
      externalDomains,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<SalesforceOpportunity[]>(
        `/organization/${organizationSlug}/external/salesforce/configuration/${configurationId}/opportunities`,
        {
          params: {
            active: true,
            domains: externalDomains,
            limit: maxItems,
          },
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      // 5 min
      staleTime: 300000,
      enabled:
        !!organizationSlug && !!configurationId && !!externalDomains?.length,
    },
  );

  const {
    data: salesforceContactSuggestions,
    isLoading: salesforceContactSuggestionsLoading,
    isRefetching: salesforceContactSuggestionsRefetching,
    refetch: refetchSalesforceContactSuggestions,
  } = useQuery(
    SalesforceContactSuggestionsQuery(
      organizationSlug!,
      configurationId,
      externalContactIds,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<SalesforceContact[]>(
        `/organization/${organizationSlug}/external/salesforce/configuration/${configurationId}/contacts`,
        {
          params: {
            contactIds: externalContactIds,
            limit: maxItems,
          },
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      // 5 min
      staleTime: 300000,
      enabled:
        !!organizationSlug && !!configurationId && !!externalContactIds?.length,
    },
  );

  const {
    data: salesforceLeadSuggestions,
    isLoading: salesforceLeadSuggestionsLoading,
    isRefetching: salesforceLeadSuggestionsRefetching,
    refetch: refetchSalesforceLeadSuggestions,
  } = useQuery(
    SalesforceLeadSuggestionsQuery(
      organizationSlug!,
      configurationId,
      undefined,
      externalContactIds,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<SalesforceLead[]>(
        `/organization/${organizationSlug}/external/salesforce/configuration/${configurationId}/leads`,
        {
          params: {
            active: true,
            contactIds: externalContactIds,
            limit: maxItems,
          },
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      // 5 min
      staleTime: 300000,
      enabled:
        !!organizationSlug && !!configurationId && !!externalContactIds?.length,
    },
  );

  useEffect(() => {
    if (!!organizationSlug && !!configurationId) {
      if (!!externalDomains?.length) {
        refetchSalesforceAccountSuggestions();
        refetchSalesforceOpportunitySuggestions();
      }
      if (!!externalContactIds?.length) {
        refetchSalesforceContactSuggestions();
        refetchSalesforceLeadSuggestions();
      }
    }
  }, [
    configurationId,
    externalContactIds?.length,
    externalDomains?.length,
    organizationSlug,
    refetchSalesforceAccountSuggestions,
    refetchSalesforceLeadSuggestions,
    refetchSalesforceContactSuggestions,
    refetchSalesforceOpportunitySuggestions,
  ]);

  const listClass = mergeStyles({
    position: 'relative',
    animationName: 'fadeInSlideAnimation',
    animationDuration: '1s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '1',
    animationFillMode: 'forwards',
    width: 'auto',
    transition: '.3s ease-in-out all',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    rowGap: '.125rem',
    marginBottom: '1rem',
  });

  const slideSpinnerClass = mergeStyles({
    height: '2rem',
    animationName: 'slideDownSpinnerAnimation',
    animationDuration: '1s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '.5',
    animationFillMode: 'forwards',
  });

  const sectionSubHeaderClass = mergeStyles({
    margin: '.5rem 0 .5rem 0!important',
    fontSize: FontSizes.mini,
    fontWeight: FontWeights.semibold,
    color: NeutralColors.gray100,
    position: 'relative',
    display: 'block',
    width: '100%',
  });

  const sectionHeaderClass = mergeStyles({
    margin: '0 0 0 0',
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.semibold,
    color: MEETINGFLOW_COLORS.teal,
    position: 'relative',
    display: 'block',
    width: '100%',

    userSelect: 'none',
    webkitUserSelect: 'none',
  });

  const salesforceAccounts = salesforceAccountSuggestions?.data
    ?.filter((account) => {
      const associatedObjectIds = associatedObjects.map((o) => o.externalId);
      return !associatedObjectIds.includes(account.Id);
    })
    ?.slice(0, maxItems);

  const salesforceOpps = salesforceOpportunitySuggestions?.data
    ?.filter((opportunity) => {
      const associatedObjectIds = associatedObjects.map((o) => o.externalId);
      return !associatedObjectIds.includes(opportunity.Id);
    })
    ?.slice(0, maxItems);

  const salesforceContacts = salesforceContactSuggestions?.data
    ?.filter((contact) => {
      const associatedObjectIds = associatedObjects.map((o) => o.externalId);
      return !associatedObjectIds.includes(contact.Id);
    })
    ?.slice(0, maxItems);

  const salesforceLeads = salesforceLeadSuggestions?.data
    ?.filter((lead) => {
      const associatedObjectIds = associatedObjects.map((o) => o.externalId);
      return !associatedObjectIds.includes(lead.Id);
    })
    ?.slice(0, maxItems);

  if (configurationsLoading) {
    return (
      <div style={{ margin: '1rem 0' }}>
        <Spinner className={slideSpinnerClass} />
      </div>
    );
  }

  if (
    !salesforceAccounts?.length &&
    !salesforceOpps?.length &&
    !salesforceContacts?.length &&
    !salesforceLeads?.length &&
    !associatedObjects.length
  ) {
    return null;
  }

  return (
    <>
      <div className={listClass}>
        <Text block className={sectionHeaderClass}>
          Salesforce
        </Text>

        {showTitle && associatedObjects.length ? (
          <Text
            block
            className={sectionSubHeaderClass}
            style={{
              marginBottom: '.5rem',
            }}
          >
            Pinned Items
          </Text>
        ) : null}

        {associatedObjects.length
          ? associatedObjects.map((o) => {
              const objType = o.objectType;
              switch (objType) {
                case 'ACCOUNT': {
                  return (
                    <SalesforceAccountTile
                      meetingPlanId={meetingflowId}
                      organizationSlug={organizationSlug}
                      key={o.externalId}
                      externalId={o.externalId}
                      name={o.name}
                      salesforceInstance={
                        configurationById(configurationId)!.instanceId!
                      }
                      onClick={() => onPickedObject(o)}
                      isPinned={true}
                      externalServiceObjectId={o.id}
                      isLoading={false}
                      onPinClick={onPinnedObject}
                      showObjectType
                      showExternalLink
                    />
                  );
                }
                case 'CONTACT': {
                  return (
                    <SalesforceContactTile
                      meetingPlanId={meetingflowId}
                      organizationSlug={organizationSlug}
                      key={o.externalId}
                      externalId={o.externalId}
                      name={o.name}
                      email={null}
                      salesforceInstance={
                        configurationById(configurationId)!.instanceId!
                      }
                      onClick={() => onPickedObject(o)}
                      isPinned={true}
                      externalServiceObjectId={o.id}
                      isLoading={false}
                      onPinClick={onPinnedObject}
                      showExternalLink
                      showObjectType
                    />
                  );
                }
                case 'LEAD': {
                  if (!hasEntitlement('SALESFORCE_LEADS')) {
                    return null;
                  }

                  return (
                    <SalesforceLeadTile
                      meetingPlanId={meetingflowId}
                      organizationSlug={organizationSlug}
                      key={o.externalId}
                      externalId={o.externalId}
                      name={o.name}
                      salesforceInstance={
                        configurationById(configurationId)!.instanceId!
                      }
                      onClick={() => onPickedObject(o)}
                      isPinned={true}
                      externalServiceObjectId={o.id}
                      isLoading={false}
                      onPinClick={onPinnedObject}
                      showObjectType
                    />
                  );
                }
                case 'DEAL': {
                  return (
                    <SalesforceOpportunityTile
                      organizationSlug={organizationSlug}
                      meetingPlanId={meetingflowId}
                      key={o.externalId}
                      externalId={o.externalId}
                      name={o.name}
                      accountName={o.parent?.name}
                      onClickAccountName={(
                        e?: React.MouseEvent<HTMLElement>,
                      ) => {
                        e?.preventDefault();
                        e?.stopPropagation();
                        if (
                          o.parent?.objectType === 'ACCOUNT' &&
                          o.parent.externalId
                        ) {
                          pushSalesforcePanel({
                            name: o.parent.name,
                            objectId: o.parent.externalId,
                            objectType: 'ACCOUNT',
                            serviceConfigurationId: configurationId,
                          });
                        }
                      }}
                      salesforceInstance={
                        configurationById(configurationId)!.instanceId!
                      }
                      onClick={() => onPickedObject(o)}
                      isPinned={true}
                      externalServiceObjectId={o.id}
                      isLoading={false}
                      onPinClick={onPinnedObject}
                      showObjectType
                    />
                  );
                }
                default:
                  return null;
              }
            })
          : null}

        {showTitle && salesforceAccounts?.length ? (
          <Text block className={sectionSubHeaderClass}>
            Suggested Accounts
          </Text>
        ) : null}
        {salesforceAccounts?.length
          ? salesforceAccounts.map((account) => {
              const externalServiceObject = {
                externalId: account.Id,
                objectType: 'ACCOUNT',
                name: account.Name,
                serviceConfigurationId: configurationId,
                serviceConfiguration: configurationById(configurationId)!,
              } as ExternalServiceObject;
              return (
                <SalesforceAccountTile
                  key={account.Id}
                  meetingPlanId={meetingflowId}
                  organizationSlug={organizationSlug}
                  externalId={account.Id}
                  name={account.Name}
                  salesforceInstance={
                    configurationById(configurationId)!.instanceId!
                  }
                  onClick={() => onPickedObject(externalServiceObject)}
                  onPinClick={onPinnedObject}
                  showExternalLink
                />
              );
            })
          : null}

        {showTitle && salesforceOpps?.length ? (
          <Text block className={sectionSubHeaderClass}>
            Suggested Opportunities
          </Text>
        ) : null}
        {salesforceOpps?.length
          ? salesforceOpps.map((opp) => {
              const externalServiceObject = {
                externalId: opp.Id,
                objectType: 'DEAL',
                name: opp.Name,
                serviceConfigurationId: configurationId,
                serviceConfiguration: configurationById(configurationId)!,
              } as ExternalServiceObject;

              return (
                <SalesforceOpportunityTile
                  key={opp.Id}
                  organizationSlug={organizationSlug}
                  meetingPlanId={meetingflowId}
                  externalId={opp.Id}
                  name={opp.Name}
                  accountName={opp.Account?.Name}
                  showExternalLink
                  onClickAccountName={(e?: React.MouseEvent<HTMLElement>) => {
                    e?.preventDefault();
                    e?.stopPropagation();
                    if (opp.Account?.Id) {
                      pushSalesforcePanel({
                        name: opp.Account?.Name,
                        objectId: opp.Account?.Id,
                        objectType: 'DEAL',
                        serviceConfigurationId: configurationId,
                      });
                    }
                  }}
                  salesforceInstance={
                    configurationById(configurationId)!.instanceId!
                  }
                  onClick={() => onPickedObject(externalServiceObject)}
                  isLoading={false}
                  onPinClick={onPinnedObject}
                />
              );
            })
          : null}

        {showTitle && salesforceContacts?.length ? (
          <Text block className={sectionSubHeaderClass}>
            Suggested Contacts
          </Text>
        ) : null}
        {salesforceContacts?.length
          ? salesforceContacts.map((contact) => {
              const externalServiceObject = {
                externalId: contact.Id,
                objectType: 'CONTACT',
                name: contact.Name,
                serviceConfigurationId: configurationId,
                serviceConfiguration: configurationById(configurationId)!,
              } as ExternalServiceObject;
              return (
                <SalesforceContactTile
                  key={contact.Id}
                  meetingPlanId={meetingflowId}
                  organizationSlug={organizationSlug}
                  externalId={contact.Id}
                  name={contact.Name}
                  email={contact.Email}
                  salesforceInstance={
                    configurationById(configurationId)!.instanceId!
                  }
                  onClick={() => onPickedObject(externalServiceObject)}
                  onPinClick={onPinnedObject}
                  showExternalLink
                />
              );
            })
          : null}

        {showTitle && salesforceLeads?.length ? (
          <Text block className={sectionSubHeaderClass}>
            Suggested Leads
          </Text>
        ) : null}
        {salesforceLeads?.length
          ? salesforceLeads.map((lead) => {
              const externalServiceObject = {
                externalId: lead.Id,
                objectType: 'LEAD',
                name: lead.Name,
                serviceConfigurationId: configurationId,
                serviceConfiguration: configurationById(configurationId)!,
              } as ExternalServiceObject;
              return (
                <SalesforceLeadTile
                  key={lead.Id}
                  meetingPlanId={meetingflowId}
                  organizationSlug={organizationSlug}
                  externalId={lead.Id}
                  name={lead.Name}
                  salesforceInstance={
                    configurationById(configurationId)!.instanceId!
                  }
                  onClick={() => onPickedObject(externalServiceObject)}
                  onPinClick={onPinnedObject}
                  showExternalLink
                />
              );
            })
          : null}
      </div>
    </>
  );
};
