import React from 'react';
import { Checkbox, CheckboxProps } from '@mui/material';
import { DSCheckboxRoot } from './DSCheckbox.styles';

export interface DSCheckboxProps extends CheckboxProps {
  children?: React.ReactNode;
}

const DSCheckbox: React.FC<DSCheckboxProps> = ({ children, ...props }) => {
  return <DSCheckboxRoot {...props}>{children}</DSCheckboxRoot>;
};

export default DSCheckbox;
