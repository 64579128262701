import {
  FontSizes,
  FontWeights,
  Link,
  mergeStyles,
  NeutralColors,
  Spinner,
  Text,
} from '@fluentui/react';
import { CompanyNewsById } from '../../../QueryNames';
import { CompanyNews } from '../../../Models/CompanyNews';
import { useAuth0 } from '@auth0/auth0-react';
import { useQueries } from 'react-query';
import { ApiClient } from '../../../Services/NetworkCommon';
import StyledDateTime from '../../StyledDateTime';
import React from 'react';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { Company } from '@meetingflow/common/Api/data-contracts';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { renderToString } from 'react-dom/server';

export type CompanyNewsListProps = {
  organizationSlug: string;
  companies: Pick<Company, 'id' | 'name' | 'logo'>[];
  maxArticlesPerCompany?: number;
  title?: string;
  showBingPrivacyNotice?: boolean;
  blankIfNoArticles?: boolean;
};
export const CompanyNewsList = ({
  organizationSlug,
  companies,
  maxArticlesPerCompany = 3,
  title = 'News',
  showBingPrivacyNotice = true,
  blankIfNoArticles,
}: CompanyNewsListProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const { isDark } = useLightOrDarkMode();

  const companyQueries = companies.map((c) => ({
    queryKey: CompanyNewsById(organizationSlug, c.id),
    queryFn: async () => {
      const token = await getAccessTokenSilently();

      const newsResult = await ApiClient.get<CompanyNews[]>(
        `/organization/${organizationSlug}/companies/${c.id}/news`,
        {
          headers: { Authorization: `Bearer ${token}` },
          params: { limit: maxArticlesPerCompany },
        },
      );
      return newsResult;
    },
  }));

  const results = useQueries(companyQueries);

  const companyNewsListClass = mergeStyles({
    '@container (width >= 35rem)': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      columnGap: '.5rem',

      '> div': {
        flexBasis: 'calc(50% - .25rem)',
        maxWidth: 'calc(50% - 0.25rem)',
      },

      '> div:only-child': {
        flexBasis: '100%',
        maxWidth: '100%',
      },
    },

    '@container (width < 35rem)': {
      display: 'block',
    },
  });

  const newsArticleClass = mergeStyles({
    margin: '0',
    padding: '.5rem .5rem .5rem calc(72px + 1.25rem)',
    boxSizing: 'border-box',
    borderRadius: '.25rem',
    marginBottom: '.5rem',
    position: 'relative',
    width: 'auto',
    height: 'auto',
    minHeight: 'calc(72px + 1rem)',
    transition: '.3s all ease-in-out',
    backgroundColor: isDark
      ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
      : MEETINGFLOW_COLORS.white,

    '.article-image': {
      position: 'absolute',
      top: '.5rem',
      left: '.5rem',
      height: '72px',
      width: '72px',
      objectFit: 'cover',
      overflow: 'hidden',
    },

    '.article-image-none': {
      position: 'absolute',
      top: '.5rem',
      left: '.5rem',
      height: '72px',
      width: '72px',
      objectFit: 'cover',
      overflow: 'hidden',
      backgroundColor: isDark ? NeutralColors.gray220 : NeutralColors.gray20,
      textAlign: 'center',
      verticalAlign: 'center',
      lineHeight: '72px',
      fontSize: FontSizes.xLarge,
      fontWeight: FontWeights.bold,
      textTransform: 'capitalize',
      color: MEETINGFLOW_COLORS.orange,
    },

    '.article-title': {
      overflow: 'hidden',
      width: '100%',
      display: '-webkit-box',
      fontSize: FontSizes.small,
      fontWeight: FontWeights.semibold,
      '-webkit-line-clamp': '1',
      '-webkit-box-orient': 'vertical',
      textDecoration: 'none !important',
      paddingRight: '1.75rem',
      boxSizing: 'border-box',
    },

    '.article-description': {
      display: '-webkit-box',
      color: NeutralColors.gray120,
      fontSize: FontSizes.small,
      overflow: 'hidden',
      width: '100%',
      boxSizing: 'border-box',
      transition: '.3s all ease-in-out',
      '-webkit-line-clamp': '2',
      '-webkit-box-orient': 'vertical',
      animationName: 'fadeInAnimation',
      animationDuration: '1s',
      transitionTimingFunction: 'linear',
      animationIterationCount: '1',
      animationFillMode: 'forwards',
      paddingRight: '1.75rem',
      marginBottom: '.15rem',
    },

    '.article-date': {
      color: NeutralColors.gray80,
      fontSize: FontSizes.mini,
      textTransform: 'uppercase',
      fontWeight: FontWeights.semibold,
      marginBottom: '.1rem',
      maxWidth: '100%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      paddingRight: '1.75rem',
      marginTop: '.5rem',

      span: {
        fontWeight: FontWeights.regular,
        textTransform: 'none',
      },
    },

    '.provider-logo': {
      height: '24px',
      width: '24px',
      position: 'absolute',
      bottom: '.5rem',
      right: '.5rem',
      borderRadius: '.25rem',
      overflow: 'hidden',
      backgroundColor: isDark ? NeutralColors.gray220 : NeutralColors.gray10,
      zIndex: 5,
    },
  });

  const isLoading = results.some((query) => query.isLoading);
  const hasData = results.some((query) => query?.data?.data?.length);
  const aggregatedData = React.useMemo(() => {
    let articleList: CompanyNews[] = [];
    results.forEach((response) => {
      if (response?.data?.data) {
        articleList = [
          ...articleList,
          ...(response?.data?.data.slice(0, maxArticlesPerCompany) || []),
        ];
      }
    });
    return articleList;
  }, [results, maxArticlesPerCompany]);

  if (blankIfNoArticles && !hasData) {
    return <></>;
  }

  return (
    <>
      <Text
        block
        style={{
          margin: '1rem 0 .5rem 0 ',
          fontSize: FontSizes.medium,
          fontWeight: FontWeights.semibold,
          color: MEETINGFLOW_COLORS.teal,
          position: 'relative',
          display: 'block',
        }}
      >
        {title}
      </Text>
      {isLoading ? <Spinner /> : null}

      <div id="company-news-list-articles" className={companyNewsListClass}>
        {results.map((response, idx) => {
          const company = companies[idx];
          return (
            <>
              {response?.data?.data?.map((n) => (
                <div
                  id="company-news-list-articles-article"
                  className={
                    n?.image?.thumbnail?.contentUrl
                      ? newsArticleClass
                      : mergeStyles(newsArticleClass, { paddingLeft: '5.5rem' })
                  }
                  key={`${n.name}_${n.datePublished}`}
                  // NOTE: Please preserve spacing, it affects rendered output
                  title={`${n?.name}

${n?.description}

${company?.name} — ${renderToString(
                    <StyledDateTime dateTime={n?.datePublished} />,
                  )
                    .replace('<span>', '')
                    .replace('</span>', '')
                    .replace(/(\n)\s+/g, '$1')}`}
                >
                  <Link href={n.url} target={'_blank'} title={n?.name}>
                    {n.provider?.[0]?.image?.thumbnail?.contentUrl ? (
                      <img
                        className={'provider-logo'}
                        src={n.provider?.[0]?.image?.thumbnail?.contentUrl}
                        alt={n.provider?.[0]?.name}
                        onError={(e) => {
                          // @ts-ignore
                          e.target.style.display = 'none';
                        }}
                      />
                    ) : null}
                  </Link>
                  {n?.image?.thumbnail?.contentUrl || company?.logo ? (
                    <img
                      src={
                        n?.image?.thumbnail?.contentUrl || company?.logo || ''
                      }
                      className="article-image"
                      alt={n?.name}
                      title={`${n?.name}

${n?.description}

${company?.name} — ${renderToString(
                        <StyledDateTime dateTime={n?.datePublished} />,
                      )
                        .replace('<span>', '')
                        .replace('</span>', '')
                        .replace(/(\n)\s+/g, '$1')}`}
                      onError={(e) => {
                        // @ts-ignore
                        e.target.style.display = 'none';
                      }}
                    />
                  ) : (
                    <div className={'article-image-none'}>
                      {company?.name?.slice(0, 3)}
                    </div>
                  )}
                  <Link
                    href={n.url}
                    target={'_blank'}
                    className={'article-title'}
                  >
                    {n?.name}
                  </Link>
                  <Text block className={'article-description'}>
                    {n?.description}
                  </Text>
                  <Text block className={'article-date'}>
                    <span>{company?.name} — </span>
                    <StyledDateTime dateTime={n?.datePublished} />
                  </Text>
                </div>
              ))}
            </>
          );
        })}
      </div>
      {aggregatedData?.length ? (
        !isLoading && showBingPrivacyNotice ? (
          <Text
            block
            style={{
              textAlign: 'center',
              fontSize: FontSizes.mini,
              color: NeutralColors.gray100,
            }}
          >
            News provided by Microsoft Bing{' • '}
            <Link href="https://privacy.microsoft.com/en-us/privacystatement">
              Privacy Statement
            </Link>
          </Text>
        ) : null
      ) : !isLoading ? (
        <Text>No news was found for these companies.</Text>
      ) : null}
    </>
  );
};
