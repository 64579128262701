import classNames from 'classnames';
import { mergeStyles, PrimaryButton, Text, TextField } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { SurveyUserOption } from '../../../../types/BuyerOrientationSurveyTypes';
import React, { useCallback } from 'react';

type SurveyOptionCardProps = {
  option: SurveyUserOption;
  isSelected: boolean;
  handleSelectOption?: (newValue: string) => void;
  className?: string;
  isDisabled?: boolean;
  showTextArea?: boolean;
  handleChangeTextArea?: (value: string) => void;
  textAreaValue?: string;
  showOtherPriorityText?: boolean;
  otherPriorityText?: string;
};

export const SurveyOptionCard = ({
  option,
  isSelected,
  handleSelectOption,
  className,
  isDisabled,
  showTextArea,
  handleChangeTextArea,
  textAreaValue,
  showOtherPriorityText,
  otherPriorityText,
}: SurveyOptionCardProps) => {
  const cardWrapper = mergeStyles({
    position: 'relative',
    padding: '0',
    borderRadius: '0.25rem',
    border: `2px solid ${DEALROOMS_COLORS.neutralLight}`,
    boxShadow: '0px 2px 3px 0px #00000008',
    backgroundColor: DEALROOMS_COLORS.white,
    color: DEALROOMS_COLORS.darkerGray,
    minHeight: 'fit-content',
    flexBasis: '31%',

    ':hover': {
      backgroundColor: DEALROOMS_COLORS.financialSecondaryGray,
      border: `2px solid ${DEALROOMS_COLORS.financialSecondaryGray}`,
    },
    ':focus': {
      outline: `2px solid  ${DEALROOMS_COLORS.darkerGray}`,
      outlineOffset: '2px',
    },
    ':active': {
      backgroundColor: DEALROOMS_COLORS.neutralLighter,
      border: `2px solid ${DEALROOMS_COLORS.neutralLight}`,
    },

    '::after': {
      outline: 'none !important',
    },

    '@media(max-width: 720px)': {
      flexBasis: '100%',
    },

    '.ms-Button-flexContainer': {
      alignItems: 'flex-start',
    },

    '.card-item': {
      padding: '1.5rem 1rem',
      borderRadius: '4px',
      gap: '0.5rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',

      '.card-item-title': {
        fontSize: '1.125rem',
        lineHeight: '1.25rem',
        textAlign: 'center',
        color: DEALROOMS_COLORS.themePrimary,
      },

      '.card-item-subtitle': {
        fontSize: '0.8125rem',
        lineHeight: '1rem',
        textAlign: 'center',
        fontWeight: '400',
        color: DEALROOMS_COLORS.themeSecondary,
      },

      '.other-option-textarea': {
        width: '100%',
        '.ms-TextField-fieldGroup': {
          border: 'none',
          minHeight: '50px',
        },
        textArea: {
          backgroundColor: DEALROOMS_COLORS.inputLightGray,
          borderRadius: '4px',
          '::placeholder': {
            color: DEALROOMS_COLORS.userSurveyInputPlaceholder,
          },
        },
      },
    },
  });

  const selectedStyle = mergeStyles({
    border: `2px solid ${DEALROOMS_COLORS.userSurveyPrimary} !important`,
    ':focus': {
      outline: 'none',
    },
  });

  const disabledStyle = mergeStyles({
    opacity: '0.3',
  });

  const handleTextFieldOnClick = useCallback(
    (
      e: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement, MouseEvent>,
    ) => {
      e.preventDefault();
      e.stopPropagation();
    },
    [],
  );

  const handleTextFieldOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (e.key === ' ') {
        e.preventDefault();
        e.stopPropagation();
        handleChangeTextArea &&
          handleChangeTextArea(
            (e.target && 'value' in e.target ? e.target.value : '') + ' ',
          );
      }
    },
    [handleChangeTextArea],
  );

  const handleTextFieldOnChange = useCallback(
    (
      e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue: string | undefined,
    ) => {
      e.preventDefault();
      e.stopPropagation();
      handleChangeTextArea && handleChangeTextArea(newValue || '');
    },
    [handleChangeTextArea],
  );

  return (
    <PrimaryButton
      key={option.optionId}
      className={classNames(
        cardWrapper,
        isSelected ? selectedStyle : undefined,
        isDisabled ? disabledStyle : undefined,
        className,
      )}
      onClick={() => handleSelectOption && handleSelectOption(option.value)}
    >
      <div className="card-item">
        {option.icon && option.icon}
        <Text className="card-item-title">{option.title}</Text>
        {option.subtitle && !showOtherPriorityText && (
          <Text className="card-item-subtitle">{option.subtitle}</Text>
        )}
        {showOtherPriorityText && (
          <Text className="card-item-subtitle">{otherPriorityText}</Text>
        )}
        {showTextArea && (
          <TextField
            className="other-option-textarea"
            value={textAreaValue || ''}
            placeholder="Tell us about yourself and your priorities..."
            multiline
            resizable={false}
            onClick={handleTextFieldOnClick}
            onKeyDown={handleTextFieldOnKeyDown}
            onChange={handleTextFieldOnChange}
          />
        )}
      </div>
    </PrimaryButton>
  );
};
