import { useNavigate } from 'react-router-dom';
import { Text } from '@fluentui/react';
import { DealRoomPrimaryButton } from '../DealRoom/Components/DealRoomButton';
import { NoAccessIllustration } from '../DealRoom/Components/Illustrations/NoAccessIllustration';
import { useCallback } from 'react';
import { NotFoundLayout } from '../Layouts/NotFoundLayout';
import { useNotFoundContentStyles } from '../../Hooks/styles/useNotFoundContentStyles';

export const OrganizationForbidden = () => {
  const navigate = useNavigate();

  const styles = useNotFoundContentStyles();

  const handleClickActionButton = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <NotFoundLayout illustration={<NoAccessIllustration />}>
      <div className={styles.subtitleContainer}>
        <Text className="subtitle-item">Forbidden!</Text>
      </div>
      <div className={styles.descriptionContainer}>
        <Text className="description-item">
          Welcome to {import.meta.env.VITE_SITE_NAME}! You don't have access to
          that page. If you believe you should be able to view this, contact the
          owner of the workspace or the person who shared this link with you.
        </Text>
      </div>
      <DealRoomPrimaryButton
        customClasses={styles.actionButton}
        onClick={handleClickActionButton}
      >
        Back to Main Page
      </DealRoomPrimaryButton>
    </NotFoundLayout>
  );
};

export default OrganizationForbidden;
