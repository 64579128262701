import {
  DefaultButton,
  Dialog,
  DialogFooter,
  IDialogContentProps,
  PrimaryButton,
} from '@fluentui/react';
import { useDeferredPromise } from '../useDeferredPromise';
import { useLightOrDarkMode } from '../useLightOrDarkMode';

export type ConfirmationAction = 'CONFIRM' | 'CANCEL';
export type UseConfirmationDialogProps = Pick<
  IDialogContentProps,
  'title' | 'subText' | 'showCloseButton'
> & {
  primaryAction: ConfirmationAction;
  removeWhiteLabel?: boolean;
};
export const useConfirmationDialog = ({
  title,
  subText,
  showCloseButton = true,
  primaryAction,
  removeWhiteLabel,
}: UseConfirmationDialogProps) => {
  const { isDark } = useLightOrDarkMode();
  const { createDeferred, resolve, reject, deferred } =
    useDeferredPromise<boolean>();

  const dialog =
    deferred && deferred.isPending ? (
      <Dialog
        dialogContentProps={{
          title,
          subText,
          showCloseButton: showCloseButton,
        }}
        hidden={deferred?.isPending !== true}
        onDismiss={() => {
          resolve(false);
        }}
      >
        {primaryAction === 'CONFIRM' ? (
          <DialogFooter>
            <PrimaryButton
              text="Confirm"
              onClick={() => {
                resolve(true);
              }}
            />
            <DefaultButton text="Cancel" onClick={() => resolve(false)} />
          </DialogFooter>
        ) : (
          <DialogFooter>
            <DefaultButton
              text="Confirm"
              onClick={() => {
                resolve(true);
              }}
              styles={{
                label: {
                  color: isDark && !removeWhiteLabel ? 'white' : undefined,
                },
              }}
            />
            <PrimaryButton text="Cancel" onClick={() => resolve(false)} />
          </DialogFooter>
        )}
      </Dialog>
    ) : null;

  return {
    createDeferred,
    resolve,
    reject,
    deferred,
    dialog,
  };
};
