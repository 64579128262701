import React from 'react';
import classNames from 'classnames';
import { DealRoomAsyncCommandBarButton } from '../DealRoomButton';
import { DealRoomUpDownVote } from '../DealRoomUpDownVote';
import { ArtifactActionsProps } from './types';
import { mergeStyles } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

/**
 * ArtifactActions Component
 *
 * Displays and handles all action buttons for an artifact:
 * - Feature/Unfeature (for sellers)
 * - Download (for downloadable artifacts)
 * - Share
 * - Upvote/Downvote
 */
export const ArtifactActions: React.FC<ArtifactActionsProps> = ({
  artifact,
  dealRoomRole,
  onFeature,
  onDownload,
  // onShare,
  onUpvote,
  onDownvote,
}) => {
  const actions = mergeStyles({
    justifySelf: 'end',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'end',
    gap: '0.25rem',
    '.action-button': {
      '&.featured': {
        color: DEALROOMS_COLORS.darkerRed,
      },
      i: {
        margin: '0',
      },
    },
  });

  return (
    <div className={actions}>
      {dealRoomRole === 'SELLER' && (
        <DealRoomAsyncCommandBarButton
          customClasses={classNames('action-button', {
            featured: !!artifact?.featuredAt,
          })}
          name={!!artifact?.featuredAt ? 'Remove Feature' : 'Feature'}
          title="Feature"
          iconProps={{
            iconName: !!artifact.featuredAt
              ? 'FavoriteStarFill'
              : 'FavoriteStar',
          }}
          onClick={async (e) => {
            e.preventDefault();
            e.stopPropagation();
            await onFeature(!artifact?.featuredAt);
          }}
          buttonStyleType="COMMAND_BAR"
        />
      )}
      {'fileName' in artifact && (
        <DealRoomAsyncCommandBarButton
          customClasses="action-button"
          name="Download"
          title="Download"
          iconProps={{ iconName: 'Download' }}
          onClick={async (e) => {
            e.preventDefault();
            e.stopPropagation();
            await onDownload();
          }}
          buttonStyleType="COMMAND_BAR"
        />
      )}
      {/*<DealRoomAsyncCommandBarButton
        customClasses="action-button"
        name="Share"
        title="Share"
        iconProps={{ iconName: 'Share' }}
        onClick={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          await onShare();
        }}
        buttonStyleType="COMMAND_BAR"
      />*/}
      <DealRoomUpDownVote
        upvoted={artifact.upvoted}
        downvoted={artifact.downvoted}
        onUpvote={onUpvote}
        onDownvote={onDownvote}
      />
    </div>
  );
};
