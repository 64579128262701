import { useAuth0 } from '@auth0/auth0-react';
import {
  DetailedUser,
  PatchMePayload,
  UpdateUserWorkspacePreferencesPayload,
  UserWorkspacePreferences,
} from '@meetingflow/common/Api/data-contracts';
import * as Axios from 'axios';
import { Duration } from 'luxon';
import { useCallback, useEffect } from 'react';
import toast from 'react-hot-toast';
import {
  UseMutateAsyncFunction,
  UseMutateFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import {
  isAxiosErrorResponse,
  isBadRequest,
  isForbiddenError,
} from '../Helpers/AxiosHelpers';
import { OrganizationUserPreferencesQuery, ProfileQuery } from '../QueryNames';
import {
  OrganizationsApiClient,
  UserApiClient,
} from '../Services/NetworkCommon';
import { useOrganization } from './useOrganization';

export type UseUserProfileData = {
  userId?: number;
  user?: DetailedUser;
  isMeetingflowAdmin?: boolean;
  updateUserProfile: UseMutateFunction<
    Axios.AxiosResponse<DetailedUser>,
    unknown,
    PatchMePayload
  >;
  updateUserProfileAsync: UseMutateAsyncFunction<
    Axios.AxiosResponse<DetailedUser>,
    unknown,
    PatchMePayload
  >;
  workspacePreferences?: UserWorkspacePreferences;
  updateWorkspacePreferences: UseMutateFunction<
    Axios.AxiosResponse<UserWorkspacePreferences>,
    unknown,
    UpdateUserWorkspacePreferencesPayload
  > | null;
  updateWorkspacePreferencesAsync: UseMutateAsyncFunction<
    Axios.AxiosResponse<UserWorkspacePreferences>,
    unknown,
    UpdateUserWorkspacePreferencesPayload
  > | null;
  isLoading: boolean;
  isRefetching: boolean;
  isFetched: boolean;
  isError: boolean;
  userProfileError?: unknown;
  workspacePreferencesError?: unknown;
  refetch: () => Promise<unknown>;
  refetchBackground: () => Promise<unknown>;
};

export const useUserProfile = (): UseUserProfileData => {
  const { getAccessTokenSilently } = useAuth0();
  const client = useQueryClient();

  const { slug: organizationSlug } = useOrganization();

  const {
    data: profileData,
    isLoading: profileLoading,
    isRefetching: profileRefetching,
    isFetched: profileFetched,
    isError: profileError,
    error: profileErrorData,
    refetch: refetchProfile,
  } = useQuery(
    ProfileQuery,
    async () => {
      const token = await getAccessTokenSilently();
      return UserApiClient.getMe({
        headers: { Authorization: `Bearer ${token}` },
      });
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
      retry: (failureCount, error) => {
        if (isForbiddenError(error)) {
          return false;
        }
        if (isBadRequest(error)) {
          return false;
        }

        return failureCount < 3;
      },
      refetchInterval: (data) => {
        if (data?.data) {
          return Duration.fromObject({ minutes: 5 }).as('milliseconds');
        } else {
          return Duration.fromObject({ seconds: 15 }).as('milliseconds');
        }
      },
      onError: (err) => {
        if (isAxiosErrorResponse(err, 205)) {
          client.invalidateQueries();
        } else if (isAxiosErrorResponse(err, [500, 503, 504])) {
          toast.error('The Meetingflow server is temporarily unavailable.', {
            duration: Infinity,
            id: 'meetingflow-unavailable',
          });
        }
      },
      onSuccess: () => toast.dismiss('meetingflow-unavailable'),
    },
  );

  const { mutate: updateUserProfile, mutateAsync: updateUserProfileAsync } =
    useMutation(
      async (updatedUser: PatchMePayload) => {
        const token = await getAccessTokenSilently();
        return UserApiClient.patchMe(updatedUser, {
          headers: { Authorization: `Bearer ${token}` },
        });
      },
      {
        onMutate: async (updatedUser) => {
          await client.cancelQueries(ProfileQuery);

          const previousResponse =
            client.getQueryData<Axios.AxiosResponse<DetailedUser>>(
              ProfileQuery,
            );

          if (previousResponse) {
            client.setQueryData<Axios.AxiosResponse<DetailedUser>>(
              ProfileQuery,
              (old) => ({
                ...old!,
                data: {
                  ...old!.data,
                  ...updatedUser,
                  updatedAt: new Date().toISOString(),
                },
              }),
            );
          }

          return { previousResponse };
        },
        onError: (err, updatedUser, context) => {
          toast.error(
            'Something went wrong updating your user profile, try again',
          );
          if (context?.previousResponse) {
            client.setQueryData(ProfileQuery, context.previousResponse);
          }
        },
        onSuccess: () => {
          toast.success(`Your user profile was succesfully updated`);
        },
        onSettled: () => {
          client.invalidateQueries(ProfileQuery);
        },
      },
    );

  const {
    data: workspacePreferencesData,
    isLoading: workspacePreferencesLoading,
    isRefetching: workspacePreferencesRefetching,
    isFetched: workspacePreferencesFetched,
    isError: workspacePreferencesError,
    error: workspacePreferencesErrorData,
    refetch: refetchWorkspacePreferences,
  } = useQuery(
    OrganizationUserPreferencesQuery(organizationSlug),
    async () => {
      const token = await getAccessTokenSilently();
      return OrganizationsApiClient.getUserWorkspacePreferences(
        organizationSlug!,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!organizationSlug,
    },
  );

  useEffect(() => {
    if (organizationSlug) {
      refetchWorkspacePreferences();
    }
  }, [organizationSlug, refetchWorkspacePreferences]);

  const {
    mutate: updateWorkspacePreferences,
    mutateAsync: updateWorkspacePreferencesAsync,
  } = useMutation(
    async (updatedPreferences: UpdateUserWorkspacePreferencesPayload) => {
      if (!organizationSlug) {
        throw new Error(
          `Cannot update Workspace preferences outside context of Workspace`,
        );
      }

      const token = await getAccessTokenSilently();
      return OrganizationsApiClient.updateUserWorkspacePreferences(
        organizationSlug!,
        updatedPreferences,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      onMutate: async (updatedPreferences) => {
        if (!organizationSlug) {
          return;
        }

        await client.cancelQueries(
          OrganizationUserPreferencesQuery(organizationSlug),
        );

        const previousResponse = client.getQueryData<
          Axios.AxiosResponse<UserWorkspacePreferences>
        >(OrganizationUserPreferencesQuery(organizationSlug));

        if (previousResponse) {
          client.setQueryData<Axios.AxiosResponse<UserWorkspacePreferences>>(
            OrganizationUserPreferencesQuery(organizationSlug),
            (old) => ({
              ...old!,
              data: {
                ...old!.data,
                ...updatedPreferences,
                updatedAt: new Date().toISOString(),
              },
            }),
          );
        }

        return { previousResponse };
      },
      onError: (err, updatedPreferences, context) => {
        if (!organizationSlug) {
          return;
        }
        toast.error(
          'Something went wrong updating the your workspace preferences, try again',
        );
        if (context?.previousResponse) {
          client.setQueryData(
            OrganizationUserPreferencesQuery(organizationSlug),
            context.previousResponse,
          );
        }
      },
      onSuccess: () => {
        toast.success(`Your workspace preferences were succesfully updated`);
      },
      onSettled: () => {
        if (!organizationSlug) {
          return;
        }
        client.invalidateQueries(
          OrganizationUserPreferencesQuery(organizationSlug),
        );
      },
    },
  );

  const refetch = useCallback(async () => {
    await refetchProfile();
    if (organizationSlug) {
      await refetchWorkspacePreferences();
    }
  }, [organizationSlug, refetchProfile, refetchWorkspacePreferences]);

  const refetchBackground = useCallback(async () => {
    await client.invalidateQueries(ProfileQuery);
    await client.invalidateQueries(
      OrganizationUserPreferencesQuery(organizationSlug),
    );
  }, [client, organizationSlug]);

  return {
    userId: profileData?.data?.id,
    user: profileData?.data,
    isMeetingflowAdmin: !!profileData?.data?.isMFAdmin,
    updateUserProfile,
    updateUserProfileAsync,
    workspacePreferences: organizationSlug
      ? workspacePreferencesData?.data
      : undefined,
    updateWorkspacePreferences: organizationSlug
      ? updateWorkspacePreferences
      : null,
    updateWorkspacePreferencesAsync: organizationSlug
      ? updateWorkspacePreferencesAsync
      : null,
    isLoading: profileLoading || workspacePreferencesLoading,
    isRefetching: profileRefetching || workspacePreferencesRefetching,
    isFetched: profileFetched && workspacePreferencesFetched,
    isError: profileError || workspacePreferencesError,
    refetch,
    refetchBackground,
    userProfileError: profileErrorData,
    workspacePreferencesError: workspacePreferencesErrorData,
  };
};
