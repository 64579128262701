import {
  FontSizes,
  FontWeights,
  Icon,
  Link,
  mergeStyles,
  NeutralColors,
  Text,
} from '@fluentui/react';
import classNames from 'classnames';
import { MouseEventHandler } from 'react';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import HubSpotIcon from '../../../../Static/Images/hubspot.png';
import { MEETINGFLOW_COLORS } from '../../../../Themes/Themes';
import { HubSpotPanelContext } from '../../../../types/HubSpotPanelContext';
import { ModifierAwareLink } from '../../../Common/ModifierAwareLink';
import { HubSpotPinTileButton } from './HubSpotPinTileButton';

export type HubSpotDealTileProps = {
  organizationSlug: string;
  meetingPlanId?: string;
  id: string;
  name: string;
  hubspotInstance: string;
  companyName?: string;
  ownerName?: string;
  ownerEmail?: string;
  stage?: string;
  showArrow?: boolean;
  showObjectType?: boolean;
  showExternalLink?: boolean;
  isPinned?: boolean;
  externalServiceObjectId?: number;
  isLoading?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  showViewAllMeetingflows?: boolean;
  onPinClick?: (e?: React.MouseEvent<HTMLElement>) => Promise<unknown>;
  onClickCompanyName?: (e?: React.MouseEvent<HTMLElement>) => void;
  pushHubSpotPanel?: (context: HubSpotPanelContext) => void;
};
export const HubSpotDealTile = ({
  organizationSlug,
  meetingPlanId,
  id,
  name,
  hubspotInstance,
  companyName,
  ownerName,
  ownerEmail,
  stage,
  showArrow,
  showObjectType,
  showExternalLink,
  isPinned,
  externalServiceObjectId,
  isLoading,
  onClick,
  onPinClick,
  onClickCompanyName,
  showViewAllMeetingflows,
  pushHubSpotPanel,
}: HubSpotDealTileProps) => {
  const { isDark } = useLightOrDarkMode();

  const showOverline = !!ownerName || !!stage || !!showObjectType;

  const opportunityTileClass = mergeStyles({
    position: 'relative',
    transition: 'all .3s ease-in-out',
    cursor: onClick ? 'pointer' : undefined,
    backgroundColor: isDark
      ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
      : MEETINGFLOW_COLORS.white,
    padding: '.25rem',
    borderRadius: '.25rem',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    columnGap: '.25rem',
    rowGap: '.25rem',
    boxSizing: 'border-box',

    '.pin': {
      flexBasis: '22px',
    },

    '.content': {
      flexBasis: 'auto',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',

      '*': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },

    '>div:not(:last-child)': {
      marginBottom: '0.25rem',
    },

    ':last-child': {
      borderBottom: 'none',
    },
    '.arrow': {
      flexBasis: '24px',
      height: '24px',
      position: 'relative',
      alignSelf: 'center',
      color: NeutralColors.gray60,
      transition: '.3s ease-in-out all',
    },
    ':hover': {
      backgroundColor: onClick
        ? isDark
          ? NeutralColors.gray210
          : MEETINGFLOW_COLORS.purpleLighter
        : undefined,
        

      '.arrow': {
        color: MEETINGFLOW_COLORS.purpleDark,
      },
      img: { filter: `grayscale(0%) !important` },
    },
  });

  const salesforceLogoClass = mergeStyles({
    position: 'relative' as const,
    cursor: 'pointer',
    transition: '.3s ease-in-out all',
    flexBasis: '28px',
    height: '20px',
    alignSelf: 'center',
  });

  return (
    <div
      className={classNames(opportunityTileClass, 'HubSpot-opportunity-tile')}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.(e);
      }}
    >
      {meetingPlanId ? (
        <HubSpotPinTileButton
          className="pin"
          organizationSlug={organizationSlug}
          meetingPlanId={meetingPlanId}
          objectId={id}
          objectName={name}
          objectType={'DEAL'}
          tileHasOverline={showOverline}
          isPinned={isPinned}
          externalServiceObjectId={externalServiceObjectId}
          onPinToggle={onPinClick}
        />
      ) : null}
      <div className="content">
        {showOverline ? (
          <Text
            style={{
              lineHeight: '1rem',
              fontWeight: FontWeights.bold,
              color: NeutralColors.gray100,
              textTransform: 'uppercase',
              fontSize: '8px',
              display: 'block',
            }}
            block
            nowrap
          >
            {showObjectType ? <span>Deal • </span> : null}
            {stage ? (
              <span style={{ textTransform: 'initial' }}>
                {showObjectType ? ' • ' : null}
                {stage}
              </span>
            ) : (
              ''
            )}
            {ownerName || ownerEmail || isLoading ? (
              <span style={{ textTransform: 'initial' }}>
                {showObjectType || stage ? ' • ' : null}
                {ownerName ?? ownerEmail ?? 'Loading...'}
              </span>
            ) : (
              ''
            )}
          </Text>
        ) : null}
        <Text
          variant="medium"
          style={{
            display: 'block',
            fontWeight: FontWeights.semibold,
            position: 'relative',
            top: showOverline ? '-.15rem' : '0',
            maxWidth: showArrow ? 'calc(100% - 2rem)' : undefined,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color: isDark ? NeutralColors.gray30 : NeutralColors.gray180,
          }}
          block
          nowrap
        >
          {name}
        </Text>
        {companyName || isLoading || showViewAllMeetingflows ? (
          <div>
            {companyName || isLoading ? (
              <span
                style={{
                  fontSize: FontSizes.mini,
                  color: NeutralColors.gray100,
                  lineHeight: '.75rem',
                  position: 'relative',
                  top: ownerName ? '-.1rem' : undefined,
                }}
              >
                <Link
                  as="a"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickCompanyName?.(e);
                  }}
                >
                  {companyName ? companyName : 'Loading...'}
                </Link>
              </span>
            ) : null}
            {(companyName || isLoading) && showViewAllMeetingflows ? (
              <span> • </span>
            ) : null}
            {showViewAllMeetingflows ? (
              <span
                style={{
                  fontSize: FontSizes.mini,
                  color: NeutralColors.gray100,
                  lineHeight: '.75rem',
                  position: 'relative',
                  top: ownerName ? '-.1rem' : undefined,
                }}
              >
                <ModifierAwareLink
                  defaultToNewTab
                  href={`/organization/${organizationSlug}/library/plans?externalServiceObjectId=${id}`}
                >
                  View Meetingflows
                </ModifierAwareLink>
              </span>
            ) : null}
          </div>
        ) : null}
      </div>
      {showExternalLink ? (
            <Icon
              key="HubSpot"
              className={salesforceLogoClass}
              imageProps={{
                height: '16px',
                src: HubSpotIcon,
                alt: 'HubSpot',
                width: '16px',
                title: `View ${name} on HubSpot`,
                style: {
                  position: 'relative',
                  color: NeutralColors.gray60,
                  filter: `grayscale(100%)`,
                  opacity: isDark ? '1' : '.5',
                  transition: '.3s ease-in-out all',
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                window.open(
                  `https://app.hubspot.com/contacts/${hubspotInstance}/deals/${id}`,
                  '_blank',
                );
              }}
            />
          ) : null}

      {showArrow ? (
        <div className="arrow">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5858 6.34317L12 4.92896L19.0711 12L12 19.0711L10.5858 17.6569L16.2427 12L10.5858 6.34317Z"
              fill="currentColor"
            />
          </svg>
        </div>
      ) : null}
    </div>
  );
};
