import axios, { AxiosError } from 'axios';
import { isArray } from 'lodash';

export const isAxiosErrorResponse = (
  err?: unknown,
  code?: number | number[],
): err is AxiosError => {
  if (!err || !axios.isAxiosError(err)) {
    return false;
  }

  if (code === undefined) {
    return true;
  }

  if (isArray(code)) {
    return (
      (err.status !== undefined && code.includes(err.status)) ||
      (err.response?.status !== undefined && code.includes(err.response.status))
    );
  }

  return err.status === code || err.response?.status === code;
};

export const isForbiddenError = (err?: unknown): err is AxiosError =>
  isAxiosErrorResponse(err, [401, 403]);

export const isBadRequest = (err?: unknown): err is AxiosError =>
  isAxiosErrorResponse(err, 400);

export const isNotFoundError = (err?: unknown): err is AxiosError =>
  isAxiosErrorResponse(err, 404);

export const isInternalServerError = (err?: unknown): err is AxiosError =>
  isAxiosErrorResponse(err, 500);
