import React, { useCallback, useMemo, useRef } from 'react';
import Avatar, { AvatarProps } from '@mui/material/Avatar';
import DSMenu from './DSMenu';
import { ClickAwayListener, MenuItem, styled } from '@mui/material';
import { useUserProfile } from '../../../Hooks/useProfile';
import DSMenuItem from './DSMenuItem';
import { useOrganization } from '../../../Hooks/useOrganization';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { useNavigate } from 'react-router';
import { Truthy } from '@meetingflow/common/TypeHelpers';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import { Person, WebAsset, Key, Logout } from '@mui/icons-material';
import { DSUserAvatar } from './DSCurrentUserAvatarMenu.styles';

export const stringToColor = (string: string): string => {
  let hash = 0;

  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

// Creates MUI avatar props (background color, initials) based on a string name
export const avatarPropsFromString = (
  name: string,
): { sx: { bgcolor: string }; children: string } => ({
  sx: {
    bgcolor: stringToColor(name),
  },
  children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
});

interface DSCurrentUserAvatarMenuProps extends AvatarProps {
  branded?: boolean;
}

const DSCurrentUserAvatarMenu: React.FC<DSCurrentUserAvatarMenuProps> = ({
  branded,
  ...props
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const userRef = useRef(null);
  const { slug: organizationSlug } = useOrganization();
  const { dealRoomId, dealRoom } = useDealRoom();
  const navigate = useNavigate();
  const {
    user: profileData,
    updateUserProfileAsync,
    refetch: refetchProfile,
  } = useUserProfile();

  const [showUserMenu, setShowUserMenu] = React.useState(false);

  const onShowUserMenu = useCallback(
    (ev: React.MouseEvent<HTMLElement>) => {
      ev.preventDefault(); // don't navigate
      setShowUserMenu(true);
    },
    [setShowUserMenu],
  );

  const onHideUserMenu = useCallback(
    () => setShowUserMenu(false),
    [setShowUserMenu],
  );

  const userMenuItems = useMemo(() => {
    const items = [
      <DSMenuItem
        key={'profile'}
        primaryText="Your Profile"
        onClick={() => {
          navigate(
            `/organization/${organizationSlug}/dealroom/${dealRoomId}/settings?group=profile`,
          );
        }}
        icon={<Person />}
        branded={branded}
      />,
      <DSMenuItem
        key={'sites'}
        primaryText="Your Decision Sites"
        onClick={() => {
          navigate(`/organization/${organizationSlug}`);
        }}
        icon={<WebAsset />}
        branded={branded}
      />,
      <MenuItem divider key="divider-1" />,
      import.meta.env.DEV ? (
        <DSMenuItem
          key={'copy-token'}
          primaryText="Copy bearer token"
          onClick={() => {
            getAccessTokenSilently().then((token) => {
              navigator?.clipboard
                ?.writeText(token)
                .then(() => toast(`Bearer token copied to clipboard`));
            });
          }}
          icon={<Key />}
          branded={branded}
        />
      ) : undefined,
      <MenuItem divider key="divider-2" />,
      <DSMenuItem
        key={'sign-out'}
        primaryText="Sign out"
        onClick={() => {
          navigate('/logout');
        }}
        icon={<Logout />}
        branded={branded}
      />,
    ].filter(Truthy);

    return items;
  }, [navigate, organizationSlug, dealRoomId, branded, getAccessTokenSilently]);

  const userName = profileData?.name || 'User';

  const avatarUrl = profileData?.avatarFileUrl || undefined;

  return (
    <ClickAwayListener onClickAway={onHideUserMenu}>
      <>
        <DSUserAvatar
          {...avatarPropsFromString(userName)}
          src={avatarUrl}
          ref={userRef}
          onClick={onShowUserMenu}
          {...props}
        />
        <DSMenu
          anchorEl={userRef.current}
          open={showUserMenu}
          onClose={onHideUserMenu}
        >
          {userMenuItems}
        </DSMenu>
      </>
    </ClickAwayListener>
  );
};

export default DSCurrentUserAvatarMenu;
