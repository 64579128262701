import React from 'react';
import { BasicContact, DealRole } from '@meetingflow/common/Api/data-contracts';

export type BuyerOrientationStepProps = {
  orientationForm: BuyerSelectedOptions;
  updateOrientationForm: React.Dispatch<SurveyAction>;
};

export type UserDetails = (
  BasicContact & {
    role?: DealRole | null;
    assistancePreference: string | null;
    consumptionPreference: string | null;
    communicationPreference: string | null;
    phoneNumber: string | null;
    priorities: string[] | null;
    otherPriorityText: string | null;
    hasWatchedWelcomeVideo?: boolean;
    hasCompletedBuyerOrientation?: boolean;
  }
) | null;

export type BuyerOrientationDialogProps = {
  handleDismiss: () => void;
  ownerAvatarUrl?: string | null;
  ownerName: string;
  userName: string;
  dealRoomContacts: string[];
  currentUserDetails: UserDetails;
  organizationSlug: string;
  userEmail: string;
  dealRoomId: number;
  userRole: DealRole;
  refetchDealRoomData?: () => Promise<void>;
};

export type SurveyUserOption = {
  optionId: number;
  title: string;
  subtitle?: string;
  value: string;
  icon?: React.ReactNode | null;
};

export type Step = {
  title: string;
  subtitle: string;
  options: SurveyUserOption[];
};

export type StepName =
  | 'personalDetails'
  | 'assistancePreference'
  | 'consumptionPreference'
  | 'communicationPreference'
  | 'priorities'
  | 'invitations';

export type StepNames = { [key in StepName]: StepName };

export type SurveyOptions = {
  [key in StepName]: Step;
};

export type StepNamesBasedOnIndex = {
  [key: number]: StepName;
};

export type StepIndexesBasedOnName = {
  [key: string]: number;
};

export type BuyerSelectedOptions = {
  name: string | null;
  title: string | null;
  department: string | null;
  assistancePreference: string | null;
  consumptionPreference: string | null;
  communicationPreference: string | null;
  phoneNumber: string | null;
  priorities: string[];
  otherPriorityText: string | null;
  invitations?: string[]; //will be modified
};

export enum ActionType {
  UPDATE_NAME = 'UPDATE_NAME',
  UPDATE_TITLE = 'UPDATE_TITLE',
  UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT',
  UPDATE_ASSISTANCE_PREFERENCE = 'UPDATE_ASSISTANCE_PREFERENCE',
  UPDATE_CONSUMPTION_PREFERENCE = 'UPDATE_CONSUMPTION_PREFERENCE',
  UPDATE_COMMUNICATION_PREFERENCE = 'UPDATE_COMMUNICATION_PREFERENCE',
  UPDATE_PHONE_NUMBER = 'UPDATE_PHONE_NUMBER',
  UPDATE_PRIORITIES = 'UPDATE_PRIORITIES',
  UPDATE_OTHER_PRIORITY = 'UPDATE_OTHER_PRIORITY',
  UPDATE_INVITATIONS = 'UPDATE_INVITATIONS',
  UPDATE_MULTIPLE_SELECTIONS = 'UPDATE_MULTIPLE_SELECTIONS',
}

export type SurveyAction = {
  type: ActionType;
  payload: string | BuyerSelectedOptions;
};

export type StepComponent = ({
  orientationForm,
  updateOrientationForm,
}: BuyerOrientationStepProps) => React.ReactNode;

export type SurveyStepComponents = {
  [key in StepName]: StepComponent;
};
