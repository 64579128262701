import { useEffect, useState } from 'react';
import {
  ApiClient,
  ExternalServicesApiClient,
} from '../Services/NetworkCommon';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { isPopup } from '../Helpers/WindowHelpers';
import { isAxiosErrorResponse } from '../Helpers/AxiosHelpers';
import { hasOwnProperty } from '@meetingflow/common/ObjectHelpers';
import { Card } from './Card';

export const AuthCallback = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [complete, setComplete] = useState(false);
  const [error, setError] = useState('');
  const [helpUrl, setHelpUrl] = useState('');

  const { getAccessTokenSilently } = useAuth0();

  const exchangeAndSaveSalesforceCode = async (
    organizationSlug: string,
    code: string,
    hmac: string,
  ) => {
    const token = await getAccessTokenSilently();
    try {
      const result = await toast.promise(
        ApiClient.post(
          `/organization/${organizationSlug}/external/salesforce/oauth/exchange`,
          {
            code,
            hmac,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        ),
        {
          loading: 'Adding Salesforce integration',
          success: 'Successfully added Salesforce integration',
          error: (err) => {
            if (isAxiosErrorResponse(err) && err?.response?.data) {
              const body = err.response.data;
              if (
                isAxiosErrorResponse(err, 403) &&
                hasOwnProperty(body, 'message') &&
                body.message === 'API_CURRENTLY_DISABLED'
              ) {
                setError(
                  `The Salesforce Web Services API is currently disabled for your user.  Please ask your Salesforce admin to enable the feature.`,
                );
                setHelpUrl(
                  `https://meetingflow.com/help-center/app-integrations/salesforce-and-meetingflow/`,
                );
                return 'The Salesforce Web Services API is currently disabled for your user';
              } else if (
                isAxiosErrorResponse(err, 403) &&
                hasOwnProperty(body, 'message') &&
                body.message === 'API_DISABLED_FOR_ORG'
              ) {
                setError(
                  `The Salesforce Web Services API is currently disabled on your Salesforce instance.  You may need to upgrade your Salesforce edition.  Please ask your Salesforce admin to enable the feature.`,
                );
                setHelpUrl(
                  `https://meetingflow.com/help-center/app-integrations/salesforce-and-meetingflow/`,
                );
                return 'The Salesforce Web Services API is currently disabled on your Salesforce instance';
              }
            }
            return `Something went wrong adding the Salesforce integration`;
          },
        },
      );

      return result.status === 200;
    } catch (err) {
      console.error(err);

      setError(
        `Something went wrong adding the Salesforce integration, please ${
          isPopup() ? 'close this window and' : ''
        } try again.`,
      );

      return false;
    }
  };

  const exchangeAndSaveHubSpotCode = async (
    organizationSlug: string,
    code: string,
    hmac: string,
  ) => {
    const token = await getAccessTokenSilently();

    try {
      const result = await toast.promise(
        ApiClient.post(
          `/organization/${organizationSlug}/external/hubspot/oauth/exchange`,
          {
            code,
            hmac,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        ),
        {
          loading: 'Adding the HubSpot integration',
          success: 'Successfully added the HubSpot integration',
          error: 'Something went wrong adding the HubSpot integration',
        },
      );

      return result.status === 200;
    } catch (err) {
      console.error(err);

      setError(
        `Something went wrong adding the HubSpot integration, please ${
          isPopup() ? 'close this window and' : ''
        } try again.`,
      );

      return false;
    }
  };

  const exchangeAndSaveSlackCode = async (
    organizationSlug: string,
    code: string,
    hmac: string,
  ) => {
    const token = await getAccessTokenSilently();

    try {
      const result = await toast.promise(
        ApiClient.post(
          `/organization/${organizationSlug}/external/slack/oauth/exchange`,
          {
            code,
            hmac,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        ),
        {
          loading: 'Adding the Slack integration',
          success: 'Successfully added the Slack integration',
          error: 'Something went wrong adding the Slack integration',
        },
      );

      return result.status === 200;
    } catch (err) {
      console.error(err);

      setError(
        `Something went wrong adding the Slack integration, please ${
          isPopup() ? 'close this window and' : ''
        } try again`,
      );

      return false;
    }
  };

  const exchangeAndSaveTeamsCode = async (
    organizationSlug: string,
    code: string,
    hmac: string,
  ) => {
    const token = await getAccessTokenSilently();

    try {
      const result = await toast.promise(
        ExternalServicesApiClient.completeTeamsOauthExchange(
          organizationSlug,
          { code, hmac },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        ),
        {
          loading: 'Adding the Microsoft Teams integration',
          success: 'Successfully added the Microsoft Teams integration',
          error: 'Something went wrong adding the Microsoft Teams integration',
        },
      );

      return result.status === 200;
    } catch (err) {
      console.error(err);

      setError(
        `Something went wrong adding the Microsoft Teams integration, please ${
          isPopup() ? 'close this window and' : ''
        } try again`,
      );

      return false;
    }
  };

  useEffect(() => {
    setError('');
    setHelpUrl('');

    if (!(searchParams.has('state') && searchParams.has('code'))) {
      toast.error(`Invalid oauth response.`);
      setError('Invalid oauth response, please try again.');
      return;
    }

    const oauthState = searchParams.get('state')!;
    const oauthCode = searchParams.get('code')!;

    if (!(!!searchParams.get('state') && !!searchParams.get('code'))) {
      toast.error(`Invalid oauth response.`);
      setError('Invalid oauth response, please try again.');
      return;
    }

    let state: Record<string, string> = {};
    try {
      state = JSON.parse(oauthState);
    } catch (err) {
      console.error(err);
      try {
        state = JSON.parse(decodeURIComponent(oauthState));
      } catch (err) {
        console.error(err);
        setError(
          `An invalid oauth reponse was received.  please ${
            isPopup() ? 'close this window and' : ''
          } try again.`,
        );
        return;
      }
    }

    const { organizationSlug, app, hmac } = state;

    const redirect: string | undefined = state.redirect;

    if (!organizationSlug || !app) {
      setError('Invalid oauth callback response');
      return;
    }

    switch (app) {
      case 'SALESFORCE': {
        exchangeAndSaveSalesforceCode(organizationSlug, oauthCode, hmac)
          .then((success) => {
            if (success) {
              if (isPopup()) {
                setComplete(true);
                window.close();
              } else if (redirect) {
                setComplete(true);
                navigate(redirect);
              } else {
                setComplete(true);
              }
            }
          })
          .catch((err) => {
            console.error(err);
            if (!error) {
              setError(
                `Something went wrong adding the Salesforce integration, please ${
                  isPopup() ? 'close this window and' : ''
                } try again.`,
              );
            }
          });
        break;
      }
      case 'HUBSPOT': {
        exchangeAndSaveHubSpotCode(organizationSlug, oauthCode, hmac)
          .then((success) => {
            if (success) {
              if (isPopup()) {
                setComplete(true);
                window.close();
              } else if (redirect) {
                setComplete(true);
                navigate(redirect);
              } else {
                setComplete(true);
              }
            }
          })
          .catch((err) => {
            console.error(err);
            if (!error) {
              setError(
                `Something went wrong adding the HubSpot integration, please ${
                  isPopup() ? 'close this window and' : ''
                } try again.`,
              );
            }
          });
        break;
      }
      case 'SLACK': {
        exchangeAndSaveSlackCode(organizationSlug, oauthCode, hmac)
          .then((success) => {
            if (success) {
              if (isPopup()) {
                setComplete(true);
                window.close();
              } else if (redirect) {
                setComplete(true);
                navigate(redirect);
              } else {
                setComplete(true);
              }
            }
          })
          .catch((err) => {
            console.error(err);
            if (!error) {
              setError(
                `Something went wrong adding the Slack integration, please ${
                  isPopup() ? 'close this window and' : ''
                } try again.`,
              );
            }
          });
        break;
      }
      case 'TEAMS': {
        exchangeAndSaveTeamsCode(organizationSlug, oauthCode, hmac)
          .then((success) => {
            if (success) {
              if (isPopup()) {
                setComplete(true);
                window.close();
              } else if (redirect) {
                setComplete(true);
                navigate(redirect);
              } else {
                setComplete(true);
              }
            }
          })
          .catch((err) => {
            console.error(err);
            if (!error) {
              setError(
                `Something went wrong adding the Microsoft Teams integration, please ${
                  isPopup() ? 'close this window and' : ''
                } try again.`,
              );
            }
          });
        break;
      }

      default: {
        setError(`Unknown oauth callback recieved for app ${app}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  if (error) {
    return (
      <div style={{ margin: '0.25rem' }}>
        <Card
          title="Something went wrong"
          contentContainerPadding="1rem"
          callToAction
        >
          <div>{error}</div>
          <br />
          {helpUrl ? (
            <div>
              Get help with this error at <a href={helpUrl}>{helpUrl}</a>
            </div>
          ) : null}
        </Card>
      </div>
    );
  }

  return complete ? (
    <div style={{ margin: '0.25rem' }}>
      <Card title="All done" contentContainerPadding="1rem">
        <div>You may now close this window</div>
      </Card>
    </div>
  ) : (
    <div style={{ margin: '0.25rem' }}>
      <Card title="Finishing up" contentContainerPadding="1rem">
        <div>We're just finishing up...</div>
      </Card>
    </div>
  );
};

export default AuthCallback;
