import { Image, Stack, Text, PrimaryButton, mergeStyleSets, ColorPicker, IColor } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../../Themes/Themes';
import { useCallback, useMemo, useState } from 'react';
import { DealRoomsApiClient } from '../../../../../Services/NetworkCommon';
import { useOrganizationSlug } from '../../../../../Hooks/useOrganizationSlug';
import { useDealRoomId } from '../../../../../Hooks/useDealRoomId';
import { useDealRoom } from '../../../../../Hooks/useDealRoom';
import { DealRoomAsyncCommandBarButton, DealRoomAsyncPrimaryButton } from '../../../Components/DealRoomButton';
import { DSConfigurationHeaderRow } from '../../Components/DSConfigurationHeaderRow';
import { useAuth0 } from '@auth0/auth0-react';
import { pickFile } from '../../../../../Helpers/FileHelpers';
import toast from 'react-hot-toast';

// Define styles for various components using mergeStyleSets
const styles = mergeStyleSets({
    header: {
        padding: '20px',
        borderBottom: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
    },
    content: {
        padding: '20px',
        flex: 1,
    },
    footer: {
        padding: '20px',
        borderTop: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
        display: 'flex',
        justifyContent: 'flex-end',
    },
    description: {
        color: DEALROOMS_COLORS.neutralSecondary,
        marginBottom: '20px',
    },
    colorPicker: {
        '.ms-ColorPicker-colorRect': {
            minWidth: '36px',
            minHeight: '36px',
        },
    },
});

export const DSConfigurationCustomization = () => {
    // Hooks for getting organization and deal room information
    const organizationSlug = useOrganizationSlug();
    const dealRoomId = useDealRoomId();
    const { dealRoom, refetch: refetchDealRoom } = useDealRoom();
    const { getAccessTokenSilently } = useAuth0();

    // State for selected color and logo modification status
    const [selectedColor, setSelectedColor] = useState<string | undefined>();
    const [isLogoModified, setIsLogoModified] = useState(false);

    // Memoized initial primary color
    const initialPrimaryColor = useMemo(() => {
        if (dealRoom?.primaryColor) {
            return dealRoom.primaryColor;
        }
        return DEALROOMS_COLORS.themePrimary;
    }, [dealRoom]);

    // Callback function to save changes
    const onSave = useCallback(async () => {
        const token = await getAccessTokenSilently();

        if (!organizationSlug || !dealRoomId) {
            return;
        }

        if (!selectedColor) {
            await refetchDealRoom();
            return;
        }

        try {
            // Update deal room with new primary color
            await DealRoomsApiClient.updateDealRoom(
                organizationSlug,
                dealRoomId,
                {
                    primaryColor: "#" + selectedColor,
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                },
            );
            await refetchDealRoom();
        } catch (error) {
            console.error('Failed to update deal room color:', error);
        }
    },
        [getAccessTokenSilently, selectedColor, organizationSlug, dealRoomId, refetchDealRoom]
    );

    return (
        <Stack tokens={{ childrenGap: 20 }} styles={{ root: { width: '100%' } }}>
            {/* Header section with save button */}
            <DSConfigurationHeaderRow
                title="Customization"
                description="You can customize the look and feel of your Decision Site here."
            >
                {<></>}
            </DSConfigurationHeaderRow>

            <Stack horizontal>
                {/* Theme color selection section */}
                <Stack className={styles.content}>
                    <Text variant="large">Theme Color</Text>
                    <Text className={styles.description}>
                        Choose a color to customize your deal room's appearance.
                    </Text>

                    <Stack horizontalAlign='center' tokens={{ childrenGap: 20 }}>
                        <ColorPicker
                            color={selectedColor || initialPrimaryColor}
                            showPreview={true}
                            alphaType='none'
                            onChange={(ev, color: IColor) => setSelectedColor(color.hex)}
                        />
                        <PrimaryButton
                            onClick={() => onSave()}
                            text="Save Color"
                            disabled={(!selectedColor || selectedColor === initialPrimaryColor)}
                        />
                    </Stack>
                </Stack>

                {/* Buyer logo upload section */}
                <Stack className={styles.content}>
                    <Text variant="large">Upload Buyer Logo</Text>
                    <Text className={styles.description}>
                        Choose an image to upload as your deal room buyer's logo.
                    </Text>

                    <Stack horizontalAlign='center' tokens={{ childrenGap: 20 }}>
                        {/* Display current buyer logo if available */}
                        {dealRoom?.buyerLogoUrl && (
                            <>
                                <Image
                                    src={dealRoom.buyerLogoUrl}
                                    width={144}
                                    height={144}
                                    style={{ borderRadius: '10%' }}
                                />
                            </>
                        )}

                        <Stack tokens={{ childrenGap: 20 }} horizontal horizontalAlign='center' style={{ width: '100%' }} >
                            {/* Button to upload new logo */}
                            <DealRoomAsyncPrimaryButton
                                text="Upload Logo"
                                style={{
                                    border: '1px solid',
                                    display: 'inline'
                                  }}
                                onClick={async (e) => {
                                    // Pick an image file
                                    const file = await pickFile({ fileTypes: 'image' });
                                    if (!file) {
                                        return;
                                    }

                                    const token = await getAccessTokenSilently();
                                    // Upload the logo with a toast notification
                                    await toast.promise(
                                        DealRoomsApiClient.uploadBuyersLogo(
                                            organizationSlug!,
                                            dealRoomId!,
                                            { file },
                                            {
                                                headers: {
                                                    Authorization: `Bearer ${token}`,
                                                },
                                            },
                                        ),
                                        {
                                            loading: 'Uploading...',
                                            success: (r) => {
                                                refetchDealRoom();
                                                setIsLogoModified(true);
                                                return 'Buyer Logo uploaded';
                                            },
                                            error: 'Failed to update buyer logo',
                                        },
                                    );
                                }}
                            />

                            {/* Button to delete existing logo */}
                            <DealRoomAsyncCommandBarButton
                                text="Delete Logo"
                                style={{
                                    border: '1px solid',
                                    display: 'inline'
                                  }}
                                onClick={async () => {
                                    const token = await getAccessTokenSilently();
                                    // Delete the logo with a toast notification
                                    await toast.promise(
                                        DealRoomsApiClient.deleteBuyersLogo(
                                            organizationSlug!,
                                            dealRoomId!,
                                            {
                                                headers: {
                                                    Authorization: `Bearer ${token}`,
                                                },
                                            },
                                        ),
                                        {
                                            loading: 'Deleting...',
                                            success: (r) => {
                                                refetchDealRoom();
                                                setIsLogoModified(true);
                                                return 'Buyer Logo deleted';
                                            },
                                            error: 'Failed to delete buyer logo',
                                        },
                                    );
                                }}
                                iconProps={{ iconName: 'Delete' }}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default DSConfigurationCustomization;