import React, { useMemo } from 'react';
import {
  FontWeights,
  Icon,
  mergeStyles,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { humanizeDateTime } from '@meetingflow/common/DateHelpers';
import { formatDistanceToNow } from 'date-fns';
import { ArtifactMetadataProps } from './types';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

/**
 * ArtifactMetadata Component
 *
 * Displays metadata information about the artifact including:
 * - Creation date and creator
 * - Last update information
 * - Time since last edit
 */
export const ArtifactMetadata: React.FC<ArtifactMetadataProps> = ({
  artifact,
}) => {
  const metadata = mergeStyles({
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '.info-icon': {
      color: DEALROOMS_COLORS.themeSecondary,
      fontSize: '16px',
    },
    '.edited-at': {
      color: DEALROOMS_COLORS.themeSecondary,
      fontSize: '13px',
      paddingBottom: '0.25rem',
    },
  });

  // Format dates
  const { createdAt, updatedAt, editedAt } = useMemo(() => {
    if (!artifact) {
      return {
        createdAt: undefined,
        updatedAt: undefined,
        editedAt: undefined,
      };
    }

    return {
      createdAt: humanizeDateTime(artifact.createdAt, {
        useRelativeDates: false,
      }),
      updatedAt: humanizeDateTime(artifact.updatedAt, {
        useRelativeDates: false,
      }),
      editedAt: formatDistanceToNow(new Date(artifact.updatedAt), {
        addSuffix: true,
      }),
    };
  }, [artifact]);

  return (
    <div className={metadata}>
      <TooltipHost
        content={
          <>
            <div>
              Uploaded by{' '}
              <span className="creator">
                {artifact.creator?.name ?? 'an unknown user'}
              </span>{' '}
              on <span>{createdAt}</span>
            </div>
            {artifact.createdAt !== artifact.updatedAt && (
              <div>
                Last updated by{' '}
                <span className="creator">
                  {artifact.creator?.name ?? 'an unknown user'}
                </span>{' '}
                on <span>{updatedAt}</span>
              </div>
            )}
          </>
        }
        directionalHint={4}
        calloutProps={{
          backgroundColor: '#6F7288',
          gapSpace: -2,
          styles: {
            root: { padding: 0, margin: 0 },
            beak: { display: 'none' },
            calloutMain: {
              padding: '.5rem',
              borderRadius: '.25rem',
              selectors: {
                '*': { color: 'white' },
                span: { color: '#D7D7D8' },
                'span.creator': { fontWeight: FontWeights.bold },
              },
            },
          },
        }}
        styles={{ root: { cursor: 'default' } }}
      >
        <Icon iconName="InfoSolid" className="info-icon" />
      </TooltipHost>
      <Text variant="xSmall" className="edited-at">
        {artifact.createdAt === artifact.updatedAt ? 'Created' : 'Edited'}{' '}
        {editedAt}
      </Text>
    </div>
  );
};
