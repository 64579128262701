import {
  ChoiceGroup,
  Dropdown,
  IconButton,
  mergeStyleSets,
  Modal,
  Stack,
  Text,
  TextField,
} from '@fluentui/react';
import {
  CheckMarkIconProps,
  ChromeCloseIconProps,
} from '../../../utils/iconProps';
import {
  DealRoomAsyncPrimaryButton,
  DealRoomCommandBarButton,
} from '../Components/DealRoomButton';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DealRoomMilestoneType } from '@meetingflow/common/Api/data-contracts';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { FileFormat } from '../../../types/MilestoneTypes';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { useMutualPlan } from '../../../Hooks/useMutualPlan';
import { useMilestonesSummary } from '../../../Hooks/useMilestonesSummary';

type RequestAttachmentsDialogProps = {
  organizationSlug: string;
  dealRoomId: number;
  onResolve: (value: unknown) => void;
  onDismiss: () => void;
  milestoneType: DealRoomMilestoneType;
};

const formatOptions: {
  text: string;
  key: FileFormat;
}[] = [
  {
    text: 'PDF',
    key: 'PDF',
  },
  {
    text: 'Excel',
    key: 'Excel',
  },
  {
    text: 'Doc',
    key: 'Doc',
  },
  {
    text: 'PPT',
    key: 'PPT',
  },
];

export const RequestAttachmentsDialog = ({
  organizationSlug,
  dealRoomId,
  onResolve,
  onDismiss,
  milestoneType,
}: RequestAttachmentsDialogProps) => {
  const [description, setDescription] = useState('');
  const [ownerId, setOwnerId] = useState<number | null>(null);
  const [selectedMilestoneType, setSelectedMilestoneType] = useState<DealRoomMilestoneType>(milestoneType || '');
  const [fileFormat, setFileFormat] = useState<FileFormat | null>(null);

  useEffect(() => {
    setSelectedMilestoneType(milestoneType);
  }, [milestoneType]);

  const { getAccessTokenSilently } = useAuth0();

  const { dealRoom } = useDealRoom(organizationSlug, dealRoomId);
  const { mutualPlan, refetch: refetchMutualPlan } = useMutualPlan(
    organizationSlug,
    dealRoomId,
  );
  const { milestonesSummary, refetch: refetchMilestonsSummary } =
    useMilestonesSummary(organizationSlug, dealRoomId);

  const dealRoomContactsOptions = useMemo(() => {
    if (!Array.isArray(dealRoom?.contacts)) return [];

    return dealRoom.contacts.map((contact) => ({
      key: contact.id,
      text: contact.name || contact?.email,
    }));
  }, [dealRoom?.contacts]);

  const isRequestButtonDisabled = useMemo(() => {
    return !description || ownerId === null || fileFormat === null;
  }, [description, fileFormat, ownerId]);

  const handleRequestAttachment = useCallback(async () => {
    try {
      if (ownerId === null || fileFormat === null) return;

      const token = await getAccessTokenSilently();
      await toast.promise(
        DealRoomsApiClient.createMilestoneAttachmentRequest(
          organizationSlug,
          dealRoomId,
          selectedMilestoneType,
          {
            assigneeId: ownerId,
            details: description,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        ),
        {
          loading: 'Requesting attachment',
          success: () => {
            onResolve(null);
            Promise.all([refetchMilestonsSummary(), refetchMutualPlan()]);
            return 'Successfully requested the attachment';
          },
          error: 'Something went wrong requesting the attachment',
        },
      );
    } catch (err) {}
  }, [
    dealRoomId,
    description,
    fileFormat,
    getAccessTokenSilently,
    onResolve,
    organizationSlug,
    ownerId,
    refetchMilestonsSummary,
    refetchMutualPlan,
    selectedMilestoneType,
  ]);

  const styles = mergeStyleSets({
    modalWrapper: {
      '& > .ms-Overlay': {
        backdropFilter: 'blur(3px)',
      },
      '.ms-Dialog-main': {
        minHeight: 'fit-content',
        backgroundColor: 'transparent',
      },
    },
    contentContainer: {
      width: 'calc(100vw - 3rem)',
      padding: '1rem 1.5rem 1.5rem',
      maxWidth: '538px',
      backgroundColor: DEALROOMS_COLORS.white,
      borderRadius: '.5rem',
      boxShadow: '0px 8px 16px 0px #00000024',
      gap: '1.5rem',
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    title: {
      color: DEALROOMS_COLORS.themePrimary,
      fontSize: '1.5rem',
      lineHeight: '2.5rem',
      fontWeight: '400',
    },
    closeButton: {
      i: {
        fontSize: '.75rem',
        color: DEALROOMS_COLORS.themePrimary,
      },
    },
    content: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },
    footerContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '1rem',
      flexWrap: 'wrap',
      '> .cancel-button': {
        padding: '0.5rem 1rem',
        justifyContent: 'center',
        '.ms-Button-label': {
          fontSize: '0.9375rem',
          lineHeight: '1.5rem',
          fontWeight: '400',
          color: DEALROOMS_COLORS.darkerGray,
        },
      },
      '> .save-button': {
        padding: '0.5rem 1rem',
        '.ms-Button-label': {
          fontSize: '0.9375rem',
          lineHeight: '1.5rem',
          fontWeight: '400',
          color: DEALROOMS_COLORS.white,
        },
      },
    },
    contentTitle: {
      fontWeight: '500',
      fontSize: '.9375rem',
      lineHeight: '1.5rem',
      textAlign: 'center',
      color: DEALROOMS_COLORS.neutralDark,
      maxWidth: '340px',
      margin: '0 auto',
    },
    contentDescription: {
      '.ms-TextField-fieldGroup': {
        borderRadius: '4px',
        border: 'none !important',
        height: 'auto',

        '::after': {
          borderRadius: '.25rem',
          border: `1px solid ${DEALROOMS_COLORS.neutralSecondary}`,
        },
      },
      '.ms-Label': {
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        fontWeight: '400',
        color: DEALROOMS_COLORS.darkerGray,
      },
      textarea: {
        backgroundColor: DEALROOMS_COLORS.inputLightGray,
        color: DEALROOMS_COLORS.darkerGray,
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '22px',
        borderRadius: '.25rem',
        padding: '9px 14px',
        height: '96px',
        maxHeight: '96px',
        overflowY: 'auto',

        '::placeholder': {
          color: DEALROOMS_COLORS.themeSecondary,
        },
      },
    },
    dropdown: {
      '.ms-Label': {
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        fontWeight: '400',
        color: DEALROOMS_COLORS.darkerGray,
      },
    },
    customLabel: {
      display: 'flex',
      alignItems: 'flex-start',
      gap: '5px',
      marginBottom: '4px',

      '.label-text': {
        color: DEALROOMS_COLORS.darkerGray,
      },

      '.label-icon': {
        color: DEALROOMS_COLORS.darkerRed,
        fontSize: '7px',
      },
    },
    formatsList: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '1.5rem',
    },
    formatsGroup: {
      '.ms-Label': {
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        fontWeight: '400',
        color: DEALROOMS_COLORS.darkerGray,
      },
      '.ms-ChoiceFieldGroup-flexContainer': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '2rem',
      },
      '.ms-ChoiceField-field': {
        display: 'flex',
        alignItems: 'center',
      },
    },
  });

  return (
    <Modal className={styles.modalWrapper} isOpen onDismiss={onDismiss}>
      <Stack className={styles.contentContainer}>
        <Stack.Item className={styles.headerContainer}>
          <Text className={styles.title}>Request Artifact</Text>
          <IconButton
            className={styles.closeButton}
            ariaLabel="Close"
            iconProps={ChromeCloseIconProps}
            onClick={onDismiss}
            alt="Close image"
          />
        </Stack.Item>
        <Stack.Item className={styles.content}>
          <Text className={styles.contentTitle}>
            Requesting an Artifact creates a task assigned to the person you’re
            requesting from
          </Text>
          <Dropdown
            options={dealRoomContactsOptions}
            label="Request Artifact From"
            required={true}
            placeholder="Select owner"
            className={styles.dropdown}
            onChange={(ev, currentOption) => {
              currentOption?.key && setOwnerId(+currentOption.key);
            }}
            selectedKey={ownerId}
          />
          <TextField
            value={description}
            className={styles.contentDescription}
            label={'Details'}
            multiline
            resizable={false}
            required={true}
            onChange={(e, newValue) => setDescription(newValue || '')}
          />
          <div>
            <ChoiceGroup
              className={styles.formatsGroup}
              label="Document format"
              required={true}
              options={formatOptions}
              selectedKey={fileFormat}
              onChange={(ev, currentOption) => {
                currentOption?.key &&
                  setFileFormat(currentOption.key as FileFormat);
              }}
            />
          </div>
          {/* <Dropdown
            options={milestonesSummary}
            label="Milestone"
            placeholder="Select milestone"
            className={styles.dropdown}
            onChange={(ev, currentOption) => {
              currentOption?.key &&
                typeof currentOption.key === 'string' &&
                setSelectedMilestoneType(
                  currentOption?.key as DealRoomMilestoneType,
                );
            }}
            selectedKey={selectedMilestoneType}
          /> */}
        </Stack.Item>
        <Stack.Item className={styles.footerContainer}>
          <DealRoomCommandBarButton
            customClasses="cancel-button"
            buttonStyleType="COMMAND_BAR"
            text="Cancel"
            onClick={onDismiss}
          />
          <DealRoomAsyncPrimaryButton
            customClasses="save-button"
            disabled={isRequestButtonDisabled}
            iconProps={CheckMarkIconProps}
            onClick={handleRequestAttachment}
            text="Request Artifact"
          />
        </Stack.Item>
      </Stack>
    </Modal>
  );
};
