import { useAuth0 } from '@auth0/auth0-react';
import { useOrganizationSlug } from './useOrganizationSlug';
import { useDealRoomId } from './useDealRoomId';
import { useQuery, useQueryClient } from 'react-query';
import { OrganizationMilestonesSummaryQuery } from '../QueryNames';
import { DealRoomsApiClient } from '../Services/NetworkCommon';
import { isForbiddenError } from '../Helpers/AxiosHelpers';
import { useCallback } from 'react';

export const useMilestonesSummary = (orgSlug?: string, dealRoomId?: number) => {
  const { getAccessTokenSilently } = useAuth0();
  const client = useQueryClient();

  const slg = useOrganizationSlug();
  const drId = useDealRoomId();
  const orgSlugFinal = orgSlug || slg;
  const dealRoomIdFinal = dealRoomId || drId;

  const {
    data: milestonesSummaryData,
    isLoading: milestonesSummaryLoading,
    isFetched: milestonesSummaryIsFetched,
    isError: milestonesSummaryIsError,
    error: milestonesSummaryError,
    refetch: refetchMilestonesSummary,
  } = useQuery(
    OrganizationMilestonesSummaryQuery(orgSlugFinal, dealRoomIdFinal),
    async () => {
      const token = await getAccessTokenSilently();
      return DealRoomsApiClient.getMilestonesSummary(
        orgSlugFinal!,
        dealRoomIdFinal!,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!orgSlugFinal && !!dealRoomIdFinal && !isNaN(dealRoomIdFinal),
      retry: (failureCount, error) => {
        if (isForbiddenError(error)) {
          return false;
        }
        return failureCount < 3;
      },
    },
  );

  const refetchAll = useCallback(async () => {
    await client.invalidateQueries(
      OrganizationMilestonesSummaryQuery(orgSlugFinal, dealRoomIdFinal),
    );
  }, [client, dealRoomIdFinal, orgSlugFinal]);

  return {
    milestonesSummary: milestonesSummaryData?.data,
    isLoading: milestonesSummaryLoading,
    isFetched: milestonesSummaryIsFetched,
    isError: milestonesSummaryIsError,
    error: milestonesSummaryError,
    refetch: refetchMilestonesSummary,
    refetchAll,
  };
};
