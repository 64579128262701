import { PrimaryButton } from '@fluentui/react';
import { DealRoomComment } from '@meetingflow/common/Api/data-contracts';
import React, { useMemo } from 'react';
import ConversationReplies from './ConversationReplies';
import { NotificationsBullet } from './NotificationsBullet';
import { ConversationMoreOptions } from './ConversationMoreOptions';
import { TimeAgoStatus } from './TimeAgoStatus';
import { useConversationContainerStyle } from './useConversationContainerStyle';
import { ImageWithFallback } from '../../../Common/ImageWithFallback';
import { OwnerPicturePlaceholderIllustration } from './ConvReplyIllustrations';
import { useUserProfile } from '../../../../Hooks/useProfile';
import { ConversationAttachments } from './ConversationAttachments';

type ConversationsElementProps = {
  children?: React.ReactNode;
  isComment?: boolean;
  conversation: DealRoomComment;
  convClick?: Function;
  convDelete?: Function;
  timeAgo: string;
  timeAgoNotification?: string;
  notifications?: number | null;
  handleSetCommentForEdit?: Function;
  editConvId?: number | null;
  isFirstComment?: boolean;
};

/**
 * ConversationsElement Component
 * Renders a conversation or comment element with user information, content, and interaction options
 * Supports both clickable and non-clickable modes (based on convClick prop)
 * Handles edit mode, notifications, and reply count display
 */
const ConversationsElement = ({
  children,
  isComment = false,
  conversation,
  convClick,
  convDelete,
  timeAgo,
  timeAgoNotification,
  notifications,
  handleSetCommentForEdit,
  editConvId,
  isFirstComment = false,
}: ConversationsElementProps) => {
  // Get mf user details
  const { user: mfUser } = useUserProfile();

  // Calculate the number of replies for this conversation
  const repliesNumber = useMemo(() => {
    if (
      conversation &&
      'allReplys' in conversation &&
      Array.isArray(conversation.allReplys)
    ) {
      return conversation.allReplys.length;
    }
    return 0;
  }, [conversation]);

  // Get unique profile pictures of users who replied
  const repliesOwnersPictures = useMemo(() => {
    if (
      conversation &&
      'allReplys' in conversation &&
      Array.isArray(conversation.allReplys)
    ) {
      return Array.from(
        new Set(
          conversation.allReplys.map(
            (reply) => reply.user.avatarFileUrl || reply.user.avatarUrl,
          ),
        ),
      ).filter(Boolean);
    }
    return [];
  }, [conversation]);

  // Get owner name, either 'You' or the user's name
  const ownerName = useMemo(() => {
    if (
      mfUser?.email.toLowerCase() === conversation.user?.email.toLowerCase()
    ) {
      return 'You';
    }

    return conversation.user?.name;
  }, [conversation?.user?.email, conversation?.user?.name, mfUser?.email]);

  // Get styles for the conversation container based on type and notification status
  const styles = useConversationContainerStyle({
    isComment,
    isRecentNotification: !!timeAgoNotification,
  });

  /**
   * Renders the main content of the conversation element
   * Includes user avatar, name, message content, and interaction options
   */
  const elementContent = () => (
    <div className={styles.convContainerStyle}>
      {/* User profile picture */}
      <ImageWithFallback
        className={styles.profileImage}
        src={
          conversation.user?.avatarFileUrl ||
          conversation.user?.avatarUrl ||
          undefined
        }
        alt="Avatar"
        fallback={
          <span>
            <OwnerPicturePlaceholderIllustration
              width="2.5rem"
              height="2.5rem"
            />
          </span>
        }
      />
      {/* Main content container */}
      <div className={styles.contentContainer}>
        {/* User name */}
        <div className={styles.ownerName}>{ownerName}</div>
        {/* Message content - either children prop or conversation text */}
        <div
          className={styles.messageContainer}
          onClick={(e) => e.stopPropagation()}
        >
          {children ? children : conversation.commentText}
        </div>
        {/* Replies section - only shown for non-comments */}
        {!isComment && (
          <ConversationReplies
            repliesCount={repliesNumber}
            isRecent={!!timeAgoNotification}
            repliesOwnersPictures={repliesOwnersPictures}
          />
        )}
        <ConversationAttachments artifacts={conversation?.artifacts || []} />
      </div>
      {/* Right side container for notifications and options */}
      <div className={styles.commentNotifications}>
        {/* More options menu (edit, delete) */}
        <ConversationMoreOptions
          conversationId={conversation.id}
          isComment={isComment}
          editConvId={editConvId}
          handleSetEditing={() => {
            if (handleSetCommentForEdit) {
              handleSetCommentForEdit(conversation.comment, conversation.id);
            }
          }}
          handleCloseEditing={() => {
            if (handleSetCommentForEdit) {
              handleSetCommentForEdit(null, null);
            }
          }}
          handleDelete={() => convDelete?.(conversation.id)}
          isOwner={
            mfUser?.email.toLowerCase() ===
            conversation.user?.email.toLowerCase()
          }
          isFirstComment={isFirstComment}
        />

        {/* Time ago and notifications container */}
        <div className={styles.commentTimeAgoContainer}>
          {!isComment && (
            <NotificationsBullet numberOfNotifications={notifications} />
          )}
          <TimeAgoStatus
            timeToShow={timeAgo}
            isHighlighted={!!timeAgoNotification}
          />
        </div>
      </div>
    </div>
  );

  return (
    <>
      {isComment ? (
        elementContent()
      ) : (
        <PrimaryButton
          className={styles.convButtonStyle}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            if (convClick) convClick(conversation.id);
          }}
        >
          {elementContent()}
        </PrimaryButton>
      )}
    </>
  );
};

export default ConversationsElement;
