import {
  DefaultButton,
  Dialog,
  DialogFooter,
  ITextField,
  PrimaryButton,
  TextField,
} from '@fluentui/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { externalize, isUrl } from '../../Helpers/URLHelpers';
import { LinkContent } from '../../types/LinkContent';
import { useDeferredPromise } from '../useDeferredPromise';
import { useLightOrDarkMode } from '../useLightOrDarkMode';

type LinkDialogProps = {
  initialText?: string;
  initialHref?: string;
  onSubmit: (value: LinkContent | PromiseLike<LinkContent>) => void;
  onCancel: (reason?: unknown) => void;
};

const LinkDialog = ({
  initialText,
  initialHref,
  onSubmit,
  onCancel,
}: LinkDialogProps) => {
  const { isDark } = useLightOrDarkMode();

  const [text, setText] = useState<string>(initialText || '');
  const [href, setHref] = useState<string>(initialHref || '');

  useEffect(() => {
    setText(initialText || '');
    setHref(initialHref || '');
  }, [initialText, initialHref]);

  const textFieldRef = useRef<ITextField>(null);
  const hrefFieldRef = useRef<ITextField>(null);

  useEffect(() => {
    // Effects run after the render pass, but before the DOM has necessarily finished updating, so the refs may be null
    // Use set immediate to execute after one tick, so the DOM has finished updating and the refs are bound
    setImmediate(() => {
      if (!initialText && textFieldRef.current) {
        textFieldRef.current.focus();
      } else if (hrefFieldRef.current) {
        hrefFieldRef.current.focus();
      }
    });
  }, [initialText, hrefFieldRef, textFieldRef]);

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter' && isUrl(externalize(href))) {
        onSubmit({ text, href: externalize(href) });
      }
    },
    [href, onSubmit, text],
  );

  return (
    <Dialog
      dialogContentProps={{
        title: initialHref ? 'Update Link' : 'Insert Link',
        showCloseButton: true,
        styles: {},
      }}
      hidden={false}
      styles={{ main: { width: '60%' } }}
      onDismiss={onCancel}
    >
      <div>
        {!initialText ? (
          <TextField
            label="Text"
            value={text}
            componentRef={textFieldRef}
            onKeyDown={handleKeyPress}
            onChange={(_e, newText) => setText(newText || '')}
          />
        ) : null}
        <TextField
          label="URL"
          value={href}
          componentRef={hrefFieldRef}
          onKeyDown={handleKeyPress}
          onChange={(_e, newHref) => setHref(newHref || '')}
        />
      </div>
      <DialogFooter>
        <PrimaryButton
          text="Confirm"
          disabled={!isUrl(externalize(href))}
          onClick={() => {
            onSubmit({ text, href: externalize(href) });
          }}
          styles={{
            label: { color: isDark ? 'white' : undefined },
          }}
        />
        <DefaultButton text="Cancel" onClick={onCancel} />
      </DialogFooter>
    </Dialog>
  );
};

export const useLinkDialog = () => {
  const { createDeferred, deferred, context, resolve, reject } =
    useDeferredPromise<LinkContent, Partial<LinkContent> | undefined>();

  const dialog = useMemo(() => {
    if (!deferred?.isPending) {
      return null;
    }

    return (
      <LinkDialog
        initialText={context?.text}
        initialHref={context?.href}
        onSubmit={resolve}
        onCancel={reject}
      />
    );
  }, [context, deferred?.isPending, reject, resolve]);

  return {
    createDeferred,
    dialog,
  };
};
