import { mergeStyles } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

export const NoTasksAvailable = () => {
  const noTasksMessageStyles = mergeStyles({
    padding: '1rem',
    color: DEALROOMS_COLORS.neutralSecondary,
    fontSize: '0.875rem',
    textAlign: 'center',
    backgroundColor: DEALROOMS_COLORS.neutralLighter,
    borderRadius: '4px',
    border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
  });

  return <div className={noTasksMessageStyles}>No tasks available</div>;
};
