import { useAuth0 } from '@auth0/auth0-react';
import { DateInput2 } from '@blueprintjs/datetime2';
import {
  Checkbox,
  DefaultButton,
  FontSizes,
  FontWeights,
  IconButton,
  mergeStyles,
  NeutralColors,
  PrimaryButton,
  Text,
  TextField,
} from '@fluentui/react';
import {
  PatchTaskPayload,
  PostTaskPayload,
  Task,
} from '@meetingflow/common/Api/data-contracts';
import { humanizeDateTime } from '@meetingflow/common/DateHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { default as classNames, default as classnames } from 'classnames';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { v4 } from 'uuid';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { MeetingPlanActionItemsPanelContext } from '../../../types/MeetingPlanPanelContext';
import { ContactPicker } from '../../Common/ContactPicker';
import { AsyncLink } from '../../HOC/AsyncLink';

type MeetingPlanActionItemsProps = {
  organizationSlug: string;
  tasks: Task[] | Partial<Task>[];
  suggestedTasks?: Task[] | Partial<Task>[];
  loading?: boolean;
  onAdd?: (task: PostTaskPayload) => void;
  onUpdate: (id: Task['id'], patch: PatchTaskPayload) => void;
  onDelete: (id: Task['id']) => void;
  disallowEdit?: boolean;
  disallowAdd?: boolean;
  showMeetingflow?: boolean;
  listMaxHeight?: string;
  meetingPlanId?: string;
  actionItemsContext?: MeetingPlanActionItemsPanelContext;
  actionItemsTitle?: string;
  suggestedActionItemsTitle?: string;
};
export const MeetingPlanActionItems = ({
  organizationSlug,
  tasks,
  suggestedTasks,
  onAdd,
  onUpdate,
  onDelete,
  disallowEdit = false,
  disallowAdd = false,
  showMeetingflow = false,
  listMaxHeight = undefined,
  meetingPlanId,
  actionItemsContext,
  actionItemsTitle,
  suggestedActionItemsTitle,
}: MeetingPlanActionItemsProps) => {
  const { user } = useAuth0();
  const { email } = user!;

  const defaultEditItem = actionItemsContext?.defaultEditItem;

  const [editItem, setEditItem] = useState<
    | Partial<
        Pick<PostTaskPayload, 'id' | 'text' | 'completed'> &
          Pick<PatchTaskPayload, 'dueDate' | 'assigneeIdOrEmail'>
      >
    | undefined
  >(
    defaultEditItem || {
      text: '',
      assigneeIdOrEmail: email?.toLowerCase(),
    },
  );

  const navigate = useNavigate();

  const { isDark } = useLightOrDarkMode();

  const appInsights = useAppInsightsContext();

  const tasksClass = useMemo(
    () =>
      mergeStyles({
        padding: '.5rem',
        marginBottom: '0.25rem',
        '>div:not(:last-child)': {
          marginBottom: '0.2rem',
        },
      }),
    [],
  );

  const fadeInClass = mergeStyles({
    position: 'relative',
    animationName: 'fadeInAnimation',
    animationDuration: '2s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '1',
    animationFillMode: 'forwards',
    width: 'auto',
    transition: '.3s ease-in-out all',
  });

  const taskClass = useMemo(
    () =>
      mergeStyles({
        display: 'flex',
        width: '100%',
        paddingBottom: '.5rem',
        borderBottom: `1px solid ${
          isDark ? NeutralColors.gray170 : NeutralColors.gray20
        }`,

        '&.suggested': {
          opacity: '.5',
          transition: '.3s ease-in-out all',

          '&:hover': {
            opacity: '1',
          },
        },

        marginBottom: '1rem',
        '.completed-checkbox': {
          flexBasis: '24px',
          position: 'relative',
          top: '.2rem',
        },
        '.complete': {
          textDecoration: 'line-through',
          flexBasis: '28px',
        },
        '.task-text': {
          overflow: 'hidden',
          flexBasis: '100%',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          fontWeight: FontWeights.semibold,
          color: isDark ? NeutralColors.white : NeutralColors.gray130,
        },
        '.task-assignee': {
          flexBasis: '33%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
        '.task-assignee-text': {
          fontWeight: FontWeights.regular,
          color: NeutralColors.gray120,
        },
        '.assignee-me': {
          color: MEETINGFLOW_COLORS.orange,
        },
        '.task-duedate': {
          textAlign: 'left',
          span: {
            color: `${
              isDark ? NeutralColors.gray120 : NeutralColors.gray130
            } !important`,
          },
        },
        '.task-edit': {
          height: '16px',
          width: '16px',
          padding: '12px',
          marginRight: '.25rem',
          position: 'relative',
          top: '0',
          display: disallowEdit ? 'none' : undefined,
        },
        '.task-delete': {
          height: '16px',
          width: '16px',
          padding: '12px',
          position: 'relative',
          top: '0',
        },
        '.no-due-date': {
          fontStyle: 'italic',
        },
      }),
    [isDark, disallowEdit],
  );

  const editTaskClass = useMemo(
    () =>
      mergeStyles({
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        rowGap: '.25rem',
        columnGap: '.25rem',

        '.text': {
          flexBasis: 'calc(50% - .125rem)',
        },

        '.due-date': {
          flexBasis: 'calc(50% - .125rem)',
          display: 'inline-block',
          '.bp5-input-group': {
            '.bp5-input': {
              boxSizing: 'border-box',
              height: '32px',
              boxShadow: 'none !important',
              border: isDark
                ? '1px solid rgb(72, 70, 68);'
                : '1px solid rgb(200, 198, 196)',
              padding: '0 8px',
              backgroundColor: isDark ? `rgb(50, 49, 48)` : NeutralColors.white,
            },
          },
          '.due-date-label': {
            display: 'block',
            fontWeight: 600,
            padding: '5px 0',
          },
        },

        '.assignee': {
          flexBasis: '100%',

          '.assignee-picker': {
            margin: '0 0 .5rem 0',
          },
        },

        '.buttons': {
          flexBasis: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          rowGap: '.25rem',
          columnGap: '.25rem',
          marginBottom: '1rem',

          button: {
            fontSize: FontSizes.small,

            height: '24px !important',
            border: 'none !important',

            '& .cancel-button': {},

            '& .ok-button': {
              padding: '0 auto',
              color: NeutralColors.white,
              backgroundColor: isDark
                ? MEETINGFLOW_COLORS.purpleDark
                : MEETINGFLOW_COLORS.purpleMedium,

              ':disabled': {
                color: NeutralColors.gray130,
                backgroundColor: MEETINGFLOW_COLORS.purpleLight,

                i: {
                  color: NeutralColors.gray130,
                },
              },
            },
          },
        },
      }),
    [isDark],
  );

  return (
    <>
      {editItem && (!disallowAdd || !disallowEdit) ? (
        <>
          <Text
            style={{
              margin: '1rem 0 .5rem 0 ',
              fontSize: FontSizes.medium,
              fontWeight: FontWeights.semibold,
              color: MEETINGFLOW_COLORS.teal,
              position: 'relative',
              display: 'block',
            }}
          >
            {editItem.id ? 'Edit Action Item' : 'New Action Item'}
          </Text>
          <div className={editTaskClass}>
            <TextField
              className="text"
              value={editItem.text || ''}
              placeholder="Enter task title"
              onChange={(_e, v) => setEditItem({ ...editItem, text: v || '' })}
              autoFocus
              onKeyUp={(e) => {
                if (!!editItem && editItem.text && e.key === 'Enter') {
                  if (editItem.id) {
                    const originalItem = tasks.find(
                      (t) => t.id === editItem.id,
                    );
                    onUpdate(editItem.id, {
                      text:
                        !originalItem || editItem.text !== originalItem.text
                          ? editItem.text
                          : undefined,
                      completed:
                        !originalItem ||
                        editItem.completed !== originalItem.completed
                          ? editItem.completed
                          : undefined,
                      assigneeIdOrEmail:
                        !originalItem ||
                        (editItem.assigneeIdOrEmail as string) !==
                          originalItem.assignee?.email
                          ? editItem.assigneeIdOrEmail
                          : undefined,
                      dueDate:
                        !originalItem ||
                        editItem.dueDate !== originalItem.dueDate
                          ? editItem.dueDate
                          : undefined,
                    });
                  } else {
                    onAdd?.({
                      id: v4(),
                      text: editItem.text,
                      assigneeIdOrEmail:
                        editItem.assigneeIdOrEmail || undefined,
                      dueDate: editItem.dueDate || undefined,
                    });
                  }
                  setEditItem(defaultEditItem);
                }
              }}
            />
            <div className={classNames('due-date', 'blueprint-component')}>
              <DateInput2
                canClearSelection
                showActionsBar
                highlightCurrentDay
                closeOnSelection={false}
                inputProps={{
                  className: isDark ? 'bp5-dark' : undefined,
                }}
                dayPickerProps={{ firstDayOfWeek: 1 }}
                timePickerProps={{ showArrowButtons: true, useAmPm: true }}
                popoverProps={{
                  className: classNames(
                    'blueprint-component',
                    isDark ? 'bp5-dark' : undefined,
                  ),
                  placement: 'right',
                  inheritDarkTheme: true,
                }}
                showTimezoneSelect={false}
                timePrecision={'minute'}
                value={editItem.dueDate}
                minDate={DateTime.now().minus({ months: 6 }).toJSDate()}
                maxDate={DateTime.now().plus({ years: 2 }).toJSDate()}
                parseDate={(d) => DateTime.fromISO(d).toJSDate() || false}
                placeholder="Set due date"
                formatDate={(d) =>
                  humanizeDateTime(DateTime.fromJSDate(d), {
                    useRelativeDates: false,
                    dateFormatOptions: {
                      day: 'numeric',
                      month: 'short',
                      year: 'numeric',
                    },
                  })
                }
                onChange={(value) =>
                  setEditItem({ ...editItem, dueDate: value })
                }
              />
            </div>
            <div className="assignee">
              <ContactPicker
                className={'assignee-picker'}
                onlyOrgMembers
                organizationSlug={organizationSlug}
                addLabel="Assign task"
                defaultPickContact={!!editItem.assigneeIdOrEmail}
                defaultEmail={editItem.assigneeIdOrEmail as string}
                onPick={(pickedEmail) => {
                  setEditItem({ ...editItem, assigneeIdOrEmail: pickedEmail });
                  return Promise.resolve(false);
                }}
                onClear={() => {
                  setEditItem({ ...editItem, assigneeIdOrEmail: null });
                }}
              />
            </div>
            <div className="buttons">
              <DefaultButton
                className="cancel-button"
                iconProps={{ iconName: 'Cancel' }}
                disabled={
                  editItem.text === defaultEditItem?.text &&
                  editItem.assigneeIdOrEmail ===
                    defaultEditItem?.assigneeIdOrEmail &&
                  editItem.dueDate === defaultEditItem?.dueDate
                }
                onClick={() => {
                  appInsights.trackEvent({
                    name: 'CANCEL_EDIT_ACTION_ITEM',
                    properties: {
                      organizationSlug,
                      meetingPlanId: meetingPlanId,
                    },
                  });
                  setEditItem(defaultEditItem);
                }}
                styles={{
                  root: {
                    height: '24px !important',
                    border: 'none !important',
                  },
                  label: {
                    fontSize: FontSizes.small,
                  },
                }}
              >
                Clear
              </DefaultButton>
              {editItem.id ? (
                <PrimaryButton
                  className="ok-button"
                  iconProps={{ iconName: 'EditSolid12' }}
                  disabled={!editItem.text}
                  onClick={() => {
                    if (!editItem.id || !editItem.text) {
                      return;
                    }

                    const originalItem = tasks.find(
                      (t) => t.id === editItem.id,
                    );

                    onUpdate(editItem.id, {
                      text:
                        !originalItem || editItem.text !== originalItem.text
                          ? editItem.text
                          : undefined,
                      completed:
                        !originalItem ||
                        editItem.completed !== originalItem.completed
                          ? editItem.completed
                          : undefined,
                      assigneeIdOrEmail:
                        !originalItem ||
                        (editItem.assigneeIdOrEmail as string) !==
                          originalItem.assignee?.email
                          ? editItem.assigneeIdOrEmail
                          : undefined,
                      dueDate:
                        !originalItem ||
                        editItem.dueDate !== originalItem.dueDate
                          ? editItem.dueDate
                          : undefined,
                    });

                    setEditItem(defaultEditItem);
                  }}
                >
                  Update
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  className="ok-button"
                  iconProps={{
                    iconName: 'CircleAdditionSolid',
                  }}
                  disabled={!editItem.text}
                  onClick={() => {
                    if (!editItem.text) {
                      return;
                    }

                    onAdd?.({
                      id: v4(),
                      text: editItem.text,
                      assigneeIdOrEmail:
                        editItem.assigneeIdOrEmail || undefined,
                      dueDate: editItem.dueDate || undefined,
                    });

                    // if (suggested) {
                    //   appInsights.trackEvent({
                    //     name: 'ACCEPT_SUGGESTED_ACTION_ITEM',
                    //     properties: {
                    //       organizationSlug,
                    //       meetingPlanId: meetingPlanId,
                    //       id: editItem.id,
                    //     },
                    //   });
                    // }

                    setEditItem(defaultEditItem);
                  }}
                >
                  Add
                </PrimaryButton>
              )}
            </div>
          </div>
        </>
      ) : null}

      <div
        className={classnames(fadeInClass, tasksClass)}
        style={{ overflow: 'auto', maxHeight: listMaxHeight }}
      >
        {actionItemsTitle && tasks.length > 0 ? (
          <Text
            style={{
              margin: '1rem 0 .5rem 0 ',
              fontSize: FontSizes.medium,
              fontWeight: FontWeights.semibold,
              color: MEETINGFLOW_COLORS.teal,
              position: 'relative',
              display: 'block',
            }}
          >
            {actionItemsTitle}
          </Text>
        ) : null}
        {tasks.map((item) => {
          return (
            <div key={item.id} className={classNames(taskClass)}>
              <Checkbox
                className="completed-checkbox"
                checked={item.completed}
                onChange={(_e, checked) =>
                  onUpdate(item.id!, { completed: !!checked })
                }
                styles={{
                  root: {
                    padding: '0',
                  },
                  checkmark: { color: 'white' },
                  checkbox: {
                    transition: 'all 0.3s ease-in-out',
                    border: `1px solid ${NeutralColors.gray80}`,
                    ':hover': {
                      border: `1px solid ${MEETINGFLOW_COLORS.purpleSecondary}`,
                    },
                  },
                }}
              />
              <div
                className={classNames(
                  'task-text',
                  item.completed ? 'complete' : undefined,
                )}
              >
                <span title={item.text}>{item.text}</span>
                <div
                  className={classNames(
                    'task-duedate',
                    !item.dueDate && 'no-due-date',
                  )}
                >
                  <Text variant="small">
                    {item.dueDate
                      ? humanizeDateTime(item.dueDate, {
                          useRelativeDates: true,
                          dateFormatOptions: {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                          },
                        })
                      : ''}
                  </Text>
                </div>
              </div>

              <div className={classNames('task-assignee')}>
                <Text
                  variant="small"
                  className={classNames(
                    'task-assignee-text',
                    item.assignee?.email === email?.toLowerCase() &&
                      !showMeetingflow
                      ? 'assignee-me'
                      : undefined,
                  )}
                >
                  <>
                    {item.assignee?.name ??
                      item.assignee?.email ??
                      'Unassigned'}
                  </>

                  {showMeetingflow ? (
                    <div
                      style={{
                        marginTop: '.25rem',
                        fontSize: FontSizes.small,
                      }}
                    >
                      <AsyncLink
                        onClick={async () => {
                          navigate(
                            `/organization/${organizationSlug}/plan/${item.meetingPlanId}`,
                          );
                        }}
                        style={{
                          color: isDark
                            ? MEETINGFLOW_COLORS.purpleTertiary
                            : MEETINGFLOW_COLORS.purpleSecondary,
                        }}
                      >
                        {item.meetingPlan?.title}
                      </AsyncLink>
                    </div>
                  ) : null}
                </Text>
              </div>

              <>
                <IconButton
                  className="task-edit"
                  iconProps={{ iconName: 'Edit' }}
                  onClick={() =>
                    setEditItem({
                      id: item.id,
                      text: item.text,
                      assigneeIdOrEmail: item.assignee?.email,
                      completed: item.completed,
                      dueDate: item.dueDate,
                    })
                  }
                />
                <IconButton
                  className="task-delete"
                  iconProps={{ iconName: 'Delete' }}
                  onClick={() => onDelete(item.id!)}
                />
              </>
            </div>
          );
        })}

        {suggestedTasks?.length ? (
          <>
            {suggestedActionItemsTitle ? (
              <Text
                style={{
                  margin: '1rem 0 .5rem 0 ',
                  fontSize: FontSizes.medium,
                  fontWeight: FontWeights.semibold,
                  color: MEETINGFLOW_COLORS.teal,
                  position: 'relative',
                  display: 'block',
                }}
              >
                {suggestedActionItemsTitle}
              </Text>
            ) : null}
            {suggestedTasks?.map((item) => {
              return (
                <div
                  key={item.id}
                  className={classNames(taskClass, 'suggested')}
                >
                  <Checkbox
                    className="completed-checkbox"
                    disabled={true}
                    checked={item.completed}
                    styles={{
                      root: {
                        padding: '0',
                      },
                      checkmark: { color: 'white' },
                      checkbox: {
                        transition: 'all 0.3s ease-in-out',
                        border: `1px solid ${NeutralColors.gray80}`,
                        ':hover': {
                          border: `1px solid ${MEETINGFLOW_COLORS.purpleSecondary}`,
                        },
                      },
                    }}
                  />
                  <div
                    className={classNames(
                      'task-text',
                      item.completed ? 'complete' : undefined,
                    )}
                  >
                    <span title={item.text}>{item.text}</span>
                  </div>

                  <IconButton
                    className="task-edit"
                    iconProps={{ iconName: 'CircleAdditionSolid' }}
                    onClick={() =>
                      setEditItem({
                        id: item.id,
                        text: item.text,
                        assigneeIdOrEmail: item.assignee?.email,
                        completed: item.completed,
                        dueDate: item.dueDate,
                      })
                    }
                  />
                </div>
              );
            })}
          </>
        ) : null}
      </div>
    </>
  );
};
