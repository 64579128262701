import { useAuth0 } from '@auth0/auth0-react';
import { mergeStyles } from '@fluentui/react';
import { ExternalServiceObject } from '@meetingflow/common/Api/data-contracts';
import React from 'react';
import { useQuery } from 'react-query';
import { MeetingPlanQuery } from '../../../../QueryNames';
import { MeetingflowsApiClient } from '../../../../Services/NetworkCommon';
import { SalesforcePanelContext } from '../../../../types/SalesforcePanelContext';
import { SalesforceInsightsPanelContent } from './SalesforceInsightsPanelContent';

export type SalesforceBrowserSidePanelPinnedContentProps = {
  organizationSlug: string;
  meetingPlanId: string;
  salesforceConfigurationId: number;
  externalDomains?: string[];
  externalContactIds?: number[];
  onPickedObject: (
    object: ExternalServiceObject | Omit<ExternalServiceObject, 'id'>,
  ) => void;
  onPinnedObject:
    | ((e?: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<unknown>)
    | undefined;
  pushSalesforcePanel: (context: SalesforcePanelContext) => void;
  associatedObjects?: ExternalServiceObject[];
  showSuggestionsToggle?: boolean;
};
export const SalesforceBrowserSidePanelPinnedContent = ({
  organizationSlug,
  meetingPlanId,
  salesforceConfigurationId,
  externalDomains,
  externalContactIds,
  onPickedObject,
  onPinnedObject,
  pushSalesforcePanel,
  associatedObjects = [],
}: SalesforceBrowserSidePanelPinnedContentProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const {
    data: meetingPlanData,
    // isLoading: meetingPlanIsLoading,
    isFetched: meetingPlanFetched,
    refetch: refetchMeetingPlan,
  } = useQuery(
    MeetingPlanQuery(organizationSlug, meetingPlanId),
    async () => {
      const token = await getAccessTokenSilently();
      return MeetingflowsApiClient.getMeetingflow(
        organizationSlug,
        meetingPlanId,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!meetingPlanId,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    },
  );

  const panelContent = mergeStyles({
    height: 'calc(100% - 12rem)',
    minHeight: 'calc(100% - 12rem)',
    padding: '.5rem .5rem 2rem .5rem',
    overflowY: 'auto',
    boxSizing: 'border-box',
  });

  return (
    <div className={panelContent}>
      {meetingPlanData?.data ? (
        <SalesforceInsightsPanelContent
          organizationSlug={organizationSlug}
          meetingflowId={meetingPlanId}
          meetingflow={meetingPlanData?.data}
          configurationId={salesforceConfigurationId}
          externalDomains={externalDomains}
          externalContactIds={externalContactIds}
          onPickedObject={onPickedObject}
          onPinnedObject={onPinnedObject}
          pushSalesforcePanel={pushSalesforcePanel}
          associatedObjects={associatedObjects}
          maxItems={5}
        />
      ) : null}
    </div>
  );
};
