import {
  Checkbox,
  DatePicker,
  Dropdown,
  FontIcon,
  NeutralColors,
  TextField,
  mergeStyles,
} from '@fluentui/react';
import { toDateOrUndefined } from '@meetingflow/common/DateHelpers';
import { toBoolean } from '@meetingflow/common/TypeHelpers';
import {
  parseLocaleFloat,
  toParsedFloatLocaleString,
} from '../../../../Helpers/NumberHelpers';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import {
  HubSpotProperty,
  HubSpotSchemaResponse,
} from '../../../../Models/HubSpot/HubSpotSchema';

export type HubSpotFormComponentProps = {
  schema?: HubSpotSchemaResponse['hubspotSchema'];
  readonly?: boolean;
  hideLabel?: boolean;
  property?: HubSpotProperty | null;
  isDirty?: boolean;
  errors?: Record<string, string[] | undefined>;
  state: Record<string, string | boolean | number | undefined | null>;
  setState: (
    value: React.SetStateAction<
      Record<string, string | boolean | number | null | undefined>
    >,
  ) => void;
};
export const HubSpotFormComponent = ({
  schema,
  property,
  errors,
  isDirty,
  readonly,
  hideLabel,
  state,
  setState,
}: HubSpotFormComponentProps) => {
  const { isDark } = useLightOrDarkMode();
  if (!schema || !property) {
    return null;
  }

  const propertyReadonly =
    property.calculated || property.modificationMetadata.readOnlyValue;
  const propertyRequired = !!schema.requiredProperties?.includes(property.name);

  const currentValue = state[property.name];

  const fieldWrapperStyles = {
    position: 'relative',
  } as React.CSSProperties;

  const clearButtonStyles = {
    display: 'inline-block',
    position: 'absolute',
    top: '2.2rem',
    right: '2.1rem',
  } as React.CSSProperties;

  const clearIconClass = mergeStyles({
    fontSize: '1.25rem',
    height: '1.25rem',
    width: '1.25rem',
    backgroundColor: isDark ? NeutralColors.gray190 : NeutralColors.gray20,
    color: isDark ? NeutralColors.gray20 : NeutralColors.gray120,
    borderRadius: '50%',
    textAlign: 'center',
    display: 'inline-block',
    lineHeight: '1.15rem',
    cursor: 'pointer',
    paddingLeft: '1px',
    transition: 'all 0.3s ease-in-out',

    ':hover': {
      backgroundColor: isDark ? NeutralColors.gray210 : NeutralColors.gray30,
      color: isDark ? NeutralColors.gray20 : NeutralColors.gray190,
    },
  });

  switch (property.type) {
    case 'number': {
      if (property.showCurrencySymbol) {
        return (
          <TextField
            key={property.name}
            disabled={readonly || propertyReadonly}
            errorMessage={errors?.[property.name]?.[0]}
            label={hideLabel ? undefined : property.label}
            prefix="$"
            styles={{
              prefix: {
                backgroundColor: isDark
                  ? NeutralColors.gray190
                  : NeutralColors.gray20,
              },
            }}
            value={currentValue ? currentValue.toLocaleString() : ''}
            onChange={(_event, newValue) => {
              if (newValue && !isNaN(parseLocaleFloat(newValue))) {
                setState({
                  ...state,
                  [property.name]: toParsedFloatLocaleString(newValue),
                });
              } else {
                setState({
                  ...state,
                  [property.name]: newValue || null,
                });
              }
            }}
          />
        );
      }
      return (
        <TextField
          key={property.name}
          disabled={readonly || propertyReadonly}
          errorMessage={errors?.[property.name]?.[0]}
          label={hideLabel ? undefined : property.label}
          type="number"
          value={(currentValue as string | undefined | null) || ''}
          onChange={(_event, newValue) => {
            setState({
              ...state,
              [property.name]: newValue || null,
            });
          }}
        />
      );
    }
    case 'boolean': {
      return (
        <Checkbox
          key={property.name}
          disabled={readonly || propertyReadonly}
          label={hideLabel ? undefined : property.label}
          checked={toBoolean(
            (currentValue as string | undefined | null) || undefined,
          )}
          onChange={(_ev, checked) =>
            setState({
              ...state,
              [property.name]: checked ? 'true' : 'false',
            })
          }
          styles={{ root: { marginBottom: '.25rem' } }}
        />
      );
    }
    case 'datetime': {
      if (!propertyRequired && !!currentValue) {
        return (
          <div style={fieldWrapperStyles}>
            <DatePicker
              key={property.name}
              disabled={readonly || propertyReadonly}
              label={hideLabel ? undefined : property.label}
              value={toDateOrUndefined(
                currentValue as string | undefined | null,
              )}
              onSelectDate={(date: Date | null | undefined) => {
                setState({
                  ...state,
                  [property.name]: date ? date.toISOString() : null,
                });
              }}
              placeholder="Select a date..."
              ariaLabel="Select a date"
            />
            <a
              onClick={() =>
                setState({
                  ...state,
                  [property.name]: null,
                })
              }
              title="Clear"
              content="Clear"
              className="clear-button"
              style={clearButtonStyles}
            >
              <FontIcon
                iconName="StatusCircleErrorX"
                className={clearIconClass}
              />
            </a>
          </div>
        );
      }

      return (
        <DatePicker
          key={property.name}
          disabled={readonly || propertyReadonly}
          label={hideLabel ? undefined : property.label}
          value={toDateOrUndefined(currentValue as string | undefined | null)}
          onSelectDate={(date: Date | null | undefined) => {
            setState({
              ...state,
              [property.name]: date ? date.toISOString() : null,
            });
          }}
          placeholder="Select a date..."
          ariaLabel="Select a date"
        />
      );
    }
    case 'enumeration': {
      // if (property.fieldType === 'radio') {
      //   let choiceGroupOptions =
      //     property?.options
      //       ?.filter((v) => !v.hidden || currentValue === v.value)
      //       ?.map((v) => ({
      //         key: v.value,
      //         text: v.label,
      //         disabled: v.hidden,
      //       })) ?? [];

      //   if (
      //     !!currentValue &&
      //     !choiceGroupOptions.some((o) => o.key === currentValue)
      //   ) {
      //     choiceGroupOptions = [
      //       {
      //         key: currentValue as string,
      //         text: currentValue as string,
      //         disabled: true,
      //       },
      //       ...choiceGroupOptions,
      //     ];
      //   }

      //   return (
      //     <ChoiceGroup
      //       key={property.name}
      //       disabled={readonly || !property?.options?.length}
      //       options={choiceGroupOptions}
      //       selectedKey={currentValue as string | undefined | null}
      //       onChange={(_evt, option) => {
      //         setState({
      //           ...state,
      //           [property.name]: (option?.key as string | undefined) || null,
      //         });
      //       }}
      //       label={property.label}
      //       required={schema.requiredProperties?.includes(property.name)}
      //     />
      //   );
      // }

      let picklistOptions =
        property?.options
          ?.filter((v) => !v.hidden || currentValue === v.value)
          ?.map((v) => ({
            key: v.value,
            text: v.label,
            disabled: v.hidden,
          })) ?? [];

      if (
        !!currentValue &&
        !picklistOptions.some((o) => o.key === currentValue)
      ) {
        picklistOptions = [
          {
            key: currentValue as string,
            text: currentValue as string,
            disabled: true,
          },
          ...picklistOptions,
        ];
      }

      if (!propertyRequired && !!currentValue) {
        return (
          <div style={fieldWrapperStyles}>
            <Dropdown
              key={property.name}
              disabled={readonly || !property?.options?.length}
              errorMessage={errors?.[property.name]?.[0]}
              options={picklistOptions}
              selectedKey={currentValue as string | undefined | null}
              onChange={(_evt, option, _index) => {
                setState({
                  ...state,
                  [property.name]: (option?.key as string | undefined) || null,
                });
              }}
              label={hideLabel ? undefined : property.label}
            />
            <a
              onClick={() =>
                setState({
                  ...state,
                  [property.name]: null,
                })
              }
              title="Clear"
              content="Clear"
              className="clear-button"
              style={{ ...clearButtonStyles, top: '2.5rem', right: '4rem' }}
            >
              <FontIcon
                iconName="StatusCircleErrorX"
                className={clearIconClass}
              />
            </a>
          </div>
        );
      }

      return (
        <Dropdown
          key={property.name}
          disabled={readonly || !property?.options?.length}
          errorMessage={errors?.[property.name]?.[0]}
          options={picklistOptions}
          selectedKey={currentValue as string | undefined | null}
          onChange={(_evt, option, _index) => {
            setState({
              ...state,
              [property.name]: (option?.key as string | undefined) || null,
            });
          }}
          label={hideLabel ? undefined : property.label}
        />
      );
    }
    case 'string': {
      if (property.fieldType === 'textarea') {
        return (
          <TextField
            key={property.name}
            disabled={readonly || propertyReadonly}
            // multiline
            // styles={{
            //   field: { height: '1rem' },
            // }}
            errorMessage={errors?.[property.name]?.[0]}
            label={hideLabel ? undefined : property.label}
            value={(currentValue as string | undefined | null) || ''}
            onChange={(_event, newValue) => {
              setState({ ...state, [property.name]: newValue || null });
            }}
          />
        );
      }
      return (
        <TextField
          key={property.name}
          disabled={readonly || propertyReadonly}
          errorMessage={errors?.[property.name]?.[0]}
          label={hideLabel ? undefined : property.label}
          value={(currentValue as string | undefined | null) || ''}
          onChange={(_event, newValue) => {
            setState({ ...state, [property.name]: newValue || null });
          }}
        />
      );
    }
    default:
      return null;
  }
};
