import { useAuth0 } from '@auth0/auth0-react';
import { OrganizationEntitlement } from '@meetingflow/common/Api/data-contracts';
import { useCallback, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useMatch, useParams } from 'react-router';
import {
  isBadRequest,
  isForbiddenError,
  isNotFoundError,
} from '../Helpers/AxiosHelpers';
import { OrganizationDomainRulesQuery, OrganizationQuery } from '../QueryNames';
import { OrganizationsApiClient } from '../Services/NetworkCommon';
import { OrganizationSlugRouteParams } from '../types/RouteParams';

export const useOrganization = (slug?: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const client = useQueryClient();

  const { organizationSlug } = useParams<OrganizationSlugRouteParams>();

  const match = useMatch({
    path: '/organization/:organizationSlug',
    end: false,
  });

  let orgSlug = slug || organizationSlug || match?.params.organizationSlug;
  if (orgSlug === 'create') {
    orgSlug = undefined;
  }

  const {
    data: orgData,
    isLoading: orgDataLoading,
    isRefetching: orgDataRefetching,
    isFetched: orgDataIsFetched,
    isError: orgDataIsError,
    error: orgDataError,
    refetch: refetchOrg,
  } = useQuery(
    OrganizationQuery(orgSlug!),
    async () => {
      const token = await getAccessTokenSilently();
      return OrganizationsApiClient.getOrganization(orgSlug!, {
        headers: { Authorization: `Bearer ${token}` },
      });
    },
    {
      enabled: !!orgSlug,
      retry: (failureCount, error) => {
        if (isForbiddenError(error)) {
          return false;
        }
        if (isBadRequest(error)) {
          return false;
        }

        return failureCount < 3;
      },
    },
  );

  const {
    data: orgDomainRules,
    isLoading: orgDomainRulesLoading,
    isRefetching: orgDomainRulesRefetching,
    isFetched: orgDomainRulesIsFetched,
    isError: orgDomainRulesIsError,
    error: orgDomainRulesError,
    refetch: refetchOrgDomainRules,
  } = useQuery(
    OrganizationDomainRulesQuery(orgSlug!),
    async () => {
      const token = await getAccessTokenSilently();
      return OrganizationsApiClient.listDomainRules(
        { organizationSlug: orgSlug! },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    { enabled: !!orgSlug },
  );

  const internalDomains: string[] = useMemo(() => {
    if (!orgData?.data?.domainRules?.length) {
      return [];
    }

    return orgData.data.domainRules.map((rule) => rule.domain);
  }, [orgData?.data?.domainRules]);

  const refetch = useCallback(async () => {
    await Promise.all([refetchOrg(), refetchOrgDomainRules()]);
  }, [refetchOrg, refetchOrgDomainRules]);

  const refetchBackground = useCallback(async () => {
    await Promise.all([
      client.invalidateQueries(OrganizationQuery(orgSlug!)),
      client.invalidateQueries(OrganizationDomainRulesQuery(orgSlug!)),
    ]);
  }, [client, orgSlug]);

  const hasEntitlement = useCallback(
    (entitlement: OrganizationEntitlement) =>
      orgData?.data?.entitlements?.includes(entitlement) || false,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgData?.data?.entitlements.join(',')],
  );

  const isLoading = orgDataLoading || orgDomainRulesLoading;
  const isRefetching = orgDataRefetching || orgDomainRulesRefetching;
  const isFetched = orgDataIsFetched && orgDomainRulesIsFetched;

  return {
    isLoading,
    isRefetching,
    isFetched,
    isError: orgDataIsError || orgDomainRulesIsError,
    forbidden: !isLoading && isForbiddenError(orgDataError),
    notFound:
      !isLoading &&
      (isNotFoundError(orgDataError) || isNotFoundError(orgDomainRulesError)),
    name: orgData?.data?.name,
    slug: orgSlug,
    organization: orgData?.data,
    entitlements: orgData?.data?.entitlements,
    domainRules: orgDomainRules?.data,
    internalDomains,
    role: orgData?.data?.memberships?.[0].role,
    isAdmin: orgData?.data?.memberships?.[0].role === 'ADMIN',
    isGuest: orgData?.data?.memberships?.[0].role === 'GUEST',
    canCreatePlans: ['ADMIN', 'CREATOR'].includes(
      orgData?.data?.memberships?.[0].role || '',
    ),
    hasEntitlement,
    refetch,
    refetchBackground,
  };
};
