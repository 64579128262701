import { isArray, isFunction } from 'lodash';
import { useCallback, useState } from 'react';

export type UseStackResult<TStack> = {
  head: TStack | undefined;
  stack: TStack[];
  push: (item: TStack) => void;
  pop: () => TStack | undefined;
  clear: () => void;
  reset: (items?: TStack | TStack[]) => void;
  empty: boolean;
  count: number;
};

export const useStack = <TStack>(
  initial?: TStack | TStack[] | (() => TStack[]),
): UseStackResult<TStack> => {
  const [stackValues, setStackValues] = useState<TStack[]>(
    isFunction(initial)
      ? initial()
      : () => {
          if (!initial) {
            return [];
          } else if (isArray(initial)) {
            return initial;
          } else {
            return [initial];
          }
        },
  );

  const [head, ...rest] = stackValues;

  const push = useCallback(
    (item: TStack) => {
      setStackValues([item, ...stackValues]);
    },
    [stackValues],
  );

  const pop = useCallback(() => {
    setStackValues([...rest]);

    return head;
  }, [head, rest]);

  const clear = useCallback(() => {
    setStackValues([]);
  }, []);

  const reset = useCallback((items?: TStack | TStack[]) => {
    if (!items) {
      setStackValues([]);
    } else if (isArray(items)) {
      setStackValues(items);
    } else {
      setStackValues([items]);
    }
  }, []);

  return {
    head,
    stack: stackValues,
    push,
    pop,
    clear,
    reset,
    empty: stackValues.length === 0,
    count: stackValues.length,
  };
};
