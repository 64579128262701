import { PropsWithChildren, useRef } from 'react';
import { useScrollToBottom } from '../../Hooks/useScrollToBottom';
import { mergeStyles } from '@fluentui/react';
import classNames from 'classnames';

type AutoScrollViewProps = {
  className?: string;
  autoScroll?: boolean;
};

export const AutoScrollView = ({
  className,
  autoScroll,
  children,
}: PropsWithChildren<AutoScrollViewProps>) => {
  const ref = useRef<HTMLDivElement>(null);

  useScrollToBottom(ref, autoScroll);

  const styles = mergeStyles({
    overflowY: 'auto',
    height: '100%',
  });

  return (
    <div className={classNames(styles, className)} ref={ref}>
      {children}
    </div>
  );
};
