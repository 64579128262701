import { useMemo } from 'react';
import { useParams } from 'react-router';

export const useDealRoomArtifactId = () => {
  const { artifactId } = useParams<{ artifactId: string }>();

  const artifactIdNumber = useMemo(
    () => (artifactId ? parseInt(artifactId, 10) : undefined),
    [artifactId],
  );

  return artifactIdNumber && !isNaN(artifactIdNumber)
    ? artifactIdNumber
    : undefined;
};
