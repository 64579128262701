import { mergeStyleSets } from '@fluentui/react';
import { useMemo } from 'react';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { ArtifactIllustration } from '../Components/Illustrations/ArtifactIllustration';
import { ActionItem } from '../../../types/MilestoneTypes';

type ActionItemAttachmentsListProps = {
  actionItem: ActionItem;
};

const numberOfAttachmentsToShow = 3;

export const ActionItemAttachmentsList = ({
  actionItem,
}: ActionItemAttachmentsListProps) => {
  // Calculate how many additional files exist beyond the first 3 that are displayed
  const remainingFilesThatAreNotShow = useMemo(() => {
    if (!actionItem) return 0;
    if (!actionItem.artifacts?.length) return 0;
    return Math.max(0, actionItem.artifacts.length - numberOfAttachmentsToShow);
  }, [actionItem]);

  const styles = mergeStyleSets({
    filesContainer: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: '.5rem',
    },
    fileContainer: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: DEALROOMS_COLORS.inputLightGray,
      borderRadius: '.25rem',
      gap: '.125rem',
      padding: '.25rem .375rem',
    },
    artifactName: {
      fontSize: '.75rem',
      lineHeight: '1.125rem',
      color: DEALROOMS_COLORS.neutralDarker,
    },
  });

  if (!actionItem?.artifacts?.length) {
    return null;
  }

  return (
    <div className={styles.filesContainer}>
      {/* Display only first 3 artifacts */}
      {actionItem.artifacts.slice(0, numberOfAttachmentsToShow).map((artifact) => (
        <div key={artifact.id} className={styles.fileContainer}>
          <ArtifactIllustration />
          <span className={styles.artifactName}>{artifact.name}</span>
        </div>
      ))}
      {/* Show count of remaining files if more than 3 exist */}
      {remainingFilesThatAreNotShow > 0 && (
        <div className={styles.fileContainer}>
          <span className={styles.artifactName}>
            +{remainingFilesThatAreNotShow}
          </span>
        </div>
      )}
    </div>
  );
};
