import { mergeStyles } from '@fluentui/react';
import { DealRoomAsyncPrimaryButton } from '../Components/DealRoomButton';
import { AddToIconProps } from '../../../utils/iconProps';

type ActionItemPanelFooterProps = {
  showFooter: boolean;
  shouldShowFulFillAttachmentButton: boolean;
  handleClickFulfillRequestAttachment: () => Promise<void>;
  handleAddAttachment: () => Promise<void>;
};

export const ActionItemPanelFooter = ({
  showFooter,
  shouldShowFulFillAttachmentButton,
  handleClickFulfillRequestAttachment,
  handleAddAttachment,
}: ActionItemPanelFooterProps) => {
  const footerStyle = mergeStyles({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  });

  // we shouldn't render the footer when we create a new action item
  if (!showFooter) return null;

  return (
    <div className={footerStyle}>
      {shouldShowFulFillAttachmentButton ? (
        <DealRoomAsyncPrimaryButton
          iconProps={AddToIconProps}
          onClick={handleClickFulfillRequestAttachment}
          text="Fulfill Request for Artifact"
        />
      ) : (
        <DealRoomAsyncPrimaryButton
          iconProps={AddToIconProps}
          onClick={handleAddAttachment}
          text="Add Artifact"
        />
      )}
    </div>
  );
};
