import {
  CheckboxVisibility,
  DetailsList,
  IColumn,
  IDetailsRowProps,
  mergeStyleSets,
} from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

interface TableListProps<T> {
  items: T[];
  columns: IColumn[];
  onRenderRow?: (props?: IDetailsRowProps) => JSX.Element;
}

export const DecisionSiteTableList = <T extends object>({
  items,
  columns,
  onRenderRow,
}: TableListProps<T>) => {
  const styles = mergeStyleSets({
    listContainer: {
      padding: '1rem 1.5rem',
      backgroundColor: DEALROOMS_COLORS.white,
      boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.02)',
      borderRadius: '.25rem',
      border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
    },
    table: {
      '.ms-DetailsHeader': {
        height: 'auto',
        lineHeight: '1rem !important',
        paddingTop: '.625rem',
        paddingBottom: '.5rem',
      },

      '.ms-DetailsHeader-cell': {
        fontSize: '0.8125rem !important',
        lineHeight: '1rem !important',
        fontWeight: '400 !important',
        minHeight: '1rem',
        height: '1rem',
        backgroundColor: 'transparent',
        color: DEALROOMS_COLORS.themeSecondary,

        '.ms-DetailsHeader-cellName': {
          fontSize: '0.8125rem !important',
          lineHeight: '1rem !important',
          fontWeight: '400 !important',
        },

        ':hover': {
          backgroundColor: DEALROOMS_COLORS.white,
          color: DEALROOMS_COLORS.themeSecondary,
        },

        ':active': {
          backgroundColor: DEALROOMS_COLORS.white,
          color: DEALROOMS_COLORS.themeSecondary,
        },

        ':selected': {
          backgroundColor: DEALROOMS_COLORS.white,
          color: DEALROOMS_COLORS.themeSecondary,
        },
      },

      '.ms-DetailsRow': {
        width: '100%',
        backgroundColor: 'transparent',
        borderBottom: `1px solid ${DEALROOMS_COLORS.neutralLight} !important`,
        cursor: 'pointer',

        ':hover': {
          backgroundColor: DEALROOMS_COLORS.financialLighterGray,
        },
      },

      '.ms-DetailsRow-cell': {
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
        color: DEALROOMS_COLORS.themePrimary,
        padding: '12px 0 12px 12px',
        fontSize: '0.9375rem',
        lineHeight: '1.5rem',
      },
    },
  });

  return (
    <div className={styles.listContainer}>
      <DetailsList
        items={items}
        columns={columns}
        checkboxVisibility={CheckboxVisibility.hidden}
        className={styles.table}
        onRenderRow={onRenderRow}
      />
    </div>
  );
};
