import { useAuth0 } from '@auth0/auth0-react';
import {
  ComboBox,
  FontIcon,
  FontSizes,
  FontWeights,
  mergeStyles,
  NeutralColors,
  Text,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { Truthy } from '@meetingflow/common/TypeHelpers';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import * as yup from 'yup';
import { EMPTY_ARRAY } from '../../Constants';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { OrganizationContactsQuery } from '../../QueryNames';
import { ContactsApiClient } from '../../Services/NetworkCommon';
import { DEALROOMS_COLORS, MEETINGFLOW_COLORS } from '../../Themes/Themes';

type MeetingPlanAddAttendeeForm = {
  email: string;
};

const formSchema = yup
  .object({
    email: yup.string().email('The specified email address is invalid.'),
  })
  .required();

export type ContactPickerProps = {
  id?: string;
  className?: string;
  organizationSlug: string;
  addLabel?: string;
  placeholderLabel?: string;
  defaultEmail?: string;
  focusOnEdit?: boolean;
  defaultPickContact?: boolean;
  allowFreeform?: true;
  onlyWithPlans?: true;
  onlyOrgMembers?: true;
  includeOrgGuests?: true;
  excludeContactEmails?: string[];
  onPick: (email: string) => Promise<boolean>;
  onClear?: () => void;
};

export const ContactPicker = ({
  id,
  className,
  organizationSlug,
  addLabel,
  placeholderLabel,
  focusOnEdit,
  defaultEmail,
  defaultPickContact,
  allowFreeform,
  excludeContactEmails,
  onlyOrgMembers,
  includeOrgGuests,
  onlyWithPlans,
  onPick,
  onClear,
}: ContactPickerProps) => {
  const { getAccessTokenSilently, user } = useAuth0();

  const { isDark } = useLightOrDarkMode();

  const addAttendeeInput = useRef<HTMLDivElement>(null);
  const [
    pickingContact,
    { setTrue: setPickingContact, setFalse: setNotPickingContact },
  ] = useBoolean(defaultPickContact ?? false);

  const { email } = user!;

  const {
    setValue,
    watch,
    control,
    formState: { isValid, errors },
  } = useForm<MeetingPlanAddAttendeeForm>({
    defaultValues: {
      email: defaultEmail ?? '',
    },
    resolver: yupResolver(formSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const currentSelection = watch('email');

  const isMeetingflow = import.meta.env.VITE_APP_NAME === 'meetingflow';
  const isDecisionSite = import.meta.env.VITE_APP_NAME !== 'meetingflow';

  const {
    data: contactsData,
    isLoading: contactSuggestionsLoading,
    refetch: refetchContacts,
  } = useQuery(
    OrganizationContactsQuery(organizationSlug!, onlyWithPlans, onlyOrgMembers),
    async () => {
      const token = await getAccessTokenSilently();
      return ContactsApiClient.listContacts(
        {
          organizationSlug: organizationSlug!,
          hasPlans: onlyWithPlans,
          isMember: onlyOrgMembers,
          includeGuests: includeOrgGuests,
          q: currentSelection || undefined,
          limit: 10,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRefetch = useCallback(debounce(refetchContacts, 500), [
    refetchContacts,
  ]);

  useEffect(() => {
    setValue('email', defaultEmail || '', {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true,
    });
  }, [defaultEmail, pickingContact, setValue]);

  useEffect(() => {
    debouncedRefetch();
  }, [currentSelection, debouncedRefetch]);

  const selectedContact = useMemo(() => {
    return contactsData?.data?.find(
      (contact) => contact.email === currentSelection?.toLowerCase(),
    );
  }, [contactsData?.data, currentSelection]);

  const contactOptions = useMemo(() => {
    if (!contactsData?.data) {
      return contactSuggestionsLoading
        ? EMPTY_ARRAY
        : [
            {
              key: 'none',
              text: 'No contacts',
              disabled: true,
            },
          ];
    }

    const filteredContacts = !contactsData?.data
      ? EMPTY_ARRAY
      : contactsData.data
          .filter(
            (contact) =>
              !excludeContactEmails?.includes(contact.email) &&
              contact.email !== email!.toLowerCase(),
          )
          .map((contact) => ({
            key: contact.email,
            text:
              contact.name && contact.name !== contact.email
                ? `${contact.name} (${contact.email})`
                : contact.email,
          }));

    return filteredContacts.length
      ? [
          !excludeContactEmails?.includes(email!.toLowerCase())
            ? { key: email!.toLowerCase(), text: `Me (${email})` }
            : undefined,
          ...filteredContacts,
        ].filter(Truthy)
      : [
          {
            key: 'none',
            text: 'No contacts',
            disabled: true,
          },
        ];
  }, [
    contactSuggestionsLoading,
    contactsData?.data,
    email,
    excludeContactEmails,
  ]);

  const addAttendeeClass = useMemo(
    () =>
      mergeStyles({
        position: 'relative',
        cursor: 'pointer',
        flexGrow: 1,
        boxSizing: 'border-box',
        margin: isValid ? '.5rem 0' : '.5rem 0 1.5rem 0',
        padding: '.25rem',
        borderRadius: '.25rem',
        height: pickingContact ? '40px' : '28px',
        maxWidth: pickingContact ? '5000rem' : '8rem',
        backgroundColor: isMeetingflow
          ? isDark
            ? MEETINGFLOW_COLORS.black
            : MEETINGFLOW_COLORS.purpleGreyMedium
          : isDark
            ? DEALROOMS_COLORS.themeSecondary
            : DEALROOMS_COLORS.themeSecondary,
        top: '.25rem',

        transition: '.5s ease-in-out all',
        ':hover': {
          backgroundColor: isMeetingflow
            ? isDark
              ? MEETINGFLOW_COLORS.purpleDark
              : MEETINGFLOW_COLORS.purpleMedium
            : isDark
              ? DEALROOMS_COLORS.themePrimary
              : DEALROOMS_COLORS.themePrimary,

          // '.add-attendee-icon': {
          //   backgroundColor: pickingContact
          //     ? undefined
          //     : MEETINGFLOW_COLORS.purpleGrey,
          //   border: `2px solid ${'transparent'}`,
          // },

          '.add-attendee-link': {
            color: MEETINGFLOW_COLORS.white,
          },
        },

        '.add-attendee-icon': {
          position: 'absolute',
          top: pickingContact ? '.6rem' : '4px',
          left: '.5rem',
          lineHeight: '1rem',
          border: `2px solid transparent`,
          color: pickingContact
            ? isMeetingflow
              ? isDark
                ? MEETINGFLOW_COLORS.white
                : MEETINGFLOW_COLORS.purpleDark
              : isDark
                ? DEALROOMS_COLORS.themePrimary
                : DEALROOMS_COLORS.themePrimary
            : MEETINGFLOW_COLORS.white,
          height: '1rem',
          width: '1rem',
          borderRadius: '1rem',
          zIndex: '10',
          textAlign: 'center',
          transition: '.5s ease-in-out all',
        },

        '.add-attendee-link': {
          opacity: pickingContact ? '0' : '1',
          width: 'auto',
          maxWidth: pickingContact ? '0' : '5000rem',
          maxHeight: pickingContact ? '0' : '5000rem',
          overflow: 'hidden',
          paddingLeft: '2.25rem',
          position: 'absolute',
          top: '.3rem',
          transition: '.5s ease-in-out all',
          fontSize: FontSizes.small,
          fontWeight: FontWeights.semibold,
          color: MEETINGFLOW_COLORS.white,
        },

        '.add-attendee-cancel': {
          cursor: 'pointer',
          opacity: pickingContact ? '1' : '0',
          width: '1.25rem',
          height: '1.25rem',
          overflow: 'hidden',
          position: 'absolute',
          top: '.6rem',
          right: '.25rem',
          transition: '.3s ease-in-out all',
          fontWeight: FontWeights.semibold,
          color: MEETINGFLOW_COLORS.white,
          backgroundColor: isMeetingflow
            ? isDark
              ? MEETINGFLOW_COLORS.black
              : MEETINGFLOW_COLORS.purpleGrey
            : isDark
              ? DEALROOMS_COLORS.themeSecondary
              : DEALROOMS_COLORS.themeSecondary,
          borderRadius: '.75rem',
          lineHeight: '1.25rem',
          textAlign: 'center',
          ':hover': {
            color: isMeetingflow
              ? MEETINGFLOW_COLORS.purpleMedium
              : DEALROOMS_COLORS.white,
            backgroundColor: isMeetingflow
              ? MEETINGFLOW_COLORS.white
              : DEALROOMS_COLORS.themePrimary,
          },
        },

        '.add-attendee-input': {
          opacity: pickingContact ? '1' : '0',
          width: 'calc(100% - 2rem)',
          maxWidth: pickingContact ? '5000rem' : '0',
          maxHeight: pickingContact ? '5000rem' : '0',
          overflow: 'hidden',
          transition: '.5s ease-in-out all',
          position: 'absolute',
          top: '.25rem',

          '.ms-ComboBox': {
            borderRadius: '.25rem',
            backgroundColor: isDark ? NeutralColors.gray200 : undefined,
            ':after': {
              borderColor: 'transparent',
              borderWidth: '0',
            },

            ':focus, *:focus': {
              outline: 'none !important',
            },
          },

          input: {
            paddingLeft: '1.75rem',
            transition: '.3s ease-in-out all',
            backgroundColor: isDark ? NeutralColors.gray200 : undefined,
          },

          '[role="alert"]': {
            margin: '.5rem 0 0 0',
          },
        },
      }),
    [pickingContact, isDark, isValid, isMeetingflow],
  );

  const toggleAddAttendeeContextMenu = () => {
    const input = addAttendeeInput?.current?.querySelector('input');
    setTimeout(() => {
      // @ts-ignore
      input?.nextElementSibling?.click();
    }, 50);
  };

  const resetAddAttendeesInput = () => {
    const input = addAttendeeInput?.current?.querySelector('input');
    if (input) {
      input.value = '';
    }
  };

  const enableAddAttendeesUI = () => {
    const input = addAttendeeInput?.current?.querySelector('input');
    if (input) {
      resetAddAttendeesInput();
      if (focusOnEdit) {
        toggleAddAttendeeContextMenu();
        input.focus();
      }
    }
  };

  const disableAddAttendeesUI = () => {
    resetAddAttendeesInput();
  };

  useEffect(() => {
    if (pickingContact) {
      enableAddAttendeesUI();
    } else {
      disableAddAttendeesUI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickingContact]);

  return (
    <div
      id={id}
      className={classNames(addAttendeeClass, className)}
      onClick={
        pickingContact
          ? undefined
          : (e) => {
              e.stopPropagation();
              e.preventDefault();
              setPickingContact();
            }
      }
    >
      <FontIcon iconName="AddFriend" className="add-attendee-icon" />

      {pickingContact ? (
        <>
          <Controller
            name="email"
            control={control}
            defaultValue={defaultEmail || ''}
            render={({ field: { onChange, value } }) => {
              return (
                <ComboBox
                  ref={addAttendeeInput}
                  text={
                    selectedContact
                      ? selectedContact.name
                        ? `${selectedContact.name} (${selectedContact.email})`
                        : selectedContact.email
                      : value
                  }
                  className="add-attendee-input"
                  allowFreeform
                  useComboBoxAsMenuWidth
                  errorMessage={errors.email?.message}
                  styles={{
                    callout: {
                      maxHeight: '300px',
                      overflowY: 'auto',
                      backgroundColor: isMeetingflow
                        ? isDark
                          ? NeutralColors.gray180
                          : MEETINGFLOW_COLORS.purpleUltraSuperLight
                        : isDark
                          ? DEALROOMS_COLORS.themePrimary
                          : DEALROOMS_COLORS.themePrimary,
                      border: `2px solid ${isMeetingflow ? MEETINGFLOW_COLORS.purpleMedium : DEALROOMS_COLORS.themePrimary}`,
                      borderTopWidth: '4px',

                      '.ms-Callout-main': {
                        backgroundColor: isMeetingflow
                          ? isDark
                            ? NeutralColors.gray180
                            : MEETINGFLOW_COLORS.purpleUltraSuperLight
                          : isDark
                            ? DEALROOMS_COLORS.themePrimary
                            : DEALROOMS_COLORS.white,
                      },

                      '.ms-ComboBox-option': {
                        color: isDark
                          ? NeutralColors.gray70
                          : NeutralColors.gray150,
                        transition: '.3s ease-in-out all',
                        fontSize: FontSizes.small,
                        minHeight: '24px',
                        ':hover, :focus': {
                          backgroundColor: isMeetingflow
                            ? MEETINGFLOW_COLORS.purpleGrey
                            : DEALROOMS_COLORS.themeSecondary,
                          color: isMeetingflow
                            ? MEETINGFLOW_COLORS.white
                            : DEALROOMS_COLORS.white,
                        },
                      },

                      '.ms-ComboBox-option.is-checked': {
                        border: 'none',
                        backgroundColor: isMeetingflow
                          ? MEETINGFLOW_COLORS.purpleGrey
                          : DEALROOMS_COLORS.themePrimary,
                        color: MEETINGFLOW_COLORS.white,
                      },
                    },
                    optionsContainerWrapper: {
                      maxHeight: '300px',
                    },
                    optionsContainer: {
                      maxHeight: '300px',
                    },
                  }}
                  calloutProps={{
                    directionalHintFixed: false,
                  }}
                  onKeyUp={(e) => {
                    e.preventDefault();
                    switch (e.key) {
                      case 'Escape': {
                        setNotPickingContact();
                        break;
                      }
                      default: {
                        break;
                      }
                    }
                  }}
                  onInputValueChange={onChange}
                  onChange={async (e, option, _index, value) => {
                    e.stopPropagation();
                    e.preventDefault();

                    const emailAddress =
                      (option?.key as string | undefined) || (value as string);

                    onChange(emailAddress || '');

                    // If we allow freeform email addresses
                    if (allowFreeform) {
                      // If the user picked a suggested contact, or entered a valid email
                      if (
                        option?.key ||
                        (value &&
                          (await formSchema.isValid({ email: emailAddress })))
                      ) {
                        // If the parent component wants us to leave picker mode
                        if (await onPick(emailAddress)) {
                          setNotPickingContact();
                        }

                        return;
                      }

                      // No option was picked, or the entered text is not a valid email
                      toggleAddAttendeeContextMenu();
                      toast.error(`The entered contact is invalid`);
                      return;
                    } /*!allowFreeform*/ else {
                      // If we do not allow freeform picking, the user must pick a valid contact suggestion
                      const picked =
                        option ??
                        contactOptions?.find(
                          (opt) => opt.key === value?.toLowerCase(),
                        );

                      // If the user did not pick a suggestion directly or hit enter on a valid entered email, display an error and prevent selection
                      if (!picked) {
                        toggleAddAttendeeContextMenu();
                        toast.error(`The entered contact is invalid`);
                        return;
                      }

                      // If the parent component wants us to leave picker mode
                      if (await onPick(picked.key as string)) {
                        setNotPickingContact();
                      }

                      return;
                    }
                  }}
                  options={contactOptions}
                  placeholder={placeholderLabel}
                />
              );
            }}
          />

          <FontIcon
            iconName="Cancel"
            className="add-attendee-cancel"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onClear?.();
              setNotPickingContact();
            }}
          />
        </>
      ) : (
        <Text className="add-attendee-link">{addLabel ?? 'Pick contact'}</Text>
      )}
    </div>
  );
};
