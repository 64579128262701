import {
  ActionType,
  BuyerOrientationStepProps,
} from '../../../../../types/BuyerOrientationSurveyTypes';
import {
  Dropdown,
  IDropdownOption,
  mergeStyles,
  Text,
  TextField,
} from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../../Themes/Themes';
import { surveyDepartmentOptions } from '../buyerOrientationSurveyUtils';
import React, { FormEvent, useCallback } from 'react';

export const BuyerOrientationPersonalDetails = ({
  orientationForm,
  updateOrientationForm,
}: BuyerOrientationStepProps) => {
  const personalDetailsContainerStyles = mergeStyles({
    width: '100%',
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    maxWidth: '400px',
    margin: '0 auto',

    '.personal-details-title': {
      color: DEALROOMS_COLORS.themeSecondary,
      lineHeight: '1.5rem',
      padding: '0 0 0.5rem 0',
      fontSize: '1.125rem',
    },

    '.personal-details-field': {
      width: '100%',
      '.ms-Label': {
        fontWeight: '400',
        color: DEALROOMS_COLORS.themePrimary,
      },
      '.ms-TextField-fieldGroup': {
        border: 'none',
        backgroundColor: DEALROOMS_COLORS.inputLightGray,
        borderRadius: '4px',
        height: 'auto',
      },
      input: {
        minHeight: '2.5rem',
        fontSize: '1rem',
        fontWeight: '400',
        '::placeholder': {
          color: DEALROOMS_COLORS.userSurveyInputPlaceholder,
        },
      },
    },

    '.personal-details-dropdown': {
      width: '100%',
      minHeight: '2.5rem',
      '.ms-Dropdown-title': {
        minHeight: '2.5rem',
        backgroundColor: DEALROOMS_COLORS.inputLightGray,
        display: 'flex',
        border: 'none',
        alignItems: 'center',
        borderRadius: '4px',
        color: orientationForm.department
          ? DEALROOMS_COLORS.themePrimary
          : DEALROOMS_COLORS.userSurveyInputPlaceholder,
        fontSize: '1rem',
      },

      '.ms-Dropdown-caretDownWrapper': {
        top: '50%',
        transform: 'translate(0, -50%)',
      },
    },
  });

  const handleUpdateDropdownOptions = useCallback(
    (
      event: FormEvent<HTMLDivElement>,
      option?: IDropdownOption | undefined,
      index?: number | undefined,
    ) => {
      updateOrientationForm({
        type: ActionType.UPDATE_DEPARTMENT,
        payload: option && typeof option.key === 'string' ? option.key : '',
      });
    },
    [updateOrientationForm],
  );

  return (
    <div className={personalDetailsContainerStyles}>
      <Text className="personal-details-title">
        Let’s start with your personal details.
      </Text>
      <TextField
        className="personal-details-field"
        placeholder="Your name"
        value={orientationForm.name || ''}
        onChange={(_e, newValue) =>
          updateOrientationForm({
            type: ActionType.UPDATE_NAME,
            payload: newValue || '',
          })
        }
      />
      <TextField
        className="personal-details-field"
        placeholder="Your title"
        value={orientationForm.title || ''}
        onChange={(_e, newValue) =>
          updateOrientationForm({
            type: ActionType.UPDATE_TITLE,
            payload: newValue || '',
          })
        }
      />
      <Dropdown
        className="personal-details-dropdown"
        selectedKey={orientationForm.department || ''}
        placeholder="Your Department..."
        options={surveyDepartmentOptions}
        onChange={handleUpdateDropdownOptions}
      />
    </div>
  );
};
