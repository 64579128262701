export const ArtifactIllustration = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.16602 12.3333H4.49935C4.03268 12.3333 3.66602 11.96 3.66602 11.5V4.49996C3.66602 4.03329 4.03268 3.66663 4.49935 3.66663H11.4993C11.9593 3.66663 12.3327 4.03329 12.3327 4.49996V9.16663C12.3327 9.43996 12.5527 9.66663 12.8327 9.66663C13.106 9.66663 13.3327 9.43996 13.3327 9.16663V4.49996C13.3327 3.48663 12.506 2.66663 11.4993 2.66663H4.49935C3.48602 2.66663 2.66602 3.48663 2.66602 4.49996V11.5C2.66602 12.5066 3.48602 13.3333 4.49935 13.3333H9.16602C9.43935 13.3333 9.66602 13.1066 9.66602 12.8333C9.66602 12.5533 9.43935 12.3333 9.16602 12.3333ZM9.51935 13.1866L13.186 9.51996C13.3793 9.31996 13.3793 9.00663 13.186 8.80663C12.986 8.60663 12.6727 8.60663 12.4727 8.80663L8.80602 12.4733C8.60602 12.6666 8.60602 12.98 8.80602 13.18C8.99935 13.3733 9.31268 13.3733 9.51268 13.18L9.51935 13.1866ZM9.65935 12.8266V9.82663C9.65935 9.73329 9.73268 9.65996 9.82601 9.65996H12.826C13.0993 9.65996 13.326 9.43329 13.326 9.15996C13.326 8.87996 13.0993 8.65996 12.826 8.65996H9.82601C9.17935 8.65996 8.65935 9.17996 8.65935 9.82663V12.8266C8.65935 13.1 8.87935 13.3266 9.15935 13.3266C9.43268 13.3266 9.65935 13.1 9.65935 12.8266Z"
      fill="#2B2D39"
    />
  </svg>
);
