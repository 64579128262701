import { mergeStyles, Text } from '@fluentui/react';
import { ExternalServiceObject } from '@meetingflow/common/Api/data-contracts';
import React from 'react';
import { HubSpotPanelContext } from '../../../../types/HubSpotPanelContext';
import { MeetingPlanHubSpotPinnedContentList } from './MeetingPlanHubSpotPinnedContentList';
import { MeetingPlanHubSpotSuggestedCompaniesList } from './MeetingPlanHubSpotSuggestedCompaniesList';
import { MeetingPlanHubSpotSuggestedDealsList } from './MeetingPlanHubSpotSuggestedDealList';
import { HubSpotInsightsPanelContent } from './HubSpotInsightsPanelContent';
import { useQuery } from 'react-query';
import { MeetingPlanQuery } from '../../../../QueryNames';
import { useAuth0 } from '@auth0/auth0-react';
import { MeetingflowsApiClient } from '../../../../Services/NetworkCommon';

export type HubSpotBrowserSidePanelPinnedContentProps = {
  organizationSlug: string;
  meetingPlanId: string;
  configurationId: number;
  externalDomains?: string[];
  externalContactIds?: number[];
  onPickedObject: (
    object: ExternalServiceObject | Omit<ExternalServiceObject, 'id'>,
  ) => void;
  onPinnedObject:
    | ((e?: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<unknown>)
    | undefined;
  pushHubSpotPanel: (context: HubSpotPanelContext) => void;
  associatedObjects?: ExternalServiceObject[];
  showSuggestionsToggle?: boolean;
};
export const HubSpotBrowserSidePanelPinnedContent = ({
  organizationSlug,
  meetingPlanId,
  configurationId,
  externalDomains,
  externalContactIds,
  onPickedObject,
  onPinnedObject,
  pushHubSpotPanel,
  associatedObjects = [],
}: HubSpotBrowserSidePanelPinnedContentProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const {
    data: meetingPlanData,
    // isLoading: meetingPlanIsLoading,
    isFetched: meetingPlanFetched,
    refetch: refetchMeetingPlan,
  } = useQuery(
    MeetingPlanQuery(organizationSlug, meetingPlanId),
    async () => {
      const token = await getAccessTokenSilently();
      return MeetingflowsApiClient.getMeetingflow(
        organizationSlug,
        meetingPlanId,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!meetingPlanId,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    },
  );

  const panelContent = mergeStyles({
    height: 'calc(100% - 12rem)',
    minHeight: 'calc(100% - 12rem)',
    padding: '.5rem .5rem 2rem .5rem',
    overflowY: 'auto',
    boxSizing: 'border-box',
  });

  return (
    <div className={panelContent}>
      {meetingPlanData?.data ? (
        <HubSpotInsightsPanelContent
          organizationSlug={organizationSlug}
          meetingflowId={meetingPlanId}
          meetingflow={meetingPlanData?.data}
          configurationId={configurationId}
          externalDomains={externalDomains}
          onPickedObject={onPickedObject}
          onPinnedObject={onPinnedObject}
          pushHubSpotPanel={pushHubSpotPanel}
          associatedObjects={associatedObjects}
          maxItems={5}
        />
      ) : null}
    </div>
  );
};
