import { DSConfigurationHeaderRow } from '../Components/DSConfigurationHeaderRow';
import React, { useCallback, useEffect, useState } from 'react';
import { PrimaryButton, IconButton, Stack, TextField, Toggle } from '@fluentui/react';
import { DealRoomsApiClient } from '../../../../Services/NetworkCommon';
import { useOrganizationSlug } from '../../../../Hooks/useOrganizationSlug';
import { useDealRoomId } from '../../../../Hooks/useDealRoomId';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-hot-toast';
import { DealRoomArtifact } from '@meetingflow/common/Api/data-contracts';

// Define the structure for callout card form data
interface KeyFactData {
    id?: number;
    title: string;
    value: string;
    editableByBuyer: boolean;
}

// Define an empty callout card template
const emptyKeyFact: KeyFactData = {
    title: '',
    value: '',
    editableByBuyer: false,
};

export const DSConfigurationKeyFacts: React.FC = () => {
    // State for storing callout cards
    const [keyFacts, setKeyFacts] = useState<KeyFactData[]>([]);
    // State to track if there are unsaved changes
    const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
    // State for storing error messages
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    // State for storing available artifacts
    const [artifacts, setArtifacts] = useState<Array<DealRoomArtifact>>([]);

    // Hooks for getting organization and deal room context
    const organizationSlug = useOrganizationSlug();
    const dealRoomId = useDealRoomId();
    const { getAccessTokenSilently } = useAuth0();

    // load key facts cards from the API
    const loadKeyFacts = useCallback(async () => {
        if (!organizationSlug || !dealRoomId) {
            return;
        }

        const token = await getAccessTokenSilently();

        const response = await DealRoomsApiClient.listKeyFacts(
            organizationSlug,
            dealRoomId,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        );

        setKeyFacts(response.data as KeyFactData[]);
    }, [organizationSlug, dealRoomId, getAccessTokenSilently]);


    // load key facts when component mounts or dependencies change
    useEffect(() => {
        loadKeyFacts();
    }, [loadKeyFacts]);

    // add a new empty key fact
    const handleAddKeyFact = () => {
        setKeyFacts([...keyFacts, { ...emptyKeyFact }]);
        setUnsavedChanges(true);
    };

    // delete a key fact
    const handleDeleteKeyFact = async (index: number) => {
        if (!organizationSlug || !dealRoomId) {
            return;
        }

        const token = await getAccessTokenSilently();
        const fact = keyFacts[index];

        const removeKeyFact = () => {
            const newFacts = [...keyFacts];
            newFacts.splice(index, 1);
            setKeyFacts(newFacts);
            setUnsavedChanges(true);
        }

        // key fact is not saved in DB - just a temporary key fact user was creating
        // no need to show toast for this case
        if (!fact.id) {
            removeKeyFact();
        }

        // key fact was saved in the DB
        if (fact.id) {
            await toast.promise(
                DealRoomsApiClient.deleteKeyFact(
                    organizationSlug,
                    dealRoomId,
                    fact.id,
                    {
                        headers: { Authorization: `Bearer ${token}` }
                    },
                ),
                {
                    loading: 'Deleting key fact...',
                    success: () => {
                        removeKeyFact();
                        return 'Key fact deleted successfully';
                    },
                    error: 'Failed to delete key fact',
                }
            );
        }
    };

    // handle changes in key fact fields
    const handleKeyFactChange = (index: number, field: keyof KeyFactData, value: (string | boolean | null)) => {
        const newFacts = [...keyFacts];
        newFacts[index] = { ...newFacts[index], [field]: value };
        setKeyFacts(newFacts);
        setUnsavedChanges(true);
    };

    // save all key facts
    const handleSave = async () => {
        if (!organizationSlug || !dealRoomId) {
            return;
        }

        // Validate that each fact has a title and description
        const invalidFacts = keyFacts.filter(fact => {
            const hasTitle = Boolean(fact.title);
            const hasValue = Boolean(fact.value);
            return !hasTitle || !hasValue;
        });

        if (invalidFacts.length > 0) {
            setErrorMessage('Each fact must have a title and value');
            return;
        }

        const token = await getAccessTokenSilently();
        const promises = keyFacts.map(async (fact) => {
            if (fact.id) {
                await DealRoomsApiClient.updateKeyFact(
                    organizationSlug,
                    dealRoomId,
                    fact.id,
                    fact,
                    {
                        headers: { Authorization: `Bearer ${token}` }
                    }
                );
            } else {
                await DealRoomsApiClient.createKeyFact(
                    organizationSlug,
                    dealRoomId,
                    fact,
                    {
                        headers: { Authorization: `Bearer ${token}` }
                    }
                );
            }
        });
        await toast.promise(
            Promise.all(promises),
            {
                loading: 'Saving key facts...',
                success: () => {
                    setErrorMessage(null);
                    setUnsavedChanges(false);
                    return 'Key facts saved successfully';
                },
                error: (error) => {
                    const message = error?.message || 'Failed to save key facts';
                    setErrorMessage(message);
                    return message;
                },
            }
        ).then(async () => {
            await loadKeyFacts();
        });
    };

    return (

        <Stack tokens={{ childrenGap: 20 }} styles={{ root: { width: '100%' } }}>
            {/* Header with buttons */}
            <DSConfigurationHeaderRow
                title="Key Facts"
                description="Key Facts are metrics and statistics on the Overview page. They can be edited by the buyer."
            >
                <PrimaryButton
                    onClick={handleAddKeyFact}
                    text="Add New Key Fact"
                    styles={{ root: { maxHeight: '20px', padding: '0 10px' } }}
                />

                <PrimaryButton
                    onClick={handleSave}
                    text="Save Changes"
                    disabled={!unsavedChanges}
                />
            </DSConfigurationHeaderRow>

            {/* Error message display */}
            {errorMessage && (
                <div style={{ color: '#a4262c', marginTop: '8px' }}>
                    {errorMessage}
                </div>
            )}

            {/* Render key facts in grid */}
            <div style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                gap: '20px',
                width: '100%'
            }}>
                {keyFacts.map((fact, index) => (
                    <Stack key={index} tokens={{ childrenGap: 10 }} styles={{
                        root: {
                            padding: 20,
                            border: '1px solid #ccc',
                            backgroundColor: '#fff',
                            borderRadius: '4px',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                        }
                    }}>
                        {/* Card header and delete button */}
                        <Stack horizontal horizontalAlign="space-between">
                            <h3>Fact {index + 1}</h3>
                            <IconButton
                                iconProps={{ iconName: 'Delete' }}
                                onClick={() => handleDeleteKeyFact(index)}
                                ariaLabel="Delete fact"
                            />
                        </Stack>

                        <TextField
                            label="Title"
                            required
                            value={fact.title}
                            onChange={(_, value) => handleKeyFactChange(index, 'title', value || '')}
                        />

                        <TextField
                            label="Value"
                            required
                            multiline
                            rows={3}
                            value={fact.value}
                            onChange={(_, value) => handleKeyFactChange(index, 'value', value || '')}
                        />

                        <Toggle
                            label="Editable By Buyer"
                            checked={fact.editableByBuyer}
                            onChange={(_, checked) => handleKeyFactChange(index, 'editableByBuyer', checked || false)}
                        />
                    </Stack>
                ))}
            </div>
        </Stack>
    );
};