import { DealRoomArtifact } from '@meetingflow/common/Api/data-contracts';
import { FileClient } from '../Services/NetworkCommon';

export type FileType = 'image' | 'video';
export type PickFileOptions = {
  excludeAcceptAll?: boolean;
  fileTypes?: FileType;
  timeoutMs?: number;
};

export const supportsShowOpenFilePicker =
  'showOpenFilePicker' in window &&
  typeof window.showOpenFilePicker === 'function';

const pickFileLegacy = async ({
  multiple,
  fileTypes,
  timeoutMs,
}: PickFileOptions & { multiple?: boolean }) => {
  return new Promise<File | File[] | null>((resolve, reject) => {
    const input = document.createElement('input');
    input.style.display = 'none';
    input.type = 'file';
    input.multiple = multiple ?? false;
    switch (fileTypes) {
      case 'image': {
        input.accept = 'image/*';
        break;
      }
      case 'video': {
        input.accept = 'video/*';
        break;
      }
      default: {
        input.accept = '*/*';
      }
    }

    const timeoutId = setTimeout(() => {
      reject();
      document.body.removeChild(input);
      document.removeEventListener('focus', onDocumentFocus);
    }, timeoutMs ?? 300000);

    input.onchange = (evt) => {
      clearTimeout(timeoutId);

      const files = (evt.target as HTMLInputElement).files;
      if (files === null || files.length === 0) {
        resolve(null);
      } else {
        const fileArray = Array.from(files);
        resolve(multiple ? fileArray : files[0]);
      }

      document.body.removeChild(input);
      document.removeEventListener('focus', onDocumentFocus);
    };

    const onDocumentFocus = () => {
      clearTimeout(timeoutId);
      setTimeout(() => {
        const { files } = input;

        if (files === null || files.length === 0) {
          resolve(null);
        } else {
          const fileArray = Array.from(files);
          resolve(multiple ? fileArray : files[0]);
        }

        document.body.removeChild(input);
      }, 250);

      document.removeEventListener('focus', onDocumentFocus);
    };

    document.body.appendChild(input);
    document.addEventListener('focus', onDocumentFocus);

    input.dispatchEvent(new MouseEvent('click'));
  });
};

const pickFileModern = async ({
  multiple,
  excludeAcceptAll,
  fileTypes,
}: PickFileOptions & { multiple?: boolean }) => {
  let types: FilePickerAcceptType[] | undefined;
  switch (fileTypes) {
    case 'image': {
      types = [
        {
          description: 'images',
          accept: {
            'image/*': [
              '.png',
              '.gif',
              '.jpg',
              '.jpeg',
              '.svg',
              '.bmp',
              '.webp',
              '.tiff',
            ],
          },
        },
      ];
      break;
    }
    case 'video': {
      types = [
        {
          description: 'videos',
          accept: {
            'video/*': [
              '.flv',
              '.mp4',
              '.m3u8',
              '.ts',
              '.3gp',
              '.mov',
              '.avi',
              '.wmv',
            ],
          },
        },
      ];
      break;
    }

    default: {
      types = [
        {
          description: 'all',
          accept: {
            '*/*': [],
          },
        },
      ];
      break;
    }
  }
  const handles = await window.showOpenFilePicker({
    multiple,
    excludeAcceptAllOption: excludeAcceptAll,
    types,
  });

  if (multiple) {
    return Promise.all(handles.map((handle) => handle.getFile()));
  }

  return handles.length ? handles[0]?.getFile() : null;
};
export const pickFile = async (opts: PickFileOptions) => {
  return (
    supportsShowOpenFilePicker
      ? pickFileModern({ ...opts, multiple: false })
      : pickFileLegacy({ ...opts, multiple: false })
  ) as Promise<File | null>;
};

export const pickFiles = async (opts: PickFileOptions) => {
  return (
    supportsShowOpenFilePicker
      ? pickFileModern({ ...opts, multiple: true })
      : pickFileLegacy({ ...opts, multiple: true })
  ) as Promise<File[]>;
};

const uploadFile = async <T = string>(
  token: string,
  url: string,
  file: File,
) => {
  const formData = new FormData();
  formData.append('file', file, file.name);
  const result = await FileClient.post<T>(url, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (result.status === 200) {
    const uploadedUrl = result.data;
    return uploadedUrl;
  }

  throw new Error(`Failed to upload file`);
};

export const uploadFileToOrg = async (
  token: string,
  organizationSlug: string,
  file: File,
) => uploadFile(token, `/organization/${organizationSlug}/files`, file);

export const uploadFileToPlan = async (
  token: string,
  organizationSlug: string,
  meetingPlanId: string,
  file: File,
) =>
  uploadFile(
    token,
    `/organization/${organizationSlug}/plan/${meetingPlanId}/files`,
    file,
  );

export const uploadFileToDealRoom = async (
  token: string,
  organizationSlug: string,
  dealRoomId: number,
  file: File,
) =>
  uploadFile<DealRoomArtifact>(
    token,
    `/organization/${organizationSlug}/dealroom/${dealRoomId}/artifacts/files`,
    file,
  );

export const blobToBase64 = (blob: Blob): Promise<string> =>
  new Promise<string>((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });

export const toContentPath = (
  fileName?: string | null,
  organizationSlug?: string,
  meetingPlanId?: string,
): string | undefined => {
  if (!fileName) {
    return undefined;
  }

  if (fileName.startsWith('static__')) {
    const trimmedFileName = fileName.split('static__')[1];
    return `/static-content/files/${trimmedFileName}`;
  }

  if (!!meetingPlanId && !!organizationSlug) {
    return `/user-content/organization/${organizationSlug}/plan/${meetingPlanId}/files/${fileName}`;
  } else if (!!organizationSlug) {
    return `/user-content/organization/${organizationSlug}/files/${fileName}`;
  }

  return undefined;
};
