import { useMemo } from 'react';
import { useDeferredPromise } from '../../useDeferredPromise';
import { AddAttachmentDialog } from '../../../Components/DealRoom/Dialogs/AddAttachmentDialog';

type UseRequestAttachmentDialogProps = {
  organizationSlug: string;
  dealRoomId: number;
  actionItemId: number | null;
  makeJustDealRoomUpload?: boolean; // for now, we'll use  this as a flag to make just deal room upload used in conversations side
};
export const useAddAttachmentDialog = ({
  organizationSlug,
  dealRoomId,
  actionItemId,
  makeJustDealRoomUpload,
}: UseRequestAttachmentDialogProps) => {
  const { createDeferred, resolve, reject, deferred } = useDeferredPromise();

  const dialog = useMemo(() => {
    if (actionItemId === null && !makeJustDealRoomUpload) return null;

    if (!deferred || !deferred.isPending) {
      return null;
    }

    return (
      <AddAttachmentDialog
        organizationSlug={organizationSlug}
        dealRoomId={dealRoomId}
        actionItemId={actionItemId || -1}
        onResolve={resolve}
        onDismiss={reject}
        makeJustDealRoomUpload={makeJustDealRoomUpload}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deferred, deferred?.isPending, dealRoomId, organizationSlug]);

  return {
    createDeferred,
    resolve,
    reject,
    deferred,
    dialog,
  };
};
