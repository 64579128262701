import { mergeStyles } from '@fluentui/react';
import { RenderLeafProps } from 'slate-react';

export const RenderLeaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  if (leaf.highlight) {
    children = <mark>{children}</mark>;
  }

  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.strikethrough) {
    children = <del>{children}</del>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  return (
    <span
      // The following is a workaround for a Chromium bug where,
      // if you have an inline at the end of a block,
      // clicking the end of a block puts the cursor inside the inline
      // instead of inside the final {text: ''} node
      // https://github.com/ianstormtaylor/slate/issues/4704#issuecomment-1006696364
      className={
        leaf.text === ''
          ? mergeStyles({
              paddingLeft: '0.1px',
            })
          : undefined
      }
      {...attributes}
    >
      {children}
    </span>
  );
};
