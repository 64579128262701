import { encoding } from 'lib0';
export const sendWSMessage = (
  ws: WebSocket | null | undefined,
  message: number,
) => {
  if (!ws) {
    return;
  }

  try {
    const encoder = encoding.createEncoder();
    encoding.writeVarUint(encoder, message);
    ws.send(encoding.toUint8Array(encoder));
  } catch (err: unknown) {
    console.warn(`Failed to send websocket message ${err}`);
  }
};
