import { CustomElement, CustomText } from '@meetingflow/common/Types/Slate';
import { Editor, Element, Path, Point, Range, Text, Transforms } from 'slate';
import {
  LIST_TYPES,
  afterText,
  beforeText,
  deindentList,
  getChildBlocks,
  getParentBlock,
  getPreviousSiblingBlock,
  isNestedListActive,
} from '../Helpers/EditorHelpers';

export const withLists = <T extends Editor>(editor: T) => {
  const {
    isVoid,
    isInline,
    insertBreak,
    insertSoftBreak,
    deleteBackward,
    deleteForward,
    deleteFragment,
    normalizeNode,
  } = editor;

  editor.isVoid = (element) => isVoid(element);

  editor.isInline = (element) => isInline(element);

  editor.insertBreak = () => {
    const { selection } = editor;
    const block = getParentBlock(editor, { type: 'list-item' });

    if (selection && block) {
      const textBefore = beforeText(editor);
      const textAfter = afterText(editor);

      // If in a list item with no text before or after, revert back to a paragraph
      if (!textBefore && !textAfter) {
        if (isNestedListActive(editor)) {
          Transforms.unwrapNodes(editor, {
            match: (n) => Element.isElement(n) && LIST_TYPES.includes(n.type),
            split: true,
            mode: 'lowest',
          });
        } else {
          Transforms.unwrapNodes(editor, {
            match: (n) => Element.isElement(n) && LIST_TYPES.includes(n.type),
            split: true,
            mode: 'all',
          });

          Transforms.setNodes(editor, {
            type: 'paragraph',
          });
        }
        return;
      }
    }

    insertBreak();
  };

  editor.insertSoftBreak = () => {
    insertSoftBreak();
  };

  editor.deleteBackward = (...args) => {
    const { selection } = editor;
    const match = getParentBlock(editor, { type: 'list-item' });

    // If user is backspacing within a list item
    if (selection && Range.isCollapsed(selection) && match) {
      const [, path] = match;

      const previousSiblingNode = getPreviousSiblingBlock(editor, {
        at: path,
        type: [...LIST_TYPES, 'list-item'] as Element['type'][],
      });

      const isStart = Point.equals(
        selection.anchor,
        Editor.start(editor, path),
      );
      // const isEnd = Point.equals(selection.anchor, Editor.end(editor, path));
      const nestedListActive = isNestedListActive(editor);

      // If backspacing at the start of a nested list item, deindent
      if (isStart && nestedListActive) {
        deindentList(editor);
        return;
      }
      // If at the start of an unested list item, and there are no previous list items
      // Switch to a paragraph
      if (isStart && !previousSiblingNode) {
        Transforms.unwrapNodes(editor, {
          match: (n) => Element.isElement(n) && LIST_TYPES.includes(n.type),
          split: true,
          mode: 'lowest',
        });

        Transforms.setNodes(editor, {
          type: 'paragraph',
        });
        return;
      }
    }

    deleteBackward(...args);
  };

  editor.deleteForward = (...args) => {
    deleteForward(...args);
  };

  editor.deleteFragment = (...args) => {
    deleteFragment(...args);
  };

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    if (Element.isElement(node) && LIST_TYPES.includes(node.type)) {
      // If the list is immediately followed by another list at the same level, merge them
      const nextPath = Path.next(path);
      if (Editor.hasPath(editor, nextPath)) {
        const [nextElement] = Editor.node(editor, nextPath) || [];
        if (
          nextElement &&
          Element.isElement(nextElement) &&
          nextElement.type === node.type
        ) {
          Transforms.mergeNodes(editor, {
            at: nextPath,
            mode: 'lowest',
          });
          return false;
        }
      }

      // If the list is immediately preceded by another list at the same level, merge them
      const previousPath = Path.hasPrevious(path)
        ? Path.previous(path)
        : undefined;
      if (previousPath && Editor.hasPath(editor, previousPath)) {
        const [previousElement] = Editor.node(editor, previousPath) || [];
        if (
          previousElement &&
          Element.isElement(previousElement) &&
          previousElement.type === node.type
        ) {
          Transforms.mergeNodes(editor, {
            at: path,
            mode: 'lowest',
          });
          return false;
        }
      }

      if (node.children.length === 0) {
        Transforms.insertNodes(
          editor,
          {
            type: 'list-item',
            children: [{ text: '' }],
          },
          { at: [...path, 0] },
        );
        return false;
      }

      const blockChildren = Array.from(
        Editor.nodes<CustomElement>(editor, {
          match: (n, p) =>
            Element.isElement(n) &&
            Path.isChild(p, path) &&
            ![...LIST_TYPES, 'list-item'].includes(n.type),
          at: path,
        }),
      );

      const textChildren = Array.from(
        Editor.nodes<CustomText>(editor, {
          match: (n, p) => Text.isText(n) && Path.isChild(p, path),
          at: path,
        }),
      );

      if (blockChildren.length) {
        Editor.withoutNormalizing(editor, () => {
          for (const [n, p] of blockChildren) {
            Transforms.setNodes(editor, { type: 'list-item' }, { at: p });
          }
        });
        return false;
      }

      if (textChildren.length) {
        Editor.withoutNormalizing(editor, () => {
          for (const [n, p] of textChildren) {
            Transforms.wrapNodes(
              editor,
              { type: 'list-item', children: [] },
              { at: p, mode: 'lowest' },
            );
          }
        });
        return false;
      }
    }

    if (Element.isElement(node) && node.type === 'list-item') {
      const parentNodes = Array.from(
        Editor.nodes(editor, {
          at: path,
          mode: 'all',
          match: (n) => Element.isElement(n) && LIST_TYPES.includes(n.type),
        }),
      );

      if (!parentNodes.length) {
        Transforms.setNodes(
          editor,
          {
            type: 'paragraph',
          },
          { at: path },
        );
        return false;
      }
    }

    return normalizeNode(entry);
  };

  return editor;
};
