import { DealRoomLinkArtifact } from '@meetingflow/common/Api/data-contracts';
import { ImageWithFallback } from '../../Common/ImageWithFallback';
import { ArtifactIconPreview } from './ArtifactIconPreview';
import { BaseArtifactCard, BaseArtifactCardProps } from './BaseArtifactCard';

export type LinkArtifactCardProps = BaseArtifactCardProps &
  Pick<DealRoomLinkArtifact, 'url' | 'thumbnailUrl'>;

export const LinkArtifactCard = ({
  url,
  orgSlug,
  dealRoomId,
  label,
  name,
  thumbnailUrl,
  ...rest
}: LinkArtifactCardProps) => {
  return (
    <BaseArtifactCard
      className="link-artifact"
      orgSlug={orgSlug}
      dealRoomId={dealRoomId}
      label={label}
      name={name}
      {...rest}
    >
      <ImageWithFallback
        src={
          thumbnailUrl ||
          `https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${url}&size=50`
        }
        fallbackSrc={
          thumbnailUrl
            ? `https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${url}&size=50`
            : undefined
        }
        alt={label || name}
        fallback={<ArtifactIconPreview type="LINK" />}
        objectFit="contain"
      />
    </BaseArtifactCard>
  );
};
