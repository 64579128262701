import { Editor, Element, Transforms } from 'slate';

export const withTags = <T extends Editor>(editor: T) => {
  const { isInline, isVoid, normalizeNode } = editor;

  editor.isVoid = (element: Element) =>
    element.type === 'tag' || isVoid(element);

  editor.isInline = (element) => element.type === 'tag' || isInline(element);

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;
    if (Element.isElement(node) && node.type === 'tag') {
      if (!node.tagId || !node.label) {
        Transforms.removeNodes(editor, { at: path });
        return false;
      }
    }
    return normalizeNode(entry);
  };

  return editor;
};
