import * as React from 'react';
import { styled } from '@mui/material';

export const DSMenuItemRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    width: '100%',

    '.icon': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    'div.text': {
        display: 'flex',
        flexDirection: 'column',
        gap: '0',

        'span.primaryText': {
            fontSize: theme.typography?.body2.fontSize,
            fontWeight: theme.typography?.fontWeightRegular
        },

        'span.secondaryText': {
            fontSize: theme.typography?.caption.fontSize,
            color: theme.palette.text.secondary,
        }
    }
}));