import { mergeStyles, Stack, Text } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../Themes/Themes';
import { PropsWithChildren } from 'react';

export interface DecisionSiteHomeContainerProps {
  title: string;
  description: string;
}

export const DecisionSiteHomeContainer = ({
  children,
  title,
  description,
}: PropsWithChildren<DecisionSiteHomeContainerProps>) => {
  const wrapperContainer = mergeStyles({
    padding: '2rem',
    height: '100vh',
    width: '100%',
    overflow: 'auto',

    '.main-container': {
      border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
      borderRadius: '4px',
      boxShadow: '0px 2px 3px 0px #00000008',

      '.header': {
        display: 'flex',
        padding: '1rem 1.5rem',
        width: 'calc(100% - 3rem)',
        flexDirection: 'column',
        borderRadius: '4px 4px 0 0',
        backgroundColor: DEALROOMS_COLORS.white,
        borderBottom: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
        gap: '.375rem',

        '.title': {
          fontSize: '1.5rem',
          fontWeight: '600',
          lineHeight: '1.5rem',
          color: DEALROOMS_COLORS.themePrimary,
        },

        '.description': {
          fontSize: '1rem',
          fontWeight: '400',
          lineHeight: '1rem',
          color: DEALROOMS_COLORS.themePrimary,
        },
      },

      '.content': {
        backgroundColor: DEALROOMS_COLORS.neutralGray,
        padding: '2rem',
      },
    },
  });

  return (
    <Stack className={wrapperContainer}>
      <Stack className="main-container">
        <Stack.Item className="header">
          <Text className="title">{title}</Text>
          <Text className="description">{description}</Text>
        </Stack.Item>
        <Stack.Item className="content">{children}</Stack.Item>
      </Stack>
    </Stack>
  );
};
