import { useAuth0 } from '@auth0/auth0-react';
import { Application } from '@meetingflow/common/Api/data-contracts';
import { isString } from 'lodash';
import { isStringArray } from '@meetingflow/common/TypeHelpers';
import { useCallback, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { OrganizationExternalServiceConfigurationsQuery } from '../QueryNames';
import { ExternalServicesApiClient } from '../Services/NetworkCommon';
import { useOrganization } from './useOrganization';

export type UseExternalServiceConfigurationsOptions = {
  app?: Application;
  withToken?: boolean;
};
export const useExternalServiceConfigurations = ({
  app,
  withToken,
}: UseExternalServiceConfigurationsOptions = {}) => {
  const { slug } = useOrganization();
  const { getAccessTokenSilently } = useAuth0();

  const client = useQueryClient();

  const {
    data: externalServiceConfigurationsData,
    isLoading: externalServiceConfigurationsLoading,
    isFetched: externalServiceConfigurationsFetched,
    refetch: refetchExternalServiceConfigurations,
  } = useQuery(
    OrganizationExternalServiceConfigurationsQuery(slug!, app, withToken),
    async () => {
      const token = await getAccessTokenSilently();
      return ExternalServicesApiClient.listConfigurations(
        { app, withToken, organizationSlug: slug! },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    { enabled: !!slug },
  );

  const allConfigurations = useMemo(
    () => externalServiceConfigurationsData?.data ?? [],
    [externalServiceConfigurationsData?.data],
  );

  const configurationsWithToken = useMemo(
    () =>
      allConfigurations.filter(
        (config) => config.externalServiceUserTokens?.length,
      ),
    [allConfigurations],
  );

  const hasToken = useCallback(
    (appType?: Application) => {
      if (!appType) {
        return !!configurationsWithToken.length;
      } else {
        return configurationsWithToken.some((config) => config.app === appType);
      }
    },
    [configurationsWithToken],
  );

  const hasTokenWithScope = useCallback(
    (scope: string | string[] | string[][], appType?: Application) => {
      if (!(appType ?? app)) return false;

      const config = configurationsWithToken.find(
        (c) =>
          c.app === (appType ?? app) && c.externalServiceUserTokens?.length,
      );

      if (!config) return false;

      if (isString(scope)) {
        // scope: string
        return config.externalServiceUserTokens?.some(
          (token) => token.scope?.includes(scope),
        );
      } else if (isStringArray(scope) && scope.length) {
        // scope: string[]
        return config.externalServiceUserTokens?.some((token) =>
          scope.every((s) => token.scope?.includes(s)),
        );
      } else {
        // string[][]
        const listOfScopes = scope as string[][];
        return listOfScopes.some(
          (scopeList) =>
            config.externalServiceUserTokens?.some((token) =>
              scopeList.every((s) => token.scope?.includes(s)),
            ),
        );
      }
    },
    [app, configurationsWithToken],
  );

  const refetchAll = useCallback(() => {
    if (!slug) {
      return Promise.resolve();
    }
    return Promise.all([
      client.invalidateQueries(
        OrganizationExternalServiceConfigurationsQuery(slug!),
      ),
      client.invalidateQueries(
        OrganizationExternalServiceConfigurationsQuery(slug!, undefined, true),
      ),
      client.invalidateQueries(
        OrganizationExternalServiceConfigurationsQuery(slug!, 'GOOGLE'),
      ),
      client.invalidateQueries(
        OrganizationExternalServiceConfigurationsQuery(slug!, 'GOOGLE', true),
      ),
      client.invalidateQueries(
        OrganizationExternalServiceConfigurationsQuery(slug!, 'M365'),
      ),
      client.invalidateQueries(
        OrganizationExternalServiceConfigurationsQuery(slug!, 'M365', true),
      ),
      client.invalidateQueries(
        OrganizationExternalServiceConfigurationsQuery(slug!, 'SALESFORCE'),
      ),
      client.invalidateQueries(
        OrganizationExternalServiceConfigurationsQuery(
          slug!,
          'SALESFORCE',
          true,
        ),
      ),
      client.invalidateQueries(
        OrganizationExternalServiceConfigurationsQuery(slug!, 'HUBSPOT'),
      ),
      client.invalidateQueries(
        OrganizationExternalServiceConfigurationsQuery(slug!, 'HUBSPOT', true),
      ),
      client.invalidateQueries(
        OrganizationExternalServiceConfigurationsQuery(slug!, 'SLACK'),
      ),
      client.invalidateQueries(
        OrganizationExternalServiceConfigurationsQuery(slug!, 'SLACK', true),
      ),
      client.invalidateQueries(
        OrganizationExternalServiceConfigurationsQuery(slug!, 'TEAMS'),
      ),
      client.invalidateQueries(
        OrganizationExternalServiceConfigurationsQuery(slug!, 'TEAMS', true),
      ),
    ]);
  }, [client, slug]);

  const fetch = useCallback(
    async (application?: Application) => {
      const token = await getAccessTokenSilently();
      const result = await ExternalServicesApiClient.listConfigurations(
        {
          organizationSlug: slug!,
          app: application || app,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      return result.status === 200 ? result.data : [];
    },
    [app, getAccessTokenSilently, slug],
  );

  const configurationById = useCallback(
    (configurationId: number, configurationApp?: Application) => {
      return configurationsWithToken.find(
        (configuration) =>
          configuration.id === configurationId &&
          (configurationApp
            ? configuration.app === configurationApp
            : app
              ? configuration.app === app
              : true),
      );
    },
    [app, configurationsWithToken],
  );

  return {
    loading: externalServiceConfigurationsLoading,
    fetched: externalServiceConfigurationsFetched,
    allConfigurations,
    configurationsWithToken,
    configurationById,
    hasToken,
    hasTokenWithScope,
    fetch,
    refetch: refetchExternalServiceConfigurations,
    refetchAll,
  };
};
