import { styled } from '@mui/material';

export const StyledNavbar = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  padding: '1rem',
  justifyContent: 'space-between',
  gap: '1rem',
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  containerType: 'inline-size',

  '.navbar-title': {
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '24px',
    color: theme.palette.text.primary,
    paddingLeft: '1rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '100%',

    '@container (max-width: 600px)': {
      maxWidth: '200px',
    },
  },

  '.navbar-actions': {
    display: 'flex',
    alignItems: 'center',
    gap: '40px',

    '.action-buttons': {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',

      '@container (max-width: 600px)': {
        gap: '0.5rem',
      },
    },  

    '.action-button': {
      padding: '0.5rem 1rem',
      transition: '.3s ease-in-out all',

      '.ms-Button-label': {
        fontSize: '15px',
        lineHeight: '24px',
        fontWeight: '400',
      },

      i: {
        marginRight: '1rem',

        '@container (max-width: 600px)': {
          marginRight: '0',
        },
      },

      'i[data-icon-name*=Chevron]': {
        marginLeft: '1rem',
        marginRight: '0',
      },

      '@container (max-width: 600px)': {

        '.button-label': {
          display: 'none',
        },

        '.MuiButton-startIcon': {
          marginRight: '0',
        },

        '.MuiButton-endIcon': {
          display: 'none',
        },
      },
    },
  },  
}));