import {
  FontSizes,
  FontWeights,
  NeutralColors,
  Text,
  mergeStyles,
  useTheme,
} from '@fluentui/react';
import {
  EditIcon,
  OpenPaneIcon,
  StatusCircleErrorXIcon,
} from '@fluentui/react-icons-mdl2';
import { SlateSidePanelShortcut } from '@meetingflow/common/Api/data-contracts';
import { Editor, Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useSlateStatic } from 'slate-react';
import { CreateDeferredPromise } from '../../../Helpers/DeferredPromise';
import {
  AddIntegrationModalOptions,
  AddIntegrationResult,
} from '../../../Hooks/useAddIntegrationModal';
import { useExternalServiceConfigurations } from '../../../Hooks/useExternalServiceConfigurations';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { MeetingPlanPanelContext } from '../../../types/MeetingPlanPanelContext';
import { SidePanelShortcutContext } from '../../../types/SidePanelShortcutContext';
import { unwrapNodes } from '../Helpers/EditorHelpers';

export const SidePanelShortcutElement = ({
  attributes,
  children,
  element,
  setPanelContext,
  createAddIntegrationDeferred,
  getSidePanelShortcutContext,
}: RenderElementProps & {
  setPanelContext: (
    context?: MeetingPlanPanelContext | MeetingPlanPanelContext[],
  ) => void;
  createAddIntegrationDeferred: CreateDeferredPromise<
    AddIntegrationResult | undefined,
    AddIntegrationModalOptions | undefined
  >;
  getSidePanelShortcutContext: CreateDeferredPromise<
    SidePanelShortcutContext,
    Partial<SidePanelShortcutContext> | undefined
  >;
}) => {
  const editor = useSlateStatic();

  const theme = useTheme();

  const { configurationsWithToken } = useExternalServiceConfigurations();

  const sidePanelActionElement = element as SlateSidePanelShortcut;

  const sidePanelShortcutClass = mergeStyles({
    fontWeight: FontWeights.semibold,
    color: theme.palette.themePrimary,
    display: 'inline-block',
    position: 'relative',
    margin: '0px 0.1rem',
  });

  const sidePanelShortcutStyles = mergeStyles({
    display: 'inline-block !important',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignItems: 'center',
    alignContent: 'space-around',
    columnGap: '0.25rem',
    minHeight: '1.2rem',
    height: 'auto',
    backgroundColor: MEETINGFLOW_COLORS.orangeDark,
    borderRadius: '.25rem',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    lineHeight: '1.1rem',
    fontWeight: FontWeights.semibold,
    fontSize: FontSizes.small,
    color: MEETINGFLOW_COLORS.white,
    cursor: 'pointer',
    transition: '.3s ease-in-out all',
    ':hover': {
      backgroundColor: MEETINGFLOW_COLORS.orange,
      color: NeutralColors.white,

      '.panel-buttons-wrapper': {
        display: 'inline-block',
        opacity: '1',
        right: '-1.25rem',
        top: '-.5rem',
      },
    },

    '.shortcut-type-icon': {
      display: 'inline-block',
      marginRight: '.25rem',
      svg: {
        display: 'inline-block',
        width: '.75rem',
        marginRight: '.25rem',
      },
    },

    '.panel-buttons-wrapper': {
      opacity: '0',
      width: 'auto',
      height: '1.5rem',
      display: 'flex',
      overflow: 'hidden',
      transition: '.3s ease-in-out all',
      backgroundColor: MEETINGFLOW_COLORS.orangeDark,
      borderTopRightRadius: '1rem',
      borderBottomRightRadius: '1rem',
      position: 'absolute',
      top: 0,
      right: 0,
      borderRadius: '.25rem',

      '[data-panel-button="true"]': {
        display: 'inline-block',
        height: '100%',
        width: '1rem',
        lineHeight: '1.5rem',
        textAlign: 'center',
        paddingLeft: '.25rem',
        paddingRight: '.25rem',
        transition: '.3s ease-in-out all',
        userSelect: 'none',
        ':hover': {
          backgroundColor: MEETINGFLOW_COLORS.orangeDarker,
        },
        svg: {
          height: '1em',
          verticalAlign: 'baseline',
        },
      },
    },
  });

  return (
    <div
      {...attributes}
      contentEditable={false}
      className={sidePanelShortcutClass}
    >
      <div
        className={sidePanelShortcutStyles}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          const path = ReactEditor.findPath(editor, element);

          Transforms.select(editor, Editor.end(editor, path));

          switch (
            sidePanelActionElement.panelType as MeetingPlanPanelContext['type']
          ) {
            case 'insights':
              setPanelContext({
                type: 'insights',
              });
              break;
            case 'contacts':
              setPanelContext({
                type: 'contacts',
              });
              break;
            case 'companies':
              setPanelContext({
                type: 'companies',
              });
              break;
            case 'plans':
              setPanelContext({
                type: 'plans',
              });
              break;
            case 'timeline':
              setPanelContext({
                type: 'timeline',
              });
              break;
            case 'chatbot':
              setPanelContext({
                type: 'chatbot',
              });
              break;
            case 'follow-up':
              setPanelContext({
                type: 'follow-up',
              });
              break;
            case 'email-followup':
              setPanelContext({
                type: 'email-followup',
              });
              break;
            case 'internal-summary':
              setPanelContext({
                type: 'internal-summary',
              });
              break;
            case 'invite-and-notify':
              setPanelContext({
                type: 'invite-and-notify',
              });
              break;
            case 'salesforce-browser': {
              if (configurationsWithToken.some((t) => t.app === 'SALESFORCE')) {
                setPanelContext({
                  type: 'salesforce-browser',
                });
              } else {
                createAddIntegrationDeferred({
                  displayOnly: 'SALESFORCE',
                  title: 'Add Salesforce',
                  description: (
                    <div
                      style={{
                        padding: '.5rem',
                      }}
                    >
                      <Text
                        variant="mediumPlus"
                        block
                        style={{
                          fontWeight: FontWeights.semibold,
                          color: MEETINGFLOW_COLORS.teal,
                          marginBottom: '.25rem',
                          paddingLeft: '1rem',
                        }}
                      >
                        Connecting your CRM is quick and easy.
                      </Text>
                      <Text
                        variant="medium"
                        block
                        style={{ maxWidth: '28rem', paddingLeft: '1rem' }}
                      >
                        Meetingflow works with Salesforce, allowing you to keep
                        your workspaces' source of truth update to date —
                        without losing your flow.
                      </Text>
                    </div>
                  ),
                })
                  .promise.then((result) => {
                    if (result?.app === 'SALESFORCE' && result?.added) {
                      setPanelContext({
                        type: 'salesforce-browser',
                      });
                    }
                  })
                  .catch((err) => {
                    console.info(`Failed to add new integration`);
                  });
              }
              break;
            }
            case 'hubspot-browser': {
              if (configurationsWithToken.some((t) => t.app === 'HUBSPOT')) {
                setPanelContext({
                  type: 'hubspot-browser',
                });
              } else {
                createAddIntegrationDeferred({
                  displayOnly: 'HUBSPOT',
                  title: 'Add HubSpot',
                  description: (
                    <div
                      style={{
                        padding: '.5rem',
                      }}
                    >
                      <Text
                        variant="mediumPlus"
                        block
                        style={{
                          fontWeight: FontWeights.semibold,
                          color: MEETINGFLOW_COLORS.teal,
                          marginBottom: '.25rem',
                          paddingLeft: '1rem',
                        }}
                      >
                        Connecting your CRM is quick and easy.
                      </Text>
                      <Text
                        variant="medium"
                        block
                        style={{ maxWidth: '28rem', paddingLeft: '1rem' }}
                      >
                        Meetingflow works with HubSpot, allowing you to keep
                        your workspaces' source of truth up to date — without
                        losing your flow.
                      </Text>
                    </div>
                  ),
                })
                  .promise.then((result) => {
                    if (result?.app === 'HUBSPOT' && result?.added) {
                      setPanelContext({
                        type: 'hubspot-browser',
                      });
                    }
                  })
                  .catch((err) => {
                    console.info(`Failed to add new integration`);
                  });
              }
              break;
            }
            case 'slack': {
              if (configurationsWithToken.some((t) => t.app === 'SLACK')) {
                setPanelContext({
                  type: 'slack',
                });
              } else {
                createAddIntegrationDeferred({
                  displayOnly: 'SLACK',
                  title: 'Add Slack',
                  description: (
                    <div
                      style={{
                        padding: '.5rem',
                      }}
                    >
                      <Text
                        variant="mediumPlus"
                        block
                        style={{
                          fontWeight: FontWeights.semibold,
                          color: MEETINGFLOW_COLORS.teal,
                          marginBottom: '.25rem',
                          paddingLeft: '1rem',
                        }}
                      >
                        Connecting Slack is quick and easy.
                      </Text>
                      <Text
                        variant="medium"
                        block
                        style={{ maxWidth: '28rem', paddingLeft: '1rem' }}
                      >
                        Meetingflow works with Slack, allowing you to keep your
                        colleagues updated — without losing your flow.
                      </Text>
                    </div>
                  ),
                })
                  .promise.then((result) => {
                    if (result?.app === 'SLACK' && result?.added) {
                      setPanelContext({
                        type: 'slack',
                      });
                    }
                  })
                  .catch((err) => {
                    console.info(`Failed to add new integration`);
                  });
              }
              break;
            }

            case 'ms-teams': {
              if (configurationsWithToken.some((t) => t.app === 'TEAMS')) {
                setPanelContext({
                  type: 'ms-teams',
                });
              } else {
                createAddIntegrationDeferred({
                  displayOnly: 'TEAMS',
                  title: 'Add Microsoft Teams',
                  description: (
                    <div
                      style={{
                        padding: '.5rem',
                      }}
                    >
                      <Text
                        variant="mediumPlus"
                        block
                        style={{
                          fontWeight: FontWeights.semibold,
                          color: MEETINGFLOW_COLORS.teal,
                          marginBottom: '.25rem',
                          paddingLeft: '1rem',
                        }}
                      >
                        Connecting Microsoft Teams is quick and easy.
                      </Text>
                      <Text
                        variant="medium"
                        block
                        style={{ maxWidth: '28rem', paddingLeft: '1rem' }}
                      >
                        Meetingflow works with Microsoft Teams, allowing you to
                        keep your colleagues updated — without losing your flow.
                      </Text>
                    </div>
                  ),
                })
                  .promise.then((result) => {
                    if (result?.app === 'TEAMS' && result?.added) {
                      setPanelContext({
                        type: 'ms-teams',
                      });
                    }
                  })
                  .catch((err) => {
                    console.info(`Failed to add new integration`);
                  });
              }
              break;
            }
            // @ts-ignore
            case 'crm-browser': {
              if (configurationsWithToken.some((t) => t.app === 'SALESFORCE')) {
                setPanelContext({
                  type: 'salesforce-browser',
                });
              } else if (
                configurationsWithToken.some((t) => t.app === 'HUBSPOT')
              ) {
                setPanelContext({
                  type: 'hubspot-browser',
                });
              } else {
                createAddIntegrationDeferred({
                  displayOnly: ['SALESFORCE', 'HUBSPOT'],
                  title: 'Add CRM Integration',
                  description: (
                    <div
                      style={{
                        padding: '.5rem',
                      }}
                    >
                      <Text
                        variant="mediumPlus"
                        block
                        style={{
                          fontWeight: FontWeights.semibold,
                          color: MEETINGFLOW_COLORS.teal,
                          marginBottom: '.25rem',
                          paddingLeft: '1rem',
                        }}
                      >
                        Connecting your CRM is quick and easy.
                      </Text>
                      <Text
                        variant="medium"
                        block
                        style={{ maxWidth: '28rem', paddingLeft: '1rem' }}
                      >
                        Meetingflow works with both Salesforce and HubSpot,
                        allowing you to keep your workspaces' source of truth
                        update to date — without losing your flow.
                      </Text>
                    </div>
                  ),
                })
                  .promise.then((result) => {
                    if (result?.app === 'SALESFORCE' && result?.added) {
                      setPanelContext({
                        type: 'salesforce-browser',
                      });
                    } else if (result?.app === 'HUBSPOT' && result?.added) {
                      setPanelContext({
                        type: 'hubspot-browser',
                      });
                    }
                  })
                  .catch((err) => {
                    console.info(`Failed to add new integration`);
                  });
              }
              break;
            }
            // @ts-ignore
            case 'chat': {
              if (configurationsWithToken.some((t) => t.app === 'SLACK')) {
                setPanelContext({
                  type: 'slack',
                });
              } else if (
                configurationsWithToken.some((t) => t.app === 'TEAMS')
              ) {
                setPanelContext({
                  type: 'ms-teams',
                });
              } else {
                createAddIntegrationDeferred({
                  displayOnly: ['SLACK', 'TEAMS'],
                  title: 'Add Chat Integration',
                  description: (
                    <div
                      style={{
                        padding: '.5rem',
                      }}
                    >
                      <Text
                        variant="mediumPlus"
                        block
                        style={{
                          fontWeight: FontWeights.semibold,
                          color: MEETINGFLOW_COLORS.teal,
                          marginBottom: '.25rem',
                          paddingLeft: '1rem',
                        }}
                      >
                        Connecting your messaging app is quick and easy.
                      </Text>
                      <Text
                        variant="medium"
                        block
                        style={{ maxWidth: '28rem', paddingLeft: '1rem' }}
                      >
                        Meetingflow works with both Slack and Microsoft Teams,
                        allowing you to keep your colleagues updated — without
                        losing your flow.
                      </Text>
                    </div>
                  ),
                })
                  .promise.then((result) => {
                    if (result?.app === 'SLACK' && result?.added) {
                      setPanelContext({
                        type: 'slack',
                      });
                    } else if (result?.app === 'TEAMS' && result?.added) {
                      setPanelContext({
                        type: 'ms-teams',
                      });
                    }
                  })
                  .catch((err) => {
                    console.info(`Failed to add new integration`);
                  });
              }
              break;
            }
            default: {
              console.warn(
                `Unknown panel type: ${sidePanelActionElement.panelType}`,
              );
            }
          }
        }}
      >
        <OpenPaneIcon contentEditable={false} className="shortcut-type-icon" />
        {children}
        <div className="panel-buttons-wrapper">
          <EditIcon
            contentEditable={false}
            data-panel-button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              const path = ReactEditor.findPath(editor, element);

              Transforms.select(editor, Editor.end(editor, path));

              const currentText = Editor.string(editor, path);

              getSidePanelShortcutContext({
                text: currentText,
                sidePanel:
                  sidePanelActionElement.panelType as MeetingPlanPanelContext['type'],
              }).promise.then((context) => {
                if (
                  context.text !== currentText ||
                  context.sidePanel !== sidePanelActionElement.panelType
                ) {
                  Editor.withoutNormalizing(editor, () => {
                    if (
                      context.sidePanel !== sidePanelActionElement.panelType
                    ) {
                      Transforms.setNodes<SlateSidePanelShortcut>(
                        editor,
                        {
                          panelType: context.sidePanel,
                        },
                        { at: path },
                      );
                    }

                    if (context.text !== currentText) {
                      Transforms.select(editor, path);
                      Transforms.insertText(editor, context.text);
                    }
                  });
                }
              });
            }}
          />

          <StatusCircleErrorXIcon
            contentEditable={false}
            data-panel-button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              const path = ReactEditor.findPath(editor, element);

              unwrapNodes(editor, 'side-panel-shortcut', { at: path });
            }}
          />
        </div>
      </div>
    </div>
  );
};
