import { useEffect, useMemo, useState } from 'react';
import {
  CheckboxVisibility,
  FontWeights,
  IColumn,
  IDialogProps,
  mergeStyles,
  NeutralColors,
  Selection,
  SelectionMode,
  Spinner,
  Text,
  useTheme,
} from '@fluentui/react';
import { StyledDetailsList } from '../../StyledDetailsList';
import { useQuery } from 'react-query';
import { MeetingPlanShadowEventQuery } from '../../../QueryNames';
import { useAuth0 } from '@auth0/auth0-react';
import { ApiClient } from '../../../Services/NetworkCommon';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { useUserProfile } from '../../../Hooks/useProfile';
import { PersonaWithTooltip } from '../../PersonaWithTooltip';
import { orderBy } from 'lodash';
import TokenInput from 'react-customize-token-input';
import 'react-customize-token-input/dist/react-customize-token-input.css';
import { AsyncPrimaryButton } from '../../HOC/AsyncButton';
import { inflect } from 'inflection';
import toast from 'react-hot-toast';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useForceUpdate } from '@fluentui/react-hooks';
import {
  CalendarEvent,
  Contact,
  DetailedMeetingflow,
} from '@meetingflow/common/Api/data-contracts';

type PersonWithKey = Contact & {
  key: string;
  sharedAt?: string;
};

const SHOW_EXTERNAL_ATTENDEES = true;

const ShowEmptyGroupsGroupProps = {
  showEmptyGroups: false,
};

const getItemKey = <T extends { key: string }>(
  item: T,
  index?: number | undefined,
): string => item.key;

type UseShadowEventAttendeesDialogStateProps = {
  organizationSlug: string;
  meetingPlanId: string;
  meetingPlan: Pick<DetailedMeetingflow, 'attendees'>;
  internalDomains: string[];
};

export const useShadowEventAttendeesDialogState = ({
  organizationSlug,
  meetingPlanId,
  meetingPlan,
  internalDomains,
}: UseShadowEventAttendeesDialogStateProps) => {
  const forceUpdate = useForceUpdate();
  const { getAccessTokenSilently } = useAuth0();
  const { user: userProfile } = useUserProfile();

  const [enteredEmails, setEnteredEmails] = useState<string[]>([]);

  const selection = useMemo(() => {
    return new Selection({
      selectionMode: SelectionMode.multiple,
      onSelectionChanged: forceUpdate,
    });
  }, [forceUpdate]);

  const [attendees, setAttendees] = useState<PersonWithKey[]>(
    meetingPlan.attendees.map((attendee) => {
      return {
        key: attendee.email,
        ...attendee,
      };
    }),
  );

  const { data: shadowEventData, isLoading: shadowEventIsLoading } = useQuery(
    MeetingPlanShadowEventQuery(organizationSlug, meetingPlanId),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<Omit<CalendarEvent, 'companies'> | undefined>(
        `/organization/${organizationSlug}/plan/${meetingPlanId}/shadow-invite`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    { cacheTime: 0 },
  );

  useEffect(() => {
    if (!shadowEventData?.data) {
      return;
    }

    const merged: Record<string, PersonWithKey> = Object.fromEntries([
      ...attendees.map((attendee) => [attendee.email, attendee]),
      ...(shadowEventData.data?.attendees?.map((attendee) => [
        attendee.email,
        { key: attendee.email, ...attendee },
      ]) || []),
    ] as [string, PersonWithKey][]);

    const newAttendees = Object.values(merged);

    let updatedSelection = newAttendees;
    if (!SHOW_EXTERNAL_ATTENDEES) {
      updatedSelection = newAttendees.filter((attendee) => {
        return internalDomains.includes(attendee.emailDomain);
      });
    }
    setAttendees(newAttendees);
    selection.setItems(updatedSelection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shadowEventData?.data, userProfile]);

  return {
    enteredEmails,
    setEnteredEmails,
    selection,
    attendees,
    setAttendees,
    shadowEventData,
    shadowEventIsLoading,
  };
};

export type ShadowEventAttendeesDialogProps = {
  organizationSlug: string;
  meetingPlanId: string;
  meetingPlan: Pick<DetailedMeetingflow, 'companies'>;
  internalDomains: string[];
  onConfirm: () => void;
  onCancel: () => void;
};

export const ShadowEventAttendeesDialog = ({
  organizationSlug,
  meetingPlanId,
  meetingPlan,
  internalDomains,
  onConfirm,
  onCancel,
  enteredEmails,
  setEnteredEmails,
  selection,
  attendees,
  setAttendees,
  shadowEventData,
  shadowEventIsLoading,
  ...rest
}: IDialogProps &
  ShadowEventAttendeesDialogProps &
  ReturnType<typeof useShadowEventAttendeesDialogState>) => {
  const { getAccessTokenSilently } = useAuth0();
  const { isDark } = useLightOrDarkMode();
  const theme = useTheme();

  const appInsights = useAppInsightsContext();

  const columns: IColumn[] = [
    {
      key: 'company',
      name: 'Company',
      minWidth: 32,
      maxWidth: 32,
      fieldName: 'company',
      onRender: (person: PersonWithKey) => {
        const company = meetingPlan.companies.find((c) =>
          c?.domains
            ?.map((domain) => domain.domain)
            .includes(person.emailDomain),
        );
        return (
          <>
            {company ? (
              <PersonaWithTooltip
                imageUrl={company?.logo || company?.twitter_avatar || undefined}
                organizationSlug={organizationSlug}
                persona={{
                  data: {
                    company: company,
                  },
                  imageUrl:
                    company?.logo || company?.twitter_avatar || undefined,

                  personaName:
                    company?.name ||
                    company?.legalName ||
                    company?.domains?.[0].domain,
                }}
                isCompany
              />
            ) : null}
          </>
        );
      },
    },
    {
      key: 'email',
      name: 'Email',
      minWidth: 100,
      maxWidth: 200,
      fieldName: 'email',
      onRender: (person: PersonWithKey) => (
        <div style={{ position: 'relative', top: '-.5rem' }}>
          {person.name ? (
            <Text
              block
              style={{
                fontWeight: FontWeights.semibold,
                lineHeight: '1.75rem',
              }}
            >
              {person.name}
            </Text>
          ) : null}
          <Text
            block
            style={{
              lineHeight: person?.name ? '.5rem' : '1.75rem',
              paddingTop: person?.name ? undefined : '.5rem',
              fontWeight: person?.name
                ? FontWeights.regular
                : FontWeights.semibold,
            }}
          >
            {person.email}
          </Text>
        </div>
      ),
    },
  ];

  const internalInvitees = attendees.filter((a) =>
    internalDomains.includes(a.emailDomain),
  );

  const externalInvitees = attendees.filter(
    (a) => !internalDomains.includes(a.emailDomain),
  );

  const items = SHOW_EXTERNAL_ATTENDEES
    ? [
        ...orderBy(internalInvitees, ['emailDomain']),
        ...orderBy(externalInvitees, ['emailDomain']),
      ]
    : orderBy(internalInvitees, ['emailDomain']);

  const groups = SHOW_EXTERNAL_ATTENDEES
    ? [
        {
          key: 'internal',
          name: 'Internal Attendees',
          startIndex: 0,
          count: internalInvitees.length,
          level: 0,
          isCollapsed: false,
        },
        {
          key: 'external',
          name: 'External Attendees',
          startIndex: internalInvitees.length,
          count: externalInvitees.length,
          level: 0,
          isCollapsed: false,
        },
      ]
    : undefined;

  const getNewlyselectedInvitees = () => {
    const selected = selection.getSelection() as PersonWithKey[];
    const selectedAndEntered = [
      ...selected,
      ...enteredEmails.map(
        (e) => ({ name: '', email: e, key: e } as PersonWithKey),
      ),
    ];
    return selectedAndEntered;
  };

  const newlySelectedInvitees = getNewlyselectedInvitees();

  if (shadowEventIsLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div style={{ margin: '1rem 0' }}>
        <Text
          block
          variant="mediumPlus"
          style={{ fontWeight: FontWeights.semibold, margin: '0 0 .5rem 0' }}
        >
          Select attendees:
        </Text>
        <StyledDetailsList
          items={items}
          groups={groups}
          columns={columns}
          selectionMode={SelectionMode.multiple}
          selection={selection}
          checkboxVisibility={CheckboxVisibility.always}
          getKey={getItemKey}
          setKey="multiple"
          isHeaderVisible={false}
          compact
          groupProps={ShowEmptyGroupsGroupProps}
          noDataMessage={`There are no other ${
            SHOW_EXTERNAL_ATTENDEES ? '' : 'internal'
          } attendees to create a prep meeting with.`}
        />
        <Text
          block
          variant="mediumPlus"
          style={{ fontWeight: FontWeights.semibold, margin: '1rem 0 .5rem 0' }}
        >
          And/or enter email addresses:
        </Text>
        <div
          className={mergeStyles({
            '.token-input-container': {
              backgroundColor: 'transparent !important',
              color: isDark ? 'white !important' : undefined,
              height: '5rem',
              marginBottom: '1rem',
              '.token-input-autosized-wrapper': {
                padding: '0 .5rem !important',
                color: isDark ? 'white !important' : undefined,
                input: {
                  color: isDark ? 'white !important' : undefined,
                },
              },
            },
            '.token-input-token': {
              height: '1.5rem !important',
              lineHeight: '1.5 !important',
              backgroundColor: `${
                isDark ? NeutralColors.gray140 : NeutralColors.gray20
              } !important`,
              color: `${
                isDark ? NeutralColors.gray20 : NeutralColors.gray120
              } !important`,
            },
          })}
        >
          <TokenInput
            tokenValues={enteredEmails}
            onTokenValuesChange={setEnteredEmails}
            placeholder="Enter email addresses, separated by commas..."
            separators={[',', ' ']}
          />
          <div
            style={{
              color: isDark ? NeutralColors.gray160 : NeutralColors.gray200,
              backgroundColor: theme.semanticColors.warningBackground,
              padding: '.5rem',
              display: newlySelectedInvitees.length ? 'block' : 'none',
              marginBottom: '1rem',
              borderRadius: '.5rem',
            }}
          >
            <Text
              block
              variant="mediumPlus"
              style={{
                fontWeight: FontWeights.semibold,
                color: NeutralColors.gray200,
              }}
            >
              Creating prep meeting with:{'\u00A0'}
            </Text>
            <Text style={{ color: NeutralColors.gray200 }}>
              {newlySelectedInvitees.map((i, idx) => {
                return (
                  <>
                    {i.email}
                    {idx !== newlySelectedInvitees.length - 1 ? ', ' : null}
                  </>
                );
              })}
            </Text>
          </div>
        </div>
      </div>
      <div style={{ textAlign: 'right' }}>
        <AsyncPrimaryButton
          text="Create prep meeting"
          onClick={async () => {
            const selected = selection.getSelection() as PersonWithKey[];
            const selectedAndEntered = [
              ...selected,
              ...enteredEmails.map(
                (e) => ({ name: '', email: e, key: e } as PersonWithKey),
              ),
            ];
            if (!selectedAndEntered.length) {
              return;
            }

            try {
              const token = await getAccessTokenSilently();

              await toast.promise(
                ApiClient.put(
                  `/organization/${organizationSlug}/plan/${meetingPlanId}/shadow-invite`,
                  {
                    attendees: selectedAndEntered.map((t) => ({
                      name: t.name || undefined,
                      email: t.email,
                    })),
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                    validateStatus: (status) => [200, 201].includes(status),
                  },
                ),
                {
                  loading: 'Creating prep meeting',
                  success: (result) => {
                    appInsights.trackEvent({
                      name:
                        result.status === 201
                          ? 'CREATE_PREP_MEETING'
                          : 'UPDATE_PREP_MEETING',
                      properties: {
                        organizationSlug,
                        meetingPlanId,
                        attendeeCount: selectedAndEntered.length,
                      },
                    });

                    return `Invited ${selectedAndEntered.length} ${inflect(
                      'person',
                      selectedAndEntered.length,
                    )} to a prep meeting!`;
                  },
                  error: (err) => {
                    return `Something went wrong sharing Meetingflow, please try again`;
                  },
                },
              );

              onConfirm();
            } catch {}
          }}
          styles={{
            label: { color: isDark ? 'white' : undefined },
          }}
        />
      </div>
    </>
  );
};

export default ShadowEventAttendeesDialog;
