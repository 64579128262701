import React, { useState } from 'react';

import { useQuery } from 'react-query';
import {
  FontWeights,
  mergeStyles,
  Persona,
  PersonaSize,
  PrimaryButton,
  TextField,
  ITag,
  Icon,
} from '@fluentui/react';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';

import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { SendInviteIllustrations } from './UserSettingsIllustrations';
import { DealRoomContactsQuery } from '../../../../QueryNames';
import { DealRoomsApiClient } from '../../../../Services/NetworkCommon';
import { useDealRoom } from '../../../../Hooks/useDealRoom';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { useUserProfile } from '../../../../Hooks/useProfile';
import useDeviceType from '../../../../Hooks/deviceDetection';

interface UserSettingsInviteProps {
  isSaving: boolean;
  organizationSlug: string;
  setIsSaving: (isSaving: boolean) => void;
}

// List of common non-work email domains
const NON_WORK_DOMAINS = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'outlook.com',
  'aol.com',
  'icloud.com',
  'mail.com',
  'protonmail.com',
  'zoho.com',
  'yandex.com',
];

const emailPattern = /^[a-zA-Z0-9._%+-]{2,}@[a-zA-Z0-9.-]{2,}\.[a-zA-Z]{2,}$/;

export const UserSettingsInvite = ({
  isSaving,
  setIsSaving,
  organizationSlug,
}: UserSettingsInviteProps) => {
  const { isMobile } = useDeviceType();

  const { getAccessTokenSilently } = useAuth0();

  const [emailsToInvite, setEmailsToInvite] = useState<ITag[]>([]);
  const [currentInput, setCurrentInput] = useState<string>('');
  const [inviteMessage, setInviteMessage] = useState<string>('');

  const { dealRoomId, dealRoomRole, dealRoom, refetch } = useDealRoom();
  const { internalDomains, role: orgRole } = useOrganization();
  const { user } = useUserProfile();
  const currentUserEmailDomain = user?.email.split('@')[1].toLowerCase() || '';
  const currentUserIsDealRoomOwner = dealRoom?.owner?.id === user?.id;
  const currentUserIsSeller =
    dealRoomRole === 'SELLER' ||
    currentUserIsDealRoomOwner ||
    (internalDomains.includes(currentUserEmailDomain) &&
      ['ADMIN', 'CREATOR', 'COLLABORATOR'].includes(orgRole || ''));

  const emailToInviteIsSeller = emailsToInvite.some(
    (email) =>
      internalDomains.includes(email.name.split('@')[1].toLowerCase()) &&
      ['ADMIN', 'CREATOR', 'COLLABORATOR'].includes(orgRole || ''),
  );

  const { data: dealRoomContactsData, refetch: refetchContacts } = useQuery(
    DealRoomContactsQuery(organizationSlug!, dealRoomId!),
    async () => {
      const token = await getAccessTokenSilently();
      return DealRoomsApiClient.listDealRoomContacts(
        organizationSlug,
        dealRoomId!,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
  );

  const isEmailValid = (email: string) => {
    if (!email) return false;
    if (!emailPattern.test(email)) return false;
    const domain = email.split('@')[1].toLowerCase();
    return !NON_WORK_DOMAINS.includes(domain);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === ',' || event.key === ' ') {
      event.preventDefault();
      const email = currentInput.trim();
      if (email && isEmailValid(email)) {
        setEmailsToInvite([...emailsToInvite, { key: email, name: email }]);
        setCurrentInput('');
      } else if (email) {
        toast.error('Please enter a valid work email address');
      }
    } else if (
      event.key === 'Backspace' &&
      !currentInput &&
      emailsToInvite.length > 0
    ) {
      setEmailsToInvite(emailsToInvite.slice(0, -1));
    }
  };

  const handleEmailRemove = (emailToRemove: ITag) => {
    setEmailsToInvite(
      emailsToInvite.filter((email) => email.key !== emailToRemove.key),
    );
  };

  const handleEachInvite = async (inviteEmail: string) => {
    const token = await getAccessTokenSilently();
    try {
      const result = await DealRoomsApiClient.addContactToDealRoom(
        organizationSlug,
        dealRoomId!,
        inviteEmail,
        {
          role: emailToInviteIsSeller ? 'SELLER' : 'BUYER',
          // @ts-ignore
          message: inviteMessage,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      if (result.status === 200) {
        // New users invited
        toast.success(
          `${inviteEmail} have been invited to Decision Site ${dealRoomId} in '${organizationSlug}'.`,
        );
      }
      // Refresh the list of contacts
      refetch();
      refetchContacts();
    } catch (e) {
      toast.error(`Something went wrong inviting ${inviteEmail}.`);
    }
  };

  const handleInvite = () => {
    const domains = emailsToInvite.map((email) =>
      email.name.split('@')[1].toLowerCase(),
    );
    if (
      domains.some((emailDomain) => NON_WORK_DOMAINS.includes(emailDomain)) ||
      NON_WORK_DOMAINS.includes(currentInput)
    ) {
      toast.error('Please enter valid work email addresses.');
      return;
    }

    const emails = emailsToInvite.map((email) => email.name);
    emails.push(currentInput);

    for (const emailToInvite of emails) {
      handleEachInvite(emailToInvite);
    }
    // reset invited emails
    setCurrentInput('');
    setEmailsToInvite([]);
  };

  const handleDeleteContact = async (contactId: string) => {
    const token = await getAccessTokenSilently();
    try {
      const result = await DealRoomsApiClient.removeContactFromDealRoom(
        organizationSlug,
        dealRoomId!,
        contactId,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      if (result.status === 204) {
        // Contact removed
        toast.success(`Contact has been removed.`);
      }

      // Refresh the list of contacts
      refetch();
    } catch (e) {
      toast.error(`Something went wrong removing the contact.`);
    }
  };

  const inviteStyle = mergeStyles({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: isMobile ? '1.5rem' : '0',
    flex: '1',
    '.invite-content': {
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      gap: '1.5rem',
      '.invite_someone_container': {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        justifyContent: 'space-between',
        alignItems: 'start',
        position: 'relative',
        i: {
          position: 'absolute',
          top: '0.35rem',
          left: '.5rem',
          zIndex: '1',
          fontSize: '1rem',
        },
        '.invite_someone_message': {
          width: '100%',
          label: {
            fontSize: '0.8rem',
            fontWeight: 200,
            '::after': {
              color: DEALROOMS_COLORS.darkerRed,
            },
          },
          textarea: {
            borderRadius: '0.25rem',
            backgroundColor: DEALROOMS_COLORS.inputLightGray,
            padding: '0.5rem 0.5rem 0.5rem 2rem',
          },
          '*': {
            border: '0',
            outline: 'none',
          },
          i: {
            position: 'absolute',
            top: '0.35rem',
            left: '.5rem',
            zIndex: '1',
          },
        },
        '.invite_someone_access_level': {
          width: '10rem',
          '>div:nth-child(1)': {
            border: '0',
            outline: '0',
            ':hover': {},
            ':focus-visible': {
              ':after': {
                border: '1px solid ' + DEALROOMS_COLORS.sidebarTextSecondary,
                outline: 'none',
              },
            },
          },
          '>div:nth-child(1):after': {
            border: '0',
            outline: '0',
          },
          'span:nth-child(1)': {
            border: '0',
            outline: '0',
            color: DEALROOMS_COLORS.darkerGray,
            backgroundColor: DEALROOMS_COLORS.inputLightGray,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            borderRadius: '0.25rem',
            svg: {
              margin: '0 0.3rem 0 0',
            },
          },
        },
        '.invite_someone_btn': {
          display: 'flex',
          width: 'content',
          height: 'content',
          padding: '0.3rem 1rem',
          borderRadius: '0.25rem',
          backgroundColor: DEALROOMS_COLORS.userSurveyPrimary,
          svg: {
            margin: '0 0.3rem 0 0',
          },
          ':disabled': {
            backgroundColor: DEALROOMS_COLORS.sidebarTextSecondary,
          },
        },
      },
      '.people-access-label': {
        fontSize: '1rem',
      },
      '.people-grid-container': {
        backgroundColor: DEALROOMS_COLORS.white,
        display: 'grid',
        gridTemplateColumns: currentUserIsSeller
          ? '1fr 1fr 1fr 1fr'
          : '1fr 1fr 1fr',
        border: `1px solid${DEALROOMS_COLORS.neutralLight}`,
        borderRadius: '0.25rem',
        padding: '0.4rem 0.6rem',
        '.header': {
          color: DEALROOMS_COLORS.themeSecondary,
          fontSize: '0.8rem',
          fontWeight: FontWeights.regular,
          borderBottom: `1px solid${DEALROOMS_COLORS.neutralLight}`,
          padding: '0.5rem',
          textAlign: 'left',
        },
        '.item': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '0.5rem',
          color: DEALROOMS_COLORS.themePrimary,
          fontSize: '0.9rem',
          fontWeight: FontWeights.regular,
          padding: '0.5rem',
          textAlign: 'left',
          borderBottom: `1px solid${DEALROOMS_COLORS.neutralLight}`,
          ':nth-last-child(-n+4)': {
            borderBottom: '0',
          },

          '.invited-name': {
            color: DEALROOMS_COLORS.themePrimary,
            fontSize: '0.8rem',
            fontWeight: FontWeights.regular,
            span: {
              display: 'block',
              color: DEALROOMS_COLORS.darkerRed,
            },
          },
        },
      },
    },
  });

  const emailInputStyles = mergeStyles({
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.25rem',
    padding: '0.25rem 0.25rem 0.25rem 1.8rem',
    minHeight: '2rem',
    backgroundColor: DEALROOMS_COLORS.inputLightGray,
    borderRadius: '0.25rem',
    cursor: 'text',
    width: 'calc(100% - 2.05rem)',
    '.email-token': {
      display: 'inline-flex',
      alignItems: 'center',
      backgroundColor: DEALROOMS_COLORS.cloudburst,
      color: 'white',
      padding: '.125rem 0.25rem .125rem .5rem',
      borderRadius: '.25rem',
      margin: '0.125rem',
      fontSize: '0.875rem',
      height: '.875rem',
      '.close-icon': {
        position: 'relative !important',
        marginLeft: '0.375rem',
        cursor: 'pointer',
        height: '1rem',
        width: '1rem',
        display: 'flex',
        alignItems: 'center',
        top: '0 !important',
        left: '0 !important',
        ':hover': {
          opacity: 0.8,
        },
      },
      i: {
        // top: '0.55rem !important',
        fontSize: '0.75rem !important',
      },
    },
    input: {
      flex: '1 1 auto',
      border: 'none',
      outline: 'none',
      backgroundColor: 'transparent',
      minWidth: '7.5rem',
      height: '1.5rem',
      padding: '0 0.2rem',
    },
  });

  return (
    <div className={inviteStyle}>
      <div className="invite-content">
        <div className="people-access-label">Invite Someone</div>
        <div className="invite_someone_container">
          <div
            className={emailInputStyles}
            onClick={() => document.getElementById('email-input')?.focus()}
          >
            <Icon iconName="Mail" />
            {emailsToInvite.map((email) => (
              <div key={email.key} className="email-token">
                {email.name}
                <Icon
                  iconName="Cancel"
                  className="close-icon"
                  onClick={() => handleEmailRemove(email)}
                />
              </div>
            ))}
            <input
              id="email-input"
              type="text"
              placeholder={
                emailsToInvite.length === 0
                  ? 'Enter work email addresses (separate by space, comma, or Enter)'
                  : ''
              }
              value={currentInput}
              onChange={(e) => setCurrentInput(e.target.value)}
              onKeyDown={handleKeyDown}
              disabled={isSaving}
            />
          </div>

          <TextField
            className="invite_someone_message"
            placeholder="Optional message"
            value={inviteMessage}
            onChange={(event, newValue) => setInviteMessage(newValue || '')}
            disabled={isSaving}
            multiline
            iconProps={{ iconName: 'Message' }}
          />

          <PrimaryButton
            className="invite_someone_btn"
            onClick={handleInvite}
            disabled={
              // if there are no emails in the list and no text in the input, or
              // if there are emails in the list but not all of them are valid, or
              // if there is text in the input but it is not a valid email
              (!emailsToInvite.length && !currentInput) ||
              (!!emailsToInvite.length &&
                !emailsToInvite.every((email) => isEmailValid(email.name))) ||
              (!!currentInput && !isEmailValid(currentInput))
            }
          >
            <SendInviteIllustrations />
            Invite
          </PrimaryButton>
        </div>
        <div className="people-access-label">People with Access</div>
        <div className="people-grid-container">
          <div className="header">Name</div>
          <div className="header">Email</div>
          <div className="header">Role</div>
          {currentUserIsSeller ? <div className="header">Actions</div> : null}

          {dealRoomContactsData?.data.map((contact) => {
            const contactIsAUser = dealRoom?.contacts.some(
              (c) => c.id === contact.id && c.userId,
            );
            return (
              <React.Fragment key={`person_with_access_${contact.id}`}>
                <div className="item">
                  <Persona
                    {...{
                      text: contact?.name || undefined,
                    }}
                    size={PersonaSize.size24}
                    hidePersonaDetails={true}
                  />
                  {contact?.name && (
                    <div className="invited-name">
                      {contact.name}{' '}
                      <span>{!contactIsAUser ? 'Pending' : ''}</span>
                    </div>
                  )}
                </div>
                <div className="item">{contact?.email}</div>
                <div className="item">
                  {internalDomains.includes(
                    contact?.email.split('@')[1].toLowerCase(),
                  )
                    ? 'SELLER'
                    : contact?.role || 'BUYER'}
                </div>
                {currentUserIsSeller && contact.id && !contact.isOwner ? (
                  <div className="item">
                    <PrimaryButton
                      className="invite_someone_btn"
                      onClick={
                        contact.id
                          ? () => handleDeleteContact(contact.id!.toString())
                          : undefined
                      }
                    >
                      Remove
                    </PrimaryButton>
                  </div>
                ) : (
                  contact.isOwner && <div className="item">Owner</div>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};
