import { styled } from '@mui/material';

export const DSTagPickerRoot = styled('div')(({ theme }) => `
  // Add custom styles here
`);


export const DSTagPickerMenuItemRoot = styled('li') (({ theme }) => `
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  padding: 8px 16px !important;

  strong {
    display: block;
    font-weight: 500;
    color: ${theme.palette.text.primary};
  }
  
  span {
    display: block;
    color: ${theme.palette.text.secondary};
    font-size: 0.875rem;
  }
`);