import { getTheme, mergeStyleSets } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

export const getStyles = () => {
  const theme = getTheme();
  return mergeStyleSets({
    wrapper: {
      display: 'flex',
      height: '100%',
    },
    viewContainer: {
      padding: '2rem',
      width: 'calc(100% - 4rem)',
      height: 'calc(100vh - 9.75rem)',
      backgroundColor: DEALROOMS_COLORS.neutralLighter,
    },
    splitViewRootLayout: {
      columnGap: '1rem',
    },
    pivotContainer: {
      marginTop: '1rem',
      padding: '0 1rem',
      borderBottom: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
      '[role=tablist]': {
        whiteSpace: 'initial',
      },
      '.ms-Pivot-text': {
        fontSize: '15px',
        fontWeight: '400',
        color: DEALROOMS_COLORS.neutralDarker,
      },
      '.ms-Pivot-link.is-selected::before': {
        backgroundColor: DEALROOMS_COLORS.neutralDark,
        borderRadius: '999px',
      },
      '.ms-Pivot-link.is-selected .ms-Pivot-text': {
        fontWeight: '500',
        color: DEALROOMS_COLORS.darkerGray,
      },
    },
    sidebarRootLayout: {
      borderRadius: '4px',
      backgroundColor: DEALROOMS_COLORS.white,
      width: 'calc(100% - 2px) !important',
      height: 'calc(100% - 2px) !important',
      minHeight: '35rem',
      border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
      boxShadow: '0 2px 2px 0 #00000005',
      '.backToConvsButton': {
        border: 'none',
        padding: '0.8rem 0.75rem;',
        i: {
          margin: '0 1rem 0 0',
        },
        ':hover': {
          color: 'inherit',
          i: {
            color: 'inherit',
          },
        },
      },
      '.backToConvsContainer': {},
    },
    content: [
      theme.fonts.medium,
      {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        minHeight: '30rem',
        backgroundColor: DEALROOMS_COLORS.white,
        borderRadius: '4px',
        border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
        boxShadow: '0 2px 2px 0 #00000005',
        flex: 'auto 1',

        '.content-title': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          gap: '0.5rem',
          padding: '1.25rem 1.5rem',
          '.icon': {
            marginRight: '6px',
            fontSize: '18px',
            color: DEALROOMS_COLORS.neutralDark,
          },
          borderBottom: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
        },
      },
    ],
    chat: {
      flex: '0 0 300px',
      borderLeft: '1px solid #ccc',
      padding: '20px',
    },
    '.title': {
      fontSize: '40px',
    },
  });
};
