export const AddFileIllustration = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 4V8.5C12.5 9.32843 13.1716 10 14 10H18.5V18.5C18.5 19.3284 17.8284 20 17 20H12.2428C13.0282 19.0491 13.5 17.8296 13.5 16.5C13.5 13.4624 11.0376 11 8 11C7.47999 11 6.97683 11.0722 6.5 11.207V5.5C6.5 4.67157 7.17157 4 8 4H12.5ZM13.5 4.25V8.5C13.5 8.77614 13.7239 9 14 9H18.25L13.5 4.25ZM12.5 16.5C12.5 18.9853 10.4853 21 8 21C5.51472 21 3.5 18.9853 3.5 16.5C3.5 14.0147 5.51472 12 8 12C10.4853 12 12.5 14.0147 12.5 16.5ZM8.5 14.5C8.5 14.2239 8.27614 14 8 14C7.72386 14 7.5 14.2239 7.5 14.5V16H6C5.72386 16 5.5 16.2239 5.5 16.5C5.5 16.7761 5.72386 17 6 17H7.5L7.5 18.5C7.5 18.7761 7.72386 19 8 19C8.27614 19 8.5 18.7761 8.5 18.5V17H10C10.2761 17 10.5 16.7761 10.5 16.5C10.5 16.2239 10.2761 16 10 16H8.5V14.5Z"
      fill="#D7D7D8"
    />
  </svg>
);
