import { useAuth0 } from '@auth0/auth0-react';
import {
  BaseButton,
  Button,
  FontIcon,
  FontSizes,
  FontWeights,
  Icon,
  Spinner,
  SpinnerSize,
  Text,
  mergeStyles,
} from '@fluentui/react';
import {
  CallRecordingStatus,
  Contact,
  DetailedMeetingflow,
  ExternalServiceObject,
  ExternalServiceObjectType,
} from '@meetingflow/common/Api/data-contracts';
import { titleCase } from '@meetingflow/common/StringHelpers';
import { isGenerateSummarySuggestion } from '@meetingflow/common/TypeHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { DateTime, Duration } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { RefetchOptions, RefetchQueryFilters, useQuery } from 'react-query';
import { Editor as SlateEditor } from 'slate';
import { HubSpotPlanContext } from '../../../Models/HubSpot/HubSpotPlanContext';
import { SalesforcePlanContext } from '../../../Models/Salesforce/SalesforcePlanContext';
import StyledDateTime from '../../StyledDateTime';
import useSummarizeMeetingflowDialog from '../../../Hooks/Modals/useSummarizeMeetingflowDialog';
import { useExternalServiceConfigurations } from '../../../Hooks/useExternalServiceConfigurations';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { useOrganization } from '../../../Hooks/useOrganization';
import { MeetingPlanSuggestedPromptsQuery } from '../../../QueryNames';
import { MeetingflowsApiClient } from '../../../Services/NetworkCommon';
import { DEALROOMS_COLORS, MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { PresenceState } from '../../../types/MeetingPlanDocument';
import { MeetingPlanPanelContext } from '../../../types/MeetingPlanPanelContext';
import { SalesforcePanelContext } from '../../../types/SalesforcePanelContext';
import { GPTChatBoxContext } from '../../GPT/GPTChatBoxContext';
import { MeetingPlanAttendees } from '../MeetingPlanAttendees';
import { HubSpotInsightsPanelContent } from './HubSpot/HubSpotInsightsPanelContent';
import { RelatedDealRoomsList } from './RelatedDealRoomsList';
import { MeetingPlanRelatedPlanReaderList } from './RelatedPlansList';
import { SalesforceInsightsPanelContent } from './Salesforce/SalesforceInsightsPanelContent';
import { DecisionSiteBaseSidePanel } from './DecisionSiteBaseSidePanel';
import { DecisionSiteMeetingPlanAttendees } from '../DecisionSiteMeetingPlanAttendees';

const MAX_ARTICLES_PER_COMPANY = 1;
const MAX_ATTENDEES = 3;

export type DecisionSiteInsightsSidePanelProps = {
  color: string;
  organizationSlug: string;
  meetingflowId: string;
  internalDomains: string[];
  externalDomains: string[];
  externalContactIds: number[];
  onRelatedPlansViewAllClick: (
    event: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | HTMLSpanElement
      | BaseButton
      | Button,
      MouseEvent
    >,
  ) => void;
  onContactsViewAllClick: (
    event: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | HTMLSpanElement
      | BaseButton
      | Button,
      MouseEvent
    >,
  ) => void;
  onMeetingflowClick: (meetingflowId: DetailedMeetingflow['id']) => void;
  onContactClick?: (c: Contact['id']) => void;
  onCompanyClick: (id: number) => void;
  meetingflow: Pick<
    DetailedMeetingflow,
    | 'id'
    | 'externalId'
    | 'startTime'
    | 'endTime'
    | 'title'
    | 'creator'
    | 'organizer'
    | 'attendees'
    | 'companies'
    | 'associations'
    | 'viewStats'
    | 'externalSeriesId'
    | 'callRecording'
    | 'lastLoggedToCrm'
  >;
  callRecordingStatus?: CallRecordingStatus;
  onBack?: () => void;
  onClose: () => void;
  awarenessStates?: Map<number, PresenceState>;
  onPickedSalesforceObject: (
    object: ExternalServiceObject | Omit<ExternalServiceObject, 'id'>,
  ) => void;
  onPickedHubSpotObject: (
    object: ExternalServiceObject | Omit<ExternalServiceObject, 'id'>,
  ) => void;
  onPinnedSalesforceObject:
    | ((e?: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<unknown>)
    | undefined;
  onPinnedHubSpotObject:
    | ((e?: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<unknown>)
    | undefined;
  pushSalesforcePanel: (context: SalesforcePanelContext) => void;
  pushHubSpotPanel: (context: SalesforcePanelContext) => void;
  showSalesforceObjectPicker: (
    defaultTab?: ExternalServiceObjectType | 'PINNED',
  ) => void;
  showHubSpotObjectPicker: (
    defaultTab?: ExternalServiceObjectType | 'PINNED',
  ) => void;
  salesforcePlanContext?: SalesforcePlanContext;
  hubspotPlanContext?: HubSpotPlanContext;
  pushPanelContext: (context: MeetingPlanPanelContext) => void;
  setChatboxContext: (context: GPTChatBoxContext | undefined) => unknown;
  refetchMeetingflow: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<unknown>;
  editor?: SlateEditor;
};
export const DecisionSiteInsightsSidePanel = ({
  color,
  organizationSlug,
  meetingflowId,
  meetingflow,
  callRecordingStatus,
  onBack,
  onClose,
  onMeetingflowClick,
  onRelatedPlansViewAllClick,
  onCompanyClick,
  onContactClick,
  onContactsViewAllClick,
  internalDomains,
  externalDomains,
  externalContactIds,
  awarenessStates,
  onPickedSalesforceObject,
  onPickedHubSpotObject,
  onPinnedSalesforceObject,
  onPinnedHubSpotObject,
  pushSalesforcePanel,
  pushHubSpotPanel,
  showSalesforceObjectPicker,
  showHubSpotObjectPicker,
  salesforcePlanContext,
  hubspotPlanContext,
  pushPanelContext,
  setChatboxContext,
  refetchMeetingflow,
  editor,
}: DecisionSiteInsightsSidePanelProps) => {
  const { isGuest, hasEntitlement } = useOrganization(organizationSlug);
  const { isDark } = useLightOrDarkMode();
  const { getAccessTokenSilently } = useAuth0();
  const appInsights = useAppInsightsContext();

  const {
    configurationsWithToken: salesforceConfigurationsWithToken,
    hasToken: hasSalesforceToken,
  } = useExternalServiceConfigurations({
    app: 'SALESFORCE',
    withToken: true,
  });

  const {
    createDeferred: createSummarizeMeetingflowDeferred,
    dialog: summarizeMeetingflowModal,
  } = useSummarizeMeetingflowDialog({ organizationSlug, meetingflowId });

  const {
    configurationsWithToken: hubspotConfigurationsWithToken,
    hasToken: hasHubspotToken,
  } = useExternalServiceConfigurations({
    app: 'HUBSPOT',
    withToken: true,
  });

  const [activeSalesforceConfigurationId, setActiveSalesforceConfigurationId] =
    useState<number | undefined>();

  const [activeHubSpotConfigurationId, setActiveHubSpotConfigurationId] =
    useState<number | undefined>();

  const {
    data: suggestedPrompts,
    isLoading: suggestedPromptsLoading,
    isFetched: suggestedPromptsFetched,
    refetch: refetchSuggestedPrompts,
  } = useQuery(
    MeetingPlanSuggestedPromptsQuery(organizationSlug, meetingflow.id),
    async () => {
      const token = await getAccessTokenSilently();
      return MeetingflowsApiClient.getSuggestedChatPrompts(
        organizationSlug,
        meetingflow.id,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    },
  );

  const beforeStart =
    DateTime.fromISO(meetingflow.startTime).diffNow('milliseconds')
      .milliseconds > 0;
  const beforeEnd =
    DateTime.fromISO(meetingflow.endTime).diffNow('milliseconds').milliseconds >
    0;
  const afterEnd =
    DateTime.fromISO(meetingflow.endTime).diffNow('milliseconds').milliseconds <
    0;
  const afterEnd24Hours =
    DateTime.fromISO(meetingflow.endTime).diffNow('milliseconds').milliseconds <
    Duration.fromObject({ days: -1 }).as('milliseconds');

  useEffect(() => {
    if (!suggestedPromptsLoading) {
      refetchSuggestedPrompts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    beforeStart,
    beforeEnd,
    afterEnd,
    afterEnd24Hours,
    meetingflow.callRecording?.lastStatus,
    callRecordingStatus,
  ]);

  useEffect(() => {
    if (!salesforceConfigurationsWithToken?.length) {
      setActiveSalesforceConfigurationId(undefined);
    } else if (salesforceConfigurationsWithToken?.length === 1) {
      setActiveSalesforceConfigurationId(
        salesforceConfigurationsWithToken[0].id,
      );
    }
  }, [salesforceConfigurationsWithToken]);

  useEffect(() => {
    if (!hubspotConfigurationsWithToken?.length) {
      setActiveHubSpotConfigurationId(undefined);
    } else if (hubspotConfigurationsWithToken?.length === 1) {
      setActiveHubSpotConfigurationId(hubspotConfigurationsWithToken[0].id);
    }
  }, [hubspotConfigurationsWithToken]);

  const salesforceAssociations = useMemo(
    () =>
      meetingflow.associations.filter(
        (a) =>
          a.serviceConfigurationId === activeSalesforceConfigurationId &&
          a.serviceConfiguration.app === 'SALESFORCE',
      ),
    [activeSalesforceConfigurationId, meetingflow.associations],
  );

  const hubspotAssociations = useMemo(
    () =>
      meetingflow.associations.filter(
        (a) =>
          a.serviceConfigurationId === activeHubSpotConfigurationId &&
          a.serviceConfiguration.app === 'HUBSPOT',
      ),
    [activeHubSpotConfigurationId, meetingflow.associations],
  );

  const sidePanelSectionClass = mergeStyles({
    margin: '0 0 1.5rem 0',
    ':empty': {
      margin: 0,
    },
  });

  const sectionHeaderClass = mergeStyles({
    margin: '0 0 .5rem 0',
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.regular,
    color: DEALROOMS_COLORS.themeSecondary,
    position: 'relative',
    display: 'block',
    width: '100%',

    userSelect: 'none',
    webkitUserSelect: 'none',
  });

  const aiSuggestionsWrapperClass = mergeStyles({
    display: 'flex',
    flexDirection: 'row',
    columnGap: '.25rem',
    rowGap: '.25rem',
    flexWrap: 'wrap',
  });

  const aiSuggestionClass = mergeStyles({
    fontSize: FontSizes.small,
    fontWeight: FontWeights.regular,
    userSelect: 'none',
    WebkitUserSelect: 'none',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    columnGap: '.25rem',
    backgroundColor: isDark
      ? DEALROOMS_COLORS.themeSecondary
      : DEALROOMS_COLORS.cloudburst,
    padding: '.25rem .75rem',
    borderRadius: '.25rem',
    color: MEETINGFLOW_COLORS.white,
    transition: '.3s ease-in-out all',

    ':hover': {
      backgroundColor: isDark
        ? MEETINGFLOW_COLORS.tealDarker
        : DEALROOMS_COLORS.themePrimary,
    },
  });

  const lastLoggedToCRMClass = mergeStyles({
    margin: '.5rem 0 1rem 0',
    backgroundColor: MEETINGFLOW_COLORS.orange,
    padding: '.5rem',
    borderRadius: '.25rem',
    color: MEETINGFLOW_COLORS.white,
    position: 'relative',
    fontSize: FontSizes.small,

    i: {
      position: 'absolute',
      top: '-.5rem',
      left: '-.5rem',
      fontSize: FontSizes.mediumPlus,
      color: MEETINGFLOW_COLORS.white,
      backgroundColor: MEETINGFLOW_COLORS.orange,
      borderRadius: '50%',
    },
  });

  return (
    <DecisionSiteBaseSidePanel
      title="Meetingflow Overview"
      onBack={onBack}
      onClose={onClose}
    >
      {!!meetingflow?.lastLoggedToCrm?.loggedAt && !isGuest ? (
        <div className={lastLoggedToCRMClass}>
          <FontIcon iconName="SkypeCircleCheck" />
          This meeting was logged to{' '}
          {meetingflow?.lastLoggedToCrm?.app === 'HUBSPOT'
            ? 'HubSpot'
            : meetingflow?.lastLoggedToCrm?.app
              ? titleCase(meetingflow?.lastLoggedToCrm?.app)
              : 'CRM'}{' '}
          by{' '}
          {meetingflow?.lastLoggedToCrm?.loggedBy?.name ||
            meetingflow?.lastLoggedToCrm?.loggedBy?.email ||
            'Unknown User'}
          , <StyledDateTime dateTime={meetingflow?.lastLoggedToCrm?.loggedAt} />
          .
        </div>
      ) : null}

      <div className={sidePanelSectionClass}>
        <Text block className={sectionHeaderClass}>
          AI Chat Suggestions
        </Text>
        <div className={aiSuggestionsWrapperClass}>
          {suggestedPromptsLoading && !suggestedPromptsFetched ? (
            <Spinner size={SpinnerSize.small} />
          ) : (
            suggestedPrompts?.data?.slice(0, 7)?.map((prompt) => {
              if (isGenerateSummarySuggestion(prompt)) {
                return (
                  <div
                    key={prompt.displayText}
                    className={aiSuggestionClass}
                    onClick={() => {
                      appInsights.trackEvent({
                        name: 'ClickGenerateSummaryAISuggestion',
                        properties: {
                          displayText: prompt.displayText,
                          summaryType: prompt.summaryType,
                        },
                      });
                      createSummarizeMeetingflowDeferred({
                        summaryType: prompt.summaryType,
                      })
                        .promise.then((result) => {
                          switch (prompt.summaryType) {
                            case 'MEETING_SUMMARY': {
                              break;
                            }
                            case 'PRE_MEETING_EMAIL':
                            case 'FOLLOWUP_EMAIL':
                            case 'REENGAGEMENT_EMAIL': {
                              pushPanelContext({
                                type: 'email-followup',
                                summaryText: result.summary,
                              });
                              break;
                            }
                            case 'SLACK_UPDATE': {
                              pushPanelContext({
                                type: 'slack',
                                summaryText: result.summary,
                              });
                              break;
                            }
                            case 'TEAMS_UPDATE': {
                              pushPanelContext({
                                type: 'ms-teams',
                                summaryText: result.summary,
                              });
                              break;
                            }
                            case 'SALESFORCE_SUMMARY': {
                              break;
                            }
                            case 'HUBSPOT_SUMMARY': {
                              pushPanelContext({
                                type: 'hubspot-log-meeting',
                                summaryText: result.summary,
                              });
                              break;
                            }
                            case 'SUGGESTED_NOTES': {
                              if (result.summarySlateNodes) {
                                editor?.insertFragment(
                                  result.summarySlateNodes,
                                  { at: editor.end([]) },
                                );
                              } else {
                                editor?.insertText(result.summary, {
                                  at: editor.end([]),
                                });
                              }
                              break;
                            }
                          }
                        })
                        .catch((err) => {});
                    }}
                  >
                    <Icon iconName="AISparkle" className="shortcut-type-icon" />
                    {prompt.displayText}
                  </div>
                );
              } else {
                return (
                  <div
                    key={prompt.content}
                    onClick={() => {
                      appInsights.trackEvent({
                        name: 'ClickChatAISuggestion',
                        properties: {
                          displayText: prompt.displayText || prompt.content,
                        },
                      });
                      setChatboxContext({
                        key: prompt.content,
                        initialMessages: [prompt],
                      });
                    }}
                    className={aiSuggestionClass}
                  >
                    <Icon iconName="AISparkle" className="shortcut-type-icon" />
                    {prompt.displayText || prompt.content}
                  </div>
                );
              }
            })
          )}
        </div>
      </div>

      <div className={sidePanelSectionClass}>
        {meetingflow.attendees && !isGuest ? (
          <>
            <Text block className={sectionHeaderClass}>
              Attendees
            </Text>
            <DecisionSiteMeetingPlanAttendees
              organizationSlug={organizationSlug}
              meetingPlan={meetingflow}
              internalDomains={internalDomains}
              onContactClick={onContactClick}
              onCompanyClick={onCompanyClick}
              rowGap={'.25rem'}
              noPivot
              awarenessStates={awarenessStates}
              showAvatars={false}
              showEmailDomains
              showContactSecondaryText={false}
              allowEditingContactNotes={true}
              salesforcePlanContext={salesforcePlanContext}
              hubspotPlanContext={hubspotPlanContext}
              showAttendeeCount
              pushPanelContext={pushPanelContext}
              companyCardBackgroundColor={'transparent'}
              contactCardBackgroundColor={'transparent'}
              setChatboxContext={setChatboxContext}
              allowEditAttendees={true}
              refetchMeetingPlan={refetchMeetingflow}
            />
          </>
        ) : null}
      </div>

      {hasEntitlement('DEAL_ROOM') ? (
        <div className={sidePanelSectionClass}>
          <RelatedDealRoomsList
            organizationSlug={organizationSlug}
            meetingflowId={meetingflowId}
          />
        </div>
      ) : null}
      {/* 
      <div className={sidePanelSectionClass}>
        <MeetingPlanRelatedPlanReaderList
          organizationSlug={organizationSlug}
          meetingflowId={meetingflowId}
          meetingflow={meetingflow}
          title="Related Meetingflows"
          filters={{ relatedMeetingflowId: meetingflowId }}
          maxCount={3}
          onContactClick={onContactClick}
          color={color}
          showTitle
          // onRelatedPlansViewAllClick={onRelatedPlansViewAllClick}
          hideIfNoContent
          stickyTopPosition="-10px"
          onMeetingflowClick={onMeetingflowClick}
          headersAreSubheaders
        />
      </div>

      {activeSalesforceConfigurationId && hasSalesforceToken() ? (
        <SalesforceInsightsPanelContent
          key={activeSalesforceConfigurationId}
          organizationSlug={organizationSlug}
          meetingflowId={meetingflowId}
          meetingflow={meetingflow}
          configurationId={activeSalesforceConfigurationId}
          onPickedObject={onPickedSalesforceObject}
          onPinnedObject={onPinnedSalesforceObject}
          pushSalesforcePanel={pushSalesforcePanel}
          associatedObjects={salesforceAssociations}
          showTitle={true}
          externalDomains={externalDomains}
          externalContactIds={externalContactIds}
        />
      ) : null}

      {activeHubSpotConfigurationId && hasHubspotToken() ? (
        <HubSpotInsightsPanelContent
          key={activeHubSpotConfigurationId}
          organizationSlug={organizationSlug}
          meetingflowId={meetingflow.id}
          meetingflow={meetingflow}
          configurationId={activeHubSpotConfigurationId}
          onPickedObject={onPickedHubSpotObject}
          onPinnedObject={onPinnedHubSpotObject}
          pushHubSpotPanel={pushHubSpotPanel}
          associatedObjects={hubspotAssociations}
          showTitle={true}
          externalDomains={externalDomains}
        />
      ) : null} */}
      {summarizeMeetingflowModal}
    </DecisionSiteBaseSidePanel>
  );
};
