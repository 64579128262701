import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Checkbox,
  Dropdown,
  FontWeights,
  Icon,
  IconButton,
  mergeStyleSets,
  Modal,
  Persona,
  PersonaSize,
  Pivot,
  PivotItem,
  Stack,
  Text,
  TextField,
} from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { artifactTypeToIconName } from '../../../Helpers/IconHelpers';
import {
  DealRoomAsyncCommandBarButton,
  DealRoomAsyncPrimaryButton,
  DealRoomCommandBarButton,
} from '../Components/DealRoomButton';
import {
  CheckMarkIconProps,
  ChromeCloseIconProps,
} from '../../../utils/iconProps';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import { OrganizationDealRoomArtifactsQuery } from '../../../QueryNames';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { isForbiddenError } from '../../../Helpers/AxiosHelpers';
import { humanizeDateTime } from '@meetingflow/common/DateHelpers';
import { pickFile } from '../../../Helpers/FileHelpers';
import toast from 'react-hot-toast';
import { useMutualPlan } from '../../../Hooks/useMutualPlan';
import { DraggableFileUploaderContainer } from '../DraggableFileUploaderContainer';
import { DealRoomArtifact } from '@meetingflow/common/Api/data-contracts';

type AddAttachmentDialogProps = {
  organizationSlug: string;
  dealRoomId: number;
  actionItemId: number;
  onResolve: (value: unknown) => void;
  onDismiss: () => void;
  makeJustDealRoomUpload?: boolean;
};

const AttachTabOptions = {
  selectAttach: 'selectAttach',
  uploadAttach: 'uploadAttach',
};

export const AddAttachmentDialog = ({
  organizationSlug,
  dealRoomId,
  actionItemId,
  onResolve,
  onDismiss,
  makeJustDealRoomUpload, // if true we update the UI and functionality to be used for conversations attachments (just for now, we'll refactor later)
}: AddAttachmentDialogProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const { mutualPlan, refetch: refetchMutualPlan } = useMutualPlan(
    organizationSlug,
    dealRoomId,
  );
  const taskOptionsList = useMemo(() => {
    if (!mutualPlan?.milestones) return [];

    return mutualPlan.milestones
      .flatMap((milestone) => milestone.actionItems || [])
      .map((actionItem) => ({
        key: actionItem?.id,
        text: actionItem?.actionItem,
      }));
  }, [mutualPlan]);

  const [selectedAttachTab, setSelectedAttachTab] = useState(
    AttachTabOptions.selectAttach,
  );
  const [selectedAttachID, setSelectedAttachID] = useState(-1);
  const [selectedItemID, setSelectedItemID] = useState<null | number>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    setSelectedItemID(actionItemId);
  }, [actionItemId]);

  const {
    data: dealRoomArtifacts,
    isLoading: artifactsLoading,
    refetch: refetchArtifacts,
  } = useQuery(
    OrganizationDealRoomArtifactsQuery(
      organizationSlug,
      dealRoomId,
      false, //featured,
      false, //includeDeleted,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return DealRoomsApiClient.listArtifacts(
        {
          organizationSlug,
          dealRoomId,
          featured: false,
          includeDeleted: false,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      // Enable the query only if organizationSlug and dealRoomId are valid
      enabled: !!organizationSlug && !!dealRoomId,

      // Retry logic for the query
      /**
       * Determines whether to retry fetching data based on the failure count and error type.
       *
       * @param {number} failureCount - The number of times the query has failed.
       * @param error - The error that occurred during the query.
       * @returns {boolean} - Returns true if the query should be retried, otherwise false.
       */
      retry: (failureCount, error) => {
        if (isForbiddenError(error)) {
          // Do not retry if the error is a forbidden error
          return false;
        }
        // Retry up to 3 times for other errors
        return failureCount < 3;
      },
    },
  );

  const styles = mergeStyleSets({
    modalWrapper: {
      '& > .ms-Overlay': {
        backdropFilter: 'blur(3px)',
      },
      '.ms-Dialog-main': {
        minHeight: 'fit-content',
        backgroundColor: 'transparent',
      },
    },
    contentContainer: {
      width: 'calc(100vw - 3rem)',
      padding: '1rem 1.5rem 1.5rem',
      maxWidth: '550px',
      backgroundColor: DEALROOMS_COLORS.white,
      borderRadius: '.5rem',
      boxShadow: '0px 8px 16px 0px #00000024',
      gap: '1.5rem',
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    title: {
      color: DEALROOMS_COLORS.themePrimary,
      fontSize: '1.5rem',
      lineHeight: '2.5rem',
      fontWeight: '400',
    },
    closeButton: {
      i: {
        fontSize: '.75rem',
        color: DEALROOMS_COLORS.themePrimary,
      },
    },
    tabSelector: {
      '>div:first-child': {
        borderBottom: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
      },
    },
    uploadTabPivot: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem 0',
      padding: '1rem 0 0 0',
    },
    taskDropdown: {
      label: {
        fontSize: '0.8rem',
        fontWeight: FontWeights.regular,
        color: DEALROOMS_COLORS.darkerGray,
      },
    },
    artifactsContainer: {
      display: 'flex',
    },
    artifactsTitle: {
      fontSize: '0.9rem',
      fontWeight: FontWeights.bold,
      color: DEALROOMS_COLORS.neutralDark,
      display: 'flex',
      justifyContent: 'center',

      padding: '1.5rem 0 1rem 0',
    },
    artifactsList: {
      display: 'grid',
      gridTemplateColumns: '0.4fr 1.5fr 1fr 1.5fr',
      borderRadius: '0.25rem',
      padding: '0.4rem 0.2rem',
      '.header': {
        color: DEALROOMS_COLORS.themeSecondary,
        fontSize: '0.8rem',
        fontWeight: FontWeights.regular,
        borderBottom: `1px solid${DEALROOMS_COLORS.neutralLight}`,
        padding: '0.5rem',
        textAlign: 'left',
      },
      '.item': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '0.5rem',
        color: DEALROOMS_COLORS.themePrimary,
        fontSize: '0.9rem',
        fontWeight: FontWeights.regular,
        padding: '0.5rem',
        textAlign: 'left',
        borderBottom: `1px solid${DEALROOMS_COLORS.neutralLight}`,
      },
      'artifact-checkbox': {
        borderRadius: '50%',
      },
    },
    uploadAttachSection: {
      width: '90% !important',
      margin: '0.8rem auto 0 auto',
    },
    uploadSectionFileName: {
      fontSize: '0.9375rem',
      lineHeight: '1.5rem',
      fontWeight: '500',
      color: DEALROOMS_COLORS.themePrimary,
      wordBreak: 'break-word',
      textAlign: 'center',
    },
    dropContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    uploadSectionMessage: {
      fontSize: '0.9375rem',
      lineHeight: '1.5rem',
      fontWeight: '500',
      color: DEALROOMS_COLORS.neutralDark,
      wordBreak: 'break-word',
      textAlign: 'center',
    },
    selectFileButton: {
      padding: '.375rem .75rem',
    },
    contentTitle: {
      '.ms-TextField-fieldGroup': {
        borderRadius: '.25rem',
        border: 'none !important',
        height: 'auto',
        '::after': {
          borderRadius: '.25rem',
          border: `1px solid ${DEALROOMS_COLORS.neutralSecondary}`,
        },
      },
      '.ms-Label': {
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        fontWeight: '400',
        color: DEALROOMS_COLORS.darkerGray,
      },
      input: {
        backgroundColor: DEALROOMS_COLORS.inputLightGray,
        color: DEALROOMS_COLORS.darkerGray,
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '1.375',
        borderRadius: '.25rem',
        padding: '9px 14px',
        '::placeholder': {
          color: DEALROOMS_COLORS.themeSecondary,
        },
      },
    },
    contentDescription: {
      '.ms-TextField-fieldGroup': {
        borderRadius: '4px',
        border: 'none !important',
        height: 'auto',

        '::after': {
          borderRadius: '.25rem',
          border: `1px solid ${DEALROOMS_COLORS.neutralSecondary}`,
        },
      },
      '.ms-Label': {
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        fontWeight: '400',
        color: DEALROOMS_COLORS.darkerGray,
      },
      textarea: {
        backgroundColor: DEALROOMS_COLORS.inputLightGray,
        color: DEALROOMS_COLORS.darkerGray,
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '22px',
        borderRadius: '.25rem',
        padding: '9px 14px',
        height: '96px',
        maxHeight: '96px',
        overflowY: 'auto',

        '::placeholder': {
          color: DEALROOMS_COLORS.themeSecondary,
        },
      },
    },
    footerContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '1rem',
      flexWrap: 'wrap',
      '> .cancel-button': {
        padding: '0.5rem 1rem',
        justifyContent: 'center',
        '.ms-Button-label': {
          fontSize: '0.9375rem',
          lineHeight: '1.5rem',
          fontWeight: '400',
          color: DEALROOMS_COLORS.darkerGray,
        },
      },
      '> .save-button': {
        padding: '0.5rem 1rem',
        '.ms-Button-label': {
          fontSize: '0.9375rem',
          lineHeight: '1.5rem',
          fontWeight: '400',
          color: DEALROOMS_COLORS.white,
        },
      },
      '> .save-button-disabled': {
        backgroundColor: DEALROOMS_COLORS.neutralDark,
        color: DEALROOMS_COLORS.lightGray,
        borderColor: DEALROOMS_COLORS.neutralDark,
        cursor: 'not-allowed',
      },
    },
  });

  const handleSelectFile = useCallback(async () => {
    try {
      const file = await pickFile({});
      if (!file) {
        return;
      }
      const hasExtension = file.name.lastIndexOf('.') > 0;
      if (!hasExtension) {
        toast.error(
          'Please select a file with an extension, like .txt or .pdf',
        );
        return;
      }
      setSelectedFile(file);
    } catch (err) {}
  }, []);

  const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFiles = event?.dataTransfer?.files;
    if (droppedFiles && droppedFiles.length > 0) {
      const currentFile = Array.from(droppedFiles)[0];
      const hasExtension = currentFile?.name?.lastIndexOf('.') > 0;
      if (!hasExtension) {
        toast.error(
          'Please select a file with an extension, like .txt or .pdf',
        );
        return;
      }
      setSelectedFile(currentFile);
    }
  }, []);

  const handleActualUpload = useCallback(async () => {
    if (!selectedFile) return;
    try {
      const token = await getAccessTokenSilently();
      return await DealRoomsApiClient.uploadFileArtifact(
        organizationSlug,
        dealRoomId,
        {
          file: selectedFile,
          ...(title ? { name: title } : {}),
          ...(description ? { description } : {}),
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    } catch (err) {
      toast.error(
        'Something went wrong uploading file, please try again later',
      );
      onDismiss();
      return err;
    }
  }, [
    dealRoomId,
    getAccessTokenSilently,
    onDismiss,
    organizationSlug,
    selectedFile,
    title,
    description,
  ]);

  const handleLinkAttachment = useCallback(
    async (attachID: number, uploadedAttachment?: DealRoomArtifact) => {
      if (makeJustDealRoomUpload) {
        // this block is used to get the artifact that was just uploaded in the conversations part
        if (uploadedAttachment) {
          onResolve(uploadedAttachment || null);
          return;
        }

        const currentSelectedAttachment = dealRoomArtifacts?.data?.find(
          (artifact) => artifact.id === attachID,
        );

        onResolve(currentSelectedAttachment || null);
        return;
      }

      if (!selectedItemID) return;
      try {
        const token = await getAccessTokenSilently();
        await toast.promise(
          DealRoomsApiClient.putActionItemArtifact(
            organizationSlug,
            dealRoomId,
            selectedItemID,
            attachID,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          ),
          {
            loading: 'Uploading Artifact',
            success: () => {
              onResolve(null);
              refetchMutualPlan().then();
              return 'Successfully uploaded artifact';
            },
            error: () => {
              onDismiss();
              return 'Failed to upload artifact';
            },
          },
        );
      } catch (err) {
        onDismiss();
      }
    },
    [
      makeJustDealRoomUpload,
      selectedItemID,
      dealRoomArtifacts?.data,
      onResolve,
      getAccessTokenSilently,
      organizationSlug,
      dealRoomId,
      refetchMutualPlan,
      onDismiss,
    ],
  );

  const handleUploadAndLinkAttachment = useCallback(async () => {
    const response = await handleActualUpload();
    if (
      response &&
      typeof response === 'object' &&
      'data' in response &&
      response.data &&
      typeof response.data === 'object' &&
      'id' in response.data &&
      typeof response.data.id === 'number'
    ) {
      await handleLinkAttachment(
        response.data.id,
        response.data as DealRoomArtifact, // this is used just in conversations side
      );
    }
  }, [handleActualUpload, handleLinkAttachment]);

  return (
    <Modal className={styles.modalWrapper} isOpen onDismiss={onDismiss}>
      <Stack className={styles.contentContainer}>
        <Stack.Item className={styles.headerContainer}>
          <Text className={styles.title}>
            {makeJustDealRoomUpload ? 'Add Artifact' : 'Add Artifact to Task'}
          </Text>
          <IconButton
            className={styles.closeButton}
            ariaLabel="Close"
            iconProps={ChromeCloseIconProps}
            onClick={onDismiss}
            alt="Close image"
          />
        </Stack.Item>
        <Stack.Item>
          <Pivot
            styles={{
              linkIsSelected: {
                color: DEALROOMS_COLORS.darkerGray,
                '::before': {
                  backgroundColor: DEALROOMS_COLORS.neutralDark,
                },
              },
            }}
            className={styles.tabSelector}
            selectedKey={selectedAttachTab}
            onLinkClick={(item) =>
              setSelectedAttachTab(
                item?.props.itemKey || AttachTabOptions.selectAttach,
              )
            }
          >
            <PivotItem
              headerText="Select Artifact"
              itemKey={AttachTabOptions.selectAttach}
            >
              <Stack className={styles.artifactsContainer}>
                <Text className={styles.artifactsTitle}>
                  {makeJustDealRoomUpload
                    ? 'Select an Artifact to upload'
                    : 'Select an Artifact to associate with this task'}
                </Text>
                <div className={styles.artifactsList}>
                  <div className="header">Select</div>
                  <div className="header">Name</div>
                  <div className="header">Owner</div>
                  <div className="header">Last Modified</div>

                  {dealRoomArtifacts?.data.map((artifact) => (
                    <React.Fragment key={`artifact_item_${artifact.id}`}>
                      <div
                        style={{
                          backgroundColor:
                            selectedAttachID === artifact.id
                              ? DEALROOMS_COLORS.neutralLighter
                              : 'transparent',
                        }}
                        className="item"
                      >
                        <Checkbox
                          className="artifact-checkbox"
                          checked={selectedAttachID === artifact.id}
                          onChange={(event, checked) =>
                            setSelectedAttachID(checked ? artifact.id : -1)
                          }
                        />
                      </div>
                      <div
                        style={{
                          backgroundColor:
                            selectedAttachID === artifact.id
                              ? DEALROOMS_COLORS.neutralLighter
                              : 'transparent',
                        }}
                        className="item"
                      >
                        <Icon
                          iconName={artifactTypeToIconName(
                            artifact.type,
                            // @ts-ignore
                            artifact.mimeType || undefined,
                          )}
                        />
                        <Text>{artifact.name || 'Artifact'}</Text>
                      </div>
                      <div
                        style={{
                          backgroundColor:
                            selectedAttachID === artifact.id
                              ? DEALROOMS_COLORS.neutralLighter
                              : 'transparent',
                        }}
                        className="item"
                      >
                        <Persona
                          {...{
                            text: artifact.creator?.name || undefined,
                            imageUrl:
                              artifact.creator?.avatarFileUrl || undefined,
                          }}
                          size={PersonaSize.size24}
                          hidePersonaDetails={true}
                        />
                        {artifact.creator?.name || 'N/A'}
                      </div>
                      <div
                        style={{
                          backgroundColor:
                            selectedAttachID === artifact.id
                              ? DEALROOMS_COLORS.neutralLighter
                              : 'transparent',
                        }}
                        className="item"
                      >
                        {humanizeDateTime(artifact.updatedAt)}
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </Stack>
            </PivotItem>

            <PivotItem
              className={styles.uploadTabPivot}
              headerText="Upload Artifact"
              itemKey={AttachTabOptions.uploadAttach}
            >
              <DraggableFileUploaderContainer
                handleOnDrop={handleDrop}
                className={styles.uploadAttachSection}
              >
                <DealRoomAsyncCommandBarButton
                  customClasses={styles.selectFileButton}
                  onClick={handleSelectFile}
                  text="Select File"
                  buttonStyleType="DEAL_ROOM"
                  iconProps={{ iconName: 'AddArtifact' }}
                />
                <div className={styles.dropContent}>
                  <Text className={styles.uploadSectionMessage}>
                    {makeJustDealRoomUpload
                      ? 'Select or drop a file to upload'
                      : 'Select or drop a file to upload and associate with this Task.'}
                  </Text>
                  {selectedFile && (
                    <Text className={styles.uploadSectionFileName}>
                      {selectedFile.name || 'N/A'}
                    </Text>
                  )}
                </div>
              </DraggableFileUploaderContainer>
              <TextField
                value={title}
                className={styles.contentTitle}
                label="Title"
                placeholder="Artifact title..."
                onChange={(e, newValue) => setTitle(newValue || '')}
              />
              <TextField
                value={description}
                className={styles.contentDescription}
                label="Description"
                multiline
                resizable={false}
                placeholder="Enter Artifact Description"
                onChange={(e, newValue) => setDescription(newValue || '')}
              />
            </PivotItem>
          </Pivot>
        </Stack.Item>
        {!makeJustDealRoomUpload && (
          <Stack.Item>
            <Dropdown
              className={styles.taskDropdown}
              options={taskOptionsList}
              onChange={(_e, option) => {
                if (option?.key !== undefined) {
                  setSelectedItemID(+option.key);
                }
              }}
              selectedKey={selectedItemID}
              label="Task"
              placeholder="Select task"
            />
          </Stack.Item>
        )}
        <Stack.Item className={styles.footerContainer}>
          <DealRoomCommandBarButton
            customClasses="cancel-button"
            buttonStyleType="COMMAND_BAR"
            text="Cancel"
            onClick={onDismiss}
          />
          <DealRoomAsyncPrimaryButton
            customClasses="save-button"
            iconProps={CheckMarkIconProps}
            onClick={async () => {
              if (selectedAttachTab === AttachTabOptions.selectAttach) {
                await handleLinkAttachment(selectedAttachID);
              } else if (selectedAttachTab === AttachTabOptions.uploadAttach) {
                await handleUploadAndLinkAttachment();
              }
            }}
            text={
              makeJustDealRoomUpload
                ? 'Add Artifact'
                : selectedAttachTab === AttachTabOptions.selectAttach
                  ? 'Add Artifact to Task'
                  : 'Add Artifact and Link to Task'
            }
            disabled={
              (selectedAttachTab === AttachTabOptions.selectAttach &&
                selectedAttachID === -1) ||
              (selectedAttachTab === AttachTabOptions.uploadAttach &&
                !selectedFile)
            }
          />
        </Stack.Item>
      </Stack>
    </Modal>
  );
};
