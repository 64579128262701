import { useAuth0 } from '@auth0/auth0-react';
import {
  FontIcon,
  FontSizes,
  FontWeights,
  IconButton,
  NeutralColors,
  Spinner,
  SpinnerSize,
  TextField,
  mergeStyles,
} from '@fluentui/react';
import { CheckMarkIcon } from '@fluentui/react-icons-mdl2';
import {
  GPTChatBoxMessage,
  ResponseRating,
} from '@meetingflow/common/Api/data-contracts';
import {
  guessNameFromEmail,
  titleCase,
} from '@meetingflow/common/StringHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import classNames from 'classnames';
import { useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useChatState } from '../../Hooks/useChatState';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { useScrollToBottom } from '../../Hooks/useScrollToBottom';
import { OrganizationsApiClient } from '../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { ChatMessage } from '../Common/Chat/ChatMessage';
import { GPTChatBoxAction } from './GPTChatBoxAction';
import { AutoScrollView } from '../Common/AutoScrollView';
import { flatMap } from 'lodash';

export type GPTChatBoxProps = {
  organizationSlug: string;
  meetingPlanId: string;
  title?: string;
  initialMessages?: GPTChatBoxMessage[];
  defaultActions?: GPTChatBoxAction[];
  onClose?: (lastMessage?: string) => void;
  showChatInput?: boolean;
  chatInputPlaceholder?: string;
  maxHeightRem?: number;
  isInSidePanel?: boolean;
  excludeAgenda?: boolean;
  excludeNotes?: boolean;
  excludeTasks?: boolean;
  excludeTranscript?: boolean;
  excludeSummary?: boolean;
  excludeDossier?: boolean;
};

export const GPTChatBox = ({
  organizationSlug,
  meetingPlanId,
  title,
  initialMessages,
  defaultActions,
  showChatInput,
  onClose,
  chatInputPlaceholder,
  maxHeightRem,
  isInSidePanel,
  excludeAgenda,
  excludeDossier,
  excludeNotes,
  excludeSummary,
  excludeTasks,
  excludeTranscript,
}: GPTChatBoxProps) => {
  const { user } = useAuth0();

  const additionalProps = useMemo(
    () => ({
      excludeAgenda,
      excludeDossier,
      excludeNotes,
      excludeSummary,
      excludeTasks,
      excludeTranscript,
    }),
    [
      excludeAgenda,
      excludeDossier,
      excludeNotes,
      excludeSummary,
      excludeTasks,
      excludeTranscript,
    ],
  );

  const {
    isGenerating,
    thoughts,
    currentOutput,
    actions,
    messages,
    addUserMessage,
    addMessage,
    setMessages,
    retryLastMessage,
    abort,
  } = useChatState({
    url: `/api/organization/${organizationSlug}/plan/${meetingPlanId}/assistant/query2`,
    defaultActions,
    initialMessages,
    additionalProps,
  });

  const [userInput, setUserInput] = useState<string>('');

  const { getAccessTokenSilently } = useAuth0();
  const { isDark } = useLightOrDarkMode();
  const appInsights = useAppInsightsContext();

  const userName = useMemo(
    () =>
      (
        user!.name ||
        guessNameFromEmail(user!.name) ||
        titleCase(user!.email!.split('@')[0])
      )?.split(/\b/g)?.[0],
    [user],
  );

  const postResponseFeedbackMutation = useMutation(
    async ({
      id,
      rating,
      feedback,
    }: {
      id: number;
      rating: ResponseRating;
      feedback?: string | null;
    }) => {
      const token = await getAccessTokenSilently();
      return OrganizationsApiClient.postAssistantResponseFeedback(
        organizationSlug,
        id,
        { rating, feedback },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    },
    {
      onMutate: async (variables) => {
        const oldMessages = [...messages];

        setMessages((current) =>
          current.map((message) =>
            message.id === variables.id
              ? {
                  ...message,
                  rating: variables.rating,
                  feedback: variables.feedback,
                }
              : message,
          ),
        );

        return { oldMessages };
      },
      onError: (_error, _variables, context) => {
        if (context?.oldMessages) {
          setMessages(context.oldMessages);
        }
      },
    },
  );

  const gptOutputBoxClass = mergeStyles({
    backgroundColor: isDark ? NeutralColors.gray190 : MEETINGFLOW_COLORS.white,
    overflow: 'hidden',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',

    '.content': {},

    '.messages': {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      padding: '1rem',
    },

    '.actions': {
      display: 'flex',
      justifyContent: 'flex-start',
      justifyItems: 'center',
      gap: '.5rem',
      paddingLeft: '2rem',
      position: 'relative',
      top: '-.5rem',
      cursor: 'pointer',
      '.action': {
        display: 'flex',
        gap: '.25rem',
        backgroundColor: isDark ? '#920061' : '#f4e6ed',

        padding: '.25rem .5rem',
        color: isDark ? '#f4e6ed' : '#920061',
        borderRadius: '.25rem',
        fontSize: FontSizes.small,

        i: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
    },

    '.thoughts': {
      display: 'flex',
      flexDirection: 'column',
      gap: '.5rem',
      backgroundColor: isDark ? NeutralColors.gray180 : '#f3f4f6',
      margin: '0 2rem',
      padding: '.5rem',
      borderRadius: '.25rem',

      '.thought': {
        display: 'flex',
        flaexDirection: 'row',
        gap: '.5rem',
        '.thought-icon': {},
        '.thought-text': {
          fontSize: FontSizes.small,
          color: isDark ? NeutralColors.gray70 : NeutralColors.gray150,
        },
      },
    },

    '.user-input-wrapper': {
      display: 'flex',
      gap: '.5rem',
      position: 'sticky',
      bottom: 0,
      zIndex: 50000,

      '.user-input': {
        display: 'flex',
        gap: '.5rem',
        '.user-input-box': {
          flexGrow: 1,
        },
        '.user-input-send': {},
      },
    },

    '.user-input-container': {
      position: 'sticky',
      bottom: '0 ',
      zIndex: 50000,
      display: 'flex',
      gap: '.5rem',
      padding: '1rem',
      backgroundColor: isDark
        ? NeutralColors.gray190
        : MEETINGFLOW_COLORS.white,
      borderTop: `1px solid ${
        isDark ? NeutralColors.gray220 : NeutralColors.gray20
      }`,
      height: 'auto',
      '.user-input-wrapper': {
        display: 'flex',
        gap: '.5rem',
        width: '100%',
        '.user-input': {
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          gap: '.5rem',
          '.user-input-box': {
            flexGrow: 1,

            textarea: {
              height: 'auto !important',
            },
          },
          '.user-input-send': {
            position: 'absolute',
            right: '1rem',
            bottom: '1rem',
            transform: 'rotate(-90deg)',

            backgroundColor: 'transparent !important',
          },
        },
      },
    },
  });

  return (
    <div className={classNames(gptOutputBoxClass, 'gpt-chat-box')}>
      <AutoScrollView className="content" autoScroll>
        <div className="messages">
          {messages.map((message, idx) => (
            <ChatMessage
              key={message.id || idx || message.content}
              id={message.id}
              userName={userName}
              role={message.role}
              displayText={message.displayText}
              content={message.content}
              rating={message.rating}
              allowCopy={!isGenerating}
              allowFeedback
              display={message.display}
              error={message.error}
              onUpvote={() => {
                if (!message.id) {
                  return;
                }
                postResponseFeedbackMutation.mutate({
                  id: message.id as number,
                  rating: 'GOOD_RESPONSE',
                });
                appInsights.trackEvent({
                  name: 'CHAT_RATING_GOOD_RESPONSE',
                  properties: {
                    organizationSlug,
                    meetingPlanId,
                    id: message.id,
                  },
                });
              }}
              onDownvote={() => {
                if (!message.id) {
                  return;
                }
                postResponseFeedbackMutation.mutate({
                  id: message.id as number,
                  rating: 'BAD_RESPONSE',
                });
                appInsights.trackEvent({
                  name: 'CHAT_RATING_BAD_RESPONSE',
                  properties: {
                    organizationSlug,
                    meetingPlanId,
                    id: message.id,
                  },
                });
              }}
              onRetry={retryLastMessage}
            />
          ))}
          {currentOutput ? (
            <ChatMessage
              role="assistant"
              className="current-output"
              content={currentOutput}
            />
          ) : null}

          {actions?.length && !isGenerating && !thoughts.length ? (
            <div className="actions">
              {actions.map((action) => {
                return (
                  <div
                    key={action.key}
                    className={classNames(`action action-${action.type}`)}
                    onClick={async () => {
                      const lastMessage = messages.findLast(
                        (message) => message.role === 'assistant',
                      );
                      switch (action.type) {
                        case 'action': {
                          const message = action.onClick(lastMessage?.content);
                          addMessage(message);
                          break;
                        }
                        case 'async-action': {
                          action
                            .onClick(lastMessage?.content)
                            .then((message) => addMessage(message));
                          break;
                        }
                        case 'finish': {
                          action.onClick(lastMessage?.content);
                          break;
                        }
                        default: {
                        }
                      }
                      appInsights.trackEvent({
                        name: 'CHAT_CLICK_ACTION',
                        properties: {
                          organizationSlug,
                          meetingPlanId,
                          actionType: action.type,
                        },
                      });
                    }}
                  >
                    {action.iconName ? (
                      <FontIcon iconName={action.iconName} />
                    ) : null}
                    {action.label}
                  </div>
                );
              })}
            </div>
          ) : null}

          {thoughts.length ? (
            <div className="thoughts">
              {thoughts.map((thought, idx) => (
                <div key={thought} className="thought">
                  {idx === thoughts.length - 1 ? (
                    <Spinner
                      className="thought-icon"
                      size={SpinnerSize.xSmall}
                    />
                  ) : (
                    <CheckMarkIcon className="thought-icon" />
                  )}
                  <div className="thought-text">{thought}</div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </AutoScrollView>

      <div className="user-input-container">
        {showChatInput ? (
          <div className="user-input-wrapper">
            <div className="user-input">
              <TextField
                className="user-input-box"
                multiline
                borderless={isGenerating}
                autoAdjustHeight
                disabled={isGenerating}
                value={userInput}
                placeholder={chatInputPlaceholder}
                onChange={(_, newValue) => setUserInput(newValue || '')}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' && userInput && !event.shiftKey) {
                    event.preventDefault();
                    event.stopPropagation();
                    setUserInput('');
                    addUserMessage(userInput, userName);
                    appInsights.trackEvent({
                      name: 'CHAT_SEND_MESSAGE',
                      properties: {
                        organizationSlug,
                        meetingPlanId,
                        sendMethod: 'enterKey',
                      },
                    });
                  }
                }}
                styles={{
                  root: {
                    opacity: isGenerating ? 0.5 : 1,
                    cursor: isGenerating ? 'not-allowed' : 'text',
                    border: 'none !important',
                    outline: 'none !important',

                    textarea: {
                      outline: 'none !important',
                      border: 'none !important',
                    },
                  },
                  fieldGroup: {
                    borderRadius: '.25rem',
                    backgroundColor: isDark
                      ? NeutralColors.gray220
                      : MEETINGFLOW_COLORS.white,

                    ':after': {
                      outline: 'none !important',
                      border: 'none !important',
                      borderRadius: '.25rem',
                    },
                  },
                  field: {
                    border: 'none !important',
                    outline: 'none !important',
                    borderRadius: '.25rem',
                    cursor: isGenerating ? 'not-allowed' : 'text',
                    paddingRight: '4rem',
                    color: isDark
                      ? NeutralColors.white
                      : MEETINGFLOW_COLORS.black,
                    backgroundColor: isDark
                      ? NeutralColors.gray220
                      : MEETINGFLOW_COLORS.white,

                    '::placeholder': {
                      color: isDark
                        ? NeutralColors.gray100
                        : NeutralColors.gray130,
                    },
                  },
                }}
              />
              <IconButton
                className="user-input-send"
                iconProps={{ iconName: 'Send' }}
                disabled={!userInput || isGenerating}
                onClick={() => {
                  if (userInput) {
                    setUserInput('');
                    addUserMessage(userInput, userName);
                    appInsights.trackEvent({
                      name: 'CHAT_SEND_MESSAGE',
                      properties: {
                        organizationSlug,
                        meetingPlanId,
                        sendMethod: 'sendButton',
                      },
                    });
                  }
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
