import { useState, useEffect } from 'react';
import { throttle } from 'lodash';

type Breakpoints = {
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
  xxl: boolean;
  xxxl: boolean;
};

const getBreakpoints = (width: number) => {
  const breakpoints: Breakpoints = {
    xs: true,
    sm: width >= 320,
    md: width >= 720,
    lg: width >= 1024,
    xl: width > 1280,
    xxl: width > 1440,
    xxxl: width > 1920,
  };

  return breakpoints;
};

const useBreakpoints = () => {
  const [breakpoint, setBreakpoint] = useState(() =>
    getBreakpoints(window.innerWidth),
  );

  useEffect(() => {
    const calcInnerWidth = throttle(() => {
      setBreakpoint(getBreakpoints(window.innerWidth));
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return breakpoint;
};
export default useBreakpoints;
