import {
  DetailedMeetingflow,
  DetailedUser,
  Task,
} from '@meetingflow/common/Api/data-contracts';
import { DeduplicateArray } from '@meetingflow/common/ArrayHelpers';
import { COMMON_INTERNAL_RESOURCE_DOMAINS } from '@meetingflow/common/PublicEmailDomains';
import { getDomain } from '@meetingflow/common/StringHelpers';
import { Truthy } from '@meetingflow/common/TypeHelpers';
import { yTextToSlateElement } from '@slate-yjs/core';
import { isSet } from 'lodash';
import { DateTime } from 'luxon';
import * as Y from 'yjs';
import { hasContent, toParagraphs } from './SlateHelpers';

export const LINE_ENDING = {
  NEWLINE: '\n',
  HTML_BR: '<br/>',
};

const DEFAULT_PLAN_PLAINTEXT_OPTIONS = {
  includePlanUrl: true,
  includeOrganizer: true,
  includeAttendees: true,
  includeSummary: true,
  includeNotes: false,
  includeCallInsights: false,
  lineEnding: LINE_ENDING.NEWLINE,
};

export type MeetingPlanToPlanTextOptions = {
  includePlanUrl?: boolean;
  includeOrganizer?: boolean;
  includeAttendees?: boolean;
  includeSummary?: boolean;
  includeNotes?: boolean;
  includeCallInsights?: boolean;
  lineEnding?: string;
};
export const toPlainText = (
  organizationSlug: string,
  meetingPlan: Pick<
    DetailedMeetingflow,
    'id' | 'organizer' | 'attendees' | 'callRecording'
  >,
  ydoc: Y.Doc,
  summary?: string,
  options: MeetingPlanToPlanTextOptions = {},
) => {
  const {
    includePlanUrl,
    includeOrganizer,
    includeAttendees,
    includeSummary,
    includeNotes,
    includeCallInsights,
    lineEnding,
  } = { ...DEFAULT_PLAN_PLAINTEXT_OPTIONS, ...options };

  const planURL = `${window.origin}/organization/${organizationSlug}/plan/${meetingPlan.id}`;

  const lines = includePlanUrl ? [planURL] : [];

  if (includeOrganizer && meetingPlan.organizer) {
    lines.push('');
    lines.push(
      meetingPlan.organizer.name
        ? `Organized by ${meetingPlan.organizer.name} (${meetingPlan.organizer.email})`
        : `Organized by ${meetingPlan.organizer.email}`,
    );
  }

  if (includeAttendees && meetingPlan.attendees.length) {
    lines.push('');
    lines.push('Attendees:');
    meetingPlan.attendees.forEach((attendee) => {
      lines.push(
        attendee.name
          ? `${attendee.name} (${attendee.email})`
          : `${attendee.email}`,
      );
    });
  }

  if (includeSummary && summary) {
    lines.push('');
    lines.push('Summary:');
    lines.push(...summary.split('\n'));
  }

  if (includeNotes) {
    const notes = yTextToSlateElement(
      ydoc.get('notes', Y.XmlText) as Y.XmlText,
    );
    if (hasContent(notes)) {
      lines.push('');
      lines.push('Notes:');
      lines.push(...toParagraphs(notes));
    }
  }

  if (includeCallInsights) {
    if (meetingPlan?.callRecording?.transcriptAnalysis?.topics?.length) {
      lines.push('');
      lines.push('Topics Discussed on Recorded Call:');
      meetingPlan?.callRecording?.transcriptAnalysis?.topics?.forEach(
        (topic) => {
          lines.push('');
          lines.push(topic.title);
          lines.push(topic.summary);
          lines.push(`${planURL}?recordingTimestamp=${topic.timestamp}`);
        },
      );
    }
  }

  return lines.join(lineEnding);
};

export const allPlanDomains = (
  meetingplan?: Pick<DetailedMeetingflow, 'attendees'>,
): string[] =>
  DeduplicateArray(
    meetingplan?.attendees.map((contact) => contact.emailDomain) || [],
  );

export const externalPlanDomains = (
  meetingplan?: Pick<DetailedMeetingflow, 'attendees'>,
  internalDomains?: string[],
): string[] => {
  const contacts = externalPlanContacts(meetingplan, internalDomains);

  return DeduplicateArray(contacts.map((contact) => contact.emailDomain));
};

export const externalPlanContacts = (
  meetingplan?: Pick<DetailedMeetingflow, 'attendees'>,
  internalDomains?: string[],
) => {
  if (!meetingplan) {
    return [];
  }

  if (!internalDomains?.length) {
    return meetingplan.attendees;
  }

  return meetingplan.attendees.filter(
    (contact) => !internalDomains.includes(contact.emailDomain),
  );
};

export const companiesWithAttendees = (
  meetingPlan: Pick<DetailedMeetingflow, 'attendees' | 'companies'>,
) =>
  meetingPlan.companies.map((c) => ({
    ...c,
    attendees: meetingPlan.attendees.filter((a) =>
      c.domains.some((d) => d.domain === a.emailDomain),
    ),
  }));

export const internalCompaniesWithAttendees = (
  meetingPlan: Pick<DetailedMeetingflow, 'attendees' | 'companies'>,
  internalDomains: string[],
) => companiesWithAttendees(meetingPlan).filter((c) => c.isInternal);

export const externalCompaniesWithAttendees = (
  meetingPlan: Pick<DetailedMeetingflow, 'attendees' | 'companies'>,
  internalDomains: string[],
) => companiesWithAttendees(meetingPlan).filter((c) => !c.isInternal);

export const chatGPTPreamble = (
  user: Pick<DetailedUser, 'name' | 'email'>,
  meetingPlan: Pick<
    DetailedMeetingflow,
    'title' | 'startTime' | 'companies' | 'creator' | 'organizer' | 'attendees'
  >,
  internalDomains: string[],
) => {
  const userDomain = getDomain(user.email);
  const companies = companiesWithAttendees(meetingPlan);

  const internalCompanies = internalCompaniesWithAttendees(
    meetingPlan,
    internalDomains,
  );
  const externalCompanies = externalCompaniesWithAttendees(
    meetingPlan,
    internalDomains,
  );

  const hasExternalCompanies = !!externalCompanies.length;

  const organizer = meetingPlan.organizer || meetingPlan.creator;

  const userCompany = companies.find((c) =>
    c.domains.some((d) => d.domain === userDomain),
  );

  const organizerCompany =
    (!!organizer &&
      companies.find((c) =>
        c.domains.some((d) => d.domain === organizer.emailDomain),
      )) ||
    undefined;

  const sentences: string[] = [];

  sentences.push(
    userCompany
      ? `I am ${user.name || user.email} and I work for ${
          userCompany.name || userCompany.legalName
        }.`
      : `I am ${user.name || user.email}.`,
  );
  sentences.push(
    hasExternalCompanies
      ? `Between <NOTES> are meeting notes from a business meeting titled "${
          meetingPlan.title
        }" between ${internalCompanies
          .map((c) => c.name || c.legalName)
          .join(', ')} and ${externalCompanies
          .map((c) => c.name || c.legalName)
          .join(', ')} which took place on ${DateTime.fromISO(
          meetingPlan.startTime,
        ).toLocaleString()}.`
      : `Between <NOTES> are meeting notes from an internal business meeting among ${internalCompanies
          .map((c) => c.name || c.legalName)
          .join(' and ')} employees,  titled "${
          meetingPlan.title
        }", and which took place on ${DateTime.fromISO(
          meetingPlan.startTime,
        ).toLocaleString()}.`,
  );
  sentences.push(
    organizer && organizerCompany
      ? `The meeting was organized by ${
          organizer.name || organizer.email
        } from ${organizerCompany.name || organizerCompany.legalName}.`
      : organizer
        ? `The meeting was organized by ${organizer.name || organizer.email}.`
        : ``,
  );
  sentences.push(
    ...internalCompanies.map(
      (c) =>
        `${c.attendees.map((a) => a.name || a.email).join(', ')} ${
          c.attendees.length === 1 ? 'works' : 'work'
        } for ${c.name || c.legalName}.`,
    ),
  );
  sentences.push(
    ...externalCompanies.map(
      (c) =>
        `${c.attendees.map((a) => a.name || a.email).join(', ')} ${
          c.attendees.length === 1 ? 'works' : 'work'
        } for ${c.name || c.legalName}.`,
    ),
  );

  return sentences.filter(Truthy).join(' ');
};

export const chatGPTPreamble2 = (
  user: Pick<DetailedUser, 'name' | 'email'>,
  meetingPlan: Pick<
    DetailedMeetingflow,
    'title' | 'startTime' | 'companies' | 'creator' | 'organizer' | 'attendees'
  >,
  internalDomains: string[],
  contentType: string,
) => {
  const userDomain = getDomain(user.email);
  const companies = companiesWithAttendees(meetingPlan);

  const internalCompanies = internalCompaniesWithAttendees(
    meetingPlan,
    internalDomains,
  );
  const externalCompanies = externalCompaniesWithAttendees(
    meetingPlan,
    internalDomains,
  );

  const hasExternalCompanies = !!externalCompanies.length;

  const organizer = meetingPlan.organizer || meetingPlan.creator;

  const userCompany = companies.find((c) =>
    c.domains.some((d) => d.domain === userDomain),
  );

  const organizerCompany =
    (!!organizer &&
      companies.find((c) =>
        c.domains.some((d) => d.domain === organizer.emailDomain),
      )) ||
    undefined;

  const sentences: string[] = [];

  sentences.push(
    userCompany
      ? `I am ${user.name || user.email} and I work for ${
          userCompany.name || userCompany.legalName
        }.`
      : `I am ${user.name || user.email}.`,
  );
  sentences.push(
    hasExternalCompanies
      ? `The following ${contentType} is from a business meeting titled "${
          meetingPlan.title
        }" between ${internalCompanies
          .map((c) => c.name || c.legalName)
          .join(', ')} and ${externalCompanies
          .map((c) => c.name || c.legalName)
          .join(', ')} which took place on ${DateTime.fromISO(
          meetingPlan.startTime,
        ).toLocaleString()}.`
      : `The following ${contentType} is from an internal business meeting among ${internalCompanies
          .map((c) => c.name || c.legalName)
          .join(' and ')} employees,  titled "${
          meetingPlan.title
        }", and which took place on ${DateTime.fromISO(
          meetingPlan.startTime,
        ).toLocaleString()}.`,
  );
  sentences.push(
    organizer && organizerCompany
      ? `The meeting was organized by ${
          organizer.name || organizer.email
        } from ${organizerCompany.name || organizerCompany.legalName}.`
      : organizer
        ? `The meeting was organized by ${organizer.name || organizer.email}.`
        : ``,
  );
  sentences.push(
    ...internalCompanies.map(
      (c) =>
        `${c.attendees.map((a) => a.name || a.email).join(', ')} ${
          c.attendees.length === 1 ? 'works' : 'work'
        } for ${c.name || c.legalName}.`,
    ),
  );
  sentences.push(
    ...externalCompanies.map(
      (c) =>
        `${c.attendees.map((a) => a.name || a.email).join(', ')} ${
          c.attendees.length === 1 ? 'works' : 'work'
        } for ${c.name || c.legalName}.`,
    ),
  );

  return sentences.filter(Truthy).join(' ');
};

export const tasksToChatGPTContext = (tasks: Task[]): string => {
  if (!tasks.length) {
    return '';
  }

  const sentences: string[] = ['Action items:'];

  for (const task of tasks) {
    let sentence = `  - ${task.text}`;

    if (task.assignee) {
      sentence += `, assigned to ${task.assignee.name || task.assignee.email}`;
    }

    if (task.dueDate) {
      sentence += `, due ${DateTime.fromISO(task.dueDate).toLocaleString()}`;
    }

    sentences.push(sentence);
  }

  return sentences.join('\n');
};

export const chatGPTInternalSummaryPrompt = (
  user: Pick<DetailedUser, 'name' | 'email'>,
  meetingPlan: Pick<
    DetailedMeetingflow,
    'title' | 'startTime' | 'companies' | 'creator' | 'organizer' | 'attendees'
  >,
  internalDomains: string[],
  tasks: boolean,
) => {
  const userDomain = getDomain(user.email);
  const companies = companiesWithAttendees(meetingPlan);

  const externalCompanies = externalCompaniesWithAttendees(
    meetingPlan,
    internalDomains,
  );

  const hasExternalCompanies = !!externalCompanies.length;

  const userCompany = companies.find((c) =>
    c.domains.some((d) => d.domain === userDomain),
  );

  const sentences: string[] = [];

  sentences.push(
    `Please study the meeting notes above and understand them in order to formulate a tl;dr.`,
    `\n`,
    `Please include in the tl;dr the answers to the following questions:`,
    `  - What is the most important takeaway from the meeting notes? Label this "Key Takeaway".`,
  );
  if (hasExternalCompanies) {
    sentences.push(
      `  - Do the meeting notes clearly suggest that ${externalCompanies
        .map((c) => c.name || c.legalName)
        .join(' or ')} may buy something ${
        userCompany ? `from ${userCompany.name || userCompany.legalName}` : ''
      } in the near future?  Label this "Sales Opportunity?"`,
    );
  }
  if (hasExternalCompanies && userCompany) {
    sentences.push(
      `  - Do the meeting notes clearly suggest any relationship risk between ${externalCompanies
        .map((c) => c.name || c.legalName)
        .join(' or ')} and ${
        userCompany.name || userCompany.legalName
      }?  Label this "Relationship Risk?"`,
    );
  }
  if (tasks) {
    sentences.push(
      `  - Note any items under "Action items:" and label these "Next Steps"`,
    );
  }

  sentences.push(
    `  - What are the strongly positive or negative sentiment points from the meeting notes?  Label these "Other Points"`,
  );
  sentences.push(`\n`);
  sentences.push(
    `Please write the tl;dr in bulleted output format. For "Next Steps" and "Other Points", if there is more than one statement, format those using use sub-bullets.  Do not make things up; only include what is supported by the meeting notes. Please write the output in brief, informal language.`,
  );

  return sentences.filter(Truthy).join('\n');
};

export const isExternalEventOrMeetingflow = (
  eventOrMeetingflow: {
    creator?: { emailDomain: string } | null;
    organizer?: { emailDomain: string } | null;
    attendees?: (
      | { emailDomain: string }
      | { contact: { emailDomain: string } }
    )[];
  },
  internalDomains: string[] | Set<string>,
) => {
  const internalDomainSet = isSet(internalDomains)
    ? internalDomains
    : new Set(internalDomains);

  // If there are *no* domains at all, then treat as internal
  if (
    !eventOrMeetingflow.creator &&
    !eventOrMeetingflow.organizer &&
    !eventOrMeetingflow.attendees?.length
  ) {
    return false;
  }

  const allDomains = DeduplicateArray(
    [
      eventOrMeetingflow.creator?.emailDomain,
      eventOrMeetingflow.organizer?.emailDomain,
      ...(eventOrMeetingflow.attendees?.map((a) =>
        'contact' in a ? a.contact.emailDomain : a.emailDomain,
      ) || []),
    ].filter(Truthy),
  );

  return allDomains.some(
    (d) =>
      !COMMON_INTERNAL_RESOURCE_DOMAINS.includes(d) &&
      !internalDomainSet.has(d),
  );
};

export const isInternalEventOrMeetingflow = (
  eventOrMeetingflow: {
    creator?: { emailDomain: string } | null;
    organizer?: { emailDomain: string } | null;
    attendees?: (
      | { emailDomain: string }
      | { contact: { emailDomain: string } }
    )[];
  },
  internalDomains: string[] | Set<string>,
) => !isExternalEventOrMeetingflow(eventOrMeetingflow, internalDomains);
