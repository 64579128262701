import { mergeStyles, Stack, Text } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../Themes/Themes';
import { PropsWithChildren } from 'react';
import classNames from 'classnames';

export interface NotFoundLayoutProps {
  illustration: React.ReactNode;
  className?: string;
  hideIllustrationBreakpoint?: number;
  resizeTextBreakpoint?: number;
}

export const NotFoundLayout = ({
  children,
  illustration,
  className,
}: PropsWithChildren<NotFoundLayoutProps>) => {
  const wrapperStyle = mergeStyles({
    height: '100vh',
    width: '100%',
    backgroundColor: DEALROOMS_COLORS.neutralLighter,

    '.main-container': {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      maxWidth: '1500px',
      margin: '0 auto',
      alignItems: 'center',
      gap: '2rem',
      padding: '2rem',
      overflow: 'auto',

      '.content': {
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem',
        flexShrink: '0',
        maxWidth: '600px',
        '@media(max-width: 900px)': {
          flexShrink: 1,
        },
      },

      '.illustration': {
        flexGrow: 1,
        maxWidth: '700px',
        maxHeight: '700px',
        flexShrink: '2',

        svg: {
          width: '100%',
          height: '100%',
        },

        '@media(max-width: 900px)': {
          display: 'none',
        },
      },

      '@media(max-width: 900px)': {
        justifyContent: 'center',
        gap: '0',
      },
    },
  });

  return (
    <Stack className={classNames(wrapperStyle, className)}>
      <Stack className="main-container">
        <Stack.Item className="content">{children}</Stack.Item>
        <Stack.Item className="illustration">{illustration}</Stack.Item>
      </Stack>
    </Stack>
  );
};
