import { mergeStyleSets, Spinner, Text, useTheme } from '@fluentui/react';
import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router';
import { useDealRoom } from '../../Hooks/useDealRoom';
import { DEALROOMS_COLORS } from '../../Themes/Themes';
import ResourceNotFound from '../ResourceNotFound';

import { DealRoomBuyersArtifactsTab } from './Tabs/DealRoomBuyersArtifactsTab';
import { DealRoomBuyersOverviewTab } from './Tabs/DealRoomBuyersOverviewTab';
import { DealRoomBuyersJourneyTab } from './Tabs/DealRoomBuyersJourneyTab';
import { DSConfigurationRoot } from './Configuration/DSConfigurationRoot';
import { UserSettingsWrapper } from './Components/UserSettings/UserSettingsWrapper';
import { useUserProfile } from '../../Hooks/useProfile';
import { DealRoomMutualProgressTab } from './Tabs/DealRoomMutualProgressTab';
import { useQueryClient } from 'react-query';

export type DealRoomBuyersViewProps = {
  organizationSlug: string;
  dealRoomId: number;
};

export const DealRoomBuyersView = ({
  organizationSlug,
  dealRoomId,
}: DealRoomBuyersViewProps) => {
  const { user } = useUserProfile();

  const {
    dealRoom,
    isLoading,
    isFetched,
    refetch: refetchDealRoomRoot,
  } = useDealRoom(organizationSlug, dealRoomId);

  const queryClient = useQueryClient();

  const refetchDealRoom = useMemo(
    () => async () => {
      await queryClient.invalidateQueries({
        queryKey: ['artifacts', dealRoomId, organizationSlug],
      });
      await refetchDealRoomRoot();
    },
    [queryClient, dealRoomId, organizationSlug, refetchDealRoomRoot],
  );

  const location = useLocation();

  const activeTab = useMemo(() => {
    const path = `/organization/:organizationSlug/dealroom/:dealRoomId`;

    if (matchPath(`${path}/artifacts`, location.pathname)) {
      return 'artifacts';
    }

    if (matchPath(`${path}/journey`, location.pathname)) {
      return 'journey';
    }

    if (matchPath(`${path}/mutual-action-plan`, location.pathname)) {
      return 'mutual-action-plan';
    }

    if (matchPath(`${path}/settings`, location.pathname)) {
      return 'settings';
    }

    if (matchPath(`${path}/config`, location.pathname)) {
      return 'config';
    }

    return 'overview';
  }, [location.pathname]);

  const styles = mergeStyleSets({
    header: {
      // ...(activeTab === 'mutual-action-plan'
      //   ? {
      //       maxWidth: 'calc(80% - 42px)',
      //     }
      //   : {}),
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      gridTemplateRows: 'auto auto',
      gridTemplateAreas: `
        "title actions"
        "subtitle actions"
      `,

      marginBottom: '1rem',

      '.title': {
        gridArea: 'title',
      },

      '.section-title': {
        fontWeight: 400,
        fontSize: '2.5rem',
        lineHeight: '3rem',
        color: DEALROOMS_COLORS.themePrimary,
      },

      '.subtitle': {
        gridArea: 'subtitle',
      },

      '.actions': {
        gridArea: 'actions',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: '1rem',
      },
    },

    content: {
      '& [role="tabpanel"]': {
        margin: '0.5rem',
      },
    },

    mainContent: {
      width: 'calc(100% - 100px)',
      height: '100%',
      overflowX: 'auto',
      padding: '32px',
    },

    prioritiesWrapper: {
      paddingBottom: '3.5rem',
    },

    headerContentWrapper: {
      marginLeft: 'auto',
    },

    mainWrapper: {
      display: 'flex',
      width: '100%',
      minHeight: '100%',
    },
  });

  const content = useMemo(() => {
    if (!dealRoom) {
      return null;
    }

    switch (activeTab) {
      case 'overview':
        return (
          <DealRoomBuyersOverviewTab
            organizationSlug={organizationSlug}
            dealRoomId={dealRoomId}
            dealRoom={dealRoom!}
            refreshDealRoom={refetchDealRoom}
          />
        );
      case 'artifacts':
        return (
          <DealRoomBuyersArtifactsTab
            organizationSlug={organizationSlug}
            dealRoomId={dealRoomId}
            dealRoom={dealRoom!}
            refreshDealRoom={refetchDealRoom}
          />
        );
      case 'journey':
        return <DealRoomBuyersJourneyTab />;
      case 'mutual-action-plan':
        return (
          // <DealRoomBuyersActionItemsTab
          //   organizationSlug={organizationSlug}
          //   dealRoomId={dealRoomId}
          //   actionItems={dealRoom.mutualActionItems}
          //   activity={dealRoom.activity.filter(
          //     (a) => a.type === 'VIEW_ARTIFACT',
          //   )}
          //   refetchDealRoom={refetchDealRoom}
          // />
          <DealRoomMutualProgressTab
            organizationSlug={organizationSlug}
            dealRoomId={dealRoomId}
          />
        );
      case 'settings':
        return (
          <UserSettingsWrapper
            organizationSlug={organizationSlug}
            dealRoomId={dealRoomId}
            refetchDealRoom={refetchDealRoom}
          />
        );
      case 'config':
        return <DSConfigurationRoot />;
      default:
        return null;
    }
  }, [activeTab, dealRoom, dealRoomId, organizationSlug, refetchDealRoom]);

  const activeTabTitle = useMemo(() => {
    switch (activeTab) {
      case 'overview':
        return `Hello ${user?.firstName || user?.name?.split(/\\s/)?.[0]}!`;
      case 'artifacts':
        return 'Artifacts';
      case 'journey':
        return 'Journey';
      case 'mutual-action-plan':
        return 'Mutual Plan';
      case 'config':
        return 'Configuration';
      case 'settings':
        return 'Settings';
    }
  }, [activeTab, user]);

  // const activeTabHeaderContent = useMemo(() => {
  //   switch (activeTab) {
  //     case 'mutual-action-plan':
  //       return (
  //         <AddMilestone
  //           organizationSlug={organizationSlug}
  //           dealRoomId={dealRoomId}
  //         />
  //       );
  //     default:
  //       return null;
  //   }
  // }, [activeTab, dealRoomId, organizationSlug]);

  if (isLoading && !isFetched) {
    return <Spinner />;
  }

  if (!dealRoom) {
    return <ResourceNotFound resourceType="Deal Room" />;
  }

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.mainContent}>
        <div className={styles.header}>
          <Text className="section-title" variant="xxLargePlus" block>
            {activeTabTitle}
          </Text>
          {/* {activeTabHeaderContent} */}
        </div>
        <div className={styles.content}>{content}</div>
      </div>
    </div>
  );
};

export default DealRoomBuyersView;
