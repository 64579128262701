import {
  Contact,
  DetailedMeetingflow,
  GPTChatBoxMessage,
} from '@meetingflow/common/Api/data-contracts';
import { DateTime } from 'luxon';

export const suggestChatPrompts = (
  time: DateTime,
  meetingflow: Pick<
    DetailedMeetingflow,
    'startTime' | 'endTime' | 'companies' | 'attendees' | 'callRecording'
  >,
  contact: Contact,
): GPTChatBoxMessage[] => {
  const suggestedMessages: GPTChatBoxMessage[] = [];

  const beforeStart = meetingflow
    ? DateTime.fromISO(meetingflow.startTime).diff(time).milliseconds > 0
    : true;
  const beforeEnd = meetingflow
    ? DateTime.fromISO(meetingflow.endTime).diff(time).milliseconds > 0
    : true;
  const afterEnd = meetingflow
    ? DateTime.fromISO(meetingflow.endTime).diff(time).milliseconds < 0
    : false;
  const afterEnd24Hours = meetingflow
    ? DateTime.fromISO(meetingflow.endTime).diff(time).milliseconds < -86400000
    : false;

  const company = meetingflow.companies.find((c) =>
    c.domains.some((d) => d.domain === contact.emailDomain),
  )!;

  if (beforeStart) {
    suggestedMessages.push({
      role: 'user',
      content: `Have I met with ${company.name} before?`,
      display: true,
    });
    suggestedMessages.push({
      role: 'user',
      content: `When did I last meet with ${company.name}?`,
      display: true,
    });
    suggestedMessages.push({
      role: 'user',
      content: `What does ${company.name} do?`,
      display: true,
    });
    suggestedMessages.push({
      role: 'user',
      content: `Please write a bio for ${company.name}`,
      display: true,
      displayText: `${company.name} bio`,
    });
    suggestedMessages.push({
      role: 'user',
      content: `${company.name} news headlines`,
      display: true,
    });
    suggestedMessages.push({
      role: 'user',
      content: `What is some recent news impacting ${company.name}'s industry?`,
      display: true,
      displayText: `${company.name} industry news`,
    });
    suggestedMessages.push({
      role: 'user',
      content: `Is there any recent financial news, such as earnings or investor updates, related to ${company.name}?`,
      display: true,
      displayText: `${company.name} investor updates`,
    });
    suggestedMessages.push({
      role: 'user',
      content: `When did we last meet with ${company.name}?`,
      display: true,
    });
    suggestedMessages.push({
      role: 'user',
      content: `Who last meet with ${company.name}?`,
      display: true,
    });
    suggestedMessages.push({
      role: 'user',
      content: `Summarize the last three meetings ${company.name}?`,
      display: true,
    });
    suggestedMessages.push({
      role: 'user',
      content: `What were the next steps from our last meeting with ${company.name}?`,
      display: true,
    });
    suggestedMessages.push({
      role: 'user',
      content: `Who are ${company.name}'s competitors?`,
      display: true,
    });
    suggestedMessages.push({
      role: 'user',
      content: `Where is ${company.name} headquartered?`,
      display: true,
    });
    suggestedMessages.push({
      role: 'user',
      content: `What are ${company.name} main products or services?`,
      display: true,
      displayText: `What are ${company.name}'s core offerings?`,
    });

    suggestedMessages.push({
      role: 'user',
      content: `Please write a bio for ${contact.name} who works for ${company.name}`,
      display: true,
      displayText: `${company.name} bio`,
    });
    suggestedMessages.push({
      role: 'user',
      content: `What is ${contact.name}'s role at ${company.name}?`,
      display: true,
    });
    suggestedMessages.push({
      role: 'user',
      content: `Have I met with ${contact.name} before?`,
      display: true,
    });
    suggestedMessages.push({
      role: 'user',
      content: `When did I last meet with ${contact.name}?`,
      display: true,
    });
    suggestedMessages.push({
      role: 'user',
      content: `Who last meet with ${contact.name} from ${company.name}?`,
      display: true,
      displayText: `Who last meet with ${contact.name}?`,
    });
    suggestedMessages.push({
      role: 'user',
      content: `When did we last meet with ${contact.name} from ${company.name}?`,
      display: true,
      displayText: `When did we last meet with ${contact.name}?`,
    });
  } else if (beforeEnd) {
    suggestedMessages.push({
      role: 'user',
      content: `What does ${company.name} do?`,
      display: true,
    });
    suggestedMessages.push({
      role: 'user',
      content: `Please write a bio for ${company.name}`,
      display: true,
      displayText: `${company.name} bio`,
    });
    suggestedMessages.push({
      role: 'user',
      content: `${company.name} news headlines`,
      display: true,
    });
    suggestedMessages.push({
      role: 'user',
      content: `What is some recent news impacting ${company.name}'s industry?`,
      display: true,
      displayText: `${company.name} industry news`,
    });
    suggestedMessages.push({
      role: 'user',
      content: `Is there any recent financial news, such as earnings or investor updates, related to ${company.name}?`,
      display: true,
      displayText: `${company.name} investor updates`,
    });
    suggestedMessages.push({
      role: 'user',
      content: `When did we last meet with ${company.name}?`,
      display: true,
    });
    suggestedMessages.push({
      role: 'user',
      content: `Who last meet with ${company.name}?`,
      display: true,
    });
    suggestedMessages.push({
      role: 'user',
      content: `Summarize the last three meetings ${company.name}?`,
      display: true,
    });
    suggestedMessages.push({
      role: 'user',
      content: `What were the next steps from our last meeting with ${company.name}?`,
      display: true,
    });
    suggestedMessages.push({
      role: 'user',
      content: `Who are ${company.name}'s competitors?`,
      display: true,
    });
    suggestedMessages.push({
      role: 'user',
      content: `Where is ${company.name} headquartered?`,
      display: true,
    });
    suggestedMessages.push({
      role: 'user',
      content: `What are ${company.name} main products or services?`,
      display: true,
      displayText: `What are ${company.name}'s core offerings?`,
    });
    suggestedMessages.push({
      role: 'user',
      content: `Please write a bio for ${contact.name} who works for ${company.name}`,
      display: true,
      displayText: `${company.name} bio`,
    });
    suggestedMessages.push({
      role: 'user',
      content: `What is ${contact.name}'s role at ${company.name}?`,
      display: true,
    });
    suggestedMessages.push({
      role: 'user',
      content: `Who last meet with ${contact.name} from ${company.name}?`,
      display: true,
      displayText: `Who last meet with ${contact.name}?`,
    });
    suggestedMessages.push({
      role: 'user',
      content: `When did we last meet with ${contact.name} from ${company.name}?`,
      display: true,
      displayText: `When did we last meet with ${contact.name}?`,
    });
  } else if (afterEnd) {
    if (!afterEnd24Hours) {
      suggestedMessages.push({
        role: 'user',
        content: `Please draft a thank you email to the attendees of the meeting.`,
        display: true,
        displayText: `Draft a thank you email`,
      });
      suggestedMessages.push({
        role: 'user',
        content: `Please draft a follow-up email to the attendees of the meeting thanking them for their time and providing a overview of what was discussed, decisions made, and next steps.`,
        display: true,
        displayText: `Draft a follow-up email`,
      });
      suggestedMessages.push({
        role: 'user',
        content: `Please draft a slack update suitable sharing with my colleages to provide a TL;DR; of the meeting.`,
        display: true,
        displayText: `Draft a slack update`,
      });
    }

    if (
      meetingflow.callRecording &&
      meetingflow.callRecording.lastStatus !== 'deleted'
    ) {
      suggestedMessages.push({
        role: 'user',
        content: `Summarize ${contact.name}'s thoughts from the call`,
        display: true,
        displayText: `How did it go?`,
      });
    }

    suggestedMessages.push({
      role: 'user',
      content: `What does ${company.name} do?`,
      display: true,
    });
    suggestedMessages.push({
      role: 'user',
      content: `Please write a bio for ${company.name}`,
      display: true,
      displayText: `${company.name} bio`,
    });
    suggestedMessages.push({
      role: 'user',
      content: `${company.name} news headlines`,
      display: true,
    });
    suggestedMessages.push({
      role: 'user',
      content: `What is some recent news impacting ${company.name}'s industry?`,
      display: true,
      displayText: `${company.name} industry news`,
    });
    suggestedMessages.push({
      role: 'user',
      content: `Is there any recent financial news, such as earnings or investor updates, related to ${company.name}?`,
      display: true,
      displayText: `${company.name} investor updates`,
    });
    suggestedMessages.push({
      role: 'user',
      content: `When did we next meeting with ${company.name}?`,
      display: true,
    });
    suggestedMessages.push({
      role: 'user',
      content: `Who is next meeting with ${company.name}?`,
      display: true,
    });
    suggestedMessages.push({
      role: 'user',
      content: `Who are ${company.name}'s competitors?`,
      display: true,
    });
    suggestedMessages.push({
      role: 'user',
      content: `Where is ${company.name} headquartered?`,
      display: true,
    });
    suggestedMessages.push({
      role: 'user',
      content: `What are ${company.name} main products or services?`,
      display: true,
      displayText: `What are ${company.name}'s core offerings?`,
    });

    suggestedMessages.push({
      role: 'user',
      content: `Please write a bio for ${contact.name} who works for ${company.name}`,
      display: true,
      displayText: `${contact.name} bio`,
    });
    suggestedMessages.push({
      role: 'user',
      content: `What is ${contact.name}'s role at ${company.name}?`,
      display: true,
    });
    suggestedMessages.push({
      role: 'user',
      content: `Who is next meeting with ${contact.name}?`,
      display: true,
      displayText: `Who is next meeting with ${contact.name} from ${company.name}?`,
    });
    suggestedMessages.push({
      role: 'user',
      content: `When are we next meeting with ${contact.name}?`,
      display: true,
      displayText: `When are we next meeting with ${contact.name} from ${company.name}?`,
    });
  }

  return suggestedMessages;
};
