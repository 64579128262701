import { useCallback } from 'react';
import {
  NavigateOptions,
  useNavigate as reactRouterUseNavigate,
  To,
} from 'react-router';

export const useNavigate = () => {
  const rrNavigate = reactRouterUseNavigate();

  const navigate = useCallback(
    (to: To, options?: NavigateOptions & { preserveQuery?: boolean }) => {
      if (!!options?.preserveQuery && 'location' in window) {
        const { location } = window;

        if (location.search && typeof to === 'string' && !to.includes('?')) {
          to += location.search;
        } else if (
          location.search &&
          typeof to === 'string' &&
          to.includes('?')
        ) {
          const toParams = new URLSearchParams(to.split('?')[1]);
          const searchParams = new URLSearchParams(location.search);
          searchParams.forEach((value, key) => {
            if (!toParams.has(key)) {
              toParams.set(key, value);
            }
          });
          to = `${to.split('?')[0]}?${toParams.toString()}`;
        } else if (location.search && typeof to === 'object' && !to.search) {
          to.search = location.search;
        } else if (location.search && typeof to === 'object' && to.search) {
          const searchParams = new URLSearchParams(to.search);
          const locationSearchParams = new URLSearchParams(location.search);
          locationSearchParams.forEach((value, key) => {
            if (!searchParams.has(key)) {
              searchParams.set(key, value);
            }
          });
          to.search = `?${searchParams.toString()}`;
        }
      }
      rrNavigate(to, options);
    },
    [rrNavigate],
  );

  return navigate;
};
