import { CustomElement } from '@meetingflow/common/Types/Slate';
import { escapeRegExp } from 'lodash';
import { Editor, Element, Point, Range, Text, Transforms } from 'slate';
import {
  beforeText,
  getParentBlock,
  isBlockActive,
} from '../Helpers/EditorHelpers';

const LIST_TYPES: Element['type'][] = ['numbered-list', 'bulleted-list'];

const inlineRegex = (trigger: string) => {
  const escaped = escapeRegExp(trigger);
  return new RegExp(`(${escaped}).+?(${escaped})$`);
};

const blockRegex = (trigger: string): RegExp => {
  const escaped = escapeRegExp(trigger);
  return new RegExp(`^(${escaped})$`);
};

const createSetBlockApply =
  (type: Element['type'], additionalProps?: Partial<Element>) =>
  (editor: Editor, range: Range) => {
    const isActive = isBlockActive(editor, type);
    const isList = LIST_TYPES.includes(type);

    Editor.withoutNormalizing(editor, () => {
      Transforms.unwrapNodes(editor, {
        match: (n) => Element.isElement(n) && LIST_TYPES.includes(n.type),
        split: true,
        mode: 'all',
      });

      Transforms.setNodes(
        editor,
        { type: isList ? 'list-item' : type, ...additionalProps },
        {
          match: (n) => Element.isElement(n) && Editor.isBlock(editor, n),
          at: range,
        },
      );

      if (!isActive && isList) {
        const block = { type, children: [] } as CustomElement;
        Transforms.wrapNodes(editor, block);
      }
    });
  };

const createSetMarkApply =
  (key: Exclude<keyof Text, 'text'>) => (editor: Editor, range: Range) => {
    Transforms.insertNodes(
      editor,
      { text: ' ' },
      {
        match: Text.isText,
        at: Range.end(range),
        select: true,
      },
    );
    Transforms.setNodes(
      editor,
      { [key]: true },
      {
        match: Text.isText,
        at: range,
        split: true,
      },
    );
  };

const SHORTCUTS = [
  {
    trigger: blockRegex('#'),
    isBlock: true,
    apply: createSetBlockApply('heading-one'),
  },
  {
    trigger: blockRegex('##'),
    isBlock: true,
    apply: createSetBlockApply('heading-two'),
  },
  {
    trigger: blockRegex('###'),
    isBlock: true,
    apply: createSetBlockApply('heading-three'),
  },
  {
    trigger: blockRegex('####'),
    isBlock: true,
    apply: createSetBlockApply('heading-four'),
  },
  {
    trigger: blockRegex('>'),
    isBlock: true,
    apply: createSetBlockApply('block-quote'),
  },
  {
    trigger: blockRegex('-'),
    isBlock: true,
    apply: createSetBlockApply('bulleted-list'),
  },
  {
    trigger: blockRegex('*'),
    isBlock: true,
    apply: createSetBlockApply('bulleted-list'),
  },
  {
    trigger: blockRegex('1.'),
    isBlock: true,
    apply: createSetBlockApply('numbered-list'),
  },
  {
    trigger: blockRegex('[]'),
    isBlock: true,
    apply: createSetBlockApply('checklist-item', { checked: false }),
  },
  {
    trigger: blockRegex('[ ]'),
    isBlock: true,
    apply: createSetBlockApply('checklist-item', { checked: false }),
  },
  {
    trigger: blockRegex('[x]'),
    isBlock: true,
    apply: createSetBlockApply('checklist-item', { checked: true }),
  },
  {
    trigger: inlineRegex('**'),
    isBlock: false,
    apply: createSetMarkApply('bold'),
  },
  {
    trigger: inlineRegex('__'),
    isBlock: false,
    apply: createSetMarkApply('bold'),
  },
  {
    trigger: inlineRegex('*'),
    isBlock: false,
    apply: createSetMarkApply('italic'),
  },
  {
    trigger: inlineRegex('_'),
    isBlock: false,
    apply: createSetMarkApply('italic'),
  },
  {
    trigger: inlineRegex('~~'),
    isBlock: false,
    apply: createSetMarkApply('strikethrough'),
  },
];

const before = (
  editor: Editor,
  at: Point,
  stringOffset: number,
): Point | undefined => {
  if (at.offset >= stringOffset) {
    return { offset: at.offset - stringOffset, path: at.path };
  }

  const entry = Editor.previous(editor, { at: at.path, match: Text.isText });
  if (!entry) {
    return undefined;
  }

  const [node, path] = entry;
  return before(
    editor,
    { offset: node.text.length, path },
    stringOffset - at.offset,
  );
};

export const withMarkdown = (editor: Editor) => {
  const { insertText } = editor;

  editor.insertText = (insert) => {
    const { selection } = editor;

    if (insert !== ' ' || !selection || !Range.isCollapsed(selection)) {
      return insertText(insert);
    }

    const { anchor } = selection;
    const block = getParentBlock(editor);
    const blockType = block?.[0].type;
    const textBefore = beforeText(editor) ?? '';

    for (const { trigger, isBlock, apply } of SHORTCUTS) {
      if (blockType !== 'paragraph' && isBlock) {
        continue;
      }

      const match = trigger.exec(textBefore);
      if (!match) {
        continue;
      }

      const [text, startText, endText] = match;
      Editor.withoutNormalizing(editor, () => {
        const matchEnd = anchor;
        const endMatchStart =
          endText && before(editor, matchEnd, endText.length);
        const startMatchEnd =
          startText && before(editor, matchEnd, text.length - startText.length);
        const matchStart = before(editor, matchEnd, text.length);

        if (!matchEnd || !matchStart) {
          return;
        }

        const matchRangeRef = Editor.rangeRef(editor, {
          anchor: matchStart,
          focus: matchEnd,
        });

        if (endMatchStart) {
          Transforms.delete(editor, {
            at: { anchor: endMatchStart, focus: matchEnd },
          });
        }
        if (startMatchEnd) {
          Transforms.delete(editor, {
            at: { anchor: matchStart, focus: startMatchEnd },
          });
        }

        const applyRange = matchRangeRef.unref();
        if (applyRange) {
          apply(editor, applyRange);
        }
      });

      return;
    }

    insertText(insert);
  };

  return editor;
};
