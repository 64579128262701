import { styled } from '@mui/material';

export const StyledListWithFilters = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  background-color: white;
  padding: 1rem;
  gap: 1rem;

  .list-filters {
  }

  .list-container {
    flex: 1;
    overflow: auto;
  }
`;