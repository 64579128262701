import { DEALROOMS_COLORS } from "../../../Themes/Themes";

export const dropdownMenuStyles = {
    root: {
        ':hover': {
            '.ms-Dropdown-title': {
                color: `${DEALROOMS_COLORS.black} !important`,
            },
        },
    },
    dropdownItem: {
      backgroundColor: `${DEALROOMS_COLORS.white}`,
      color: `${DEALROOMS_COLORS.black} !important`,

      '&:hover': {
        backgroundColor: `${DEALROOMS_COLORS.neutralLight}`,
        color: `${DEALROOMS_COLORS.black} !important`,
      },
    },
    dropdownItemSelected: {
      backgroundColor: `${DEALROOMS_COLORS.themeSecondary}`,
      color: `${DEALROOMS_COLORS.white} !important`,

      '&:hover': {
        backgroundColor: `${DEALROOMS_COLORS.themeSecondary}`,
        color: `${DEALROOMS_COLORS.white} !important`,
      },
    },
};
