import React from 'react';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import { DSMenuItemRoot } from './DSMenuItem.styles';
import { useDealRoom } from '../../../Hooks/useDealRoom';

export interface DSMenuItemProps extends MenuItemProps {
  primaryText: string | React.ReactNode;
  secondaryText?: string | React.ReactNode;
  icon?: React.ReactNode;
  iconColor?: string;
  branded?: boolean;
}

const DSMenuItem: React.FC<DSMenuItemProps> = ({
  primaryText,
  secondaryText,
  icon,
  iconColor,
  branded,
  ...props
}) => {
  const { dealRoom } = useDealRoom();
  const dealRoomPrimaryColor = dealRoom?.primaryColor;
  const menuItemColor =
    iconColor || (branded && dealRoomPrimaryColor) || 'inherit';

  return (
    <MenuItem {...props}>
      <DSMenuItemRoot>
        {icon && (
          <div className="icon" style={{ color: menuItemColor }}>
            {icon}
          </div>
        )}
        <div className="text">
          <span className="primaryText"> {primaryText}</span>
          {secondaryText && (
            <span className="secondaryText">{secondaryText}</span>
          )}
        </div>
      </DSMenuItemRoot>
    </MenuItem>
  );
};

export default DSMenuItem;
