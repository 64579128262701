import { useState, useEffect } from 'react';

const useDeviceType = (
  mobileWidth: number = 768,
  tabletWidth: number = 1024,
) => {
  const [deviceType, setDeviceType] = useState({
    isDesktop: true, // Default to desktop
    isTablet: false,
    isMobile: false,
  });

  useEffect(() => {
    // Function to check window size and user agent
    const checkDeviceType = () => {
      const width = window.innerWidth;
      const userAgent = navigator.userAgent.toLowerCase();

      // Detect device type based on width and user agent
      if (
        /mobile|android|iphone|ipad|ipod|blackberry|windows phone/.test(
          userAgent,
        ) ||
        width <= mobileWidth
      ) {
        setDeviceType({
          isDesktop: false,
          isTablet: false,
          isMobile: true, // Mobile devices
        });
      } else if (
        (width > mobileWidth && width <= tabletWidth) ||
        /tablet|ipad/.test(userAgent)
      ) {
        setDeviceType({
          isDesktop: false,
          isTablet: true, // Tablet devices
          isMobile: false,
        });
      } else {
        setDeviceType({
          isDesktop: true, // Desktop devices
          isTablet: false,
          isMobile: false,
        });
      }
    };

    // Check device type on initial load
    checkDeviceType();

    // Add resize event listener to detect changes
    window.addEventListener('resize', checkDeviceType);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', checkDeviceType);
    };
  }, [mobileWidth, tabletWidth]);

  return deviceType;
};

export default useDeviceType;
