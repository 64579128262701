import {
  Contact,
  DetailedMeetingflow,
  PatchTaskPayload,
  PostTaskPayload,
  Task,
} from '@meetingflow/common/Api/data-contracts';
import { MeetingPlanActionItemsPanelContext } from '../../../types/MeetingPlanPanelContext';
import { MeetingPlanActionItems } from '../Sections/MeetingPlanActionItems';
import { BaseSidePanel } from './BaseSidePanel';

export interface ActionItemsSidePanelProps {
  meetingPlan:
    | Pick<
        DetailedMeetingflow,
        | 'id'
        | 'attendees'
        | 'creator'
        | 'organizer'
        | 'companies'
        | 'userActivity'
        | 'textSummary'
        | 'tasks'
      >
    | undefined;
  organizationSlug: string;
  onBack?: () => void;
  onClose: () => void;
  onContactClick: (c: Contact['id']) => void;

  tasks: Task[] | Partial<Task>[];
  suggestedTasks: Task[] | Partial<Task>[];
  loading?: boolean;
  onAdd?: (task: PostTaskPayload) => void;
  onUpdate: (id: Task['id'], patch: PatchTaskPayload) => void;
  onDelete: (id: Task['id']) => void;

  actionItemsContext?: MeetingPlanActionItemsPanelContext;
}

export const ActionItemsSidePanel = ({
  meetingPlan,
  organizationSlug,
  onBack,
  onClose,
  onContactClick,
  tasks,
  loading: tasksLoading,
  onAdd,
  onUpdate,
  onDelete,
  suggestedTasks,
  actionItemsContext,
}: ActionItemsSidePanelProps) => {
  return (
    <BaseSidePanel title="Action Items" onBack={onBack} onClose={onClose}>
      {meetingPlan && tasks ? (
        <>
          <MeetingPlanActionItems
            organizationSlug={organizationSlug}
            loading={tasksLoading}
            tasks={tasks}
            suggestedTasks={suggestedTasks}
            onAdd={onAdd}
            onUpdate={onUpdate}
            onDelete={onDelete}
            meetingPlanId={meetingPlan?.id}
            actionItemsContext={actionItemsContext}
            actionItemsTitle="Action Items"
            suggestedActionItemsTitle="Suggested From Your Call Transcript"
          />
        </>
      ) : null}
    </BaseSidePanel>
  );
};
