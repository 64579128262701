import { useAuth0 } from '@auth0/auth0-react';
import {
  FontSizes,
  IDialogContentProps,
  mergeStyles,
  NeutralColors,
  SearchBox,
  Spinner,
  Text,
} from '@fluentui/react';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { BaseModal } from '../../Components/MeetingPlans/Dialogs/BaseModal';
import { SalesforceContactTile } from '../../Components/MeetingPlans/SidePanels/Salesforce/SalesforceContactTile';
import { SalesforceContact } from '../../Models/Salesforce/SalesforceContact';
import { SalesforceContactsQuery } from '../../QueryNames';
import { ApiClient } from '../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';
import useBreakpoints from '../useBreakpoints';
import { useDeferredPromise } from '../useDeferredPromise';
import { useExternalServiceConfigurations } from '../useExternalServiceConfigurations';
import { useLightOrDarkMode } from '../useLightOrDarkMode';

export type UsePickSalesforceContactModalProps = Pick<
  IDialogContentProps,
  'title' | 'subText' | 'showCloseButton'
> & {
  organizationSlug: string;
  salesforceConfigurationId: number;
};
export const usePickSalesforceContactModal = ({
  title,
  subText,
  showCloseButton = true,
  organizationSlug,
  salesforceConfigurationId,
}: UsePickSalesforceContactModalProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const breakpoints = useBreakpoints();
  const { createDeferred, resolve, reject, deferred } = useDeferredPromise<
    string | null
  >();
  const { isDark } = useLightOrDarkMode();

  const [q, setQ] = useState<string>('');

  const { loading: configurationsLoading, configurationById } =
    useExternalServiceConfigurations({ app: 'SALESFORCE', withToken: true });

  const {
    data: salesforceContacts,
    isLoading: salesforceContactsLoading,
    isRefetching: salesforceContactsRefetching,
    refetch: refetchSalesforceContacts,
  } = useQuery(
    SalesforceContactsQuery(organizationSlug!, salesforceConfigurationId),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<SalesforceContact[]>(
        `/organization/${organizationSlug}/external/salesforce/configuration/${salesforceConfigurationId}/contacts`,
        {
          params: {
            q: q ?? undefined,
            domains: undefined,
          },
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRefetch = useCallback(
    debounce(refetchSalesforceContacts, 250),
    [refetchSalesforceContacts],
  );

  useEffect(() => {
    debouncedRefetch();
  }, [q, debouncedRefetch]);

  useEffect(() => {
    refetchSalesforceContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesforceConfigurationId]);

  const panelContent = mergeStyles({
    padding: '0 .5rem',
    height: '100%',
    minHeight: '100%',
  });

  const slideSpinnerClass = mergeStyles({
    height: '2rem',
    animationName: 'slideDownSpinnerAnimation',
    animationDuration: '1s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '.5',
    animationFillMode: 'forwards',
    position: 'absolute',
    top: '4.35rem',
    right: '.5rem',
  });

  const dialog = (
    <BaseModal
      title={(title as string) ?? 'Select Salesforce Contact'}
      subtitle={subText}
      styles={{
        root: {
          '.ms-Dialog-main': {
            overflow: 'hidden',
          },
        },
        layer: {
          '.ms-Dialog-main': {
            overflow: 'hidden',
          },
        },
        main: {
          maxWidth: breakpoints.lg ? '120rem' : '90%',
          height: 'auto',
          maxHeight: '80%',
          backgroundColor: isDark
            ? NeutralColors.gray180
            : MEETINGFLOW_COLORS.white,
        },
        scrollableContent: {
          overflow: 'hidden',
          '> div:first-child': {
            backgroundColor: MEETINGFLOW_COLORS.orange,
            marginBottom: '0 !important',
            padding: '0',

            'div:first-child': {
              borderBottom: 'none !important',
            },

            button: {
              backgroundColor: 'transparent !important',
              transition: '.3s ease-in-out all',
              ':hover': {
                backgroundColor: 'rgba(255,255,255,.25) !important',
              },
            },

            '> div': {
              marginBottom: '0 !important',
              padding: '.25rem .5rem !important',
              span: {
                color: MEETINGFLOW_COLORS.white,
                fontSize: FontSizes.medium,
              },
            },
          },
        },
      }}
      isOpen={deferred?.isPending}
      onDismiss={() => reject('Cancelled')}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          columnGap: '0',
          rowGap: '0',
          margin: '0',
          padding: ' 0 0 .5rem .5rem',
        }}
      >
        <div className={panelContent}>
          <div
            style={{
              marginLeft: '-1rem',
              marginRight: '-.5rem',
              marginBottom: '.5rem',
              padding: '.5rem',
              borderBottom: `1px solid ${
                isDark ? NeutralColors.gray180 : MEETINGFLOW_COLORS.purpleGrey
              }`,
              backgroundColor: isDark
                ? NeutralColors.gray210
                : MEETINGFLOW_COLORS.purpleUltraLight,
            }}
          >
            <SearchBox
              disabled={
                salesforceContactsLoading || salesforceContactsRefetching
              }
              placeholder="Search contacts by name..."
              value={q}
              onChange={(e, newValue) => setQ(newValue ?? '')}
              styles={{
                root: {
                  transition: '.3s ease-in-out all',
                  fontSize: FontSizes.small,
                  height: '1.5rem',
                  marginTop: '.5rem',
                  width:
                    configurationsLoading ||
                    salesforceContactsLoading ||
                    salesforceContactsRefetching
                      ? 'calc(100% - 2rem)'
                      : '100%',
                },
              }}
            />
          </div>
        </div>
        <div style={{ maxHeight: 'calc(90vh - 15rem)', overflow: 'auto' }}>
          <div style={{ padding: '0 .5rem' }}>
            {salesforceContactsLoading || salesforceContactsRefetching ? (
              <Spinner className={slideSpinnerClass} />
            ) : null}
            {salesforceContacts?.data &&
            salesforceContacts?.data?.length > 0 ? (
              salesforceContacts?.data?.map((contact) => {
                return (
                  <SalesforceContactTile
                    key={contact.Id}
                    organizationSlug={organizationSlug}
                    externalId={contact.Id}
                    name={contact.Name}
                    email={contact.Email}
                    salesforceInstance={
                      configurationById(salesforceConfigurationId)!.instanceId!
                    }
                    onClick={() => resolve(contact.Id)}
                  />
                );
              })
            ) : (
              <div>
                {!salesforceContactsLoading && !salesforceContactsRefetching ? (
                  <Text
                    block
                    variant="small"
                    style={{ fontStyle: 'italic', margin: '5rem 0' }}
                  >
                    There are no matching contacts. Change your filters above to
                    show all contacts, if needed.
                  </Text>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    </BaseModal>
  );

  return {
    createDeferred,
    resolve,
    reject,
    deferred,
    dialog,
  };
};
