import { useAuth0 } from '@auth0/auth0-react';
import { FontSizes, NeutralColors } from '@fluentui/theme';
import { Application } from '@meetingflow/common/Api/data-contracts';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { isArray } from 'lodash';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { IntegrationTile } from '../Components/Integrations/IntegrationTile';
import { BaseModal } from '../Components/MeetingPlans/Dialogs/BaseModal';
import {
  ApiClient,
  ExternalServicesApiClient,
} from '../Services/NetworkCommon';
import hubspotIcon from '../Static/Images/hubspot.png';
import teamsIcon from '../Static/Images/ms-teams.png';
import salesforceIcon from '../Static/Images/salesforce.png';
import slackIcon from '../Static/Images/slack.png';
import { MEETINGFLOW_COLORS } from '../Themes/Themes';
import useBreakpoints from './useBreakpoints';
import { useDeferredPromise } from './useDeferredPromise';
import { useExternalServiceConfigurations } from './useExternalServiceConfigurations';
import { useLightOrDarkMode } from './useLightOrDarkMode';
import { useOrganization } from './useOrganization';
import { usePopupAuth } from './usePopupOauth';

export type AddIntegrationResult = { app: Application; added: boolean };

export type AddIntegrationModalOptions = {
  displayOnly?: Application | Application[];
  title?: string;
  description?: string | ReactNode;
};

export const useAddIntegrationModal = (
  { displayOnly, title, description }: AddIntegrationModalOptions = {
    displayOnly: undefined,
    title: 'Integrations',
    description: undefined,
  },
) => {
  const navigate = useNavigate();
  const appInsights = useAppInsightsContext();
  const { getAccessTokenSilently } = useAuth0();
  const { slug, role } = useOrganization();
  const breakpoints = useBreakpoints();
  const { createPopup, showAuth, closePopup, inProgress } = usePopupAuth();
  const { createDeferred, resolve, reject, deferred } = useDeferredPromise<
    AddIntegrationResult | undefined,
    AddIntegrationModalOptions | undefined
  >();
  const { isDark } = useLightOrDarkMode();

  const { configurationsWithToken, hasToken, fetch, refetch, refetchAll } =
    useExternalServiceConfigurations();

  useEffect(() => {
    refetch();
  }, [refetch]);

  const servicesToDisplay = useMemo(() => {
    if (deferred?.context?.displayOnly) {
      if (isArray(deferred?.context?.displayOnly)) {
        return deferred.context.displayOnly;
      } else {
        return [deferred.context.displayOnly];
      }
    } else if (displayOnly) {
      if (isArray(displayOnly)) {
        return displayOnly;
      } else {
        return [displayOnly];
      }
    } else {
      return ['HUBSPOT', 'SALESFORCE', 'SLACK', 'TEAMS'] as Application[];
    }
  }, [deferred?.context?.displayOnly, displayOnly]);

  const removeIntegration = useCallback(
    (app: Application) => async () => {
      const tokenId = configurationsWithToken?.find(
        (config) =>
          config.app === app && config.externalServiceUserTokens?.length,
      )?.externalServiceUserTokens?.[0]?.id;

      if (!tokenId) {
        return;
      }

      const token = await getAccessTokenSilently();
      const result = await ExternalServicesApiClient.deleteUserToken(
        slug!,
        tokenId,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      if (result.status === 204) {
        appInsights.trackEvent({
          name: 'REMOVE_INTEGRATION',
          properties: {
            organizationSlug: slug,
            app,
          },
        });

        refetchAll();
        resolve({ app, added: false });
      }
    },
    [
      appInsights,
      configurationsWithToken,
      getAccessTokenSilently,
      refetchAll,
      resolve,
      slug,
    ],
  );

  const dialog = (
    <BaseModal
      title={deferred?.context?.title || title || 'Integrations'}
      styles={{
        root: {},
        layer: {},
      }}
      isOpen={deferred?.isPending}
      onDismiss={() => resolve?.(undefined)}
    >
      {deferred?.context?.description || description ? (
        <div>{deferred?.context?.description || description}</div>
      ) : null}

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: servicesToDisplay.length > 1 ? '1fr 1fr' : '1fr',
          columnGap: '.75rem',
          rowGap: '.75rem',
          margin: '.5rem',
        }}
      >
        {servicesToDisplay.includes('SALESFORCE') ? (
          <IntegrationTile
            iconSrc={salesforceIcon}
            iconHeight="32px"
            iconWidth="47px"
            iconAlt="Salesforce"
            title="Salesforce"
            description="Update opportunity fields and log your meetings in Salesforce."
            integrationActive={hasToken('SALESFORCE')}
            needsCreatorRole={!['ADMIN', 'CREATOR'].includes(role || '')}
            disabled={inProgress || !['ADMIN', 'CREATOR'].includes(role || '')}
            noMaxWidth={servicesToDisplay.length === 1}
            onAdd={async () => {
              const createdPopup = createPopup(
                `Salesforce Login`,
                `Redirecting you to Salesforce login`,
              );
              try {
                const token = await getAccessTokenSilently();
                const res = await ApiClient.get(
                  `/organization/${slug!}/external/salesforce/oauth/url`,
                  {
                    params: {},
                    headers: { Authorization: `Bearer ${token}` },
                  },
                );

                if (res.status === 200) {
                  if (createdPopup) {
                    await showAuth(res.data);
                    const updatedConfigurations = await fetch('SALESFORCE');
                    // If we have more Salesforce configurations with a token than before, we added one
                    if (
                      updatedConfigurations.filter(
                        (configuration) =>
                          configuration.externalServiceUserTokens?.length,
                      ).length >
                      configurationsWithToken.filter(
                        (configuration) => configuration.app === 'SALESFORCE',
                      ).length
                    ) {
                      toast.success(
                        `Successfully added new Salesforce integration!`,
                      );
                      appInsights.trackEvent({
                        name: 'ADD_INTEGRATION',
                        properties: {
                          organizationSlug: slug,
                          app: 'SALESFORCE',
                        },
                      });

                      refetchAll();
                      resolve({ app: 'SALESFORCE', added: true });
                    }
                  } else {
                    navigate(res.data);
                  }
                } else {
                  closePopup();
                  toast.error('Failed to get salesforce login URL');
                }
              } catch {
                if (createdPopup) {
                  closePopup();
                }
                toast.error('Failed to log in to salesforce');
              }
            }}
            onRemove={removeIntegration('SALESFORCE')}
          />
        ) : null}

        {servicesToDisplay.includes('HUBSPOT') ? (
          <IntegrationTile
            iconSrc={hubspotIcon}
            iconHeight="32px"
            iconWidth="32px"
            iconAlt="HubSpot"
            title="HubSpot"
            description="Update deal fields and log your meetings in HubSpot."
            integrationActive={hasToken('HUBSPOT')}
            needsCreatorRole={!['ADMIN', 'CREATOR'].includes(role || '')}
            disabled={inProgress || !['ADMIN', 'CREATOR'].includes(role || '')}
            noMaxWidth={servicesToDisplay.length === 1}
            onAdd={async () => {
              const createdPopup = createPopup(
                `HubSpot Login`,
                `Redirecting you to HubSpot login`,
              );
              try {
                const token = await getAccessTokenSilently();
                const res = await ApiClient.get(
                  `/organization/${slug!}/external/hubspot/oauth/url`,
                  {
                    params: {},
                    headers: { Authorization: `Bearer ${token}` },
                  },
                );

                if (res.status === 200) {
                  if (createdPopup) {
                    await showAuth(res.data);
                    const updatedConfigurations = await fetch('HUBSPOT');
                    // If we have more Slack configurations with a token than before, we added one
                    if (
                      updatedConfigurations.filter(
                        (configuration) =>
                          configuration.externalServiceUserTokens?.length,
                      ).length >
                      configurationsWithToken.filter(
                        (configuration) => configuration.app === 'HUBSPOT',
                      ).length
                    ) {
                      toast.success(
                        `Successfully added new HubSpot integration!`,
                      );
                      appInsights.trackEvent({
                        name: 'ADD_INTEGRATION',
                        properties: {
                          organizationSlug: slug,
                          app: 'HUBSPOT',
                        },
                      });

                      refetchAll();
                      resolve({ app: 'HUBSPOT', added: true });
                    }
                  } else {
                    navigate(res.data);
                  }
                } else {
                  closePopup();
                  toast.error('Failed to get HubSpot login URL');
                }
              } catch {
                if (!!createdPopup) {
                  closePopup();
                }
                toast.error('Failed to log in to HubSpot');
              }
            }}
            onRemove={removeIntegration('HUBSPOT')}
          />
        ) : null}

        {servicesToDisplay.includes('SLACK') ? (
          <IntegrationTile
            iconSrc={slackIcon}
            iconHeight="32px"
            iconWidth="32px"
            iconAlt="Slack"
            title="Slack"
            description="Send meeting summaries to Slack. Our app for Slack cannot read messages and does not store any data."
            integrationActive={hasToken('SLACK')}
            needsCreatorRole={!['ADMIN', 'CREATOR'].includes(role || '')}
            disabled={inProgress || !['ADMIN', 'CREATOR'].includes(role || '')}
            noMaxWidth={servicesToDisplay.length === 1}
            onAdd={async () => {
              const createdPopup = createPopup(
                `Slack Login`,
                `Redirecting you to Slack login`,
              );
              try {
                const token = await getAccessTokenSilently();
                const res = await ApiClient.get(
                  `/organization/${slug!}/external/slack/oauth/url`,
                  {
                    params: {},
                    headers: { Authorization: `Bearer ${token}` },
                  },
                );

                if (res.status === 200) {
                  if (createdPopup) {
                    await showAuth(res.data);
                    const updatedConfigurations = await fetch('SLACK');
                    // If we have more Slack configurations with a token than before, we added one
                    if (
                      updatedConfigurations.filter(
                        (configuration) =>
                          configuration.externalServiceUserTokens?.length,
                      ).length >
                      configurationsWithToken.filter(
                        (configuration) => configuration.app === 'SLACK',
                      ).length
                    ) {
                      toast.success(
                        `Successfully added new Slack integration!`,
                      );
                      appInsights.trackEvent({
                        name: 'ADD_INTEGRATION',
                        properties: {
                          organizationSlug: slug,
                          app: 'SLACK',
                        },
                      });

                      refetchAll();
                      resolve({ app: 'SLACK', added: true });
                    }
                  } else {
                    navigate(res.data);
                  }
                } else {
                  closePopup();
                  toast.error('Failed to get Slack login URL');
                }
              } catch {
                if (createdPopup) {
                  closePopup();
                }
                toast.error('Failed to log in to Slack');
              }
            }}
            onRemove={removeIntegration('SLACK')}
          />
        ) : null}

        {servicesToDisplay.includes('TEAMS') ? (
          <IntegrationTile
            iconSrc={teamsIcon}
            iconHeight="32px"
            iconWidth="32px"
            iconAlt="Microsoft Teams"
            title="Microsoft Teams"
            description="Send meeting summaries to Microsoft Teams. Our app for Microsoft Teams cannot read messages and does not store any data."
            integrationActive={hasToken('TEAMS')}
            needsCreatorRole={!['ADMIN', 'CREATOR'].includes(role || '')}
            disabled={inProgress || !['ADMIN', 'CREATOR'].includes(role || '')}
            noMaxWidth={servicesToDisplay.length === 1}
            onAdd={async () => {
              const createdPopup = createPopup(
                `Microsoft Teams Login`,
                `Redirecting you to Microsoft Teams login`,
              );
              try {
                const token = await getAccessTokenSilently();
                const res = await ExternalServicesApiClient.getTeamsOauthUrl(
                  { organizationSlug: slug! },
                  {
                    headers: { Authorization: `Bearer ${token}` },
                  },
                );

                if (res.status === 200) {
                  if (createdPopup) {
                    await showAuth(res.data);
                    const updatedConfigurations = await fetch('TEAMS');
                    // If we have more Slack configurations with a token than before, we added one
                    if (
                      updatedConfigurations.filter(
                        (configuration) =>
                          configuration.externalServiceUserTokens?.length,
                      ).length >
                      configurationsWithToken.filter(
                        (configuration) => configuration.app === 'TEAMS',
                      ).length
                    ) {
                      toast.success(
                        `Successfully added new Microsoft Teams integration!`,
                      );
                      appInsights.trackEvent({
                        name: 'ADD_INTEGRATION',
                        properties: {
                          organizationSlug: slug,
                          app: 'TEAMS',
                        },
                      });

                      refetchAll();
                      resolve({ app: 'TEAMS', added: true });
                    }
                  } else {
                    navigate(res.data);
                  }
                } else {
                  closePopup();
                  toast.error('Failed to get Microsoft Teams login URL');
                }
              } catch {
                if (createdPopup) {
                  closePopup();
                }
                toast.error('Failed to log in to Microsoft Teams');
              }
            }}
            onRemove={removeIntegration('TEAMS')}
          />
        ) : null}
      </div>
    </BaseModal>
  );

  return {
    createDeferred,
    resolve,
    reject,
    deferred,
    dialog,
  };
};

export default useAddIntegrationModal;
