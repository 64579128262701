import { ContextualMenu, DirectionalHint } from '@fluentui/react';
import {
  DeleteIconProps,
  DownloadIconProps,
} from '../../../../utils/iconProps';
import { useCallback, useMemo } from 'react';
import saveAs from 'file-saver';
import { useDealRoom } from '../../../../Hooks/useDealRoom';
import toast from 'react-hot-toast';
import { DealRoomsApiClient } from '../../../../Services/NetworkCommon';
import { useNavigate } from '../../../../Hooks/useNavigate';
import { useAuth0 } from '@auth0/auth0-react';
import { DeferredPromise } from '../../../../Helpers/DeferredPromise';
import { ArtifactContextualMenuCustomItem } from './ArtifactContextualMenuCustomItem';
import { useUserProfile } from '../../../../Hooks/useProfile';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { isNil } from 'lodash';

type ArtifactContextualMenuProps = {
  showArtifactMenu: boolean;
  contextMenuTargetId: number | null;
  handleDismissContextualMenu: () => void;
  artifactId: number;
  organizationSlug: string;
  dealRoomId: number;
  createConfirmDeleteDeferred: () => DeferredPromise<boolean, undefined>;
};

export const ArtifactContextualMenu = ({
  showArtifactMenu,
  contextMenuTargetId,
  handleDismissContextualMenu,
  organizationSlug,
  dealRoomId,
  artifactId,
  createConfirmDeleteDeferred,
}: ArtifactContextualMenuProps) => {
  const navigate = useNavigate();

  const { dealRoom, refetch: refetchDealRoom } = useDealRoom(
    organizationSlug,
    dealRoomId,
  );
  const { user: mfUser } = useUserProfile();
  const { role: orgRole } = useOrganization();

  const { getAccessTokenSilently } = useAuth0();

  const switchToOtherAttachment = useCallback(() => {
    const otherAttachments =
      dealRoom?.artifacts?.filter(
        (artifact) =>
          artifact.id !== contextMenuTargetId && !artifact.deletedAt,
      ) || [];
    if (otherAttachments.length) {
      navigate(
        `/organization/${organizationSlug}/dealroom/${dealRoomId}/artifact/${otherAttachments[0].id}`,
        { preserveQuery: true },
      );
    } else {
      navigate(
        `/organization/${organizationSlug}/dealroom/${dealRoomId}/overview/`,
        { preserveQuery: true },
      );
    }
  }, [
    contextMenuTargetId,
    dealRoom?.artifacts,
    dealRoomId,
    navigate,
    organizationSlug,
  ]);

  const getSpecificArtifact = useCallback(
    (targetId: number | null) =>
      dealRoom?.artifacts?.find((artifact) => artifact.id === targetId),
    [dealRoom?.artifacts],
  );

  const handleOnDownloadArtifact = useCallback(() => {
    try {
      const currentArtifact = getSpecificArtifact(contextMenuTargetId);
      if (currentArtifact && 'fileUrl' in currentArtifact) {
        saveAs(currentArtifact.fileUrl, currentArtifact.name);
      }
    } catch (err: unknown) {}
  }, [contextMenuTargetId, getSpecificArtifact]);

  const handleOnDeleteArtifact = useCallback(() => {
    // used then() because contextual menu item can't receive an async onClick func
    if (!contextMenuTargetId) {
      return;
    }

    createConfirmDeleteDeferred()
      .promise.then((confirmDelete) => {
        if (!confirmDelete) {
          return;
        }

        getAccessTokenSilently()
          .then((token) => {
            toast
              .promise(
                DealRoomsApiClient.deleteArtifact(
                  organizationSlug!,
                  dealRoomId,
                  contextMenuTargetId,
                  {
                    headers: { Authorization: `Bearer ${token}` },
                  },
                ),
                {
                  loading: 'Deleting Artifact',
                  success: (_response) => {
                    return 'Successfully deleted Artifact';
                  },
                  error: (err: unknown) => {
                    return 'Something went wrong deleting Artifact, please try again later';
                  },
                },
              )
              .then(() => {
                refetchDealRoom()
                  .then(() => {
                    if (artifactId === contextMenuTargetId) {
                      switchToOtherAttachment();
                    }
                  })
                  .catch((refetchError) => {
                    console.error(
                      'Error during refetching Decision Site:',
                      refetchError,
                    );
                  });
              })
              .catch((toastError) => {
                console.error('Error during deletion:', toastError);
              });
          })
          .catch((tokenError) => {
            console.error('Error fetching access token:', tokenError);
          });
      })
      .catch((confirmError) => {
        console.error('Error during confirmation:', confirmError);
      });
  }, [
    artifactId,
    contextMenuTargetId,
    createConfirmDeleteDeferred,
    dealRoomId,
    getAccessTokenSilently,
    organizationSlug,
    refetchDealRoom,
    switchToOtherAttachment,
  ]);

  const getCurrentContextTarget = useMemo(() => {
    const currentElement = document?.getElementById(
      `attachment-more-options-${contextMenuTargetId}`,
    );

    return currentElement ?? undefined;
  }, [contextMenuTargetId]);

  const isRemoveOptionDisabled = useMemo(() => {
    const currentArtifact = getSpecificArtifact(contextMenuTargetId);

    if (orgRole === 'ADMIN') return false;

    if (
      !isNil(dealRoom?.owner?.id) &&
      !isNil(mfUser?.id) &&
      dealRoom?.owner?.id === mfUser?.id
    ) {
      return false;
    }

    if (
      !isNil(currentArtifact?.creator?.id) &&
      !isNil(mfUser?.id) &&
      currentArtifact?.creator?.id === mfUser?.id
    ) {
      return false;
    }

    return true;
  }, [
    contextMenuTargetId,
    dealRoom?.owner?.id,
    getSpecificArtifact,
    mfUser?.id,
    orgRole,
  ]);

  const contextualMenuItems = useMemo(
    () => [
      {
        key: 'download_attachment',
        text: 'Download',
        iconProps: DownloadIconProps,
        onClick: handleOnDownloadArtifact,
      },
      {
        key: 'remove_attachment',
        text: 'Delete',
        iconProps: DeleteIconProps,
        onClick: handleOnDeleteArtifact,
        disabled: isRemoveOptionDisabled,
      },
    ],
    [handleOnDeleteArtifact, handleOnDownloadArtifact, isRemoveOptionDisabled],
  );

  if (!showArtifactMenu || !contextMenuTargetId) return null;

  return (
    <ContextualMenu
      key={contextMenuTargetId}
      target={getCurrentContextTarget}
      onDismiss={handleDismissContextualMenu}
      directionalHint={DirectionalHint.bottomRightEdge}
      contextualMenuItemAs={ArtifactContextualMenuCustomItem}
      items={contextualMenuItems}
    />
  );
};
