import { MilestoneDatePicker } from './MilestoneDatePicker';
import { Dropdown, IconButton, TextField } from '@fluentui/react';
import {
  dealRoomActionItemStatusOptions,
  isSameDueDate,
} from './milestonesUtils';
import { ActionItemMoreOptions } from './ActionItemMoreOptions';
import { ActionItemOwnerContainer } from './ActionItemOwnerContainer';
import { useCallback, useEffect, useState } from 'react';
import { ActionItem } from '../../../types/MilestoneTypes';
import { DealRoomActionItemStatus } from '@meetingflow/common/Api/data-contracts';
import toast from 'react-hot-toast';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { useAuth0 } from '@auth0/auth0-react';
import { useMutualPlan } from '../../../Hooks/useMutualPlan';
import { CheckMarkIconProps } from '../../../utils/iconProps';
import { useBoolean } from '@fluentui/react-hooks';
import { dropdownMenuStyles } from './styles';
import { ActionItemAttachmentsList } from './ActionItemAttachmentsList';
import { useMilestoneActionItemStyles } from '../../../Hooks/styles/useMilestoneActionItemStyles';
import { CommentsContextualMenu } from './TaskComments/CommentsContextualMenu';

type MilestoneActionItemProps = {
  actionItem: ActionItem;
  onActionItemClick?: () => void;
  organizationSlug: string;
  dealRoomId: number;
  setTaskIdForComments: (taskId: number) => void;
};

export const MilestoneActionItem = ({
  actionItem,
  onActionItemClick,
  organizationSlug,
  dealRoomId,
  setTaskIdForComments,
}: MilestoneActionItemProps) => {
  const [isEditingTitle, { setTrue: setIsEditing, setFalse: setIsNotEditing }] =
    useBoolean(false);
  const [newActionItemTitle, setNewActionItemTitle] = useState('');

  useEffect(() => {
    setNewActionItemTitle(actionItem?.actionItem || '');
  }, [actionItem.actionItem]);

  const { getAccessTokenSilently } = useAuth0();

  const { mutualPlan, refetch: refetchMutualPlan } = useMutualPlan(
    organizationSlug,
    dealRoomId,
  );

  const handleUpdateTitle = useCallback(async () => {
    if (!actionItem.actionItem || newActionItemTitle === actionItem?.actionItem)
      return;
    try {
      const token = await getAccessTokenSilently();
      await toast.promise(
        DealRoomsApiClient.updateActionItemTitle(
          organizationSlug,
          dealRoomId,
          actionItem.id,
          { title: newActionItemTitle },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        ),
        {
          loading: 'Updating task',
          success: (result) => {
            refetchMutualPlan().then();
            return 'Successfully updated the task';
          },
          error: 'Something went wrong updating the task',
        },
      );
    } catch (err) {}
  }, [
    actionItem.actionItem,
    actionItem.id,
    dealRoomId,
    getAccessTokenSilently,
    newActionItemTitle,
    organizationSlug,
    refetchMutualPlan,
  ]);

  const handleUpdateStatus = useCallback(
    async (newStatus: string | number | undefined) => {
      if (typeof newStatus !== 'string' || newStatus === actionItem?.status)
        return;

      try {
        const token = await getAccessTokenSilently();
        await toast.promise(
          DealRoomsApiClient.updateActionItemStatus(
            organizationSlug,
            dealRoomId,
            actionItem.id,
            { status: newStatus as DealRoomActionItemStatus },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          ),
          {
            loading: 'Updating task',
            success: (result) => {
              refetchMutualPlan().then();
              return 'Successfully updated the task';
            },
            error: 'Something went wrong updating the task',
          },
        );
      } catch (err) {}
    },
    [
      actionItem,
      dealRoomId,
      getAccessTokenSilently,
      organizationSlug,
      refetchMutualPlan,
    ],
  );

  const handleUpdateDueDate = useCallback(
    async (newDate: Date | null | undefined) => {
      if (!newDate) return;

      // If the due date hasn't changed, don't make a request
      if (
        actionItem?.dueDate &&
        isSameDueDate(actionItem.dueDate, newDate.toISOString())
      )
        return;

      try {
        const token = await getAccessTokenSilently();
        await toast.promise(
          DealRoomsApiClient.updateActionItemDueDate(
            organizationSlug,
            dealRoomId,
            actionItem.id,
            { dueDate: newDate.toISOString() },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          ),
          {
            loading: 'Updating task',
            success: (result) => {
              refetchMutualPlan().then();
              return 'Successfully updated the task';
            },
            error: 'Something went wrong updating the task',
          },
        );
      } catch (err) {}
    },
    [
      actionItem,
      dealRoomId,
      getAccessTokenSilently,
      organizationSlug,
      refetchMutualPlan,
    ],
  );

  const handleClick = () => {
    onActionItemClick && onActionItemClick();
  };

  const styles = useMilestoneActionItemStyles(actionItem?.status);

  return (
    <div className={styles.taskContainer} onClick={handleClick}>
      <div className={styles.topSection}>
        {isEditingTitle ? (
          <div className={styles.taskTitleEditingContainer}>
            <TextField
              className={styles.taskTitleEditing}
              value={newActionItemTitle}
              onClick={(e) => e.stopPropagation()}
              onChange={(event, newValue) =>
                setNewActionItemTitle(newValue || '')
              }
              onBlur={(e) => {
                if (newActionItemTitle) return;
                setIsNotEditing();
                setNewActionItemTitle(actionItem?.actionItem || '');
              }}
              onKeyDown={async (e) => {
                if (e.key === 'Enter' && newActionItemTitle) {
                  setIsNotEditing();
                  await handleUpdateTitle();
                }
              }}
            />
            {newActionItemTitle && (
              <IconButton
                className={styles.saveTitleButton}
                iconProps={CheckMarkIconProps}
                disabled={!newActionItemTitle}
                onClick={async (e) => {
                  e.stopPropagation();
                  setIsNotEditing();
                  await handleUpdateTitle();
                }}
              />
            )}
          </div>
        ) : (
          <div
            onClick={(e) => {
              e.stopPropagation();
              setIsEditing();
            }}
            className={styles.taskTitle}
          >
            {actionItem?.actionItem}
          </div>
        )}
        <CommentsContextualMenu
          organizationSlug={organizationSlug}
          dealRoomId={dealRoomId}
          taskId={actionItem.id}
          setTaskIdForComments={() => setTaskIdForComments(actionItem.id)}
        />
        <MilestoneDatePicker
          value={actionItem?.dueDate}
          onChange={handleUpdateDueDate}
        />
        <Dropdown
          className={styles.statusDropdown}
          onClick={(e) => e.stopPropagation()}
          options={dealRoomActionItemStatusOptions}
          selectedKey={actionItem?.status}
          onChange={async (ev, currentOption) => {
            await handleUpdateStatus(currentOption?.key);
          }}
          styles={dropdownMenuStyles}
        />
        <ActionItemOwnerContainer
          organizationSlug={organizationSlug}
          dealRoomId={dealRoomId}
          ownerId={actionItem?.assignee?.id}
          actionItemId={actionItem?.id}
          avatarUrl={actionItem?.assignee?.avatarUrl || undefined}
        />
        <ActionItemMoreOptions
          actionItemId={actionItem?.id}
          organizationSlug={organizationSlug}
          dealRoomId={dealRoomId}
          handleViewTask={handleClick}
        />
      </div>
      <ActionItemAttachmentsList actionItem={actionItem} />
    </div>
  );
};
