import { useAuth0 } from '@auth0/auth0-react';
import {
  FontIcon,
  FontSizes,
  FontWeights,
  Text,
  TooltipHost,
  mergeStyles,
} from '@fluentui/react';
import { ExternalServiceObject } from '@meetingflow/common/Api/data-contracts';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useExternalServiceConfigurations } from '../../../../Hooks/useExternalServiceConfigurations';
import { ApiClient } from '../../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../../Themes/Themes';

export type SalesforcePinTileButtonProps = {
  organizationSlug: string;
  meetingPlanId: string;
  objectName: string;
  objectType: string;
  objectId: string;
  externalServiceObjectId?: number;
  tileHasOverline?: boolean;
  showObjectType?: boolean;
  isPinned?: boolean;
  onPinToggle?: (
    e?: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => Promise<unknown>;
  className?: string;
};
export const SalesforcePinTileButton = ({
  organizationSlug,
  meetingPlanId,
  objectName,
  objectType,
  objectId,
  externalServiceObjectId,
  tileHasOverline,
  isPinned,
  onPinToggle,
  className,
}: SalesforcePinTileButtonProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const { configurationsWithToken } = useExternalServiceConfigurations({
    app: 'SALESFORCE',
    withToken: true,
  });

  const [activeSalesforceConfigurationId, setActiveSalesforceConfigurationId] =
    useState<number | undefined>();

  useEffect(() => {
    if (configurationsWithToken?.length === 1) {
      setActiveSalesforceConfigurationId(configurationsWithToken[0].id);
    }
  }, [configurationsWithToken]);

  const pinClass = mergeStyles({
    padding: '.25rem',
    marginTop: tileHasOverline ? '.25rem' : 0,
    marginRight: '.25rem',
    borderRadius: '.25rem',
    transition: '.3s all ease-in-out',
    color: isPinned
      ? MEETINGFLOW_COLORS.purpleMedium
      : MEETINGFLOW_COLORS.purpleLight,
    cursor: 'pointer',

    ':hover': {
      color: MEETINGFLOW_COLORS.purpleDark,
    },
  });

  return (
    <TooltipHost
      className={className}
      content={
        <div style={{ padding: '.5rem' }}>
          <Text
            style={{
              fontSize: FontSizes.smallPlus,
              fontWeight: FontWeights.bold,
              color: 'white',
            }}
          >
            {isPinned
              ? `Unpin ${objectType} from Meetingflow`
              : `Pin ${objectType} to Meetingflow`}
            <span
              style={{
                display: 'block',
                fontSize: FontSizes.mini,
                fontWeight: FontWeights.regular,
              }}
            >
              {isPinned ? (
                <>
                  Unpinning {objectName} from this Meetingflow will remove it
                  from your Pinned list, and affect the relevancy of "Related
                  Meetingflows."
                </>
              ) : (
                <>
                  Pinning {objectName} to this Meetingflow will ensure it's
                  always easily accessible, as well as improve relevancy of
                  "Related Meetingflows."
                </>
              )}
            </span>
          </Text>
        </div>
      }
      styles={{
        root: {
          alignSelf: 'baseline',
        },
      }}
      calloutProps={{
        backgroundColor: MEETINGFLOW_COLORS.teal,
        styles: {
          root: {
            color: 'green',
            padding: 0,
            maxWidth: '18rem',
          },
          beak: {
            color: 'green',
          },
        },
      }}
    >
      <FontIcon
        className={pinClass}
        onClick={async (e: React.MouseEvent<HTMLElement>) => {
          e.preventDefault();
          e.stopPropagation();
          if (isPinned) {
            await toast.promise(
              (async () => {
                const token = await getAccessTokenSilently();
                return ApiClient.delete<ExternalServiceObject>(
                  `/organization/${organizationSlug}/plan/${meetingPlanId}/association/${externalServiceObjectId}`,
                  {
                    headers: { Authorization: `Bearer ${token}` },
                  },
                );
              })(),
              {
                loading: `Unpinning ${objectType.toLowerCase()} ${objectName} from this Meetingflow.`,
                success: (result) => {
                  if (onPinToggle) {
                    onPinToggle(e);
                  }
                  return `Unpinned ${objectType.toLowerCase()} ${objectName} from this Meetingflow.`;
                },
                error: (err) => {
                  return `Something went wrong unpinning ${objectType} ${objectName} from this Meetingflow.`;
                },
              },
            );
          } else {
            await toast.promise(
              (async () => {
                let urlParam = '';
                switch (objectType) {
                  case 'Account': {
                    urlParam = 'account';
                    break;
                  }
                  case 'Contact': {
                    urlParam = 'contact';
                    break;
                  }
                  case 'Lead': {
                    urlParam = 'lead';
                    break;
                  }
                  case 'Opportunity': {
                    urlParam = 'opportunity';
                    break;
                  }
                  default:
                }
                const token = await getAccessTokenSilently();
                return ApiClient.put<ExternalServiceObject>(
                  `/organization/${organizationSlug}/plan/${meetingPlanId}/external/salesforce/${urlParam}`,
                  {
                    configurationId: activeSalesforceConfigurationId,
                    objectId,
                  },
                  {
                    headers: { Authorization: `Bearer ${token}` },
                  },
                );
              })(),
              {
                loading: `Pinning ${objectType.toLowerCase()} ${objectName} to this Meetingflow.`,
                success: (result) => {
                  if (onPinToggle) {
                    onPinToggle(e);
                  }
                  return `Pinned ${objectType.toLowerCase()} ${objectName} to this Meetingflow.`;
                },
                error: (err) => {
                  return `Something went wrong pinning ${objectType.toLowerCase()} ${objectName} to this Meetingflow.`;
                },
              },
            );
          }
        }}
        iconName={isPinned ? 'PinnedSolid' : 'Pinned'}
        title={
          isPinned ? 'Unpin this item' : 'Pin this item to this Meetingflow'
        }
      />
    </TooltipHost>
  );
};
