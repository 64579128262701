import { Icon, mergeStyles, Spinner, useTheme } from '@fluentui/react';
import { RandomPick } from '@meetingflow/common/ArrayHelpers';
import { CursorEditor } from '@slate-yjs/core';
import { useCallback, useMemo, useState } from 'react';
import { isIOS, isMacOs } from 'react-device-detect';
import { Descendant, Editor, Transforms } from 'slate';
import { Slate } from 'slate-react';
import { CreateDeferredPromise } from '../../Helpers/DeferredPromise';
import { activeChords } from '../../Helpers/KeyboardEventHelpers';
import { useMentions } from '../../Hooks/editor/useMentions';
import {
  AddIntegrationModalOptions,
  AddIntegrationResult,
} from '../../Hooks/useAddIntegrationModal';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { MeetingPlanPanelContext } from '../../types/MeetingPlanPanelContext';
import { CustomEditable } from './CustomEditable';
import { DecoratedCustomEditable } from './DecoratedCustomEditable';

const isMacOrIOS = isMacOs || isIOS;

const EDITOR_HINTS: string[] = [];

const EDITOR_HINTS_WITH_MENTION = [
  ...EDITOR_HINTS,
  "Type @ to mention teammates (they'll get a notification), and select text to format it.",
];

export interface EditorFrameProps {
  name: string;
  organizationSlug: string;
  meetingflowId?: string;
  companyId?: number;
  contactId?: number;
  editor: Editor;
  value?: Descendant[];
  onChange?: (value: Descendant[]) => void;
  editorMaxHeight?: number | string;
  placeholder?: string;
  readonly?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalEventProps?: Record<string, any>;
  showEditableIcon?: boolean;
  showAutoSaveIndicator?: boolean;
  backgroundColor?: string;
  wrapperBackgroundColor?: string;
  smallToolbar?: boolean;
  setPanelContext?: (
    context?: MeetingPlanPanelContext | MeetingPlanPanelContext[],
  ) => void;
  createAddIntegrationDeferred?: CreateDeferredPromise<
    AddIntegrationResult | undefined,
    AddIntegrationModalOptions | undefined
  >;
  allowTags?: boolean;
  allowMentions?: boolean;
  hideMentionHint?: boolean;
  showPlaceholderHint?: boolean;
  defaultMentionSuggestions?: {
    id: number;
    name: string | null;
    email: string;
  }[];
  alwaysFixedToolbar?: boolean;
}

const EditorFrame = ({
  name,
  organizationSlug,
  meetingflowId,
  companyId,
  contactId,
  editor,
  readonly,
  value,
  onChange,
  placeholder,
  editorMaxHeight,
  additionalEventProps = {},
  showEditableIcon = false,
  backgroundColor,
  wrapperBackgroundColor,
  setPanelContext,
  createAddIntegrationDeferred,
  allowTags,
  allowMentions,
  showPlaceholderHint,
  defaultMentionSuggestions,
  hideMentionHint = false,
  alwaysFixedToolbar = true,
}: EditorFrameProps) => {
  const theme = useTheme();
  const { isDark } = useLightOrDarkMode();

  const [localValue, setLocalValue] = useState<Descendant[]>(value ?? []);

  const {
    onKeyDown: mentionKeyDownHandler,
    onSlateChange: mentionSlateChangeHandler,
    mentionSuggestions,
  } = useMentions({
    editor,
    organizationSlug,
    meetingflowId,
    companyId,
    contactId,
    allowMentions,
    defaultMentionSuggestions,
  });

  const editorPlaceholder = useMemo(() => {
    if (!showPlaceholderHint || !allowMentions) {
      return placeholder;
    }

    return `${placeholder}. ${
      allowMentions && !hideMentionHint
        ? RandomPick(EDITOR_HINTS_WITH_MENTION)
        : []
    }`;
  }, [allowMentions, placeholder, showPlaceholderHint, hideMentionHint]);

  const editorStyle = mergeStyles({
    background: wrapperBackgroundColor || 'transparent',
    color: theme.palette.neutralDark,
    position: 'relative',
    paddingLeft: showEditableIcon ? '.25rem' : undefined,
    maxHeight: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',

    ':hover .editable-icon': {
      opacity: '1',
      color: `${
        isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.purpleDarker
      } !important`,
      backgroundColor: `${
        isDark ? MEETINGFLOW_COLORS.tealDark : MEETINGFLOW_COLORS.purpleGrey
      } !important`,
    },

    '.editable-icon': {
      display: 'inline-block',
      float: 'left',
      position: 'absolute',
      fontSize: '10px',
      top: '-.2rem',
      right: '-.35rem',
      padding: '.25rem',
      cursor: 'pointer',
      borderRadius: '50%',
      width: '1rem',
      height: '1rem',
      textAlign: 'center',
      lineHeight: '1rem',
      transition: '.3s ease-in-out all',
      color: MEETINGFLOW_COLORS.tealDark,
      opacity: '0',
    },

    'span[data-slate-leaf="true"] * span[data-slate-placeholder="true"],[data-slate-node="element"]:not(div) span[data-slate-placeholder="true"]':
      {
        display: 'none !important',
      },

    '[data-slate-placeholder="true"]': {
      position: 'absolute !important',
      top: '3.5rem !important',
      marginTop: hideMentionHint ? '0 !important' : undefined,
    },

    '.empty-text': {
      margin: hideMentionHint ? '0 !important' : undefined,
    },
  });

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      const { ctrlChord, cmdChord } = activeChords(event);

      if (
        ((isMacOrIOS && cmdChord) || (!isMacOrIOS && ctrlChord)) &&
        event.key === 'a'
      ) {
        event.preventDefault();
        event.stopPropagation();

        Transforms.select(editor, []);

        return true;
      }

      const mentionHandled = mentionKeyDownHandler(event);
      if (mentionHandled) {
        return true;
      }
    },
    [editor, mentionKeyDownHandler],
  );

  const onSlateChange = useCallback(
    (v: Descendant[]) => {
      (onChange ?? setLocalValue)(v);

      if (allowMentions) {
        mentionSlateChangeHandler(v);
      }
    },
    [allowMentions, mentionSlateChangeHandler, onChange],
  );

  return (
    <div className={editorStyle}>
      {showEditableIcon ? (
        <Icon iconName="Edit" className="editable-icon" />
      ) : null}
      <Slate
        key={editor.id}
        editor={editor}
        initialValue={value ?? localValue}
        onChange={onSlateChange}
      >
        {(value ?? localValue).length === 0 ? (
          <Spinner />
        ) : CursorEditor.isCursorEditor(editor) ? (
          <DecoratedCustomEditable
            organizationSlug={organizationSlug}
            meetingflowId={meetingflowId}
            allowTags={allowTags}
            name={name}
            editorMaxHeight={editorMaxHeight}
            placeholder={editorPlaceholder}
            readonly={readonly}
            additionalEventProps={additionalEventProps}
            backgroundColor={backgroundColor}
            onKeyDown={onKeyDown}
            setPanelContext={setPanelContext}
            createAddIntegrationDeferred={createAddIntegrationDeferred}
            alwaysFixedToolbar={alwaysFixedToolbar}
          />
        ) : (
          <CustomEditable
            organizationSlug={organizationSlug}
            meetingflowId={meetingflowId}
            allowTags={allowTags}
            name={name}
            editorMaxHeight={editorMaxHeight}
            placeholder={editorPlaceholder}
            readonly={readonly}
            additionalEventProps={additionalEventProps}
            backgroundColor={backgroundColor}
            onKeyDown={onKeyDown}
            setPanelContext={setPanelContext}
            createAddIntegrationDeferred={createAddIntegrationDeferred}
            alwaysFixedToolbar={alwaysFixedToolbar}
          />
        )}
        {mentionSuggestions}
      </Slate>
    </div>
  );
};

export default EditorFrame;
