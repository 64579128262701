import { FontSizes, Icon, Text, mergeStyles } from '@fluentui/react';
import classNames from 'classnames';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

type UpvoteDownvoteProps = {
  className?: string;
  upvotes?: number;
  upvoted: boolean;
  downvotes?: number;
  downvoted: boolean;
  onUpvote?: () => Promise<unknown>;
  onDownvote?: () => Promise<unknown>;
};

export const DealRoomUpDownVote = ({
  className,
  upvotes,
  upvoted,
  downvotes,
  downvoted,
  onUpvote,
  onDownvote,
}: UpvoteDownvoteProps) => {
  const styles = mergeStyles({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'center',
    width: 'fit-content',
    cursor: 'pointer',
    fontSize: FontSizes.size16,
    border: `1px solid ${DEALROOMS_COLORS.lighterGray}`,
    color: DEALROOMS_COLORS.darkerGray,
    borderRadius: '4px',

    i: {
      margin: 0,
      padding: '1px',
      fontSize: FontSizes.size16,
      color:
        upvoted || downvoted
          ? DEALROOMS_COLORS.black
          : DEALROOMS_COLORS.themePrimary,
    },

    '.vote': {
      display: 'flex',
      flexDirection: 'row',
      gap: '0.25rem',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '4px',
      padding: '6px',
      ':hover': {
        backgroundColor: DEALROOMS_COLORS.neutralLighter,
      },
    },
    '.divider': {
      width: '1px',
      height: '30px',
      backgroundColor: DEALROOMS_COLORS.lighterGray,
    },
  });

  return (
    <div className={mergeStyles(styles, className)}>
      <div className={classNames('vote', { upvoted })} onClick={onUpvote}>
        <Icon title="Upvote" iconName={upvoted ? 'LikeSolid' : 'Like'} />
        {upvoted !== undefined ? <Text variant="medium">{upvotes}</Text> : null}
      </div>
      <div className="divider" />
      <div className={classNames('vote', { downvoted })} onClick={onDownvote}>
        <Icon
          title="Downvote"
          iconName={downvoted ? 'DislikeSolid' : 'Dislike'}
        />
        {downvotes !== undefined ? (
          <Text variant="medium">{downvotes}</Text>
        ) : null}
      </div>
    </div>
  );
};
