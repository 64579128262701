import { mergeStyles, Stack } from '@fluentui/react';
import { DealRoomSidePanelCTA } from '../DealRoomSidePanelCTA';
import { DealRoomPrimaryButton } from './DealRoomButton';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { useCallback, useEffect, useState } from 'react';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { useAuth0 } from '@auth0/auth0-react';
import { useOrganizationSlug } from '../../../Hooks/useOrganizationSlug';
import { useDealRoomId } from '../../../Hooks/useDealRoomId';
import { useNavigate } from '../../../Hooks/useNavigate';

interface CalloutCard {
  id: number;
  title: string;
  description: string;
  link?: string | null;
  ctaLabel?: string | null;
  artifactId?: number | null;
}

export const RightSidePanelCTAs = () => {
  const [calloutCards, setCalloutCards] = useState<CalloutCard[]>([]);
  const { getAccessTokenSilently } = useAuth0();
  const organizationSlug = useOrganizationSlug();
  const dealRoomId = useDealRoomId();
  const navigate = useNavigate();

  const styles = mergeStyles({
    padding: '1.5rem',
    border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
    borderRadius: '4px',
    gap: '2rem',
    backgroundColor: DEALROOMS_COLORS.neutralGray,
    boxShadow: '0px 2px 2px 0px #00000005',

    '.basic-actions-container': {
      gap: '1rem',
    },
  });

  const loadCalloutCards = useCallback(async () => {
    if (!organizationSlug || !dealRoomId) {
      return;
    }

    const token = await getAccessTokenSilently();
    const response = await DealRoomsApiClient.listCalloutCards(
      organizationSlug,
      dealRoomId,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );

    setCalloutCards(response.data);
  }, [organizationSlug, dealRoomId, getAccessTokenSilently]);

  useEffect(() => {
    loadCalloutCards();
  }, [loadCalloutCards]);

  const handleCardClick = (card: CalloutCard) => {
    if (card.artifactId) {
      navigate(
        `/organization/${organizationSlug}/dealroom/${dealRoomId}/artifact/${card.artifactId}`,
        { preserveQuery: true },
      );
    } else if (card.link) {
      window.open(card.link, '_blank');
    }
  };

  if (calloutCards.length === 0) {
    return null;
  }

  return (
    <Stack className={styles}>
      <Stack className="basic-actions-container">
        {calloutCards.map((card) => (
          <DealRoomSidePanelCTA
            key={card.id}
            label={card.title}
            description={card.description}
            onClick={() => handleCardClick(card)}
          >
            {card.ctaLabel && (
              <DealRoomPrimaryButton 
                buttonStyleType="PRIMARY" 
                onClick={(e) => {
                  e.stopPropagation(); // Prevent the parent onClick from firing
                  handleCardClick(card);
                }}
              >
                {card.ctaLabel}
              </DealRoomPrimaryButton>
            )}
          </DealRoomSidePanelCTA>
        ))}
      </Stack>
    </Stack>
  );
};
