import { useAuth0 } from '@auth0/auth0-react';
import {
  FontIcon,
  FontSizes,
  FontWeights,
  Link,
  mergeStyles,
  NeutralColors,
  PersonaSize,
  Spinner,
  Text,
} from '@fluentui/react';
import {
  Contact,
  DetailedMeetingflow,
} from '@meetingflow/common/Api/data-contracts';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import { useExternalServiceConfigurations } from '../../../Hooks/useExternalServiceConfigurations';
import { HubSpotContact } from '../../../Models/HubSpot/HubSpotContact';
import { HubSpotPlanContext } from '../../../Models/HubSpot/HubSpotPlanContext';
import { SalesforceUser } from '../../../Models/Salesforce/SaleforceUser';
import { SalesforcePlanContext } from '../../../Models/Salesforce/SalesforcePlanContext';
import {
  HubSpotContactContextQuery,
  OrganizationContactQuery,
  SalesforceContactContextQuery,
} from '../../../QueryNames';
import { ApiClient, ContactsApiClient } from '../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import ContactResourcesDoc from '../../Organization/Library/ContactResourcesDoc';
import ResourceNotFound from '../../ResourceNotFound';
import { CompanyCard, ContactCard } from '../MeetingPlanAttendees';
import { BaseSidePanel } from './BaseSidePanel';
import { ContactRecentPlansList } from './ContactRecentPlansList';
import { HubSpotContactTile } from './HubSpot/HubSpotContactTile';
import { SalesforceContactTile } from './Salesforce/SalesforceContactTile';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';

export type ContactSidePanelProps = {
  color: string;
  organizationSlug: string;
  contactId: Contact['id'];
  meetingPlan:
    | Pick<
        DetailedMeetingflow,
        | 'id'
        | 'title'
        | 'creator'
        | 'organizer'
        | 'attendees'
        | 'companies'
        | 'externalId'
        | 'viewStats'
        | 'startTime'
        | 'endTime'
        | 'callRecording'
      >
    | undefined;
  salesforcePlanContext?: SalesforcePlanContext;
  hubspotPlanContext?: HubSpotPlanContext;
  onMeetingflowClick: (meetingflowId: DetailedMeetingflow['id']) => void;
  onCompanyClick: (id: number) => void;
  onContactClick: (id: number) => void;
  onBack?: () => void;
  onClose: () => void;
};
export const ContactSidePanel = ({
  color,
  organizationSlug,
  contactId,
  meetingPlan,
  salesforcePlanContext,
  hubspotPlanContext,
  onBack,
  onClose,
  onMeetingflowClick,
  onCompanyClick,
  onContactClick,
}: ContactSidePanelProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { isDark } = useLightOrDarkMode();

  const { configurationsWithToken: salesforceConfigurations } =
    useExternalServiceConfigurations({
      app: 'SALESFORCE',
      withToken: true,
    });

  const { configurationsWithToken: hubspotConfigurations } =
    useExternalServiceConfigurations({
      app: 'HUBSPOT',
      withToken: true,
    });

  const {
    data: salesforceContacts,
    isLoading: salesforceContactsLoading,
    isError: salesforceContactsError,
  } = useQuery(
    SalesforceContactContextQuery(
      organizationSlug,
      contactId,
      salesforceConfigurations[0]?.id,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<SalesforceUser[]>(
        `/organization/${organizationSlug}/external/salesforce/configuration/${salesforceConfigurations[0]?.id}/contact/${contactId}/context`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    { enabled: !!salesforceConfigurations.length },
  );

  const {
    data: hubspotContacts,
    isLoading: hubspotContactsLoading,
    isError: hubspotContactsError,
  } = useQuery(
    HubSpotContactContextQuery(
      organizationSlug,
      contactId,
      hubspotConfigurations[0]?.id,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<HubSpotContact[]>(
        `/organization/${organizationSlug}/external/hubspot/configuration/${hubspotConfigurations[0]?.id}/contact/${contactId}/context`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    { enabled: !!hubspotConfigurations.length },
  );

  const sidePanelSectionClass = mergeStyles({
    margin: '1rem 0',
  });

  const isAttendee = useMemo(() => {
    if (!meetingPlan) {
      return false;
    }

    return (
      meetingPlan.organizer?.id === contactId ||
      meetingPlan.creator?.id === contactId ||
      meetingPlan.attendees.some((attendee) => attendee.id === contactId)
    );
  }, [contactId, meetingPlan]);

  const { data: contactData, isLoading: contactLoading } = useQuery(
    OrganizationContactQuery(organizationSlug, contactId),
    async () => {
      const token = await getAccessTokenSilently();
      return ContactsApiClient.getContact(organizationSlug, contactId, {
        headers: { Authorization: `Bearer ${token}` },
      });
    },
  );

  const contact = useMemo(
    () =>
      contactData?.data ??
      meetingPlan?.attendees.find((a) => a.id === contactId),
    [contactData?.data, contactId, meetingPlan?.attendees],
  );

  const company = useMemo(
    () =>
      contact &&
      meetingPlan?.companies.find(
        (c) => c.domains?.map((d) => d.domain).includes(contact.emailDomain),
      ),
    [contact, meetingPlan?.companies],
  );

  const contactCardWrapperClass = mergeStyles({
    '> div': {
      width: '100% !important',
      maxWidth: '100% !important',
    },
  });

  return (
    <BaseSidePanel
      title={isAttendee ? 'Attendee Details' : 'Contact Details'}
      onBack={isAttendee ? onBack : undefined}
      onClose={onClose}
    >
      {!contact && contactLoading ? (
        <Spinner />
      ) : !contact ? (
        <ResourceNotFound resourceType="Contact" />
      ) : (
        <>
          <div className={sidePanelSectionClass}>
            <Text
              block
              style={{
                fontSize: FontSizes.medium,
                fontWeight: FontWeights.semibold,
                color: MEETINGFLOW_COLORS.teal,
                marginTop: '.5rem',
                marginBottom: '.5rem',
              }}
            >
              Details and Notes
            </Text>
            <div className={contactCardWrapperClass}>
              <ContactCard
                personaSize={PersonaSize.size72}
                key={contact.email}
                contact={contact}
                meetingPlan={meetingPlan}
                salesforcePlanContext={salesforcePlanContext}
                hubspotPlanContext={hubspotPlanContext}
                organizationSlug={organizationSlug}
                company={company}
                domain={contact.emailDomain}
                displayContactNotes
                allowEditingContactNotes
                showSecondaryText
                backgroundColor={
                  isDark
                    ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
                    : MEETINGFLOW_COLORS.white
                }
              />
            </div>
          </div>

          <div className={sidePanelSectionClass}>
            <Text
              block
              style={{
                fontSize: FontSizes.medium,
                fontWeight: FontWeights.semibold,
                color: MEETINGFLOW_COLORS.teal,
                marginTop: '.5rem',
                marginBottom: '.5rem',
              }}
            >
              Resources
            </Text>
            <ContactResourcesDoc
              contact={contact}
              organizationSlug={organizationSlug}
            />
          </div>

          <div className={sidePanelSectionClass}>
            <Text
              block
              style={{
                fontSize: FontSizes.medium,
                fontWeight: FontWeights.semibold,
                color: MEETINGFLOW_COLORS.teal,
                marginTop: '.5rem',
                marginBottom: '.5rem',
              }}
            >
              Company
            </Text>

            {company ? (
              <CompanyCard
                organizationSlug={organizationSlug}
                company={company}
                salesforcePlanContext={salesforcePlanContext}
                hubspotPlanContext={hubspotPlanContext}
                onClick={onCompanyClick}
                personaSize={PersonaSize.size48}
                backgroundColor={
                  isDark
                    ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
                    : MEETINGFLOW_COLORS.white
                }
              />
            ) : null}
          </div>
          {hubspotConfigurations.length ? (
            <div className={sidePanelSectionClass}>
              <Text
                block
                style={{
                  fontSize: FontSizes.medium,
                  fontWeight: FontWeights.semibold,
                  color: MEETINGFLOW_COLORS.teal,
                  marginTop: '.5rem',
                  marginBottom: '.5rem',
                }}
              >
                HubSpot Contacts
              </Text>
              {hubspotContactsLoading ? (
                <Spinner />
              ) : hubspotContactsError ? (
                <Text
                  block
                  variant="mediumPlus"
                  style={{ fontStyle: 'italic', margin: '1rem 0' }}
                >
                  Something went wrong fetching HubSpot contacts associated with
                  this contact. Please try again.
                </Text>
              ) : !hubspotContacts?.data.length ? (
                <>
                  <Text
                    block
                    variant="mediumPlus"
                    style={{ fontStyle: 'italic', margin: '1rem 0' }}
                  >
                    There are no matching contacts.
                  </Text>
                </>
              ) : (
                hubspotContacts.data.map((hsc) => (
                  <HubSpotContactTile
                    key={hsc.id}
                    id={hsc.id}
                    firstName={hsc.properties.firstname}
                    lastName={hsc.properties.lastname}
                    email={hsc.properties.email}
                    hubspotInstance={hubspotConfigurations[0].instanceId}
                    onClick={undefined}
                  />
                ))
              )}
            </div>
          ) : null}
          {salesforceConfigurations.length &&
          salesforceContacts?.data.length ? (
            <div className={sidePanelSectionClass}>
              <Text
                block
                style={{
                  fontSize: FontSizes.medium,
                  fontWeight: FontWeights.semibold,
                  color: MEETINGFLOW_COLORS.teal,
                  marginTop: '.5rem',
                  marginBottom: '.5rem',
                }}
              >
                Salesforce Contacts
              </Text>
              {salesforceContactsLoading ? (
                <Spinner />
              ) : salesforceContactsError ? (
                <Text
                  block
                  variant="mediumPlus"
                  style={{ fontStyle: 'italic', margin: '1rem 0' }}
                >
                  Something went wrong fetching Salesforce contacts associated
                  with this contact. Please try again.
                </Text>
              ) : (
                salesforceContacts.data.map((sfc) => (
                  <SalesforceContactTile
                    key={sfc.Id}
                    organizationSlug={organizationSlug}
                    externalId={sfc.Id}
                    name={sfc.Name}
                    email={sfc.Email}
                    salesforceInstance={salesforceConfigurations[0].instanceId}
                  />
                ))
              )}
            </div>
          ) : null}

          <div className={sidePanelSectionClass}>
            <Text
              block
              style={{
                fontSize: FontSizes.medium,
                fontWeight: FontWeights.semibold,
                color: MEETINGFLOW_COLORS.teal,
                marginTop: '.5rem',
                marginBottom: '.5rem',
              }}
            >
              Recent Meetingflows
              <Link
                style={{
                  display: 'block',
                  float: 'right',
                  textAlign: 'right',
                  fontWeight: FontWeights.semilight,
                  fontSize: FontSizes.small,
                  marginRight: 0,
                  transition: '.3s ease-in-out all',
                }}
                onClick={() =>
                  navigate(
                    `/organization/${organizationSlug}/library/plans?attendees=${contactId}`,
                  )
                }
              >
                All Meetingflows
                <FontIcon
                  style={{
                    display: 'inline-block',
                    position: 'relative',
                    top: '.15rem',
                    marginLeft: '.25rem',
                  }}
                  iconName="PageRight"
                />
              </Link>
            </Text>
            <ContactRecentPlansList
              organizationSlug={organizationSlug}
              currentMeetingPlanId={meetingPlan?.id}
              contact={contact}
              maxCount={10}
              onContactClick={onContactClick}
              color={color}
              onMeetingflowClick={onMeetingflowClick}
            />
          </div>
        </>
      )}
    </BaseSidePanel>
  );
};
