import { useAuth0 } from '@auth0/auth0-react';
import {
  Checkbox,
  Dropdown,
  FontSizes,
  IDropdownOption,
  Text,
  TextField,
} from '@fluentui/react';
import {
  DetailedMeetingflow,
  Task,
} from '@meetingflow/common/Api/data-contracts';
import { DeduplicateArray } from '@meetingflow/common/ArrayHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { inflect } from 'inflection';
import { orderBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useQuery, useQueryClient } from 'react-query';
import * as Y from 'yjs';
import { AsyncPrimaryButton } from '../../../../Components/HOC/AsyncButton';
import StyledDateTime from '../../../../Components/StyledDateTime';
import {
  getDefaultState,
  getFieldNameFieldMap,
  getYupObjectValidator,
} from '../../../../Helpers/HubSpot/HubSpotFieldHelpers';
import { validateData } from '../../../../Helpers/HubSpot/HubSpotYupValidators';
import {
  LINE_ENDING,
  toPlainText,
} from '../../../../Helpers/MeetingPlanHelpers';
import { hasContent } from '../../../../Helpers/yjsHelpers';
import { useExternalServiceConfigurations } from '../../../../Hooks/useExternalServiceConfigurations';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import { useLocalStorageState } from '../../../../Hooks/useLocalStorageState';
import { useUserProfile } from '../../../../Hooks/useProfile';
import { HubSpotMeeting } from '../../../../Models/HubSpot/HubSpotMeeting';
import { HubSpotPlanContext } from '../../../../Models/HubSpot/HubSpotPlanContext';
import { HubSpotSchemaResponse } from '../../../../Models/HubSpot/HubSpotSchema';
import {
  HubSpotObjectSchemaQuery,
  HubSpotPlanContextQuery,
} from '../../../../QueryNames';
import { ApiClient } from '../../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../../Themes/Themes';
import MeetingPlanNotesSummaryForm from '../../../Common/NotesSummaryForm';
import { BaseSidePanel } from '../BaseSidePanel';
import { HubSpotFormComponent } from './HubSpotFormComponent';

export type LogMeetingToHubSpotSidePanelProps = {
  organizationSlug: string;
  meetingPlanId: string;
  internalDomains: string[];
  meetingPlan: Pick<
    DetailedMeetingflow,
    | 'id'
    | 'title'
    | 'startTime'
    | 'organizer'
    | 'creator'
    | 'attendees'
    | 'companies'
    | 'associations'
    | 'textSummary'
    | 'callRecording'
    | 'userActivity'
  >;
  ydoc: Y.Doc;
  notesYArray: Y.XmlText;
  tasks: Task[];
  initialSummary?: string;
  onSuccess: () => void;
  onClose: () => void;
  onBack?: () => void;
  hubspotPlanContext?: HubSpotPlanContext;
};

export const LogMeetingToHubSpotSidePanel = ({
  organizationSlug,
  meetingPlanId,
  internalDomains,
  meetingPlan,
  ydoc,
  notesYArray,
  hubspotPlanContext,
  tasks,
  initialSummary,
  onSuccess,
  onClose,
  onBack,
}: LogMeetingToHubSpotSidePanelProps) => {
  const appInsights = useAppInsightsContext();
  const { getAccessTokenSilently } = useAuth0();
  const { user } = useUserProfile();

  const client = useQueryClient();

  const { isDark } = useLightOrDarkMode();

  const {
    configurationsWithToken: hubspotConfigurations,
    loading: hubspotConfigurationsLoading,
    configurationById,
  } = useExternalServiceConfigurations({ app: 'HUBSPOT', withToken: true });

  const [meetingState, setMeetingState] = useState<
    Record<string, string | boolean | number | undefined | null>
  >({});

  const hubspotAssociations = useMemo(
    () =>
      meetingPlan?.associations?.filter(
        (a) => a.serviceConfiguration.app === 'HUBSPOT',
      ),
    [meetingPlan?.associations],
  );

  const [activeConfigurationId, setActiveConfigurationId] = useState<number>(
    hubspotAssociations?.[0]?.serviceConfigurationId || -1,
  );

  const associatedDeals = useMemo(
    () =>
      hubspotAssociations.filter(
        (a) =>
          a.serviceConfigurationId === activeConfigurationId &&
          a.objectType === 'DEAL',
      ),
    [activeConfigurationId, hubspotAssociations],
  );
  const associatedCompanies = useMemo(
    () =>
      hubspotAssociations.filter(
        (a) =>
          a.serviceConfigurationId === activeConfigurationId &&
          a.objectType === 'ACCOUNT',
      ),
    [activeConfigurationId, hubspotAssociations],
  );

  const associatedContacts = useMemo(
    () =>
      hubspotAssociations.filter(
        (a) =>
          a.serviceConfigurationId === activeConfigurationId &&
          a.objectType === 'CONTACT',
      ),
    [activeConfigurationId, hubspotAssociations],
  );

  const meetingCompanies = useMemo(() => {
    return DeduplicateArray([
      ...associatedCompanies.map((c) => c.name),
      ...(hubspotPlanContext?.companies
        ? Object.values(hubspotPlanContext.companies).map(
            (c) => c.properties.name,
          )
        : []),
    ]);
  }, [associatedCompanies, hubspotPlanContext?.companies]);

  const meetingContacts = useMemo(() => {
    return DeduplicateArray([
      ...associatedContacts.map((c) => c.name),
      ...(hubspotPlanContext?.contacts
        ? Object.values(hubspotPlanContext.contacts).map((c) =>
            c.properties.firstname
              ? c.properties.firstname + ' ' + c.properties.lastname
              : c.properties.email,
          )
        : []),
    ]);
  }, [associatedContacts, hubspotPlanContext?.contacts]);

  const [title, setTitle] = useState<string>(meetingPlan?.title || '');
  const [summary, setSummary] = useState<string>(
    initialSummary || meetingPlan?.textSummary || '',
  );
  const [meetingOutcome, setMeetingOutcome] = useState<string>('COMPLETED');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [gptSummary, setGPTSummary] = useState<string | undefined>('');

  const [includeFullNotes, setFullNotes] = useLocalStorageState<boolean>(
    'log-to-hubspot-include-full-notes',
    true,
  );

  const [includeCallInsights, setIncludeCallInsights] =
    useLocalStorageState<boolean>('log-to-hubspot-include-call-insights', true);

  const [createMissingContacts, setCreateMissingContacts] =
    useLocalStorageState<boolean>('create-missing-hubspot-contacts', false);

  const {
    data: hubspotMeetingSchema,
    isLoading: hubspotMeetingSchemaLoading,
    refetch: refetchHubspotMeetingSchema,
  } = useQuery(
    HubSpotObjectSchemaQuery(
      organizationSlug!,
      activeConfigurationId,
      'meetings',
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<HubSpotSchemaResponse>(
        `/organization/${organizationSlug!}/external/hubspot/configuration/${activeConfigurationId}/schema/meetings`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled:
        !!activeConfigurationId && !!configurationById(activeConfigurationId),
    },
  );

  useEffect(() => {
    if (
      hubspotAssociations?.[0]?.serviceConfigurationId &&
      !!configurationById(hubspotAssociations?.[0]?.serviceConfigurationId)
    ) {
      setActiveConfigurationId(
        hubspotAssociations?.[0]?.serviceConfigurationId,
      );
    } else if (hubspotConfigurations.length === 1) {
      setActiveConfigurationId(hubspotConfigurations[0].id);
    }
  }, [configurationById, hubspotAssociations, hubspotConfigurations]);

  useEffect(() => {
    if (!!activeConfigurationId && configurationById(activeConfigurationId)) {
      refetchHubspotMeetingSchema();
    }
  }, [activeConfigurationId, configurationById, refetchHubspotMeetingSchema]);

  const fieldNameFieldMap = useMemo(
    () => getFieldNameFieldMap(hubspotMeetingSchema?.data?.hubspotSchema),
    [hubspotMeetingSchema?.data?.hubspotSchema],
  );

  const createableCustomFields = useMemo(() => {
    if (!hubspotMeetingSchema?.data) {
      return [];
    }

    return hubspotMeetingSchema.data.customFields
      .map((f) => f.fieldName)
      .filter(
        (fieldName) =>
          fieldNameFieldMap[fieldName]?.calculated === false &&
          fieldNameFieldMap[fieldName]?.modificationMetadata?.readOnlyValue ===
            false,
      );
  }, [fieldNameFieldMap, hubspotMeetingSchema?.data]);

  const formSchema = useMemo(
    () =>
      getYupObjectValidator(
        hubspotMeetingSchema?.data?.hubspotSchema,
        createableCustomFields,
        fieldNameFieldMap,
      ),
    [
      createableCustomFields,
      fieldNameFieldMap,
      hubspotMeetingSchema?.data?.hubspotSchema,
    ],
  );

  const [isValid, errors] = useMemo(
    () => validateData(formSchema, meetingState),
    [formSchema, meetingState],
  );

  useEffect(() => {
    if (!hubspotMeetingSchema?.data?.hubspotSchema) {
      setMeetingState({});
      return;
    }
    const defaultState = getDefaultState(
      hubspotMeetingSchema.data.hubspotSchema,
      createableCustomFields,
      fieldNameFieldMap,
    );
    setMeetingState(defaultState);
  }, [
    createableCustomFields,
    fieldNameFieldMap,
    hubspotMeetingSchema?.data?.hubspotSchema,
  ]);

  const lastLoggedToCRM = useMemo(() => {
    return orderBy(
      meetingPlan?.userActivity?.filter(
        (a) =>
          a.type.toString() === 'LOG_MEETING_TO_CRM' &&
          a.additionalDetails.app === 'HUBSPOT',
      ),
      ['createdAt'],
      ['desc'],
    )?.[0];
  }, [meetingPlan?.userActivity]);

  const lastLoggedToCRMBy = meetingPlan?.attendees.find(
    (a) => a.email === lastLoggedToCRM?.user?.email,
  );

  const lastLoggedBanner = (
    <>
      {lastLoggedToCRM ? (
        <Text
          block
          style={{
            backgroundColor: MEETINGFLOW_COLORS.orange,
            padding: '.5rem',
            borderRadius: '.25rem',
            margin: '.5rem 0',
            fontSize: FontSizes.small,
            color: isDark ? undefined : 'white',
          }}
        >
          This meeting was logged to HubSpot{' '}
          <StyledDateTime
            dateTime={lastLoggedToCRM.createdAt}
            displayComponents={['date']}
          />{' '}
          by{' '}
          {lastLoggedToCRMBy?.name ||
            lastLoggedToCRMBy?.email ||
            `Unknown User`}
          .
        </Text>
      ) : null}
    </>
  );

  return (
    <BaseSidePanel
      title={`Log Meeting to HubSpot`}
      loading={false}
      onBack={onBack}
      onClose={onClose}
    >
      {lastLoggedBanner}
      <div
        style={{
          display: 'grid',
          gridTemplateRows: 'auto auto auto auto auto 1fr',
          gridTemplateColumns: 'auto 1fr auto auto',
          gridTemplateAreas: `
            'associations associations associations associations'
            'configuration-picker configuration-picker none0 none0 '
            'title title title title'
            'meeting-outcome meeting-outcome none1 none1'
            'custom-fields custom-fields custom-fields custom-fields'
            'summary summary summary summary'
            'create-missing-external-contacts create-missing-external-contacts none2 none2'
            'include-notes include-notes none3 none3'
            'none4 none4 cancel-button add-button'`,
          padding: '1rem',
        }}
      >
        <div
          style={{
            gridArea: 'associations',
            marginBottom: '0.75rem',
          }}
        >
          This meeting with be associated with {associatedDeals.length}{' '}
          {inflect('deal', associatedDeals.length)}
          {associatedDeals.length
            ? ` (${associatedDeals.map((d) => d.name).join(', ')})`
            : ''}
          , {meetingCompanies.length}{' '}
          {inflect('company', meetingCompanies.length)}
          {meetingCompanies.length ? ` (${meetingCompanies.join(', ')})` : null}
          , and {meetingContacts.length}{' '}
          {inflect('contact', meetingContacts.length)}{' '}
          {meetingContacts.length ? ` (${meetingContacts.join(', ')})` : null}
        </div>
        {!!hubspotConfigurations.length && hubspotConfigurations.length > 1 ? (
          <div
            style={{
              gridArea: 'configuration-picker',
              marginBottom: '0.75rem',
            }}
          >
            <Dropdown
              disabled={hubspotConfigurationsLoading}
              options={hubspotConfigurations.map((configuration) => ({
                key: configuration.id,
                text: configuration.instanceId,
              }))}
              selectedKey={activeConfigurationId}
              onChange={(_evt, option, _index) => {
                setActiveConfigurationId(
                  (option?.key as number | undefined) || -1,
                );
              }}
              label="HubSpot instance"
            />
          </div>
        ) : undefined}

        <div style={{ gridArea: 'title', marginBottom: '0.75rem' }}>
          <TextField
            label="Title"
            value={title}
            onChange={(_e, newValue) => setTitle(newValue ?? '')}
          />
        </div>

        <div
          style={{
            gridArea: 'meeting-outcome',
            marginBottom: '0.75rem',
          }}
        >
          <Dropdown
            disabled={hubspotMeetingSchemaLoading}
            options={
              hubspotMeetingSchema?.data?.hubspotSchema?.properties
                ?.find((property) => property.name === 'hs_meeting_outcome')
                ?.options?.map(
                  (option) =>
                    ({
                      key: option.value,
                      text: option.label,
                    }) as IDropdownOption,
                ) ?? []
            }
            selectedKey={meetingOutcome}
            onChange={(_evt, option, _index) =>
              setMeetingOutcome((option?.key as string | undefined) || '')
            }
            label="Meeting Outcome"
          />
        </div>

        <div style={{ gridArea: 'custom-fields', marginBottom: '0.75rem' }}>
          {createableCustomFields.map((fieldName) => {
            const property = fieldNameFieldMap[fieldName];
            if (!property) {
              return null;
            }

            return (
              <HubSpotFormComponent
                key={property.name}
                schema={hubspotMeetingSchema?.data?.hubspotSchema}
                errors={errors}
                isDirty
                property={property}
                state={meetingState}
                setState={setMeetingState}
              />
            );
          })}
        </div>

        <div style={{ gridArea: 'summary', marginBottom: '0.75rem' }}>
          <MeetingPlanNotesSummaryForm
            organizationSlug={organizationSlug}
            meetingplan={meetingPlan}
            summaryType={'HUBSPOT_SUMMARY'}
            displaySubject={false}
            bodyLabel="Summary"
            summarizeDisabled={
              !hasContent(notesYArray) &&
              !meetingPlan?.callRecording?.transcript?.length
            }
            onSummaryChange={setSummary}
            onSummaryGenerated={setGPTSummary}
            surfaceName="HUBSPOT_SUMMARY"
            summarizeLabel={'Generate a summary'}
            initialSummaryValue={
              initialSummary || meetingPlan?.textSummary || ''
            }
          />
        </div>

        <div style={{ gridArea: 'create-missing-external-contacts' }}>
          <Checkbox
            title="Create contacts for new external attendees"
            label="Create contacts for new external attendees"
            checked={createMissingContacts}
            onChange={(_e, checked) => setCreateMissingContacts(!!checked)}
          />
        </div>

        <div style={{ gridArea: 'include-notes' }}>
          <Checkbox
            styles={{ root: { marginTop: '.5rem' } }}
            title="Include full notes"
            label="Include full notes"
            checked={includeFullNotes}
            onChange={(_e, checked) => setFullNotes(!!checked)}
          />

          {meetingPlan?.callRecording?.transcriptAnalysis?.topics?.length ? (
            <Checkbox
              styles={{ root: { marginTop: '.5rem' } }}
              title="Include Call Insights"
              label="Include Call Insights"
              checked={includeCallInsights}
              onChange={(_e, checked) => setIncludeCallInsights(!!checked)}
            />
          ) : null}
        </div>

        <div style={{ gridArea: 'add-button', marginLeft: '0.5rem' }}>
          <AsyncPrimaryButton
            disabled={
              isLoading ||
              (includeFullNotes ? false : summary.length === 0) ||
              !isValid
            }
            onClick={async () => {
              const description = toPlainText(
                organizationSlug!,
                meetingPlan,
                ydoc,
                summary,
                {
                  includeNotes: includeFullNotes,
                  includeCallInsights: includeCallInsights,
                  lineEnding: LINE_ENDING.HTML_BR,
                },
              );
              const token = await getAccessTokenSilently();
              setIsLoading(true);
              toast.promise(
                ApiClient.post<HubSpotMeeting>(
                  `/organization/${organizationSlug}/external/hubspot/configuration/${activeConfigurationId}/meetings/log`,
                  {
                    meetingPlanId,
                    title: title
                      ? `[Meetingflow] ${title}`
                      : `[Meetingflow] ${meetingPlan.title}`,
                    notes: description,
                    outcome: meetingOutcome || undefined,
                    payload: meetingState,
                    createMissingContacts: createMissingContacts,
                    gptSummary,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      'x-meetingplan-id': meetingPlanId,
                    },
                  },
                ),
                {
                  loading: 'Logging meeting to HubSpot...',
                  success: () => {
                    setIsLoading(false);
                    appInsights.trackEvent({
                      name: 'LOG_MEETING',
                      properties: {
                        organizationSlug,
                        planId: meetingPlanId,
                        app: 'HUBSPOT',
                      },
                    });

                    // It takes hubspot a while to make the new contact available,
                    // Clear the query cache after 15 seconds to try make sure we see on refresh
                    setTimeout(() => {
                      client.invalidateQueries(
                        HubSpotPlanContextQuery(
                          organizationSlug,
                          meetingPlanId,
                          hubspotConfigurations[0].id,
                        ),
                      );
                    }, 15000);

                    onSuccess?.();

                    return <>Successfully logged meeting to HubSpot.</>;
                  },
                  error: (result) => {
                    setIsLoading(false);
                    appInsights.trackEvent({
                      name: 'LOG_MEETING_ERROR',
                      properties: {
                        organizationSlug,
                        planId: meetingPlanId,
                        app: 'HUBSPOT',
                        statusCode: result.status,
                        statusText: result.statusText,
                      },
                    });
                    return (
                      <>
                        Error logging meeting to HubSpot. This error has been
                        logged and Meetingflow support staff has been notified.
                      </>
                    );
                  },
                },
              );
            }}
          >
            {isLoading ? 'Saving...' : 'Log Meeting'}
          </AsyncPrimaryButton>
        </div>
      </div>
    </BaseSidePanel>
  );
};
