import {
  CommandButton,
  Dropdown,
  FontSizes,
  FontWeights,
  IComboBoxOption,
  IToggleStyles,
  NeutralColors,
  SearchBox,
  Toggle,
  mergeStyles,
  IDropdownOption,
} from '@fluentui/react';
import {
  Attendee,
  CallRecordingStatus,
  Contact,
  Utterance,
} from '@meetingflow/common/Api/data-contracts';
import { DeduplicateArray } from '@meetingflow/common/ArrayHelpers';
import {
  transcriptify,
  tryMatchAttendeesToParticipants,
} from '@meetingflow/common/TranscriptHelpers';
import { Truthy } from '@meetingflow/common/TypeHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import classNames from 'classnames';
import { Duration } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { useOrganization } from '../../Hooks/useOrganization';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { speakerColors } from '../MeetingPlans/CallRecordingPlayer';

const formatSeconds = (seconds: number) =>
  Duration.fromObject({ seconds }).toFormat('h:mm:ss');

export type VideoTranscriptProps = {
  playerTime?: number;
  botStatus?: CallRecordingStatus;
  meetingPlanId?: string;
  participants?: string[];
  attendees: Pick<Attendee, 'id' | 'email' | 'name' | 'emailDomain'>[];
  callTranscript: Utterance[];
  onSeekTranscriptUtterance?: (startTime: number) => void;
  showCollabDialogForTimestamp?: (timestamp: number) => void;
  isFullscreen?: boolean;
  onContactClick?: (c: Contact['id']) => void;
  isRealtime?: boolean;
};

export const VideoTranscript = ({
  callTranscript,
  playerTime,
  botStatus,
  meetingPlanId,
  attendees,
  onSeekTranscriptUtterance,
  showCollabDialogForTimestamp,
  isFullscreen,
  onContactClick,
  isRealtime,
}: VideoTranscriptProps) => {
  const { slug: organizationSlug } = useOrganization();

  const [autoScroll, setAutoScroll] = useState<boolean>(true);

  const utterances = useMemo(
    () =>
      transcriptify(callTranscript).map((utterance, idx) => ({
        idx,
        ...utterance,
      })),
    [callTranscript],
  );

  const [searchString, setSearchString] = useState<string>('');
  const [selectedSpeakers, setSelectedSpeakers] = useState<IComboBoxOption[]>(
    [],
  );

  const { isDark } = useLightOrDarkMode();

  const appInsights = useAppInsightsContext();

  const turns = useMemo(
    () => transcriptify(callTranscript, false),
    [callTranscript],
  );

  const speakers = useMemo(
    () =>
      DeduplicateArray(turns.map((t) => t.speaker))
        .filter(Truthy)
        .sort(),
    [turns],
  );

  const getColorForSpeaker = useCallback(
    (speaker: string) => {
      const speakerIdx = speakers.indexOf(speaker);
      return speakerColors[speakerIdx];
    },
    [speakers],
  );

  const speakerContactMatches = useMemo(
    () => tryMatchAttendeesToParticipants(speakers, attendees),
    [attendees, speakers],
  );

  const filteredUtterances = useMemo(() => {
    if (!searchString.trim() && !selectedSpeakers?.length) {
      return utterances;
    } else {
      return utterances
        .filter((u) => {
          if (!searchString.trim()) {
            return true;
          }
          return (
            u.text.toLowerCase().includes(searchString.trim().toLowerCase()) ||
            u?.speaker
              ?.toLowerCase()
              ?.includes(searchString.trim().toLowerCase())
          );
        })
        .filter((u) => {
          if (!selectedSpeakers?.length) {
            return true;
          }
          return selectedSpeakers.some((s) => s.key === u.speaker);
        });
    }
  }, [searchString, utterances, selectedSpeakers]);

  const activeIndex = useMemo(() => {
    if (!utterances.length) {
      return undefined;
    }

    // During recording, the last utterance is always active
    if (botStatus === 'in_call_recording') {
      return utterances.length - 1;
    }

    // There's no active utterance if the video is playing
    if (!playerTime) {
      return undefined;
    }

    const index = utterances.findIndex(
      (utterance) => utterance.endTime >= playerTime,
    );

    return index === -1 ? undefined : index;
  }, [botStatus, playerTime, utterances]);

  const dropdownStyles = useMemo(
    () => ({
      root: {
        flexGrow: 1,
        transition: '.3s ease-in-out all',
      },
      dropdown: {
        height: '1.5rem',
        lineHeight: '1.5rem',
        fontSize: FontSizes.small,
        backgroundColor: isDark
          ? NeutralColors.gray200
          : MEETINGFLOW_COLORS.purpleGrey,
        border: 'none',
        borderRadius: '.25rem',
        ':after': {
          border: 'none !important',
        },
      },
      title: {
        height: '1.5rem',
        lineHeight: '1.25rem',
        backgroundColor: isDark
          ? NeutralColors.gray180
          : MEETINGFLOW_COLORS.purpleGrey,
        border: 'none',
        borderRadius: '.25rem',
        color: isDark ? NeutralColors.gray80 : MEETINGFLOW_COLORS.purpleDark,
      },
      caretDownWrapper: {
        height: '1.5rem',
        lineHeight: '1.5rem',
      },
      label: {
        padding: 0,
        height: '.75rem',
        lineHeight: '.75rem',
        fontSize: FontSizes.mini,
        marginBottom: '.25rem',
        color: NeutralColors.gray120,
      },
    }),
    [isDark],
  );

  const searchBoxStyles = useMemo(
    () => ({
      root: {
        flexGrow: 1,
        transition: '.3s ease-in-out all',
        height: 'auto',
        borderRadius: '.25rem',
        backgroundColor: isDark
          ? NeutralColors.gray180
          : MEETINGFLOW_COLORS.purpleGrey,
        border: 'none',
        ':after': {
          border: 'none',
        },
      },
      field: {
        height: '1.25rem',
        borderRadius: '.25rem',
        fontSize: FontSizes.small,
      },
    }),
    [isDark],
  );

  const transcriptStyles = mergeStyles({
    '.transcript-controls': {
      display: 'flex',
      columnGap: '.25rem',
      padding: '.25rem',
      width: 'calc(100% + 1rem)',
      marginLeft: '-.5rem',
      position: 'sticky',
      top: '0',
      backgroundColor: isDark
        ? NeutralColors.gray220
        : MEETINGFLOW_COLORS.purpleUltraSuperLightish,
      zIndex: 10,
      '> div': {
        flex: '1 1 auto',
      },
    },
    '.transcript': {
      paddingLeft: '3rem',
      '.utterance': {
        display: 'flex',
        flexDirection: 'column',
        fontSize: FontSizes.small,
        lineHeight: '1.25rem',
        marginBottom: '0',
        boxSizing: 'content-box',
        transition: '.3s ease-in-out all',
        position: 'relatitve',
        color: isDark ? NeutralColors.gray50 : NeutralColors.gray160,
        containerType: 'inline-size',
        cursor: 'pointer',

        '.utterance-actions': {
          flex: '1 1 50px',
          marginLeft: '.5rem',
          padding: '0 0 0 .5rem',
          transition: '.3s ease-in-out all',
          fontWeight: FontWeights.regular,
          color: NeutralColors.gray90,

          '.ms-Button-menuIcon': {
            color: isDark ? NeutralColors.gray140 : NeutralColors.gray90,
          },
        },

        '.utterance-time': {
          flex: '1 1 120px',
          marginLeft: '-5rem',
          padding: '0 .5rem 0 .5rem',
          transition: '.3s ease-in-out all',
          fontSize: FontSizes.mini,
          fontWeight: FontWeights.regular,
          color: NeutralColors.gray90,
          marginRight: '.5rem',
          boxSizing: 'border-box',
          width: '58px',
          display: 'inline-block',
          textAlign: 'right',
        },

        '.utterance-text': {
          flex: '1 1 100%',
          lineHeight: '1.25rem',
          display: 'block',
          padding: '0 2.5rem .25rem .5rem',
          transition: '.3s ease-in-out all',
          borderLeft: `1px solid ${
            isDark ? MEETINGFLOW_COLORS.black : MEETINGFLOW_COLORS.purpleGrey
          }`,

          ':hover': {
            padding: '.25rem 2.5rem .25rem .5rem',
            marginLeft: '.5rem',
            borderTopRightRadius: '.25rem',
            borderBottomRightRadius: '.25rem',
            backgroundColor: isDark
              ? NeutralColors.gray180
              : MEETINGFLOW_COLORS.purpleGrey,
          },
        },

        '.speaker': {
          clear: 'both',
          display: 'inline-block',
          fontWeight: FontWeights.semibold,
          width: 'auto',
          lineHeight: '1rem',
          padding: '.25rem .5rem',
          boxSizing: 'border-box',
          animationName: 'fadeInAnimation',
          animationDuration: '.3s',
          transitionTimingFunction: 'linear',
          animationIterationCount: '1',
          animationFillMode: 'forwards',
          zIndex: 10,
          color: isDark
            ? MEETINGFLOW_COLORS.white
            : MEETINGFLOW_COLORS.purpleMedium,
          marginTop: '.5rem',
          backgroundColor: 'transparent',
          borderBottom: `1px solid ${
            isDark ? MEETINGFLOW_COLORS.black : MEETINGFLOW_COLORS.purpleGrey
          }`,
        },
      },

      '.utterance:first-child .speaker': {
        marginTop: 0,
      },

      ['.utterance-' + activeIndex + ' .utterance:']: {
        borderColor: 'transparent',
      },

      ['.utterance-' + activeIndex + ' .utterance-text']: {
        animationName: 'fadeInAnimation',
        animationDuration: '.3s',
        transitionTimingFunction: 'linear',
        animationIterationCount: '1',
        animationFillMode: 'forwards',
        borderRadius: '.25rem',
        borderLeft: 'none',
        marginLeft: '1.5rem !important',
        marginRight: '2rem !important',
        marginTop: '.5rem !important',
        marginBottom: '.5rem !important',
        lineHeight: '1rem',
        backgroundColor: isDark
          ? MEETINGFLOW_COLORS.orange
          : MEETINGFLOW_COLORS.orangeLight,
        padding: '.5rem .5rem !important',
        color: isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.black,
        fontWeight: FontWeights.semibold,
        boxShadow: isDark
          ? '1px 1px 2px rgba(0, 0, 0, .5)'
          : '1px 1px 2px rgba(0, 0, 0, 0.025)',
        '*': {
          fontWeight: FontWeights.semibold,
        },

        ':hover': {
          color: isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.white,
          backgroundColor: isDark
            ? MEETINGFLOW_COLORS.orangeDark
            : MEETINGFLOW_COLORS.orange,
        },

        '.utterance-actions': {
          padding: '0 !important',
          height: 0,
          width: 0,
          margin: 0,
        },

        '.utterance-time': {
          position: 'absolute',
          fontWeight: FontWeights.semibold,
          color: isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.black,
          textAlign: 'right',
        },
      },
    },

    '.no-data-message': {
      display: 'block',
      padding: '2rem',
      textAlign: 'center',
      fontSize: FontSizes.medium,
      fontStyle: 'italic',
      color: MEETINGFLOW_COLORS.magenta,
      fontFamily: 'Georgia, serif',
    },
  });

  useEffect(() => {
    if (autoScroll && activeIndex !== undefined) {
      const transcriptContainer = document.querySelector<HTMLDivElement>(
        'div.transcript-full',
      );
      const element = transcriptContainer?.querySelector<HTMLDivElement>(
        `div.utterance.utterance-${activeIndex}`,
      );
      if (element && transcriptContainer) {
        // Check if the element is not visible.
        const rect = element.getBoundingClientRect();
        const parentRect = transcriptContainer.getBoundingClientRect();

        const isVisible =
          rect.top >= parentRect.top && rect.bottom <= parentRect.bottom;

        // If the element is not visible within `transcriptContainer`, scroll the transcriptContainer div to make it visible.
        if (!isVisible) {
          transcriptContainer.scrollTo({
            top: element.offsetTop - 72,
            behavior: 'smooth',
          });
        }
      }
    }
  }, [autoScroll, activeIndex]);

  const speakerOptions = useMemo(
    () => speakers.map((s) => ({ key: s, text: s })) as IComboBoxOption[],
    [speakers],
  );

  const toggleStyles = {
    root: {
      display: 'inline-block',
      textAlign: 'right',
      whiteSpace: 'nowrap',
      position: 'relative',
      top: '-.2rem',
      paddingRight: '.5rem',
      marginBottom: '0',
      minWidth: '5rem',
      '.ms-Toggle-innerContainer': {
        display: 'inline-block',
        whiteSpace: 'nowrap',
        textAlign: 'left',
        marginTop: '.25rem',
        button: {
          transition: 'all .3s ease-in-out',
          backgroundColor: isDark
            ? NeutralColors.gray180
            : MEETINGFLOW_COLORS.purpleGrey,
        },
      },
      'label, button': {
        marginRight: '.25rem',
        textAlign: 'right',
        fontSize: FontSizes.small,
        color: NeutralColors.gray120,
        border: 'none !important',
      },
      label: {
        height: '.75rem',
        lineHeight: '.75rem',
        fontSize: FontSizes.mini,
        marginBottom: '.5rem',
        color: NeutralColors.gray120,
      },
      button: {
        position: 'relative',
        top: '.1rem',
      },

      'button[aria-checked="false"]': {
        '.ms-Toggle-thumb': {
          backgroundColor: MEETINGFLOW_COLORS.purpleLight,
        },
      },

      '.ms-Toggle-thumb': {
        backgroundColor: MEETINGFLOW_COLORS.purpleMedium,
      },
      'button:disabled .ms-Toggle-thumb': {
        backgroundColor: isDark ? NeutralColors.gray170 : NeutralColors.gray70,
      },
      'button:hover': {
        backgroundColor: isDark
          ? NeutralColors.gray190
          : MEETINGFLOW_COLORS.purpleLight,

        '.ms-Toggle-thumb': {
          backgroundColor: MEETINGFLOW_COLORS.purpleSecondary,
        },
      },
    },
  } as Partial<IToggleStyles>;

  const onSelectedSpeakersChanged = useCallback(
    (
      _e: React.FormEvent<HTMLDivElement>,
      o: IDropdownOption<unknown> | undefined,
    ) => {
      if (o) {
        const index = selectedSpeakers.findIndex((x) => x.key === o.key);
        if (selectedSpeakers && o.selected) {
          index === -1 && setSelectedSpeakers([...selectedSpeakers, o]);
        } else if (selectedSpeakers && !o.selected) {
          setSelectedSpeakers([
            ...selectedSpeakers.slice(0, index),
            ...selectedSpeakers.slice(index + 1),
          ]);
        }
      }
    },
    [selectedSpeakers],
  );

  const onAutoscrollChanged = useCallback(
    (
      _e: React.MouseEvent<HTMLElement, MouseEvent>,
      checked: boolean | undefined,
    ) => {
      appInsights.trackEvent({
        name: 'CALL_RECORDING_TRANSCRIPT_TOGGLE_AUTO_SCROLL',
        properties: {
          meetingPlanId,
          autoScroll: checked,
        },
      });
      checked !== undefined && setAutoScroll(checked);
    },
    [appInsights, meetingPlanId],
  );

  const transcriptUtterances = useMemo(
    () =>
      filteredUtterances.map((utterance) => (
        <div
          key={`${utterance.idx}-${utterance.text}`}
          className={classNames('utterance', `utterance-${utterance.idx}`)}
          onClick={() =>
            onSeekTranscriptUtterance?.(Math.max(0, utterance.startTime - 0.5))
          }
          title={`Video Time: ${formatSeconds(
            utterance.startTime,
          )}-${formatSeconds(utterance.endTime)}`}
        >
          {utterance.idx === 0 ||
          utterance.speaker !==
            filteredUtterances[utterance.idx - 1]?.speaker ? (
            <span
              className="speaker"
              style={{
                cursor:
                  onContactClick &&
                  !!utterance.speaker &&
                  speakerContactMatches[utterance.speaker]
                    ? 'pointer'
                    : 'default',
                color: utterance?.speaker
                  ? getColorForSpeaker(utterance?.speaker)
                  : undefined,
              }}
              onClick={
                onContactClick &&
                !!utterance.speaker &&
                speakerContactMatches[utterance.speaker]
                  ? (e) => {
                      // Navigate to contact page
                      e.stopPropagation();
                      if (utterance.speaker !== null) {
                        const contact =
                          speakerContactMatches[utterance.speaker];
                        if (contact && isFullscreen) {
                          window.open(
                            `/organization/${organizationSlug}/library/contact/${contact.id}`,
                            '_blank',
                          );
                        } else if (contact) {
                          onContactClick(contact.id);
                        }
                      }
                    }
                  : undefined
              }
            >
              {utterance.speaker}{' '}
              {!!utterance.speaker && speakerContactMatches[utterance.speaker]
                ? `(${speakerContactMatches[utterance.speaker].emailDomain})`
                : null}
            </span>
          ) : null}
          <span className="utterance-text">
            <span className="utterance-actions">
              <CommandButton
                key={`${utterance.text}_${utterance.startTime}_${utterance.endTime}`}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                menuIconProps={{
                  iconName: 'More',
                }}
                menuProps={{
                  items: [
                    {
                      key: 'seek',
                      text: 'Seek video to this timestamp',
                      iconProps: { iconName: 'FastForward' },
                      onClick: () => {
                        onSeekTranscriptUtterance?.(
                          Math.max(0, utterance.startTime - 0.5),
                        );
                        appInsights.trackEvent({
                          name: 'CALL_RECORDING_TRANSCRIPT_MORE_MENU_SEEK_TO_TOPIC',
                          properties: {
                            meetingPlanId,
                            recordingTimestamp: utterance.startTime,
                          },
                        });
                      },
                    },

                    {
                      key: 'share',
                      text: 'Share Meetingflow at this Timestamp',
                      iconProps: { iconName: 'Share' },
                      onClick: () => {
                        showCollabDialogForTimestamp?.(utterance.startTime);
                        appInsights.trackEvent({
                          name: 'CALL_RECORDING_TRANSCRIPT_MORE_MENU_SHARE_TIMESTAMP',
                          properties: {
                            meetingPlanId,
                            recordingTimestamp: utterance.startTime,
                          },
                        });
                      },
                    },
                    {
                      key: 'copy',
                      text: 'Copy link to Meetingflow at this timestamp',
                      iconProps: { iconName: 'Copy' },
                      onClick: (e) => {
                        e?.preventDefault();
                        const externalView =
                          window.location.href.includes('/share/');
                        const url = externalView
                          ? `${window.location.href}?recordingTimestamp=${utterance.startTime}`
                          : `${window.location.origin}/organization/${organizationSlug}/plan/${meetingPlanId}/?recordingTimestamp=${utterance.startTime}`;
                        navigator.clipboard
                          .writeText(url)
                          .then(() =>
                            toast.success(
                              `Copied Meetingflow URL (with timestamp) to the clipboard.`,
                            ),
                          );
                        appInsights.trackEvent({
                          name: 'CALL_RECORDING_TRANSCRIPT_MORE_MENU_COPY_LINK',
                          properties: {
                            meetingPlanId,
                            recordingTimestamp: utterance.startTime,
                          },
                        });
                      },
                    },
                  ],
                }}
                styles={{
                  root: {
                    height: '1rem',
                    width: '2rem',
                    position: 'absolute',
                    marginTop: '.25rem',
                    right: 0,
                  },
                }}
              />
            </span>
            <span className="utterance-time">{`${formatSeconds(
              utterance.startTime,
            )}`}</span>
            {utterance.text}
          </span>
        </div>
      )),
    [
      appInsights,
      filteredUtterances,
      getColorForSpeaker,
      isFullscreen,
      meetingPlanId,
      onContactClick,
      onSeekTranscriptUtterance,
      organizationSlug,
      showCollabDialogForTimestamp,
      speakerContactMatches,
    ],
  );

  return (
    <div className={transcriptStyles}>
      <div className={'transcript-controls'}>
        <SearchBox
          onChange={(e, v) => setSearchString(v || '')}
          styles={searchBoxStyles}
          placeholder="Search..."
        />
        <Dropdown
          placeholder="Select speakers..."
          multiSelect
          options={speakerOptions}
          styles={dropdownStyles}
          onChange={onSelectedSpeakersChanged}
        />
        <Toggle
          label={isRealtime ? 'Autoscroll to Latest' : 'Scroll with Video'}
          checked={autoScroll}
          onChange={onAutoscrollChanged}
          styles={toggleStyles}
        />
      </div>
      <div className="transcript">
        {transcriptUtterances}

        {filteredUtterances.length === 0 ? (
          <div>
            <span className="no-data-message">
              No results found. You may want to clear your search and/or select
              more speakers.
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
};
