import {
  CalendarEvent,
  DealRoomArtifact,
} from '@meetingflow/common/Api/data-contracts';
import { Modal, Stack, IButton, IPivot } from '@fluentui/react';
import {
  DealRoomAsyncPrimaryButton,
  DealRoomCommandBarButton,
} from '../Components/DealRoomButton';
import { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import {
  DealRoomsApiClient,
  MeetingflowsApiClient,
} from '../../../Services/NetworkCommon';
import { useNavigate } from '../../../Hooks/useNavigate';
import { useQueryClient } from 'react-query';
import { matchPath } from 'react-router';
import {
  MeetingPlanQuery,
  OrganizationMeetingPlansQuery,
  OrganizationMeetingsHappeningSoon,
  OrganizationUpcomingMeetings,
} from '../../../QueryNames';
import { isAxiosErrorResponse } from '../../../Helpers/AxiosHelpers';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { getStyles } from './UploadDealRoomAttachmentsDialog.styles';
import { DialogHeader } from './UploadAttachmentComponents/DialogHeader';
import { UploadForm } from './UploadAttachmentComponents/UploadForm';

interface UploadDealRoomAttachmentsDialogProps {
  organizationSlug: string;
  dealRoomId: number;
  onAdd: (item: DealRoomArtifact) => void;
  onDismiss: () => void;
  artifact?: DealRoomArtifact;
  file?: File;
}

/**
 * Dialog component for uploading attachments to a deal room.
 * Supports both file uploads and URL attachments.
 */
export const UploadDealRoomAttachmentsDialog = ({
  organizationSlug,
  dealRoomId,
  onAdd,
  onDismiss,
  artifact,
  file,
}: UploadDealRoomAttachmentsDialogProps) => {
  // State management
  const [selectedFile, setSelectedFile] = useState<File | null>(file || null);
  const [url, setUrl] = useState('');
  const [title, setTitle] = useState('');
  const [meetingflowTitle, setMeetingflowTitle] = useState('');
  const [description, setDescription] = useState('');
  const [selectedTab, setSelectedTab] = useState<string>('file');

  // Refs
  const submitButtonRef = useRef<IButton>(null);
  const pivotRef = useRef<IPivot>(null);

  // Hooks
  const styles = getStyles();
  const client = useQueryClient();
  const { dealRoomRole } = useDealRoom(organizationSlug, dealRoomId);
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  // Navigation guard
  useEffect(() => {
    if (!dealRoomId || !organizationSlug) {
      navigate(`/`);
    }
  }, [dealRoomId, organizationSlug, navigate]);

  const handleDeleteArtifact = useCallback(async () => {
    if (!artifact) return;
    try {
      const token = await getAccessTokenSilently();
      await DealRoomsApiClient.deleteArtifact(
        organizationSlug!,
        dealRoomId,
        artifact.id,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return await client.refetchQueries({
        queryKey: ['artifacts', dealRoomId, organizationSlug],
      });
    } catch (err: unknown) {
      return err;
    }
  }, [artifact, dealRoomId, getAccessTokenSilently, organizationSlug, client]);

  // Example meetingflow URL: https://app.meetingflow.com/organization/example-co/plan/5aef782f-0fd9-4398-9b41-6f7f48eea422
  const parseMeetingflowUrl = useCallback(
    (url: string) => {
      try {
        const match = matchPath(
          '/organization/:organizationSlug/plan/:meetingPlanId',
          new URL(url).pathname,
        );
        if (!match) {
          return {
            isMeetingflowUrl: false,
            isMeetingflowUrlInCurrentOrg: false,
          };
        }
        const { organizationSlug: urlOrganizationSlug } = match.params;
        return {
          isMeetingflowUrl: true,
          isMeetingflowUrlInCurrentOrg:
            urlOrganizationSlug === organizationSlug,
          organizationSlug: urlOrganizationSlug,
          meetingPlanId: match.params.meetingPlanId,
        };
      } catch {
        return { isMeetingflowUrl: false, isMeetingflowUrlInCurrentOrg: false };
      }
    },
    [organizationSlug],
  );

  const isMeetingflowUrl = useCallback(() => {
    return parseMeetingflowUrl(url).isMeetingflowUrl;
  }, [url, parseMeetingflowUrl]);

  const isMeetingflowUrlInCurrentOrg = useCallback(() => {
    return parseMeetingflowUrl(url).isMeetingflowUrlInCurrentOrg;
  }, [url, parseMeetingflowUrl]);

  const createNewMeetingflowAndArtifact = useCallback(
    async (event?: CalendarEvent | undefined) => {
      const token = await getAccessTokenSilently();

      let reqBody = {};
      if (event) {
        reqBody = {
          source: event.source,
          eventId: event.externalId,
          eventTime: event.startTime,
        };
      } else {
        reqBody = {
          title: meetingflowTitle,
        };
      }

      let newMeetingflowId: string | null = null;

      await toast.promise(
        MeetingflowsApiClient.postMeetingflow(organizationSlug, reqBody, {
          headers: { Authorization: `Bearer ${token}` },
          validateStatus: (code) => code === 201 || code === 302,
        }),
        {
          loading: 'Creating Meetingflow',
          success: (result) => {
            Promise.all([
              client.invalidateQueries(
                OrganizationMeetingPlansQuery(organizationSlug),
              ),
              client.invalidateQueries(
                OrganizationMeetingsHappeningSoon(organizationSlug),
              ),
              client.invalidateQueries(
                OrganizationUpcomingMeetings(organizationSlug),
              ),
            ]);

            client.setQueryData(
              MeetingPlanQuery(organizationSlug, result.data.id),
              result,
            );

            newMeetingflowId = result.data.id;

            if (result.status === 201) {
              return `A Meetingflow ${
                event ? `for ${event?.title}` : ''
              } has been created!`;
            } else if (result.status === 302) {
              return `A Meetingflow ${
                event ? `for ${event?.title}` : ''
              } has been created!`;
            }

            return null;
          },
          error: (err) => {
            if (err && isAxiosErrorResponse(err, 403)) {
              return `You don't have permission to create Meetingflows in this workspace`;
            }
            return 'Something went wrong creating the Meetingflow, please try again';
          },
        },
      );

      if (newMeetingflowId) {
        await toast.promise(
          DealRoomsApiClient.createLinkArtifact(
            organizationSlug,
            dealRoomId,
            {
              type: 'MEETINGFLOW',
              meetingflowId: newMeetingflowId,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          ),
          {
            loading: 'Uploading attachment...',
            success: (result) => {
              onAdd(result.data);

              navigate(
                `/organization/${organizationSlug}/dealroom/${dealRoomId}/plan/${newMeetingflowId}`,
              );
              return `Successfully uploaded Meetingflow attachment`;
            },
            error: 'Failed to upload Meetingflow attachment',
          },
        );
      }
    },
    [
      client,
      dealRoomId,
      getAccessTokenSilently,
      meetingflowTitle,
      navigate,
      onAdd,
      organizationSlug,
    ],
  );

  const uploadArtifact = useCallback(
    async (artifactId: number | null, createNewMeetingflow?: boolean) => {
      if (createNewMeetingflow && !meetingflowTitle) return;

      if (createNewMeetingflow) {
        await createNewMeetingflowAndArtifact();
      }

      if (!selectedFile && !url) return;

      const token = await getAccessTokenSilently();

      if (selectedFile && selectedTab === 'file') {
        await toast.promise(
          DealRoomsApiClient.uploadFileArtifact(
            organizationSlug,
            dealRoomId,
            {
              file: selectedFile!,
              ...(title || artifact?.name
                ? { name: title || artifact?.name }
                : {}),
              ...(description ? { description } : {}),
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          ),
          {
            loading: 'Uploading attachment...',
            success: (result) => {
              onAdd(result.data);
              if (artifactId && result.data && 'id' in result.data) {
                navigate(
                  `/organization/${organizationSlug}/dealroom/${dealRoomId}/artifact/${result.data.id}`,
                  { preserveQuery: true },
                );
              }
              return `Successfully ${artifact ? 'updated' : 'uploaded'} artifact`;
            },
            error: 'Failed to upload artifact',
          },
        );
      } else if (
        url &&
        organizationSlug &&
        dealRoomId &&
        selectedTab === 'url'
      ) {
        if (isMeetingflowUrlInCurrentOrg()) {
          const { organizationSlug, meetingPlanId } = parseMeetingflowUrl(url);

          if (organizationSlug && meetingPlanId) {
            await toast.promise(
              DealRoomsApiClient.createLinkArtifact(
                organizationSlug!,
                dealRoomId,
                {
                  type: 'MEETINGFLOW',
                  meetingflowId: meetingPlanId,
                },
                {
                  headers: { Authorization: `Bearer ${token}` },
                },
              ),
              {
                loading: 'Uploading attachment...',
                success: (result) => {
                  onAdd(result.data);
                  return `Successfully uploaded Meetingflow attachment`;
                },
                error: 'Failed to upload Meetingflow attachment',
              },
            );
          }
        } else {
          if (isMeetingflowUrl() && !isMeetingflowUrlInCurrentOrg()) {
            toast.error(
              'It looks like this is a Meetingflow from a different organization. To add a Meetingflow, provide a URL from the current organization.',
            );
            return;
          }

          await toast.promise(
            DealRoomsApiClient.createLinkArtifact(
              organizationSlug,
              dealRoomId,
              {
                type: 'LINK',
                url,
                name: title || url,
                description: description || undefined,
              },
              {
                headers: { Authorization: `Bearer ${token}` },
              },
            ),
            {
              loading: 'Uploading artifact...',
              success: (result) => {
                onAdd(result.data);
                return `Successfully uploaded artifact`;
              },
              error: 'Failed to upload artifact',
            },
          );
        }
      }
    },
    [
      artifact,
      dealRoomId,
      description,
      getAccessTokenSilently,
      navigate,
      onAdd,
      organizationSlug,
      selectedFile,
      title,
      url,
      isMeetingflowUrl,
      parseMeetingflowUrl,
      createNewMeetingflowAndArtifact,
      meetingflowTitle,
      selectedTab,
      isMeetingflowUrlInCurrentOrg,
    ],
  );

  const handleUploadFile = useCallback(async () => {
    if (!selectedFile && !url && !meetingflowTitle) return;

    if (artifact) {
      const response = await handleDeleteArtifact();
      if (
        response &&
        typeof response === 'object' &&
        'status' in response &&
        response.status === 204
      ) {
        await uploadArtifact(artifact.id);
        await client.refetchQueries({
          queryKey: ['artifacts', dealRoomId, organizationSlug],
        });
      } else {
        toast.error('Something went wrong! Please try again later.');
      }
    } else {
      await uploadArtifact(null, selectedTab === 'new-meetingflow');
      await client.refetchQueries({
        queryKey: ['artifacts', dealRoomId, organizationSlug],
      });
    }
  }, [
    artifact,
    handleDeleteArtifact,
    selectedFile,
    uploadArtifact,
    client,
    dealRoomId,
    organizationSlug,
    url,
    meetingflowTitle,
    selectedTab,
  ]);

  // Submit on Enter key press
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleUploadFile();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleUploadFile]);

  // Focus on submit button when file is selected
  useEffect(() => {
    if (selectedFile) {
      submitButtonRef.current?.focus();
    }
  }, [selectedFile]);

  const buttonDisabled =
    (selectedTab === 'file' && !selectedFile) ||
    (selectedTab === 'url' && !url) ||
    (selectedTab === 'new-meetingflow' && !meetingflowTitle);

  return (
    <Modal className={styles.modalWrapper} isOpen onDismiss={onDismiss}>
      <Stack className={styles.contentContainer}>
        <DialogHeader
          title={artifact ? 'Update Version' : 'Add New Artifact'}
          onDismiss={onDismiss}
        />

        <UploadForm
          title={title}
          setTitle={setTitle}
          description={description}
          setDescription={setDescription}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          artifact={artifact}
          url={url}
          setUrl={setUrl}
          dealRoomRole={dealRoomRole}
          meetingflowTitle={meetingflowTitle}
          setMeetingflowTitle={setMeetingflowTitle}
          pivotRef={pivotRef}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />

        <Stack.Item className={styles.footerContainer}>
          <DealRoomCommandBarButton
            customClasses="cancel-button"
            buttonStyleType="COMMAND_BAR"
            text="Cancel"
            onClick={onDismiss}
          />
          <DealRoomAsyncPrimaryButton
            customClasses={classNames('save-button', {
              ['save-button-disabled']: buttonDisabled,
            })}
            componentRef={submitButtonRef}
            disabled={buttonDisabled}
            onClick={handleUploadFile}
            text="Add Artifact"
          />
        </Stack.Item>
      </Stack>
    </Modal>
  );
};
