export const PrioritiesHeaderLogo = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.4154 13.9997C23.9592 13.9997 26.832 16.8725 26.832 20.4163C26.832 23.9602 23.9592 26.833 20.4154 26.833C16.8715 26.833 13.9987 23.9602 13.9987 20.4163C13.9987 16.8725 16.8715 13.9997 20.4154 13.9997ZM23.5029 17.6705L18.7285 22.4449L17.382 20.6497C17.1887 20.3919 16.8231 20.3397 16.5654 20.533C16.3076 20.7263 16.2554 21.0919 16.4487 21.3497L18.1987 23.683C18.4117 23.967 18.8268 23.9965 19.0779 23.7455L24.3279 18.4955C24.5557 18.2677 24.5557 17.8983 24.3279 17.6705C24.1001 17.4427 23.7307 17.4427 23.5029 17.6705ZM12.8541 19.833H3.20703L3.0883 19.841C2.66121 19.8989 2.33203 20.265 2.33203 20.708C2.33203 21.1913 2.72378 21.583 3.20703 21.583H12.9212C12.8625 21.2027 12.832 20.8131 12.832 20.4163C12.832 20.2201 12.8395 20.0255 12.8541 19.833ZM13.6863 16.9163H3.20703C2.72378 16.9163 2.33203 16.5246 2.33203 16.0413C2.33203 15.5984 2.66121 15.2323 3.0883 15.1743L3.20703 15.1663H14.9432C14.4458 15.6847 14.0213 16.2735 13.6863 16.9163ZM24.7904 10.4997H3.20703L3.0883 10.5077C2.66121 10.5656 2.33203 10.9317 2.33203 11.3747C2.33203 11.8579 2.72378 12.2497 3.20703 12.2497H24.7904L24.9091 12.2417C25.3362 12.1837 25.6654 11.8177 25.6654 11.3747C25.6654 10.8914 25.2736 10.4997 24.7904 10.4997ZM24.7904 5.83301H3.20703L3.0883 5.841C2.66121 5.89893 2.33203 6.26503 2.33203 6.70801C2.33203 7.19126 2.72378 7.58301 3.20703 7.58301H24.7904L24.9091 7.57502C25.3362 7.51708 25.6654 7.15099 25.6654 6.70801C25.6654 6.22476 25.2736 5.83301 24.7904 5.83301Z"
      fill="#AFAEB1"
    />
  </svg>
);
