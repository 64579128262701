import { useAuth0 } from '@auth0/auth0-react';
import {
  FontSizes,
  FontWeights,
  NeutralColors,
  mergeStyles,
  useTheme,
} from '@fluentui/react';
import { StatusCircleErrorXIcon } from '@fluentui/react-icons-mdl2';
import { SlateTag } from '@meetingflow/common/Types/Slate';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { Editor, Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useSlateStatic } from 'slate-react';
import { useOrganization } from '../../../Hooks/useOrganization';
import { OrganizationTagQuery } from '../../../QueryNames';
import { TagsApiClient } from '../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { MeetingPlanPanelContext } from '../../../types/MeetingPlanPanelContext';

export const TagElement = ({
  attributes,
  children,
  element,
  setPanelContext,
}: RenderElementProps & {
  setPanelContext?: (
    context?: MeetingPlanPanelContext | MeetingPlanPanelContext[],
  ) => void;
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const theme = useTheme();
  const editor = useSlateStatic();

  const { slug: organizationSlug } = useOrganization();

  const slateTag = element as SlateTag;

  const {
    data: tag,
    isLoading: tagLoading,
    isRefetching: tagRefetching,
    refetch: refetchTag,
  } = useQuery(
    OrganizationTagQuery(organizationSlug!, slateTag.tagId),
    async () => {
      const token = await getAccessTokenSilently();
      return TagsApiClient.getTag(organizationSlug!, slateTag.tagId, {
        headers: { Authorization: `Bearer ${token}` },
      });
    },
    {
      enabled: !!organizationSlug && !!slateTag.tagId,
      onSuccess: (tagResponse) => {
        const path = ReactEditor.findPath(editor, element);

        if (!slateTag.label || slateTag.label !== tagResponse.data.label) {
          Transforms.setNodes(
            editor,
            {
              label: tagResponse.data.label,
            } as Partial<SlateTag>,
            { at: path },
          );
        }
      },
    },
  );

  useEffect(() => {
    if (slateTag.tagId) {
      refetchTag();
    }
  }, [refetchTag, slateTag.tagId]);

  const tagClass = mergeStyles({
    fontWeight: FontWeights.semibold,
    color: theme.palette.themePrimary,
    display: 'inline-block',
    position: 'relative',
    margin: '0px 0.1rem',
  });

  const tagStyles = mergeStyles({
    display: 'inline-flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignItems: 'center',
    alignContent: 'space-around',
    columnGap: '0.25rem',
    height: '1.2rem',
    backgroundColor: MEETINGFLOW_COLORS.magentaDark,
    borderRadius: '1rem',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    lineHeight: '1.1rem',
    fontWeight: FontWeights.semibold,
    fontSize: FontSizes.small,
    color: MEETINGFLOW_COLORS.white,
    cursor: 'pointer',
    transition: '.3s ease-in-out all',
    ':hover': {
      backgroundColor: MEETINGFLOW_COLORS.magenta,
      color: NeutralColors.white,
      paddingRight: '0',

      '.tags-buttons-wrapper': {
        display: 'inline-block',
        maxWidth: '5000rem',
        marginLeft: '.25rem',
      },
    },

    '.tags-buttons-wrapper': {
      maxWidth: '0px',
      width: 'auto',
      height: '100%',
      display: 'flex',
      overflow: 'hidden',
      transition: '.3s ease-in-out all',
      backgroundColor: MEETINGFLOW_COLORS.magentaDark,
      borderTopRightRadius: '1rem',
      borderBottomRightRadius: '1rem',

      '[data-tag-button="true"]': {
        display: 'inline-block',
        height: '100%',
        width: '1rem',
        lineHeight: '1.2rem',
        textAlign: 'center',
        paddingLeft: '.25rem',
        paddingRight: '.25rem',
        transition: '.3s ease-in-out all',
        ':hover': {
          backgroundColor: MEETINGFLOW_COLORS.tealDarker,
        },
        svg: {
          height: '1em',
          verticalAlign: 'baseline',
        },
      },
    },
  });

  return (
    <div contentEditable={false} {...attributes} className={tagClass}>
      {children}
      <div
        className={tagStyles}
        contentEditable={false}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          if (!organizationSlug || !slateTag.tagId) {
            return;
          }

          const path = ReactEditor.findPath(editor, element);
          Transforms.select(editor, Editor.end(editor, path));

          setPanelContext?.({
            type: 'plans',
            filters: { tagId: slateTag.tagId },
            title: `Meetingflows tagged #${
              tag?.data.label ?? slateTag.label ?? slateTag.tagId
            }`,
          });
        }}
      >
        <span contentEditable={false} className="pound-sign">
          #
        </span>
        {tag?.data.label ?? slateTag.label ?? slateTag.tagId}
        <div className="tags-buttons-wrapper">
          <StatusCircleErrorXIcon
            contentEditable={false}
            data-tag-button
            onClick={(e) => {
              e.stopPropagation();
              Transforms.delete(editor, {
                at: ReactEditor.findPath(editor, element),
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};
