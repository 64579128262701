import { DatePicker, IDatePicker, mergeStyleSets, Text } from '@fluentui/react';
import { DateTime } from 'luxon';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import classNames from 'classnames';
import { useCallback, useRef } from 'react';

type MilestoneDatePickerProps = {
  value: string | null | undefined;
  onChange: (value: Date | null | undefined) => void;
  hideTitle?: boolean;
  className?: string;
};

export const MilestoneDatePicker = ({
  value,
  onChange,
  hideTitle,
  className,
}: MilestoneDatePickerProps) => {
  const datePickerRef = useRef<IDatePicker | null>(null);
  const styles = mergeStyleSets({
    datePickerContainer: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: DEALROOMS_COLORS.neutralLighter,
      borderRadius: '.25rem',
    },
    datePickerLabel: {
      cursor: 'pointer',
      paddingLeft: '.5rem',
      lineHeight: '2rem',
      fontSize: '1rem',
      color: DEALROOMS_COLORS.neutralDarker,
    },
    datePicker: {
      minWidth: '120px',
      '.ms-TextField-fieldGroup': {
        border: 'none',
        backgroundColor: DEALROOMS_COLORS.neutralLighter,
        display: 'flex',
        alignItems: 'center',
        ':after': {
          border: 'none',
          outline: `1px solid ${DEALROOMS_COLORS.neutralSecondary}`,
          outlineOffset: '-3px',
        },
      },

      '.ms-TextField-field': {
        border: 'none',
        height: 'fit-content',
        fontSize: '1rem',
        lineHeight: '1rem',
        color: DEALROOMS_COLORS.neutralDarker,
        '> span': {
          color: DEALROOMS_COLORS.neutralDarker,
        },
      },
      '.ms-TextField > span > div:empty': {
        display: 'none',
      },
      '.ms-TextField-fieldGroup > div': {
        marginRight: '.5rem',
      },
    },
  });

  const handleOpenDatePicker = useCallback(() => {
    try {
      datePickerRef?.current?.showDatePickerPopup();
    } catch (err) {}
  }, []);

  const formatSelectedDate = (date: Date | undefined): string => {
    if (!date) return '';
    return DateTime.fromJSDate(date).toFormat('MM/dd/yyyy');
  };

  return (
    <div
      className={styles.datePickerContainer}
      onClick={(e) => e.stopPropagation()}
    >
      {!hideTitle && (
        <Text className={styles.datePickerLabel} onClick={handleOpenDatePicker}>
          Due
        </Text>
      )}
      <DatePicker
        componentRef={datePickerRef}
        className={classNames(styles.datePicker, className)}
        placeholder="Date..."
        value={value ? new Date(value) : undefined}
        onSelectDate={onChange}
        formatDate={formatSelectedDate}
      />
    </div>
  );
};
