import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

export type colorType = string | undefined | null;

type IconProps = {
  color?: colorType;
};

export const ProfileSettingsIllustrations = ({ color }: IconProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 11C7.55228 11 8 10.5523 8 10C8 9.44771 7.55228 9 7 9C6.44772 9 6 9.44771 6 10C6 10.5523 6.44772 11 7 11ZM8 13C8 13.5523 7.55228 14 7 14C6.44772 14 6 13.5523 6 13C6 12.4477 6.44772 12 7 12C7.55228 12 8 12.4477 8 13ZM10 11C10.5523 11 11 10.5523 11 10C11 9.44771 10.5523 9 10 9C9.44771 9 9 9.44771 9 10C9 10.5523 9.44771 11 10 11ZM11 13C11 13.5523 10.5523 14 10 14C9.44771 14 9 13.5523 9 13C9 12.4477 9.44771 12 10 12C10.5523 12 11 12.4477 11 13ZM13 11C13.5523 11 14 10.5523 14 10C14 9.44771 13.5523 9 13 9C12.4477 9 12 9.44771 12 10C12 10.5523 12.4477 11 13 11ZM17 5.5C17 4.11929 15.8807 3 14.5 3H5.5C4.11929 3 3 4.11929 3 5.5V14.5C3 15.8807 4.11929 17 5.5 17H14.5C15.8807 17 17 15.8807 17 14.5V5.5ZM4 7H16V14.5C16 15.3284 15.3284 16 14.5 16H5.5C4.67157 16 4 15.3284 4 14.5V7ZM5.5 4H14.5C15.3284 4 16 4.67157 16 5.5V6H4V5.5C4 4.67157 4.67157 4 5.5 4Z"
      fill={color || DEALROOMS_COLORS.neutralDarker}
    />
  </svg>
);

export const BuyerPrefSettingsIllustrations: React.FC<IconProps> = ({
  color,
}: IconProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.96094 6.66699H6.04427"
      stroke={color || DEALROOMS_COLORS.themeSecondary}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.625 6.66699H16.0417"
      stroke={color || DEALROOMS_COLORS.themeSecondary}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.96094 13.333H10.2109"
      stroke={color || DEALROOMS_COLORS.themeSecondary}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.7891 13.333H16.0391"
      stroke={color || DEALROOMS_COLORS.themeSecondary}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.33594 8.54199C9.37147 8.54199 10.2109 7.70253 10.2109 6.66699C10.2109 5.63146 9.37147 4.79199 8.33594 4.79199C7.3004 4.79199 6.46094 5.63146 6.46094 6.66699C6.46094 7.70253 7.3004 8.54199 8.33594 8.54199Z"
      stroke={color || DEALROOMS_COLORS.themeSecondary}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 15.208C13.5355 15.208 14.375 14.3685 14.375 13.333C14.375 12.2975 13.5355 11.458 12.5 11.458C11.4645 11.458 10.625 12.2975 10.625 13.333C10.625 14.3685 11.4645 15.208 12.5 15.208Z"
      stroke={color || DEALROOMS_COLORS.themeSecondary}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const InviteSettingsIllustrations: React.FC<IconProps> = ({
  color,
}: IconProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3173_4198)">
      <path
        d="M4.81612 16.042H11.0133C11.4837 16.042 11.8539 15.6517 11.7601 15.1907C11.5014 13.918 10.6561 11.667 7.91472 11.667C5.17331 11.667 4.32809 13.918 4.06929 15.1907C3.97555 15.6517 4.3457 16.042 4.81612 16.042Z"
        stroke={color || DEALROOMS_COLORS.themeSecondary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.125 11.667C14.8573 11.667 15.5668 13.4569 15.8532 14.747C16.0079 15.4437 15.4444 16.042 14.7308 16.042H13.9583"
        stroke={color || DEALROOMS_COLORS.themeSecondary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.91667 8.54134C9.18232 8.54134 10.2083 7.51533 10.2083 6.24967C10.2083 4.98402 9.18232 3.95801 7.91667 3.95801C6.65101 3.95801 5.625 4.98402 5.625 6.24967C5.625 7.51533 6.65101 8.54134 7.91667 8.54134Z"
        stroke={color || DEALROOMS_COLORS.themeSecondary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2891 8.54134C13.5547 8.54134 14.3724 7.51532 14.3724 6.24967C14.3724 4.98402 13.5547 3.95801 12.2891 3.95801"
        stroke={color || DEALROOMS_COLORS.themeSecondary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3173_4198">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CheckmarkSettingsIllustrations: React.FC<IconProps> = ({
  color,
}: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.75 12.75L10 15.25L16.25 8.75"
      stroke={color || DEALROOMS_COLORS.white}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const TimeZonesIllustrations: React.FC<IconProps> = ({
  color,
}: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 19.25C16.0041 19.25 19.25 16.0041 19.25 12C19.25 7.99594 16.0041 4.75 12 4.75C7.99594 4.75 4.75 7.99594 4.75 12C4.75 16.0041 7.99594 19.25 12 19.25Z"
      stroke={color || DEALROOMS_COLORS.themeSecondary}
      strokeWidth="1.5"
    />
    <path
      d="M12 8V12L14 14"
      stroke={color || DEALROOMS_COLORS.themeSecondary}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SendInviteIllustrations: React.FC<IconProps> = ({
  color,
}: IconProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.51799 16.3167L10.5597 4.23335H9.43466L15.4763 16.3167L16.3013 15.4667L10.2597 12.55C10.0847 12.4667 9.88466 12.4667 9.70966 12.55L3.66799 15.4667L4.49299 16.3083L4.51799 16.3167ZM3.39299 15.75C3.12633 16.275 3.68466 16.85 4.21799 16.5917L10.2597 13.675H9.70966L15.7513 16.5917C16.2847 16.85 16.843 16.275 16.5763 15.7417L10.5347 3.65835C10.3013 3.19168 9.64299 3.19168 9.40966 3.65835L3.36799 15.7417L3.39299 15.75Z"
      fill={color || DEALROOMS_COLORS.white}
    />
    <path
      d="M10.625 12.9167V10.625C10.625 10.275 10.3417 10 10 10C9.65 10 9.375 10.275 9.375 10.625V12.9167C9.375 13.2583 9.65 13.5417 10 13.5417C10.3417 13.5417 10.625 13.2583 10.625 12.9167Z"
      fill={color || DEALROOMS_COLORS.white}
    />
  </svg>
);

export const MailIllustrations: React.FC<IconProps> = ({
  color,
}: IconProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.58203 6.45829C4.58203 5.87496 5.04036 5.41663 5.6237 5.41663H14.3737C14.9487 5.41663 15.4154 5.87496 15.4154 6.45829V13.5416C15.4154 14.1166 14.9487 14.5833 14.3737 14.5833H5.6237C5.04036 14.5833 4.58203 14.1166 4.58203 13.5416V6.45829ZM3.33203 6.45829V13.5416C3.33203 14.8 4.35703 15.8333 5.6237 15.8333H14.3737C15.632 15.8333 16.6654 14.8 16.6654 13.5416V6.45829C16.6654 5.19163 15.632 4.16663 14.3737 4.16663H5.6237C4.35703 4.16663 3.33203 5.19163 3.33203 6.45829Z"
      fill={color || DEALROOMS_COLORS.themePrimary}
    />
    <path
      d="M4.16461 5.88324L9.58128 10.6749C9.81461 10.8832 10.1729 10.8832 10.4063 10.6749L15.8229 5.88324C16.0813 5.64991 16.0979 5.25824 15.8729 4.99991C15.6396 4.73324 15.2479 4.71657 14.9896 4.94157L9.57295 9.73324H10.3979L4.98128 4.94157C4.71461 4.70824 4.32295 4.73324 4.09795 4.99157C3.86461 5.24991 3.88961 5.64157 4.14795 5.86657L4.16461 5.88324Z"
      fill={color || DEALROOMS_COLORS.themePrimary}
    />
  </svg>
);
