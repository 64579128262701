export const ChatIllustration = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_170_961"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="12"
      height="12"
    >
      <rect width="12" height="12" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_170_961)">
      <path
        d="M3.125 6.875H6.875V6.125H3.125V6.875ZM3.125 5.375H8.875V4.625H3.125V5.375ZM3.125 3.875H8.875V3.125H3.125V3.875ZM1.25 10.5192V2.15387C1.25 1.90129 1.3375 1.6875 1.5125 1.5125C1.6875 1.3375 1.90129 1.25 2.15387 1.25H9.84613C10.0987 1.25 10.3125 1.3375 10.4875 1.5125C10.6625 1.6875 10.75 1.90129 10.75 2.15387V7.84613C10.75 8.09871 10.6625 8.3125 10.4875 8.4875C10.3125 8.6625 10.0987 8.75 9.84613 8.75H3.01925L1.25 10.5192ZM2.7 8H9.84613C9.88463 8 9.91988 7.98396 9.95188 7.95188C9.98396 7.91988 10 7.88462 10 7.84613V2.15387C10 2.11537 9.98396 2.08012 9.95188 2.04812C9.91988 2.01604 9.88463 2 9.84613 2H2.15387C2.11537 2 2.08013 2.01604 2.04813 2.04812C2.01604 2.08012 2 2.11537 2 2.15387V8.69238L2.7 8Z"
        fill="#424242"
      />
    </g>
  </svg>
);
