export const readClipboardContents = async (): Promise<string | undefined> => {
  try {
    if (
      navigator.clipboard &&
      'readText' in navigator.clipboard &&
      typeof navigator.clipboard.readText === 'function'
    ) {
      return navigator.clipboard.readText();
    } else {
      console.info('Clipboard API is not available.');
      return undefined;
    }
  } catch (error) {
    console.error('Failed to read from the clipboard:', error);
    return undefined;
  }
};
