import {
  FontWeights,
  IconButton,
  mergeStyles,
  NeutralColors,
} from '@fluentui/react';
import { SlateAIAction } from '@meetingflow/common/Api/data-contracts';
import { Editor, Node, Range } from 'slate';
import { useSlate } from 'slate-react';
import { CreateDeferredPromise } from '../../../Helpers/DeferredPromise';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { AIActionContext } from '../../../types/AIActionContext';
import {
  getParentBlock,
  insertAIAction,
  isBlockActive,
} from '../Helpers/EditorHelpers';

export type AIActionButtonProps = {
  hint?: string;
  getAIActionContext: CreateDeferredPromise<
    AIActionContext,
    Partial<AIActionContext> | undefined
  >;
};
export const AIActionButton = ({
  hint,
  getAIActionContext,
}: AIActionButtonProps) => {
  const editor = useSlate();
  const { isDark } = useLightOrDarkMode();

  const isActive = isBlockActive(editor, 'ai-action');

  const iconButtonClass = mergeStyles({
    backgroundColor: isActive
      ? isDark
        ? MEETINGFLOW_COLORS.purpleSecondary
        : MEETINGFLOW_COLORS.white
      : 'transparent',
    color: isActive
      ? isDark
        ? MEETINGFLOW_COLORS.white
        : MEETINGFLOW_COLORS.purpleSecondary
      : NeutralColors.gray30,
    width: '1.75rem',
    height: '1.75rem',
    i: {
      fontWeight: FontWeights.semibold,
    },

    ':hover': {
      backgroundColor: MEETINGFLOW_COLORS.purpleSecondary,
      color: 'white !important',
    },
  });

  const title =
    `${isActive ? 'Update' : 'Insert'} AI Action` + (hint ? ` (${hint})` : '');

  return (
    <IconButton
      iconProps={{ iconName: 'AISparkle' }}
      className={iconButtonClass}
      title={title}
      alt={title}
      data-meetingflow-is-active={isActive}
      onClick={(event) => {
        event.preventDefault();

        const { selection } = editor;

        const activeAIActionNode = getParentBlock<SlateAIAction>(editor, {
          type: 'ai-action',
        });

        const activeAIAction = activeAIActionNode?.[0];

        getAIActionContext(
          activeAIAction
            ? {
                prompt: activeAIAction.prompt,
                text: Node.string(activeAIAction),
              }
            : selection && !Range.isCollapsed(selection)
            ? { text: Editor.string(editor, selection) }
            : undefined,
        )
          .promise.then((content) => {
            insertAIAction(
              editor,
              content.text,
              content.displayText,
              content.prompt,
            );
          })
          .catch();
      }}
    />
  );
};
