/**
 * Component for displaying and managing attachments in a conversation.
 * Renders a list of artifacts with their icons and optional delete functionality.
 */

import { Icon, IconButton, mergeStyleSets } from '@fluentui/react';
import { useCallback } from 'react';
import { Artifact } from '../../../../types/MilestoneTypes';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { artifactTypeToIconName } from '../../../../Helpers/IconHelpers';
import { hasOwnOptionalStringProperty } from '@meetingflow/common/ObjectHelpers';
import { CancelIconProps } from '../../../../utils/iconProps';

type ConversationAttachmentsProps = {
  artifacts: Artifact[];
  onDelete?: (artifactId: number) => void;
};

export const ConversationAttachments = ({
  artifacts,
  onDelete,
}: ConversationAttachmentsProps) => {
  const styles = mergeStyleSets({
    filesContainer: {
      display: 'flex',
      paddingTop: '.25rem',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: '.5rem',
    },
    fileContainer: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: DEALROOMS_COLORS.neutralLight,
      borderRadius: '.25rem',
      gap: '.125rem',
      padding: '.25rem .375rem',
    },
    artifactName: {
      fontSize: '.75rem',
      lineHeight: '1.125rem',
      color: DEALROOMS_COLORS.neutralDarker,
    },
    deleteButton: {
      padding: 0,
      marginLeft: '.25rem',
      height: '20px',
      width: '20px',
      ':hover': {
        backgroundColor: 'transparent',
      },
    },
    deleteIcon: {
      fontSize: '.75rem',
      color: DEALROOMS_COLORS.neutralDarker,
    },
    fileIcon: {
      color: DEALROOMS_COLORS.themeSecondary,
    },
  });

  const getArtifactIconName = useCallback((artifact: Artifact) => {
    return artifactTypeToIconName(
      artifact.type,
      hasOwnOptionalStringProperty(artifact, 'mimeType')
        ? artifact.mimeType
        : undefined,
    );
  }, []);

  if (!artifacts?.length) {
    return null;
  }

  return (
    <div className={styles.filesContainer}>
      {artifacts.map((artifact) => (
        <div key={artifact.id} className={styles.fileContainer}>
          <Icon
            className={styles.fileIcon}
            iconName={getArtifactIconName(artifact)}
          />
          <span className={styles.artifactName}>{artifact.name}</span>
          {onDelete && (
            <IconButton
              iconProps={CancelIconProps}
              className={styles.deleteButton}
              styles={{
                icon: styles.deleteIcon,
              }}
              onClick={() => onDelete(artifact.id)}
              title="Remove"
            />
          )}
        </div>
      ))}
    </div>
  );
};
