import {
  IButtonProps,
  PrimaryButton,
  CommandBarButton,
  mergeStyles,
  FontSizes,
} from '@fluentui/react';
import React from 'react';
import {
  AsyncPrimaryButton,
  AsyncCommandBarButton,
} from '../../HOC/AsyncButton';
import classNames from 'classnames';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

type ButtonStyleType = 'PRIMARY' | 'COMMAND_BAR' | 'DEAL_ROOM';

type WithButtonStyleType = {
  buttonStyleType?: ButtonStyleType;
  customClasses?: string;
  disabled?: boolean;
};

export const withDealRoomStyling =
  <T extends Pick<IButtonProps, 'style'>>(
    InnerComponent: React.ComponentType<T>,
  ) =>
  (props: T & WithButtonStyleType): JSX.Element => {
    const buttonStyle = mergeStyles({
      ...(props.buttonStyleType === 'COMMAND_BAR'
        ? {
            border: `1px solid ${DEALROOMS_COLORS.lighterGray}`,
            color: DEALROOMS_COLORS.darkerGray,
            padding: '0.375rem 0.75rem ',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            opacity: props?.disabled ? '0.6' : '1',
            cursor: props.disabled ? 'not-allowed !important' : 'pointer',
            i: {
              fontSize: FontSizes.size12,
              color: DEALROOMS_COLORS.themePrimary,
              padding: '1px',
              margin: '0 5px 0 0',
            },
            span: {
              margin: 0,
            },
            ':hover': {
              color: DEALROOMS_COLORS.darkerGray,
              i: {
                color: `${DEALROOMS_COLORS.themePrimary} !important`,
              },
            },
            ':active': {
              color: DEALROOMS_COLORS.darkerGray,
              i: {
                color: `${DEALROOMS_COLORS.themePrimary} !important`,
              },
            },
          }
        : {}),
      ...(props.buttonStyleType === 'DEAL_ROOM'
        ? {
            position: 'relative',
            padding: '.5rem 1rem',
            borderRadius: '.25rem',
            border: 'none',
            backgroundColor: DEALROOMS_COLORS.themeSecondary,
            color: DEALROOMS_COLORS.white,
            minHeight: 'fit-content',
            fontSize: '1rem',
            lineHeight: '1.375rem',
            i: {
              color: DEALROOMS_COLORS.lightGray,
              margin: '0 0.375rem 0 0',
            },
            ':hover': {
              backgroundColor: DEALROOMS_COLORS.themePrimary,
              border: 'none',
              color: DEALROOMS_COLORS.white,
              i: {
                color: `${DEALROOMS_COLORS.lightGray} !important`,
              },
            },
            ':focus-visible': {
              outline: `1px solid  ${DEALROOMS_COLORS.darkerGray}`,
              outlineOffset: '2px',
              color: DEALROOMS_COLORS.white,
            },
            ':active': {
              backgroundColor: DEALROOMS_COLORS.themePrimary,
              border: 'none',
              color: DEALROOMS_COLORS.white,
              i: {
                color: `${DEALROOMS_COLORS.lightGray} !important`,
              },
            },
            '::after': {
              outline: 'none !important',
            },
            '.ms-Button-label': {
              margin: '0',
            },
          }
        : {}),
      'i:has(> svg)': {
        display: 'flex',
        height: 'auto',
      },
    });

    return (
      <InnerComponent
        className={classNames(buttonStyle, props.customClasses || '')}
        {...props}
        style={{
          ...props.style,
          margin: '0px',
          borderRadius: '4px',
        }}
      />
    );
  };

export const DealRoomPrimaryButton = withDealRoomStyling(PrimaryButton);
export const DealRoomAsyncPrimaryButton =
  withDealRoomStyling(AsyncPrimaryButton);
export const DealRoomCommandBarButton = withDealRoomStyling(CommandBarButton);
export const DealRoomAsyncCommandBarButton = withDealRoomStyling(
  AsyncCommandBarButton,
);

export default DealRoomAsyncCommandBarButton;
