import { useAuth0 } from '@auth0/auth0-react';
import {
  Checkbox,
  CheckboxVisibility,
  FontSizes,
  FontWeights,
  IColumn,
  IDialogProps,
  mergeStyles,
  NeutralColors,
  Selection,
  SelectionMode,
  Spinner,
  SpinnerSize,
  Text,
  TextField,
  useTheme,
} from '@fluentui/react';
import { useForceUpdate } from '@fluentui/react-hooks';
import {
  Contact,
  DetailedMeetingflow,
  OrganizationUserRole,
  Task,
} from '@meetingflow/common/Api/data-contracts';
import { OmitValues } from '@meetingflow/common/ObjectHelpers';
import { getDomain } from '@meetingflow/common/StringHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { Y } from '@syncedstore/core';
import { inflect } from 'inflection';
import { orderBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import TokenInput from 'react-customize-token-input';
import 'react-customize-token-input/dist/react-customize-token-input.css';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import { titleCase } from '../../../Helpers/Typography';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { useOrganization } from '../../../Hooks/useOrganization';
import { MeetingPlanShare } from '../../../Models/MeetingPlan';
import {
  MeetingPlanSharesQuery,
  OrganizationContactQuery,
} from '../../../QueryNames';
import {
  ApiClient,
  ContactsApiClient,
  MeetingflowsApiClient,
} from '../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { AsyncPrimaryButton } from '../../HOC/AsyncButton';
import { StyledDetailsList } from '../../StyledDetailsList';
import { CompanyCard, ContactCard } from '../MeetingPlanAttendees';
import { Duration } from 'luxon';
import { useLocalStorageState } from '../../../Hooks/useLocalStorageState';

type PersonWithKey = Contact & {
  key: string;
  share?: MeetingPlanShare;
  [key: string]: unknown;
};

const SHOW_SHARE_ACCESS_USERS = false;

const ShowEmptyGroupsGroupProps = {
  showEmptyGroups: false,
};

const getItemKey = (item: Contact, index?: number | undefined): string =>
  item.email;

const formatSeconds = (seconds: number) =>
  Duration.fromObject({ seconds }).toFormat('h:mm:ss');

type UseShareDialogInviteShareSelectorStateProps = {
  organizationSlug: string;
  meetingPlanId: string;
  meetingPlan: Pick<
    DetailedMeetingflow,
    'textSummary' | 'attendees' | 'companies' | 'callRecording'
  >;
  internalDomains: string[];
  defaultSelectedContactEmail?: string;
  hideExternalAttendees?: boolean;
};

export const useShareDialogInviteShareSelectorState = ({
  organizationSlug,
  meetingPlanId,
  meetingPlan,
  internalDomains,
  defaultSelectedContactEmail,
  hideExternalAttendees = false,
}: UseShareDialogInviteShareSelectorStateProps) => {
  const forceUpdate = useForceUpdate();

  const { getAccessTokenSilently } = useAuth0();

  const [enteredEmails, setEnteredEmails] = useState<string[]>([]);
  const [message, setMessage] = useState<string>(
    meetingPlan?.textSummary || '',
  );

  const selection = useMemo(() => {
    return new Selection({
      selectionMode: SelectionMode.multiple,
      onSelectionChanged: forceUpdate,
    });
  }, [forceUpdate]);

  const [invitees, setInvitees] = useState<PersonWithKey[]>(
    meetingPlan.attendees.map((attendee) => {
      return {
        key: attendee.email,
        ...attendee,
      };
    }),
  );

  const { data: meetingPlanShares, isLoading: meetingPlanSharesLoading } =
    useQuery(
      MeetingPlanSharesQuery(organizationSlug, meetingPlanId, false),
      async () => {
        const token = await getAccessTokenSilently();
        return MeetingflowsApiClient.listShares(
          { organizationSlug, meetingPlanId },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      },
      { cacheTime: 0 },
    );

  useEffect(() => {
    if (meetingPlanShares?.data === undefined) {
      return;
    }

    const merged: Record<string, PersonWithKey> = Object.fromEntries([
      ...invitees.map((invitee) => [
        invitee.email,
        OmitValues(invitee, 'share'),
      ]),
      ...meetingPlanShares.data.map((share) => [
        share.email,
        {
          key: share.email,
          ...invitees.find((invitee) => invitee.email === share.email),
          share: share,
        },
      ]),
    ] as [string, PersonWithKey][]);

    const newInvitees = Object.values(merged);
    let updatedSelection = newInvitees;

    if (hideExternalAttendees) {
      updatedSelection = newInvitees.filter((invitee) => {
        return internalDomains.includes(invitee?.emailDomain?.toLowerCase());
      });
    }

    setInvitees(newInvitees);
    selection.setItems(updatedSelection);

    // Set the default selection if there is one
    if (defaultSelectedContactEmail) {
      selection.setKeySelected(defaultSelectedContactEmail, true, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalDomains, meetingPlanShares?.data, selection]);

  return {
    enteredEmails,
    setEnteredEmails,
    message,
    setMessage,
    invitees,
    setInvitees,
    selection,
    meetingPlanShares,
    meetingPlanSharesLoading,
    hideExternalAttendees,
  };
};

type InviteAndNotifyActionSummaryProps = {
  organizationSlug: string;
  email: string;
  name?: string | null;
  orgRole?: OrganizationUserRole;
  inviteRole?: OrganizationUserRole;
};

const InviteAndNotifyActionSummary = ({
  organizationSlug,
  email,
  name,
  orgRole,
  inviteRole,
}: InviteAndNotifyActionSummaryProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const { data: contact, isLoading: contactLoading } = useQuery(
    OrganizationContactQuery(organizationSlug, email),
    async () => {
      const token = await getAccessTokenSilently();
      return ContactsApiClient.getContact(organizationSlug, email, {
        headers: { Authorization: `Bearer ${token}` },
      });
    },
    {
      enabled: !name || !orgRole,
    },
  );

  const label = useMemo(() => {
    return contact?.data?.name || name || email;
  }, [contact?.data?.name, email, name]);

  const role = useMemo(() => {
    return contact?.data?.orgRole ?? orgRole;
  }, [contact?.data?.orgRole, orgRole]);

  return (
    <div
      key={email}
      style={{
        display: 'flex',
        padding: '.25rem 0',
      }}
    >
      <Text
        style={{
          flex: 1,
          color: NeutralColors.gray200,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: FontSizes.small,
        }}
      >
        {label}
      </Text>
      <Text
        style={{
          fontSize: FontSizes.small,
          flex: 1,
          color: NeutralColors.gray200,
        }}
      >
        {role ? (
          titleCase(role)
        ) : contactLoading ? (
          <Spinner size={SpinnerSize.xSmall} />
        ) : (
          'Not a member'
        )}
      </Text>
      <Text
        style={{
          fontSize: FontSizes.small,
          flex: 1,
          color: NeutralColors.gray200,
        }}
      >
        {role ? (
          <>Notify</>
        ) : contactLoading ? (
          <Spinner size={SpinnerSize.xSmall} />
        ) : (
          <>Invite as {inviteRole} and notify</>
        )}
      </Text>
    </div>
  );
};

export type InviteContactsDialogProps = {
  organizationSlug: string;
  meetingPlanId: string;
  meetingPlan: Pick<
    DetailedMeetingflow,
    'companies' | 'textSummary' | 'callRecording'
  >;
  internalDomains: string[];
  onConfirm: (contacts: Contact[], message: string) => void;
  onCancel: () => void;
  defaultSelectedContactEmail?: string;
  notesYArray: Y.XmlText;
  tasks: Task[];
  recordingTimestamp?: number;
};

export const InviteContactsDialog = ({
  organizationSlug,
  meetingPlanId,
  meetingPlan,
  internalDomains,
  onConfirm,
  onCancel,
  defaultSelectedContactEmail,
  enteredEmails,
  setEnteredEmails,
  message,
  setMessage,
  invitees,
  setInvitees,
  selection,
  meetingPlanShares,
  meetingPlanSharesLoading,
  hideExternalAttendees,
  notesYArray,
  tasks,
  recordingTimestamp,
  ...rest
}: IDialogProps &
  InviteContactsDialogProps &
  ReturnType<typeof useShareDialogInviteShareSelectorState>) => {
  const { isDark } = useLightOrDarkMode();
  const theme = useTheme();
  const { domainRules } = useOrganization(organizationSlug);
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [isGenerating] = useState<boolean>(false);
  const [includeTimestamp, setIncludeTimestamp] = useState<boolean>(
    recordingTimestamp ? true : false,
  );
  const appInsights = useAppInsightsContext();

  const [includeCallInsights, setIncludeCallInsights] =
    useLocalStorageState<boolean>(
      'invite-and-notify-include-call-insights',
      true,
    );

  const getInviteAsRoleForEmailAddress = (emailAddress: string) => {
    const emailDomain = getDomain(emailAddress);
    const domainRule = domainRules?.find((dr) => dr.domain === emailDomain);
    return domainRule?.roleType || 'GUEST';
  };

  const columns: IColumn[] = [
    {
      key: 'company',
      name: 'Company',
      minWidth: 36,
      maxWidth: 36,
      fieldName: 'company',
      onRender: (person: PersonWithKey) => {
        const company = meetingPlan.companies.find(
          (c) =>
            c?.domains
              ?.map((domain) => domain.domain)
              .includes(person.emailDomain),
        );
        return (
          <div style={{ paddingTop: '2px' }}>
            {company ? (
              <CompanyCard
                company={company}
                organizationSlug={organizationSlug}
                logoOnly
                onClick={() => {
                  navigate(
                    `/organization/${organizationSlug}/library/companies/${company.id}`,
                  );
                }}
              />
            ) : undefined}
          </div>
        );
      },
    },
    {
      key: 'email',
      name: 'Email',
      minWidth: 200,
      fieldName: 'email',
      onRender: (person: PersonWithKey) => (
        <ContactCard
          contact={person}
          organizationSlug={organizationSlug}
          domain={person.emailDomain}
          hideBottomBorder
          hideSocialIcons
          onContactClick={() => {} /* noop */}
        />
      ),
    },
  ];

  const internalInvitees = invitees.filter(
    (a) =>
      internalDomains.includes(a?.emailDomain?.toLowerCase()) &&
      a?.id !== undefined,
  );

  const externalInvitees = invitees.filter(
    (a) =>
      !internalDomains.includes(a?.emailDomain?.toLowerCase()) &&
      a?.id !== undefined,
  );

  const sharesOnly = invitees.filter((a) => a.id === undefined && a.share);

  const items =
    !hideExternalAttendees && SHOW_SHARE_ACCESS_USERS
      ? [
          ...orderBy(
            internalInvitees,
            ['share', 'emailDomain'],
            ['desc', 'asc'],
          ),
          ...orderBy(
            externalInvitees,
            ['share', 'emailDomain'],
            ['desc', 'asc'],
          ),
          ...orderBy(sharesOnly, ['share', 'emailDomain'], ['desc', 'asc']),
        ]
      : SHOW_SHARE_ACCESS_USERS
        ? [
            ...orderBy(internalInvitees, ['emailDomain']),
            ...orderBy(sharesOnly, ['share', 'emailDomain'], ['desc', 'asc']),
          ]
        : !hideExternalAttendees
          ? [
              ...orderBy(internalInvitees, ['emailDomain']),
              ...orderBy(externalInvitees, ['emailDomain']),
            ]
          : orderBy(internalInvitees, ['emailDomain']);

  const groups =
    !hideExternalAttendees && SHOW_SHARE_ACCESS_USERS
      ? [
          {
            key: 'internal',
            name: 'Internal Attendees',
            startIndex: 0,
            count: internalInvitees.length,
            level: 0,
            isCollapsed: false,
          },
          {
            key: 'external',
            name: 'External Attendees',
            startIndex: internalInvitees.length,
            count: externalInvitees.length,
            level: 0,
            isCollapsed: false,
          },
          {
            key: 'sharesOnly',
            name: 'Non-Attendees',
            startIndex: internalInvitees.length + externalInvitees.length,
            count: sharesOnly.length,
            level: 0,
            isCollapsed: false,
          },
        ]
      : SHOW_SHARE_ACCESS_USERS
        ? [
            {
              key: 'internal',
              name: 'Internal Attendees',
              startIndex: 0,
              count: internalInvitees.length,
              level: 0,
              isCollapsed: false,
            },
            {
              key: 'sharesOnly',
              name: 'Non-Attendees',
              startIndex: internalInvitees.length,
              count: sharesOnly.length,
              level: 0,
              isCollapsed: false,
            },
          ]
        : !hideExternalAttendees
          ? [
              {
                key: 'internal',
                name: 'Internal Attendees',
                startIndex: 0,
                count: internalInvitees.length,
                level: 0,
                isCollapsed: false,
              },
              {
                key: 'external',
                name: 'External Attendees',
                startIndex: internalInvitees.length,
                count: externalInvitees.length,
                level: 0,
                isCollapsed: false,
              },
            ]
          : [
              {
                key: 'internal',
                name: 'Internal Attendees',
                startIndex: 0,
                count: internalInvitees.length,
                level: 0,
                isCollapsed: false,
              },
            ];

  const getNewlyselectedInvitees = () => {
    const selected = selection.getSelection() as PersonWithKey[];
    const selectedAndEntered = [
      ...selected,
      ...enteredEmails.map(
        (e) => ({ name: '', email: e, key: e }) as PersonWithKey,
      ),
    ];

    return selectedAndEntered;
  };

  const newlySelectedInvitees = getNewlyselectedInvitees();

  if (meetingPlanSharesLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div style={{ margin: '1rem 0' }}>
        <Text
          block
          variant="medium"
          style={{ fontWeight: FontWeights.semibold, margin: '0 0 .25rem 0' }}
        >
          Share this Meetingflow with...
        </Text>
        <StyledDetailsList
          items={items}
          groups={groups}
          columns={columns}
          selectionMode={SelectionMode.multiple}
          selection={selection}
          checkboxVisibility={CheckboxVisibility.always}
          getKey={getItemKey}
          setKey={'multiple'}
          isHeaderVisible={false}
          compact
          groupProps={ShowEmptyGroupsGroupProps}
          noDataMessage={`There are no other ${
            !hideExternalAttendees ? '' : 'internal'
          } attendees to invite to this Meetingflow.`}
          styles={{}}
        />
      </div>
      <div
        className={mergeStyles({
          '.token-input-container': {
            backgroundColor: isDark
              ? 'transparent !important'
              : MEETINGFLOW_COLORS.white,
            color: isDark ? 'white !important' : undefined,
            height: '5rem',
            marginBottom: '1rem',
            '.token-input-autosized-wrapper': {
              padding: '0 .5rem !important',

              color: isDark ? 'white !important' : undefined,
              input: {
                color: isDark ? 'white !important' : undefined,
                fontSize: `${FontSizes.small} !important`,
              },
            },
          },
          '.token-input-token': {
            height: '1.25rem !important',
            lineHeight: '1.25 !important',
            fontSize: `${FontSizes.small} !important`,
            backgroundColor: `${
              isDark ? NeutralColors.gray140 : MEETINGFLOW_COLORS.purpleGrey
            } !important`,
            color: `${
              isDark ? NeutralColors.gray20 : NeutralColors.gray150
            } !important`,
          },
          '.token-input-token-list .token-input-token .token-input-token__delete-button':
            {
              borderLeft: 'none !important',
              padding: '0 4px !important',
            },
        })}
      >
        <TokenInput
          tokenValues={enteredEmails}
          onTokenValuesChange={setEnteredEmails}
          placeholder={
            enteredEmails.length
              ? 'Enter more emails...'
              : "Not who you're looking for? Enter their email addresses..."
          }
          separators={[',', ' ']}
        />
      </div>

      <Text
        block
        variant="mediumPlus"
        style={{
          fontWeight: FontWeights.semibold,
          color: isDark ? 'white' : NeutralColors.gray200,
          marginBottom: '.5rem',
          animationName: 'fadeInAnimation',
          animationDuration: '1s',
          transitionTimingFunction: 'linear',
          animationIterationCount: '1',
          animationFillMode: 'forwards',
        }}
      >
        Message
      </Text>

      <TextField
        multiline
        placeholder={'Enter a message to the recipients...'}
        styles={{ root: { marginBottom: '1rem' }, field: { height: '12rem' } }}
        value={message}
        onChange={(_e, newValue) => {
          setMessage(newValue || '');
        }}
      />

      {recordingTimestamp ? (
        <Checkbox
          label={`Include video timestamp - ${formatSeconds(
            recordingTimestamp,
          )}`}
          onChange={(e, v) => setIncludeTimestamp(!!v)}
          defaultChecked={includeTimestamp}
        />
      ) : null}

      {meetingPlan?.callRecording?.transcriptAnalysis?.topics?.length ? (
        <Checkbox
          styles={{ root: { marginTop: '.5rem' } }}
          title="Include Call Insights"
          label="Include Call Insights"
          checked={includeCallInsights}
          onChange={(_e, checked) => setIncludeCallInsights(!!checked)}
        />
      ) : null}

      {newlySelectedInvitees.length ? (
        <>
          <Text
            block
            variant="mediumPlus"
            style={{
              fontWeight: FontWeights.semibold,
              color: isDark ? 'white' : NeutralColors.gray200,
              marginBottom: '.5rem',
              animationName: 'fadeInAnimation',
              animationDuration: '1s',
              transitionTimingFunction: 'linear',
              animationIterationCount: '1',
              animationFillMode: 'forwards',
            }}
          >
            Confirm
          </Text>
          <div
            style={{
              color: isDark ? NeutralColors.gray160 : NeutralColors.gray200,
              backgroundColor: theme.semanticColors.warningBackground,
              padding: '.5rem',
              borderRadius: '.5rem',
              marginBottom: '1rem',
              animationName: 'fadeInAnimation',
              animationDuration: '1s',
              transitionTimingFunction: 'linear',
              animationIterationCount: '1',
              animationFillMode: 'forwards',
            }}
          >
            <div
              style={{
                display: 'flex',
              }}
            >
              <Text
                style={{
                  fontSize: FontSizes.small,
                  fontWeight: FontWeights.semibold,
                  flex: 1,
                  color: NeutralColors.gray200,
                }}
              >
                Person
              </Text>
              <Text
                style={{
                  fontSize: FontSizes.small,
                  fontWeight: FontWeights.semibold,
                  flex: 1,
                  color: NeutralColors.gray200,
                }}
              >
                Current Role
              </Text>
              <Text
                style={{
                  fontSize: FontSizes.small,
                  fontWeight: FontWeights.semibold,
                  flex: 1,
                  color: NeutralColors.gray200,
                }}
              >
                Action
              </Text>
            </div>
            {newlySelectedInvitees.map((i) => (
              <InviteAndNotifyActionSummary
                key={i.email}
                organizationSlug={organizationSlug}
                email={i.email}
                name={i.name}
                orgRole={i.orgRole}
                inviteRole={getInviteAsRoleForEmailAddress(i.email)}
              />
            ))}
          </div>
        </>
      ) : null}

      <div style={{ textAlign: 'right' }}>
        <AsyncPrimaryButton
          text={
            newlySelectedInvitees.filter((i) => i.orgRole === undefined)
              .length > 0
              ? 'Invite and notify'
              : 'Notify'
          }
          disabled={(!selection.count && !enteredEmails.length) || isGenerating}
          onClick={async () => {
            const selected = selection.getSelection() as PersonWithKey[];
            const selectedAndEntered = [
              ...selected,
              ...enteredEmails.map(
                (e) => ({ name: '', email: e, key: e }) as PersonWithKey,
              ),
            ];
            if (!selectedAndEntered.length) {
              return;
            }

            const token = await getAccessTokenSilently();

            try {
              await toast.promise(
                ApiClient.post(
                  `/organization/${organizationSlug}/plan/${meetingPlanId}/share`,
                  {
                    shareWith: selectedAndEntered.map((t) => ({
                      name: t.name || undefined,
                      email: t.email,
                    })),
                    message,
                    includeRecordingTopics:
                      includeCallInsights &&
                      meetingPlan?.callRecording?.transcriptAnalysis?.topics
                        ?.length
                        ? true
                        : undefined,
                    recordingTimestamp:
                      recordingTimestamp && includeTimestamp
                        ? recordingTimestamp
                        : undefined,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                    validateStatus: (status) => [201].includes(status),
                  },
                ),
                {
                  loading: 'Sharing Meetingflow',
                  success: () => {
                    appInsights.trackEvent({
                      name: 'INVITE_AND_NOTIFY',
                      properties: {
                        organizationSlug,
                        meetingPlanId,
                        inviteCount: selectedAndEntered.length,
                      },
                    });

                    return `Shared this Meetingflow with ${
                      selectedAndEntered.length
                    } ${inflect(
                      'person',
                      selectedAndEntered.length,
                    )} via email!`;
                  },
                  error: (err) => {
                    return `Something went wrong sharing Meetingflow, please try again`;
                  },
                },
              );

              onConfirm(selectedAndEntered, message);
            } catch {}
          }}
          styles={{
            label: { color: isDark ? 'white' : undefined },
          }}
        />
      </div>
    </>
  );
};

export default InviteContactsDialog;
