import { OptionalArgTuple } from '@meetingflow/common/TypeHelpers';

export type CreateDeferredPromise<T, TContext = undefined> = (
  ...context: OptionalArgTuple<TContext>
) => DeferredPromise<T, TContext>;

export type DeferredPromise<T, TContext = undefined> = {
  resolve: (value: T | PromiseLike<T>) => void;
  reject: (reason?: unknown) => void;
  context: TContext;
  promise: ObservablePromise<T, TContext>;
};

export type PromiseState = 'pending' | 'resolved' | 'rejected';
export class ObservablePromise<T, TContext = undefined> extends Promise<T> {
  public state: PromiseState;

  public ctx: TContext;

  constructor(
    executor: (
      resolve: (value: T | PromiseLike<T>) => void,
      reject: (reason?: unknown) => void,
    ) => void,
    ...context: OptionalArgTuple<TContext>
  ) {
    super(
      (
        resolve: (value: T | PromiseLike<T>) => void,
        reject: (reason?: unknown) => void,
      ) => {
        executor(
          (value: T | PromiseLike<T>) => {
            this.state = 'resolved';
            resolve(value);
          },
          (reason?: unknown) => {
            this.state = 'rejected';
            reject(reason);
          },
        );
      },
    );
    this.state = 'pending';
    this.ctx = context[0]!;
  }

  public get context() {
    return this.ctx;
  }

  public get isPending() {
    return this.state === 'pending';
  }

  public get isResolved() {
    return this.state === 'resolved';
  }

  public get isRejected() {
    return this.state === 'rejected';
  }
}

export const makeDeferred = <T, TContext = undefined>(
  ...context: OptionalArgTuple<TContext>
) => {
  const deferred: Partial<DeferredPromise<T, TContext>> = {
    context: context[0],
  };
  const promise = new ObservablePromise<T, TContext>((resolve, reject) => {
    deferred.resolve = resolve;
    deferred.reject = reject;
  }, ...context);
  deferred.promise = promise;

  return deferred as DeferredPromise<T, TContext>;
};
